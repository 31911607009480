import moment from 'moment';
import React from 'react';
import { AttributeValue, AttributeValueType } from 'utils/types';
import AttributeChip from './AttributeChip';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';

interface AttributesProps {
    showAll?: boolean;
    attributes: Array<AttributeValue>;
}

const AttributeChips = ({ showAll = false, ...props }: AttributesProps) => {
    const { attributes } = props;
    const limitIndex = showAll ? attributes.length : 8;
    const theme = useTheme();

    const getAttributeFormattedValue = (value: AttributeValue) => {
        switch (value.type) {
            case AttributeValueType.TIME:
                return moment(value.value, 'hh:mm:ss').format('hh:mm A');
            case AttributeValueType.DATE:
                return moment(value.value, 'YYYY-MM-DD').format('MM/DD/YYYY');
            case AttributeValueType.DATETIME:
                return moment(value.value, 'YYYY-MM-DD hh:mm:ss').format('yyyy/MM/DD hh:mm a');

            default:
                return value.value;
        }
    };

    return (
        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            <React.Fragment>
                {attributes.map(
                    (attribute: AttributeValue, index: number) =>
                        index < limitIndex && (
                            <AttributeChip
                                sx={{ margin: theme.spacing(2, 2, 2, 0), maxWidth: '5000px' }}
                                label={`${attribute.name}: ${getAttributeFormattedValue(
                                    attribute,
                                )}`}
                                size="small"
                                key={index}
                            />
                        ),
                )}
                {attributes.length > 8 && !showAll && (
                    <AttributeChip
                        sx={{ margin: theme.spacing(2, 2, 2, 0), maxWidth: '5000px' }}
                        label={`+ ${attributes.length - 8}`}
                        size="small"
                    />
                )}
            </React.Fragment>
        </Box>
    );
};

export default AttributeChips;
