import { Box, Button, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { pushMessage } from '../../../redux/reducers/messages';
import { startForSelf } from '../../../redux/reducers/movementLogs';
import { useAppDispatch } from '../../../redux/store';
import ROUTES from '../../../Routes/routes';
import { skipSession } from '../api/calendar';
import { Attendance } from '../train.types';
import CardWithItems from './CardWithItems';
import { ListItemCard, RowSkeleton } from './index';

interface Props {
    attendance: Array<Attendance>;
    loading?: boolean;
    error?: string;
    title: string;
    emptyPhrase?: string;
    showOrganizationImage?: boolean;
    showLoggingButtons?: boolean;
    onSessionSkipped?: (eventId: string, individualId: string) => void;
}

const now = DateTime.now().toSeconds();

export default function SessionsCard({
    loading = false,
    attendance,
    error,
    title,
    showOrganizationImage = false,
    emptyPhrase = 'None found',
    showLoggingButtons = false,
    onSessionSkipped = () => {},
}: Props): ReactElement {
    const dispatch = useAppDispatch();
    const { push } = useHistory();

    const getColorForSession = (attendance: Attendance): string => {
        if (attendance.event.starts_at_timestamp < now && !attendance.ended_at) {
            return '#F44336';
        }

        if (attendance.event.starts_at_timestamp > now && !attendance.ended_at) {
            return '#4CAF50';
        }

        if (attendance.started_at && attendance.ended_at) {
            return '#2196F3';
        }

        return '#003E9D';
    };

    const handleLogForSelfClicked = async (sessionUuid: string, eventUuid: string) => {
        const response: any = await dispatch(
            startForSelf({
                local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                session: sessionUuid,
                event: eventUuid,
            }),
        );
        if (startForSelf.fulfilled.match(response)) {
            push(ROUTES.AddQuickSession.path.replace(':id', response.payload.uuid));
        } else {
            dispatch(
                pushMessage({
                    status: 'error',
                    message: response.payload.message,
                }),
            );
        }
    };
    const handleSessionSkipped = (attendance: Attendance) => {
        onSessionSkipped && onSessionSkipped(attendance.event.uuid, attendance.individual.uuid);
        skipSession({ event: attendance.event.uuid, individual: attendance.individual.uuid })
            .then(() => {})
            .catch((response) => {
                dispatch(
                    pushMessage({
                        status: 'error',
                        message: response.message,
                    }),
                );
            });
    };

    const handlePreviewClicked = (sessionUuid: string) => {
        push(ROUTES.ViewSession.path.replace(':id', sessionUuid));
    };

    return (
        <CardWithItems title={title}>
            {loading && <RowSkeleton />}

            {!loading && attendance.length === 0 && <Box mt={8}>{emptyPhrase}</Box>}

            {attendance.map((att) => (
                <ListItemCard
                    showLoggingButtons={showLoggingButtons}
                    image={att.event.session_photo ?? ''}
                    due={att.event.starts_at_timestamp}
                    title={att.event.session_name}
                    key={`${att.individual.uuid}${att.event.uuid}`}
                    showOrganizationImage={showOrganizationImage}
                    organizationImage={att.event.organization_photo}
                    organizationName={att.event.organization_name}
                    color={getColorForSession(att)}
                    actions={
                        <>
                            <Button
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                }}
                                onClick={(e) => {
                                    handleSessionSkipped(att);
                                    e.stopPropagation();
                                }}
                                size="small"
                            >
                                Skip
                            </Button>
                            <Button
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                }}
                                onClick={(e) => {
                                    att.event.session_id &&
                                        handlePreviewClicked(att.event.session_id);
                                    e.stopPropagation();
                                }}
                                size="small"
                                color="primary"
                            >
                                Preview
                            </Button>
                            <Button
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                }}
                                onClick={(e) => {
                                    att.event.session_id &&
                                        handleLogForSelfClicked(
                                            att.event.session_id,
                                            att.event.uuid,
                                        );
                                    e.stopPropagation();
                                }}
                                size="small"
                                color="primary"
                            >
                                Begin
                            </Button>
                        </>
                    }
                />
            ))}

            <Typography style={{ marginTop: 16 }}>{error}</Typography>
        </CardWithItems>
    );
}
