import cubejs, { CubejsApi } from '@cubejs-client/core';
import { AxiosError } from 'axios';
import * as chrono from 'chrono-node';
import { DateTime } from 'luxon';
import moment from 'moment';
import { getCubeToken, getCubeTokenForOrganization } from './api/charts.api';
import {
    AthleteSubscription,
    DateRangeValue,
    RawDataPointMetrics,
    Subscription,
} from './app.types';
import { RawDataPoint, RawDataPointEnhanced } from './modules/community/community.types';
import { LogDto, LogSet, Organization, TimeDisplayFormat } from './utils';

export const cubejsApi: CubejsApi = cubejs(() => getCubeToken().then((r) => r.data.token), {
    apiUrl: `${process.env.REACT_APP_CUBE_URL}/cubejs-api/v1`,
});

export const cubejsApiForOrganization: CubejsApi = cubejs(
    () => getCubeTokenForOrganization().then((r) => r.data.token),
    {
        apiUrl: `${process.env.REACT_APP_CUBE_URL}/cubejs-api/v1`,
    },
);

export const convertCubeDateRangeToApiDateRange = (
    dateRange: DateRangeValue,
): { start: DateTime; end: DateTime } => {
    if (typeof dateRange === 'string') {
        const date = chrono.parse(dateRange)[0];
        if (date) {
            return {
                start: date.start.date()
                    ? DateTime.fromJSDate(date.start.date()).startOf('day')
                    : DateTime.now(),
                end: date.end?.date()
                    ? DateTime.fromJSDate(date.end.date()).endOf('day')
                    : DateTime.now(),
            };
        } else {
            return {
                start: DateTime.now().minus({ days: 14 }).startOf('day'),
                end: DateTime.now().endOf('day'),
            };
        }
    }

    return {
        start: DateTime.fromISO(dateRange[0]),
        end: DateTime.fromISO(dateRange[1]),
    };
};
export const isSubscribedToVideoAnalysis = (
    organizationUuid: string,
    subscriptions: Subscription[],
) => {
    return (
        subscriptions.findIndex(
            (subscription) =>
                subscription.name === 'default' &&
                subscription.stripe_status === 'active' &&
                subscription.organization_uuid === organizationUuid,
        ) !== -1
    );
};

export const isSubscribedToSqlAccess = (
    organizationUuid: string,
    subscriptions: Subscription[],
) => {
    return (
        subscriptions.findIndex(
            (subscription) =>
                subscription.name === 'sql' &&
                subscription.stripe_status === 'active' &&
                subscription.organization_uuid === organizationUuid,
        ) !== -1
    );
};

export const isSubscribedToOrgAccess = (
    organizationUuid: string,
    subscriptions: Subscription[],
) => {
    return (
        subscriptions.findIndex(
            (subscription) =>
                (subscription.name === 'organization' ||
                    subscription.name === 'organization_combo') &&
                subscription.stripe_status === 'active' &&
                subscription.organization_uuid === organizationUuid,
        ) !== -1
    );
};
export const isSubscribedToAthleteSubscription = (subscriptions: AthleteSubscription[]) =>
    subscriptions.findIndex((subscription) => subscription.stripe_status === 'active') !== -1;
export const isSubscribedToOrgAccessTier = (
    organizationUuid: string,
    subscriptions: Subscription[],
    stripe_price: string[],
) => {
    return (
        subscriptions.findIndex(
            (subscription) =>
                (subscription.name === 'organization' ||
                    subscription.name === 'organization_combo') &&
                subscription.stripe_status === 'active' &&
                subscription.organization_uuid === organizationUuid &&
                stripe_price.indexOf(subscription.stripe_price) !== -1,
        ) !== -1
    );
};

export function formatCurrency({
    amount,
    currency,
    locale = 'en-US',
}: {
    amount: number;
    currency: string;
    locale?: string;
}): string {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    }).format(amount);
}

export function stripMarkdown(markdownText: string) {
    return markdownText
        .replace(/(\*\*|__)(.*?)\1/g, '$2') // Bold **text**
        .replace(/(\*|_)(.*?)\1/g, '$2') // Italic *text*
        .replace(/~~(.*?)~~/g, '$2') // Strikethrough ~~text~~
        .replace(/\n/g, ' ') // New lines to spaces
        .replace(/!\[(.*?)\]\((.*?)\)/g, '') // Remove images ![alt](src)
        .replace(/\[(.*?)\]\((.*?)\)/g, '$1') // Links [text](href)
        .replace(/(#{1,6})\s/g, '') // Remove headings # H1
        .replace(/(>|<)/g, '') // Blockquotes > and lists <
        .replace(/(-|\*|\+|(\d+\.)\s)/g, '') // Lists - * +
        .substring(0, 200); // Limit to 200 chars
}

export const secondsToDurationString = (seconds: number, format: TimeDisplayFormat): string => {
    return moment.duration(seconds, 'seconds').format(format as string, {
        trim: false,
    });
};
export const millisecondsToDurationString = (
    milliseconds: number,
    format: TimeDisplayFormat,
): string => {
    return moment.duration(milliseconds, 'milliseconds').format(format as string, {
        trim: false,
    });
};

export const convertToSetsForLogDto = (logs: Array<LogDto>): Array<LogSet> => {
    let goalSets = [];

    for (let i = 0; i < logs.length; i++) {
        if (!logs[i].movement) {
            continue;
        }
        if (i == 0 || logs[i].movementUuid != logs[i - 1].movementUuid) {
            goalSets.push({
                mediaUrl: logs[i].mediaUrl,
                mediaType: logs[i].mediaType,
                movement: logs[i].movement,
                logs: [logs[i]],
            });
        } else {
            goalSets[goalSets.length - 1].logs.push(logs[i]);
        }
    }
    return goalSets;
};

export function filterObject(obj: { [key: string]: any }) {
    const newObj = {} as any;

    Object.keys(obj).forEach((key) => {
        if (obj[key]) {
            newObj[key] = obj[key];
        }
    });

    return newObj;
}

export const isNumeric = (num: any) =>
    (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
    !isNaN(num as number);

export function convertHttpErrorIntoMessage(e: AxiosError, message?: string): string {
    if (e.response?.status === 422) {
        return e.response.data.errors[Object.keys(e.response.data.errors)[0]][0];
    } else {
        return message ?? 'Error. Please contact customer support.';
    }
}

export function createUrlWithParams(url: string, params: { [key: string]: string | number }) {
    const paramsString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
    return `${url}?${paramsString}`;
}

export function computeAdditionalCropMetrics(
    data: RawDataPointEnhanced[] | false,
): RawDataPointMetrics {
    if (!data || data.length === 0) {
        return {
            maxVelocity: 0,
            totalDistance: 0,
            timeOfMaxVelocity: 0,
            distanceFromStartToMaxVelocity: 0,
            distanceFromMaxVelocityToEnd: 0,
            timeFromMaxVelocityToEnd: 0,
        };
    }
    // calculate max velocity
    const mappedVelocity = data.map((point) => point['Velocity (mph)']);
    const maxVelocity = Math.max(...mappedVelocity);

    // calculate index of maxVelocity
    const indexOfMaxVelocity = mappedVelocity.indexOf(maxVelocity);

    // calculate time of maxVelocity
    const timeOfMaxVelocity = data[indexOfMaxVelocity]['Duration (s)'];

    // calculate distance from start to maxVelocity
    const distanceFromStartToMaxVelocity = data[indexOfMaxVelocity]['Cumulative Distance (yd)'];

    // calculate total distance
    const totalDistance = data[data.length - 1]['Cumulative Distance (yd)'];

    // calculate distance from maxVelocity to end
    const distanceFromMaxVelocityToEnd =
        data[data.length - 1]['Cumulative Distance (yd)'] - distanceFromStartToMaxVelocity;

    //calculate time from maxVelocity to end
    const timeFromMaxVelocityToEnd = data[data.length - 1]['Duration (s)'] - timeOfMaxVelocity;

    return {
        maxVelocity,
        timeOfMaxVelocity,
        totalDistance,
        distanceFromStartToMaxVelocity,
        distanceFromMaxVelocityToEnd,
        timeFromMaxVelocityToEnd,
    };
}

export function getAllRawDataForPoints(data: RawDataPoint[] | false): RawDataPointEnhanced[] {
    if (!data) {
        return [];
    }
    const points: RawDataPointEnhanced[] = data.map((point) => {
        const pointEnhanced = { ...point } as RawDataPointEnhanced;
        pointEnhanced['Velocity (mph)'] = point['Velocity (m/s)'] * 2.23694;
        pointEnhanced['Duration (s)'] = (point['Timestamp'] - data[0]['Timestamp']) / 1000;
        return pointEnhanced;
    });

    return points.reduce((acc: RawDataPointEnhanced[], obj, index) => {
        const distance = obj['Distance (m)'] * 1.09361;
        const cumulativeDistance =
            index > 0 ? (acc[index - 1]['Cumulative Distance (yd)'] ?? 0) + distance : distance;
        acc.push({ ...obj, 'Cumulative Distance (yd)': cumulativeDistance });
        return acc;
    }, []);
}

export const hasNonPersonalOrganization = (organizations: Organization[]): boolean => {
    return organizations.findIndex((organization) => organization.type !== 'personal') !== -1;
};

export function sampleRawDataPointEnhanced(
    rawData: RawDataPointEnhanced[],
    frequency: number = 10,
): RawDataPointEnhanced[] {
    const sampledData: RawDataPointEnhanced[] = [];
    for (let i = 0; i < rawData.length; i += frequency) {
        sampledData.push(rawData[i]);
    }
    return sampledData;
}

export const distinctColorsHex = [
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FF00FF',
    '#00FFFF',
    '#800000',
    '#008000',
    '#000080',
    '#808000',
    '#800080',
    '#008080',
    '#C00000',
    '#00C000',
    '#0000C0',
    '#C0C000',
    '#C000C0',
    '#00C0C0',
    '#400000',
    '#004000',
    '#000040',
    '#404040',
    '#400040',
    '#004040',
    '#404000',
    '#200000',
    '#002000',
    '#000020',
    '#202000',
    '#200020',
    '#002020',
    '#600000',
    '#006000',
    '#000060',
    '#606000',
    '#600060',
    '#006060',
    '#A00000',
    '#00A000',
    '#0000A0',
    '#A0A000',
    '#A000A0',
    '#00A0A0',
    '#200040',
    '#402000',
    '#204000',
    '#004020',
    '#400020',
    '#600040',
    '#406000',
    '#604000',
    '#006040',
    '#400060',
    '#A00040',
    '#40A000',
    '#A04000',
    '#00A040',
    '#4000A0',
    '#A00060',
    '#60A000',
    '#A06000',
    '#00A060',
    '#6000A0',
    '#800040',
    '#408000',
    '#804000',
    '#008040',
    '#400080',
    '#800060',
    '#608000',
    '#806000',
    '#008060',
    '#600080',
    '#C00040',
    '#40C000',
    '#C04000',
    '#00C040',
    '#4000C0',
    '#C00060',
    '#60C000',
    '#C06000',
    '#00C060',
    '#6000C0',
    '#800020',
    '#208000',
    '#802000',
    '#008020',
    '#200080',
    '#800060',
    '#608000',
    '#806000',
    '#008060',
    '#2000C0',
    '#C00020',
    '#20C000',
    '#C02000',
    '#00C020',
    '#2000C0',
];
export const formatDateTimeStringToLocale = (
    dateString?: string | null,
    timeZone?: string | null,
): string => {
    if (!dateString) {
        return '';
    }

    let date = DateTime.fromFormat(dateString, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' });

    return timeZone
        ? date.setZone(timeZone).toFormat('yyyy-MM-dd h:mm:ssa ZZZZ')
        : date.toFormat('yyyy-MM-dd h:mm:ssa ZZZZ');
};
export const formatDateStringToLocale = (dateString?: string | null): string => {
    if (!dateString) {
        return '';
    }

    const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd');

    return date.toLocaleString(DateTime.DATE_HUGE);
};
