import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AdminSprintDatabase() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Quickly scale your organization by inviting new athletes to Groups using QR
                        codes or invite links. Training and Learning Content can also be shared
                        using an invite link so users clicking on the link become part of your
                        organization.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/884056623?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Extreme Scalability'}
                            description={
                                'Quickly scale your organization using Group invite links and QR codes.'
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminSprintDatabase;
