import {
    AuthScreenLayout,
    ForgotUsernameForm,
    TextButton,
    TypographySubtitle,
    TypographyTitle,
} from 'components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from 'Routes/routes';

const ForgotUsername = () => {
    const { push } = useHistory();

    return (
        <AuthScreenLayout>
            <TypographyTitle
                sx={{
                    marginY: 5,
                }}
            >
                Forgot your username?
            </TypographyTitle>
            <TypographySubtitle
                sx={{
                    textAlign: 'center',
                    marginTop: 5,
                    marginBottom: 16,
                }}
            >
                You will receive a link to get username via email.
            </TypographySubtitle>
            <ForgotUsernameForm />
            <TextButton onClick={() => push(ROUTES.Login.path)}>Back to login</TextButton>
        </AuthScreenLayout>
    );
};

export default ForgotUsername;
