import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import VimeoEmbed from './VimeoEmbed'; // Import your VimeoEmbed component

interface VimeoCardProps {
    videoUrl: string;
    title?: string;
    description?: string;
    width?: number | string;
}

const VimeoCard: React.FC<React.PropsWithChildren<VimeoCardProps>> = ({
    videoUrl,
    title,
    description,
    width,
}) => {
    return (
        <Card sx={{ width: { width } }}>
            <VimeoEmbed videoUrl={videoUrl} /> {/* Render VimeoEmbed component inside the Card */}
            <CardContent>
                <Typography variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default VimeoCard;
