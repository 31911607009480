import { Avatar, Box, Typography } from '@mui/material';
import RowDivider from 'components/Separators/RowDivider';
import { capitalize, isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import ROUTES from 'Routes/routes';
import { SpotlightResult } from 'utils';
import { useTheme } from '@mui/system';

interface SpotlightSearchResultProps {
    result: SpotlightResult;
    onClick: () => void;
}

const SpotlightSearchResult = ({ result, onClick }: SpotlightSearchResultProps) => {
    const { push } = useHistory();
    const theme = useTheme();
    // const ability = useContext(AbilityContext);

    const onSelectResult = () => {
        switch (result.resultType.toLowerCase()) {
            case 'session': {
                push(ROUTES.EditSession.path.replace(':id', result.resultId));
                break;
            }
            case 'movement': {
                push(ROUTES.ViewMovement.path.replace(':id', result.resultId));
                break;
            }
            case 'group': {
                push(ROUTES.GroupDetails.path.replace(':id', result.resultId));
                break;
            }
            case 'individual': {
                push(ROUTES.IndividualDetails.path.replace(':individualId', result.resultId));
                break;
            }
            case 'form': {
                push(ROUTES.CreateAssessment.path.replace(':id', result.resultId));
                break;
            }
            default: {
                return;
            }
        }
        onClick();
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                '&:hover': {
                    background: theme.palette.backgroundColor.grey,
                },
            }}
            tabIndex={0}
            role="button"
            onClick={onSelectResult}
            onKeyDown={onSelectResult}
        >
            <Box display="flex" m={5} style={{ gap: 10 }}>
                <Avatar
                    src={result.avatarUrl}
                    alt={result.resultText}
                    sx={{ height: 30, width: 30 }}
                    variant={'rounded'}
                >
                    {result.resultText.trim().substring(0, 1).toUpperCase()}
                </Avatar>
                <Box width="90%">
                    <Box display="flex" alignItems="center" style={{ gap: 5 }}>
                        <Typography variant="body2">{result.resultText}</Typography>
                        {!isEmpty(result.resultType) && (
                            <Typography variant="caption" color="textSecondary">
                                ({capitalize(result.resultType)})
                            </Typography>
                        )}
                    </Box>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        component="div"
                        noWrap
                        sx={{ overflow: 'hidden', wordWrap: 'break-word' }}
                    >
                        {result.resultSubtext}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" component="div">
                        {result.resultCaption}
                    </Typography>
                </Box>
            </Box>
            <RowDivider />
        </Box>
    );
};

export default SpotlightSearchResult;
