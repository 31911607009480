import { Contact, Invite, Link, Role, SharedUser } from 'utils';
import React, { FC, useEffect, useState } from 'react';
import {
    addUser,
    getInvites,
    getLinks,
    getSharedUsers,
    removeSharedUser,
    revokeInvite,
} from 'api/Individual';

import SharingDrawer from '../../../components/Navigation/SharingDrawer';
import { getRoles } from 'api';

interface IndividualSharingDrawerProps {
    individualId: string;
    open: boolean;
    onClose: () => void;
    onUsersLoaded?: (users: Array<SharedUser>) => void;
}

const IndividualSharingDrawer: FC<React.PropsWithChildren<IndividualSharingDrawerProps>> = ({
    individualId,
    open,
    onClose,
    onUsersLoaded = () => {
        /*no-op */
    },
}) => {
    const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const [sharingInProgress, setSharingInProgress] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [links, setLinks] = useState<Array<Link>>([]);
    const [invites, setInvites] = useState<Array<Invite>>([]);

    useEffect(() => {
        loadSharedUsers(individualId);
        loadLinks(individualId);
        loadInvites(individualId);
        getRoles('individual').then((response) => {
            setRoles(response.data);
        });
    }, [individualId]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadSharedUsers = (id: string) => {
        setSharingInProgress(true);
        getSharedUsers(id).then((response) => {
            setSharedUsers(response.data);
            setSharingInProgress(false);
            onUsersLoaded(response.data);
        });
    };

    const loadLinks = (id: string) => {
        getLinks(id).then((response) => {
            setLinks(response.data);
        });
    };

    const loadInvites = (id: string) => {
        getInvites(id).then((response) => {
            setInvites(response.data);
        });
    };

    const onSharedUserRemoved = (user: SharedUser) => {
        const { uuid: userId } = user;
        setSharingInProgress(true);
        setSharedUsers((users) => users.filter((u) => u.uuid !== userId));
        onUsersLoaded(sharedUsers.filter((u) => u.uuid !== userId));
        removeSharedUser({ individualId, userId }).finally(() => {
            setSharingInProgress(false);
        });
    };

    const onShared = (message: string, users: Array<Contact>, role = 'session-manager') => {
        setSharingInProgress(true);
        addUser({ individualId, users: users.map((u) => u.uuid), role, message })
            .then((response) => {
                setSharedUsers(response.data);
                onUsersLoaded(response.data);
                loadInvites(individualId);
            })
            .finally(() => {
                setSharingInProgress(false);
            });
    };

    const handleInviteRevoked = (invite: Invite) => {
        setInvites((invites) => invites.filter((i) => i.uuid !== invite.uuid));
        revokeInvite({ individualId, inviteId: invite.uuid });
    };

    const handleInviteResent = (invite: Invite) => {
        onShared(
            '',
            [{ uuid: invite.email, name: invite.email, relationship: 'invitee', type: 'email' }],
            invite.role.name,
        );
    };

    const handleLinkDeletedClicked = (link: Link) => {
        setLinks((links) => links.filter((l) => l.uuid !== link.uuid));
    };
    const handleLinkCreated = (link: Link) => {
        setLinks((links) => [link, ...links]);
    };

    return (
        <SharingDrawer
            open={open}
            onClose={onClose}
            userAccessList={sharedUsers}
            onRemove={onSharedUserRemoved}
            onShared={onShared}
            processing={sharingInProgress}
            onLinkCreated={handleLinkCreated}
            onLinkDeleteClicked={handleLinkDeletedClicked}
            onLinkDeleteFailed={() => {}}
            onLinkDeleteSucceeded={() => {}}
            onInviteRevoked={handleInviteRevoked}
            onInviteResent={handleInviteResent}
            entityId={individualId}
            entityType="individuals"
            links={links}
            roles={roles}
            invites={invites}
        />
    );
};

export default IndividualSharingDrawer;
