import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    LinearProgress,
    Typography,
} from '@mui/material';
import { MediaType, somethingWentWrong, UploadedMedia } from 'utils';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import useTheme from '@mui/material/styles/useTheme';

import CloudUpload from '@mui/icons-material/CloudUpload';
import RoundButton from 'components/Buttons/RoundButton';
import S3Upload from 'utils/S3Upload';
import { authSelector } from 'redux/reducers/auth';
// import { confirmViaDialog } from './ConfirmationDialog';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

interface ImageUploadDialogProps {
    open: boolean;
    onMediaSelection: (uploadedMedia: UploadedMedia[]) => void;
    onClose: () => void;
    allow: MediaType;
    multiple?: boolean;
    buttonText?: string;
}

export default function ImageUploadDialog({
    onMediaSelection,
    onClose,
    multiple = false,
    allow,
    open,
    buttonText = 'Upload',
}: ImageUploadDialogProps): ReactElement {
    // We are going to have a reference to the hidden input element with type file
    // So we can trigger open the file selection dialog automatically when needed
    const [fileInputRef, setFileInputRef] = useState<HTMLInputElement | null>(null);
    const onInputRefCb = useCallback((inputNode: any) => {
        // ref value changed to node
        setFileInputRef(inputNode);
    }, []);

    useEffect(() => {
        if (open && !multiple && fileInputRef) {
            fileInputRef.click();
        }
    }, [fileInputRef, open, multiple]);

    const [uploadedMediaList, setUploadedMediaList] = useState<UploadedMedia[]>([]);
    const uploadedMediaListRef = useRef<UploadedMedia[]>([]);
    uploadedMediaListRef.current = uploadedMediaList;

    const [uploading, setUploading] = useState<boolean>(false);

    // We are using `totalCompleted` counter to check if all files have been uploaded
    // If not, we should not let user click on `Attach Media` button because not all
    // uploaded media items in the list will have the uploaded s3 tmp url path
    const [totalCompleted, setTotalCompleted] = useState<number>(0);
    const totalCompletedRef = useRef<number>(0);
    totalCompletedRef.current = totalCompleted;
    const theme = useTheme();
    const { currentUser } = useSelector(authSelector);

    // Uploading file to s3 using multi-part uploads
    const uploadToS3 = (uploadedMedia: UploadedMedia) => {
        const uploader = new S3Upload();

        uploader.onStarted = () => {
            setUploading(true);
            let mediaList = cloneDeep(uploadedMediaListRef.current);
            let mediaToUpdate = mediaList?.find(({ uuid }) => uuid == uploadedMedia.uuid);
            if (mediaToUpdate) {
                mediaToUpdate.uploadProgress = 0;
                setUploadedMediaList(mediaList);
            }
        };

        uploader.onBeforeSigner = (fileName, xhr) => {
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', `Bearer ${currentUser?.accessToken}`);
        };

        uploader.onProgress = debounce((_1, _2, p) => {
            let mediaList = cloneDeep(uploadedMediaListRef.current);
            let mediaToUpdate = mediaList?.find(({ uuid }) => uuid == uploadedMedia.uuid);
            if (
                mediaToUpdate &&
                mediaToUpdate?.uploadProgress &&
                mediaToUpdate.uploadProgress < 100
            ) {
                mediaToUpdate.uploadProgress = p;
                setUploadedMediaList(mediaList);
            }
        }, 1000);

        uploader.onComplete = (_1, awsObjectKey) => {
            let mediaList = cloneDeep(uploadedMediaListRef.current);
            let mediaToUpdate = mediaList?.find(({ uuid }) => uuid == uploadedMedia.uuid);
            if (mediaToUpdate) {
                mediaToUpdate.uploadProgress = 100;
                // Save the aws tmp url (tmp/object-uuid)
                mediaToUpdate.tmpFileUrl = awsObjectKey;
                setUploadedMediaList(mediaList);
                // Increament the number of completed uploads
                // This is required later to check if all files have been uploaded
                setTotalCompleted(totalCompletedRef.current + 1);
            }
            setUploading(false);
        };

        uploader.onError = () => {
            somethingWentWrong();
            setUploading(false);
        };

        uploader.uploadToS3(uploadedMedia.uploadedFile as File);
    };

    const setSelectedFile = (selectedFiles: FileList | null) => {
        if (!selectedFiles) return;

        // For single file upload
        if (!multiple && uploadedMediaList.length === 1) {
            const [file] = selectedFiles;
            let dataUrl = getDataUrl(file);

            let uploadedMedia = {
                // This uuid is just for internal JS reference to
                // find the element from the list and update the object values
                uuid: uuidv4(),
                // The mime type
                type: file.type,
                // The tmp file url from s3
                tmpFileUrl: '',
                // File data blob url(not the actual long blob stream but its the blob url to avoid using lot memory)
                fileDataUrl: dataUrl,
                // Original file n ame
                originalName: file.name,
                // The uploaded file instance
                uploadedFile: file,
            } as UploadedMedia;

            // Initial the upload to s3 filesystem
            uploadToS3(uploadedMedia);
            setUploadedMediaList([uploadedMedia]);
        } else {
            // For multiple file upload
            let newUploadedMediaList = Array.from(selectedFiles || []).map((file: File) => {
                let dataUrl = getDataUrl(file);

                let uploadedMedia = {
                    // This uuid is just for internal JS reference to
                    // find the element from the list and update the object values
                    uuid: uuidv4(),
                    // The mime type
                    type: file.type,
                    // The tmp file url from s3
                    tmpFileUrl: '',
                    // File data blob url(not the actual long blob stream but its the blob url to avoid using lot memory)
                    fileDataUrl: dataUrl,
                    // Original file n ame
                    originalName: file.name,
                    // The uploaded file instance
                    uploadedFile: file,
                } as UploadedMedia;

                // Initial the upload to s3 filesystem
                uploadToS3(uploadedMedia);
                return uploadedMedia;
            });

            // Append the newly uploaded files to the current media list
            setUploadedMediaList([
                ...(cloneDeep(uploadedMediaList) || []),
                ...newUploadedMediaList,
            ]);
        }
    };

    // TODO: determine where to re-integrate this part of the flow
    // Cancel an uploaded file after confirmation
    // We are not sending a request to delete the uploaded file because
    // These files go on s3 tmp bucket first and are deleted after 24 hours
    // So these partially uploaded/cancelled files will be auto deleted next day
    // const cancelUpload = async (index: number) => {
    //     if (
    //         await confirmViaDialog({
    //             confirmation: {
    //                 title: 'Are you sure?',
    //                 message: 'This will cancel the upload progress.',
    //             },
    //         })
    //     ) {
    //         let updatedUploadedMedia = cloneDeep(uploadedMediaList) as UploadedMedia[];
    //         updatedUploadedMedia.splice(index, 1);
    //         setUploadedMediaList(updatedUploadedMedia);
    //     }
    // };

    // Get data url in form of a string/blob of the uploaded media
    const getDataUrl = (file: File): string | null => {
        return URL.createObjectURL(file);
    };

    // Get the allowed mime type for the HTML file input based on
    // the allow property
    const getAllowedMimeType = (): string => {
        switch (allow) {
            case MediaType.image:
                return 'image/*';

            case MediaType.video:
                return 'video/*';

            case MediaType.imageOrVideo:
                return 'image/*,video/*';

            case MediaType.file:
                return '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

            default:
                return '*';
        }
    };

    const resetForNextUpload = () => {
        setUploadedMediaList([]);
        setTotalCompleted(0);
    };

    return (
        <Dialog open={open} aria-labelledby="upload-media-dialog">
            <DialogContent>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-around"
                    mt={6}
                    sx={{ overflow: 'hidden', backgroundColor: theme.palette.background.paper }}
                >
                    {/* An empty grid list item button to trigger new upload selection window */}

                    <ImageList rowHeight={180} sx={{ width: 500, justifyContent: 'center' }}>
                        {(multiple || (!multiple && uploadedMediaList.length == 0)) && (
                            <ImageListItem
                                component="label"
                                sx={{
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    background: theme.palette.common.white,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    '& .MuiGridListTile-tile': {
                                        color: theme.palette.primary.main,
                                        height: 'auto',
                                    },
                                }}
                            >
                                <CloudUpload
                                    fontSize="large"
                                    sx={{ fontSize: 24, color: theme.palette.primary.main }}
                                />
                                <input
                                    type="file"
                                    hidden
                                    accept={getAllowedMimeType()}
                                    multiple={multiple}
                                    ref={onInputRefCb}
                                    onChange={(event) => setSelectedFile(event.target.files)}
                                />
                                <Typography variant="h6">Upload Media</Typography>
                            </ImageListItem>
                        )}

                        {!isEmpty(uploadedMediaList) &&
                            uploadedMediaList?.map((media: UploadedMedia, index) => {
                                return (
                                    <ImageListItem key={index}>
                                        {/* When the media is a video, add video preview */}
                                        {media.type.startsWith('video') && (
                                            // eslint-disable-next-line jsx-a11y/media-has-caption
                                            <video
                                                width="320"
                                                height="240"
                                                controls={true}
                                                autoPlay={false}
                                                style={{ width: '100%', cursor: 'pointer' }}
                                                playsInline
                                            >
                                                <source
                                                    src={media.fileDataUrl || ''}
                                                    type={media.type}
                                                />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                        {/* When the media is an image, add image preview */}
                                        {media.type.startsWith('image') && (
                                            <img
                                                src={media.fileDataUrl || ''}
                                                alt={media.originalName}
                                            />
                                        )}
                                        {/* For all other types no preview available */}
                                        <ImageListItemBar title={media.originalName} />
                                    </ImageListItem>
                                );
                            })}
                    </ImageList>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box width="100%" mb={12} sx={{ color: theme.palette.primary.main }}>
                    {uploading ? (
                        <Box textAlign="center" mb={18}>
                            <Box mb={8}>Uploading file</Box>
                            <LinearProgress style={{ width: 320, margin: '0 auto' }} />
                        </Box>
                    ) : (
                        <>
                            {uploadedMediaList.length > 0 && (
                                <Box flex="1 0 100%" textAlign="center" mb={8}>
                                    <label htmlFor="reuploadFile" style={{ cursor: 'pointer' }}>
                                        choose another file
                                    </label>
                                    <input
                                        id="reuploadFile"
                                        name="reuploadFile"
                                        type="file"
                                        hidden
                                        accept={getAllowedMimeType()}
                                        multiple={multiple}
                                        onChange={(event) => setSelectedFile(event.target.files)}
                                    />
                                </Box>
                            )}
                            <Box
                                display="flex"
                                flexWrap="wrap"
                                justifyContent="center"
                                style={{ gap: 8 }}
                            >
                                <RoundButton
                                    disableElevation
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        onClose();
                                        // Set current media list to empty for the next upload
                                        resetForNextUpload();
                                    }}
                                >
                                    Cancel
                                </RoundButton>
                                {uploadedMediaList.length > 0 && (
                                    <RoundButton
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        disabled={uploading}
                                        onClick={() => {
                                            // Sent the uploaded media list to the parent component
                                            onMediaSelection(uploadedMediaList);
                                            onClose();
                                            // Set current media list to empty for the next upload
                                            resetForNextUpload();
                                        }}
                                    >
                                        {buttonText}
                                    </RoundButton>
                                )}
                            </Box>
                        </>
                    )}
                </Box>
            </DialogActions>
        </Dialog>
    );
}
