import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { ReactElement } from 'react';

export default function LandingPageSkeleton(): ReactElement {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Grid container direction="column" alignItems="center" spacing={10}>
            <Grid item>
                <Skeleton variant="circular" width={100} height={100} />
            </Grid>
            <Grid item>
                <Skeleton variant="text" width={300} />
            </Grid>
            <Grid
                item
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Skeleton variant="text" width={breakpoint ? 225 : 400} />
                <Skeleton variant="text" width={breakpoint ? 250 : 375} />
                <Skeleton variant="text" width={breakpoint ? 275 : 425} />
            </Grid>
            <Grid item>
                <Skeleton variant="rectangular" width={breakpoint ? 275 : 450} height={210} />
            </Grid>
        </Grid>
    );
}
