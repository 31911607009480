import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Fields, UserData } from 'utils';

type ReturnType = Promise<AxiosResponse<UserData>>;

const updateUserProfile = (
    userUuid: string | null,
    first_name: string | null,
    last_name: string | null,
    email: string | null,
    phone: string | null,
    profile_photo: string | null,
    fields?: Fields,
): ReturnType => {
    let payload = {
        first_name,
        last_name,
        email,
        phone,
        profile_photo,
        fields,
    };

    return Request.put(
        `/users/${userUuid}`,
        Object.fromEntries(Object.entries(payload).filter(([, v]) => v != null)),
    );
};

export default updateUserProfile;
