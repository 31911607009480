import { Request } from 'api';
import { AxiosResponse } from 'axios';
import {Link, OrganizationPublic} from 'utils';

type ReturnType = Promise<AxiosResponse<OrganizationPublic>>;

const getOrganizationBySlug = (slug: string): ReturnType => {
    return Request.get(`/public/marketplace/organization/${slug}`);
};

export default getOrganizationBySlug;
