import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { SearchToken } from 'utils';

type ReturnType = Promise<AxiosResponse<AlgoliaResponse>>;

export enum AlgoliaIndex {
    Movement = 'movement_index',
    Group = 'group_index',
    Site = 'site_index',
    Individual = 'individual_index',
    Program = 'program_index',
}

export interface AlgoliaSearchProps {
    searchQuery: string;
    searchToken: SearchToken;
    filters?: Array<any> | null;
    attributes?: Array<string> | null;
    hitsPerPage?: number | null;
    page?: number | null;
}

export interface AlgoliaResponse {
    hits: any[]; // We have the new types which are prefixed with `Searched...` e.g. SearchecMovement in our types file
    hitsPerPage: number;
    nbHits: number;
    nbPages: number;
    page: number;
    params: string;
    processingTimeMS: number;
    query: string;
}

const search = (
    index: AlgoliaIndex,
    {
        searchQuery,
        searchToken,
        filters = [],
        attributes = [],
        hitsPerPage,
        page = 0,
    }: AlgoliaSearchProps,
): ReturnType => {
    return Request.post(
        `${process.env.REACT_APP_ALGOLIA_API_HOST}/indexes/${index}/query`,
        {
            query: searchQuery ?? undefined,
            // Filters and facetFilters are basically used for same purpose. however, to simplify the API call here
            // We will use facetFilters to search through primary attributes e.hg as type of movement, name etc
            // and filters to search through attributes of an entity (_tags in terms of algolia indexed document)
            // Ref : https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/filters-and-facetfilters/
            facetFilters: filters,
            // `[back, neck]` becomes `back OR neck`
            filters: attributes?.map((attr) => attr.trim()).join(' OR '),
            // No of results to show per page
            // Avoid brute forcing from frontend
            hitsPerPage: Math.max(
                hitsPerPage ||
                    parseInt(process.env.REACT_APP_ALGOLIA_API_MIN_HITS_PER_PAGE as string, 10),
                1000,
            ),
            page: page,
            // We are sending the API key in the request body(undocumented) instead of sending it in the request header.
            // This key may become large as it's a signed key from user's permissions
            apiKey: searchToken.key,
        },
        {
            headers: {
                'X-Algolia-Application-Id': process.env.REACT_APP_ALGOLIA_APP_ID,
            },
        },
    );
};

export default search;
