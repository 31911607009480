import { Box, Grid } from '@mui/material';
import { validateInvite } from 'api';
import acceptInvite from 'api/Public/acceptInvite';
import { ButtonWithLoader, CustomButton, TypographySubtitle, TypographyTitle } from 'components';
import LandingPageHeroCard from 'components/Cards/LandingPageHeroCard';
import { LandingPageSkeleton } from 'components/Skeletons';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { authSelector, logOut, shuffleOrganizationsLoadKey } from 'redux/reducers/auth';
import { userSelector } from 'redux/reducers/user';
import ROUTES from 'Routes/routes';
import { NumberParam, useQueryParam } from 'use-query-params';
import { ShareableInvite } from 'utils';
import { getRedirectPath } from '../../utils/redirectionPath';

export default function AcceptInvite(): ReactElement {
    const { token }: { token: string } = useParams();
    const [loading, setLoading] = useState(false);
    const [accepting, setAccepting] = useState(false);
    const [invite, setInvite] = useState<ShareableInvite>();
    const [error, setError] = useState('');
    const { isAuth, currentUser } = useSelector(authSelector);
    const dispatch = useDispatch();
    const { userData } = useSelector(userSelector);
    const { push } = useHistory();
    const [autoAccept] = useQueryParam('auto_accept', NumberParam);

    useEffect(() => {
        if (autoAccept === 1) {
            handleInviteAccepted();
        }
    }, [autoAccept]);
    const handleInviteAccepted = () => {
        setAccepting(true);
        acceptInvite(token)
            .then(() => {
                if (!invite?.shareable_type) {
                    return;
                }
                // shuffle the organization load key from auth state if it's an organization
                // we are accepting the invite for
                if (invite?.shareable_type === 'organization') {
                    dispatch(shuffleOrganizationsLoadKey());
                }

                push(getRedirectPath(invite?.shareable_type, invite?.shareable_uuid, invite?.role));
            })
            .catch(() => {
                setError(
                    'The invite cannot be found or it has already been accepted. Ask ' +
                        invite?.sent_by_name +
                        ' to send it again.',
                );
            })
            .finally(() => {
                setAccepting(false);
            });
    };

    const handleAuthClicked = () => {
        push(ROUTES.Register.path, {
            referrer: ROUTES.AcceptInvite.path.replace(`:token`, token) + '?auto_accept=1',
            referrer_message:
                'Finish creating an account or logging in before accessing ' + invite?.title,
        });
    };

    const handleLoginClicked = () => {
        dispatch(logOut());
        push(ROUTES.Login.path, {
            referrer: ROUTES.AcceptInvite.path.replace(`:token`, token),
            referrer_message: 'Login before accessing ' + invite?.title,
        });
    };

    useEffect(() => {
        setLoading(true);
        validateInvite(token)
            .then((response) => {
                setInvite(response.data);
            })
            .catch((error) => {
                if (error?.response?.status === 404) {
                    setError('The invite link has expired or is invalid.');
                }
                if (error?.response?.status === 410) {
                    setError('The invite link has expired or is invalid.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    return (
        <Grid
            sx={{
                height: '100%',
            }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={10}>
                {loading && <LandingPageSkeleton />}
                {!loading && (
                    <React.Fragment>
                        {error && <div>{error}</div>}
                        {!error && invite && (
                            <React.Fragment>
                                <TypographyTitle align="center">
                                    You've been invited by {invite.sent_by_name}
                                </TypographyTitle>
                                <LandingPageHeroCard
                                    title={invite.title || ''}
                                    description={invite.description || ''}
                                    avatarSrc={invite.image_url || ''}
                                />
                                <React.Fragment>
                                    {isAuth && (
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="start"
                                            alignItems="center"
                                        >
                                            <TypographySubtitle align="center">
                                                You're logged in as{' '}
                                                <b>
                                                    {userData?.user_name}({userData?.email})
                                                </b>
                                            </TypographySubtitle>
                                            <ButtonWithLoader
                                                color="primary"
                                                variant="contained"
                                                fullWidth
                                                onClick={handleInviteAccepted}
                                                disabled={accepting}
                                                loading={accepting}
                                            >
                                                Accept Invite
                                            </ButtonWithLoader>
                                            <CustomButton onClick={handleLoginClicked}>
                                                Not {userData?.user_name}? Change User
                                            </CustomButton>
                                        </Box>
                                    )}
                                    {!isAuth && (
                                        <CustomButton
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                            onClick={handleAuthClicked}
                                        >
                                            Accept Invite
                                        </CustomButton>
                                    )}
                                </React.Fragment>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </Grid>
        </Grid>
    );
}
