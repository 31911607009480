import { confirmViaDialog } from '../../../components/Dialogs/ConfirmationDialog';
import { FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
    Asset,
    Attribute,
    AttributeValue,
    EntityType,
    Movement,
    PartialUploadList,
} from 'utils/types';
import { FormModal } from '../../../components/Dialogs';
import {
    archiveMovement,
    attachMediaAsAsset,
    getAttaching,
    removeMultipleAssets,
    unArchiveMovement,
    updateMovement,
} from 'redux/reducers/movements';
import { useAppDispatch } from 'redux/store';
import { getPartialUploadList, uploadFile } from 'redux/reducers/media';
import { useSelector } from 'react-redux';
import { authSelector } from 'redux/reducers/auth';
import { EXIT_TEXT, isSubset, SAVE_INFORMATION_TEXT, SAVE_TEXT, UNSAVED_CHANGES_TEXT } from 'utils';
import { isRejected } from '@reduxjs/toolkit';
import { loadAttributes } from 'redux/reducers/attributes';
import EditMovementForm from './EditMovementForm';

interface EditMovementModalProps {
    /** open Whether or not the modal is open */
    open: boolean;
    /** onClose when the modal is closed */
    onClose: () => void;
    /** movement The current selected movement */
    movement: Movement;
    /** attributes The array of attributes */
    attributes: Array<Attribute>;
}

export default function EditMovementModal({
    open,
    onClose,
    movement,
    attributes,
}: EditMovementModalProps): ReactElement {
    const dispatch = useAppDispatch();
    const onButtonClicked = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };
    const { currentUser } = useSelector(authSelector);
    const uploads = useSelector(getPartialUploadList);
    const attaching = useSelector(getAttaching);
    const [filteredUploads, setFilteredUploads] = useState<PartialUploadList | undefined>();

    useEffect(() => {
        setFilteredUploads(
            Object.keys(uploads).reduce((newObject, key) => {
                if (uploads[key].entityId === movement.uuid) {
                    newObject[key] = uploads[key];
                }
                return newObject;
            }, {} as PartialUploadList),
        );
    }, [uploads, movement]);

    const formRef = useRef<FormikProps<FormikValues>>(null);
    const [assetsToDelete, setAssetsToDelete] = useState<Asset[]>([]);
    const [attributeValues, setAttributeValues] = useState<AttributeValue[]>(
        movement?.attributes || [],
    );
    const onAssetDeleted = (movement: Movement, asset: Asset) => {
        setAssetsToDelete([...assetsToDelete, asset]);
    };
    const checkChange = () => {
        const values = formRef.current?.values;
        if (attributeValues.length !== movement?.attributes?.length) return true;
        if (
            values?.name !== movement.name ||
            (values?.description !== movement.description && values.description) ||
            (movement.description && !values.description)
        )
            return true;
        for (let i = 0; i < attributeValues.length; i++) {
            if (attributeValues[i].name !== movement?.attributes[i].name) return true;
        }
        return false;
    };
    const handleClose = async () => {
        if (checkChange()) {
            const res = await confirmViaDialog({
                confirmation: {
                    title: UNSAVED_CHANGES_TEXT,
                    message: SAVE_INFORMATION_TEXT,
                    cancelButtonText: EXIT_TEXT,
                    okButtonText: SAVE_TEXT,
                    onCancelPressed: () => onCancel(),
                },
            });
            res && onButtonClicked();
        } else onCancel();
    };
    const onCancel = () => {
        setAssetsToDelete([]);
        onClose();
    };
    const onFileSelected = (file: File): void => {
        dispatch(
            uploadFile(currentUser?.accessToken || '', file, EntityType.MOVEMENT, movement.uuid),
        );
    };

    const [loading, setLoading] = useState<boolean>(false);

    const onArchive = () => {
        setLoading(true);

        dispatch(archiveMovement({ movementUuid: movement.uuid })).then((response) => {
            setLoading(false);
            if (!isRejected(response)) {
                onClose();
            }
        });
    };

    const onUnarchive = () => {
        setLoading(true);

        dispatch(unArchiveMovement({ movementUuid: movement.uuid })).then((response) => {
            setLoading(false);
            if (!isRejected(response)) {
                onClose();
            }
        });
    };

    const onSubmit = (values: FormikValues) => {
        setLoading(true);
        Promise.all([
            dispatch(
                updateMovement({
                    uuid: movement.uuid,
                    name: values.name,
                    description: values.description,
                    attributes: attributeValues,
                    movement_presets: values.movement_presets,
                    cover_asset_uuid: values.cover_asset_uuid || null,
                }),
            ),
            dispatch(
                removeMultipleAssets(
                    movement.uuid,
                    assetsToDelete.map((a) => a.uuid),
                ),
            ),
        ]).then((responses) => {
            if (!isSubset(attributeValues, attributes)) {
                dispatch(loadAttributes());
            }
            setAssetsToDelete([]);
            setLoading(false);
            // Close the modal only when all promisses are successfully resolved
            if (responses.filter((response) => isRejected(response)).length == 0) {
                onClose();
            }
        });
    };
    return (
        <FormModal
            handleButtonClicked={onButtonClicked}
            disabled={loading || attaching}
            loading={loading || attaching}
            handleClose={handleClose}
            open={open}
            title="Edit Movement"
            deleteText={movement.is_archived ? 'Unarchive' : 'Archive'}
            handleDelete={() => {
                handleClose();
                if (movement.is_archived) {
                    onUnarchive();
                } else {
                    onArchive();
                }
            }}
        >
            {!!movement && (
                <EditMovementForm
                    onAssetDeleted={(asset) => onAssetDeleted(movement, asset)}
                    onAttributeValuesChanged={setAttributeValues}
                    innerRef={formRef}
                    onSubmit={onSubmit}
                    attributes={attributes}
                    movement={movement}
                    onFileSelected={onFileSelected}
                    onMediaSelected={(media) => {
                        dispatch(
                            attachMediaAsAsset({
                                mediaUuid: media.uuid,
                                movementUuid: movement.uuid,
                            }),
                        );
                    }}
                    assetsToDelete={assetsToDelete}
                    uploads={filteredUploads || {}}
                />
            )}
        </FormModal>
    );
}
