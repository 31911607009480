import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';
import {
    getEntriesGroupedByDate,
    getTimelineSelector,
    loadDimensions,
    loadEntries,
    selectFilters,
} from '../../../redux/reducers/timeline';
import { useAppDispatch } from '../../../redux/store';
import { Dimensions } from '../../../utils';
import { DynamicTimeline } from '../components';

const TimelineScene: React.FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useAppDispatch();

    const {
        filters,
        next,
        isLoading,
        isLoaded,
        filtersLoaded,
        filtersLoading,
        selectedFilters,
        unattestedActivities,
        archivedActivities,
    } = useSelector((state: RootState) => getTimelineSelector(state));

    const entries = useSelector(getEntriesGroupedByDate);
    useEffect(() => {
        dispatch(loadDimensions());
    }, [dispatch]);

    const loadMore = () => {
        dispatch(loadDimensions());
        dispatch(loadEntries({ page: next ?? '', selectedFilters }));
    };

    useEffect(() => {
        dispatch(loadEntries({ selectedFilters }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters]);

    const handleFilters = (selectedFilters: Dimensions[]) => {
        dispatch(selectFilters(selectedFilters));
    };

    return (
        <DynamicTimeline
            entries={entries}
            filters={filters}
            filtersLoading={filtersLoading}
            filtersLoaded={filtersLoaded}
            next={next}
            loadMore={loadMore}
            handleFilters={handleFilters}
            isLoading={isLoading}
            isLoaded={isLoaded}
            selectedFilters={selectedFilters}
            archivedActivities={archivedActivities}
            unattestedActivities={unattestedActivities}
        />
    );
};

export default TimelineScene;
