import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HideImageIcon from '@mui/icons-material/HideImage';
import SearchIcon from '@mui/icons-material/Search';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    IconButton,
    Input,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { Theme } from '@mui/material/styles';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { getAllMedia, uploadMedia } from '../../api/media.api';
import { filterObject } from '../../app.functions';
import { Media } from '../../app.types';
import { getMp4ClipFromRenditions, getThumbnailFromRenditions, MediaType } from '../../utils';
import { OrganizationFilterList } from '../Filters';
import ImageUploadDialog from './ImageUploadDialog';

interface Props extends DialogProps {
    onSelected: (media: Media) => void;
    onClose: () => void;
    /**
     * onInputClick is an optional prop. If you pass it in, you are expected to prompt a file
     * input from the parent component.
     */
    onInputClick?: () => void;
    showVideo?: boolean;
    showImage?: boolean;
    organization?: string;
    autoSelectMediaAfterUpload?: boolean;
}

const searchBarSx = (theme: Theme) => ({
    flexGrow: 1,
    borderRadius: 14,
    padding: theme.spacing(2),
    margin: theme.spacing(4, 0, 4, 2),
    backgroundColor: 'white',
    color: theme.palette.text.secondary,

    '&&&:before': {
        borderBottom: 'none',
    },
    '&&:after': {
        borderBottom: 'none',
    },
    '&::placeholder': {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(6),
    },

    [theme.breakpoints.down(900)]: {
        minWidth: 250,
    },

    [theme.breakpoints.down('md')]: {
        borderRadius: '0px',
        margin: theme.spacing(0),
    },
});

export default function ChooseMediaModal({
    open,
    fullScreen,
    onSelected,
    onClose,
    showVideo = false,
    showImage = true,
    onInputClick = undefined,
    organization = '',
    autoSelectMediaAfterUpload = false,
}: Props): ReactElement {
    const limit = 5;
    const [media, setMedia] = useState<Media[]>([]);
    const [showUpload, setShowUpload] = useState<boolean>(false);
    const [zoom, setZoom] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [hasAnotherPage, setHasAnotherPage] = useState<boolean>(false);
    const [category, setCategory] = useState<string>('all');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [searchValue, setSearchValue] = useState<string>('');
    const [uploading, setUploading] = useState<boolean>(false);
    const [debouncedSearchValue] = useDebounce(searchValue, 300);
    const { organizations } = useContext(OrganizationsContext);
    const [selectedOrganization, setSelectedOrganization] = useState<string | false>(false);

    useEffect(() => {
        if (!open) {
            return;
        }
        let payload = { limit, page: 1 } as any;
        if (category !== 'all') payload['filter[category]'] = category;
        if (debouncedSearchValue) payload['filter[search]'] = debouncedSearchValue;
        if (selectedOrganization) payload['filter[organization_uuid]'] = selectedOrganization;
        if (showVideo && !showImage) payload['filter[is_video]'] = true;
        if (showImage && !showVideo) payload['filter[is_image]'] = true;
        if (showImage && showVideo) payload['filter[is_either]'] = true;
        getAllMedia(payload)
            .then((response) => {
                setMedia(response.data.data);
                setPage(response.data.meta.current_page);
                setHasAnotherPage(!!response.data.links.next);
            })
            .catch();
    }, [category, debouncedSearchValue, selectedOrganization, showVideo, showImage, open]);

    function handleNextPage() {
        let payload = { page: page + 1, limit } as any;
        if (category !== 'all') payload['filter[category]'] = category;
        if (debouncedSearchValue) payload['filter[search]'] = debouncedSearchValue;
        if (showVideo && !showImage) payload['filter[is_video]'] = true;
        if (showImage && !showVideo) payload['filter[is_image]'] = true;
        if (showImage && showVideo) payload['filter[is_either]'] = true;
        getAllMedia(payload)
            .then((response) => {
                setMedia((media) => [...media, ...response.data.data]);
                setPage(response.data.meta.current_page);
                setHasAnotherPage(!!response.data.links.next);
            })
            .catch();
    }

    const getAllow = useCallback(() => {
        if (showVideo && !showImage) return MediaType.video;
        if (showImage && !showVideo) return MediaType.image;
        if (showImage && showVideo) return MediaType.imageOrVideo;
        return MediaType.image;
    }, [showImage, showVideo]);

    const onOrganizationSelected = (organization: string | false) => {
        setSelectedOrganization(organization);
    };

    const theme = useTheme();

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            onClose={onClose}
            scroll="paper"
            sx={{
                '& .MuiDialog-paper': {
                    [theme.breakpoints.up('sm')]: {
                        height: '80vh',
                        minWidth: '80vw',
                        maxWidth: '80vw',
                    },
                    backgroundImage: 'linear-gradient( 185deg, #11196E 10%, #010525 100%)',
                },
            }}
        >
            <DialogTitle
                sx={{
                    '& .MuiDialogTitle-root': {
                        borderBottomStyle: 'solid',
                        borderWidth: 1,
                        borderBottom: '#11196E',
                    },
                }}
            >
                {uploading && <LinearProgress />}

                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    flexWrap={'wrap'}
                >
                    <IconButton
                        sx={{
                            '& .MuiIconButton-root': {
                                color: 'white',
                            },
                        }}
                        onClick={onClose}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Select
                        sx={searchBarSx(theme)}
                        value={category}
                        onChange={(e) => setCategory(e.target.value as string)}
                    >
                        <MenuItem value={'all'}>All Categories</MenuItem>
                        <MenuItem value={'group'}>Groups</MenuItem>
                        <MenuItem value={'movement'}>Movements</MenuItem>
                        <MenuItem value={'session'}>Sessions</MenuItem>
                        <MenuItem value={'individual'}>Athletes</MenuItem>
                        <MenuItem value={'form'}>Assessments</MenuItem>
                        <MenuItem value={'user'}>Profile Photos</MenuItem>
                        <MenuItem value={'other'}>Uploaded By Me</MenuItem>
                    </Select>

                    <Input
                        onChange={(e) => setSearchValue(e.target.value as string)}
                        value={searchValue}
                        sx={{
                            ...searchBarSx(theme),
                            '& .MuiInputBase-input': {
                                marginLeft: theme.spacing(6),
                            },
                        }}
                        startAdornment={<SearchIcon />}
                        placeholder={'Search For Images...'}
                    />

                    <Button
                        variant={'contained'}
                        sx={{ backgroundColor: '#11196E', color: 'white' }}
                        size={'large'}
                        onClick={() => {
                            if (onInputClick) {
                                onInputClick();
                            } else {
                                setShowUpload(true);
                            }
                        }}
                    >
                        Upload New
                        <CloudUploadIcon style={{ margin: 8 }} />
                    </Button>

                    <Button variant={'contained'} onClick={() => setZoom(!zoom)}>
                        {!zoom ? `Zoom In` : 'Zoom Out'}
                    </Button>
                    <Typography style={{ color: '#BDBDBD' }}>
                        <OrganizationFilterList
                            organizations={organizations}
                            onClicked={(organization) => onOrganizationSelected(organization.uuid)}
                            selectedOrganization={selectedOrganization}
                        />
                    </Typography>
                    <ImageUploadDialog
                        open={showUpload}
                        onClose={() => setShowUpload(false)}
                        onMediaSelection={(files) => {
                            setUploading(true);
                            uploadMedia(
                                filterObject({
                                    path: files[0].tmpFileUrl,
                                    name: files[0].originalName,
                                    mime_type: files[0].type,
                                    size: files[0].uploadedFile.size,
                                    organization,
                                }),
                            )
                                .then((response) => {
                                    setMedia((media) => [response.data, ...media]);
                                    autoSelectMediaAfterUpload && onSelected(response.data);
                                })
                                .catch((e) => {
                                    if (e.response.status === 422) {
                                        setErrorMessage(
                                            e.response.data.errors[
                                                Object.keys(e.response.data.errors)[0]
                                            ][0],
                                        );
                                    } else {
                                        setErrorMessage('Unexpected error while saving.');
                                    }
                                })
                                .finally(() => setUploading(false));
                        }}
                        allow={getAllow()}
                    />
                </Box>

                {errorMessage && (
                    <Box color={red[200]}>
                        {errorMessage} You can try uploading another file or contact customer
                        support.
                    </Box>
                )}
            </DialogTitle>
            <DialogContent sx={{ minHeight: 400 }}>
                {media.map((m) => (
                    <Paper
                        sx={{
                            margin: theme.spacing(3, 0, 2, 0),
                            minHeight: 60,
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            cursor: 'pointer',
                            '&:hover > .addButton': {
                                marginRight: 15,
                                visibility: 'visible',
                                opacity: 1,
                            },
                        }}
                        onClick={() => onSelected(m)}
                        key={m.uuid}
                    >
                        {m.mime_type.startsWith('image') && (
                            <Box
                                component="img"
                                sx={{
                                    width: !zoom ? 100 : 250,
                                    height: 'auto',
                                    marginRight: 5,
                                }}
                                src={m.url}
                                alt={m.uuid}
                            />
                        )}
                        {m.mime_type.startsWith('video') && m.renditions.length > 0 && (
                            // eslint-disable-next-line jsx-a11y/media-has-caption

                            <Box
                                component="video"
                                sx={{
                                    width: 100,
                                    height: 'auto',
                                    marginRight: 5,
                                }}
                                poster={getThumbnailFromRenditions(m.renditions)}
                                controls={false}
                                autoPlay={true}
                                playsInline={true}
                                loop={true}
                                muted
                            >
                                <source
                                    src={getMp4ClipFromRenditions(m.renditions)}
                                    type={m.mime_type}
                                />
                            </Box>
                        )}
                        {m.mime_type.startsWith('video') && m.renditions.length === 0 && (
                            <Tooltip
                                placement={'right-start'}
                                title="Video optimization in progress. You can use this video in the meantime."
                            >
                                <Box
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                    }}
                                >
                                    <HideImageIcon />
                                    <Box sx={{ color: grey[700], fontSize: '0.7em' }}>
                                        Preview Unavailable
                                    </Box>
                                </Box>
                            </Tooltip>
                        )}
                        <Box
                            className="addButton"
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                right: 20,
                                top: 0,
                                visibility: 'hidden',
                                opacity: 0,
                                transition: 'opacity .2s, visibility .2s',
                            }}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <AddCircleIcon color={'primary'} />
                        </Box>
                        <Typography variant="subtitle1">{m.name}</Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-around"
                            paddingLeft="16px"
                            marginLeft="auto"
                        >
                            <Avatar
                                src={m.organization?.logo_url ?? ''}
                                sx={{
                                    marginRight: theme.spacing(4),
                                    marginLeft: theme.spacing(4),
                                    width: theme.spacing(12),
                                    height: theme.spacing(12),
                                }}
                                alt={''}
                            />
                        </Box>
                    </Paper>
                ))}
                <Box display={'flex'} justifyContent={'center'}>
                    <Button
                        sx={{
                            color: 'white',
                        }}
                        disabled={!hasAnotherPage}
                        onClick={() => handleNextPage()}
                    >
                        Show More
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
