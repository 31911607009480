import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateTime } from 'luxon';
import React from 'react';
import { DateRange } from '@mui/x-date-pickers-pro/models';

const CustomDateRangePicker = ({
    value,
    onChange,
}: {
    value: DateRange<DateTime>;
    onChange: (value: DateRange<DateTime>) => void;
}) => {
    return (
        <DateRangePicker
            value={value}
            onChange={(newValue) => {
                onChange(newValue);
            }}
            slotProps={{
                textField: {
                    size: 'small',
                },
                fieldSeparator: {
                    children: ' to ',
                },
            }}
        />
    );
};

export default CustomDateRangePicker;
