import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Session } from 'utils';
import { SessionDuplicationPayload } from '../train.types';

export function duplicateSession(
    id: string,
    payload: SessionDuplicationPayload,
): Promise<AxiosResponse<Session>> {
    return Request.post(`/workouts/sessions/${id}/duplicate`, payload);
}

export function archiveSession(id: string): Promise<AxiosResponse> {
    return Request.post(`/workouts/sessions/${id}/archive`, {});
}

export function unarchiveSession(id: string): Promise<AxiosResponse> {
    return Request.post(`/workouts/sessions/${id}/unarchive`, {});
}

export function markSessionAsFavorite(id: string): Promise<AxiosResponse> {
    return Request.post(`/workouts/sessions/${id}/favorite`, {});
}

export function markSessionAsNotFavorite(id: string): Promise<AxiosResponse> {
    return Request.post(`/workouts/sessions/${id}/unfavorite`, {});
}

export function viewSession(id: string): Promise<AxiosResponse<Session>> {
    return Request.get(`/workouts/sessions/${id}`);
}
