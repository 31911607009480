import { Box, Typography, List, ListItem } from '@mui/material';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import ReactFlow, {
    Background,
    Controls,
    useEdgesState,
    useNodesState,
    useReactFlow,
    ReactFlowProvider,
} from 'reactflow';
import PartnerViewNode from '../components/PartnerViewNode';
import { useNetworkingEnrollerTree, useNetworkingRanks } from '../state/NetworkingContext';
import FilterPanel from '../components/NodeFilter';
import { filterNodes, getLayoutedElements } from '../networking.functions';

const Flow = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [nameFilter, setNameFilter] = useState('');
    const [rankFilter, setRankFilter] = useState<string[]>([]);
    const [salesFilter, setSalesFilter] = useState<number | ''>('');
    const [subscriptionFilter, setSubscriptionFilter] = useState<string[]>([]);
    const [athleteFilter, setAthleteFilter] = useState<string[]>([]);
    const ranksData = useNetworkingRanks();
    const enrollerTree = useNetworkingEnrollerTree();
    const nodeTypes = useMemo(() => ({ partnerViewer: PartnerViewNode }), []);
    const { setCenter } = useReactFlow();

    useEffect(() => {
        if (!enrollerTree.loaded || !enrollerTree.data || !ranksData.loaded) {
            return;
        }
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            enrollerTree.data,
            '',
            false,
            ranksData.data || [],
            'horizontal',
        );
        setNodes(layoutedNodes);
        console.log(layoutedNodes);
        setEdges(layoutedEdges);
    }, [setNodes, setEdges, enrollerTree, ranksData.loaded, ranksData.data]);

    const filteredNodes = useMemo(
        () =>
            filterNodes(
                nodes,
                nameFilter,
                rankFilter,
                salesFilter,
                subscriptionFilter,
                athleteFilter,
            ),
        [nodes, nameFilter, rankFilter, salesFilter, subscriptionFilter, athleteFilter],
    );

    const filteredEdges = useMemo(() => {
        const nodeIds = new Set(filteredNodes.map((node) => node.id));
        return edges.filter((edge) => nodeIds.has(edge.source) && nodeIds.has(edge.target));
    }, [filteredNodes, edges]);

    const alphabetizedNodes = useMemo(() => {
        return filteredNodes
            .filter((node) => node.data.matchesFilter)
            .sort((a, b) => a.data.label.localeCompare(b.data.label))
            .map((node) => ({ id: node.id, label: node.data.label }));
    }, [filteredNodes]);

    const handleNodeClick = useCallback(
        (nodeId: string) => {
            const node = nodes.find((n) => n.id === nodeId);
            if (node) {
                setCenter(node.position.x + 200, node.position.y + 100, { zoom: 1, duration: 500 });
            }
        },
        [nodes, setCenter],
    );

    return (
        <Box sx={{ height: '100%', display: 'flex' }}>
            <Box
                sx={{
                    width: '15%',
                    p: 2,
                    borderRight: 1,
                    borderColor: 'divider',
                    backgroundColor: 'white',
                    overflowY: 'auto',
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Filters
                </Typography>
                <FilterPanel
                    nameFilter={nameFilter}
                    setNameFilter={setNameFilter}
                    rankFilter={rankFilter}
                    setRankFilter={setRankFilter}
                    salesFilter={salesFilter}
                    setSalesFilter={setSalesFilter}
                    subscriptionFilter={subscriptionFilter}
                    setSubscriptionFilter={setSubscriptionFilter}
                    athleteFilter={athleteFilter}
                    setAthleteFilter={setAthleteFilter}
                />

                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                    }}
                >
                    <Typography gutterBottom>Organizations ({alphabetizedNodes.length})</Typography>
                    {alphabetizedNodes.length ? (
                        <List
                            dense
                            disablePadding
                            sx={{
                                border: '1px solid lightgray',
                                padding: '4px',
                                borderRadius: '4px',
                                maxHeight: '180px',
                                overflowY: 'auto',
                            }}
                        >
                            {alphabetizedNodes.map((node) => (
                                <ListItem
                                    key={node.id}
                                    disableGutters
                                    onClick={() => handleNodeClick(node.id)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                        },
                                    }}
                                >
                                    <Typography variant="body2">{node.label}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        ''
                    )}
                </Box>
            </Box>

            {/* ReactFlow Canvas */}
            <Box sx={{ width: '85%', height: '100%' }}>
                <ReactFlow
                    nodeTypes={nodeTypes}
                    nodes={filteredNodes}
                    edges={filteredEdges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    minZoom={0.1}
                    fitView
                >
                    <Background />
                    <Controls />
                </ReactFlow>
            </Box>
        </Box>
    );
};

const EnrollerTreeScene = () => {
    return (
        <ReactFlowProvider>
            <Flow />
        </ReactFlowProvider>
    );
};

export default EnrollerTreeScene;
