import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';

const CustomButton: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
    return (
        <Button
            {...props}
            sx={{
                textTransform: 'none',
                fontSize: 14,
                maxWidth: '100%',
                ...props.sx, // to allow further style customizations
            }}
        >
            {props.children}
        </Button>
    );
};

export default CustomButton;
