import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { SharedUser } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<SharedUser>>>;

const addUsersToForm = (
    id: string,
    users: Array<string>,
    role: string,
    message?: string,
): ReturnType => {
    return Request.post(`/data/forms/${id}/users`, {
        users,
        role,
        message,
    });
};

export default addUsersToForm;
