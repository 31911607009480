import React, { FunctionComponent, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogContent,
    Link,
    Tab,
    Tabs,
    useMediaQuery,
} from '@mui/material';
import { getFollowers, getFollowing } from '../api';
import { useAppDispatch } from '../../../redux/store';
import { feedActions } from '../../../redux/reducers/social';
import { SocialUser } from '../social.types';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/system';

interface OwnProps {
    open: boolean;
    onClose: () => void;
    initialTab: number;
}

type Props = OwnProps;

const FollowerFollowingDialog: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
    const [followers, setFollowers] = useState<Array<SocialUser>>([]);
    const [followersLoaded, setFollowersLoaded] = useState<boolean>(false);
    const [following, setFollowing] = useState<Array<SocialUser>>([]);
    const [followingLoaded, setFollowingLoaded] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<number>(props.initialTab);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useAppDispatch();
    const { push } = useHistory();
    const onFollowClicked = (slug: string) => {
        dispatch(feedActions.follow({ user: slug }));
        setFollowing((users) =>
            users.map((user) =>
                user.slug === slug ? Object.assign({}, user, { isFollowing: true }) : user,
            ),
        );
    };
    const onUnfollowClicked = (slug: string) => {
        dispatch(feedActions.unfollow({ user: slug }));
        setFollowing((users) =>
            users.map((user) =>
                user.slug === slug ? Object.assign({}, user, { isFollowing: false }) : user,
            ),
        );
    };
    useEffect(() => {
        getFollowers()
            .then((followers) => {
                setFollowers(followers.data.data);
                setFollowersLoaded(true);
            })
            .catch(() => {});
        getFollowing()
            .then((following) => {
                setFollowing(following.data.data.map((f) => ({ ...f, isFollowing: true })));
                setFollowingLoaded(true);
            })
            .catch(() => {});
    }, []);
    return (
        <Dialog onClose={props.onClose} open={props.open} fullWidth={isMobile}>
            <DialogContent sx={{ height: 375 }}>
                <Tabs
                    value={selectedTab}
                    onChange={(event, newValue) => setSelectedTab(newValue)}
                    aria-label="basic tabs example"
                >
                    <Tab label="Followers" />
                    <Tab label="Following" />
                </Tabs>
                {selectedTab === 0 && (
                    <div>
                        {followers.length === 0 && followersLoaded && (
                            <p>Inspire others and share your invite link to get Followers!</p>
                        )}
                        {followers.map((f: any) => (
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                                key={f.slug}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar src={f.photo} alt={`${f.firstName} ${f.lastName}`} />
                                    <Link
                                        sx={{ paddingLeft: 8 }}
                                        href="#"
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                            push(`/@${f.slug}`);
                                            e.preventDefault();
                                        }}
                                    >
                                        {f.firstName} {f.lastName}
                                    </Link>
                                </Box>
                            </Box>
                        ))}
                    </div>
                )}
                {selectedTab === 1 && (
                    <div>
                        {following.length === 0 && followingLoaded && (
                            <p>
                                No Followers yet :( Inspire others by sharing links and following
                                each other!
                            </p>
                        )}
                        {following.map((f: any) => (
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                                key={f.slug}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar src={f.photo} alt={`${f.firstName} ${f.lastName}`} />
                                    <Link
                                        sx={{ paddingLeft: 8 }}
                                        href="#"
                                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                            push(`/@${f.slug}`);
                                            e.preventDefault();
                                        }}
                                    >
                                        {f.firstName} {f.lastName}
                                    </Link>
                                </Box>
                                {!!f.isFollowing && (
                                    <Button
                                        onClick={() => onUnfollowClicked(f.slug)}
                                        sx={{
                                            background: `${theme.palette.backgroundColor.freeSpeechBlue}!important`,
                                            padding: theme.spacing(2, 5),
                                            marginRight: theme.spacing(8),
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            lineHeight: '22px',
                                            color: theme.palette.backgroundColor.paper,
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Following
                                    </Button>
                                )}
                                {!f.isFollowing && (
                                    <Button
                                        variant="outlined"
                                        onClick={() => onFollowClicked(f.slug)}
                                        sx={{
                                            borderColor:
                                                theme.palette.backgroundColor.freeSpeechBlue,
                                            color: theme.palette.backgroundColor.freeSpeechBlue,
                                            padding: theme.spacing(2, 5),
                                            marginRight: theme.spacing(8),
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            lineHeight: '22px',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Follow
                                    </Button>
                                )}
                            </Box>
                        ))}
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default FollowerFollowingDialog;
