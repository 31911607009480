import { Crop, RawDataPointEnhanced } from '../../community/community.types';
import { RawDataPointMetrics } from '../../../app.types';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { distinctColorsHex } from '../../../app.functions';
import CustomTooltipRawGpsData from '../../../components/Charting/CustomTooltipRawGpsData';
import { Exploration, ExplorationFilters } from '../explore.types';
import { convertApiFiltersToExplorationFilters } from '../explore.func';
import { formatNumber } from '../../community/community.func';

const ExplorationCropsSingleChart = ({
    exploration,
    crops,
    cropData,
    cropAdditionalData,
    onExcludedCropsChanged,
    filters,
}: {
    exploration: Exploration;
    crops: Crop[];
    cropData: { [key: string]: RawDataPointEnhanced[] | false };
    cropAdditionalData: { [key: string]: RawDataPointMetrics };
    onExcludedCropsChanged?: (crops: string[]) => void;
    filters: ExplorationFilters;
}) => {
    const [xAxisMax, setXAxisMax] = useState<number>(0);

    useEffect(() => {
        const max = Math.max(
            ...Object.keys(cropData).map((cropUuid) => {
                const cropDataForCrop = cropData[cropUuid];
                if (cropDataForCrop === false) {
                    return 0;
                }
                return Math.max(...cropDataForCrop.map((d) => d['Duration (s)']));
            }),
        );
        setXAxisMax(max);
    }, [cropData]);

    return (
        <Box>
            <Box
                style={{
                    width: '100%',
                    height: 600,
                    backgroundColor: '#D9DADF',
                    borderRadius: 20,
                    border: '0px solid #A7ABB7',
                    background:
                        'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                    padding: 20,
                }}
            >
                <ResponsiveContainer width={'100%'} height={'100%'}>
                    <LineChart>
                        <Tooltip
                            cursor={false}
                            content={(props) => (
                                <CustomTooltipRawGpsData {...props} showAllSeries={true} />
                            )}
                        />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis
                            tickCount={xAxisMax * 10}
                            allowDuplicatedCategory={false}
                            type={'number'}
                            dataKey="Duration (s)"
                            label={{
                                value: 'Duration (s)',
                                position: 'insideBottom',
                                offset: 30,
                            }}
                            angle={-90}
                            textAnchor="end"
                            height={100}
                            style={{
                                fontSize: '0.50rem',
                            }}
                            domain={[0, xAxisMax]}
                        />
                        <YAxis
                            label={{
                                value: convertApiFiltersToExplorationFilters(exploration.filters)
                                    .yAxisKey,
                                angle: -90,
                                position: 'insideBottomLeft',
                                offset: 20,
                            }}
                            domain={[0, 'dataMax']}
                            tickFormatter={formatNumber}
                            style={{
                                fontSize: '0.75rem',
                            }}
                        />
                        {Object.keys(cropData)
                            .filter((c) => {
                                // only show crops that are not excluded
                                if (filters.excludedCrops) {
                                    return filters.excludedCrops.indexOf(c) === -1;
                                }
                                return true;
                            })

                            .map((cropUuid) => (
                                <Line
                                    data={cropData[cropUuid]}
                                    name={
                                        crops.find((c) => c.uuid === cropUuid)?.upload.individual
                                            .name
                                    }
                                    key={cropUuid}
                                    dataKey={
                                        convertApiFiltersToExplorationFilters(exploration.filters)
                                            .yAxisKey
                                    }
                                    stroke={
                                        distinctColorsHex[
                                            crops.findIndex((c) => c.uuid === cropUuid)
                                        ]
                                    }
                                />
                            ))}
                    </LineChart>
                </ResponsiveContainer>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Athlete</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Crop</TableCell>
                            <TableCell>D</TableCell>
                            <TableCell>MaxV</TableCell>
                            <TableCell>T To MaxV</TableCell>
                            <TableCell>T MaxV-End</TableCell>
                            <TableCell>D To MaxV</TableCell>
                            <TableCell>D MaxV-End</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(cropAdditionalData).map((cropUuid) => (
                            <TableRow key={cropUuid}>
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        {
                                            crops.find((c) => c.uuid === cropUuid)?.upload
                                                .individual.name
                                        }
                                        {filters.excludedCrops &&
                                            filters.excludedCrops?.indexOf(cropUuid) === -1 && (
                                                <Box
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        backgroundColor:
                                                            distinctColorsHex[
                                                                crops.findIndex(
                                                                    (c) => c.uuid === cropUuid,
                                                                )
                                                            ],
                                                    }}
                                                />
                                            )}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    {crops.find((c) => c.uuid === cropUuid)?.upload.activity_date}
                                </TableCell>
                                <TableCell>
                                    {crops.find((c) => c.uuid === cropUuid)?.label}
                                </TableCell>
                                <TableCell>
                                    {cropAdditionalData[cropUuid]?.totalDistance.toFixed(2)}
                                </TableCell>
                                <TableCell>
                                    {cropAdditionalData[cropUuid]?.maxVelocity.toFixed(2)}
                                </TableCell>
                                <TableCell>
                                    {cropAdditionalData[cropUuid]?.timeOfMaxVelocity.toFixed(2)}
                                </TableCell>
                                <TableCell>
                                    {cropAdditionalData[cropUuid]?.timeFromMaxVelocityToEnd.toFixed(
                                        2,
                                    )}
                                </TableCell>
                                <TableCell>
                                    {cropAdditionalData[
                                        cropUuid
                                    ]?.distanceFromStartToMaxVelocity.toFixed(2)}
                                </TableCell>
                                <TableCell>
                                    {cropAdditionalData[
                                        cropUuid
                                    ]?.distanceFromMaxVelocityToEnd.toFixed(2)}
                                </TableCell>
                                <TableCell>
                                    {filters.excludedCrops &&
                                        filters.excludedCrops?.indexOf(cropUuid) > -1 && (
                                            <Button
                                                onClick={() => {
                                                    // call onExcludedCropsChanged with the new list of excluded crops based on filters.excludedCrops
                                                    // by removing the cropUuid from the list
                                                    onExcludedCropsChanged
                                                        ? onExcludedCropsChanged(
                                                              filters.excludedCrops
                                                                  ? filters.excludedCrops.filter(
                                                                        (c) => c !== cropUuid,
                                                                    )
                                                                  : [],
                                                          )
                                                        : () => {};
                                                }}
                                            >
                                                Add
                                            </Button>
                                        )}
                                    {!filters.excludedCrops ||
                                        (filters.excludedCrops &&
                                            filters.excludedCrops?.indexOf(cropUuid) === -1 && (
                                                <Button
                                                    onClick={() =>
                                                        // call onExcludedCropsChanged with the new list of excluded crops based on filters.excludedCrops
                                                        onExcludedCropsChanged
                                                            ? onExcludedCropsChanged([
                                                                  ...(filters.excludedCrops as string[]),
                                                                  cropUuid,
                                                              ])
                                                            : () => {}
                                                    }
                                                >
                                                    Remove
                                                </Button>
                                            ))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ExplorationCropsSingleChart;
