import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, CircularProgress, TextField, Tooltip, useTheme } from '@mui/material';
import { DataGridPremium, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import { GridColDef } from '@mui/x-data-grid-premium';
import { useNetworkingOrganization } from '../state/NetworkingContext';
import { PaidAthletePointTransaction } from '../networking.types';
import { getPaidAthletePointTransactions, getPaidAthletePointSummary } from '../networking.api';
import { GetPaidAthletePointTransactionsParams, AthletePointsSummary } from '../networking.api';
import { AttachMoney, InfoOutlined, Search } from '@mui/icons-material';

const PaidAthletePointTransactionsScene = () => {
    const theme = useTheme();
    const [transactions, setTransactions] = useState<PaidAthletePointTransaction[]>([]);
    const [summary, setSummary] = useState<AthletePointsSummary | null>(null);
    const organization = useNetworkingOrganization();

    const [totalTransactions, setTotalTransactions] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [searchText, setSearchText] = useState('');
    const [searchParams, setSearchParams] = useState<GetPaidAthletePointTransactionsParams>({
        'filter[date_between]': '',
        'filter[partner_pap_search]': '',
        sort: '-transaction_date',
        page: 1,
        per_page: 10,
    });

    const handleSearch = () => {
        const newParams = { ...searchParams };

        if (startDate && endDate) {
            if (endDate < startDate) {
                alert('End date must be after or equal to start date');
                return;
            }
            newParams['filter[date_between]'] = `${startDate},${endDate}`;
        } else {
            delete newParams['filter[date_between]'];
        }

        if (searchText) {
            newParams['filter[partner_pap_search]'] = searchText;
        } else {
            delete newParams['filter[partner_pap_search]'];
        }

        setSearchParams(newParams);
    };

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        setSearchParams({
            ...searchParams,
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        });
    };

    useEffect(() => {
        if (!organization?.data?.uuid) return;

        setIsLoading(true);
        getPaidAthletePointTransactions(organization.data.uuid, searchParams)
            .then((response) => {
                setTransactions(response.data.data);
                setTotalTransactions(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch transactions', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [organization, searchParams]);

    useEffect(() => {
        if (!organization?.data?.uuid) return;

        getPaidAthletePointSummary(organization.data.uuid)
            .then((response) => {
                setSummary(response.data);
            })
            .catch((error) => {
                console.error('Failed to fetch summary', error);
            });
    }, [organization?.data?.uuid]);

    const handleSort = async (model: GridSortModel) => {
        if (!model.length) return;

        setIsLoading(true);
        let sortField = model[0].field;
        const sortOrder = model[0].sort;

        // Only allow sorting by paps_quantity and transaction_date
        if (!['paps_quantity', 'transaction_date'].includes(sortField)) return;

        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        const params = {
            ...searchParams,
            sort: sortField as GetPaidAthletePointTransactionsParams['sort'],
        };

        setSearchParams(params);
    };

    const currentLeftPoints = Math.floor((summary?.net_paid_athletes_left ?? 0) / 100);
    const currentRightPoints = Math.floor((summary?.net_paid_athletes_right ?? 0) / 100);
    const totalLeftPoints = Math.floor((summary?.total_paid_athletes_left ?? 0) / 100);
    const totalRightPoints = Math.floor((summary?.total_paid_athletes_right ?? 0) / 100);

    const columns: GridColDef<PaidAthletePointTransaction>[] = [
        {
            field: 'paps_quantity',
            headerName: 'Paid Athlete Count',
            width: 130,
            sortable: true,
            type: 'number',
        },
        {
            field: 'leg',
            headerName: 'Leg',
            width: 100,
            sortable: false,
            renderCell: (params) => (params.value.toUpperCase() === 'L' ? 'Left' : 'Right'),
        },
        {
            field: 'partner_pap_quantities',
            headerName: 'Partner Monthly Paid Athlete Counts',
            width: 450,
            sortable: false,
            renderHeader: () => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    Partner Monthly Paid Athlete Counts
                    <Tooltip title="The numbers below show how many paid athlete subscriptions the named partner organizations in your downline had in the monthly period up to the transaction date. For every 100 paid athlete subscriptions, 1 athlete point is generated.">
                        <InfoOutlined style={{ marginLeft: '5px', fontSize: '16px' }} />
                    </Tooltip>
                </div>
            ),
            renderCell: (params) => {
                const quantities =
                    params.value as PaidAthletePointTransaction['partner_pap_quantities'];
                const content = Object.values(quantities)
                    .map((data) => `${data.name}: ${data.total}`)
                    .join(', ');

                return (
                    <Tooltip title={content}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                            }}
                        >
                            {content}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: 'transaction_date',
            headerName: 'Transaction Date',
            width: 150,
            sortable: true,
            renderCell: (params) => {
                const date = new Date(params.value as string);
                return date.toLocaleDateString();
            },
        },
    ];

    return (
        <div className="relative">
            {isLoading && (
                <div
                    style={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                    }}
                >
                    <div className="bg-white rounded-lg p-4 shadow-lg flex flex-col items-center gap-2">
                        <CircularProgress size={80} />
                    </div>
                </div>
            )}
            <Box sx={{ margin: theme.spacing(0, 2) }}>
                <Box width="98%" overflow="auto">
                    <h2>Paid Athlete Points</h2>
                    <Alert
                        icon={<AttachMoney fontSize="large" />}
                        style={{
                            margin: '10px',
                            border: '2px solid green',
                            width: '',
                            fontSize: '0.8rem',
                        }}
                    >
                        <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                            Athlete Point System Overview
                        </div>
                        <div style={{ marginBottom: '8px' }}>
                            Trigger a $150 bonus when you generate 2 Athlete Points in one leg and 3
                            Athlete Points in another leg in a single cycle. You can earn close to a
                            thousand bonuses in a cycle. See Maximum Payouts Schedule.
                        </div>
                        <div style={{ marginBottom: '8px' }}>1 cycle = 1 month</div>
                        <div style={{ marginBottom: '8px' }}>
                            1 Athlete Point = 100 paid Athlete 1TUL Subscriptions
                        </div>
                        <div style={{ marginBottom: '8px' }}>
                            Points accumulate each month starting the 2nd month of subscription and
                            rollover until paid.
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <strong>
                                Note: You must have at least a leadership rank of Manager before you
                                can collect athlete point bonuses.
                            </strong>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '16px',
                            }}
                        >
                            <div style={{ flex: 1 }}>
                                <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                    Paid Athlete Points (Current)
                                </div>
                                <div style={{ marginBottom: '4px' }}>
                                    Left Leg: {currentLeftPoints}{' '}
                                    {currentLeftPoints === 1 ? 'point' : 'points'} (
                                    {summary?.net_paid_athletes_left ?? 0} net paid monthly
                                    athletes)
                                </div>
                                <div style={{ marginBottom: '4px' }}>
                                    Right Leg: {currentRightPoints}{' '}
                                    {currentRightPoints === 1 ? 'point' : 'points'} (
                                    {summary?.net_paid_athletes_right ?? 0} net paid monthly
                                    athletes)
                                </div>
                            </div>

                            <div style={{ flex: 1 }}>
                                <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                    Paid Athlete Points (Historical)
                                </div>
                                <div style={{ marginBottom: '4px' }}>
                                    Left Leg: {totalLeftPoints}{' '}
                                    {totalLeftPoints === 1 ? 'point' : 'points'} (
                                    {summary?.total_paid_athletes_left ?? 0} total paid monthly
                                    athletes)
                                </div>
                                <div style={{ marginBottom: '4px' }}>
                                    Right Leg: {totalRightPoints}{' '}
                                    {totalRightPoints === 1 ? 'point' : 'points'} (
                                    {summary?.total_paid_athletes_right ?? 0} total paid monthly
                                    athletes)
                                </div>
                            </div>

                            <div style={{ flex: 1 }}>
                                <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                    Commission History
                                </div>
                                <div style={{ marginBottom: '4px' }}>
                                    Total Count: {summary?.total_commissions ?? 0}
                                </div>
                                <div>
                                    Total Amount: $
                                    {((summary?.total_commissions_amount ?? 0) / 100).toFixed(2)}
                                </div>
                            </div>
                        </div>
                    </Alert>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            mb: 2,
                            alignItems: 'center',
                            backgroundColor: '#f5f5f5',
                            padding: 2,
                            borderRadius: 1,
                        }}
                    >
                        <TextField
                            type="date"
                            label="Start Date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            type="date"
                            label="End Date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                min: startDate || undefined,
                            }}
                        />
                        <TextField
                            label="Search Downline Organization"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            size="small"
                            sx={{ minWidth: 200 }}
                        />
                        <Button
                            variant="contained"
                            startIcon={<Search />}
                            onClick={handleSearch}
                            size="medium"
                        >
                            Search
                        </Button>
                    </Box>

                    <DataGridPremium
                        rows={transactions}
                        columns={columns}
                        checkboxSelection={false}
                        sortingMode="server"
                        onSortModelChange={handleSort}
                        style={{ height: '624px', marginBottom: '50px' }}
                        pagination
                        pageSizeOptions={[5, 10, 20, 50, 100]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        rowCount={totalTransactions}
                        paginationMode="server"
                        disableColumnMenu={true}
                        showCellVerticalBorder={true}
                        sx={{
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell': {
                                fontSize: '0.8rem',
                            },
                            '& .MuiDataGrid-columnHeader': {
                                fontSize: '0.8rem',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontSize: '0.8rem',
                            },
                            '& .MuiDataGrid-footerContainer': {
                                fontSize: '0.8rem',
                            },
                        }}
                    />
                </Box>
            </Box>
        </div>
    );
};

export default PaidAthletePointTransactionsScene;
