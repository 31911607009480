import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Invite } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Invite>>>;

const getInvites = (individualId: string): ReturnType => {
    return Request.get(`/individuals/${individualId}/invites`);
};

export default getInvites;
