import { Close } from '@mui/icons-material';
import { Alert, IconButton, Slide, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { markMessageAsSeen, messageSelector } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import { GlobalMessage } from 'utils';

const FlashNotifications = () => {
    const dispatch = useAppDispatch();
    const { data } = useSelector(messageSelector);
    const [currentMessage, setCurrentMessage] = useState<null | GlobalMessage>(null);

    useEffect(() => {
        setCurrentMessage(data[0] || null);
    }, [data]);

    const showNextAvailableMessage = () => {
        if (currentMessage) {
            dispatch(markMessageAsSeen(currentMessage));
        }
    };

    if (!currentMessage) {
        return null;
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={showNextAvailableMessage}
            autoHideDuration={6000}
            open={true}
            TransitionComponent={Slide}
            key={currentMessage.uuid}
            action={
                <React.Fragment>
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={showNextAvailableMessage}
                    >
                        <Close />
                    </IconButton>
                </React.Fragment>
            }
        >
            <Alert onClose={showNextAvailableMessage} severity={currentMessage.status}>
                {currentMessage.message}
                {currentMessage.action && (
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={currentMessage?.action?.actionLink || ''}
                    >
                        {currentMessage?.action?.actionText || ''}
                    </a>
                )}
            </Alert>
        </Snackbar>
    );
};

export default FlashNotifications;
