import { Member } from '../../../utils';
import { Autocomplete, Avatar, TextField } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useDebounce } from 'use-debounce';
import { getMembers } from '../api/groups.api';

interface MembersAutocompleteProps {
    group: string;
    onChange: (members: any) => void;
    value: Member[] | Member | null;
    multiple?: boolean;
    size?: 'small' | 'medium';
}

function MembersAutocomplete({
    onChange,
    group,
    value,
    multiple = true,
    size = 'medium',
}: MembersAutocompleteProps) {
    const [loading, setLoading] = useState(false);
    const [memberSearchValue, setMemberSearchValue] = useState<string>('');
    const [debouncedSearchValue] = useDebounce(memberSearchValue, 300);
    const [members, setMembers] = useState<Member[]>([]);

    const loadMembers = useCallback(
        (
            params: { limit?: number; page?: number; 'filter[name]'?: string } = {
                limit: 100,
            },
        ) => {
            setLoading(true);
            getMembers(group, {
                ...params,
                include: 'individual',
            })
                .then((membersResponse) => {
                    setMembers(membersResponse.data.data);
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        },
        [group],
    );

    useEffect(() => {
        let params: { limit?: number; page?: number; 'filter[name]'?: string } = {
            limit: 200,
        };
        if (debouncedSearchValue) {
            params['filter[name]'] = debouncedSearchValue;
        }
        loadMembers(params);
    }, [debouncedSearchValue, group, loadMembers]);

    return (
        <Autocomplete
            freeSolo
            size={size}
            multiple={multiple}
            id="member-autocomplete"
            options={members}
            selectOnFocus={false}
            inputValue={memberSearchValue}
            onInputChange={(_, newInputValue) => {
                setMemberSearchValue(newInputValue);
            }}
            value={value}
            loading={loading}
            onChange={(_, value) => {
                if (typeof value !== 'string') {
                    onChange(value as Member[]);
                }
            }}
            filterOptions={(options) => options}
            getOptionLabel={(option: Member | string) => {
                if (typeof option !== 'string') {
                    return option.individual.name as string;
                }
                return option;
            }}
            getOptionKey={(option: string | Member) => {
                if (typeof option !== 'string') {
                    return option.uuid;
                }
                return option;
            }}
            isOptionEqualToValue={(option: Member, value: string | Member) => {
                if (typeof value === 'string') {
                    return false;
                }
                if (option.uuid) {
                    return option.uuid === value?.uuid;
                }
                return false;
            }}
            style={{
                width: '100%',
                maxWidth: 375,
                minWidth: 250,
                margin: 2,
            }}
            renderOption={(props, option) => {
                const { key, ...otherProps } = props as any;
                return (
                    <li key={key} {...otherProps}>
                        <Avatar src={option.individual.profile_photo ?? ''} />
                        {option.individual.name}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Choose Member(s)"
                    variant="outlined"
                    color={'primary'}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="primary" size={24} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default MembersAutocomplete;
