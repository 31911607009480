import { Box, Button, Stack, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { OrganizationsContext } from '../../contexts/OrganizationsContext';
import animationData from '../../lottie-animations/checkbox.json';
import { Organization } from '../../utils';

function OrganizationInvitationAccepted() {
    const [organization, setOrganization] = useState<Organization>();
    let { id } = useParams<{ id: string }>();
    const { organizations } = useContext(OrganizationsContext);

    useEffect(() => {
        if (organizations) {
            const org = organizations.find((org) => org.uuid === id);
            if (org) {
                setOrganization(org);
            }
        }
    }, [id, organizations]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Lottie animationData={animationData} style={{ width: '300px', height: '300px' }} />
            {organization && (
                <>
                    <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        You've joined {organization.name}!
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Try clicking some of the links to explore all that 1Tul has to offer.
                    </Typography>
                    <Stack direction={'row'} spacing={2}>
                        <Link
                            to={`/community/individuals?filter[organization]=${organization.uuid}`}
                        >
                            <Button variant="contained" color="primary">
                                View Athletes
                            </Button>
                        </Link>
                        <Link to={`/community/groups?filter[organization]=${organization.uuid}`}>
                            <Button variant="contained" color="primary">
                                View Groups
                            </Button>
                        </Link>
                    </Stack>
                </>
            )}
        </Box>
    );
}

export default OrganizationInvitationAccepted;
