import React, { FC, useEffect, useRef } from 'react';

interface Props {
    shouldScrollTo: boolean;
}

const ScrollToViewWrapper: FC<React.PropsWithChildren<Props>> = ({ children, shouldScrollTo }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (shouldScrollTo) {
            ref.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [shouldScrollTo]);

    return <div ref={ref}>{children}</div>;
};

export default ScrollToViewWrapper;
