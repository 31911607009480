import * as React from 'react';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';
import SimplifasterTimeSegments from '../media/SimplifasterTimeSegments.png';

function AdminAddMembersToGroup() {
    const openArticleInNewWindow = () => {
        window.open(
            'https://simplifaster.com/articles/prove-athletes-faster-best-evidence/',
            '_blank',
        );
    };
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Using one-second segments to measure distance, especially from a still
                        start, can offer several benefits when assessing the performance and
                        progress of athletes.
                    </Typography>
                    <Card sx={{ maxWidth: 'auto', marginTop: 8 }}>
                        <CardMedia
                            component="img"
                            alt="Simplifaster Time Segments"
                            height="auto"
                            image={SimplifasterTimeSegments}
                            sx={{ objectFit: 'cover' }}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Time Segment Analysis
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Read this Simplifaster article on how to use timesegment analysis as
                                a best evidence to prove athletes are getting faster.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={openArticleInNewWindow}>
                                View Article
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/880636769?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Analyse Time Segments'}
                            description={'Analyse Time Segments'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminAddMembersToGroup;
