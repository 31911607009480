import { Block, CheckBox, CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Container, Divider, Typography } from '@mui/material';
import { viewSubmission } from 'api';
import AssetPreviewCard from 'components/Cards/AssetPreviewCard';
import { isEmpty } from 'lodash';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
    Asset,
    Choice,
    getHtml,
    getIdFromEndOfRoute,
    Question,
    somethingWentWrong,
    Submission,
} from 'utils';

const SubmissionResult = () => {
    const [submission, setSubmission] = useState<Submission>();
    const [totalQuestions, setTotalQuestions] = useState<number>(0);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        let isMounted = true;
        viewSubmission(getIdFromEndOfRoute(location.pathname))
            .then((response) => {
                if (isMounted) {
                    setSubmission(response.data);
                    setTotalQuestions(
                        response.data.form.sections
                            .map((section) => section.questions.length)
                            .reduce((sum, noOfQuestions) => sum + noOfQuestions, 0),
                    );
                }
            })
            .catch(() => isMounted && somethingWentWrong('Failed to Load Assessment'))
            .finally(() => isMounted && setLoading(false));

        return () => {
            isMounted = false;
        };
    }, []);

    const findAnswer = (questionUuid: string): string | null => {
        return submission?.values?.find((v) => v.question.uuid == questionUuid)?.text_value || null;
    };

    const questionHasCorrectAnswers = (question: Question): boolean =>
        question?.choices?.some((choice) => choice.is_answer) || false;

    // Get choice icon to show based on the answer in the submission form
    // If answer is correct, show green, if answer is incorrect, show red
    // If answer is not what user has selected, show black
    const getChoiceIcon = (hasCorrectAnswers: boolean, choice: Choice, answer: string | null) => {
        if (choice.text === answer && !hasCorrectAnswers) {
            return <CheckBox />;
        }
        // Correct Answer
        if (choice.text === answer && choice.is_answer) {
            return (
                <CheckBox
                    sx={{
                        color: '#4CAF50',
                        '&.MuiCheckbox-checked': {
                            color: '#4CAF50',
                        },
                    }}
                />
            );
        }
        // Wrong answer
        if (choice.text === answer && !choice.is_answer) {
            return (
                <Block
                    sx={{
                        color: 'red',
                        '&.Mui-checked': {
                            color: 'green',
                        },
                    }}
                />
            );
        }
        // Potential answer which user did not choose
        if (choice.text !== answer && choice.is_answer) {
            return (
                <CheckBoxOutlined
                    sx={{
                        color: '#4CAF50',
                        '&.MuiCheckbox-checked': {
                            color: '#4CAF50',
                        },
                    }}
                />
            );
        }
        // Not an answer or selected choice
        return <CheckBoxOutlineBlank />;
    };

    return (
        <FixedLayout
            title="Assessment Submission Results"
            content={
                <Container maxWidth="md">
                    {loading ? (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 50,
                                minHeight: '200px',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box
                            textAlign="center"
                            display="flex"
                            flexDirection="column"
                            style={{ gap: 6 }}
                            mt={10}
                            mb={30}
                            sx={{
                                gap: 6,
                                mt: 10,
                                mb: 30,
                            }}
                        >
                            <Box
                                width={'100%'}
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                borderBottom={'1px solid gray'}
                                mb={8}
                            >
                                <Typography variant="h6">
                                    {submission?.form.title || 'Untitled Form'}
                                </Typography>
                                <Typography variant="body2">
                                    {submission?.individual?.name || 'Unknown'}
                                </Typography>
                            </Box>
                            {submission?.score && (
                                <Box
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    {submission.score}% ({submission.score_numerator} /
                                    {submission.score_denominator})
                                </Box>
                            )}
                            {submission?.form?.sections?.length ? (
                                submission?.form?.sections?.map((section, sectionIndex) => {
                                    // Convert markdown to HTML
                                    const descriptionHTML: string = getHtml(
                                        section.description || '',
                                    );

                                    return (
                                        <React.Fragment key={section.uuid}>
                                            <Typography variant="h6">
                                                Section {sectionIndex + 1}
                                            </Typography>
                                            <Box mb={10}>
                                                <Typography
                                                    variant="body2"
                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                >
                                                    {section.name}
                                                </Typography>
                                            </Box>
                                            <Box pt={5} pb={5} textAlign="left">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: descriptionHTML,
                                                    }}
                                                />
                                            </Box>
                                            {!isEmpty(section?.asset) && (
                                                <AssetPreviewCard
                                                    fullwidth={true}
                                                    asset={section.asset as Asset}
                                                    altTitle={section.name}
                                                    onAssetRemoved={() => {}}
                                                    disableRemove={true}
                                                />
                                            )}
                                            {totalQuestions > 0 ? (
                                                section.questions.map((question, questionIndex) => {
                                                    return (
                                                        <Box
                                                            key={question.uuid}
                                                            display="flex"
                                                            flexDirection="column"
                                                            style={{ gap: 20 }}
                                                            textAlign="left"
                                                        >
                                                            <Typography variant="h6">
                                                                Question {questionIndex + 1}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                style={{ whiteSpace: 'pre-wrap' }}
                                                            >
                                                                {question.question}{' '}
                                                            </Typography>
                                                            {question?.choices && (
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                >
                                                                    {question.choices.map(
                                                                        (choice) => {
                                                                            return (
                                                                                <Box
                                                                                    sx={{
                                                                                        display:
                                                                                            'flex',
                                                                                        alignItems:
                                                                                            'center',
                                                                                        '& > :first-of-type':
                                                                                            {
                                                                                                marginRight:
                                                                                                    '10px',
                                                                                            },
                                                                                    }}
                                                                                    key={
                                                                                        choice.uuid
                                                                                    }
                                                                                >
                                                                                    {getChoiceIcon(
                                                                                        questionHasCorrectAnswers(
                                                                                            question,
                                                                                        ),
                                                                                        choice,
                                                                                        findAnswer(
                                                                                            question?.uuid ||
                                                                                                '',
                                                                                        ),
                                                                                    )}
                                                                                    {choice.text}
                                                                                </Box>
                                                                            );
                                                                        },
                                                                    )}
                                                                </Typography>
                                                            )}
                                                            {!isEmpty(question?.asset) && (
                                                                <AssetPreviewCard
                                                                    fullwidth={true}
                                                                    asset={question.asset as Asset}
                                                                    altTitle={question.question}
                                                                    onAssetRemoved={() => {}}
                                                                    disableRemove={true}
                                                                />
                                                            )}
                                                            {question.type === 'text' && (
                                                                <Typography variant="body2">
                                                                    Submitted answer :{' '}
                                                                    {findAnswer(
                                                                        question?.uuid || '',
                                                                    ) || 'No Answer'}
                                                                </Typography>
                                                            )}

                                                            <Divider />
                                                        </Box>
                                                    );
                                                })
                                            ) : (
                                                <Typography variant="body2">
                                                    This assessment does not contain any questions.
                                                </Typography>
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <Typography variant="body2">
                                    This assessment does not contain any sections.
                                </Typography>
                            )}
                        </Box>
                    )}
                </Container>
            }
            footerActions={
                <Box display="flex" justifyContent="center">
                    <Button
                        sx={{
                            marginTop: (theme) => theme.spacing(6),
                        }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            history.push('/class');
                        }}
                    >
                        Finished
                    </Button>
                </Box>
            }
        />
    );
};

export default SubmissionResult;
