import InputBase from '@mui/material/InputBase';
import Close from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftOutlined';
import React from 'react';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

export interface SearchClearableInputProps {
    value: string;
    onClose: () => void;
    placeholderText?: string;
    onChange: (value: string) => void;
}

export default function SearchClearableCloseableInput({
    value,
    onChange,
    onClose,
    placeholderText = 'Search...',
}: SearchClearableInputProps) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.backgroundColor.main,
                '&:hover': {
                    backgroundColor: theme.palette.backgroundColor.hover,
                },
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <Box
                role="button"
                tabIndex={0}
                onClick={onClose}
                onKeyDown={onClose}
                sx={{
                    width: '8%',
                    display: 'flex',
                    alignItems: 'center',
                    '&:focus': {
                        outline: 'none',
                    },
                }}
            >
                <ChevronLeftIcon sx={{ cursor: 'pointer' }} />
            </Box>
            <InputBase
                value={value}
                sx={{
                    width: '82%',
                    fontSize: '18px',
                    color: 'inherit',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    padding: theme.spacing(6, 2, 6, 2),
                }}
                placeholder={placeholderText}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => {
                    onChange(e.target.value);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onKeyDown={(e) => {
                    e.stopPropagation();
                }}
            />
            {value && (
                <Box
                    role="button"
                    tabIndex={0}
                    onClick={() => onChange('')}
                    sx={{
                        width: '8%',
                        display: 'flex',
                        alignItems: 'center',
                        '&:focus': {
                            outline: 'none',
                        },
                    }}
                    onKeyDown={() => onChange('')}
                >
                    <Close sx={{ cursor: 'pointer' }} />
                </Box>
            )}
        </Box>
    );
}
