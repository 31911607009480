import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { DateTime } from 'luxon';
import React from 'react';
import { useSelector } from 'react-redux';
import { pushMessage } from 'redux/reducers/messages';
import { getUserPermissions, updateUserData, userSelector } from 'redux/reducers/user';
import { useAppDispatch } from 'redux/store';
import { yupSchemas } from 'utils';

const ProfileDetailsForm = () => {
    const theme = useTheme();
    const { userData } = useSelector(userSelector);
    const dispatch = useAppDispatch();
    console.log(userData);
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                firstName: userData?.first_name || '',
                lastName: userData?.last_name || '',
                email: userData?.email || '',
                phone: userData?.phone_national ? userData.phone_national.replace(/\s/g, '') : '',
                birthDate: userData?.fields?.birth_date || '',
            }}
            validationSchema={yupSchemas.profile}
            onSubmit={async (
                { firstName, lastName, email, phone, birthDate },
                { setErrors, setSubmitting },
            ) => {
                setSubmitting(true);
                const result = await dispatch(
                    updateUserData({
                        userUuid: userData?.uuid || '',
                        first_name: firstName,
                        last_name: lastName,
                        email,
                        phone,
                        profile_photo: null,
                        fields: {
                            ...userData?.fields,
                            birth_date: birthDate,
                        },
                    }),
                );
                setSubmitting(false);
                if (updateUserData.fulfilled.match(result)) {
                    // We need to update organization to make sure if there are any updates done to personal organization
                    // due to user's name change, we pull the latest org name
                    // That happens through permissions
                    dispatch(getUserPermissions({ id: userData?.uuid || '' }));

                    dispatch(
                        pushMessage({
                            status: 'success',
                            message: 'Profile updated successfully.',
                        }),
                    );
                } else {
                    if (result.payload) {
                        // Server sent validation errors
                        if ('errors' in result.payload) {
                            dispatch(
                                pushMessage({
                                    status: 'error',
                                    message: result.payload.message,
                                }),
                            );
                            setErrors(result.payload?.errors || {});
                        } else {
                            // No validation errors
                            // Something might be wrong on server side
                            dispatch(
                                pushMessage({
                                    status: 'error',
                                    message: 'Something went wrong, please try again',
                                }),
                            );
                        }
                    }
                }
            }}
        >
            {({ isSubmitting, isValid, dirty, values, setFieldValue, errors }) => (
                <Form>
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Field
                            sx={{ width: '48%', marginBottom: theme.spacing(10) }}
                            id={'firstName'}
                            component={TextField}
                            variant="filled"
                            name="firstName"
                            label="First Name"
                            style={{ justifyContent: 'flex-start' }}
                        />
                        <Field
                            sx={{ width: '48%', marginBottom: theme.spacing(10) }}
                            component={TextField}
                            variant="filled"
                            name="lastName"
                            label="Last Name"
                            style={{ justifyContent: 'flex-end' }}
                        />
                    </Box>
                    <Field
                        sx={{ width: '48%', marginBottom: theme.spacing(10) }}
                        component={TextField}
                        variant="filled"
                        name="email"
                        type="email"
                        label="Email Address"
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Field
                            id="phone"
                            name="phone"
                            mask="(999)999-9999"
                            label="Phone Number"
                            variant="filled"
                            component={TextField}
                            sx={{ width: '48%', marginBottom: theme.spacing(10) }}
                            style={{ justifyContent: 'flex-start' }}
                        />

                        <DatePicker
                            label="Birth Date"
                            value={
                                values.birthDate
                                    ? DateTime.fromFormat(values.birthDate, 'yyyy-MM-dd')
                                    : null
                            }
                            onChange={(value): void => {
                                const formattedDate = value ? value.toFormat('yyyy-MM-dd') : '';
                                setFieldValue('birthDate', formattedDate);
                            }}
                            slotProps={{
                                textField: {
                                    variant: 'filled',
                                    error: Boolean(errors.birthDate),
                                    helperText: errors.birthDate as string,
                                    sx: { width: '48%', marginBottom: theme.spacing(10) },
                                },
                            }}
                        />
                    </div>
                    {!isSubmitting && (
                        <Button
                            size="large"
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!(isValid && dirty)}
                            sx={{ width: '48%', marginBottom: theme.spacing(10) }}
                        >
                            Update Details{'  '}
                        </Button>
                    )}{' '}
                    {isSubmitting && (
                        <Button
                            size="large"
                            type="button"
                            color="primary"
                            variant="contained"
                            sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        >
                            Updating Profile{'  '}
                            <CircularProgress
                                disableShrink
                                size={35}
                                color="inherit"
                                sx={{ marginLeft: theme.spacing(5) }}
                            />
                        </Button>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default ProfileDetailsForm;
