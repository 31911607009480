import Bold from './OpenSans-Bold.ttf';
import ExtraBold from './OpenSans-ExtraBold.ttf';
import Light from './OpenSans-Light.ttf';
import Medium from './OpenSans-Medium.ttf';
import Regular from './OpenSans-Regular.ttf';
import SemiBold from './OpenSans-SemiBold.ttf';

type FontFace = {
    fontFamily?: string;
    src?: string;
    unicodeRange?: string;
    fontVariant?: string;
    fontDisplay: string;
    fontFeatureSettings?: string;
    fontVariationSettings?: string;
    fontWeight?: number | string;
    fontStyle?: string;
    fontStretch?: string;
    fontSize?: string;
};

type JSSFontface = FontFace;

const fontFamily = 'OpenSans';

const fontDisplay = 'swap';

const fontStyle = 'normal';

const unicodeRange =
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';

const OpenSansLight: JSSFontface = {
    fontStyle,
    fontFamily,
    fontDisplay,
    unicodeRange,
    fontWeight: 300,
    src: `local('${fontFamily}'), local('${fontFamily}-Light'), url(${Light}) format('ttf')`,
};
const OpenSansRegular: JSSFontface = {
    fontStyle,
    fontFamily,
    fontDisplay,
    unicodeRange,
    fontWeight: 400,
    src: `local('${fontFamily}'), local('${fontFamily}-Regular'), url(${Regular}) format('ttf')`,
};
const OpenSansMedium: JSSFontface = {
    fontStyle,
    fontFamily,
    fontDisplay,
    unicodeRange,
    fontWeight: 500,
    src: `local('${fontFamily}'), local('${fontFamily}-Regular'), url(${Medium}) format('ttf')`,
};
const OpenSansBold: JSSFontface = {
    fontStyle,
    fontFamily,
    fontDisplay,
    unicodeRange,
    fontWeight: 700,
    src: `local('${fontFamily}'), local('${fontFamily}-Bold'), url(${Bold}) format('ttf')`,
};
const OpenSansSemiBold: JSSFontface = {
    fontStyle,
    fontFamily,
    fontDisplay,
    unicodeRange,
    fontWeight: 600,
    src: `local('${fontFamily}'), local('${fontFamily}-Black'), url(${SemiBold}) format('ttf')`,
};

const OpenSansExtraBold: JSSFontface = {
    fontStyle,
    fontFamily,
    fontDisplay,
    unicodeRange,
    fontWeight: 800,
    src: `local('${fontFamily}'), local('${fontFamily}-ExtraBold'), url(${ExtraBold}) format('ttf')`,
};

const OpenSans = [
    OpenSansBold,
    OpenSansLight,
    OpenSansMedium,
    OpenSansRegular,
    OpenSansSemiBold,
    OpenSansExtraBold,
];

export default OpenSans;
