import { Close as CloseIcon } from '@mui/icons-material';
import {
    Box,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import { getForm } from 'api';
import Placeholder from 'images/placeholder.jpeg';
import React, { useEffect, useState } from 'react';
import { Form } from 'utils';
import { AssessmentSubmissionPreview } from '../components';

interface ViewAssessmentModalProps {
    open: boolean;
    onClose: () => void;
    assessmentId: string;
}

const ViewAssessmentModal = ({ open, onClose, assessmentId }: ViewAssessmentModalProps) => {
    const [assessment, setAssessment] = useState<Form | null>(null);
    const isLoading = !assessment;

    useEffect(() => {
        let isMounted = true;
        if (open && assessmentId) {
            setAssessment(null); // Reset on new open/id
            getForm(assessmentId).then((response) => {
                if (isMounted) {
                    setAssessment(response.data as Form);
                }
            });
        }
        return () => {
            isMounted = false;
        };
    }, [assessmentId, open]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth scroll="paper">
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{assessment?.title || 'View Assessment'}</Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Container maxWidth="md">
                    {isLoading ? (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 50,
                                minHeight: '200px',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {assessment?.asset && (
                                <Box
                                    sx={{
                                        maxWidth: 500,
                                        margin: 'auto',
                                    }}
                                >
                                    <figure>
                                        <Box
                                            component={'img'}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                objectPosition: 'center center',
                                                cursor: 'pointer',
                                            }}
                                            src={assessment.image_urls['1000'] ?? Placeholder}
                                            alt={assessment?.title}
                                        />
                                    </figure>
                                </Box>
                            )}
                            {assessment && <AssessmentSubmissionPreview form={assessment} view />}
                        </>
                    )}
                </Container>
            </DialogContent>
        </Dialog>
    );
};

export default ViewAssessmentModal;
