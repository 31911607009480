import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SchoolIcon from '@mui/icons-material/School';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@mui/lab';
import {
    Autocomplete,
    Avatar,
    Box,
    Container,
    Grid,
    ListItem,
    ListItemText,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { uniqBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { StringParam, useQueryParam } from 'use-query-params';
import { getAllAthletes } from '../../../api/app.api';
import EmptyState from '../../../components/Feedback/EmptyStates/EmptyState';
import { OrganizationFilterList } from '../../../components/Filters';
import { TimelineEntries, UserLimited } from '../../../utils';
import { getAttendance } from '../../train/api/calendar';
import { Attendance, SessionSearchDto } from '../../train/train.types';
import { AthleteTimelineParams, getAthleteTimeline } from '../api/athletes';

function CareTeamDashboard(): React.ReactElement {
    const [athleteId, setAthleteId] = useQueryParam('athlete', StringParam);
    const [inputValue, setInputValue] = React.useState('');
    const [athletes, setAthletes] = useState<UserLimited[]>([]);
    const [athletesLoaded, setAthletesLoaded] = useState<boolean>(false);
    const [timeline, setTimeline] = useState<TimelineEntries[]>([]);
    const [sessions, setSessions] = useState<Attendance[]>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<string | false>(false);
    const [assessments, setAssessments] = useState<Attendance[]>([]);

    function renderAssessmentItem(props: any) {
        const e = assessments[props.index];
        return (
            <ListItem key={props.index} style={props.style} alignItems="flex-start">
                <ListItemText
                    primary={e.event.form_name}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                sx={{ display: 'inline' }}
                                color="textPrimary"
                            >
                                Assigned By {e.event.organization_name}
                            </Typography>
                            - {moment(e.event.starts_at_timestamp * 1000).format('llll')}
                        </React.Fragment>
                    }
                />
            </ListItem>
        );
    }

    function renderSessionItem(props: any) {
        const s = sessions[props.index];
        return (
            <ListItem key={props.index} style={props.style} alignItems="flex-start">
                <ListItemText
                    primary={s.event.session_name}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                sx={{ display: 'inline' }}
                                color="textPrimary"
                            >
                                Assigned By {s.event.organization_name}
                            </Typography>
                            {/*<Avatar src={s.organization_photo ?? ''} sx={(theme) => ({width: theme.spacing(1),
        height: theme.spacing(1),})} />-{' '}*/}
                            - {moment(s.event.starts_at_timestamp * 1000).format('llll')}
                        </React.Fragment>
                    }
                />
            </ListItem>
        );
    }

    useEffect(() => {
        getAllAthletes()
            .then((athletes) => {
                setAthletes(athletes.data);
                setAthletesLoaded(true);
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        let params = {} as AthleteTimelineParams;
        if (athleteId) {
            params['filter[athlete]'] = athleteId;
        } else {
            return;
        }
        if (selectedOrganization) {
            params['filter[organization_source_uuid]'] = selectedOrganization;
        }
        getAthleteTimeline(params)
            .then((response) => {
                setTimeline(response.data.data);
            })
            .catch(() => {});

        let baseFilters = {
            sort: '-starts_at',
            'filter[starts_after]': moment().startOf('day').unix().toString(),
            'filter[individual.secondary_owner_uuid]': athleteId as string,
            'filter[not_skipped]': '1',
        } as SessionSearchDto;

        if (selectedOrganization) {
            baseFilters['filter[organization_uuid]'] = [selectedOrganization];
        }

        getAttendance({ ...baseFilters, 'filter[is_session]': '1' })
            .then((response) => setSessions(response.data))
            .catch(() => {});

        getAttendance({ ...baseFilters, 'filter[is_assessment]': '1' })
            .then((response) => setAssessments(response.data))
            .catch(() => {});
    }, [athleteId, selectedOrganization]);
    const theme = useTheme();
    return (
        <Container maxWidth="xl" style={{ paddingBottom: 64, paddingLeft: 0, paddingRight: 0 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 24,
                    borderBottom: '1px solid grey',
                    backgroundColor: 'white',
                    [theme.breakpoints.down('lg')]: {
                        flexDirection: 'column',
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingRight: 10,
                        paddingLeft: 10,
                    },
                }}
            >
                <Box sx={{ flex: '1 1 0%', marginTop: 10, marginBottom: 6 }}>
                    <Typography variant={'subtitle1'}>Athlete Workload Overview</Typography>
                </Box>
                {athletesLoaded && (
                    <Autocomplete
                        id="athlete-autocomplete"
                        options={athletes}
                        inputValue={inputValue}
                        onInputChange={(_, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        getOptionLabel={(option: UserLimited) => option.name}
                        isOptionEqualToValue={(option: UserLimited, value) =>
                            option?.uuid === value?.uuid
                        }
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}
                            >
                                <Avatar src={option.profile_photo ?? '   '} variant="rounded" />{' '}
                                <Typography sx={{ paddingLeft: 8 }}> {option.name}</Typography>
                            </Box>
                        )}
                        value={athletes.find((a) => a.uuid === athleteId) ?? null}
                        sx={{
                            root: {
                                color: !athleteId ? '#000000' : 'inherit',
                                backgroundColor: !athleteId ? '#ECF0F7' : '#ffffff',
                            },
                            listbox: {
                                color: !athleteId ? '#000000' : 'inherit',
                                backgroundColor: !athleteId ? '#ECF0F7' : '#ffffff',
                            },
                        }}
                        style={{ width: 300 }}
                        onChange={(e, newUser) => {
                            setAthleteId(newUser?.uuid ?? '');
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Choose An Athlete"
                                variant="outlined"
                                color={'primary'}
                            />
                        )}
                    />
                )}
            </Box>
            {athleteId && (
                <Box marginY={16} display="flex" alignItems="center" flexWrap="wrap">
                    <OrganizationFilterList
                        organizations={uniqBy(
                            athletes
                                .find((a) => a.uuid === athleteId)
                                ?.individuals?.map((i) => i.organization) ?? [],
                            'uuid',
                        )}
                        onClicked={(organization) => setSelectedOrganization(organization.uuid)}
                        selectedOrganization={selectedOrganization}
                    />
                </Box>
            )}
            {athleteId && (
                <Grid style={{ marginTop: 8 }} container>
                    <Grid sx={{ padding: 16 }} item xs={12} md={6}>
                        <Paper
                            sx={{
                                borderRadius: '1rem',
                                padding: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                flex: '1 1 auto',
                            }}
                            elevation={2}
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                borderBottom="1px solid grey"
                                padding={8}
                            >
                                <Avatar
                                    src={
                                        athletes.find((a) => a.uuid === athleteId)?.profile_photo ??
                                        ''
                                    }
                                    variant="rounded"
                                />
                                <Typography variant="h6">Assessments Scheduled</Typography>
                            </Box>
                            <Box height={350}>
                                {assessments.length === 0 && (
                                    <EmptyState
                                        title={'No Assessments Due'}
                                        phrase={
                                            'Assign athletes content through Learn -> Assessments.'
                                        }
                                        icon={<SchoolIcon />}
                                    />
                                )}
                                {assessments.length > 0 && (
                                    <AutoSizer>
                                        {({ width, height }: { width: number; height: number }) => (
                                            <FixedSizeList
                                                height={height}
                                                width={width}
                                                itemSize={72}
                                                itemCount={assessments.length}
                                                style={{
                                                    width: '100%',
                                                    backgroundColor: theme.palette.background.paper,
                                                }}
                                            >
                                                {renderAssessmentItem}
                                            </FixedSizeList>
                                        )}
                                    </AutoSizer>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid sx={{ padding: 16 }} item xs={12} md={6}>
                        <Paper
                            sx={{
                                borderRadius: '1rem',
                                padding: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                flex: '1 1 auto',
                            }}
                            elevation={2}
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                borderBottom="1px solid grey"
                                padding={8}
                            >
                                <Avatar
                                    src={
                                        athletes.find((a) => a.uuid === athleteId)?.profile_photo ??
                                        ''
                                    }
                                    variant="rounded"
                                />
                                <Typography variant="h6">Training Scheduled</Typography>
                            </Box>
                            <Box height={350}>
                                {sessions.length === 0 && (
                                    <EmptyState
                                        title={'No Training'}
                                        phrase={'Assign training by visiting Train -> Sessions.'}
                                        icon={<DirectionsWalkIcon />}
                                    />
                                )}
                                {sessions.length > 0 && (
                                    <AutoSizer>
                                        {({ width, height }: { width: number; height: number }) => (
                                            <FixedSizeList
                                                height={height}
                                                width={width}
                                                itemSize={72}
                                                itemCount={sessions.length}
                                                style={{
                                                    width: '100%',
                                                    backgroundColor: theme.palette.background.paper, // Using theme here
                                                }}
                                            >
                                                {renderSessionItem}
                                            </FixedSizeList>
                                        )}
                                    </AutoSizer>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: 16 }}>
                        <Paper sx={{ borderRadius: '1rem' }} elevation={2}>
                            {timeline.length === 0 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    paddingY={20}
                                >
                                    <PermIdentityIcon />
                                    <Typography
                                        variant="h6"
                                        style={{ marginTop: 6, fontWeight: 'bold' }}
                                    >
                                        No History
                                    </Typography>
                                    <Typography variant="body1" style={{ marginTop: 3 }}>
                                        Send this athlete a session to complete through Train &gt;
                                        Sessions!
                                    </Typography>
                                </Box>
                            )}
                            {timeline.length > 0 && (
                                <Timeline>
                                    {timeline.map((e) => (
                                        <TimelineItem key={e.id}>
                                            <TimelineSeparator>
                                                <Avatar
                                                    src={
                                                        athletes.find((a) => a.uuid === athleteId)
                                                            ?.profile_photo ?? ''
                                                    }
                                                    alt={e.organization_name}
                                                    variant="rounded"
                                                />

                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineOppositeContent
                                                style={{
                                                    maxWidth: '1px',
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                }}
                                            />
                                            <TimelineContent>
                                                <Typography variant="body2" color="textSecondary">
                                                    {moment(e.start_time * 1000).format(
                                                        'MMM Do YYYY HH:mma',
                                                    )}
                                                </Typography>
                                                <Typography variant="h6" component="h1">
                                                    {e.session_name}
                                                </Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Timeline>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            )}
            {!athleteId && (
                <Box
                    border="2px dashed grey"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    mt={16}
                    mx={4}
                    p={40}
                >
                    <PermIdentityIcon />
                    <Typography variant="h6" style={{ marginTop: 6, fontWeight: 'bold' }}>
                        No Athlete Selected
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: 3 }}>
                        Select An Athlete To View Reports
                    </Typography>
                </Box>
            )}
        </Container>
    );
}

export default CareTeamDashboard;
