import { AxiosResponse } from 'axios';
import { Stride } from '../community.types';
import { Request } from '../../../api';
import { SimplePaginateResponse } from '../../../app.types';
import { Analysis } from '../../../utils';

export const getAnalysis = (analysis_id: string): Promise<AxiosResponse<Analysis>> => {
    return Request.get(`/video-analyses/${analysis_id}`);
};

export const getStrideData = (
    analysis_id: string,
): Promise<AxiosResponse<SimplePaginateResponse<Stride>>> => {
    return Request.get(`/warehouse/analyses`, { params: { 'filter[uuid]': analysis_id } });
};
