import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { SharedUser } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<SharedUser>>>;

const addUser = (params: {
    individualId: string;
    users: Array<string>;
    role: string;
    message?: string;
}): ReturnType => {
    const { individualId, ...data } = params;
    return Request.post(`/individuals/${individualId}/users`, data);
};

export default addUser;
