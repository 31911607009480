import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Contact } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Contact>>>;

const getContacts = (id: string): ReturnType => {
    return Request.get(`users/${id}/contacts`);
};

export default getContacts;
