import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import {
    getNotificationCounts,
    selectNotificationCounts,
    selectNotificationsLastLoaded,
} from '../redux/reducers/user';
import { useAppDispatch } from '../redux/store';
import { useSelector } from 'react-redux';

interface ContextState {
    counts: { unattested_count: number } | null;
    last_loaded: number | null;
}

const Context = React.createContext<ContextState | null>(null);

export const useNotificationData = () => {
    const state = React.useContext(Context);
    if (state === null) {
        throw new Error('You must use useNotificationData inside NotificationDataProviderContext');
    }
    return state;
};

export const NotificationDataProvider: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
}) => {
    const dispatch = useAppDispatch();
    const notificationCounts = useSelector(selectNotificationCounts);
    const notificationsLastLoaded = useSelector(selectNotificationsLastLoaded);
    useEffect(() => {
        if (
            !notificationsLastLoaded ||
            (notificationsLastLoaded &&
                notificationsLastLoaded < DateTime.now().toSeconds() - 60 * 1000 * 5)
        ) {
            dispatch(getNotificationCounts());
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Context.Provider
            value={{ counts: notificationCounts, last_loaded: notificationsLastLoaded }}
        >
            {children}
        </Context.Provider>
    );
};
