import TimerIcon from '@mui/icons-material/Timer';
import { Button } from '@mui/material';
import React, { useCallback } from 'react';

interface Props {
    message: string;
    onClick: () => void;
    icon?: React.ReactElement;
    disabled?: boolean;
}

function EmptyStateWithDashedBorder(props: Props) {
    const renderIcon = useCallback(() => {
        if (!props.icon) {
            return <TimerIcon style={{ width: 80, height: 80 }} />;
        }
        return props.icon;
    }, [props.icon]);
    return (
        <Button
            disabled={props.disabled}
            onClick={() => props.onClick()}
            style={{
                marginTop: 12,
                display: 'block',
                position: 'relative',
                width: '100%',
                border: '2px dashed gray',
                borderRadius: 5,
                textAlign: 'center',
            }}
        >
            {renderIcon()}
            <span style={{ marginTop: 4, display: 'block', fontWeight: 'bold' }}>
                {props.message}
            </span>
        </Button>
    );
}

export default EmptyStateWithDashedBorder;
