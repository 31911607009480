import { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { SimplePaginateResponse } from '../../../app.types';
import { CommunityReportFilters, SavedFilter } from '../community.types';

interface CreateSavedFilterPayload {
    type: string;
    title: string;
    short_description?: string;
    filters: CommunityReportFilters;
    organization?: string;
    tag?: string | null;
}

interface UpdateSavedFilterPayload {
    title?: string | null;
    short_description?: string | null;
    tag_id?: string | null;
    filters?: CommunityReportFilters;
}

export function getAllSavedFilters(query: {
    'filter[title]'?: string[];
    'filter[tag_id]'?: string[];
    'filter[organization_uuid]'?: string | null;
    limit?: number;
    page?: number;
}): Promise<AxiosResponse<SimplePaginateResponse<SavedFilter>>> {
    return Request.get(`/analytics/saved-filters`, { params: query });
}

export function createSavedFilter(
    payload: CreateSavedFilterPayload,
): Promise<AxiosResponse<SavedFilter>> {
    return Request.post(`/analytics/saved-filters`, payload);
}

export function updateSavedFilter(
    id: string,
    payload: UpdateSavedFilterPayload,
): Promise<AxiosResponse<SavedFilter>> {
    return Request.patch(`/analytics/saved-filters/${id}`, payload);
}

export function deleteSavedFilter(id: string): Promise<AxiosResponse<void>> {
    return Request.delete(`/analytics/saved-filters/${id}`);
}

export function viewSavedFilter(id: string): Promise<AxiosResponse<SavedFilter>> {
    return Request.get(`/analytics/saved-filters/${id}`);
}
