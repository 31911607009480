import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Program } from 'utils';

type ReturnType = Promise<AxiosResponse<Program>>;

const updateSessionsForProgram = (uuid: string, sessions: Array<string>): ReturnType => {
    return Request.put(`/workouts/programs/${uuid}/sessions`, {
        sessions,
    });
};

export default updateSessionsForProgram;
