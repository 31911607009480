import React from 'react';
import { Chip, Input, MenuItem, Select } from '@mui/material';

interface Props {
    values: { value: string; label: string }[];
    onChange: (value: string) => void;
    value: string;
}

export default function ChipSelect({ values, onChange, value }: Props): React.ReactElement {
    function renderChip(label: string): React.ReactNode {
        return (
            <Chip
                label={label}
                sx={{
                    root: { paddingLeft: 1, paddingRight: 1 },
                    label: { lineHeight: '2.25rem' },
                }}
                clickable
                color="default"
            />
        );
    }

    return (
        <Select
            labelId="chip-select"
            id="chip-select"
            value={value}
            onChange={(e) => {
                onChange(e.target.value as string);
            }}
            input={<Input />}
            renderValue={(selected: any) =>
                renderChip(values.find((v) => v.value === selected)?.label ?? 'None')
            }
        >
            {values.map((statusLabel) => (
                <MenuItem key={statusLabel.value} value={statusLabel.value}>
                    {renderChip(statusLabel.label)}
                </MenuItem>
            ))}
        </Select>
    );
}
