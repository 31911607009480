import { AxiosResponse } from 'axios';
import { Tag, TagType } from '../app.types';
import { Request } from './index';

export const getTags = (params: { 'filter[type]': string }): Promise<AxiosResponse<Tag[]>> => {
    return Request.get(`/workouts/tags`, { params });
};

export const getHierarchyTags = (params: {
    'filter[type]': string;
    'filter[organization]': string;
}): Promise<AxiosResponse<Tag[]>> => {
    return Request.get(`/hierarchy/tags`, { params });
};
export const createHierarchyTag = (data: {
    name: string;
    organization: string;
    type: TagType;
}): Promise<AxiosResponse<Tag>> => {
    return Request.post(`/hierarchy/tags`, data);
};
