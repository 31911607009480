import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'; // react-router-dom is used for navigation
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { Box, Button, ListItemButton } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { getGpsSessionsForGroup } from '../api/gpsAnalysis.api';
import { GpsSummary } from '../community.types';

import { formatDateTimeStringToLocale } from '../../../app.functions';

const ListOfGpsSummaryDates = ({ groupId, summary }: { groupId: string; summary?: GpsSummary }) => {
    const { push } = useHistory();
    const [items, setItems] = React.useState<GpsSummary[]>([]);

    useEffect(() => {
        getGpsSessionsForGroup(groupId, { limit: 500, page: 1 })
            .then((res) => {
                setItems(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [groupId, summary]);

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    onClick={() => push(`/community/groups/${groupId}/gps-summary/create`)}
                    color="primary"
                    variant={'contained'}
                >
                    Upload GPS Summary
                </Button>
            </Box>
            <List
                sx={{
                    width: '100%',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 500,
                    '& ul': { padding: 0 },
                }}
                dense
                component="nav"
                aria-label="list of session dates"
            >
                {items.map((item, index) => (
                    <ListItemButton
                        onClick={() =>
                            push(`/community/groups/${groupId}/gps-summary/${item.uuid}`)
                        }
                        key={index}
                    >
                        <ListItemText
                            primaryTypographyProps={{ fontSize: 12 }}
                            primary={formatDateTimeStringToLocale(item.local_session_date_time)}
                        />
                        <ListItemSecondaryAction>
                            <OpenInNew />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                ))}
            </List>
        </>
    );
};

export default ListOfGpsSummaryDates;
