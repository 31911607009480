import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ url: string }>>;

const createBillingPortalSession = (
    return_url: string,
    organization: string,
    flow?: string,
    price?: string,
): ReturnType => {
    return Request.post(`/organizations/${organization}/billing-portal-session-v2`, {
        return_url: return_url,
        price,
        flow,
    });
};
export default createBillingPortalSession;
