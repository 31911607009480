import React, { HTMLAttributes, ReactElement } from 'react';
import {
    Asset,
    getMp4ClipFromAssets,
    getThumbnailFromAssets,
    getWebmClipFromAssets,
} from '../../utils';
import { Button } from '@mui/material';
import { VideoClipPlayer } from './index';

interface Props extends HTMLAttributes<HTMLBaseElement> {
    assets: Asset[];
    onClick?: () => void;
    clickable?: boolean;
    videoProps?: React.HTMLAttributes<HTMLVideoElement>;
}

function MovementVid({ assets, onClick, clickable = true, videoProps }: Props): ReactElement {
    return (
        <>
            {clickable && (
                <Button onClick={() => onClick && onClick()}>
                    <VideoClipPlayer
                        mp4={getMp4ClipFromAssets(assets)}
                        webm={getWebmClipFromAssets(assets)}
                        poster={getThumbnailFromAssets(assets)}
                        videoProps={videoProps}
                    />
                </Button>
            )}
            {!clickable && (
                <VideoClipPlayer
                    mp4={getMp4ClipFromAssets(assets)}
                    webm={getWebmClipFromAssets(assets)}
                    poster={getThumbnailFromAssets(assets)}
                    videoProps={videoProps}
                />
            )}
        </>
    );
}

export { MovementVid as MovementVideoClip };
