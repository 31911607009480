import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDimensions, getEntries } from 'api';

import { AxiosError } from 'axios';
import { groupBy } from 'lodash';
import moment from 'moment';
import {
    Activity,
    Dimensions,
    getPropertyFromSearchParams,
    SupportTeam,
    TimelineEntries,
} from 'utils';
import { RootState } from '.';
import getMyActivities from '../../api/Timeline/getMyActivities';
import {
    archiveActivityApi,
    attestActivityApi,
    supportTeam,
    toggleSupportTeamApi,
    unarchiveActivityApi,
} from '../../modules/plan/api/timeline';
import { getNotificationCounts } from './user';

export interface TimelineError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export type FiltersBySourceType = {
    [key: string]: Array<Dimensions>;
};

export type EntriesByCreatedAt = Array<TimelineEntries>;

export interface TimelineState {
    isLoading: boolean;
    isLoaded: boolean;
    filters: FiltersBySourceType;
    filtersLoading: boolean;
    filtersLoaded: boolean;
    selectedFilters: Dimensions[];
    allFilters: Dimensions[];
    entries: EntriesByCreatedAt;
    current: string | null;
    next: string | null;
    error: TimelineError;
    archivedActivities: Activity[];
    unattestedActivities: Activity[];
    supportTeam: SupportTeam[];
}

export const initialState: TimelineState = {
    isLoading: false,
    isLoaded: false,
    filters: {},
    filtersLoading: false,
    filtersLoaded: false,
    selectedFilters: [],
    allFilters: [],
    entries: [],
    next: null,
    current: null,
    error: { message: '' },
    archivedActivities: [],
    unattestedActivities: [],
    supportTeam: [],
};

export const attestActivity = createAsyncThunk<
    boolean,
    { activity: Activity },
    { rejectValue: TimelineError }
>('timeline/attest', async ({ activity }, { rejectWithValue, dispatch }) => {
    try {
        await attestActivityApi(activity);
        dispatch(getNotificationCounts());
        return true;
    } catch (err: any) {
        return rejectWithValue({ message: 'Error Attesting Activity ' });
    }
});

export const toggleSupportTeam = createAsyncThunk<
    boolean,
    { id: string },
    { rejectValue: TimelineError }
>('timeline/toggleSupport', async ({ id }, { rejectWithValue }) => {
    try {
        await toggleSupportTeamApi(id);
        return true;
    } catch (err: any) {
        return rejectWithValue({ message: 'Error Toggling Support Team User ' });
    }
});

export const archiveActivity = createAsyncThunk<
    boolean,
    { activity: Activity },
    { rejectValue: TimelineError }
>('timeline/archiveActivity', async ({ activity }, { rejectWithValue, dispatch }) => {
    try {
        await archiveActivityApi(activity);
        dispatch(getNotificationCounts());
        return true;
    } catch (err: any) {
        return rejectWithValue({ message: 'Error Archiving Activity ' });
    }
});

export const unarchiveActivity = createAsyncThunk<
    boolean,
    { activity: Activity },
    { rejectValue: TimelineError }
>('timeline/unarchiveActivity', async ({ activity }, { rejectWithValue }) => {
    try {
        await unarchiveActivityApi(activity);
        return true;
    } catch (err: any) {
        return rejectWithValue({ message: 'Error Unarchiving Activity ' });
    }
});

export const loadDimensions = createAsyncThunk<
    { filters: Dimensions[] },
    undefined,
    { rejectValue: TimelineError }
>('dimensions/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getDimensions();
        return {
            filters: data,
        };
    } catch (err: any) {
        const error: AxiosError<TimelineError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Loading Filters' });
    }
});

export const loadSupportTeam = createAsyncThunk<
    { data: SupportTeam[] },
    undefined,
    { rejectValue: TimelineError }
>('timeline/loadSupportTeam', async (_, { rejectWithValue }) => {
    try {
        const { data } = await supportTeam();
        return {
            data,
        };
    } catch (err: any) {
        const error: AxiosError<TimelineError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Could not load support team' });
    }
});

export const loadArchivedActivites = createAsyncThunk<
    { activities: Activity[] },
    undefined,
    { rejectValue: TimelineError }
>('timeline/fetchArchivedActivities', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getMyActivities({ 'filter[archived]': 1, 'filter[complete]': 1 });
        return {
            activities: data,
        };
    } catch (err: any) {
        const error: AxiosError<TimelineError> = err;
        return rejectWithValue(
            error.response?.data ?? { message: 'Error Loading Your Activities' },
        );
    }
});

export const loadUnattestedActivities = createAsyncThunk<
    { activities: Activity[] },
    undefined,
    { rejectValue: TimelineError }
>('timeline/fetchUnattestedActivities', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getMyActivities({
            'filter[unattested]': 1,
            'filter[complete]': 1,
            'filter[unarchived]': 1,
        });
        return {
            activities: data,
        };
    } catch (err: any) {
        const error: AxiosError<TimelineError> = err;
        return rejectWithValue(
            error.response?.data ?? { message: 'Error Loading Your Activities' },
        );
    }
});

export const loadEntries = createAsyncThunk<
    { entries: TimelineEntries[]; next: string | null; current: string | null },
    { page?: string; selectedFilters?: Dimensions[] },
    { rejectValue: TimelineError }
>('entries/fetchAll', async ({ page, selectedFilters }, { rejectWithValue }) => {
    try {
        const filtersQueryString =
            selectedFilters &&
            selectedFilters.map((filter) => `filter[${filter.type}][]=${filter.id}`).join('&');
        const { data } = await getEntries(page, filtersQueryString);
        const nextPage = getPropertyFromSearchParams(data.links.next, 'page');

        return {
            entries: data.data.map((entry) => ({
                ...entry,
                // Converting the timeline entry start time of the day to local time
                // Group by at LINK src/redux/reducers/timeline.ts:141
                start_time_day: moment.unix(entry.start_time_utc).local().format('YYYY-MM-DD'),
                end_time_day: entry.end_time_utc
                    ? moment.unix(entry.end_time_utc).local().format('YYYY-MM-DD')
                    : null,
            })),
            next: nextPage,
            current: page ?? null,
        };
    } catch (err: any) {
        const error: AxiosError<TimelineError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Loading Entries' });
    }
});

export const timelineSlice = createSlice({
    name: 'timeline',
    initialState,
    reducers: {
        selectFilters: (state, { payload }: PayloadAction<Dimensions[]>) => {
            state.selectedFilters = payload;
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(loadDimensions.fulfilled, (state, { payload }) => {
            const groupedFilters = groupBy(payload.filters, 'type');
            state.filters = {
                ...state.filters,
                ...groupedFilters,
            };
            state.error = { message: '' };
            state.allFilters = payload.filters;
            state.filtersLoaded = true;
            state.filtersLoading = false;
        });
        addCase(loadDimensions.pending, (state) => {
            state.error = { message: '' };
            state.filtersLoading = true;
        });
        addCase(loadDimensions.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Loading Assessments';
        });
        addCase(loadEntries.fulfilled, (state, { payload }) => {
            console.log(payload);
            const oldState = payload.current !== null ? state.entries : [];
            state.entries = [...oldState, ...payload.entries];
            state.current = payload.current;
            state.next = payload.next;
            state.error = { message: '' };
            state.isLoading = false;
            state.isLoaded = true;
        });
        addCase(loadEntries.pending, (state) => {
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(loadEntries.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Loading Assessments';
            state.isLoading = false;
        });
        addCase(loadUnattestedActivities.fulfilled, (state, { payload }) => {
            state.unattestedActivities = payload.activities;
        });
        addCase(loadArchivedActivites.fulfilled, (state, { payload }) => {
            state.archivedActivities = payload.activities;
        });
        addCase(attestActivity.pending, (state, action) => {
            state.unattestedActivities = state.unattestedActivities.filter(
                (a) => a.uuid !== action.meta.arg.activity.uuid,
            );
        });
        addCase(loadSupportTeam.fulfilled, (state, { payload }) => {
            state.supportTeam = payload.data;
        });
        addCase(toggleSupportTeam.pending, (state, { meta }) => {
            const ix = state.supportTeam.findIndex((u) => u.uuid === meta.arg.id);
            state.supportTeam[ix].revoked = !state.supportTeam[ix].revoked;
        });
        addCase(archiveActivity.pending, (state, action) => {
            state.unattestedActivities = state.unattestedActivities.filter(
                (a) => a.uuid !== action.meta.arg.activity.uuid,
            );
            state.archivedActivities.push({
                ...action.meta.arg.activity,
                archived_at: Math.round(moment().unix() / 1000),
            });
        });
        addCase(unarchiveActivity.pending, (state, action) => {
            state.archivedActivities = state.archivedActivities.filter(
                (a) => a.uuid !== action.meta.arg.activity.uuid,
            );
            state.unattestedActivities.push({
                ...action.meta.arg.activity,
                archived_at: null,
            });
        });
    },
});

export const getTimelineSelector = (state: RootState) => state.timeline;

export const getEntriesGroupedByDate = createSelector([getTimelineSelector], (timeline) =>
    groupBy(timeline.entries, 'reference_date_day'),
);
export const getSupportTeamGroupedByOrg = createSelector([getTimelineSelector], (timeline) =>
    groupBy(timeline.supportTeam, 'organization.uuid'),
);
export const getUnattestedCount = createSelector(
    getTimelineSelector,
    (timeline) => timeline.unattestedActivities.length,
);
export const { selectFilters } = timelineSlice.actions;
