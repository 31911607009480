import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Alert, Container, Fab, TextField, Typography, useTheme } from '@mui/material';
import { acceptLink, validateLink } from 'api';

import LandingPageHeroCard from 'components/Cards/LandingPageHeroCard';
import LayoutMetaHead from 'Layouts/Partials/LayoutMetaHead';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { authSelector, shuffleOrganizationsLoadKey } from 'redux/reducers/auth';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import ROUTES from 'Routes/routes';
import { NumberParam, useQueryParam } from 'use-query-params';
import { Link, ShareableClassification } from 'utils';
import { getRedirectPath } from '../../utils/redirectionPath';

const LandingPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: Array<string> }>({});
    const { isAuth } = useSelector(authSelector);
    const { linkId }: { linkId: string } = useParams();
    const [link, setLink] = useState<Link | null>(null);
    const { push } = useHistory();
    const dispatch = useAppDispatch();
    const [autoAccept] = useQueryParam('auto_accept', NumberParam);
    const theme = useTheme();

    const getButtonText = (shareableType: ShareableClassification, roleName: string): string => {
        if (roleName === 'Family Member') {
            return 'be a Family Member';
        }
        switch (shareableType) {
            case 'program':
                return 'View Program';
            case 'session':
                return 'View Session';
            case 'organization':
                return 'View Organization';
            case 'movement':
                return 'View Movement';
            case 'form':
                return 'View Form';
            case 'event':
                return 'View Event';
            case 'group':
                return 'Join Group';
            case 'individual':
            case 'individuals':
                return 'Manage Individual';
            default:
                return 'View';
        }
    };

    useEffect(() => {
        if (linkId) {
            validateLink(linkId)
                .then((response) => setLink(response.data))
                .catch((error) => {
                    if (error.response.status === 500) {
                        dispatch(
                            pushMessage({
                                status: 'error',
                                message: 'Something went wrong, please try again',
                            }),
                        );
                    }
                });
        }
    }, [linkId, dispatch, push]);

    const handleLinkAccess = useCallback(() => {
        if (!link) {
            return;
        }

        if (!isAuth) {
            push(ROUTES.Login.path, {
                referrer: `${ROUTES.LandingPage.path?.replace(`:linkId`, linkId)}?auto_accept=1`,
                referrer_message: `Just a few steps away from accessing '${link.title || ''}'`,
            });
        } else {
            setIsLoading(true);
            let payload: { code?: string } = {};
            if (link.has_code) {
                payload.code = code;
            }
            acceptLink(linkId, payload)
                .then(() => {
                    setIsLoading(false);
                    // if the link is an organization, we should shuffle the organizations load key
                    // from the authState
                    if (link.shareable_type === 'organization') {
                        dispatch(shuffleOrganizationsLoadKey());
                    }
                    push(getRedirectPath(link.shareable_type, link.shareable_uuid, link.role));
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error.response.status == 404) {
                        push(ROUTES.NotFound.path);
                    } else if (error.response.status == 422 || error.response.status == 409) {
                        setErrors(error.response.data.errors);
                    } else {
                        dispatch(
                            pushMessage({
                                status: 'error',
                                message:
                                    'We had an error processing this request. Please try again or contact customer support.',
                            }),
                        );
                    }
                });
        }
    }, [link, code, dispatch, isAuth, linkId, push]);

    useEffect(() => {
        if (autoAccept === 1) {
            handleLinkAccess();
        }
    }, [autoAccept, handleLinkAccess]);

    return (
        <Container maxWidth="md" sx={{ marginTop: 5 }}>
            {/* Adding meta tags */}
            <LayoutMetaHead
                title={link?.title || undefined}
                description={link?.description || undefined}
                imageUrl={link?.image_url || undefined}
            />
            <LandingPageHeroCard
                isLoading={!link}
                title={link?.title || ''}
                subtitle={link?.role.display_name ? `(${link?.role.display_name})` : ''}
                description={link?.description || ''}
                avatarSrc={link?.image_url || ''}
                organization={link?.secondary_title}
                organizationImage={link?.secondary_image_url}
            />
            {isLoading && (
                <Typography
                    variant="body2"
                    align="center"
                    sx={{
                        marginBottom: theme.spacing(5),
                    }}
                >
                    Adding the {link?.shareable_type || ''} to your account...
                </Typography>
            )}

            {link?.has_code && (
                <TextField
                    sx={{
                        marginTop: theme.spacing(10),
                        marginBottom: theme.spacing(10),
                        width: '100%',
                    }}
                    label="Code"
                    variant="outlined"
                    color="secondary"
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                />
            )}
            {Object.keys(errors).length > 0 && (
                <Alert
                    sx={{
                        marginBottom: theme.spacing(10),
                    }}
                    severity="error"
                >
                    {errors[Object.keys(errors)[0]][0]}
                </Alert>
            )}
            {link?.title && (
                <Fab
                    disabled={isLoading}
                    color={'primary'}
                    sx={{
                        marginTop: theme.spacing(10),
                        left: '50%',
                        position: 'relative',
                        transform: 'translateX(-50%)',
                        zIndex: 1102,
                        [theme.breakpoints.up('md')]: {
                            bottom: 39,
                        },
                    }}
                    variant="extended"
                    onClick={handleLinkAccess}
                >
                    {isAuth
                        ? getButtonText(link.shareable_type, link?.role?.display_name || '')
                        : `Sign in or Sign up And ${getButtonText(
                              link.shareable_type,
                              link?.role?.display_name || '',
                          )}`}
                    <ArrowForwardIosIcon />
                </Fab>
            )}
        </Container>
    );
};

export default LandingPage;
