import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Button, CircularProgress } from '@mui/material';
import { yupSchemas } from 'utils';
import { useSelector } from 'react-redux';
import { getUserPermissions, updateUserData, userSelector } from 'redux/reducers/user';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import useTheme from '@mui/material/styles/useTheme';

const ProfileDetailsForm = () => {
    const theme = useTheme();
    const { userData } = useSelector(userSelector);
    const dispatch = useAppDispatch();
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                email: userData?.email || '',
                firstName: userData?.first_name || '',
                lastName: userData?.last_name || '',
                phone: userData?.phone_national ? userData.phone_national.replace(/\s/g, '') : '',
                school: userData?.fields?.school || '',
                sport: userData?.fields?.sport || '',
            }}
            validationSchema={yupSchemas.profile}
            onSubmit={async (
                { firstName, lastName, email, phone, school, sport },
                { setErrors, setSubmitting },
            ) => {
                setSubmitting(true);
                const result = await dispatch(
                    updateUserData({
                        userUuid: userData?.uuid || '',
                        first_name: firstName,
                        last_name: lastName,
                        email,
                        phone,
                        profile_photo: null,
                        fields: {
                            ...userData?.fields,
                            school,
                            sport,
                        },
                    }),
                );
                setSubmitting(false);
                if (updateUserData.fulfilled.match(result)) {
                    // We need to update organization to make sure if there are any updates done to personal organization
                    // due to user's name change, we pull the latest org name
                    // That happens through permissions
                    dispatch(getUserPermissions({ id: userData?.uuid || '' }));

                    dispatch(
                        pushMessage({
                            status: 'success',
                            message: 'Profile updated successfully.',
                        }),
                    );
                } else {
                    if (result.payload) {
                        // Server sent validation errors
                        if ('errors' in result.payload) {
                            setErrors(result.payload?.errors || {});
                        } else {
                            // No validation errors
                            // Something might be wrong on server side
                            dispatch(
                                pushMessage({
                                    status: 'error',
                                    message: 'Something went wrong, please try again',
                                }),
                            );
                        }
                    }
                }
            }}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <Field
                        sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        component={TextField}
                        variant="filled"
                        name="school"
                        type="school"
                        label="School(s)"
                    />
                    <Field
                        sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        component={TextField}
                        variant="filled"
                        name="sport"
                        type="sport"
                        label="Sport(s)"
                    />
                    {!isSubmitting && (
                        <Button
                            size="large"
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!(isValid && dirty)}
                            sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        >
                            Update Schools and Sports{'  '}
                        </Button>
                    )}{' '}
                    {isSubmitting && (
                        <Button
                            size="large"
                            type="button"
                            color="primary"
                            variant="contained"
                            sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        >
                            Updating Profile{'  '}
                            <CircularProgress
                                disableShrink
                                size={35}
                                color="inherit"
                                sx={{ marginLeft: theme.spacing(5) }}
                            />
                        </Button>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default ProfileDetailsForm;
