import { DashboardCounts, Organization } from '../../../utils';
import { Attendance } from '../train.types';

export interface TrainDashboardState {
    dashboardLoading: boolean;
    dashboardLoaded: boolean;
    dashboard: DashboardCounts;
    attendanceLoading: boolean;
    attendanceLoaded: boolean;
    attendance: Array<Attendance>;
    organizations: Array<Organization>;
    sessionsError: string;
    completedSessionsLoading: boolean;
    completedSessions: Array<Attendance>;
    completedSessionsError: string;
    selectedCategory: string | false;
    selectedOrganization: string | false;
}

const initialState = {
    dashboardLoading: false,
    dashboardLoaded: false,
    dashboard: {
        upcoming: 0,
        complete: 0,
        overdue: 0,
    } as DashboardCounts,
    attendanceLoading: false,
    attendanceLoaded: false,
    attendance: [],
    organizations: [],
    sessionsError: '',
    completedSessionsLoading: false,
    completedSessions: [],
    completedSessionsError: '',
    selectedCategory: false,
    selectedOrganization: false,
} as TrainDashboardState;

export type Actions =
    | 'select-category'
    | 'select-organization'
    | 'load-session-queued'
    | 'load-session-success'
    | 'load-session-failure'
    | 'load-dashboard-queued'
    | 'load-dashboard-success'
    | 'load-dashboard-failure'
    | 'load-completed-sessions'
    | 'load-completed-sessions-success'
    | 'load-completed-sessions-failure'
    | 'session-skipped-queued'
    | 'organizations-loaded';

function reducer(
    state: TrainDashboardState,
    action: { type: Actions; payload: { [key: string]: any } },
): TrainDashboardState {
    switch (action.type) {
        case 'select-category': {
            return {
                ...state,
                selectedCategory: action.payload.category,
            };
        }
        case 'select-organization': {
            return {
                ...state,
                selectedOrganization: action.payload.organization,
            };
        }
        case 'load-session-queued': {
            return {
                ...state,
                attendanceLoading: true,
            };
        }
        case 'load-session-failure': {
            return {
                ...state,
                attendanceLoading: false,
                sessionsError: 'Could not load sessions. Try again later',
            };
        }
        case 'load-session-success': {
            return {
                ...state,
                attendanceLoading: false,
                attendanceLoaded: true,
                attendance: action.payload.sessions,
            };
        }
        case 'load-dashboard-queued': {
            return {
                ...state,
                dashboardLoading: true,
            };
        }
        case 'load-dashboard-failure': {
            return {
                ...state,
                dashboardLoading: false,
            };
        }
        case 'load-dashboard-success': {
            return {
                ...state,
                dashboardLoading: false,
                dashboardLoaded: true,
                dashboard: action.payload.dashboard,
            };
        }
        case 'load-completed-sessions': {
            return {
                ...state,
                completedSessionsLoading: true,
            };
        }
        case 'load-completed-sessions-failure': {
            return {
                ...state,
                completedSessionsLoading: false,
                completedSessionsError: 'Could not load sessions. Try again later',
            };
        }
        case 'load-completed-sessions-success': {
            return {
                ...state,
                completedSessionsLoading: false,
                completedSessions: action.payload.sessions,
            };
        }
        case 'organizations-loaded': {
            return {
                ...state,
                organizations: action.payload.organizations,
            };
        }
        case 'session-skipped-queued': {
            return {
                ...state,
                attendance: state.attendance.filter(
                    (s) =>
                        `${s.event.uuid}${s.individual.uuid}` !==
                        `${action.payload.eventId}${action.payload.individualId}`,
                ),
            };
        }
        default:
            throw new Error();
    }
}

export { initialState, reducer };
