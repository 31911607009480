import { FormModal } from '../../../components';
import { updateExploration } from '../explore.api';
import { Exploration } from '../explore.types';
import React, { useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Stack, TextField, Typography } from '@mui/material';

const EditExplorationModal = ({
    open,
    handleClose,
    exploration,
    onSaved,
    onSaveError,
}: {
    open: boolean;
    handleClose: () => void;
    exploration: Exploration;
    onSaved: (exploration: Exploration) => void;
    onSaveError: () => void;
}) => {
    const saveExploration = useCallback(
        (values: { title?: string }) => {
            updateExploration(exploration.uuid, values)
                .then((result) => {
                    onSaved(result.data);
                    handleClose();
                })
                .catch(() => {
                    onSaveError();
                });
        },
        [exploration, onSaved, handleClose, onSaveError],
    );

    const { values, errors, handleChange } = useFormik({
        initialValues: {
            title: exploration.title,
        },
        validationSchema: yup.object({
            title: yup
                .string()
                .required('Title is required')
                .max(200, 'Title is too long. Try a shorter title.'),
        }),
        onSubmit: () => {},
    });
    return (
        <FormModal
            handleClose={handleClose}
            open={open}
            handleButtonClicked={() => saveExploration(values)}
            disabled={Boolean(errors.title)}
        >
            <form
                onSubmit={(e) => {
                    saveExploration(values);
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Stack spacing={8}>
                    <Typography>Enter a new title for your Exploration</Typography>
                    <TextField
                        autoFocus={true}
                        id="title"
                        name="title"
                        label="Exploration Title"
                        value={values.title}
                        onChange={handleChange}
                        error={!!errors.title}
                        helperText={errors.title as string}
                    />
                </Stack>
            </form>
        </FormModal>
    );
};

export default EditExplorationModal;
