import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FullScreenDialogCard from '../components/HowToDialogCard';
import MarketPlaceOverview from '../media/MarketplaceCoverPage.png';
import MarketPlaceSessions from '../media/MarketPlaceSessions.png';
import MarketPlaceGetStarted from '../media/MarketPlaceLaunch.png';

function AdminLearningContentOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        <strong>Welcome to our Marketplace,</strong> an integral part of our
                        expansive multi-level marketing platform. By participating in our
                        Marketplace, you have the unique opportunity to both sell your own content
                        and also the content of others, earning a substantial portion of the revenue
                        generated from these sales.
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={MarketPlaceOverview}
                    title="Marketplace Overview Sessions"
                    headline="Sell Sessions"
                    description="Create quality sessions and sell them to other organizations on your Marketplace landing page."
                />
                <FullScreenDialogCard
                    image={MarketPlaceSessions}
                    title="Sell Learning Content"
                    headline="Sell Learning Content"
                    description="Create quality learning content and sell them to other organizations on your Marketplace landing page."
                />
                <FullScreenDialogCard
                    image={MarketPlaceGetStarted}
                    title="Get Started"
                    headline="Get Started"
                    description="Go to your Organizational Settings to launch your Marketplace."
                />
            </Grid>
        </>
    );
}

export default AdminLearningContentOverview;
