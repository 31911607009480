import { AxiosResponse } from 'axios';
import { SimplePaginateResponse } from '../app.types';
import { UserLimited } from '../utils';
import { Request } from './index';

export function getAllAthletes(): Promise<AxiosResponse<Array<UserLimited>>> {
    return Request.get(`athletes`);
}

export function getAllAthletesPaginated(
    payload: Record<string, any>,
): Promise<AxiosResponse<SimplePaginateResponse<UserLimited>>> {
    return Request.get(`athletes/paginated`, { params: payload });
}
