import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Program } from 'utils';

type ReturnType = Promise<AxiosResponse<Program>>;

const updateProgramDetails = (uuid: string, name: string, description?: string): ReturnType => {
    return Request.put(`/workouts/programs/${uuid}`, {
        name,
        description,
    });
};

export default updateProgramDetails;
