import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { AttributeValue, Member } from 'utils';

type ReturnType = Promise<AxiosResponse<Member>>;

const updateMember = (
    group: string,
    member: string,
    payload: {
        first_name?: string;
        last_name?: string;
        attributes?: Array<AttributeValue>;
        member_type?: string;
        profile_photo_path?: string;
        position_tag_uuid?: string;
        position_group_tag_uuid?: string;
    },
): ReturnType => {
    return Request.put(`/groups/${group}/members/${member}`, payload);
};

export default updateMember;
