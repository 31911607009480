import { AxiosResponse } from 'axios';
import { Media, SimplePaginateResponse } from '../app.types';
import { Request } from './index';

export const getAllMedia = (payload: {
    limit?: number;
    page?: number;
    'filter[name]'?: string;
    'filter[category]'?: string;
}): Promise<AxiosResponse<SimplePaginateResponse<Media>>> => {
    return Request.get(`/media`, { params: payload });
};

export const uploadMedia = (payload: {
    path: string;
    name: string;
    mime_type: string;
    size: number;
    organization: string;
}): Promise<AxiosResponse<Media>> => {
    return Request.post(`/media`, payload);
};
