import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<void>>;

const archiveMovement = (uuid: string): ReturnType => {
    return Request.post(`/workouts/movements/${uuid}/archive`);
};

export default archiveMovement;
