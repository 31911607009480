import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { CreatePhysicalProductParams, PhysicalProduct } from './physicalProducts';

interface PhysicalProductPaginatedApiResponse {
    data: Array<PhysicalProduct>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type PhysicalProductReturnTypeCollection = Promise<
    AxiosResponse<PhysicalProductPaginatedApiResponse>
>;

interface GetPhysicalProductParams {
    name?: string;
    product_category?: string;
    is_active?: boolean;
    sort?: 'name' | 'created_at' | 'product_category' | 'inventory_count';
}

const getPhysicalProducts = (
    params?: GetPhysicalProductParams,
): PhysicalProductReturnTypeCollection => {
    const filterParams = {
        'filter[name]': params?.name,
        'filter[is_active]': params?.is_active,
        sort: params?.sort,
    };
    return Request.get(`admin/marketplace/physical-products`, { params: filterParams });
};

type PhysicalProductReturnTypeSingle = Promise<AxiosResponse<PhysicalProduct>>;
type PhysicalProductCategoryReturnTypeList = Promise<AxiosResponse<string[]>>;

const createPhysicalProduct = (
    physicalProductData: CreatePhysicalProductParams,
): PhysicalProductReturnTypeSingle => {
    return Request.post(`admin/marketplace/physical-products`, physicalProductData);
};

const updatePhysicalProduct = (
    physicalProductUuid: string,
    physicalProductData: CreatePhysicalProductParams,
): PhysicalProductReturnTypeSingle => {
    return Request.put(
        `admin/marketplace/physical-products/${physicalProductUuid}`,
        physicalProductData,
    );
};

const getPhysicalProductCategories = (): PhysicalProductCategoryReturnTypeList => {
    return Request.get(`admin/marketplace/physical-product-categories`);
};

export {
    createPhysicalProduct,
    updatePhysicalProduct,
    getPhysicalProducts,
    getPhysicalProductCategories,
};
export type { GetPhysicalProductParams };
