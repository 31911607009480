import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { TimelineEntriesResponse } from 'utils';

type ReturnType = Promise<AxiosResponse<TimelineEntriesResponse>>;

const getEntries = (page?: string, filters?: string): ReturnType => {
    return Request.get(
        `/timeline/entries?limit=100&sort=-start_time_utc&${filters ?? ''}&page=${page ?? ''}`,
    );
};

export default getEntries;
