import React from 'react';
import Alert from '@mui/material/Alert';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { hasDigit, hasLowerCase, hasSpecialCharacter, hasUpperCase } from 'utils';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/system';

interface Props {
    password: string;
}

const PasswordAlert = ({ password }: Props) => {
    const theme = useTheme();
    const condition1 = password?.length > 7;
    const condition2 = hasUpperCase(password);
    const condition3 = hasLowerCase(password);
    const condition4 = hasDigit(password) || hasSpecialCharacter(password);
    const allConditionsChecked = condition1 && condition2 && condition3 && condition4;

    return (
        <React.Fragment>
            {password.length > 0 && !allConditionsChecked && (
                <Alert
                    sx={{
                        textAlign: 'start',
                        fontSize: '12px',
                        display: 'flex',
                        margin: theme.spacing(2, 0),
                    }}
                    severity="info"
                >
                    <div>
                        Password must be:
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: theme.spacing(2),
                            }}
                        >
                            {condition1 ? (
                                <CheckCircleOutlineOutlinedIcon
                                    sx={{ color: '#52BE80', height: '16px', width: '16px' }}
                                />
                            ) : (
                                <CancelOutlinedIcon sx={{ height: '16px', width: '16px' }} />
                            )}
                            Equal or Greater then 8 characters.
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: theme.spacing(2),
                            }}
                        >
                            {condition2 ? (
                                <CheckCircleOutlineOutlinedIcon
                                    sx={{ color: '#52BE80', height: '16px', width: '16px' }}
                                />
                            ) : (
                                <CancelOutlinedIcon sx={{ height: '16px', width: '16px' }} />
                            )}{' '}
                            Password must contain 1 or more uppercase characters.
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: theme.spacing(2),
                            }}
                        >
                            {condition3 ? (
                                <CheckCircleOutlineOutlinedIcon
                                    sx={{ color: '#52BE80', height: '16px', width: '16px' }}
                                />
                            ) : (
                                <CancelOutlinedIcon sx={{ height: '16px', width: '16px' }} />
                            )}{' '}
                            Password must contain 1 or more lowercase characters.
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: theme.spacing(2),
                            }}
                        >
                            {condition4 ? (
                                <CheckCircleOutlineOutlinedIcon
                                    sx={{ color: '#52BE80', height: '16px', width: '16px' }}
                                />
                            ) : (
                                <CancelOutlinedIcon sx={{ height: '16px', width: '16px' }} />
                            )}{' '}
                            Password must contain 1 or more digits / special characters.
                        </Box>
                    </div>
                </Alert>
            )}
        </React.Fragment>
    );
};

export default PasswordAlert;
