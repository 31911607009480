import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AdminAddMembersToGroup() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL allows you to display athlete profiles in a time period for GPS summary
                        data and view them side by side. Answer questions like &quot;Who was the
                        fastest athlete last month?&quot; and &quot;Who ran the most sprint last
                        month?&quot;
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/883111082?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Explore Athlete Profiles'}
                            description={''}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminAddMembersToGroup;
