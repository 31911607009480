import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Link } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Link>>>;

const getLinksForGroup = (id: string): ReturnType => {
    return Request.get(`/links/group/${id}`);
};

export default getLinksForGroup;
