import {
    addUserToOrganization,
    getInvitesForOrganization,
    getLinksForOrganization,
    getRoles,
    getSharedUsersOnOrganization,
    removeSharedUserForOrganization,
    revokeInviteFromOrganization,
} from 'api';
import React, { ReactElement, useEffect, useState } from 'react';
import { Contact, Invite, Link, Role, Organization, SharedUser } from 'utils';
import SharingDrawer from './SharingDrawer';

interface Props {
    organization: Organization;
    open: boolean;
    onClose: () => void;
    onUsersChanged?: (users: Array<SharedUser>) => void;
}

export default function OrganizationSharingDrawer({
    organization,
    open,
    onClose = () => {},
    onUsersChanged = () => {},
}: Props): ReactElement {
    const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const [sharingInProgress, setSharingInProgress] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [links, setLinks] = useState<Array<Link>>([]);
    const [invites, setInvites] = useState<Array<Invite>>([]);

    useEffect(() => {
        loadSharedUsers(organization.uuid);
        loadLinks(organization.uuid);
        loadInvites(organization.uuid);
        getRoles('organization').then((response) => {
            setRoles(response.data);
        });
    }, [organization]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadSharedUsers = (id: string) => {
        setSharingInProgress(true);
        getSharedUsersOnOrganization(id).then((response) => {
            setSharedUsers(response.data);
            onUsersChanged(response.data);
            setSharingInProgress(false);
        });
    };

    const loadLinks = (id: string) => {
        getLinksForOrganization(id).then((response) => {
            setLinks(response.data);
        });
    };

    const loadInvites = (id: string) => {
        getInvitesForOrganization(id).then((response) => {
            setInvites(response.data);
        });
    };

    const onSharedUserRemoved = (user: SharedUser) => {
        setSharingInProgress(true);
        setSharedUsers((users) => users.filter((u) => u.uuid !== user.uuid));
        onUsersChanged(sharedUsers.filter((u) => u.uuid !== user.uuid));
        removeSharedUserForOrganization(organization.uuid, user.uuid)
            .then(() => {
                setSharingInProgress(false);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const onShared = (message: string, users: Array<Contact>, role = 'organization-manager') => {
        setSharingInProgress(true);
        const usersToAdd: Array<string> = users.map((u) => u.uuid);
        addUserToOrganization(organization.uuid, usersToAdd, role, message)
            .then((response) => {
                setSharedUsers(response.data);
                onUsersChanged(response.data);
                setSharingInProgress(false);
                loadInvites(organization.uuid);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const handleInviteRevoked = (invite: Invite) => {
        setInvites((invites) => invites.filter((i) => i.uuid !== invite.uuid));
        revokeInviteFromOrganization(organization.uuid, invite.uuid)
            .then(() => {})
            .catch(() => {});
    };

    const handleInviteResent = (invite: Invite) => {
        onShared(
            '',
            [{ uuid: invite.email, name: invite.email, relationship: 'invitee', type: 'email' }],
            invite.role.name,
        );
    };

    const handleLinkDeletedClicked = (link: Link) => {
        setLinks((links) => links.filter((l) => l.uuid !== link.uuid));
    };
    const handleLinkCreated = (link: Link) => {
        setLinks((links) => [link, ...links]);
    };

    return (
        <SharingDrawer
            title={'Organization Access Rights and Permissions'}
            open={open}
            onClose={onClose}
            userAccessList={sharedUsers}
            onRemove={onSharedUserRemoved}
            onShared={onShared}
            processing={sharingInProgress}
            onLinkCreated={handleLinkCreated}
            onLinkDeleteClicked={handleLinkDeletedClicked}
            onLinkDeleteFailed={() => {}}
            onLinkDeleteSucceeded={() => {}}
            onInviteRevoked={handleInviteRevoked}
            onInviteResent={handleInviteResent}
            entityId={organization.uuid}
            entityType="organization"
            links={links}
            roles={roles}
            invites={invites}
        />
    );
}
