import { Popover } from '@mui/material';
import { useNonInitialEffect } from 'hooks';
import { filter, pickBy } from 'lodash';
import React, { useCallback } from 'react';
import { Dimension } from '../home.types';
import DimensionChoiceSelector from './DimensionChoiceSelector';
import DimensionFilterChip from './DimensionFilterChip';

interface Props {
    onChange: (value: (number | string)[]) => void;
    resourcePath?: 'individuals' | 'movements' | 'groups' | 'tem-exercises';
    buttonText: string;
    id: string;
    otherFilters?: { [key: string]: string };
    dataGetter?: (page: number, limit: number, payload?: any) => Promise<any>;
    dataMapper?: (data: any) => { dimensions: Dimension[]; page: number; hasMore: boolean };
    initialState?: { [key: string]: boolean };
}

const DimensionFilterBuilder = React.memo(function DimensionFilterBuilder({
    onChange,
    resourcePath,
    buttonText,
    id,
    otherFilters = {},
    dataGetter,
    dataMapper,
    initialState = {},
}: Props) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [filterValue, setFilterValue] = React.useState<{ [key: string]: boolean }>(initialState);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const getSelectedValues = useCallback(() => {
        return filter(filterValue, (v) => !!v);
    }, [filterValue]);

    useNonInitialEffect(() => {
        onChange(Object.keys(pickBy(filterValue, (v) => !!v)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue]);
    return (
        <>
            <Popover
                open={!!anchorEl}
                id={id}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted={true}
            >
                {anchorEl && (
                    <DimensionChoiceSelector
                        onChange={setFilterValue}
                        value={filterValue}
                        resourcePath={resourcePath}
                        otherFilters={otherFilters}
                        dataGetter={dataGetter}
                        dataMapper={dataMapper}
                    />
                )}
            </Popover>
            <DimensionFilterChip
                onClick={handleClick}
                variant={getSelectedValues().length > 0 ? 'contained' : 'outlined'}
            >
                {buttonText}{' '}
                {getSelectedValues().length > 0 ? `(${getSelectedValues().length})` : ''}
            </DimensionFilterChip>
        </>
    );
});

export default DimensionFilterBuilder;
