import React, { ReactElement, useEffect, useState } from 'react';

interface Props {
    duration: number;
    children: React.ReactNode;
    onFinish: () => void;
}

function FlashMessage({ duration, children, onFinish }: Props): ReactElement<any, any> | null {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    useEffect(() => {
        let timer = setTimeout(() => {
            setIsVisible(false);
            onFinish();
        }, duration);

        return () => {
            window.clearTimeout(timer);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return isVisible ? <>{children}</> : <></>;
}

export default FlashMessage;
