import React from 'react';
import { DateTime } from 'luxon';
import { Box, Button } from '@mui/material';
import { grey } from '@mui/material/colors';

interface DateCellProps {
    date: DateTime;
    onClick: () => void;
    indicator?: string;
    isSelected?: boolean;
}

const DateCell: React.FC<React.PropsWithChildren<DateCellProps>> = ({
    date,
    onClick,
    indicator,
    isSelected,
}) => (
    <Box
        sx={{
            width: 100,
            height: 100,
            minWidth: 100,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: grey[300],
            cursor: 'pointer',
            position: 'relative',
        }}
        onClick={onClick}
    >
        <Box sx={{ fontSize: 'small', color: grey[600], textAlign: 'center' }}>
            {date.monthShort}
        </Box>
        <Box sx={{ fontSize: 'medium', textAlign: 'center', color: grey[600] }}>
            {date.weekdayShort}
        </Box>
        <Box sx={{ fontSize: 'large', color: grey[800], fontWeight: 'bold', textAlign: 'center' }}>
            <Button
                variant={isSelected ? 'contained' : 'text'}
                onClick={() => onClick()}
                size={'small'}
                sx={{ borderRadius: '50%', minWidth: 50, minHeight: 50 }}
            >
                {date.day}
            </Button>
        </Box>
        {indicator && (
            <Box
                style={{
                    textAlign: 'center',
                    position: 'absolute',
                    bottom: '5px',
                    right: '45px',
                    width: '10px',
                    height: '10px',
                    backgroundColor: indicator,
                    borderRadius: '50%',
                }}
            ></Box>
        )}
    </Box>
);

export default DateCell;
