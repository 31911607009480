import React from 'react';

function CalendarAddDateIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="none">
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M13.25 2.25H14c.825 0 1.5.675 1.5 1.5v12c0 .825-.675 1.5-1.5 1.5H2c-.825 0-1.5-.675-1.5-1.5v-12c0-.825.675-1.5 1.5-1.5h.75V.75h1.5v1.5h7.5V.75h1.5v1.5zM2 15.75h12V7.5H2v8.25zM2 6h12V3.75H2V6zm5.25 3h1.5v1.5h1.5V12h-1.5v1.5h-1.5V12h-1.5v-1.5h1.5V9z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default CalendarAddDateIcon;
