import { AvatarGroup } from '@mui/material';
import AvatarWithInitials from 'components/Icons/AvatarWithInitials';
import React, { ReactElement } from 'react';
import { SharedUser } from 'utils';

interface Props {
    sharedUsers: Array<SharedUser>;
}

function SharedUsersAvatarGroup({ sharedUsers }: Props): ReactElement {
    return (
        <AvatarGroup max={3}>
            {sharedUsers.map((user) => (
                <AvatarWithInitials
                    key={user.uuid}
                    alt={user.display_name}
                    firstname={user.display_name.split(' ')[0]}
                    lastname={user.display_name.split(' ')[1] ?? undefined}
                    src={user.avatar_url ?? undefined}
                />
            ))}
        </AvatarGroup>
    );
}

export { SharedUsersAvatarGroup };
