import React, { useContext, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Form, FormikErrors, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import { isEmpty } from 'lodash';
import { useAppDispatch } from 'redux/store';
import { createNewSession } from 'redux/reducers/sessions';
import { pushMessage } from 'redux/reducers/messages';
import { OrganizationsContext } from '../../contexts/OrganizationsContext';
import { confirmViaDialog, FormModal } from '.';
import {
    EXIT_TEXT,
    SAVE_INFORMATION_TEXT,
    SAVE_TEXT,
    somethingWentWrong,
    UNSAVED_CHANGES_TEXT,
} from 'utils';
import useTheme from '@mui/material/styles/useTheme';

interface CreateSessionModalProps {
    open: boolean;
    defaultTitle?: string;
    onClose: (
        newVal: boolean,
        sessionId?: string,
        sessionName?: string,
        sessionDesc?: string,
    ) => void;
}

export default function CreateSessionModal({
    open,
    defaultTitle,
    onClose,
}: CreateSessionModalProps) {
    const dispatch = useAppDispatch();
    const [showOtherDuration, setShowOtherDuration] = useState(false);
    const { organizations } = useContext(OrganizationsContext);
    const theme = useTheme();
    const validationSchema = yup.object({
        title: yup.string().required('Title is required'),
        duration: yup.number().min(0, 'Duration must be positive'),
        organization: yup.string().required('Organization is required'),
    });

    interface ServerErrors {
        [key: string]: string[];
    }

    function transformToFormikErrors(
        serverErrors: ServerErrors,
    ): FormikErrors<{ title: string; duration: number; organization: string }> {
        let formikErrors: FormikErrors<{ title: string; duration: number; organization: string }> =
            {};

        Object.keys(serverErrors).forEach((key) => {
            if (['title', 'duration', 'organization'].includes(key)) {
                formikErrors[key as keyof typeof formikErrors] = serverErrors[key].join(', ');
            }
        });

        return formikErrors;
    }

    const formik = useFormik({
        initialValues: {
            title: defaultTitle || '',
            duration: 0,
            organization: organizations.length ? organizations[0].uuid : '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setErrors }) => {
            const result = await dispatch(
                createNewSession({
                    title: values.title,
                    description: '',
                    duration: values.duration || 0,
                    organization: values.organization,
                }),
            );

            if (createNewSession.fulfilled.match(result)) {
                onClose(true, result.payload.data.uuid, values.title);
                dispatch(
                    pushMessage({
                        status: 'success',
                        message: 'Session created successfully.',
                    }),
                );
            } else {
                if (result?.payload && 'errors' in result.payload && result.payload.errors) {
                    const formikErrors = transformToFormikErrors(
                        result.payload.errors as ServerErrors,
                    );
                    setErrors(formikErrors);
                } else {
                    somethingWentWrong();
                }
            }
        },
    });

    const onCancel = async () => {
        if (formik.dirty) {
            const res = await confirmViaDialog({
                confirmation: {
                    title: UNSAVED_CHANGES_TEXT,
                    message: SAVE_INFORMATION_TEXT,
                    cancelButtonText: EXIT_TEXT,
                    okButtonText: SAVE_TEXT,
                    onCancelPressed: close,
                },
            });
            if (res) {
                formik.handleSubmit();
            }
        } else {
            close();
        }
    };

    const close = () => {
        onClose(false);
    };

    return (
        <FormikProvider value={formik}>
            <FormModal
                handleButtonClicked={formik.handleSubmit}
                loading={false}
                handleClose={onCancel}
                open={open}
                title="New Session"
            >
                <Form>
                    {!isEmpty(organizations) && (
                        <FormControl fullWidth sx={{ marginBottom: theme.spacing(4) }}>
                            <InputLabel id="organization-label">Organization</InputLabel>
                            <Select
                                labelId="organization-label"
                                name="organization"
                                value={formik.values.organization}
                                onChange={formik.handleChange}
                                label="Organization"
                            >
                                {organizations.map((organization) => (
                                    <MenuItem key={organization.uuid} value={organization.uuid}>
                                        <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                            <Avatar
                                                src={organization.image_urls['avatar'] ?? ''}
                                                alt={organization.name}
                                                variant="rounded"
                                                sx={{ marginRight: 2 }}
                                            />
                                            <Typography>{organization.name}</Typography>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <TextField
                        fullWidth
                        name="title"
                        label="Session Name"
                        variant="outlined"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                        sx={{ marginBottom: theme.spacing(4) }}
                    />

                    <Box
                        py={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        mx={5}
                    >
                        <InputLabel>Session Duration - optional</InputLabel>
                        {showOtherDuration && (
                            <IconButton onClick={() => setShowOtherDuration(false)} size="large">
                                <CancelIcon />
                            </IconButton>
                        )}
                    </Box>
                    {showOtherDuration && (
                        <TextField
                            sx={{ marginBottom: theme.spacing(4) }}
                            label="Duration (Minutes)"
                            name="duration"
                            variant="filled"
                            placeholder="Enter duration in minutes"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={formik.values.duration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.duration && Boolean(formik.errors.duration)}
                            helperText={formik.touched.duration && formik.errors.duration}
                        />
                    )}
                    {!showOtherDuration && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-around"
                            pt={5}
                        >
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => formik.setFieldValue('duration', 15)}
                                color={formik.values.duration === 15 ? 'primary' : 'inherit'}
                            >
                                15 Minutes
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => formik.setFieldValue('duration', 30)}
                                color={formik.values.duration === 30 ? 'primary' : 'inherit'}
                            >
                                30 Minutes
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => formik.setFieldValue('duration', 45)}
                                color={formik.values.duration === 45 ? 'primary' : 'inherit'}
                            >
                                45 Minutes
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => formik.setFieldValue('duration', 60)}
                                color={formik.values.duration === 60 ? 'primary' : 'inherit'}
                            >
                                1 Hour
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => setShowOtherDuration(true)}
                            >
                                Other
                            </Button>
                        </Box>
                    )}
                </Form>
            </FormModal>
        </FormikProvider>
    );
}
