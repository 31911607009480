import NavigationLayout from '../../Layouts/NavigationLayout';
import DashboardLayout from './scenes/DashboardLayout';

export const homeRoutes = [
    {
        exact: false,
        layout: NavigationLayout,
        path: '/home',
        component: DashboardLayout,
        private: true,
    },
];
