import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<void>>;

const revokeInviteFromSessions = (id: string, inviteUuid: string): ReturnType => {
    return Request.post(`/workouts/sessions/${id}/invites/${inviteUuid}/revoke`);
};

export default revokeInviteFromSessions;
