import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation, Redirect, Switch, Route } from 'react-router-dom';
import { Tabs, Tab, Container, AppBar, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { UseAdminStatus } from '../../contexts/AdminContext';
import OrdersList from './modules/orders/scenes/OrdersList';
import OrganizationsList from './modules/organizations/scenes/OrganizationsList';
import PodList from './modules/pods/scenes/PodList';
import PhysicalProductList from './modules/physicalProducts/scenes/PhysicalProductList';
import SubscriptionList from './modules/subscriptions/scenes/SubscriptionList';
import CommissionList from './modules/commissions/scenes/CommissionList';
import DescriptionIcon from '@mui/icons-material/Description';
import InventoryIcon from '@mui/icons-material/Inventory';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import NetworkingPayoutList from './modules/networkingPayoutLogs/scenes/NetworkingPayoutList';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import MarketplaceItemsList from './modules/marketplace/scenes/MarketplaceItemsList';
import Badge from '@mui/material/Badge';
import { AccountTree, SpeakerPhone } from '@mui/icons-material';
import BusinessIcon from '@mui/icons-material/Business';
import BinaryTreeAdmin from './modules/binaryTree/scenes/BinaryTreeAdmin';
import AthleteList from './modules/athletes/scenes/AthleteList';
import PersonIcon from '@mui/icons-material/Person';

const StyledTab = styled(Tab)(({ theme }) => ({
    width: '10%', // Set to fit the numbers of tabs/icons
    minWidth: 'auto',
    minHeight: '40px', // Keep the minimum height
    height: '40px', // Keep the fixed height
    fontSize: '0.6rem', // Reduced font size for the text
    padding: '10px 8px 4px', // Add more top padding to lower the content
    '& .MuiTab-wrapper': {
        display: 'flex',
        flexDirection: 'row', // Ensure icon and text are in a row
        justifyContent: 'center',
        alignItems: 'center', // Center items vertically
        height: '100%', // Full height of the tab
    },
    '& .MuiTab-iconWrapper': {
        marginBottom: '2px', // Set the margin-bottom to 2px as requested
        marginRight: theme.spacing(1),
    },
    '& .MuiSvgIcon-root': {
        fontSize: '1.2rem', // Base size for the icon
    },
}));

export default function AdminBasePage(): ReactElement {
    const history = useHistory();
    const location = useLocation();
    const isAdmin = UseAdminStatus().isAdmin;
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('sm'));
    const [tabValue, setTabValue] = useState(0);
    const { pendingOrders, pendingMarketplaceResellRequests, pendingSubscriptionChangeRequests } =
        UseAdminStatus();

    useEffect(() => {
        console.debug('pending orders', pendingOrders);
        console.debug('pending resell requests', pendingMarketplaceResellRequests);
        console.debug('pending subscription change requests', pendingSubscriptionChangeRequests);
    }, [pendingOrders, pendingMarketplaceResellRequests, pendingSubscriptionChangeRequests]);

    useEffect(() => {
        if (location.pathname === '/admin') {
            history.replace('/admin/orders');
        }
    }, [location.pathname, history]);

    useEffect(() => {
        if (location.pathname.includes('/admin/orders')) setTabValue(0);
        else if (location.pathname.includes('/admin/orgs')) setTabValue(1);
        else if (location.pathname.includes('/admin/athletes')) setTabValue(2);
        else if (location.pathname.includes('/admin/pods')) setTabValue(3);
        else if (location.pathname.includes('/admin/products')) setTabValue(4);
        else if (location.pathname.includes('/admin/subscriptions')) setTabValue(5);
        else if (location.pathname.includes('/admin/commissions')) setTabValue(6);
        else if (location.pathname.includes('/admin/marketplace')) setTabValue(7);
        else if (location.pathname.includes('/admin/payout-logs')) setTabValue(8);
        else if (location.pathname.includes('/admin/binary-tree')) setTabValue(9);
        else setTabValue(0);
    }, [location.pathname]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        const paths = [
            'orders',
            'orgs',
            'athletes',
            'pods',
            'products',
            'subscriptions',
            'commissions',
            'marketplace',
            'payout-logs',
            'binary-tree',
        ];
        history.push(paths[newValue]);
    };

    const badgeStyle = {
        '& .MuiBadge-badge': {
            fontSize: '0.5rem',
            height: '14px',
            minWidth: '14px',
            padding: '0 4px',
            top: '20%',
            right: '-15%',
        },
    };

    return isAdmin ? (
        <>
            <AppBar position="sticky" color="default" elevation={1}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="admin navigation tabs"
                >
                    <StyledTab
                        icon={
                            <Badge badgeContent={pendingOrders} color="error" sx={badgeStyle}>
                                <DescriptionIcon />
                            </Badge>
                        }
                        label={isNarrow ? '' : 'Orders'}
                    />
                    <StyledTab icon={<BusinessIcon />} label={isNarrow ? '' : 'Orgs'} />
                    <StyledTab icon={<PersonIcon />} label={isNarrow ? '' : 'Athletes'} />
                    <StyledTab icon={<SpeakerPhone />} label={isNarrow ? '' : 'Pods'} />
                    <StyledTab icon={<InventoryIcon />} label={isNarrow ? '' : 'Products'} />
                    <StyledTab
                        icon={
                            <Badge
                                badgeContent={pendingSubscriptionChangeRequests}
                                color="error"
                                sx={badgeStyle}
                            >
                                <CalendarTodayIcon />
                            </Badge>
                        }
                        label={isNarrow ? '' : 'Subscriptions'}
                    />
                    <StyledTab icon={<AttachMoneyIcon />} label={isNarrow ? '' : 'Commissions'} />
                    <StyledTab
                        icon={
                            <Badge
                                badgeContent={pendingMarketplaceResellRequests}
                                color="error"
                                sx={badgeStyle}
                            >
                                <VideoLibraryIcon />
                            </Badge>
                        }
                        label={isNarrow ? '' : 'Marketplace'}
                    />
                    <StyledTab icon={<RequestPageIcon />} label={isNarrow ? '' : 'Payout Logs'} />
                    <StyledTab icon={<AccountTree />} label={isNarrow ? '' : 'Binary Tree'} />
                </Tabs>
            </AppBar>
            <Container maxWidth={false} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Switch>
                    <Route path="/admin/orders" component={OrdersList} />
                    <Route path="/admin/orgs" component={OrganizationsList} />
                    <Route path="/admin/athletes" component={AthleteList} />
                    <Route path="/admin/pods" component={PodList} />
                    <Route path="/admin/products" component={PhysicalProductList} />
                    <Route path="/admin/subscriptions" component={SubscriptionList} />
                    <Route path="/admin/commissions" component={CommissionList} />
                    <Route path="/admin/marketplace" component={MarketplaceItemsList} />
                    <Route path="/admin/payout-logs" component={NetworkingPayoutList} />
                    <Route path="/admin/binary-tree" component={BinaryTreeAdmin} />
                </Switch>
            </Container>
        </>
    ) : (
        <Redirect to="/" />
    );
}
