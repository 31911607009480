import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Submission } from 'utils';

type ReturnType = Promise<AxiosResponse<Submission>>;

const submitFormSubmission = (formId: string, submissionId: string): ReturnType => {
    return Request.post(`/data/forms/${formId}/submissions/${submissionId}/submit`);
};
export default submitFormSubmission;
