import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import {
    Grid,
    IconButton,
    Paper,
    Tooltip,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import { ProfileDetailsForm, ProfilePhotoForm, TypographyTitle } from 'components';
import AdminOrgList from 'components/Navigation/AdministrativeOrgList';
import AffiliatedOrgList from 'components/Navigation/AffiliatedOrgList';
import AssociatedOrgList from 'components/Navigation/AssociatedOrgList';
import AnthropometricDetails from '../../components/Forms/AnthropometricDetails';
import SchoolSportFormDetails from '../../components/Forms/SchoolSportFormDetails';
import SocialMediaDetailsForm from '../../components/Forms/SocialMediaDetailsForm';
import React, { useEffect, useState } from 'react';
import { AthletePod } from '../../app.types';
import getAthletePods from '../../api/Auth/getAthletePods';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

const Profile = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage] = useState('');
    const [pods, setPods] = useState<AthletePod[]>([]);

    useEffect(() => {
        const fetchPods = async () => {
            try {
                const response = await getAthletePods();
                console.debug('getAthletePods response', response.data);
                const dataWithIds = response.data.map((pod) => ({ ...pod, id: pod.uuid }));
                setPods(dataWithIds);
            } catch (error) {
                console.error('Failed to fetch pods', error);
            }
        };

        fetchPods();
    }, []);

    return (
        <Grid container margin={4} alignItems={'flex-start'} justifyContent={'center'}>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <SnackbarContent message={snackbarMessage} />
            </Snackbar>
            <Grid item lg={3} sm={4} xs={12} margin={6}>
                <Paper sx={{ padding: 6 }}>
                    <TypographyTitle
                        align="center"
                        sx={{
                            marginY: 10,
                            marginX: 2,
                        }}
                    >
                        Profile Photo
                    </TypographyTitle>
                    <ProfilePhotoForm />
                </Paper>
            </Grid>

            <Grid
                item
                lg={3}
                sm={4}
                xs={12}
                margin={6}
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <Paper sx={{ padding: 6 }}>
                    <TypographyTitle
                        align="center"
                        sx={{
                            marginY: 10,
                            marginX: 2,
                        }}
                    >
                        My FiyrPods
                    </TypographyTitle>
                    <List>
                        {pods.map((pod) => (
                            <ListItem
                                key={pod.uuid}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)', // adjust the color as needed
                                    },
                                }}
                            >
                                <ListItemText
                                    primary={`${pod.organization_name} - ${pod.simple_id} (${pod.serial_number})`}
                                />
                                <ListItemIcon>
                                    {pod.transfer_request_accepted_at === null ? (
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                padding: '6px 16px',
                                                fontSize: '0.875rem',
                                                minWidth: '64px',
                                                boxSizing: 'border-box',
                                                transition: 'background-color 0.3s',
                                                fontWeight: 500,
                                                lineHeight: 1.75,
                                                borderRadius: '4px',
                                                letterSpacing: '0.02857em',
                                                textTransform: 'uppercase',
                                                color: '#fff',
                                                backgroundColor: 'grey',
                                            }}
                                        >
                                            INACTIVE
                                        </span>
                                    ) : (
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                padding: '6px 16px',
                                                fontSize: '0.875rem',
                                                minWidth: '64px',
                                                boxSizing: 'border-box',
                                                transition: 'background-color 0.3s',
                                                fontWeight: 500,
                                                lineHeight: 1.75,
                                                borderRadius: '4px',
                                                letterSpacing: '0.02857em',
                                                textTransform: 'uppercase',
                                                color: '#fff',
                                                backgroundColor: 'green',
                                            }}
                                        >
                                            Active
                                        </span>
                                    )}
                                </ListItemIcon>
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Grid>

            <Grid item lg={3} sm={4} xs={12} margin={6}>
                <Paper sx={{ padding: 6 }}>
                    <TypographyTitle
                        align="center"
                        sx={{
                            marginY: 10,
                            marginX: 2,
                        }}
                    >
                        User Details
                    </TypographyTitle>
                    <ProfileDetailsForm />
                </Paper>
            </Grid>
            <Grid item lg={3} sm={4} xs={12} margin={6}>
                <Paper sx={{ padding: 6 }}>
                    <TypographyTitle
                        align="center"
                        sx={{
                            marginY: 10,
                            marginX: 2,
                        }}
                    >
                        Social Media
                    </TypographyTitle>
                    <SocialMediaDetailsForm />
                </Paper>
            </Grid>
            <Grid item lg={3} sm={4} xs={12} margin={6}>
                <Paper sx={{ padding: 6 }}>
                    <TypographyTitle
                        align="center"
                        sx={{
                            marginY: 10,
                            marginX: 2,
                        }}
                    >
                        Biometrics
                    </TypographyTitle>
                    <AnthropometricDetails />
                </Paper>
            </Grid>
            <Grid item lg={3} sm={4} xs={12} margin={6}>
                <Paper sx={{ padding: 6 }}>
                    <TypographyTitle
                        align="center"
                        sx={{
                            marginY: 10,
                            marginX: 2,
                        }}
                    >
                        Schools and Sports
                    </TypographyTitle>
                    <SchoolSportFormDetails />
                </Paper>
            </Grid>
            <Grid item lg={3} sm={4} xs={12} margin={6}>
                <Paper sx={{ padding: 6 }}>
                    <TypographyTitle
                        align="center"
                        sx={{
                            marginY: 10,
                            marginX: 2,
                        }}
                    >
                        Admin Databases
                        <Tooltip
                            placement="top"
                            title="Administrative databases are those you have admin rights over so you can invite users, add groups, create assessments, create movements, and other administrative rights"
                        >
                            <IconButton>
                                <PrivacyTipOutlinedIcon sx={{ marginLeft: 4 }} />
                            </IconButton>
                        </Tooltip>
                    </TypographyTitle>

                    <AdminOrgList />
                    <TypographyTitle
                        align="center"
                        sx={{
                            marginY: 10,
                            marginX: 2,
                        }}
                    >
                        Affiliated Databases
                        <Tooltip
                            placement="top"
                            title="Affiliated databases are those you are connected with
                                 because you are an organizational coach or content creator at this organization"
                        >
                            <IconButton>
                                <PrivacyTipOutlinedIcon sx={{ marginLeft: 4 }} />
                            </IconButton>
                        </Tooltip>
                    </TypographyTitle>
                    <AffiliatedOrgList />
                    <TypographyTitle
                        align="center"
                        sx={{
                            marginY: 10,
                            marginX: 2,
                        }}
                    >
                        Associated Databases
                        <Tooltip
                            placement="top"
                            title="Associated databases are those you are connected with
                                 because another database has shared content (assessments,
                                    movements, or sessions) with you or you are an athlete of
                                    an organization using this database"
                        >
                            <IconButton>
                                <PrivacyTipOutlinedIcon sx={{ marginLeft: 4 }} />
                            </IconButton>
                        </Tooltip>
                    </TypographyTitle>
                    <AssociatedOrgList />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Profile;
