import React, { ReactElement } from 'react';
import { Box, Card, CardActionArea, CardProps } from '@mui/material';
import { CardTitleText } from '../../modules/train/components';

interface Props {
    count: number;
    title: string;
    onClick: () => void;
    color?: string;
    icon?: React.ReactElement;
    cardProps?: CardProps;
}

export default function CountCard(props: Props): ReactElement {
    return (
        <Card sx={{ borderRadius: 8, maxWidth: 256, color: 'white' }} {...(props.cardProps || {})}>
            <CardActionArea
                onClick={props.onClick}
                sx={{
                    display: 'flex',
                    justifyContent: props.icon ? 'space-between' : 'center',
                    backgroundColor: (theme) =>
                        props.color ?? theme.palette.backgroundColor.freeSpeechBlue,
                    alignItems: 'center',
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingBottom: 7.5,
                    paddingTop: 7.5,
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CardTitleText>{props.title}</CardTitleText>
                    <Box component="span" fontSize={20} lineHeight="28px">
                        {props.count}
                    </Box>
                </Box>
                {props.icon}
            </CardActionArea>
        </Card>
    );
}
