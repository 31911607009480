import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<any>>;

const getSocialFeed = (profileId: string, page: number = 1): ReturnType => {
    return Request.get(`/public/social/feed/${profileId}?page=${page}`);
};

export default getSocialFeed;
