import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Movement } from 'utils';
import { EncodingOptionsPayload } from '../../app.types';

type ReturnType = Promise<AxiosResponse<Movement>>;

const attachAsset = (
    path: string,
    movementUuid: string,
    encoding_options?: EncodingOptionsPayload,
): ReturnType => {
    return Request.post(`/workouts/movements/${movementUuid}/assets`, {
        path,
        encoding_options,
    });
};

export default attachAsset;
