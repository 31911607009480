import { Box } from '@mui/material';
import React, { useContext } from 'react';
import {
    Channel,
    ChannelHeader,
    ChannelList,
    Chat,
    MessageInput,
    MessageList,
    Thread,
    Window,
} from 'stream-chat-react';
import { ChatContext } from '../contexts/ChatContext';
import 'stream-chat-react/dist/css/v2/index.css';

const options = { presence: true, state: true };

const StreamChatPage = () => {
    const { client, userConnected, userData } = useContext(ChatContext);

    if (!client || !userConnected || !userData) {
        return <>Loading...</>;
    }

    return (
        <Chat client={client}>
            <Box
                sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    '& .str-chat-channel-list': {
                        width: 300,
                    },
                    minHeight: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexShrink: 0,
                        overflowY: 'auto',
                        overflowX: 'none',
                    }}
                >
                    <ChannelList
                        filters={{ members: { $in: [userData.uuid] }, type: 'messaging' }}
                        options={options}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 1,
                    }}
                >
                    <Channel>
                        <Window>
                            <ChannelHeader />
                            <MessageList />
                            <MessageInput />
                        </Window>
                        <Thread />
                    </Channel>
                </Box>
            </Box>
        </Chat>
    );
};

export default StreamChatPage;
