import React from 'react';
import Radio from '@mui/material/Radio';
import { RadioGroupProps } from 'formik-mui';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';

type Props = RadioGroupProps & {
    name: string;
    classes: any;
    className: string;
    options: Array<{ value: string; label: string }>;
};

const renderOptions = (
    options: Array<{ value: string; label: string }>,
    classes: any,
    className: string,
) => {
    return options.map((option: { label: string; value: string }, index) => (
        <FormControlLabel
            key={index}
            value={option.value}
            classes={classes}
            className={className}
            labelPlacement="start"
            control={
                <Radio
                    icon={<RadioButtonUnchecked color="disabled" />}
                    checkedIcon={<CheckCircle color="primary" />}
                />
            }
            label={option.label}
        />
    ));
};

const RadioGroupInput = ({
    field,
    classes,
    className,
    form: { touched, errors },
    name,
    options,
    children,
    ...props
}: Props) => {
    const fieldName = name || field.name;

    return (
        <React.Fragment>
            <RadioGroup {...field} {...props} name={fieldName}>
                {options ? renderOptions(options, classes, className) : children}
            </RadioGroup>

            {touched[fieldName] && errors[fieldName] && (
                <span style={{ color: 'red', fontFamily: 'sans-serif' }}>
                    {errors[fieldName] as string}
                </span>
            )}
        </React.Fragment>
    );
};

export default RadioGroupInput;
