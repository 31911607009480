import { Skeleton, SxProps } from '@mui/material';
import { Box, Theme } from '@mui/system';
import useScript from 'hooks/useScript';
import { DateTime } from 'luxon';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';

interface VideoSource {
    /** src The source url of the video file */
    src: string;

    /** type The mime-type of the video file */
    type?: string;
    /** withCredentials Should always be true */
    withCredentials?: boolean;
}

export interface PlayerProps {
    sources: Array<VideoSource>;
    classesSx: SxProps<Theme>;
    /** poster The poster url */
    poster: string;
    /** cookiesToSet The cookie to set before attempting to retrieve the video */
    cookiesToSet?: { [key: string]: string | null | number };

    size: { [key: string]: boolean };

    crossorigin?: 'anonymous' | 'use-credentials';
}

type CloudfrontCookie = 'CloudFront-Policy' | 'CloudFront-Signature' | 'CloudFront-Key-Pair-Id';

function PlayerComponent({
    sources,
    poster,
    classesSx,
    cookiesToSet = {},
    size = {},
    crossorigin,
}: PlayerProps): ReactElement {
    const playerId = useRef<string>(uuidv4());

    const status = useScript(
        'https://player-static.qencode.com/release/qencode-bootstrapper.min.js',
        `qencode-player-js`,
    );

    const [instanceId, setInstanceId] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie] = useCookies([
        'CloudFront-Policy',
        'CloudFront-Signature',
        'CloudFront-Key-Pair-Id',
    ]);
    let dt = DateTime.now();
    dt.set({ hour: dt.hour + 2 });
    Object.keys(cookiesToSet).forEach((key) => {
        setCookie(key as CloudfrontCookie, cookiesToSet[key], {
            domain: process.env.REACT_APP_CDN_COOKIE_DOMAIN,
            path: '/',
            expires: dt.toJSDate(),
            sameSite: 'lax',
        });
    });

    useEffect(() => {
        if (status === 'ready') {
            let bypassOptions = {
                poster,
            } as any;
            if (crossorigin) {
                bypassOptions['crossorigin'] = crossorigin;
            }
            window.qPlayer(
                playerId.current,
                {
                    size,
                    licenseKey: 'ba0f3ebe-bdff-8ed7-85cd-7b2b30e05d1e',
                    videoSources: sources,
                    autoplay: true,
                    bypassOptions,
                },
                function (this: any) {
                    setLoading(false);
                    setInstanceId(this._instanceId);
                },
            );
        }
    }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            if (instanceId && window.qencodePlayers[instanceId]) {
                window.qencodePlayers[instanceId].dispose();
                delete window.qencodePlayers[instanceId];
            }
        };
    }, [instanceId]);
    return (
        <Box>
            {loading && (
                <Skeleton
                    style={{ display: 'block', margin: 'auto' }}
                    variant="rectangular"
                    width="100%"
                    height={118}
                />
            )}
            <Box id={playerId.current} sx={classesSx}></Box>
        </Box>
    );
}

export { PlayerComponent as Player };
