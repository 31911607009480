import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'redux/reducers/auth';
import {
    fetchChatToken,
    getUserData,
    userChatToken,
    userIsLoadedSelector,
} from 'redux/reducers/user';
import { useAppDispatch } from '../redux/store';

interface Props {}

export const UserContext = React.createContext<Props>({});

export function UserProvider({ children }: { children: React.ReactNode }) {
    const { isAuth } = useSelector(authSelector);
    const userLoaded = useSelector(userIsLoadedSelector);
    const chatToken = useSelector(userChatToken);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isAuth) {
            return;
        }
        if (userLoaded) {
            return;
        }
        dispatch(getUserData());
        if (!chatToken) {
            dispatch(fetchChatToken());
        }
    }, [isAuth, userLoaded, dispatch, chatToken]);

    return <UserContext.Provider value={{}}>{children}</UserContext.Provider>;
}
