import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export type ButtonWithLoaderProps = ButtonProps & {
    loading?: boolean;
    value?: number;
    sx?: any; // adding this line to allow sx within ButtonWithLoaderProps
};

const ButtonWithLoader = ({ sx, children, loading, value, ...props }: ButtonWithLoaderProps) => {
    return (
        <Button
            sx={{
                textTransform: 'none',
                ...sx, // to allow further style customizations
            }}
            {...props}
        >
            {children}
            {loading && (
                <CircularProgress variant={value ? 'determinate' : 'indeterminate'} value={value} />
            )}
        </Button>
    );
};

export default ButtonWithLoader;
