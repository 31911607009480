import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';
import React, { ReactElement } from 'react';

export default function IndividualsListSkeleton(): ReactElement {
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Skeleton animation="wave" variant="rectangular" height={45} />
            </Grid>
            <Grid item>
                <Skeleton animation="wave" variant="rectangular" height={45} />
            </Grid>
            <Grid item>
                <Skeleton animation="wave" variant="rectangular" height={45} />
            </Grid>
            <Grid item>
                <Skeleton animation="wave" variant="rectangular" height={45} />
            </Grid>
            <Grid item>
                <Skeleton animation="wave" variant="rectangular" height={45} />
            </Grid>
            <Grid item>
                <Skeleton animation="wave" variant="rectangular" height={45} />
            </Grid>
            <Grid item>
                <Skeleton animation="wave" variant="rectangular" height={45} />
            </Grid>
            <Grid item>
                <Skeleton animation="wave" variant="rectangular" height={45} />
            </Grid>
            <Grid item>
                <Skeleton animation="wave" variant="rectangular" height={45} />
            </Grid>
        </Grid>
    );
}
