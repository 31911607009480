import {
    Exploration,
    ExplorationFilters,
    ExplorationFiltersYaxisKeyType,
    ExplorationType,
    MeasureKey,
} from '../explore.types';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import DimensionFilterBuilder from '../../home/components/DimensionFilterBuilder';
import CheckboxFilterList from '../../../components/Inputs/CheckboxFilterList';
import React, { useCallback, useEffect, useState } from 'react';
import { getAllCropLabels } from '../explore.api';
import { CropLabel } from '../../community/community.types';
import {
    convertApiFiltersToExplorationFilters,
    groupDataGetter,
    groupDataMapper,
    individualDataGetter,
    individualDataMapper,
} from '../explore.func';
import { getTags } from 'api/tags.api';
import { Tag } from '../../../app.types';
import CubeRelativeDateRangeSelector, {
    RangeChoice,
} from '../../../components/FormControl/CubeRelativeDateRangeSelector';
import { TimeDimensionGranularity } from '@cubejs-client/core';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from 'react-router-dom';

type SectionType =
    | 'title'
    | 'range'
    | 'individuals'
    | 'groups'
    | 'tags'
    | 'cropLabels'
    | 'displayOnSameGraph'
    | 'yAxisKey'
    | 'gpsSummaryMeasure'
    | 'gpsSummaryMeasures'
    | 'timeGrouping'
    | 'excludedCrops';

type PanelSections = Record<ExplorationType, SectionType[]>;

const panelSections: PanelSections = {
    crops: [
        'title',
        'range',
        'individuals',
        'groups',
        'tags',
        'cropLabels',
        'displayOnSameGraph',
        'yAxisKey',
        'excludedCrops',
    ],
    gps_summary_leaderboard: ['title', 'range', 'individuals', 'groups', 'gpsSummaryMeasure'],
    gps_summary_athlete_profile: ['title', 'range', 'individuals', 'groups', 'gpsSummaryMeasures'],
    gps_summary_athlete_progress: [
        'title',
        'range',
        'individuals',
        'groups',
        'timeGrouping',
        'gpsSummaryMeasure',
    ],
    gps_summary_acwr: ['title', 'range', 'individuals', 'groups'],
};

const ExplorationDetailsPanel = ({
    exploration,
    onChange,
}: {
    exploration: Exploration;
    onChange: (filters: ExplorationFilters, title: string) => void;
}) => {
    const [cropLabels, setCropLabels] = useState<CropLabel[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [title, setTitle] = useState(exploration.title);
    const history = useHistory();

    const [values, setValues] = useState<ExplorationFilters>(
        convertApiFiltersToExplorationFilters(exploration.filters),
    );

    const getExclusionsforExploration = useCallback((): RangeChoice[] => {
        if (exploration.type === 'gps_summary_acwr') {
            return [
                'last 90 days',
                'last 120 days',
                'last 6 months',
                'from 6 months ago to now',
                'from 1 year ago to now',
                'this year',
                'all',
            ];
        }
        return ['all'];
    }, [exploration]);

    useEffect(() => {
        if (exploration) {
            setValues(convertApiFiltersToExplorationFilters(exploration.filters));
        }
    }, [exploration]);

    useEffect(() => {
        async function fetchCropLabels() {
            try {
                const cropLabels = await getAllCropLabels(exploration.organization.uuid);
                setCropLabels(cropLabels.data);
            } catch (e: any) {
                console.log(e);
            }
        }

        fetchCropLabels();
    }, [exploration.organization.uuid]);

    useEffect(() => {
        async function loadTags() {
            try {
                const response = await getTags({ 'filter[type]': 'uploads' });
                setTags(response.data);
            } catch (e: any) {
                console.log(e);
            }
        }

        loadTags();
    }, [exploration.organization.uuid]);

    return (
        <Card
            sx={{
                width: '100%',
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 73px)',
            }}
        >
            <CardHeader
                title={
                    <Box display="flex" alignItems="center">
                        <IconButton
                            color="inherit"
                            onClick={() => history.goBack()}
                            aria-label="back"
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                        <Typography variant="h5">Details</Typography>
                    </Box>
                }
            />

            <CardContent>
                <Stack
                    direction="column"
                    divider={<Divider orientation="horizontal" flexItem />}
                    spacing={6}
                >
                    <Box>
                        {exploration && (
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {exploration.organization.name}
                            </Typography>
                        )}
                    </Box>
                    {panelSections[exploration.type].map((section) => (
                        <>
                            {section === 'title' && (
                                <Stack spacing={6} direction={'column'}>
                                    <Typography variant={'body2'}>Exploration Title</Typography>
                                    {exploration && (
                                        <TextField
                                            size={'small'}
                                            autoFocus={true}
                                            id="title"
                                            name="title"
                                            value={title}
                                            onChange={(e) => {
                                                setTitle(e.target.value);
                                                onChange(values, e.target.value);
                                            }}
                                        />
                                    )}
                                </Stack>
                            )}
                            {section === 'range' && (
                                <Stack spacing={6} direction={'column'}>
                                    {exploration.type === 'gps_summary_acwr' && (
                                        <Typography variant={'body2'}>
                                            Date Range To Show On Report. Note: ACWR is calculated
                                            using the previous 7 days of data.
                                        </Typography>
                                    )}
                                    <CubeRelativeDateRangeSelector
                                        exclude={getExclusionsforExploration()}
                                        size={'small'}
                                        value={values.range}
                                        onChange={(range) => {
                                            setValues({ ...values, range });
                                            onChange({ ...values, range }, title);
                                        }}
                                        customStartRecent={exploration.type === 'gps_summary_acwr'}
                                    />
                                </Stack>
                            )}
                            {section === 'individuals' && (
                                <Stack spacing={6} direction={'column'}>
                                    <DimensionFilterBuilder
                                        initialState={values.individuals.reduce(
                                            (obj: any, uuid) => {
                                                obj[uuid] = true;
                                                return obj;
                                            },
                                            {},
                                        )}
                                        onChange={(v) => {
                                            setValues({ ...values, individuals: v as string[] });
                                            onChange(
                                                { ...values, individuals: v as string[] },
                                                title,
                                            );
                                        }}
                                        dataGetter={(page, limit, payload) =>
                                            individualDataGetter(page, limit, {
                                                ...payload,
                                                'filter[name]': payload['filter[query]'] ?? '',
                                                'filter[organization]':
                                                    exploration?.organization.uuid,
                                            })
                                        }
                                        dataMapper={individualDataMapper}
                                        buttonText={'Filter Athletes'}
                                        id={'search-individuals'}
                                    />
                                </Stack>
                            )}
                            {section === 'groups' && (
                                <Stack spacing={6} direction={'column'}>
                                    <DimensionFilterBuilder
                                        initialState={values.groups.reduce((obj: any, uuid) => {
                                            obj[uuid] = true;
                                            return obj;
                                        }, {})}
                                        onChange={(v) => {
                                            setValues((oldValues) => ({
                                                ...oldValues,
                                                groups: v as string[],
                                            }));
                                            onChange({ ...values, groups: v as string[] }, title);
                                        }}
                                        dataGetter={(page, limit, payload) =>
                                            groupDataGetter(page, limit, {
                                                ...payload,
                                                'filter[name]': payload['filter[query]'] ?? '',
                                                'filter[organization]':
                                                    exploration?.organization.uuid,
                                            })
                                        }
                                        dataMapper={groupDataMapper}
                                        buttonText={'Filter Groups'}
                                        id={'search-groups'}
                                    />
                                </Stack>
                            )}
                            {section === 'tags' && (
                                <Stack spacing={6} direction={'column'}>
                                    {tags.length > 0 && values.tags && (
                                        <CheckboxFilterList
                                            value={values.tags}
                                            onChange={(v) => {
                                                setValues((oldValues) => ({
                                                    ...oldValues,
                                                    tags: v,
                                                }));
                                                onChange({ ...values, tags: v }, title);
                                            }}
                                            options={tags.map((c) => ({
                                                label: `${c.name}`,
                                                value: c.id,
                                            }))}
                                            buttonText={'Filter Categories'}
                                        />
                                    )}
                                </Stack>
                            )}
                            {section === 'cropLabels' && (
                                <Stack spacing={6} direction={'column'}>
                                    <Typography variant={'body2'}>Crops</Typography>
                                    {cropLabels.length > 0 && values.cropLabels && (
                                        <CheckboxFilterList
                                            value={values.cropLabels}
                                            onChange={(v) => {
                                                setValues((oldValues) => ({
                                                    ...oldValues,
                                                    cropLabels: v,
                                                }));
                                                onChange({ ...values, cropLabels: v }, title);
                                            }}
                                            options={cropLabels.map((c) => ({
                                                label: `${c.label} (${c.count})`,
                                                value: c.label,
                                            }))}
                                            buttonText={'Choose Crop Labels'}
                                        />
                                    )}
                                    {cropLabels.length === 0 && (
                                        <Typography sx={{}} variant={'body2'}>
                                            No crops yet. Create some crops by uploading GPS data to
                                            an athlete
                                        </Typography>
                                    )}
                                </Stack>
                            )}
                            {section === 'displayOnSameGraph' && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(event) => {
                                                setValues((oldValues) => ({
                                                    ...oldValues,
                                                    showOnSameGraph: event.target.checked,
                                                }));
                                                onChange(
                                                    {
                                                        ...values,
                                                        showOnSameGraph: event.target.checked,
                                                    },
                                                    title,
                                                );
                                            }}
                                            checked={values.showOnSameGraph}
                                            size="small"
                                        />
                                    }
                                    label={'Display On Same Graph'}
                                />
                            )}
                            {section === 'yAxisKey' && (
                                <Stack spacing={6} direction={'column'}>
                                    <Typography variant={'body2'}>
                                        Value Displayed On Y Axis
                                    </Typography>
                                    <Select
                                        value={values.yAxisKey}
                                        onChange={(e) => {
                                            setValues((oldValues) => ({
                                                ...oldValues,
                                                yAxisKey: e.target
                                                    .value as ExplorationFiltersYaxisKeyType,
                                            }));
                                            onChange(
                                                {
                                                    ...values,
                                                    yAxisKey: e.target
                                                        .value as ExplorationFiltersYaxisKeyType,
                                                },
                                                title,
                                            );
                                        }}
                                    >
                                        <MenuItem value={'Velocity (mph)'}>Velocity (mph)</MenuItem>
                                        <MenuItem value={'Cumulative Distance (yd)'}>
                                            Distance (yd)
                                        </MenuItem>
                                    </Select>
                                </Stack>
                            )}
                            {section === 'timeGrouping' && (
                                <Stack spacing={6} direction={'column'}>
                                    <Typography variant={'body2'}>Time Comparison</Typography>
                                    <Select
                                        value={values.timeGrouping}
                                        onChange={(e) => {
                                            setValues((oldValues) => ({
                                                ...oldValues,
                                                timeGrouping: e.target
                                                    .value as TimeDimensionGranularity,
                                            }));
                                            onChange(
                                                {
                                                    ...values,
                                                    timeGrouping: e.target
                                                        .value as TimeDimensionGranularity,
                                                },
                                                title,
                                            );
                                        }}
                                    >
                                        <MenuItem value={'day'}>Day</MenuItem>
                                        <MenuItem value={'week'}>Week</MenuItem>
                                        <MenuItem value={'month'}>Month</MenuItem>
                                    </Select>
                                </Stack>
                            )}
                            {section === 'gpsSummaryMeasures' && (
                                <Stack spacing={6} direction={'column'}>
                                    <Typography variant={'body2'}>Measurement(s)</Typography>
                                    <FormControl>
                                        <Select
                                            multiple={true}
                                            value={values.measures}
                                            onChange={(e) => {
                                                setValues((oldValues) => ({
                                                    ...oldValues,
                                                    measures: e.target.value as MeasureKey[],
                                                }));
                                                onChange(
                                                    {
                                                        ...values,
                                                        measures: e.target.value as MeasureKey[],
                                                    },
                                                    title,
                                                );
                                            }}
                                        >
                                            <MenuItem value={'GpsSummary.avgSpeed'}>
                                                Average Max Speed (mph)
                                            </MenuItem>
                                            <MenuItem value={'GpsSummary.maxSpeed'}>
                                                Max Speed (mph)
                                            </MenuItem>
                                            <MenuItem value={'GpsSummary.numberOfSprints'}>
                                                Total Number Of Sprints
                                            </MenuItem>
                                            <MenuItem value={'GpsSummary.maxNumberOfSprints'}>
                                                Max Number Of Sprints In A Session
                                            </MenuItem>
                                            <MenuItem value={'GpsSummary.maxDistance'}>
                                                Max Distance In A Session
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                            )}
                            {section === 'gpsSummaryMeasure' && (
                                <Stack spacing={6} direction={'column'}>
                                    <Typography variant={'body2'}>Measurement</Typography>
                                    <Select
                                        value={values.measures}
                                        onChange={(e) => {
                                            setValues((oldValues) => ({
                                                ...oldValues,
                                                measures: [e.target.value as MeasureKey],
                                            }));
                                            onChange(
                                                {
                                                    ...values,
                                                    measures: [e.target.value as MeasureKey],
                                                },
                                                title,
                                            );
                                        }}
                                    >
                                        <MenuItem value={'GpsSummary.maxSpeed'}>
                                            Max Speed (mph)
                                        </MenuItem>
                                        <MenuItem value={'GpsSummary.numberOfSprints'}>
                                            Total Number Of Sprints
                                        </MenuItem>
                                        <MenuItem value={'GpsSummary.maxNumberOfSprints'}>
                                            Max Number Of Sprints
                                        </MenuItem>
                                        <MenuItem value={'GpsSummary.maxDistance'}>
                                            Max Distance
                                        </MenuItem>
                                    </Select>
                                </Stack>
                            )}
                        </>
                    ))}
                </Stack>
            </CardContent>
        </Card>
    );
};

export default ExplorationDetailsPanel;
