import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FullScreenDialogCard from '../components/HowToDialogCard';
import AdminsOrganizeMultipleOrgs from '../media/AdminsOrganizeMultipleOrgs.png';
import AdminDuplicateTrainingContent from '../media/AdminDuplicateTrainingContent.png';
import AdminCreateNewOrgs from '../media/AdminCreateNewOrgs.png';
import AdminTimeline from '../media/AdminTimeline.png';
import AdminCalendarView from '../media/AdminCalendarView.png';
import AdminTimelineFilter from '../media/AdminTimelineFilter.png';

function OneTulForAdmins() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL Administrators can easily manage multiple organizations, multiple
                        teams, create training and learning programs, and manage actionable data
                        from many sources including gps, 1080 motion, AI Video Motion Analysis,
                        strength training, physical therapy, and educational learning content.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            <b>Elite Performance</b> coaches can quickly and uniformly{' '}
                            <b>scale their consulting</b> reach or{' '}
                            <b>add additional franchisees </b>
                            by managing multiple organizations and teams from one interface.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={AdminsOrganizeMultipleOrgs}
                    title="Admins Manage Multiple Organizations"
                    headline="Admins Manage Multipe Organizations"
                    description="Admins can easily see all their teams amongst all their organizations."
                />

                <FullScreenDialogCard
                    image={AdminDuplicateTrainingContent}
                    title="Dupicate Training Content Amongst Organizations"
                    headline="Duplicate Training Content Amongst Organizations"
                    description="Keep uniformity amongst all organizations by duplicating the same training content amongst them."
                />
                <FullScreenDialogCard
                    image={AdminCreateNewOrgs}
                    title="Easily Create New Organizations of Franchisees"
                    headline="Easily Create New Organizations of Franchisees"
                    description="Quickly scale by adding new organizations or franchisees then accelerating their growth by duplicating your uniform content to the new organizations or franchisees."
                />

                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Admins can view all actions taken within their organization or
                            franchisees in their admin timeline.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={AdminTimeline}
                    title="Admin Timeline"
                    headline="Admin Timeline"
                    description="Admins can quickly view all actions taken within their organizations or franchisees."
                />
                <FullScreenDialogCard
                    imgPosition={'top'}
                    image={AdminTimelineFilter}
                    title="Timeline Filter"
                    headline="Timeline Filter"
                    description="Filter the timeline by activity type, athlete, group, organization, or content type."
                />
                <FullScreenDialogCard
                    imgPosition={'top'}
                    image={AdminCalendarView}
                    title="Admin Calendar View"
                    headline="Admin Calendar View"
                    description="Admin calendar view lets you quickly see who is assigned what and when."
                />
            </Grid>
        </>
    );
}

export default OneTulForAdmins;
