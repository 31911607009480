import React, { useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Typography,
    useTheme,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import { formatCurrency, stripMarkdown } from '../../../app.functions';
import { Organization } from '../../../utils';
import { grey } from '@mui/material/colors';
import { Link, useLocation } from 'react-router-dom';

interface MarketplaceCardProps {
    id: string;
    sellerUuid: string;
    title: string;
    description: string;
    image: string;
    price: number;
    avatarUrl: string;
    headerTitle: string;
    headerSubtitle?: string;
    loading: boolean;
    organizations: Organization[];
    onPurchase(organization: Organization, sellerUuid: string): void;
}

const MarketplaceCard: React.FC<MarketplaceCardProps> = ({
    id,
    sellerUuid,
    title,
    description,
    image,
    price,
    avatarUrl,
    headerTitle,
    headerSubtitle = '',
    loading,
    organizations,
    onPurchase,
}) => {
    const { pathname } = useLocation();
    const theme = useTheme();
    const [selectedOrganization, setSelectedOrganization] = useState<string>('');

    const handleOrganizationChange = (event: SelectChangeEvent) => {
        setSelectedOrganization(event.target.value);
    };

    return (
        <Card
            sx={{
                width: 300,
                height: 440,
                borderRadius: 3,
                background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                color: theme.palette.common.white,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                },
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <CardHeader
                avatar={
                    <Avatar variant={'square'} src={avatarUrl} aria-label="recipe">
                        {title.substr(0, 2)}
                    </Avatar>
                }
                title={headerTitle}
                subheader={headerSubtitle}
                sx={{ color: 'white' }}
            />
            {image ? (
                <CardMedia
                    component="div"
                    sx={{
                        height: 120,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                    image={image}
                />
            ) : (
                <Box
                    sx={{
                        backgroundColor: grey[100],
                        height: 120,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Typography sx={{ textAlign: 'center' }} variant={'h4'}>
                        -
                    </Typography>
                </Box>
            )}
            <CardContent sx={{ flexGrow: 1, position: 'relative', zIndex: 1, pt: 2 }}>
                <Typography gutterBottom variant="h6" component="h6">
                    {title}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    {description ? stripMarkdown(description) : '-'}
                </Typography>
            </CardContent>
            <CardActions sx={{ flexDirection: 'column', alignItems: 'stretch', padding: 2 }}>
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                    <InputLabel sx={{ color: 'white' }}>Select an Organization</InputLabel>
                    <Select
                        value={selectedOrganization}
                        onChange={handleOrganizationChange}
                        label="Select an Organization"
                        sx={{
                            color: 'white',
                            '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                            marginBottom: '6px',
                        }}
                    >
                        {organizations.map((org) => (
                            <MenuItem key={org.uuid} value={org.uuid}>
                                {org.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '8px',
                        margin: '4px',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            const org = organizations.find((o) => o.uuid === selectedOrganization);
                            if (org) onPurchase(org, sellerUuid);
                        }}
                        disabled={loading || !selectedOrganization}
                        sx={{
                            flex: 1,
                            padding: '10px',
                            borderRadius: 1,
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
                            },
                        }}
                    >
                        Purchase {formatCurrency({ amount: price / 100, currency: 'usd' })}
                    </Button>
                    <Link to={`${pathname}/${id}`} style={{ textDecoration: 'none', flex: 1 }}>
                        <Button
                            variant="outlined"
                            fullWidth
                            sx={{
                                color: 'white',
                                borderColor: 'white',
                                padding: '10px',
                                '&:hover': {
                                    borderColor: 'white',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                            }}
                        >
                            View Details
                        </Button>
                    </Link>
                </Box>
            </CardActions>
        </Card>
    );
};

export default MarketplaceCard;
