import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllForms } from 'api';

import { AxiosError } from 'axios';
import { keyBy } from 'lodash';
import { Form, PaginationLink } from 'utils';
import { RootState } from '.';

export interface AssessmentsError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

type PaginationByType = {
    [key: string]: PaginationLink;
};

type Data = {
    [key: string]: Form;
};

export interface AssessmentsState {
    isLoading: boolean;
    isLoaded: boolean;
    data: Data;
    matches: number;
    pagination?: PaginationLink | PaginationByType;
    error: AssessmentsError;
}

export const initialState: AssessmentsState = {
    isLoading: false,
    isLoaded: false,
    data: {},
    matches: 0,
    pagination: {
        first: null,
        last: null,
        prev: null,
        next: null,
    },
    error: { message: '' },
};

export const loadAssessments = createAsyncThunk<
    { assessments: Form[]; pagination: PaginationLink },
    { query: { [key: string]: any } },
    { rejectValue: AssessmentsError }
>('assessments/fetchAll', async ({ query }, { rejectWithValue }) => {
    try {
        const { data } = await getAllForms(query);
        return {
            pagination: data.links,
            assessments: data.data,
        };
    } catch (err: any) {
        const error: AxiosError<AssessmentsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Loading Assessments' });
    }
});

export const assessmentsSlice = createSlice({
    name: 'assessments',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadAssessments.fulfilled, (state, { payload }) => {
            state.data = keyBy(payload.assessments, 'uuid');
            state.pagination = initialState.pagination;
            state.error = { message: '' };
            state.isLoading = false;
            state.isLoaded = true;
        });
        addCase(loadAssessments.pending, (state) => {
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(loadAssessments.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Loading Assessments';
            state.isLoading = false;
        });
    },
});

export const getAssessmentsSelector = (state: RootState) => state.assessments;

export const getAllAssessments = createSelector([getAssessmentsSelector], (assessments) =>
    Object.values(assessments.data),
);
