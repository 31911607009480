import { Dialog, Slide } from '@mui/material';
import { CreateGroupForm } from 'components';
import React, { ReactElement } from 'react';
import { Group, GroupType } from 'utils';
import { TransitionProps } from '@mui/material/transitions';

export interface CreateGroupModalProps {
    onGroupCreated: (group: Group) => void;
    onGroupCreatedFailed: (message: string) => void;
    groupType: GroupType;
    open?: boolean;
    onClose?: Function;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateGroupModal({
    onGroupCreated,
    onGroupCreatedFailed,
    groupType = GroupType.GROUP,
    open = true,
    onClose,
}: CreateGroupModalProps): ReactElement {
    const handleGroupCreated = (group: Group) => {
        onGroupCreated(group);
    };
    const handleGroupCreationFailed = (message: string) => {
        onGroupCreatedFailed(message);
    };
    return (
        <Dialog open={open} TransitionComponent={Transition}>
            <CreateGroupForm
                onGroupCreationFailed={handleGroupCreationFailed}
                onGroupCreated={handleGroupCreated}
                groupType={groupType}
                onClose={onClose}
            />
        </Dialog>
    );
}
