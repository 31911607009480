import { AxiosResponse } from 'axios';
import { Request } from '../../api';
import { Media } from '../../app.types';

export interface UpdateMediaPayload {
    name?: string;
    description?: string | null;
}

export function updateMedia(id: string, data: UpdateMediaPayload): Promise<AxiosResponse<Media>> {
    return Request.put(`media/${id}`, data);
}

export function deleteMedia(id: string): Promise<AxiosResponse<void>> {
    return Request.delete(`media/${id}`);
}
