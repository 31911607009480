import React, { useState, useEffect } from 'react';
import {
    Dialog,
    Paper,
    List,
    ListItem,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    Typography,
    Tooltip,
} from '@mui/material';
import { Order, UpdateOrderParams } from '../orders.types';
import { updateOrder } from '../orders.api';
import { getOrderStatuses } from '../orders.api';
import { Divider } from '@mui/material';
import StripeLogo from '../../../../../../src/images/StripeLogo.svg';
import { Organization } from '../../../../../utils';
import { adminViewOrganization } from '../../../admin.organizations.api';
import PrintIcon from '@mui/icons-material/Print';
interface OrderDialogProps {
    open: boolean;
    order: Order | null;
    onClose: () => void;
    onSave: () => void;
}

const OrderDialog: React.FC<OrderDialogProps> = ({ open, order, onClose, onSave }) => {
    const [orderStatus, setOrderStatus] = useState<string>(order?.order_status || '');
    const [orderNotes, setOrderNotes] = useState<string>('');
    const [trackingNumber, setTrackingNumber] = useState<string>('');
    const [sellerOrg, setSellerOrg] = useState<Organization | null>(null);
    const [buyerOrg, setBuyerOrg] = useState<Organization | null>(null);
    const [orderStatuses, setOrderStatuses] = useState<string[]>([]);
    const [updateOrderModalOpen, setUpdateOrderModalOpen] = useState<boolean>(false); // State to control modal visibility
    const [updateOrderResult, setUpdateOrderResult] = useState<string>('');
    const listItemStyles = { marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0 };

    useEffect(() => {
        setSellerOrg(null);
        setBuyerOrg(null);

        const fetchSellerOrg = async (sellerOrgUuid: string) => {
            try {
                const { data } = await adminViewOrganization(sellerOrgUuid);
                setSellerOrg(data);
            } catch (e) {
                console.error('Error fetching organization details');
                setSellerOrg(null);
            }
        };

        const fetchBuyerOrg = async (buyerOrgUuid: string) => {
            try {
                const { data } = await adminViewOrganization(buyerOrgUuid);
                setBuyerOrg(data);
            } catch (e) {
                console.error('Error fetching organization details');
                setBuyerOrg(null);
            }
        };

        const fetchOrderStatuses = async () => {
            const response = await getOrderStatuses();
            setOrderStatuses(response.data);
        };

        if (order) {
            setOrderStatus(order.order_status);
            setOrderNotes(order.order_notes || '');
            setTrackingNumber(order.shipping_tracking_number || '');
            fetchSellerOrg(order.seller_org_uuid);

            if (order.buyer_org_uuid) {
                fetchBuyerOrg(order.buyer_org_uuid);
            }
        }

        fetchOrderStatuses();
    }, [order]);

    const renderBuyerLogo = () => {
        if (buyerOrg && buyerOrg.logo_url) {
            return (
                <div>
                    <p style={{ fontSize: '0.6rem' }}>buyer logo</p>
                    <Tooltip title="Right click to save buyer logo file">
                        <img
                            src={buyerOrg.logo_url}
                            alt="Buyer Logo"
                            style={{
                                width: '40px',
                                height: '40px',
                                objectFit: 'contain',
                                cursor: 'pointer',
                            }}
                        />
                    </Tooltip>
                </div>
            );
        }
        return null;
    };

    const handleSave = async () => {
        if (order) {
            const updateParams: UpdateOrderParams = {
                order_status: orderStatus,
                order_notes: orderNotes,
                shipping_tracking_number: trackingNumber,
            };
            try {
                await updateOrder(order.uuid, updateParams);
                setUpdateOrderResult('Update successful.');
            } catch (error) {
                setUpdateOrderResult('Update failed.');
            } finally {
                onSave();
                setUpdateOrderModalOpen(true);
            }
        }
    };

    const formatCurrency = (amount: number): string => {
        amount = amount / 100;
        return `$${amount.toFixed(2)}`;
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    <div style={{ position: 'absolute', top: '0', right: '0', margin: '8px' }}>
                        <Typography
                            variant="body2"
                            style={{ fontSize: '0.8rem', textAlign: 'right' }}
                        >
                            Created <strong>{order?.created_at.toLocaleString()}</strong>
                        </Typography>
                    </div>
                    Edit Order
                    {order && (
                        <>
                            <a
                                href={`https://dashboard.stripe.com/payments/${order.stripe_sale_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={StripeLogo}
                                    alt="Stripe"
                                    style={{ height: '20px' }}
                                    title="See order on Stripe dashboard"
                                />
                            </a>
                            <Grid container direction="row" alignItems="left" spacing={1}>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Total <strong>{formatCurrency(order.total)}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Items{' '}
                                        <strong>
                                            {formatCurrency(order.commissionable_total)}
                                        </strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Tax <strong>{formatCurrency(order.tax)}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Shipping <strong>{formatCurrency(order.shipping)}</strong>
                                    </Typography>
                                </Grid>
                                <Grid container style={{ marginLeft: '4px', marginTop: '8px' }}>
                                    <Grid item>
                                        <Typography variant="body2" style={{ fontSize: '0.8rem' }}>
                                            <>
                                                <span>
                                                    Seller <strong>{order?.seller_org_name}</strong>
                                                    {sellerOrg && sellerOrg.subscription_tier && (
                                                        <strong style={{ marginLeft: '4px' }}>
                                                            ({sellerOrg.subscription_tier.name})
                                                        </strong>
                                                    )}
                                                </span>
                                                <span style={{ fontSize: '0.7rem' }}>
                                                    &nbsp;
                                                    {sellerOrg &&
                                                        sellerOrg.creator &&
                                                        `${sellerOrg?.creator?.first_name} ${sellerOrg?.creator?.last_name} - ${sellerOrg?.creator?.email} ${sellerOrg?.creator?.phone_national}`}
                                                </span>
                                            </>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ marginLeft: '4px' }}>
                                    <Grid item>
                                        <Typography variant="body2" style={{ fontSize: '0.8rem' }}>
                                            <>
                                                <span>
                                                    Buyer{' '}
                                                    <strong>
                                                        {order?.buyer_org_name
                                                            ? order.buyer_org_name
                                                            : `${order.buyer_athlete?.first_name} ${order.buyer_athlete?.last_name}`}
                                                    </strong>
                                                    {buyerOrg && buyerOrg.subscription_tier && (
                                                        <strong style={{ marginLeft: '4px' }}>
                                                            ({buyerOrg.subscription_tier.name})
                                                        </strong>
                                                    )}
                                                </span>
                                                <span style={{ fontSize: '0.7rem' }}>
                                                    &nbsp;
                                                    {buyerOrg && buyerOrg.creator
                                                        ? `${buyerOrg?.creator?.first_name} ${buyerOrg?.creator?.last_name} - ${buyerOrg?.creator?.email} ${buyerOrg?.creator?.phone_national}`
                                                        : `${order.buyer_athlete?.email} ${order.buyer_athlete?.phone}`}
                                                </span>
                                            </>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {order && (
                        <>
                            {renderBuyerLogo()}
                            {order.order_status !== 'backfilled' ? (
                                <div>
                                    <Grid container spacing={3}>
                                        <Grid item xs={5}>
                                            <Paper
                                                elevation={1}
                                                sx={{ minHeight: 300, overflow: 'auto', p: 5 }}
                                            >
                                                <h4>Ship To:</h4>
                                                <div
                                                    style={{
                                                        fontSize: '0.8rem',
                                                    }}
                                                >
                                                    <span>
                                                        {order.buyer_org_name ??
                                                            `${order.buyer_athlete?.first_name} ${order.buyer_athlete?.last_name}`}
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: '0.8rem',
                                                    }}
                                                >
                                                    <span>
                                                        {order.shipping_address_1}{' '}
                                                        {order.shipping_address_2
                                                            ? `${order.shipping_address_2}, `
                                                            : ''}
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: '0.8rem',
                                                    }}
                                                >
                                                    <span>
                                                        {order.shipping_city},{' '}
                                                        {order.shipping_state} {order.shipping_zip}
                                                    </span>
                                                </div>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Paper
                                                elevation={1}
                                                sx={{
                                                    minHeight: 300,
                                                    overflow: 'auto',
                                                    p: 0,
                                                }}
                                            >
                                                <h4 style={{ marginBottom: 0, marginLeft: 5 }}>
                                                    Pre-Ship Checklist:{' '}
                                                </h4>

                                                <List
                                                    sx={{
                                                        marginBottom: 0,
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                    }}
                                                >
                                                    <ListItem sx={listItemStyles}>
                                                        ☐ Ready to Ship &nbsp;{'  '}
                                                        <em>
                                                            {'  '} (Initial Here {'-->'} ____)
                                                        </em>
                                                    </ListItem>
                                                    <ListItem sx={listItemStyles}>
                                                        ☐ Flashed - Org, Club ID, and Unit #
                                                    </ListItem>
                                                    <ListItem sx={listItemStyles}>
                                                        ☐ Final QA: LCD Centered, Flat and Enclosure
                                                        Smooth
                                                    </ListItem>

                                                    <ListItem sx={listItemStyles}>
                                                        <span style={{ fontWeight: 450 }}>
                                                            ☐ Non-Gold Buyer:{' '}
                                                        </span>
                                                        {'  '}
                                                        Stealth 1-Unit Charger (per Unit)
                                                    </ListItem>
                                                    <ListItem sx={listItemStyles}>
                                                        <span style={{ fontWeight: 450 }}>
                                                            ☐ Gold Buyer:
                                                        </span>{' '}
                                                        1 Unit ➞ Free 1-unit Charger
                                                    </ListItem>
                                                    <ListItem sx={listItemStyles}>
                                                        <span style={{ fontWeight: 450 }}>
                                                            ☐ Gold Buyer:
                                                        </span>{' '}
                                                        2-3 Units ➞ Free 3-unit Charger
                                                    </ListItem>
                                                    <ListItem sx={listItemStyles}>
                                                        <span style={{ fontWeight: 450 }}>
                                                            ☐ Gold Buyer:
                                                        </span>{' '}
                                                        5-8 Units ➞ Free GW + 8-unit Charger
                                                    </ListItem>
                                                    <ListItem sx={listItemStyles}>
                                                        ☐ Type-C cable and apporpriate power supply
                                                        for charger
                                                    </ListItem>
                                                    <ListItem sx={listItemStyles}>
                                                        ☐ + FiyrLink (if org purchase history totals
                                                        at least 5 pods
                                                    </ListItem>
                                                    <ListItem sx={listItemStyles}>
                                                        ☐ Include tripod for all FiyrLink orders
                                                    </ListItem>
                                                </List>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </div>
                            ) : (
                                <div>
                                    <h4>Backfilled order (no shipping information)</h4>
                                </div>
                            )}
                            <h4>Line Items</h4>

                            <Divider style={{ marginTop: '20px', backgroundColor: 'lightgray' }} />
                            {order.line_items.map((item, index) => (
                                <React.Fragment key={item.uuid}>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ marginTop: '10px', marginLeft: '20px' }}
                                    >
                                        <Grid item xs={12} style={{ position: 'relative' }}>
                                            <Typography
                                                variant="body2"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    color: '#111111',
                                                    position: 'absolute',
                                                    left: '-20px',
                                                    top: '0',
                                                }}
                                            >
                                                {index + 1}.
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                style={{ fontSize: '1.5rem', color: '#111111' }}
                                            >
                                                <strong>
                                                    {item.stripe_price_id.includes('coupon_')
                                                        ? 'Coupon'
                                                        : 'Product'}
                                                    :
                                                </strong>{' '}
                                                {item.description}
                                            </Typography>
                                        </Grid>
                                        {/* Repeat for other Grid items within this map function, omitting the numbering Typography for subsequent items */}
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                style={{ fontSize: '0.8rem', color: '#111111' }}
                                            >
                                                <strong>
                                                    {item.stripe_price_id.includes('coupon_')
                                                        ? 'Coupon ID'
                                                        : 'Price ID'}
                                                    :
                                                </strong>{' '}
                                                <a
                                                    href={
                                                        item.stripe_price_id.includes('coupon_')
                                                            ? `https://dashboard.stripe.com/coupons/${item.stripe_price_id.replace(
                                                                  'coupon_',
                                                                  '',
                                                              )}`
                                                            : `https://dashboard.stripe.com/prices/${item.stripe_price_id}`
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {item.stripe_price_id.includes('coupon_')
                                                        ? item.stripe_price_id.replace(
                                                              'coupon_',
                                                              '',
                                                          )
                                                        : item.stripe_price_id}
                                                </a>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                style={{ fontSize: '0.8rem', color: '#111111' }}
                                            >
                                                <strong>Unit Amount:</strong>{' '}
                                                {item.stripe_price_id.includes('coupon_')
                                                    ? `(${formatCurrency(item.unit_amount)})`
                                                    : formatCurrency(item.unit_amount)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h6"
                                                style={{ fontSize: '1.4rem', color: '#111111' }}
                                            >
                                                <strong>Quantity:</strong> {item.quantity}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                style={{ fontSize: '0.8rem', color: '#111111' }}
                                            >
                                                <strong>Subtotal:</strong>{' '}
                                                {item.stripe_price_id.includes('coupon_')
                                                    ? `(${formatCurrency(item.amount_subtotal)})`
                                                    : formatCurrency(item.amount_subtotal)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider
                                        style={{
                                            marginTop: '20px',
                                            backgroundColor: 'lightgray',
                                            marginBottom: '24px',
                                        }}
                                    />
                                </React.Fragment>
                            ))}
                            <TextField
                                label="Order Notes"
                                value={orderNotes}
                                fullWidth
                                margin="dense"
                                onChange={(e) => setOrderNotes(e.target.value)}
                                multiline
                                rows={2}
                                size="small"
                            />
                            <TextField
                                label="Tracking number"
                                value={trackingNumber}
                                margin="dense"
                                onChange={(e) => setTrackingNumber(e.target.value)}
                                size="small"
                                style={{ width: '35%' }}
                            />
                            <Grid container spacing={2} alignItems="flex-start">
                                <Grid item xs={12}>
                                    <FormControl fullWidth margin="dense">
                                        <InputLabel>Order Status</InputLabel>
                                        <Select
                                            value={orderStatus}
                                            label="Order Status"
                                            size="small"
                                            onChange={(e) =>
                                                setOrderStatus(e.target.value as string)
                                            }
                                            style={{ width: '35%' }}
                                        >
                                            {orderStatuses.map((status) => (
                                                <MenuItem key={status} value={status}>
                                                    {status}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button onClick={handlePrint} startIcon={<PrintIcon />}>
                        Print
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSave} variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={updateOrderModalOpen}
                onClose={() => setUpdateOrderModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>Update Order</DialogTitle>
                <Divider />
                <DialogContent style={{ minWidth: '320px' }}>
                    <p id="simple-modal-description" style={{ fontSize: '0.9rem' }}>
                        {updateOrderResult}
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => setUpdateOrderModalOpen(false)}
                        style={{ float: 'right' }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OrderDialog;
