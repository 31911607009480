import { Crop, RawDataPointEnhanced } from '../../community/community.types';
import { RawDataPointMetrics } from '../../../app.types';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {
    CartesianGrid,
    ComposedChart,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { formatNumber } from '../../community/community.func';
import React from 'react';
import CustomTooltipRawGpsData from '../../../components/Charting/CustomTooltipRawGpsData';
import { sampleRawDataPointEnhanced } from 'app.functions';
import { Exploration } from '../explore.types';
import { convertApiFiltersToExplorationFilters } from '../explore.func';

const ExplorationCropsListOfCharts = ({
    exploration,
    crops,
    cropData,
    cropAdditionalData,
}: {
    exploration: Exploration;
    crops: Crop[];
    cropData: { [key: string]: RawDataPointEnhanced[] | false };
    cropAdditionalData: { [key: string]: RawDataPointMetrics };
}) => {
    return (
        <>
            {crops.map((c) => (
                <>
                    <Typography>
                        {c.upload.individual.name} {c.upload.activity_date}
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>MaxV</TableCell>
                                    <TableCell>T To MaxV</TableCell>
                                    <TableCell>T MaxV-End</TableCell>
                                    <TableCell>D To MaxV</TableCell>
                                    <TableCell>D MaxV-End</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {cropAdditionalData[c.uuid]?.maxVelocity.toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        {cropAdditionalData[c.uuid]?.timeOfMaxVelocity.toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        {cropAdditionalData[
                                            c.uuid
                                        ]?.timeFromMaxVelocityToEnd.toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        {cropAdditionalData[
                                            c.uuid
                                        ]?.distanceFromStartToMaxVelocity.toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        {cropAdditionalData[
                                            c.uuid
                                        ]?.distanceFromMaxVelocityToEnd.toFixed(2)}
                                    </TableCell>
                                    <TableCell>Remove</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        key={c.uuid}
                        style={{
                            width: '100%',
                            height: 300,
                            backgroundColor: '#D9DADF',
                            borderRadius: 20,
                            border: '0px solid #A7ABB7',
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                            padding: 20,
                        }}
                    >
                        <ResponsiveContainer width="100%" height="100%">
                            <ComposedChart
                                margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip
                                    content={(props) => <CustomTooltipRawGpsData {...props} />}
                                />
                                <YAxis
                                    yAxisId="left"
                                    label={{
                                        value: convertApiFiltersToExplorationFilters(
                                            exploration.filters,
                                        ).yAxisKey,
                                        angle: -90,
                                        position: 'insideBottomLeft',
                                        offset: 20,
                                    }}
                                    domain={[0, 'dataMax']}
                                    tickFormatter={formatNumber}
                                    style={{
                                        fontSize: '0.75rem',
                                    }}
                                />
                                <YAxis
                                    yAxisId="heartRate"
                                    label={{
                                        value: 'Heart Rate (bpm)',
                                        angle: 90,
                                        position: 'insideBottomRight',
                                        align: 'center',
                                        style: {
                                            whiteSpace: 'nowrap', // Prevents text from wrapping
                                        },
                                    }}
                                    orientation="right"
                                    domain={['dataMin - 10', 'dataMax']}
                                    style={{
                                        fontSize: '0.50rem',
                                    }}
                                />
                                <XAxis
                                    type={'number'}
                                    dataKey="Duration (s)"
                                    scale={'sequential'}
                                    label={{
                                        value: 'Duration (s)',
                                        position: 'insideBottom',
                                    }}
                                    angle={-30}
                                    textAnchor="end"
                                    height={50}
                                    style={{
                                        fontSize: '0.50rem',
                                    }}
                                />
                                <Scatter
                                    data={Object.values(cropData[c.uuid] || [])}
                                    yAxisId={'left'}
                                    dataKey={
                                        convertApiFiltersToExplorationFilters(exploration.filters)
                                            .yAxisKey
                                    }
                                    fill="#25C3F1"
                                />
                                <Scatter
                                    yAxisId={'heartRate'}
                                    dataKey="Heart Rate (bpm)"
                                    fill={'#FF0000'}
                                    data={sampleRawDataPointEnhanced(
                                        Object.values(cropData[c.uuid] || []),
                                    )}
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Box>
                </>
            ))}
        </>
    );
};

export default ExplorationCropsListOfCharts;
