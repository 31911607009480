import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { FormControl, Select } from '@mui/material';
import React, { ReactElement } from 'react';
import { Role, yupSchemas } from 'utils';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { FormikOnChange } from '.';

export interface CreateLinkFormProps {
    /** onSubmit Submit the form */
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void;
    /** onChange Values when the form is submitted */
    onChange: (isValid: boolean, dirty: boolean, values: FormikValues) => void;
    /** ref The reference to the form */
    formRef: React.Ref<FormikProps<FormikValues>>;
    /** roles */
    roles: Array<Role>;
}

export default function CreateLinkForm({
    onSubmit,
    formRef,
    onChange,
    roles,
}: CreateLinkFormProps): ReactElement {
    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                code: '',
                role: roles.filter((r) => !r.name.includes('admin'))[0].name,
            }}
            validationSchema={yupSchemas.createLink}
            onSubmit={(values, formikHelpers) => {
                onSubmit(values, formikHelpers);
            }}
        >
            {({ values, handleChange, errors }) => {
                return (
                    <Form style={{ width: '100%' }}>
                        <FormikOnChange onChange={onChange} />
                        <div>
                            Create publicly accessable links to share with players, coaches and
                            trainers to access your content.
                        </div>
                        <FormControl>
                            <Select
                                value={values.role}
                                onChange={handleChange}
                                error={Boolean(errors.role)}
                                name="role"
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role.name} value={role.name}>
                                        {role.display_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            id="code"
                            label="Access Code (optional)"
                            type="code"
                            placeholder="JUNE2020"
                            fullWidth
                            error={Boolean(errors.code)}
                            value={values.code}
                            onChange={handleChange}
                            name="code"
                            helperText={
                                (errors.code as string) ??
                                'Choose a secret code for your players, coaches and trainers to access your content.'
                            }
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}
