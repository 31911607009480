import React from 'react';

interface VimeoEmbedProps {
    videoUrl: string;
}

const VimeoEmbed: React.FC<React.PropsWithChildren<VimeoEmbedProps>> = ({ videoUrl }) => {
    return (
        <div style={{ padding: '85.88% 0 0 0', position: 'relative' }}>
            <iframe
                src={videoUrl}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                }}
                title="Vimeo Video"
            ></iframe>
        </div>
    );
};

export default VimeoEmbed;
