import { Box, Grid, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { maxBy, minBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { GpsDataPoint, Motion } from '../community.types';
import ChartGradientPaper from '../components/Charting/ChartGradientPaperContainer';

interface Props {
    motion: Motion;
}

function MotionDataCard({ title, value }: { title: string; value: string }) {
    return (
        <Paper sx={{ p: 4, m: 6 }}>
            <Typography sx={{ color: grey[700] }}>{title}</Typography>
            <Typography variant={'h5'}>{value}</Typography>
        </Paper>
    );
}

function ViewTemMotion({ motion }: Props) {
    const [data, setData] = useState<GpsDataPoint[]>([]);
    useEffect(() => {
        if (!motion) return;
        setData(
            motion.raw_data.map((d) => ({
                ...d,
                position: +(d.position * 3.28084).toFixed(3),
                speed: +Math.abs(d.speed * 2.23694).toFixed(3),
                time: +d.time.toFixed(3),
                power: +Math.abs(d.power).toFixed(3),
            })),
        );
    }, [motion]);
    return (
        <Box>
            <ChartGradientPaper>
                <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart
                        data={data}
                        margin={{
                            top: 5,
                            right: 10,
                            left: 0,
                            bottom: 20,
                        }}
                    >
                        <defs>
                            <filter id="drop-shadow" x="-50%" y="-50%" width="150%" height="150%">
                                <feOffset result="offOut" in="SourceAlpha" dx="1" dy="1" />
                                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
                                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                            </filter>
                        </defs>

                        <Tooltip
                            payload={[]}
                            contentStyle={{
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                border: '1px solid #a8bfd0',
                                borderRadius: 15,
                            }}
                        />
                        <CartesianGrid strokeDasharray="3 3" />

                        <Line
                            yAxisId={'power'}
                            name="Power (w)"
                            dataKey="power"
                            dot={false}
                            stroke="#886b3a"
                            strokeWidth={1.4}
                            filter="url(#drop-shadow)"
                        />
                        <Line
                            dot={false}
                            yAxisId={'power'}
                            name="Force (n)"
                            dataKey="force"
                            stroke="#b04b9d"
                            strokeWidth={1.4}
                            filter="url(#drop-shadow)"
                        />
                        <Bar
                            yAxisId={'distance'}
                            name="Distance (ft)"
                            type="monotone"
                            dataKey="position"
                            fill="#7794C5"
                            barSize={0.2}
                        />
                        <Line
                            dot={false}
                            stroke="#02d3fe"
                            yAxisId={'speed'}
                            name="Speed (mph)"
                            dataKey="speed"
                            strokeWidth={2}
                            filter="url(#drop-shadow)"
                        />
                        <YAxis
                            yAxisId={'distance'}
                            type={'number'}
                            allowDataOverflow={true}
                            label={{
                                value: 'Distance (ft)',
                                angle: -90,
                                position: 'right',
                                textColor: '#A7ABB7',
                                fontSize: 12,
                            }}
                        />
                        <YAxis
                            yAxisId={'speed'}
                            type={'number'}
                            allowDataOverflow={true}
                            label={{
                                value: 'Speed (MPH)',
                                angle: -90,
                                position: 'insideLeft',
                                textColor: '#A7ABB7',
                                fontSize: 12,
                            }}
                        />
                        <YAxis
                            orientation={'right'}
                            yAxisId={'power'}
                            type={'number'}
                            allowDataOverflow={true}
                            label={{
                                value: 'Power (W) / Force (N)',
                                angle: -90,
                                position: 'insideTopRight',
                                textColor: '#A7ABB7',
                                fontSize: 12,
                                offset: 10,
                            }}
                        />
                        <XAxis
                            name="Time (s)"
                            dataKey="time"
                            label={{
                                value: 'Time (s)',
                                angle: 0,
                                position: 'bottom',
                                textColor: '#A7ABB7',
                                fontSize: 14,
                            }}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            </ChartGradientPaper>
            <Box sx={{ marginTop: 5 }}>
                <ChartGradientPaper>
                    <Typography sx={{ p: 4 }} variant={'h6'}>
                        Summary Data
                    </Typography>
                    <Grid container spacing={5}>
                        <Grid item xs={12} md={3}>
                            <MotionDataCard
                                title={'Exercise'}
                                value={`${motion.exercise_name} / ${motion.exercise_arch_type_name}`}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MotionDataCard title={'Side'} value={`${motion.side}`} />
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ padding: 4 }}>
                            {motion.exercise_name === 'Running' && (
                                <MotionDataCard
                                    title={'Load'}
                                    value={`${
                                        motion.raw_data[0]?.speed < 0
                                            ? -1 * motion.ecc_mass
                                            : motion.ecc_mass
                                    }kg`}
                                />
                            )}
                            {motion.exercise_name !== 'Running' && (
                                <>
                                    {motion.is_eccentric && (
                                        <MotionDataCard
                                            title={'Eccentric Load'}
                                            value={`${motion.ecc_mass}kg`}
                                        />
                                    )}
                                    {!motion.is_eccentric && (
                                        <MotionDataCard
                                            title={'Concentric Load'}
                                            value={`${motion.con_mass}kg`}
                                        />
                                    )}
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MotionDataCard
                                title={'Speed (peak / avg)'}
                                value={`${(motion.peak_speed * 2.23694).toFixed(2)} / ${(
                                    motion.avg_speed * 2.23694
                                ).toFixed(2)}`}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MotionDataCard
                                title={'Acceleration (peak / avg)'}
                                value={`${(motion.peak_acceleration * 3.28).toFixed(2)} / ${(
                                    motion.avg_acceleration * 3.28
                                ).toFixed(2)}`}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MotionDataCard
                                title={'Power W (peak / avg)'}
                                value={`${motion.peak_power.toFixed(
                                    2,
                                )} / ${motion.avg_power.toFixed(2)}`}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MotionDataCard
                                title={'Force N (peak / avg)'}
                                value={`${motion.peak_force.toFixed(
                                    1,
                                )} / ${motion.avg_force.toFixed(1)}`}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MotionDataCard title={'Work'} value={`${motion.work.toFixed(1)} kJ`} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MotionDataCard
                                title={'Distance'}
                                value={`${(
                                    ((maxBy(motion.raw_data, (p) => p.position)?.position ?? 0) -
                                        (minBy(motion.raw_data, (p) => p.position)?.position ??
                                            0)) *
                                    3.28084
                                ).toFixed(1)} ft`}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <MotionDataCard
                                title={'Duration'}
                                value={`${maxBy(motion.raw_data, (p) => p.time)?.time.toFixed(2)}s`}
                            />
                        </Grid>
                    </Grid>
                </ChartGradientPaper>
            </Box>
        </Box>
    );
}

export default ViewTemMotion;
