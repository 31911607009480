import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import {
    getCurrentUser,
    getCurrentUserPermissions,
    getUserNotificationCounts,
    updateUserProfile,
} from 'api';
import getAthleteSubscriptions from 'api/Auth/getAthleteSubscriptions';
import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import {
    EntityPermission,
    Fields,
    OrganizationActions,
    ShareableClassification,
    UserData,
} from 'utils';
import { RootState } from '.';
import getAthletePods from '../../api/Auth/getAthletePods';
import getChatToken from '../../api/Auth/getChatToken';
import resendVerificationEmail from '../../api/Auth/resendVerificationEmail';
import { AthletePod, AthleteSubscription } from '../../app.types';
import { AuthError } from './auth';

export interface UserError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export interface UserState {
    error?: UserError;
    isLoading: boolean;
    isLoaded: boolean;
    userData?: UserData;
    userPermissions?: Array<EntityPermission>;
    subscriptions: Array<AthleteSubscription>;
    subscriptionsLoaded: boolean;
    pods: Array<AthletePod>;
    podsLoaded: boolean;
    notificationCounts: { unattested_count: number } | null;
    notificationCountsLastLoaded: number | null;
    chatToken: string;
}

export const initialState: UserState = {
    error: undefined,
    isLoading: false,
    isLoaded: false,
    userData: undefined,
    subscriptions: [],
    subscriptionsLoaded: false,
    pods: [],
    podsLoaded: false,
    notificationCounts: null,
    notificationCountsLastLoaded: null,
    chatToken: '',
};

export const getUserData = createAsyncThunk<
    UserData,
    undefined,
    {
        rejectValue: UserError;
    }
>('user/getUserData', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getCurrentUser();
        return data;
    } catch (err: any) {
        const error: AxiosError<UserError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const getUserSubscriptions = createAsyncThunk<
    Array<AthleteSubscription>,
    undefined,
    {
        rejectValue: UserError;
    }
>('user/getUserSubscriptions', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getAthleteSubscriptions();
        return data;
    } catch (err: any) {
        const error: AxiosError<UserError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const getUserPods = createAsyncThunk<
    Array<AthletePod>,
    undefined,
    {
        rejectValue: UserError;
    }
>('user/getUserPods', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getAthletePods();
        console.debug('getAthletePods data', data);
        return data;
    } catch (err: any) {
        const error: AxiosError<UserError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const updateUserData = createAsyncThunk<
    UserData,
    {
        userUuid: string | null;
        first_name: string | null;
        last_name: string | null;
        email: string | null;
        phone: string | null;
        profile_photo: string | null;
        fields?: Fields;
    },
    {
        rejectValue: UserError;
    }
>(
    'user/updateUserProfile',
    async (
        { userUuid, first_name, last_name, email, phone, profile_photo, fields },
        { rejectWithValue },
    ) => {
        try {
            const { data } = await updateUserProfile(
                userUuid,
                first_name,
                last_name,
                email,
                phone,
                profile_photo,
                fields,
            );
            return data;
        } catch (err: any) {
            const error: AxiosError<UserError> = err;
            return rejectWithValue(error.response?.data ?? { message: 'Error' });
        }
    },
);

export const resendEmailVerification = createAsyncThunk<
    {},
    {},
    {
        rejectValue: UserError;
    }
>('user/resendVerificationEmail', async (_, { rejectWithValue }) => {
    try {
        return await resendVerificationEmail();
    } catch (err: any) {
        const error: AxiosError<UserError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const getUserPermissions = createAsyncThunk<
    Array<EntityPermission>,
    { id: string },
    {
        rejectValue: UserError;
    }
>('user/getUserPermissions', async ({ id }, { rejectWithValue }) => {
    try {
        const { data } = await getCurrentUserPermissions(id);
        return data;
    } catch (err: any) {
        const error: AxiosError<UserError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const getNotificationCounts = createAsyncThunk<
    { unattested_count: number },
    undefined,
    {
        rejectValue: UserError;
    }
>('user/getUserNotificationCounts', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getUserNotificationCounts();
        return data;
    } catch (err: any) {
        const error: AxiosError<UserError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const fetchChatToken = createAsyncThunk<
    string,
    void,
    { state: RootState; rejectValue: AuthError }
>('auth/fetchChatToken', async (_, { getState, rejectWithValue }) => {
    try {
        const userId = getState().auth.currentUser?.accessToken; // Assuming accessToken can be used to identify the user
        if (!userId) {
            throw new Error('No access token available');
        }
        const response = await getChatToken();
        return response.data.data;
    } catch (err: any) {
        const error: AxiosError<AuthError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Failed to fetch chat token' });
    }
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserError: (state) => {
            state.error = undefined;
            state.isLoading = false;
        },
        clearUserData: (state) => {
            state.userData = undefined;
            state.isLoaded = false;
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(getUserData.fulfilled, (state, { payload }) => {
            state.userData = payload;
            state.isLoaded = true;
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(getUserData.pending, (state) => {
            state.userData = undefined;
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(updateUserData.fulfilled, (state, { payload }) => {
            state.userData = payload;
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(getUserData.rejected, (state, { payload }) => {
            state.userData = undefined;
            state.error = payload;
            state.isLoading = false;
        });
        addCase(getUserPermissions.fulfilled, (state, { payload }) => {
            state.userPermissions = payload;
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(getUserPermissions.pending, (state) => {
            state.isLoading = true;
        });
        addCase(getUserPermissions.rejected, (state, { payload }) => {
            state.userPermissions = undefined;
            state.error = payload;
            state.isLoading = false;
        });
        addCase(getNotificationCounts.fulfilled, (state, { payload }) => {
            state.notificationCounts = payload;
            state.notificationCountsLastLoaded = DateTime.now().toMillis();
        });
        addCase(getUserSubscriptions.fulfilled, (state, { payload }) => {
            state.subscriptions = payload;
            state.subscriptionsLoaded = true;
        });
        addCase(fetchChatToken.fulfilled, (state, { payload }) => {
            state.chatToken = payload;
        });
    },
});

export const { clearUserError } = userSlice.actions;

export const userSelector = (state: RootState): UserState => state.user;

export const userSubscriptionsSelector = createSelector(
    userSelector,
    (user: UserState): Array<AthleteSubscription> => user.subscriptions,
);

export const userSubscriptionsIsLoadedSelector = createSelector(
    userSelector,
    (user: UserState): boolean => user.subscriptionsLoaded,
);

export const userPodsSelector = createSelector(
    userSelector,
    (user: UserState): Array<AthletePod> => user.pods,
);

export const userPodsIsLoadedSelector = createSelector(
    userSelector,
    (user: UserState): boolean => user.podsLoaded,
);

export const userProfilePhotoSelector = createSelector(
    userSelector,
    (user: UserState): string | null | undefined => {
        return user.userData?.profile_photo;
    },
);

export const userIsLoadedSelector = createSelector(
    userSelector,
    (user: UserState): boolean => user.isLoaded,
);

export const userChatToken = createSelector(userSelector, (user: UserState) => user.chatToken);

export const userOriginalProfilePhotoSelector = createSelector(userSelector, (user: UserState) => {
    return user.userData?.profile_photo;
});

export const getMyPermissions = createSelector(userSelector, (user) => user.userPermissions);
export const selectNotificationCounts = createSelector(
    userSelector,
    (user) => user.notificationCounts,
);
export const selectNotificationsLastLoaded = createSelector(
    userSelector,
    (user) => user.notificationCountsLastLoaded,
);

export const getPermissionsWithClassification = (classification: ShareableClassification) =>
    createSelector(getMyPermissions, (permissions) =>
        permissions?.filter((p) => p.team.classification === classification),
    );
export const getPermissionsWithAction = (
    action: OrganizationActions,
    classification: ShareableClassification,
) =>
    createSelector(getPermissionsWithClassification(classification), (permissions) =>
        permissions?.filter((permission) =>
            permission.permissions.some((permission) => permission.name === action),
        ),
    );

export const getOrganizationsForMovementCreation = createSelector(
    getPermissionsWithAction('organization:create-movement', 'organization'),
    (permissions) => permissions?.map((permission) => permission.team),
);
export const getOrganizationsForSessionCreation = createSelector(
    getPermissionsWithAction('organization:create-session', 'organization'),
    (permission) => permission?.map((permission) => permission.team),
);
export const getOrganizationsForProgramCreation = createSelector(
    getPermissionsWithAction('organization:create-program', 'organization'),
    (permission) => permission?.map((permission) => permission.team),
);
export const getOrganizationsForFormCreation = createSelector(
    getPermissionsWithAction('organization:create-form', 'organization'),
    (permission) => permission?.map((permission) => permission.team),
);

export const getAssociatedOrganizations = createSelector(
    getPermissionsWithClassification('organization'),
    (permissions) => permissions?.map((p) => p.team),
);
export const getOrganizationsWitOrgCoachRole = createSelector(getMyPermissions, (permissions) =>
    permissions
        ?.filter(
            (p) =>
                p.team.classification === 'organization' &&
                p.roles.some((role) => role.name === 'organization-coach'),
        )
        .map((p) => p.team),
);
export const getOrganizationsWitOrgContentCreatorRole = createSelector(
    getMyPermissions,
    (permissions) =>
        permissions
            ?.filter(
                (p) =>
                    p.team.classification === 'organization' &&
                    p.roles.some((role) => role.name === 'organization-content-manager'),
            )
            .map((p) => p.team),
);
export const getOrganizationsWithOrgAdminRole = createSelector(getMyPermissions, (permissions) =>
    permissions
        ?.filter(
            (p) =>
                p.team.classification === 'organization' &&
                p.roles.some((role) => role.name === 'organization-admin'),
        )
        .map((p) => p.team),
);
export const getOrganizationsWithAdminOrCoachRoles = createSelector(
    getMyPermissions,
    (permissions) =>
        permissions
            ?.filter(
                (p) =>
                    p.team.classification === 'organization' &&
                    p.roles.some((role) =>
                        ['organization-coach', 'organization-admin'].includes(role.name),
                    ),
            )
            .map((p) => p.team),
);
