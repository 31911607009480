import { yupSchemas } from 'utils';
import ROUTES from 'Routes/routes';
import { restorePassword } from 'api';
import { useHistory } from 'react-router';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordAlert from 'components/Alerts/PasswordAlert';
import PasswordStrenghIndicator from 'components/Indicators/PasswordStrenghIndicator';
import ButtonWithLoader from 'components/Buttons/ButtonWithLoader';
import SnackBar from 'components/Indicators/SnackBar';
import useTheme from '@mui/material/styles/useTheme';

const RestorePasswordForm = () => {
    const history = useHistory();
    const { push } = useHistory();
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [notifyUserMessage, setNotifyUserMessage] = useState('');
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);

    useEffect(() => {
        if (history) {
            const location = history.location;
            const resetToken = new URLSearchParams(location.search).get('token') ?? '';
            const userEmail = new URLSearchParams(location.search).get('email') ?? '';
            setEmail(userEmail);
            setToken(resetToken);
        }
    }, [history, email, token]);

    const handleSubmit = async (values: { password: string; confirmPassword: string }) => {
        const { password, confirmPassword } = values;
        setIsLoading(true);
        try {
            const response = await restorePassword(email, password, confirmPassword, token);
            if (response?.request?.status === 204) {
                setSuccess(true);
                setNotifyUserMessage('Password Changed Successfully');
            } else {
                setSuccess(false);
                setNotifyUserMessage('Some error occured. Try again later');
            }
        } catch (err: any) {
            setSuccess(false);
            setNotifyUserMessage(err.message);
        } finally {
            setIsLoading(false);
        }
    };
    const theme = useTheme();

    return (
        <Formik
            initialValues={yupSchemas.passwordAndConfirmPassword.getDefault()}
            validationSchema={yupSchemas.passwordAndConfirmPassword}
            onSubmit={handleSubmit}
        >
            {({ values }) => (
                <Form
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <PasswordAlert password={values.password} />
                    <Field
                        sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        component={TextField}
                        disabled={isLoading}
                        name="password"
                        type={passwordVisibility ? 'text' : 'password'}
                        label="Password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setPasswordVisibility(!passwordVisibility)}
                                        onMouseDown={() =>
                                            setPasswordVisibility(!passwordVisibility)
                                        }
                                        size="large"
                                    >
                                        {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <PasswordStrenghIndicator password={values.password} />
                    <Field
                        sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        component={TextField}
                        disabled={isLoading}
                        name="confirmPassword"
                        type={confirmPasswordVisibility ? 'text' : 'password'}
                        label="Confirm Password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            setConfirmPasswordVisibility(!confirmPasswordVisibility)
                                        }
                                        onMouseDown={() =>
                                            setConfirmPasswordVisibility(!confirmPasswordVisibility)
                                        }
                                        size="large"
                                    >
                                        {confirmPasswordVisibility ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <ButtonWithLoader
                        type="submit"
                        sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        Reset Password
                    </ButtonWithLoader>
                    <SnackBar
                        open={notifyUserMessage ? true : false}
                        message={notifyUserMessage}
                        setOpen={() => {
                            setNotifyUserMessage('');
                            push(ROUTES.Login.path);
                        }}
                        severity={success ? 'success' : 'error'}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default RestorePasswordForm;
