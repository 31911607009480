import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Organization } from 'utils';

type ReturnType = Promise<AxiosResponse<Organization>>;

const viewOrganization = (id: string): ReturnType => {
    return Request.get(`/organizations/${id}`);
};

export default viewOrganization;
