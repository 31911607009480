import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { SearchToken } from 'utils';

type ReturnType = Promise<AxiosResponse<SearchToken>>;

const getSearchToken = (): ReturnType => {
    return Request.get(`/search/key`);
};

export default getSearchToken;
