import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransferStats } from 'evaporate';
import { AppDispatch } from 'redux/store';
import { EntityType, PartialUploadList } from 'utils';
import S3Upload from 'utils/S3Upload';
import { RootState } from '.';
import { EncodingOptionsPayload, Media } from '../../app.types';
import { attachAsset, notifyUploadComplete, notifyUploadStarted } from './movements';

export interface MediaError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export interface MediaState {
    media: Media[];
    uploads: PartialUploadList;
    uploadsClosed: boolean;
    error: MediaError;
}

export const initialState: MediaState = {
    media: [],
    uploads: {},
    uploadsClosed: false,
    error: { message: '' },
};

export const closeUploads = createAction('media/closeUploads');

const uploadProgress = createAction<{
    fileName: string;
    originalFileName: string;
    progress: number;
    stats: TransferStats;
    entityType: EntityType;
    entityId: string;
}>('media/uploadProgress');

export const uploadEncodingOptionsSelected = createAction<{
    fileName: string;
    encodingOptions: EncodingOptionsPayload;
}>('media/uploadEncodingOptionsSelected');

export const uploadComplete = createAction<{
    fileName: string;
    awsObjectKey: string;
    entityType: EntityType;
    entityId: string;
}>('media/uploadComplete');
export const uploadCancelled = createAction<{ fileName: string }>('media/uploadCancelled');
export const uploadError = createAction<{ fileName: string; msg: string }>('media/uploadError');
export const uploadStarted = createAction<{ fileName: string }>('media/uploadStarted');
export const uploadRemoved = createAction<{ fileName: string }>('media/uploadRemoved');

export function uploadFile(
    accessToken: string,
    file: File,
    entityType: EntityType,
    entityId: string,
) {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        dispatch(notifyUploadStarted({ id: entityId }));
        uploadNewFile(dispatch, accessToken, file, entityType, entityId)
            .then((response) => {
                dispatch(notifyUploadComplete({ id: entityId }));
                return dispatch(
                    uploadComplete({
                        fileName: response.fileName,
                        awsObjectKey: response.awsObjectKey,
                        entityId: response.entityId,
                        entityType: response.entityType,
                    }),
                );
            })
            .then((uploadComplete) => {
                if (getState().movements.ids.findIndex((id) => id === entityId) > -1) {
                    dispatch(
                        attachAsset({
                            path: uploadComplete.payload.fileName,
                            movementUuid: entityId,
                        }),
                    ).then(() =>
                        dispatch(
                            uploadRemoved({
                                fileName: uploadComplete.payload.fileName,
                            }),
                        ),
                    );
                }
            })
            .catch((error) => {
                dispatch(notifyUploadComplete({ id: entityId }));
                dispatch(
                    uploadError({
                        msg: error.msg,
                        fileName: error.fileName,
                    }),
                );
            });
    };
}

export function uploadNewFile(
    dispatch: AppDispatch,
    accessToken: string,
    file: File,
    entityType: EntityType,
    entityId: string,
): Promise<{ fileName: string; awsObjectKey: string; entityType: EntityType; entityId: string }> {
    return new Promise((resolve, reject) => {
        const uploader = new S3Upload();

        uploader.onStarted = (fileName) => {
            dispatch(uploadStarted({ fileName }));
        };

        uploader.onBeforeSigner = (fileName, xhr) => {
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
        };

        uploader.onProgress = (fileName, originalFileName, progress, stats) => {
            dispatch(
                uploadProgress({
                    fileName,
                    originalFileName,
                    progress,
                    stats,
                    entityType,
                    entityId,
                }),
            );
        };

        uploader.onCancelled = (fileName) => {
            dispatch(uploadCancelled({ fileName }));
        };

        uploader.onComplete = (fileName, awsObjectKey) => {
            resolve({ fileName, awsObjectKey, entityType, entityId });
        };

        uploader.onError = (fileName, msg) => {
            dispatch(uploadError({ fileName, msg }));
            reject({ msg, fileName });
        };

        uploader.uploadToS3(file);
    });
}

export const mediaSlice = createSlice({
    name: 'media',
    initialState,
    reducers: {
        closeUploads: (state) => {
            state.uploadsClosed = true;
        },
        addMedia: (state, action: PayloadAction<Media>) => {
            state.media.push(action.payload);
        },
        removeMedia: (state, action: PayloadAction<Media>) => {
            state.media = state.media.filter((m) => m.uuid !== action.payload.uuid);
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(uploadProgress, (state, action) => {
            return {
                ...state,
                uploads: {
                    ...state.uploads,
                    [action.payload.fileName]: {
                        ...state.uploads[action.payload.fileName],
                        progress: action.payload.progress,
                        originalFileName: action.payload.originalFileName,
                        name: action.payload.fileName,
                        stats: action.payload.stats,
                        entityId: action.payload.entityId,
                        entityType: action.payload.entityType,
                    },
                },
            };
        });
        addCase(uploadStarted, (state) => {
            return {
                ...state,
                uploadsClosed: false,
            };
        });
        addCase(uploadEncodingOptionsSelected, (state, action) => ({
            ...state,
            uploads: {
                ...state.uploads,
                [action.payload.fileName]: {
                    ...state.uploads[action.payload.fileName],
                    encodingOptions: action.payload.encodingOptions,
                },
            },
        }));
        addCase(uploadRemoved, (state, action) => {
            // eslint-disable-next-line  @typescript-eslint/no-unused-vars
            const { [action.payload.fileName]: value, ...uploads } = state.uploads;
            return {
                ...state,
                uploads,
            };
        });
    },
});

export const mediaSelector = (state: RootState) => state.media;
export const { addMedia, removeMedia } = mediaSlice.actions;
export const getPartialUploadList = createSelector(mediaSelector, (media) => media.uploads);
export const getMedia = createSelector(mediaSelector, (media) => media.media);
export const getUploadsClosed = createSelector(mediaSelector, (media) => media.uploadsClosed);
