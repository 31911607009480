import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<void>>;

const revokeInvite = (params: { individualId: string; inviteId: string }): ReturnType => {
    return Request.post(`/individuals/${params.individualId}/invites/${params.inviteId}/revoke`);
};

export default revokeInvite;
