import React, { useState } from 'react';
import {
    Typography,
    Box,
    TextField,
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox,
    Button,
    Paper,
    Divider,
    Grid,
    Alert,
    Snackbar,
} from '@mui/material';
import { Email } from '@mui/icons-material';

interface FormData {
    name: string;
    organizationName: string;
    email: string;
    phone: string;
    dateReturn: string;
    numPods: string;
    podSerials: string[];
    returnReason: string;
    returnReasonOther: string;
    condition: string;
    damageDescription: string;
    trackingNumber: string;
    shippingCarrier: string;
    dateShipped: string;
    acknowledgments: {
        cleaned: boolean;
        accessories: boolean;
        packaging: boolean;
        photos: boolean;
    };
    signature: string;
}

const FiyrPodReturnForm = () => {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        organizationName: '',
        email: '',
        phone: '',
        dateReturn: '',
        numPods: '',
        podSerials: ['', '', '', ''],
        returnReason: '',
        returnReasonOther: '',
        condition: '',
        damageDescription: '',
        trackingNumber: '',
        shippingCarrier: '',
        dateShipped: '',
        acknowledgments: {
            cleaned: false,
            accessories: false,
            packaging: false,
            photos: false,
        },
        signature: '',
    });

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success' as 'success' | 'error',
    });

    const handleChange = (field: string, value: string | boolean) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handlePodSerialChange = (index: number, value: string) => {
        const newPodSerials = [...formData.podSerials];
        newPodSerials[index] = value;
        setFormData((prev) => ({
            ...prev,
            podSerials: newPodSerials,
        }));
    };

    const handleAcknowledgmentChange = (field: keyof typeof formData.acknowledgments) => {
        setFormData((prev) => ({
            ...prev,
            acknowledgments: {
                ...prev.acknowledgments,
                [field]: !prev.acknowledgments[field],
            },
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // Format the form data into an email-friendly format
        const emailBody = `
FiyrPod Return Form Submission

Customer Information:
Name: ${formData.name}
Organization: ${formData.organizationName}
Email: ${formData.email}
Phone: ${formData.phone}

Return Details:
Date of Return: ${formData.dateReturn}
Number of Pods: ${formData.numPods}
Pod Serial Numbers:
${formData.podSerials.map((serial, index) => `${index + 1}. ${serial}`).join('\n')}

Return Reason: ${formData.returnReason}
${formData.returnReasonOther ? `Other Reason: ${formData.returnReasonOther}` : ''}

Condition: ${formData.condition}
${formData.damageDescription ? `Damage Description: ${formData.damageDescription}` : ''}

Shipping Information:
Tracking Number: ${formData.trackingNumber}
Carrier: ${formData.shippingCarrier}
Date Shipped: ${formData.dateShipped}

Acknowledgments:
- Pods Cleaned: ${formData.acknowledgments.cleaned ? 'Yes' : 'No'}
- Accessories Included: ${formData.acknowledgments.accessories ? 'Yes' : 'No'}
- Properly Packaged: ${formData.acknowledgments.packaging ? 'Yes' : 'No'}
- Photos Included: ${formData.acknowledgments.photos ? 'Yes' : 'No'}

Customer Signature: ${formData.signature}
`;

        // Create mailto link with pre-filled content
        const mailtoLink = `mailto:npriest@1tul.com?subject=FiyrPod Return Form Submission&body=${encodeURIComponent(
            emailBody,
        )}`;

        // Open default email client
        window.location.href = mailtoLink;

        // Show success message
        setSnackbar({
            open: true,
            message:
                'Email client opened with form data. Please send the email to complete submission.',
            severity: 'success',
        });
    };

    return (
        <Box sx={{ maxWidth: '800px', margin: 'auto', p: 3 }}>
            <Paper sx={{ p: 4 }}>
                <form onSubmit={handleSubmit}>
                    <Typography variant="h4" gutterBottom>
                        FiyrPod Billing and Returns Policy
                    </Typography>

                    {/* Billing Section */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            FiyrPod Billing
                        </Typography>
                        <Typography paragraph>
                            Our billing system operates on a monthly subscription basis, with
                            charges occurring on different dates when you have multiple
                            subscriptions. Multiple billing cycles typically result from adding
                            pods, changing subscription levels, or resuming previously cancelled
                            subscriptions. This ensures accurate billing for your specific usage
                            period.
                        </Typography>
                        <Typography paragraph>
                            For organization and athlete memberships, billing continues until you
                            initiate cancellation through the app, at which point charges stop
                            immediately. For FiyrPod unit leases, billing continues until we receive
                            and inspect your returned pods, which may take up to one business day
                            after delivery.
                        </Typography>
                    </Box>

                    {/* Returns Section */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Returns
                        </Typography>
                        <Typography paragraph>
                            All FiyrPod returns must be shipped with a completed return form to:
                            Athlete Management Technologies ATT: Returns 3753 Arroyo Sorrento Rd.
                            San Diego, CA 92130
                        </Typography>
                        <Typography paragraph>
                            Proper documentation through the return form is essential for processing
                            your return and stopping any associated charges.
                        </Typography>
                    </Box>

                    {/* Cancellations Section */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Cancellations
                        </Typography>
                        <Typography paragraph>
                            Organization and athlete memberships can be cancelled immediately
                            through our app&apos;s self-service cancellation feature. Your access
                            and billing will stop at the time of cancellation.
                        </Typography>
                        <Typography paragraph>
                            For FiyrPod unit leases, the cancellation process requires physical
                            return of the equipment. Your lease agreement remains active until we
                            receive and inspect your returned pods, which may take up to two
                            business days after delivery. To ensure proper cancellation, please
                            include the completed return form with your shipment.
                        </Typography>
                    </Box>

                    {/* Disputed Charges Section */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Disputed Charges
                        </Typography>
                        <Typography paragraph>
                            When a charge is disputed through your bank or credit card company, AMT
                            incurs processing fees and administrative costs to resolve the dispute.
                            To discourage fraudulent or erroneous disputes, a $30 fee will be
                            assessed for any charge that is wrongfully disputed. A dispute is
                            considered wrongful when the original charge was valid according to our
                            billing terms and cancellation policies outlined above.
                        </Typography>
                        <Typography paragraph>
                            Before disputing a charge, please review your billing history and
                            contact our customer service team to resolve any questions or concerns.
                        </Typography>
                    </Box>

                    {/* Late Fees Section */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Late Fees and Failed Payments
                        </Typography>
                        <Typography paragraph>
                            When a payment fails or becomes overdue, a $15 fee will be assessed. If
                            your payment method is declined, we will attempt to process the payment
                            again over the following days. You will receive email notifications
                            about failed payments and instructions to update your payment
                            information.
                        </Typography>
                        <Typography paragraph>
                            To avoid service interruption and additional fees, please ensure your
                            payment method is current and has sufficient funds.
                        </Typography>
                    </Box>

                    <Divider sx={{ my: 4 }} />

                    <Typography variant="h4" gutterBottom>
                        FiyrPod Return Form
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Customer Information
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    required
                                    value={formData.name}
                                    onChange={(e) => handleChange('name', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Organization Name"
                                    required
                                    value={formData.organizationName}
                                    onChange={(e) =>
                                        handleChange('organizationName', e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    type="email"
                                    required
                                    value={formData.email}
                                    onChange={(e) => handleChange('email', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    required
                                    value={formData.phone}
                                    onChange={(e) => handleChange('phone', e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Return Details
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Date of Return"
                                    type="date"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    value={formData.dateReturn}
                                    onChange={(e) => handleChange('dateReturn', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Number of Pods Being Returned"
                                    type="number"
                                    required
                                    value={formData.numPods}
                                    onChange={(e) => handleChange('numPods', e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Box sx={{ mt: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Pod Serial Numbers:
                            </Typography>
                            {formData.podSerials.map((serial, index) => (
                                <TextField
                                    key={index}
                                    fullWidth
                                    label={`Pod ${index + 1} Serial Number`}
                                    value={serial}
                                    onChange={(e) => handlePodSerialChange(index, e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                            ))}
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Reason for Return:
                            </Typography>
                            <RadioGroup
                                value={formData.returnReason}
                                onChange={(e) => handleChange('returnReason', e.target.value)}
                            >
                                <FormControlLabel
                                    value="end-of-lease"
                                    control={<Radio required />}
                                    label="End of lease term"
                                />
                                <FormControlLabel
                                    value="early-termination"
                                    control={<Radio />}
                                    label="Early termination"
                                />
                                <FormControlLabel
                                    value="technical-issues"
                                    control={<Radio />}
                                    label="Technical issues"
                                />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                            </RadioGroup>
                            {formData.returnReason === 'other' && (
                                <TextField
                                    fullWidth
                                    label="Please specify"
                                    required
                                    value={formData.returnReasonOther}
                                    onChange={(e) =>
                                        handleChange('returnReasonOther', e.target.value)
                                    }
                                    sx={{ mt: 1 }}
                                />
                            )}
                        </Box>

                        <Box sx={{ mt: 3 }}>
                            <Typography variant="subtitle1" gutterBottom>
                                Current Condition of Pods:
                            </Typography>
                            <RadioGroup
                                value={formData.condition}
                                onChange={(e) => handleChange('condition', e.target.value)}
                            >
                                <FormControlLabel
                                    value="like-new"
                                    control={<Radio required />}
                                    label="Like new"
                                />
                                <FormControlLabel
                                    value="minor-wear"
                                    control={<Radio />}
                                    label="Minor wear"
                                />
                                <FormControlLabel
                                    value="significant-wear"
                                    control={<Radio />}
                                    label="Significant wear"
                                />
                                <FormControlLabel
                                    value="damaged"
                                    control={<Radio />}
                                    label="Damaged"
                                />
                            </RadioGroup>
                            {formData.condition === 'damaged' && (
                                <TextField
                                    fullWidth
                                    label="Please describe the damage"
                                    required
                                    multiline
                                    rows={3}
                                    value={formData.damageDescription}
                                    onChange={(e) =>
                                        handleChange('damageDescription', e.target.value)
                                    }
                                    sx={{ mt: 1 }}
                                />
                            )}
                        </Box>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Return Shipping Information
                        </Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Tracking Number"
                                    required
                                    value={formData.trackingNumber}
                                    onChange={(e) => handleChange('trackingNumber', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Shipping Carrier"
                                    required
                                    value={formData.shippingCarrier}
                                    onChange={(e) =>
                                        handleChange('shippingCarrier', e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Date Shipped"
                                    type="date"
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    value={formData.dateShipped}
                                    onChange={(e) => handleChange('dateShipped', e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Customer Acknowledgment
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.acknowledgments.cleaned}
                                        onChange={() => handleAcknowledgmentChange('cleaned')}
                                        required
                                    />
                                }
                                label="I have cleaned all pods"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.acknowledgments.accessories}
                                        onChange={() => handleAcknowledgmentChange('accessories')}
                                        required
                                    />
                                }
                                label="I have included all original accessories"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.acknowledgments.packaging}
                                        onChange={() => handleAcknowledgmentChange('packaging')}
                                        required
                                    />
                                }
                                label="I have properly packaged items for shipping"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.acknowledgments.photos}
                                        onChange={() => handleAcknowledgmentChange('photos')}
                                    />
                                }
                                label="I have included photos of pods (if damaged)"
                            />
                        </Box>
                        <Typography variant="subtitle2" gutterBottom>
                            I understand that:
                        </Typography>
                        <ul>
                            <li>Billing will continue until pods are received and inspected</li>
                            <li>Inspection may take up to 2 business days after receipt</li>
                            <li>Additional charges may apply for damaged or missing items</li>
                            <li>Return shipping costs are my responsibility</li>
                        </ul>
                        <TextField
                            fullWidth
                            label="Customer Signature"
                            required
                            value={formData.signature}
                            onChange={(e) => handleChange('signature', e.target.value)}
                            sx={{ mt: 2 }}
                        />
                    </Box>

                    <Button
                        type="submit"
                        variant="contained"
                        startIcon={<Email />}
                        size="large"
                        sx={{ mt: 2 }}
                    >
                        Submit Form via Email
                    </Button>
                </form>
            </Paper>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default FiyrPodReturnForm;
