import { Avatar, Box, Card, CardMedia, Skeleton, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';
import { getHtml } from 'utils';

export interface LandingPageHeroCardProps {
    title: string;
    description: string;
    isLoading?: boolean;
    subtitle?: string;
    avatarSrc?: string;
    bannerSrc?: string;
    organization?: string;
    organizationImage?: string;
}

const LandingPageHeroCard = ({
    title,
    description,
    avatarSrc,
    bannerSrc,
    isLoading = false,
    subtitle = '',
    organization,
    organizationImage,
}: LandingPageHeroCardProps) => {
    const theme = useTheme();
    return (
        <>
            {!isLoading && (
                <>
                    <Box width="100%" maxWidth="lg" component="div" mt={20}>
                        {bannerSrc && (
                            <Card component="div" sx={{ width: '100%' }} elevation={0}>
                                <CardMedia sx={{ height: 600 }} image={bannerSrc} title={title} />
                            </Card>
                        )}
                        {avatarSrc && (
                            <Avatar
                                variant="rounded"
                                alt={title}
                                src={avatarSrc}
                                sx={{
                                    height: theme.spacing(124),
                                    width: theme.spacing(124),
                                    margin: theme.spacing(12, 'auto'),
                                    boxShadow:
                                        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                                }}
                            />
                        )}
                    </Box>
                    <Box width="100%" maxWidth="md" component="div" textAlign="center" mb={20}>
                        {title && (
                            <Typography
                                variant="h5"
                                sx={{
                                    color: theme.palette.text.primary,
                                    margin: theme.spacing(8, 0),
                                }}
                            >
                                {title}
                            </Typography>
                        )}
                        {subtitle && (
                            <Typography variant="body2">
                                {' '}
                                Shared with {subtitle} permissions from:
                            </Typography>
                        )}
                        {organization && (
                            <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="center"
                                marginY={6}
                            >
                                {organizationImage && (
                                    <Box marginRight={4}>
                                        <Avatar variant="rounded" src={organizationImage} />
                                    </Box>
                                )}
                                <Typography variant="h6">{organization}</Typography>
                            </Box>
                        )}
                        {description && (
                            <Box m={20} mb={50}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        margin: theme.spacing(8, 0),
                                        textAlign: 'left',
                                    }}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: getHtml(description || ''),
                                        }}
                                    />
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </>
            )}
            {isLoading && (
                <>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                            height: theme.spacing(124),
                            width: theme.spacing(124),
                            margin: theme.spacing(12, 'auto'),
                            boxShadow:
                                '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                        }}
                    />
                    <Skeleton
                        animation="wave"
                        height={30}
                        sx={{ color: theme.palette.text.primary, margin: theme.spacing(8, 0) }}
                    />
                    <Skeleton animation="wave" height={20} />
                    <Skeleton animation="wave" height={20} />
                    <Skeleton animation="wave" height={20} />
                    <Skeleton animation="wave" height={20} width="80%" />
                </>
            )}
        </>
    );
};

export default LandingPageHeroCard;
