import React, { FC } from 'react';

type FilterLabelProps = {
    name: string;
    image?: string | null;
};

const FilterLabel: FC<React.PropsWithChildren<FilterLabelProps>> = ({ name, image }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {image && (
                <figure
                    style={{
                        borderRadius: 24,
                        height: 24,
                        margin: 0,
                        marginRight: 8,
                        overflow: 'hidden',
                        width: 24,
                    }}
                >
                    <img
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                        src={image}
                        alt={name}
                    />
                </figure>
            )}
            <div>{name}</div>
        </div>
    );
};

export { FilterLabel };
