import { ChartPivotRow } from '@cubejs-client/core';
import { mapValues } from 'lodash';
import React from 'react';
import { CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { duckDateFormatter, formatNumber } from '../../community.func';

function CartesianChart({ resultSet, children, ChartComponent, ...props }: any) {
    let data = resultSet.chartPivot(props.pivotConfig);

    if (!props.plotMissingOnChart) {
        data = data.map((row: ChartPivotRow) =>
            mapValues(row, (value) => (value === 0 ? null : value)),
        );
    }

    return (
        <ResponsiveContainer
            width={props.width ?? '100%'}
            height={'100%'}
            minHeight={props.height ?? 500}
        >
            <ChartComponent data={data}>
                {!props.hideLegend && (
                    <Legend
                        wrapperStyle={{
                            padding: '0 2rem',
                            height: 100,
                            overflow: 'auto',
                        }}
                    />
                )}
                {!props.hideXAxis && (
                    <XAxis
                        interval={0}
                        dataKey="x"
                        height={120}
                        angle={-75}
                        textAnchor="end"
                        tickFormatter={duckDateFormatter}
                    />
                )}
                {!props.hideYAxis && (
                    <YAxis tickFormatter={formatNumber} domain={['dataMin', 'dataMax']} />
                )}
                <CartesianGrid />
                {children}
                {!props.hideTooltip && (
                    <Tooltip
                        itemSorter={(item: any) => {
                            return (item.value as number) * -1;
                        }}
                        labelFormatter={duckDateFormatter}
                        payload={[]}
                        contentStyle={{
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',

                            border: '1px solid #a8bfd0',
                            borderRadius: 15,
                        }}
                    />
                )}
            </ChartComponent>
        </ResponsiveContainer>
    );
}

export default CartesianChart;
