import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getOrganizationsWithAdminOrCoachRoles } from '../../redux/reducers/user';
import { getInitials } from 'utils';
import { orderBy } from 'lodash';

const AssociatedOrgList = () => {
    const organizationsWithRoles = useSelector(getOrganizationsWithAdminOrCoachRoles) || [];

    return (
        <>
            {organizationsWithRoles.length === 0 ? (
                <Typography textAlign={'center'}>
                    You have no associated organizations that you train, learn, or share content
                    with.{' '}
                </Typography>
            ) : (
                <List>
                    {orderBy(organizationsWithRoles, ['display_name'], ['asc'])?.map(
                        (organization) => (
                            <ListItem button key={organization.name}>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        src={organization.image_urls || ''}
                                        alt={organization.name}
                                        style={{
                                            width: 40,
                                            height: 40,
                                        }}
                                    >
                                        {getInitials(organization.display_name)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText>{organization.display_name}</ListItemText>
                            </ListItem>
                        ),
                    )}
                </List>
            )}
        </>
    );
};

export default AssociatedOrgList;
