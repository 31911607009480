import { Avatar, Box, Button, Link, TextField } from '@mui/material';
import { CustomButton } from 'components/Buttons';
import { TypographyTitleh6 } from 'components/Typography';
import ShowMoreText from 'react-show-more-text';
import { UserProfile, useToggle } from 'utils';
import React, { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import FollowerFollowingDialog from './FollowerFollowingDialog';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/system';

interface SocialProfileCardProps {
    user: UserProfile;
    bio: string;
    slug: string;
    isDescriptionEditable?: Boolean;
    canFollow?: Boolean;
    isFollowing?: boolean;
    onMyProfileClicked?: () => void;
    onFollowClicked?: () => void;
    onUnfollowClicked?: () => void;
    onSave?: (description: string) => void;
    isMyProfile: boolean;
}

const SocialProfileCard = ({
    user,
    bio,
    isDescriptionEditable = false,
    canFollow = false,
    isFollowing = false,
    onMyProfileClicked = () => {},
    onFollowClicked = () => {},
    onUnfollowClicked = () => {},
    onSave = () => {},
    isMyProfile = false,
}: SocialProfileCardProps) => {
    const [isEditorOpened, setIsEditorOpened] = useState(false);
    const [isFollowingListOpen, toggleIsFollowingListOpen] = useToggle(false);
    const [description, setDescription] = useState(bio ?? '');
    const [descriptionLength, setDescriptionLength] = useState(bio.length);
    const [initialTabState, setInitialTabState] = useState(0);
    const { push } = useHistory();
    const theme = useTheme();
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDescription(e.target.value);
        setDescriptionLength(e.target.value.length);
    };
    useEffect(() => {
        setDescription(bio);
    }, [bio]);
    return (
        <Box
            sx={{
                width: '100%',
                background: theme.palette.backgroundColor.action,
                padding: theme.spacing(24, 16),
                display: 'flex',
                flexDirection: 'column',
                wordBreak: 'break-word',
                maxWidth: '359px',
                [theme.breakpoints.down('sm')]: {
                    maxWidth: '100%',
                    padding: theme.spacing(12.5, 8),
                },
            }}
        >
            {isFollowingListOpen && (
                <FollowerFollowingDialog
                    open={isFollowingListOpen}
                    onClose={() => toggleIsFollowingListOpen()}
                    initialTab={initialTabState}
                ></FollowerFollowingDialog>
            )}
            <Box sx={{ width: '100%', display: 'flex', marginBottom: theme.spacing(14) }}>
                {isMyProfile && (
                    <Button
                        onClick={() => {
                            setInitialTabState(0);
                            toggleIsFollowingListOpen();
                        }}
                        sx={{
                            background: theme.palette.backgroundColor.freeSpeechBlue,
                            padding: theme.spacing(2, 5),
                            marginRight: theme.spacing(8),
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '22px',
                            color: theme.palette.backgroundColor.paper,
                            textTransform: 'uppercase',
                        }}
                    >{`${user.followers_count} followers`}</Button>
                )}
                {!isMyProfile && (
                    <span
                        style={{
                            background: theme.palette.backgroundColor.freeSpeechBlue,
                            padding: theme.spacing(2, 5),
                            marginRight: theme.spacing(8),
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '22px',
                            color: theme.palette.backgroundColor.paper,
                            textTransform: 'uppercase',
                        }}
                    >{`${user.followers_count} followers`}</span>
                )}
                {!isMyProfile && (
                    <span
                        style={{
                            background: theme.palette.backgroundColor.freeSpeechBlue,
                            padding: theme.spacing(2, 5),
                            marginRight: theme.spacing(8),
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '22px',
                            color: theme.palette.backgroundColor.paper,
                            textTransform: 'uppercase',
                        }}
                    >{`${user.following_count} following`}</span>
                )}
                {isMyProfile && (
                    <Button
                        onClick={() => {
                            setInitialTabState(1);
                            toggleIsFollowingListOpen();
                        }}
                        sx={{
                            background: theme.palette.backgroundColor.freeSpeechBlue,
                            padding: theme.spacing(2, 5),
                            marginRight: theme.spacing(8),
                            fontWeight: 600,
                            fontSize: '12px',
                            lineHeight: '22px',
                            color: theme.palette.backgroundColor.paper,
                            textTransform: 'uppercase',
                        }}
                    >{`${user.following_count} following`}</Button>
                )}
            </Box>
            <Avatar
                variant="rounded"
                sx={{
                    width: '100%',
                    height: 287,
                    objectFit: 'contain',
                    marginBottom: theme.spacing(16),
                }}
                src={user.profile_photo['1000'] ?? ''}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    marginBottom: theme.spacing(16),
                }}
            >
                <TypographyTitleh6
                    sx={{
                        color: '#000000',
                        marginRight: theme.spacing(3),
                        [theme.breakpoints.down('sm')]: {
                            marginBottom: theme.spacing(5),
                        },
                    }}
                >
                    <Link href="#" onClick={() => push(`/@${user.slug}`)}>
                        {user.name}
                    </Link>
                </TypographyTitleh6>
                {canFollow && !isFollowing && (
                    <Button onClick={onFollowClicked} variant="outlined" color="primary">
                        {isMyProfile && <>Hiding My Posts On My Feed</>}
                        {!isMyProfile && <>Follow</>}
                    </Button>
                )}
                {canFollow && isFollowing && (
                    <Button
                        onClick={onUnfollowClicked}
                        variant="contained"
                        color="primary"
                        endIcon={<CheckIcon />}
                    >
                        {isMyProfile && <>Showing My Posts on My Feed</>}
                        {!isMyProfile && <>Following</>}
                    </Button>
                )}
                {!canFollow && (
                    <CustomButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={
                            isDescriptionEditable
                                ? () => {
                                      if (isEditorOpened) {
                                          onSave(description);
                                      }
                                      setIsEditorOpened(!isEditorOpened);
                                  }
                                : onMyProfileClicked
                        }
                    >
                        {!isDescriptionEditable
                            ? 'MY PROFILE'
                            : isEditorOpened
                            ? 'Save Changes'
                            : 'Edit'}
                    </CustomButton>
                )}
            </Box>
            {!isEditorOpened ? (
                <Box sx={{ color: '#000000', width: '100%' }}>
                    <ShowMoreText more="view more" less="view less" lines={2}>
                        {description}
                    </ShowMoreText>
                </Box>
            ) : (
                <TextField
                    id="standard-textarea"
                    label="Write Your Bio Here"
                    placeholder="Write Your Bio Here"
                    multiline
                    variant="standard"
                    color="primary"
                    helperText={`${descriptionLength}/250`}
                    value={description}
                    inputProps={{
                        maxLength: 250,
                    }}
                    FormHelperTextProps={{
                        style: {
                            textAlign: 'right',
                        },
                    }}
                    onChange={handleChange}
                />
            )}
        </Box>
    );
};

export default SocialProfileCard;
