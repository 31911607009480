import { AxiosResponse } from 'axios';
import { Integration } from '../app.types'; /*Get all integrations for 1080*/
import { Request } from './index';
/*Get all integrations for 1080*/
export const getAllIntegrations = (
    organizationUuid: string,
): Promise<AxiosResponse<Integration[]>> => {
    return Request.get(`/organizations/${organizationUuid}/integrations`);
};

export const createIntegration = (
    organizationUuid: string,
    payload: {
        api_key: string;
        api_type: string;
    },
): Promise<AxiosResponse<Integration>> => {
    return Request.post(`/organizations/${organizationUuid}/integrations`, payload);
};
