/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, List, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import React, { ReactElement } from 'react';
import { AttributeValue } from 'utils/types';
import Chip from '@mui/material/Chip';
import AttributeValueInput from './AttributeValueInput';
import { FormikErrors, FormikValues } from 'formik';
import { isEmpty } from 'lodash';
import useTheme from '@mui/material/styles/useTheme';

/**
 * AttributeListViewer properties
 */
export interface AttributeListViewerProps {
    /** attributeValues The set of attribute values to view */
    attributeValues: AttributeValue[];
    /**
     * deleted Callback for when the delete button is clicked for one of the attributes
     */
    deleted: (values: AttributeValue[]) => void;
    /**
     * updated Callback for when the attributes are updated (any change occurs)
     */
    updated: (values: AttributeValue[]) => void;

    errors?: FormikErrors<FormikValues>;
}

export default function AttributeListViewer({
    attributeValues,
    deleted,
    updated,
    errors,
}: AttributeListViewerProps): ReactElement {
    const handleChange = (newValue: AttributeValue, index: number) => {
        const originalValues = [...attributeValues];

        originalValues[index] = newValue;
        updated(originalValues);
    };

    const onDeleteClicked = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        index: number,
    ) => {
        deleted(attributeValues.filter((v, i) => i == index));
        updated(attributeValues.filter((v, i) => i !== index && !isEmpty(v.value)));
    };
    const theme = useTheme();

    return (
        <>
            <Box mt={5} textAlign="left">
                <Typography variant="caption" color="textSecondary">
                    Attributes/Tags
                </Typography>
            </Box>
            <List>
                {attributeValues.map((value: AttributeValue, index: number) => (
                    <ListItem
                        key={`attribute.name--${value.name}`}
                        disableGutters
                        sx={{ marginBottom: theme.spacing(3) }}
                    >
                        <Chip
                            sx={{
                                marginRight: theme.spacing(10),
                                background: theme.palette.backgroundColor.hover,
                                '& .MuiChip-label': {
                                    minWidth: 100,
                                },
                            }}
                            label={value.name}
                            onDelete={(event) => onDeleteClicked(event, index)}
                        />
                        <AttributeValueInput
                            error={(errors && errors[`attributes.${index}`]?.toString()) ?? ''}
                            value={value}
                            onChange={(newValue) => handleChange(newValue, index)}
                        />
                    </ListItem>
                ))}
            </List>
        </>
    );
}
