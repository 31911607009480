import Typography from '@mui/material/Typography';
import { Box, Paper, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';

const DasboardCard = ({
    title,
    value,
    subtext,
    infoText,
    icon,
}: {
    title: string;
    value: number | string;
    subtext?: string;
    infoText?: string;
    icon?: React.ReactNode;
}) => {
    return (
        <Paper
            sx={{
                overflow: 'hidden',
                borderRadius: 2,
                p: 6,
                boxShadow: 1,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '1rem',
                        fontWeight: 'medium',
                        color: 'text.secondary',
                    }}
                >
                    {title}
                </Typography>
                {infoText && (
                    <Tooltip title={infoText}>
                        <InfoIcon sx={{ fontSize: '1rem', color: 'text.secondary' }} />
                    </Tooltip>
                )}
            </Box>
            <Typography
                sx={{
                    mt: 1,
                    fontSize: '2.25rem',
                    fontWeight: 'bold',
                    lineHeight: 'tight',
                    color: 'text.primary',
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: 1,
                }}
            >
                {value}
                {icon}
                {subtext && (
                    <Typography
                        component="span"
                        sx={{
                            fontSize: '0.875rem',
                            color: 'text.secondary',
                            fontWeight: 'normal',
                        }}
                    >
                        {subtext}
                    </Typography>
                )}
            </Typography>
        </Paper>
    );
};
export default DasboardCard;
