import { Box, Drawer, IconButton, LinearProgress, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { ReactElement, useState } from 'react';
import { grey } from '@mui/material/colors';
import { ShareCard } from 'components/Cards';

import { Contact, Invite, Link, Role, ShareableClassification, SharedUser } from 'utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CustomButton } from 'components/Buttons';
import CreateLinkModal from 'components/Dialogs/CreateLinkModal';
import { deleteLink } from 'api';
import { TypographyLargeText, TypographyTitle } from 'components/Typography';
import { LinkList } from 'components/Lists';
import { useTheme } from '@mui/system';

export interface SharingDrawerProps {
    title?: string;
    /** open If the drawer is currently open */
    open: boolean;
    /** onClose When the close button is clicked */
    onClose: () => void;
    /** userAccessList */
    userAccessList: Array<SharedUser>;
    /** onRemove When a user is removed from the sharing list */
    onRemove: (user: SharedUser) => void;
    /** onShared When the user presses the share button */
    onShared: (message: string, users: Array<Contact>, role: string) => void;
    /** processing If the sharing drawer is processing something */
    processing: boolean;
    /** onError If an api error or some other error occurs in the drawer. */
    onError?: (message: string) => void;
    /** entityId The entity id that we are sharing */
    entityId: string;
    /** entityType The shareable entity */
    entityType: ShareableClassification;
    /** links The sharing links for the entity  */
    links: Array<Link>;
    /** onLinkDeleteClicked  */
    onLinkDeleteClicked: (link: Link) => void;
    /** onLinkDeleteFailed */
    onLinkDeleteFailed: (link: Link) => void;
    /** onLinkDeleteSucceeded */
    onLinkDeleteSucceeded: (link: Link) => void;
    /** onLinkCreated */
    onLinkCreated: (link: Link) => void;
    /** roles An array of roles to use. */
    roles: Array<Role>;
    /** invites */
    invites: Array<Invite>;
    /** onInviteRevoked */
    onInviteRevoked: (invite: Invite) => void;
    /** onInviteResent */
    onInviteResent: (invite: Invite) => void;
    /** restrictsRoles **/
    restrictsRoles?: boolean;
}

export default function SharingDrawer({
    title,
    open,
    onClose,
    userAccessList,
    onRemove,
    onShared,
    processing = false,
    entityId,
    entityType,
    links,
    roles,
    onError = () => {},
    onLinkCreated,
    onLinkDeleteClicked,
    onLinkDeleteFailed,
    onLinkDeleteSucceeded,
    onInviteResent,
    onInviteRevoked,
    invites = [],
    restrictsRoles = false,
}: SharingDrawerProps): ReactElement {
    const theme = useTheme();
    const onDoneClicked = () => {
        onClose();
    };
    const closeDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        onClose();
    };
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = (response: Link | false) => {
        if (response) {
            onLinkCreated(response);
        }
        setDialogOpen(false);
    };

    const onLinkDelete = async (link: Link) => {
        onLinkDeleteClicked(link);
        try {
            await deleteLink(entityType, entityId, link.uuid);
            onLinkDeleteSucceeded(link);
        } catch (err: any) {
            onLinkDeleteFailed(link);
        }
    };

    const handleInviteRevoked = (invite: Invite) => {
        onInviteRevoked(invite);
    };
    const handleInviteResent = (invite: Invite) => {
        onInviteResent(invite);
    };
    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={(e: React.KeyboardEvent | React.MouseEvent) => closeDrawer(e)}
            sx={{
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('sm')]: {
                    width: 425,
                },
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
            }}
        >
            {processing && <LinearProgress />}

            <Box
                sx={{ padding: theme.spacing(8), flexShrink: 0 }}
                display="flex"
                alignItems="center"
            >
                <IconButton onClick={onDoneClicked} size="large">
                    <ArrowBackIosIcon />
                </IconButton>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                        marginRight: theme.spacing(24),
                    }}
                >
                    <TypographyTitle>{title ? title : 'Share'}</TypographyTitle>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderTop: 1,
                    borderTopStyle: 'solid',
                    borderTopColor: grey[300],
                    padding: theme.spacing(8),
                    backgroundColor: '#fff',
                    flexShrink: 0,
                    minHeight: 450,
                    overflowY: 'visible',
                }}
            >
                <Box
                    sx={{
                        padding: theme.spacing(0, 8),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <TypographyLargeText color="primary">Public Links</TypographyLargeText>
                    <CustomButton
                        sx={{ marginRight: theme.spacing(8) }}
                        size="small"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleClickOpen}
                    >
                        Create
                    </CustomButton>
                </Box>
                <CreateLinkModal
                    roles={roles}
                    open={dialogOpen}
                    onClose={handleClose}
                    entityId={entityId}
                    entityType={entityType}
                />
                <LinkList links={links} onDelete={onLinkDelete}></LinkList>
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                {roles && roles.length > 0 && (
                    <>
                        <Box textAlign="center" mb={5}>
                            <Typography variant="body1">- OR -</Typography>
                        </Box>
                        <ShareCard
                            userAccessList={userAccessList}
                            onShared={onShared}
                            restrictsRoles={restrictsRoles}
                            onRemoveClicked={onRemove}
                            onError={onError}
                            roles={roles}
                            invites={invites}
                            onInviteResent={handleInviteResent}
                            onInviteRevoked={handleInviteRevoked}
                        />
                    </>
                )}
            </Box>
        </Drawer>
    );
}
