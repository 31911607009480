import { AxiosResponse } from 'axios';
import { Request } from 'api';

type ReturnType = Promise<AxiosResponse<Array<any>>>;

const postToFeed = (activity: string): ReturnType => {
    return Request.post(`/workouts/activities/${activity}/add-to-social-feed`, {});
};

export default postToFeed;
