import React, { useMemo, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Movement, Organization } from 'utils';
import Search from './Search';
import debounce from 'lodash/debounce';
import { getAllMovements } from 'api';
import Button from '@mui/material/Button';

interface Props {
    onMovementSelected: (movement: Movement) => void;
    organization: Organization;
}

const SearchMovement = ({ organization, onMovementSelected }: Props) => {
    const theme = useTheme();
    const breakPoint = useMediaQuery(theme.breakpoints.up('sm'));

    const [inputValue, setInputValue] = React.useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [movementsFilteredList, setMovementsFilteredList] = useState<Array<Movement>>([]);

    const debouncedSearchMovement = useMemo(
        () =>
            debounce(
                (value) =>
                    searchMovements({
                        'filter[name]': value,
                    }),
                400,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const searchMovements = (params = {}) => {
        setLoading(true);
        getAllMovements(
            organization ? { ...params, 'filter[organization]': organization.uuid } : params,
        ).then(function (result) {
            setMovementsFilteredList(result.data.data);
            setLoading(false);
        });
    };

    const handleInputChanged = (value: string) => {
        setInputValue(value);
        debouncedSearchMovement(value);
    };
    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                variant={'text'}
                color={'primary'}
                onClick={() => {
                    searchMovements();
                    setIsOpen(true);
                }}
            >
                Add Movement(s)
            </Button>
            <Search
                isOpen={isOpen}
                breakPoint={breakPoint}
                inputValue={inputValue}
                handleClose={handleClose}
                setInputValue={handleInputChanged}
                isFiltered={!!inputValue.length}
                placeholderText={'Search for a movement'}
                searchResults={movementsFilteredList.map((m) => ({
                    id: m.uuid,
                    title: m.name,
                    subtitle: '',
                    avatarUrl: '',
                }))}
                onCreateClicked={() => {}}
                loading={loading}
                showOverlay={false}
                onResultSelected={(result) => {
                    setIsOpen(false);
                    onMovementSelected(
                        movementsFilteredList.find((i) => i.uuid === result.id) ??
                            movementsFilteredList[0],
                    );
                }}
                organization={organization}
                createButtonText={''}
            />
        </React.Fragment>
    );
};

export default SearchMovement;
