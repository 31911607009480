import { ChartPivotRow, SeriesNamesColumn, TableColumn } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { Avatar, Box, Grid, Paper, TableContainer } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { TypographyTitleh4, TypographyTitleh6 } from 'components';
import React from 'react';
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    PolarAngleAxis,
    PolarGrid,
    Radar,
    RadarChart,
    ResponsiveContainer,
    Tooltip,
} from 'recharts';
import { duckDateFormatter } from '../../community.func';
import CartesianChart from './CartesianChart';

const COLORS_SERIES = [
    '#5b8ff9',
    '#5ad8a6',
    '#5e7092',
    '#f6bd18',
    '#6f5efa',
    '#6ec8ec',
    '#945fb9',
    '#ff9845',
    '#299796',
    '#fe99c3',
];

const NumberComponent = ({ children }: { children: React.ReactElement }) => (
    <Typography
        variant="h4"
        style={{
            textAlign: 'center',
        }}
    >
        {children}
    </Typography>
);

const GridColumn = ({ column, row }: any) => {
    if (column.type === 'string' && column.key === 'DimIndividual.profilePhotoUrl') {
        return (
            <Avatar
                key={column.key}
                variant="rounded"
                sx={{
                    minWidth: 100,
                    minHeight: 100,
                    objectFit: 'cover',
                    margin: 12,
                }}
                src={row[column.key]}
                alt=""
            />
        );
    }
    if (column.type === 'string') {
        return <TypographyTitleh6>{duckDateFormatter(row[column.key])}</TypographyTitleh6>;
    }
    if (column.type === 'number') {
        return (
            <TypographyTitleh4>
                {row[column.key]}
                {column?.meta?.unit}
            </TypographyTitleh4>
        );
    }
    return <>{duckDateFormatter(row[column.key])}</>;
};

const TypeToChartComponent = {
    line: function line({ resultSet, pivotConfig, ...props }: any) {
        return (
            <CartesianChart
                resultSet={resultSet}
                ChartComponent={LineChart}
                pivotConfig={pivotConfig}
                {...props}
            >
                <defs>
                    <filter id="drop-shadow" x="-50%" y="-50%" width="150%" height="150%">
                        <feOffset result="offOut" in="SourceAlpha" dx="1" dy="1" />
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                </defs>
                {resultSet.seriesNames(pivotConfig).map((series: any, i: any) => (
                    <Line
                        dot={false}
                        connectNulls={true}
                        key={series.key}
                        dataKey={series.key}
                        name={series.title}
                        stroke={COLORS_SERIES[i]}
                        strokeWidth={1.4}
                        filter="url(#drop-shadow)"
                    />
                ))}
            </CartesianChart>
        );
    },
    bar: function bar({ resultSet, pivotConfig, ...props }: any) {
        return (
            <CartesianChart
                resultSet={resultSet}
                ChartComponent={BarChart}
                pivotConfig={pivotConfig}
                {...props}
            >
                {resultSet.seriesNames(pivotConfig).map((series: any, i: any) => (
                    <Bar
                        key={series.key}
                        stackId="a"
                        dataKey={series.key}
                        name={series.title}
                        fill={COLORS_SERIES[i]}
                    />
                ))}
            </CartesianChart>
        );
    },
    area: function area({ resultSet, ...props }: any) {
        return (
            <CartesianChart
                resultSet={resultSet}
                pivotConfig={props.pivotConfig}
                ChartComponent={AreaChart}
                {...props}
            >
                {resultSet.seriesNames(props.pivotConfig).map((series: any, i: any) => (
                    <Area
                        connectNulls={true}
                        key={series.key}
                        stackId="a"
                        dataKey={series.key}
                        name={series.title}
                        stroke={COLORS_SERIES[i]}
                        fill={COLORS_SERIES[i]}
                    />
                ))}
            </CartesianChart>
        );
    },
    pie: function pie({ resultSet, pivotConfig, ...props }: any) {
        if (resultSet.seriesNames().length === 0) {
            return (
                <Box
                    width={100}
                    height={100}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    No Data
                </Box>
            );
        }
        return (
            <ResponsiveContainer width={props.width ?? '100%'} minHeight={props.height ?? 500}>
                <PieChart {...props.pieChartOptions}>
                    <Pie
                        isAnimationActive={false}
                        data={resultSet.chartPivot(pivotConfig)}
                        nameKey="x"
                        dataKey={resultSet.seriesNames(pivotConfig)[0].key}
                        {...props.pieOptions}
                    >
                        {resultSet
                            .chartPivot(pivotConfig)
                            .map((e: ChartPivotRow, index: number) => (
                                <Cell
                                    key={index}
                                    fill={COLORS_SERIES[index % COLORS_SERIES.length]}
                                />
                            ))}
                    </Pie>
                    {!props.hideLegend && <Legend />}
                    {!props.hideTooltip && <Tooltip />}
                </PieChart>
            </ResponsiveContainer>
        );
    },
    radar: function radar({ resultSet, pivotConfig, ...props }: any) {
        if (resultSet.seriesNames(pivotConfig).length === 0) {
            return (
                <Box
                    width={100}
                    height={100}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    No Data
                </Box>
            );
        }
        let measures = props.cubeMeta.cubes.flatMap((c: any) => c.measures);

        let seriesNames = resultSet.seriesNames(pivotConfig);
        return (
            <ResponsiveContainer width={props.width ?? '100%'} minHeight={props.height ?? 500}>
                <RadarChart
                    outerRadius={90}
                    width={730}
                    height={250}
                    data={resultSet.chartPivot(pivotConfig)}
                >
                    <PolarGrid />
                    <PolarAngleAxis
                        dataKey="x"
                        tickFormatter={(t) => {
                            let measure = measures.find((m: any) => m.name === t);
                            if (measure) {
                                return measure.shortTitle;
                            }
                            return t;
                        }}
                    />
                    {seriesNames.map((e: SeriesNamesColumn, index: number) => (
                        <Radar
                            key={index}
                            dataKey={e.key}
                            fill={COLORS_SERIES[index % COLORS_SERIES.length]}
                            fillOpacity={0.6}
                        />
                    ))}
                    {!props.hideLegend && <Legend />}
                </RadarChart>
            </ResponsiveContainer>
        );
    },
    number: function number({ resultSet }: any) {
        return (
            <NumberComponent>
                {resultSet.seriesNames().reduce((currentValue: number, value: any) => {
                    return currentValue + resultSet.totalRow()[value.key];
                }, 0)}
            </NumberComponent>
        );
    },
    table: function table({ resultSet, pivotConfig }: any) {
        return (
            <TableContainer>
                <Table size={'small'} aria-label="chart">
                    <TableHead>
                        <TableRow>
                            {resultSet.tableColumns(pivotConfig).map((c: TableColumn) => (
                                <TableCell key={c.key}>{c.shortTitle}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {resultSet.tablePivot(pivotConfig).map((row: any, index: any) => (
                            <TableRow key={index}>
                                {resultSet.tableColumns(pivotConfig).map((c: TableColumn) => (
                                    <TableCell key={c.key}>
                                        {c.key === 'DimIndividual.profilePhotoUrl' ? (
                                            <Avatar
                                                variant="rounded"
                                                sx={{ width: 30, height: 30 }}
                                                src={row[c.key]}
                                                alt=""
                                            />
                                        ) : (
                                            duckDateFormatter(row[c.key])
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    },
    athletes: function table({ resultSet, pivotConfig, ...props }: any) {
        return (
            <Grid key={2} container alignItems={'center'}>
                {resultSet.tablePivot(pivotConfig).map((r: any, i: number) => (
                    <Grid key={i} item xs={12} sm={6} md={4} lg={2} style={{ padding: 8 }}>
                        {!props.simplifiedView && (
                            <Paper
                                style={{
                                    width: '100%',
                                    height: 320,
                                }}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                >
                                    {resultSet.tableColumns(pivotConfig).map((c: any) => (
                                        <GridColumn key={c.key} column={c} row={r} />
                                    ))}
                                </Box>
                            </Paper>
                        )}
                        {props.simplifiedView && (
                            <Paper
                                sx={{
                                    width: '100%',
                                    height: 60,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Avatar
                                    variant="rounded"
                                    sx={{ width: 30, height: 30 }}
                                    src=""
                                    alt=""
                                />
                            </Paper>
                        )}
                    </Grid>
                ))}
            </Grid>
        );
    },
} as any;
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
    .map((key) => ({
        [key]: React.memo(TypeToChartComponent[key]),
    }))
    .reduce((a, b) => ({ ...a, ...b }));

function renderChart(Component: any) {
    return function renderer({ resultSet, error, ...props }: any) {
        return (
            (resultSet && <Component resultSet={resultSet} {...props} />) ||
            (error && error.toString()) || <CircularProgress />
        );
    };
}

const ChartRenderer = ({ vizState = {} }: any) => {
    const { query, chartType, ...options } = vizState;
    const component = TypeToMemoChartComponent[chartType];
    const renderProps = useCubeQuery(query);
    return component && renderChart(component)({ ...options, ...renderProps });
};

export default ChartRenderer;
