import { Button, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import React from 'react';
import { Form } from '../../../utils';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

interface Props {
    forms: Form[];
    selectedForms: Form[];
    onChange: (forms: Form[]) => void;
}

function GroupFormsList({ forms, selectedForms, onChange }: Props): React.ReactElement {
    const theme = useTheme();

    function onSelect(form: Form) {
        const selected = [...selectedForms];

        const ix = selected.findIndex((s) => s.uuid === form.uuid);

        if (ix > -1) {
            selected.splice(ix, 1);
        } else {
            selected.push(form);
        }

        onChange(selected);
    }

    function renderItem(props: any) {
        const s = forms[props.index];
        return (
            <ListItem button key={props.index} style={props.style} onClick={() => onSelect(s)}>
                <ListItemIcon style={{ marginRight: 10 }}>
                    {selectedForms.findIndex((sess) => sess.uuid === s.uuid) > -1 ? (
                        <Button size={'small'} variant="contained" color="primary">
                            <CheckCircleOutlineIcon
                                style={{ color: theme.palette.secondary.main }}
                            />
                            &nbsp; Shared
                        </Button>
                    ) : (
                        <Button size={'small'}>
                            <RadioButtonUncheckedIcon />
                            &nbsp; Share
                        </Button>
                    )}
                </ListItemIcon>
                {selectedForms.findIndex((sess) => sess.uuid === s.uuid) > -1 ? (
                    <ListItemText primary={s.title} />
                ) : (
                    <ListItemText secondary={s.title} />
                )}
            </ListItem>
        );
    }

    return (
        <AutoSizer>
            {({ width, height }: { width: number; height: number }) => (
                <FixedSizeList height={height} width={width} itemSize={48} itemCount={forms.length}>
                    {renderItem}
                </FixedSizeList>
            )}
        </AutoSizer>
    );
}

export default GroupFormsList;
