import 'react-calendar/dist/Calendar.css';
import useTheme from '@mui/material/styles/useTheme';
import { CustomButton } from 'components/Buttons';
import { Filters } from 'components/Filters';
import React, { ChangeEvent, FC } from 'react';
import { useHistory } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';

import { Dimensions } from 'utils';

interface CalendarLeftPanelProps {
    isLoading: boolean;
    isLoaded: boolean;
    filters: Record<string, Dimensions[]>;
    selectedFilters: Dimensions[];
    value: string;
    onChangeFilter: (filters: Dimensions[]) => void;
    onChangeSelect: (event: ChangeEvent<{ value: unknown }>) => void;
}

const CalendarLeftPanel: FC<React.PropsWithChildren<CalendarLeftPanelProps>> = ({
    isLoading,
    isLoaded,
    filters,
    selectedFilters,
    onChangeFilter,
}) => {
    const theme = useTheme();
    const { push } = useHistory();

    return (
        <div
            style={{
                width: '100%',
                padding: theme.spacing(15, 10),
                overflowY: 'scroll',

                [theme.breakpoints.down('sm')]: {
                    padding: theme.spacing(5),
                },
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexFlow: 'row wrap',
                    marginBottom: theme.spacing(8),
                    [theme.breakpoints.down(900)]: {
                        flexDirection: 'column',
                    },
                }}
            >
                <Router>
                    <CustomButton
                        color="primary"
                        variant="contained"
                        onClick={() => push('/train/sessions')}
                    >
                        Select Session then + My Calendar to Assign
                    </CustomButton>
                </Router>
            </div>

            <Filters
                filters={filters}
                isLoading={isLoading}
                isLoaded={isLoaded}
                selectedFilters={selectedFilters}
                handleChangeFilter={onChangeFilter}
            />
        </div>
    );
};

export default CalendarLeftPanel;
