import { createLink } from 'api';
import CreateLinkForm from 'components/Forms/CreateLinkForm';
import { FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useRef, useState } from 'react';
import {
    Link,
    Role,
    ShareableClassification,
    SAVE_INFORMATION_TEXT,
    SAVE_TEXT,
    UNSAVED_CHANGES_TEXT,
    EXIT_TEXT,
} from 'utils';
import { FormModal, confirmViaDialog } from '.';

interface CreateLinkModalProps {
    /** open Whether or not the movement modal is open */
    open: boolean;
    /** Callback when the movement modal is closed */
    onClose: (link: Link | false) => void;
    /** entityId The entity id that we're shairing */
    entityId: string;
    /** entityType The type of entity we are sharing */
    entityType: ShareableClassification;
    /** roles An array of roles to use in the selection */
    roles: Array<Role>;
}

export default function CreateLinkModal({
    open,
    onClose,
    entityId,
    entityType,
    roles,
}: CreateLinkModalProps): ReactElement {
    const [disabled, setDisabled] = useState(true);

    const [loading, setLoading] = useState<boolean>(false);

    const formRef = useRef<FormikProps<FormikValues>>(null);

    const onButtonClicked = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const onCancel = async () => {
        if (formRef.current?.dirty) {
            const res = await confirmViaDialog({
                confirmation: {
                    title: UNSAVED_CHANGES_TEXT,
                    message: SAVE_INFORMATION_TEXT,
                    cancelButtonText: EXIT_TEXT,
                    okButtonText: SAVE_TEXT,
                    onCancelPressed: () => onClose(false),
                },
            });
            res && onButtonClicked();
        } else {
            onClose(false);
        }
    };

    const onChange = (isValid: boolean) => {
        setDisabled(!isValid);
    };

    const onSubmit = async (values: FormikValues) => {
        try {
            setLoading(true);

            const response = await createLink(entityId, entityType, values.role, values.code);
            setLoading(false);
            if (response?.request?.status === 200) {
                onClose(response.data);
            }
        } catch (err) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <FormModal
            handleButtonClicked={onButtonClicked}
            disabled={disabled || loading}
            loading={loading}
            handleClose={() => onCancel()}
            open={open}
            fullWidth={false}
            buttonText="Create"
            title="Create New Link"
        >
            <CreateLinkForm
                roles={roles}
                formRef={formRef}
                onChange={onChange}
                onSubmit={onSubmit}
            />
        </FormModal>
    );
}
