import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getAllAttributesForCurrentUser } from 'api';
import { AxiosError } from 'axios';
import { Attribute, AttributeCategory } from 'utils';
import { RootState } from '.';

export interface AttributeError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export interface AttributeState {
    isLoading: boolean;
    data: Array<Attribute>;
    error: AttributeError;
}

export const initialState: AttributeState = {
    isLoading: false,
    data: [],
    error: { message: '' },
};

export const loadAttributes = createAsyncThunk<
    { data: Attribute[] },
    undefined,
    { rejectValue: AttributeError }
>('attributes/fetchAll', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getAllAttributesForCurrentUser();
        return {
            data,
        };
    } catch (err: any) {
        const error: AxiosError<AttributeError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Loading Attributes' });
    }
});

export const attributeSlice = createSlice({
    name: 'attributes',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadAttributes.fulfilled, (state, { payload }) => {
            state.data = payload.data;
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(loadAttributes.pending, (state) => {
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(loadAttributes.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Loading Attributes';
            state.isLoading = false;
        });
    },
});

const getAttributes = (state: RootState) => state.attributes.data;

export const getMemberAttributes = createSelector([getAttributes], (attributes) =>
    attributes.filter((a) => a.category === AttributeCategory.Member),
);

export const getMovementAttributes = createSelector([getAttributes], (attributes) =>
    attributes.filter((a) => a.category === AttributeCategory.Movement),
);

export const getSessionAttributes = createSelector([getAttributes], (attributes) =>
    attributes.filter((a) => a.category === AttributeCategory.Session),
);

export const getIndividualAttributes = createSelector([getAttributes], (attributes) =>
    attributes.filter((a) => a.category === AttributeCategory.Individual),
);

export const getMovementAttributesByOrganization = createSelector(
    [getAttributes, (_, organization_uuid) => organization_uuid],
    (movementAttributes, organization_uuid) =>
        movementAttributes.filter((a) => a.organization_uuid === organization_uuid),
);
