import { Chip, ChipProps } from '@mui/material';
import React, { ReactElement } from 'react';
import { cyan, green, orange, red, yellow } from '@mui/material/colors';
import { useTheme } from '@mui/system';

export type InviteStatusChipStatus = 'valid' | 'expired' | 'revoked' | 'declined' | 'accepted';

const getColor = (status: InviteStatusChipStatus): string => {
    switch (status) {
        case 'valid':
            return green[500];
        case 'expired':
            return yellow[200];
        case 'revoked':
            return red[500];
        case 'declined':
            return orange[500];
        case 'accepted':
            return cyan[500];
    }
};

export type InviteStatusChipProps = ChipProps & { status: InviteStatusChipStatus };

export default function InviteStatusChip({
    status,
    ...props
}: InviteStatusChipProps): ReactElement {
    const theme = useTheme();
    return (
        <Chip
            sx={{
                marginRight: theme.spacing(3),
                height: '28px',
                borderRadius: '8px',
                color: '#fff',
                backgroundColor: getColor(status),
            }}
            {...props}
        />
    );
}
