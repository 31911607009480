import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { NewSessionMovement, Session } from 'utils';

type ReturnType = Promise<AxiosResponse<Session>>;

const updateMovementOfSession = (
    updatedMovement: NewSessionMovement,
    sessionId: string,
    movementSessionUuid: string,
): ReturnType => {
    return Request.put(
        `/workouts/sessions/${sessionId}/movements/${movementSessionUuid}`,
        updatedMovement,
    );
};

export default updateMovementOfSession;
