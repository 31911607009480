import { useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

const FormikAutosave = () => {
    const formik = useFormikContext();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSubmit = useCallback(debounce(formik.submitForm, 1000), [formik.submitForm]);

    useEffect(() => {
        debouncedSubmit();
    }, [debouncedSubmit, formik.values]);

    return <></>;
};

export default FormikAutosave;
