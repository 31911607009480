import React, { useEffect, useState, useRef } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    TextField,
    useMediaQuery,
    useTheme,
    Select,
    MenuItem,
    Snackbar,
} from '@mui/material';
import { styled } from '@mui/system';
import {
    DataGridPremium,
    GridSortModel,
    GridRenderCellParams,
    GridColDef,
    GridValueFormatter,
} from '@mui/x-data-grid-premium';
import { debounce } from 'lodash';
import { Organization } from 'utils';
import { adminGetAllOrganizations } from '../../../admin.organizations.api';
import {
    getMarketplaceItems,
    updateMarketplaceItemResellStatus,
    GetMarketplaceItemsParams,
} from '../marketplace.api';
import { MarketplaceItem } from '../../../../../app.types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const ResponsiveFormItem = styled(Box)(({ theme }) => ({
    width: 'calc(25% - 16px)',
    maxWidth: '300px',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 16px)',
        maxWidth: 'none',
    },
}));

const ResponsiveForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    margin: theme.spacing(-1),
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));

const MarketplaceItemsList = () => {
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [marketplaceItems, setMarketplaceItems] = useState<MarketplaceItem[]>([]);
    const [searchParams, setSearchParams] = useState<GetMarketplaceItemsParams>({
        resell_status: 'pending',
        organization_uuid: '',
        sort: '-created_at',
        page: 1,
        per_page: 50,
    });
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [totalRows, setTotalRows] = useState(0);
    const searchParamsRef = useRef(searchParams);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [snackMessage, setSnackMessage] = React.useState<string | null>(null);
    const [hasSeenReviewRequestModal, setHasSeenReviewRequestModal] = useState<boolean>(false);

    useEffect(() => {
        searchParamsRef.current = searchParams;
    }, [searchParams]);

    useEffect(() => {
        fetchMarketplaceItems();
        setHasSeenReviewRequestModal(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel]);

    const fetchMarketplaceItems = async () => {
        try {
            const params = {
                ...searchParamsRef.current,
                page: paginationModel.page + 1,
                per_page: paginationModel.pageSize,
            };

            if (params.resell_status === 'all') {
                params.resell_status = '';
            }
            const response = await getMarketplaceItems(params);
            setMarketplaceItems(response.data.data);
            setTotalRows(response.data.meta.total);
            if (
                params.resell_status === 'pending' &&
                response.data.meta.total > 0 &&
                !hasSeenReviewRequestModal
            ) {
                openDialogModal(
                    'Review Requested',
                    `You have ${response.data.meta.total} marketplace items to review`,
                );
            }
        } catch (error) {
            console.error('Failed to fetch marketplace items', error);
            openDialogModal('Error', 'Failed to fetch marketplace items');
        }
    };

    const fetchOrganizations = debounce((searchTerm: string, perPage?: number) => {
        adminGetAllOrganizations(searchTerm, perPage)
            .then((response) => {
                setOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch organizations', error);
            });
    }, 300);

    useEffect(() => {
        fetchOrganizations('', 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearchParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = event.target;
        setSearchParams({
            ...searchParams,
            [name]: value,
        });
    };

    const handleMarketplaceItemSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const params = {
                ...searchParams,
                page: 1,
                per_page: paginationModel.pageSize,
            };
            await fetchMarketplaceItemSearchResults(params);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchMarketplaceItemSearchResults = async (params: GetMarketplaceItemsParams) => {
        try {
            if (params.resell_status === 'all') {
                params.resell_status = '';
            }
            const response = await getMarketplaceItems(params);
            setMarketplaceItems(response.data.data);
            setTotalRows(response.data.meta.total);
            setPaginationModel({ ...paginationModel, page: 0 });
        } catch (error) {
            console.error('Failed to fetch marketplace items', error);
            openDialogModal('Error', 'Failed to fetch marketplace items');
        }
    };

    const handleMarketplaceItemSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        const params = {
            ...searchParams,
            sort: sortField as GetMarketplaceItemsParams['sort'],
            page: paginationModel.page + 1,
            per_page: paginationModel.pageSize,
        };

        await fetchMarketplaceItemSearchResults(params);
    };

    const openDialogModal = (title: string, message: string) => {
        setDialogTitle(title);
        setDialogMessage(message);
        setOpenDialog(true);
    };

    const closeDialogModal = () => {
        setOpenDialog(false);
    };

    const handleResellStatusChange = async (uuid: string, newStatus: string) => {
        try {
            await updateMarketplaceItemResellStatus(uuid, newStatus);
            await fetchMarketplaceItems();
            setSnackMessage('Resell status updated successfully');
        } catch (error) {
            console.error('Failed to update resell status', error);
            openDialogModal('Error', 'Failed to update resell status');
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 500,
            sortable: true,
            renderCell: (params) => {
                return params.row.marketplaceable?.title;
            },
        },
        {
            field: 'organization_name',
            headerName: 'Organization',
            width: 300,
            sortable: true,
            renderCell: (params) => {
                return params.row.organization?.name;
            },
        },
        {
            field: 'marketplaceable.object',
            headerName: 'Type',
            width: 100,
            sortable: true,
            renderCell: (params) => {
                return params.row.marketplaceable?.object;
            },
        },
        {
            field: 'resell_status',
            headerName: 'Resell Status',
            width: 200,
            sortable: true,
            renderCell: (params: GridRenderCellParams<MarketplaceItem>) => (
                <Select
                    value={params.value}
                    onChange={(e) => handleResellStatusChange(params.row.uuid, e.target.value)}
                    size="small"
                >
                    <MenuItem value="disabled">Disabled</MenuItem>
                    <MenuItem value="enabled">Enabled</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                </Select>
            ),
        },
        {
            field: 'price',
            headerName: 'Price',
            width: 150,
            sortable: true,
            renderCell: (params) => {
                return `$${(params.value / 100).toFixed(2)}`;
            },
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 150,
            sortable: true,
            valueFormatter: ((params: number) => {
                const date = new Date(params * 1000);
                return date.toLocaleDateString('en-CA', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });
            }) as GridValueFormatter<MarketplaceItem>,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            sortable: false,
            renderCell: (params: GridRenderCellParams<MarketplaceItem>) => (
                <Button
                    variant="contained"
                    size="small"
                    startIcon={<OpenInNewIcon />}
                    onClick={() => {
                        let objectType = params.row.marketplaceable?.object;

                        const url =
                            objectType === 'session'
                                ? `${process.env.REACT_APP_URL}/train/sessions/${params.row.marketplaceable?.uuid}/view`
                                : `${process.env.REACT_APP_URL}/class/assessments/preview/${params.row.marketplaceable?.uuid}`;
                        window.open(url, '_blank');
                    }}
                >
                    PREVIEW
                </Button>
            ),
        },
    ];

    return (
        <Box sx={{ margin: theme.spacing(0, 2) }}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={!!snackMessage}
                message={snackMessage ?? ''}
                autoHideDuration={3000}
                onClose={() => setSnackMessage(null)}
            />
            <h2>Marketplace Items</h2>
            <section style={{ marginBottom: '2rem' }}>
                <h4>Filter Marketplace Items</h4>
                <ResponsiveForm onSubmit={handleMarketplaceItemSearch} noValidate>
                    <ResponsiveFormItem>
                        <Autocomplete
                            value={
                                organizations.find(
                                    (org) => org.uuid === searchParams.organization_uuid,
                                ) || null
                            }
                            onChange={(event, newValue) => {
                                setSearchParams({
                                    ...searchParams,
                                    organization_uuid: newValue ? newValue.uuid : '',
                                });
                            }}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchOrganizations(newInputValue);
                                }
                            }}
                            getOptionKey={(option) => option.uuid}
                            filterOptions={(options) => options}
                            size={'small'}
                            fullWidth
                            options={organizations}
                            getOptionLabel={(option) =>
                                `${option.name} (${option.subscription_tier?.name})`
                            }
                            renderOption={(props, option) => (
                                <li {...props}>
                                    {`${option.name} (${option.subscription_tier?.name}) - ${option.uuid}`}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} label="All Organizations" />
                            )}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            select
                            name="resell_status"
                            value={searchParams.resell_status}
                            onChange={handleSearchParameterChange}
                            label="Resell Status"
                            size={'small'}
                            fullWidth
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="disabled">Disabled</MenuItem>
                            <MenuItem value="enabled">Enabled</MenuItem>
                            <MenuItem value="pending">Pending</MenuItem>
                        </TextField>
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            size={'medium'}
                            variant="contained"
                            fullWidth={isNarrow}
                        >
                            Search Marketplace Items
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>
            <Box width="98%" maxHeight="600px" overflow="auto" className="dataGridContainer">
                <DataGridPremium
                    rows={marketplaceItems}
                    columns={columns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model) => handleMarketplaceItemSort(model)}
                    getRowId={(row) => row.uuid}
                    style={{ height: '624px', marginBottom: '50px' }}
                    paginationMode="server"
                    pagination
                    rowCount={totalRows}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                    disableColumnMenu={true}
                    sx={{
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                    }}
                />
            </Box>
            <Dialog
                open={openDialog}
                onClose={closeDialogModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Paper style={{ minWidth: '300px' }}>
                    <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialogModal} variant="contained" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        </Box>
    );
};

export default MarketplaceItemsList;
