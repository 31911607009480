import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { MovementChartData, MovementMetricFilter } from '../../../../app.types';
import { chooseFilters, loadMovements, loadUsers } from './actions';
import { MovementFilterState, movementsAdapter } from './movement.entity';
import { UserFilterState, usersAdapter } from './user.entity';

export interface ReportsFilterState {
    movements: MovementFilterState;
    users: UserFilterState;
    filters: MovementMetricFilter;
    data: MovementChartData;
    error: string | null;
}

// reducers
export const initialState: ReportsFilterState = {
    movements: movementsAdapter.getInitialState({
        loading: false,
        loaded: false,
        lastLoaded: null,
    }),
    filters: {
        user_key: [],
        movement_key: -1,
        starts_after: moment().subtract(30, 'year').unix(),
        ends_before: moment().unix(),
        measurement: 'min',
        metric: 'time_value',
        order_direction: 'asc',
        group_by: 'day',
    },
    data: [],
    users: usersAdapter.getInitialState({
        loading: false,
        loaded: false,
    }),
    error: null,
};

// base slice
export const reportsSlice = createSlice({
    name: 'plan/reports',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadUsers.pending, (state) => {
            state.users.loading = true;
        });
        addCase(loadUsers.fulfilled, (state, { payload }) => {
            usersAdapter.setAll(state.users, payload);
            state.users.loading = false;
            state.users.loaded = true;
        });
        addCase(loadUsers.rejected, (state) => {
            state.users.loading = false;
        });
        addCase(loadMovements.fulfilled, (state, action) => {
            movementsAdapter.setAll(state.movements, action.payload);
            if (action.payload.length === 0) {
                state.data = [];
            }
            if (action.payload.length > 0 && state.filters.movement_key < 1) {
                state.filters.movement_key = action.payload[0].movement_key;
            }
        });
        addCase(chooseFilters.pending, (state, action) => {
            state.filters = action.meta.arg;
        });
        addCase(chooseFilters.fulfilled, (state, action) => {
            state.data = action.payload;
        });
    },
});
