import { Skeleton } from '@mui/material';
import React from 'react';

export default function RowSkeleton(): React.ReactElement {
    return (
        <>
            <Skeleton animation="wave" height={100} style={{ marginBottom: 8, width: '100%' }} />
            <Skeleton animation="wave" height={100} style={{ marginBottom: 8, width: '100%' }} />
            <Skeleton animation="wave" height={100} style={{ marginBottom: 8, width: '100%' }} />
        </>
    );
}
