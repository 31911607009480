import { useEffect, useState } from 'react';
import { getTags } from '../../../api/tags.api';
import { Tag } from '../../../app.types';
import CheckboxFilterList from '../../../components/Inputs/CheckboxFilterList';

interface Props {
    value: string[];
    onChange: (v: string[]) => void;
}

export function SprintCategoryFilter(props: Props) {
    const [tags, setTags] = useState<Tag[]>([]);
    useEffect(() => {
        getTags({
            'filter[type]': 'uploads',
        })
            .then((response) => {
                setTags(response.data);
            })
            .catch(() => {})
            .finally(() => {});
    }, []);
    return (
        <CheckboxFilterList
            options={tags.map((t) => ({ label: t.name, value: t.id }))}
            buttonText={'Category'}
            {...props}
        />
    );
}
