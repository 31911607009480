import Snackbar from '@mui/material/Snackbar';
import React, { ReactElement, useEffect, useState } from 'react';
import { LinearProgressWithLabel } from 'components/Functional';
import SnackbarContent from '@mui/material/SnackbarContent';
import { PartialUploadList } from 'utils';
import { useAppDispatch } from '../../redux/store';
import { Button } from '@mui/material';
import { closeUploads } from '../../redux/reducers/media';

export interface UploadsSnackBarProps {
    /** uploads A list of uploads to display */
    uploads: PartialUploadList;
}

export default function UploadsSnackBar({ uploads }: UploadsSnackBarProps): ReactElement {
    const [avgUploadProgress, setAvgUploadProgress] = useState(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const arr = Object.keys(uploads);
        const getProgress = (key: string) => uploads[key]['progress'];
        const avg = arr.reduce((a, c) => a + getProgress(c), 0) / arr.length;
        setAvgUploadProgress(avg);
    }, [uploads]);

    useEffect(() => {
        if (avgUploadProgress === 1) {
            dispatch(closeUploads());
        }
    }, [dispatch, avgUploadProgress]);

    const generateMessage = (): React.ReactNode => {
        return (
            <React.Fragment>
                {avgUploadProgress < 1 && 'Uploads In Progress'}
                {avgUploadProgress === 1 && 'Uploads Pending'}
                <LinearProgressWithLabel value={avgUploadProgress * 100} />
            </React.Fragment>
        );
    };
    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={true}
            >
                <SnackbarContent
                    sx={{
                        '.MuiSnackbarContent-message': {
                            minWidth: 200,
                            color: (theme) => theme.palette.primary.contrastText,
                        },
                        flex: '1 1 auto',
                    }}
                    message={generateMessage()}
                    action={
                        <Button color={'secondary'} onClick={() => dispatch(closeUploads())}>
                            Close
                        </Button>
                    }
                />
            </Snackbar>
        </React.Fragment>
    );
}
