import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Meta, Movement, PaginationLink } from 'utils';

type GetAllMovements = {
    length: number;
    data: Movement[];
    links: PaginationLink;
    meta: Meta;
};

type ReturnType = Promise<AxiosResponse<GetAllMovements>>;

const getAllMovements = (query = {}): ReturnType => {
    return Request.get(`/workouts/movements`, {
        params: query,
    });
};

export default getAllMovements;
