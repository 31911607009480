import { Grid, Skeleton } from '@mui/material';
import React, { ReactElement } from 'react';

export interface TableWithCardsSkeletonProps {}

export default function TableWithCardsSkeleton(): ReactElement {
    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Skeleton animation={false} />
            </Grid>
            <Grid item xs={9}>
                <Skeleton animation={false} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton animation="wave" variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton animation="wave" variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton animation="wave" variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton animation="wave" variant="rectangular" height={50} />
            </Grid>
            <Grid item xs={9}></Grid>
            <Grid item xs={3}>
                <Skeleton animation="wave" />
            </Grid>
        </Grid>
    );
}
