import { Box, Button, Dialog, LinearProgress } from '@mui/material';
import useTheme from '@mui/system/useTheme';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { getAllForms } from '../../../api';
import { getSessions } from '../api/sessions';
import SelectObjectModalContents from './SelectObjectModalContents';

interface Props {
    open: boolean;
    fullScreen: boolean;
    onClose: () => void;
    onSelect: (objects: { [key: string]: any }) => void;
    filters: { 'filter[organization]'?: string };
    objectType?: 'session' | 'form';
    allowMultiple?: boolean;
    uniqueKey?: string;
}

const SelectContentModal: React.FC<React.PropsWithChildren<Props>> = ({
    allowMultiple = false,
    objectType = 'session',
    uniqueKey = 'uuid',
    filters,
    ...props
}: Props) => {
    const theme = useTheme();
    const [selectedObjects, setSelectedObjects] = useState<{ [key: string]: any }>({});
    const [searchValue, setSearchValue] = useState<string>('');
    const [debouncedSearchValue] = useDebounce(searchValue, 300);
    const [objects, setObjects] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        if (objectType === 'session') {
            getSessions({
                ...filters,
                limit: '100',
                'filter[title]': debouncedSearchValue,
            })
                .then((response) => {
                    setObjects(response.data.data);
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        } else if (objectType === 'form') {
            getAllForms({
                ...filters,
                'filter[title]': debouncedSearchValue,
            })
                .then((response) => {
                    setObjects(response.data.data);
                })
                .catch(() => {})
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [objectType, filters, debouncedSearchValue]);

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            fullScreen={props.fullScreen}
            sx={{
                width: '100%',
                maxWidth: 736,
                padding: 8,
                [theme.breakpoints.up('sm')]: {
                    height: 500,
                    padding: 30,
                },
                backgroundColor: '#ECEFF5',
                position: 'relative',
            }}
        >
            {loading && (
                <LinearProgress style={{ position: 'absolute', top: 0, left: 0, right: 0 }} />
            )}
            <SelectObjectModalContents
                coverPhotoObjectPath={
                    objectType === 'session' ? 'cover_photo' : 'image_urls.avatar'
                }
                searchPlaceholder={
                    objectType === 'session' ? 'Search Session...' : 'Search Assessments...'
                }
                noneFoundText={
                    objectType === 'session' ? 'No Sessions Found' : 'No Assessments Found'
                }
                notSelectedText={objectType === 'session' ? 'Add Session' : 'Add Assessment'}
                objects={objects.reduce(
                    (newSessions: { [key: string]: any }, session) => ({
                        ...newSessions,
                        [session[uniqueKey]]: session,
                    }),
                    {},
                )}
                selectedObjects={selectedObjects}
                onChange={(objects) => {
                    if (allowMultiple) {
                        setSelectedObjects({ ...objects });
                    } else {
                        if (Object.values(objects).length == 1) {
                            props.onSelect(objects);
                            props.onClose();
                        }
                    }
                }}
                searchValue={searchValue}
                searchValueChanged={(event) => setSearchValue(event.target.value)}
            />
            <Box
                display={'flex'}
                justifyContent={
                    Object.keys(selectedObjects).length > 0 ? 'space-between' : 'center'
                }
                alignItems={'center'}
                position="absolute"
                bottom="0"
                right={0}
                left={0}
                height={60}
                px={8}
                style={{ backgroundColor: '#ECEFF5' }}
            >
                <Button onClick={() => props.onClose()} color={'primary'} variant={'outlined'}>
                    Close
                </Button>
                {Object.keys(selectedObjects).length > 0 && allowMultiple && (
                    <Button
                        onClick={() => {
                            props.onSelect(selectedObjects);
                            props.onClose();
                        }}
                        color={'primary'}
                        variant={'contained'}
                    >
                        Add Selected Sessions
                    </Button>
                )}
            </Box>
        </Dialog>
    );
};

export default SelectContentModal;
