import React, { ReactElement } from 'react';

interface Props {
    children: React.ReactNode;
}

export default function CardTitleText({ children }: Props): ReactElement {
    return (
        <span style={{ textTransform: 'uppercase', fontWeight: 600, fontSize: 16, lineHeight: 2 }}>
            {children}
        </span>
    );
}
