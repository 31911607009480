import { AbilityContext } from 'components/Functional/Can';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'redux/reducers/auth';
import { getUserPermissions, userSelector } from 'redux/reducers/user';
import { useAppDispatch } from 'redux/store';
import { buildAbilityFor } from 'utils/ability';

// This component is loading user permissions on every route change
// and then updating the ability of the user
// This happens only if user is already logged in

const LoadUserPermissions: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const { isAuth } = useSelector(authSelector);
    const { userData, userPermissions } = useSelector(userSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (userData?.uuid) {
            dispatch(getUserPermissions({ id: userData.uuid }));
        }
    }, [dispatch, userData]);

    return (
        <AbilityContext.Provider
            value={buildAbilityFor(isAuth ? userData : null, isAuth ? userPermissions : [])}
        >
            {children}
        </AbilityContext.Provider>
    );
};

export default LoadUserPermissions;
