import { CircularProgress, Grid, Typography } from '@mui/material';
import { useMemo } from 'react';

interface SpeedManagementPlayerMetricsGridProps {
    loading: boolean;
    title: string;
    sprints: number | null;
    distance: number | null;
    playerLoad: number | null;
    accelerations: number | null;
    decelerations: number | null;
    highSpeedSprints: number | null;
    highSpeedDistanceEighty: number | null;
    highSpeedDistanceEightyFive: number | null;
}

const SpeedManagementPlayerMetricsGrid = ({
    loading,
    title,
    sprints,
    distance,
    playerLoad,
    accelerations,
    decelerations,
    highSpeedSprints,
    highSpeedDistanceEighty,
    highSpeedDistanceEightyFive,
}: SpeedManagementPlayerMetricsGridProps) => {
    const formatYardsToMiles = useMemo(
        () =>
            (yards: number): string => {
                if (yards < 1760) {
                    return `${Math.round(yards)} yards`;
                }
                const miles = yards / 1760;
                return `${miles.toFixed(3)} miles`;
            },
        [],
    );
    return (
        <>
            <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ mb: 1, textAlign: 'center' }}
            >
                {title}
            </Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                        # Sprints
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {loading ? (
                            <CircularProgress size={16} />
                        ) : sprints === null ? (
                            '-'
                        ) : (
                            sprints
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                        # Sprints &gt; 85% Max Speed
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {loading ? (
                            <CircularProgress size={16} />
                        ) : highSpeedSprints === null ? (
                            '-'
                        ) : (
                            highSpeedSprints
                        )}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                        Total Distance
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {loading ? (
                            <CircularProgress size={16} />
                        ) : distance === null ? (
                            '-'
                        ) : (
                            formatYardsToMiles(distance)
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                        Player Load
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {loading ? (
                            <CircularProgress size={16} />
                        ) : playerLoad === null ? (
                            '-'
                        ) : (
                            playerLoad
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                        # Of Accels
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {loading ? (
                            <CircularProgress size={16} />
                        ) : accelerations === null ? (
                            '-'
                        ) : (
                            accelerations
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                        # Of Decels
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {loading ? (
                            <CircularProgress size={16} />
                        ) : decelerations === null ? (
                            '-'
                        ) : (
                            decelerations
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                        Distance &gt; 85% Max Speed
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {loading ? (
                            <CircularProgress size={16} />
                        ) : highSpeedDistanceEightyFive === null ? (
                            '-'
                        ) : (
                            // Random amount as a percent from 0.5% to 3%
                            formatYardsToMiles(highSpeedDistanceEightyFive)
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                        Distance &gt; 80% Max Speed
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>
                        {loading ? (
                            <CircularProgress size={16} />
                        ) : highSpeedDistanceEighty === null ? (
                            '-'
                        ) : (
                            // Random amount as a percent from 0.5% to 3%
                            formatYardsToMiles(highSpeedDistanceEighty)
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default SpeedManagementPlayerMetricsGrid;
