import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListSubheader,
    TextField,
} from '@mui/material';
import { createHierarchyTag, getHierarchyTags } from '../../api/tags.api';
import { Tag, TagType } from '../../app.types';
import { Organization } from '../../utils';

const ViewAndCreateTagsDialog: React.FC<
    React.PropsWithChildren<{
        open: boolean;
        onClose: () => void;
        onCreated: (tag: Tag) => void;
        organization: Organization;
        title?: string;
        listTitle?: string;
        type: TagType;
    }>
> = ({
    open,
    onClose,
    organization,
    type,
    onCreated,
    title = 'Create New Tag',
    listTitle = 'Existing Positions',
}) => {
    const [tags, setTags] = useState<Tag[]>([]);
    const [newTag, setNewTag] = useState<string>('');

    useEffect(() => {
        if (open && organization && type) {
            (async () => {
                try {
                    const response = await getHierarchyTags({
                        'filter[type]': type,
                        'filter[organization]': organization.uuid,
                    });
                    setTags(response.data);
                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [open, organization, type]);

    const handleCreate = useCallback(() => {
        (async () => {
            try {
                const createResponse = await createHierarchyTag({
                    name: newTag,
                    organization: organization.uuid,
                    type: type,
                });
                setTags([...tags, createResponse.data]);
                onCreated(createResponse.data);
                setNewTag('');
            } catch (e) {
                console.log(e);
            }
        })();
    }, [organization, type, newTag, tags, onCreated]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Enter New Position Here"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                />
                <List subheader={<ListSubheader>{listTitle}</ListSubheader>}>
                    {tags.map((tag) => (
                        <ListItem key={tag.id}>{tag.name}</ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewAndCreateTagsDialog;
