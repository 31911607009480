import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Movement } from 'utils';

type ReturnType = Promise<AxiosResponse<Movement>>;

const removeAsset = (assetUuid: string, movementUuid: string): ReturnType => {
    return Request.delete(`/workouts/movements/${movementUuid}/assets/${assetUuid}`);
};

export default removeAsset;
