import React from 'react';

import Lottie from 'lottie-react';
import Loader from '../../lottie-animations/Loader.json';

const CustomLoadingOverlay = () => {
    return (
        <div
            style={{
                position: 'relative',
                top: '50%',
                left: '100%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Lottie
                animationData={Loader} // Correct property name
                loop={true}
                autoplay={true}
                style={{
                    width: '100px', // Adjust the width and height as needed
                    height: '100px',
                    cursor: 'pointer',
                }}
            />
        </div>
    );
};

export default CustomLoadingOverlay;
