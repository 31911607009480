import CheckboxFilterList from '../../../components/Inputs/CheckboxFilterList';

interface Props {
    value: string[];
    onChange: (v: string[]) => void;
}

export function PersonalRecordForSprintsFilter(props: Props) {
    return (
        <CheckboxFilterList
            options={[{ label: 'Max Vmax', value: 'FactSprintsRanked.isVmaxPersonalRecord' }]}
            buttonText={'Max / Min'}
            {...props}
        />
    );
}
