import { Info } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    IconButton,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getTags } from '../../../api/tags.api';
import { Tag } from '../../../app.types';
import {
    SearchIndividual,
    SelectDateChip,
    SideDrawerWithTitleAndActions,
    TypographyBodyOne,
    TypographySubtitle,
    TypographyTitle,
} from '../../../components';
import MclloydLogo from '../../../images/McLLOYDLogo.png';
import Session from '../../../images/SessionSummaryMcLloyd.png';
import { Group, Individual } from '../../../utils';
import { processGpsSessionForGroup, validateGpsSessionFromFile } from '../api/gpsAnalysis.api';
import { viewGroup } from '../api/groups.api';
import { mapTagIntoFreeSoloOption } from '../community.func';
import FreeSoloCreateOptionDialog, {
    FreeSoloOption,
} from '../components/FreeSoloCreateOptionDialog';

function GroupGpsSummaryCreate() {
    const [group, setGroup] = useState<Group>();
    const [csvFile, setCsvFile] = useState<FileList | null>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [dateTime, setDateTime] = useState<DateTime | null>(null);
    const [tag, setTag] = useState<FreeSoloOption | null>(null);
    const [tags, setTags] = useState<Tag[]>([]);
    const [snackMessage, setSnackMessage] = useState<string | null>(null);
    const [athleteNames, setAthleteNames] = useState<{ [key: string]: Individual } | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [formValues, setFormValues] = useState<{
        path: string;
        identifiers: { [key: string]: Individual | null };
        date: string;
        tag: string | null;
        file_name: string | null;
    }>({
        path: '',
        identifiers: {},
        date: '',
        tag: null,
        file_name: null,
    });
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [path, setPath] = useState<string | null>(null);
    const { id } = useParams<{ id: string }>();
    const { push } = useHistory();

    const onSubmit = useCallback(() => {
        setProcessing(true);

        const processWithDelay = () => {
            return new Promise((resolve) => {
                setTimeout(resolve, 4500); // Add a 3-second delay
            });
        };

        processGpsSessionForGroup(id, {
            ...formValues,
            identifiers: Object.keys(formValues.identifiers).map((key) => ({
                identifier: key,
                uuid: formValues.identifiers[key]?.uuid ?? null,
            })),
        })
            .then((result) => {
                push(`/community/groups/${group?.uuid}/gps-summary/${result.data.uuid}`);
            })
            .catch(() => {
                setSnackMessage('Unexpected error while saving.');
            })
            .finally(() => {
                setProcessing(false);
                setDrawerOpen(false);
                setPath(null);
                setAthleteNames(null);
                setCsvFile(null);
                // After the .finally code is executed, add the 3-second delay
                return processWithDelay();
            });
    }, [id, formValues, group, push]);

    useEffect(() => {
        setFormValues((oldValues) => ({
            ...oldValues,
            path: path ?? '',
            date: dateTime ? dateTime.toFormat('yyyy-MM-dd HH:mm:ss') : '',
            tag: tag?.id ?? null,
        }));
    }, [path, dateTime, tag]);

    useEffect(() => {
        getTags({ 'filter[type]': 'reports' })
            .then((response) => {
                setTags(response.data);
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        if (path && athleteNames) {
            setDrawerOpen(true);

            setFormValues((previousValues) => ({
                ...previousValues,
                path: path,
                identifiers: Object.keys(athleteNames).reduce((obj: any, key: any) => {
                    obj[key] = athleteNames[key] ?? null;
                    return obj;
                }, {}),
            }));
        }
    }, [path, athleteNames]);

    useEffect(() => {
        viewGroup(id)
            .then((groupResponse) => {
                setGroup(groupResponse.data);
                setLoaded(true);
            })
            .catch(() => {
                setLoaded(false);
            });
    }, [id, setGroup]);

    useEffect(() => {
        // upload csv file and check if it's valid
        setFormValues((v) => ({
            ...v,
            file_name: csvFile && csvFile.length > 0 ? csvFile[0].name : null,
        }));
        if (csvFile && csvFile.length > 0) {
            setUploading(true);
            setSnackMessage(null);
            validateGpsSessionFromFile(id, csvFile)
                .then((response) => {
                    setPath(response.data.path);
                    setAthleteNames(response.data.athletes);
                })
                .catch((e) => {
                    if (e.response.status === 409 || e.response.status === 422) {
                        setSnackMessage(
                            e.response.data.errors[Object.keys(e.response.data.errors)[0]][0],
                        );
                    } else {
                        setSnackMessage('Unexpected error while saving.');
                    }
                })
                .finally(() => {
                    setUploading(false);
                });
        }
    }, [csvFile, id]);

    return (
        <Container maxWidth={'xl'} style={{ paddingTop: 16 }}>
            {!loaded && <TypographyTitle>Loading Details...</TypographyTitle>}
            {group && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid',
                            borderBottomColor: grey[500],
                            padding: 12,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Avatar
                                sx={{ width: 80, height: 80, marginRight: 2 }}
                                alt={group.name}
                                src={group.image_urls.avatar || ''}
                                variant={'rounded'}
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <TypographyTitle>{group.name}</TypographyTitle>
                                <TypographySubtitle>
                                    Upload A New GPS Session Summary CSV File
                                </TypographySubtitle>
                            </Box>
                        </Box>
                        <img src={MclloydLogo} height="60" alt={'STV4'} />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: 24,
                        }}
                    >
                        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                            <DialogContent>
                                <img
                                    src={Session}
                                    alt="Info"
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </DialogContent>
                        </Dialog>
                        <TypographyTitle sx={{ mb: 12 }}>
                            Upload McLloyd STv4 session export ending in &quot;.NUMBERS&quot; or
                            &quot;.EXCEL&quot; with &quot;Player Load&quot; column.
                            <IconButton color="primary" onClick={() => setOpenDialog(true)}>
                                <Info />
                            </IconButton>
                        </TypographyTitle>
                        <TableContainer component={Paper} sx={{ maxWidth: 475 }}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Csv File
                                        </TableCell>
                                        <TableCell align="right">
                                            {csvFile && csvFile.length > 0 && (
                                                <Box>
                                                    <TypographyBodyOne sx={{ mb: 12 }}>
                                                        {csvFile[0].name}
                                                    </TypographyBodyOne>
                                                    {uploading && (
                                                        <TypographyBodyOne sx={{ mb: 12 }}>
                                                            Uploading...
                                                        </TypographyBodyOne>
                                                    )}
                                                </Box>
                                            )}
                                            <label htmlFor="csv-upload-button">
                                                <input
                                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    name="csv-upload-button"
                                                    id="csv-upload-button"
                                                    type="file"
                                                    hidden
                                                    onChange={(event) => {
                                                        setCsvFile(event.target.files);
                                                    }}
                                                />
                                                <Button
                                                    disabled={uploading}
                                                    variant={'contained'}
                                                    component={'span'}
                                                >
                                                    {uploading ? (
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '10px',
                                                            }}
                                                        >
                                                            <CircularProgress
                                                                color="inherit"
                                                                size={24}
                                                            />
                                                            Uploading File Please Wait...
                                                        </Box>
                                                    ) : (
                                                        `Upload ${csvFile ? 'Another' : ''} File`
                                                    )}
                                                </Button>
                                            </label>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography>
                            Click the info icon if you need help locating the file in McLloyd.{' '}
                            <IconButton color="primary" onClick={() => setOpenDialog(true)}>
                                <Info />
                            </IconButton>
                        </Typography>
                    </Box>
                </>
            )}
            {athleteNames && (
                <SideDrawerWithTitleAndActions
                    open={drawerOpen}
                    disabled={!dateTime || processing}
                    onClose={() => {
                        setDrawerOpen(false);
                    }}
                    onSubmit={() => onSubmit()}
                    submitButtonText={processing ? 'Processing...' : 'Process GPS Summary'}
                    processing={processing}
                    title={
                        <Box>
                            <Box>Match The Athletes From Your .csv File To 1Tul Athletes</Box>
                            <Box>
                                <TypographySubtitle>
                                    Note: We will create new athletes for the ones you do not
                                    select. Press Remove to remove an athlete from the list.
                                </TypographySubtitle>
                            </Box>
                        </Box>
                    }
                    body={
                        <Box sx={{ width: '100%' }}>
                            <Table sx={{ width: '100%' }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            File Name
                                        </TableCell>
                                        <TableCell align="right">
                                            {formValues.file_name ?? '-'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Date/Time
                                        </TableCell>
                                        <TableCell align="right">
                                            <SelectDateChip
                                                type={'datetime'}
                                                onChange={(v) => setDateTime(v)}
                                                value={dateTime}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {group && (
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Tag
                                            </TableCell>
                                            <TableCell align="right">
                                                <FreeSoloCreateOptionDialog
                                                    placeholder={
                                                        'Choose category (optional, for categorizing data)'
                                                    }
                                                    value={tag ? tag : null}
                                                    onChange={(v) => setTag(v)}
                                                    organization={
                                                        group.organization
                                                            ? group.organization.uuid
                                                            : ''
                                                    }
                                                    choices={tags.map((t) =>
                                                        mapTagIntoFreeSoloOption(t),
                                                    )}
                                                    onTagCreated={() => {}}
                                                    createProps={{ type: 'reports' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                <span>GPS Athlete Name</span>
                                <KeyboardArrowRightIcon />
                                <span>1Tul Athlete Name</span>
                            </Box>
                            {Object.keys(formValues.identifiers).map((name) => (
                                <Box
                                    key={name}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span>{name}</span>
                                    <KeyboardArrowRightIcon />
                                    {group && group.organization && (
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <SearchIndividual
                                                buttonText={
                                                    formValues.identifiers[name]?.name ?? '-'
                                                }
                                                createButtonText={''}
                                                organization={group.organization}
                                                onIndividualSelected={(individual) => {
                                                    setFormValues((oldValues) => ({
                                                        ...oldValues,
                                                        identifiers: {
                                                            ...oldValues.identifiers,
                                                            [name]: individual[0] ?? null,
                                                        },
                                                    }));
                                                }}
                                                multi={false}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                        name
                                                            .replace(/^\d+\./, '')
                                                            .trim()
                                                            .toLowerCase() !==
                                                        formValues.identifiers[name]?.name
                                                            ?.trim()
                                                            .toLowerCase()
                                                            ? 'rgba(255, 0, 132, 0.166)'
                                                            : 'inherit',
                                                }}
                                            />
                                            {formValues.identifiers[name] && (
                                                <Button
                                                    onClick={() => {
                                                        setFormValues((oldValues) => ({
                                                            ...oldValues,
                                                            identifiers: {
                                                                ...oldValues.identifiers,
                                                                [name]: null,
                                                            },
                                                        }));
                                                    }}
                                                >
                                                    Clear
                                                </Button>
                                            )}
                                            <Button
                                                onClick={() => {
                                                    setFormValues((oldValues) => {
                                                        const newIdentifiers = {
                                                            ...oldValues.identifiers,
                                                        };
                                                        delete newIdentifiers[name];
                                                        return {
                                                            ...oldValues,
                                                            identifiers: newIdentifiers,
                                                        };
                                                    });
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    }
                    loading={false}
                />
            )}
            <Snackbar
                open={!!snackMessage}
                message={snackMessage ?? ''}
                autoHideDuration={3000}
                onClose={() => setSnackMessage(null)}
            />
        </Container>
    );
}

export default GroupGpsSummaryCreate;
