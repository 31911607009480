import { bulkLogActivity, endActivity, updateActivity } from 'api';
import { AxiosResponse } from 'axios';
import { confirmViaDialog } from 'components';
import convert from 'convert-units';
import { cloneDeep, omit } from 'lodash';
import React from 'react';
import { pushMessage } from 'redux/reducers/messages';
import { store } from 'redux/store';
import {
    AccelerationUnit,
    Activity,
    DistanceUnit,
    ForceUnit,
    LoadUnit,
    Log,
    Movement,
    MovementLogReq,
    PowerUnit,
    RangeOfMotionUnit,
    SessionMovement,
    SetAttributeType,
    SpeedUnit,
    TimeDisplayFormat,
    TimeUnit,
} from 'utils';
import { v4 as uuidv4 } from 'uuid';

const timeDisplayHierarchy: string[] = [
    'ss',
    'ss.SSS',
    'mm:ss',
    'mm:ss.SSS',
    'hh:mm:ss',
    'hh:mm:ss.SSS',
];

interface LogDomainInterface {
    saveLogUpdates: (activity: Activity, updatedLogs: Array<Log>) => Promise<AxiosResponse>;
    endActivityLog: (activity: Activity) => Promise<AxiosResponse>;
    updateActivity: (
        activity: Activity,
        payload: { notes?: string; date?: string | null },
    ) => Promise<AxiosResponse>;
    makeLogFromSession: (sessionMovement: SessionMovement) => Log;
    updateActivityLogStatus: (
        logs: Array<Log>,
        updatedLog: Log,
        setLogs: React.Dispatch<any>,
    ) => void;
    updateActivityLogSetStatus: (
        logs: Array<Log>,
        updatedLogs: Array<Log>,
        setLogs: React.Dispatch<any>,
    ) => void;
    updateActivityLogValue: (
        updatedLog: Log,
        updatedLogCriteria: SetAttributeType,
        newValue: number,
        setLogs: React.Dispatch<any>,
    ) => void;
    addActivityLog: (
        activity: Activity,
        logs: Array<Log>,
        previousLog: Log,
        setLogs: React.Dispatch<any>,
    ) => void;
    removeActivityLog: (
        activity: Activity,
        logs: Array<Log>,
        removedLog: Log,
        setLogs: React.Dispatch<any>,
    ) => void;
    removeMultipleActivityLogs: (
        activity: Activity,
        logs: Array<Log>,
        removedLogs: Array<Log>,
        setLogs: React.Dispatch<any>,
    ) => void;
    addCriteriaToActivityLog: (
        logs: Array<Log>,
        updatedLogs: Array<Log>,
        criteria: SetAttributeType,
        setLogs: React.Dispatch<any>,
    ) => void;
    removeCriteriaFromActivityLog: (
        logs: Array<Log>,
        updatedLogs: Array<Log>,
        criteria: SetAttributeType,
        setLogs: React.Dispatch<any>,
    ) => void;
    onMultipleMovementsAddedToActivityLog: (
        logs: Array<Log>,
        addedMovements: Array<Movement>,
        setLogs: React.Dispatch<any>,
    ) => void;
    markAllActivityLogsAsComplete: (logs: Array<Log>, setLogs: React.Dispatch<any>) => void;
    changeActivityLogUnit: (
        updatedLogs: Array<Log>,
        criteria: SetAttributeType,
        oldUnit: string,
        newUnit: string,
        setLogs: React.Dispatch<any>,
    ) => void;
}

const LogDomain: LogDomainInterface = {
    endActivityLog(activity: Activity): Promise<AxiosResponse> {
        // TBD  : Need to take this timezone from user settings or asking user about timezond in which the log was started or ended
        let localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toString();
        return endActivity(localTimeZone, activity.uuid);
    },
    updateActivity(
        activity: Activity,
        payload: { notes?: string; date?: string | null },
    ): Promise<AxiosResponse> {
        return updateActivity(activity.uuid, payload);
    },
    // This is used to save the log updates in the backend
    saveLogUpdates(activity: Activity, updatedLogs: Array<Log>): Promise<AxiosResponse> {
        // We need to only send updates in the backend when there are no errors.
        // Ad error here is a value which is out of bounds. like a load of 1000000000 is not valid.
        let isError = false;

        let payload: Array<MovementLogReq> =
            updatedLogs?.map((log, orderIndex) => {
                // Check for load value out of bounds
                if (log.load_value && log.load_value > Math.pow(10, 5) && !isError) {
                    store.dispatch(
                        pushMessage({
                            status: 'error',
                            message: `Load value ${log.load_value} of ${
                                log.movement?.name || ''
                            } is too large. Please use bigger load scale if available.`,
                        }),
                    );
                    isError = true;
                }
                // Check for distance value out of bounds
                if (log.distance_value && log.distance_value > Math.pow(10, 5) && !isError) {
                    store.dispatch(
                        pushMessage({
                            status: 'error',
                            message: `Distance value ${log.distance_value} of ${
                                log.movement?.name || ''
                            } is too large. Please use bigger distance scale if available.`,
                        }),
                    );
                    isError = true;
                }

                if (
                    log.acceleration_value &&
                    log.acceleration_value > Math.pow(10, 3) &&
                    !isError
                ) {
                    store.dispatch(
                        pushMessage({
                            status: 'error',
                            message: `Acceleration value ${log.acceleration_value} of ${
                                log.movement?.name || ''
                            } is too large.`,
                        }),
                    );
                    isError = true;
                }

                if (log.power_value && log.power_value > Math.pow(10, 5) && !isError) {
                    store.dispatch(
                        pushMessage({
                            status: 'error',
                            message: `Power value ${log.power_value} of ${
                                log.movement?.name || ''
                            } is too large.`,
                        }),
                    );
                    isError = true;
                }

                if (log.range_of_motion_value && log.range_of_motion_value > 360 && !isError) {
                    store.dispatch(
                        pushMessage({
                            status: 'error',
                            message: `Range Of Motion value ${log.range_of_motion_value} of ${
                                log.movement?.name || ''
                            } is too large. Please use a value between 0 and 360 degrees.`,
                        }),
                    );
                    isError = true;
                }

                // Check for time value out of bounds

                if (log.time_value && log.time_value > Infinity && !isError) {
                    store.dispatch(
                        pushMessage({
                            status: 'error',
                            message: `Time value  of ${
                                log.movement?.name || ''
                            } is too large. Please add a duration less or equal to than 12 hours.`,
                        }),
                    );
                    isError = true;
                }

                return {
                    uuid: log.uuid,
                    reps: log.reps ?? null,
                    load_unit: log.load_unit || LoadUnit.Pound,
                    load_value: log.load_value ?? null,
                    distance_unit: log.distance_unit || DistanceUnit.Yard,
                    distance_value: log.distance_value ?? null,
                    time_unit: log.time_unit ?? TimeUnit.Millisecond,
                    time_display_format: log.time_display_format || TimeDisplayFormat.Common,
                    time_value: log.time_value ?? null,
                    rest_unit: log.rest_unit || TimeUnit.Second,
                    rest_value: log.rest_value ?? null,
                    range_of_motion_value: log.range_of_motion_value ?? null,
                    range_of_motion_unit: log.range_of_motion_unit || RangeOfMotionUnit.Degree,
                    speed_value: log.speed_value ?? null,
                    speed_unit: log.speed_unit || SpeedUnit.MPH,
                    acceleration_value: log.acceleration_value ?? null,
                    acceleration_unit: log.acceleration_unit || AccelerationUnit.FPS,
                    power_value: log.power_value ?? null,
                    power_unit: log.power_unit || PowerUnit.Watt,
                    rsi_value: log.rsi_value ?? null,
                    rpm_value: log.rpm_value ?? null,
                    force_value: log.force_value ?? null,
                    force_unit: log.force_unit || ForceUnit.Newton,
                    movement: log.movement?.uuid ?? null,
                    order: orderIndex + 1,
                    marked_as_complete: log.marked_as_complete || false,
                    body_side: log.body_side ?? null,
                    rpe_value: log.rpe_value ?? null,
                    band_value: log.band_value ?? null,
                    gps_player_load_value: log.gps_player_load_value ?? null,
                    gps_accel_count_value: log.gps_accel_count_value ?? null,
                    gps_decel_count_value: log.gps_decel_count_value ?? null,
                } as MovementLogReq;
            }) || [];

        let localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toString();

        // If error exists, then do not update the logs in backend
        if (!activity || isError) {
            return Promise.reject(new Error('Error occured before saving'));
        }

        return bulkLogActivity(payload, activity.uuid, localTimeZone);
    },
    // We have the concept of session movement in a log. Which essentially ties which movements are part of a session
    // However, logs is a different backend context. So we make a log object from a session movement object here
    // when there are no logs for this activity.
    // This is used when a new activity is created and there are no logs added yet
    makeLogFromSession(sessionMovement: SessionMovement): Log {
        return {
            uuid: uuidv4(),
            created_at: null,
            time_value: sessionMovement.pivot?.time_value,
            time_display_format:
                sessionMovement.pivot?.time_display_format || TimeDisplayFormat.Second,
            load_value: sessionMovement.pivot?.load_value,
            load_unit: sessionMovement.pivot?.load_unit || LoadUnit.Pound,
            distance_value: sessionMovement.pivot?.distance_value,
            distance_unit: sessionMovement.pivot?.distance_unit || DistanceUnit.Yard,
            reps: sessionMovement.pivot?.reps,
            range_of_motion_value: sessionMovement.pivot?.range_of_motion_value,
            range_of_motion_unit:
                sessionMovement.pivot?.range_of_motion_unit || RangeOfMotionUnit.Degree,
            speed_value: sessionMovement.pivot?.speed_value,
            speed_unit: sessionMovement.pivot?.speed_unit || SpeedUnit.MPH,
            acceleration_value: sessionMovement.pivot?.acceleration_value,
            acceleration_unit: sessionMovement.pivot?.acceleration_unit || AccelerationUnit.FPS,
            power_value: sessionMovement.pivot?.power_value,
            power_unit: sessionMovement.pivot?.power_unit || PowerUnit.Watt,
            rsi_value: sessionMovement.pivot?.rsi_value ?? null,
            rpm_value: sessionMovement.pivot?.rpm_value ?? null,
            force_value: sessionMovement.pivot?.force_value ?? null,
            force_unit: sessionMovement.pivot?.force_unit || ForceUnit.Newton,
            marked_as_complete: false,
            movement: omit(sessionMovement, 'pivot') as Movement,
            is_from_original_session: true,
            body_side: sessionMovement.pivot?.body_side,
            rpe_value: sessionMovement.pivot?.rpe_value,
            band_value: sessionMovement.pivot?.band_value,
            rest_value: sessionMovement.pivot?.rest_value,
            rest_unit: sessionMovement.pivot?.rest_unit || TimeUnit.Second,
            gps_player_load_value: sessionMovement.pivot?.gps_player_load_value ?? null,
            gps_accel_count_value: sessionMovement.pivot?.gps_accel_count_value ?? null,
            gps_decel_count_value: sessionMovement.pivot?.gps_decel_count_value ?? null,
        };
    },

    // Update the log status and mark the log as compete or incomplete
    updateActivityLogStatus(logs: Array<Log>, updatedLog: Log, setLogs: React.Dispatch<any>) {
        let updatedLogs = cloneDeep(logs);

        let foundLog = updatedLogs.find((log) => log.uuid == updatedLog.uuid);

        if (!foundLog) {
            return;
        }

        foundLog.marked_as_complete = !foundLog.marked_as_complete;

        setLogs(updatedLogs);
    },

    // Update the log status for completion for entire group of logs belonging to an activity set
    updateActivityLogSetStatus(
        logs: Array<Log>,
        updatedLogs: Array<Log>,
        setLogs: React.Dispatch<any>,
    ) {
        let completCount = updatedLogs.filter((log) => log.marked_as_complete == true).length;

        if (completCount >= updatedLogs.length) {
            // All are complete already so do nothing
            return;
        }

        // Mark all as complete
        setLogs(
            cloneDeep(logs).map((log) => {
                if (updatedLogs.findIndex((updatedLog) => updatedLog.uuid == log.uuid) >= 0) {
                    log.marked_as_complete = true;
                }

                return log;
            }),
        );
    },

    updateActivityLogValue(
        updatedLog: Log,
        updatedLogCriteria: SetAttributeType,
        newValue: any,
        setLogs: React.Dispatch<any>,
    ) {
        setLogs((logs: Array<Log>) => {
            let logIx = logs.findIndex((log) => log.uuid == updatedLog.uuid);

            if (logIx === -1) {
                return logs;
            }

            switch (updatedLogCriteria) {
                case SetAttributeType.Reps:
                    logs[logIx].reps = newValue;
                    break;

                case SetAttributeType.Distance:
                    logs[logIx].distance_value = newValue;
                    break;

                case SetAttributeType.Time:
                    logs[logIx].time_value = newValue;
                    break;

                case SetAttributeType.Load:
                    logs[logIx].load_value = newValue;
                    break;

                case SetAttributeType.RangeOfMotion:
                    logs[logIx].range_of_motion_value = newValue;
                    break;

                case SetAttributeType.Speed:
                    logs[logIx].speed_value = newValue;
                    break;

                case SetAttributeType.Acceleration:
                    logs[logIx].acceleration_value = newValue;
                    break;

                case SetAttributeType.Power:
                    logs[logIx].power_value = newValue;
                    break;

                case SetAttributeType.RSI:
                    logs[logIx].rsi_value = newValue;
                    break;
                case SetAttributeType.RPM:
                    logs[logIx].rpm_value = newValue;
                    break;

                case SetAttributeType.Force:
                    logs[logIx].force_value = newValue;
                    break;

                case SetAttributeType.BodySide:
                    logs[logIx].body_side = newValue;
                    break;

                case SetAttributeType.RPE:
                    logs[logIx].rpe_value = newValue;
                    break;

                case SetAttributeType.Band:
                    logs[logIx].band_value = newValue;
                    break;

                case SetAttributeType.Rest:
                    logs[logIx].rest_value = newValue;
                    break;

                case SetAttributeType.GPSPlayerLoad:
                    logs[logIx].gps_player_load_value = newValue;
                    break;

                case SetAttributeType.GPSAccelCount:
                    logs[logIx].gps_accel_count_value = newValue;
                    break;

                case SetAttributeType.GPSDecelCount:
                    logs[logIx].gps_decel_count_value = newValue;
                    break;

                default:
                    break;
            }
            logs[logIx].marked_as_complete = true;
            return [...logs];
        });
    },
    // Add new activity log with a debounce to avoid multiple API requests when the button
    // is clicked continuously
    addActivityLog(
        activity: Activity,
        logs: Array<Log>,
        previousLog: Log,
        setLogs: React.Dispatch<any>,
    ) {
        let updatedLogs = cloneDeep(logs);

        let newLog = cloneDeep(previousLog);
        newLog.uuid = uuidv4();
        newLog.is_from_original_session = false;
        newLog.marked_as_complete = false;

        let previousLogIndex: number = updatedLogs.findIndex((log) => log.uuid == previousLog.uuid);

        if (previousLogIndex < 0) {
            return;
        }

        updatedLogs.splice(previousLogIndex + 1, 0, newLog);

        setLogs(updatedLogs);
    },
    // Remove an activity log
    removeActivityLog(
        activity: Activity,
        logs: Array<Log>,
        removedLog: Log,
        setLogs: React.Dispatch<any>,
    ) {
        let updatedLogs = cloneDeep(logs);

        updatedLogs = updatedLogs.filter((log) => log.uuid != removedLog.uuid);

        setLogs(updatedLogs);
    },
    // Remove multiple logs from an activity set
    removeMultipleActivityLogs(
        activity: Activity,
        logs: Array<Log>,
        removedLogs: Array<Log>,
        setLogs: React.Dispatch<any>,
    ) {
        let updatedLogs = cloneDeep(logs).filter(
            (log) => removedLogs.findIndex((removedLog) => removedLog.uuid == log.uuid) < 0,
        );

        setLogs(updatedLogs);
    },
    // Add criteria to the log. This is called when user adds criteria chips
    // A criteria is a set attribute like load, distance, reps etc
    // These values are defaults when you select a chip
    addCriteriaToActivityLog(
        logs: Array<Log>,
        updatedLogs: Array<Log>,
        criteria: SetAttributeType,
        setLogs: React.Dispatch<any>,
    ) {
        setLogs(
            cloneDeep(logs as Array<Log>).map((log) => {
                if (updatedLogs.findIndex((updatedLog) => updatedLog.uuid == log?.uuid) >= 0) {
                    switch (criteria) {
                        case SetAttributeType.Reps:
                            log.reps = 1;
                            break;

                        case SetAttributeType.Load:
                            log.load_value = 1;
                            break;

                        case SetAttributeType.Time:
                            log.time_value = 1;
                            break;

                        case SetAttributeType.Distance:
                            log.distance_value = 1;
                            break;

                        case SetAttributeType.RangeOfMotion:
                            log.range_of_motion_value = 1;
                            break;

                        case SetAttributeType.Speed:
                            log.speed_value = 1;
                            break;

                        case SetAttributeType.Acceleration:
                            log.acceleration_value = 1;
                            break;

                        case SetAttributeType.Power:
                            log.power_value = 1;
                            break;

                        case SetAttributeType.RSI:
                            log.rsi_value = 1;
                            break;
                        case SetAttributeType.RPM:
                            log.rpm_value = 1;
                            break;

                        case SetAttributeType.Force:
                            log.force_value = 1;
                            break;

                        case SetAttributeType.BodySide:
                            log.body_side = 'left';
                            break;

                        case SetAttributeType.RPE:
                            log.rpe_value = 3; // moderate
                            break;

                        case SetAttributeType.Band:
                            log.band_value = 3; // moderate
                            break;

                        case SetAttributeType.Rest:
                            log.rest_value = 1;
                            break;

                        case SetAttributeType.GPSPlayerLoad:
                            log.gps_player_load_value = 1;
                            break;

                        case SetAttributeType.GPSAccelCount:
                            log.gps_accel_count_value = 1;
                            break;

                        case SetAttributeType.GPSDecelCount:
                            log.gps_decel_count_value = 1;
                            break;

                        default:
                            break;
                    }
                }
                return log;
            }) || [],
        );
    },
    // Remove criteria from the activity log set. This is called when user removes criteria chips
    // A criteria is a set attribute like load, distance, reps etc
    removeCriteriaFromActivityLog(
        logs: Array<Log>,
        updatedLogs: Array<Log>,
        criteria: SetAttributeType,
        setLogs: React.Dispatch<any>,
    ) {
        setLogs(
            cloneDeep(logs).map((log) => {
                if (updatedLogs.findIndex((updatedLog) => updatedLog.uuid == log?.uuid) >= 0) {
                    switch (criteria) {
                        case SetAttributeType.Reps:
                            log.reps = null;
                            break;

                        case SetAttributeType.Load:
                            log.load_value = null;
                            break;

                        case SetAttributeType.Time:
                            log.time_value = null;
                            break;

                        case SetAttributeType.Distance:
                            log.distance_value = null;
                            break;

                        case SetAttributeType.RangeOfMotion:
                            log.range_of_motion_value = null;
                            break;

                        case SetAttributeType.Speed:
                            log.speed_value = null;
                            break;

                        case SetAttributeType.Acceleration:
                            log.acceleration_value = null;
                            break;

                        case SetAttributeType.Power:
                            log.power_value = null;
                            break;

                        case SetAttributeType.RSI:
                            log.rsi_value = null;
                            break;

                        case SetAttributeType.RPM:
                            log.rpm_value = null;
                            break;

                        case SetAttributeType.Force:
                            log.force_value = null;
                            break;

                        case SetAttributeType.BodySide:
                            log.body_side = null;
                            break;

                        case SetAttributeType.RPE:
                            log.rpe_value = null;
                            break;

                        case SetAttributeType.Band:
                            log.band_value = null;
                            break;

                        case SetAttributeType.Rest:
                            log.rest_value = null;
                            break;

                        case SetAttributeType.GPSPlayerLoad:
                            log.gps_player_load_value = null;
                            break;
                        case SetAttributeType.GPSAccelCount:
                            log.gps_accel_count_value = null;
                            break;

                        case SetAttributeType.GPSDecelCount:
                            log.gps_decel_count_value = null;
                            break;

                        default:
                            break;
                    }
                }
                return log;
            }) || [],
        );
    },
    // Add multiple movements to a log. This is called when user chooses multiple movements from the
    // movement selection model and adds it to the current activity log
    onMultipleMovementsAddedToActivityLog(
        logs: Array<Log>,
        addedMovements: Array<Movement>,
        setLogs: React.Dispatch<any>,
    ) {
        let updatedLogs = cloneDeep(logs);

        addedMovements.forEach((addedMovement) => {
            const presets = addedMovement.movement_presets || {};

            updatedLogs.push({
                uuid: uuidv4(),
                created_at: null,
                time_unit: TimeUnit.Millisecond,
                time_display_format: presets['Time'] || TimeDisplayFormat.Run,
                time_value: 'Time' in presets ? 10 : null,
                load_value: 'Load' in presets ? 1 : null,
                load_unit: presets['Load'] || LoadUnit.Pound,
                distance_unit: presets['Distance'] || DistanceUnit.Yard,
                distance_value: 'Distance' in presets ? 1 : null,
                range_of_motion_unit: presets['RangeOfMotion'] || RangeOfMotionUnit.Degree,
                range_of_motion_value: 'RangeOfMotion' in presets ? 1 : null,
                speed_unit: presets['Speed'] || SpeedUnit.MPH,
                speed_value: 'Speed' in presets ? 1 : null,
                acceleration_unit: presets['Acceleration'] || AccelerationUnit.FPS,
                acceleration_value: 'Acceleration' in presets ? 1 : null,
                rsi_value: 'RSI' in presets ? 1 : null,
                rpm_value: 'RPM' in presets ? 1 : null,
                force_unit: presets['Force'] || ForceUnit.Newton,
                force_value: 'Force' in presets ? 1 : null,
                reps: 'Reps' in presets ? 1 : null,
                marked_as_complete: false,
                movement: addedMovement,
                body_side: 'BodySide' in presets ? 'both' : null,
                rpe_value: 'RPE' in presets ? 3 : null,
                band_value: 'Band' in presets ? 3 : null,
                rest_value: 'Rest' in presets ? 10 : null,
                rest_unit: presets['Rest'] || TimeUnit.Second,
                gps_player_load_value: 'GPSPlayerLoad' in presets ? 1 : null,
                gps_accel_count_value: 'GPSAccelCount' in presets ? 1 : null,
                gps_decel_count_value: 'GPSDecelCount' in presets ? 1 : null,
            } as Log);
        });

        setLogs(updatedLogs);
    },
    // This is used to mark all logs from the activity as complete
    markAllActivityLogsAsComplete(logs: Array<Log>, setLogs: React.Dispatch<any>) {
        setLogs(
            cloneDeep(logs).map((log) => {
                log.marked_as_complete = true;
                return log;
            }) || [],
        );
    },
    // Change unit of an activity log from the settings
    async changeActivityLogUnit(
        updatedLogs: Array<Log>,
        criteria: SetAttributeType,
        oldUnit: string,
        newUnit: string,
        setLogs: React.Dispatch<any>,
    ) {
        // If user changes time from a higher to lower unit, we can not convert duration into required mask
        // So we ask for user's confirmation before erasing the values
        if (
            criteria == SetAttributeType.Time &&
            timeDisplayHierarchy.indexOf(oldUnit as TimeDisplayFormat) >
                timeDisplayHierarchy.indexOf(newUnit as TimeDisplayFormat)
        ) {
            if (
                !(await confirmViaDialog({
                    confirmation: {
                        title: 'Are you sure?',
                        message: 'This will delete the existing time values for this set.',
                    },
                }))
            ) {
                return;
            }
        }

        setLogs((logs: Array<Log>) => {
            return logs.map((log) => {
                if (updatedLogs.findIndex((updatedLog) => updatedLog.uuid == log.uuid) >= 0) {
                    switch (criteria) {
                        case SetAttributeType.Load:
                            log.load_value = parseFloat(
                                convert(log.load_value || 0)
                                    .from(oldUnit as LoadUnit)
                                    .to(newUnit as LoadUnit)
                                    .toFixed(4),
                            );
                            log.load_unit = newUnit;
                            break;

                        case SetAttributeType.Distance:
                            log.distance_value = parseFloat(
                                convert(log.distance_value || 0)
                                    .from(oldUnit as DistanceUnit)
                                    .to(newUnit as DistanceUnit)
                                    .toFixed(4),
                            );
                            log.distance_unit = newUnit;
                            break;

                        case SetAttributeType.Speed:
                            log.speed_value = parseFloat(
                                convert(log.speed_value || 0)
                                    .from(oldUnit as SpeedUnit)
                                    .to(newUnit as SpeedUnit)
                                    .toFixed(4),
                            );
                            log.speed_unit = newUnit;
                            break;

                        case SetAttributeType.Time:
                            if (
                                timeDisplayHierarchy.indexOf(oldUnit as TimeDisplayFormat) >
                                timeDisplayHierarchy.indexOf(newUnit as TimeDisplayFormat)
                            ) {
                                log.time_value = 1000;
                                log.time_display_format = newUnit;
                            } else {
                                log.time_display_format = newUnit;
                            }
                            log.time_unit = TimeUnit.Millisecond;
                            break;

                        default:
                            break;
                    }
                }

                return log;
            });
        });
    },
};

export default LogDomain;
