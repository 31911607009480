import { Request } from './index';

function getCubeToken(params = {}) {
    return Request.get(`/cube-token`, {
        params,
    });
}

function getCubeTokenForOrganization() {
    return Request.get(`/cube-token-for-organization`);
}

export { getCubeToken, getCubeTokenForOrganization };
