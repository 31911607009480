import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { CalendarEvent } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<CalendarEvent>>>;

const getEvents = (queryParams?: string): ReturnType => {
    return Request.get(`/calendar/events${queryParams ? `?${queryParams}` : ''}`);
};

export default getEvents;
