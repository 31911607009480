import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Link } from 'utils';

type ReturnType = Promise<AxiosResponse<Link>>;

const deleteLink = (shareableType: string, shareableId: string, linkId: String): ReturnType => {
    return Request.delete(`/links/${shareableType}/${shareableId}/${linkId}`);
};

export default deleteLink;
