import React from 'react';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import LandingPageNavBar from './LandingPageNavBar';

function LandingPageAbout() {
    const gradientTextStyles = {
        mt: 4,
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 8,
        maxWidth: 900,
        background: 'linear-gradient(to bottom, #fffffF, #A7ABB7)', // Example gradient
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent', // This makes the text transparent, showing only the background
        backgroundClip: 'text',
        color: 'transparent', // Fallback for browsers that do not support background-clip
        marginTop: 15,
    };

    return (
        <>
            <LandingPageNavBar />
            <Box
                sx={{ flex: 1, backgroundImage: 'linear-gradient(to bottom, #0066ff, #070A19)' }}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start" // This will distribute space evenly
                alignItems="center"
                height="100%"
            >
                <Typography sx={gradientTextStyles} variant="h3" component="div">
                    California Privacy Policy
                </Typography>
                <Typography sx={gradientTextStyles} variant="h5" component="div">
                    This Privacy Policy for California Residents supplements the information
                    contained in Athlete Management Technologies Inc.&apos;s general Privacy Policy
                    https://www.1tul.com/privacy and applies solely to all visitors, users, and
                    others who reside in the State of California (&apos;consumers&apos; or
                    &apos;you&apos;). We adopt this notice to comply with the California Consumer
                    Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same
                    meaning when used in this Policy. This Policy does not apply to
                    workforce-related personal information collected from California-based
                    employees, job applicants, contractors, or similar individuals.
                </Typography>
                <Typography
                    sx={gradientTextStyles}
                    style={{ textAlign: 'left' }}
                    variant="body2"
                    component="div"
                >
                    Information We Collect. We collect information that identifies, relates to,
                    describes, references, is reasonably capable of being associated with, or could
                    reasonably be linked, directly or indirectly, with a particular consumer,
                    household, or device (&apos;personal information&apos;). Personal information
                    does not include: Publicly available information from government records.
                    Deidentified or aggregated consumer information. Information excluded from the
                    CCPA&apos;s scope, like: health or medical information covered by the Health
                    Insurance Portability and Accountability Act of 1996 (HIPAA) and the California
                    Confidentiality of Medical Information Act (CMIA), clinical trial data, or other
                    qualifying research data; personal information covered by certain
                    sector-specific privacy laws, including the Fair Credit Reporting Act (FCRA),
                    the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy
                    Act (FIPA), and the Driver&apos;s Privacy Protection Act of 1994. <br />
                    In particular, we have collected the following categories of personal
                    information from consumers within the last twelve (12) months: Category Examples
                    Collected <br />
                    A. Identifiers. A real name, alias, postal address, unique personal identifier,
                    online identifier, Internet Protocol address, email address, account name, or
                    other similar identifiers. YES <br />
                    B. Personal information categories listed in the California Customer Records
                    statute (Cal. Civ. Code § 1798.80(e)). A name, physical characteristics or
                    description, address, telephone number. Some personal information included in
                    this category may overlap with other categories. YES <br />
                    C. Protected classification characteristics under California or federal law.
                    Age, race, color, ancestry, national origin, citizenship, religion or creed,
                    marital status, medical condition, physical or mental disability, sex (including
                    gender, gender identity, gender expression, pregnancy or childbirth and related
                    medical conditions), sexual orientation, veteran or military status, genetic
                    information (including familial genetic information). YES <br />
                    D. Commercial information. Records of personal property, products or services
                    purchased, obtained, or considered, or other purchasing or consuming histories
                    or tendencies. NO <br />
                    E. Biometric information. Physiological, behavioral, and biological
                    characteristics, or activity patterns used to extract a template or other
                    identifier or identifying information, and sleep, health, or exercise data. YES
                    <br />
                    F. Internet or other similar network activity. Browsing history, search history,
                    information on a consumer&apos;s interaction with a website, application, or
                    advertisement. YES <br />
                    G. Geolocation data. Geolocation data, such as device location. YES <br />
                    H. Sensory data. Audio, electronic, visual, thermal, olfactory, or similar
                    information. NO <br />
                    I. Professional or employment-related information. Current or past job history
                    or performance evaluations. NO <br />
                    J. Non-public education information (per the Family Educational Rights and
                    Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)). Education records
                    directly related to a student maintained by an educational institution or party
                    acting on its behalf, such as grades, transcripts, class lists, student
                    schedules, student identification codes, student financial information, or
                    student disciplinary records. YES <br />
                    K. Inferences drawn from other personal information. Profile reflecting a
                    person&apos;s preferences, characteristics, psychological trends,
                    predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
                    YES <br />
                    We obtain the categories of personal information listed above from the following
                    categories of sources: Directly from you. For example, from forms you complete
                    or products and services you purchase. Indirectly from you. For example, from
                    observing your actions on our Application. From third-parties that interact with
                    us in connection with the services we perform. <br />
                    Use of Personal Information. We may use or disclose the personal information we
                    collect for one or more of the following purposes: To fulfill or meet the reason
                    you provided the information. For example, to provide you with personalized
                    analytics reports about your athletic and general wellness. To provide, support,
                    personalize, and develop our Application, products, and services. To create,
                    maintain, customize, and secure your account with us. To communicate with you
                    and personalize your experience and to deliver content relevant to your
                    interests. This may in the future include delivering targeted offers and ads
                    through our Application, third-party sites, and via email (with your consent,
                    where required by law). To respond to law enforcement requests and as required
                    by applicable law, court order, or governmental regulations. To evaluate or
                    conduct a merger, divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of our assets, whether as a going concern
                    or as part of bankruptcy, liquidation, or similar proceeding, in which personal
                    information held by us about our users is among the assets transferred. We will
                    not collect additional categories of personal information or use the personal
                    information we collected for materially different, unrelated, or incompatible
                    purposes without providing you notice and, where required, obtaining your
                    consent. <br />
                    Sharing Personal Information. We may provide your personal information to
                    service providers, but solely pursuant to written contracts that describe the
                    purposes, require the recipient to keep the personal information confidential,
                    and prohibit using the disclosed information for any purpose except performing
                    the contract. In the preceding twelve (12) months, Company has not disclosed
                    personal information for a business purpose to the categories of third parties
                    indicated in the chart below. We do not sell personal information.In the
                    preceding twelve (12) months, Company has not sold personal information.For more
                    on your personal information sale rights, see Personal Information Sales Opt-Out
                    and Opt-In Rights. Personal Information Category Category of Third-Party
                    Recipients Business Purpose Disclosures Sales <br />
                    A: Identifiers. Our internet service provider, data analytic provider, operating
                    system and platform, service providers, affiliates, partners, cookie data
                    recipients (i.e., Google Analytics) None <br />
                    B: California Customer Records personal information categories. Our internet
                    service provider, data analytic provider, operating system and platform, service
                    providers, affiliates, partners, cookie data recipients (i.e., Google Analytics)
                    None <br />
                    C: Protected classification characteristics under California or federal law.
                    None. None <br />
                    D: Commercial information. None. None <br />
                    E: Biometric information. None. None <br />
                    F: Internet or other similar network activity. Our internet service provider,
                    data analytic provider, operating system and platform, service providers,
                    affiliates, partners, cookie data recipients (i.e., Google Analytics) None{' '}
                    <br />
                    G: Geolocation data. None. None <br />
                    H: Sensory data. None. None <br />
                    I: Professional or employment-related information. None. None <br />
                    J: Non-public education information. None. None <br />
                    K: Inferences drawn from other personal information. None. None <br />
                    Your Rights and Choices The CCPA provides consumers (California residents) with
                    specific rights regarding their personal information. This section describes
                    your CCPA rights and explains how to exercise those rights. <br />
                    Right to Know and Data Portability. You have the right to request that we
                    disclose certain information to you about our collection and use of your
                    personal information over the past 12 months (the &apos;right to
                    know&apos;).Once we receive your request and confirm your identity (see
                    Exercising Your Rights to Know or Delete), we will disclose to you: The
                    categories of personal information we collected about you. The categories of
                    sources for the personal information we collected about you. Our business or
                    commercial purpose for collecting or selling that personal information. The
                    categories of third parties with whom we share that personal information. If we
                    sold or disclosed your personal information for a business purpose, two separate
                    lists disclosing: sales, identifying the personal information categories that
                    each category of recipient purchased; and disclosures for a business purpose,
                    identifying the personal information categories that each category of recipient
                    obtained. The specific pieces of personal information we collected about you
                    (also called a data portability request). <br />
                    Right to Delete. You have the right to request that we delete any of your
                    personal information that we collected from you and retained, subject to certain
                    exceptions (the &apos;right to delete&apos;). Once we receive your request and
                    confirm your identity (see Exercising Your Rights to Know or Delete), we will
                    review your request to see if an exception allowing us to retain the information
                    applies. We may deny your deletion request if retaining the information is
                    necessary for us or our service provider(s) to: Complete the transaction for
                    which we collected the personal information, provide a good or service that you
                    requested, take actions reasonably anticipated within the context of our ongoing
                    business relationship with you, fulfill the terms of a written warranty or
                    product recall conducted in accordance with federal law, or otherwise perform
                    our contract with you. Detect security incidents, protect against malicious,
                    deceptive, fraudulent, or illegal activity, or prosecute those responsible for
                    such activities. Debug products to identify and repair errors that impair
                    existing intended functionality. Exercise free speech, ensure the right of
                    another consumer to exercise their free speech rights, or exercise another right
                    provided for by law. Comply with the California Electronic Communications
                    Privacy Act (Cal.Penal Code § 1546 et. seq.). Engage in public or peer-reviewed
                    scientific, historical, or statistical research in the public interest that
                    adheres to all other applicable ethics and privacy laws, when the
                    information&apos;s deletion may likely render impossible or seriously impair the
                    research&apos;s achievement, if you previously provided informed consent. Enable
                    solely internal uses that are reasonably aligned with consumer expectations
                    based on your relationship with us. Comply with a legal obligation. Make other
                    internal and lawful uses of that information that are compatible with the
                    context in which you provided it. We will delete or deidentify personal
                    information not subject to one of these exceptions from our records and will
                    direct our service providers to take similar action. <br />
                    Exercising Your Rights to Know or Delete. To exercise your rights to know or
                    delete described above, please submit a request by either: Emailing us at
                    support@1tul.com; or Submitting a support request within the Application. Only
                    you, or someone legally authorized to act on your behalf, may make a request to
                    know or delete related to your personal information.To designate an authorized
                    agent, please submit a request by emailing us at support@1tul.com. You may only
                    submit a request to know twice within a 12-month period. <br />
                    Your request to know or delete must: Provide sufficient information that allows
                    us to reasonably verify you are the person about whom we collected personal
                    information or an authorized representative. Describe your request with
                    sufficient detail that allows us to properly understand, evaluate, and respond
                    to it. We cannot respond to your request or provide you with personal
                    information if we cannot verify your identity or authority to make the request
                    and confirm the personal information relates to you. You do not need to create
                    an account with us to submit a request to know or delete. However, we do
                    consider requests made through your password protected account sufficiently
                    verified when the request relates to personal information associated with that
                    specific account. We will only use personal information provided in the request
                    to verify the requestor&apos;s identity or authority to make it. <br />
                    Response Timing and Format. We will confirm receipt of your request within ten
                    (10) business days.If you do not receive confirmation within the 10-day
                    timeframe, please contact us at support@1tul.com. We endeavor to substantively
                    respond to a verifiable consumer request within forty-five (45) days of its
                    receipt. If we require more time (up to another 45 days), we will inform you of
                    the reason and extension period in writing. If you have an account with us, we
                    will deliver our written response to that account. If you do not have an account
                    with us, we will deliver our written response by mail or electronically, at your
                    option. Any disclosures we provide will only cover the 12-month period preceding
                    our receipt of your request. The response we provide will also explain the
                    reasons we cannot comply with a request, if applicable. For data portability
                    requests, we will select a format to provide your personal information that is
                    readily usable and should allow you to transmit the information from one entity
                    to another entity without hindrance. We do not charge a fee to process or
                    respond to your verifiable consumer request unless it is excessive, repetitive,
                    or manifestly unfounded. If we determine that the request warrants a fee, we
                    will tell you why we made that decision and provide you with a cost estimate
                    before completing your request. <br />
                    Personal Information Sales Opt-Out and Opt-In Rights. If you are age 16 or
                    older, you have the right to direct us to not sell your personal information at
                    any time (the &apos;right to opt-out&apos;). We do not sell and have no actual
                    knowledge that we have sold the personal information of consumers who are less
                    than 16 years old. We do not currently offer an opt out from the sale of
                    personal information because we do not and have not sold personal information
                    for monetary or other valuable consideration within at least the last 12 months.
                    <br />
                    Non-Discrimination. We will not discriminate against you for exercising any of
                    your CCPA rights. Unless permitted by the CCPA, we will not: Deny you goods or
                    services. Charge you different prices or rates for goods or services, including
                    through granting discounts or other benefits, or imposing penalties. Provide you
                    a different level or quality of goods or services. Suggest that you may receive
                    a different price or rate for goods or services or a different level or quality
                    of goods or services. <br />
                    Changes to Our Privacy Policy. We reserve the right to amend this privacy policy
                    at our discretion and at any time.When we make changes to this privacy policy,
                    we will post the updated notice on the Application and update the notice&apos;s
                    effective date.Your continued use of our Application following the posting of
                    changes constitutes your acceptance of such changes. <br />
                    Contact Information If you have any questions or comments about this notice, the
                    ways in which Athlete Management Technologies, Inc.collects and uses your
                    information described here and in the Privacy Policy, your choices and rights
                    regarding such use, or wish to exercise your rights under California law, please
                    do not hesitate to contact us through our Settings feature or at: Postal
                    Address: Athlete Management Technologies, LLC. 3753 Arroyo Sorrento Rd. San
                    Diego, CA 92130 support@1TUL.com
                </Typography>

                <Button
                    sx={gradientTextStyles}
                    variant="contained"
                    size={'large'}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                    Back to the top
                </Button>
            </Box>
        </>
    );
}

export default LandingPageAbout;
