import React from 'react';
import {
    Box,
    Button,
    Drawer,
    IconButton,
    LinearProgress,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import { TypographyTitleh6 } from '../Typography';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
    open: boolean;
    disabled: boolean;
    onClose: () => void;
    onSubmit: () => void;
    title: React.ReactElement;
    body: React.ReactElement;
    loading: boolean;
    submitButtonText?: string;
    processing?: boolean;
}

function SideDrawerWithTitleAndActions({
    open,
    onClose,
    title,
    body,
    onSubmit,
    loading,
    disabled,
    submitButtonText = 'Save',
    processing = false,
}: Props) {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Drawer
            PaperProps={{ style: { width: isMobile ? '100%' : '60%' } }}
            anchor={'right'}
            open={open}
            onClose={() => onClose()}
        >
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    onSubmit();
                }}
                style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}
            >
                {loading && <LinearProgress />}
                <Box
                    sx={{
                        flex: '1 1 0%',
                        minHeight: 0,
                        overflowY: 'scroll',
                        borderBottom: '1px solid',
                        borderBottomColor: grey[200],
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid',
                            borderBottomColor: grey[200],
                            padding: 8,
                        }}
                    >
                        <TypographyTitleh6>{title}</TypographyTitleh6>
                        <IconButton onClick={() => onClose()} aria-label="close" size="medium">
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            padding: 8,
                        }}
                    >
                        {body}
                    </Box>
                </Box>
                <Box
                    sx={{
                        flexShrink: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 8,
                    }}
                >
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button
                        disabled={disabled}
                        color={'primary'}
                        variant={'contained'}
                        type="submit"
                    >
                        {processing ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <CircularProgress color="inherit" size={24} />
                                Processing ...
                            </Box>
                        ) : (
                            submitButtonText
                        )}
                    </Button>
                </Box>
            </form>
        </Drawer>
    );
}

export default SideDrawerWithTitleAndActions;
