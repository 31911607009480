import InputBase from '@mui/material/InputBase';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

export interface SearchInputProps {
    /** onChange A callback for when the value changes */
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    /** onKeyUp a calback for keydown event */
    onKeyUp: (event: React.KeyboardEvent) => void;
    /** value The value of the search field */
    value: string;
    /** placeholderText The text of the placeholder for the search input */
    placeholderText?: string;
}

export default function SearchInput({
    onChange,
    onKeyUp,
    placeholderText = 'Search...',
}: SearchInputProps) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'relative',
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.backgroundColor.main,
                '&:hover': {
                    backgroundColor: theme.palette.backgroundColor.hover,
                },
                marginRight: theme.spacing(4),
                marginLeft: 0,
                width: '100%',
            }}
        >
            <Box
                sx={{
                    padding: theme.spacing(0, 4),
                    height: '100%',
                    position: 'absolute',
                    pointerEvents: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <SearchIcon />
            </Box>
            <InputBase
                placeholder={placeholderText}
                sx={{
                    color: 'inherit',
                    width: '100%',
                    padding: `${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(4)}px 0`,
                    paddingLeft: `calc(1em + ${theme.spacing(8)}px)`,
                    '&:hover': {
                        backgroundColor: theme.palette.backgroundColor.hover,
                    },
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={onChange}
                onKeyUp={onKeyUp}
            />
        </Box>
    );
}
