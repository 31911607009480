import { createSelector } from '@reduxjs/toolkit';
import { CombinedState, combineReducers } from 'redux';
import { UserFilter } from '../../../modules/plan/plan.types';
import { RootState } from '../index';
import { ReportsFilterState, reportsSlice } from './reports';
import { MovementFilterState, movementsAdapter } from './reports/movement.entity';
import { UserFilterState, usersAdapter } from './reports/user.entity';

export type PlanReducerState = CombinedState<{
    reports: ReportsFilterState;
}>;

// reducers
export const planReducer = combineReducers<PlanReducerState>({
    reports: reportsSlice.reducer,
});

// selectors
const { selectAll, selectById } = movementsAdapter.getSelectors<MovementFilterState>(
    (state) => state,
);

const { selectAll: selectAllUsers, selectById: selectUserById } =
    usersAdapter.getSelectors<UserFilterState>((state) => state);

export const reportsSelector = (state: RootState) => state.plan.reports;
export const movementsSelector = createSelector(reportsSelector, (state) => state.movements);
export const usersSelector = createSelector(reportsSelector, (state) => state.users);
export const selectFilters = createSelector(reportsSelector, (state) => state.filters);
export const selectData = createSelector(reportsSelector, (state) => state.data);
export const selectLoaded = createSelector(movementsSelector, (movements) => movements.loaded);
export const selectUsersLoaded = createSelector(usersSelector, (users) => users.loaded);
export const selectMovements = createSelector(movementsSelector, (movements) =>
    selectAll(movements),
);
export const selectMovement = createSelector(reportsSelector, (reports) =>
    selectById(reports.movements, reports.filters.movement_key ?? ''),
);
export const selectUsers = createSelector(usersSelector, (users) => selectAllUsers(users));
export const selectUsersDictionary = createSelector(usersSelector, (users) => users.entities);
export const selectSelectedUsers = createSelector(
    reportsSelector,
    (reports): Array<number> => reports.filters.user_key,
);
export const selectSelectedUsersCollection = createSelector(
    reportsSelector,
    (reports): Array<UserFilter> =>
        reports.filters.user_key.map((k) => selectUserById(reports.users, k) as UserFilter),
);

export const reportsSelectors = {
    selectLoaded,
    selectUsersLoaded,
    selectMovements,
    selectMovement,
    selectUsers,
    selectUsersDictionary,
    selectSelectedUsers,
    selectSelectedUsersCollection,
    selectFilters,
    selectData,
};
