import { useEffect, useState } from 'react';

enum ScriptStatus {
    LOADING = 'loading',
    IDLE = 'idle',
    READY = 'ready',
    ERROR = 'error',
}

const useScript = (url: string, id: string) => {
    const [status, setStatus] = useState<ScriptStatus | null>(
        url ? ScriptStatus.LOADING : ScriptStatus.IDLE,
    );
    useEffect(() => {
        if (!url) {
            setStatus(ScriptStatus.IDLE);
            return;
        }
        let script: HTMLScriptElement | null = document.getElementById(id) as HTMLScriptElement;

        if (!script) {
            script = document.createElement('script');

            script.src = url;
            script.async = false;
            script.id = id;
            script.setAttribute('data-status', ScriptStatus.LOADING);

            document.body.appendChild(script);

            const setAttributeFromEvent = (event: any) => {
                script?.setAttribute(
                    'data-status',
                    event.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR,
                );
            };

            script.addEventListener('load', setAttributeFromEvent);
            script.addEventListener('error', setAttributeFromEvent);
        } else {
            setStatus(script.getAttribute('data-status') as ScriptStatus);
        }

        const setStateFromEvent = (event: any) => {
            setStatus(event.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR);
        };
        script?.addEventListener('load', setStateFromEvent);
        script?.addEventListener('error', setStateFromEvent);

        return () => {
            if (script) {
                script.removeEventListener('load', setStateFromEvent);
                script.removeEventListener('error', setStateFromEvent);
            }
        };
    }, [url, id]);
    return status;
};

export default useScript;
