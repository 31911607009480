import axios, { AxiosError } from 'axios';
import { logOut } from 'redux/reducers/auth';
import { store } from 'redux/store';

let Request = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: { Accept: 'application/json' },
});

export const setupInterceptors = (rootStore: typeof store) => {
    Request.interceptors.request.use((config) => {
        const token = rootStore.getState().auth.currentUser?.accessToken;
        console.log('Interceptor - current user', rootStore.getState().auth.currentUser);
        console.log('Interceptor - config', config);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            config.headers.Authorization = '';
        }
        return config;
    });

    Request.interceptors.response.use(
        (response) => {
            return response;
        },
        (error: AxiosError<any>) => {
            // Do not redirect to /unauthorized if the check admin route returns a 403
            if (error.config?.url?.includes('admin/check')) {
                return;
            }

            // Redirect to login page if server gives 401 unauthenticated response
            if (error.response?.status === 401) {
                rootStore.dispatch(logOut());
            }

            // For all other 403 responses redirect to /unauthorized
            if (error.response?.status === 403) {
                window.location.href = '/unauthorized';
            } else {
                return Promise.reject(error);
            }
        },
    );
};

export default Request;
