import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { UpdateOrderParams, Order } from './orders.types';

interface PaginatedApiResponse {
    data: Array<Order>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;

interface GetOrdersParams {
    order_status?:
        | 'all'
        | ''
        | 'received'
        | 'processing'
        | 'shipping'
        | 'completed'
        | 'cancelled'
        | 'backfilled'
        | 'archived';
    seller_org_uuid?: string;
    buyer_org_uuid?: string;
    athlete_uuid?: string;
    sort?: 'created_at' | '-created_at';
    page?: number;
    per_page?: number;
}

const getOrders = (params?: GetOrdersParams): ReturnTypeCollection => {
    console.log(params);
    const filterParams = {
        'filter[order_status]': params?.order_status,
        'filter[seller_org_uuid]': params?.seller_org_uuid,
        'filter[buyer_org_uuid]': params?.buyer_org_uuid,
        'filter[athlete_uuid]': params?.athlete_uuid,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/marketplace/orders`, { params: filterParams });
};

type ReturnTypeSingle = Promise<AxiosResponse<Order>>;

const updateOrder = (orderUuid: string, orderData: UpdateOrderParams): ReturnTypeSingle => {
    return Request.put(`admin/marketplace/orders/${orderUuid}`, orderData);
};

type ReturnTypeOrderStatuses = Promise<AxiosResponse<Array<string>>>;

const getOrderStatuses = (): ReturnTypeOrderStatuses => {
    return Request.get(`admin/marketplace/orders/order-statuses`);
};

export { updateOrder, getOrders, getOrderStatuses };
export type { UpdateOrderParams, GetOrdersParams };
