import React from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Typography,
    useTheme,
} from '@mui/material';
import { formatCurrency, stripMarkdown } from '../../../app.functions';
import { Organization } from '../../../utils';
import { grey } from '@mui/material/colors';
import { Link } from 'react-router-dom';

interface AthleteMarketplaceCardProps {
    id: string;
    sellerUuid: string;
    title: string;
    description: string;
    image: string;
    price: number;
    avatarUrl: string;
    headerTitle: string;
    headerSubtitle?: string;
    loading: boolean;
    organization: Organization;
    onPurchase(sellerUuid: string): void;
    saleDate?: number | null;
}

const AthleteMarketplaceCard: React.FC<AthleteMarketplaceCardProps> = ({
    id,
    sellerUuid,
    title,
    description,
    image,
    price,
    avatarUrl,
    headerTitle,
    headerSubtitle = '',
    loading,
    organization,
    onPurchase,
    saleDate,
}) => {
    const theme = useTheme();

    return (
        <Card
            sx={{
                width: 300,
                height: 440,
                borderRadius: 3,
                background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                color: theme.palette.common.white,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                },
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <CardHeader
                avatar={
                    <Avatar variant={'square'} src={avatarUrl} aria-label="recipe">
                        {title.substr(0, 2)}
                    </Avatar>
                }
                title={headerTitle}
                subheader={headerSubtitle}
                sx={{ color: 'white' }}
            />
            {image ? (
                <CardMedia
                    component="div"
                    sx={{
                        height: 120,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                    image={image}
                />
            ) : (
                <Box
                    sx={{
                        backgroundColor: grey[100],
                        height: 120,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <Typography sx={{ textAlign: 'center' }} variant={'h4'}>
                        -
                    </Typography>
                </Box>
            )}
            <CardContent sx={{ flexGrow: 1, position: 'relative', zIndex: 1, pt: 2 }}>
                <Typography gutterBottom variant="h6" component="h6">
                    {title}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    {description ? stripMarkdown(description) : '-'}
                </Typography>
            </CardContent>
            <CardActions sx={{ flexDirection: 'column', alignItems: 'stretch', padding: 2 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '8px',
                        margin: '4px',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onPurchase(sellerUuid);
                        }}
                        disabled={loading || !organization || typeof saleDate === 'number'}
                        sx={{
                            flex: 1,
                            padding: '10px',
                            borderRadius: 1,
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
                            },
                        }}
                    >
                        {typeof saleDate === 'number'
                            ? `Bought ${new Date(saleDate * 1000).toLocaleDateString()}`
                            : `Purchase ${formatCurrency({
                                  amount: price / 100,
                                  currency: 'usd',
                              })}`}
                    </Button>
                    <Link
                        to={`/marketplace/${organization.slug}/${id}`}
                        style={{ textDecoration: 'none', flex: 1 }}
                    >
                        <Button
                            variant="outlined"
                            fullWidth
                            sx={{
                                color: 'white',
                                borderColor: 'white',
                                padding: '10px',
                                '&:hover': {
                                    borderColor: 'white',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                            }}
                        >
                            View Details
                        </Button>
                    </Link>
                </Box>
            </CardActions>
        </Card>
    );
};

export default AthleteMarketplaceCard;
