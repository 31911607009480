import {
    gridRowSelectionStateSelector,
    GridToolbarContainer,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid-premium';
import { Box, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import { deleteExploration } from '../explore.api';
import { SearchInput } from '../../../components';
import debounce from 'lodash/debounce';

declare module '@mui/x-data-grid-premium' {
    interface ToolbarPropsOverrides {
        setRowSelectionModel: (v: any) => void;
        onError: () => void;
        onDeleteStart: () => void;
        onDeleteFinished: (amount: number) => void;
        onSearchChange: (v: string) => void;
    }
}

function CustomToolBar({
    setRowSelectionModel,
    onError,
    onDeleteStart,
    onDeleteFinished,
    onSearchChange,
}: {
    setRowSelectionModel: (v: any) => void;
    onError: () => void;
    onDeleteStart: () => void;
    onDeleteFinished: (amount: number) => void;
    onSearchChange: (v: string) => void;
}) {
    const apiRef = useGridApiContext();
    const selectedRows = useGridSelector(apiRef, gridRowSelectionStateSelector);
    const [searchValue] = useState<string>('');

    const handleCancelClick = () => {
        setRowSelectionModel([]);
    };

    const handleSearchChange = debounce((v: string) => {
        onSearchChange(v);
    }, 140);

    const handleDeleteClick = () => {
        const rowsToDelete = selectedRows.map((row) => row as string);
        // map each selected row to a promise that deletes the exploration
        // and use Promise.all() to wait for all of them to finish
        onDeleteStart();
        Promise.all(rowsToDelete.map((row) => deleteExploration(row as string)))
            .then(() => {
                setRowSelectionModel([]);
                onDeleteFinished(rowsToDelete.length);
            })
            .catch(() => {
                onError();
            });
    };
    return (
        <GridToolbarContainer
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Box>
                <SearchInput
                    onChange={(e) => handleSearchChange(e.target.value)}
                    onKeyUp={() => {}}
                    value={searchValue}
                />
            </Box>
            {selectedRows.length > 0 && (
                <Box>
                    <Button onClick={handleCancelClick}>Cancel</Button>
                    <IconButton onClick={handleDeleteClick}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )}
        </GridToolbarContainer>
    );
}

export default CustomToolBar;
