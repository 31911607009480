import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { MovementLogReq } from 'utils';

type ReturnType = Promise<AxiosResponse<{}>>;

const bulkLogActivity = (
    logs: MovementLogReq[],
    activityId: string,
    localTimezone: string,
): ReturnType => {
    return Request.post(`/workouts/activities/${activityId}/logs/bulk`, {
        logs: logs,
        local_timezone: localTimezone,
    });
};

export default bulkLogActivity;
