import Grid from '@mui/material/Grid';
import React, { ReactElement } from 'react';
import FilterSkeleton from './FilterSkeleton';
import TimelineSkeleton from './TimelineSkeleton';

type TimelineWithFilterSkeletonProps = {
    maxWidth?: number | string;
};

export default function TimelineWithFilterSkeleton({
    maxWidth = '100%',
}: TimelineWithFilterSkeletonProps): ReactElement {
    return (
        <Grid
            container
            sx={{
                marginTop: 32,
                padding: '32px',
                maxWidth: maxWidth,
                margin: '0 auto',
            }}
            spacing={10}
        >
            <Grid item xs={3}>
                <FilterSkeleton />
            </Grid>
            <Grid item xs={9}>
                <TimelineSkeleton />
            </Grid>
        </Grid>
    );
}
