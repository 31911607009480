import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityState,
    PayloadAction,
} from '@reduxjs/toolkit';
import {
    archiveMovement as apiArchiveMovement,
    attachAsset as apiAttachAsset,
    attachMediaAsAsset as apiAttachMediaAsAsset,
    createMovement as apiCreateMovement,
    getAllMovements as apiGetAllMovements,
    removeAsset as apiRemoveAsset,
    unarchiveMovement as apiUnarchiveMovement,
    updateMovement as apiUpdateMovement,
    viewMovement,
} from 'api';
import { AxiosError } from 'axios';
import {
    Asset,
    AttributeValue,
    Meta,
    Movement,
    MovementPreset,
    MovementType,
    PaginationLink,
} from 'utils';
import { RootState } from '.';
import { EncodingOptionsPayload } from '../../app.types';
import { loadAttributes } from './attributes';
import { uploadRemoved } from './media';

export interface MovementsError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export interface MovementsState extends EntityState<Movement> {
    isLoading: boolean;
    isLoaded: boolean;
    pagination: PaginationLink;
    paginationMeta: Meta;
    error: MovementsError;
    selected: string | null;
    selectedLoaded: boolean;
    selectedLoading: boolean;
    attaching: boolean;
}

const adapter = createEntityAdapter<Movement>({
    selectId: (movement) => movement.uuid,
});

export const initialState: MovementsState = adapter.getInitialState({
    isLoading: false,
    isLoaded: false,
    attaching: false,
    pagination: {
        first: null,
        prev: null,
        last: null,
        next: null,
    },
    paginationMeta: {
        path: '',
        per_page: 10,
        current_page: 1,
    },
    error: { message: '' },
    selected: null,
    selectedLoaded: false,
    selectedLoading: false,
});

export const loadMovements = createAsyncThunk<
    { data: Movement[]; pagination: PaginationLink; meta: Meta },
    { 'filter[name]': string; page?: number },
    { rejectValue: MovementsError }
>('movements/fetchAll', async (query, { rejectWithValue }) => {
    try {
        const { data } = await apiGetAllMovements(query);
        return { data: data.data, pagination: data.links, meta: data.meta };
    } catch (err: any) {
        const error: AxiosError<MovementsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Loading Movements' });
    }
});

export const loadSelected = createAsyncThunk<
    { data: Movement },
    { uuid: string },
    { rejectValue: MovementsError }
>('movements/view', async ({ uuid }, { rejectWithValue }) => {
    try {
        const { data } = await viewMovement(uuid);
        return {
            data,
        };
    } catch (err: any) {
        const error: AxiosError<MovementsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Loading Movements' });
    }
});

export const archiveMovement = createAsyncThunk<
    string,
    {
        movementUuid: string;
    },
    {
        rejectValue: MovementsError;
    }
>('movements/archive', async ({ movementUuid }, { rejectWithValue }) => {
    try {
        await apiArchiveMovement(movementUuid);
        return movementUuid;
    } catch (err: any) {
        const error: AxiosError<MovementsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const unArchiveMovement = createAsyncThunk<
    Movement,
    {
        movementUuid: string;
    },
    {
        rejectValue: MovementsError;
    }
>('movements/unarchive', async ({ movementUuid }, { rejectWithValue }) => {
    try {
        const { data } = await apiUnarchiveMovement(movementUuid);
        return data;
    } catch (err: any) {
        const error: AxiosError<MovementsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const createMovement = createAsyncThunk<
    Movement,
    {
        name: string;
        description: string | null;
        organization: string | null;
        movementType: MovementType;
        attributes: AttributeValue[];
        movement_presets: MovementPreset | null;
        cover_asset_uuid: string | null;
        uuid?: string;
    },
    {
        rejectValue: MovementsError;
    }
>(
    'movements/create',
    async (
        {
            name,
            description,
            movementType,
            attributes,
            uuid,
            organization,
            movement_presets,
            cover_asset_uuid,
        },
        { rejectWithValue, dispatch, getState },
    ) => {
        try {
            const { data } = await apiCreateMovement(
                name,
                description,
                organization,
                movementType,
                attributes,
                movement_presets,
                cover_asset_uuid,
                uuid,
            );

            dispatch(loadAttributes());

            const state: RootState = getState() as RootState;

            const uploadsToConnectToMovement = Object.keys(state.media.uploads).filter((upload) => {
                return (
                    state.media.uploads[upload].entityId === uuid &&
                    state.media.uploads[upload].progress === 1
                );
            });

            if (uploadsToConnectToMovement.length > 0) {
                const assets = uploadsToConnectToMovement.map((tmpKey) => ({
                    path: tmpKey,
                    movementUuid: uuid ?? '',
                    encodingOptions: state.media.uploads[tmpKey].encodingOptions,
                }));

                dispatch(attachMultipleAssets(assets)).then((results) => {
                    return Promise.all(
                        results.map((result) => {
                            return dispatch(uploadRemoved({ fileName: result.meta.arg.path }));
                        }),
                    );
                });
            }

            return data;
        } catch (err: any) {
            const error: AxiosError<MovementsError> = err;
            return rejectWithValue(error.response?.data ?? { message: 'Error' });
        }
    },
);

export const updateMovement = createAsyncThunk<
    Movement,
    {
        name: string;
        description: string | null;
        attributes: AttributeValue[];
        uuid: string;
        movement_presets: MovementPreset | null;
        cover_asset_uuid: string | null;
    },
    {
        rejectValue: MovementsError;
    }
>(
    'movements/update',
    async (
        { name, description, attributes, uuid, movement_presets, cover_asset_uuid },
        { rejectWithValue },
    ) => {
        try {
            const { data } = await apiUpdateMovement(
                uuid,
                name,
                description,
                attributes,
                movement_presets,
                cover_asset_uuid,
            );
            return data;
        } catch (err: any) {
            const error: AxiosError<MovementsError> = err;
            return rejectWithValue(error.response?.data ?? { message: 'Error' });
        }
    },
);

export const attachAsset = createAsyncThunk<
    Movement,
    {
        path: string;
        movementUuid: string;
        encodingOptions?: EncodingOptionsPayload;
    },
    {
        rejectValue: MovementsError;
    }
>('movements/attachAsset', async ({ path, movementUuid, encodingOptions }, { rejectWithValue }) => {
    try {
        const { data } = await apiAttachAsset(path, movementUuid, encodingOptions);
        return data;
    } catch (err: any) {
        const error: AxiosError<MovementsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const attachMediaAsAsset = createAsyncThunk<
    Movement,
    {
        mediaUuid: string;
        movementUuid: string;
    },
    {
        rejectValue: MovementsError;
    }
>('movements/attachMediaAsAsset', async ({ mediaUuid, movementUuid }, { rejectWithValue }) => {
    try {
        const { data } = await apiAttachMediaAsAsset(mediaUuid, movementUuid);
        return data;
    } catch (err: any) {
        const error: AxiosError<MovementsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const attachMultipleAssets = (
    assets: Array<{ path: string; movementUuid: string; encodingOptions?: EncodingOptionsPayload }>,
) => {
    return (dispatch: any) => {
        return Promise.all(assets.map((assetDefinition) => dispatch(attachAsset(assetDefinition))));
    };
};

export const removeAsset = createAsyncThunk<
    Movement,
    {
        assetUuid: string;
        movementUuid: string;
    },
    {
        rejectValue: MovementsError;
    }
>('movements/remove-asset', async ({ assetUuid, movementUuid }, { rejectWithValue }) => {
    try {
        const { data } = await apiRemoveAsset(assetUuid, movementUuid);
        return data;
    } catch (err: any) {
        const error: AxiosError<MovementsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const removeMultipleAssets = (movementUuid: string, assets: Array<string>) => {
    return (dispatch: any) => {
        return Promise.all(
            assets.map((assetUuid) =>
                dispatch(
                    removeAsset({
                        assetUuid,
                        movementUuid,
                    }),
                ),
            ),
        );
    };
};

export const movementSlice = createSlice({
    name: 'movements',
    initialState,
    reducers: {
        clearSelected: (state) => {
            state.selected = null;
        },
        notifyUploadStarted: (state, action: PayloadAction<{ id: string }>) => {
            adapter.updateOne(state, { id: action.payload.id, changes: { is_uploading: true } });
        },
        notifyUploadComplete: (state, action: PayloadAction<{ id: string }>) => {
            adapter.updateOne(state, { id: action.payload.id, changes: { is_uploading: false } });
        },
        assetProcessingComplete: (state, action: PayloadAction<{ asset: Asset }>) => {
            const movement = state.entities[action.payload.asset.assetable_uuid ?? ''];

            if (movement) {
                const assetIndex = movement.assets.findIndex((a) => {
                    return a.uuid === action.payload.asset.uuid;
                });
                const assets = movement.assets.map((asset, index) => {
                    if (index !== assetIndex) {
                        return asset;
                    }
                    return action.payload.asset;
                });
                adapter.updateOne(state, { id: movement.uuid, changes: { assets } });
            }
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(loadMovements.fulfilled, (state, { payload }) => {
            adapter.setAll(state, payload.data);
            state.error = { message: '' };
            state.pagination = payload.pagination;
            state.paginationMeta = payload.meta;
            state.isLoading = false;
            state.isLoaded = true;
        });
        addCase(loadMovements.pending, (state) => {
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(loadMovements.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Loading Movements';
            state.isLoading = false;
        });
        addCase(loadSelected.fulfilled, (state, { payload }) => {
            adapter.upsertOne(state, payload.data);
            state.selected = payload.data.uuid;
            state.error = { message: '' };
            state.selectedLoaded = true;
            state.selectedLoading = false;
        });
        addCase(loadSelected.pending, (state) => {
            state.selectedLoading = true;
        });
        addCase(loadSelected.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Loading Movements';
            state.selectedLoading = false;
        });
        addCase(createMovement.fulfilled, (state, { payload }) => {
            state.error = { message: '' };
            state.isLoading = false;
            adapter.setAll(state, [payload, ...selectAll(state)]);
        });
        addCase(createMovement.pending, (state) => {
            state.isLoading = true;
        });
        addCase(createMovement.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Saving Movement';
            state.isLoading = false;
        });
        addCase(archiveMovement.fulfilled, (state, { payload }) => {
            state.error = { message: '' };
            state.isLoading = false;
            adapter.removeOne(state, payload);
        });
        addCase(unArchiveMovement.fulfilled, (state, { payload }) => {
            state.error = { message: '' };
            state.isLoading = false;
            adapter.removeOne(state, payload.uuid);
        });
        addCase(updateMovement.fulfilled, (state, { payload }) => {
            state.error = { message: '' };
            state.isLoading = false;
            adapter.upsertOne(state, payload);
        });
        addCase(updateMovement.pending, (state) => {
            state.isLoading = true;
        });
        addCase(updateMovement.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Saving Movement';
            state.isLoading = false;
        });
        addCase(attachAsset.pending, (state) => {
            state.attaching = true;
        });
        addCase(attachAsset.fulfilled, (state, { payload }) => {
            state.attaching = false;
            adapter.updateOne(state, { id: payload.uuid, changes: { assets: payload.assets } });
        });
        addCase(attachAsset.rejected, (state) => {
            state.attaching = false;
        });
        addCase(removeAsset.fulfilled, (state, { payload }) => {
            adapter.updateOne(state, { id: payload.uuid, changes: { assets: payload.assets } });
        });
        addCase(attachMediaAsAsset.fulfilled, (state, { payload }) => {
            state.attaching = false;
            adapter.updateOne(state, { id: payload.uuid, changes: { assets: payload.assets } });
        });
        addCase(attachMediaAsAsset.pending, (state) => {
            state.attaching = true;
        });
        addCase(attachMediaAsAsset.rejected, (state) => {
            state.attaching = false;
        });
    },
});

export const { clearSelected, notifyUploadComplete, notifyUploadStarted, assetProcessingComplete } =
    movementSlice.actions;

export const movementsSelector = (state: RootState) => state.movements;
const { selectAll, selectById } = adapter.getSelectors<MovementsState>((state) => state);

export const getAllMovements = createSelector(movementsSelector, selectAll);

export const getSelectedMovement = createSelector([movementsSelector], (movements) =>
    selectById(movements, movements.selected ?? ''),
);
export const getSelectedMovementLoaded = createSelector(
    [movementsSelector],
    (movements) => movements.selectedLoaded,
);
export const getSelectedMovementLoading = createSelector(
    [movementsSelector],
    (movements) => movements.selectedLoading,
);

export const getAttaching = createSelector(movementsSelector, (state) => state.attaching);
