import { Request } from 'api';
import { AxiosResponse } from 'axios';
import type { Calendar } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Calendar>>>;

const getGroupCalendars = (groupId: string): ReturnType => {
    return Request.get(`/groups/${groupId}/calendars`);
};

export default getGroupCalendars;
