import React from 'react';
import { Helmet } from 'react-helmet';

interface LayoutMetaHeadProps {
    title?: string;
    description?: string;
    imageUrl?: string;
}

const LayoutMetaHead = ({ title, description, imageUrl }: LayoutMetaHeadProps) => {
    const metaTitle: string = title || '1Tul';
    const metaDescription: string =
        description ||
        'The first athlete-centered education and training platform where you own your data and can share it real-time with your entire athlete support team.';
    const metaUrl: string = window.location.href;
    const metaImageUrl: string = imageUrl || `${window.location.origin}/1TULlogo192.png`;
    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="Description" content={metaDescription} />

                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={metaImageUrl} />
                <meta property="og:url" content={metaUrl} />
                <meta name="twitter:card" content={metaImageUrl} />

                <meta property="og:site_name" content={metaTitle} />
                <meta name="twitter:image:alt" content={metaTitle} />
            </Helmet>
        </>
    );
};

export default LayoutMetaHead;
