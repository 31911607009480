import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Group } from 'utils';

type ReturnType = Promise<AxiosResponse<ImportErrorResponse | null>>;

export type ImportErrorResponse = {
    import_errors: String[];
};

const importMembers = (group: Group, tmpFilePath: string): ReturnType => {
    return Request.post(`/imports/group/members/${group.uuid}`, {
        file: tmpFilePath,
    });
};

export default importMembers;
