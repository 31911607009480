import { Meta } from '@cubejs-client/core';
import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CircularProgress,
    Container,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Snackbar,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { queue, QueueObject } from 'async';
import { Form, Formik } from 'formik';
import map from 'lodash/map';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Can, TypographyTitleh6 } from '../../../components';
import FormikAutosave from '../../../components/Forms/FormikAutosave';
import {
    addReportToDashboard,
    deleteDashboard,
    removeReportFromDashboard,
    updateChart,
    updateDashboard,
    updateOrderOfChartsInDashboard,
    viewDashboard,
} from '../api/dashboards.api';
import { getAllSavedFilters } from '../api/savedFilters.api';
import { Chart, Dashboard, SavedFilter } from '../community.types';
import DashboardSharingDrawer from '../components/DashboardSharingDrawer';
import TimeseriesReport from '../components/TimeseriesReport';

interface Props {
    cubeMeta: Meta;
    onDelete: (dashboardId: string) => void;
}

function resetChartIndex(charts: Chart[]) {
    return charts.map((c, index) => ({
        ...c,
        order: index,
        settings: {
            width: c.settings.width ?? 6,
        },
    }));
}

function ViewDashboard({ cubeMeta, onDelete }: Props) {
    let { dashboardId } = useParams<{ dashboardId: string }>();
    const { push } = useHistory();
    const [loading, setLoading] = useState(false);
    const [dashboard, setDashboard] = useState<Dashboard>();
    const [savedFilters, setSavedFilters] = useState<SavedFilter[]>([]);
    const [sharingDrawerOpen, setSharingDrawerOpen] = useState(false);
    const [raised, setRaised] = useState(false);
    const [creating, setCreating] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [savedFilterId, setSavedFilterId] = useState('');
    const [savedFilter, setSavedFilter] = useState<SavedFilter>();
    const [mode, setMode] = useState<'view' | 'edit'>('view');
    const [q, setQ] = useState<
        QueueObject<{
            task: 'delete' | 'update' | 'reorder';
            dashboardId: string;
            payload: {
                chartId?: string;
                order?: string[];
                updateFields?: {
                    settings?: {
                        width?: number;
                    };
                };
            };
        }>
    >();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setQ(
            queue(function (
                task: {
                    task: 'delete' | 'update' | 'reorder';
                    dashboardId: string;
                    payload: {
                        chartId?: string;
                        order?: string[];
                        updateFields?: {
                            settings?: {
                                width?: number;
                            };
                        };
                    };
                },
                callback: any,
            ) {
                if (task.task === 'delete') {
                    removeReportFromDashboard(task.dashboardId, task.payload.chartId ?? '')
                        .then(() => {})
                        .catch(() => {})
                        .finally(() => callback());
                }
                if (task.task === 'reorder') {
                    updateOrderOfChartsInDashboard(task.dashboardId, task.payload.order ?? [])
                        .then(() => {})
                        .catch(() => {})
                        .finally(() => callback());
                }
                if (task.task === 'update') {
                    updateChart(
                        task.dashboardId,
                        task.payload?.chartId ?? '',
                        task.payload?.updateFields ?? {},
                    )
                        .then(() => {})
                        .catch(() => {})
                        .finally(() => callback());
                }
            }, 1),
        );
    }, []);

    useEffect(() => {
        if (dashboardId) {
            setLoading(true);
            viewDashboard(dashboardId)
                .then((d) => {
                    setDashboard({
                        ...d.data,
                        charts: resetChartIndex(d.data.charts),
                    });
                    getAllSavedFilters({
                        limit: 250,
                        'filter[organization_uuid]': d.data.organization?.uuid ?? undefined,
                    })
                        .then((d) => setSavedFilters(d.data.data))
                        .catch(() => {})
                        .finally(() => setCreateLoading(false));
                })
                .catch(() => {})
                .finally(() => setLoading(false));
            setCreateLoading(true);
        }
    }, [dashboardId]);

    useEffect(() => {
        if (savedFilterId && savedFilters.length > 0) {
            setSavedFilter(savedFilters.find((f) => f.uuid === savedFilterId));
        }
    }, [savedFilterId, savedFilters]);

    const handleDeleteChart = useCallback(
        (chartId: string) => {
            if (dashboard && q) {
                q.push({ task: 'delete', dashboardId: dashboard.uuid, payload: { chartId } });
                const newCharts = resetChartIndex(
                    dashboard.charts.filter((c) => c.uuid !== chartId),
                );

                setDashboard({
                    ...dashboard,
                    charts: newCharts,
                });
            }
        },
        [dashboard, q],
    );

    const handleChartPositionChanged = useCallback(
        (id: string, e: SelectChangeEvent<number>) => {
            setDashboard((dashboard) => {
                if (dashboard) {
                    // set the new position
                    let chartArray = dashboard.charts;

                    let chart = dashboard.charts.find((c) => c.uuid === id);

                    if (!chart) {
                        return dashboard;
                    }

                    chartArray.splice(chart.order || 0, 1);
                    chartArray.splice(+e.target.value, 0, chart);

                    // reset the index values to the actual position in the array
                    chartArray = resetChartIndex(chartArray);

                    q?.push({
                        task: 'update',
                        dashboardId: dashboard.uuid,
                        payload: { chartId: id, order: map(chartArray, 'uuid') },
                    });

                    return {
                        ...dashboard,
                        charts: chartArray,
                    };
                }
                return dashboard;
            });
        },
        [q],
    );

    const handleChartDataChanged = useCallback(
        (id: string, e: SelectChangeEvent<number>) => {
            setDashboard((dashboard) => {
                if (dashboard && q) {
                    q.push({
                        task: 'update',
                        dashboardId: dashboard.uuid,
                        payload: {
                            chartId: id,
                            updateFields: {
                                settings: {
                                    width: +e.target.value,
                                },
                            },
                        },
                    });
                    return {
                        ...dashboard,
                        charts: dashboard.charts.map((c) => {
                            if (c.uuid === id) {
                                return {
                                    ...c,
                                    settings: {
                                        ...c.settings,
                                        width: +e.target.value,
                                    },
                                };
                            }
                            return c;
                        }),
                    };
                }
                return dashboard;
            });
        },
        [q],
    );

    const [snackMessage, setSnackMessage] = useState<string>('');

    return (
        <>
            <Snackbar
                open={Boolean(snackMessage)}
                onClose={() => setSnackMessage('')}
                message={snackMessage}
                autoHideDuration={3000}
            ></Snackbar>
            <Drawer
                PaperProps={{ style: { width: isMobile ? '100%' : '60%' } }}
                anchor={'right'}
                open={creating}
                onBackdropClick={() => setCreating(false)}
            >
                <form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        overflowY: 'scroll',
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        setCreateLoading(true);
                        addReportToDashboard(dashboardId, savedFilterId)
                            .then((chart) => {
                                setCreating(false);
                                if (!dashboard || !chart) {
                                    return;
                                }
                                setDashboard({
                                    ...dashboard,
                                    charts: [...dashboard.charts, chart.data],
                                });
                            })
                            .catch((e) => {
                                console.log(e);
                            })
                            .finally(() => setCreateLoading(false));
                    }}
                >
                    {createLoading && <LinearProgress />}
                    <Box
                        display="flex"
                        alignItems={'center'}
                        justifyContent="space-between"
                        style={{ borderBottom: '1px solid grey', padding: 20 }}
                    >
                        <Box display="flex" alignItems="center">
                            <TrendingUpIcon />
                            <TypographyTitleh6
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    alignItems: 'center',
                                    marginLeft: 8,
                                }}
                            >
                                Add A Report To Dashboard
                            </TypographyTitleh6>
                        </Box>
                        <IconButton
                            onClick={() => setCreating(false)}
                            aria-label="close"
                            size="medium"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        p={8}
                        borderBottom={'1px solid grey'}
                        flex={1}
                    >
                        <Typography variant={'body1'} style={{ marginBottom: 8 }}>
                            Choose and preview a report to add to your dashboard
                        </Typography>
                        <Select
                            name="saved_filter_id"
                            type="text"
                            fullWidth
                            label="Choose Report"
                            variant="outlined"
                            inputProps={{ name: 'saved_filter_id', id: 'saved_filter_id' }}
                            disabled={createLoading}
                            value={savedFilterId}
                            onChange={(e) => setSavedFilterId(e.target.value as string)}
                        >
                            {savedFilters.map((filter) => (
                                <MenuItem key={filter.uuid} value={filter.uuid}>
                                    {filter.title}
                                </MenuItem>
                            ))}
                        </Select>
                        {savedFilter && (
                            <TimeseriesReport
                                filters={savedFilter.filters}
                                cubeMeta={cubeMeta}
                                type={savedFilter.type}
                            />
                        )}
                    </Box>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                        p={8}
                        style={{ flexShrink: 1 }}
                    >
                        <Button variant={'outlined'} onClick={() => setCreating(false)}>
                            Cancel
                        </Button>
                        <Button
                            disabled={createLoading}
                            variant={'contained'}
                            color={'primary'}
                            type={'submit'}
                        >
                            Add Report
                        </Button>
                    </Box>
                </form>
            </Drawer>
            {loading && (
                <Box padding={80} display="flex" width="100%" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
            {dashboard && !loading && (
                <>
                    {sharingDrawerOpen && (
                        <DashboardSharingDrawer
                            dashboardId={dashboardId}
                            onClose={() => setSharingDrawerOpen(false)}
                            open={sharingDrawerOpen}
                            onUsersLoaded={() => {}}
                        ></DashboardSharingDrawer>
                    )}
                    <Container maxWidth={'xl'}>
                        {dashboard && (
                            <Can I={'dashboard:update'} this={dashboard}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {mode === 'edit' && (
                                        <>
                                            <Button
                                                onClick={() => {
                                                    deleteDashboard(dashboard.uuid)
                                                        .then(() => {
                                                            onDelete(dashboard.uuid);
                                                            push(`/community/dashboards`);
                                                        })
                                                        .catch(() => {
                                                            setSnackMessage(
                                                                'Unable to delete dashboard',
                                                            );
                                                        });
                                                }}
                                            >
                                                Delete
                                            </Button>
                                            <Button onClick={() => setMode('view')}>Close</Button>
                                        </>
                                    )}
                                    {mode === 'view' && (
                                        <>
                                            <IconButton
                                                onClick={() => setMode('edit')}
                                                size="large"
                                            >
                                                <Typography variant="body1" color="primary">
                                                    EDIT
                                                </Typography>
                                                <EditIcon color="primary" />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => setSharingDrawerOpen(true)}
                                                size="large"
                                            >
                                                <Typography variant="body1" color="primary">
                                                    SHARE
                                                </Typography>
                                                <ShareIcon color="primary" />
                                            </IconButton>
                                        </>
                                    )}
                                </Box>
                            </Can>
                        )}
                        <Box p={8}>
                            <Box>
                                {mode === 'edit' && (
                                    <Formik
                                        initialValues={{
                                            title: dashboard.title,
                                            description: dashboard.description ?? '',
                                        }}
                                        onSubmit={(values) => {
                                            updateDashboard(dashboard.uuid, values)
                                                .then(() =>
                                                    setDashboard((dashboard) => {
                                                        if (dashboard) {
                                                            return {
                                                                ...dashboard,
                                                                ...values,
                                                            };
                                                        }
                                                        return dashboard;
                                                    }),
                                                )
                                                .catch(() => {});
                                        }}
                                    >
                                        {({ handleChange, values }) => (
                                            <Form>
                                                <FormikAutosave />
                                                <Stack spacing={4}>
                                                    <TextField
                                                        id="dashboard-title"
                                                        label="Dashboard Title"
                                                        value={values.title}
                                                        name={'title'}
                                                        onChange={handleChange}
                                                    />
                                                    <TextField
                                                        id="dashboard-description"
                                                        label="Dashboard Description"
                                                        multiline
                                                        rows={3}
                                                        name={'description'}
                                                        value={values.description}
                                                        onChange={handleChange}
                                                    />
                                                </Stack>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                                {mode === 'view' && (
                                    <>
                                        <Typography variant={'h5'}>{dashboard.title}</Typography>
                                        <Typography variant={'body1'}>
                                            {dashboard.description}
                                        </Typography>
                                    </>
                                )}
                            </Box>
                        </Box>
                        <Grid container>
                            {dashboard.charts.map((chart) => (
                                <Grid key={chart.uuid} item xs={12} sm={chart.settings?.width ?? 6}>
                                    <Card sx={{ padding: 8, margin: 4 }}>
                                        <CardHeader
                                            title={chart.savedFilter.title}
                                            action={
                                                mode === 'edit' && (
                                                    <Box sx={{ display: 'flex' }}>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleDeleteChart(chart.uuid)
                                                            }
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                        <FormControl fullWidth>
                                                            <InputLabel
                                                                id={`${chart.uuid}-position`}
                                                            >
                                                                Position
                                                            </InputLabel>
                                                            <Select
                                                                labelId={`${chart.uuid}-position`}
                                                                id={`${chart.uuid}-position`}
                                                                value={chart.order}
                                                                label="Position"
                                                                onChange={(e) =>
                                                                    handleChartPositionChanged(
                                                                        chart.uuid,
                                                                        e,
                                                                    )
                                                                }
                                                            >
                                                                {[
                                                                    ...Array(
                                                                        dashboard.charts.length,
                                                                    ).keys(),
                                                                ].map((i) => (
                                                                    <MenuItem key={i} value={i}>
                                                                        {i}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl fullWidth>
                                                            <InputLabel id={`${chart.uuid}-width`}>
                                                                Width
                                                            </InputLabel>
                                                            <Select
                                                                labelId={`${chart.uuid}-width`}
                                                                id={`${chart.uuid}-width`}
                                                                value={chart.settings?.width}
                                                                label="Width"
                                                                onChange={(e) =>
                                                                    handleChartDataChanged(
                                                                        chart.uuid,
                                                                        e,
                                                                    )
                                                                }
                                                            >
                                                                <MenuItem value={4}>1/3</MenuItem>
                                                                <MenuItem value={6}>1/2</MenuItem>
                                                                <MenuItem value={9}>3/4</MenuItem>
                                                                <MenuItem value={12}>Full</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                )
                                            }
                                        />
                                        <CardContent>
                                            <TimeseriesReport
                                                filters={chart.savedFilter.filters}
                                                cubeMeta={cubeMeta}
                                                type={chart.savedFilter.type}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                            <Can I={'dashboard:update'} this={dashboard}>
                                <Grid item xs={12} sm={6}>
                                    <Card
                                        onMouseOver={() => setRaised(true)}
                                        onMouseOut={() => setRaised(false)}
                                        raised={raised}
                                        style={{ border: '1px dashed' }}
                                    >
                                        <CardActionArea onClick={() => setCreating(true)}>
                                            <Box
                                                height={300}
                                                display={'flex'}
                                                flexDirection={'column'}
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <AddIcon fontSize={'large'} />
                                                <Typography variant={'h5'}>Add Report</Typography>
                                                <Typography
                                                    sx={{ marginTop: 3 }}
                                                    variant={'subtitle2'}
                                                >
                                                    Multiple reports can be added to a dashboard
                                                </Typography>
                                                <Typography
                                                    sx={{ marginTop: 3 }}
                                                    variant={'subtitle2'}
                                                >
                                                    The edit icon allows you to adjust the position
                                                    and size of each report
                                                </Typography>
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            </Can>
                        </Grid>
                    </Container>
                </>
            )}
        </>
    );
}

export default ViewDashboard;
