import moment from 'moment';
import { Actions, Dimensions, EntityType, TimelineEntries, TimelineType } from 'utils';
import { GenericSubject } from './ability';

type EntryDetails = {
    desc: string;
    url: string | null;
    permissionObject: GenericSubject;
    buttonText: string;
    buttonPermission: Actions;
};

type EntriesTypeMap = {
    [key in TimelineType]: EntryDetails;
};

const convertToDateSentence = (startTime: number | null, endTime: number | null): string => {
    if (!startTime || (!startTime && !endTime)) {
        return '';
    }
    const startTimeMoment = moment(startTime * 1000);
    let dateString = ` ${startTimeMoment.format('MM/DD/YY').toString()}`;

    if (!endTime) {
        dateString = `${dateString} `;
    } else {
        dateString = `${dateString} at ${startTimeMoment.format('h:mma').toString()}`;
    }
    return dateString;
};

const timelineTypeMapper = (item: TimelineEntries): EntryDetails => {
    const timelineType: EntriesTypeMap = {
        'group.created': {
            desc: `${item.user_first_name} added the group ${item.actionable_name} to ${item.organization_name}`,
            url: `/community/groups/${item.actionable_id}`,
            permissionObject: {
                object: EntityType.GROUP,
                uuid: item.group_id ?? '',
                organization: { uuid: item.organization_id ?? '' },
                organization_uuid: item.organization_id ?? '',
                owner_uuid: item.group_owner_id ?? '',
            },
            buttonText: 'View Group',
            buttonPermission: 'group:view-members',
        },
        'individual.created': {
            desc: `${item.user_first_name} added ${item.actionable_name} to ${item.organization_name}`,
            url: `/community/individuals/${item.actionable_id}`,
            permissionObject: {
                object: EntityType.INDIVIDUAL,
                uuid: item.individual_id ?? '',
                organization: { uuid: item.organization_id ?? '' },
                organization_uuid: item.organization_id ?? '',
                owner_uuid: item.individual_owner_id ?? '',
                managing_user: { uuid: item.individual_secondary_owner_id ?? '' },
            },
            buttonText: 'View Individual',
            buttonPermission: 'individual:view',
        },
        'program.added.to.calendar': {
            desc: ` ${item.user_first_name} added some sessions to ${
                item.individual_name
            }'s calendar starting ${convertToDateSentence(
                item.event_starts_at,
                item.event_ends_at,
            )}`,
            url: null,
            permissionObject: {
                object: EntityType.INDIVIDUAL,
                uuid: item.individual_id ?? '',
                organization: { uuid: item.organization_id ?? '' },
                organization_uuid: item.organization_id ?? '',
                owner_uuid: item.individual_owner_id ?? '',
                managing_user: { uuid: item.individual_secondary_owner_id ?? '' },
            },
            buttonText: 'View Calendar',
            buttonPermission: 'program:view',
        },
        'organization.created': {
            desc: `${item.actionable_name} was created by ${item.user_first_name}`,
            url: null,
            permissionObject: {
                object: EntityType.ORGANIZATION,
                uuid: item.organization_id ?? '',
                owner_uuid: item.organization_owner_id ?? '',
            },
            buttonText: 'View Organization',
            buttonPermission: 'organization:update',
        },
        'form.submitted': {
            desc: `${item.user_first_name} ${item.user_last_name} submitted  ${item.actionable_name} for ${item.timelineable_name}`,
            url: `/assessments/${item.degenerate_id}`,
            permissionObject: {
                object: EntityType.INDIVIDUAL,
                uuid: item.individual_id ?? '',
                organization: { uuid: item.organization_id ?? '' },
                organization_uuid: item.organization_id ?? '',
                owner_uuid: item.individual_owner_id ?? '',
                managing_user: { uuid: item.individual_secondary_owner_id ?? '' },
            },
            buttonText: 'View Assessment Result',
            buttonPermission: 'individual:manage-submissions',
        },
        'session.activity.started': {
            desc: `${item.user_first_name} started logging ${item.actionable_name} for ${item.timelineable_name}`,
            url: `/train/activities/${item.activity_id}`,
            permissionObject: {
                object: EntityType.INDIVIDUAL,
                uuid: item.individual_id ?? '',
                organization: { uuid: item.organization_id ?? '' },
                organization_uuid: item.organization_id ?? '',
                owner_uuid: item.individual_owner_id ?? '',
                managing_user: { uuid: item.individual_secondary_owner_id ?? '' },
            },
            buttonText: !item.activity_ended_at ? 'View Activity In Progress' : 'Edit Log',
            buttonPermission: 'individual:manage-logs',
        },
        'session.activity.ended': {
            desc: `${item.user_first_name} finished logging  ${item.actionable_name} for ${item.timelineable_name}`,
            url: `/activities/${item.activity_id}`,
            permissionObject: {
                object: EntityType.INDIVIDUAL,
                uuid: item.individual_id ?? '',
                organization: { uuid: item.organization_id ?? '' },
                organization_uuid: item.organization_id ?? '',
                owner_uuid: item.individual_owner_id ?? '',
                managing_user: { uuid: item.individual_secondary_owner_id ?? '' },
            },
            buttonText: 'View Log Details',
            buttonPermission: 'individual:manage-logs',
        },
    };

    return timelineType[item.type];
};

const listOfFilters = (selectedFilters: Dimensions[], filter: Dimensions) => {
    let filterList = selectedFilters;
    const filterIndex = filterList.findIndex((f) => filter.type === f.type && filter.id === f.id);

    if (filterIndex > -1) {
        const tempFilterList = [...selectedFilters];
        tempFilterList.splice(filterIndex, 1);
        filterList = tempFilterList;
    } else {
        filterList = [...selectedFilters, filter];
    }

    return filterList;
};

const searchDimensions = <T extends Record<string, any[]>>(
    searchFilters: Record<string, string>,
    filters: T,
) => {
    if (!Object.entries(searchFilters).length) {
        return filters;
    }
    const filtersArray = Object.entries(searchFilters).reduce((acc, [key, value]) => {
        acc[key as keyof T] = filters[key].filter((filter) => {
            return filter.name.toLowerCase().includes(value.toLowerCase() as string);
        });
        return acc;
    }, {} as any);

    return { ...filters, ...filtersArray };
};

export { listOfFilters, searchDimensions, timelineTypeMapper };
