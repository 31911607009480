import { DateRange, Dialpad, TextFields, Timer, Today } from '@mui/icons-material';
import { Chip, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers-pro';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React, { ReactElement, useState } from 'react';
import { AttributeValue, AttributeValueType, deleteItemFromIndexOfArray } from 'utils';

export interface AttributeValueInputProps {
    /** value The value of the input field */
    value: AttributeValue;
    /** onChange callback for updating the attribute value */
    onChange: (event: any) => void;

    error?: string;
}

export default function AttributeValueInput({
    value,
    onChange,
    error = '',
}: AttributeValueInputProps): ReactElement {
    const [currentValue, setCurrentValue] = useState<string>(value.value as string);
    const onValueChanedDebounced = (newValue: string) => {
        onChange({
            ...value,
            value: newValue,
        });
    };

    // returns unselected choices of an attribute
    const getChoices = () => {
        if (value.choices?.length && typeof value.value !== 'string') {
            // Create a copy of value.choices
            let choices: Array<string> = [...value.choices];

            value.value.map((val) => {
                const ind = choices.indexOf(val);
                if (ind > -1) {
                    choices = deleteItemFromIndexOfArray(choices, ind);
                }
            });

            // Sort the new array
            let sortedChoices = [...choices].sort();

            return sortedChoices;
        }
        return [];
    };

    const onValueChanged = (event: any) => {
        setCurrentValue(event.target.value);
        onValueChanedDebounced(event.target.value);
    };

    const handleTagChange = (newValue: (string | string[])[]) => {
        onChange({
            ...value,
            value: newValue,
        });
    };

    const handleInsertListValue = (newValue: string) => {
        if (value.value.indexOf(newValue) === -1) {
            handleTagChange([...value.value, newValue]);
        }
    };
    return (
        <React.Fragment>
            {value.type === AttributeValueType.TEXT && (
                <TextField
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <TextFields color="action" />
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                    error={Boolean(error)}
                    helperText={error as string}
                    value={currentValue}
                    onChange={onValueChanged}
                    inputProps={{ maxLength: 200 }}
                />
            )}
            {value.type === AttributeValueType.NUMERIC && (
                <TextField
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Dialpad color="action" />
                            </InputAdornment>
                        ),
                    }}
                    id="text-value"
                    type="number"
                    variant="standard"
                    error={Boolean(error)}
                    helperText={error as string}
                    value={currentValue}
                    onChange={onValueChanged}
                />
            )}
            {value.type === AttributeValueType.DATE && (
                <DatePicker
                    value={
                        isEmpty(currentValue)
                            ? null
                            : DateTime.fromFormat(currentValue as string, 'yyyy/MM/DD')
                    }
                    onChange={(newDate: DateTime | null) => {
                        const newDateValueString = newDate?.toFormat('yyyy/MM/dd');
                        setCurrentValue(newDateValueString || '');
                        onValueChanedDebounced(newDateValueString || '');
                    }}
                    slotProps={{
                        textField: {
                            helperText: error as string,
                            variant: 'standard',
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Today color="action" />
                                    </InputAdornment>
                                ),
                            },
                        },
                    }}
                />
            )}
            {value.type === AttributeValueType.TIME && (
                <TimePicker
                    value={
                        isEmpty(currentValue)
                            ? null
                            : DateTime.fromFormat(currentValue as string, 'HH:mm:ss')
                    }
                    format="hh:mm a"
                    onChange={(newDate: DateTime | null) => {
                        const newDateValueString = newDate?.toFormat('HH:mm:ss');
                        setCurrentValue(newDateValueString || '');
                        onValueChanedDebounced(newDateValueString || '');
                    }}
                    slotProps={{
                        textField: {
                            helperText: error as string,
                            variant: 'standard',
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <Timer color="action" />
                                    </InputAdornment>
                                ),
                            },
                        },
                    }}
                />
            )}
            {value.type === AttributeValueType.DATETIME && (
                <DateTimePicker
                    value={
                        isEmpty(currentValue)
                            ? null
                            : DateTime.fromFormat(currentValue as string, 'yyyy-MM-DD HH:mm:ss')
                    }
                    format="MM/dd/yyyy hh:mm a"
                    onChange={(newDate: DateTime | null) => {
                        const newDateValueString = newDate?.toFormat('yyyy-MM-dd HH:mm:ss');
                        setCurrentValue(newDateValueString || '');
                        onValueChanedDebounced(newDateValueString || '');
                    }}
                    slotProps={{
                        textField: {
                            helperText: error as string,
                            variant: 'standard',
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <DateRange color="action" />
                                    </InputAdornment>
                                ),
                            },
                        },
                    }}
                />
            )}
            {value.type === AttributeValueType.LIST && Array.isArray(currentValue) && (
                <Autocomplete
                    multiple
                    id="tags-filled"
                    options={getChoices()}
                    defaultValue={[]}
                    freeSolo
                    onChange={(event, val) => {
                        handleTagChange(val);
                    }}
                    onInputChange={(event, val) => {
                        const length = val.length;
                        let newVal = val;
                        if (length > 1 && val[length - 1] === ',') {
                            newVal = newVal.replace(',', '');
                            handleInsertListValue(newVal);
                        }
                    }}
                    sx={{ width: '100%' }}
                    limitTags={2}
                    value={typeof value.value === 'string' ? currentValue : value.value}
                    renderTags={(value: any, getTagProps) =>
                        value.map((option: string, index: number) => (
                            // eslint-disable-next-line react/jsx-key
                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            error={Boolean(error)}
                            helperText={error as string}
                            onBlur={(event) =>
                                event.target.value.length &&
                                handleInsertListValue(event.target.value)
                            }
                            placeholder="Type new value..."
                            label={`List values here`}
                        />
                    )}
                />
            )}
        </React.Fragment>
    );
}
