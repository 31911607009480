import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FullScreenDialogCard from '../components/HowToDialogCard';
import TeamDetails from 'images/TeamDetailsView.png';
import ListOfTeams from 'images/ListOfGroups.png';
import TeamAccess from 'images/GroupAccessPermissions.png';
import AdminInviteToGroup from '../media/AdminInviteToGroup.png';
import AdminCreateIndividual from '../media/AdminCreateIndividual.png';
import AdminInviteManagingUser from '../media/AdminInviteManagingUser.png';

function AdminGroupManagement() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL allows you to easily manage large groups.
                    </Typography>
                </Grid>

                <FullScreenDialogCard
                    image={ListOfTeams}
                    title="Team View"
                    headline="View All Your Teams in One Place"
                    description="View all your teams in one place."
                />
                <FullScreenDialogCard
                    image={TeamDetails}
                    title="Team Dashboard"
                    headline="Team Dashboard"
                    description="Quickly view members, assign sessions and learning content, or import a large number of athletes from a csv file."
                />
                <FullScreenDialogCard
                    image={TeamAccess}
                    title="Team Permissions"
                    headline="Team Permissions"
                    description="Invite other coaches, trainers, or athletes to the team and set their access permissions."
                />
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Invite new members thru QR codes or add them manually to the group.
                    </Typography>
                </Grid>

                <FullScreenDialogCard
                    image={AdminInviteToGroup}
                    title="Invite Members to Group Link or QR Code"
                    headline="Invite Members to Group Link or QR Code"
                    description="Invite Members to a Group using a Link or QR Code."
                />
                <FullScreenDialogCard
                    image={AdminCreateIndividual}
                    title="Create Individuals"
                    headline="Create Individuals"
                    description="Quickly create new individuals and collect data on them without needing a user attached to the individual. No need to wait for email confirmations before collecting data."
                />
                <FullScreenDialogCard
                    image={AdminInviteManagingUser}
                    title="Invite Managing User to Individual"
                    headline="Invite Managing User to Individual"
                    description="When you are ready, invite the managing user to the individual so the athlete can see their data and log for themselves."
                />
            </Grid>
        </>
    );
}

export default AdminGroupManagement;
