import { OpenInNew } from '@mui/icons-material';
import {
    Dialog,
    Fade,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useState } from 'react';
import { cubejsApi } from '../../../app.functions';
import { DateRangeValue } from '../../../app.types';
import { Individual, openInNewTab } from '../../../utils';
import {
    loadHighSpeedSprintsData,
    loadLogCounts,
    loadSprintData,
    loadTimeSegmentKpis,
} from '../api/kpi-queries';
import { aggregateData } from '../community.func';
import { IndividualKpis } from '../community.types';
import IndividualKpisCard from '../components/IndividualKpisCard';

interface Props {
    individual: Individual;
    fullname: string;
}

const IndividualDetailsKpis = ({ individual, fullname }: Props) => {
    const [kpis, setKpis] = useState<IndividualKpis>({});
    const [highSpeedSprintsDrilldown, setHighSpeedSprintsDrilldown] = useState<any>();
    const [drilldownQuery, setDrilldownQuery] = useState<any>();
    const [drilldownResults, setDrilldownResults] = useState<any>();
    const [maxVDrilldown, setMaxVDrilldown] = useState<any>();
    const [maxSpeedDrilldownQuery, setMaxSpeedDrilldownQuery] = useState<any>();
    const [maxSpeedDrilldownResults, setMaxSpeedDrilldownResults] = useState<any>();
    const [isLoadingKpis, setIsLoadingKpis] = useState(false);

    useEffect(() => {
        if (!drilldownQuery) {
            return;
        }
        cubejsApi.load(drilldownQuery).then((resultSet) => {
            setDrilldownResults(resultSet.tablePivot());
        });
    }, [drilldownQuery]);

    useEffect(() => {
        if (!maxSpeedDrilldownQuery) {
            return;
        }
        cubejsApi.load(maxSpeedDrilldownQuery).then((resultSet) => {
            setMaxSpeedDrilldownResults(resultSet.tablePivot());
        });
    }, [maxSpeedDrilldownQuery]);

    // load kpis
    useEffect(() => {
        const fetchKpis = async () => {
            if (individual) {
                setIsLoadingKpis(true);
                try {
                    const results = await loadSprintData(individual.uuid);

                    const splits = await cubejsApi.load({
                        dimensions: ['DimSplit.splitDistanceName', 'DimSplit.minDistanceYards'],
                        measures: ['FactSprintSplits.minTime'],
                        filters: [
                            {
                                dimension: 'DimIndividual.uuid',
                                operator: 'equals',
                                values: [individual.uuid],
                            },
                            {
                                dimension: 'FactSprintSplits.load',
                                operator: 'equals',
                                values: ['0', '1'],
                            },
                        ],
                        order: {
                            'DimSplit.minDistanceYards': 'asc',
                        },
                    });

                    const maxVCurrentTable = results.tablePivot({
                        fillMissingDates: true,
                    });

                    setMaxVDrilldown(
                        results.drillDown(
                            {
                                xValues: [],
                                yValues: ['FactSprintsRanked.vMaxMaxMph'],
                            },
                            { fillMissingDates: true },
                        ),
                    );

                    const maxSpeeds = aggregateData(
                        maxVCurrentTable,
                        'FactSprintsRanked.vMaxMaxMph',
                        'max',
                    );

                    const totalSprints = aggregateData(
                        results.tablePivot({
                            fillMissingDates: true,
                        }),
                        'FactSprintsRanked.count',
                        'sum',
                    );

                    const totalActiveDays = aggregateData(
                        results.tablePivot({
                            fillMissingDates: true,
                        }),
                        'FactSprintsRanked.count',
                        'countNonZero',
                    );

                    const highSpeedSprintsData = await loadHighSpeedSprintsData(individual.uuid);

                    setHighSpeedSprintsDrilldown(
                        highSpeedSprintsData.drillDown(
                            {
                                xValues: [],
                                yValues: ['FactSprintsRanked.count'],
                            },
                            { fillMissingDates: true },
                        ),
                    );

                    const highSpeedSprints = aggregateData(
                        highSpeedSprintsData.tablePivot({
                            fillMissingDates: true,
                        }),
                        'FactSprintsRanked.count',
                        'sum',
                    );

                    const highSpeedDays = aggregateData(
                        highSpeedSprintsData.tablePivot({
                            fillMissingDates: true,
                        }),
                        'FactSprintsRanked.count',
                        'countNonZero',
                    );

                    const activitiesData = await loadLogCounts(individual.uuid);

                    const activitiesCounts = aggregateData(
                        activitiesData.tablePivot({
                            fillMissingDates: true,
                        }),
                        'FactActivities.count',
                        'sum',
                    );

                    const maxTimeSegmentResults = await loadTimeSegmentKpis(individual.uuid);

                    setKpis({
                        totalSprints,
                        maxSpeeds,
                        highSpeedSprints,
                        highSpeedDays,
                        totalActiveDays: totalActiveDays,
                        splitTimes: splits.tablePivot(),
                        logsSubmitted: activitiesCounts,
                        maxTimeSegment: {
                            maxVelocity:
                                maxTimeSegmentResults.tablePivot()[0][
                                    'FactSprintTimeSegments.velocityMaxMph'
                                ],
                            averageVelocity:
                                maxTimeSegmentResults.tablePivot()[0][
                                    'FactSprintTimeSegments.averageVelocityMph'
                                ],
                            totalDistance:
                                maxTimeSegmentResults.tablePivot()[0][
                                    'FactSprintTimeSegments.distanceFt'
                                ],
                        },
                    });
                } catch (e) {
                    console.log(e);
                } finally {
                    setIsLoadingKpis(false); // Stop loading regardless of success or failure
                }
            }
        };
        fetchKpis();
    }, [individual]);

    return (
        <div>
            <Dialog
                open={Boolean(drilldownResults) || Boolean(maxSpeedDrilldownResults)}
                onClose={() => {
                    setDrilldownResults(null);
                    setDrilldownQuery(null);
                    setMaxSpeedDrilldownResults(null);
                    setMaxSpeedDrilldownQuery(null);
                }}
            >
                <Fade in={Boolean(drilldownResults) || Boolean(maxSpeedDrilldownResults)}>
                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableCell>Date</TableCell>
                                <TableCell>High Speed</TableCell>
                                <TableCell></TableCell>
                            </TableHead>
                            <TableBody>
                                {maxSpeedDrilldownResults &&
                                    maxSpeedDrilldownResults.map((result: any) => (
                                        <TableRow key={result['DimSprint.uuid']}>
                                            <TableCell>
                                                {result['DimDate.fullDate.day'].slice(0, 10)}
                                            </TableCell>
                                            <TableCell>
                                                {result['FactSprintsRanked.vMaxMph'].toFixed(2)}
                                                mph
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        openInNewTab(
                                                            `/community/individuals/${result['DimIndividual.uuid']}/performance/${result['DimUpload.uuid']}/sprints/${result['DimSprint.uuid']}`,
                                                        )
                                                    }
                                                    size="small"
                                                >
                                                    <OpenInNew fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {drilldownResults &&
                                    drilldownResults.map((result: any) => (
                                        <TableRow key={result['DimSprint.uuid']}>
                                            <TableCell>
                                                {result['DimDate.fullDate.day'].slice(0, 10)}
                                            </TableCell>
                                            <TableCell>
                                                {result['FactSprintsRanked.vMaxMph'].toFixed(2)}
                                                mph
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        openInNewTab(
                                                            `/community/individuals/${result['DimIndividual.uuid']}/performance/${result['DimUpload.uuid']}/sprints/${result['DimSprint.uuid']}`,
                                                        )
                                                    }
                                                    size="small"
                                                >
                                                    <OpenInNew fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                </Fade>
            </Dialog>
            {individual && (
                <IndividualKpisCard
                    individual={individual}
                    fullname={fullname}
                    onMaxVelocityClick={(range: DateRangeValue) => {
                        maxVDrilldown.timeDimensions = [
                            {
                                dimension: 'DimDate.fullDate',
                                granularity: 'day',
                                dateRange: range,
                            },
                        ];
                        maxVDrilldown.limit = 5;
                        maxVDrilldown.order = {
                            'FactSprintsRanked.vMaxMph': 'desc',
                        };

                        setMaxSpeedDrilldownQuery(maxVDrilldown);
                    }}
                    onDrilldownClick={(range: DateRangeValue) => {
                        highSpeedSprintsDrilldown.timeDimensions = [
                            {
                                dimension: 'DimDate.fullDate',
                                granularity: 'day',
                                dateRange: range,
                            },
                        ];

                        setDrilldownQuery(highSpeedSprintsDrilldown);
                    }}
                    kpis={kpis}
                    isLoadingKpis={isLoadingKpis}
                />
            )}
        </div>
    );
};

export default IndividualDetailsKpis;
