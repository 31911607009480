import { Avatar, Box, Button, Link, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { CustomButton } from 'components/Buttons';
import { TypographyTitleh6 } from 'components/Typography';
import ShowMoreText from 'react-show-more-text';
import { UserProfile, useToggle } from 'utils';
import React, { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import FollowerFollowingDialog from './FollowerFollowingDialog';
import { useHistory } from 'react-router-dom';

interface SocialProfileCardMobileProps {
    user: UserProfile;
    bio: string;
    isDescriptionEditable?: Boolean;
    canFollow?: Boolean;
    isFollowing?: boolean;
    onMyProfileClicked?: () => void;
    onFollowClicked?: () => void;
    onUnfollowClicked?: () => void;
    onSave?: (description: string) => void;
    isMyProfile?: boolean;
}

const SocialProfileCardMobile = ({
    user,
    bio,
    isDescriptionEditable = false,
    canFollow = false,
    isFollowing = false,
    onMyProfileClicked = () => {},
    onFollowClicked = () => {},
    onUnfollowClicked = () => {},
    onSave = () => {},
    isMyProfile = false,
}: SocialProfileCardMobileProps) => {
    const [isEditorOpened, setIsEditorOpened] = useState(false);
    const [description, setDescription] = useState(bio ?? '');
    const [descriptionLength, setDescriptionLength] = useState(bio.length);
    const [initialTabState, setInitialTabState] = useState(0);
    const { push } = useHistory();
    const theme = useTheme();
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDescription(e.target.value);
        setDescriptionLength(e.target.value.length);
    };
    const [isFollowingListOpen, toggleIsFollowingListOpen] = useToggle(false);

    useEffect(() => {
        setDescription(bio);
    }, [bio]);
    return (
        <Box sx={{ color: '#000000', width: '100%' }}>
            <Box display="flex" justifyContent="flex-start" marginBottom={16}>
                {canFollow && !isFollowing && (
                    <Button onClick={onFollowClicked} variant="outlined" color="primary">
                        {isMyProfile && <>Hiding My Posts On My Feed</>}
                        {!isMyProfile && <>Follow</>}
                    </Button>
                )}
                {canFollow && isFollowing && (
                    <Button
                        onClick={onUnfollowClicked}
                        variant="contained"
                        color="primary"
                        endIcon={<CheckIcon />}
                    >
                        {isMyProfile && <>Showing My Posts on My Feed</>}
                        {!isMyProfile && <>Following</>}
                    </Button>
                )}
            </Box>
            <Box display="flex">
                <Avatar
                    variant="square"
                    sx={{ width: '35%', height: '100%' }}
                    src={user.profile_photo['350'] ?? ''}
                />

                <Box sx={{ padding: theme.spacing(0, 8, 0, 8) }} flexGrow={1}>
                    {isFollowingListOpen && (
                        <FollowerFollowingDialog
                            open={isFollowingListOpen}
                            onClose={() => toggleIsFollowingListOpen()}
                            initialTab={initialTabState}
                        ></FollowerFollowingDialog>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            borderBottom: '1px solid #003E9D',
                            paddingBottom: theme.spacing(2),
                        }}
                    >
                        {isMyProfile && (
                            <Button
                                onClick={() => {
                                    setInitialTabState(0);
                                    toggleIsFollowingListOpen();
                                }}
                                sx={{
                                    padding: theme.spacing(1, 3, 0, 3),
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: theme.palette.backgroundColor.freeSpeechBlue,
                                    textTransform: 'uppercase',
                                    width: '50%',
                                    textAlign: 'center',
                                }}
                            >{`${user.followers_count} followers`}</Button>
                        )}
                        {!isMyProfile && (
                            <span
                                style={{
                                    padding: theme.spacing(1, 3, 0, 3),
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: theme.palette.backgroundColor.freeSpeechBlue,
                                    textTransform: 'uppercase',
                                    width: '50%',
                                    textAlign: 'center',
                                }}
                            >{`${user.followers_count} followers`}</span>
                        )}
                        {!isMyProfile && (
                            <span
                                style={{
                                    padding: theme.spacing(1, 3, 0, 3),
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: theme.palette.backgroundColor.freeSpeechBlue,
                                    textTransform: 'uppercase',
                                    width: '50%',
                                    textAlign: 'center',
                                }}
                            >{`${user.following_count} following`}</span>
                        )}
                        {isMyProfile && (
                            <Button
                                onClick={() => {
                                    setInitialTabState(1);
                                    toggleIsFollowingListOpen();
                                }}
                                sx={{
                                    padding: theme.spacing(1, 3, 0, 3),
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                    color: theme.palette.backgroundColor.freeSpeechBlue,
                                    textTransform: 'uppercase',
                                    width: '50%',
                                    textAlign: 'center',
                                }}
                            >{`${user.following_count} following`}</Button>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <TypographyTitleh6 sx={{ color: '#000000' }} style={{ marginTop: '8px' }}>
                            {user.name}
                        </TypographyTitleh6>
                    </Box>

                    {!isEditorOpened ? (
                        <Box sx={{ color: '#000000', width: '100%' }}>
                            <ShowMoreText more="view more" less="view less" lines={2}>
                                {description}
                            </ShowMoreText>
                            <Typography variant="subtitle2">
                                <Link href="#" onClick={() => push(`/@${user.slug}`)}>
                                    {`${window.location.host}/@${user.slug}`}
                                </Link>
                            </Typography>
                        </Box>
                    ) : (
                        <TextField
                            id="standard-textarea"
                            label="Write Your Bio Here"
                            placeholder="Write Your Bio Here"
                            multiline
                            variant="standard"
                            color="primary"
                            helperText={`${descriptionLength}/150`}
                            value={description}
                            style={{ width: '100%' }}
                            inputProps={{
                                maxLength: 200,
                            }}
                            FormHelperTextProps={{
                                style: {
                                    textAlign: 'right',
                                },
                            }}
                            onChange={handleChange}
                        />
                    )}
                    <Box
                        display="flex"
                        style={{ flexDirection: 'row-reverse', width: '100%', marginTop: '16px' }}
                    >
                        {!canFollow && (
                            <CustomButton
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={
                                    isDescriptionEditable
                                        ? () => {
                                              if (isEditorOpened) {
                                                  onSave(description);
                                              }
                                              setIsEditorOpened(!isEditorOpened);
                                          }
                                        : onMyProfileClicked
                                }
                            >
                                {!isDescriptionEditable
                                    ? 'MY PROFILE'
                                    : isEditorOpened
                                    ? 'Save Changes'
                                    : 'Edit'}
                            </CustomButton>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default SocialProfileCardMobile;
