import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Box, InputLabel, useTheme } from '@mui/material';

import { ContentState, convertFromRaw, convertToRaw, EditorState, Modifier } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import React, { ReactElement, useState } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { EMOJI_LIST } from '../../utils';

export interface TextEditorInputProps {
    label: string;
    placeholder?: string;
    defaultValue?: string | null;
    editorOverrides?: EditorProps;
    onChange: (newValue: string) => void;
}

const customDraftToMarkdown = (raw: any) => {
    return draftToMarkdown(raw, {
        styleItems: {
            UNDERLINE: {
                open: () => '__',
                close: () => '__',
            },
        },
    });
};

const customMarkdownToDraft = (markdown: string) => {
    // Pre-process the markdown to convert underline syntax
    const processedMarkdown = markdown.replace(/__([^_]+)__/g, '<u>$1</u>');

    const rawDraft = markdownToDraft(processedMarkdown);

    // Post-process the raw draft to convert <u> tags to UNDERLINE style
    rawDraft.blocks = rawDraft.blocks.map((block) => {
        let text = block.text;
        const inlineStyleRanges = [...(block.inlineStyleRanges || [])];

        let offset = 0;
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const startIndex = text.indexOf('<u>', offset);
            if (startIndex === -1) break;

            const endIndex = text.indexOf('</u>', startIndex);
            if (endIndex === -1) break;

            // Add UNDERLINE style
            inlineStyleRanges.push({
                offset: startIndex - offset,
                length: endIndex - startIndex - 3,
                style: 'UNDERLINE',
            });

            // Adjust offsets of existing styles
            inlineStyleRanges.forEach((range) => {
                if (range.offset > startIndex) {
                    range.offset -= 3;
                }
                if (range.offset + range.length > endIndex) {
                    range.length -= 4;
                }
            });

            text =
                text.slice(0, startIndex) +
                text.slice(startIndex + 3, endIndex) +
                text.slice(endIndex + 4);
            offset = startIndex;
        }

        return {
            ...block,
            text,
            inlineStyleRanges,
        };
    });

    return rawDraft;
};

const TextEditorInput = ({
    label,
    defaultValue = '',
    placeholder = '',
    editorOverrides = {},
    onChange,
}: TextEditorInputProps): ReactElement => {
    // The default value is a string containing parse HTML by the draft js library
    // We need to convert it into a format containing content `blocks` which is recognized by draft js
    const rawDraft = customMarkdownToDraft((defaultValue || '') as string);
    const contentState = convertFromRaw(rawDraft);
    const editorState = EditorState.createWithContent(contentState);
    const theme = useTheme();

    const [content, setContent] = useState<EditorState>(editorState);

    const onEditorStateChange = (newContent: EditorState) => {
        // We need to only emit the onchange event to the parent when the actual content(markdown) changes
        // However, draft-js keeps track of the selection state & mouse inside the editor state,
        // so onChange is triggered even the user clicks away with mouse,
        // We check for the old and new editor state's `content` to know for sure if some characters are added or deleted
        if (newContent.getCurrentContent() !== content.getCurrentContent()) {
            let raw = convertToRaw(newContent.getCurrentContent());
            console.log('raw', raw);
            let markdownString = customDraftToMarkdown(raw);
            console.log('markdownString', markdownString);
            onChange(markdownString);
        }

        // But we must update the `editor state` in the component's state as it is a controlled input, to keep it in sync with user actions
        setContent(newContent);
    };

    // Draft js has a known issue that, when you paste a content from another html/website and it has figure HTML tag, it throws error
    // `figure` is reserved inside draft JS for internal processing
    const handlePastedText = (
        text: string,
        _: string,
        currentEditorState: EditorState,
    ): boolean => {
        const pastedBlocks = ContentState.createFromText(text);

        const newState = Modifier.replaceWithFragment(
            currentEditorState.getCurrentContent(),
            currentEditorState.getSelection(),
            pastedBlocks.getBlockMap(),
        );

        const newEditorState = EditorState.push(currentEditorState, newState, 'insert-fragment');
        onEditorStateChange(newEditorState);

        // We need to return true to tell draft js that we have handled the paste from our side already
        return true;
    };

    return (
        <>
            <InputLabel
                shrink={true}
                color="secondary"
                sx={{
                    textAlign: 'left',
                }}
            >
                {label}
            </InputLabel>

            <Box
                sx={{
                    '& .rdw-option-wrapper': {
                        background: theme.palette.backgroundColor.hover,
                    },
                    '& .rdw-dropdown-selectedtext': {
                        background: theme.palette.backgroundColor.hover,
                    },
                    '& .rdw-dropdownoption-default': {
                        background: theme.palette.backgroundColor.hover,
                        padding: theme.spacing(3),
                    },
                    '& .rdw-option-wrapper:hover': {
                        background: '#dee2e4',
                        boxShadow: 'none',
                    },
                    '& .rdw-dropdown-wrapper:hover': {
                        background: '#dee2e4',
                        boxShadow: 'none',
                    },
                    '& .rdw-dropdown-selectedtext:hover': {
                        background: '#dee2e4',
                        boxShadow: 'none',
                    },
                    '& .rdw-editor-toolbar': {
                        border: 0,
                        background: 'transparent',
                    },
                    '& .rdw-option-active': {
                        border: `1px solid rgba(0, 0, 0, 0.42)`,
                        boxShadow: 'none',
                    },
                    '& .rdw-link-modal': {
                        background: theme.palette.backgroundColor.hover,
                    },
                    '& .DraftEditor-editorContainer': {
                        zIndex: 0,
                    },
                    '& .rdw-emoji-modal': {
                        height: 'auto',
                    },
                    '& .rdw-link-modal-target-option': {
                        display: 'none',
                    },
                    '& .rdw-editor-main': {
                        backgroundColor: theme.palette.backgroundColor.main,
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
                        padding: theme.spacing(5, 6),
                    },
                }}
            >
                <Editor
                    stripPastedStyles={true}
                    placeholder={placeholder}
                    editorState={content}
                    onEditorStateChange={onEditorStateChange}
                    handlePastedText={handlePastedText}
                    spellCheck={true}
                    toolbar={{
                        options: ['emoji', 'link', 'inline', 'blockType', 'fontSize', 'list'],
                        inline: {
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                        },
                        list: {
                            options: ['unordered', 'ordered'],
                        },
                        link: {
                            options: ['link'],
                            defaultTargetOption: '_blank',
                        },
                        blockType: {
                            options: ['Normal', 'H1', 'Blockquote'],
                        },
                        fontSize: {
                            options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
                        },
                        emoji: {
                            emojis: EMOJI_LIST,
                        },
                    }}
                    {...editorOverrides}
                />
            </Box>
        </>
    );
};

export default TextEditorInput;
