import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    getUserPods,
    getUserSubscriptions,
    userIsLoadedSelector,
    userPodsIsLoadedSelector,
    userPodsSelector,
    userSubscriptionsIsLoadedSelector,
    userSubscriptionsSelector,
} from 'redux/reducers/user';
import { AthletePod, AthleteSubscription } from '../app.types';
import { useAppDispatch } from '../redux/store';
import { Button, Snackbar } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory, useLocation } from 'react-router-dom';
import athleteUpdatePaymentMethod from '../api/Auth/athleteUpdatePaymentMethod';
import { getFromLocalStorageWithExpiry, setInLocalStorageWithExpiry } from '../utils/localStorage';

interface Props {
    subscriptions: AthleteSubscription[];
    subscriptionsLoaded: boolean;
    userPods: AthletePod[];
    userPodsIsLoaded: boolean;
    isModalOpen: boolean;
    closeModal: () => void;
}

export const AthleteContext = React.createContext<Props>({
    subscriptions: [],
    subscriptionsLoaded: false,
    userPods: [],
    userPodsIsLoaded: false,
    isModalOpen: false,
    closeModal: () => {},
});

export function AthleteSubscriptionsProvider({ children }: { children: React.ReactNode }) {
    const subscriptions = useSelector(userSubscriptionsSelector);
    const subscriptionsLoaded = useSelector(userSubscriptionsIsLoadedSelector);
    const userLoaded = useSelector(userIsLoadedSelector);
    const userSubscriptionsIsLoaded = useSelector(userSubscriptionsIsLoadedSelector);
    const userPods = useSelector(userPodsSelector);
    const userPodsIsLoaded = useSelector(userPodsIsLoadedSelector);
    const dispatch = useAppDispatch();

    const [isTransferRequestModalOpen, setIsTransferRequestModalOpen] = useState(false);
    const [isTransferRequestModalClosedInSession, setIsTransferRequestModalClosedInSession] =
        useState(false);
    const [isPastDueAthleteSubscriptionModalOpen, setIsPastDueAthleteSubscriptionModalOpen] =
        useState(false);
    const [
        isPastDueAthleteSubscriptionModalClosedInSession,
        setIsPastDueAthleteSubscriptionModalClosedInSession,
    ] = useState(false);
    const [hasDefaultSubscription, setHasDefaultSubscription] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('1Tul Subscription Required');
    const [dialogContent, setDialogContent] = useState(
        'You will need to sign up for a 1Tul subscription. Please see your marketplaces and choose one from which to purchase a subscription.',
    );
    const location = useLocation();
    const [snackMessage, setSnackMessage] = useState<string>('');

    const closeTransferRequestModal = () => {
        setIsTransferRequestModalOpen(false);
        setIsTransferRequestModalClosedInSession(true);
    };

    const handleChangeBillingMethod = () => {
        setInLocalStorageWithExpiry('athleteUpdatePaymentMethodAcknowledged', true, 24);
        athleteUpdatePaymentMethod(window.location.href)
            .then((response) => {
                closePastDueAthleteSubscriptionModal();
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                setSnackMessage(`Error creating checkout session. ${e.response?.data?.message}`);
            });
    };

    const closePastDueAthleteSubscriptionModal = () => {
        setInLocalStorageWithExpiry('athleteUpdatePaymentMethodAcknowledged', true, 24);
        setIsPastDueAthleteSubscriptionModalOpen(false);
        setIsPastDueAthleteSubscriptionModalClosedInSession(true);
    };

    const history = useHistory();

    const ModalComponent = () => (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!snackMessage}
                autoHideDuration={6000}
                message={snackMessage}
                onClose={() => setSnackMessage('')}
            />
            <Dialog
                open={isTransferRequestModalOpen}
                onClose={() => setIsTransferRequestModalOpen(false)}
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogContent}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            history.push('/athletes');
                            setIsTransferRequestModalOpen(false);
                        }}
                    >
                        View My Marketplaces
                    </Button>
                    <Button variant="outlined" onClick={() => setIsTransferRequestModalOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isPastDueAthleteSubscriptionModalOpen}
                onClose={closePastDueAthleteSubscriptionModal}
            >
                <DialogTitle>Athlete Subscription Payment Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        One or more of your athlete subscriptions are past due. Please update your
                        payment information on Stripe as soon as possible to avoid any service
                        interruptions.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleChangeBillingMethod}>
                        Update Payment Information
                    </Button>
                    <Button variant="outlined" onClick={closePastDueAthleteSubscriptionModal}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

    useEffect(() => {
        if (userLoaded && !userSubscriptionsIsLoaded) {
            dispatch(getUserSubscriptions()).then((subscriptions: any) => {
                if (
                    subscriptions.payload.some((subscription: any) => {
                        return (
                            subscription.name === 'default' || subscription.name === 'athlete_combo'
                        );
                    })
                ) {
                    setHasDefaultSubscription(true);
                } else {
                    setHasDefaultSubscription(false);

                    if (!location.pathname.includes('/athletes')) {
                        // setIsTransferRequestModal(true); @todo this is commented because this modal annoys users.
                    }
                }
            });
        }

        /**
         * Pod transfer modal notification logic.
         */
        if (userLoaded && hasDefaultSubscription && !userPodsIsLoaded) {
            dispatch(getUserPods()).then((pods: any) => {
                if (pods.payload?.length > 0) {
                    /**
                     * To display a pod transfer pending modal notification, there must be at least one pod
                     * that has a null transfer_request_accepted_at and there must be no pods
                     * where transfer_request_accepted_at is not null.
                     */
                    const hasPendingPodTransferRequest =
                        pods.payload.some(
                            (pod: any) => pod.transfer_request_accepted_at === null,
                        ) &&
                        !pods.payload.some((pod: any) => pod.transfer_request_accepted_at !== null);
                    if (
                        hasPendingPodTransferRequest &&
                        !isTransferRequestModalClosedInSession &&
                        !location.pathname.includes('/athletes')
                    ) {
                        console.debug('Pod transfer request(s) found');
                        setIsTransferRequestModalOpen(true);
                        setDialogTitle('Pod Transfer Request');
                        setDialogContent(
                            'You have a FiyrPod transfer request. Please see your marketplaces and choose one from which to purchase a subscription.',
                        );
                    }
                }
            });
        }

        /**
         * Athlete subscription payment error modal notification logic.
         */
        if (userLoaded && userSubscriptionsIsLoaded) {
            const hasPastDueSubscription = subscriptions.some(
                (subscription) => subscription.stripe_status === 'past_due',
            );
            if (
                hasPastDueSubscription &&
                !isPastDueAthleteSubscriptionModalClosedInSession &&
                getFromLocalStorageWithExpiry('athleteUpdatePaymentMethodAcknowledged') !== true
            ) {
                setIsPastDueAthleteSubscriptionModalOpen(true);
            }
        }
    }, [
        location.pathname,
        userLoaded,
        userSubscriptionsIsLoaded,
        dispatch,
        userPodsIsLoaded,
        userPods,
        isTransferRequestModalClosedInSession,
        subscriptions,
        isPastDueAthleteSubscriptionModalClosedInSession,
        hasDefaultSubscription,
    ]);

    return (
        <AthleteContext.Provider
            value={{
                subscriptions,
                subscriptionsLoaded,
                userPods,
                userPodsIsLoaded,
                isModalOpen: isTransferRequestModalOpen,
                closeModal: closeTransferRequestModal,
            }}
        >
            {children}
            <ModalComponent />
        </AthleteContext.Provider>
    );
}
