import React from 'react';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Typography } from '@mui/material';
import LandingPageNavBar from './LandingPageNavBar';
import FullScreenDialogCard from '../../modules/Learn1TUL/components/HowToDialogCard';
import CompareSprint from '../../images/CompareSprints.png';
import TimeSegmentAnalysis from '../../images/TimeSegmentAnalysis.png';
import AIVideoAnalysis from '../../images/AIVideoAnalysis.png';
import TrackingPlayerLoad from '../../images/TrackingPlayerLoad.png';
import GPSSessionSummary from '../../images/GPSSessionSummary.png';
import DataTableView from '../../images/DataTableView.png';
import LearningContent from '../../images/LearningContent.png';
import ShareReports from '../../images/ShareReports.png';
import ShareSessions from '../../images/ShareSessions.png';
import MovementLibrary from '../../images/MovementLibrary.png';
import SessionsList from '../../images/SessionsList.png';
import MediaLibrary from '../../images/MediaLibrary.png';
import ListOfTeams from '../../images/ListOfGroups.png';
import TeamDetails from '../../images/TeamDetailsView.png';
import TeamAccess from '../../images/GroupAccessPermissions.png';
import MakeReportStep1 from '../../modules/Learn1TUL/media/MakeReportStep1.png';
import MakeReportStep2 from '../../modules/Learn1TUL/media/MakeReportStep2.png';
import MakeReportStep3 from '../../modules/Learn1TUL/media/MakeReportStep3.png';
import MakeDashboardStep1 from '../../modules/Learn1TUL/media/MakeDashboardStep1.png';
import MakeDashboardStep2 from '../../modules/Learn1TUL/media/MakeDashboardStep2.png';
import MakeDashboardStep3 from '../../modules/Learn1TUL/media/MakeDashboardStep3.png';

interface LandingAuthScreenLayoutProps {
    children: React.ReactNode;
    containerClassName?: string;
}

function LandingAuthScreenLayout({ children }: LandingAuthScreenLayoutProps) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const gradientTextStyles = {
        mt: 4,
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 8,
        maxWidth: 900,
        background: 'linear-gradient(to bottom, #fffffF, #A7ABB7)', // Example gradient
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent', // This makes the text transparent, showing only the background
        backgroundClip: 'text',
        color: 'transparent', // Fallback for browsers that do not support background-clip
        marginTop: 15,
    };

    return (
        <>
            <LandingPageNavBar />
            <Box
                sx={{ flex: 1, backgroundImage: 'linear-gradient(to bottom, #0066ff, #070A19)' }}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start" // This will distribute space evenly
                alignItems="center"
                height="100%"
            >
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    Speed, Training, and Learning data management collaboration platform for
                    organizations that scale.
                </Typography>
                <Grow in={true}>
                    <Paper
                        elevation={matches ? 3 : 0}
                        sx={(theme) => ({
                            width: '100%',
                            maxWidth: '90%',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            padding: theme.spacing(24, 16),
                            [theme.breakpoints.down('md')]: {
                                minHeight: '50%',
                                width: 'calc(100% - 44px)',
                                flexDirection: 'column',
                            },
                        })}
                    >
                        {children}
                    </Paper>
                </Grow>
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    Managing and processing speed data is our specialty.
                </Typography>
                <Grid container spacing={9} lg={11}>
                    <FullScreenDialogCard
                        image={CompareSprint}
                        title="Compare Sprint"
                        headline="Sprint Comparison"
                        description="Compare sprint performance between athletes, sessions, and types of
                                training."
                    />
                    <FullScreenDialogCard
                        image={TimeSegmentAnalysis}
                        title="Time Segment Analysis"
                        headline="Time Segment Analysis"
                        description="GPS derived one second segments provide the best evidence to analyse
                                all phases of a sprint."
                    />
                    <FullScreenDialogCard
                        image={AIVideoAnalysis}
                        title="AI Video Analysis"
                        headline="AI Video Analysis"
                        description="Capture stride, flexion, and extension asymmetries. Also includes 10
                                yard split times with average speed."
                    />
                </Grid>
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    1TUL to process, organise, analyse, and share training data to help athletes,
                    training professionals, and organizations prove performance improvement.
                </Typography>
                <Grid container spacing={9} lg={11}>
                    <FullScreenDialogCard
                        image={TrackingPlayerLoad}
                        title="Tracking Player Load"
                        headline="Tracking Team Player Load"
                        description="Track team player load and compare it to individual player load.
                                Compare practice and game loads to better monitor and plan
                                practices."
                    />
                    <FullScreenDialogCard
                        image={GPSSessionSummary}
                        title="GPS Session Summary"
                        headline="GPS Session Summaries"
                        description="Track GPS session summaries and compare them to other sessions. Sort
                                metric data and compare athletes against each other and past
                                performances."
                    />
                    <FullScreenDialogCard
                        image={DataTableView}
                        title="Data Table View"
                        headline="Data Table View"
                        description="Quickly navigate all your data in data table view. Sort and filter
                                to find the data you need. Quickly drill down to the sprint or
                                training session."
                    />
                </Grid>
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    We support learning and collaboration by providing you tools to create
                    educational material and share them with groups and individuals.
                </Typography>
                <Grid container spacing={9} justifyContent="space-around" lg={11}>
                    <FullScreenDialogCard
                        image={LearningContent}
                        title="Learning Content"
                        headline="Learning Management"
                        description="Create and share learning content with athletes and coaches. Track
                                who has completed the content and their knowledge retention."
                    />
                    <FullScreenDialogCard
                        image={ShareReports}
                        title="Share Reports"
                        headline="Sharing Reports"
                        description="Share reports via a link and password or QR. Assign permissions
                                through the link to limit access and editing rights."
                    />
                    <FullScreenDialogCard
                        image={ShareSessions}
                        title="Share Sessions"
                        headline="Sharing Sessions"
                        description="Share Sessions inside and outside your organization via a link and
                                password or QR. Assign permissions through the link to limit access
                                and editing rights."
                    />
                </Grid>
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    We help you keep all your training materials in one spot.
                </Typography>
                <Grid container spacing={9} justifyContent="space-around" lg={11}>
                    <FullScreenDialogCard
                        image={MovementLibrary}
                        title="Movement Library"
                        headline="Movement Library"
                        description="Develop your own movement library and share it with your athletes.
                                Select which metrics you want to track and how you want to track
                                them."
                    />
                    <FullScreenDialogCard
                        image={SessionsList}
                        title="Training Sessions and Drills"
                        headline="Training Sessions and Drills"
                        description="Combine your movement library into training sessions and drills.
                                Assign sessions and drills to your athletes or share them with
                                other coaches."
                    />
                    <FullScreenDialogCard
                        image={MediaLibrary}
                        title="Media Library"
                        headline="Media Library"
                        description="Build your media library and quickly reuse media accross the entire
                                platform. Reuse in learning content, sessions, and drills."
                    />
                </Grid>
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    We help you manage large groups.
                </Typography>
                <Grid container spacing={9} justifyContent="space-around" lg={11}>
                    <FullScreenDialogCard
                        image={ListOfTeams}
                        title="Team View"
                        headline="View All Your Teams in One Place"
                        description="View all your teams in one place."
                    />
                    <FullScreenDialogCard
                        image={TeamDetails}
                        title="Team Dashboard"
                        headline="Team Dashboard"
                        description="Quickly view members, assign sessions and learning content, or import a large number of athletes from a csv file."
                    />
                    <FullScreenDialogCard
                        image={TeamAccess}
                        title="Team Permissions"
                        headline="Team Permissions"
                        description="Invite other coaches, trainers, or athletes to the team and set their access permissions."
                    />
                </Grid>
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    We help you unlock the power of your training data with reports and dashboards.
                </Typography>
                <Grid
                    container
                    spacing={9}
                    justifyContent="space-around"
                    lg={11}
                    sx={{ marginBottom: 25 }}
                >
                    <FullScreenDialogCard
                        lgSize={6}
                        image={MakeReportStep1}
                        title="Create Report: Select Organization and Data Source"
                        headline="Create Report: Select Organization and Data Source"
                        description="You must select an organization
                            then its data source (Training Logs, Sprint Profiling - Sprints, Sprint
                            Profiling - Time Segments, 1080 Motion, or GPS Group Summary)."
                    />
                    <FullScreenDialogCard
                        lgSize={6}
                        image={MakeReportStep2}
                        title="Create Report: Select Filters, Report Type, and Chart Type"
                        headline="Create Report: Select Filters, Report Type, and Chart Type"
                        description="Next select the appropriate filters and chart type. These options vary depending upon the data source."
                    />
                    <FullScreenDialogCard
                        lgSize={6}
                        image={MakeReportStep3}
                        title="Create Report: Name and Save Report"
                        headline="Create Report: Name and Save Report"
                        description="Finally, you must name and save your report."
                    />
                    <FullScreenDialogCard
                        lgSize={6}
                        image={MakeDashboardStep1}
                        title="Create Dashboard: Click New Dashboard"
                        headline="Create Dashboard: Click New Dashboard"
                        description="Go to Community -> Dashboards and Click New Dashboard."
                    />
                    <FullScreenDialogCard
                        lgSize={6}
                        image={MakeDashboardStep2}
                        title="Create Dashboard: Select Title, Description, and Organization."
                        headline="Create Dashboard: Select Title, Description, and Organization."
                        description="Next select the appropriate Title, Description, and Organization."
                    />
                    <FullScreenDialogCard
                        lgSize={6}
                        image={MakeDashboardStep3}
                        title="Create Dashboard: Add Reports"
                        headline="Create Dashboard: Add Reports"
                        description="Finally, add as many reports to the dashboard as you like."
                    />
                </Grid>
                <Button
                    sx={gradientTextStyles}
                    variant="contained"
                    size={'large'}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                    Back to the top
                </Button>
            </Box>
        </>
    );
}

export default LandingAuthScreenLayout;
