import { Refresh } from '@mui/icons-material';
import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { unionBy } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { viewIndividual } from '../../../api';
import { getTags } from '../../../api/tags.api';
import { Tag } from '../../../app.types';
import { NextPrevPagination } from '../../../components';
import EmptyStateWithDashedBorder from '../../../components/Feedback/EmptyStates/EmptyStateWithDashedBorder';
import { authSelector } from '../../../redux/reducers/auth';
import { Individual, Meta, PaginationLink, useToggle } from '../../../utils';
import { getExternalIds, importTemData } from '../api/individuals.api';
import { getUploads } from '../api/performance.api';
import { ExternalId, PerformanceDataUpload } from '../community.types';
import MultiChipList from '../components/MultiChipList';
import { PerformanceDataUploadDrawer } from './index';

interface UploadsFilter {
    'filter[tag]': Array<string>;
    sort: string;
    page: number;
}

function IndividualPerformanceMetrics() {
    const { individualId } = useParams<{ individualId: string }>();
    const [individual, setIndividual] = useState<Individual | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const { currentUser } = useSelector(authSelector);
    const [uploads, setUploads] = useState<PerformanceDataUpload[]>([]);
    const [showProcessingMessage, setShowProcessingMessage] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationLink>();
    const [externalIds, setExternalIds] = useState<ExternalId[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [uploading, toggleUploading] = useToggle();
    const [filters, setFilters] = useState<UploadsFilter>({
        'filter[tag]': [],
        sort: '-activity_date',
        page: 1,
    });
    const [meta, setMeta] = useState<Meta>();
    const match = useRouteMatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [dateRange, setDateRange] = useState<[DateTime | null, DateTime | null]>([
        DateTime.now()
            .minus({
                week: 1,
            })
            .startOf('day'),
        DateTime.now()
            .minus({
                days: 1,
            })
            .endOf('day'),
    ]);

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleSubmit = () => {
        if (!dateRange[0] || !dateRange[1]) {
            return;
        }
        importTemData(individualId, {
            start: dateRange[0].toFormat('yyyy-MM-dd HH:mm:ss'),
            end: dateRange[1].toFormat('yyyy-MM-dd HH:mm:ss'),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
            .then(() => {
                setShowProcessingMessage(true);
                setTimeout(() => {
                    loadUploads();
                    setShowProcessingMessage(false);
                }, 3000);
                handleModalClose();
            })
            .catch(() => {
                handleModalClose();
            });
    };

    const { push } = useHistory();
    useEffect(() => {
        viewIndividual(individualId)
            .then((individualResponse) => {
                setIndividual(individualResponse.data);
            })
            .catch(() => {
                push('/not-found');
            });

        getExternalIds(individualId)
            .then((response) => {
                setExternalIds(response.data);
            })
            .catch(() => {
                setExternalIds([]);
            });
    }, [individualId, push]);

    useEffect(() => {
        loadUploads();
        getTags({ 'filter[type]': 'uploads' })
            .then((response) => {
                setTags(response.data);
            })
            .catch(() => {});
    }, [individualId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadUploads();
    }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadUploads = useCallback(() => {
        setLoading(true);
        getUploads(individualId, { ...filters })
            .then((response) => {
                setPagination(response.data.links);
                setMeta(response.data.meta);
                setUploads(response.data.data);
            })
            .catch(() => {})
            .finally(() => setLoading(false));
    }, [individualId, filters]);

    return (
        <Container sx={{ marginTop: 8, marginBottom: 40 }}>
            <Box sx={{ padding: 8, display: 'flex', alignItems: 'center' }}>
                <Avatar
                    src={individual?.organization.image_urls.avatar ?? ''}
                    variant="rounded"
                    sx={{ width: 80, height: 80 }}
                />
                <Typography variant={'h4'} sx={{ marginLeft: 8, marginBottom: 8 }}>
                    Sessions List
                </Typography>
            </Box>

            {individual && (
                <React.Fragment>
                    <Paper>
                        <Box
                            paddingX={4}
                            paddingY={8}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ borderBottom: 1, borderBottomColor: grey[300] }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    sx={{ width: 56, height: 56, margin: 8 }}
                                    src={individual.image_urls.avatar ?? ''}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant={'h6'}>{individual.name}</Typography>
                                    <Typography sx={{ color: grey[600] }} variant={'body1'}>
                                        {individual.organization.name}
                                    </Typography>
                                </Box>
                            </Box>
                            <div>
                                <Tooltip title="Refresh List">
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                        onClick={() => loadUploads()}
                                        size="large"
                                    >
                                        <Refresh />
                                    </IconButton>
                                </Tooltip>
                                {externalIds.length > 0 &&
                                    externalIds.filter((i) => i.id_type === 'tem').length > 0 && (
                                        <Button
                                            sx={{ marginRight: 6 }}
                                            variant="outlined"
                                            onClick={handleModalOpen}
                                        >
                                            Run 1080 Sync
                                        </Button>
                                    )}

                                <Dialog open={modalOpen} onClose={handleModalClose}>
                                    <DialogTitle>Select a Date Range</DialogTitle>
                                    <DialogContent>
                                        <DateRangePicker
                                            value={dateRange}
                                            onChange={(newValue) => {
                                                setDateRange(newValue);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                    variant: 'standard',
                                                },
                                                fieldSeparator: {
                                                    children: ' to ',
                                                },
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleModalClose}>Cancel</Button>
                                        <Button onClick={handleSubmit}>Submit</Button>
                                    </DialogActions>
                                </Dialog>
                                <Button variant="contained" onClick={() => toggleUploading()}>
                                    Upload GPS Sprint Data
                                </Button>
                            </div>
                        </Box>
                        <Grid container spacing={2} sx={{ padding: 4 }}>
                            <MultiChipList
                                value={tags
                                    .filter(
                                        (t) =>
                                            filters['filter[tag]'].findIndex(
                                                (tagInFilter) => tagInFilter === t.id,
                                            ) > -1,
                                    )
                                    .map((v) => ({ label: v.name, id: v.id, isDeleteable: true }))}
                                choices={tags.map((t) => ({
                                    label: t.name,
                                    id: t.id,
                                    isDeleteable: true,
                                }))}
                                onChange={(v) =>
                                    setFilters((filters) => ({
                                        ...filters,
                                        'filter[tag]': v.map((c) => c.id),
                                    }))
                                }
                            />
                            <FormControl>
                                <InputLabel id="sort">Sort By</InputLabel>
                                <Select
                                    labelId="sort"
                                    id="sort"
                                    value={filters.sort}
                                    label="Sort"
                                    onChange={(event) => {
                                        setFilters({
                                            ...filters,
                                            sort: event.target.value,
                                            page: 1,
                                        });
                                    }}
                                    defaultValue={'activity_date'}
                                >
                                    <MenuItem value={'-activity_date'}>Session Date</MenuItem>
                                    <MenuItem value={'activity_date'}>
                                        Session Date - Oldest to Newest
                                    </MenuItem>
                                    <MenuItem value={'-created_at'}>
                                        Most Recently Uploaded
                                    </MenuItem>
                                    <MenuItem value={'created_at'}>
                                        Uploaded - Oldest to Newest
                                    </MenuItem>

                                    <MenuItem value={'file_name'}>Name - A to Z</MenuItem>
                                    <MenuItem value={'-file_name'}>Name - Z to A</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {showProcessingMessage && (
                            <Alert severity="warning">
                                <AlertTitle>Please Wait While Processing</AlertTitle>
                                We are currently processing your GPS file. You can press the refresh
                                button while you wait to check if it&apos;s ready. It usually takes
                                between 5 seconds and 1 minute.
                            </Alert>
                        )}
                        <Grid container>
                            {uploads.map((upload) => (
                                <Grid
                                    key={upload.uuid}
                                    style={{ padding: 12 }}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                >
                                    <Card>
                                        <CardActionArea
                                            style={{ height: 200 }}
                                            onClick={() => push(`${match.url}/${upload.uuid}`)}
                                        >
                                            <CardContent
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography
                                                    gutterBottom
                                                    variant="body2"
                                                    component="div"
                                                >
                                                    Session Date{' '}
                                                </Typography>
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="div"
                                                >
                                                    {moment(upload.activity_date).format(
                                                        'MMMM DD, YYYY',
                                                    )}
                                                </Typography>
                                                <Typography gutterBottom variant="body2">
                                                    File Name
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    sx={{ wordBreak: 'break-all' }}
                                                    gutterBottom
                                                >
                                                    {upload.file_name}
                                                </Typography>

                                                {upload.tem_exercise_name && (
                                                    <Typography
                                                        variant="subtitle2"
                                                        sx={{ wordBreak: 'break-all' }}
                                                        gutterBottom
                                                    >
                                                        {upload.tem_exercise_name}
                                                    </Typography>
                                                )}

                                                {upload.tag && <Chip label={upload.tag.name} />}

                                                {upload.error && (
                                                    <span
                                                        style={{
                                                            display: 'inline-flex',
                                                            justifyContent: 'center',
                                                            padding: '1px 4px',
                                                            backgroundColor: red[100],
                                                            color: red[800],
                                                            borderRadius: '0.375rem',
                                                        }}
                                                    >
                                                        Error
                                                    </span>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        {uploads.length !== 0 && (
                            <NextPrevPagination
                                onNext={() =>
                                    setFilters({
                                        ...filters,
                                        page: meta ? meta.current_page + 1 : 1,
                                    })
                                }
                                onPrev={() =>
                                    setFilters({
                                        ...filters,
                                        page: meta ? meta.current_page - 1 : 1,
                                    })
                                }
                                disablePrev={!pagination?.prev || loading}
                                disableNext={!pagination?.next || loading}
                            />
                        )}
                        {uploads.length === 0 && (
                            <EmptyStateWithDashedBorder
                                onClick={() => toggleUploading()}
                                message="Upload Sprint GPS Data"
                            />
                        )}
                    </Paper>
                    <Box sx={{ height: 80 }} />
                    {currentUser && individual && (
                        <PerformanceDataUploadDrawer
                            onClose={() => toggleUploading()}
                            open={uploading}
                            currentUser={currentUser}
                            individual={individual}
                            onUploaded={() => {
                                setShowProcessingMessage(true);
                                setTimeout(() => {
                                    loadUploads();
                                    setShowProcessingMessage(false);
                                }, 7500);
                            }}
                            tags={tags}
                            onTagCreated={(tag) => {
                                setTags((previousTags) =>
                                    unionBy(previousTags, [tag], (t) => t.id),
                                );
                            }}
                        />
                    )}
                </React.Fragment>
            )}
        </Container>
    );
}

export default IndividualPerformanceMetrics;
