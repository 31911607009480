import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Cancel, CheckCircle, CheckCircleOutline, Star } from '@mui/icons-material';
import { confirmViaDialog } from 'components/Dialogs/ConfirmationDialog';
import { BandOptions, Log, RPEOptions, SetAttributeType, TimeDisplayFormat } from 'utils';
import { SessionTimeInput } from 'components/Inputs';
import LogInput from 'components/FormControl/LogInput';
import LogSelect from 'components/FormControl/LogSelect';
import useTheme from '@mui/material/styles/useTheme';

export interface LogMovementRowProps {
    index: number;
    log: Log;
    applicableCriterias: Array<SetAttributeType>;
    onActivityLogValueChanged: (
        updatedLog: Log,
        updatedGoalCriteria: SetAttributeType,
        newValue: any,
    ) => void;
    onActivityLogRemoved: (removedLog: Log) => void;
    onActivityLogStatusChanged: (updatedLog: Log) => void;
    movementName: string;
}

const LogMovementRow = ({
    index,
    log,
    applicableCriterias,
    onActivityLogRemoved,
    onActivityLogValueChanged,
    onActivityLogStatusChanged,
    movementName,
}: LogMovementRowProps) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                '& .MuiInputBase-root': {
                    borderBottom: `1px solid transparent`,
                    '&:hover, &:focus': {
                        borderBottom: `1px solid ${theme.palette.text.secondary}`,
                    },
                },
            }}
            key={log.uuid}
        >
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={10}
                    sx={{
                        padding: theme.spacing(2, 8),
                        [theme.breakpoints.down('md')]: {
                            padding: theme.spacing(2, 4),
                        },
                    }}
                >
                    <Box sx={{ width: theme.spacing(25) }}>
                        <Typography variant="body2">{index + 1}</Typography>
                    </Box>

                    {/* Reps column */}
                    {applicableCriterias.includes(SetAttributeType.Reps) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                value={log.reps}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(log, SetAttributeType.Reps, newValue);
                                }}
                            />
                        </Box>
                    )}

                    {/* Load column */}
                    {applicableCriterias.includes(SetAttributeType.Load) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                value={log.load_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(log, SetAttributeType.Load, newValue);
                                }}
                            />
                        </Box>
                    )}

                    {/* Time column */}
                    {applicableCriterias.includes(SetAttributeType.Time) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <SessionTimeInput
                                defaultValueInMilliseconds={log.time_value || 0}
                                onTimeChanged={(durationInMilliseconds: number) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.Time,
                                        durationInMilliseconds,
                                    );
                                }}
                                format={
                                    (log.time_display_format as TimeDisplayFormat) ||
                                    TimeDisplayFormat.Common
                                }
                            />
                        </Box>
                    )}

                    {/* Distance column */}
                    {applicableCriterias.includes(SetAttributeType.Distance) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                value={log.distance_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.Distance,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Range Of Motion column */}
                    {applicableCriterias.includes(SetAttributeType.RangeOfMotion) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                value={log.range_of_motion_value}
                                max={360}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.RangeOfMotion,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Speed column */}
                    {applicableCriterias.includes(SetAttributeType.Speed) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                value={log.speed_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.Speed,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Speed column */}
                    {applicableCriterias.includes(SetAttributeType.Acceleration) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                value={log.acceleration_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.Acceleration,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Power column */}
                    {applicableCriterias.includes(SetAttributeType.Power) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                value={log.power_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.Power,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* RSI column */}
                    {applicableCriterias.includes(SetAttributeType.RSI) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                min={0}
                                value={log.rsi_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(log, SetAttributeType.RSI, newValue);
                                }}
                            />
                        </Box>
                    )}

                    {/* RPM column */}
                    {applicableCriterias.includes(SetAttributeType.RPM) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                min={0}
                                value={log.rpm_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(log, SetAttributeType.RPM, newValue);
                                }}
                            />
                        </Box>
                    )}

                    {/* Force column */}
                    {applicableCriterias.includes(SetAttributeType.Force) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                min={0}
                                value={log.force_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.Force,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Body Side column */}
                    {applicableCriterias.includes(SetAttributeType.BodySide) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogSelect
                                value={log.body_side}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.BodySide,
                                        newValue,
                                    );
                                }}
                                options={{
                                    left: 'Left',
                                    right: 'Right',
                                    both: 'Both',
                                }}
                            />
                        </Box>
                    )}

                    {/* RPE column */}
                    {applicableCriterias.includes(SetAttributeType.RPE) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogSelect
                                value={log.rpe_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.RPE,
                                        parseInt(newValue, 10),
                                    );
                                }}
                                options={RPEOptions}
                            />
                        </Box>
                    )}

                    {/* Band column */}
                    {applicableCriterias.includes(SetAttributeType.Band) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogSelect
                                value={log.band_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.Band,
                                        parseInt(newValue, 10),
                                    );
                                }}
                                options={BandOptions}
                            />
                        </Box>
                    )}

                    {/* Rest column */}
                    {applicableCriterias.includes(SetAttributeType.Rest) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                value={log.rest_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(log, SetAttributeType.Rest, newValue);
                                }}
                            />
                        </Box>
                    )}

                    {/* GPS Player Load column */}
                    {applicableCriterias.includes(SetAttributeType.GPSPlayerLoad) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                min={0}
                                value={log.gps_player_load_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.GPSPlayerLoad,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* GPS Acceleration Count column */}
                    {applicableCriterias.includes(SetAttributeType.GPSAccelCount) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                min={0}
                                value={log.gps_accel_count_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.GPSAccelCount,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* GPS Deceleration Count column */}
                    {applicableCriterias.includes(SetAttributeType.GPSDecelCount) && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            <LogInput
                                min={0}
                                value={log.gps_decel_count_value}
                                onValueChanged={(newValue: string) => {
                                    onActivityLogValueChanged(
                                        log,
                                        SetAttributeType.GPSDecelCount,
                                        newValue,
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Actions column */}
                    <Box
                        sx={{
                            width: theme.spacing(30),
                            marginRight: theme.spacing(4),
                            [theme.breakpoints.down('md')]: {
                                marginRight: 0,
                            },
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ fontWeight: 700 }}
                        >
                            {log.is_from_original_session ? (
                                <IconButton
                                    sx={{
                                        padddingTop: 0,
                                        padddingBottom: 0,
                                        [theme.breakpoints.down('md')]: {
                                            padding: 0,
                                        },
                                    }}
                                    style={{ visibility: 'hidden' }}
                                    size="large"
                                >
                                    <Star fontSize="small" />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={async () => {
                                        if (
                                            await confirmViaDialog({
                                                confirmation: {
                                                    title: 'Are you sure?',
                                                    message: `This will delete set #${
                                                        index + 1
                                                    } from '${movementName}'.`,
                                                },
                                            })
                                        ) {
                                            onActivityLogRemoved(log);
                                        }
                                    }}
                                    sx={{
                                        padddingTop: 0,
                                        padddingBottom: 0,
                                        [theme.breakpoints.down('md')]: {
                                            padding: 0,
                                        },
                                    }}
                                    size="large"
                                >
                                    <Cancel fontSize="small" />
                                </IconButton>
                            )}
                            <Box sx={{ flexDirection: 'column', justifyContent: 'center' }}>
                                {!log.marked_as_complete && (
                                    <Typography variant={'caption'} textAlign={'left'}>
                                        Skipped
                                    </Typography>
                                )}
                                <IconButton
                                    onClick={() => onActivityLogStatusChanged(log)}
                                    sx={{
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                    size="large"
                                >
                                    {log.marked_as_complete ? (
                                        <CheckCircle color="secondary" />
                                    ) : (
                                        <CheckCircleOutline />
                                    )}
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Divider
                    sx={{ height: theme.spacing(5), background: theme.palette.common.white }}
                />
            </>
        </Box>
    );
};

export default LogMovementRow;
