import React, { useContext, useState } from 'react';
import { Navigation } from 'utils';

export interface NavigationContextProps {
    primaryNavigation: Navigation;
    secondaryNavigation: Navigation;
    setPrimaryNavigation: (navigation: Navigation) => void;
    setSecondaryNavigation: (navigation: Navigation) => void;
    getBreadcrumbs: () => string[];
    pageTitle: string;
    setPageTitle: (title: string) => void;
    isRedirectionBlocked: boolean;
    blockRedirection: (block: boolean) => void;
}

export interface NavigationContextProviderProps {
    defaultPrimaryNavigation?: Navigation;
    defaultSecondaryNavigation?: Navigation;
    children?: React.ReactNode;
}

const NavigationContext = React.createContext<NavigationContextProps>({
    // There values below are just initial values to avoid typescript throwing error
    primaryNavigation: Navigation.EMPTY,
    secondaryNavigation: Navigation.EMPTY,
    setPrimaryNavigation: (_: Navigation) => _,
    setSecondaryNavigation: (_: Navigation) => _,
    getBreadcrumbs: () => [],
    pageTitle: '',
    setPageTitle: (_: string) => _,
    isRedirectionBlocked: false,
    blockRedirection: (_: boolean) => _,
});

const NavigationContextProvider: React.FC<
    React.PropsWithChildren<NavigationContextProviderProps>
> = ({
    defaultPrimaryNavigation = Navigation.EMPTY,
    defaultSecondaryNavigation = Navigation.EMPTY,
    children,
}) => {
    const [pageTitle, setPageTitle] = useState<string>('');

    const [primaryNavigation, setPrimaryNavigation] =
        useState<Navigation>(defaultPrimaryNavigation);
    const [secondaryNavigation, setSecondaryNavigation] = useState<Navigation>(
        defaultSecondaryNavigation,
    );

    const [isRedirectionBlocked, blockRedirection] = useState<boolean>(false);

    // We are not using breadcrumbs for now but just keeping it here when we need it
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);

    const getBreadcrumbs = () => breadcrumbs;

    return (
        <NavigationContext.Provider
            value={{
                primaryNavigation,
                setPrimaryNavigation,
                secondaryNavigation,
                setSecondaryNavigation,
                getBreadcrumbs,
                pageTitle,
                setPageTitle,
                isRedirectionBlocked,
                blockRedirection,
            }}
        >
            {children}
        </NavigationContext.Provider>
    );
};

export const useNavigationContext = (): NavigationContextProps => useContext(NavigationContext);

export default NavigationContextProvider;
