import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { UserProfile } from '../../../utils';

type ReturnType = Promise<AxiosResponse<{ data: UserProfile; meta: { isFollowing: boolean } }>>;

const getSocialProfile = (profileId: string): ReturnType => {
    return Request.get(`/public/social/profile/${profileId}`);
};

export default getSocialProfile;
