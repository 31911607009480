import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CustomButton from 'components/Buttons/CustomButton';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';

interface NextPrevPaginationProps {
    onNext: () => void;
    onPrev: () => void;
    disableNext: boolean;
    disablePrev: boolean;
}

const NextPrevPagination = ({
    onNext,
    onPrev,
    disableNext,
    disablePrev,
}: NextPrevPaginationProps) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: theme.spacing(5),
            }}
        >
            <CustomButton
                variant="text"
                color="primary"
                disabled={disablePrev}
                startIcon={<NavigateBeforeIcon />}
                onClick={onPrev}
            >
                Previous
            </CustomButton>
            <CustomButton
                variant="text"
                color="primary"
                endIcon={<NavigateNextIcon />}
                disabled={disableNext}
                onClick={onNext}
            >
                Next
            </CustomButton>
        </Box>
    );
};

export default NextPrevPagination;
