import { AppBar, Toolbar } from '@mui/material';
import { SpotlightSearch } from 'components';
import ProfileOptions from 'components/Navigation/ProfileOptions';
import Logo from 'images/db_icon.png';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useNotificationData } from '../../contexts/NotificationDataProviderContext';
import AlertIconWithBadge from './AlertIconWithBadge';
import SecondaryNavigation from './SecondaryNavigation';

const MobileTopNavigation = () => {
    const { counts } = useNotificationData();
    return (
        <AppBar position={'relative'} component="header">
            <Toolbar style={{ justifyContent: 'space-between' }}>
                <Link to="/">
                    <img src={Logo} alt="1TUL Logo" width={60} />
                </Link>
                <SpotlightSearch />
                <AlertIconWithBadge count={counts?.unattested_count ?? 0} />
                <ProfileOptions className="" />
            </Toolbar>
            <SecondaryNavigation />
        </AppBar>
    );
};
export default MobileTopNavigation;
