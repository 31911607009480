import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

export type ButtonWithLoaderProps = ButtonProps & { disabled?: boolean };

const ButtonSaveLoader = ({ style, children, disabled, ...props }: ButtonWithLoaderProps) => (
    <Button style={{ textTransform: 'none', ...style, backgroundColor: '#11196E' }} {...props}>
        {children}
        {!!disabled && ''}
    </Button>
);

export default ButtonSaveLoader;
