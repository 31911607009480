import { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { Chart, Dashboard } from '../community.types';
import { Invite, Link, SharedUser } from '../../../utils';

interface CreateDashboardPayload {
    title: string;
    description: string | null;
    organization: string | null;
}

export function getAllDashboards(
    params: {
        'filter[organization_uuid]'?: string;
        'filter[title]'?: string;
        'filter[description]'?: string;
    } = {},
): Promise<AxiosResponse<Dashboard[]>> {
    return Request.get(`/analytics/dashboards`, { params });
}

export function createDashboard(
    payload: CreateDashboardPayload,
): Promise<AxiosResponse<Dashboard>> {
    return Request.post(`/analytics/dashboards`, payload);
}

export function updateDashboard(
    dashboardId: string,
    payload: {
        title?: string;
        description?: string;
    },
): Promise<AxiosResponse<Dashboard>> {
    return Request.put(`/analytics/dashboards/${dashboardId}`, payload);
}

export function deleteDashboard(id: string): Promise<AxiosResponse<void>> {
    return Request.delete(`/analytics/dashboards/${id}`);
}

export function viewDashboard(id: string): Promise<AxiosResponse<Dashboard>> {
    return Request.get(`/analytics/dashboards/${id}`);
}

export function addReportToDashboard(
    dashboardId: string,
    savedFilterId: string,
): Promise<AxiosResponse<Chart>> {
    return Request.post(`/analytics/dashboards/${dashboardId}/charts`, {
        saved_filter: savedFilterId,
    });
}

export function updateOrderOfChartsInDashboard(
    dashboardId: string,
    order: string[],
): Promise<AxiosResponse<Chart>> {
    return Request.put(`/analytics/dashboards/${dashboardId}/charts/reorder`, {
        order: order,
    });
}

export function updateChart(
    dashboardId: string,
    chartId: string,
    payload: { settings?: { width?: number } },
): Promise<AxiosResponse<Chart>> {
    return Request.put(`/analytics/dashboards/${dashboardId}/charts/${chartId}`, payload);
}

export function removeReportFromDashboard(
    dashboardId: string,
    chartId: string,
): Promise<AxiosResponse<void>> {
    return Request.delete(`/analytics/dashboards/${dashboardId}/charts/${chartId}`);
}

export function getInvitesForDashboard(id: string): Promise<AxiosResponse<Array<Invite>>> {
    return Request.get(`/analytics/dashboards/${id}/invites`);
}

export function getLinksForDashboard(id: string): Promise<AxiosResponse<Array<Link>>> {
    return Request.get(`/links/dashboard/${id}`);
}

export function getSharedUsersForDashboard(id: string): Promise<AxiosResponse<SharedUser[]>> {
    return Request.get(`/analytics/dashboards/${id}/users`);
}

export function removeSharedUserFromDashboard(
    id: string,
    userId: string,
): Promise<AxiosResponse<void>> {
    return Request.delete(`/analytics/dashboards/${id}/users/${userId}`);
}

export function revokeInviteForDashboard(
    id: string,
    inviteId: string,
): Promise<AxiosResponse<void>> {
    return Request.delete(`/analytics/dashboards/${id}/invites/${inviteId}`);
}

export function addUsersToDashboard(
    id: string,
    users: Array<string>,
    role: string,
    message?: string,
): Promise<AxiosResponse<Array<SharedUser>>> {
    return Request.post(`/analytics/dashboards/${id}/users`, {
        users,
        role,
        message,
    });
}
