import { AxiosResponse } from 'axios';
import { Request } from 'api';

type ReturnType = Promise<AxiosResponse<void>>;

const unfollow = (user: string): ReturnType => {
    return Request.post(`/social/unfollow`, {
        user,
    });
};

export default unfollow;
