import styled from '@emotion/styled';
import FullCalendar, { EventApi } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, Grid, Hidden, IconButton, LinearProgress, MenuItem, Select } from '@mui/material';

import useTheme from '@mui/material/styles/useTheme';
import moment from 'moment';
import React, { createRef, FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { calendarActions, calendarSelectors } from 'redux/reducers/calendar';
import { useAppDispatch } from 'redux/store';
import { CALENDAR_VIEWS, Dimensions, useToggle } from 'utils';
import { CalendarLeftPanel, ViewEventModal } from '../components';

interface CalendarPageProps {}

const CalendarPage: FC<React.PropsWithChildren<CalendarPageProps>> = () => {
    const theme = useTheme();
    const calendarRef = createRef<FullCalendar>();
    const dispatch = useAppDispatch();

    const { byId: eventsById, loading } = useSelector(calendarSelectors.eventsSelector);
    const {
        activeFilters,
        byType: filters,
        loading: filtersLoading,
        isLoaded: filtersLoaded,
    } = useSelector(calendarSelectors.filtersSelector);

    const [view, setView] = useState(CALENDAR_VIEWS[0].value);

    useEffect(() => {
        dispatch(calendarActions.loadFilters());
    }, [dispatch]);

    const handleChangeSelect = (event: any) => {
        const calendarApi = calendarRef?.current?.getApi();
        if (!calendarApi) return;
        const { value: selectValue } = event.target;
        if (typeof selectValue !== 'string') return;

        calendarApi.changeView(selectValue);
        setView(selectValue);
    };

    const [open, toggleOpen] = useToggle(false);
    const [activeEvent, setActiveEvent] = useState<EventApi | null>(null);
    const [openViewEvent, setOpenViewEvent] = useState<boolean>(false);

    const handleOpenViewEvent = useCallback((eventApi: { event: EventApi }): void => {
        setActiveEvent(eventApi.event);
        setOpenViewEvent(true);
    }, []);

    const handleEventChanged = useCallback(() => {
        dispatch(
            calendarActions.loadEvents({
                filters: activeFilters,
                startsAfter: moment(calendarRef?.current?.getApi().view.currentStart)
                    .subtract(60, 'days')
                    .unix()
                    .toString(),
                endsBefore: moment(calendarRef?.current?.getApi().view.currentEnd)
                    .add(60, 'days')
                    .unix()
                    .toString(),
            }),
        );
    }, [activeFilters, calendarRef, dispatch]);

    const handleCloseViewEvent = (): void => {
        setOpenViewEvent(false);
        // UX: set the active event to "null" first, and append
        // the active event delay to avoid the modal text from
        // flickering as it fades out
        setTimeout(() => setActiveEvent(null), 200);
    };

    const container = () => window?.document?.body ?? undefined;

    const handleChangeFilter = (filters: Dimensions[]) => {
        dispatch(
            calendarActions.loadEvents({
                filters,
                startsAfter: moment(calendarRef?.current?.getApi().view.currentStart)
                    .subtract(60, 'days')
                    .unix()
                    .toString(),
                endsBefore: moment(calendarRef?.current?.getApi().view.currentEnd)
                    .add(60, 'days')
                    .unix()
                    .toString(),
            }),
        );
    };

    const Panel = (
        <CalendarLeftPanel
            isLoading={filtersLoading}
            isLoaded={filtersLoaded}
            filters={filters}
            selectedFilters={activeFilters}
            value={view}
            onChangeFilter={handleChangeFilter}
            onChangeSelect={handleChangeSelect}
        />
    );

    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    position: 'relative',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                    },
                }}
            >
                {loading && (
                    <LinearProgress
                        sx={{
                            height: theme.spacing(3),
                            left: 0,
                            position: 'absolute',
                            top: 0,
                            width: '100%',
                        }}
                    />
                )}
                <Drawer
                    anchor="left"
                    sx={{
                        width: '85vw',
                    }}
                    container={container}
                    variant="temporary"
                    open={open}
                    onClose={toggleOpen}
                >
                    {Panel}
                </Drawer>
                <Hidden smDown>
                    <div
                        style={{
                            width: '25%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',

                            [theme.breakpoints.down('sm')]: {
                                width: '10%',
                                height: 'auto',
                            },
                        }}
                    >
                        {Panel}
                    </div>
                </Hidden>
                <div
                    style={{
                        width: '75%',
                        height: '100%',
                        paddingTop: theme.spacing(10),

                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                        },
                    }}
                >
                    <StyleWrapper>
                        <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Hidden smUp>
                                <div
                                    style={{
                                        width: '25%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <IconButton onClick={toggleOpen} size="large">
                                        <MenuIcon />
                                    </IconButton>
                                </div>
                            </Hidden>
                            <Select
                                sx={{
                                    height: 32,
                                    width: 90,
                                    fontSize: 14,
                                    color: theme.palette.primary.main,
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '& .MuiSelect-icon': {
                                        color: `${theme.palette.primary.main} !important`,
                                    },
                                }}
                                value={view}
                                onChange={handleChangeSelect}
                                variant="outlined"
                                color="primary"
                            >
                                {CALENDAR_VIEWS.map(({ label, value }) => (
                                    <MenuItem value={value} key={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item>
                            <FullCalendar
                                ref={calendarRef}
                                editable={true}
                                events={Object.values(eventsById)}
                                eventClick={handleOpenViewEvent}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                headerToolbar={{ right: 'next', center: 'title', left: 'prev' }}
                                contentHeight="auto"
                                datesSet={(arg) => {
                                    if (arg) {
                                        dispatch(
                                            calendarActions.loadEvents({
                                                filters: activeFilters,
                                                startsAfter: moment(arg.start)
                                                    .subtract(60, 'days')
                                                    .unix()
                                                    .toString(),
                                                endsBefore: moment(arg.end)
                                                    .add(60, 'days')
                                                    .unix()
                                                    .toString(),
                                            }),
                                        );
                                    }
                                }}
                            />
                        </Grid>
                    </StyleWrapper>
                </div>
            </div>
            {activeEvent && (
                <ViewEventModal
                    open={openViewEvent}
                    event={activeEvent}
                    handleClose={handleCloseViewEvent}
                    onChanged={handleEventChanged}
                />
            )}
        </>
    );
};

export const StyleWrapper = styled.div`
    .fc-button.fc-prev-button,
    .fc-button.fc-next-button {
        background: #7f8f9f;
        stroke: #7f8f9f;
    }
`;

export default CalendarPage;
