import { Box, Radio, RadioGroup, Typography } from '@mui/material';
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { visuallyHidden } from '@mui/utils';

interface Option {
    value: string;
    label: string;
    description: string | React.ReactNode;
    price: string;
    image?: string | any;
}

interface ChooseOrganizationPlanRadioGroupProps {
    options: Option[];
    onSelected: (value: string) => void;
    initialValue: string;
}

const ChooseOrganizationPlanRadioGroup = ({
    initialValue,
    options,
    onSelected,
}: ChooseOrganizationPlanRadioGroupProps) => {
    const [selectedValue, setSelectedValue] = React.useState<string>(initialValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
        onSelected(event.target.value);
    };

    return (
        <Box component="fieldset" sx={{ border: 'none', p: 0 }}>
            <legend style={visuallyHidden}>Server size</legend>
            <RadioGroup
                name="server-size"
                value={selectedValue}
                onChange={handleChange}
                sx={{
                    // ensure label root is 100% width

                    '& .MuiFormControlLabel-root': { mb: 2, width: '100%' },
                    '& .MuiSvgIcon-root': { fontSize: 28 }, // Customize the radio icon size
                }}
            >
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        value={option.value}
                        control={<Radio />}
                        label={
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                <img
                                    src={option.image}
                                    alt={`${option.label} plan`}
                                    style={{ width: 50, height: 50, marginRight: 16 }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ fontWeight: 'medium' }}
                                        >
                                            {option.label}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {option.description}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{ fontWeight: 'medium', textAlign: 'right' }}
                                        >
                                            {option.price}
                                        </Typography>
                                        <Typography
                                            sx={{ textAlign: 'right' }}
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            /mo
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        }
                        sx={{
                            width: '100%',
                            '&:hover': {
                                bgcolor: 'transparent',
                            },
                            '& .MuiTypography-root': {
                                display: 'flex',
                                flexDirection: 'column',
                            },
                            '& .MuiRadio-root': {
                                color:
                                    selectedValue === option.value
                                        ? 'primary.main'
                                        : 'action.disabled',
                            },
                            '& .MuiFormControlLabel-label': {
                                width: '100%',
                            },
                        }}
                    />
                ))}
            </RadioGroup>
        </Box>
    );
};

export default ChooseOrganizationPlanRadioGroup;
