import React, { useState } from 'react';
import { Box, Button, InputBase, MenuItem, Popover, TextField } from '@mui/material';
import moment from 'moment';
import { debounce, range } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@mui/system';

interface DurationPickerProps {
    initialDurationInSeconds: number;
    onChange: (durationInSeconds: number) => void;
}

const DurationPicker = ({ initialDurationInSeconds, onChange }: DurationPickerProps) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [open, setOpen] = React.useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setAnchorEl(event.target as HTMLDivElement);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
        updateDuration(hour, minute, second);
    };

    const [hour, setHour] = useState<string>(
        moment('2015-01-01').startOf('day').seconds(initialDurationInSeconds).format('HH'),
    );
    const [minute, setMinute] = useState<string>(
        moment('2015-01-01').startOf('day').seconds(initialDurationInSeconds).format('mm'),
    );
    const [second, setSecond] = useState<string>(
        moment('2015-01-01').startOf('day').seconds(initialDurationInSeconds).format('ss'),
    );

    const updateDuration = debounce(
        (hourValue: string, minuteValue: string, secondValue: string) => {
            let durationInSeconds = 0;
            durationInSeconds += (parseInt(hourValue, 10) || 0) * 3600;
            durationInSeconds += (parseInt(minuteValue, 10) || 0) * 60;
            durationInSeconds += parseInt(secondValue, 10) || 0;
            onChange(durationInSeconds);
        },
        1000,
    );

    return (
        <>
            <InputBase
                value={`${hour}:${minute}:${second}`}
                type="text"
                readOnly={true}
                inputProps={{
                    min: 0,
                    max: 1000,
                    fontSize: 13,
                    'aria-label': 'naked',
                }}
                placeholder="00:00:00"
                onClick={handleClick}
            />
            <Popover
                id={uuidv4()}
                open={open}
                anchorEl={anchorEl}
                onClose={() => {}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ backgroundColor: theme.palette.backgroundColor.hover, zIndex: 999 }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        p={10}
                        sx={{
                            gap: 10,
                            '& .MuiSelect-selectMenu': {
                                fontSize: 14,
                            },
                        }}
                    >
                        <TextField
                            select
                            sx={{ width: theme.spacing(50) }}
                            label="Hours"
                            value={hour}
                            onChange={(e) => {
                                setHour(e.target.value);
                            }}
                        >
                            {range(0, 23).map((hourValue) => {
                                let hourValueForDisplay = hourValue.toString().padStart(2, '0');
                                return (
                                    <MenuItem
                                        key={`hour-${hourValueForDisplay}`}
                                        value={hourValueForDisplay}
                                        sx={{ fontSize: 14 }}
                                    >
                                        {hourValue}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                        <TextField
                            select
                            sx={{ width: theme.spacing(50) }}
                            label="Minutes"
                            value={minute}
                            onChange={(e) => {
                                setMinute(e.target.value);
                            }}
                        >
                            {range(0, 59).map((minValue) => {
                                let minValueForDisplay = minValue.toString().padStart(2, '0');
                                return (
                                    <MenuItem
                                        key={`hour-${minValueForDisplay}`}
                                        value={minValueForDisplay}
                                        sx={{ fontSize: 14 }}
                                    >
                                        {minValue}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                        <TextField
                            select
                            sx={{ width: theme.spacing(50) }}
                            label="Seconds"
                            value={second}
                            onChange={(e) => {
                                setSecond(e.target.value);
                            }}
                        >
                            {range(0, 59).map((secValue) => {
                                let secValueForDisplay = secValue.toString().padStart(2, '0');
                                return (
                                    <MenuItem
                                        key={`hour-${secValueForDisplay}`}
                                        value={secValueForDisplay}
                                        sx={{ fontSize: 14 }}
                                    >
                                        {secValue}
                                    </MenuItem>
                                );
                            })}
                        </TextField>
                    </Box>
                    <Box textAlign="center" pb={2}>
                        <Button size="small" variant="text" onClick={() => handleClose()}>
                            Done
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};

export default DurationPicker;
