import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Activity } from 'utils';

type ReturnType = Promise<AxiosResponse<Activity>>;

const startActivity = (
    local_timezone: string,
    individual: string,
    session?: string,
): ReturnType => {
    return Request.post(`/workouts/activities/start`, { local_timezone, individual, session });
};
export default startActivity;
