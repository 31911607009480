import { Timeline } from '@mui/lab';
import { Box, Grid, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import TimelineWithFilterSkeleton from 'components/Skeletons/TimelineWithFilters';
import React, { ReactElement, useMemo, useState } from 'react';
import { FiltersBySourceType } from 'redux/reducers/timeline';

import { Activity, Dimensions, TimelineEntries } from 'utils';
import { searchDimensions } from 'utils/timelineUtils';
import { DynamicTimelineItem, Filters, LoadMore } from './';

type Entries = {
    [key: string]: TimelineEntries[];
};

type DynamicTimelineProps = {
    entries: Entries;
    filters: FiltersBySourceType;
    filtersLoading: boolean;
    filtersLoaded: boolean;
    next: string | null;
    isLoading: boolean;
    isLoaded: boolean;
    handleFilters: (filters: Dimensions[]) => void;
    loadMore: () => void;
    selectedFilters: Dimensions[];
    archivedActivities: Activity[];
    unattestedActivities: Activity[];
};

const DynamicTimeline = ({
    entries,
    filters,
    next,
    isLoading,
    isLoaded,
    handleFilters,
    loadMore,
    filtersLoading,
    filtersLoaded,
    selectedFilters,
    archivedActivities,
    unattestedActivities,
}: DynamicTimelineProps): ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [searchFilters, setSearchFilters] = useState({});
    const [showFiltersMobile, setShowFiltersMobile] = useState(false);

    const searchedFilters: FiltersBySourceType = useMemo(
        () => searchDimensions(searchFilters, filters),
        [filters, searchFilters],
    );

    const handleSearchFilters = (searchFilters: Record<string, string>) => {
        setSearchFilters(searchFilters);
    };

    const toggleMobileFilters = () => setShowFiltersMobile(!showFiltersMobile);

    if (isLoading && !isLoaded) {
        return <TimelineWithFilterSkeleton />;
    }

    const timelineEntries = Object.entries(entries);

    return (
        <div>
            {!isLoading && <Box height={4} sx={{ width: '100%' }} />}
            {isLoading && <LinearProgress sx={{ width: '100%' }} />}
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <Filters
                        isLoading={filtersLoading}
                        isLoaded={filtersLoaded}
                        filters={searchedFilters}
                        isMobile={isMobile}
                        showFiltersMobile={showFiltersMobile}
                        toggleMobileFilters={toggleMobileFilters}
                        handleFilters={handleFilters}
                        handleSearchFilters={handleSearchFilters}
                        selectedFilters={selectedFilters}
                        archivedActivities={archivedActivities}
                        unattestedActivities={unattestedActivities}
                    />
                </Grid>
                <Grid item xs={12} sm={8} sx={{ width: '100%' }}>
                    <Box mb={25}>
                        <Timeline
                            sx={{
                                flexGrow: 1,
                                overflowY: showFiltersMobile ? 'hidden' : 'auto',
                                marginTop: (theme) =>
                                    theme.breakpoints.down('sm') ? 0 : undefined,
                                width: '100%', // updated line
                            }}
                        >
                            {!timelineEntries.length && isLoaded ? (
                                <Typography variant="subtitle1" align="center" gutterBottom>
                                    No events to show
                                </Typography>
                            ) : (
                                timelineEntries.map(([key, timeline], index: number) => (
                                    <React.Fragment key={`timeline-item-${index}-${key}`}>
                                        {timeline.length > 0 && (
                                            <DynamicTimelineItem
                                                data={timeline}
                                                date={key}
                                                key={key}
                                                hideSegment={
                                                    index === timelineEntries.length - 1 && !next
                                                }
                                            />
                                        )}
                                    </React.Fragment>
                                ))
                            )}
                            {next && <LoadMore isLoading={isLoading} handleLoadMore={loadMore} />}
                        </Timeline>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export { DynamicTimeline };
