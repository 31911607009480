import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ url: string }>>;

const createCheckoutComboAthleteSubscriptionWithVestsAndOptionalChargers = (
    return_url: string,
    seller_org_id: string,
    pod_uuid: string,
    vest_price_id_quantity_map: Record<string, number>,
    charger_price_id_quantity_map: Record<string, number>,
): ReturnType => {
    return Request.post(`/athlete-users/billing/checkout-combo-subscription-session`, {
        return_url,
        seller_org_id,
        pod_uuid,
        vest_price_id_quantity_map,
        charger_price_id_quantity_map,
    });
};
export default createCheckoutComboAthleteSubscriptionWithVestsAndOptionalChargers;
