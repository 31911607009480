import QrCode2Icon from '@mui/icons-material/QrCode2';
import ShareIcon from '@mui/icons-material/Share';
import { Box, CircularProgress, IconButton, Fab } from '@mui/material';
import { getSession } from 'api';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'Routes/routes';
import { Session } from 'utils';
import { SessionSharingDrawer } from '../../components';
import { AbilityContext } from '../../components/Functional/Can';
import SessionSummary from './SessionSummary';
import { startForSelf } from '../../redux/reducers/movementLogs';
import { pushMessage } from '../../redux/reducers/messages';
import { useAppDispatch } from '../../redux/store';
import { styled } from '@mui/material/styles';

const FabContainer = styled(Box)(({ theme }) => ({
    position: 'fixed',
    bottom: theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)',
    // Adjust bottom spacing if there's a navigation footer
    [theme.breakpoints.down('md')]: {
        bottom: theme.spacing(8), // Increase this value based on your footer height
    },
    // Ensure FAB stays above ALL other content including footer
    zIndex: 9999,
}));

const ViewSession = () => {
    const { push } = useHistory();
    let { id: sessionUuid } = useParams<{ id: string }>();

    const [session, setSession] = useState<Session | null>(null);
    const [sessionShareDrawer, setSessionShareDrawer] = useState<boolean>(false);
    const ability = useContext(AbilityContext);
    const dispatch = useAppDispatch();
    const [creatingActivity, setCreatingActivity] = useState<boolean>(false);

    const onLogAthleteClicked = async (session: Session) => {
        setCreatingActivity(true);
        const response: any = await dispatch(
            startForSelf({
                local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                session: session.uuid,
            }),
        );
        if (startForSelf.fulfilled.match(response)) {
            push(ROUTES.AddQuickSession.path.replace(':id', response.payload.uuid));
        } else {
            dispatch(
                pushMessage({
                    status: 'error',
                    message: response.payload.message,
                }),
            );
        }
    };

    // Load the form from the database
    useEffect(() => {
        if (!session) {
            getSession(sessionUuid).then((response) => {
                let session = response.data as Session;
                // Making sure the session movements are shown as per their order in the backend
                session?.movements?.sort(
                    (m1, m2) => (m1?.pivot?.order || 0) - (m2?.pivot?.order || 0),
                );
                setSession(response.data as Session);
            });
        }
    }, [sessionUuid, session]);

    return (
        <FixedLayout
            title={session ? session.title : 'View Session'}
            titleActions={
                <React.Fragment>
                    {session &&
                        (ability.can('session:share', session) ||
                            ability.can('session:share-partial', session)) && (
                            <IconButton onClick={() => setSessionShareDrawer(true)} size="large">
                                <QrCode2Icon color="inherit" />
                                <ShareIcon color="inherit" />
                            </IconButton>
                        )}
                </React.Fragment>
            }
            content={
                session ? (
                    <React.Fragment>
                        {sessionShareDrawer && (
                            <SessionSharingDrawer
                                open={true}
                                onClose={() => setSessionShareDrawer(false)}
                                sessionUuid={session.uuid}
                                restrictsRolesToShare={!ability.can('session:share', session)}
                                onUsersLoaded={() => {}}
                            />
                        )}
                        <SessionSummary session={session as Session} />

                        {/* Replace the footer button with a FAB */}
                        <FabContainer>
                            <Fab
                                color="primary"
                                disabled={creatingActivity}
                                onClick={() => {
                                    onLogAthleteClicked(session).then(() => {});
                                }}
                                size="large"
                                sx={{
                                    mb: 2,
                                    borderRadius: '4px',
                                    width: '120px',
                                    height: '48px',
                                    '& .MuiSvgIcon-root': {
                                        marginRight: '8px',
                                    },
                                    '@media (max-width: 899px)': {
                                        mb: 36,
                                    },
                                }}
                            >
                                START
                            </Fab>
                        </FabContainer>
                    </React.Fragment>
                ) : (
                    <Box pt={20} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )
            }
        ></FixedLayout>
    );
};

export default ViewSession;
