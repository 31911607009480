import { CombinedState, combineReducers } from 'redux';

import { feedSelector, feedSlice, follow, loadFeed, SocialFeedState, unfollow } from './feed';

import {
    deleteFromFeed,
    loadMyFeed,
    myFeedSelector,
    myFeedSlice,
    MyFeedState,
    postToFeed,
} from './my-feed';

import {
    loadMyProfile,
    myProfileSelector,
    profileSlice,
    ProfileState,
    updateProfile,
} from './profile';

export type SocialReducerState = CombinedState<{
    myFeed: MyFeedState;
    myProfile: ProfileState;
    feed: SocialFeedState;
}>;

export const socialReducer = combineReducers<SocialReducerState>({
    myFeed: myFeedSlice.reducer,
    myProfile: profileSlice.reducer,
    feed: feedSlice.reducer,
});

export const myFeedActions = {
    loadMyFeed,
    postToFeed,
    deleteFromFeed,
    updateProfile,
};

export const profileActions = {
    loadMyProfile,
};

export const feedActions = {
    loadFeed,
    follow,
    unfollow,
};

export const feedSelectors = {
    myFeedSelector,
    myProfileSelector,
    feedSelector,
};
