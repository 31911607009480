import { AxiosResponse } from 'axios';
import { Request } from 'api';

type ReturnType = Promise<AxiosResponse<void>>;

const updateProfile = (payload: { bio: string }): ReturnType => {
    return Request.put(`/social/profile`, payload);
};

export default updateProfile;
