import React from 'react';
import Box from '@mui/material/Box';

interface LayoutWithTopBarProps {
    children: React.ReactNode;
    title: string;
    onBack: () => void;
}

const LayoutWithTopBar = ({ children }: LayoutWithTopBarProps) => {
    return (
        <Box sx={{ flex: 1 }}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {children}
            </Box>
        </Box>
    );
};

export default LayoutWithTopBar;
