import React, { ChangeEventHandler, useRef, useState } from 'react';
import Button from '@mui/material/Button';

const DirectoryInput = ({ onChange }: { onChange: ChangeEventHandler<HTMLInputElement> }) => {
    const inputFolder = useRef<HTMLInputElement | null>(null);
    const [fileCount, setFileCount] = useState<number>(0);

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange(e);

        const fileInput = e.currentTarget;
        if (fileInput?.files) {
            let csvFiles = Array.from(fileInput.files).filter((file) => file.type === 'text/csv');
            setFileCount(csvFiles.length);
        }
    };

    return (
        <Button
            variant="contained"
            color={fileCount > 0 ? 'inherit' : 'primary'}
            component="label"
            style={{ fontSize: '16px', padding: '10px' }}
        >
            {fileCount > 0 ? `${fileCount} files uploaded` : 'Upload Folder'}
            <input
                multiple
                onChange={handleInputChange}
                type="file"
                hidden
                ref={(node) => {
                    inputFolder.current = node;

                    if (node) {
                        ['webkitdirectory', 'directory', 'mozdirectory'].forEach((attr) => {
                            node.setAttribute(attr, '');
                        });
                    }
                }}
            />
        </Button>
    );
};

export default DirectoryInput;
