import {
    AuthScreenLayout,
    ForgotPasswordForm,
    TextButton,
    TypographySubtitle,
    TypographyTitle,
} from 'components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from 'Routes/routes';

const ForgotPassword = () => {
    const { push } = useHistory();

    return (
        <AuthScreenLayout>
            <TypographyTitle
                sx={{
                    marginY: 5,
                }}
            >
                Forgot your password?
            </TypographyTitle>
            <TypographySubtitle
                sx={{
                    textAlign: 'center',
                    marginTop: 5,
                    marginBottom: 16,
                }}
            >
                You will receive a link to create a new password via email.
            </TypographySubtitle>
            <ForgotPasswordForm />
            <TextButton onClick={() => push(ROUTES.Login.path)}>Back to login</TextButton>
        </AuthScreenLayout>
    );
};

export default ForgotPassword;
