import { Box, Paper, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { Group } from 'utils';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

export type GroupMemberCountChipProps = {
    group: Group;
};

const GroupMemberCountChip = ({ group }: GroupMemberCountChipProps): ReactElement => {
    const getMemberCountText = () => {
        let countText: string[] = [];
        if (!group.member_counts) {
            return '';
        }

        Object.keys(group.member_counts).forEach((key) => {
            if (!group.member_counts) {
                return;
            }
            countText.push(`${group.member_counts[key].name}s: ${group.member_counts[key].count}`);
        });

        return countText.join(' | ');
    };
    const getUserCount = () => {
        return group.members_with_users;
    };

    return (
        <Paper
            style={{ padding: 5, margin: 6, textAlign: 'center' }}
            variant="elevation"
            sx={{ backgroundColor: '#ffffff' }}
        >
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Typography variant="caption" color="textPrimary">
                    {group.members_count
                        ? `Total Members: ${group.members_count}`
                        : `No members are in this group`}
                </Typography>
                <Box display="flex" alignItems="center" sx={{ marginTop: 1 }}>
                    <VerifiedUserIcon sx={{ color: '#1B9CEA', fontSize: 'medium' }} />
                    <Typography
                        variant="caption"
                        style={{ color: getUserCount() === 0 ? '#ff3d60' : '#1B9CEA' }}
                    >
                        {getUserCount() > 0
                            ? `Total Users: ${getUserCount()}`
                            : `No users are in this group`}
                    </Typography>
                </Box>
                {Boolean(group.members_count) && (
                    <Typography variant="caption" color="textSecondary">
                        {getMemberCountText()}
                    </Typography>
                )}
            </Box>
        </Paper>
    );
};

export default GroupMemberCountChip;
