import { Avatar, Box, Button, Snackbar, Typography } from '@mui/material';
import {
    DataGridPremium,
    GridColDef,
    GridRowModel,
    GridRowsProp,
    GridToolbar,
} from '@mui/x-data-grid-premium';
import { queue } from 'async';
import { SelectDateChip } from 'components/Chips';
import { sortBy } from 'lodash';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { BandOptions, Group, Session } from '../../../utils';
import {
    saveGroupWorkoutRow,
    submitGroupWorkout,
    updateGroupWorkout,
    viewGroupWorkout,
} from '../api/groups';
import { GroupWorkout } from '../train.types';

const rpeValueOptions = [
    { value: 1, label: 'Very Light' },
    { value: 2, label: 'Light' },
    {
        value: 3,
        label: 'Moderate',
    },
    { value: 4, label: 'Hard' },
    { value: 5, label: 'Max Effort' },
];
const bandValueOptions = [
    { value: 1, label: BandOptions[1] },
    { value: 2, label: BandOptions[2] },
    { value: 3, label: BandOptions[3] },
    { value: 4, label: BandOptions[4] },
    { value: 5, label: BandOptions[5] },
];

function mapColumnsToMeasurementType(columns: GridColDef[]): GridColDef[] {
    return columns.map((c: any) => {
        let newColumnDefinition: any = {
            ...c,
            editable: true,
            sortable: false,
            type: 'number',
            minWidth: 175,
        };
        let measurement = newColumnDefinition.field.split('|')[1];

        if (newColumnDefinition.field === 'player') {
            newColumnDefinition.type = 'string';
            newColumnDefinition.editable = false;
        } else {
            newColumnDefinition.minWidth = 200;
        }

        if (measurement === 'body_side') {
            newColumnDefinition.type = 'singleSelect';
            newColumnDefinition.valueOptions = ['left', 'right', 'both'];
            newColumnDefinition.valueFormatter = function valueFormatter(value?: string) {
                return capitalize(value ?? '');
            };
        }
        if (measurement === 'rpe_value') {
            newColumnDefinition.type = 'singleSelect';
            newColumnDefinition.valueOptions = rpeValueOptions;
            newColumnDefinition.valueFormatter = function valueFormatter(value?: number) {
                return rpeValueOptions.find((o) => o.value === (value ?? -1))?.label ?? '';
            };
        }
        if (measurement === 'band_value') {
            newColumnDefinition.type = 'singleSelect';
            newColumnDefinition.valueOptions = bandValueOptions;
            newColumnDefinition.valueFormatter = function valueFormatter(value?: number) {
                return bandValueOptions.find((o) => o.value === value ?? -1)?.label ?? '';
            };
        }

        return newColumnDefinition as GridColDef;
    });
}

function GroupLogging(): React.ReactElement {
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [q, setQ] = useState<any>();
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const { groupWorkoutId } = useParams<{ groupWorkoutId: string }>();
    const [group, setGroup] = useState<Group>();
    const [workout, setWorkout] = useState<GroupWorkout>();
    const [session, setSession] = useState<Session>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [saveError, setSaveError] = useState<string>('');
    useEffect(() => {
        viewGroupWorkout(groupWorkoutId)
            .then((response) => {
                setGroup(response.data.group);
                setWorkout(response.data);
                setSession(response.data.session);
                setRows(sortBy(response.data.rows, (r) => r.player));
                setColumns(mapColumnsToMeasurementType(response.data.columns));
            })
            .catch(() => {})
            .finally(() => {});
    }, [groupWorkoutId]);

    useEffect(() => {
        setQ(
            queue(function (task: { groupWorkoutId: string; newRow: GridRowModel }, callback: any) {
                setSaving(true);
                saveGroupWorkoutRow(task.groupWorkoutId, task.newRow)
                    .then(() => {})
                    .catch(() => setSaveError('Error while saving. Try again'))
                    .finally(() => {
                        setSaving(false);
                        callback();
                    });
            }, 1),
        );
    }, []);

    const processRowUpdate = React.useCallback(
        (newRow: GridRowModel) => {
            // Make the HTTP request to save in the backend
            q.push({ groupWorkoutId, newRow });
            return newRow;
        },
        [q, groupWorkoutId],
    );

    const handleSubmit = React.useCallback(() => {
        setSubmitting(true);
        submitGroupWorkout(groupWorkoutId)
            .then(() =>
                setWorkout((prevWorkout: any) => {
                    return { ...prevWorkout, submitted_at: DateTime.now().toSeconds() };
                }),
            )
            .catch(() => setSaveError('Error while submitting log. Try again or contact support'))
            .finally(() => setSubmitting(false));
    }, [groupWorkoutId]);

    const handleDateChange = (newDate: DateTime | null) => {
        setSaving(true);
        updateGroupWorkout(groupWorkoutId, { date: moment(newDate).format('YYYY-MM-DD HH:mm:00') })
            .then((result) => {
                setWorkout(result.data);
            })
            .catch(() => {
                setSaveError('Error while saving date. Try again or contact support');
            })
            .finally(() => setSaving(false));
    };

    return (
        <>
            <Snackbar
                open={Boolean(saveError)}
                autoHideDuration={3000}
                onClose={() => setSaveError('')}
                message={saveError}
                action={
                    <Button color="secondary" size="small" onClick={() => setSaveError('')}>
                        Ok
                    </Button>
                }
            />
            <Box
                sx={{
                    paddingX: { xs: 2, lg: 12 },
                    paddingY: 2,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Avatar
                    src={group?.organization?.image_urls.avatar ?? ''}
                    variant={'square'}
                    sx={{ width: { xs: 40, md: 80 }, height: { xs: 40, md: 80 } }}
                />
                <Typography
                    sx={{ marginLeft: 8, fontSize: { xs: '1.5em', md: '2em' }, fontWeight: 'bold' }}
                >
                    Group Log
                </Typography>
            </Box>
            <Box sx={{ paddingX: { lg: 12 }, paddingY: { lg: 3 }, width: '100%' }}>
                <Box
                    sx={{
                        display: { md: 'flex' },
                        alignItems: { md: 'center' },
                        justifyContent: { md: 'space-between' },
                        width: '100%',
                        boxShadow: { lg: 3 },
                        backgroundColor: 'white',
                        borderRadius: { lg: 3 },
                        paddingY: 12,
                        paddingX: 8,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            sx={{
                                width: { xs: 40, md: 80 },
                                height: { xs: 40, md: 80 },
                                marginRight: 6,
                            }}
                            src={group?.image_urls['avatar'] ?? ''}
                            variant={'rounded'}
                            alt={group?.name ?? ''}
                        />
                        <Box sx={{ flex: '1 1 0%' }}>
                            <Typography variant="h5">{group?.name}</Typography>
                            <Typography variant="body1">Logging for {session?.title}</Typography>
                            {workout?.submitted_at ? (
                                <>
                                    {moment(workout?.date ? workout.date * 1000 : DateTime.now())
                                        .set('second', 0)
                                        .format('llll')}
                                </>
                            ) : (
                                <SelectDateChip
                                    type={'datetime'}
                                    value={
                                        workout?.date
                                            ? DateTime.fromSeconds(workout?.date)
                                            : DateTime.now()
                                    }
                                    onChange={handleDateChange}
                                />
                            )}
                        </Box>
                    </Box>
                    {workout && (
                        <Box
                            sx={{
                                marginTop: {
                                    xs: 8,
                                    md: 0,
                                },
                                marginLeft: {
                                    xs: 0,
                                    md: 8,
                                },
                                display: 'flex',
                            }}
                        >
                            {!workout?.submitted_at && (
                                <Button
                                    disabled={Boolean(submitting || saving || editing)}
                                    onClick={handleSubmit}
                                    variant="contained"
                                    color="primary"
                                >
                                    Submit
                                </Button>
                            )}
                            {workout?.submitted_at && 'Submitted - No Editing'}
                        </Box>
                    )}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', paddingX: { lg: 12 } }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGridPremium
                        sx={{
                            backgroundColor: '#ffffff',
                            boxShadow: { lg: 3 },
                            borderRadius: { lg: 3 },
                            padding: { lg: 2 },
                            height: '80vh',
                        }}
                        autoHeight={false}
                        rows={rows}
                        columns={columns}
                        pinnedColumns={{ left: ['player'] }}
                        editMode="row"
                        hideFooterPagination={true}
                        disableRowSelectionOnClick={true}
                        hideFooter={true}
                        isCellEditable={() => !workout?.submitted_at}
                        onCellEditStart={() => setEditing(true)}
                        onCellEditStop={() => setEditing(false)}
                        processRowUpdate={(row) => processRowUpdate(row)}
                        onProcessRowUpdateError={() => setSaveError('Error while saving')}
                        slots={{ toolbar: GridToolbar, loadingOverlay: CustomLoadingOverlay }}
                    />
                </div>
            </Box>
        </>
    );
}

export default GroupLogging;
