import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Button,
    Checkbox,
    Chip,
    Dialog,
    Divider,
    FormControlLabel,
    Input,
    Paper,
    Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React, { ChangeEvent, FC, Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    FiltersBySourceType,
    getSupportTeamGroupedByOrg,
    getUnattestedCount,
    loadArchivedActivites,
    loadSupportTeam,
    loadUnattestedActivities,
} from 'redux/reducers/timeline';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { Activity, Dimensions } from 'utils';
import { listOfFilters } from 'utils/timelineUtils';
import { useAppDispatch } from '../../../redux/store';
import { maybePluralize } from '../plan.functions';
import FilterDialogContents from './FilterDialogContents';
import { FilterLabel } from './FilterLabel';
import { MobileFilterLayout } from './MobileFilterLayout';

type SearchFilters = Record<string, string>;

type FiltersProps = {
    filters: FiltersBySourceType;
    showFiltersMobile: boolean;
    isMobile: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    toggleMobileFilters: () => void;
    handleFilters: (selectedFilters: Dimensions[]) => void;
    handleSearchFilters: (searchFilters: SearchFilters) => void;
    selectedFilters: Dimensions[];
    archivedActivities: Activity[];
    unattestedActivities: Activity[];
};

const Filters: FC<React.PropsWithChildren<FiltersProps>> = ({
    filters,
    isMobile,
    showFiltersMobile,
    toggleMobileFilters,
    handleFilters,
    handleSearchFilters,
    isLoaded,
    isLoading,
    selectedFilters,
    archivedActivities,
    unattestedActivities,
}) => {
    const [searchValue, setSearchValue] = useState<Record<string, string>>({});
    const theme = useTheme();
    const handleFilterClick = (
        event: SyntheticEvent<HTMLLabelElement | HTMLInputElement>,
        filter: Dimensions,
    ) => {
        const { type } = event.target as HTMLInputElement;

        if (type === 'checkbox') {
            let filterList = listOfFilters(selectedFilters, filter);
            handleFilters(filterList);
        }
    };

    const getSelectedFiltersCount = (value: Dimensions[]): string | number => {
        let result = 0;

        selectedFilters.map((selectedFilter) => {
            let found = value.filter((x) => x.id === selectedFilter.id);
            if (found.length) result++;
        });

        return result > 0 ? result : '';
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.currentTarget;
        const searchValueObj = { ...searchValue, [name]: value };
        setSearchValue(searchValueObj);
        handleSearchFilters(searchValueObj);
    };
    const handleSubmitFilters = (): void => handleFilters(selectedFilters);
    const handleClearFilters = (): void => handleFilters([]);

    const filterEntries = Object.entries(filters);
    const dispatch = useAppDispatch();
    const supportTeam = useSelector(getSupportTeamGroupedByOrg);

    const [modalOpen, setModalOpen] = useQueryParam('modalOpen', BooleanParam);

    const unattestedCount = useSelector(getUnattestedCount);

    useEffect(() => {
        dispatch(loadArchivedActivites());
        dispatch(loadUnattestedActivities());
        dispatch(loadSupportTeam());
    }, [dispatch]);

    return !filterEntries.length && isLoaded && !isLoading ? (
        <div
            style={{
                marginTop: theme.spacing(10),
                flexBasis: '100%',
                [theme.breakpoints.up('md')]: {
                    flexBasis: '25%',
                },
            }}
        >
            <Typography variant="subtitle1" align="center" gutterBottom>
                No timeline filters to show
            </Typography>
        </div>
    ) : (
        <>
            {isMobile && (
                <div
                    style={{
                        flexBasis: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        padding: '16px 16px 8px',
                    }}
                >
                    <Badge
                        color="primary"
                        sx={{ display: 'flex', alignItems: 'center', gap: 8 }}
                        badgeContent={selectedFilters.length}
                        onClick={toggleMobileFilters}
                    >
                        <FilterListIcon />
                        <span>Filters</span>
                    </Badge>
                </div>
            )}
            <Paper
                elevation={4}
                sx={{
                    margin: '15px 15px 0px 15px',
                    padding: 15,
                    flexBasis: '25%',
                    overflowX: 'hidden',
                    [theme.breakpoints.down('lg')]: {
                        flexBasis: '35%',
                    },
                    [theme.breakpoints.down('sm')]: {
                        display: showFiltersMobile ? 'block' : 'none',
                        position: 'absolute',
                        top: 0,
                        margin: 0,
                        background: 'white',
                        zIndex: 1100,
                        height: 'calc(100vh - 144px)',
                        overflowY: 'scroll',
                    },
                }}
            >
                <Dialog
                    open={modalOpen ?? false}
                    onClose={() => setModalOpen(false)}
                    aria-labelledby="view care team"
                    aria-describedby="viewing care team"
                    fullScreen={isMobile}
                    sx={{
                        '.MuiPaper-root': {
                            margin: '15px 15px 0px 15px',
                            padding: 15,
                            flexBasis: '25%',
                            overflowX: 'hidden',
                            '@media (max-width: 1280px)': {
                                flexBasis: '35%',
                            },
                            '@media (max-width: 600px)': {
                                display: showFiltersMobile ? 'block' : 'none',
                                position: 'absolute',
                                top: 0,
                                margin: 0,
                                background: 'white',
                                zIndex: 1100,
                                height: 'calc(100vh - 144px)', //  accounts for the 2 bottom navs
                                overflowY: 'scroll',
                            },
                        },
                        '& .MuiDialog-paper': {
                            // stated classname as in Mui docs
                            width: '100%',
                            maxWidth: 736,
                            minHeight: 400,
                            padding: 30,
                        },
                    }}
                >
                    <FilterDialogContents
                        isVisible={modalOpen ?? false}
                        onCloseClicked={() => setModalOpen(false)}
                        unattestedActivities={unattestedActivities}
                        archivedActivities={archivedActivities}
                        supportTeam={supportTeam}
                    />
                </Dialog>
                <div>
                    <MobileFilterLayout
                        isMobile={isMobile}
                        showFiltersMobile={showFiltersMobile}
                        submitFilters={handleSubmitFilters}
                        toggleMobileFilters={toggleMobileFilters}
                        handleClearFilters={handleClearFilters}
                    >
                        <Button
                            style={{ width: '100%', display: 'flex', alignItems: 'center' }}
                            sx={{ margin: 0, paddingLeft: 6 }}
                            onClick={() => setModalOpen(true)}
                        >
                            <NotificationsIcon
                                sx={{ marginRight: 8, color: theme.palette.primary.light }}
                            />
                            <Typography
                                sx={{
                                    color: (theme) => theme.palette.primary.light,
                                    display: 'inline',
                                    fontWeight: 600,
                                }}
                            >
                                {unattestedCount}
                            </Typography>
                            &nbsp;{maybePluralize(unattestedCount, 'Notification')} To Review
                        </Button>
                        {filterEntries.map(([key, value]) => (
                            <Fragment key={key}>
                                <Divider />
                                <Accordion
                                    key={key}
                                    sx={{
                                        boxShadow: 'none',
                                        background: 'transparent',
                                        '&:before': {
                                            backgroundColor: 'transparent',
                                        },
                                        '&.Mui-expanded': {
                                            margin: 0,
                                        },
                                    }}
                                >
                                    <AccordionSummary
                                        sx={{
                                            minHeight: 15,
                                            marginBottom: 8,
                                            marginTop: 8,
                                            padding: 0,
                                            '&.MuiExpanded': {
                                                minHeight: 15,
                                                padding: 0,
                                                marginRight: 0,
                                            },
                                            '& .MuiAccordionSummary-expandIcon': {
                                                padding: 0,
                                                marginRight: 0,
                                            },
                                            '& .MuiIconButton-edgeEnd': {
                                                padding: 0,
                                                marginRight: 0,
                                            },
                                            '& > .Mui-expanded': {
                                                padding: 0,
                                                minHeight: 15,
                                                margin: 0,
                                            },
                                            '& > .MuiAccordionSummary-content': {
                                                margin: 0,
                                                alignItems: 'center',
                                            },
                                        }}
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <h4 style={{ margin: 0, paddingLeft: 6 }}>
                                            {value[0]?.type_display || 'Nothing to filter'}
                                        </h4>
                                        {getSelectedFiltersCount(value) && (
                                            <Chip
                                                sx={{
                                                    marginLeft: 8,
                                                    height: 'auto',
                                                    '& .MuiChip-label': {
                                                        padding: (theme) => theme.spacing(1, 4),
                                                    },
                                                }}
                                                color="primary"
                                                label={getSelectedFiltersCount(value)}
                                            />
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 4,
                                        }}
                                    >
                                        <>
                                            <Input
                                                onChange={handleChange}
                                                value={searchValue[key] ?? ''}
                                                sx={{
                                                    backgroundColor: '#fff',
                                                    width: '100%',
                                                    borderRadius: 4,
                                                    '&&:before': {
                                                        display: 'none',
                                                    },
                                                    '&&:after': {
                                                        display: 'none',
                                                    },
                                                    '@media (max-width: 600px)': {
                                                        backgroundColor: '#003f9d3b',
                                                    },
                                                }}
                                                name={key}
                                                startAdornment={
                                                    <SearchIcon
                                                        sx={{
                                                            fontSize: 16,
                                                            margin: '0 8px',
                                                            color: (theme) =>
                                                                theme.palette.text.secondary,
                                                        }}
                                                    />
                                                }
                                                placeholder={`Find ${
                                                    value[0]?.type_display || 'Nothing to filter'
                                                } `}
                                            />
                                            {value.map((filter) => (
                                                <FormControlLabel
                                                    key={filter.id}
                                                    sx={{
                                                        fontSize: 16,
                                                        flexBasis: '100%',
                                                        '& > span': {
                                                            fontSize: 'inherit',
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 15,
                                                            },
                                                        },
                                                    }}
                                                    aria-label="Acknowledge"
                                                    onClick={(event) =>
                                                        handleFilterClick(event, filter)
                                                    }
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                selectedFilters.findIndex(
                                                                    (f) =>
                                                                        f.type === filter.type &&
                                                                        f.id === filter.id,
                                                                ) > -1
                                                            }
                                                        />
                                                    }
                                                    label={
                                                        <FilterLabel
                                                            name={filter.name}
                                                            image={filter.image_url}
                                                        />
                                                    }
                                                />
                                            ))}
                                        </>
                                    </AccordionDetails>
                                </Accordion>
                            </Fragment>
                        ))}
                    </MobileFilterLayout>
                </div>
            </Paper>
        </>
    );
};

export { Filters };
