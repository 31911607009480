import { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMovementAttributes, loadAttributes } from 'redux/reducers/attributes';
import { authSelector } from 'redux/reducers/auth';
import {
    addMedia,
    getMedia,
    getPartialUploadList,
    removeMedia,
    uploadEncodingOptionsSelected,
    uploadFile,
} from 'redux/reducers/media';
import { createMovement } from 'redux/reducers/movements';
import { useAppDispatch } from 'redux/store';
import {
    AttributeValue,
    EntityType,
    EXIT_TEXT,
    Movement,
    MovementType,
    PartialUploadList,
    SAVE_INFORMATION_TEXT,
    SAVE_TEXT,
    UNSAVED_CHANGES_TEXT,
} from 'utils';
import { confirmViaDialog } from './ConfirmationDialog';
import { EncodingOptionsPayload } from '../../app.types';
import { CreateMovementForm } from 'components/Forms';
import FormModal from './FormModal';

interface CreateMovementModalProps {
    /** open Whether or not the movement modal is open */
    open: boolean;
    /** Callback when the movement modal is closed */
    onClose: (movement: Movement | null) => void;

    defaultName?: string | null;

    defaultOrganization?: string | null;
}

export default function CreateMovementModal({
    open,
    onClose,
    defaultName = '',
    defaultOrganization = null,
}: CreateMovementModalProps): ReactElement {
    const dispatch = useAppDispatch();

    const data = useSelector(getMovementAttributes);

    const { currentUser } = useSelector(authSelector);

    const [disabled, setDisabled] = useState(true);

    const uploads = useSelector(getPartialUploadList);
    const media = useSelector(getMedia);

    const [loading, setLoading] = useState<boolean>(false);

    const [attributeValues, setAttributeValues] = useState<AttributeValue[]>([]);

    const formRef = useRef<FormikProps<FormikValues>>(null);

    const [uuid, setUuid] = useState<string | undefined>();

    const filteredUploads = Object.keys(uploads).reduce((newObject, key) => {
        if (uploads[key].entityId === uuid) {
            newObject[key] = uploads[key];
        }
        return newObject;
    }, {} as PartialUploadList);

    useEffect(() => {
        setUuid(uuidv4());
    }, [open]);

    useEffect(() => {
        dispatch(loadAttributes());
    }, [dispatch]);

    const onButtonClicked = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const onChange = (isValid: boolean) => {
        setDisabled(!isValid);
    };

    const onFileSelected = (file: File): void => {
        if (currentUser && uuid) {
            dispatch(uploadFile(currentUser.accessToken, file, EntityType.MOVEMENT, uuid));
        }
    };

    const onEncodingOptionsSelected = (fileName: string, options: EncodingOptionsPayload): void => {
        dispatch(uploadEncodingOptionsSelected({ fileName, encodingOptions: options }));
    };

    const onCancel = async () => {
        const values = formRef.current?.values;
        if (formRef.current?.dirty || values?.name || attributeValues.length > 0) {
            const res = await confirmViaDialog({
                confirmation: {
                    title: UNSAVED_CHANGES_TEXT,
                    message: SAVE_INFORMATION_TEXT,
                    cancelButtonText: EXIT_TEXT,
                    okButtonText: SAVE_TEXT,
                    onCancelPressed: () => onClose(null),
                },
            });
            res && onButtonClicked();
        } else onClose(null);
    };

    const onSubmit = async (values: FormikValues, { setErrors }: FormikHelpers<{}>) => {
        setLoading(true);
        const result = await dispatch(
            createMovement({
                name: values.name,
                description: values.description,
                movementType: MovementType.STRENGTH, // default to strength
                organization: values.organization,
                attributes: attributeValues,
                movement_presets: values.movement_presets,
                cover_asset_uuid: values.cover_asset_uuid || null,
                uuid,
            }),
        );

        // if the create movement action was successfull, attach the media
        if (createMovement.fulfilled.match(result)) {
            setAttributeValues([]);
            setLoading(false);
            onClose(result.payload);
        } else if (createMovement.rejected.match(result)) {
            setLoading(false);
            setErrors(result.payload?.errors ?? {});
        }
    };

    return (
        <FormModal
            handleButtonClicked={onButtonClicked}
            disabled={disabled || loading}
            loading={loading}
            handleClose={onCancel}
            open={open}
            title="New Movement"
        >
            {currentUser && (
                <CreateMovementForm
                    accessToken={currentUser.accessToken}
                    formRef={formRef}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    onFileSelected={onFileSelected}
                    onEncodingOptionsSelected={onEncodingOptionsSelected}
                    onMediaAdded={(media) => {
                        dispatch(addMedia(media));
                    }}
                    onMediaDeleteClicked={(media) => {
                        dispatch(removeMedia(media));
                    }}
                    uploads={filteredUploads}
                    media={media}
                    onAttributeValuesChanged={setAttributeValues}
                    attributes={data}
                    defaultName={defaultName}
                    defaultOrganization={defaultOrganization}
                />
            )}
        </FormModal>
    );
}
