import { Box, Divider, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import useNavigationTabConfig, { NavigationTabConfigProps } from './navigationconfig';
import { useNavigationContext } from './NavigationContext';

const SecondaryNavigation = () => {
    const { push } = useHistory();
    const theme = useTheme();
    const navigationTabConfig = useNavigationTabConfig();

    const { setSecondaryNavigation, secondaryNavigation, primaryNavigation } =
        useNavigationContext();

    const getSecondaryNavigation = (): NavigationTabConfigProps[] =>
        navigationTabConfig.find(
            ({ value }: NavigationTabConfigProps) => value == primaryNavigation,
        )?.secondaryNavigationTabs || [];

    if (!secondaryNavigation || getSecondaryNavigation().length == 0) {
        return null;
    }

    // When navigation context is setting secondary navigation state
    // There might be a case for split second where navigation context is updated and secondary navigation is still updating
    // and navigation is not part of the secondary navigation yet
    // To avoid console throwing an arror, we return null so material ui's tab does not have an invalid value error
    if (
        getSecondaryNavigation &&
        !getSecondaryNavigation()?.some((sn) => sn.value == secondaryNavigation)
    ) {
        return null;
    }

    return (
        <>
            <Divider
                sx={{
                    backgroundColor: '#ffffff29',
                }}
            />
            <Toolbar style={{ justifyContent: 'space-between' }} variant="dense" disableGutters>
                <Box
                    width="100%"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    sx={{
                        backgroundColor: theme.palette.common.white,
                    }}
                >
                    <Tabs indicatorColor="primary" value={secondaryNavigation}>
                        {getSecondaryNavigation().map(
                            (navigationTab: NavigationTabConfigProps, index: number) => (
                                <Tab
                                    key={`navigation-tab-${index}`}
                                    value={navigationTab.value}
                                    sx={{
                                        width: 'inherit',
                                        minWidth: 'inherit',
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        '&.Mui-selected': {
                                            color: theme.palette.primary.main,
                                        },
                                        '& .MuiTypography-root': {
                                            fontWeight: 600,
                                        },
                                    }}
                                    label={
                                        <Typography variant="caption">
                                            {navigationTab.label}
                                        </Typography>
                                    }
                                    onClick={() => {
                                        push(navigationTab.path);
                                        setSecondaryNavigation(navigationTab.value);
                                    }}
                                />
                            ),
                        )}
                    </Tabs>
                </Box>
            </Toolbar>
        </>
    );
};
export default SecondaryNavigation;
