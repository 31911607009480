import { createTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { OpenSans } from './fonts';

declare module '@mui/system' {
    interface DefaultTheme extends Theme {}
}

const defaultTheme = createTheme();

const DefaultTheme = createTheme({
    palette: {
        primary: {
            main: '#00196E',
            dark: '#00196E',
            light: '#003E9D',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
        },
        secondary: {
            main: '#C6960E',
            light: '#FDC649',
            dark: '#916900',
        },
        backgroundColor: {
            grey: '#FAFAFA',
            main: '#F8F9FA',
            hover: '#F1F3F4',
            paper: '#FFFFFF',
            action: '#F7F7F7',
            lightGrey: '#EBF0F7',
            quickSilver: '#9E9E9E',
            whiteSmoke: '#F5F5F5',
            scrollBar: 'rgba(0, 0, 0, 0.3)',
            divider: 'rgba(0, 0, 0, 0.08)',
            topBar: 'linear-gradient(0deg, rgba(0, 62, 157, 0.08), rgba(0, 62, 157, 0.08)), #FFFFFF',
            freeSpeechBlue: '#5068CF',
            brightWhite: '#FFFFFF',
        },
    },
    spacing: 2,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@font-face': [...OpenSans],
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F8F9FA',
                    '&:hover': {
                        backgroundColor: '#F1F3F4',
                    },
                    '&.Mui-focused': {
                        backgroundColor: '#F1F3F4',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    '&.Mui-expanded': {
                        margin: '10px 0px',
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: ['Open Sans', 'Roboto', 'Inter'].join(','),
        h1: {
            fontWeight: 600,
            fontSize: 96,
        },
        h2: {
            fontSize: '2rem',
            '@media (min-width:600px)': {
                fontSize: '2.2rem',
            },
            [defaultTheme.breakpoints.up('md')]: {
                fontSize: '3rem',
            },
        },
        h3: {
            fontWeight: 600,
            fontSize: 48,
        },
        h4: {
            fontWeight: 600,
            fontSize: 34,
        },
        h5: {
            fontWeight: 600,
            fontSize: 24,
        },
        h6: {
            fontWeight: 600,
            fontSize: 20,
        },
        body1: {
            fontSize: 16,

            letterSpacing: 0.15,
        },
        body2: {
            fontSize: 14,

            letterSpacing: 0.15,
        },

        caption: {
            fontSize: 12,

            letterSpacing: 0.4,
        },
        overline: {
            textTransform: 'uppercase',
            fontSize: 12,

            letterSpacing: 0.5,
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: 16,

            letterSpacing: 0.15,
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: 14,

            letterSpacing: 0.1,
        },
    },
});

export default DefaultTheme;
