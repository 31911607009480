import { Organization } from '../../utils';
import { DateRangeValue } from '../../app.types';
import { TimeDimensionGranularity } from '@cubejs-client/core';

export type ExplorationType =
    | 'crops'
    | 'gps_summary_leaderboard'
    | 'gps_summary_athlete_profile'
    | 'gps_summary_athlete_progress'
    | 'gps_summary_acwr';

// ExplorationTypeMap
export const ExplorationTypeMap = new Map<ExplorationType, string>([
    ['crops', 'Crops'],
    ['gps_summary_athlete_profile', 'Athlete Profile'],
    ['gps_summary_leaderboard', 'Athlete Leaderboard'],
    ['gps_summary_athlete_progress', 'Athlete Progress Or Exertion'],
    ['gps_summary_acwr', 'Acute To Chronic Workload Ratio'],
]);

export interface Exploration {
    uuid: string;
    type: ExplorationType;
    title: string;
    organization: Organization;
    updated_at: number;
    filters: ExplorationApiFilters;
    groups: string[];
    individuals: string[];
}

export type ExplorationFiltersYaxisKeyType = 'Velocity (mph)' | 'Cumulative Distance (yd)';
export type MeasureKey =
    | 'GpsSummary.maxSpeed'
    | 'GpsSummary.maxDistance'
    | 'GpsSummary.numberOfSprints'
    | 'GpsSummary.maxNumberOfSprints'
    | 'GpsSummary.totalDistance'
    | 'GpsSummary.acuteToChronicRatio'
    | 'GpsSummary.maxPlayerLoad'
    | 'GpsSummary.acuteLoad'
    | 'GpsSummary.chronicLoad'
    | 'GpsSummary.acuteLoadDistance'
    | 'GpsSummary.chronicLoadDistance'
    | 'GpsSummary.acuteToChronicRatioDistance'
    | 'GpsSummary.totalPlayerLoad';
export type DimensionKey = 'GpsSummary.athleteName' | 'GpsSummary.athleteProfilePhotoUrl';

export interface ExplorationApiFilters {
    range?: DateRangeValue;
    showOnSameGraph?: boolean;
    individuals?: string[];
    groups?: string[];
    cropLabels?: string[];
    tags?: string[];
    yAxisKey?: ExplorationFiltersYaxisKeyType;
    measures?: MeasureKey[];
    dimensions?: DimensionKey[];
    timeGrouping?: TimeDimensionGranularity;
    excludedCrops?: string[];
}

export interface ExplorationBaseFilterProperties {
    range: DateRangeValue;
    individuals: string[];
    groups: string[];
}

export interface ExplorationFilters extends ExplorationBaseFilterProperties {
    showOnSameGraph?: boolean;
    cropLabels?: string[];
    tags?: string[];
    yAxisKey?: ExplorationFiltersYaxisKeyType;
    measures?: MeasureKey[];
    dimensions?: DimensionKey[];
    timeGrouping?: TimeDimensionGranularity;
    excludedCrops?: string[];
}
