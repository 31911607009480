import { Box } from '@mui/material';
import TextEditorInput, { TextEditorInputProps } from 'components/Inputs/TextEditorInput';
import React, { FC } from 'react';

interface Props extends TextEditorInputProps {}

const QuestionDescriptionTextEditor: FC<React.PropsWithChildren<Props>> = ({ ...restProps }) => {
    return (
        <Box
            sx={{
                border: '1px solid rgba(0, 62, 157, 0.5)',
                borderRadius: 4,
                '& .MuiInputLabel-root': { display: 'none' },
                '& .rdw-editor-toolbar': {
                    borderBottom: '1px solid rgba(0, 62, 157, 0.5)',
                    padding: 0,
                    marginBottom: 0,
                },
                '& [class^="rdw-"][class$="-wrapper"]': {
                    background: 'none',
                    border: 'none',
                    margin: 0,
                    '& :hover': { boxShadow: 'none' },
                },
                '& [class^="rdw-"][class$="-dropdown"]': {
                    background: 'none',
                    border: 'none',
                    margin: 0,
                },
                '& .rdw-dropdown-selectedtext': { background: 'none', border: 'none', margin: 0 },
                '& .rdw-option-active': {
                    backgroundColor: 'rgba(0, 62, 157, 0.15)',
                    border: 'none',
                    margin: 0,
                    boxShadow: 'none',
                },
                '& .rdw-editor-main': {
                    backgroundColor: '#fff',
                    border: 'none',
                    borderRadius: '0 4px 4px 0',
                    padding: 8,
                    minHeight: 160,
                },
                '& .public-DraftStyleDefault-block': { color: '#00196E' },
                '& .public-DraftEditorPlaceholder-inner': { opacity: 0, visibility: 'none' },
            }}
        >
            <TextEditorInput {...restProps} />
        </Box>
    );
};

export default QuestionDescriptionTextEditor;
