import { Box, Chip, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { ChipChoice } from './MultiChipList';

interface Props {
    choices: Array<ChipChoice>;
    value: ChipChoice | null | undefined;
    onChange: (value: ChipChoice | null | undefined) => void;
}

function SingleChipList({ choices, value, onChange }: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDelete = () => () => {
        onChange(null);
        return true;
    };
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 4,
                    m: 0,
                }}
            >
                {value && (
                    <Chip
                        sx={{ marginRight: 2 }}
                        key={value.id}
                        label={value.label}
                        onDelete={!value.isDeleteable ? undefined : handleDelete()}
                        color={'primary'}
                    />
                )}
                {!value && (
                    <Chip
                        sx={{ paddingX: 2, paddingY: 1 }}
                        icon={<AddIcon fontSize={'small'} />}
                        onClick={handleClick}
                        label={'Choose Category'}
                    />
                )}
            </Box>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {choices.map((c) => (
                    <MenuItem onClick={() => onChange(c)} key={c.id}>
                        {c.label}
                    </MenuItem>
                ))}
                {choices.length === 0 && <MenuItem>No choices</MenuItem>}
            </Menu>
        </>
    );
}

export default SingleChipList;
