import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AdminRawGPSSessionSummary() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL automates Session Duration, Session Distance, Max Velocity, Number of
                        Sprints, Distance in Speed Zones, Time in Acceleration Zones, Speed in Time
                        Zones, Session Graph and much more so you can best Analyse Raw GPS Session
                        Summary Data.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/881446586?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Analysing Raw GPS Session Summary Data'}
                            description={''}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminRawGPSSessionSummary;
