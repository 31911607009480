import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<void>>;

const cancelPodTransferToAthlete = (
    organizationUuid: string,
    selectedPodUuid: string,
): ReturnType => {
    return Request.post(
        `organizations/${organizationUuid}/pods/${selectedPodUuid}/cancel-transfer`,
    );
};

export { cancelPodTransferToAthlete };
