import { Organization } from '../../utils';
import { Avatar, Box, MenuItem } from '@mui/material';
import React from 'react';

export const renderOrganizationMenuItem = (value: Organization) => {
    return (
        <MenuItem key={value.uuid} value={value.uuid}>
            <Box
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                }}
            >
                <Avatar
                    src={value.image_urls['avatar'] ?? ''}
                    alt={value.name}
                    variant="rounded"
                    sx={{ marginRight: 3 }}
                />
                {value.name}
            </Box>
        </MenuItem>
    );
};
