import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AdminSprintDatabase() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        <strong>Do you train athletes? </strong>
                        Create a group for athletes and share training and learning content with the
                        group rather than individually to each athlete. Then quickly scale that
                        group by inviting new athletes to the group using QR codes or invite links.{' '}
                        <br></br>
                    </Typography>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        <strong>Do you lead a team of coaches? </strong>
                        Create a group for coaches and share training and learning content with the
                        coaches group with view and copy permissions to allow the coaches to use the
                        content with their athletes.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/884083976?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Share Training and Learning Content with Groups'}
                            description={
                                'Quickly scale your organization by setting sharing appropriate content with a Group then inviting users to the Group.'
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminSprintDatabase;
