import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { ShareableInvite } from 'utils';

type ReturnType = Promise<AxiosResponse<ShareableInvite>>;

const validateInvite = (inviteId: string): ReturnType => {
    return Request.post(`/public/invites/${inviteId}/validate`);
};

export default validateInvite;
