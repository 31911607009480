import React from 'react';
import { InputBaseProps, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface LogSelectProps {
    options: Record<string, string>;
    onValueChanged: (newValue: string) => void;
}

const LogSelect: React.FC<React.PropsWithChildren<InputBaseProps & LogSelectProps>> = ({
    options,
    onValueChanged,
    ...props
}: InputBaseProps & LogSelectProps) => {
    return (
        <Select
            {...props}
            onChange={(e: SelectChangeEvent<any>) => {
                onValueChanged(e.target.value as string);
            }}
        >
            {Object.keys(options).map((key) => (
                <MenuItem value={key} key={key}>
                    {options[key]}
                </MenuItem>
            ))}
        </Select>
    );
};

export default LogSelect;
