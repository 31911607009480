import { Divider } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

const RowDivider = () => {
    const theme = useTheme();

    return (
        <Divider
            sx={{ width: '100%', height: '1px', background: theme.palette.backgroundColor.divider }}
        />
    );
};

export default RowDivider;
