import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Organization } from '../../utils';

type ReturnType = Promise<AxiosResponse<Organization>>;

const deleteOrganizationMedia = (
    organizationUuid: string | undefined,
    type: string,
): ReturnType => {
    return Request.post(`/organizations/${organizationUuid}/delete-media`, { type: type });
};

export default deleteOrganizationMedia;
