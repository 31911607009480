import { grey } from '@mui/material/colors';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import React from 'react';

export interface ClipStartTimeButtonGroupSelectProps {
    /** clipStartTime The start time of the clip */
    clipStartTime: number;
    /** onClipStartTimeSelected Callback for when the clip start time is selected */
    onClipStartTimeSelected: (clipStartTime: number) => void;
}

const ClipStartTimeButtonGroupSelect = (props: ClipStartTimeButtonGroupSelectProps) => {
    return (
        <Box sx={{ fontSize: 10, color: grey[600] }}>
            <Typography textAlign={'center'}>Video Delay:</Typography>
            <ButtonGroup
                disableElevation
                size={'small'}
                variant="outlined"
                aria-label="choose clip time"
            >
                {[0, 1, 2].map((i) => (
                    <Button
                        key={i}
                        onClick={() => props.onClipStartTimeSelected(i)}
                        variant={props.clipStartTime === i ? 'contained' : 'outlined'}
                    >
                        {i}
                    </Button>
                ))}
            </ButtonGroup>
        </Box>
    );
};

export default ClipStartTimeButtonGroupSelect;
