import { Button, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import React, { useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { Dashboard } from '../community.types';

interface Props {
    dashboards: Dashboard[];
    selectedDashboards: Dashboard[];
    onChange: (dashboards: Dashboard[]) => void;
}

function GroupDashboardsList({
    dashboards,
    selectedDashboards,
    onChange,
}: Props): React.ReactElement {
    const theme = useTheme();

    const onSelect = useCallback(
        (dashboard: Dashboard) => {
            const selected = [...selectedDashboards];

            const ix = selected.findIndex((s) => s.uuid === dashboard.uuid);

            if (ix > -1) {
                selected.splice(ix, 1);
            } else {
                selected.push(dashboard);
            }

            onChange(selected);
        },
        [selectedDashboards, onChange],
    );

    function renderItem(props: any) {
        const s = dashboards[props.index];
        return (
            <ListItem button key={props.index} style={props.style} onClick={() => onSelect(s)}>
                <ListItemIcon style={{ marginRight: 10 }}>
                    {selectedDashboards.findIndex((sess) => sess.uuid === s.uuid) > -1 ? (
                        <Button variant="contained" size={'small'} color="primary">
                            <CheckCircleOutlineIcon
                                style={{ color: theme.palette.secondary.main }}
                            />
                            &nbsp; Shared
                        </Button>
                    ) : (
                        <Button size={'small'}>
                            <RadioButtonUncheckedIcon />
                            &nbsp; Share
                        </Button>
                    )}
                </ListItemIcon>
                {selectedDashboards.findIndex((sess) => sess.uuid === s.uuid) > -1 ? (
                    <ListItemText primary={s.title} />
                ) : (
                    <ListItemText secondary={s.title} />
                )}
            </ListItem>
        );
    }

    return (
        <AutoSizer>
            {({ width, height }: { width: number; height: number }) => (
                <FixedSizeList
                    height={height}
                    width={width}
                    itemSize={48}
                    itemCount={dashboards.length}
                >
                    {renderItem}
                </FixedSizeList>
            )}
        </AutoSizer>
    );
}

export default GroupDashboardsList;
