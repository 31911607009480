import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Session } from 'utils';

type ReturnType = Promise<AxiosResponse<Session>>;

const reorderSessionMovements = (sessionId: string, movements: string[]): ReturnType => {
    return Request.post(`/workouts/sessions/${sessionId}/movements/reorder`, movements);
};

export default reorderSessionMovements;
