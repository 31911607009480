import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

const LoadingWithText = ({ text = 'Loading...' }) => (
    <Stack
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
        spacing={8}
    >
        <CircularProgress />
        <Typography variant="body2">{text}</Typography>
    </Stack>
);
export default LoadingWithText;
