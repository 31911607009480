import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<void>>;

const removeSharedUser = (params: { individualId: string; userId: string }): ReturnType => {
    return Request.delete(`/individuals/${params.individualId}/users/${params.userId}`);
};

export default removeSharedUser;
