import { Box, Chip, Menu, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

export interface ChipChoice {
    id: string;
    label: string;
    isDeleteable: boolean;
}

interface Props {
    choices: Array<ChipChoice>;
    value: Array<ChipChoice>;
    onChange: (value: Array<ChipChoice>) => void;
    label?: string;
}

function MultiChipList({ choices, value, onChange, label = 'Tag Filter' }: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDelete = (chipToDelete: ChipChoice) => () => {
        onChange(value.filter((v) => v.id !== chipToDelete.id));
        return true;
    };
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 4,
                    m: 0,
                }}
            >
                {value.map((chip) => {
                    return (
                        <Chip
                            sx={{ marginRight: 2 }}
                            key={chip.id}
                            label={chip.label}
                            onDelete={!chip.isDeleteable ? undefined : handleDelete(chip)}
                        />
                    );
                })}
                <Chip
                    sx={{ paddingX: 2, paddingY: 1 }}
                    icon={<AddIcon fontSize={'small'} />}
                    onClick={handleClick}
                    label={label}
                />
            </Box>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {choices.map((c) => (
                    <MenuItem onClick={() => onChange([...value, c])} key={c.id}>
                        {c.label}
                    </MenuItem>
                ))}
                {choices.length === 0 && <MenuItem>No choices</MenuItem>}
            </Menu>
        </>
    );
}

export default MultiChipList;
