import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEvents } from 'api';
import { keyBy } from 'lodash';
import { refactorBackendEventsToFullCalendarEvents } from 'utils';
import mapDimensionsToQsParams from 'utils/mapDimensionsToQsParams';
import { CalendarEvent, Dimensions, FullCalendarEvent } from 'utils/types';

import { RootState } from '..';

export interface CalendarEventState {
    byId: Record<string, FullCalendarEvent>;
    loading: boolean;
    error: string | null;
}

// actions
export const loadEvents = createAsyncThunk<
    { data: CalendarEvent[] },
    { filters?: Dimensions[]; startsAfter: string; endsBefore: string },
    { rejectValue: { message: string } }
>('calendarEvents/fetch', async (arg, { rejectWithValue }) => {
    const filters = mapDimensionsToQsParams(arg.filters);
    const dateRange = `filter[starts_after]=${arg.startsAfter}&filter[ends_before]=${arg.endsBefore}`;
    try {
        const { data } = await getEvents(`${dateRange}${filters ? `&${filters}` : ''}`);
        return { data };
    } catch {
        return rejectWithValue({ message: 'Failed to get calendar events' });
    }
});

// reducers
export const initialState: CalendarEventState = {
    byId: {},
    loading: true,
    error: null,
};

export const eventsSlice = createSlice({
    name: 'calendarEvents',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadEvents.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(loadEvents.fulfilled, (state, { payload }) => {
            state.byId = keyBy(
                refactorBackendEventsToFullCalendarEvents(payload.data),
                (mappedEvent) => {
                    return mappedEvent.id;
                },
            );
            state.loading = false;
            state.error = null;
        });
        addCase(loadEvents.rejected, (state, { payload }) => {
            state.byId = {};
            state.loading = false;
            state.error = payload?.message ?? 'Error loading calendar events';
        });
    },
});

// selectors
export const eventsSelector = (state: RootState) => state.calendarEvents.events;
