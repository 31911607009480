import { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { SimplePaginateResponse } from '../../../app.types';
import { Individual } from '../../../utils';
import { GpsSummary } from '../community.types';

export function validateGpsSessionFromFile(
    groupId: string,
    files: FileList,
): Promise<AxiosResponse<{ athletes: { [key: string]: Individual }; path: string }>> {
    const formData = new FormData();
    formData.append('file', files[0]);
    return Request.post(`/groups/${groupId}/gps-sessions/validate`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function processGpsSessionForGroup(
    groupId: string,
    payload: { path: string; identifiers: { identifier: string; uuid: string | null }[] },
): Promise<AxiosResponse<GpsSummary>> {
    return Request.post(`/groups/${groupId}/gps-sessions/process`, payload);
}

export function getGpsSessionsForGroup(
    groupId: string,
    params: { page: number; limit: number },
): Promise<AxiosResponse<SimplePaginateResponse<GpsSummary>>> {
    return Request.get(`/groups/${groupId}/gps-sessions`, {
        params,
    });
}

export function viewGpsSessionForGroup(
    groupId: string,
    sessionId: string,
): Promise<AxiosResponse<GpsSummary>> {
    return Request.get(`/groups/${groupId}/gps-sessions/${sessionId}`);
}

export function updateGpsSession(
    groupId: string,
    sessionId: string,
    payload: { local_session_date_time?: string; tag?: string },
): Promise<AxiosResponse<GpsSummary>> {
    return Request.put(`/groups/${groupId}/gps-sessions/${sessionId}`, payload);
}

export function deleteGpsSession(groupId: string, sessionId: string): Promise<AxiosResponse<void>> {
    return Request.delete(`/groups/${groupId}/gps-sessions/${sessionId}`);
}
