import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import { SvgIconProps, useMediaQuery, useTheme } from '@mui/material';
import { Group, Organization } from 'utils';
import Search from './Search';
import debounce from 'lodash/debounce';
import { getGroups } from '../../modules/community/api/groups.api';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface Props {
    onGroupSelected: (group: Group) => void;
    showLoadingOverlay?: boolean;
    buttonText?: string | React.ReactElement<SvgIconProps>;
    buttonVariant?: 'text' | 'contained' | 'outlined';
    buttonClass?: string;
    buttonComponent?: React.ElementType;
    disabled?: boolean;
    organization: Organization;
    buttonColor?: 'primary' | 'inherit' | 'secondary';
    createButtonText?: string;
    sx?: SxProps<Theme>;
}

const SearchGroup = ({
    onGroupSelected,
    showLoadingOverlay = false,
    buttonText = 'Log Session',
    buttonVariant = 'text',
    buttonClass = '',
    buttonComponent = 'button',
    buttonColor = 'primary',
    disabled = false,
    organization,
    createButtonText = 'Add Athlete',
    sx,
}: Props) => {
    const theme = useTheme();
    const breakPoint = useMediaQuery(theme.breakpoints.up('sm'));

    const [inputValue, setInputValue] = React.useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const debouncedSearchGroup = useMemo(
        () =>
            debounce(
                (value) =>
                    SearchGroups({
                        'filter[name]': value,
                    }),
                400,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const SearchGroups = (params = {}) => {
        setLoading(true);
        getGroups(
            organization ? { ...params, 'filter[organization]': organization.uuid } : params,
        ).then(function (result) {
            setfilteredGroupsList(result.data.data);
            setLoading(false);
        });
    };

    const handleInputChanged = (value: string) => {
        setInputValue(value);
        debouncedSearchGroup(value);
    };
    const handleClose = () => {
        setIsOpen(false);
    };

    const [filteredGroupsList, setfilteredGroupsList] = useState<Array<Group>>([]);

    return (
        <React.Fragment>
            <Button
                variant={buttonVariant}
                className={typeof buttonClass === 'string' ? buttonClass : undefined}
                sx={typeof buttonClass === 'object' ? buttonClass : sx}
                disabled={disabled}
                color={buttonColor}
                component={buttonComponent}
                aria-describedby={isOpen ? 'search-member-popover' : undefined}
                onClick={() => {
                    SearchGroups();
                    setIsOpen(true);
                }}
            >
                {buttonText}
            </Button>
            <Search
                isOpen={isOpen}
                breakPoint={breakPoint}
                inputValue={inputValue}
                handleClose={handleClose}
                setInputValue={handleInputChanged}
                isFiltered={!!inputValue.length}
                searchResults={filteredGroupsList.map((i) => ({
                    id: i.uuid,
                    title: i.name,
                    subtitle: '',
                    avatarUrl: i.image_urls.avatar ?? '',
                }))}
                onCreateClicked={() => {}}
                loading={loading}
                showOverlay={showLoadingOverlay}
                onResultSelected={(result) => {
                    setIsOpen(false);
                    onGroupSelected(
                        filteredGroupsList.find((i) => i.uuid === result.id) ??
                            filteredGroupsList[0],
                    );
                }}
                organization={organization}
                createButtonText={createButtonText}
                createButtonVisible={false}
                placeholderText={'Search for a group'}
            />
        </React.Fragment>
    );
};

export default SearchGroup;
