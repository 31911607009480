import NavigationLayout from '../../Layouts/NavigationLayout';
import MediaLibraryDashboard from './scenes/MediaLibraryDashboard';

export const mediaRoutes = [
    {
        exact: true,
        layout: NavigationLayout,
        path: '/media',
        component: MediaLibraryDashboard,
        private: true,
    },
];
