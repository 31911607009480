import { Filter } from '@cubejs-client/core';
import { OpenInNew } from '@mui/icons-material';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { Avatar, Box, Button, Paper, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridActionsCellItemProps,
    GridOverlay,
    GridRenderCellParams,
    GridRowParams,
    GridSortItem,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { cubejsApi } from '../../../app.functions';
import CustomDateRangePicker from '../../../components/FormControl/CustomDateRangePicker';
import { AbilityContext } from '../../../components/Functional/Can';
import CustomPagination from '../../../components/Functional/CustomPagination';
import CheckboxFilterList from '../../../components/Inputs/CheckboxFilterList';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import { useNonInitialEffect } from '../../../hooks';
import { GenericSubject } from '../../../utils/ability';
import DimensionFilterBuilder from '../components/DimensionFilterBuilder';

const GpsSummaryDashboard = () => {
    const [cubeRows, setCubeRows] = useState<any>([]);
    const [count, setCount] = useState(0);
    const [dateRangeValue, setDateRangeValue] = useState<DateRange<DateTime>>([null, null]);
    const [individualFilters, setIndividualFilters] = useState<{ [key: string]: any }>({});
    const [groupFilters, setGroupFilters] = useState<{ [key: string]: any }>({});
    const savedFilters = localStorage.getItem('gpsSummaryFilters');
    const [organizationFilters, setOrganizationFilters] = useState<string[]>([]);
    const ability = useContext(AbilityContext);

    const initialQueryOptions = savedFilters
        ? JSON.parse(savedFilters)
        : {
              sort: {},
              limit: 100,
              page: 1,
          };
    const [queryOptions, setQueryOptions] = useState<{
        sort?: { [key: string]: 'asc' | 'desc' };
        limit: number;
        page: number;
        filters?: {
            individual?: Filter;
            group?: Filter;
            organization?: Filter;
            before?: Filter;
            after?: Filter;
        };
    }>(initialQueryOptions);

    const [resetKey, setResetKey] = useState(0);
    const resetFilters = () => {
        setQueryOptions({
            sort: {},
            limit: 100,
            page: 1,
        });
        setDateRangeValue([null, null]);
        setOrganizationFilters([]);
        setIndividualFilters({});
        setGroupFilters({});
        localStorage.removeItem('gpsSummaryFilters');
        // Increment resetKey to force child components to re-render
        setResetKey((prevKey) => prevKey + 1);
    };

    useEffect(() => {
        localStorage.setItem('gpsSummaryFilters', JSON.stringify(queryOptions));
    }, [queryOptions]);

    const [isLoading, setIsLoading] = useState(false);
    const [debouncedQueryOptions] = useDebounce(queryOptions, 700);
    const { organizations } = useContext(OrganizationsContext);
    const { push } = useHistory();

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setQueryOptions((o) => ({
            ...o,
            sort: sortModel.reduce((result: { [key: string]: any }, item: GridSortItem) => {
                result[item.field] = item.sort;
                return result;
            }, {}),
        }));
    }, []);

    const handleIndividualsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                filters: {
                    ...queryOptions.filters,
                    individual:
                        value.length > 0
                            ? {
                                  member: 'DimIndividual.uuid',
                                  operator: 'equals',
                                  values: value,
                              }
                            : undefined,
                },
            });
        },
        [queryOptions],
    );

    const handleGroupsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                filters: {
                    ...queryOptions.filters,
                    group:
                        value.length > 0
                            ? {
                                  member: 'DimMember.groupUuid',
                                  operator: 'equals',
                                  values: value,
                              }
                            : undefined,
                },
            });
            setIndividualFilters((filters) => ({
                ...filters,
                'filter[group_uuid]': value.length > 0 ? value.join(',') : undefined,
            }));
        },
        [queryOptions],
    );

    useNonInitialEffect(() => {
        let before: Filter;
        let after: Filter;
        if (dateRangeValue[0]) {
            after = {
                member: 'DimDate.fullDate',
                operator: 'afterDate',
                values: [dateRangeValue[0]?.toISODate()],
            } as Filter;
        }
        if (dateRangeValue[1]) {
            before = {
                member: 'DimDate.fullDate',
                operator: 'beforeDate',
                values: [dateRangeValue[1]?.toISODate()],
            } as Filter;
        }
        setQueryOptions((options) => {
            return {
                ...options,
                filters: {
                    before: before,
                    after: after,
                },
            };
        });
    }, [dateRangeValue]);

    useEffect(() => {
        setIsLoading(true);

        cubejsApi
            .load({
                dimensions: [
                    'DimIndividual.name',
                    'DimIndividual.uuid',
                    'DimIndividual.managingUserUuid',
                    'DimOrganization.uuid',
                    'DimGroup.uuid',
                    'DimDate.fullDate',
                    'FactGpsSummaries.factGpsSummaryKey',
                    'FactGpsSummaries.dimSessionDateTime',
                    'FactGpsSummaries.dimDistance',
                    'FactGpsSummaries.dimIdentifier',
                    'FactGpsSummaries.dimMaxAcceleration',
                    'FactGpsSummaries.dimMaxSpeed',
                    'FactGpsSummaries.dimDistance',
                    'FactGpsSummaries.dimNumberOfAccelerationsOver2',
                    'FactGpsSummaries.dimNumberOfAccelerationsOver3',
                    'FactGpsSummaries.dimNumberOfAccelerationsOver4',
                    'FactGpsSummaries.dimNumberOfChangesOfDirection',
                    'FactGpsSummaries.dimNumberOfDecelerations',
                    'FactGpsSummaries.dimPlayerLoad',
                    'FactGpsSummaries.dimStepImbalance',
                    'FactGpsSummaries.uuid',
                ],
                ungrouped: true,
                limit: debouncedQueryOptions.limit,
                offset: (debouncedQueryOptions.page - 1) * debouncedQueryOptions.limit,
                filters: Object.values(debouncedQueryOptions.filters ?? {}).filter((f) => !!f),
                order: debouncedQueryOptions.sort,
            })
            .then((resultSet: any) => {
                setCubeRows(resultSet.tablePivot());
            })
            .catch(() => {})
            .finally(() => {
                setIsLoading(false);
            });
        setCount(0);
    }, [debouncedQueryOptions]);

    const getRowActions = useCallback(
        (params: GridRowParams) => {
            let actions: React.ReactElement<GridActionsCellItemProps>[] = [];
            const genericIndividual = {
                uuid: params.row['DimIndividual.uuid'],
                object: 'individual',
                organization_uuid: params.row['DimOrganization.uuid'],
                managing_user: params.row['DimIndividual.managingUserUuid']
                    ? { uuid: params.row['DimIndividual.managingUserUuid'] }
                    : null,
            } as GenericSubject;
            if (ability.can('individual:manage-logs', genericIndividual)) {
                actions.push(
                    <GridActionsCellItem
                        key={'view-button'}
                        icon={<OpenInNew />}
                        onClick={() => {
                            push(
                                `/community/groups/${params.row['DimGroup.uuid']}/gps-summary/${params.row['FactGpsSummaries.uuid']}`,
                            );
                        }}
                        label={'View'}
                    />,
                );
            }
            return actions;
        },
        [push, ability],
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'DimIndividual.name',
                headerName: 'Athlete',
                minWidth: 180,
                filterable: false,
                groupable: true,
            },
            {
                field: 'DimOrganization.uuid',
                headerName: 'Organization',
                width: 100,
                filterable: false,
                sortable: false,
                renderCell: (params: GridRenderCellParams<any, string>) => (
                    <Avatar
                        src={organizations.find((o) => o.uuid === params.value)?.logo_url ?? ''}
                        sx={{ width: 40, height: 40 }}
                    />
                ),
            },
            {
                field: 'DimDate.fullDate',
                headerName: 'Date',
                filterable: false,
                groupable: false,
                renderCell: (params: GridRenderCellParams<any, string>) => (
                    <>{moment(params.value).format('MM/DD/YYYY')}</>
                ),
            },
            {
                field: 'FactGpsSummaries.dimIdentifier',
                headerName: 'GPS ID',
                filterable: false,
                groupable: false,
                minWidth: 150,
            },
            {
                field: 'FactGpsSummaries.dimDistance',
                headerName: 'Distance',
                filterable: false,
                groupable: false,
                minWidth: 100,
            },
            {
                field: 'FactGpsSummaries.dimMaxAcceleration',
                headerName: 'Max Accel',
                filterable: false,
                groupable: false,
                minWidth: 100,
            },
            {
                field: 'FactGpsSummaries.dimMaxSpeed',
                headerName: 'Max Speed',
                filterable: false,
                groupable: false,
                minWidth: 100,
            },
            {
                field: 'FactGpsSummaries.dimNumberOfAccelerationsOver2',
                headerName: 'Accel > 2',
                filterable: false,
                groupable: false,
                minWidth: 100,
            },
            {
                field: 'FactGpsSummaries.dimNumberOfAccelerationsOver3',
                headerName: '# Accel > 3',
                filterable: false,
                groupable: false,
                minWidth: 100,
            },
            {
                field: 'FactGpsSummaries.dimNumberOfAccelerationsOver4',
                headerName: '# Accel > 4',
                filterable: false,
                groupable: false,
                minWidth: 100,
            },
            {
                field: 'FactGpsSummaries.dimNumberOfChangesOfDirection',
                headerName: '# COD',
                filterable: false,
                groupable: false,
                minWidth: 60,
            },
            {
                field: 'FactGpsSummaries.dimNumberOfDecelerations',
                headerName: '# Decel',
                filterable: false,
                groupable: false,
                minWidth: 80,
            },
            {
                field: 'FactGpsSummaries.dimPlayerLoad',
                headerName: 'Load',
                filterable: false,
                groupable: false,
                minWidth: 80,
            },
            {
                field: 'FactGpsSummaries.dimStepImbalance',
                headerName: 'Step Imbalance',
                filterable: false,
                groupable: false,
                minWidth: 100,
                renderCell: (params: GridRenderCellParams<any, number>) => {
                    // return as a percent
                    return <>{params.value ? (params.value * 100).toFixed(1) : '0'}%</>;
                },
            },
            {
                field: 'actions',
                headerName: 'View',
                type: 'actions',
                getActions: getRowActions,
            },
        ],
        [getRowActions, organizations],
    );
    const castIconStyle: React.CSSProperties = {
        animation: 'constantScale 3s infinite', // Apply the animation
        cursor: 'pointer',
    };
    const CustomNoRowsOverlay = () => {
        return (
            <GridOverlay>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>GPS Summary data comes from session summary imports.</Typography>
                    <Typography>
                        This is different than raw gps data which allows you in-depth analysis of a
                        single sprint.
                    </Typography>
                    <Typography>
                        GPS Session Summary data will be presented here once it is imported.
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography>
                            Learn more about this by clicking on the Learn Center at the top right
                            of your screen:{' '}
                        </Typography>
                        <div style={castIconStyle}>
                            <CastForEducationIcon sx={{ marginRight: 8, marginLeft: 9 }} />
                        </div>
                    </div>
                </div>
            </GridOverlay>
        );
    };
    const CustomNoDataOverlay = () => {
        return (
            <GridOverlay>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>
                        No Data found. Please reset filters to allow data to be presented.
                    </Typography>
                </div>
            </GridOverlay>
        );
    };

    return (
        <>
            <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ marginY: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <DimensionFilterBuilder
                            key={`groupFilter-${resetKey}`}
                            id={'filter-list-groups'}
                            resourcePath="groups"
                            buttonText="Group(s)"
                            onChange={handleGroupsFilterChanged}
                            otherFilters={groupFilters}
                        />
                    </Box>
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <DimensionFilterBuilder
                            key={`individualFilter-${resetKey}`} // Key added here
                            id={'filter-list-individuals'}
                            resourcePath="individuals"
                            buttonText="Athletes"
                            onChange={handleIndividualsFilterChanged}
                            otherFilters={individualFilters}
                        />
                    </Box>
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <CheckboxFilterList
                            buttonText={'Organizations'}
                            onChange={(v) => {
                                setOrganizationFilters(v);
                                setQueryOptions((f) => ({
                                    ...f,
                                    filters: {
                                        ...f.filters,
                                        organization:
                                            v.length > 0
                                                ? {
                                                      member: 'DimOrganization.uuid',
                                                      operator: 'equals',
                                                      values: v,
                                                  }
                                                : undefined,
                                    },
                                }));
                            }}
                            options={organizations.map((o) => ({
                                label: o.name,
                                value: o.uuid,
                                avatar_url: o.logo_url,
                            }))}
                            value={organizationFilters}
                        />
                    </Box>

                    <Box sx={{ padding: 2 }}>
                        <CustomDateRangePicker
                            value={dateRangeValue}
                            onChange={(newValue) => {
                                setDateRangeValue(newValue);
                            }}
                        />
                    </Box>
                    <Box sx={{ marginRight: 1, spacing: 1 }}>
                        <Button
                            onClick={resetFilters}
                            variant={
                                Object.values(queryOptions?.filters ?? {}).some((f) => !!f)
                                    ? 'contained'
                                    : 'outlined'
                            }
                            color="primary"
                        >
                            {Object.values(queryOptions?.filters ?? {}).some((f) => !!f)
                                ? 'Reset Filters'
                                : 'No Filters'}
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        width: '100%',
                        '& .super-app.normal': {
                            backgroundColor: '#FFFFFF',
                            color: '#252525',
                            fontWeight: '400',
                        },
                        '& .super-app.negative': {
                            backgroundColor: '#FFFFFF',
                            color: '#252525',
                            fontWeight: '400',
                        },
                        '& .super-app.positive': {
                            backgroundColor: '#FFFFFF',
                            color: '#252525',
                            fontWeight: '400',
                        },
                    }}
                >
                    <DataGridPremium
                        sx={{
                            backgroundColor: '#ffffff',
                            boxShadow: { lg: 3 },
                            borderRadius: { lg: 3 },
                            padding: { lg: 2 },
                            '.MuiDataGrid-virtualScrollerContent': {
                                height: '100%!important',
                            },
                        }}
                        getRowId={(row) => row['FactGpsSummaries.factGpsSummaryKey']}
                        autoHeight={false}
                        density="compact"
                        rows={cubeRows}
                        columns={columns}
                        initialState={{
                            pinnedColumns: { left: ['dimIndividual.name'] },
                            sorting: { sortModel: [{ field: 'DimDate.fullDate', sort: 'desc' }] },
                        }}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        loading={isLoading}
                        disableRowSelectionOnClick
                        slots={{
                            footer: CustomPagination,
                            loadingOverlay: CustomLoadingOverlay,
                            noRowsOverlay:
                                queryOptions?.filters &&
                                Object.values(queryOptions.filters).some((value) => value != null)
                                    ? CustomNoDataOverlay
                                    : CustomNoRowsOverlay,
                        }}
                        slotProps={{
                            footer: {
                                hasNext: true,
                                hasPrevious: queryOptions.page > 1,
                                onNext: () => setQueryOptions((o) => ({ ...o, page: o.page + 1 })),
                                onPrevious: () =>
                                    setQueryOptions((o) => ({ ...o, page: o.page - 1 })),
                                page: queryOptions.page,
                                total: count,
                            },
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
};
export default GpsSummaryDashboard;
