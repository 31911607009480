import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import ManageMultipleOrgs from '../media/ManageMultipleOrgs.png';
import OrgGroupFilter from '../media/OrgGroupFilter.png';
import OrgReportFilter from '../media/OrgReportFilter.png';
import GPSIntegration from '../media/GPSLearn.png';
import FullScreenDialogCard from '../components/HowToDialogCard';
import AIVideoAnalysie from 'images/AIVideoAnalysis.png';
import TemTrain from '../media/SimplifasterMcLloyd1080.png';
import SessionSummaryDrilldown from '../media/SessionSummaryDrilldown.png';
import SprintDatabaseView from '../media/SprintDatabaseView.png';
import SprintDrilldown from '../media/SprintDrilldown.png';

function HowWeDifferent() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Modern sports training professionals, athletes and coaches frequently juggle
                        affiliations with multiple teams and organizations. <br /> 1TUL allows you
                        to consolidate and manage all these connections in one centralized hub.{' '}
                        <br /> 1TUL empowers you to share your data with unlimited coaches and
                        athletes. This unmatched scalability, paired with our dedication to Speed
                        training management, makes 1TUL the premier choice for your
                        organization&apos;s needs.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Extreme Multi-organization Scalability.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}

                <FullScreenDialogCard
                    image={ManageMultipleOrgs}
                    title="Manage Multiple Organizations"
                    headline="Manage Multiple Organizations"
                    description="Manage multiple organizations with ease. Automatically organize all training data and content between organizations."
                />
                <FullScreenDialogCard
                    image={OrgGroupFilter}
                    title="Organization Group Filter"
                    headline="Organization Group Filter"
                    description="Quickly apply organizational filters to only view the groups under a specific organization."
                />
                <FullScreenDialogCard
                    image={OrgReportFilter}
                    title="Organization Report Filter"
                    headline="Organization Report Filter"
                    description="Quickly search, filter, and build dynamic organization specific performance reports to share within each organization."
                />
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Emphasis in Speed Management
                    </Typography>
                </Grid>

                <FullScreenDialogCard
                    image={GPSIntegration}
                    title="GPS Integration"
                    headline="GPS Integration"
                    description="GPS is the premier tool for speed management. We have direct integrations with Catapult, Playertek, and McLloyd GPS."
                />
                <FullScreenDialogCard
                    image={TemTrain}
                    title="1080 Motion"
                    headline="1080 Motion"
                    description="We have a direct API with 1080 motion that allows you all the capabilities of our GPS data processing."
                />
                <FullScreenDialogCard
                    image={AIVideoAnalysie}
                    title="AI Video Analysis"
                    headline="AI Video Analysis"
                    description="All you need is an iPhone. Upload a 60 fps video and we will process it and provide you with a report on the athlete's biometric sprint performance."
                />
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Advanced Drill-down Analysis
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={SprintDatabaseView}
                    title="Sprint Database View"
                    headline="Sprint Database View"
                    description="Quickly drill down from sprint database view to individual sprint view"
                />
                <FullScreenDialogCard
                    image={SprintDrilldown}
                    title="Individual Sprint View"
                    headline="Individual Sprint View"
                    description="Individual sprint view allows drill down into each instance speed in .1s increments."
                />
                <FullScreenDialogCard
                    image={SessionSummaryDrilldown}
                    title="Session Summary Drilldown"
                    headline="Session Summary Drilldown"
                    description="Quickly drill down to individual sprint view and manual sprint cropping from session summary."
                />
            </Grid>
        </>
    );
}

export default HowWeDifferent;
