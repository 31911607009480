import React from 'react';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface AuthScreenLayoutProps {
    children: React.ReactNode;
    sx?: object;
}

function AuthScreenLayout({ children, sx }: AuthScreenLayoutProps) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Box
            sx={{
                flex: 1,
                backgroundImage: 'linear-gradient(to bottom, #0066ff, #070A19)',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Grow in={true}>
                <Paper
                    elevation={matches ? 3 : 0}
                    sx={{
                        maxWidth: 394,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: theme.spacing(24, 16),
                        minHeight: {
                            xs: '100%',
                            sm: '90%',
                            md: null,
                        },
                        width: {
                            xs: '100%',
                            sm: 'calc(100% - 64px)',
                        },
                        ...sx,
                    }}
                >
                    {children}
                </Paper>
            </Grow>
        </Box>
    );
}

export default AuthScreenLayout;
