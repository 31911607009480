import React from 'react';
import { Stack, IconButton, TextField } from '@mui/material';

interface QuantityControlProps {
    value: number;
    onDecrement: () => void;
    onIncrement: () => void;
    disabled?: boolean;
    decrementDisabled?: boolean;
    incrementDisabled?: boolean;
    readOnly?: boolean;
}

const QuantityControl: React.FC<QuantityControlProps> = ({
    value,
    onDecrement,
    onIncrement,
    disabled = false,
    decrementDisabled = false,
    incrementDisabled = false,
    readOnly = false,
}) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={0}
            sx={{
                width: '120px',
                minWidth: '120px',
                justifyContent: 'space-between',
            }}
        >
            <IconButton
                size="small"
                onClick={onDecrement}
                disabled={disabled || decrementDisabled}
                sx={{
                    width: '32px',
                    height: '32px',
                    minWidth: '32px',
                    padding: 0,
                }}
            >
                −
            </IconButton>
            <TextField
                value={value}
                inputProps={{
                    readOnly: readOnly,
                    style: {
                        textAlign: 'center',
                        padding: '4px',
                        width: '40px',
                        minWidth: '40px',
                        fontSize: '0.8rem',
                    },
                }}
                size="small"
                sx={{
                    width: '40px',
                    minWidth: '40px',
                    '& .MuiOutlinedInput-root': {
                        padding: 0,
                    },
                }}
            />
            <IconButton
                size="small"
                onClick={onIncrement}
                disabled={disabled || incrementDisabled}
                sx={{
                    width: '32px',
                    height: '32px',
                    minWidth: '32px',
                    padding: 0,
                }}
            >
                +
            </IconButton>
        </Stack>
    );
};

export default QuantityControl;
