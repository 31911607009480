import React from 'react';
import { Image_Urls } from 'utils';

export interface ResponsiveImageProps {
    src: Image_Urls;
    style?: React.CSSProperties;
}

const ResponsiveImage = ({ src, style }: ResponsiveImageProps) => {
    const handleBreakpoint = (key: string) => {
        switch (key) {
            case '200':
                return '(max-width: 600px)';
            case '500':
                return '(max-width: 960px)';
            case 'avatar':
                return '(max-width: 0px)';
            case 'original':
                return '(max-width: 0px)';
            case '1000':
            default:
                return '';
        }
    };

    const formatSources = (src: Image_Urls) => {
        const arr = [];
        for (const [key, value] of Object.entries(src)) {
            arr.push({
                srcSet: value || '',
                media: handleBreakpoint(key),
            });
        }
        return arr;
    };

    return (
        <picture style={style}>
            {formatSources(src).map((source, index) => (
                <source key={`source-${index}`} media={source.media} srcSet={source.srcSet} />
            ))}
            <img src={src['500'] ?? ''} alt="Flowers" style={{ width: 'auto' }} />
        </picture>
    );
};

export default ResponsiveImage;
