import MuiTextField from '@mui/material/TextField';
import { fieldToTextField, TextFieldProps } from 'formik-mui';
import React from 'react';
import ReactInputMask from 'react-input-mask';

interface Props extends TextFieldProps {
    mask: string;
}

const MaskedInput = ({ mask, ...props }: Props) => {
    const { id, name, value, onChange, disabled, onBlur, ...textFieldProps } =
        fieldToTextField(props);

    return (
        <ReactInputMask
            id={id}
            name={name}
            mask={mask}
            //@ts-ignore
            value={value as string}
            onBlur={onBlur}
            onChange={onChange}
            disabled={disabled}
        >
            <MuiTextField {...textFieldProps} />
        </ReactInputMask>
    );
};

export default MaskedInput;
