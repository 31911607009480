import React, { ReactElement, useEffect, useState } from 'react';
import {
    Box,
    Container,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Snackbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { FeedActivityCard, SocialProfileCard, SocialProfileCardMobile } from '../components';
import { useSelector } from 'react-redux';
import { feedSelectors, myFeedActions, profileActions } from '../../../redux/reducers/social';
import { useAppDispatch } from '../../../redux/store';
import moment from 'moment';
import { CopyLink, CustomButton, TypographyTitleh6 } from '../../../components';
import { dayOfYear } from '../../../utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useQueryParams, withDefault } from 'use-query-params';
import { createEnumParam } from 'serialize-query-params/lib/params';
import { useHistory } from 'react-router-dom';

export default function MyFeed(): ReactElement {
    const { push } = useHistory();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useAppDispatch();
    const history = useHistory();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [routeQuery, setRouteQuery] = useQueryParams({
        status: withDefault(createEnumParam(['all', 'posted', 'unposted']), 'all'),
    });
    const { items, query, loading, loaded, hasAnotherPage } = useSelector(
        feedSelectors.myFeedSelector,
    );
    const { profile } = useSelector(feedSelectors.myProfileSelector);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        dispatch(profileActions.loadMyProfile());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(
            myFeedActions.loadMyFeed({
                query: {
                    status: routeQuery.status,
                    page: 1,
                },
            }),
        );
    }, [routeQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePostClicked = (item: any, index: number) => {
        dispatch(myFeedActions.postToFeed({ activity: item.activity_uuid, index }));
        setMessage('Posted To Your Social Feed');
        setOpen(true);
    };

    const handleSaveClicked = (bio: string) => {
        dispatch(myFeedActions.updateProfile({ bio }));
    };

    const onNextPage = () => {
        dispatch(
            myFeedActions.loadMyFeed({
                query: {
                    status: routeQuery.status,
                    page: query.page + 1,
                },
            }),
        );
    };

    const handleDeleteClicked = (item: any, index: number) => {
        dispatch(myFeedActions.deleteFromFeed({ activity: item.activity_uuid, index }));
        setMessage('Removed from your feed');
        setOpen(true);
    };

    const handleClose = (event: any, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Container>
            {mobileScreen && (
                <Box
                    sx={{ marginTop: 8, paddingLeft: 16, paddingRight: 16 }}
                    display="flex"
                    flexDirection="column"
                    paddingTop="16"
                >
                    <RadioGroup
                        value={routeQuery.status}
                        onChange={(target) => setRouteQuery({ status: target.currentTarget.value })}
                        row
                        aria-label="post status"
                        name="row-radio-buttons-group"
                        color="primary"
                    >
                        <FormControlLabel value="unposted" control={<Radio />} label="Unposted" />
                        <FormControlLabel value="posted" control={<Radio />} label="Posted" />
                        <FormControlLabel value="all" control={<Radio />} label="All" />
                    </RadioGroup>
                </Box>
            )}
            {!mobileScreen && (
                <Box
                    sx={{
                        width: '100%',
                        marginTop: 36,
                        marginBottom: 8,
                        paddingLeft: 16,
                        paddingRight: 16,
                    }}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingTop="16"
                >
                    <CustomButton
                        variant={'contained'}
                        color={'primary'}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.goBack()}
                    >
                        Back
                    </CustomButton>
                    <RadioGroup
                        value={routeQuery.status}
                        onChange={(target) => setRouteQuery({ status: target.currentTarget.value })}
                        row
                        aria-label="post status"
                        name="row-radio-buttons-group"
                        color="primary"
                    >
                        <FormControlLabel value="unposted" control={<Radio />} label="Unposted" />
                        <FormControlLabel value="posted" control={<Radio />} label="Posted" />
                        <FormControlLabel value="all" control={<Radio />} label="All" />
                    </RadioGroup>
                </Box>
            )}
            <Grid
                sx={{ marginTop: 8, paddingLeft: 16, paddingRight: 16 }}
                container
                spacing={3}
                direction={isMobile ? 'column' : 'row'}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    sx={
                        isMobile
                            ? {
                                  right: 0,
                                  height: 150,
                              }
                            : {
                                  right: 0,
                                  height: 150,
                                  marginTop: 16,
                              }
                    }
                >
                    {profile && isMobile && (
                        <SocialProfileCardMobile
                            bio={profile.bio ?? ''}
                            user={profile}
                            onSave={(description: string) => handleSaveClicked(description)}
                            isDescriptionEditable
                            isMyProfile
                        />
                    )}
                    {profile && !isMobile && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            style={{ width: '100%', maxWidth: '360px', overflow: 'hidden' }}
                        >
                            <SocialProfileCard
                                bio={profile.bio ?? ''}
                                slug={profile.slug}
                                user={profile}
                                onSave={(description: string) => handleSaveClicked(description)}
                                isDescriptionEditable
                                isMyProfile
                            />
                            <Box>
                                <CopyLink
                                    link={`${window.location.host}/@${profile?.slug}`}
                                    onCopyToClipboard={() => {}}
                                />
                            </Box>
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12} sm={6} md={8} lg={8}>
                    {items.length === 0 && loaded && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="h6" color="primary">
                                NO POSTS
                            </Typography>
                        </Box>
                    )}
                    {items.length > 0 && loaded && (
                        <Grid container>
                            {items.map((item: any, index: any) => (
                                <Grid
                                    key={index}
                                    item
                                    sx={{ width: '100%', padding: '16px 10px 10px 10px' }}
                                >
                                    {index === 0 ||
                                    (index !== 0 &&
                                        items[index - 1] &&
                                        dayOfYear(items[index - 1].created) !==
                                            dayOfYear(item.created)) ? (
                                        <TypographyTitleh6
                                            color="primary"
                                            style={{ paddingBottom: '16px' }}
                                        >
                                            {moment(item.created * 1000)
                                                .local()
                                                .format('MMMM DD, YYYY')}
                                        </TypographyTitleh6>
                                    ) : (
                                        ''
                                    )}
                                    <FeedActivityCard
                                        initialExpandedState={false}
                                        sessionTitle={item.session_name ?? ''}
                                        sessionCoverPhoto={item.session_cover_photo ?? ''}
                                        logs={item.logs}
                                        title={`${item.individual_name} completed ${item.session_name}`}
                                        subheader={moment(item.date * 1000).calendar()}
                                        description={item.session_description ?? ''}
                                        onPostClicked={() => handlePostClicked(item, index)}
                                        onDeleteClicked={() => handleDeleteClicked(item, index)}
                                        onAvatarClicked={() => push(`/@${item.user_slug}`)}
                                        isPublic={item.public}
                                        avatar={item.profile_photo[200]}
                                        avatarName={item.user_full_name}
                                    />
                                </Grid>
                            ))}
                            <Grid item sx={{ width: '100%', padding: '16px 10px 10px 10px' }}>
                                <CustomButton
                                    style={{ marginBottom: '80px' }}
                                    disabled={!hasAnotherPage || loading}
                                    onClick={onNextPage}
                                >
                                    view more
                                </CustomButton>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Snackbar
                autoHideDuration={3000}
                message={message}
                open={open}
                onClose={handleClose}
            ></Snackbar>
        </Container>
    );
}
