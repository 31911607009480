import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Drawer,
    IconButton,
    LinearProgress,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { getTags } from '../../../api/tags.api';
import { Tag } from '../../../app.types';
import { TypographyTitleh6 } from '../../../components';
import { createSavedFilter } from '../api/savedFilters.api';
import { mapTagIntoFreeSoloOption } from '../community.func';
import { CommunityReportFilters, SavedFilter, SavedFilterType } from '../community.types';
import FreeSoloCreateOptionDialog, {
    FreeSoloOption,
} from '../components/FreeSoloCreateOptionDialog';

interface Props {
    fullScreen: boolean;
    open: boolean;
    onClose: () => void;
    onSaved: (filter: SavedFilter) => void;
    onSavedError: () => void;
    organizationId: string;
    filters: CommunityReportFilters;
    savedFilterType: SavedFilterType;
}

interface FormValues {
    title: string;
    short_description: string;
    tag: FreeSoloOption | undefined;
}

const validationSchema = yup.object({
    title: yup.string().required('Title is required'),
    short_description: yup.string(),
    // You can add more validation for 'tag' if needed
});

function CommunityReportsCreateSavedFilterDrawer({
    fullScreen,
    open,
    onClose,
    onSaved,
    onSavedError,
    organizationId,
    filters,
    savedFilterType,
}: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState<Tag[]>([]);

    useEffect(() => {
        getTags({ 'filter[type]': 'reports' })
            .then((response) => {
                setTags(response.data);
            })
            .catch(() => {
                /* handle errors */
            });
    }, []);

    const formik = useFormik<FormValues>({
        initialValues: {
            title: '',
            short_description: '',
            tag: undefined, // Adjust based on your needs
        },
        validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            createSavedFilter({
                title: values.title,
                short_description: values.short_description,
                type: savedFilterType,
                filters: filters,
                organization: organizationId,
                tag: values.tag?.id ?? null,
            })
                .then(({ data }) => {
                    setLoading(false);
                    onSaved(data);
                })
                .catch(() => {
                    setLoading(false);
                    onSavedError();
                });
        },
    });

    return (
        <Drawer
            PaperProps={{ style: { width: fullScreen ? '100%' : '60%' } }}
            anchor={'right'}
            open={open}
            onClose={onClose}
        >
            <FormikProvider value={formik}>
                <Form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        overflowY: 'scroll',
                    }}
                >
                    {loading && <LinearProgress />}
                    <Box
                        display="flex"
                        alignItems={'center'}
                        justifyContent="space-between"
                        style={{ borderBottom: '1px solid grey', padding: 20 }}
                    >
                        <TypographyTitleh6
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            Save Chart
                        </TypographyTitleh6>
                        <IconButton onClick={onClose} aria-label="close" size="medium">
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Stack sx={{ p: 8, flex: 1, borderBottom: '1px solid grey' }} spacing={4}>
                        <Typography variant={'body1'} style={{ marginBottom: 8 }}>
                            Give this chart a title and description so you can easily find it later
                        </Typography>
                        <TextField
                            fullWidth
                            name="title"
                            label="Title"
                            variant="outlined"
                            placeholder="e.g Average Number Of Push Ups For Matthew"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                        />
                        <TextField
                            fullWidth
                            name="short_description"
                            label="Short description (optional)"
                            variant="outlined"
                            placeholder="e.g Shows the total push ups for Matthew for the last 30 days"
                            value={formik.values.short_description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.short_description &&
                                Boolean(formik.errors.short_description)
                            }
                            helperText={
                                formik.touched.short_description && formik.errors.short_description
                            }
                        />
                        <FreeSoloCreateOptionDialog
                            placeholder="Choose category (optional, for finding later)"
                            value={formik.values.tag ? formik.values.tag : null}
                            onChange={(v) => formik.setFieldValue('tag', v)}
                            organization={organizationId}
                            choices={tags.map(mapTagIntoFreeSoloOption)}
                            onTagCreated={() => {}}
                            createProps={{ type: 'reports' }}
                        />
                    </Stack>

                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                        p={8}
                        style={{ flexShrink: 1 }}
                    >
                        <Button variant={'outlined'} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            disabled={loading}
                            variant={'contained'}
                            color={'primary'}
                            type={'submit'}
                        >
                            Save
                        </Button>
                    </Box>
                </Form>
            </FormikProvider>
        </Drawer>
    );
}

export default CommunityReportsCreateSavedFilterDrawer;

// Assuming you are using ReactDOM to render your app
// ReactDOM.render(<App />, document.getElementById('root'));
