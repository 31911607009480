import { AxiosResponse } from 'axios';
import { Request } from 'api';

type ReturnType = Promise<AxiosResponse<void>>;

const follow = (user: string): ReturnType => {
    return Request.post(`/social/follow`, {
        user,
    });
};

export default follow;
