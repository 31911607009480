export const ROWS_PER_TABLE_PAGE = 10;
export const DATE_FORMAT = 'YYYY-MM-DD';
export const LIST_ITEMS_THIRTY = 30;
export const MEMBER_TYPES = {
    COACH: 'Coach',
    ATHLETE: 'Athlete',
    TRAINER: 'Trainer',
    FAMILY_MEMBER: 'Family Member',
    OTHER: 'Other',
    MEMBER_MANAGER: 'Member Manager',
};
export const HITS_PER_PAGE = 100;
export const CALENDAR_VIEWS = [
    {
        label: 'Month',
        value: 'dayGridMonth',
    },
    {
        label: 'Week',
        value: 'timeGridWeek',
    },
    {
        label: 'Day',
        value: 'timeGridDay',
    },
];

export const CALENDAR_COMMANDS = {
    TODAY: 'today',
    NEXT: 'next',
    PREV: 'prev',
    CHANGE_VIEW: 'change view',
    GO_TO_FIRST_MONTH: 'go to first month',
};
export const RECENET_SEARCHES_LENGTH = 5;
export const RECENT_SEARCHES_LOCAL_STORAGE_TAG = '5tul_recent_searches';
export const UNSAVED_CHANGES_TEXT = 'You have unsaved changes';
export const SAVE_INFORMATION_TEXT = 'Save your information before leaving?';
export const EXIT_TEXT = 'Exit';
export const SAVE_TEXT = 'Save & Exit';
export const ASSESSMENT_FILTER_OPTIONS = [
    {
        name: 'All',
        value: 'all',
    },
    {
        name: 'Sports Specific',
        value: 'sport-specific',
    },
    {
        name: 'Athletic Training',
        value: 'athletic-training',
    },
    {
        name: 'Learning Assessments',
        value: 'learning-assessments',
    },
];
export const EMOJI_LIST: string[] = [
    '✅',
    '❌',
    '🦑',
    '👀',
    '⏲️',
    '🧠',
    '💓',
    '🔥',
    '💣',
    '💯',
    '⬆',
    '⬇',
];
