import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';

const TypographySubtitle = styled(Typography)(({ theme, style }) => ({
    fontWeight: 'light',
    color: theme.palette.text.secondary,
    ...style,
}));

export default TypographySubtitle;
