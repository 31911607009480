import { Box, Typography } from '@mui/material';
import { ButtonWithLoader } from 'components/Buttons';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import { TextField } from 'formik-mui';
import React, { useEffect, useRef } from 'react';
import { transformErrorIntoFormikError } from '../auth.functions';
import { resetPassword, ResetPasswordPayload } from '../auth.schemas';
import { ErrorResponse } from '../auth.types';

interface Props {
    onSubmit: (payload: ResetPasswordPayload) => void;
    loading: boolean;
    errorMessage: string;
    errors: ErrorResponse;
}

const ResetPasswordForm = ({ onSubmit, loading, errorMessage, errors }: Props) => {
    const formRef = useRef<FormikProps<FormikValues>>(null);

    useEffect(() => {
        formRef?.current?.setErrors(transformErrorIntoFormikError(errors));
    }, [errors]);

    return (
        <>
            <Typography variant={'h6'} color={'error'}>
                {errorMessage}
            </Typography>
            <Formik
                innerRef={formRef}
                initialValues={resetPassword.getDefault()}
                validationSchema={resetPassword}
                onSubmit={(payload) => onSubmit(payload as ResetPasswordPayload)}
            >
                {({ isValid, dirty }) => (
                    <Form>
                        <Box>
                            <Field
                                sx={{
                                    width: '100%',
                                    marginTop: 8,
                                    marginBottom: 8,
                                }}
                                component={TextField}
                                variant="filled"
                                name="user_name"
                                type="text"
                                label="Username"
                            />
                        </Box>
                        <Box>
                            <Field
                                sx={{
                                    width: '100%',
                                    marginTop: 8,
                                    marginBottom: 8,
                                }}
                                component={TextField}
                                variant="filled"
                                name="password"
                                type={'password'}
                                label="Password"
                            />
                        </Box>
                        <Box>
                            <Field
                                sx={{
                                    width: '100%',
                                    marginTop: 8,
                                    marginBottom: 8,
                                }}
                                component={TextField}
                                variant="filled"
                                name="password_confirmation"
                                type={'password'}
                                label="Password Confirmation"
                            />
                        </Box>
                        <Box>
                            <ButtonWithLoader
                                sx={{
                                    marginTop: 8,
                                    width: '100%',
                                }}
                                size="large"
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={!dirty || !isValid || loading}
                                loading={loading}
                            >
                                Reset
                            </ButtonWithLoader>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ResetPasswordForm;
