import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Checkbox, Container, Grid, Paper } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import useTheme from '@mui/material/styles/useTheme';
import { CustomButton, LayoutWithTopBar, ListWithSearchContainer, SnackBar } from 'components';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ROUTES from 'Routes/routes';
import { Group, GroupType, Meta, PaginationLink, useToggle } from 'utils';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { getGroups } from '../api/groups.api';
import { CreateGroupModal, GroupCard } from '../components';

const ListOfGroups = () => {
    const initialUrlSearchParams = new URLSearchParams(window.location.search);

    const [query, setQuery] = useState<{
        'filter[name]'?: string;
        'filter[organization]'?: string;
        'filter[is_archived]'?: boolean;
        page?: number;
    }>({
        'filter[name]': initialUrlSearchParams.get('filter[name]') || '',
        'filter[organization]': initialUrlSearchParams.get('filter[organization]') || '',
        'filter[is_archived]': initialUrlSearchParams.get('filter[is_archived]') === 'true',
        page: Number(initialUrlSearchParams.get('page')) || 1,
    });
    const [searchValue, setSearchValue] = useState<string>(query['filter[name]'] as string);
    const [createGroupModal, toggleCreateGroupModal] = useToggle(false);
    const [groups, setGroups] = useState<Array<Group>>([]);
    const [paginationMeta, setPaginationMeta] = useState<Meta | null>(null);
    const [paginationLinks, setPaginationLinks] = useState<PaginationLink | null>(null);
    const [loading, setLoading] = useState(true);
    const [loadingResults, setLoadingResults] = useState(false);
    const [notifyUserMessage, setNotifyUserMessage] = useState('');
    const { location } = useHistory();
    const isGroupsPath = Boolean(location.pathname === ROUTES.ListOfGroups.path);
    const debounceLoadData = useCallback(debounce(setQuery, 400), []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearchValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoadingResults(true);
        setSearchValue(event.target.value);
        debounceLoadData({ 'filter[name]': event.target.value });
    };

    const onNextClicked = () => {
        const nextPage = paginationMeta ? paginationMeta.current_page + 1 : 1;
        handlePageChanged(nextPage);
    };

    const onPrevClicked = () => {
        const prevPage = paginationMeta ? paginationMeta.current_page - 1 : 1;
        handlePageChanged(prevPage);
    };

    const handlePageChanged = (newPage: number) => {
        setQuery((query) => ({ ...query, page: newPage }));
    };

    useEffect(() => {
        let isMounted = true;
        getGroups({
            include: 'membersSample,membersCount,organization',
            'filter[type]': `${isGroupsPath ? 'group,team' : 'individual'}`,
            ...query,
        })
            .then((response) => {
                if (isMounted) {
                    setGroups(response.data.data);
                    setPaginationLinks(response.data.links);
                    setPaginationMeta(response.data.meta);
                }
            })
            .catch(() => isMounted && setNotifyUserMessage('Failed to Load groups'))
            .finally(() => {
                if (isMounted) {
                    setLoadingResults(false);
                    setLoading(false);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [query, isGroupsPath]);

    const theme = useTheme();

    return (
        <LayoutWithTopBar title={'Groups & Teams'} onBack={() => {}}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    padding: theme.spacing(5),
                    overflow: 'scroll',

                    [theme.breakpoints.down('sm')]: {
                        background: theme.palette.backgroundColor.action,
                    },
                }}
            >
                <Container maxWidth="xl">
                    <ListWithSearchContainer
                        searchValue={searchValue}
                        setSearchValue={handleSearchValueChanged}
                        setOpen={() => {}}
                        onClickButton={() => toggleCreateGroupModal()}
                        isError={false}
                        errorMessage=""
                        searchBarPlaceHolder="Search"
                        buttonText={isGroupsPath ? 'NEW GROUP' : 'ADD INDIVIDUAL'}
                        searchBarLoader={loadingResults}
                        onFilter={(q) => {
                            setQuery((query) => ({ ...query, ...q }));
                        }}
                        enabledFilters={{
                            'filter[organization]': query['filter[organization]'] || '',
                        }}
                    >
                        {loading ? (
                            <>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight="50vh"
                                >
                                    <CustomLoadingOverlay />
                                </Box>
                            </>
                        ) : (
                            <Grid container spacing={3}>
                                {
                                    // Sort groups by name and then map over them
                                    groups
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((group, index) => (
                                            <Grid item xs={12} md={6} lg={6} key={index}>
                                                <GroupCard
                                                    group={group}
                                                    isGroupsPath={isGroupsPath}
                                                />
                                            </Grid>
                                        ))
                                }
                            </Grid>
                        )}
                        {groups.length > 0 && (
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <CustomButton
                                    variant="text"
                                    color="primary"
                                    disabled={Boolean(!paginationLinks?.prev)}
                                    startIcon={<NavigateBeforeIcon />}
                                    onClick={() => onPrevClicked()}
                                >
                                    Previous
                                </CustomButton>
                                <CustomButton
                                    variant="text"
                                    color="primary"
                                    endIcon={<NavigateNextIcon />}
                                    disabled={Boolean(!paginationLinks?.next)}
                                    onClick={() => onNextClicked()}
                                >
                                    Next
                                </CustomButton>
                            </Box>
                        )}
                        <CreateGroupModal
                            groupType={isGroupsPath ? GroupType.GROUP : GroupType.INDIVIDUAL}
                            onGroupCreatedFailed={() =>
                                setNotifyUserMessage('Failed to create group')
                            }
                            onGroupCreated={(group) => {
                                toggleCreateGroupModal();
                                setGroups([...(groups ?? []), group]);
                            }}
                            open={createGroupModal}
                            onClose={toggleCreateGroupModal}
                        />
                        <SnackBar
                            open={notifyUserMessage ? true : false}
                            setOpen={() => setNotifyUserMessage('')}
                            severity="error"
                            message={notifyUserMessage}
                        />
                    </ListWithSearchContainer>
                    {!loading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Paper>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(query['filter[is_archived]'])}
                                            onChange={() => {
                                                setQuery((query) => ({
                                                    ...query,
                                                    'filter[is_archived]':
                                                        !query['filter[is_archived]'],
                                                }));
                                            }}
                                            name="archived"
                                            color="primary"
                                            size="medium"
                                            sx={{ marginLeft: 8 }}
                                        />
                                    }
                                    label="Show Only Archived Groups"
                                />
                            </Paper>
                        </Box>
                    )}
                </Container>
            </Box>
        </LayoutWithTopBar>
    );
};

export default ListOfGroups;
