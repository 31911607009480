import {
    Add,
    DeleteOutline,
    ExpandLess,
    ExpandMore,
    FileCopy,
    PhotoLibrary,
} from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Hidden,
    IconButton,
    InputBase,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ChooseMediaDialog, confirmViaDialog } from 'components';

import AssetPreviewCard from 'components/Cards/AssetPreviewCard';
import { cloneDeep, debounce, orderBy } from 'lodash';
import React, { ReactElement, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import {
    addQuestionToFormSection,
    choicesReoredered,
    questionsReoredered,
    removeQuestionFromFormSection,
    updateSectionDetails,
} from 'redux/reducers/form';
import { useAppDispatch } from 'redux/store';
import { Asset, AssetVersion, AssetVersionType, Question, Section } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { Media } from '../../../app.types';
import QuestionDescriptionTextEditor from './QuestionDescriptionTextEditor';
import ScrollToViewWrapper from './ScrollToViewWrapper';
import TextQuestionCard from './TextQuestionCard';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

export interface QuestionSectionCardProps {
    section: Section;
    disableDelete: boolean;
    sectionIndex: number;
    formUuid: string;
    addSection: (formUuid: string) => void;
    copySection: (section: Section) => void;
    deleteSection: (args: { formUuid: string; section: Section }) => void;
    organization: string;
    onMoveUp: () => void;
    onMoveDown: () => void;
    canMoveUp: boolean;
    canMoveDown: boolean;
}

export interface QuestionChoice {
    uuid: string;
    text: string;
    media: string | null;
}

const QuestionSectionCard = ({
    section,
    disableDelete,
    sectionIndex,
    formUuid,
    addSection,
    copySection,
    deleteSection,
    organization,
    onMoveUp,
    onMoveDown,
    canMoveUp,
    canMoveDown,
}: QuestionSectionCardProps): ReactElement => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    const dispatch = useAppDispatch();

    const [expanded, setExpanded] = useState<boolean>(true);
    const [showSectionMediaUpload, setShowSectionMediaUpload] = useState<boolean>(false);

    // For indexing list of questions to auto-scrolling to
    // during addition, copy, and/or deletion of questions
    const initialLastQuestionIdx = section.questions?.length - 1 || 0;
    const [scrollToQuestionIdx, setScrollToQuestionIdx] = useState(-1);

    const onQuestionAdd = () => {
        dispatch(
            addQuestionToFormSection({
                formUuid: formUuid,
                sectionUuid: section.uuid,
            }),
        );
        setScrollToQuestionIdx(initialLastQuestionIdx + 1);
    };

    // When question is copied, bubble up the changes to section
    // however its important to replace the uuids before we copy over
    const onQuestionCopied = (copiedQuestion: Question) => {
        // Duplicate the question over in the section
        let newQuestion = cloneDeep(copiedQuestion);
        // We need to update the uuids of the question and choices
        newQuestion.uuid = uuidv4();
        newQuestion.choices =
            newQuestion?.choices?.map((choice) => {
                choice.uuid = uuidv4();
                return choice;
            }) || [];

        // Add question to the section
        dispatch(
            addQuestionToFormSection({
                formUuid,
                sectionUuid: section.uuid,
                newQuestion: newQuestion,
            }),
        );
        setScrollToQuestionIdx(initialLastQuestionIdx + 1);
    };

    const onQuestionDelete = async (questionUuid: string) => {
        const targetQuestion = section.questions.find((q) => q.uuid === questionUuid);
        if (
            await confirmViaDialog({
                confirmation: {
                    title: 'Are you sure?',
                    message:
                        'This will delete question ' +
                        `"${targetQuestion?.question || 'Untitled'}"` +
                        ' and all its choices if it is a multiple choice question.',
                },
            })
        ) {
            dispatch(
                removeQuestionFromFormSection({
                    formUuid: formUuid,
                    sectionUuid: section.uuid,
                    removedQuestionUuid: questionUuid,
                }),
            );
            setScrollToQuestionIdx(initialLastQuestionIdx - 1);
        }
    };

    const onDropEnd = (result: DropResult) => {
        // Dropped outside the expected container
        if (!result.destination || !result.source || !result.type) {
            return;
        }
        // Question was re-ordered
        if (result.type == 'questions') {
            const questionUuidsInOrder: Array<string> = orderBy(section.questions, 'order').map(
                (question) => question.uuid as string,
            );

            const [removed] = questionUuidsInOrder.splice(result.source.index, 1);
            questionUuidsInOrder.splice(result.destination.index, 0, removed);

            dispatch(
                questionsReoredered({
                    formUuid: formUuid,
                    sectionUuid: section.uuid,
                    questionUuidsInOrder: questionUuidsInOrder,
                }),
            );
        }
        // Question choice was re-ordered
        if (result.type == 'choices') {
            const updatedQuestionUuid = result.source.droppableId;

            // Find the question for which choices are re-ordered
            let updatedQuestion = section.questions.find(({ uuid }) => uuid == updatedQuestionUuid);

            if (!updatedQuestion) {
                return;
            }

            const choiceUuidsInOrder: Array<string> = orderBy(
                updatedQuestion?.choices || [],
                'order',
            ).map((choice) => choice.uuid as string);

            // Re-order choices
            const [removed] = choiceUuidsInOrder.splice(result.source.index, 1) || [];
            choiceUuidsInOrder.splice(result.destination.index, 0, removed);

            dispatch(
                choicesReoredered({
                    formUuid: formUuid,
                    sectionUuid: section.uuid,
                    questionUuid: updatedQuestion.uuid as string,
                    choiceUuidsInOrder: choiceUuidsInOrder,
                }),
            );
        }
    };

    const onSectionAssetUploaded = (media: Media) => {
        const newAsset = {
            uuid: media.uuid,
            purpose: 'uploaded',
            versions: [
                {
                    uuid: uuidv4(),
                    type: AssetVersionType.Uploaded,
                    file_mime_type: media.mime_type,
                    file_signed_url: media.url,
                    file_size: null,
                    height: null,
                    width: null,
                    duration: null,
                    framerate: null,
                    encoding_status: '',
                    tmpFileUrl: media.path,
                } as AssetVersion,
            ],
        } as Asset;

        dispatch(
            updateSectionDetails({
                formUuid: formUuid,
                sectionUuid: section.uuid,
                updatePayload: {
                    asset: newAsset,
                    media_uuid: media.uuid,
                },
            }),
        );
    };

    return (
        <Box mb={10} id={section.uuid} key={section.uuid}>
            <Box
                sx={{
                    background: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    display: 'flex',
                    alignItems: 'center',
                    padding: theme.spacing(3, 5),
                    fontSize: 12,
                    fontWeight: 700,
                    lineHeight: '22px',
                    textTransform: 'uppercase',
                    borderRadius: '5px 5px 0 0',
                }}
            >
                <Box flexGrow={1}>Section {sectionIndex + 1}</Box>
                {canMoveUp && (
                    <IconButton
                        onClick={onMoveUp}
                        size="small"
                        sx={{ color: 'white', marginRight: 1 }}
                        title="Move section up"
                    >
                        <ArrowUpward fontSize="small" />
                    </IconButton>
                )}
                {canMoveDown && (
                    <IconButton
                        onClick={onMoveDown}
                        size="small"
                        sx={{ color: 'white' }}
                        title="Move section down"
                    >
                        <ArrowDownward fontSize="small" />
                    </IconButton>
                )}
            </Box>
            <Accordion
                expanded={expanded || isMdUp}
                sx={{
                    '&.Mui-expanded': {
                        marginTop: 0,
                    },
                    [theme.breakpoints.up('md')]: {
                        boxShadow: 'none',
                        border: '1px solid rgba(0, 62, 157, 0.5)',
                        borderRadius: '0 0 4px 4px',
                        position: 'relative',
                    },
                }}
            >
                <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        minHeight: theme.spacing(23),
                        padding: theme.spacing(8, 9, 2),
                        '&.Mui-focusVisible': {
                            background: 'none', // remove highlighted default,
                        },
                    }}
                >
                    {/* section actions */}
                    <Box
                        flexDirection="column"
                        alignItems="center"
                        position="absolute"
                        sx={{
                            display: 'none',
                            [theme.breakpoints.up('md')]: {
                                display: 'flex',
                                gap: theme.spacing(20),
                                left: 'calc(100% + 1px)',
                                backgroundColor: theme.palette.common.white,
                                padding: theme.spacing(25, 8),
                                top: -1,
                                border: '1px solid rgba(0, 62, 157, 0.5)',
                                borderLeft: 'none',
                                borderRadius: '0 4px 4px 0',
                            },
                        }}
                    >
                        {!disableDelete && (
                            <Tooltip title="Remove section">
                                <IconButton
                                    aria-label="remove section"
                                    size="small"
                                    onClick={() => deleteSection({ formUuid, section })}
                                >
                                    <DeleteOutline />
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title="Copy section">
                            <IconButton
                                aria-label="copy section"
                                size="small"
                                onClick={() => copySection(section)}
                            >
                                <FileCopy />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Add section">
                            <IconButton
                                aria-label="add section"
                                size="small"
                                onClick={() => addSection(formUuid)}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {/* section content */}
                    <Grid container justifyContent="space-between">
                        {/* Section title */}
                        <Grid item xs={11} md={12}>
                            <InputBase
                                fullWidth
                                sx={{
                                    paddingBottom: 0,
                                    '&:hover': {
                                        backgroundColor: theme.palette.backgroundColor.hover,
                                    },
                                    '&.Mui-focused': {
                                        backgroundColor: theme.palette.backgroundColor.hover,
                                    },
                                }}
                                defaultValue={
                                    section.name == 'Untitled Section' ? '' : section.name
                                }
                                inputProps={{
                                    style: {
                                        fontSize: 17,
                                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                                        paddingBottom: 6,
                                        paddingLeft: 5,
                                    },
                                    'aria-label': 'naked',
                                }}
                                multiline
                                placeholder="Untitled Section"
                                maxRows={5}
                                onChange={debounce((event: React.ChangeEvent<HTMLInputElement>) => {
                                    dispatch(
                                        updateSectionDetails({
                                            formUuid: formUuid,
                                            sectionUuid: section.uuid,
                                            updatePayload: {
                                                name: event.target.value || '',
                                            },
                                        }),
                                    );
                                }, 2000)}
                            />
                        </Grid>

                        {/* Accordion toggle */}
                        <Hidden mdUp>
                            <Grid
                                item
                                xs={1}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    padding: '6px 0 7px', // match v-alignment of section input
                                    alignItems: 'center',
                                    '& > .MuiButtonBase-root': {
                                        padding: 0,
                                    },
                                }}
                            >
                                <IconButton
                                    aria-label="toggle section summary"
                                    onClick={() => {
                                        setExpanded(!expanded);
                                    }}
                                    size="large"
                                >
                                    {expanded ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            </Grid>
                        </Hidden>
                    </Grid>
                </AccordionSummary>

                {/* Section details & questions */}
                <AccordionDetails
                    sx={{
                        backgroundColor: theme.palette.common.white,
                        padding: '4px 18px 24px',
                        display: 'block',
                    }}
                >
                    {/* Section image preview */}
                    {section.asset && (
                        <Box mb={4}>
                            <AssetPreviewCard
                                key={section.asset.uuid}
                                fullwidth={true}
                                disableRemove
                                className={`
        & > div {
            text-align: left;
            overflow: hidden;
        };
         & img {
            width: auto;
            height: 120px;
            object-fit: contain;
        };
      `}
                                asset={section.asset}
                                altTitle={section.name}
                                onAssetRemoved={() => {
                                    dispatch(
                                        updateSectionDetails({
                                            formUuid: formUuid,
                                            sectionUuid: section.uuid,
                                            updatePayload: {
                                                asset: null,
                                            },
                                        }),
                                    );
                                }}
                            />
                        </Box>
                    )}

                    {/* Section description editor */}
                    <Box pb={5}>
                        <Box mb={4}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={11}
                                    sx={{
                                        fontSize: 14,
                                        lineHeight: '16px',
                                        color: theme.palette.text.secondary,
                                    }}
                                >
                                    Description
                                </Grid>
                                {/* Add image */}
                                <Grid item xs={1}>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Tooltip
                                            title={`${
                                                section.asset ? 'Update' : 'Add'
                                            } section photo`}
                                        >
                                            <IconButton
                                                aria-label="section photo"
                                                onClick={() => {
                                                    setShowSectionMediaUpload(true);
                                                }}
                                                size="small"
                                            >
                                                <PhotoLibrary />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <QuestionDescriptionTextEditor
                            label=""
                            placeholder="Enter section description here"
                            defaultValue={section.description || ''}
                            editorOverrides={{
                                toolbar: {
                                    options: ['inline', 'emoji', 'list', 'link'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                    emoji: {
                                        emojis: [
                                            '✅',
                                            '❌',
                                            '🦑',
                                            '👀',
                                            '⏲️',
                                            '🧠',
                                            '💓',
                                            '🔥',
                                            '💣',
                                            '💯',
                                        ],
                                    },
                                    list: {
                                        options: ['unordered', 'ordered'],
                                    },
                                    link: {
                                        options: ['link'],
                                        defaultTargetOption: '_blank',
                                    },
                                },
                            }}
                            onChange={debounce((newDescription: string) => {
                                dispatch(
                                    updateSectionDetails({
                                        formUuid: formUuid,
                                        sectionUuid: section.uuid,
                                        updatePayload: {
                                            description: newDescription,
                                        },
                                    }),
                                );
                            }, 2000)}
                        />
                    </Box>

                    <DragDropContext onDragEnd={onDropEnd}>
                        {/* We are implementing nested drag and drop here where
                        one container has questions which can be reorders using drag and drop
                        and each question may have choices which can be re-ordered as well
                        So its important to have droppableId different in the containers*/}
                        <Droppable type="questions" droppableId={section.uuid}>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {orderBy(section.questions || [], 'order').map(
                                        (question, idx) => (
                                            <Draggable
                                                key={question.uuid}
                                                draggableId={question.uuid || ''}
                                                index={idx}
                                            >
                                                {(provided) => {
                                                    return (
                                                        <ScrollToViewWrapper
                                                            shouldScrollTo={
                                                                scrollToQuestionIdx === idx
                                                            }
                                                        >
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                            >
                                                                <TextQuestionCard
                                                                    organization={organization}
                                                                    provided={provided}
                                                                    question={question}
                                                                    key={question.uuid}
                                                                    onQuestionAdd={onQuestionAdd}
                                                                    onQuestionCopied={
                                                                        onQuestionCopied
                                                                    }
                                                                    onQuestionDelete={
                                                                        onQuestionDelete
                                                                    }
                                                                    // We want to avoid deleting the first row so that we dont end up
                                                                    // having a section with no question in it
                                                                    disableDelete={
                                                                        section.questions.length < 2
                                                                    }
                                                                    sectionUuid={section.uuid}
                                                                    formUuid={formUuid}
                                                                />
                                                            </div>
                                                        </ScrollToViewWrapper>
                                                    );
                                                }}
                                            </Draggable>
                                        ),
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </AccordionDetails>
            </Accordion>

            {showSectionMediaUpload && (
                <ChooseMediaDialog
                    showVideo={true}
                    open={true}
                    fullScreen={false}
                    onClose={() => setShowSectionMediaUpload(false)}
                    onSelected={(media) => {
                        setShowSectionMediaUpload(false);
                        onSectionAssetUploaded(media);
                    }}
                    autoSelectMediaAfterUpload={true}
                    organization={organization}
                />
            )}
        </Box>
    );
};

export default QuestionSectionCard;
