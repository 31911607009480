import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Session } from 'utils';

type ReturnType = Promise<AxiosResponse<Session>>;

const removeMovementFromSession = (sessionId: string, sessionMovementId: string): ReturnType => {
    return Request.delete(`/workouts/sessions/${sessionId}/movements/${sessionMovementId}`);
};

export default removeMovementFromSession;
