import React from 'react';
import { isEmpty, startCase, toLower } from 'lodash';
import { Container, Hidden, Box, AppBar, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/system';

export interface LayoutProps {
    title: string;
    titleActions?: React.ReactElement | null;
    titleHidden?: boolean;
    content: React.ReactElement;
    footerActions?: React.ReactElement | null;
    backRoute?: string | null;
    disableBackButton?: boolean | null;
    hideFooterOnMobileDevices?: boolean | null;
    displayLeftActions?: React.ReactElement;
}

const FixedLayout = ({
    title,
    titleActions = null,
    titleHidden = false,
    content,
    footerActions = null,
    backRoute = null,
    disableBackButton = false,
    hideFooterOnMobileDevices = false,
    displayLeftActions = <></>,
}: LayoutProps) => {
    const history = useHistory();
    const theme = useTheme();

    return (
        <>
            {!titleHidden && (
                <AppBar
                    position="sticky"
                    component="header"
                    color="transparent"
                    sx={{
                        backgroundColor: theme.palette.common.white,
                    }}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        px={2}
                        height={75}
                    >
                        <Box display="flex" alignItems="center">
                            {!disableBackButton ? (
                                <ArrowBackIos
                                    sx={{ marginLeft: 6, cursor: 'pointer' }}
                                    onClick={() => {
                                        isEmpty(backRoute)
                                            ? history.goBack()
                                            : history.push(backRoute || '');
                                    }}
                                />
                            ) : (
                                <div>&nbsp;</div>
                            )}
                            {displayLeftActions}
                        </Box>
                        <Typography
                            align="center"
                            variant="h6"
                            sx={{ fontSize: 16 }}
                            color="primary"
                        >
                            {startCase(toLower(title))}
                        </Typography>
                        {/* Page title actions or button children which will be shown on right corner of the title
                For example share button on a session or publish toggle button etc */}
                        {!isEmpty(titleActions) ? <div>{titleActions}</div> : <div>&nbsp;</div>}
                    </Box>
                </AppBar>
            )}

            {/* Scrollable Container */}
            <Container
                sx={{
                    paddingBottom: '60px',
                    maxWidth: '90vw',
                }}
            >
                {content}
            </Container>
            {/* We can optionally hide the footer on mobile devices using hideFooterOnMobileDevices property */}
            {/* This will give flexibility to show footer on a specific page if needed in future */}
            {/* Desktop bottom actions bar */}
            <Hidden smDown>
                {/* Fixed Footer Bar / Actions Bar / Button Bar */}
                {!isEmpty(footerActions) && (
                    <AppBar
                        position="fixed"
                        component="footer"
                        color="transparent"
                        sx={{
                            bottom: 0,
                            top: 'inherit',
                            backgroundColor: theme.palette.common.white,
                            height: '50px',
                            [theme.breakpoints.down('sm')]: {
                                bottom: 72,
                            },
                        }}
                    >
                        {footerActions}
                    </AppBar>
                )}
            </Hidden>
            {/* Mobile bottom actions bar */}
            {!hideFooterOnMobileDevices && (
                <Hidden smUp>
                    {!isEmpty(footerActions) && (
                        <AppBar
                            position="relative"
                            component="footer"
                            color="transparent"
                            sx={{
                                bottom: 0,
                                top: 'inherit',
                                backgroundColor: theme.palette.common.white,
                                height: '50px',
                                [theme.breakpoints.down('sm')]: {
                                    bottom: 72,
                                },
                            }}
                        >
                            {footerActions}
                        </AppBar>
                    )}
                </Hidden>
            )}
        </>
    );
};

export default FixedLayout;
