import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    addMovementsToSessionInBulk,
    addMovementToSession,
    createSession,
    getAllSessions,
    removeMovementFromSession,
    reorderSessionMovements,
    updateMovementOfSession,
    updateSession,
} from 'api';
import { AxiosError } from 'axios';
import { Meta, NewSessionMovement, PaginationLink, Session } from 'utils';
import { RootState } from '.';

export interface SessionsError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export interface SessionsState {
    isLoading: boolean;
    isLoaded: boolean;
    data: Array<Session>;
    pagination: PaginationLink;
    paginationMeta: Meta;
    error: SessionsError;
    newSession: Session | null;
}

export const initialState: SessionsState = {
    isLoading: false,
    isLoaded: false,
    data: [],
    pagination: {
        first: null,
        prev: null,
        last: null,
        next: null,
    },
    paginationMeta: {
        path: '',
        per_page: 10,
        current_page: 1,
    },
    error: { message: '' },
    newSession: null,
};

export const loadSessions = createAsyncThunk<
    { data: Session[]; pagination: PaginationLink; paginationMeta: Meta },
    { 'filter[title]': string; page?: number },
    { rejectValue: SessionsError }
>('sessions/fetchAll', async (query, { rejectWithValue }) => {
    try {
        const { data } = await getAllSessions(query);
        return { data: data.data, pagination: data.links, paginationMeta: data.meta };
    } catch (err: any) {
        const error: AxiosError<SessionsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Loading Sessions' });
    }
});

export const createNewSession = createAsyncThunk<
    { data: Session },
    { title: string; description: string; duration: number; organization: string | null },
    { rejectValue: SessionsError }
>(
    'session/createSession',
    async ({ title, description, duration, organization = null }, { rejectWithValue }) => {
        try {
            const { data } = await createSession(title, description, duration, organization);
            return { data };
        } catch (err: any) {
            const error: AxiosError<SessionsError> = err;
            return rejectWithValue(error.response?.data ?? { message: 'Error Creating Session' });
        }
    },
);

export const updateExistingSession = createAsyncThunk<
    { data: Session },
    { session: Session },
    { rejectValue: SessionsError }
>('session/updateSession', async ({ session }, { rejectWithValue }) => {
    try {
        const { data } = await updateSession(session.uuid, {
            title: session.title,
            description: session.description,
            attributes: session.attributes,
            duration: (session?.duration as number) || 0,
        });
        return { data };
    } catch (err: any) {
        const error: AxiosError<SessionsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Updating Session' });
    }
});

export const addNewMovementToSession = createAsyncThunk<
    { data: Session },
    { newMovement: NewSessionMovement; sessionId: string },
    { rejectValue: SessionsError }
>('session/addMovementToSession', async ({ newMovement, sessionId }, { rejectWithValue }) => {
    try {
        const { data } = await addMovementToSession(sessionId, {
            movement: newMovement.movement,
            distance_value: newMovement.distance_value,
            distance_unit: newMovement.distance_unit,
            time_value: newMovement.time_value,
            time_display_format: newMovement.time_display_format,
            rest_value: newMovement.rest_value,
            load_value: newMovement.load_value,
            load_unit: newMovement.load_unit,
            reps: newMovement.reps,
        });
        return { data };
    } catch (err: any) {
        const error: AxiosError<SessionsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Adding Movement' });
    }
});

export const addMovementsToExistingSessionInBulk = createAsyncThunk<
    { data: Session },
    { newMovements: NewSessionMovement[]; sessionId: string },
    { rejectValue: SessionsError }
>(
    'session/addMovementsToSessionInBulk',
    async ({ newMovements, sessionId }, { rejectWithValue }) => {
        try {
            const { data } = await addMovementsToSessionInBulk(newMovements, sessionId);
            return { data };
        } catch (err: any) {
            const error: AxiosError<SessionsError> = err;
            return rejectWithValue(error.response?.data ?? { message: 'Error Adding Movements' });
        }
    },
);

export const deleteMovementFromExistingSession = createAsyncThunk<
    {},
    { sessionId: string; sessionMovementId: string },
    { rejectValue: SessionsError }
>(
    'session/removeMovementFromSession',
    async ({ sessionId, sessionMovementId }, { rejectWithValue }) => {
        try {
            const { data } = await removeMovementFromSession(sessionId, sessionMovementId);
            return { data };
        } catch (err: any) {
            const error: AxiosError<SessionsError> = err;
            return rejectWithValue(error.response?.data ?? { message: 'Error Deleting Movement' });
        }
    },
);

export const updateExistingMovement = createAsyncThunk<
    { data: Session },
    { newMovement: NewSessionMovement; sessionId: string; movementSessionUuid: string },
    { rejectValue: SessionsError }
>(
    'session/updateMovementOfSession',
    async ({ newMovement, sessionId, movementSessionUuid }, { rejectWithValue }) => {
        try {
            const { data } = await updateMovementOfSession(
                { ...newMovement },
                sessionId,
                movementSessionUuid,
            );
            return { data };
        } catch (err: any) {
            const error: AxiosError<SessionsError> = err;
            return rejectWithValue(error.response?.data ?? { message: 'Error Adding Movement' });
        }
    },
);

export const reorderExistingSessionMovements = createAsyncThunk<
    { data: Session },
    { sessionId: string; movements: string[] },
    { rejectValue: SessionsError }
>('session/reorderMovements', async ({ sessionId, movements }, { rejectWithValue }) => {
    try {
        const { data } = await reorderSessionMovements(sessionId, movements);
        return { data };
    } catch (err: any) {
        const error: AxiosError<SessionsError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Reordering Movements' });
    }
});

export const sessionSlice = createSlice({
    name: 'sessions',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadSessions.fulfilled, (state, { payload }) => {
            state.data = payload.data;
            state.newSession = null;
            state.pagination = payload.pagination;
            state.paginationMeta = payload.paginationMeta;
            state.error = { message: '' };
            state.isLoading = false;
            state.isLoaded = true;
        });
        addCase(loadSessions.pending, (state) => {
            state.newSession = null;
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(loadSessions.rejected, (state, { payload }) => {
            state.newSession = null;
            state.error.message = payload?.message ?? 'Error Loading Sessions';
            state.isLoading = false;
        });
        addCase(createNewSession.fulfilled, (state, { payload }) => {
            state.newSession = payload.data;
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(createNewSession.pending, (state) => {
            state.newSession = null;
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(createNewSession.rejected, (state, { payload }) => {
            state.newSession = null;
            state.error = payload ?? { message: 'Error', errors: {} };
            state.isLoading = false;
        });
        addCase(addNewMovementToSession.fulfilled, (state, { payload }) => {
            state.newSession = payload.data;
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(addNewMovementToSession.pending, (state) => {
            state.newSession = null;
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(addNewMovementToSession.rejected, (state, { payload }) => {
            state.newSession = null;
            state.error.message = payload?.message ?? 'Error Adding Movement';
            state.isLoading = false;
        });
        addCase(deleteMovementFromExistingSession.fulfilled, (state) => {
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(deleteMovementFromExistingSession.pending, (state) => {
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(deleteMovementFromExistingSession.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Deleting Movement';
            state.isLoading = false;
        });
        addCase(updateExistingMovement.fulfilled, (state, { payload }) => {
            state.newSession = payload.data;
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(updateExistingMovement.pending, (state) => {
            state.newSession = null;
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(updateExistingMovement.rejected, (state, { payload }) => {
            state.newSession = null;
            state.error.message = payload?.message ?? 'Error Updating Movement';
            state.isLoading = false;
        });
        addCase(updateExistingSession.fulfilled, (state, { payload }) => {
            state.newSession = payload.data;
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(updateExistingSession.pending, (state) => {
            state.newSession = null;
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(updateExistingSession.rejected, (state, { payload }) => {
            state.newSession = null;
            state.error.message = payload?.message ?? 'Error Updating Movement';
            state.isLoading = false;
        });
        addCase(reorderExistingSessionMovements.fulfilled, (state, { payload }) => {
            state.error = { message: '' };
            state.isLoading = false;
            const sessionIndex = state.data.findIndex((m) => m.uuid === payload.data.uuid);

            if (sessionIndex > -1) {
                const newData = [...state.data];
                newData.splice(sessionIndex, 1, payload.data);
                state.data = newData;
            }
        });
        addCase(reorderExistingSessionMovements.pending, (state) => {
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(reorderExistingSessionMovements.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Reordering Movements';
            state.isLoading = true;
        });
        addCase(addMovementsToExistingSessionInBulk.fulfilled, (state, { payload }) => {
            state.newSession = payload.data;
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(addMovementsToExistingSessionInBulk.pending, (state) => {
            state.newSession = null;
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(addMovementsToExistingSessionInBulk.rejected, (state, { payload }) => {
            state.newSession = null;
            state.error.message = payload?.message ?? 'Error Adding Movements';
            state.isLoading = false;
        });
    },
});

export const sessionsSelector = (state: RootState) => state.sessions;
