import { createAsyncThunk } from '@reduxjs/toolkit';
import { MovementChartData, MovementFilter, MovementMetricFilter } from '../../../../app.types';
import {
    getReportMovements,
    getReportUsers,
    getUserReportForMovement,
} from '../../../../modules/plan/api/reports';
import { UserFilter } from '../../../../modules/plan/plan.types';

export const loadUsers = createAsyncThunk<
    Array<UserFilter>,
    undefined,
    { rejectValue: { message: string } }
>('plan/reports/filters/getUsers', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getReportUsers();
        return data;
    } catch {
        return rejectWithValue({ message: 'Failed to get data' });
    }
});

export const loadMovements = createAsyncThunk<
    MovementFilter[],
    undefined,
    { rejectValue: { message: string } }
>('plan/reports/filters/getMovements', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getReportMovements();
        return data;
    } catch {
        return rejectWithValue({ message: 'Failed to get data' });
    }
});

export const chooseFilters = createAsyncThunk<
    MovementChartData,
    MovementMetricFilter,
    { rejectValue: { message: string } }
>(
    'plan/reports/chooseFilters',
    async (filters, { rejectWithValue }: { rejectWithValue?: any; getState?: any }) => {
        try {
            const { data } = await getUserReportForMovement(filters);
            return data;
        } catch (e: any) {
            return rejectWithValue({ message: 'Failed to get the data' });
        }
    },
);
