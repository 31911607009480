import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Activity } from 'utils';

type ReturnType = Promise<AxiosResponse<Activity>>;

const getActivity = (id: string): ReturnType => {
    return Request.get(`/workouts/activities/${id}`);
};
export default getActivity;
