import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AISprintVideoSetup() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL AI Video Analysis is an advanced markerless biomechanical joint
                        tracking tool that allows you to analyse sprint performance with just an
                        iPhone and <b>60 frames per second (fps) video</b>.
                    </Typography>
                </Grid>

                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            <b>Video must be taken from a still point of view.</b> You cannot move
                            the camera to track the athlete, it must be still with the athlete
                            running through the frame. The total field of view is roughly 20 yards.
                            It is best to have the athlete run into the frame from a fly in rather
                            than a starting position in order to get accurate readings on
                            asymmetries. If you would like to have speed and split times automated
                            then setup the camera in the middle of a line with 5 yard lines on each
                            side and a 5 yard buffer on each side of the lines. Alternatively, cones
                            can be placed 10 yards apart and the camera placed between the cones.
                            <b> Only one athlete should be in the video.</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            title={'Video with Cones'}
                            videoUrl={
                                'https://player.vimeo.com/video/875774651?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/875774431?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Video with Field Lines'}
                            description={'Video with Field Lines'}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/875826821?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Video Example without Field Lines or Cones'}
                            description={'Video Example without Field Lines or Cones'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AISprintVideoSetup;
