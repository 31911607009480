import React, { useContext, useRef, useState } from 'react';
import { createIndividual, getSignedUrl, uploadFile } from 'api';
import {
    EXIT_TEXT,
    Individual,
    SAVE_INFORMATION_TEXT,
    SAVE_TEXT,
    UNSAVED_CHANGES_TEXT,
    yupSchemas,
} from 'utils';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import { Avatar, Box, FormControl, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { TextField } from 'formik-mui';
import { UploadLogo } from 'components/Functional';
import { AxiosError } from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { OrganizationsContext } from '../../contexts/OrganizationsContext';
import ButtonWithLoader from 'components/Buttons/ButtonWithLoader';
import { confirmViaDialog } from 'components/Dialogs/ConfirmationDialog';
import { AbilityContext } from '../Functional/Can';

export interface CreateIndividualFormProps {
    /** onIndividualCreated Individual created callback */
    onIndividualCreated: (individual: Individual) => void;
    /** onIndividualCreationFailed Individual creation failed */
    onIndividualCreationFailed: (errorMessage: string) => void;
    onClose?: Function;
}

const CreateIndividualForm = ({
    onIndividualCreated,
    onIndividualCreationFailed,
    onClose,
}: CreateIndividualFormProps) => {
    const [loading, setLoading] = useState(false);
    const [uploadLogo, setUploadLogo] = useState<File>();
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const { organizations } = useContext(OrganizationsContext);
    const ability = useContext(AbilityContext);

    const onCancel = async () => {
        if (formRef.current?.dirty || uploadLogo) {
            const res = await confirmViaDialog({
                confirmation: {
                    title: UNSAVED_CHANGES_TEXT,
                    message: SAVE_INFORMATION_TEXT,
                    cancelButtonText: EXIT_TEXT,
                    okButtonText: SAVE_TEXT,
                    onCancelPressed: () => onClose && onClose(),
                },
            });
            res && formRef.current?.handleSubmit();
        } else {
            onClose && onClose();
        }
    };

    return (
        <>
            <div>
                <IconButton onClick={() => onCancel()} size="large">
                    <CloseIcon />
                </IconButton>
            </div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    minWidth: 400,
                    backgroundColor: 'white',
                }}
            >
                <Formik
                    innerRef={formRef}
                    initialValues={yupSchemas.createIndividual.getDefault()}
                    validationSchema={yupSchemas.createIndividual}
                    onSubmit={async ({ individualName, organization }) => {
                        setLoading(true);
                        try {
                            if (uploadLogo) {
                                const response = await getSignedUrl();
                                if (response.status === 201) {
                                    const res = await uploadFile(response.data.url, uploadLogo);
                                    if (res.status === 200) {
                                        const organizationLogo = response.data.key;
                                        const formSubmitted = await createIndividual(
                                            individualName,
                                            organization,
                                            organizationLogo,
                                        );
                                        if (formSubmitted.status === 201) {
                                            onIndividualCreated(formSubmitted.data);
                                        }
                                    }
                                }
                            } else {
                                const response = await createIndividual(
                                    individualName,
                                    organization,
                                );
                                if (response.status === 201) {
                                    onIndividualCreated(response.data);
                                }
                            }
                        } catch (err: any) {
                            const error: AxiosError<{ message: string }> = err;
                            onIndividualCreationFailed(error.message);
                        } finally {
                            setLoading(false);
                        }
                    }}
                >
                    {({ isValid, dirty, handleChange, values }) => (
                        <Form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                backgroundColor: 'white',
                                flex: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    flex: 1,
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <UploadLogo
                                    uploadImage={uploadLogo}
                                    setUploadImage={setUploadLogo}
                                    loading={loading}
                                    imageName="cover photo"
                                />
                                <Box
                                    sx={(theme) => ({
                                        width: '85%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',

                                        [theme.breakpoints.down(850)]: {
                                            width: '60%',
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            width: '90%',
                                        },
                                    })}
                                >
                                    <Field
                                        style={{
                                            width: '100%',
                                            marginTop: 16,
                                            marginBottom: 5,
                                        }}
                                        name="individualName"
                                        id="individualName"
                                        label="Athlete's Name"
                                        variant="filled"
                                        component={TextField}
                                    />
                                    {organizations && organizations.length > 0 && (
                                        <FormControl fullWidth>
                                            <Select
                                                name="organization"
                                                id="organization"
                                                value={values.organization}
                                                onChange={handleChange}
                                                variant={'filled'}
                                            >
                                                {organizations
                                                    .filter((o) =>
                                                        ability.can(
                                                            'organization:create-session',
                                                            o,
                                                        ),
                                                    )
                                                    .map((o) => {
                                                        return (
                                                            <MenuItem key={o.uuid} value={o.uuid}>
                                                                <Box
                                                                    sx={{
                                                                        alignItems: 'center',
                                                                        display: 'flex',
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        src={
                                                                            o.image_urls[
                                                                                'avatar'
                                                                            ] ?? ''
                                                                        }
                                                                        alt={o.name}
                                                                        variant="rounded"
                                                                        sx={{
                                                                            marginRight: 6,
                                                                            display: 'inline-block',
                                                                        }}
                                                                    />
                                                                    <Typography
                                                                        sx={{
                                                                            display: 'inline-block',
                                                                        }}
                                                                    >
                                                                        {o.name}
                                                                    </Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={(theme) => ({
                                    display: 'flex',
                                    marginTop: theme.spacing(10),
                                    padding: theme.spacing(12, 24),
                                    justifyContent: 'center',
                                    borderTop: '1px solid #EBEBEB',

                                    [theme.breakpoints.down('sm')]: {
                                        padding: theme.spacing(8),
                                    },
                                })}
                            >
                                <ButtonWithLoader
                                    loading={loading}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    size="large"
                                    disabled={!(isValid && dirty) || loading}
                                >
                                    Add Athlete
                                </ButtonWithLoader>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default CreateIndividualForm;
