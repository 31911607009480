import { Request } from 'api';
import { AxiosResponse } from 'axios';

import {
    GetNetworkingPayoutLogsParams,
    NetworkingPayoutLog,
    CommandLog,
} from './networkingPayoutLogs.types';

interface PaginatedApiResponse {
    data: Array<NetworkingPayoutLog>;
    links: any;
    meta: any;
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;

type CommandLogResponse = Promise<AxiosResponse<CommandLog[]>>;

export const getNetworkingPayoutLogsForAdmin = (
    params?: GetNetworkingPayoutLogsParams,
): ReturnTypeCollection => {
    const filterParams = {
        'filter[command_id]': params?.command_id,
        'filter[created_at]': params?.created_at,
        'filter[log]': params?.log,
        'filter[log_level]': params?.log_level,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/networking-payout-logs`, { params: filterParams });
};

export const getNetworkingPayoutJobList = (
    params?: GetNetworkingPayoutLogsParams,
): CommandLogResponse => {
    const filterParams = {
        'filter[created_at]': params?.created_at,
    };
    return Request.get(`admin/networking-payout-job-list`, { params: filterParams });
};

export type { GetNetworkingPayoutLogsParams };
