import React, { FC, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { confirmViaDialog, UploadLogo } from 'components';
import { Attribute, AttributeCategory, AttributeValue, Individual } from 'utils/types';
import AttributeInput from 'components/FormControl/AttributeInput';
import FormModal from '../../../components/Dialogs/FormModal';
import { EXIT_TEXT, SAVE_INFORMATION_TEXT, SAVE_TEXT, UNSAVED_CHANGES_TEXT } from 'utils';
import { useSelector } from 'react-redux';
import { getIndividualAttributes } from 'redux/reducers/attributes';
import useTheme from '@mui/material/styles/useTheme';

interface EditIndividualModalProps {
    individual: Individual;
    loading: boolean;
    open: boolean;
    onClose: () => void;
    onSave: (params: { attributes: AttributeValue[]; file?: File; name: string }) => void;
}

const EditIndividualModal: FC<React.PropsWithChildren<EditIndividualModalProps>> = ({
    individual,
    loading,
    open,
    onClose,
    onSave,
}) => {
    const [profilePhoto, setProfilePhoto] = useState<File>();
    const [individualName, setIndividualName] = useState(individual.name ?? '');
    const [attributeValues, setAttributeValues] = useState<AttributeValue[]>(
        individual.attributes ?? [],
    );
    const nameMaxValidationError = Boolean(individualName.length > 100);
    const attributes = useSelector(getIndividualAttributes);
    const theme = useTheme();
    const handleChangeAttribute = (values: AttributeValue[]): void => {
        setAttributeValues(values);
    };

    const handleButtonClick = () => {
        onSave({ file: profilePhoto, attributes: attributeValues, name: individualName });
    };

    const checkAttributes = () => {
        if (attributeValues.length !== individual?.attributes?.length) return true;
        for (let i = 0; i < attributeValues.length; i++) {
            if (attributeValues[i].name !== individual?.attributes[i].name) return true;
        }
        return false;
    };
    const onCancel = async () => {
        //if user changed name and cancels without saving then reverting it
        setIndividualName(individual.name);

        if (profilePhoto || checkAttributes()) {
            const res = await confirmViaDialog({
                confirmation: {
                    title: UNSAVED_CHANGES_TEXT,
                    message: SAVE_INFORMATION_TEXT,
                    cancelButtonText: EXIT_TEXT,
                    okButtonText: SAVE_TEXT,
                    onCancelPressed: () => onClose(),
                },
            });
            res && handleButtonClick();
        } else {
            onClose();
        }
    };

    return (
        <FormModal
            buttonText="Save"
            handleButtonClicked={handleButtonClick}
            handleClose={onCancel}
            loading={loading}
            open={open}
            disabled={nameMaxValidationError || !individualName.length}
            title="Edit Information"
        >
            <IconButton
                aria-label="close"
                sx={{
                    position: 'absolute',
                    right: theme.spacing(1),
                    top: theme.spacing(2.5),
                    color: theme.palette.grey[500],
                }}
                onClick={onCancel}
                size="large"
            >
                <CloseIcon />
            </IconButton>
            <UploadLogo
                button={false}
                defaultImgSrc={individual?.profile_photo}
                onClose={() => {
                    /* no-op */
                }}
                setUploadImage={setProfilePhoto}
                uploadContainerSx={{
                    width: '88px',
                    height: '88px',
                    borderRadius: '10%',
                    marginRight: '10px',
                }}
                uploadedImageSx={{
                    width: '88px',
                    height: '88px',
                    borderRadius: '10%',
                    marginRight: '10px',
                }}
                uploadImage={profilePhoto}
            />
            <TextField
                onChange={(e) => setIndividualName(e.target.value)}
                variant="filled"
                label="Name"
                error={nameMaxValidationError}
                helperText={nameMaxValidationError ? 'Name cannot exceed 100 characters' : ''}
                value={individualName}
                fullWidth
                sx={{ marginBlock: '20px 10px' }}
            />
            <Box>
                <AttributeInput
                    attributeCategory={AttributeCategory.Individual}
                    addText={attributeValues.length > 0 ? 'Add Another Tag' : 'Add Tag'}
                    attributes={(attributes || []) as Attribute[]}
                    onChange={handleChangeAttribute}
                    value={attributeValues}
                />
            </Box>
        </FormModal>
    );
};
export default EditIndividualModal;
