import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    IconButton,
    Input,
    LinearProgress,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { orange, teal } from '@mui/material/colors';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { getAllForms } from '../../../api';
import getAllSessions from '../../../api/Sessions/getAllSessions';
import { TypographyBodyOne } from '../../../components';
import { useNonInitialEffect } from '../../../hooks/useNonInitialEffect';
import { Form, Group, Session } from '../../../utils';
import { getAllDashboards } from '../api/dashboards.api';
import {
    batchUpdateSharedDashboards,
    batchUpdateSharedForms,
    batchUpdateSharedSessions,
    getGroupDashboards,
    getGroupForms,
    getGroupSessions,
} from '../api/groups.api';
import { Dashboard } from '../community.types';
import GroupDashboardsList from '../components/GroupDashboardsList';
import GroupFormsList from '../components/GroupFormsList';
import GroupSessionsList from '../components/GroupSessionsList';

interface Props {
    group: Group;
    onClose: () => void;
}

function ShareContentWithGroup(props: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [sessions, setSessions] = useState<Session[]>([]);
    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const [filteredSessions, setFilteredSessions] = useState<Session[]>([]);
    const [filteredDashboards, setFilteredDashboards] = useState<Dashboard[]>([]);
    const [filteredForms, setFilteredForms] = useState<Form[]>([]);
    const [selectedSessions, setSelectedSessions] = useState<Session[]>([]);
    const [selectedDashboards, setSelectedDashboards] = useState<Dashboard[]>([]);
    const [selectedForms, setSelectedForms] = useState<Form[]>([]);
    const [forms, setForms] = useState<Form[]>([]);
    const [sessionSearch, setSessionSearch] = useState<string>('');
    const [dashboardSearch, setDashboardSearch] = useState<string>('');
    const [formSearch, setFormSearch] = useState<string>('');
    const [clicks, setClicks] = useState<number>(0);
    const [formClicks, setFormClicks] = useState<number>(0);
    const [debouncedClicks] = useDebounce(clicks, 500);
    const [debouncedFormClicks] = useDebounce(formClicks, 500);
    const [tab, setTab] = useState<number>(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useNonInitialEffect(() => {
        batchUpdateSharedSessions(
            props.group.uuid,
            selectedSessions.map((s) => s.uuid),
        )
            .then(() => {})
            .catch(() => {});
    }, [debouncedClicks]); // eslint-disable-line react-hooks/exhaustive-deps

    useNonInitialEffect(() => {
        batchUpdateSharedDashboards(
            props.group.uuid,
            selectedDashboards.map((s) => s.uuid),
        )
            .then(() => {})
            .catch(() => {});
    }, [debouncedClicks]); // eslint-disable-line react-hooks/exhaustive-deps

    useNonInitialEffect(() => {
        batchUpdateSharedForms(
            props.group.uuid,
            selectedForms.map((s) => s.uuid),
        )
            .then(() => {})
            .catch(() => {});
    }, [debouncedFormClicks]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!formSearch) {
            setFilteredForms(forms);
            return;
        }

        const fuse = new Fuse(forms, {
            includeScore: false,
            keys: ['title', 'description'],
        });
        setFilteredForms(fuse.search(formSearch).map((result) => result.item));
    }, [formSearch, forms]);

    useEffect(() => {
        if (!sessionSearch) {
            setFilteredSessions(sessions);
            return;
        }

        const fuse = new Fuse(sessions, {
            includeScore: false,
            keys: ['title', 'description'],
        });
        setFilteredSessions(fuse.search(sessionSearch).map((result) => result.item));
    }, [sessionSearch, sessions]);

    useEffect(() => {
        if (!dashboardSearch) {
            setFilteredDashboards(dashboards);
            return;
        }

        const fuse = new Fuse(dashboards, {
            includeScore: false,
            keys: ['title', 'description'],
        });
        setFilteredDashboards(fuse.search(dashboardSearch).map((result) => result.item));
    }, [dashboardSearch, dashboards]);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getAllSessions({ limit: 250, 'filter[organization]': props.group.organization?.uuid }),
            getGroupSessions(props.group.uuid),
            getAllForms({ 'filter[organization]': props.group.organization?.uuid, limit: 250 }),
            getGroupForms(props.group.uuid),
            getAllDashboards({
                'filter[organization_uuid]': props.group.organization?.uuid,
            }),
            getGroupDashboards(props.group.uuid),
        ])
            .then(
                ([
                    sessionResponse,
                    selectedSessionsResponse,
                    formsResponse,
                    selectedFormsResponse,
                    dashboardsResponse,
                    selectedDashboardsResponse,
                ]) => {
                    setLoading(false);
                    setSessions(sessionResponse.data.data);
                    setForms(formsResponse.data.data);
                    setDashboards(dashboardsResponse.data);
                    setSelectedSessions(selectedSessionsResponse.data);
                    setSelectedForms(selectedFormsResponse.data);
                    setSelectedDashboards(selectedDashboardsResponse.data);
                },
            )
            .catch(() => {
                setLoading(false);
            });
    }, [props.group]);

    return (
        <Box
            style={{ height: '100%', overflowY: 'scroll' }}
            display={'flex'}
            flexDirection={'column'}
        >
            <Box
                style={{
                    backgroundColor: '#003E9D',
                    paddingTop: 12,
                    paddingBottom: 4,
                    paddingLeft: 24,
                    paddingRight: 24,
                    color: '#FFF',
                }}
            >
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <IconButton
                        style={{ color: '#FFF', marginRight: 10 }}
                        onClick={() => props.onClose()}
                        aria-label="close"
                        size="medium"
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Box>
                        <TypographyBodyOne style={{ color: '#FFF' }}>
                            Share with {props.group.name}
                        </TypographyBodyOne>
                    </Box>
                </Box>
            </Box>
            {loading && <LinearProgress />}
            <Tabs
                value={tab}
                onChange={(_, value) => setTab(value)}
                aria-label="share sessions, assessments and dashboards"
                variant={isMobile ? 'fullWidth' : 'standard'}
            >
                <Tab label="Sessions" />
                <Tab label="Assessments" />
                <Tab label="Dashboards" />
            </Tabs>
            {tab === 0 && (
                <>
                    <Box padding={8}>
                        <Input
                            disableUnderline={true}
                            startAdornment={<SearchIcon />}
                            placeholder="Search Sessions"
                            value={sessionSearch}
                            onChange={(e) => setSessionSearch(e.target.value)}
                            style={{
                                width: '100%',
                                borderRadius: '32px',
                                borderColor: teal[800],
                                borderStyle: 'solid',
                                borderWidth: 1,
                                padding: theme.spacing(5),
                                background: theme.palette.background.paper,
                            }}
                        />
                    </Box>
                    <Box position="relative" flex="1 1 0">
                        <Box position="absolute" top={0} bottom={0} left={0} right={0}>
                            <GroupSessionsList
                                onChange={(sessions) => {
                                    setSelectedSessions(sessions);
                                    setClicks((c) => c + 1);
                                }}
                                sessions={filteredSessions}
                                selectedSessions={selectedSessions}
                            />
                        </Box>
                    </Box>
                </>
            )}
            {tab === 1 && (
                <>
                    <Box padding={8}>
                        <Input
                            disableUnderline={true}
                            startAdornment={<SearchIcon />}
                            placeholder="Search Assessments"
                            value={formSearch}
                            onChange={(e) => setFormSearch(e.target.value)}
                            style={{
                                width: '100%',
                                borderRadius: '32px',
                                borderColor: theme.palette.secondary.main,
                                borderStyle: 'solid',
                                borderWidth: 1,
                                padding: theme.spacing(5),
                                background: theme.palette.background.paper,
                            }}
                        />
                    </Box>
                    <Box position="relative" flex="1 1 0">
                        <Box position="absolute" top={0} bottom={0} left={0} right={0}>
                            <GroupFormsList
                                onChange={(forms) => {
                                    setSelectedForms(forms);
                                    setFormClicks((c) => c + 1);
                                }}
                                forms={filteredForms}
                                selectedForms={selectedForms}
                            />
                        </Box>
                    </Box>
                </>
            )}
            {tab === 2 && (
                <>
                    <Box padding={8}>
                        <Input
                            disableUnderline={true}
                            startAdornment={<SearchIcon />}
                            placeholder="Search Dashboards"
                            value={sessionSearch}
                            onChange={(e) => setDashboardSearch(e.target.value)}
                            style={{
                                width: '100%',
                                borderRadius: '32px',
                                borderColor: orange[800],
                                borderStyle: 'solid',
                                borderWidth: 1,
                                padding: theme.spacing(5),
                                background: theme.palette.background.paper,
                            }}
                        />
                    </Box>
                    <Box position="relative" flex="1 1 0">
                        <Box position="absolute" top={0} bottom={0} left={0} right={0}>
                            <GroupDashboardsList
                                onChange={(dashboards) => {
                                    setSelectedDashboards(dashboards);
                                    setClicks((c) => c + 1);
                                }}
                                dashboards={filteredDashboards}
                                selectedDashboards={selectedDashboards}
                            />
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
}

export default ShareContentWithGroup;
