import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<
    AxiosResponse<{
        uuid: string;
        name: string;
    }>
>;

const createOrganization = (
    name: string,
    zip: string,
    type: string,
    logo?: string,
    referring_organization?: string,
    associated_user?: string,
    associated_user_is_owner?: boolean,
    parent_organization?: string,
): ReturnType => {
    const payload: {
        name: string;
        type: string;
        zip: string;
        logo?: string;
        referring_organization?: string;
        associated_user?: string;
        associated_user_is_owner?: boolean;
        parent_organization?: string;
    } = {
        name: name,
        type: type,
        zip: zip,
        referring_organization: referring_organization,
        associated_user: associated_user,
        associated_user_is_owner: associated_user_is_owner,
        parent_organization: parent_organization,
    };
    if (logo) {
        payload.logo = logo;
    }
    return Request.post(`/organizations`, payload);
};

export default createOrganization;
