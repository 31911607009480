import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { SharedUser } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<SharedUser>>>;

const getSharedUsers = (id: string): ReturnType => {
    return Request.get(`/workouts/sessions/${id}/users`);
};

export default getSharedUsers;
