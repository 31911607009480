import { Dialog, Slide } from '@mui/material';
import { CreateIndividualForm } from 'components';
import React, { ReactElement } from 'react';
import { Individual } from 'utils';
import { TransitionProps } from '@mui/material/transitions';

export interface CreateIndividualModalProps {
    onIndividualCreated: (individual: Individual) => void;
    onIndividualCreatedFailed: (message: string) => void;
    open?: boolean;
    onClose?: Function;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function CreateIndividualModal({
    onIndividualCreated,
    onIndividualCreatedFailed,
    open = true,
    onClose,
}: CreateIndividualModalProps): ReactElement {
    const handleIndividualCreated = (individual: Individual) => {
        onIndividualCreated(individual);
    };
    const handleIndividualCreationFailed = (message: string) => {
        onIndividualCreatedFailed(message);
    };
    return (
        <Dialog open={open} TransitionComponent={Transition}>
            <CreateIndividualForm
                onIndividualCreationFailed={handleIndividualCreationFailed}
                onIndividualCreated={handleIndividualCreated}
                onClose={onClose}
            />
        </Dialog>
    );
}
