import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{}>>;

const restorePassword = (
    email: string,
    password: string,
    passwordConfirmation: string,
    token: string,
): ReturnType => {
    return Request.post(`/authentication/password/reset`, {
        email,
        password,
        password_confirmation: passwordConfirmation,
        token,
    });
};
export default restorePassword;
