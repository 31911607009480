import React, { ReactElement, useState, useEffect } from 'react';
import { Container, Snackbar, Grid, Typography } from '@mui/material';
import OrganizationTile from '../components/OrganizationTile';
import getOrganizationsForAthleteUser from '../../../api/Organization/getOrganizationsForAthleteUser';
import { OrganizationPublic } from 'utils';

const AthleteHome = (): ReactElement => {
    const [snackMessage, setSnackMessage] = useState('');
    const [organizations, setOrganizations] = useState<OrganizationPublic[]>([]);

    useEffect(() => {
        fetchOrganizations();
    }, []);

    const fetchOrganizations = async () => {
        try {
            const response = await getOrganizationsForAthleteUser();
            setOrganizations(response.data);
        } catch (error) {
            setSnackMessage('Failed to fetch organizations');
        }
    };

    const handleOrganizationClick = (slug: string) => {
        window.open(`${window.location}/${slug}`, '_blank');
    };

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                paddingTop: 20,
            }}
            maxWidth={'xl'}
        >
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!snackMessage}
                autoHideDuration={6000}
                message={snackMessage}
                onClose={() => setSnackMessage('')}
            />
            <Typography variant="h5" component="h1" gutterBottom>
                My Organizations
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {organizations.map((org) => (
                    <Grid item key={org.uuid}>
                        <OrganizationTile
                            organization={org}
                            onClick={() => handleOrganizationClick(org.slug)}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default AthleteHome;
