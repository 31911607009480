import React from 'react';
import { Box, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import { DistanceUnit, LoadUnit, SetAttributeType, SpeedUnit, TimeDisplayFormat } from 'utils';
import { Delete, DirectionsRun, FitnessCenter, Settings, Speed, Timer } from '@mui/icons-material';
import { confirmViaDialog } from 'components/Dialogs/ConfirmationDialog';
import { useTheme } from '@mui/system';

export interface SessionMovementGoalSettingsProps {
    removeConfirmationMessage: string;
    disableRemove?: boolean | undefined;
    onRemoveConfirmed: () => void;
    onUnitChanged: (criteria: SetAttributeType, oldUnit: string, newUnit: string) => void;
    loadUnit: LoadUnit;
    distanceUnit: DistanceUnit;
    speedUnit: SpeedUnit;
    timeDisplayFormat: TimeDisplayFormat;
}

const SessionMovementGoalSettings = ({
    removeConfirmationMessage,
    onUnitChanged,
    onRemoveConfirmed,
    loadUnit,
    distanceUnit,
    speedUnit,
    timeDisplayFormat,
    disableRemove = false,
}: SessionMovementGoalSettingsProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const theme = useTheme();
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                sx={{
                    padddingTop: 0,
                    padddingBottom: 0,
                    [theme.breakpoints.down('md')]: {
                        padding: 0,
                    },
                }}
                onClick={handleClick}
                size="large"
            >
                <Settings />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {!disableRemove && (
                    <MenuItem
                        sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                        onClick={async () => {
                            if (
                                await confirmViaDialog({
                                    confirmation: {
                                        title: 'Are you sure?',
                                        message: removeConfirmationMessage,
                                    },
                                })
                            ) {
                                onRemoveConfirmed();
                                handleClose();
                            }
                        }}
                        dense={true}
                    >
                        <Delete fontSize="small" />
                        &nbsp; Remove From Session
                    </MenuItem>
                )}
                <MenuItem sx={{ color: theme.palette.text.secondary, fontSize: 14 }} dense={true}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%', gap: theme.spacing(8) }}
                    >
                        <div>
                            <FitnessCenter fontSize="small" />
                            &nbsp; Load Unit
                        </div>
                        <TextField
                            id="standard-select-currency"
                            select
                            value={loadUnit}
                            onChange={(e) => {
                                onUnitChanged(SetAttributeType.Load, loadUnit, e.target.value);
                                handleClose();
                            }}
                            sx={{
                                '& .MuiSelect-root': {
                                    fontSize: 14,
                                    color: theme.palette.text.secondary,
                                },
                            }}
                        >
                            <MenuItem
                                value={LoadUnit.Pound}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Pound
                            </MenuItem>
                            <MenuItem
                                value={LoadUnit.Kilogram}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Kilogram
                            </MenuItem>
                            <MenuItem
                                value={LoadUnit.Gram}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Gram
                            </MenuItem>
                        </TextField>
                    </Box>
                </MenuItem>
                <MenuItem sx={{ color: theme.palette.text.secondary, fontSize: 14 }} dense={true}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%', gap: theme.spacing(8) }}
                    >
                        <div>
                            <DirectionsRun fontSize="small" />
                            &nbsp; Distance Unit
                        </div>
                        <TextField
                            id="standard-select-currency"
                            select
                            value={distanceUnit}
                            onChange={(e) => {
                                onUnitChanged(
                                    SetAttributeType.Distance,
                                    distanceUnit,
                                    e.target.value,
                                );
                                handleClose();
                            }}
                            sx={{
                                '& .MuiSelect-root': {
                                    fontSize: 14,
                                    color: theme.palette.text.secondary,
                                },
                            }}
                        >
                            <MenuItem
                                value={DistanceUnit.Mile}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Mile
                            </MenuItem>
                            <MenuItem
                                value={DistanceUnit.Feet}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Feet
                            </MenuItem>
                            <MenuItem
                                value={DistanceUnit.Yard}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Yards
                            </MenuItem>
                            <MenuItem
                                value={DistanceUnit.Inch}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Inches
                            </MenuItem>
                            <MenuItem
                                value={DistanceUnit.Kilometer}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Kilometer
                            </MenuItem>
                            <MenuItem
                                value={DistanceUnit.Meter}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Meter
                            </MenuItem>
                            <MenuItem
                                value={DistanceUnit.Centimeter}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Centimeter
                            </MenuItem>
                        </TextField>
                    </Box>
                </MenuItem>
                <MenuItem sx={{ color: theme.palette.text.secondary, fontSize: 14 }} dense={true}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%', gap: theme.spacing(8) }}
                    >
                        <div>
                            <Speed fontSize="small" />
                            &nbsp; Speed Unit
                        </div>
                        <TextField
                            id="standard-select-currency"
                            select
                            value={speedUnit}
                            onChange={(e) => {
                                onUnitChanged(SetAttributeType.Speed, speedUnit, e.target.value);
                                handleClose();
                            }}
                            sx={{
                                '& .MuiSelect-root': {
                                    fontSize: 14,
                                    color: theme.palette.text.secondary,
                                },
                            }}
                        >
                            <MenuItem
                                value={SpeedUnit.MPH}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Miles Per Hour
                            </MenuItem>
                            <MenuItem
                                value={SpeedUnit.KPH}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Kilometers Per Hour
                            </MenuItem>
                            <MenuItem
                                value={SpeedUnit.MS}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Meters Per Second
                            </MenuItem>
                        </TextField>
                    </Box>
                </MenuItem>
                <MenuItem sx={{ color: theme.palette.text.secondary, fontSize: 14 }} dense={true}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%', gap: theme.spacing(8) }}
                    >
                        <div>
                            <Timer fontSize="small" />
                            &nbsp; Time Unit
                        </div>
                        <TextField
                            id="standard-select-currency"
                            select
                            value={timeDisplayFormat}
                            onChange={(e) => {
                                onUnitChanged(
                                    SetAttributeType.Time,
                                    timeDisplayFormat,
                                    e.target.value,
                                );
                                handleClose();
                            }}
                            sx={{
                                '& .MuiSelect-root': {
                                    fontSize: 14,
                                    color: theme.palette.text.secondary,
                                },
                            }}
                        >
                            <MenuItem
                                value={TimeDisplayFormat.Second}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Basic/Seconds (seconds)
                            </MenuItem>
                            <MenuItem
                                value={TimeDisplayFormat.Meet}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Track Meet (seconds.milliseconds)
                            </MenuItem>
                            <MenuItem
                                value={TimeDisplayFormat.Run}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Run/Jog (minutes:seconds.milliseconds)
                            </MenuItem>
                            <MenuItem
                                value={TimeDisplayFormat.Common}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Common/Short Duration (minutes:seconds)
                            </MenuItem>
                            <MenuItem
                                value={TimeDisplayFormat.Duration}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Marathon/Long Duration (hours:minutes:seconds)
                            </MenuItem>
                            <MenuItem
                                value={TimeDisplayFormat.DurationWithPrecision}
                                sx={{ color: theme.palette.text.secondary, fontSize: 14 }}
                            >
                                Long Duration with milliseconds (hours:minutes:seconds.milliseconds)
                            </MenuItem>
                        </TextField>
                    </Box>
                </MenuItem>
            </Menu>
        </>
    );
};

export default SessionMovementGoalSettings;
