import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Organization } from 'utils';
import { Box, Button } from '@mui/material';
import SearchWithResults from './SearchWithResults';
import { LinearProgressWithOverlay } from 'components/Functional/LinearProgressWithOverlay';
import { SearchResult } from '.';

export interface SearchUserProps {
    anchorEl?: any;
    isOpen: boolean;
    inputValue: string;
    setInputValue: any;
    breakPoint: boolean;
    isFiltered: boolean;
    searchResults: Array<SearchResult>;
    loading: boolean;
    handleClose: () => void;
    onCreateClicked: () => void;
    onResultSelected: (result: SearchResult) => void;
    showOverlay?: boolean;
    organization?: Organization;
    createButtonText?: string;
    placeholderText?: string;
    multi?: boolean;
    selectedResults?: Array<string>;
    onMultiButtonClicked?: () => void;
    createButtonVisible?: boolean;
}

const Search = ({
    isOpen = false,
    handleClose,
    inputValue,
    setInputValue,
    searchResults,
    breakPoint,
    isFiltered,
    loading,
    onResultSelected,
    onCreateClicked,
    organization,
    showOverlay = false,
    createButtonText = 'Create New Athlete',
    placeholderText = 'Find athlete',
    multi = false,
    selectedResults = [],
    onMultiButtonClicked = () => {},
    createButtonVisible = true,
}: SearchUserProps) => {
    return (
        <>
            {isOpen && (
                <Dialog
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    fullScreen={!breakPoint}
                    open={isOpen}
                    onClose={handleClose}
                >
                    <LinearProgressWithOverlay loading={showOverlay} />
                    <Box
                        sx={{
                            position: 'relative',
                            height: 'auto',
                            overflowY: 'auto',
                            flexGrow: 1,
                        }}
                    >
                        <SearchWithResults
                            onResultSelected={onResultSelected}
                            closeableInput={breakPoint}
                            isFiltered={isFiltered}
                            handleClose={handleClose}
                            inputValue={inputValue}
                            handleCreateClicked={onCreateClicked}
                            setInputValue={setInputValue}
                            searchResults={searchResults}
                            loading={loading}
                            organization={organization}
                            buttonText={createButtonText}
                            placeholderText={placeholderText}
                            multi={multi}
                            selectedResults={selectedResults}
                            createButtonVisible={createButtonVisible}
                        />
                    </Box>
                    {multi && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexShrink: 1,
                                padding: 4,
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                onClick={onMultiButtonClicked}
                                disabled={selectedResults.length > 20}
                                size={'small'}
                                color={'primary'}
                                variant={'contained'}
                            >
                                {selectedResults.length < 2
                                    ? `Select (${selectedResults.length})`
                                    : `Selected (${selectedResults.length})`}
                                {selectedResults.length > 20 ? ' (Max 20)' : ''}
                            </Button>
                        </Box>
                    )}
                </Dialog>
            )}
        </>
    );
};

export default Search;
