import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Session } from 'utils';

type ReturnType = Promise<AxiosResponse<Session>>;

const getSession = (id: string): ReturnType => {
    return Request.get(`/workouts/sessions/${id}`);
};

export default getSession;
