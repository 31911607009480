import { styled } from '@mui/system';
import Typography, { TypographyProps } from '@mui/material/Typography';

const TypographyLargeText = styled(Typography)<TypographyProps>(({ theme, style }) => ({
    fontWeight: 500,
    color: theme.palette.text.secondary,
    fontSize: 15,
    lineHeight: 0.4,
    textTransform: 'none',
    ...style,
}));

export default TypographyLargeText;
