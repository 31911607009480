import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { DataGridPremium, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import {
    getPhysicalProducts,
    createPhysicalProduct,
    updatePhysicalProduct,
    GetPhysicalProductParams,
    getPhysicalProductCategories,
} from '../physicalProducts.api';
import { CreatePhysicalProductParams, PhysicalProduct } from '../physicalProducts';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';

const PhysicalProductList = () => {
    const [physicalProducts, setPhysicalProducts] = useState<PhysicalProduct[]>([]);
    const [physicalProductCategories, setPhysicalProductCategories] = useState<string[]>([]);
    const [physicalProductsSearchParams] = useState<GetPhysicalProductParams>({
        name: '',
        product_category: '',
        sort: 'inventory_count',
    });

    // Form data for create new physical product form.
    const [newPhysicalProductData, setNewPhysicalProductData] = useState({
        name: '',
        product_category: '',
        description: '',
        stripe_product_id: '',
        stripe_price_id: '',
        is_active: false,
        inventory_count: 0,
    });

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [editedRows, setEditedRows] = useState<{ [key: string]: { [field: string]: boolean } }>(
        {},
    );
    const [selectedCategory, setSelectedCategory] = useState<string>('All');

    const dataGridSx = {
        '& .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
            {
                outline: 'none',
            },
        '& .MuiDataGrid-cell': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
            backgroundColor: 'transparent',
        },
        '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
            backgroundColor: 'transparent',
        },
        '& .MuiDataGrid-row.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover, & .MuiDataGrid-row.Mui-selected.Mui-hovered':
            {
                backgroundColor: 'transparent',
            },
    };

    const CategoryFilter = () => (
        <FormControl
            fullWidth
            margin="normal"
            size="small"
            style={{ width: '200px', marginBottom: '0' }}
        >
            <InputLabel>Filter by Category</InputLabel>
            <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Filter by Category"
            >
                <MenuItem value="All">All Categories</MenuItem>
                {physicalProductCategories.map((category, index) => (
                    <MenuItem key={index} value={category}>
                        {category}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const handleCategoryChange = (event: SelectChangeEvent) => {
        setSelectedCategory(event.target.value);
    };

    useEffect(() => {
        getPhysicalProducts()
            .then((response) => {
                let filteredProducts = response.data.data;
                if (selectedCategory !== 'All') {
                    filteredProducts = filteredProducts.filter(
                        (product) => product.product_category === selectedCategory,
                    );
                }
                setPhysicalProducts(filteredProducts);
            })
            .catch((error) => {
                setSnackbarMessage('Failed to fetch physical products');
                setSnackbarOpen(true);
                console.error('Failed to fetch physical products', error);
            });
    }, [selectedCategory]);

    useEffect(() => {
        getPhysicalProducts()
            .then((response) => {
                setPhysicalProducts(response.data.data);
            })
            .catch((error) => {
                setSnackbarMessage('Failed to fetch physical products');
                setSnackbarOpen(true);
                console.error('Failed to fetch physical products', error);
            });

        getPhysicalProductCategories()
            .then((response) => {
                setPhysicalProductCategories(response.data);
            })
            .catch((error) => {
                setSnackbarMessage('Failed to fetch physical product categories');
                setSnackbarOpen(true);
                console.error('Failed to fetch physical product categories', error);
            });
    }, []);

    const openDialogModal = (title: string, message: string) => {
        setDialogTitle(title);
        setDialogMessage(message);
        setOpenDialog(true);
    };

    const closeDialogModal = () => {
        setOpenDialog(false);
    };

    const handleFieldChange = (
        value: string | number | boolean,
        row: PhysicalProduct,
        field: keyof PhysicalProduct,
    ) => {
        const updatedValue = typeof value === 'string' ? value : String(value);

        const updatedProducts = physicalProducts.map((product) =>
            product.uuid === row.uuid ? { ...product, [field]: updatedValue } : product,
        );
        setPhysicalProducts(updatedProducts);
        setEditedRows((prev) => ({
            ...prev,
            [row.uuid]: { ...prev[row.uuid], [field]: true },
        }));
    };

    // Function to handle physical product form submission
    const handleNewPhysicalProductSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!newPhysicalProductData.name) {
            openDialogModal('Error', 'Please set a name for this product.');
            return;
        }

        if (!newPhysicalProductData.description) {
            openDialogModal('Error', 'Please set a description for this product.');
            return;
        }

        if (!newPhysicalProductData.stripe_product_id) {
            openDialogModal('Error', 'Please set a stripe product id for this product.');
            return;
        }

        try {
            const physicalProductData: CreatePhysicalProductParams = {
                name: newPhysicalProductData.name,
                product_category: newPhysicalProductData.product_category,
                description: newPhysicalProductData.description,
                stripe_product_id: newPhysicalProductData.stripe_product_id,
                stripe_price_id: newPhysicalProductData.stripe_price_id,
                is_active: newPhysicalProductData.is_active,
                inventory_count: newPhysicalProductData.inventory_count,
            };

            await createPhysicalProduct(physicalProductData);

            // Clear the form
            setNewPhysicalProductData({
                name: '',
                description: '',
                product_category: '',
                stripe_product_id: '',
                stripe_price_id: '',
                is_active: false,
                inventory_count: 0,
            });

            // Fetch the updated list of physical products
            const response = await getPhysicalProducts(physicalProductsSearchParams);
            setPhysicalProducts(response.data.data);
            openDialogModal('Success', `Product created successfully.`);
        } catch (error: any) {
            openDialogModal('Error', error.response.data.message);
            console.error(error);
        }
    };

    const handleIsActiveChange = (event: SelectChangeEvent<unknown>, row: PhysicalProduct) => {
        const updatedProducts = physicalProducts.map((product) =>
            product.uuid === row.uuid
                ? { ...product, is_active: event.target.value === 'true' }
                : product,
        );
        setPhysicalProducts(updatedProducts);
        setEditedRows((prev) => ({
            ...prev,
            [row.uuid]: { ...prev[row.uuid], is_active: true },
        }));
    };

    const handleInventoryCountChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        row: PhysicalProduct,
    ) => {
        const newValue = parseInt(event.target.value, 10);

        const updatedProducts = physicalProducts.map((product) =>
            product.uuid === row.uuid ? { ...product, inventory_count: newValue } : product,
        );
        setPhysicalProducts(updatedProducts);
        setEditedRows((prev) => ({
            ...prev,
            [row.uuid]: { ...prev[row.uuid], inventory_count: true },
        }));
    };

    const physicalProductColumns: GridColDef<PhysicalProduct, any, any>[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            renderCell: (params: GridRenderCellParams<PhysicalProduct>) => (
                <TextField
                    value={params.value}
                    onChange={(e) => {
                        handleFieldChange(e.target.value, params.row, 'name');
                    }}
                    onKeyDown={(e) => {
                        if (e.key === ' ') {
                            e.stopPropagation();
                        }
                    }}
                    size="small"
                    fullWidth
                    sx={{ height: '80%' }}
                    InputProps={{
                        sx: { height: '100%' },
                    }}
                />
            ),
        },
        {
            field: 'product_category',
            headerName: 'Product Category',
            width: 200,
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 350,
            renderCell: (params: GridRenderCellParams<PhysicalProduct>) => (
                <TextField
                    value={params.value}
                    onChange={(e) => {
                        handleFieldChange(e.target.value, params.row, 'description');
                    }}
                    onKeyDown={(e) => {
                        if (e.key === ' ') {
                            e.stopPropagation();
                        }
                    }}
                    size="small"
                    fullWidth
                    multiline
                    sx={{ height: '80%' }}
                    InputProps={{
                        sx: { height: '100%' },
                    }}
                />
            ),
        },
        {
            field: 'stripe_product_id',
            headerName: 'Stripe Product ID',
            width: 200,
            editable: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams<PhysicalProduct, any, any>) => (
                <a
                    href={`https://dashboard.stripe.com/products/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        {
            field: 'stripe_price_id',
            headerName: 'Stripe Price ID',
            width: 200,
            editable: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams<PhysicalProduct, any, any>) => (
                <a
                    href={`https://dashboard.stripe.com/prices/${params.value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        {
            field: 'inventory_count',
            headerName: 'Inventory Count',
            width: 150,
            editable: false,
            type: 'number',
            sortable: false,
            renderCell: (params: GridRenderCellParams<PhysicalProduct>) => (
                <TextField
                    type="number"
                    value={params.value}
                    onChange={(event) => handleInventoryCountChange(event, params.row)}
                    size="small"
                    inputProps={{ min: 0 }}
                    sx={{ height: '80%' }}
                    InputProps={{
                        sx: { height: '100%' },
                    }}
                />
            ),
        },
        {
            field: 'is_active',
            headerName: 'Is Active',
            width: 150,
            editable: false,
            sortable: false,
            renderCell: (params: GridRenderCellParams<PhysicalProduct>) => (
                <FormControl size="small" fullWidth sx={{ height: '80%' }}>
                    <Select
                        value={params.value ? 'true' : 'false'}
                        onChange={(event) => handleIsActiveChange(event, params.row)}
                        displayEmpty
                        size="small"
                        fullWidth
                        sx={{ height: '100%' }}
                    >
                        <MenuItem value="true">Active</MenuItem>
                        <MenuItem value="false">Inactive</MenuItem>
                    </Select>
                </FormControl>
            ),
        },
        {
            field: 'edit',
            headerName: '',
            width: 200,
            sortable: false,
            renderCell: (params: GridRenderCellParams<PhysicalProduct>) => (
                <Button
                    onClick={() => handleUpdateProduct(params.row)}
                    size="small"
                    variant="contained"
                    disabled={
                        !editedRows[params.row.uuid] ||
                        !Object.values(editedRows[params.row.uuid]).some(Boolean)
                    }
                >
                    Update
                </Button>
            ),
            align: 'right',
        },
    ];

    const handleUpdateProduct = async (rowData: PhysicalProduct) => {
        const updatedData: CreatePhysicalProductParams = {
            name: rowData.name,
            product_category: rowData.product_category,
            description: rowData.description,
            stripe_product_id: rowData.stripe_product_id,
            stripe_price_id: rowData.stripe_price_id,
            is_active: rowData.is_active,
            inventory_count: rowData.inventory_count,
        };

        try {
            await updatePhysicalProduct(rowData.uuid, updatedData);
            setSnackbarMessage('Product updated successfully.');
            setSnackbarOpen(true);
            const updatedProducts = await getPhysicalProducts();
            setPhysicalProducts(updatedProducts.data.data);
            setEditedRows((prev) => {
                const newEditedRows = { ...prev };
                delete newEditedRows[rowData.uuid];
                return newEditedRows;
            });
        } catch (error) {
            setSnackbarMessage('Error updating product.');
            setSnackbarOpen(true);
            console.error('Failed to update product', error);
        }
    };

    const handleNewPhysicalProductParameterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = event.target;

        // Check if the field being updated is inventory_count
        if (name === 'inventory_count') {
            // Parse the value to an integer
            const intValue = parseInt(value, 10);

            // If the parsed value is negative, prevent the update by returning early
            // or setting it to a default value like 0
            if (intValue < 0) {
                setNewPhysicalProductData({
                    ...newPhysicalProductData,
                    [name]: 0, // Resetting to 0 if negative value is entered
                });
                return; // Prevent further execution for negative values
            }
        }

        // Update the state for all other cases
        setNewPhysicalProductData({
            ...newPhysicalProductData,
            [name]: value,
        });
    };

    // @ts-ignore
    return (
        <Box style={{ marginLeft: '2rem' }}>
            <h2>Physical Products</h2>
            <section style={{ marginBottom: '2rem', maxWidth: '300px' }}>
                <h4>Create New Physical Product</h4>
                <form onSubmit={handleNewPhysicalProductSubmit} noValidate>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Product Name"
                            name="name"
                            value={newPhysicalProductData.name}
                            onChange={handleNewPhysicalProductParameterChange}
                            placeholder="Enter Product Name"
                            size="small"
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal" size="small">
                        <InputLabel>Product Category</InputLabel>
                        <Select
                            label="Product Category"
                            id="product_category"
                            value={newPhysicalProductData.product_category}
                            onChange={(event) =>
                                setNewPhysicalProductData({
                                    ...newPhysicalProductData,
                                    product_category: event.target.value,
                                })
                            }
                            size="small"
                        >
                            {physicalProductCategories.map((category, index) => (
                                <MenuItem key={index} value={category}>
                                    {category}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Description"
                            name="description"
                            value={newPhysicalProductData.description}
                            onChange={handleNewPhysicalProductParameterChange}
                            placeholder="Enter Description"
                            size="small"
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Stripe Product ID"
                            name="stripe_product_id"
                            value={newPhysicalProductData.stripe_product_id}
                            onChange={handleNewPhysicalProductParameterChange}
                            placeholder="Enter Stripe Product ID"
                            size="small"
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Stripe Price ID"
                            name="stripe_price_id"
                            value={newPhysicalProductData.stripe_price_id}
                            onChange={handleNewPhysicalProductParameterChange}
                            placeholder="Enter Stripe Price ID"
                            size="small"
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            label="Inventory Count"
                            name="inventory_count"
                            value={newPhysicalProductData.inventory_count}
                            onChange={handleNewPhysicalProductParameterChange}
                            placeholder="Enter Inventory Count"
                            type="number"
                            size="small"
                            variant="outlined"
                        />
                    </FormControl>
                    <FormControl fullWidth margin="normal" size="small">
                        <InputLabel>Is Active</InputLabel>
                        <Select
                            label="Is Active"
                            id="is_active"
                            value={String(newPhysicalProductData.is_active)}
                            onChange={(event) =>
                                setNewPhysicalProductData({
                                    ...newPhysicalProductData,
                                    is_active: event.target.value === 'true',
                                })
                            }
                            size="small"
                        >
                            <MenuItem value="true">Active</MenuItem>
                            <MenuItem value="false">Inactive</MenuItem>
                        </Select>
                    </FormControl>
                    <Button type="submit" size="medium" variant="contained" sx={{ mt: 2 }}>
                        Create
                    </Button>
                </form>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h4>Physical Products</h4>
                <CategoryFilter />
            </section>
            <Box width="98%" maxHeight="600px" overflow="auto">
                <DataGridPremium
                    rows={physicalProducts}
                    columns={physicalProductColumns}
                    checkboxSelection={false}
                    sortingMode="server"
                    getRowId={(row) => row.uuid}
                    style={{ height: '624px', marginBottom: '50px' }}
                    sx={dataGridSx}
                    disableColumnMenu={true}
                />
            </Box>
            <Dialog
                open={openDialog}
                onClose={closeDialogModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Paper>
                    <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialogModal} variant="contained" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setSnackbarOpen(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </Box>
    );
};

export default PhysicalProductList;
