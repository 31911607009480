import React from 'react';
import QRCode from 'react-qr-code';

const AppQRCode = () => {
    const appUrl = 'https://apps.apple.com/us/app/fiyrpod-athlete/id6479374909';

    return (
        <div
            style={{ marginTop: 30 }}
            className="mt-5 flex flex-col items-center justify-center p-6 bg-gradient-to-b from-gray-50 to-white"
        >
            <div className="bg-white rounded-3xl shadow-xl p-8 w-full max-w-sm">
                {/* QR Code Container */}
                <div className="bg-black p-6 rounded-2xl mb-6">
                    <div className="bg-white p-4 rounded-xl">
                        <QRCode
                            value={appUrl}
                            size={256}
                            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </div>

                {/* App Info */}
                <div className="text-center">
                    <p className="text-sm text-gray-600 mb-4">
                        Scan to download from the Apple App Store
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AppQRCode;
