import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ data: string }>>;

const getChatToken = (): ReturnType => {
    return Request.get(`/chat/token?platform=web`);
};

export default getChatToken;
