import { AxiosResponse } from 'axios';
import { Request } from 'api';

type ReturnType = Promise<AxiosResponse<Array<any>>>;

const getFeed = (query: { page: number; status?: string }): ReturnType => {
    return Request.get(`/social/feed`, { params: query });
};

export default getFeed;
