import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Container, Grid, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CustomButton, TypographyTitleh6 } from '../../../components';
import { useHistory, useParams } from 'react-router-dom';
import { dayOfYear, UserProfile } from '../../../utils';
import { FeedActivityCard, SocialProfileCard, SocialProfileCardMobile } from '../components';
import moment from 'moment';
import { feedActions } from '../../../redux/reducers/social';
import { useAppDispatch } from '../../../redux/store';
import ROUTES from '../../../Routes/routes';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../redux/reducers/auth';
import { getSocialFeed, getSocialProfile } from '../api';
import { userSelector } from '../../../redux/reducers/user';
import { loadMyProfile, myProfileSelector } from '../../../redux/reducers/social/profile';

export default function SocialProfile(): ReactElement {
    const [profileLoading, setProfileLoading] = useState(false);
    const [feedLoading, setFeedLoading] = useState(false);
    const [profile, setProfile] = useState<UserProfile>();
    const theme = useTheme();
    const [isFollowing, setIsFollowing] = useState<boolean>(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [feed, setFeed] = useState<any[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [hasAnotherPage, setHasAnotherPage] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const dispatch = useAppDispatch();
    const { push } = useHistory();
    const { isAuth } = useSelector(authSelector);
    const { userData } = useSelector(userSelector);
    const { profile: myProfile } = useSelector(myProfileSelector);

    useEffect(() => {
        if (userData) {
            dispatch(loadMyProfile());
        }
    }, [dispatch, userData]);

    const { id } = useParams<{ id: string }>();

    const onNextPage = () => {
        setPage((page) => page + 1);
        setFeedLoading(true);
        getSocialFeed(id, page)
            .then((response) => {
                setFeed((feed) => [...feed, ...response.data.data]);
                setHasAnotherPage(!!response.data.next_page_url);
            })
            .finally(() => setFeedLoading(false));
    };

    const handleFollowClicked = () => {
        if (!isAuth) {
            push(ROUTES.Login.path, {
                referrer: `/@${profile?.slug ?? ''}`,
                referrer_message: `Sign in to follow ${profile?.name}`,
            });
            return;
        }
        setIsFollowing(true);
        setProfile((profile) => {
            if (profile) {
                profile.followers_count++;

                return profile;
            }
            return profile;
        });
        dispatch(feedActions.follow({ user: id }));
    };
    const handleUnfollowClicked = () => {
        setIsFollowing(false);
        setProfile((profile) => {
            if (profile) {
                profile.followers_count--;

                return profile;
            }
            return profile;
        });
        dispatch(feedActions.unfollow({ user: id }));
    };

    useEffect(() => {
        setProfileLoading(true);

        Promise.all([getSocialProfile(id), getSocialFeed(id)])
            .then((responses) => {
                const [profileResponse, socialFeedResponse] = responses;
                setProfile(profileResponse.data.data);
                setIsFollowing(profileResponse.data.meta.isFollowing);
                setFeed(socialFeedResponse.data.data);
                setHasAnotherPage(!!socialFeedResponse.data.next_page_url);
            })
            .catch(() => {})
            .finally(() => {
                setProfileLoading(false);
                setFeedLoading(false);
                setLoaded(true);
            });
    }, [id]);
    return (
        <Container maxWidth={'lg'}>
            <Grid container sx={{ padding: '36px 16px' }}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    {profileLoading && <Skeleton variant="rectangular" width={360} height={375} />}
                    {!profileLoading && !!profile && (
                        <>
                            {!isMobile ? (
                                <SocialProfileCard
                                    bio={profile.bio ?? ''}
                                    slug={profile.slug}
                                    canFollow={true}
                                    isFollowing={isFollowing}
                                    onFollowClicked={() => handleFollowClicked()}
                                    onUnfollowClicked={() => handleUnfollowClicked()}
                                    user={profile}
                                    isMyProfile={myProfile?.slug === profile.slug}
                                />
                            ) : (
                                <SocialProfileCardMobile
                                    bio={profile.bio ?? ''}
                                    canFollow={true}
                                    isFollowing={isFollowing}
                                    onFollowClicked={() => handleFollowClicked()}
                                    onUnfollowClicked={() => handleUnfollowClicked()}
                                    user={profile}
                                    isMyProfile={myProfile?.slug === profile.slug}
                                />
                            )}
                        </>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    sx={{ paddingTop: theme.spacing(0), width: '100%' }}
                >
                    {feed.length === 0 && loaded && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{ height: '100%' }}
                        >
                            <Typography variant="h6" color="primary">
                                NO POSTS
                            </Typography>
                        </Box>
                    )}
                    {feed.length > 0 && loaded && (
                        <Grid item>
                            {feed.map((item, index) => (
                                <Grid key={index} item sx={{ width: '100%' }}>
                                    {index === 0 ||
                                    (index !== 0 &&
                                        feed[index - 1] &&
                                        dayOfYear(feed[index - 1].created) !==
                                            dayOfYear(item.created)) ? (
                                        <TypographyTitleh6 color="primary">
                                            {moment(item.created * 1000)
                                                .local()
                                                .format('MMMM DD, YYYY')}
                                        </TypographyTitleh6>
                                    ) : (
                                        ''
                                    )}
                                    <FeedActivityCard
                                        initialExpandedState={false}
                                        sessionTitle={item.session_name ?? ''}
                                        sessionCoverPhoto={item.session_cover_photo ?? ''}
                                        logs={item.logs}
                                        title={`${item.individual_name} completed ${item.session_name}`}
                                        subheader={moment(item.date * 1000).calendar()}
                                        description={item.session_description ?? ''}
                                        onPostClicked={() => {}}
                                        onDeleteClicked={() => {}}
                                        isPublic={item.public}
                                        avatar={item.profile_photo[200]}
                                        avatarName={item.user_full_name}
                                        showActions={false}
                                    />
                                </Grid>
                            ))}
                            <Grid item sx={{ width: '100%' }}>
                                <CustomButton
                                    disabled={!hasAnotherPage || feedLoading}
                                    onClick={onNextPage}
                                >
                                    view more
                                </CustomButton>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}
