import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import RemoteGPSCoaching from '../media/RemoteGPSCoaching.png';
import StandardVideoAnalysis from '../media/StandardVideoAnalysis.png';
import AthleteSprintDatabaseViews from '../media/AthleteSprintDatabaseView.png';
import VideoAnalysisDetailedReport from '../media/VideoAnalysisDetailedReport.png';
import GroupReportingTemplate from '../media/GroupReportingTemplate.png';
import ShareLinkGroupAccess from '../media/ShareLinkGroupAccess.png';
import FullScreenDialogCard from '../components/HowToDialogCard';

function AIVideoOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Experience the future of remote coaching with 1TUL. Whether you&apos;re a
                        coach overseeing a team or an athlete striving for improvement, our platform
                        empowers you to connect and collaborate seamlessly from anywhere in the
                        world. Harness the power of cutting-edge technology, AI-driven insights, and
                        real-time data analysis to enhance your coaching experience. Stay connected,
                        track progress, and elevate your coaching game to new heights.
                    </Typography>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={RemoteGPSCoaching}
                    title="Remote GPS Coaching"
                    headline="Remote GPS Coaching"
                    description="Easily observe GPS data from McLloyd, Catapult, or other providers and provide meaningful feedback."
                />

                <FullScreenDialogCard
                    image={StandardVideoAnalysis}
                    title="Remote Video Analysis"
                    headline="Remote Video Analysis"
                    description="Athletes can upload their own videos and coaches can provide feedback."
                />
                <FullScreenDialogCard
                    image={VideoAnalysisDetailedReport}
                    title="Remote AI Video Analysis"
                    headline="Remote AI Video Analysis"
                    description="Athletes can upload their own videos and 1TUL will process it through our machine learning system to provide you with insightful measurements to augment your coaching and feedback."
                />

                {/* First row */}
                <FullScreenDialogCard
                    image={ShareLinkGroupAccess}
                    title="Share Link Group Access"
                    headline="Share Link Group Access"
                    description="Unleash the power of Share Link Group Access to quickly scale to thousands of users."
                />
                <FullScreenDialogCard
                    image={GroupReportingTemplate}
                    title="Group Reporting Template"
                    headline="Group Reporting Template"
                    description="Easily standardize your group KPI's. Make one report and dashboard for the group and each athlete will have their own copy of the report and dashboard that includes only their data."
                />
                <FullScreenDialogCard
                    image={AthleteSprintDatabaseViews}
                    title="Database Views"
                    headline="Database Views"
                    description="Database views allow you to quickly see all your athletes and their data in one spot. You can also filter and sort the data to quickly find the athlete you are looking for."
                />
            </Grid>
        </>
    );
}

export default AIVideoOverview;
