import NavigationLayout from '../../Layouts/NavigationLayout';
import MarketplaceHome from './scenes/MarketplaceHome';
import MarketplaceItem from './scenes/MarketplaceItem';

export const marketplaceRoutes = [
    {
        layout: NavigationLayout,
        path: '/marketplace/:orgUuidOrSlug',
        component: MarketplaceHome,
        private: true,
        exact: true,
    },
    {
        layout: NavigationLayout,
        path: '/marketplace/:orgUuidOrSlug/:itemId',
        component: MarketplaceItem,
        private: true,
    },
];
