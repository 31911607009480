import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

interface Props extends SvgIconProps {
    checked: boolean;
}

function CheckIconCircle(props: Props) {
    return (
        <SvgIcon
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle
                cx="12.5"
                cy="12"
                r="12"
                fill={props.checked ? '#4CAF50' : 'none'}
                stroke={props.checked ? 'none' : '#00000042'}
            />
            <path
                d="M10.2501 15.1275L7.12262 12L6.05762 13.0575L10.2501 17.25L19.2501 8.25L18.1926 7.1925L10.2501 15.1275Z"
                fill={props.checked ? 'white' : '#00000042'}
            />
        </SvgIcon>
    );
}

export default CheckIconCircle;
