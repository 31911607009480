import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { AdminTreeNodes, AdminRank } from './binaryTree.types';

export interface BinaryTreeOperation {
    type: 'detach' | 'attach' | 'delete';
    nodeId: string;
    nodeName?: string;
    fromParentId?: string;
    fromParentName?: string;
    toParentId?: string;
    toParentName?: string;
    leg?: 'L' | 'R';
}

const adminGetBinaryTree = (): Promise<AxiosResponse<AdminTreeNodes>> => {
    return Request.get(`admin/binary-tree`);
};

/**
 * @param operations - array of operations to perform on the binary tree: attach, detach, delete
 */
const adminUpdateBinaryTree = (operations: BinaryTreeOperation[]): Promise<AxiosResponse<any>> => {
    return Request.post(`admin/binary-tree/update`, { operations });
};

const adminChangeSponsor = (
    partnerUuid: string,
    newSponsorUuid: string,
): Promise<AxiosResponse<any>> => {
    return Request.post(`admin/binary-tree/${partnerUuid}/change-sponsor`, { newSponsorUuid });
};

const adminGetRanks = ({
    filters,
}: {
    filters: Record<any, any>;
}): Promise<AxiosResponse<AdminRank[]>> => {
    return Request.get(`/admin/ranks`, {
        params: filters,
    });
};

export { adminGetBinaryTree, adminGetRanks, adminUpdateBinaryTree, adminChangeSponsor };
