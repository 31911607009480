import { Meta, Query } from '@cubejs-client/core';
import * as chrono from 'chrono-node';
import { DateTime } from 'luxon';
import React from 'react';
import { PieProps } from 'recharts';
import { TimezoneKey } from '../../../utils';
import { CommunityReportFilters, SavedFilterType } from '../community.types';
import ChartRenderer from './Charting/ChartRenderer';

interface Props {
    filters: CommunityReportFilters;
    cubeMeta: Meta;
    width?: number | string;
    height?: number | string;
    hideLegend?: boolean;
    plotMissingOnChart?: boolean;
    hideTooltip?: boolean;
    hideXAxis?: boolean;
    hideYAxis?: boolean;
    pieOptions?: Partial<PieProps>;
    type: SavedFilterType;
    simplifiedView?: boolean;
    limit?: number;
}

const TimeseriesReport = ({ filters, limit, ...props }: Props) => {
    let pivotConfig = {
        fillMissingDates: false,
        x: [],
        y: ['measures'],
    } as any;

    let order = {};
    let cubeFilters: any[] = [];

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone as TimezoneKey;

    let dimensions = [] as any;
    let timeDimensions = [] as any;
    if (!filters.reportType.startsWith('chart') && !filters.reportType.startsWith('radar')) {
        let timeDimensionColumn = 'FactLogs.utcTimestamp';

        if (props.type === 'community.upload') {
            timeDimensionColumn = 'DimDate.fullDate';
            timezone = 'UTC';
        }

        if (props.type === 'community.upload.time.segments') {
            timeDimensionColumn = 'DimDate.fullDate';
            timezone = 'UTC';
        }

        if (props.type === 'community.tem') {
            timeDimensionColumn = 'DimDate.fullDate';
            timezone = 'UTC';
        }

        if (props.type === 'community.group.gps') {
            timeDimensionColumn = 'DimDate.fullDate';
            timezone = 'UTC';
        }

        if (props.type === 'community.activity') {
            timeDimensionColumn = 'FactLogs.localSelectedTimestamp';
        }

        let timeDimension = {
            dimension: timeDimensionColumn,
            granularity: filters.timeGroup === 'none' ? null : filters.timeGroup,
        } as any;

        if (filters.timeDimension && filters.timeDimension !== 'all') {
            timeDimension['dateRange'] = filters.timeDimension;
        }
        timeDimensions.push(timeDimension);
    } else {
        if (filters.timeDimension && filters.timeDimension !== 'all') {
            if (typeof filters.timeDimension === 'string') {
                const date = chrono.parse(filters.timeDimension)[0];
                if (date) {
                    const values = [];
                    if (date.start.date()) {
                        values.push(DateTime.fromJSDate(date.start.date()).startOf('day'));
                    }
                    if (date.end?.date()) {
                        values.push(DateTime.fromJSDate(date.end.date()).endOf('day'));
                    }
                    cubeFilters.push({
                        member: 'DimDate.fullDate',
                        operator: 'inDateRange',
                        values: values,
                    });
                }
            } else {
                cubeFilters.push({
                    member: 'DimDate.fullDate',
                    operator: 'inDateRange',
                    values: filters.timeDimension,
                });
            }
        }
    }

    if (filters.reportType === 'trend') {
        pivotConfig['x'] = ['DimDate.fullDate.day'];
        pivotConfig['y'] = ['DimIndividual.name', 'measures'];
        dimensions.push('DimIndividual.name');
    }
    if (filters.reportType === 'trend-by-movement') {
        pivotConfig['x'] = ['DimDate.fullDate.day'];
        pivotConfig['y'] = ['DimMovement.name', 'DimIndividual.name', 'measures'];
        dimensions.push('DimIndividual.name', 'DimMovement.name');
    }
    if (filters.reportType === 'trend-combined-athletes') {
        pivotConfig['x'] = ['DimDate.fullDate.day'];
        pivotConfig['y'] = ['DimMovement.name', 'measures'];
        dimensions.push('DimMovement.name');
    }
    if (filters.reportType === 'trend-by-group') {
        pivotConfig['x'] = ['DimDate.fullDate.day'];
        pivotConfig['y'] = ['DimGroup.name', 'measures'];
        dimensions.push('DimGroup.name');
    }
    if (filters.reportType === 'chart' && filters.chartType !== 'radar') {
        pivotConfig['x'] = ['DimIndividual.name'];
        pivotConfig['y'] = ['measures'];
        dimensions.push('DimIndividual.name');
    }
    if (filters.reportType === 'chart' && filters.chartType === 'table') {
        pivotConfig['x'] = [
            'DimIndividual.name',
            'DimDate.fullDate',
            'DimIndividual.profilePhotoUrl',
        ];
        dimensions.push('DimDate.fullDate');
        dimensions.push('DimIndividual.profilePhotoUrl');
    }

    if (filters.reportType === 'chart' && filters.chartType === 'radar') {
        pivotConfig['x'] = ['measures'];
        pivotConfig['y'] = ['DimIndividual.name'];
        dimensions.push('DimIndividual.name');
    }

    if (filters.reportType === 'chart-by-movement' && filters.chartType !== 'radar') {
        pivotConfig['x'] = ['DimMovement.name'];
        pivotConfig['y'] = ['measures'];
        dimensions.push('DimMovement.name');
    }

    if (filters.reportType === 'chart-by-movement' && filters.chartType === 'radar') {
        pivotConfig['x'] = ['measures'];
        pivotConfig['y'] = ['DimMovement.name'];
        dimensions.push('DimMovement.name');
    }

    if (filters.reportType === 'chart-athletes-movements') {
        pivotConfig['x'] = ['DimIndividual.name', 'DimMovement.name'];
        pivotConfig['y'] = ['measures'];
        dimensions.push('DimIndividual.name', 'DimMovement.name');
    }

    if (filters.fillMissingDates) {
        pivotConfig['fillMissingDates'] = true;
    }

    if (filters.reportType === 'chart' && props.type === 'community.upload.time.segments') {
        dimensions = ['DimTimeSegment.maxTime', 'DimIndividual.name'];
        pivotConfig['x'] = ['DimTimeSegment.maxTime'];
        pivotConfig['y'] = ['measures'];
        order = {
            'DimTimeSegment.maxTime': 'asc',
        };
    }

    if (filters.reportType === 'trend' && props.type === 'community.upload.time.segments') {
        pivotConfig['y'] = ['DimIndividual.name', 'DimTimeSegment.timeSegmentName', 'measures'];
        dimensions.push('DimTimeSegment.timeSegmentName');
    }

    if (
        typeof filters.load !== 'undefined' &&
        filters.load.length > 0 &&
        props.type === 'community.upload'
    ) {
        cubeFilters.push({
            member: 'FactSprints.load',
            operator: 'equals',
            values: filters.load,
        });
    }

    if (
        typeof filters.load !== 'undefined' &&
        filters.load.length > 0 &&
        props.type === 'community.upload.time.segments'
    ) {
        cubeFilters.push({
            member: 'FactSprintTimeSegments.load',
            operator: 'equals',
            values: filters.load,
        });
    }

    if (filters.movements.length > 0) {
        cubeFilters.push({
            member: 'DimMovement.uuid',
            operator: 'equals',
            values: filters.movements.map((i) => i.uuid),
        });
    }

    if (filters.segment && filters.segment.length > 0) {
        cubeFilters.push({
            member: 'DimTimeSegment.maxTime',
            operator: 'equals',
            values: filters.segment,
        });
    }

    if (filters.groups && filters.groups.length > 0) {
        cubeFilters.push({
            member: 'DimGroup.uuid',
            operator: 'equals',
            values: filters.groups.map((g) => g.uuid),
        });
    }

    if (filters.athleteGroups && filters.athleteGroups.length > 0) {
        cubeFilters.push({
            member: 'DimMember.groupUuid',
            operator: 'equals',
            values: filters.athleteGroups.map((g) => g.uuid),
        });
    }

    if (filters.tag) {
        cubeFilters.push({
            member: 'DimTag.tagId',
            operator: 'equals',
            values: [filters.tag.id],
        });
    }

    if (filters.temExercises && filters.temExercises.length > 0) {
        cubeFilters.push({
            member: 'DimTemExerciseType.guid',
            operator: 'equals',
            values: filters.temExercises,
        });
    }

    if (!filters.showSkipped && props.type === 'community.activity') {
        cubeFilters.push({
            member: 'FactLogs.markedAsComplete',
            operator: 'equals',
            values: ['1'],
        });
    }

    if (['community.upload', 'community.upload.time.segments'].indexOf(props.type) > -1) {
        cubeFilters.push({
            member: 'DimUpload.isDeleted',
            operator: 'equals',
            values: ['0'],
        });
    }

    if (filters.individuals.length > 0) {
        cubeFilters.push({
            member: 'DimIndividual.uuid',
            operator: 'equals',
            values: filters.individuals.map((i) => i.uuid),
        });
    }

    let measures: any = [];

    if (props.type === 'community.activity') {
        measures =
            filters.measurements.length > 0
                ? filters.measurements.map((m) => m.name)
                : ['FactLogs.distanceValueYd'];
    } else if (props.type === 'community.tem') {
        measures =
            filters.measurements.length > 0
                ? filters.measurements.map((m) => m.name)
                : ['FactTemMotions.peakSpeedMax'];
    } else if (props.type === 'community.group.gps') {
        measures =
            filters.measurements.length > 0
                ? filters.measurements.map((m) => m.name)
                : ['FactGpsSummaries.maxPlayerLoad'];
    } else if (props.type === 'community.upload') {
        measures =
            filters.measurements.length > 0
                ? filters.measurements.map((m) => m.name)
                : ['FactSprints.vMaxMax'];
    } else if (props.type === 'community.upload.time.segments') {
        measures =
            filters.measurements.length > 0
                ? filters.measurements.map((m) => m.name)
                : ['FactSprintTimeSegments.velocityMaxMph'];
    } else if (props.type === 'community.performance') {
        measures =
            filters.measurements.length > 0
                ? filters.measurements.map((m) => m.name)
                : ['LatestLogs.distanceValueFt'];
    }

    if (filters.organizations.length > 0) {
        cubeFilters.push({
            member: 'DimOrganization.uuid',
            operator: 'equals',
            values: filters.organizations,
        });
    }

    const chartQuery = {
        measures,
        timeDimensions,
        dimensions,
        timezone,
        filters: cubeFilters,
        order,
    } as Query;

    if (limit) {
        chartQuery['limit'] = limit;
    }

    return (
        <ChartRenderer
            vizState={{
                query: chartQuery,
                chartType: filters.chartType ?? 'line',
                pivotConfig,
                hideLegend: filters.hideLegend ?? false,
                ...props,
            }}
        />
    );
};

export default TimeseriesReport;
