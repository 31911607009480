import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
} from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import { DateTimePicker } from '@mui/x-date-pickers-pro/';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

function SimpleDateTimePickerDialog({
    open,
    onChosen,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
    onChosen: (date: DateTime | null) => void;
}) {
    const [date, setDate] = useState<DateTime | null>(DateTime.now());

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Actual Sprint Date and Time in Video</DialogTitle>
            <DialogContent sx={{ marginTop: 10 }}>
                <Grid container justifyContent="center">
                    <DateTimePicker minutesStep={5} value={date} onChange={(v) => setDate(v)} />
                </Grid>
            </DialogContent>
            <DialogContentText sx={{ marginBottom: 10 }}>
                <Typography variant="body1" fontWeight="bold" align={'center'}>
                    <br />
                    Video Requirements:
                    <br />
                    <br /> (1) Videos must be taken from the side
                    <br /> (2) One athlete in the frame
                    <br /> (3) 60fps or higher
                </Typography>
            </DialogContentText>
            <DialogActions>
                <Button variant="outlined" size="large" onClick={() => onChosen(date)}>
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SimpleDateTimePickerDialog;
