function setInLocalStorageWithExpiry(key: string, value: any, hours: number) {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + hours * 60 * 60 * 1000,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function getFromLocalStorageWithExpiry(key: string) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}

export { setInLocalStorageWithExpiry, getFromLocalStorageWithExpiry };
