import { List, ListSubheader } from '@mui/material';
import React, { ReactElement } from 'react';
import { Invite } from 'utils';
import InviteRow from 'components/ListElements/InviteRow';
import { RowDivider } from 'components/Separators';

export interface InviteListProps {
    invites: Array<Invite>;
    onResend: (invite: Invite) => void;
    onRevoke: (invite: Invite) => void;
}

export default function InviteList({ invites, onResend, onRevoke }: InviteListProps): ReactElement {
    return (
        <List>
            <RowDivider />
            <ListSubheader>Invites</ListSubheader>
            {invites.map((invite) => {
                return (
                    <InviteRow
                        key={invite.uuid}
                        onResend={onResend}
                        onRevoke={onRevoke}
                        invite={invite}
                    />
                );
            })}
        </List>
    );
}
