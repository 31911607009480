import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import EditIcon from '@mui/icons-material/Edit';
import FacebookIcon from '@mui/icons-material/Facebook';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    Grid,
    Stack,
    Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Slide from '@mui/material/Slide';
import useTheme from '@mui/material/styles/useTheme';
import { TransitionProps } from '@mui/material/transitions';
import { getSignedUrl, uploadFile, viewIndividual } from 'api';
import {
    Can,
    confirmViaDialog,
    CopyLink,
    CustomButton,
    ThumbnailTitleChip,
    TypographyTitleh6,
} from 'components';
import { isEmpty } from 'lodash';
import moment from 'moment';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { FC, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getIndividualAttributes, loadAttributes } from 'redux/reducers/attributes';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import { isSubset } from 'utils';
import { AttributeCategory, AttributeValue, Individual, Member } from 'utils/types';
import VideoAnalysisFieldLines from '../../../../src/images/VideoAnalysisFieldLines.png';
import VideoAnalysisHelper from '../../../../src/images/VideoAnalysisHelper.png';
import { getIndividualMembers, removeManagingUserFromIndividual } from '../api/groups.api';
import { deleteIndividual, updateIndividual } from '../api/individuals.api';
import {
    EditIndividualModal,
    IndividualDetailsCard,
    IndividualDetailsSkeleton,
    IndividualSharingDrawer,
} from '../components';
import VideoFeed from '../components/VideoFeed';
import IndividualDetailsKpis from './IndividualDetailsKpis';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface UpdateIndividualParams {
    name: string;
    attributes: AttributeValue[];
    file?: File;
}

const IndividualDetails: FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useAppDispatch();

    const { push } = useHistory();
    const { individualId } = useParams<{ individualId: string }>();
    const [loading, setLoading] = useState<boolean>(true);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [individual, setIndividual] = useState<Individual | undefined>(undefined);
    const [members, setMembers] = useState<Member[] | undefined>(undefined);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);
    const attributesList = useSelector(getIndividualAttributes);
    const [openQR, setOpenQR] = React.useState(false);
    const theme = useTheme();
    const handleClickOpenQR = () => {
        setOpenQR(true);
    };

    const handleCloseQR = () => {
        setOpenQR(false);
    };

    useEffect(() => {
        dispatch(loadAttributes());
        Promise.all([viewIndividual(individualId), getIndividualMembers(individualId)])
            .then(([individualResponse, memberResponse]) => {
                setIndividual(individualResponse.data);
                setMembers(memberResponse.data);
            })
            .catch(() => {
                dispatch(
                    pushMessage({
                        status: 'error',
                        message: 'Failed to load the player information.',
                    }),
                );
            })
            .finally(() => {
                setLoading(false);
            });
    }, [dispatch, individualId]);

    const handleOpenEditModal = (): void => {
        setOpenEditModal(true);
    };

    const handleCloseEditModal = (): void => {
        setOpenEditModal(false);
    };

    const handleOpenInvite = (): void => {
        setOpenInviteModal(true);
    };

    const handleCloseInvite = (): void => {
        setOpenInviteModal(false);
    };

    function handleArchive(individualId: string) {
        setDeleting(true);
        deleteIndividual(individualId)
            .then(() => {
                setIndividual(
                    (individual) =>
                        ({
                            ...individual,
                            archived_at: Math.round(moment().unix() / 1000),
                        } as Individual),
                );
                push('/community/individuals');
            })
            .finally(() => {
                setDeleting(false);
            });
    }

    const handleManagingUserRemoved = (individual: Individual): void => {
        confirmViaDialog({
            confirmation: {
                title: 'Are you sure?',
                message: `This will remove ${individual?.managing_user?.name}(${individual?.managing_user?.user_name})'s ability to manage this individual.`,
            },
        })
            .then((confirmed) => {
                if (confirmed) {
                    // User confirmed, proceed with removal
                    return removeManagingUserFromIndividual(individual.uuid)
                        .then(() => {
                            // Update the individual state after removal
                            setIndividual((prevIndividual) => {
                                if (!prevIndividual) {
                                    return prevIndividual;
                                }
                                return {
                                    ...prevIndividual,
                                    managing_user: null,
                                } as Individual;
                            });
                        })
                        .catch((error) => {
                            // Handle removal error here if needed
                            console.error('Error removing managing user:', error);
                        });
                } else {
                    // User canceled, do nothing
                }
            })
            .catch(() => {
                // Handle dialog error here if needed
            });
    };

    /* Upload the photo, and return the key from the update response */
    const handleUpdateProfilePhoto = async (
        photo: File | undefined,
    ): Promise<string | undefined> => {
        if (!photo) {
            return;
        }
        try {
            const getSignedUrlResponse = await getSignedUrl();
            await uploadFile(getSignedUrlResponse.data.url, photo);
            return getSignedUrlResponse.data.key;
        } catch {
            throw new Error('Failed to update player photo');
        }
    };

    const handleClickSave = async (modalUpdates: UpdateIndividualParams): Promise<void> => {
        try {
            setUpdating(true);
            const photoKey = await handleUpdateProfilePhoto(modalUpdates.file);
            const attributes = modalUpdates.attributes
                .filter(({ value }) => !isEmpty(value))
                .map((param) => ({
                    name: param.name,
                    value: param.value,
                    type: param.type,
                    category: AttributeCategory.Individual,
                }));
            const updates = { attributes, profile_photo: photoKey, name: modalUpdates.name };
            const updateIndividualResponse = await updateIndividual(individualId, updates);
            handleCloseEditModal();
            setIndividual(updateIndividualResponse.data);
            if (!isSubset(modalUpdates.attributes, attributesList)) {
                dispatch(loadAttributes());
            }
            dispatch(
                pushMessage({
                    status: 'success',
                    message: 'Player information updated successfully.',
                }),
            );
        } catch {
            dispatch(
                pushMessage({
                    status: 'error',
                    message: 'Failed to update the player information.',
                }),
            );
        }
        setUpdating(false);
    };

    const mapIndividualAttributes = (
        individualAttributes?: AttributeValue[],
    ): Array<{ header: string; subheader: string | number }> => {
        if (!individualAttributes?.length) {
            return [];
        }

        return individualAttributes.map(({ name, value }) => ({
            header: name,
            subheader: Array.isArray(value) ? value.join(', ') : value ?? '',
        }));
    };

    const isEmptyResponse = !loading && !individual;

    const [open, setOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalFieldLinesOpen, setModalFieldLinesOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };
    const handleModalFieldLinesOpen = () => {
        setModalFieldLinesOpen(true);
    };

    const handleModalFieldLinesClose = () => {
        setModalFieldLinesOpen(false);
    };

    return (
        <FixedLayout
            title="Individual Details"
            content={
                <>
                    <Box
                        sx={{
                            '@media screen and (min-width: md)': {
                                maxWidth: '90vw',
                                margin: '0 auto',
                            },
                        }}
                    >
                        {loading && <IndividualDetailsSkeleton />}
                        {isEmptyResponse && (
                            <Box
                                sx={{
                                    fontSize: 18,
                                    padding: theme.spacing(17),
                                    textAlign: 'center',
                                }}
                            >
                                Cannot find information on the player.
                            </Box>
                        )}
                        <Grid container>
                            <Grid item style={{ margin: '0 0 16px', padding: '16px  12px' }}>
                                {individual && (
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            fontWeight: 500,
                                            gap: theme.spacing(8),
                                            padding: theme.spacing(17, 0),
                                            flexFlow: 'row wrap',
                                        }}
                                    >
                                        <Avatar
                                            variant="rounded"
                                            sx={(theme) => ({
                                                height: 44,
                                                width: 44,
                                                [theme.breakpoints.up('md')]: {
                                                    height: 88,
                                                    width: 88,
                                                },
                                            })}
                                            alt={individual.name}
                                            src={individual.profile_photo}
                                        />
                                        <Typography
                                            sx={(theme) => ({
                                                fontSize: 24,
                                                [theme.breakpoints.up('md')]: {
                                                    fontSize: 34,
                                                },
                                            })}
                                        >
                                            {individual.name}
                                        </Typography>
                                        <Box
                                            sx={(theme) => ({
                                                display: 'flex',
                                                flex: '1 0 100%',
                                                gap: theme.spacing(8),
                                                [theme.breakpoints.up('md')]: {
                                                    flex: '1 1',
                                                },
                                            })}
                                        >
                                            <Can I={'individual:update'} this={individual}>
                                                <Button
                                                    sx={{
                                                        textTransform: 'none',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                    color="primary"
                                                    size="medium"
                                                    variant="outlined"
                                                    onClick={handleOpenEditModal}
                                                    startIcon={<EditIcon />}
                                                >
                                                    Edit profile
                                                </Button>
                                                <Button
                                                    sx={{
                                                        textTransform: 'none',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                    color="primary"
                                                    size="medium"
                                                    variant="outlined"
                                                    onClick={handleOpenInvite}
                                                    startIcon={<FamilyRestroomIcon />}
                                                >
                                                    Family Members
                                                </Button>
                                            </Can>
                                            <Can I={'individual:manage-logs'} this={individual}>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    startIcon={<DirectionsRunIcon />}
                                                    onClick={() =>
                                                        push(
                                                            `/community/individuals/${individual.uuid}/performance`,
                                                        )
                                                    }
                                                >
                                                    Sessions List
                                                </Button>
                                            </Can>
                                        </Box>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={5}
                                style={{ margin: '0 0 16px', padding: '16px  12px' }}
                            >
                                {individual && individual?.managing_user && (
                                    <Card sx={{ marginBottom: theme.spacing(8) }}>
                                        <Grid item>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                sx={{ marginTop: 5 }}
                                            >
                                                <VerifiedUserIcon
                                                    sx={{ color: '#1B9CEA', marginLeft: 6 }}
                                                />
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        fontWeight: 450,
                                                        marginLeft: 1,
                                                    }}
                                                >
                                                    Managing User Details
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <CardContent sx={{ display: 'flex' }}>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    marginRight: 4,
                                                    width: 36,
                                                    height: 36,
                                                }}
                                                src={individual?.image_urls?.avatar ?? ''}
                                            ></Avatar>
                                            <span>
                                                This individual is managed by{' '}
                                                <strong>{individual?.managing_user?.name}</strong>
                                                <br />
                                                <br />
                                                {' username: '}
                                                <strong>
                                                    {individual?.managing_user?.user_name}
                                                </strong>
                                            </span>
                                        </CardContent>
                                        <Can I="individual:delete" this={individual}>
                                            <CardActions>
                                                <CustomButton
                                                    onClick={() =>
                                                        handleManagingUserRemoved(individual)
                                                    }
                                                    size="small"
                                                >
                                                    Remove {individual.managing_user.first_name}
                                                </CustomButton>
                                            </CardActions>
                                        </Can>
                                    </Card>
                                )}
                                {individual && (
                                    <Stack direction={'column'} spacing={4}>
                                        <IndividualDetailsCard
                                            individual={individual}
                                            attributes={mapIndividualAttributes(
                                                individual.attributes,
                                            )}
                                            title="Individual Profile"
                                            emptyResultsText="Add Details"
                                            deleting={deleting}
                                            onEditProfileClick={() => handleOpenEditModal()}
                                            onDeleteClick={
                                                !individual.archived_at
                                                    ? () => handleArchive(individual.uuid)
                                                    : undefined
                                            }
                                        />
                                    </Stack>
                                )}
                                {individual && individual.managing_user && (
                                    <>
                                        <Card sx={{ marginBottom: 8, marginTop: 8 }}>
                                            <Grid container direction={'column'}>
                                                <Grid item>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            fontWeight: 450,
                                                            textAlign: 'center',
                                                            marginBottom: 6,
                                                            marginTop: 6,
                                                        }}
                                                    >
                                                        User Profile
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction={'row'}
                                                    sx={{ padding: 6, spacing: 5 }}
                                                >
                                                    <Grid item>
                                                        <Box
                                                            sx={{
                                                                color: 'black',
                                                                display: 'inline',
                                                                fontWeight: 'medium',
                                                                mx: 0.5,
                                                                fontSize: 18,
                                                            }}
                                                        >
                                                            Name:
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                color: 'text.secondary',
                                                                display: 'inline',
                                                                fontWeight: 'bold',
                                                                fontSize: 19,
                                                                marginLeft: 5,
                                                            }}
                                                        >
                                                            {individual.managing_user?.first_name ??
                                                                ''}
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                color: 'text.secondary',
                                                                display: 'inline',
                                                                fontWeight: 'bold',
                                                                fontSize: 19,
                                                                marginLeft: 2,
                                                            }}
                                                        >
                                                            {individual.managing_user?.last_name ??
                                                                ''}
                                                        </Box>
                                                    </Grid>
                                                    <Grid item container alignItems="center">
                                                        {individual.managing_user?.fields?.sport &&
                                                            individual.managing_user?.fields?.sport
                                                                .length > 0 && (
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            color: 'black',
                                                                            fontWeight: 'medium',
                                                                            mx: 0.5,
                                                                            fontSize: 18,
                                                                        }}
                                                                    >
                                                                        Sport(s):
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            color: 'text.secondary',
                                                                            fontWeight: 'bold',
                                                                            fontSize: 19,
                                                                            marginLeft: 5,
                                                                        }}
                                                                    >
                                                                        {
                                                                            individual.managing_user
                                                                                ?.fields?.sport
                                                                        }
                                                                    </Box>
                                                                </Box>
                                                            )}
                                                    </Grid>
                                                    <Grid item container alignItems="center">
                                                        {individual.managing_user?.fields?.height &&
                                                            individual.managing_user?.fields?.height
                                                                .length > 0 && (
                                                                <Grid item>
                                                                    <Box
                                                                        sx={{
                                                                            color: 'black',
                                                                            display: 'inline',
                                                                            fontWeight: 'medium',
                                                                            mx: 0.5,
                                                                            fontSize: 18,
                                                                        }}
                                                                    >
                                                                        Height:
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            color: 'text.secondary',
                                                                            display: 'inline',
                                                                            fontWeight: 'bold',
                                                                            fontSize: 19,
                                                                            marginLeft: 5,
                                                                        }}
                                                                    >
                                                                        {individual.managing_user
                                                                            ?.fields?.height ?? ''}
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                    </Grid>
                                                    <Grid item container alignItems="center">
                                                        {individual.managing_user?.fields?.weight &&
                                                            individual.managing_user?.fields?.weight
                                                                .length > 0 && (
                                                                <Grid item>
                                                                    <Box
                                                                        sx={{
                                                                            color: 'black',
                                                                            display: 'inline',
                                                                            fontWeight: 'medium',
                                                                            mx: 0.5,
                                                                            fontSize: 18,
                                                                        }}
                                                                    >
                                                                        Weight:
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            color: 'text.secondary',
                                                                            display: 'inline',
                                                                            fontWeight: 'bold',
                                                                            fontSize: 19,
                                                                            marginLeft: 5,
                                                                        }}
                                                                    >
                                                                        {individual.managing_user
                                                                            ?.fields?.weight ?? ''}
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                    </Grid>
                                                    <Grid item container alignItems="center">
                                                        {individual.managing_user?.fields
                                                            ?.birth_date &&
                                                            individual.managing_user?.fields
                                                                ?.birth_date.length > 0 && (
                                                                <Grid item>
                                                                    <Box
                                                                        sx={{
                                                                            color: 'black',
                                                                            display: 'inline',
                                                                            fontWeight: 'medium',
                                                                            mx: 0.5,
                                                                            fontSize: 18,
                                                                        }}
                                                                    >
                                                                        Birthdate:
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            color: 'text.secondary',
                                                                            display: 'inline',
                                                                            fontWeight: 'bold',
                                                                            fontSize: 19,
                                                                            marginLeft: 5,
                                                                        }}
                                                                    >
                                                                        {moment(
                                                                            individual.managing_user
                                                                                ?.fields
                                                                                ?.birth_date,
                                                                        ).format('MM-DD-YYYY')}
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                    </Grid>
                                                    <Grid item container alignItems="center">
                                                        {individual.managing_user?.fields?.school &&
                                                            individual.managing_user?.fields?.school
                                                                .length > 0 && (
                                                                <Grid item>
                                                                    <Box
                                                                        sx={{
                                                                            color: 'black',
                                                                            display: 'inline',
                                                                            fontWeight: 'medium',
                                                                            mx: 0.5,
                                                                            fontSize: 18,
                                                                        }}
                                                                    >
                                                                        School(s):
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            color: 'text.secondary',
                                                                            display: 'inline',
                                                                            fontWeight: 'bold',
                                                                            fontSize: 19,
                                                                            marginLeft: 5,
                                                                        }}
                                                                    >
                                                                        {
                                                                            individual.managing_user
                                                                                ?.fields?.school
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                    </Grid>
                                                    <Grid item container alignItems="center">
                                                        {individual.managing_user?.fields
                                                            ?.instagram &&
                                                            individual.managing_user?.fields
                                                                ?.instagram.length > 0 && (
                                                                <Grid
                                                                    item
                                                                    container
                                                                    sx={{
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            marginTop: 8,
                                                                        }}
                                                                    >
                                                                        <InstagramIcon
                                                                            sx={{
                                                                                color: 'black',
                                                                                fontSize: 20,
                                                                            }}
                                                                        />
                                                                        <Box
                                                                            sx={{
                                                                                color: 'black',
                                                                                fontWeight:
                                                                                    'medium',
                                                                                mx: 0.5,
                                                                                fontSize: 18,
                                                                            }}
                                                                        >
                                                                            Instagram:
                                                                        </Box>
                                                                    </Box>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                        }}
                                                                    >
                                                                        <Link
                                                                            href={`${individual.managing_user?.fields?.instagram}`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            sx={{
                                                                                color: 'text.secondary',
                                                                                fontWeight: 'bold',
                                                                                fontSize: 19,
                                                                                marginLeft: 5,
                                                                            }}
                                                                        >
                                                                            {individual
                                                                                .managing_user
                                                                                ?.fields
                                                                                ?.instagram ?? ''}
                                                                        </Link>
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                    </Grid>
                                                    <Grid item container alignItems="center">
                                                        {individual.managing_user?.fields
                                                            ?.facebook &&
                                                            individual.managing_user?.fields
                                                                ?.facebook.length > 0 && (
                                                                <Grid
                                                                    item
                                                                    container
                                                                    sx={{
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            marginTop: 8,
                                                                        }}
                                                                    >
                                                                        <FacebookIcon
                                                                            sx={{
                                                                                color: 'black',
                                                                                fontSize: 20,
                                                                            }}
                                                                        />
                                                                        <Box
                                                                            sx={{
                                                                                color: 'black',
                                                                                fontWeight:
                                                                                    'medium',
                                                                                mx: 0.5,
                                                                                fontSize: 18,
                                                                            }}
                                                                        >
                                                                            Facebook:
                                                                        </Box>
                                                                    </Box>
                                                                    <Grid item sm={12}>
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                                justifyContent:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            <Link
                                                                                href={`${individual.managing_user?.fields?.facebook}`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                sx={{
                                                                                    color: 'text.secondary',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize: 19,
                                                                                    marginLeft: 5,
                                                                                }}
                                                                            >
                                                                                {individual
                                                                                    .managing_user
                                                                                    ?.fields
                                                                                    ?.facebook ??
                                                                                    ''}
                                                                            </Link>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                    </Grid>
                                                    <Grid item container alignItems="center">
                                                        {individual.managing_user?.fields
                                                            ?.facebook &&
                                                            individual.managing_user?.fields
                                                                ?.facebook.length > 0 && (
                                                                <Grid
                                                                    item
                                                                    container
                                                                    sx={{
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent:
                                                                                'center',
                                                                            marginTop: 8,
                                                                        }}
                                                                    >
                                                                        <TwitterIcon
                                                                            sx={{
                                                                                color: 'black',
                                                                                fontSize: 20,
                                                                            }}
                                                                        />
                                                                        <Box
                                                                            sx={{
                                                                                color: 'black',
                                                                                fontWeight:
                                                                                    'medium',
                                                                                mx: 0.5,
                                                                                fontSize: 18,
                                                                            }}
                                                                        >
                                                                            Twitter:
                                                                        </Box>
                                                                    </Box>
                                                                    <Grid item sm={12}>
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                                justifyContent:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            <Link
                                                                                href={`${individual.managing_user?.fields?.twitter}`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                sx={{
                                                                                    color: 'text.secondary',
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                    fontSize: 19,
                                                                                    marginLeft: 5,
                                                                                }}
                                                                            >
                                                                                {individual
                                                                                    .managing_user
                                                                                    ?.fields
                                                                                    ?.twitter ?? ''}
                                                                            </Link>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </>
                                )}
                                {individual && (
                                    <IndividualDetailsKpis
                                        individual={individual}
                                        fullname={individual.name}
                                    />
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={7}
                                style={{ margin: '0 0 16px', padding: '16px  12px' }}
                            >
                                {individual?.organization && (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'center'}
                                        sx={{
                                            width: '100%',
                                            borderRadius: 1,
                                            boxShadow: theme.shadows[1],
                                            backgroundColor: '#ffffff',
                                            marginBottom: theme.spacing(8),
                                        }}
                                    >
                                        <ThumbnailTitleChip
                                            title={`${individual.organization.name}`}
                                            src={individual.organization.logo_url || ''}
                                            avatarVariant="rounded"
                                            sx={(theme) => ({
                                                elevation: 0,
                                                display: 'flex',
                                                flexDirection: 'column', // if multiple items, they will stack vertically
                                                alignItems: 'center', // this will center items horizontally
                                                justifyContent: 'center', // this will center items vertically
                                                width: '100%', // take only as much width as needed, or use '100%' for full width
                                                gap: theme.spacing(8),
                                                marginBottom: theme.spacing(8),
                                                marginLeft: 8,
                                                padding: theme.spacing(8, 6),
                                                '& .title': {
                                                    fontSize: 20,
                                                    fontWeight: 600,
                                                },
                                                '& .thumbnail': {
                                                    height: 44,
                                                    width: 44,
                                                    [theme.breakpoints.up('md')]: {
                                                        height: 88,
                                                        width: 88,
                                                    },
                                                },
                                            })}
                                        />
                                    </Box>
                                )}
                                {individual && !individual?.managing_user && (
                                    <Card sx={{ marginBottom: theme.spacing(8) }}>
                                        <CardContent>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent={'center'}
                                            >
                                                <VerifiedUserIcon sx={{ color: '#1B9CEA' }} />
                                                <TypographyTitleh6
                                                    textAlign={'center'}
                                                    sx={{ marginLeft: 2 }}
                                                >
                                                    Invite Managing User
                                                </TypographyTitleh6>
                                            </Box>
                                            <CopyLink
                                                link={`${process.env.REACT_APP_URL}/i/${individual.slug}`}
                                                onCopyToClipboard={() => {}}
                                            />
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <div
                                                        id={'Container'}
                                                        style={{
                                                            height: 'auto',
                                                            margin: '0 auto',
                                                            maxWidth: 100,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {!openInviteModal && (
                                                            <QRCode
                                                                size={256}
                                                                style={{
                                                                    height: 'auto',
                                                                    maxWidth: '100%',
                                                                    width: '100%',
                                                                    marginTop: 10,
                                                                }}
                                                                value={`${process.env.REACT_APP_URL}/i/${individual.slug}`}
                                                                viewBox={`0 0 256 256`}
                                                            />
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item>
                                                    {!openInviteModal && (
                                                        <Button onClick={handleClickOpenQR}>
                                                            Enlarge
                                                        </Button>
                                                    )}
                                                    <Dialog
                                                        open={openQR}
                                                        TransitionComponent={Transition}
                                                        keepMounted
                                                        onClose={handleCloseQR}
                                                        aria-describedby="alert-dialog-slide-description"
                                                    >
                                                        <DialogContent>
                                                            <QRCode
                                                                size={356}
                                                                style={{
                                                                    height: 'auto',
                                                                    maxWidth: '100%',
                                                                    width: '100%',
                                                                    marginTop: 10,
                                                                }}
                                                                value={`${process.env.REACT_APP_URL}/i/${individual.slug}`}
                                                                viewBox={`0 0 256 256`}
                                                            />
                                                        </DialogContent>
                                                    </Dialog>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                )}
                                {members && individual && (
                                    <IndividualDetailsCard
                                        individual={individual}
                                        title="Teams/Groups"
                                        attributes={members.map(({ group, memberType }) => ({
                                            logo_url: group.image_urls.avatar || '',
                                            header: group.name,
                                            subheader: memberType.name,
                                        }))}
                                    />
                                )}
                                <>
                                    <Button
                                        color="inherit"
                                        variant="text"
                                        onClick={handleClickOpen}
                                        sx={{ marginTop: 5 }}
                                    >
                                        Click for Sprint Analysis Video Requirements{' '}
                                        <HelpCenterOutlinedIcon />
                                    </Button>
                                    <Dialog
                                        open={open}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleClose}
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogTitle>
                                            {'Sprint Analysis Processing Video Requirements'}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-slide-description">
                                                When you upload through the &quot;Sprint Analysis
                                                Processing&quot; path your video will be sent to our
                                                servers for processing. <br />
                                                <br />
                                                This processing will take 1-2 minutes and will be
                                                done in the background. <br />
                                                <br />
                                                You will be able to see the results in the
                                                &quot;Video Analysis Database&quot; under your top
                                                left cloud database icon.
                                            </DialogContentText>
                                            <DialogContentText>
                                                <Typography variant="body1" fontWeight="bold">
                                                    <br />
                                                    Requirements:
                                                    <br /> (1) Videos must be taken from the side
                                                    <br /> (2) One athlete in the frame
                                                    <br /> (3) 60fps or higher
                                                </Typography>
                                            </DialogContentText>
                                            <Button onClick={handleModalOpen} sx={{ marginTop: 6 }}>
                                                <ImageSearchIcon sx={{ marginRight: 4 }} /> View
                                                Example Video Angle
                                            </Button>
                                            <Button
                                                onClick={handleModalFieldLinesOpen}
                                                sx={{ marginTop: 6 }}
                                            >
                                                <ImageSearchIcon sx={{ marginRight: 4 }} /> View
                                                Example Video Angle with Field Lines
                                            </Button>
                                            <Dialog open={modalOpen} onClose={handleModalClose}>
                                                <DialogTitle>{'Example Video'}</DialogTitle>
                                                <DialogContent>
                                                    <img
                                                        src={VideoAnalysisHelper}
                                                        alt="example video"
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleModalClose}>
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                            <Dialog
                                                open={modalFieldLinesOpen}
                                                onClose={handleModalFieldLinesClose}
                                            >
                                                <DialogTitle>
                                                    {'Example Field Lines Video'}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <img
                                                        src={VideoAnalysisFieldLines}
                                                        alt="example field lines video"
                                                        style={{
                                                            maxWidth: '100%',
                                                            maxHeight: '100%',
                                                        }}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleModalFieldLinesClose}>
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose}>I understand</Button>
                                        </DialogActions>
                                    </Dialog>
                                </>

                                {individual && <VideoFeed individual={individual} />}
                            </Grid>
                        </Grid>
                    </Box>
                    {individual && openEditModal && (
                        <EditIndividualModal
                            individual={individual}
                            loading={updating}
                            onClose={handleCloseEditModal}
                            onSave={handleClickSave}
                            open={true}
                        />
                    )}
                    {openInviteModal && openInviteModal && (
                        <IndividualSharingDrawer
                            individualId={individualId}
                            open={true}
                            onClose={handleCloseInvite}
                            onUsersLoaded={() => {}}
                        />
                    )}
                </>
            }
        />
    );
};

export default IndividualDetails;
