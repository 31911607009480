import {
    addUserToSession,
    getInvites,
    getLinks,
    getRoles,
    getSharedUsers,
    removeSharedUser,
    revokeInviteFromSessions,
} from 'api';
import React, { ReactElement, useEffect, useState } from 'react';
import { Contact, Invite, Link, Role, SharedUser } from 'utils';
import SharingDrawer from './SharingDrawer';

interface Props {
    sessionUuid: string;
    open: boolean;
    onClose: () => void;
    onUsersLoaded: (users: Array<SharedUser>) => void;
    restrictsRolesToShare?: boolean;
}

export default function SessionSharingDrawer({
    sessionUuid,
    open,
    restrictsRolesToShare = false,
    onClose = () => {},
    onUsersLoaded = () => {},
}: Props): ReactElement {
    const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const [sharingInProgress, setSharingInProgress] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [links, setLinks] = useState<Array<Link>>([]);
    const [invites, setInvites] = useState<Array<Invite>>([]);

    useEffect(() => {
        if (sessionUuid && (setSharedUsers.length == 0 || links.length == 0)) {
            loadSharedUsers(sessionUuid);
            loadLinks(sessionUuid);
            loadInvites(sessionUuid);
            getRoles('session').then((response) => {
                if (restrictsRolesToShare) {
                    // remove session-manager and session-admin as the roles that you're
                    // able to share or edit
                    setRoles(
                        response.data.filter(
                            (role) => ['session-viewer', 'session-admin'].indexOf(role.name) === -1,
                        ),
                    );
                } else {
                    setRoles(response.data);
                }
            });
        }
    }, [sessionUuid]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadSharedUsers = (id: string) => {
        setSharingInProgress(true);
        getSharedUsers(id).then((response) => {
            setSharedUsers(response.data);
            setSharingInProgress(false);
            onUsersLoaded(response.data);
        });
    };

    const loadLinks = (id: string) => {
        getLinks(id).then((response) => {
            setLinks(response.data);
        });
    };

    const loadInvites = (id: string) => {
        getInvites(id).then((response) => {
            setInvites(response.data);
        });
    };

    const onSharedUserRemoved = (user: SharedUser) => {
        setSharingInProgress(true);
        setSharedUsers((users) => users.filter((u) => u.uuid !== user.uuid));
        onUsersLoaded(sharedUsers.filter((u) => u.uuid !== user.uuid));
        removeSharedUser(sessionUuid, user.uuid)
            .then(() => {
                setSharingInProgress(false);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const onShared = (message: string, users: Array<Contact>, role = 'session-manager') => {
        setSharingInProgress(true);
        const usersToAdd: Array<string> = users.map((u) => u.uuid);
        addUserToSession(sessionUuid, usersToAdd, role, message)
            .then((response) => {
                setSharedUsers(response.data);
                setSharingInProgress(false);
                onUsersLoaded(response.data);
                loadInvites(sessionUuid);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const handleInviteRevoked = (invite: Invite) => {
        setInvites((invites) => invites.filter((i) => i.uuid !== invite.uuid));
        revokeInviteFromSessions(sessionUuid, invite.uuid)
            .then(() => {})
            .catch(() => {});
    };

    const handleInviteResent = (invite: Invite) => {
        onShared(
            '',
            [{ uuid: invite.email, name: invite.email, relationship: 'invitee', type: 'email' }],
            invite.role.name,
        );
    };

    const handleLinkDeletedClicked = (link: Link) => {
        setLinks((links) => links.filter((l) => l.uuid !== link.uuid));
    };
    const handleLinkCreated = (link: Link) => {
        setLinks((links) => [link, ...links]);
    };

    return (
        <SharingDrawer
            title={'Session Sharing and Access Permissions'}
            open={open}
            onClose={onClose}
            restrictsRoles={restrictsRolesToShare}
            userAccessList={sharedUsers}
            onRemove={onSharedUserRemoved}
            onShared={onShared}
            processing={sharingInProgress}
            onLinkCreated={handleLinkCreated}
            onLinkDeleteClicked={handleLinkDeletedClicked}
            onLinkDeleteFailed={() => {}}
            onLinkDeleteSucceeded={() => {}}
            onInviteRevoked={handleInviteRevoked}
            onInviteResent={handleInviteResent}
            entityId={sessionUuid}
            entityType="session"
            links={links}
            roles={roles}
            invites={invites}
        />
    );
}
