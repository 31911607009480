import { Filter, Query, TimeDimension, TimeDimensionGranularity } from '@cubejs-client/core';
import { CubeProvider, QueryRenderer } from '@cubejs-client/react';
import {
    Box,
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    Bar,
    BarChart,
    Brush,
    CartesianGrid,
    ComposedChart,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { getHierarchyTags } from '../../../api/tags.api';
import { cubejsApi } from '../../../app.functions';
import { DateRangeValue, Tag } from '../../../app.types';
import { TypographyTitle } from '../../../components';
import CubeRelativeDateRangeSelector from '../../../components/FormControl/CubeRelativeDateRangeSelector';
import { Group, Individual } from '../../../utils';
import { getGroups, getMembers, viewGroup } from '../api/groups.api';
import { formatDateStringLuxon, formatNumber } from '../community.func';
import TagMultiSelect from '../components/Filters/TagMultiSelect';
import ListOfGpsSummaryDates from '../components/ListOfGpsSummaryDates';

const MeasureMap: { [key: string]: string } = {
    'GPS Summary Data Total Player Load': 'Total Load',
    'GPS Summary Data Average Player Load': 'Avg Load',
    'GPS Summary Data Total Distance': 'Distance',
    'GPS Summary Data Average Distance': 'Avg Distance',
    'GPS Summary Data Acute Load': 'Acute Load (Last 7 Day Avg)',
    'GPS Summary Data Chronic Load': 'Chronic Load (Previous 21 Day Avg)',
    'GPS Summary Data ACWR': 'Acute Chronic Workload Ratio (ACWR)',
};

type MeasureType =
    | 'FactGpsSummaries.totalPlayerLoad'
    | 'FactGpsSummaries.totalDistance'
    | 'FactGpsSummaries.avgDistance'
    | 'FactGpsSummaries.avgPlayerLoad'
    | 'FactGpsSummaries.avgAcceleration'
    | 'FactGpsSummaries.avgNumberOfSprints'
    | 'FactGpsSummaries.avgNumberOfAccelerations'
    | 'FactGpsSummaries.avgNumberOfDecelerations'
    | 'FactGpsSummaries.avgNumberOfChangesOfDirection'
    | 'FactGpsSummaries.avgHeartRate'
    | 'FactGpsSummaries.avgMaxHeartRate'
    | 'FactGpsSummaries.acuteLoad'
    | 'FactGpsSummaries.chronicLoad'
    | 'FactGpsSummaries.acuteToChronicRatio'
    | 'FactGpsSummaries.acuteToChronicRatioDistance';

const configurations: {
    label: string;
    measures: MeasureType[];
    dimensions: string[];
    value: string;
}[] = [
    {
        label: 'Average Load',
        value: 'avg_load',
        measures: ['FactGpsSummaries.avgPlayerLoad'],
        dimensions: [],
    },
    {
        label: 'Average Load By Tag',
        value: 'avg_load_by_tag',
        measures: ['FactGpsSummaries.avgPlayerLoad'],
        dimensions: ['DimTag.name'],
    },
    {
        label: 'Total Load',
        value: 'load',
        measures: [
            'FactGpsSummaries.totalPlayerLoad',
            'FactGpsSummaries.acuteLoad',
            'FactGpsSummaries.chronicLoad',
            'FactGpsSummaries.acuteToChronicRatio',
        ],
        dimensions: [],
    },
    {
        label: 'Total Load By Tag',
        value: 'load_by_tag',
        measures: ['FactGpsSummaries.totalPlayerLoad'],
        dimensions: ['DimTag.name'],
    },
    {
        label: 'Average Distance',
        value: 'avgDistance',
        measures: ['FactGpsSummaries.avgDistance'],
        dimensions: [],
    },
    {
        label: 'Average Distance By Tag',
        value: 'avgDistanceByTag',
        measures: ['FactGpsSummaries.avgDistance'],
        dimensions: ['DimTag.name'],
    },
    {
        label: 'Distance',
        value: 'distance',
        measures: ['FactGpsSummaries.totalDistance'],
        dimensions: [],
    },
    {
        label: 'Average Number Of Accelerations',
        value: 'acceleration_number',
        measures: ['FactGpsSummaries.avgNumberOfAccelerations'],
        dimensions: [],
    },
    {
        label: 'Average Number Of Accelerations By Tag',
        value: 'acceleration_number_by_tag',
        measures: ['FactGpsSummaries.avgNumberOfAccelerations'],
        dimensions: ['DimTag.name'],
    },
    {
        label: 'Average Number Of Decelerations',
        value: 'deceleration_number',
        measures: ['FactGpsSummaries.avgNumberOfDecelerations'],
        dimensions: [],
    },
    {
        label: 'Average Number Of Decelerations By Tag',
        value: 'deceleration_number_by_tag',
        measures: ['FactGpsSummaries.avgNumberOfDecelerations'],
        dimensions: ['DimTag.name'],
    },
    {
        label: 'Changes Of Direction',
        value: 'changes_of_direction',
        measures: ['FactGpsSummaries.avgNumberOfChangesOfDirection'],
        dimensions: [],
    },
    {
        label: 'Changes Of Direction By Tag',
        value: 'changes_of_direction_by_tag',
        measures: ['FactGpsSummaries.avgNumberOfChangesOfDirection'],
        dimensions: ['DimTag.name'],
    },
    {
        label: 'Average Acceleration',
        value: 'acceleration',
        measures: ['FactGpsSummaries.avgAcceleration'],
        dimensions: [],
    },
    {
        label: 'Average Acceleration By Tag',
        value: 'avg_acceleration_by_tag',
        measures: ['FactGpsSummaries.avgAcceleration'],
        dimensions: ['DimTag.name'],
    },
    {
        label: 'Average Number Of Sprints',
        value: 'avg_number',
        measures: ['FactGpsSummaries.avgNumberOfSprints'],
        dimensions: [],
    },
    {
        label: 'Average Number Of Sprints By Tag',
        value: 'avg_number_by_tag',
        measures: ['FactGpsSummaries.avgNumberOfSprints'],
        dimensions: ['DimTag.name'],
    },
    {
        label: 'Average Heart Rate',
        value: 'avg_heart_rate',
        measures: ['FactGpsSummaries.avgHeartRate'],
        dimensions: [],
    },
    {
        label: 'Average Heart Rate By Tag',
        value: 'avg_heart_rate_by_tag',
        measures: ['FactGpsSummaries.avgHeartRate'],
        dimensions: ['DimTag.name'],
    },
    {
        label: 'Peak Heart Rate',
        value: 'avg_peak_heart_rate',
        measures: ['FactGpsSummaries.avgMaxHeartRate'],
        dimensions: [],
    },
    {
        label: 'Peak Heart Rate By Tag',
        value: 'avg_peak_heart_rate_by_tag',
        measures: ['FactGpsSummaries.avgMaxHeartRate'],
        dimensions: ['DimTag.name'],
    },
    {
        label: 'Acute Chronic Workload Ratio (Load)',
        value: 'acwr_load',
        measures: ['FactGpsSummaries.acuteToChronicRatio'],
        dimensions: [],
    },
    {
        label: 'Acute Chronic Workload Ratio (Distance)',
        value: 'acwr_load_distance',
        measures: ['FactGpsSummaries.acuteToChronicRatioDistance'],
        dimensions: [],
    },
];

const formatTooltip = (value: number, o: any) => {
    let split = o.split(',');
    let returnValue = split[0];
    if (MeasureMap[split[0]]) {
        returnValue = MeasureMap[split[0]];
    }
    return [formatNumber(value), returnValue];
};

const colors = ['#FF6492', '#141446', '#7A77FF'];

const GpsSummaries = () => {
    const { push } = useHistory();
    const { groupId } = useParams<{ groupId: string }>();
    const [group, setGroup] = React.useState<Group>();
    const [dateRangeLoaded, setDateRangeLoaded] = useState(false);
    const [individuals, setIndividuals] = React.useState<Individual[]>([]);
    const [groups, setGroups] = React.useState<Group[]>([]);
    const [positions, setPositions] = React.useState<Tag[]>([]);
    const [positionGroups, setPositionGroups] = React.useState<Tag[]>([]);
    const [cubeQuery, setCubeQuery] = React.useState<Query>();
    const [filters, setFilters] = React.useState<{
        groupId: string;
        tags: string[];
        dateRange: DateRangeValue;
        measures: MeasureType[];
        dimensions: string[];
        selectedIndividual: string;
        configuration: string;
        timeGrouping: TimeDimensionGranularity;
        positions: string[];
        positionGroups: string[];
    }>({
        groupId: groupId,
        tags: [],
        dateRange: 'last 60 days',
        measures: configurations.find((c) => c.value === 'avg_load_by_tag')?.measures ?? [],
        dimensions: [],
        selectedIndividual: '',
        configuration: 'avg_load',
        timeGrouping: 'day',
        positions: [],
        positionGroups: [],
    });
    const handlePositionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        let positions = filters.positions.filter((p) => p !== e.target.value);

        if (e.target.checked) {
            positions.push(e.target.value);
        }
        console.log(positions);
        setFilters((filters) => ({ ...filters, positions }));
    };

    const handlePositionGroupChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        let positionGroups = filters.positionGroups.filter((p) => p !== e.target.value);

        if (e.target.checked) {
            positionGroups.push(e.target.value);
        }

        setFilters((filters) => ({ ...filters, positionGroups }));
    };
    useEffect(() => {
        viewGroup(groupId)
            .then((response) => setGroup(response.data))
            .catch(() => {})
            .finally();
        getGroups({ page: 1, limit: 1000 })
            .then((response) => setGroups(response.data.data))
            .catch(() => {})
            .finally();
        getMembers(groupId, { limit: 1000 })
            .then((response) => {
                setIndividuals(response.data.data.map((m) => m.individual));
            })
            .catch(() => {});
    }, [groupId]);

    useEffect(() => {
        if (!group) {
            return;
        }
        getHierarchyTags({
            'filter[type]': 'position',
            'filter[organization]': group?.organization?.uuid ?? '',
        })
            .then((response) => {
                setPositions(response.data);
            })
            .catch(() => {
                console.log('error getting positions');
            });
        getHierarchyTags({
            'filter[type]': 'position_group',
            'filter[organization]': group?.organization?.uuid ?? '',
        })
            .then((response) => {
                setPositionGroups(response.data);
            })
            .catch(() => {
                console.log('error getting positions');
            });
    }, [group]);

    useEffect(() => {
        let f: Filter[] = [];
        if (filters.tags.length > 0) {
            f.push({
                member: 'DimTag.tagId',
                operator: 'equals',
                values: filters.tags,
            });
        }

        if (filters.groupId) {
            f.push({
                member: 'DimGroup.uuid',
                operator: 'equals',
                values: [filters.groupId],
            });
        }

        if (filters.positions.length > 0) {
            f.push({
                member: 'DimPosition.tagId',
                operator: 'equals',
                values: filters.positions,
            });
        }

        if (filters.positionGroups.length > 0) {
            f.push({
                member: 'DimPositionGroup.tagId',
                operator: 'equals',
                values: filters.positionGroups,
            });
        }

        let timeDimensions: TimeDimension[] = [];

        if (filters.dateRange !== 'all') {
            timeDimensions.push({
                dimension: 'FactGpsSummaries.dimSessionDateTime',
                granularity: filters.timeGrouping,
                dateRange: filters.dateRange,
            });
        } else {
            timeDimensions.push({
                dimension: 'FactGpsSummaries.dimSessionDateTime',
                granularity: filters.timeGrouping,
            });
        }

        setCubeQuery({
            dimensions: filters.dimensions,
            filters: f,
            measures: filters.measures,
            order: {
                'FactGpsSummaries.dimSessionDateTime': 'asc',
            },
            timeDimensions,
            limit: 5000,
        });
    }, [filters]);

    useEffect(() => {
        // do an initial load on cube to determine the latest date and
        // the earliest date.
        cubejsApi
            .load({
                measures: [
                    'FactGpsSummaries.latestSessionDateTime',
                    'FactGpsSummaries.earliestSessionDateTime',
                ],
                filters: [
                    {
                        member: 'DimGroup.uuid',
                        operator: 'equals',
                        values: [groupId],
                    },
                ],
            })
            .then((results) => {
                let latest = results?.rawData()[0][
                    'FactGpsSummaries.latestSessionDateTime'
                ] as string;
                let earliest = results?.rawData()[0][
                    'FactGpsSummaries.earliestSessionDateTime'
                ] as string;

                if (latest && earliest) {
                    const latestDateTime = DateTime.fromFormat(latest, 'yyyy-MM-dd HH:mm:ss');
                    const earliestDateTime = DateTime.fromFormat(earliest, 'yyyy-MM-dd HH:mm:ss');

                    const diff = latestDateTime.diff(earliestDateTime, 'days').days;

                    // if the time span between latest and earliest is at least 1 day, set the date range to that
                    if (diff >= 1 && diff <= 90) {
                        setFilters((filters) => ({
                            ...filters,
                            groupId: groupId,
                            dateRange: [
                                earliestDateTime.startOf('day').toISODate() ?? '',
                                latestDateTime.endOf('day').toISODate() ?? '',
                            ],
                        }));
                    } else {
                        setFilters((filters) => ({
                            ...filters,
                            groupId: groupId,
                            dateRange: 'last 90 days',
                        }));
                    }
                }
                setDateRangeLoaded(true);
            });
    }, [groupId]);

    return (
        <CubeProvider cubejsApi={cubejsApi}>
            <Container sx={{ padding: 8 }} maxWidth={'xl'}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 4 }}>
                    <TypographyTitle>View All Uploaded / Imported GPS Summaries</TypographyTitle>
                    <Select
                        name="measure"
                        id="measure"
                        size={'small'}
                        value={filters.configuration}
                        onChange={(e) =>
                            setFilters((filters) => ({
                                ...filters,
                                configuration: e.target.value,
                                measures:
                                    configurations.find((c) => c.value === e.target.value)
                                        ?.measures ?? [],
                                dimensions:
                                    configurations.find((c) => c.value === e.target.value)
                                        ?.dimensions ?? [],
                            }))
                        }
                    >
                        {configurations.map((config) => (
                            <MenuItem key={config.value} value={config.value}>
                                <Box
                                    sx={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {config.label}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box
                    component={Paper}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 8,
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <TagMultiSelect
                            tagType={'reports'}
                            selected={filters.tags}
                            setSelected={(v) => setFilters((filters) => ({ ...filters, tags: v }))}
                        />
                        <CubeRelativeDateRangeSelector
                            size="small"
                            value={filters.dateRange}
                            onChange={(v) => {
                                setFilters((oldFilters) => {
                                    return { ...oldFilters, dateRange: v };
                                });
                            }}
                        />
                        <Select
                            name="timeGrouping"
                            id="timeGrouping"
                            size={'small'}
                            label={'Group By'}
                            value={filters.timeGrouping}
                            onChange={(e) =>
                                setFilters((filters) => ({
                                    ...filters,
                                    timeGrouping: e.target.value as TimeDimensionGranularity,
                                }))
                            }
                        >
                            <MenuItem value={'day'}>Day</MenuItem>
                            <MenuItem value={'week'}>Week</MenuItem>
                            <MenuItem value={'month'}>Month</MenuItem>
                        </Select>
                    </Stack>

                    <Select
                        name="group"
                        id="groups"
                        size={'small'}
                        value={group?.uuid ?? ''}
                        onChange={(e) => push(`/community/groups/${e.target.value}/gps-summary`)}
                    >
                        {groups.map((group) => (
                            <MenuItem key={group.uuid} value={group.uuid}>
                                <Box
                                    sx={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {group.name}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Grid container spacing={2} sx={{ mt: 4 }}>
                    <Grid xs={12} sm={4} md={3} lg={2}>
                        <Stack spacing={2}>
                            <Paper sx={{ p: 4 }}>
                                <ListOfGpsSummaryDates groupId={groupId} />
                            </Paper>
                            <Paper sx={{ p: 4 }}>
                                <Typography variant={'h6'}>Position(s)</Typography>
                                {positions.length === 0 && (
                                    <Typography variant={'body2'}>No positions</Typography>
                                )}
                                <FormGroup>
                                    {positions.map((position) => (
                                        <FormControlLabel
                                            key={position.id}
                                            control={
                                                <Checkbox
                                                    onChange={handlePositionChanged}
                                                    value={position.id}
                                                />
                                            }
                                            label={position.name}
                                        />
                                    ))}
                                </FormGroup>
                                <Typography variant={'h6'}>Position Group(s)</Typography>
                                {positionGroups.length === 0 && (
                                    <Typography variant={'body2'}>No position groups</Typography>
                                )}
                                <FormGroup>
                                    {positionGroups.map((group) => (
                                        <FormControlLabel
                                            key={group.id}
                                            control={
                                                <Checkbox
                                                    onChange={handlePositionGroupChanged}
                                                    value={group.id}
                                                />
                                            }
                                            label={group.name}
                                        />
                                    ))}
                                </FormGroup>
                            </Paper>
                            <Paper sx={{ p: 4 }}>
                                <Typography variant={'h6'}>Selected Athlete</Typography>
                                <RadioGroup
                                    aria-label="tag type"
                                    name="type"
                                    value={filters.selectedIndividual}
                                    onChange={(e) =>
                                        setFilters((f) => ({
                                            ...f,
                                            selectedIndividual: e.target.value,
                                        }))
                                    }
                                >
                                    {individuals
                                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                                        .sort((a, b) => {
                                            // Move the selected individual to the top
                                            if (a.uuid === filters.selectedIndividual) {
                                                return -1; // a comes before b
                                            } else if (b.uuid === filters.selectedIndividual) {
                                                return 1; // b comes before a
                                            } else {
                                                return 0; // No change
                                            }
                                        })
                                        .map((i) => (
                                            <FormControlLabel
                                                key={i.uuid}
                                                value={i.uuid}
                                                control={<Radio size={'small'} />}
                                                label={i.name}
                                            />
                                        ))}
                                </RadioGroup>
                            </Paper>
                        </Stack>
                    </Grid>
                    <Grid xs={12} sm={8} md={9} lg={10}>
                        <Stack spacing={4}>
                            <Paper sx={{ width: '100%', p: 4 }}>
                                <TypographyTitle>
                                    {
                                        configurations.find(
                                            (m) => m.value === filters.configuration,
                                        )?.label
                                    }
                                </TypographyTitle>
                                <Box sx={{ width: '100%', height: 400 }}>
                                    {cubeQuery && dateRangeLoaded && (
                                        <QueryRenderer
                                            query={cubeQuery}
                                            render={(props) => (
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <ComposedChart
                                                        height={450}
                                                        data={props?.resultSet?.chartPivot() ?? []}
                                                        margin={{
                                                            top: 5,
                                                            right: 30,
                                                            left: 20,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid
                                                            horizontal={true}
                                                            vertical={false}
                                                            strokeDasharray="1"
                                                        />
                                                        <XAxis
                                                            height={120}
                                                            dataKey="x"
                                                            angle={-90}
                                                            textAnchor="end"
                                                            tickFormatter={formatDateStringLuxon}
                                                            interval={0}
                                                            style={{
                                                                fontSize: '0.75rem',
                                                            }}
                                                        />
                                                        <YAxis
                                                            yAxisId={'default'}
                                                            tickFormatter={formatNumber}
                                                            style={{
                                                                fontSize: '0.75rem',
                                                            }}
                                                        />
                                                        {props?.resultSet?.seriesNames() &&
                                                            props?.resultSet
                                                                ?.seriesNames()
                                                                ?.findIndex(
                                                                    (s) =>
                                                                        s.key ===
                                                                        'FactGpsSummaries.acuteToChronicRatio',
                                                                ) > -1 && (
                                                                <YAxis
                                                                    yAxisId={'acwr'}
                                                                    tickFormatter={formatNumber}
                                                                    style={{
                                                                        fontSize: '0.75rem',
                                                                    }}
                                                                    orientation={'right'}
                                                                />
                                                            )}
                                                        <Tooltip
                                                            labelFormatter={formatDateStringLuxon}
                                                            formatter={formatTooltip}
                                                        />
                                                        <Brush
                                                            dataKey="x"
                                                            height={30}
                                                            stroke="#8884d8"
                                                            tickFormatter={formatDateStringLuxon}
                                                        />
                                                        {props?.resultSet?.seriesNames() &&
                                                            props?.resultSet
                                                                ?.seriesNames()
                                                                ?.findIndex(
                                                                    (s) =>
                                                                        s.key ===
                                                                        'FactGpsSummaries.acuteToChronicRatio',
                                                                ) > -1 && (
                                                                <ReferenceLine
                                                                    yAxisId={'acwr'}
                                                                    y={1.3}
                                                                    stroke="red"
                                                                />
                                                            )}
                                                        {props?.resultSet
                                                            ?.seriesNames()
                                                            ?.filter(
                                                                (s) =>
                                                                    s.key !==
                                                                    'FactGpsSummaries.acuteToChronicRatio',
                                                            )
                                                            .map((series, i) => (
                                                                <Bar
                                                                    yAxisId={'default'}
                                                                    key={series.key}
                                                                    stackId="a"
                                                                    dataKey={series.key}
                                                                    name={series.title}
                                                                    fill={colors[i]}
                                                                />
                                                            ))}
                                                        {props?.resultSet
                                                            ?.seriesNames()
                                                            ?.filter(
                                                                (s) =>
                                                                    s.key ===
                                                                    'FactGpsSummaries.acuteToChronicRatio',
                                                            )
                                                            .map((series, i) => (
                                                                <Line
                                                                    dot={false}
                                                                    yAxisId={'acwr'}
                                                                    key={
                                                                        'FactGpsSummaries.acuteToChronicRatio'
                                                                    }
                                                                    dataKey={series.key}
                                                                    name={series.title}
                                                                    fill={colors[i]}
                                                                />
                                                            ))}
                                                    </ComposedChart>
                                                </ResponsiveContainer>
                                            )}
                                        />
                                    )}
                                </Box>
                            </Paper>
                            <Paper sx={{ width: '100%', p: 4 }}>
                                <TypographyTitle>Selected Athlete</TypographyTitle>
                                <Box sx={{ width: '100%', height: 400 }}>
                                    {cubeQuery && filters.selectedIndividual && (
                                        <QueryRenderer
                                            query={{
                                                ...cubeQuery,
                                                filters: [
                                                    ...(cubeQuery.filters ?? []),
                                                    {
                                                        member: 'DimIndividual.uuid',
                                                        operator: 'equals',
                                                        values: [filters.selectedIndividual],
                                                    },
                                                ],
                                            }}
                                            render={(props) => (
                                                <ResponsiveContainer width="100%" height="100%">
                                                    <BarChart
                                                        height={450}
                                                        data={props?.resultSet?.chartPivot() ?? []}
                                                        margin={{
                                                            top: 5,
                                                            right: 30,
                                                            left: 20,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid
                                                            horizontal={true}
                                                            vertical={false}
                                                            strokeDasharray="1"
                                                        />
                                                        <XAxis
                                                            height={120}
                                                            dataKey="x"
                                                            angle={-90}
                                                            textAnchor="end"
                                                            tickFormatter={formatDateStringLuxon}
                                                            interval={0}
                                                            style={{
                                                                fontSize: '0.75rem',
                                                            }}
                                                        />
                                                        <YAxis
                                                            tickFormatter={formatNumber}
                                                            style={{
                                                                fontSize: '0.75rem',
                                                            }}
                                                        />
                                                        <Tooltip
                                                            labelFormatter={formatDateStringLuxon}
                                                            formatter={formatTooltip}
                                                        />
                                                        <Brush
                                                            dataKey="x"
                                                            height={30}
                                                            stroke="#8884d8"
                                                            tickFormatter={formatDateStringLuxon}
                                                        />
                                                        {configurations.find(
                                                            (m) =>
                                                                m.value === filters.configuration,
                                                        )?.value === 'acwr_load' && (
                                                            <ReferenceLine y={1.3} stroke="red" />
                                                        )}
                                                        {props?.resultSet
                                                            ?.seriesNames()
                                                            .map((series, i) => (
                                                                <Bar
                                                                    key={series.key}
                                                                    stackId="a"
                                                                    dataKey={series.key}
                                                                    name={series.title}
                                                                    fill={colors[i]}
                                                                />
                                                            ))}
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            )}
                                        />
                                    )}
                                </Box>
                            </Paper>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </CubeProvider>
    );
};
export default GpsSummaries;
