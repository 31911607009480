import { Close, Save, Share, Sync, Visibility } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Snackbar, Tooltip, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { getForm } from 'api';
import { Can, FormSharingDrawer, SharedUsersAvatarGroup } from 'components';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formSelector, saveForm, setForm } from 'redux/reducers/form';
import { useAppDispatch } from 'redux/store';
import ROUTES from 'Routes/routes';
import { Form, getIdFromEndOfRoute, SharedUser, useToggle } from 'utils';
import { AssessmentForm } from '../components';

const CreateAssessment = () => {
    const theme = useTheme();
    const breakpoint = useMediaQuery(theme.breakpoints.up('md'));
    const { push, location } = useHistory();
    let formUuid = getIdFromEndOfRoute(location.pathname);
    const dispatch = useAppDispatch();
    const { form, updating } = useSelector(formSelector);
    const [shareDrawerOpen, toggleShareDrawerOpen] = useToggle();
    const [showActions, setShowActions] = useState<boolean>(false);
    const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const share = () => {
        toggleShareDrawerOpen();
    };
    const getFormAndSaveIntoStore = useCallback(() => {
        getForm(formUuid).then((response) => {
            dispatch(setForm(response.data as Form));
            setShowActions(true);
        });
    }, [dispatch, formUuid]);
    const [snackMessage, setSnackMessage] = useState<string>('');

    // Load the form from the database
    useEffect(() => {
        if (formUuid && !showActions) {
            getFormAndSaveIntoStore();
        }
    }, [formUuid, showActions, getFormAndSaveIntoStore]);

    const backToList = useCallback(() => {
        push(ROUTES.ClassAssessments.path);
    }, [push]);

    const saveChanges = useCallback(() => {
        dispatch(saveForm()).then((res) => {
            let httpStatus = res.payload;
            if (httpStatus !== 204) {
                setSnackMessage(
                    'Failed to create assessment. Please reload the page and try again.',
                );
            }
        });
    }, [dispatch]);

    const onPreview = useCallback(() => {
        push(ROUTES.AssessmentSubmissionPreview.path.replace(':id', formUuid || ''));
    }, [push, formUuid]);

    return (
        <>
            <FixedLayout
                title=""
                titleHidden={!breakpoint}
                titleActions={
                    <Box display="flex" minHeight={96} justifyContent={'space-between'}>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={!!snackMessage}
                            autoHideDuration={6000}
                            message={snackMessage}
                            onClose={() => setSnackMessage('')}
                        />
                        {showActions && (
                            <Box alignItems="center" display="flex" justifyContent="center">
                                <Tooltip title="Preview assessment">
                                    <IconButton size="small" onClick={onPreview}>
                                        <Visibility color="primary" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={updating ? 'Saving...' : 'Save assessment'}>
                                    {/* <span> Fixes MUI error w.r.t.
                                     * setting a disabled button in tooltips
                                     */}
                                    <span>
                                        <IconButton
                                            disabled={updating}
                                            size="small"
                                            onClick={saveChanges}
                                        >
                                            {updating ? (
                                                <Sync color="primary" />
                                            ) : (
                                                <Save color="primary" />
                                            )}
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <SharedUsersAvatarGroup sharedUsers={sharedUsers} />
                            </Box>
                        )}
                    </Box>
                }
                content={
                    <>
                        {updating && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    zIndex: 1,
                                    '@media (min-width:600px)': {
                                        top: 50,
                                    },
                                }}
                            >
                                <LinearProgress />
                            </Box>
                        )}
                        {showActions ? <AssessmentForm /> : <div />}
                    </>
                }
                hideFooterOnMobileDevices={false}
                footerActions={
                    (showActions && !breakpoint && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-around"
                            sx={{
                                background: '#5068CF',
                                borderRadius: '16px 16px 0 0',
                                color: 'white',
                                height: '50px',
                            }}
                        >
                            <IconButton
                                aria-label="close"
                                sx={{
                                    width: '30px',
                                    '&:disabled': {
                                        color: 'inherit',
                                    },
                                }}
                                size="small"
                                color="inherit"
                                onClick={backToList}
                            >
                                <Close />
                            </IconButton>
                            <IconButton
                                aria-label="share"
                                sx={{
                                    width: '30px',
                                    '&:disabled': {
                                        color: 'inherit',
                                    },
                                }}
                                size="small"
                                color="inherit"
                                onClick={share}
                            >
                                <Share />
                            </IconButton>
                            <IconButton
                                aria-label="save"
                                disabled={updating}
                                sx={{
                                    width: '30px',
                                    '&:disabled': {
                                        color: 'inherit',
                                    },
                                }}
                                size="small"
                                color="inherit"
                                onClick={saveChanges}
                            >
                                {updating ? <Sync /> : <Save />}
                            </IconButton>
                            <IconButton
                                aria-label="preview"
                                sx={{
                                    width: '30px',
                                    '&:disabled': {
                                        color: 'inherit',
                                    },
                                }}
                                size="small"
                                color="inherit"
                                onClick={onPreview}
                            >
                                <Visibility />
                            </IconButton>
                        </Box>
                    )) ||
                    null
                }
            />
            {form && (
                <Can I="form:share" this={form}>
                    <FormSharingDrawer
                        open={shareDrawerOpen}
                        onClose={toggleShareDrawerOpen}
                        formId={formUuid}
                        onUsersLoaded={setSharedUsers}
                    />
                </Can>
            )}
        </>
    );
};

export default CreateAssessment;
