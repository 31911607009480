import { Filter } from '@cubejs-client/core';
import { OpenInNew } from '@mui/icons-material';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, IconButton, Menu, MenuItem, Paper, Stack, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { GridColDef } from '@mui/x-data-grid';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridActionsCellItemProps,
    GridOverlay,
    GridRowParams,
    GridSortItem,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { cubejsApi } from '../../../app.functions';
import CustomDateRangePicker from '../../../components/FormControl/CustomDateRangePicker';
import { AbilityContext } from '../../../components/Functional/Can';
import CustomPagination from '../../../components/Functional/CustomPagination';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { useNonInitialEffect } from '../../../hooks';
import { GenericSubject } from '../../../utils/ability';
import DimensionFilterBuilder from '../components/DimensionFilterBuilder';

const toTimeFormat = (time: number) => {
    return (time / 1000).toFixed(3);
};

function VideoAnalysisDashboard() {
    const [loading, setLoading] = useState<boolean>(false);
    const [dateRangeValue, setDateRangeValue] = useState<DateRange<DateTime>>([null, null]);
    const [individualFilters, setIndividualFilters] = useState<{ [key: string]: any }>({});
    const [groupFilters] = useState<{ [key: string]: any }>({});
    const [rows, setRows] = useState<any>([]);
    const [speedRows, setSpeedRows] = useState<any>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [speedRowCount, setSpeedRowCount] = useState<number>(0);
    const { push } = useHistory();
    const [rowCountState, setRowCountState] = React.useState(rowCount);
    const ability = React.useContext(AbilityContext);

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);
    const [speedRowCountState, setSpeedRowCountState] = React.useState(speedRowCount);

    React.useEffect(() => {
        setSpeedRowCountState((prevSpeedRowCountState) =>
            speedRowCount !== undefined ? speedRowCount : prevSpeedRowCountState,
        );
    }, [speedRowCount, setSpeedRowCountState]);

    const getRowActions = useCallback(
        (params: GridRowParams) => {
            let actions: React.ReactElement<GridActionsCellItemProps>[] = [];
            const genericIndividual = {
                uuid: params.row['DimIndividual.uuid'],
                object: 'individual',
                organization_uuid: params.row['DimOrganization.uuid'],
                managing_user: params.row['DimIndividual.managingUserUuid']
                    ? { uuid: params.row['DimIndividual.managingUserUuid'] }
                    : null,
            } as GenericSubject;
            if (
                ability.can('individual:manage-videos', genericIndividual) ||
                ability.can('individual:manage-logs', genericIndividual)
            ) {
                actions.push(
                    <GridActionsCellItem
                        key={'view-button'}
                        icon={<OpenInNew />}
                        onClick={() => {
                            push(
                                `/community/video-analysis/${params.row['DimVideoAnalysis.uuid']}`,
                            );
                        }}
                        label={'View'}
                    />,
                );
            }

            return actions;
        },
        [push, ability],
    );

    const speedColumns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'DimIndividual.name',
                headerName: 'Athlete',
                minWidth: 180,
                filterable: false,
                sortable: true,
            },
            {
                field: 'DimVideoAnalysis.dateTimeUtc',
                headerName: 'Date',
                minWidth: 120,
                filterable: false,
                sortable: true,
            },

            {
                field: 'FactVideoAnalysis.dimTenYardTime',
                headerName: '10 Yard Time',
                filterable: false,
                sortable: false,
                width: 120,
            },
            {
                field: 'FactVideoAnalysis.dimTenYardSpeedMph',
                headerName: '10 Yard Speed',
                filterable: false,
                sortable: true,
                width: 120,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'View',
                getActions: (params) => getRowActions(params),
            },
        ],
        [getRowActions],
    );

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'DimIndividual.name',
                headerName: 'Athlete',
                minWidth: 180,
                filterable: false,
                sortable: true,
            },
            {
                field: 'DimDate.fullDate',
                headerName: 'Date',
                minWidth: 120,
                filterable: false,
                sortable: true,
            },
            {
                field: 'FactVideoAnalysisStrides.strideNumber',
                headerName: '#',
                filterable: false,
                sortable: false,
                width: 30,
            },
            {
                field: 'FactVideoAnalysisStrides.strideTimeLeft',
                headerName: 'Stride Time (L)',
                filterable: false,
                sortable: true,
                width: 120,
            },
            {
                field: 'FactVideoAnalysisStrides.strideTimeRight',
                headerName: 'Stride Time (R)',
                filterable: false,
                sortable: true,
                width: 120,
            },
            {
                field: 'FactVideoAnalysisStrides.airTimeLeft',
                headerName: 'Air Time (L)',
                filterable: false,
                sortable: true,
                width: 120,
            },
            {
                field: 'FactVideoAnalysisStrides.airTimeRight',
                headerName: 'Air Time (R)',
                filterable: true,
                sortable: true,
                width: 120,
            },
            {
                field: 'FactVideoAnalysisStrides.groundTimeLeft',
                headerName: 'Ground Time (L)',
                filterable: false,
                sortable: true,
                width: 120,
            },
            {
                field: 'FactVideoAnalysisStrides.groundTimeRight',
                headerName: 'Ground Time (R)',
                filterable: false,
                sortable: true,
                width: 120,
            },
            {
                field: 'FactVideoAnalysisStrides.peakExtensionLeft',
                headerName: 'Peak Ext (L)',
                filterable: false,
                sortable: true,
                width: 120,
            },
            {
                field: 'FactVideoAnalysisStrides.peakExtensionRight',
                headerName: 'Peak Ext (R)',
                filterable: false,
                sortable: true,
                width: 120,
            },
            {
                field: 'FactVideoAnalysisStrides.peakFlexionLeft',
                headerName: 'Peak Flexion (L)',
                filterable: false,
                sortable: true,
                width: 120,
            },
            {
                field: 'FactVideoAnalysisStrides.peakFlexionRight',
                headerName: 'Peak Flexion (R)',
                filterable: false,
                sortable: true,
                width: 120,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'View',
                getActions: getRowActions,
            },
        ],
        [getRowActions],
    );
    const [queryOptions, setQueryOptions] = useState<{
        sort?: { [key: string]: 'asc' | 'desc' };
        limit: number;
        page: number;
        filters: {
            individual?: Filter;
            group?: Filter;
            after?: Filter;
            before?: Filter;
        };
    }>({
        sort: {
            'DimDate.fullDate': 'desc',
        },
        limit: 100,
        page: 1,
        filters: {},
    });

    const [debouncedQueryOptions] = useDebounce(queryOptions, 700);

    useEffect(() => {
        setLoading(true);
        cubejsApi
            .load({
                dimensions: [
                    'FactVideoAnalysis.factVideoAnalysisKey',
                    'DimVideoAnalysis.uuid',
                    'DimOrganization.uuid',
                    'DimIndividual.uuid',
                    'DimIndividual.managingUserUuid',
                    'DimIndividual.name',
                    'DimVideoAnalysis.dateTimeUtc',
                    'FactVideoAnalysis.dimTenYardTime',
                    'FactVideoAnalysis.dimTenYardSpeedMph',
                ],
                filters: Object.values(debouncedQueryOptions.filters).filter((filter) => !!filter),
                ungrouped: true,
                limit: debouncedQueryOptions.limit,
                offset: (debouncedQueryOptions.page - 1) * debouncedQueryOptions.limit,
                order: debouncedQueryOptions.sort,
            })
            .then((resultSet) => {
                setSpeedRows(
                    resultSet.tablePivot().map((analysis: any) => ({
                        ...analysis,
                        summary_ten_yard_time: toTimeFormat(
                            analysis['FactVideoAnalysis.dimTenYardTime'],
                        ),
                        summary_ten_yard_speed: toTimeFormat(
                            analysis['FactVideoAnalysis.dimTenYardSpeedMph'],
                        ),
                        'DimVideoAnalysis.dateTimeUtc': moment(
                            analysis['DimVideoAnalysis.dateTimeUtc'],
                        ).format('MM/DD/YYYY'),
                    })),
                );
            })
            .catch(() => {})
            .catch(() => {})
            .finally(() => setLoading(false));

        cubejsApi
            .load({
                filters: Object.values(debouncedQueryOptions.filters).filter((filter) => !!filter),
                dimensions: [],
                measures: ['FactVideoAnalysis.count'],
            })
            .then((resultSet: any) => {
                setSpeedRowCount(resultSet.tablePivot()[0]['FactVideoAnalysis.count']);
            })
            .catch(() => {})
            .finally(() => {});

        cubejsApi
            .load({
                dimensions: [
                    'FactVideoAnalysisStrides.factVideoAnalysisStrideKey',
                    'DimOrganization.uuid',
                    'DimIndividual.uuid',
                    'DimIndividual.managingUserUuid',
                    'DimIndividual.name',
                    'DimDate.fullDate',
                    'DimVideoAnalysis.uuid',
                    'FactVideoAnalysisStrides.strideNumber',
                    'FactVideoAnalysisStrides.strideTimeLeft',
                    'FactVideoAnalysisStrides.strideTimeRight',
                    'FactVideoAnalysisStrides.airTimeLeft',
                    'FactVideoAnalysisStrides.airTimeRight',
                    'FactVideoAnalysisStrides.groundTimeLeft',
                    'FactVideoAnalysisStrides.groundTimeRight',
                    'FactVideoAnalysisStrides.peakExtensionLeft',
                    'FactVideoAnalysisStrides.peakExtensionRight',
                    'FactVideoAnalysisStrides.peakFlexionLeft',
                    'FactVideoAnalysisStrides.peakFlexionRight',
                    'FactVideoAnalysisStrides.startTimestamp',
                    'FactVideoAnalysisStrides.endTimestamp',
                ],
                filters: Object.values(debouncedQueryOptions.filters).filter((filter) => !!filter),
                ungrouped: true,
                limit: debouncedQueryOptions.limit,
                offset: (debouncedQueryOptions.page - 1) * debouncedQueryOptions.limit,
                order: debouncedQueryOptions.sort,
            })
            .then((resultSet: any) => {
                setRows(
                    resultSet.tablePivot().map((stride: any) => ({
                        ...stride,
                        'DimDate.fullDate': moment(stride['DimDate.fullDate']).format('MM/DD/YYYY'),
                        'FactVideoAnalysisStrides.strideTimeLeft': toTimeFormat(
                            stride['FactVideoAnalysisStrides.strideTimeLeft'],
                        ),
                        'FactVideoAnalysisStrides.strideTimeRight': toTimeFormat(
                            stride['FactVideoAnalysisStrides.strideTimeRight'],
                        ),
                        'FactVideoAnalysisStrides.airTimeLeft': toTimeFormat(
                            stride['FactVideoAnalysisStrides.airTimeLeft'],
                        ),
                        'FactVideoAnalysisStrides.airTimeRight': toTimeFormat(
                            stride['FactVideoAnalysisStrides.airTimeRight'],
                        ),
                        'FactVideoAnalysisStrides.groundTimeLeft': toTimeFormat(
                            stride['FactVideoAnalysisStrides.groundTimeLeft'],
                        ),
                        'FactVideoAnalysisStrides.groundTimeRight': toTimeFormat(
                            stride['FactVideoAnalysisStrides.groundTimeRight'],
                        ),
                        'FactVideoAnalysisStrides.startTimestamp': toTimeFormat(
                            stride['FactVideoAnalysisStrides.startTimestamp'],
                        ),
                        'FactVideoAnalysisStrides.endTimestamp': toTimeFormat(
                            stride['FactVideoAnalysisStrides.endTimestamp'],
                        ),
                    })),
                );
            })
            .catch(() => {})
            .finally(() => {});

        cubejsApi
            .load({
                filters: Object.values(debouncedQueryOptions.filters).filter((filter) => !!filter),
                dimensions: [],
                measures: ['FactVideoAnalysisStrides.count'],
            })
            .then((resultSet: any) => {
                setRowCount(resultSet.tablePivot()[0]['FactVideoAnalysisStrides.count']);
            })
            .catch(() => {})
            .finally(() => {});
    }, [debouncedQueryOptions]);

    const handleGroupsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                filters: {
                    ...queryOptions.filters,
                    group:
                        value.length > 0
                            ? ({
                                  member: 'DimMember.groupUuid',
                                  operator: 'equals',
                                  values: value,
                              } as Filter)
                            : undefined,
                },
            });
            setIndividualFilters((filters) => ({
                ...filters,
                'filter[group_uuid]': value.length > 0 ? value.join(',') : undefined,
            }));
        },
        [queryOptions],
    );

    const handleIndividualsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                filters: {
                    ...queryOptions.filters,
                    individual:
                        value.length > 0
                            ? ({
                                  member: 'DimIndividual.uuid',
                                  operator: 'equals',
                                  values: value,
                              } as Filter)
                            : undefined,
                },
            });
        },
        [queryOptions],
    );

    useNonInitialEffect(() => {
        let before: Filter;
        let after: Filter;
        if (dateRangeValue[0]) {
            after = {
                member: 'DimDate.fullDate',
                operator: 'afterDate',
                values: [dateRangeValue[0]?.toISODate()],
            } as Filter;
        }
        if (dateRangeValue[1]) {
            before = {
                member: 'DimDate.fullDate',
                operator: 'beforeDate',
                values: [dateRangeValue[1]?.toISODate()],
            } as Filter;
        }
        setQueryOptions((options) => {
            return {
                ...options,
                filters: {
                    before: before,
                    after: after,
                },
            };
        });
    }, [dateRangeValue]);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setQueryOptions((o) => ({
            ...o,
            sort: sortModel.reduce((result: { [key: string]: any }, item: GridSortItem) => {
                result[item.field] = item.sort;
                return result;
            }, {}),
        }));
    }, []);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [layoutFormat, setLayoutFormat] = React.useState<'stride analysis' | 'speed analysis'>(
        'stride analysis',
    );
    const castIconStyle: React.CSSProperties = {
        animation: 'constantScale 3s infinite', // Apply the animation
        cursor: 'pointer',
    };
    const CustomNoRowsOverlay = () => {
        return (
            <GridOverlay>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography>Video Analysis AI requires a subscription.</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography>
                            Learn how by clicking on the Learn Center at the top right of your
                            screen:{' '}
                        </Typography>
                        <div style={castIconStyle}>
                            <CastForEducationIcon sx={{ marginRight: 8, marginLeft: 9 }} />
                        </div>
                    </div>
                    <Typography>
                        Or click on the organization name under your profile picture in the top
                        right and then select Premium Features.
                    </Typography>
                </div>
            </GridOverlay>
        );
    };
    return (
        <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" spacing={2} alignItems={'center'} flexWrap={'wrap'}>
                <DimensionFilterBuilder
                    id={'filter-list-groups'}
                    resourcePath="groups"
                    buttonText="Group(s)"
                    onChange={handleGroupsFilterChanged}
                    otherFilters={groupFilters}
                />
                <DimensionFilterBuilder
                    id={'filter-list-individuals'}
                    resourcePath="individuals"
                    buttonText="Athletes"
                    onChange={handleIndividualsFilterChanged}
                    otherFilters={individualFilters}
                />
                <CustomDateRangePicker
                    value={dateRangeValue}
                    onChange={(newValue) => {
                        setDateRangeValue(newValue);
                    }}
                />
                <IconButton
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <Badge badgeContent={'New'} color="default">
                        <SettingsIcon
                            fontSize={'large'}
                            sx={{
                                animation: 'rotation 35s infinite linear',
                                '@keyframes rotation': {
                                    from: {
                                        transform: 'rotate(0deg)',
                                    },
                                    to: {
                                        transform: 'rotate(359deg)',
                                    },
                                },
                            }}
                            color={'primary'}
                        />
                    </Badge>
                </IconButton>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem>
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        <ToggleButtonGroup
                            color="primary"
                            value={layoutFormat}
                            exclusive
                            onChange={(event, newLayoutFormat) => {
                                setLayoutFormat(newLayoutFormat);
                                handleClose();
                            }}
                            aria-label="Platform"
                        >
                            <ToggleButton value="stride analysis">Stride Analysis</ToggleButton>
                            <ToggleButton value="speed analysis">Speed Analysis</ToggleButton>
                        </ToggleButtonGroup>
                    </MenuItem>
                </Menu>
            </Stack>
            <Box
                sx={{
                    flexGrow: 1,
                    width: '100%',
                }}
            >
                <DataGridPremium
                    sx={{
                        backgroundColor: '#ffffff',
                        boxShadow: { lg: 3 },
                        borderRadius: { lg: 3 },
                        padding: { lg: 2 },
                        '.MuiDataGrid-virtualScrollerContent': {
                            height: '100%!important',
                        },
                    }}
                    autoHeight={false}
                    density="compact"
                    rows={layoutFormat === 'stride analysis' ? rows : speedRows}
                    columns={layoutFormat === 'stride analysis' ? columns : speedColumns}
                    getRowId={(row) => {
                        if (layoutFormat === 'stride analysis') {
                            return row['FactVideoAnalysisStrides.factVideoAnalysisStrideKey'];
                        } else {
                            return row['FactVideoAnalysis.factVideoAnalysisKey'];
                        }
                    }}
                    initialState={{
                        pinnedColumns: { left: ['individual_name'] },
                        sorting: { sortModel: [{ field: 'DimDate.fullDate', sort: 'desc' }] },
                    }}
                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    loading={loading}
                    disableRowSelectionOnClick
                    rowCount={
                        layoutFormat === 'stride analysis' ? rowCountState : speedRowCountState
                    }
                    slots={{
                        footer: CustomPagination,
                        loadingOverlay: CustomLoadingOverlay,
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        footer: {
                            hasNext: true,
                            hasPrevious: queryOptions.page > 1,
                            onNext: () => setQueryOptions((o) => ({ ...o, page: o.page + 1 })),
                            onPrevious: () => setQueryOptions((o) => ({ ...o, page: o.page - 1 })),
                            page: queryOptions.page,
                            total: rowCount,
                        },
                    }}
                />
            </Box>
        </Paper>
    );
}

export default React.memo(VideoAnalysisDashboard);
