import { Search } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    LinearProgress,
    TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from 'use-debounce';
import { getDimensionChoices } from '../home.api';
import { Dimension } from '../home.types';

interface Props {
    resourcePath?: string;
    value: { [key: string]: boolean };
    onChange: (value: { [key: number]: boolean }) => void;
    otherFilters?: { [key: string]: string };
    dataGetter?: (
        page: number,
        limit: number,
        payload?: { 'filter[query]'?: string; 'filter[is_current]'?: number },
    ) => Promise<any>;
    dataMapper?: (data: any) => { dimensions: Dimension[]; page: number; hasMore: boolean };
}

const PAGE_LIMIT = 100;

export default React.memo(function DimensionChoiceSelector({
    resourcePath,
    value,
    onChange,
    otherFilters,
    dataGetter,
    dataMapper,
}: Props) {
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [data, setData] = useState<Dimension[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [debouncedSearchText] = useDebounce(searchText, 300);

    const fetchNextPage = () => {
        fetchPage(page + 1, {
            'filter[query]': debouncedSearchText,
            'filter[is_current]': 1,
        });
    };

    const sortData = useCallback(
        (inputData: Dimension[]) => {
            // Sort the data alphabetically by title
            const sortedData = [...inputData].sort((a, b) => a.title.localeCompare(b.title));

            // Sort the data to keep selected ones at the top
            sortedData.sort((a, b) => {
                const aSelected = value[a.uuid];
                const bSelected = value[b.uuid];
                if (aSelected === bSelected) return 0;
                return aSelected ? -1 : 1;
            });

            return sortedData;
        },
        [value],
    ); // value is a dependency because it's used inside sortData

    const fetchPage = useCallback(
        (p: number, payload: any = {}) => {
            setLoading(true); // Start by setting the loading state

            if (dataGetter && dataMapper) {
                dataGetter(p, PAGE_LIMIT, payload)
                    .then((responseData) => {
                        const mappedData = dataMapper(responseData.data);
                        const sortedDimensions = sortData(mappedData.dimensions); // Use the sorting function here
                        setPage(mappedData.page);
                        setData((oldData) => {
                            if (mappedData.page === 1) {
                                return sortedDimensions;
                            }
                            return [...oldData, ...sortedDimensions];
                        });
                        setHasMore(mappedData.hasMore);
                    })
                    .catch(() => setLoading(false))
                    .finally(() => setLoading(false));
            } else if (resourcePath) {
                getDimensionChoices(resourcePath, {
                    ...otherFilters,
                    page: p,
                    limit: PAGE_LIMIT,
                    ...payload,
                })
                    .then((response) => {
                        const sortedDimensions = sortData(response.data.data); // Use the sorting function here too
                        setPage(response.data.meta.current_page);
                        setData((oldData) => {
                            if (p === 1) {
                                return sortedDimensions;
                            }
                            return [...oldData, ...sortedDimensions];
                        });
                        setHasMore(!!response.data.links.next);
                    })
                    .catch(() => setLoading(false))
                    .finally(() => setLoading(false));
            }
        },
        [resourcePath, otherFilters, dataGetter, dataMapper, sortData], // Add sortData to the dependency array
    );

    useEffect(() => {
        console.log('debounced', debouncedSearchText);
        fetchPage(1, {
            'filter[query]': debouncedSearchText,
            'filter[is_current]': 1,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchText]);

    return (
        <Box>
            {loading && <LinearProgress />}
            <TextField
                sx={{ padding: 4 }}
                variant={'standard'}
                id={'athlete-search'}
                value={searchText}
                placeholder={'Search...'}
                onChange={(e) => setSearchText(e.target.value)} // This is just for setting the searchText
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
            />

            <InfiniteScroll
                next={fetchNextPage}
                dataLength={data.length}
                height={300}
                hasMore={hasMore}
                loader={<LinearProgress />}
            >
                <FormGroup sx={{ paddingX: 4 }}>
                    {data.map((row) => (
                        <FormControlLabel
                            sx={{ width: '100%' }}
                            key={row.uuid}
                            control={
                                <Checkbox
                                    checked={!!value[row.uuid]}
                                    value={row.uuid}
                                    onChange={(event, checked) => {
                                        const updatedValue = {
                                            ...value,
                                            [event.target.value]: checked,
                                        };
                                        onChange(updatedValue);
                                        setData((oldData) => sortData(oldData)); // Re-sort the data upon athlete selection/deselection
                                    }}
                                    size="small"
                                />
                            }
                            label={
                                <Box sx={{ display: 'flex' }}>
                                    <Avatar
                                        sx={{ width: 32, height: 32, mr: 4 }}
                                        src={row.image_url ?? ''}
                                        alt={row.title}
                                        variant={'rounded'}
                                    />
                                    {row.title}
                                </Box>
                            }
                        />
                    ))}
                </FormGroup>
            </InfiniteScroll>
        </Box>
    );
});
