import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import map from 'lodash/map';
import { Dashboard } from '../modules/community/community.types';
import {
    Actions,
    CalendarEvent,
    EntityPermission,
    EntityType,
    Form,
    Group,
    Individual,
    Movement,
    Organization,
    Program,
    Session,
    ShareableClassification,
    UserData,
} from './types';

export interface GenericSubject {
    uuid: string;
    object: EntityType;
    organization?: { uuid: string };
    organization_uuid?: string;
    managing_user?: { uuid: string };
    owner_uuid: string;
}

type Subjects =
    | ShareableClassification
    | Session
    | Program
    | Movement
    | Organization
    | Form
    | Group
    | Individual
    | CalendarEvent
    | Dashboard
    | GenericSubject
    | 'all';

export type AppAbility = Ability<[Actions, Subjects]>;
export const NewAppAbility = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(
    user: UserData | null,
    entityPermissions: Array<EntityPermission>,
) {
    const { can, rules } = new AbilityBuilder(NewAppAbility);

    if (!user) {
        return rules;
    }

    /**
     * Build the permissions. Note, we're not using the exact permission set as the
     * backend because not all permissions are used on the front end.  We're selectively
     * choosing and assigning them.
     *
     * @see https://casl.js.org/v5/en/guide/conditions-in-depth
     */
    entityPermissions.forEach(({ roles, team, permissions }: EntityPermission) => {
        roles.forEach(({ name }: { name: string; display_name: string }) => {
            if (name === 'organization-admin') {
                can(
                    [
                        'movement:share',
                        'movement:update',
                        'movement:publish',
                        'movement:view',
                        'movement:delete',
                        'movement:duplicate',
                    ],
                    'movement',
                    {
                        'organization.uuid': team.name,
                    },
                );
                can(
                    [
                        'session:share',
                        'session:update',
                        'session:publish',
                        'session:view',
                        'session:delete',
                        'session:assign',
                        'session:duplicate',
                        'session:assign-self',
                    ],
                    'session',
                    {
                        'organization.uuid': team.name,
                    },
                );
                can(
                    [
                        'form:share',
                        'form:update',
                        'form:delete',
                        'form:view',
                        'form:assign',
                        'form:assign-self',
                        'form:duplicate',
                    ],
                    'form',
                    {
                        'organization.uuid': team.name,
                    },
                );
                can(
                    [
                        'individual:manage-calendar',
                        'individual:manage-logs',
                        'individual:manage-submissions',
                        'individual:manage-videos',
                        'individual:update',
                        'individual:view',
                        'individual:delete',
                    ],
                    'individual',
                    {
                        organization_uuid: team.name,
                    },
                );
                can(
                    [
                        'group:add-members',
                        'group:update',
                        'group:view-members',
                        'group:update-members',
                        'group:invite-members',
                        'group:view-logs',
                        'group:manage-logs',
                        'group:manage-calendar',
                    ],
                    'group',
                    {
                        organization_uuid: team.name,
                    },
                );
                can(
                    ['saved-filter:update', 'saved-filter:delete', 'saved-filter:view'],
                    'saved-filter',
                    {
                        'organization.uuid': team.name,
                    },
                );
                can(['dashboard:view', 'dashboard:update', 'dashboard:delete'], 'dashboard', {
                    'organization.uuid': team.name,
                });
            }
            if (name === 'organization-content-manager') {
                can(
                    [
                        'movement:share',
                        'movement:update',
                        'movement:publish',
                        'movement:view',
                        'movement:delete',
                        'movement:duplicate',
                    ],
                    'movement',
                    {
                        'organization.uuid': team.name,
                    },
                );
                can(
                    [
                        'session:share',
                        'session:update',
                        'session:publish',
                        'session:view',
                        'session:delete',
                        'session:assign',
                        'session:duplicate',
                        'session:assign-self',
                    ],
                    'session',
                    {
                        'organization.uuid': team.name,
                    },
                );
                can(
                    [
                        'form:share',
                        'form:update',
                        'form:delete',
                        'form:view',
                        'form:assign',
                        'form:assign-self',
                        'form:duplicate',
                    ],
                    'form',
                    {
                        'organization.uuid': team.name,
                    },
                );
                can(
                    [
                        'individual:manage-calendar',
                        'individual:manage-logs',
                        'individual:manage-submissions',
                        'individual:manage-videos',
                        'individual:update',
                        'individual:view',
                        'individual:delete',
                    ],
                    'individual',
                    {
                        organization_uuid: team.name,
                    },
                );
                can(
                    [
                        'group:add-members',
                        'group:update',
                        'group:view-members',
                        'group:update-members',
                        'group:invite-members',
                        'group:view-logs',
                        'group:manage-logs',
                        'group:manage-calendar',
                    ],
                    'group',
                    {
                        organization_uuid: team.name,
                    },
                );
                can(['saved-filter:update', 'saved-filter:view'], 'saved-filter', {
                    'organization.uuid': team.name,
                });
                can(['dashboard:view', 'dashboard:update'], 'dashboard', {
                    'organization.uuid': team.name,
                });
            }
            if (name === 'organization-coach') {
                can(['movement:view', 'movement:duplicate'], 'movement', {
                    'organization.uuid': team.name,
                });
                can(
                    ['session:view', 'session:assign', 'session:duplicate', 'session:assign-self'],
                    'session',
                    {
                        'organization.uuid': team.name,
                    },
                );
                can(['form:view', 'form:assign', 'form:assign-self', 'form:duplicate'], 'form', {
                    'organization.uuid': team.name,
                });
                can(
                    [
                        'individual:manage-calendar',
                        'individual:manage-logs',
                        'individual:manage-submissions',
                        'individual:manage-videos',
                        'individual:update',
                        'individual:view',
                    ],
                    'individual',
                    {
                        organization_uuid: team.name,
                    },
                );
                can(['saved-filter:update', 'saved-filter:view'], 'saved-filter', {
                    'organization.uuid': team.name,
                });
                can(['dashboard:view', 'dashboard:update'], 'dashboard', {
                    'organization.uuid': team.name,
                });
            }
            can(map(permissions, 'name'), team.classification, { uuid: team.name });
        });
    });

    // attach managing user permission to the individual
    can(
        [
            'individual:manage-calendar',
            'individual:manage-logs',
            'individual:manage-submissions',
            'individual:update',
            'individual:view',
        ],
        'individual',
        {
            'managing_user.uuid': user.uuid,
        },
    );

    // attach owner permissions to the individual
    can(
        [
            'individual:manage-calendar',
            'individual:manage-logs',
            'individual:manage-submissions',
            'individual:manage-videos',
            'individual:update',
            'individual:view',
            'individual:delete',
        ],
        'individual',
        {
            owner_uuid: user.uuid,
        },
    );

    can(
        [
            'session:share',
            'session:update',
            'session:publish',
            'session:view',
            'session:delete',
            'session:assign',
            'session:duplicate',
        ],
        'session',
        {
            owner_uuid: user.uuid,
        },
    );

    can(
        [
            'form:share',
            'form:update',
            'form:view',
            'form:delete',
            'form:assign',
            'form:assign-self',
            'form:duplicate',
        ],
        'form',
        {
            owner_uuid: user.uuid,
        },
    );

    can(
        [
            'group:add-members',
            'group:update',
            'group:view-members',
            'group:update-members',
            'group:invite-members',
            'group:view-logs',
            'group:manage-logs',
            'group:manage-calendar',
        ],
        'group',
        {
            owner_uuid: user.uuid,
        },
    );

    can(
        [
            'movement:duplicate',
            'movement:delete',
            'movement:publish',
            'movement:share',
            'movement:view',
            'movement:update',
        ],
        'movement',
        {
            owner_uuid: user.uuid,
        },
    );

    can(
        [
            'saved-filter:update',
            'saved-filter:view',
            'saved-filter:delete',
            'dashboard:update',
            'dashboard:view',
            'dashboard:delete',
        ],
        'dashboard',
        {
            owner_uuid: user.uuid,
        },
    );

    can(['event:update', 'event:delete'], 'event', {
        owner: user.uuid,
    });

    return rules;
}

export function buildAbilityFor(
    user: UserData | null = null,
    entityPermissions: Array<EntityPermission> = [],
): AppAbility {
    return new NewAppAbility(defineRulesFor(user, entityPermissions), {
        detectSubjectType: (object) => object?.object,
    });
}
