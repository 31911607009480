import { CubeProvider, QueryRenderer } from '@cubejs-client/react';
import { Box } from '@mui/material';
import { cubejsApiForOrganization, formatCurrency } from 'app.functions';
import { DateTime } from 'luxon';
import React from 'react';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { useNetworkingOrganization } from '../state/NetworkingContext';
import { AnalyticsTooltip } from './AnalyticsTooltip';
import DasboardCard from './DashboardCard';

const PartnershipEarningsAnalytics: React.FC = () => {
    const organizationDetails = useNetworkingOrganization();
    const nextPayoutDate = React.useMemo(() => {
        const now = DateTime.now();
        return now.day <= 15 ? now.set({ day: 15 }) : now.set({ day: 1 }).plus({ months: 1 });
    }, []);

    return organizationDetails.data ? (
        <CubeProvider cubejsApi={cubejsApiForOrganization}>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
                    gap: 3,
                    mb: 4,
                }}
            >
                <QueryRenderer
                    query={{
                        measures: ['FactPayouts.totalAmount'],
                        dimensions: ['DimPayout.payoutDate'],
                        order: {
                            'DimPayout.payoutDate': 'desc',
                        },
                        limit: 1,
                        filters: [
                            {
                                member: 'DimOrganization.uuid',
                                operator: 'equals',
                                values: [organizationDetails.data.uuid],
                            },
                        ],
                    }}
                    render={({ resultSet }) => {
                        const data = resultSet?.tablePivot() || [];
                        return (
                            <DasboardCard
                                title="Last Payout"
                                value={formatCurrency({
                                    amount: Number(data[0]?.['FactPayouts.totalAmount']) / 100 || 0,
                                    currency: 'USD',
                                })}
                                subtext={
                                    data[0]?.['DimPayout.payoutDate']
                                        ? new Date(
                                              data[0]?.['DimPayout.payoutDate'] as string,
                                          ).toLocaleDateString()
                                        : 'No payouts yet'
                                }
                            />
                        );
                    }}
                />
                <QueryRenderer
                    query={{
                        measures: ['FactPayouts.totalAmount'],
                        filters: [
                            {
                                member: 'DimOrganization.uuid',
                                operator: 'equals',
                                values: [organizationDetails.data.uuid],
                            },
                        ],
                    }}
                    render={({ resultSet }) => (
                        <DasboardCard
                            title="Total Payouts"
                            value={formatCurrency({
                                amount:
                                    Number(
                                        resultSet?.tablePivot()[0]?.['FactPayouts.totalAmount'],
                                    ) / 100 || 0,
                                currency: 'USD',
                            })}
                        />
                    )}
                />
                <QueryRenderer
                    query={{
                        measures: ['FactCommissions.totalAmount'],
                        filters: [
                            {
                                member: 'FactCommissions.isPaid',
                                operator: 'equals',
                                values: ['false'],
                            },
                            {
                                or: [
                                    {
                                        and: [
                                            {
                                                member: 'DimBonus.type',
                                                operator: 'notEquals',
                                                values: [
                                                    'First Level Check Matching',
                                                    'Second Level Check Matching',
                                                    'Third Level Check Matching',
                                                ],
                                            },
                                            {
                                                member: 'FactCommissions.earnedDate',
                                                operator: 'beforeDate',
                                                values: [
                                                    nextPayoutDate.minus({ days: 30 }).toISODate(),
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        and: [
                                            {
                                                member: 'DimBonus.type',
                                                operator: 'equals',
                                                values: [
                                                    'First Level Check Matching',
                                                    'Second Level Check Matching',
                                                    'Third Level Check Matching',
                                                ],
                                            },
                                            {
                                                member: 'FactCommissions.earnedDate',
                                                operator: 'beforeDate',
                                                values: [DateTime.now().toISODate()],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                member: 'DimOrganization.uuid',
                                operator: 'equals',
                                values: [organizationDetails.data.uuid],
                            },
                        ],
                    }}
                    render={({ resultSet }) => (
                        <DasboardCard
                            title="Next Payout Estimate"
                            value={formatCurrency({
                                amount:
                                    Number(
                                        resultSet?.tablePivot()[0]?.['FactCommissions.totalAmount'],
                                    ) / 100 || 0,
                                currency: 'USD',
                            })}
                            subtext={`Expected ${nextPayoutDate.toLocaleString()}`}
                            infoText={`This is an estimate based on commissions earned before ${nextPayoutDate
                                .minus({ days: 30 })
                                .toLocaleString()} based on our 30 day payout cycle`}
                        />
                    )}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: 200,
                    mb: 4,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    p: 3,
                    boxShadow: 1,
                }}
            >
                <QueryRenderer
                    query={{
                        measures: ['FactPayouts.totalAmount'],
                        timeDimensions: [
                            {
                                dimension: 'DimPayout.payoutDate',
                                granularity: 'month',
                                dateRange: [
                                    DateTime.now().minus({ months: 6 }).toISODate(),
                                    DateTime.now().toISODate(),
                                ],
                            },
                        ],
                        order: {
                            'DimPayout.payoutDate': 'asc',
                        },
                        filters: [
                            {
                                member: 'DimOrganization.uuid',
                                operator: 'equals',
                                values: [organizationDetails.data.uuid],
                            },
                        ],
                    }}
                    render={({ resultSet }) => {
                        const data =
                            resultSet?.tablePivot().map((row) => ({
                                date: DateTime.fromISO(
                                    row['DimPayout.payoutDate.month'] as string,
                                ).toFormat('LLL yyyy'),
                                amount: Number(row['FactPayouts.totalAmount']) / 100,
                            })) || [];

                        const hasData = data.some((row) => row.amount > 0);

                        if (!hasData) {
                            return (
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'text.secondary',
                                    }}
                                >
                                    No earnings data available yet
                                </Box>
                            );
                        }

                        return (
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={data}>
                                    <XAxis dataKey="date" />
                                    <Tooltip content={<AnalyticsTooltip />} />
                                    <Bar dataKey="amount" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        );
                    }}
                />
            </Box>
        </CubeProvider>
    ) : (
        <Box />
    );
};

export default PartnershipEarningsAnalytics;
