import React, { useEffect } from 'react';
import { loadMovements, loadUsers } from '../../../redux/reducers/plan/reports/actions';
import { useAppDispatch } from '../../../redux/store';

export default function ReportDataResolver(): React.ReactElement {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(loadUsers());
        dispatch(loadMovements());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
}
