import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Link } from 'utils';

type ReturnType = Promise<AxiosResponse<Link>>;

const createLink = (
    shareableId: string,
    shareableType: string,
    role: string,
    code?: string,
): ReturnType => {
    return Request.post(`/links/${shareableType}/${shareableId}`, {
        role,
        code,
    });
};

export default createLink;
