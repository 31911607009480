import InputBase from '@mui/material/InputBase';
import Close from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

export interface SearchClearableInputProps {
    /** onChange A callback for when the value changes */
    onChange: (value: string) => void;
    /** value The value of the search field */
    value: string;
    /** placeholderText The text of the placeholder for the search input */
    placeholderText?: string;
}

export default function SearchClearableInput({
    value,
    onChange,
    placeholderText = 'Search...',
}: SearchClearableInputProps) {
    const theme = useTheme();
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.backgroundColor.main,
                '&:hover': {
                    backgroundColor: theme.palette.backgroundColor.hover,
                },
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <InputBase
                value={value}
                sx={{
                    width: '90%',
                    fontSize: 18,
                    color: 'inherit',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    padding: theme.spacing(6, 2, 6, 6),
                }}
                placeholder={placeholderText}
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) => {
                    onChange(e.target.value);
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onKeyDown={(e) => {
                    e.stopPropagation();
                }}
            />
            {value && (
                <Box
                    role="button"
                    tabIndex={0}
                    onClick={() => onChange('')}
                    sx={{
                        width: '8%',
                        display: 'flex',
                        alignItems: 'center',
                        '&:focus': {
                            outline: 'none',
                        },
                    }}
                    onKeyDown={() => onChange('')}
                >
                    <Close sx={{ cursor: 'pointer' }} />
                </Box>
            )}
        </Box>
    );
}
