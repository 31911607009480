import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import { DateTime } from 'luxon';
import React, { ReactElement, useContext, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import CountCard from '../../../components/Cards/CountCard';
import { OrganizationFilterList } from '../../../components/Filters';
import { userSelector } from '../../../redux/reducers/user';
import { CountSearchDto, Organization } from '../../../utils';
import { getAttendance, getCalendarDashboard } from '../api/calendar';

import { SessionsCard } from '../components';
import { AttendanceSearchFilters } from '../train.types';
import * as fromDashboardState from './TrainDashboard.state';

const TrainDashboard = (): ReactElement => {
    const { organizations } = useContext(OrganizationsContext);
    const screenSizeSmall = useMediaQuery(useTheme().breakpoints.down('sm'));

    const [state, dispatch] = useReducer(
        fromDashboardState.reducer,
        fromDashboardState.initialState,
    );

    const { userData } = useSelector(userSelector);

    const theme = useTheme();

    const onCategorySelected = (category: string) => {
        if (category === state.selectedCategory) {
            dispatch({ type: 'select-category', payload: { category: null } });
        } else {
            dispatch({ type: 'select-category', payload: { category } });
        }
    };
    const onOrganizationalFilterClicked = (organization: Organization) => {
        if (organization.uuid === state.selectedOrganization) {
            dispatch({
                type: 'select-organization',
                payload: { organization: null },
            });
        } else {
            dispatch({
                type: 'select-organization',
                payload: { organization: organization.uuid },
            });
        }
    };
    const getCardTitle = (category: string): string => {
        switch (category) {
            case 'upcoming':
                return 'Upcoming Sessions';
            case 'overdue':
                return 'Overdue Sessions';
            case 'complete':
                return 'Complete Sessions';
            default:
                return 'Assigned Sessions';
        }
    };

    useEffect(() => {
        dispatch({
            type: 'organizations-loaded',
            payload: { organizations },
        });
    }, [organizations]);

    useEffect(() => {
        dispatch({ type: 'load-session-queued', payload: {} });
        let searchPayload = {
            'filter[is_session]': '1',
            'filter[individual.secondary_owner_uuid]': userData?.uuid,
            'filter[not_skipped]': '1',
            sort: 'starts_at',
        } as AttendanceSearchFilters;

        if (!state.selectedCategory) {
            searchPayload['filter[incomplete]'] = '1';
        }
        if (state.selectedCategory === 'upcoming') {
            searchPayload['filter[upcoming]'] = '1';
        }
        if (state.selectedCategory === 'overdue') {
            searchPayload['filter[overdue]'] = '1';
        }
        if (state.selectedCategory === 'complete') {
            searchPayload['filter[complete]'] = '1';
        }
        if (state.selectedOrganization) {
            searchPayload['filter[event.organization.source_id]'] = [state.selectedOrganization];
        }

        getAttendance(searchPayload)
            .then((response) =>
                dispatch({
                    type: 'load-session-success',
                    payload: { sessions: response.data },
                }),
            )
            .catch(() => dispatch({ type: 'load-session-failure', payload: {} }));
    }, [state.selectedCategory, state.selectedOrganization]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch({ type: 'load-dashboard-queued', payload: {} });
        let countSearchPayload = {
            'filter[is_session]': '1',
            'filter[not_skipped]': '1',
            'filter[individual.secondary_owner_uuid]': userData?.uuid,
        } as CountSearchDto;
        if (state.selectedOrganization) {
            countSearchPayload['filter[event.organization.source_id]'] = state.selectedOrganization;
        }
        getCalendarDashboard(countSearchPayload)
            .then((results) =>
                dispatch({
                    type: 'load-dashboard-success',
                    payload: { dashboard: results.data },
                }),
            )
            .catch(() => {
                dispatch({ type: 'load-dashboard-failure', payload: {} });
            });
    }, [state.selectedOrganization, userData]);
    useEffect(() => {
        dispatch({ type: 'load-completed-sessions', payload: {} });
        getAttendance({
            'filter[individual.secondary_owner_uuid]': userData?.uuid ?? '',
            'filter[ends_before]': DateTime.now().toSeconds().toString(),
            'filter[is_session]': '1',
            'filter[not_skipped]': '1',
            'filter[complete]': '1',
        })
            .then((response) =>
                dispatch({
                    type: 'load-completed-sessions-success',
                    payload: { sessions: response.data },
                }),
            )
            .catch(() => dispatch({ type: 'load-completed-sessions-failure', payload: {} }));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container style={{ paddingTop: 32, paddingBottom: 64 }}>
            <Box marginBottom={16} display="flex" justifyContent="space-around" width={'100%'}>
                <CountCard
                    title="UPCOMING"
                    count={state.dashboard.upcoming}
                    icon={screenSizeSmall ? undefined : <AssignmentIcon />}
                    color={
                        state.selectedCategory && state.selectedCategory !== 'upcoming'
                            ? theme.palette.backgroundColor.quickSilver
                            : '#4CAF50'
                    }
                    onClick={() => onCategorySelected('upcoming')}
                    cardProps={{
                        style: {
                            flexBasis: '30%',
                        },
                    }}
                />
                <CountCard
                    title="OVERDUE"
                    count={state.dashboard.overdue}
                    icon={screenSizeSmall ? undefined : <ErrorOutlineIcon />}
                    color={
                        (state.selectedCategory && state.selectedCategory !== 'overdue') ||
                        state.dashboard.overdue === 0
                            ? theme.palette.backgroundColor.quickSilver
                            : '#F44336'
                    }
                    onClick={() => onCategorySelected('overdue')}
                    cardProps={{
                        style: {
                            flexBasis: '30%',
                        },
                    }}
                />
                <CountCard
                    title="COMPLETED"
                    count={state.dashboard.complete}
                    icon={screenSizeSmall ? undefined : <AssignmentTurnedInIcon />}
                    color={
                        state.selectedCategory && state.selectedCategory !== 'complete'
                            ? theme.palette.backgroundColor.quickSilver
                            : '#2196F3'
                    }
                    onClick={() => onCategorySelected('complete')}
                    cardProps={{
                        style: {
                            flexBasis: '30%',
                        },
                    }}
                />
            </Box>
            <Box
                marginBottom={16}
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                width={'100%'}
            >
                <OrganizationFilterList
                    organizations={state.organizations}
                    onClicked={(organization) => onOrganizationalFilterClicked(organization)}
                    selectedOrganization={state.selectedOrganization}
                />
            </Box>
            <Grid container>
                <Grid sx={{ padding: 8 }} item xs={12} md={!state.selectedCategory ? 8 : 12}>
                    <SessionsCard
                        showLoggingButtons={true}
                        error={state.sessionsError}
                        loading={state.attendanceLoading && !state.attendanceLoaded}
                        attendance={state.attendance}
                        emptyPhrase="No sessions found"
                        title={getCardTitle(state.selectedCategory ? state.selectedCategory : '')}
                        showOrganizationImage={true}
                        onSessionSkipped={(eventId, individualId) =>
                            dispatch({
                                type: 'session-skipped-queued',
                                payload: { eventId, individualId },
                            })
                        }
                    />
                </Grid>
                {!state.selectedCategory && (
                    <Grid sx={{ padding: 8 }} item xs={12} md={4}>
                        <SessionsCard
                            error={state.completedSessionsError}
                            loading={state.completedSessionsLoading}
                            attendance={state.completedSessions}
                            title="Completed Sessions"
                        />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default TrainDashboard;
