import React, { FC } from 'react';

import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';

const AssessmentFilterSkeleton: FC<React.PropsWithChildren<unknown>> = () => (
    <Grid container spacing={4}>
        <Grid item xs={3}>
            <Skeleton animation="wave" variant="rectangular" height={28} />
        </Grid>
        <Grid item xs={3}>
            <Skeleton animation="wave" variant="rectangular" height={28} />
        </Grid>
        <Grid item xs={6}>
            <Skeleton animation="wave" variant="rectangular" height={28} />
        </Grid>
    </Grid>
);

export default AssessmentFilterSkeleton;
