import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<void>>;

const acceptInvite = (token: string): ReturnType => {
    return Request.post(`/invites/accept`, {
        token,
    });
};

export default acceptInvite;
