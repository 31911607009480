import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import ScribeCard from '../components/ScribeCard';

function MultiRawDataImportToGroup() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Use folder import to group to save time importing your raw gps data for
                        larger sessions or team data.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Manual multi-file folder CSV import thru groups.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}

                <ScribeCard
                    title={'Multiple CSV imports to Group'}
                    headline={'Multiple CSV imports to Group'}
                    iframeSrc={
                        'https://scribehow.com/embed/Upload_Multiple_Raw_GPS_Files_to_Group__oL6Dn9GRSIaIG-46Pg6Hrw?as=scrollable&skipIntro=true&removeLogo=true'
                    }
                />
            </Grid>
        </>
    );
}

export default MultiRawDataImportToGroup;
