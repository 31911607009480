import { AxiosResponse } from 'axios';
import { Request } from 'api';

type ReturnType = Promise<AxiosResponse<Array<any>>>;

const getMyFeed = (query: { page: number; status?: string }): ReturnType => {
    return Request.get(`/social/my-feed`, { params: query });
};

export default getMyFeed;
