import { List, Timer } from '@mui/icons-material';
import { Box, Chip, CircularProgress, Container, Typography, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import React from 'react';
import { getHtml, Session } from 'utils';
import ViewSessionMovement from './ViewSessionMovement';

export interface SessionSummaryProps {
    session: Session;
}

export interface EditSessionRefProps {
    onSessionSaved: () => Promise<AxiosResponse<Session>> | null;
    onSessionShareDrawerOpened: () => void;
    getSession: () => Session | null;
}

const SessionSummary = ({ session }: SessionSummaryProps) => {
    const theme = useTheme();

    // Convert markdown to HTML
    const descriptionHTML: string = getHtml(session.description || '');

    return (
        <>
            {session ? (
                <Container
                    sx={{
                        marginBottom: theme.spacing(10),
                    }}
                    maxWidth="md"
                >
                    {/* Duration */}
                    <Box pt={8} pb={8} textAlign="center">
                        <Typography variant="caption" color="textSecondary">
                            <List
                                sx={{
                                    verticalAlign: 'bottom',
                                }}
                            />{' '}
                            {session.movements?.length} Movements{' '}
                            <Timer
                                sx={{
                                    verticalAlign: 'bottom',
                                }}
                            />
                            {session.duration || 'NA'} minutes
                        </Typography>
                    </Box>
                    {session.cover_photo && (
                        <Box textAlign="center" pt={5} pb={5}>
                            <img
                                src={session.cover_photo}
                                alt={session.title || ''}
                                style={{
                                    whiteSpace: 'pre-line',
                                }}
                            />
                        </Box>
                    )}
                    {session.organization && (
                        <Box pt={5} pb={5}>
                            <Typography variant="body2" align="center">
                                This session belongs to {session.organization?.name}.
                            </Typography>
                        </Box>
                    )}
                    {/* Description */}
                    <Box pt={8} pb={8} textAlign="left">
                        <div dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
                    </Box>
                    {/* Attributes */}
                    {session.attributes.length > 0 && (
                        <Box
                            pt={8}
                            pb={8}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{ gap: 20 }}
                        >
                            {session.attributes.map((attribute, attrIndex) => (
                                <Chip
                                    key={attrIndex}
                                    label={`${attribute.name}: ${
                                        Array.isArray(attribute.value)
                                            ? attribute.value.join(', ')
                                            : attribute.value
                                    }`}
                                />
                            ))}
                        </Box>
                    )}
                    {/* Movements */}
                    <ViewSessionMovement sessionMovements={session.movements || []} />
                </Container>
            ) : (
                <Box pt={20} display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

SessionSummary.displayName = 'SessionSummary';

export default SessionSummary;
