import CloudUpload from '@mui/icons-material/CloudUpload';
import { Box, ButtonBase, Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
    coverUrl: null | string;
    onClick: () => void;
}

const CoverPhotoButton: FC<React.PropsWithChildren<Props>> = ({ coverUrl, onClick }) => {
    return (
        <ButtonBase
            onClick={onClick}
            sx={{
                background: coverUrl
                    ? `linear-gradient(89.01deg, rgba(46, 107, 200, 0.4) -5.34%, rgba(0, 62, 157, 0.4) 51.84%, rgba(0, 25, 110, 0.4) 106.13%), url(${coverUrl})`
                    : 'linear-gradient(89.01deg, #2E6BC8 -5.34%, #003E9D 51.84%, #00196E 106.13%)',
                backgroundSize: 'cover',
                // biased position for landscape photos
                backgroundPosition: 'center 17.5%',
                borderRadius: 1,
                color: 'white',
                height: 200,
                textAlign: 'center',
                textTransform: 'uppercase',
                width: '100%',
                '@media (min-width: 960px)': {
                    fontSize: 14,
                    lineHeight: '200px',
                    mt: 30,
                },
            }}
        >
            <CloudUpload />
            <Box ml={2}>
                <Typography variant="body2">
                    {coverUrl ? 'Update Cover Media' : 'Upload Cover Media'}
                </Typography>
            </Box>
        </ButtonBase>
    );
};

export default CoverPhotoButton;
