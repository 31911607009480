import { Request } from 'api';
import { AxiosResponse } from 'axios';
import {
    Form,
    Group,
    Individual,
    Member,
    MemberType,
    Meta,
    PaginationLink,
    Session,
} from '../../../utils';
import { GroupWorkout } from '../../train/train.types';
import { Dashboard } from '../community.types';

const deleteMember = (groupId: string, memberId: string): Promise<AxiosResponse<void>> => {
    return Request.delete(`/groups/${groupId}/members/${memberId}`);
};

const getMemberTypes = (): Promise<AxiosResponse<Array<MemberType>>> => {
    return Request.get(`/utilities/member-types`);
};

const updateGroup = (group: Group): Promise<AxiosResponse<Group>> => {
    return Request.put(`/groups/${group.uuid}`, group);
};

const archiveGroup = (group: string): Promise<AxiosResponse<void>> => {
    return Request.delete(`/groups/${group}`);
};

const unarchiveGroup = (group: string): Promise<AxiosResponse<void>> => {
    return Request.post(`/groups/${group}/unarchive`);
};
const viewGroup = (id: string): Promise<AxiosResponse<Group>> => {
    return Request.get(`/groups/${id}`);
};

const batchUpdateSharedSessions = (groupId: string, sessionIds: string[]) => {
    return Request.put(`/groups/${groupId}/sessions`, { sessions: sessionIds });
};

const batchUpdateSharedDashboards = (groupId: string, dashboardIds: string[]) => {
    return Request.put(`/groups/${groupId}/dashboards`, { dashboards: dashboardIds });
};

const batchUpdateSharedForms = (groupId: string, formIds: string[]) => {
    return Request.put(`/groups/${groupId}/forms`, { forms: formIds });
};
const getGroupSessions = (groupId: string): Promise<AxiosResponse<Session[]>> => {
    return Request.get<Session[]>(`/groups/${groupId}/sessions`);
};
const getGroupForms = (groupId: string): Promise<AxiosResponse<Form[]>> => {
    return Request.get<Form[]>(`/groups/${groupId}/forms`);
};
const getGroupDashboards = (groupId: string): Promise<AxiosResponse<Dashboard[]>> => {
    return Request.get<Dashboard[]>(`/groups/${groupId}/dashboards`);
};

const getGroupWorkouts = (groupId: string): Promise<AxiosResponse<GroupWorkout[]>> => {
    return Request.get<GroupWorkout[]>(`/workouts/group-workouts`, { params: { group: groupId } });
};

const getMembers = (
    group: string,
    params = {},
): Promise<AxiosResponse<{ data: Array<Member>; meta: Meta; links: PaginationLink }>> => {
    return Request.get(`/groups/${group}/members`, {
        params,
    });
};

const getIndividualMembers = (individualUuid: string): Promise<AxiosResponse<Array<Member>>> => {
    return Request.get(`/individuals/${individualUuid}/members`);
};

const getGroups = (
    params = {},
): Promise<AxiosResponse<{ data: Array<Group>; links: PaginationLink; meta: Meta }>> => {
    return Request.get(`/groups`, {
        params,
    });
};

const removeManagingUserFromIndividual = (individualId: string): Promise<AxiosResponse<void>> => {
    return Request.post(`/individuals/remove-managing-user`, {
        individual: individualId,
    });
};

const attachManagingUserToIndividual = (
    individualId: string,
): Promise<AxiosResponse<Individual>> => {
    return Request.post(`/individuals/attach-managing-user`, {
        individual: individualId,
    });
};

export {
    deleteMember,
    getMemberTypes,
    updateGroup,
    viewGroup,
    getMembers,
    getIndividualMembers,
    getGroups,
    attachManagingUserToIndividual,
    removeManagingUserFromIndividual,
    batchUpdateSharedSessions,
    getGroupSessions,
    getGroupForms,
    batchUpdateSharedForms,
    getGroupWorkouts,
    batchUpdateSharedDashboards,
    getGroupDashboards,
    archiveGroup,
    unarchiveGroup,
};
