import React, { FC } from 'react';
import AssessmentFilterSkeleton from './AssessmentFilterSkeleton';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Skeleton } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

const LayoutWithSearchSkeleton: FC<React.PropsWithChildren<unknown>> = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                maxWidth: 800,
                margin: '25px 20px',
                [theme.breakpoints.up('sm')]: {
                    width: 600,
                    margin: '36px auto',
                },
            }}
        >
            <Box>
                <Box mb={7}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Skeleton animation="wave" variant="rectangular" height={56} />
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={28}>
                    <AssessmentFilterSkeleton />
                </Box>
            </Box>
            <Box>
                <Grid container>
                    {[...Array(8)].map((_, idx) => (
                        <Grid key={`skeletona-${idx}`} item xs={6} sm={3}>
                            <Skeleton animation="wave" variant="rectangular" height={125} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default LayoutWithSearchSkeleton;
