import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import ScribeCard from '../components/ScribeCard';

function AthleteLoggingAssignment() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: 10, marginTop: 15 }}
                    justifyContent={'center'}
                >
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Athletes log training assignments from their train dashboard.{' '}
                    </Typography>
                </Grid>

                {/* First row */}

                <ScribeCard
                    title={'Athlete Begining an Assignment'}
                    headline={'Logging Assigned Session'}
                    iframeSrc={
                        'https://scribehow.com/embed/Athlete_Logging_Assigned_Session__NL6i-t07Qx2-ixgKAG_VAA?as=scrollable&skipIntro=true&removeLogo=true'
                    }
                />
            </Grid>
        </>
    );
}

export default AthleteLoggingAssignment;
