import { DateTime } from 'luxon';
import type { DateValue, Session, SessionStackCreateRequest } from './types';

const ADD_SESSION_STACK_DATE_FORMAT = 'yyyy-LL-dd HH:mm:ss';
const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Maps the date values collected from <AssignPlan /> to a request body
 * for POST: /api/groups/:groupId/calendars/:calendarId/add-session-stack
 */
export async function mapSessionStackUpdate({
    programId,
    formEntries,
    sessions,
}: {
    programId?: string;
    formEntries: {
        [id: string]: DateValue;
    };
    sessions: Session[];
}): Promise<SessionStackCreateRequest> {
    return new Promise((resolve) => {
        const hasNoUpdates = Object.values(formEntries).every((update) => update.date === null);
        if (hasNoUpdates) {
            resolve({ program: programId, sessions: [] });
        }
        // eslint-disable-next-line no-unused-vars
        const sessionStack = Object.entries(formEntries).reduce<
            SessionStackCreateRequest['sessions']
        >((memo, [index, { date, time, timezone, isWholeDay }], currentIndex) => {
            console.log(index);
            if (!date) {
                return memo;
            }
            const targetSession = sessions[currentIndex];
            const duration = targetSession?.duration || 0;
            const updateParams = {
                uuid: targetSession.uuid,
                start_date_time: formatStartDateTimeRequest({ isWholeDay, time, date }),
                /* default to default datetime (00:00) if the time was not provided */
                end_date_time: formatEndDateTimeRequest({ isWholeDay, time, date, duration }),
                start_timezone: timezone || DEFAULT_TIMEZONE,
                end_timezone: isWholeDay ? null : timezone || DEFAULT_TIMEZONE,
            };
            memo = [...memo, updateParams];
            return memo;
        }, []);
        resolve(
            programId ? { program: programId, sessions: sessionStack } : { sessions: sessionStack },
        );
    });
}

type DateTimeRequest = Pick<DateValue, 'isWholeDay' | 'time' | 'date'>;

export function formatStartDateTimeRequest({ date, time, isWholeDay }: DateTimeRequest): string {
    if (isWholeDay && date) {
        return date.set({ hour: 0, minute: 0, second: 0 }).toFormat(ADD_SESSION_STACK_DATE_FORMAT);
    }
    if (time && date) {
        const formattedTime = time.set({ second: 0 }).toFormat('HH:mm:ss');
        const formattedDate = date.toFormat('yyyy-LL-dd');
        return `${formattedDate} ${formattedTime}`;
    }

    return date?.toFormat(ADD_SESSION_STACK_DATE_FORMAT) || '';
}

interface EndDateTimeRequest extends DateTimeRequest {
    duration: number | string;
}

export function formatEndDateTimeRequest({
    date,
    time,
    isWholeDay,
    duration,
}: EndDateTimeRequest): string | null {
    if (isWholeDay) {
        return null;
    }
    if (time && date) {
        const formattedTime = time.set({ second: 0 }).toFormat('HH:mm:ss');
        const formattedDate = date.toFormat('yyyy-LL-dd');
        const combinedDateTimeString = `${formattedDate} ${formattedTime}`;
        return DateTime.fromFormat(combinedDateTimeString, 'yyyy-LL-dd HH:mm:ss')
            .plus({ minutes: duration as number })
            .toFormat(ADD_SESSION_STACK_DATE_FORMAT);
    }

    if (date) {
        return date.plus({ minutes: duration as number }).toFormat(ADD_SESSION_STACK_DATE_FORMAT);
    }
    return null;
}
