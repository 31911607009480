import * as React from 'react';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import AppleLogo from '../media/AppleLogo.png';
import WindowsPCLogo from '../media/WindowsPCLogo.png';
import { getPresignedDownloadUrl } from '../Learn1TUL.api'; // Adjust the import path as needed

const MAC_FILE_KEY = 'public/McLloyd-Downloads/FiyrPod.dmg'; // S3 key
const PC_FILE_KEY = 'public/McLloyd-Downloads/FiyrPod.exe'; // S3 key

const handleDownload = async (fileKey: string) => {
    try {
        const response = await getPresignedDownloadUrl(fileKey);
        const url = response.data.url;
        window.location.href = url; // This triggers the download
    } catch (error) {
        console.error('Error fetching presigned URL', error);
    }
};

function FiyrPodHelpCenterOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3} justifyContent={'space-evenly'}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        The FiyrPod help center provides a comprehensive set of resources to help
                        you get the most out of your FiyrPod subscription. Here you will find the
                        latest software downloads for Mac and PC as well as other helpful tutorials.
                    </Typography>
                </Grid>

                {/* First row */}
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            sx={{
                                height: 260,
                                backgroundSize: '97%',
                                backgroundPosition: 'top',
                            }}
                            image={AppleLogo}
                            title="FiyrPod Download for Mac"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                FiyrPod Download for Mac
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Download the latest FiyrPod software for Mac by clicking below
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => handleDownload(MAC_FILE_KEY)}>
                                Download for Mac
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardMedia
                            sx={{
                                height: 260,
                                backgroundSize: '97%',
                                backgroundPosition: 'top',
                            }}
                            image={WindowsPCLogo}
                            title="FiyrPod Download for PC"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                FiyrPod Download for PC
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Download the latest FiyrPod software for PC by clicking below
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => handleDownload(PC_FILE_KEY)}>
                                Download for PC
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}

export default FiyrPodHelpCenterOverview;
