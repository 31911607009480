import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<void>>;

const acceptLink = (linkId: string, payload: { code?: string } = {}): ReturnType => {
    return Request.post(`/links/${linkId}/accept`, payload);
};

export default acceptLink;
