import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FullScreenDialogCard from '../components/HowToDialogCard';
import AdminAutomatedSprintDetection from '../media/AdminGPSAutomatedSprintDetection.png';
import SprintCropInitial from '../media/SprintCropInitial.png';
import SprintCrop2nd from '../media/SprintCrop2nd.png';
import SprintCropFineTune from '../media/SprintCropFineTune.png';
import MPHDecelComparison from '../media/MPHDecelComparison.png';
import fortyYardSameAthlete from '../media/40YardSameAthlete.png';

function AdminGPSOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        GPS data can provide the most accurate and most granular method to monitor
                        an athlete&apos;s speed.{' '}
                        <b>There are two types of gps data that 1TUL processes: Raw GPS data</b> and{' '}
                        <b>Session Summary GPS data</b>. Raw data is the most complete data and
                        allows in depth analysis of the athlete&apos;s movement. Session Summary
                        data is just a summary of the session and does not provide the ability to
                        drill down and analyse each sprint in detail.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Features that work with Raw GPS data include: Automated Sprint
                            Detection, Sprint Cropping, Sprint Cropping Exploration, Deceleration
                            Analysis, Speed Control Analysis, Sprint Database Dashboard, and Time
                            Segments Dashboard
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={AdminAutomatedSprintDetection}
                    title="Automated Sprint Detection"
                    headline="Automated Sprint Detection"
                    description="Automated sprint detection detects sprints where the athlete has a near standing still start and a threshold MPH reached for at least 1 second.
                    Sprint detection stops once the athlete has decreased to 70% of maximum velocity for that sprint."
                />

                <FullScreenDialogCard
                    image={SprintCropInitial}
                    title="Sprint Cropping 1hz"
                    headline="Sprint Cropping 1hz"
                    description="Sprint cropping in 1hz allows to to take a rough crop in 1 second increments to be fine tuned in the next step."
                />
                <FullScreenDialogCard
                    image={SprintCrop2nd}
                    title="Sprint Cropping 1hz Fine Tune"
                    headline="Sprint Cropping 1h Fine Tune"
                    description="Sprint cropping in 1hz fine tune allows you to get more granular with the crop and prepare for the final step."
                />
                <FullScreenDialogCard
                    image={SprintCropFineTune}
                    title="Sprint Cropping 10hz Fine Tune"
                    headline="Sprint Cropping 10hz Fine Tune"
                    description="Sprint cropping in 10hz allows you to manually select the time range so you can collect more granular data on a wide variety of runs such as change of direction, football routes, stolen bases, and other sport specific sprints and decelerations."
                />
                <FullScreenDialogCard
                    image={fortyYardSameAthlete}
                    title="Sprint Cropping Exploration"
                    headline="Sprint Cropping Exploration"
                    description="Sprint cropping exploration allows you to compare different sprints from different sessions and allows comparison between different athletes.
                    These manual crops can be added to one graph for separation analysis and speed control analysis."
                />
                <FullScreenDialogCard
                    image={MPHDecelComparison}
                    title="Deceleration Analysis"
                    headline="Deceleration Analysis"
                    description="Deceleration analysis inside of sprint croping allows you to analyse total distance, max speed, time to max speed, max speed time to stop, distance to max speed, and distance from max speed to stop."
                />
            </Grid>
        </>
    );
}

export default AdminGPSOverview;
