import { LocationState, yupSchemas } from 'utils';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { Field, Form, Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField } from 'formik-mui';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { registerUser } from 'redux/reducers/auth';
import { getUserData } from '../../redux/reducers/user';
import MaskedInput from 'components/Inputs/MaskedInput';
import { ButtonWithLoader } from 'components/Buttons';
import { useTheme } from '@mui/system';

const RegisterForm = () => {
    const theme = useTheme();

    const { push } = useHistory();
    const location = useLocation<LocationState>();

    const dispatch = useAppDispatch();

    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [passwordConfirmationVisibility, setPasswordConfirmationVisibility] = useState(false);

    const togglePasswordVisibility = () => setPasswordVisibility(!passwordVisibility);
    const togglePasswordConfirmationVisibility = () =>
        setPasswordConfirmationVisibility(!passwordConfirmationVisibility);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%' }} display="flex" flexDirection="column" alignItems="center">
                <Formik
                    initialValues={yupSchemas.registration.getDefault()}
                    validationSchema={yupSchemas.registration}
                    onSubmit={async (
                        {
                            email,
                            firstName,
                            lastName,
                            password,
                            password_confirmation,
                            phone,
                            user_name,
                        },
                        { setErrors, setSubmitting },
                    ) => {
                        setSubmitting(true);
                        const result = await dispatch(
                            registerUser({
                                email,
                                firstName,
                                lastName,
                                password,
                                password_confirmation,
                                phone,
                                user_name,
                            }),
                        );
                        setSubmitting(false);
                        if (registerUser.fulfilled.match(result)) {
                            if (localStorage.getItem('athleteSubscribeUrl')) {
                                const redirect = localStorage.getItem('athleteSubscribeUrl');
                                localStorage.removeItem('athleteSubscribeUrl');
                                window.location.href = redirect || '/home';
                            } else if (location.state?.referrer) {
                                push(location.state.referrer);
                            } else {
                                push('/home');
                            }
                            await dispatch(getUserData());
                        } else {
                            if (result.payload) {
                                // Server sent validation errors
                                if ('errors' in result.payload) {
                                    setErrors(result.payload?.errors || {});
                                }
                            }
                        }
                    }}
                >
                    {({ isValid, dirty, isSubmitting }) => (
                        <Form>
                            <Grid container justifyContent="center" spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        id="firstName"
                                        name="firstName"
                                        variant="filled"
                                        label="First Name"
                                        component={TextField}
                                        style={{ width: '100%', marginBottom: theme.spacing(10) }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        id="lastName"
                                        name="lastName"
                                        variant="filled"
                                        label="Last Name"
                                        component={TextField}
                                        style={{ width: '100%', marginBottom: theme.spacing(10) }}
                                    />
                                </Grid>
                            </Grid>
                            <Field
                                id="email"
                                name="email"
                                label="Email"
                                variant="filled"
                                component={TextField}
                                style={{ width: '100%', marginBottom: theme.spacing(10) }}
                            />
                            <Field
                                id="user_name"
                                name="user_name"
                                label="Username"
                                variant="filled"
                                component={TextField}
                                style={{ width: '100%', marginBottom: theme.spacing(10) }}
                            />
                            <Field
                                id="phone"
                                name="phone"
                                mask="(999)999-9999"
                                label="Phone Number"
                                variant="filled"
                                component={MaskedInput}
                                style={{ width: '100%', marginBottom: theme.spacing(10) }}
                            />
                            <Grid container justifyContent="center" spacing={4}>
                                <Grid item xs={12} sm={12}>
                                    <Field
                                        id="password"
                                        name="password"
                                        label="Password"
                                        variant="filled"
                                        component={TextField}
                                        style={{ width: '100%', marginBottom: theme.spacing(10) }}
                                        type={passwordVisibility ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={togglePasswordVisibility}
                                                        onMouseDown={togglePasswordVisibility}
                                                        size="large"
                                                    >
                                                        {passwordVisibility ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Field
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        label="Confirm Password"
                                        variant="filled"
                                        component={TextField}
                                        style={{ width: '100%', marginBottom: theme.spacing(10) }}
                                        type={passwordConfirmationVisibility ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            togglePasswordConfirmationVisibility
                                                        }
                                                        onMouseDown={
                                                            togglePasswordConfirmationVisibility
                                                        }
                                                        size="large"
                                                    >
                                                        {passwordConfirmationVisibility ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <ButtonWithLoader
                                size="large"
                                type="submit"
                                color="primary"
                                variant="contained"
                                sx={{ width: '100%', marginBottom: theme.spacing(10) }}
                                disabled={isSubmitting ? true : !(isValid && dirty)}
                                loading={isSubmitting}
                            >
                                {isSubmitting ? 'Creating Account' : 'Create Account'}
                            </ButtonWithLoader>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default RegisterForm;
