import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { useSelector } from 'react-redux';
import { authSelector } from '../redux/reducers/auth';
import authorizePusher from '../api/Auth/authorizePusher';

interface PusherContextProps {
    pusher: Pusher | null;
}

interface PusherProviderProps {
    children: React.ReactNode;
}

const PusherContext = React.createContext<PusherContextProps>({
    pusher: null,
});

function PusherProvider({ children }: PusherProviderProps) {
    const { isAuth } = useSelector(authSelector);
    const [pusher, setPusher] = useState<Pusher | null>(null);

    useEffect(() => {
        if (isAuth && !pusher) {
            const newInstanceOfPusher = new Pusher(process.env.REACT_APP_PUSHER_KEY ?? '', {
                cluster: process.env.REACT_APP_PUSHER_CLUSTER,
                forceTLS: true,
                authorizer: function (channel) {
                    return {
                        authorize: function (socketId, callback) {
                            authorizePusher({ socket_id: socketId, channel_name: channel.name })
                                .then((res) => {
                                    if (res.status !== 200) {
                                        throw new Error(`Received ${res.status}`);
                                    }
                                    return res.data;
                                })
                                .then((data) => callback(null, data))
                                .catch((error) => {
                                    callback(new Error(`Error calling auth endpoint: ${error}`), {
                                        auth: '',
                                    });
                                });
                        },
                    };
                },
            });
            setPusher(newInstanceOfPusher);
        } else if (isAuth && pusher && !pusher.connection?.connection) {
            pusher.connect();
        } else if (!isAuth && pusher) {
            pusher.disconnect();
        }
    }, [isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

    return <PusherContext.Provider value={{ pusher }}>{children}</PusherContext.Provider>;
}

function usePusher() {
    const context = React.useContext(PusherContext);
    if (!context) {
        throw new Error('usePusher must be used within a PusherProvider');
    }
    const { pusher } = context;
    return pusher;
}

export { PusherProvider, usePusher };
