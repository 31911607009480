import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { tz } from 'moment-timezone';
import React, { FC, useState } from 'react';

import { TimezoneKey, timezones } from '../../utils';

type TimezoneSelectProps = {
    /** dispatches the component */
    onChange: (timezone: string) => void;
    /** select classname placed at the topmost component wrapper */
    className?: string;
    /** Form label text */
    label?: string;
    /** Form ID */
    id: string;
    value: TimezoneKey;
};

const TimezoneSelect: FC<React.PropsWithChildren<TimezoneSelectProps>> = ({
    className,
    id,
    label,
    onChange,
    value,
}) => {
    const [baseTimezone, setBaseTimezone] = useState<TimezoneKey>(value || tz.guess());

    const handleChangeTimezone = (e: SelectChangeEvent<TimezoneKey>): void => {
        const timezone = e.target.value as TimezoneKey;
        setBaseTimezone(timezone);
        onChange(timezone);
    };

    return (
        <FormControl className={className}>
            {label && (
                <InputLabel shrink id="timezone-select-label">
                    {label}
                </InputLabel>
            )}
            <Select
                labelId="timezone-select-label"
                id={id}
                value={baseTimezone}
                onChange={handleChangeTimezone}
            >
                {Object.entries(timezones).map(([value, text]) => (
                    <MenuItem key={text} value={value}>
                        {text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TimezoneSelect;
