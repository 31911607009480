import { AxiosResponse } from 'axios';
import { Request } from '../../api';
import { SimplePaginateResponse } from '../../app.types';
import { UserData } from '../../utils';

interface paramInterface {
    'filter[name]': string;
    perPage?: number | undefined;
}

const adminGetAllUsers = (
    searchTerm: string,
    perPage?: number | undefined,
): Promise<AxiosResponse<SimplePaginateResponse<UserData>>> => {
    let params: paramInterface = {
        'filter[name]': searchTerm,
    };

    if (perPage) {
        params = {
            'filter[name]': searchTerm,
            perPage: perPage,
        };
    }
    return Request.get('/admin/users', {
        params,
    });
};

export { adminGetAllUsers };
