import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Submission } from 'utils';

type ReturnType = Promise<AxiosResponse<Submission>>;

const viewSubmission = (id: string): ReturnType => {
    return Request.get(`/data/submissions/${id}`);
};
export default viewSubmission;
