import {
    CommunityDashboards,
    GroupDetails,
    IndividualDetails,
    ListOfGroups,
    ListOfIndividuals,
    VideoAnalysis,
} from './scenes';
import NavigationLayout from '../../Layouts/NavigationLayout';
import { Navigation } from '../../utils';
import IndividualPerformanceMetrics from './scenes/IndividualPerformanceMetrics';
import ForceVelocityProfile from './scenes/ForceVelocityProfile';
import CommunityReports from './scenes/CommunityReports';
import GroupWorkouts from './scenes/GroupWorkouts';
import GroupGpsSummaryCreate from './scenes/GroupGpsSummaryCreate';
import GpsSummaries from './scenes/GpsSummaries';
import GroupGpsSummaryView from './scenes/GroupGpsSummaryView';
import GroupGpsUpload from './scenes/GroupGpsUpload';
import SpeedManagement from './scenes/SpeedManagement';
import IndividualsGpsSummaryReport from './scenes/IndividualsGpsSummaryReport';

export const communityRoutes = [
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/groups/:id',
        private: true,
        component: GroupDetails,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/groups/:id/logs',
        private: true,
        component: GroupWorkouts,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/groups/:id/speed-management',
        private: true,
        component: SpeedManagement,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/groups/:id/gps-summary/create',
        private: true,
        component: GroupGpsSummaryCreate,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/groups/:id/gps-summary/:summaryId',
        private: true,
        component: GroupGpsSummaryView,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/groups/:groupId/gps-summary',
        private: true,
        component: GpsSummaries,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/groups/:groupId/gps-upload',
        private: true,
        component: GroupGpsUpload,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/individuals/:individualId',
        component: IndividualDetails,
        private: true,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/individuals/:individualId/performance',
        component: IndividualPerformanceMetrics,
        private: true,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/individuals/:individualId/performance/:uploadId/sprints/:sprintId',
        component: ForceVelocityProfile,
        private: true,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/individuals/:individualId/performance/:uploadId/motions/:motionId',
        component: ForceVelocityProfile,
        private: true,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/individuals/:individualId/performance/:uploadId',
        component: ForceVelocityProfile,
        private: true,
        primaryNavigation: Navigation.COMMUNITY,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/groups',
        component: ListOfGroups,
        private: true,
        primaryNavigation: Navigation.COMMUNITY,
        secondaryNavigation: Navigation.COMMUNITY_GROUPS_TEAMS,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/individuals',
        private: true,
        component: ListOfIndividuals,
        primaryNavigation: Navigation.COMMUNITY,
        secondaryNavigation: Navigation.COMMUNITY_INDIVIDUALS,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/community/gps-summary',
        private: true,
        component: IndividualsGpsSummaryReport,
        primaryNavigation: Navigation.COMMUNITY,
        secondaryNavigation: Navigation.COMMUNITY_INDIVIDUALS,
    },
    {
        exact: false,
        layout: NavigationLayout,
        path: '/community/reports',
        private: true,
        component: CommunityReports,
        primaryNavigation: Navigation.COMMUNITY,
        secondaryNavigation: Navigation.COMMUNITY_REPORTS,
    },
    {
        exact: false,
        layout: NavigationLayout,
        path: '/community/dashboards/:dashboardId?',
        private: true,
        component: CommunityDashboards,
        primaryNavigation: Navigation.COMMUNITY,
        secondaryNavigation: Navigation.COMMUNITY_DASHBOARDS,
    },
    {
        exact: false,
        layout: NavigationLayout,
        path: '/community/video-analysis/:analysisId',
        private: true,
        component: VideoAnalysis,
        primaryNavigation: Navigation.COMMUNITY,
    },
];
