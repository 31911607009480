import { EventApi } from '@fullcalendar/common';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers-pro/';
import axios, { AxiosError } from 'axios';
import { TypographyBodyTwo, TypographyTitle, TypographyTitleh6 } from 'components/Typography';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { convertHttpErrorIntoMessage } from '../../../app.functions';
import { Can, confirmViaDialog } from '../../../components';
import { CalendarEvent, TimezoneKey, timezones, yupSchemas } from '../../../utils';
import { deleteEventFromCalendar, editEventOnCalendar } from '../../train/api/calendar';
import FlashMessage from './FlashMessage';

export interface ViewEventModelProps {
    event: EventApi;
    open: boolean;
    headerRight?: React.ReactNode;
    handleClose: () => void;
    onChanged: () => void;
}

interface FormValues {
    title: string;
    starts_at: DateTime;
    timezone: TimezoneKey;
    ends_at: DateTime | null;
    all_day: boolean;
}

function getName(event: EventApi): string {
    if (event.extendedProps.individual_id) {
        return event.extendedProps.individual_name;
    }
    if (event.extendedProps.group_id) {
        return event.extendedProps.group_name;
    }
    return 'Unknown Individual or Group Name';
}

function ViewEventModal({ event, open, headerRight, handleClose, onChanged }: ViewEventModelProps) {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const formik = useFormik<FormValues>({
        initialValues: {
            title: event.title,
            starts_at: DateTime.fromSQL(event.extendedProps.starts_at),
            timezone: event.extendedProps.starts_at_timezone,
            ends_at: event.extendedProps.ends_at
                ? DateTime.fromSQL(event.extendedProps.ends_at)
                : null,
            all_day: event.allDay,
        },
        validationSchema: yupSchemas.editEvent,
        onSubmit: (values) => {
            setLoading(true);
            editEventOnCalendar(event.id, {
                title: values.title,
                starts_at: values.starts_at.toFormat('yyyy-MM-dd HH:mm:ss'),
                ends_at: values.ends_at?.toFormat('yyyy-MM-dd HH:mm:ss') ?? null,
            })
                .then(() => {
                    onChanged();
                    handleClose();
                })
                .catch((error: Error | AxiosError) => {
                    if (axios.isAxiosError(error)) {
                        setErrorMessage(convertHttpErrorIntoMessage(error));
                    }
                })
                .finally(() => setLoading(false));
        },
    });

    useEffect(() => {
        if (formik.values.all_day) {
            formik.setFieldValue('ends_at', null);
        } else {
            formik.setFieldValue('ends_at', formik.values.starts_at.plus({ hours: 1 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.all_day]);

    const onDeleteClick = useCallback(() => {
        setLoading(true);

        deleteEventFromCalendar(event.id)
            .then(() => onChanged())
            .catch(() => {})
            .finally(() => {
                handleClose();
                setLoading(false);
            });
    }, [event, handleClose, onChanged]);

    return (
        <Dialog
            fullWidth={true}
            fullScreen={fullScreen}
            open={open && Boolean(event)}
            onClose={handleClose}
        >
            {loading && <LinearProgress />}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {event && (
                    <Box>
                        <Can I="event:update" this={event.extendedProps as CalendarEvent}>
                            {!editMode && (
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => setEditMode(true)}
                                    size="large"
                                >
                                    <EditIcon />
                                </IconButton>
                            )}
                        </Can>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                                setEditMode(false);
                                handleClose();
                            }}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}
                <Box display="flex" alignItems="center">
                    {headerRight}
                </Box>
            </Box>
            <DialogContent
                dividers
                sx={{ display: 'flex', padding: theme.spacing(8), flexDirection: 'column' }}
            >
                {!event && 'No Event'}
                {event && editMode && (
                    <form onSubmit={formik.handleSubmit}>
                        <Stack
                            spacing={8}
                            sx={{
                                padding: 8,
                                borderBottom: '1px solid grey',
                                flex: 1,
                            }}
                        >
                            <TextField
                                name="title"
                                fullWidth
                                label="Title"
                                variant="outlined"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.title)}
                                helperText={formik.errors.title}
                            />
                            {!formik.values.all_day && (
                                <Box sx={{ display: 'flex' }}>
                                    <DateTimePicker
                                        label="Starts At"
                                        value={formik.values.starts_at}
                                        minutesStep={5}
                                        onChange={(value: DateTime | null): void => {
                                            console.log(value);
                                            formik.setFieldValue('starts_at', value);
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: Boolean(formik.errors.starts_at),
                                                helperText: formik.errors.starts_at ? 'Error' : '',
                                            },
                                        }}
                                    />
                                </Box>
                            )}
                            {formik.values.all_day && (
                                <Box>
                                    <DatePicker
                                        label="Starts At"
                                        value={formik.values.starts_at}
                                        onChange={(value): void => {
                                            formik.setFieldValue('starts_at', value);
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: Boolean(formik.errors.starts_at),
                                                helperText: formik.errors.starts_at as string,
                                            },
                                        }}
                                    />
                                </Box>
                            )}
                            {!formik.values.all_day && (
                                <Box>
                                    <DateTimePicker
                                        label="Ends At"
                                        value={formik.values.ends_at}
                                        onChange={(value): void => {
                                            console.log(value);
                                            formik.setFieldValue('ends_at', value);
                                        }}
                                        minutesStep={5}
                                        minDateTime={formik.values.starts_at}
                                        slotProps={{
                                            textField: {
                                                error: Boolean(formik.errors.ends_at),
                                                helperText: formik.errors.ends_at,
                                            },
                                        }}
                                    />
                                </Box>
                            )}
                            <TypographyTitleh6>
                                {timezones[formik.values.timezone]}
                            </TypographyTitleh6>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="all_day"
                                        onChange={formik.handleChange}
                                        checked={formik.values.all_day}
                                    />
                                }
                                label="All Day"
                            />
                        </Stack>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: 8,
                                flexShrink: 1,
                            }}
                        >
                            <Box>
                                <Button
                                    sx={{ mr: 2 }}
                                    onClick={() => {
                                        confirmViaDialog({
                                            confirmation: {
                                                title: 'Are You Sure?',
                                                message:
                                                    'This will permanently remove this event from the calendar',
                                                cancelButtonText: 'Cancel',
                                                okButtonText: 'Delete',
                                                onCancelPressed: () => {},
                                            },
                                        }).then((result) => {
                                            result && onDeleteClick();
                                        });
                                    }}
                                    color={'warning'}
                                    type={'button'}
                                    variant={'outlined'}
                                >
                                    Delete
                                </Button>
                                <Button
                                    onClick={() => handleClose()}
                                    type={'button'}
                                    variant={'outlined'}
                                >
                                    Cancel
                                </Button>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {errorMessage && (
                                    <FlashMessage
                                        onFinish={() => setErrorMessage('')}
                                        duration={2500}
                                    >
                                        <p>{errorMessage}</p>
                                    </FlashMessage>
                                )}
                                <Button
                                    disabled={!formik.isValid}
                                    variant={'contained'}
                                    color={'primary'}
                                    type={'submit'}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
                {event && !editMode && (
                    <React.Fragment>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={0.5}>
                                <Item elevation={0}>
                                    <TypographyTitle>
                                        <Avatar
                                            src={event?.extendedProps.organization_photo}
                                            alt={event?.extendedProps.organization_name}
                                        />{' '}
                                    </TypographyTitle>
                                </Item>
                                <Item elevation={0}>
                                    <TypographyTitle>Scheduled {event?.title} for</TypographyTitle>
                                </Item>
                            </Grid>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={0.5}>
                                <Grid item>
                                    <Item elevation={0}>
                                        <Avatar
                                            variant="rounded"
                                            src={
                                                event?.extendedProps.managing_user_photo ||
                                                event?.extendedProps.individual_photo ||
                                                event?.extendedProps.group_photo
                                            }
                                        />
                                    </Item>
                                </Grid>
                                <Grid item>
                                    <Item elevation={0}>
                                        <TypographyTitle>{getName(event)}</TypographyTitle>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid2
                                container
                                spacing={0.5}
                                direction="row"
                                justifyContent={'center'}
                            >
                                <Item elevation={0}>
                                    <TypographyTitleh6 sx={{ marginTop: theme.spacing(4) }}>
                                        Starts at
                                    </TypographyTitleh6>
                                    <TypographyBodyTwo sx={{ marginTop: theme.spacing(4) }}>
                                        {event?.start?.toLocaleTimeString()}
                                    </TypographyBodyTwo>
                                </Item>

                                <Item elevation={0}>
                                    <TypographyTitleh6 sx={{ marginTop: theme.spacing(4) }}>
                                        Ends at
                                    </TypographyTitleh6>

                                    <TypographyBodyTwo sx={{ marginTop: theme.spacing(4) }}>
                                        {event?.end?.toLocaleTimeString()}
                                    </TypographyBodyTwo>
                                </Item>
                            </Grid2>
                        </Box>
                    </React.Fragment>
                )}
            </DialogContent>
        </Dialog>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    elevation: 0,
    color: theme.palette.text.primary,
}));

export default ViewEventModal;
