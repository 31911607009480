import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/system';

const TypographyTitleh4 = styled(Typography)<TypographyProps>(({ style }) => ({
    fontSize: 34,
    fontWeight: 600,
    ...style,
}));

export default TypographyTitleh4;
