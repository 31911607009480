import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { AttributeValue, Movement, MovementPreset, MovementType } from 'utils';

type ReturnType = Promise<AxiosResponse<Movement>>;

const createMovement = (
    name: string,
    description: string | null,
    organization: string | null,
    movementType: MovementType,
    attributes: AttributeValue[],
    movement_presets: MovementPreset | null,
    cover_asset_uuid: string | null,
    uuid?: string,
): ReturnType => {
    const payload: {
        name: string;
        description: string | null;
        movement_type: MovementType;
        attributes: AttributeValue[];
        organization?: string | null;
        uuid?: string;
        movement_presets: MovementPreset | null;
        cover_asset_uuid: string | null;
    } = {
        name,
        description,
        movement_type: movementType,
        attributes,
        uuid,
        movement_presets,
        cover_asset_uuid,
    };
    if (organization) {
        payload.organization = organization;
    }
    return Request.post(`/workouts/movements`, payload);
};

export default createMovement;
