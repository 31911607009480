import { AxiosResponse } from 'axios';
import { Request } from 'api';
import { ResponseWithPagination } from '../../../utils';
import { SocialUser } from '../social.types';

type ReturnType = Promise<AxiosResponse<ResponseWithPagination<SocialUser>>>;

const getFollowing = (): ReturnType => {
    return Request.get(`/social/following`);
};

export default getFollowing;
