import { Add } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Drawer, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useEffect, useMemo, useState } from 'react';
import { updateMember } from '../api';
import { getHierarchyTags } from '../api/tags.api';
import { Tag, TagType } from '../app.types';
import { TypographySubtitle, TypographyTitleh4 } from '../components';
import ViewAndCreateTagsDialog from '../components/Dialogs/ViewAndCreateTagsDialog';
import { getMembers } from '../modules/community/api/groups.api';
import { Group } from '../utils';

const mapTagsIntoValueOptions = (tags: Tag[], type: TagType) => {
    return [
        ...tags
            .filter((tag) => tag.type === type)
            .map((tag) => {
                return {
                    value: tag.id,
                    label: tag.name,
                };
            }),
    ];
};

function MemberSpreadsheet({
    group,
    open,
    onClose,
}: {
    group: Group;
    open: boolean;
    onClose: () => void;
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [createPositionDialogOpen, setCreatePositionDialogOpen] = useState(false);
    const [createPositionGroupDialogOpen, setCreatePositionGroupDialogOpen] = useState(false);
    const [tags, setTags] = useState<Tag[]>([]);
    const [rows, setRows] = useState<GridRowsProp>([]);
    const columns = useMemo(() => {
        const columns: GridColDef[] = [
            {
                field: 'first_name',
                headerName: 'First Name',
                width: 200,
                editable: true,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'last_name',
                headerName: 'Last Name',
                width: 200,
                editable: true,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'position',
                headerName: 'Position',
                width: 200,
                disableColumnMenu: true,
                sortable: false,
                editable: true,
                type: 'singleSelect',
                valueOptions: mapTagsIntoValueOptions(tags, 'position'),
                valueFormatter: (value?: string) => {
                    const tag = tags.find((tag: Tag) => tag.id === (value ?? ''));
                    return tag ? tag.name : '-';
                },
            },
            {
                field: 'position_group',
                headerName: 'Position Group',
                width: 200,
                disableColumnMenu: true,
                sortable: false,
                type: 'singleSelect',
                editable: true,
                valueOptions: mapTagsIntoValueOptions(tags, 'position_group'),
                valueFormatter: (value?: string) => {
                    const tag = tags.find((tag: Tag) => tag.id === (value ?? ''));
                    return tag ? tag.name : '-';
                },
            },
        ];
        return columns;
    }, [tags]);

    useEffect(() => {
        if (open && group?.organization?.uuid) {
            (async () => {
                try {
                    const response = await getHierarchyTags({
                        'filter[type]': 'position,position_group',
                        'filter[organization]': group.organization?.uuid ?? '',
                    });
                    setTags(response.data);
                } catch (e) {
                    console.log(e);
                }
            })();

            (async () => {
                try {
                    const response = await getMembers(group.uuid, { limit: 1000 });
                    setRows(
                        response.data.data?.map((member) => {
                            return {
                                uuid: member.uuid,
                                first_name: member.first_name,
                                last_name: member.last_name,
                                position: member.position?.id ?? '',
                                position_group: member.positionGroup?.id ?? '',
                            };
                        }) ?? [],
                    );
                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [open, group]);

    const saveMember = async (updatedRow: any) => {
        try {
            await updateMember(group.uuid, updatedRow.uuid, {
                position_tag_uuid: updatedRow.position,
                position_group_tag_uuid: updatedRow.position_group,
            });
            return updatedRow;
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Drawer
            PaperProps={{ style: { width: isMobile ? '100%' : '60%' } }}
            anchor={'right'}
            open={open}
            onClose={() => onClose()}
        >
            {group && group.organization && (
                <ViewAndCreateTagsDialog
                    type={'position'}
                    organization={group.organization}
                    open={createPositionDialogOpen}
                    onClose={() => setCreatePositionDialogOpen(false)}
                    title={'Create New Position'}
                    onCreated={(tag) => {
                        setTags([...tags, tag]);
                    }}
                />
            )}
            {group && group.organization && (
                <ViewAndCreateTagsDialog
                    type={'position_group'}
                    organization={group.organization}
                    open={createPositionGroupDialogOpen}
                    onClose={() => setCreatePositionGroupDialogOpen(false)}
                    title={'Create New Position Group'}
                    onCreated={(tag) => {
                        setTags([...tags, tag]);
                    }}
                    listTitle="Existing Position Groups"
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'auto',
                    overflowY: 'auto',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid',
                        borderBottomColor: grey[200],
                        padding: 8,
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TypographyTitleh4>Quickly Update Group Members</TypographyTitleh4>
                        <TypographySubtitle>
                            Double click on the cell you want to edit
                        </TypographySubtitle>

                        <Box>
                            <Button
                                href={'#'}
                                onClick={() => {
                                    setCreatePositionDialogOpen(true);
                                }}
                            >
                                <Add /> Position
                            </Button>
                            <Button
                                href={'#'}
                                onClick={() => {
                                    setCreatePositionGroupDialogOpen(true);
                                }}
                            >
                                <Add /> Position Group
                            </Button>
                        </Box>
                    </Box>
                    <IconButton onClick={() => onClose()} aria-label="close" size="medium">
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </Box>
                <DataGridPremium
                    disableRowSelectionOnClick={true}
                    processRowUpdate={saveMember}
                    onProcessRowUpdateError={() => {
                        console.log('error');
                    }}
                    editMode="row"
                    columns={columns}
                    rows={rows}
                    getRowId={(row) => row['uuid']}
                    autoHeight={true}
                />
            </Box>
        </Drawer>
    );
}

export default MemberSpreadsheet;
