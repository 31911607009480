import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
    Autocomplete,
} from '@mui/material';
import { getContacts } from 'api';
import AvatarWithInitials from 'components/Icons/AvatarWithInitials';
import { InviteList } from 'components/Lists';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/user';
import { Contact, Invite, isEmail, Role, SharedUser } from 'utils';

export interface ShareCardProps {
    userAccessList: Array<SharedUser>;
    onCopyToClipboard?: (copiedUrl: string) => void;
    onShared: (message: string, users: Array<Contact>, role: string) => void;
    onRemoveClicked: (user: SharedUser) => void;
    onError?: (message: string) => void;
    restrictsRoles?: boolean;
    roles: Array<Role>;
    invites: Array<Invite>;
    onInviteResent: (invite: Invite) => void;
    onInviteRevoked: (invite: Invite) => void;
}

const ADMIN_PATTERN = '-admin';

const ShareCard = ({
    userAccessList,
    onShared,
    onRemoveClicked,
    roles,
    restrictsRoles = false,
    onError = () => {},
    invites = [],
    onInviteRevoked = () => {},
    onInviteResent = () => {},
}: ShareCardProps) => {
    const [userList, setUserList] = useState<Array<Contact>>([]);

    const [user, setUser] = useState<Array<Contact>>([]);

    const [message, setMessage] = useState('');
    const [search, setSearch] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchTextDebounce, setSearchTextDebounce] = useState<number>(0);

    const [shareRole, setShareRole] = useState<string>(
        roles.filter((r) => !r.name.includes('admin'))[0].name,
    );

    const { userData } = useSelector(userSelector);

    const onChange = (text: string) => {
        if (searchTextDebounce) {
            clearTimeout(searchTextDebounce);
        }
        setSearchTextDebounce(window.setTimeout(() => setSearchText(text), 750));
    };

    const handleInviteRevoked = (invite: Invite) => {
        onInviteRevoked(invite);
    };
    const handleInviteResend = (invite: Invite) => {
        onInviteResent(invite);
    };

    useEffect(() => {
        if (userData && searchText) {
            getContacts(userData?.uuid || '')
                .then((response: any) => {
                    setUserList(() => response.data);
                })
                .catch(() => onError('Could not load user list. Try again.'));
        }
    }, [searchText, userData, onError]);

    const canSwitchUser = (user: SharedUser, role: Role): boolean => {
        const numberOfAdmins = userAccessList.filter((user: SharedUser) =>
            user.role.name.includes(ADMIN_PATTERN),
        ).length;

        if (
            numberOfAdmins <= 1 &&
            user.role.name.includes(ADMIN_PATTERN) &&
            !role.name.includes(ADMIN_PATTERN)
        ) {
            return false;
        }

        return true;
    };

    const canRemoveUser = (user: SharedUser): boolean => {
        const numberOfAdmins = userAccessList.filter((user: SharedUser) =>
            user.role.name.includes(ADMIN_PATTERN),
        ).length;

        if (numberOfAdmins <= 1 && user.role.name.includes(ADMIN_PATTERN)) {
            return false;
        }

        return true;
    };

    return (
        <React.Fragment>
            <Autocomplete
                // added sx prop and removed className
                sx={{ height: 38 }}
                multiple
                id="tags-standard"
                options={userList}
                getOptionLabel={(option: Contact | string) =>
                    typeof option === 'string' ? option : option?.name
                }
                isOptionEqualToValue={(option: Contact, value: Contact) =>
                    option.uuid === value.uuid
                }
                defaultValue={user}
                popupIcon={false}
                clearIcon={false}
                value={user}
                freeSolo
                onChange={(e, value) => {
                    setUser(() =>
                        value.map((contact: string | Contact) => {
                            if (typeof contact === 'string') {
                                return {
                                    uuid: contact,
                                    name: contact,
                                    type: 'email',
                                } as Contact;
                            } else {
                                return contact;
                            }
                        }),
                    );
                }}
                inputValue={search}
                onInputChange={(e, value) => {
                    onChange(value);
                    setSearch(value);
                }}
                renderTags={(value: any[], getTagProps) =>
                    value.map((option: Contact, index: number) => (
                        <Chip
                            avatar={option.type === 'user' ? <Avatar>{option.name}</Avatar> : <></>}
                            variant="outlined"
                            label={option.name}
                            {...getTagProps({ index })}
                            key={index}
                            color={
                                option.type === 'email' && !isEmail(option.name)
                                    ? 'secondary'
                                    : 'default'
                            }
                            // Modified classes prop
                            sx={{
                                outlinedSecondary: {
                                    color: '#eb3434',
                                    border: '1px solid #eb3434',
                                },
                                deleteIconOutlinedColorSecondary: { color: '#eb3434' },
                                deleteIconColorSecondary: { color: '#eb3434' },
                            }}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="filled"
                        label="Type Name Or Email Address To Add"
                    />
                )}
                renderOption={(props, option: Contact | string) => (
                    <ListItem // added sx prop and removed className
                        sx={{
                            display: 'flex',
                        }}
                        disableGutters
                        {...props}
                    >
                        <ListItemAvatar>
                            <Avatar>{typeof option === 'string' ? option : option?.name}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={typeof option === 'string' ? option : option?.name}
                            secondary={typeof option === 'string' ? '' : option?.user_name}
                        />
                    </ListItem>
                )}
            />
            {user.length > 0 && (
                // added sx prop and removed className
                <Box sx={{ padding: 8 }}>
                    <TextField
                        // added sx prop and removed className
                        sx={{ width: '100%', marginBottom: 8 }}
                        variant="filled"
                        label="Message"
                        name="message"
                        multiline
                        rows={3}
                        maxRows={8}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Box display="flex" justifyContent="space-between">
                        {roles.findIndex((r) => shareRole === r.name) > -1 ? (
                            <Select
                                value={shareRole}
                                onChange={(e) => setShareRole(e.target.value as string)}
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role.name} value={role.name}>
                                        {role.display_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <React.Fragment>
                                {roles.find((r) => r.name === shareRole)?.display_name}
                            </React.Fragment>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            disableFocusRipple
                            onClick={() => {
                                onShared(message, user, shareRole);
                                setUser([]);
                            }}
                        >
                            Send
                        </Button>
                    </Box>
                </Box>
            )}

            {user.length == 0 && (
                <>
                    {userAccessList.length > 0 && <ListSubheader>Users</ListSubheader>}
                    <List // added sx prop and removed className
                        sx={{ pt: 0, pb: 0, pr: 8, pl: 8 }}
                    >
                        {userAccessList.map((user) => {
                            let userRoleName = user.role.name;
                            return (
                                <ListItem disableGutters key={user.uuid}>
                                    <ListItemAvatar>
                                        <AvatarWithInitials
                                            alt={user.display_name}
                                            firstname={user.display_name.split(' ')[0]}
                                            lastname={user.display_name.split(' ')[1] ?? undefined}
                                            src={user.avatar_url ?? undefined}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={user.display_name}
                                        secondary={user.name}
                                    />
                                    <ListItemSecondaryAction>
                                        {!restrictsRoles ? (
                                            <Select
                                                onChange={(e) => {
                                                    e.target.value &&
                                                        onShared(
                                                            message,
                                                            [
                                                                {
                                                                    uuid: user.uuid,
                                                                    name: user.name,
                                                                    type: 'user',
                                                                } as Contact,
                                                            ],
                                                            e.target.value as string,
                                                        );
                                                }}
                                                value={userRoleName}
                                            >
                                                {roles.map((r: Role) => (
                                                    <MenuItem
                                                        disabled={!canSwitchUser(user, r)}
                                                        key={r.name}
                                                        value={r.name}
                                                    >
                                                        {r.display_name}
                                                    </MenuItem>
                                                ))}
                                                <Divider light />
                                                <MenuItem
                                                    disabled={!canRemoveUser(user)}
                                                    onClick={() => onRemoveClicked(user)}
                                                    value=""
                                                >
                                                    Remove
                                                </MenuItem>
                                            </Select>
                                        ) : (
                                            <>{user.role.display_name}</>
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                </>
            )}
            {invites.length > 0 && (
                <InviteList
                    onResend={handleInviteResend}
                    onRevoke={handleInviteRevoked}
                    invites={invites}
                />
            )}
        </React.Fragment>
    );
};

export default ShareCard;
