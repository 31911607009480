import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Form } from 'utils';

type ReturnType = Promise<AxiosResponse<Form>>;

export interface FormUpdatePayload {
    title?: string | null;
    description?: string | null;
    type?: string | null;
    media?: string | null;
}

const updateForm = (id: string, payload: FormUpdatePayload): ReturnType => {
    return Request.put(`/data/forms/${id}/bulk`, payload);
};

export default updateForm;
