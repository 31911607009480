import { AxiosResponse } from 'axios';
import { Request } from '../../api';
import { Individual, Meta, PaginationLink } from '../../utils';

interface IndividualsResponse {
    data: Individual[];
    links: PaginationLink;
    meta: Meta;
}

type ReturnType = Promise<AxiosResponse<IndividualsResponse>>;

const adminGetIndividuals = (params = {}): ReturnType => {
    return Request.get(`/admin/individuals`, {
        params,
    });
};

export { adminGetIndividuals };
