import {
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getInitials } from '../../../utils';
import ActivitySetTable from '../../../components/Tables/ActivitySetTable';
import { convertToSetsForLogDto } from '../../../app.functions';

interface FeedActivityCardProps {
    title: string;
    description: string;
    sessionTitle: string;
    sessionCoverPhoto: string;
    logs: Array<any>;
    initialExpandedState: boolean;
    onPostClicked?: () => void;
    onDeleteClicked?: () => void;
    isPublic: boolean;
    avatarName: string;
    avatar: string | null;
    showActions?: boolean;
    subheader?: React.ReactNode;
    onAvatarClicked?: () => void;
}

const FeedActivityCard = ({
    title,
    description,
    sessionTitle,
    sessionCoverPhoto,
    logs,
    initialExpandedState,
    onPostClicked = () => {},
    onDeleteClicked = () => {},
    onAvatarClicked = () => {},
    isPublic,
    avatarName,
    showActions = true,
    avatar = null,
    subheader = <></>,
}: FeedActivityCardProps) => {
    const [expanded, setExpanded] = React.useState(initialExpandedState);
    const [sets, setSets] = useState<any[]>([]);
    const theme = useTheme();

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleButtonClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        onPostClicked();
    };

    const handleDeleteClicked = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        onDeleteClicked();
    };

    const handleButtonMouseDown = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setSets(convertToSetsForLogDto(logs));
    }, [logs]);

    return (
        <Box display="flex" alignItems="start">
            {!isMobile && (
                <Avatar
                    sx={{
                        marginTop: 12,
                        marginBottom: 12,
                        marginRight: 36,
                        marginLeft: 0,
                        variant: 'rounded',
                    }}
                    variant="rounded"
                    alt={avatarName}
                    src={avatar ?? ''}
                    onClick={onAvatarClicked}
                />
            )}
            <Card
                sx={{
                    width: '100%',
                    boxShadow:
                        '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
                }}
            >
                {!expanded && (
                    <CardActionArea
                        onClick={handleExpandClick}
                        aria-expanded
                        aria-label="show more"
                    >
                        <CardHeader
                            sx={{
                                paddingTop: 1.5, // 1 unit equals 8px by default, 1.5 units is equivalent to 12px
                                paddingBottom: 1.5,
                                paddingLeft: 1.375, // this is equivalent to 11px
                                '.MuiCardHeader-action': {
                                    marginTop: 0,
                                    marginRight: 0,
                                    alignSelf: 'auto',
                                },
                            }}
                            avatar={
                                <Avatar
                                    variant="rounded"
                                    src={sessionCoverPhoto ?? ''}
                                    sx={{
                                        width: 66,
                                        height: 35,
                                        '& img': {
                                            objectFit: 'cover',
                                        },
                                    }}
                                >
                                    {getInitials(sessionTitle || '')}
                                </Avatar>
                            }
                            title={title}
                            subheader={subheader}
                            titleTypographyProps={{ variant: 'body1' }}
                            action={
                                showActions && (
                                    <>
                                        {!isPublic && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                startIcon={<AddIcon />}
                                                onClick={handleButtonClicked}
                                                onMouseDown={handleButtonMouseDown}
                                            >
                                                POST
                                            </Button>
                                        )}
                                        {isPublic && (
                                            <IconButton
                                                aria-label="delete"
                                                onClick={handleDeleteClicked}
                                                onMouseDown={handleButtonMouseDown}
                                                size="large"
                                            >
                                                <VisibilityOffIcon />
                                            </IconButton>
                                        )}
                                    </>
                                )
                            }
                        />
                    </CardActionArea>
                )}
                <Collapse in={expanded} timeout={expanded ? 'auto' : 0} unmountOnExit>
                    <CardActionArea
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <CardHeader title={sessionTitle} />
                    </CardActionArea>
                    {sessionCoverPhoto && (
                        <CardMedia
                            sx={{ height: 335 }}
                            image={sessionCoverPhoto}
                            title={sessionTitle}
                        />
                    )}
                    <CardContent>
                        <Typography variant="body2" component="p">
                            {description}
                        </Typography>

                        <ActivitySetTable sets={sets} />
                    </CardContent>
                </Collapse>
            </Card>
        </Box>
    );
};

export default FeedActivityCard;
