import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Link } from 'utils';

type ReturnType = Promise<AxiosResponse<Link>>;

const validateLink = (linkId: string): ReturnType => {
    return Request.post(`/public/links/${linkId}/validate`);
};

export default validateLink;
