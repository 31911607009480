import React from 'react';
import { Organization } from '../../utils';
import { Avatar, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
    organizations: Organization[];
    onClicked: (organization: Organization) => void;
    selectedOrganization: string | false;
    title?: string | false;
    avatarSize?: 'small' | 'medium' | 'large' | number;
    iconButtonSize?: 'small' | 'medium' | 'large' | number;
    bottomMargin?: 'small' | 'medium' | 'large' | number;
    titleSize?:
        | 'inherit'
        | 'caption'
        | 'body1'
        | 'body2'
        | 'subtitle1'
        | 'subtitle2'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6';
}

const OrganizationFilterList: React.FC<React.PropsWithChildren<Props>> = ({
    organizations,
    onClicked,
    selectedOrganization,
    title = 'Organization Filter',
    avatarSize,
    iconButtonSize,
    bottomMargin,
    titleSize,
}: Props) => {
    const theme = useTheme();

    return (
        <Grid container direction="column" justifyContent="center">
            {title && (
                <Grid item>
                    <Typography
                        variant={titleSize}
                        style={{
                            fontWeight: 600,
                            marginBottom: bottomMargin,
                            textAlign: 'center',
                        }}
                    >
                        {title}
                    </Typography>
                </Grid>
            )}
            <Grid container direction="row" justifyContent="center">
                {organizations.map((organization) => (
                    <Grid item key={organization.uuid}>
                        <IconButton
                            style={{
                                marginLeft: 8,
                                padding: 0,
                                width: iconButtonSize,
                                height: iconButtonSize,
                            }}
                            disableRipple
                            onClick={() => onClicked(organization)}
                        >
                            <Tooltip title={organization.name}>
                                <Avatar
                                    sx={{
                                        width: avatarSize,
                                        height: avatarSize,
                                        '&:hover': {
                                            boxShadow:
                                                '1px 4px 6px -1px rgba(0, 0, 0, 0.4), 1px 7px 11px rgba(0, 0, 0, 0.24), 1px 2px 28px rgba(0, 0, 0, 0.22)',
                                        },
                                        boxShadow:
                                            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                                        border:
                                            selectedOrganization === organization.uuid
                                                ? `3px solid ${theme.palette.secondary.main}`
                                                : 'none',
                                    }}
                                    src={organization.image_urls['avatar'] ?? ''}
                                    alt={organization.name}
                                    variant="rounded"
                                />
                            </Tooltip>
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default React.memo(OrganizationFilterList);
