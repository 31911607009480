import { Box, CircularProgress } from '@mui/material';
import { getActivity } from 'api';
import { LogSummary } from 'pages';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'Routes/routes';
import { Activity } from 'utils';

const ViewLog = () => {
    let { id: activityUuid } = useParams<{ id: string }>();
    const [activity, setActivity] = useState<Activity | null>(null);
    const { push } = useHistory();

    // Load the form from the database
    useEffect(() => {
        if (!activity) {
            getActivity(activityUuid)
                .then((response) => {
                    let activityLogs = response.data as Activity;

                    // Remove placeholder logs without movement
                    activityLogs.logs = activityLogs.logs.filter((log) => log.movement != null);
                    setActivity(activityLogs);
                })
                .catch(() => push(ROUTES.NotFound.path));
        }
    }, [activityUuid, activity, push]);

    return (
        <FixedLayout
            title="Log Summary"
            content={
                activity ? (
                    <LogSummary activity={activity as Activity} />
                ) : (
                    <Box pt={20} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )
            }
        />
    );
};

export default ViewLog;
