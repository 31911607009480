import { Star, StarOutline } from '@mui/icons-material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import {
    Accordion as MuiAccordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { styled } from '@mui/system';

import {
    AttributeChips,
    Can,
    ChooseOrganizationDialog,
    SearchIndividual,
    TypographySubtitle,
} from 'components';
import { AbilityContext } from 'components/Functional/Can';
import _ from 'lodash';
import React, { MouseEventHandler, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getOrganizationsForSessionCreation } from 'redux/reducers/user';
import { getInitials, Individual, Organization, Session, somethingWentWrong } from 'utils';
import { getSession } from '../../../api';
import MovementSummaryCard from '../../../components/Cards/MovementSummaryCard';
import SearchGroup from '../../../components/Search/SearchGroup';
import { getSelfIndividual } from '../../plan/api/individuals';
import { markSessionAsFavorite, markSessionAsNotFavorite } from '../api/sessions';

export interface SessionCardProps {
    item: Session;
    creatingActivity: boolean;
    creatingGroupWorkout: boolean;
    onIndividualClicked: Function;
    onGroupClicked: Function;
    onLog?: MouseEventHandler<HTMLButtonElement>;
    onSessionAssign: (args: { sessionId: string; individualId: [] }) => void;
    onSessionClicked: MouseEventHandler<HTMLButtonElement | HTMLLIElement | HTMLDivElement>;
    onSessionViewClicked: MouseEventHandler<HTMLButtonElement | HTMLLIElement | HTMLDivElement>;
    onSessionDuplicated: (
        session: Session,
        organization: Organization,
        onDuplicationCompetedCallback: () => void,
    ) => void;
    onLogSelfClicked: (onLogSelfClickedCallBack: () => void) => void;
}

const MuiAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    padding: theme.spacing(0),
    justifyContent: 'space-between',
    '& .MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(0),
        padding: theme.spacing(1),
        justifyContent: 'space-between',
        color: theme.palette.text.secondary,
    },
}));

const SessionCard = ({
    item,
    creatingActivity,
    onSessionClicked,
    onSessionViewClicked,
    onIndividualClicked,
    onSessionDuplicated,
    onLogSelfClicked,
    onGroupClicked,
    creatingGroupWorkout,
}: SessionCardProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isFavorite, setIsFavorite] = useState<boolean>(false);
    const [assigning, setAssigning] = useState<boolean>(false);
    const { push } = useHistory();
    const ability = useContext(AbilityContext);
    const theme = useTheme();
    const [showOrganizationSelectionDialog, setShowOrganizationSelectionDialog] =
        useState<boolean>(false);
    const [duplicatingSession, setDuplicatingSession] = useState<Session | null>(null);
    const [logSelfSessionLoader, setLogSelfSessionLoader] = useState<boolean>(false);
    const organizations = useSelector(getOrganizationsForSessionCreation);
    const [buttonAnchor, setButtonAnchor] = useState<null | HTMLElement>(null);
    const [buttonAnchorForLog, setButtonAnchorForLog] = useState<null | HTMLElement>(null);
    const [buttonAnchorForAssign, setButtonAnchorForAssign] = useState<null | HTMLElement>(null);
    const [loadedSession, setLoadedSession] = useState<Session>();
    const sm = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        setIsFavorite(item.is_favorite);
    }, [item.is_favorite]);

    const handleButtonClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        setButtonAnchor(event.currentTarget);
        event.stopPropagation();
    };
    const handleButtonClosed = (event: React.MouseEvent<HTMLButtonElement>) => {
        setButtonAnchor(null);
        setButtonAnchorForLog(null);
        setButtonAnchorForAssign(null);
        event.stopPropagation();
    };

    const buildQueryParameterForIndividuals = (individuals: Individual[]) => {
        let result = '?';

        individuals.forEach((i) => (result += `individuals=${i.uuid}&`));

        return result;
    };

    const handleFavorite = () => {
        // We are doing optimistic update as favorite and unfavorite actions are idempotent
        setIsFavorite((f) => !f);
        (isFavorite ? markSessionAsNotFavorite(item.uuid) : markSessionAsFavorite(item.uuid)).catch(
            (response) => {
                somethingWentWrong(response.data.message);
                // Roll back optimistic update
                setIsFavorite((f) => !f);
            },
        );
    };

    useEffect(() => {
        if (isExpanded) {
            getSession(item.uuid)
                .then((response) => {
                    // Filter out duplicate movements based on their id using Lodash
                    const uniqueMovements = _.uniqBy(response.data.movements, 'name');

                    // Sort the unique movements based on their order
                    uniqueMovements.sort(
                        (m1, m2) => (m1?.pivot?.order || 0) - (m2?.pivot?.order || 0),
                    );

                    // Update the session data with the unique movements
                    response.data.movements = uniqueMovements;
                    setLoadedSession(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching session data:', error);
                });
        }
    }, [isExpanded, item]);

    return (
        <Box
            boxShadow={3}
            sx={{
                borderRadius: '4px',
                marginBottom: theme.spacing(2),
                '& .MuiAccordionSummary-content.Mui-expanded': {
                    marginTop: 0,
                },
            }}
        >
            <MuiAccordion onChange={(e, expanded) => setIsExpanded(expanded)}>
                <MuiAccordionSummary
                    style={!isExpanded ? { alignItems: 'center' } : { alignItems: 'flex-start' }}
                >
                    <Box display="flex" sx={{ flex: '1 1 0%' }}>
                        <Avatar
                            variant="rounded"
                            src={item.cover_photo ?? ''}
                            sx={{
                                height: isExpanded ? { sm: 80, md: 160 } : 'auto',
                                minWidth: isExpanded ? 'none' : 120,
                                width: isExpanded ? { sm: 120, md: 240 } : 120,
                                marginRight: isExpanded ? (theme) => theme.spacing(4) : 'none',
                                padding: (theme) => theme.spacing(0, 0),
                                overflow: 'hidden',
                            }}
                        >
                            <PhotoCameraIcon />
                        </Avatar>
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            sx={{ flex: '1 1 0%' }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    color: theme.palette.text.primary,
                                    marginLeft: theme.spacing(4),
                                    marginRight: theme.spacing(4),
                                }}
                            >
                                {item.title}
                            </Typography>

                            {/* Session description */}
                            {isExpanded && (
                                <Box pt={0} textAlign="left">
                                    <Box
                                        sx={{ padding: theme.spacing(0, 4, 0, 0) }}
                                        dangerouslySetInnerHTML={{ __html: item.description || '' }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <Box display="flex" alignItems="center" justifyContent="flex-end" mr={4}>
                        <TypographySubtitle
                            sx={{
                                marginRight: sm ? 1 : 12,
                                fontWeight: 400,
                                fontSize: '12px',
                                textAlign: 'center',
                            }}
                        >
                            {item.movement_count === 0
                                ? 'Empty'
                                : item.movement_count === 1
                                ? '1 Movement'
                                : `${item.movement_count} Movement Sets`}
                        </TypographySubtitle>
                        <TypographySubtitle
                            sx={{
                                marginRight: sm ? 1 : 12,
                                fontWeight: 400,
                                fontSize: '12px',
                                textAlign: 'center',
                            }}
                        ></TypographySubtitle>
                        <TypographySubtitle
                            sx={{
                                marginRight: sm ? 1 : 12,
                                fontWeight: 400,
                                fontSize: '12px',
                                textAlign: 'center',
                            }}
                        >
                            {item.duration ?? 0} min
                        </TypographySubtitle>
                        {item.organization && (
                            <Avatar
                                variant="rounded"
                                src={item.organization?.logo_url || ''}
                                sx={{
                                    marginRight: 8,
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                {getInitials(item.organization.name || '')}
                            </Avatar>
                        )}
                        <IconButton
                            onClick={(event) => {
                                handleFavorite();
                                // If the current clicked event target is the icon for marking
                                // session as favorite, do not change accordion expansion state
                                event.stopPropagation();
                            }}
                            size="small"
                        >
                            {isFavorite ? (
                                <Star sx={{ color: theme.palette.secondary.main }} />
                            ) : (
                                <StarOutline />
                            )}
                        </IconButton>
                    </Box>
                </MuiAccordionSummary>
                <AccordionDetails
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: theme.spacing(0, 0, 0, 4),
                    }}
                >
                    {/* Session attributes */}
                    <AttributeChips attributes={item.attributes}></AttributeChips>

                    {/*All action buttons applicable for this session item*/}
                    <Box display="flex" justifyContent="flex-end">
                        <Can I="session:duplicate" this={item}>
                            <ChooseOrganizationDialog
                                open={showOrganizationSelectionDialog}
                                onOrganizationSelected={(selectedOrganization: Organization) => {
                                    setDuplicatingSession(item);
                                    onSessionDuplicated(item, selectedOrganization, () =>
                                        setDuplicatingSession(null),
                                    );
                                    setShowOrganizationSelectionDialog(false);
                                }}
                                onCancel={() => {
                                    setShowOrganizationSelectionDialog(false);
                                }}
                                buttonText="Duplicate"
                            />
                        </Can>

                        <Button
                            aria-label="Log"
                            aria-haspopup="true"
                            onClick={(e) => {
                                setButtonAnchorForLog(e.currentTarget);
                            }}
                        >
                            Log
                        </Button>

                        <Button
                            aria-label="Assign"
                            aria-haspopup="true"
                            onClick={(e) => {
                                setButtonAnchorForAssign(e.currentTarget);
                            }}
                        >
                            Assign
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={buttonAnchorForLog}
                            keepMounted
                            open={Boolean(buttonAnchorForLog)}
                            onClose={handleButtonClosed}
                        >
                            <Can I="session:assign-self" this={item}>
                                <MenuItem
                                    onClick={() => {
                                        setLogSelfSessionLoader(true);
                                        onLogSelfClicked(() => setLogSelfSessionLoader(false));
                                    }}
                                    color="primary"
                                    disabled={logSelfSessionLoader}
                                >
                                    {logSelfSessionLoader && (
                                        <CircularProgress size={20} color="inherit" />
                                    )}{' '}
                                    My Log
                                </MenuItem>
                            </Can>
                            <Can I="session:assign" this={item}>
                                <SearchIndividual
                                    buttonComponent={MenuItem}
                                    sx={{
                                        marginLeft: 8,
                                        justifyContent: 'flex-start',
                                        textTransform: 'none',
                                        overflowWrap: 'normal',
                                        display: 'flex',
                                        fontSize: 16,
                                        fontWeight: 'initial',
                                        color: 'inherit',
                                    }}
                                    buttonText="Athlete Log"
                                    organization={item.organization}
                                    onIndividualSelected={(individual) =>
                                        onIndividualClicked(individual, item)
                                    }
                                    showLoadingOverlay={creatingActivity}
                                    multi={false}
                                />
                            </Can>
                            <Can I="session:assign" this={item}>
                                <SearchGroup
                                    buttonComponent={MenuItem}
                                    sx={{
                                        marginLeft: 8,
                                        justifyContent: 'flex-start',
                                        textTransform: 'none',
                                        overflowWrap: 'normal',
                                        display: 'flex',
                                        fontSize: 16,
                                        fontWeight: 'initial',
                                        color: 'inherit',
                                    }}
                                    buttonText="Group Log"
                                    createButtonText={''}
                                    organization={item.organization}
                                    onGroupSelected={(group) => onGroupClicked(group, item)}
                                    showLoadingOverlay={creatingGroupWorkout}
                                />
                            </Can>
                        </Menu>
                        <Menu
                            id="simple-menu"
                            anchorEl={buttonAnchorForAssign}
                            keepMounted
                            open={Boolean(buttonAnchorForAssign)}
                            onClose={handleButtonClosed}
                        >
                            <Can I="session:assign-self" this={item}>
                                <MenuItem
                                    onClick={() => {
                                        setAssigning(true);
                                        getSelfIndividual(item.organization.uuid)
                                            .then(({ data }) => {
                                                console.log(data);
                                                push(
                                                    `/plan/sessions/${item.uuid}/assign?individuals=${data.uuid}`,
                                                );
                                                setAssigning(false);
                                            })
                                            .catch(() => {
                                                somethingWentWrong(
                                                    `Please check that you are a registered athlete for ${item.organization.name}`,
                                                );
                                                setAssigning(false);
                                            });
                                    }}
                                    color="primary"
                                    disabled={assigning}
                                >
                                    {!assigning && <>Assign To Myself</>}
                                    {assigning && <>Loading...</>}
                                </MenuItem>
                            </Can>
                            <Can I="session:assign" this={item}>
                                {buttonAnchorForAssign && (
                                    <>
                                        <SearchIndividual
                                            buttonComponent={MenuItem}
                                            sx={{
                                                marginLeft: 8,
                                                justifyContent: 'flex-start',
                                                textTransform: 'none',
                                                overflowWrap: 'normal',
                                                display: 'flex',
                                                fontSize: 16,
                                                fontWeight: 'initial',
                                                color: 'inherit',
                                            }}
                                            organization={item.organization}
                                            buttonColor="primary"
                                            buttonText="Assign To Athlete"
                                            onIndividualSelected={(individuals: Individual[]) => {
                                                push(
                                                    `/plan/sessions/${
                                                        item.uuid
                                                    }/assign${buildQueryParameterForIndividuals(
                                                        individuals,
                                                    )}`,
                                                );
                                            }}
                                            multi={true}
                                        />
                                    </>
                                )}
                            </Can>
                        </Menu>
                        {/* Only showing the 3 dots for more options if user has
                        permissions to perform any action from the more options dropdown */}
                        {(ability.can('session:duplicate', item) ||
                            ability.can('session:view', item) ||
                            ability.can('session:duplicate', item) ||
                            ability.can('session:share', item) ||
                            ability.can('session:assign', item)) && (
                            <>
                                <Button
                                    aria-label="show session options"
                                    aria-haspopup="true"
                                    onClick={(e) => handleButtonClicked(e)}
                                >
                                    Manage
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    keepMounted
                                    anchorEl={buttonAnchor}
                                    open={Boolean(buttonAnchor)}
                                    onClose={handleButtonClosed}
                                >
                                    <Can I="session:view" this={item}>
                                        <MenuItem color="primary" onClick={onSessionViewClicked}>
                                            Preview
                                        </MenuItem>
                                    </Can>
                                    <Can I="session:update" this={item}>
                                        <MenuItem color="primary" onClick={onSessionClicked}>
                                            Edit
                                        </MenuItem>
                                    </Can>
                                    <Can I="session:share" this={item}>
                                        <MenuItem color="primary" onClick={onSessionViewClicked}>
                                            Share
                                        </MenuItem>
                                    </Can>
                                    <Can I="session:duplicate" this={item}>
                                        <MenuItem
                                            onClick={() => {
                                                setDuplicatingSession(item);
                                                if (organizations?.length == 1) {
                                                    onSessionDuplicated(
                                                        item,
                                                        {
                                                            uuid: organizations?.[0]?.name || '',
                                                            name:
                                                                organizations?.[0]?.display_name ||
                                                                '',
                                                        } as Organization,
                                                        () => setDuplicatingSession(null),
                                                    );
                                                } else {
                                                    setShowOrganizationSelectionDialog(true);
                                                }
                                            }}
                                            color={
                                                duplicatingSession?.uuid === item.uuid
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                            disabled={duplicatingSession?.uuid === item.uuid}
                                        >
                                            {duplicatingSession &&
                                            duplicatingSession.uuid === item.uuid
                                                ? 'Duplicating'
                                                : 'Duplicate'}
                                        </MenuItem>
                                    </Can>
                                </Menu>
                            </>
                        )}
                    </Box>
                    {isExpanded && loadedSession && (
                        <>
                            <TypographySubtitle>Session Movements Summary</TypographySubtitle>
                            {loadedSession.movements?.length === 0 && (
                                <TypographySubtitle>
                                    No movements assigned to this session
                                </TypographySubtitle>
                            )}
                            <Grid container sm={12} md={12}>
                                {loadedSession.movements?.map((movement, index) => {
                                    const movementNumber = index + 1;
                                    return (
                                        <Grid
                                            item
                                            key={index}
                                            sx={{ alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <MovementSummaryCard
                                                item={movement}
                                                index={index}
                                                key={index}
                                                movementNumber={movementNumber}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </>
                    )}
                </AccordionDetails>
            </MuiAccordion>
        </Box>
    );
};

export default SessionCard;
