import { AxiosResponse } from 'axios';
import Request from '../../api/Request';

const createAthleteCheckoutSession = (
    return_url: string,
    price: string,
    seller_org_id: string,
    flow?: string,
): Promise<AxiosResponse<{ url: string }>> => {
    return Request.post(`/athlete-users/billing/checkout`, {
        price,
        return_url,
        seller_org_id,
        flow,
    });
};

export { createAthleteCheckoutSession };
