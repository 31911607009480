import Grid from '@mui/material/Grid';
import { Skeleton } from '@mui/material';
import React, { ReactElement } from 'react';

export default function TimelineSkeleton(): ReactElement {
    return (
        <Grid container spacing={10}>
            <Grid item xs={12}>
                <Skeleton
                    sx={{ height: 550, width: '100%', marginBottom: 8, borderRadius: 4 }}
                    animation="wave"
                    variant="rectangular"
                />
            </Grid>
        </Grid>
    );
}
