import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, CircularProgress, Fab, Paper, useMediaQuery, useTheme } from '@mui/material';
import { addSessionStack, getGroupCalendars, getIndividuals, getSession } from 'api';
import { TwoColumnLayout } from 'components';
import { CardsListSkeleton } from 'components/Skeletons';
import { pickBy } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import ROUTES from 'Routes/routes';
import { ArrayParam, useQueryParam } from 'use-query-params';
import {
    CalendarEvent,
    DateValue,
    Individual,
    mapSessionStackUpdate,
    Session,
    TimezoneKey,
} from 'utils';
import addSessionStackToGroup from '../../../api/Group/addSessionStackToGroup';
import EventListCard from '../../../components/Cards/EventListCard';
import { viewGroup } from '../../community/api/groups.api';
import { getEvents } from '../api/timeline';
import { PlanSessionCard } from '../components';
import SelectContentModal from '../components/SelectContentModal';

type SessionUpdates = {
    [ix: string]: DateValue;
};

const AssignSession: FC<React.PropsWithChildren<unknown>> = () => {
    const { push } = useHistory();
    const dispatch = useAppDispatch();
    const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('sm'));
    const { sessionId, groupId }: { sessionId: string; groupId?: string } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const theme = useTheme();
    const [name, setName] = useState<string>('');
    const [organizationId, setOrganizationId] = useState<string>('');
    const [assigneePhotoUrl] = useState<string>('');
    const [upcomingEvents, setUpcomingEvents] = useState<CalendarEvent[]>([]);
    const [sessions, setSessions] = useState<Session[]>([]);
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone as TimezoneKey;
    const [individualIds] = useQueryParam('individuals', ArrayParam);
    const [individuals, setIndividuals] = useState<Individual[]>([]);
    const [sessionUpdates, setSessionUpdates] = useState<SessionUpdates>({
        0: {
            date: null,
            time: null,
            timezone: currentTimezone,
            isWholeDay: false,
        },
    });

    const [sessionOpenModal, setSessionOpenModal] = useState<boolean>(false);

    useEffect(() => {
        if (!individualIds) {
            return;
        }
        getIndividuals({
            'filter[where_uuid]': individualIds?.join(','),
            include: 'calendars',
        })
            .then((individuals) => {
                setIndividuals(individuals.data.data);
            })
            .finally();
    }, [individualIds]);

    // loads the resources to be displayed
    useEffect(() => {
        let requests = [getSession(sessionId)] as Promise<any>[];

        if (groupId) {
            requests.push(viewGroup(groupId));
            requests.push(getEvents({ 'filter[group_source_uuid][]': groupId }));
        }

        Promise.all(requests)
            .then((responses) => {
                const [sessionResponse, eventsResponse] = responses;
                const { name, organization_uuid } = sessionResponse.data;
                setSessions([sessionResponse.data]);
                setName(name);
                setOrganizationId(organization_uuid);
                if (eventsResponse) {
                    setUpcomingEvents(eventsResponse.data);
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch(
                    pushMessage({ status: 'error', message: 'Failed to load session details' }),
                );
            })
            .finally(() => setLoading(false));
    }, [dispatch, sessionId, groupId]);

    const handleClickSubmit = useCallback(async (): Promise<void> => {
        setSubmitting(true);
        try {
            const sessionUpdateRequestBody = await mapSessionStackUpdate({
                formEntries: sessionUpdates,
                sessions: sessions,
            });
            if (!sessionUpdateRequestBody.sessions.length) {
                throw new Error(
                    'Fill out the date in any of the sessions in order to assign a program.',
                );
            }
            if (groupId) {
                let { data } = await getGroupCalendars(groupId);
                const calendarId = data[0]?.uuid;
                if (!calendarId) {
                    throw new Error('Failed to retrieve calendar information.');
                }
                await addSessionStackToGroup({
                    groupId,
                    calendarId,
                    data: sessionUpdateRequestBody,
                });
            }
            if (individualIds && individualIds.length > 0) {
                const promises = individuals.map((individual: Individual) => {
                    return addSessionStack({
                        individualId: individual.uuid,
                        calendarId:
                            individual.calendars && individual.calendars.length > 0
                                ? individual.calendars[0]
                                : '',
                        data: sessionUpdateRequestBody,
                    });
                });

                await Promise.all(promises);
            }
            dispatch(
                pushMessage({ status: 'success', message: 'Successfully assigned the session.' }),
            );
            push(ROUTES.CalendarPage.path);
        } catch (err: any) {
            let message = '';

            if (err.response.data) {
                message = err.response.data.errors[Object.keys(err.response.data.errors)[0]][0];
            } else {
                message = err.message;
            }

            dispatch(
                pushMessage({
                    status: 'error',
                    message,
                }),
            );
        } finally {
            setSubmitting(false);
        }
    }, [sessionUpdates, sessions, groupId, individuals, individualIds, dispatch, push]);

    return (
        <>
            <TwoColumnLayout
                side={
                    upcomingEvents && upcomingEvents.length > 0 ? (
                        <EventListCard
                            events={upcomingEvents.map((e) => ({
                                id: e.uuid,
                                title: e.session_name,
                                starts_at: e.starts_at_timestamp,
                                ends_at: e.ends_at_timestamp,
                            }))}
                            title={`${name}'s Calendar`}
                            assigneePhotoUrl={assigneePhotoUrl}
                        />
                    ) : (
                        <Paper elevation={6} style={{ width: '100%', padding: 16 }}>
                            <ul>
                                {individuals.map((i) => (
                                    <li key={i.uuid}>{i.name}</li>
                                ))}
                            </ul>
                        </Paper>
                    )
                }
                body={
                    <Box sx={{ width: '100%' }}>
                        {loading && <CardsListSkeleton />}
                        {sessions.map((s, index) => (
                            <PlanSessionCard
                                key={`${s.uuid}-${index}`}
                                onChange={(date) =>
                                    setSessionUpdates((state) => ({
                                        ...state,
                                        [index]: date,
                                    }))
                                }
                                sessionDate={
                                    sessionUpdates[index] ??
                                    ({
                                        date: null,
                                        time: null,
                                        timezone: null,
                                        isWholeDay: false,
                                    } as DateValue)
                                }
                                item={s}
                            />
                        ))}

                        <Box width="100%" display="flex" justifyContent="center">
                            <Button
                                onClick={() => setSessionOpenModal(true)}
                                color="primary"
                                variant="outlined"
                            >
                                Add Another Session
                                <AddIcon />
                            </Button>
                        </Box>
                        <Fab
                            disabled={submitting}
                            color="primary"
                            sx={{
                                bottom: 80,
                                left: '50%',
                                position: 'fixed',
                                transform: 'translateX(-50%)',
                                zIndex: 1102,
                                [theme.breakpoints.up('md')]: {
                                    bottom: 39,
                                },
                            }}
                            variant="extended"
                            onClick={() => handleClickSubmit()}
                        >
                            <Box mr={5}>
                                {submitting ? (
                                    <CircularProgress size={26} color="inherit" />
                                ) : (
                                    'Assign Session'
                                )}
                            </Box>
                            <ArrowForwardIcon />
                        </Fab>
                    </Box>
                }
            />

            {sessionOpenModal && (
                <SelectContentModal
                    filters={pickBy({ 'filter[organization]': organizationId }, (v) => !!v)}
                    open={sessionOpenModal}
                    onClose={() => setSessionOpenModal(false)}
                    onSelect={(sessions) => {
                        Object.keys(sessions).map(() => {
                            setSessionUpdates((sessionUpdates) => ({
                                ...sessionUpdates,
                                [Object.keys(sessionUpdates).length]: {
                                    date: null,
                                    time: null,
                                    timezone: currentTimezone,
                                    isWholeDay: false,
                                },
                            }));
                        });

                        setSessions((s) => [...s, ...Object.values(sessions)]);
                    }}
                    fullScreen={isMobileDevice}
                />
            )}
        </>
    );
};
export default AssignSession;
