import React from 'react';
import { AppBar, Box, Button, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useHistory } from 'react-router-dom';
import DbLogo from '../../images/db_icon.png';
import Lottie from 'lottie-react';
import Rocket from '../../lottie-animations/Rocket.json';

const MenuBar: React.FC<React.PropsWithChildren<unknown>> = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const handleLoginClick = () => {
        history.push('/login');
    };
    const handleFeaturesClick = () => {
        history.push('/features');
    };
    const handleAboutClick = () => {
        history.push('/about');
    };
    const handleContactUsClick = () => {
        history.push('/ContactUs');
    };
    const handleAppUserAgreementUsClick = () => {
        history.push('/AppUserAgreement');
    };
    const handlePrivacyPolicyClick = () => {
        history.push('/PrivacyPolicy');
    };
    const handleCAPrivacyPolicyClick = () => {
        history.push('/CaPrivacyPolicy');
    };
    return (
        <AppBar
            position="static"
            color="default"
            elevation={3}
            sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {/* Logo and Title */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            mt: 4,
                            mb: 2,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        variant="subtitle1"
                        component="div"
                        onClick={() => history.push('/')}
                    >
                        <img src={DbLogo} alt="1TUL Logo" width={50} />
                        1TUL
                    </Typography>
                    {!isMobile && <Typography variant="h6" color="inherit" noWrap></Typography>}
                </Box>
                {/* Navigation Links */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button color="inherit" onClick={handleFeaturesClick}>
                        Features
                    </Button>
                    <Button color="inherit" onClick={handleAboutClick}>
                        About us
                    </Button>

                    <Button color="inherit" onClick={handleContactUsClick}>
                        Contact us
                    </Button>
                    <Button color="inherit" onClick={handleCAPrivacyPolicyClick}>
                        CA Privacy Policy
                    </Button>
                    <Button color="inherit" onClick={handlePrivacyPolicyClick}>
                        Privacy Policy
                    </Button>
                    <Button color="inherit" onClick={handleAppUserAgreementUsClick}>
                        App User Agreement
                    </Button>

                    {/*<Button color="inherit">Pricing</Button>
                    <Button color="inherit">Contact</Button>*/}
                </Box>

                {/* Action Icons */}
                <Box>
                    <Tooltip title="Login" arrow>
                        <Lottie
                            animationData={Rocket}
                            loop={true}
                            autoplay={true}
                            style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                            onClick={handleLoginClick}
                        />
                    </Tooltip>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default MenuBar;
