import { Box, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/reducers/user';

const Unauthorized = () => {
    const userData = useSelector(userSelector).userData;

    useEffect(() => {
        // Capture the unauthorized event with user details
        Sentry.captureMessage('User encountered an unauthorized error', {
            user: {
                id: userData?.uuid,
                firstName: userData?.first_name,
                lastName: userData?.last_name,
                email: userData?.email,
                // Add any other relevant fields you'd like to capture
            },
        });
    }, [userData]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    fontSize: 60,
                    color: 'common.white',
                    textAlign: 'center',
                    fontWeight: 'bolder',
                }}
            >
                You don't have permissions to view this page.
            </Typography>
        </Box>
    );
};

export default Unauthorized;
