import { CubeProvider } from '@cubejs-client/react';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { cubejsApi } from '../../../app.functions';
import CommunityReportsViewAllCharts from './CommunityReportsViewAllCharts';
import CommunityReportsViewReport from './CommunityReportsViewReport';

function CommunityReports() {
    let { path } = useRouteMatch();
    return (
        <CubeProvider cubejsApi={cubejsApi}>
            <Switch>
                <Route exact path={path}>
                    <CommunityReportsViewAllCharts />
                </Route>
                <Route path={`${path}/charts/create/:savedFilterType`}>
                    <CommunityReportsViewReport />
                </Route>
                <Route path={`${path}/charts/:savedFilterId`}>
                    <CommunityReportsViewReport />
                </Route>
            </Switch>
        </CubeProvider>
    );
}

export default CommunityReports;
