import './index.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistor, store } from 'redux/store';
import { setupInterceptors } from './api/Request';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import NavigationContextProvider from 'Layouts/Navigation/NavigationContext';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: 'https://bc6ed48076394584929ba2054fe17867@o1015697.ingest.sentry.io/5981329',
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.05,
    enabled: process.env.NODE_ENV !== 'development',
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <NavigationContextProvider>
                <App />
            </NavigationContextProvider>
        </PersistGate>
    </Provider>,
);

setupInterceptors(store);
