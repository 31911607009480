import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Session } from 'utils';

type ReturnType = Promise<AxiosResponse<Session>>;

export interface AddMovementToSessionPayload {
    movement: string;
    distance_value?: number;
    distance_unit?: string;
    time_value?: number;
    time_display_format?: string;
    time_display_unit?: string;
    rest_value?: number;
    load_value?: number;
    load_unit?: string;
    reps?: number;
}

const addMovementToSession = (
    sessionId: string,
    payload: AddMovementToSessionPayload,
): ReturnType => {
    return Request.post(`/workouts/sessions/${sessionId}/movements`, payload);
};

export default addMovementToSession;
