import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Snackbar,
    TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { createSqlUser, deleteSqlUser, getAllSqlUsers } from '../../api/organizations.api';
import { SqlUser } from '../../app.types';
import { Organization } from '../../utils';

function getUserNamePrefix(orgName: string): string {
    // Remove non-alphanumeric characters and convert to uppercase
    let cleanedName = orgName.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

    // If the cleaned name is 4 characters or longer, take the first 4 characters
    if (cleanedName.length >= 4) {
        return cleanedName.substring(0, 4);
    }

    // If the cleaned name is less than 4 characters, pad with "X"
    while (cleanedName.length < 4) {
        cleanedName += 'X';
    }

    return cleanedName;
}

const SqlUsersList = ({ organization }: { organization: Organization }) => {
    const [users, setUsers] = useState<SqlUser[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [visibility, setVisibility] = useState<{ [key: number]: boolean }>({});
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState<string>('');
    const [newUser, setNewUser] = useState({ user_name: '', password: '' });

    useEffect(() => {
        getAllSqlUsers(organization.uuid)
            .then((response) => {
                setUsers(response.data);
            })
            .catch(() => {
                setSnackMessage('Error fetching users');
            })
            .finally(() => {});
    }, [organization]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddUser = useCallback(() => {
        setLoading(true);

        createSqlUser(organization.uuid, {
            user_name: `${getUserNamePrefix(organization.name)}_${newUser.user_name}`,
        })
            .then((response) => {
                setUsers((users) => [...users, response.data]);
                setNewUser({ user_name: '', password: '' });
            })
            .catch((e) => {
                if (e.response.status === 409 || e.response.status === 422) {
                    setSnackMessage(
                        e.response.data.errors[Object.keys(e.response.data.errors)[0]][0],
                    );
                } else {
                    setSnackMessage('Unexpected error while saving.');
                }
            })
            .finally(() => {
                setLoading(false);
                handleClose();
            });

        //
    }, [organization, newUser]);

    const handleVisibility = (index: number) => {
        setVisibility({ ...visibility, [index]: !visibility[index] });
    };

    const handleDelete = (index: number) => {
        deleteSqlUser(organization.uuid, users[index].uuid)
            .then(() => {
                setUsers((users) => users.filter((user) => user.uuid !== users[index].uuid));
            })
            .catch(() => {
                setSnackMessage('Error deleting user');
            });
    };

    return (
        <div>
            <List>
                {users.map((user, index) => (
                    <ListItem key={index}>
                        <ListItemText
                            primary={user.user_name}
                            secondary={visibility[index] ? user.password : '••••••••'}
                        />
                        <IconButton edge="end" onClick={() => handleVisibility(index)}>
                            {visibility[index] ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                        <CopyToClipboard
                            text={user.password}
                            onCopy={() => {
                                setSnackMessage('Copied!');
                            }}
                        >
                            <IconButton edge="end">
                                <ContentCopyIcon />
                            </IconButton>
                        </CopyToClipboard>
                        <IconButton edge="end" onClick={() => handleDelete(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
            >
                Add User
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add User</DialogTitle>
                <DialogContent>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {getUserNamePrefix(organization.name)}_
                                </InputAdornment>
                            ),
                        }}
                        autoFocus
                        margin="dense"
                        id="user_name"
                        label="User Name (only letters and numbers)"
                        type="text"
                        fullWidth
                        value={newUser.user_name}
                        onChange={(e) =>
                            setNewUser({
                                ...newUser,
                                user_name: e.target.value,
                            })
                        }
                    />
                    <Box>
                        Choose a unique user name for the user to log in with. The user name must
                        only contain letters and numbers and is automatically prefixed with{' '}
                        {getUserNamePrefix(organization.name)}_.
                    </Box>
                    <Button disabled={loading} onClick={handleAddUser} color="primary">
                        Add
                    </Button>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={!!snackMessage}
                message={snackMessage}
                autoHideDuration={3000}
                onClose={() => setSnackMessage('')}
            />
        </div>
    );
};

export default SqlUsersList;
