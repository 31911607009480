import { Box, Button, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Cancel, Check } from '@mui/icons-material';
import omit from 'lodash/omit';
import startCase from 'lodash/startCase';
import React from 'react';
import {
    AccelerationUnit,
    DistanceUnit,
    ForceUnit,
    LoadUnit,
    MovementPreset,
    PowerUnit,
    RangeOfMotionUnit,
    SetAttributeType,
    SpeedUnit,
    TimeDisplayFormat,
    TimeUnit,
} from 'utils';

interface MovementPresetsProps {
    value: MovementPreset;
    onChange: (selectedPreset: MovementPreset) => void;
}

const MovementPresets: React.FC<React.PropsWithChildren<MovementPresetsProps>> = ({
    value,
    onChange,
}) => {
    // These are the units mapping for the set attribute type(a.k.a. set goal type)
    const units: Record<string, Record<string, string>> = {};
    units[SetAttributeType.Distance] = DistanceUnit;
    units[SetAttributeType.Load] = LoadUnit;
    units[SetAttributeType.Time] = TimeDisplayFormat;
    units[SetAttributeType.Speed] = SpeedUnit;
    units[SetAttributeType.RangeOfMotion] = RangeOfMotionUnit;
    units[SetAttributeType.Force] = ForceUnit;
    units[SetAttributeType.Rest] = TimeUnit;
    units[SetAttributeType.Acceleration] = AccelerationUnit;
    units[SetAttributeType.Power] = PowerUnit;

    // Setting up default units when user sees this input
    const defaultUnits: Record<string, string> = {};
    defaultUnits[SetAttributeType.Distance] = DistanceUnit.Yard;
    defaultUnits[SetAttributeType.Load] = LoadUnit.Pound;
    defaultUnits[SetAttributeType.Time] = TimeDisplayFormat.Meet;
    defaultUnits[SetAttributeType.Speed] = SpeedUnit.MPH;
    defaultUnits[SetAttributeType.RangeOfMotion] = RangeOfMotionUnit.Degree;
    defaultUnits[SetAttributeType.Force] = ForceUnit.Newton;
    defaultUnits[SetAttributeType.Rest] = TimeUnit.Second;
    defaultUnits[SetAttributeType.Acceleration] = AccelerationUnit.FPS;
    defaultUnits[SetAttributeType.Power] = PowerUnit.Watt;

    const addAttribute = (key: SetAttributeType) => {
        onChange({
            ...value,
            [key]: defaultUnits[key] || null,
        });
    };
    const removeAttribute = (key: SetAttributeType) => {
        onChange(omit(value, key));
    };

    const changePresetUnit = (key: SetAttributeType, newUnit: string) => {
        onChange({
            ...value,
            [key]: newUnit,
        });
    };

    return (
        <Box>
            <Box mb={4}>
                <InputLabel shrink={true} color="secondary">
                    Movement Presets
                </InputLabel>
            </Box>
            <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexWrap="wrap"
                style={{ gap: 10 }}
            >
                {Object.keys(SetAttributeType).map((key, index) => {
                    const isAdded = Object.keys(value).includes(key as SetAttributeType);
                    return (
                        <Button
                            key={index}
                            size="small"
                            color={!isAdded ? 'inherit' : 'secondary'}
                            startIcon={isAdded && <Check />}
                            variant="contained"
                            endIcon={
                                isAdded && (
                                    <Cancel
                                        onClick={() => removeAttribute(key as SetAttributeType)}
                                    />
                                )
                            }
                            onClick={() => {
                                if (!isAdded) {
                                    addAttribute(key as SetAttributeType);
                                }
                            }}
                        >
                            <Typography variant="caption">{startCase(key)}</Typography>

                            {units[key as SetAttributeType] &&
                                Object.keys(units[key as SetAttributeType]).length > 0 && (
                                    <>
                                        <Typography variant="caption" sx={{ margin: '0 10px' }}>
                                            in
                                        </Typography>
                                        <Select
                                            sx={{
                                                '& .MuiSelect-root': {
                                                    padding: '0 24px 0 0',
                                                    lineHeight: 0,
                                                },
                                                '&:before': {
                                                    border: 0,
                                                },
                                            }}
                                            variant={'standard'}
                                            defaultValue={value[key] || defaultUnits[key] || null}
                                            onChange={(e) => {
                                                changePresetUnit(
                                                    key as SetAttributeType,
                                                    e.target.value as string,
                                                );
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        >
                                            {Object.keys(units[key as SetAttributeType]).map(
                                                (unitKey, unitIndex: number) => (
                                                    <MenuItem
                                                        key={unitKey}
                                                        value={
                                                            units[key as SetAttributeType][unitKey]
                                                            //display time as value
                                                        }
                                                        selected={unitIndex === 0}
                                                    >
                                                        <Typography variant="caption">
                                                            {key === SetAttributeType.Time
                                                                ? units[key as SetAttributeType][
                                                                      unitKey
                                                                  ]
                                                                : unitKey}
                                                        </Typography>
                                                    </MenuItem>
                                                ),
                                            )}
                                        </Select>
                                    </>
                                )}
                        </Button>
                    );
                })}
            </Box>
        </Box>
    );
};

export default MovementPresets;
