import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { follow as apiFollow, getFeed, unfollow as apiUnfollow } from '../../../modules/social/api';
import { RootState } from '../index';

export interface SocialFeedState {
    items: Array<any>;
    loading: boolean;
    loaded: boolean;
    query: { page: number };
    hasAnotherPage: boolean;
    error: string | null;
}

// reducers
export const initialState: SocialFeedState = {
    items: [],
    loading: true,
    loaded: false,
    error: null,
    hasAnotherPage: false,
    query: { page: 1 },
};

// actions
export const loadFeed = createAsyncThunk<
    { data: any },
    { query: { page: number } },
    { rejectValue: { message: string } }
>('social/fetchFeed', async ({ query }, { rejectWithValue }) => {
    try {
        const { data } = await getFeed(query);
        return { data };
    } catch {
        return rejectWithValue({ message: 'Failed to get feed data' });
    }
});

export const follow = createAsyncThunk<
    undefined,
    { user: string },
    { rejectValue: { message: string } }
>('social/follow', async ({ user }, { rejectWithValue }) => {
    try {
        await apiFollow(user);
    } catch {
        return rejectWithValue({ message: 'Failed to get feed data' });
    }
});

export const unfollow = createAsyncThunk<
    undefined,
    { user: string },
    { rejectValue: { message: string } }
>('social/unfollow', async ({ user }, { rejectWithValue }) => {
    try {
        await apiUnfollow(user);
    } catch {
        return rejectWithValue({ message: 'Failed to get feed data' });
    }
});

// base slice
export const feedSlice = createSlice({
    name: 'myFeed',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadFeed.pending, (state, action) => {
            state.loading = true;
            state.error = null;
            state.query = action.meta.arg.query;
        });
        addCase(loadFeed.fulfilled, (state, action) => {
            state.loading = false;
            state.loaded = true;
            if (!action.payload.data.prev_page_url) {
                state.items = action.payload.data.data;
            } else {
                state.items = [...state.items, ...action.payload.data.data];
            }
            state.error = null;
            state.hasAnotherPage = !!action.payload.data.next_page_url;
        });
        addCase(loadFeed.rejected, (state) => {
            state.loading = false;
            state.error = null;
        });
    },
});

// selectors
export const feedSelector = (state: RootState) => state.social.feed;
