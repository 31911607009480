import React from 'react';
import { InputBase, InputBaseProps } from '@mui/material';
import { isNumeric } from '../../app.functions';

interface LogInputProps {
    min?: number | undefined;
    max?: number | undefined;
    onValueChanged: (newValue: string) => void;
}

const LogInput: React.FC<React.PropsWithChildren<InputBaseProps & LogInputProps>> = ({
    onValueChanged,
    ...props
}: InputBaseProps & LogInputProps) => {
    return (
        <InputBase
            type="text"
            inputProps={{
                inputMode: 'decimal',
                pattern: '[0-9]\\d{0,9}(\\.\\d{1,3})?%?',
                fontSize: 13,
                'aria-label': 'naked',
            }}
            placeholder="0"
            {...props}
            onChange={(e) => {
                if (isNumeric(e.target.value) || !e.target.value) {
                    onValueChanged(e.target.value);
                }
            }}
            disabled={props.readOnly}
        />
    );
};

export default LogInput;
