import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

export interface EmptyCardProps {
    children: React.ReactNode;
}

export default function EmptyCard({ children }: EmptyCardProps): ReactElement {
    const theme = useTheme();

    return (
        <Box
            sx={{
                flex: '0 0 auto',
                marginRight: 12,
                border: 1,
                borderStyle: 'dashed',
                borderRadius: 4,
                color: theme.palette.text.secondary,
                width: 128,
                height: 80,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {children}
        </Box>
    );
}
