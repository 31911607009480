import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import SessionListWithSearch from '../media/SessionListWithSearch.png';
import FullScreenDialogCard from '../components/HowToDialogCard';
import EditMovement from '../media/EditMovement.png';
import MovementListWithSearch from '../media/MovementListWithSearch.png';
import AdminViewTrainDatabase from '../media/AdminViewTrainDatabase.png';
import TrainLogDetails from '../media/TrainLogDetails.png';
import LogSessionGroup from '../media/LogSessionGroup.png';

function AdminTrainData() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Training data is data that is collected through movements and sessions
                        created inside 1TUL. Many data-points can be collected on a variety of
                        movements.{' '}
                        <b>
                            Data-points include: Reps; Load in pounds or kilograms; Time in many
                            formats; Distance in yards, meters, feet, inches, and centimeters; Rest
                            in seconds; Range of Motion in degrees; Speed in MPH or KM/H; RSI; Force
                            in Newtons; Body Side R/L; RPE 1-5; Bands in light, medium, and heavy;
                            Acceleration in FPS; Power in watts, and RPM.
                        </b>
                    </Typography>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={EditMovement}
                    title="Movement Data Collection Options"
                    headline="Movement Data Collection Options"
                    description="1TUL allows you to create movements and select which relevant data you wish to collect on the movement."
                />

                <FullScreenDialogCard
                    image={MovementListWithSearch}
                    title="Movement List with Search"
                    headline="Movement List with Search"
                    description="Arange all your movements with video autoplay."
                />
                <FullScreenDialogCard
                    image={SessionListWithSearch}
                    title="Session List with Search"
                    headline="Session List with Search"
                    description="Add movements together to make a session. Add sessions to sessions to add warm ups and cool downs to sessions quickly."
                />

                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Training data can be logged by a coach for the athlete or for a group or
                            the athlete can individually log assigned sessions.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={LogSessionGroup}
                    title="Log Session Options"
                    headline="Log Session Options"
                    description="Coaches can easily log a session for an athlete, for a group, or assign a session to an athlete or group."
                />
                <FullScreenDialogCard
                    image={AdminViewTrainDatabase}
                    title="Train Database View"
                    headline="Train Database View"
                    description="View all the training data in real time as its logged by athlete or coaches. Sort the data and quickly view logs."
                />
                <FullScreenDialogCard
                    image={TrainLogDetails}
                    title="View Log Details"
                    headline="View Log Details"
                    description="View log details to see every set and rep."
                />
            </Grid>
        </>
    );
}

export default AdminTrainData;
