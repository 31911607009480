import React from 'react';
import { Media } from '../../../app.types';
import { getMp4ClipFromRenditions, getThumbnailFromRenditions } from '../../../utils';

interface Props {
    media: Media;
    mediaClassName?: string;
    style?: React.CSSProperties;
}

function ViewMediaImageOrVideo({ media, mediaClassName, style }: Props): React.ReactElement {
    return (
        <>
            {media.mime_type.startsWith('image') && (
                <img src={media.url} alt={media.uuid} className={mediaClassName} style={style} />
            )}
            {media.mime_type.startsWith('video') && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                    className={mediaClassName}
                    src={getMp4ClipFromRenditions(media.renditions)}
                    poster={getThumbnailFromRenditions(media.renditions)}
                    autoPlay={true}
                    controls={false}
                    muted={true}
                    loop={true}
                    playsInline
                >
                    <source src={media.url} type={media.mime_type} />
                </video>
            )}
        </>
    );
}

export default ViewMediaImageOrVideo;
