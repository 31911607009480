import React, { Fragment, useState } from 'react';
import {
    AvatarProps,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Popover,
} from '@mui/material';
import { logOut } from 'redux/reducers/auth';
import { markAllMessagesAsSeen, pushMessage } from 'redux/reducers/messages';
import ROUTES from 'Routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { userOriginalProfilePhotoSelector, userSelector } from 'redux/reducers/user';
import { useHistory } from 'react-router-dom';
import { userLogOut, userLogOutFromAllDevices } from 'api';
import WaitingLoaderOverlay from './WaitingLoaderOverlay';
import ProfileDropdown from './ProfileDropdown';
import { AvatarWithInitials } from 'components/Icons';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import useTheme from '@mui/material/styles/useTheme';

type Props = AvatarProps & {
    className?: string;
};

const ProfileOptions = ({ className }: Props) => {
    const { push } = useHistory();
    const dispatch = useDispatch();
    const { userData } = useSelector(userSelector);
    const profilePhoto = useSelector(userOriginalProfilePhotoSelector);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showLogOutLoader, setShowLogOutLoader] = useState<boolean>(false);
    const theme = useTheme();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const setLogOutAction = (action: string) => setLogOutDialogType(action);

    const [logOutDialogType, setLogOutDialogType] = useState<null | String>(null);

    const navigateToOrganization = (organization: any) => {
        push(ROUTES.EditOrganization.path.replace(':id', organization.uuid));
    };

    return (
        <Fragment>
            {showLogOutLoader && (
                <WaitingLoaderOverlay
                    open={showLogOutLoader}
                    waitText="Logging out..."
                    fullScreen={true}
                />
            )}
            <Dialog
                open={Boolean(logOutDialogType) && !showLogOutLoader}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {' '}
                        {logOutDialogType == 'log-out'
                            ? `You will be logged out from this device.`
                            : ``}
                        {logOutDialogType == 'log-out-all-devices'
                            ? `You will be logged out from all devices.`
                            : ``}
                        {logOutDialogType === null ? `Cancelling...` : ``}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLogOutDialogType(null)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => {
                            setShowLogOutLoader(true);
                            switch (logOutDialogType) {
                                case 'log-out':
                                    // We need to wait because sometimes the dispatch happens first from store which deletes the
                                    // token, this results in the log out request not sending bearer token
                                    await userLogOut();
                                    dispatch(logOut());
                                    // We need to mark all messages as seen so that we don't show these messages after user logs out
                                    dispatch(markAllMessagesAsSeen());
                                    setShowLogOutLoader(false);
                                    push(ROUTES.Login.path);
                                    dispatch(
                                        pushMessage({
                                            status: 'success',
                                            message: 'Logged out successfully from this device.',
                                        }),
                                    );
                                    break;
                                case 'log-out-all-devices':
                                    // We need to wait because sometimes the dispatch happens first from store which deletes the
                                    // token, this results in the log out request not sending bearer token
                                    await userLogOutFromAllDevices();
                                    dispatch(logOut());
                                    dispatch(markAllMessagesAsSeen());
                                    setShowLogOutLoader(false);
                                    push(ROUTES.Login.path);
                                    dispatch(
                                        pushMessage({
                                            status: 'success',
                                            message: 'Logged out successfully from all devices.',
                                        }),
                                    );
                                    break;
                                default:
                                    setShowLogOutLoader(false);
                                    dispatch(
                                        pushMessage({
                                            status: 'error',
                                            message: 'Something went wrong',
                                        }),
                                    );
                                    break;
                            }
                        }}
                        color="primary"
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            {userData &&
                (profilePhoto ? (
                    <AvatarWithInitials
                        alt={`${userData.first_name} ${userData.last_name}`}
                        src={profilePhoto ?? ''}
                        aria-haspopup="true"
                        lastname={userData.last_name}
                        firstname={userData.first_name}
                        onClick={handleClick}
                        sx={{
                            width: theme.spacing(18),
                            height: theme.spacing(18),
                            cursor: 'pointer',
                            backgroundColor: 'common.white',
                            color: 'primary.main',
                            '&:hover': {
                                transform: 'scale(1.25)',
                                animation: 'pulse 4s infinite',
                            },
                        }}
                        className={className}
                        variant="rounded"
                    />
                ) : (
                    <Tooltip title="Please upload you profile photo now" placement="right">
                        <Fab
                            sx={{
                                animation: 'pulse 4s infinite',
                                width: theme.spacing(18),
                                height: theme.spacing(18),
                            }}
                            color="primary"
                            aria-label="add"
                        >
                            <AvatarWithInitials
                                alt={`${userData.first_name} ${userData.last_name}`}
                                src={profilePhoto ?? ''}
                                aria-haspopup="true"
                                lastname={userData.last_name}
                                firstname={userData.first_name}
                                onClick={handleClick}
                                sx={{
                                    width: theme.spacing(18),
                                    height: theme.spacing(18),
                                    cursor: 'pointer',
                                    backgroundColor: 'common.white',
                                    color: 'primary.main',
                                }}
                                className={className}
                                variant="rounded"
                            />
                        </Fab>
                    </Tooltip>
                ))}
            <Popover
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
                sx={{
                    marginTop: 2,
                }}
            >
                <ProfileDropdown
                    onOrganizationClicked={(organization) => navigateToOrganization(organization)}
                    onLogOut={setLogOutAction}
                    hideProfileOptions={() => {
                        setAnchorEl(null);
                    }}
                />
            </Popover>
        </Fragment>
    );
};

export default ProfileOptions;
