import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCalendarDimensions } from 'api';
import { groupBy, merge } from 'lodash';

import { Dimensions } from 'utils';
import { RootState } from '..';
import { loadEvents } from './events';

export interface CalendarFilterState {
    loading: boolean;
    isLoaded: boolean;
    activeFilters: Dimensions[];
    byType: Record<string, Dimensions[]>;
    error: string | null;
}

// actions
export const loadFilters = createAsyncThunk<
    { data: Dimensions[] },
    undefined,
    { rejectValue: { message: string } }
>('calendarFilters/fetch', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getCalendarDimensions();
        return { data };
    } catch {
        return rejectWithValue({ message: 'Failed to get calendar filters' });
    }
});

// reducers
export const initialState: CalendarFilterState = {
    loading: true,
    isLoaded: false,
    activeFilters: [],
    byType: {},
    error: null,
};

export const filtersSlice = createSlice({
    name: 'calendarFilters',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadFilters.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        addCase(loadEvents.pending, (state, { meta }) => {
            state.activeFilters = meta.arg.filters ?? state.activeFilters;
        });
        addCase(loadFilters.fulfilled, (state, { payload }) => {
            const typeDisplayMap: Record<string, string> = {
                individual_uuid: 'Athletes',
                group_uuid: 'Groups or Teams',
                session_uuid: 'Sessions',
                program_uuid: 'Programs',
            };
            const mapDisplayType = payload.data.map((filterItem) => {
                // set fallback for an uncategorized filter as "Other"
                const typeDisplay = typeDisplayMap[filterItem.type] || 'Other';
                return {
                    ...filterItem,
                    type_display: typeDisplay,
                };
            });
            state.byType = merge(state.byType, groupBy(mapDisplayType, 'type'));
            state.loading = false;
            state.isLoaded = true;
            state.error = null;
        });
        addCase(loadFilters.rejected, (state, { payload }) => {
            state.loading = false;
            state.isLoaded = false;
            state.error = payload?.message ?? 'Error loading calendar filters';
        });
    },
});

// selectors
export const filtersSelector = (state: RootState) => state.calendarEvents.filters;
