import React, { Fragment, ReactElement } from 'react';
import { Organization } from 'utils';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { CircularProgress } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ArrowRight } from '@mui/icons-material';
import { SearchClearableCloseableInput, SearchClearableInput } from 'components/Inputs';
import { SearchResult } from '.';
import { useTheme } from '@mui/system';

import Box from '@mui/material/Box';

interface SearchUserWithResultsProps {
    searchResults: Array<SearchResult>;
    setInputValue: any;
    handleClose: () => void;
    handleCreateClicked: () => void;
    inputValue: string;
    closeableInput: boolean;
    onResultSelected: (result: SearchResult) => void;
    isFiltered: boolean;
    loading: boolean;
    organization?: Organization;
    buttonText?: string;
    placeholderText?: string;
    multi?: boolean;
    selectedResults?: Array<string>;
    createButtonVisible?: boolean;
}

export default function SearchWithResults({
    searchResults,
    setInputValue,
    handleClose,
    handleCreateClicked,
    inputValue,
    closeableInput,
    onResultSelected,
    loading,
    organization,
    buttonText = 'Create New Athlete',
    placeholderText = 'Find athlete',
    multi = false,
    selectedResults = [],
    createButtonVisible = true,
}: SearchUserWithResultsProps): ReactElement {
    const theme = useTheme();
    return (
        <div>
            <Grid
                container
                spacing={3}
                sx={{
                    '&.MuiGrid-spacing-xs-3': {
                        padding: 0,
                        margin: 0,
                    },
                    '&.MuiGrid-spacing-xs-3 > .MuiGrid-item': {
                        padding: 0,
                    },
                }}
            >
                <Grid item xs={12}>
                    {!closeableInput ? (
                        <SearchClearableCloseableInput
                            onChange={(value) => setInputValue(value)}
                            placeholderText={placeholderText}
                            onClose={handleClose}
                            value={inputValue}
                        />
                    ) : (
                        <SearchClearableInput
                            onChange={(value) => setInputValue(value)}
                            placeholderText={placeholderText}
                            value={inputValue}
                        />
                    )}
                </Grid>
                {buttonText && createButtonVisible && (
                    <Grid item xs={12}>
                        <Button
                            sx={{
                                width: '100%',
                                padding: theme.spacing(6, 8, 6, 8),
                                justifyContent: 'start',
                            }}
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={() => handleCreateClicked()}
                        >
                            {buttonText}
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    fontSize: 13,
                    height: 28,
                    display: 'flex',
                    fontWeight: 500,
                    margin: '0px 10px',
                    letterSpacing: 0.46,
                    alignItems: 'center',
                    textTransform: 'uppercase',
                    color: theme.palette.text.secondary,
                }}
            >
                {organization ? `Showing results from ${organization.name}` : 'Search Results'}
            </Grid>
            {!loading ? (
                // eslint-disable-next-line no-constant-condition
                <Grid item xs={12}>
                    <List>
                        {searchResults.map((result, index) => (
                            <Fragment key={index}>
                                <ListItem
                                    sx={{
                                        borderStyle:
                                            selectedResults.indexOf(result.id) > -1
                                                ? 'inset'
                                                : 'filled',
                                        backgroundColor:
                                            selectedResults.indexOf(result.id) > -1
                                                ? grey[200]
                                                : 'transparent',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: theme.palette.backgroundColor.hover,
                                        },
                                    }}
                                    onClick={() => onResultSelected(result)}
                                >
                                    <ListItemAvatar>
                                        <Avatar src={result.avatarUrl || ''} alt={result.title} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={result.title}
                                        secondary={result.subtitle ?? ''}
                                    />
                                    <ListItemSecondaryAction>
                                        {!multi && <ArrowRight />}
                                        {multi && (
                                            <>
                                                {selectedResults.indexOf(result.id) === -1 ? (
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={() => onResultSelected(result)}
                                                    >
                                                        Select
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => onResultSelected(result)}
                                                        color="primary"
                                                        size="small"
                                                    >
                                                        Selected
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </Fragment>
                        ))}
                    </List>
                </Grid>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: theme.spacing(10, 0),
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </div>
    );
}
