import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<void>>;

const revokeInviteForForm = (id: string, inviteId: string): ReturnType => {
    return Request.post(`/data/forms/${id}/invites/${inviteId}/revoke`);
};

export default revokeInviteForForm;
