import {
    addUserToMovement,
    getInvitesForMovement,
    getLinksForMovement,
    getRoles,
    getSharedUsersOnMovement,
    removeSharedUserForMovement,
    revokeInviteFromMovement,
} from 'api';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Contact, Invite, Link, Movement, Role, SharedUser } from 'utils';
import SharingDrawer from './SharingDrawer';

interface Props {
    movement: Movement;
    open: boolean;
    onClose: () => void;
    onUsersChanged?: (users: Array<SharedUser>) => void;
}

export default function MovementSharingDrawer({
    movement,
    open = false,
    onClose = () => {},
    onUsersChanged = () => {},
}: Props): ReactElement {
    const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const [sharingInProgress, setSharingInProgress] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [links, setLinks] = useState<Array<Link>>([]);
    const [invites, setInvites] = useState<Array<Invite>>([]);
    const previousMovementUuid = useRef<string>('');
    useEffect(() => {
        if (movement.uuid !== previousMovementUuid.current) {
            loadSharedUsers(movement.uuid);
            loadLinks(movement.uuid);
            loadInvites(movement.uuid);
            getRoles('movement').then((response) => {
                setRoles(response.data);
            });
        }

        previousMovementUuid.current = movement.uuid;
    }, [movement]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSharedUserRemoved = (user: SharedUser) => {
        setSharingInProgress(true);
        setSharedUsers((users) => users.filter((u) => u.uuid !== user.uuid));
        onUsersChanged(sharedUsers.filter((u) => u.uuid !== user.uuid));
        removeSharedUserForMovement(movement.uuid, user.uuid)
            .then(() => {
                setSharingInProgress(false);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const onShared = (message: string, users: Array<Contact>, role = 'movement-manager') => {
        setSharingInProgress(true);
        const usersToAdd: Array<string> = users.map((u) => u.uuid);
        addUserToMovement(movement.uuid, usersToAdd, role, message)
            .then((response) => {
                setSharedUsers(response.data);
                onUsersChanged(response.data);
                setSharingInProgress(false);
                loadInvites(movement.uuid);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const loadSharedUsers = (id: string) => {
        setSharingInProgress(true);
        getSharedUsersOnMovement(id).then((response) => {
            setSharedUsers(response.data);
            onUsersChanged(response.data);
            setSharingInProgress(false);
        });
    };

    const loadLinks = (id: string) => {
        getLinksForMovement(id).then((response) => {
            setLinks(response.data);
        });
    };

    const loadInvites = (id: string) => {
        getInvitesForMovement(id).then((response) => {
            setInvites(response.data);
        });
    };

    const handleInviteRevoked = (invite: Invite) => {
        setInvites((invites) => invites.filter((i) => i.uuid !== invite.uuid));
        revokeInviteFromMovement(movement.uuid, invite.uuid)
            .then(() => {})
            .catch(() => {});
    };

    const handleInviteResent = (invite: Invite) => {
        onShared(
            '',
            [{ uuid: invite.email, name: invite.email, relationship: 'invitee', type: 'email' }],
            invite.role.name,
        );
    };

    const handleLinkDeletedClicked = (link: Link) => {
        setLinks((links) => links.filter((l) => l.uuid !== link.uuid));
    };
    const handleLinkCreated = (link: Link) => {
        setLinks((links) => [link, ...links]);
    };
    return (
        <SharingDrawer
            title={'Share Movement'}
            onLinkDeleteClicked={handleLinkDeletedClicked}
            onLinkDeleteFailed={() => {}}
            onLinkDeleteSucceeded={() => {}}
            onLinkCreated={handleLinkCreated}
            links={links}
            roles={roles}
            invites={invites}
            onInviteRevoked={handleInviteRevoked}
            onInviteResent={handleInviteResent}
            entityType="movement"
            entityId={movement.uuid}
            processing={sharingInProgress}
            onRemove={onSharedUserRemoved}
            onShared={onShared}
            userAccessList={sharedUsers}
            onClose={onClose}
            open={open}
        />
    );
}
