import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AdminAddMembersToGroup() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL allows you to Compare athlete progress or exertion by day, week or
                        month for GPS summary data. Answer questions like &quot;How has this athlete
                        improved week by week in the last 3 weeks compared to the rest of the
                        team?&quot;
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/883318016?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Explore Athlete Progress'}
                            description={''}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminAddMembersToGroup;
