import {
    addUserToGroup,
    getInvitesForGroup,
    getLinksForGroup,
    getRoles,
    getSharedUsersOnGroup,
    removeSharedUserForGroup,
    revokeInviteFromGroup,
} from 'api';
import React, { ReactElement, useEffect, useState } from 'react';
import { Contact, Invite, Link, Role, SharedUser } from 'utils';
import SharingDrawer from '../../../components/Navigation/SharingDrawer';

interface Props {
    groupUuid: string;
    open: boolean;
    onClose: () => void;
    onUsersChanged?: (users: Array<SharedUser>) => void;
}

export default function GroupSharingDrawer({
    groupUuid,
    open = false,
    onClose = () => {},
    onUsersChanged = () => {},
}: Props): ReactElement {
    const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const [sharingInProgress, setSharingInProgress] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [links, setLinks] = useState<Array<Link>>([]);
    const [invites, setInvites] = useState<Array<Invite>>([]);
    useEffect(() => {
        loadSharedUsers(groupUuid);
        loadLinks(groupUuid);
        loadInvites(groupUuid);
        getRoles('group').then((response) => {
            setRoles(response.data);
        });
    }, [groupUuid]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSharedUserRemoved = (user: SharedUser) => {
        setSharingInProgress(true);
        setSharedUsers((users) => users.filter((u) => u.uuid !== user.uuid));
        onUsersChanged(sharedUsers.filter((u) => u.uuid !== user.uuid));
        removeSharedUserForGroup(groupUuid, user.uuid)
            .then(() => {
                setSharingInProgress(false);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const onShared = (message: string, users: Array<Contact>, role = 'group-manager') => {
        setSharingInProgress(true);
        const usersToAdd: Array<string> = users.map((u) => u.uuid);
        addUserToGroup(groupUuid, usersToAdd, role, message)
            .then((response) => {
                setSharedUsers(response.data);
                onUsersChanged(response.data);
                setSharingInProgress(false);
                loadInvites(groupUuid);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const loadSharedUsers = (id: string) => {
        setSharingInProgress(true);
        getSharedUsersOnGroup(id).then((response) => {
            setSharedUsers(response.data);
            onUsersChanged(response.data);
            setSharingInProgress(false);
        });
    };

    const loadLinks = (id: string) => {
        getLinksForGroup(id).then((response) => {
            setLinks(response.data);
        });
    };

    const loadInvites = (id: string) => {
        getInvitesForGroup(id).then((response) => {
            setInvites(response.data);
        });
    };

    const handleInviteRevoked = (invite: Invite) => {
        setInvites((invites) => invites.filter((i) => i.uuid !== invite.uuid));
        revokeInviteFromGroup(groupUuid, invite.uuid)
            .then(() => {})
            .catch(() => {});
    };

    const handleInviteResent = (invite: Invite) => {
        onShared(
            '',
            [{ uuid: invite.email, name: invite.email, relationship: 'invitee', type: 'email' }],
            invite.role.name,
        );
    };

    const handleLinkDeletedClicked = (link: Link) => {
        setLinks((links) => links.filter((l) => l.uuid !== link.uuid));
    };
    const handleLinkCreated = (link: Link) => {
        setLinks((links) => [link, ...links]);
    };
    return (
        <SharingDrawer
            title={'Group Access Rights'}
            onLinkDeleteClicked={handleLinkDeletedClicked}
            onLinkDeleteFailed={() => {}}
            onLinkDeleteSucceeded={() => {}}
            onLinkCreated={handleLinkCreated}
            links={links}
            roles={roles}
            invites={invites}
            onInviteRevoked={handleInviteRevoked}
            onInviteResent={handleInviteResent}
            entityType="group"
            entityId={groupUuid}
            processing={sharingInProgress}
            onRemove={onSharedUserRemoved}
            onShared={onShared}
            userAccessList={sharedUsers}
            onClose={onClose}
            open={open}
        />
    );
}
