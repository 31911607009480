import {
    Box,
    ButtonBase,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { LogSet, RPEOptions, BandOptions } from '../../utils';
import { VideoClipOrImage } from '../../modules/social/components';
import { grey } from '@mui/material/colors';
import VideocamIcon from '@mui/icons-material/Videocam';
import { millisecondsToDurationString } from 'app.functions';

export interface ActivitySetTableProps {
    sets: LogSet[];
    alwaysExpand?: boolean;
}

const ActivitySetTable = ({ sets, alwaysExpand = false }: ActivitySetTableProps) => {
    const setHasCol = (value: any): boolean => {
        return value !== null && value !== undefined;
    };
    const [expandedSet, setExpandedSet] = useState<number>(-1);
    const [expandedVideo, setExpandedVideo] = useState<number>(-1);
    const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('sm'));

    return (
        <>
            {sets
                .filter((set) => set.logs.filter((log) => log.markedAsComplete).length > 0)
                .map((set, index) => (
                    <Box key={index}>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            flexDirection={
                                isMobileDevice && expandedVideo === index ? 'column' : 'row'
                            }
                        >
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                mr={8}
                                width={expandedVideo === index ? 250 : 75}
                                minHeight={75}
                            >
                                {
                                    <>
                                        {set.mediaUrl && (
                                            <VideoClipOrImage
                                                mediaUrl={set.mediaUrl}
                                                mediaType={set.mediaType}
                                                onClick={() =>
                                                    index === expandedVideo
                                                        ? setExpandedVideo(-1)
                                                        : setExpandedVideo(index)
                                                }
                                            />
                                        )}
                                        {!set.mediaUrl && (
                                            <Box
                                                display={'flex'}
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                                bgcolor={grey[100]}
                                                width={75}
                                                height={60}
                                                borderRadius="4px"
                                            >
                                                <VideocamIcon></VideocamIcon>
                                            </Box>
                                        )}
                                    </>
                                }
                            </Box>
                            <ButtonBase
                                onClick={() =>
                                    index === expandedSet
                                        ? setExpandedSet(-1)
                                        : setExpandedSet(index)
                                }
                            >
                                <Typography variant="body1">
                                    {set.movement.name ?? 'Unknown Exercise'}
                                </Typography>
                            </ButtonBase>
                        </Box>
                        {(expandedSet === index || alwaysExpand) && (
                            <Box>
                                <TableContainer>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow style={{ background: '#003E9D14' }}>
                                                <TableCell>Set</TableCell>
                                                {setHasCol(set.logs[0].reps) && (
                                                    <TableCell align="right">Reps</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].loadValue) && (
                                                    <TableCell align="right">Load</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].timeValue) && (
                                                    <TableCell align="right">Time</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].distanceValue) && (
                                                    <TableCell align="right">Dist</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].speedValue) && (
                                                    <TableCell align="right">Speed</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].forceValue) && (
                                                    <TableCell align="right">Force</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].accelerationValue) && (
                                                    <TableCell align="right">
                                                        Acceleration
                                                    </TableCell>
                                                )}
                                                {setHasCol(set.logs[0].powerValue) && (
                                                    <TableCell align="right">Power</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].rangeOfMotionValue) && (
                                                    <TableCell align="right">ROM</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].bodySide) && (
                                                    <TableCell align="right">Body Side</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].rpeValue) && (
                                                    <TableCell align="right">RPE</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].bandValue) && (
                                                    <TableCell align="right">Band</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].restValue) && (
                                                    <TableCell align="right">Rest</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].rpmValue) && (
                                                    <TableCell align="right">RPM</TableCell>
                                                )}
                                                {setHasCol(set.logs[0].gpsPlayerLoadValue) && (
                                                    <TableCell align="right">
                                                        GPSPlayerLoad
                                                    </TableCell>
                                                )}
                                                {setHasCol(set.logs[0].gpsAccelCountValue) && (
                                                    <TableCell align="right">
                                                        GPSAccelCount
                                                    </TableCell>
                                                )}
                                                {setHasCol(set.logs[0].gpsDecelCountValue) && (
                                                    <TableCell align="right">
                                                        GPSDecelCount
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {set.logs
                                                .filter((log) => log.markedAsComplete)
                                                .map((log: any, index: number) => (
                                                    <TableRow
                                                        key={log.uuid}
                                                        style={{ background: 'white' }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {index + 1}
                                                        </TableCell>
                                                        {setHasCol(set.logs[0].reps) && (
                                                            <TableCell align="right">
                                                                {log.reps}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].loadValue) && (
                                                            <TableCell align="right">
                                                                {log.loadValue}
                                                                {log.loadUnit}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].timeValue) && (
                                                            <TableCell align="right">
                                                                {millisecondsToDurationString(
                                                                    log.timeValue,
                                                                    log.timeDisplayFormat,
                                                                )}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].distanceValue) && (
                                                            <TableCell align="right">
                                                                {log.distanceValue}
                                                                {log.distanceUnit}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].speedValue) && (
                                                            <TableCell align="right">
                                                                {log.speedValue}
                                                                {log.speedUnit}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].forceValue) && (
                                                            <TableCell align="right">
                                                                {log.forceValue}
                                                                {log.forceUnit}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(
                                                            set.logs[0].accelerationValue,
                                                        ) && (
                                                            <TableCell align="right">
                                                                {log.accelerationValue}
                                                                {log.accelerationUnit}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].powerValue) && (
                                                            <TableCell align="right">
                                                                {log.powerValue}
                                                                {log.powerUnit}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(
                                                            set.logs[0].rangeOfMotionValue,
                                                        ) && (
                                                            <TableCell align="right">
                                                                {log.rangeOfMotionValue}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].bodySide) && (
                                                            <TableCell align="right">
                                                                {log.bodySide}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].rpeValue) && (
                                                            <TableCell align="right">
                                                                {RPEOptions[log.rpeValue as number]}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].bandValue) && (
                                                            <TableCell align="right">
                                                                {
                                                                    BandOptions[
                                                                        log.bandValue as number
                                                                    ]
                                                                }
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].restValue) && (
                                                            <TableCell align="right">
                                                                {log.restValue}
                                                                {log.restUnit}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(set.logs[0].rpmValue) && (
                                                            <TableCell align="right">
                                                                {log.rpmValue}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(
                                                            set.logs[0].gpsPlayerLoadValue,
                                                        ) && (
                                                            <TableCell align="right">
                                                                {log.gpsPlayerLoadValue}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(
                                                            set.logs[0].gpsAccelCountValue,
                                                        ) && (
                                                            <TableCell align="right">
                                                                {log.gpsAccelCountValue}
                                                            </TableCell>
                                                        )}
                                                        {setHasCol(
                                                            set.logs[0].gpsDecelCountValue,
                                                        ) && (
                                                            <TableCell align="right">
                                                                {log.gpsDecelCountValue}
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}
                    </Box>
                ))}
        </>
    );
};
export default ActivitySetTable;
