import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

function AssignIcon(props: SvgIconProps) {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            fill="none"
            viewBox="0 0 22 20"
            {...props}
        >
            <path
                fill="#fff"
                d="M20 3.18L8.59 14.6l-4.24-4.24 1.41-1.41 2.83 2.83 10-10L20 3.18zM10 18c-4.41 0-8-3.59-8-8s3.59-8 8-8c1.57 0 3.04.46 4.28 1.25l1.45-1.45A10.02 10.02 0 0010 0C4.48 0 0 4.48 0 10s4.48 10 10 10c1.73 0 3.36-.44 4.78-1.22l-1.5-1.5c-1 .46-2.11.72-3.28.72zm7-5h-3v2h3v3h2v-3h3v-2h-3v-3h-2v3z"
            ></path>
        </SvgIcon>
    );
}

export default AssignIcon;
