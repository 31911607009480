import { Accordion, AccordionActions, AccordionDetails, Typography } from '@mui/material';
import { Group, Individual, Member, useToggle } from 'utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AvatarWithInitials } from 'components';
import { AttributeChips } from 'components/Chips';
import { Can } from 'components/Functional';
import { CustomButton } from 'components/Buttons';
import React from 'react';
import ROUTES from '../../../Routes/routes';
import { useHistory } from 'react-router-dom';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useTheme } from '@mui/system';

export interface GroupMemberRowProps {
    member: Member;
    group?: Group;
    onEditMember: () => void;
    onDeleteMember: () => void;
    onInviteClicked: (individual: Individual) => void;
    deleting: boolean;
}

const GroupMemberRow = ({
    member,
    group,
    onEditMember,
    onInviteClicked,
    onDeleteMember,
    deleting,
}: GroupMemberRowProps) => {
    const [expanded, toggleExpanded] = useToggle(false);
    // Inside the GroupMemberRow component:
    const history = useHistory();
    const theme = useTheme();
    const handleIndividualDetailsClick = () => {
        const route = ROUTES.IndividualDetails.path.replace(
            ':individualId',
            member.individual.uuid,
        );
        history.push(route);
    };

    return (
        <Accordion expanded={expanded}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: theme.spacing(8),
                    color: theme.palette.text.secondary,
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: theme.spacing(0),
                        color: theme.palette.text.secondary,
                        width: '100%',
                    }}
                >
                    <div
                        style={{ padding: theme.spacing(8), display: 'flex', alignItems: 'center' }}
                    >
                        <AvatarWithInitials
                            sx={{ width: '60px', height: '60px', cursor: 'pointer' }}
                            firstname={member.first_name ?? ''}
                            lastname={member.last_name ?? ''}
                            src={member.image_urls?.avatar ?? ''}
                            onClick={toggleExpanded}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            flexDirection: 'column',
                            padding: theme.spacing(8, 10, 8, 0),
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: '20px',
                                lineHeight: '24px',
                                color: theme.palette.text.primary,
                                wordBreak: 'break-word',
                                maxWidth: '95%',
                                cursor: 'pointer',
                            }}
                            onClick={toggleExpanded}
                        >{`${member.first_name ?? ''} ${member.last_name ?? ''}`}</Typography>

                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: theme.palette.text.secondary,
                                wordBreak: 'break-word',
                                maxWidth: '95%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {member.memberType.name}{' '}
                            {!!member?.individual?.managing_user_uuid && (
                                <>
                                    {'  |  '}
                                    <VerifiedUserIcon sx={{ color: '#1B9CEA' }} />
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: 400,
                                            color: '#A7ABB7',
                                            marginLeft: 1,
                                        }}
                                    >
                                        {member?.individual?.managing_user?.user_name}
                                    </Typography>
                                </>
                            )}
                        </Typography>
                    </div>
                </div>
                <ExpandMoreIcon
                    onClick={toggleExpanded}
                    sx={(theme) => ({
                        transition: 'all 0.5s',
                        borderRadius: '100%',
                        fill: theme.palette.text.secondary,
                        '&:hover': {
                            cursor: 'pointer',
                            background: '#80808017',
                            transform: 'scale(1.04)',
                        },
                        transform: `${expanded ? 'rotateX(180deg)' : 'none'} !important`,
                    })}
                />
            </div>
            <AccordionDetails sx={{ padding: theme.spacing(8) }}>
                <AttributeChips attributes={member?.attributes ?? []} />
            </AccordionDetails>
            <AccordionActions>
                {group && (
                    <React.Fragment>
                        <Can I="group:update-members" this={group}>
                            <CustomButton
                                disabled={deleting}
                                onClick={onDeleteMember}
                                variant="text"
                                color="primary"
                            >
                                Remove Member
                            </CustomButton>
                        </Can>
                        {!member?.individual?.managing_user_uuid && (
                            <Can I="individual:update" this={member.individual}>
                                <CustomButton onClick={() => onInviteClicked(member.individual)}>
                                    Invite
                                </CustomButton>
                            </Can>
                        )}
                        <Can I="group:update-members" this={group}>
                            <CustomButton onClick={onEditMember} variant="text" color="primary">
                                Member Details
                            </CustomButton>
                        </Can>
                        <Can I="group:update-members" this={group}>
                            <CustomButton
                                onClick={handleIndividualDetailsClick}
                                variant="text"
                                color="primary"
                            >
                                Individual Details
                            </CustomButton>
                        </Can>
                    </React.Fragment>
                )}
            </AccordionActions>
        </Accordion>
    );
};

export default GroupMemberRow;
