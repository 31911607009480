import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Chip } from '@mui/material';
import {
    BaseSingleInputFieldProps,
    DateValidationError,
    FieldSection,
    UseDateFieldProps,
} from '@mui/x-date-pickers-pro';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers-pro/';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

interface Props {
    onChange: (newDate: DateTime | null) => void;
    value: DateTime | null;
    style?: React.CSSProperties; // Add this line
    chipColor?: 'default' | 'primary' | 'secondary'; // Add this line
    type?: 'date' | 'datetime';
}

interface ChipFieldProps
    extends UseDateFieldProps<DateTime, false>,
        BaseSingleInputFieldProps<
            DateTime | null,
            DateTime,
            FieldSection,
            false,
            DateValidationError
        > {
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    chipColor: 'default' | 'primary' | 'secondary'; // Add this line
    style?: React.CSSProperties; // Add this line
    inputRef?: React.Ref<any>;
    setPickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    chipDateLabel: (date: DateTime | null) => string;
    value: DateTime | null;
}

function ChipField(props: ChipFieldProps) {
    const {
        setPickerOpen,
        chipColor,
        chipDateLabel,
        style,
        value,
        InputProps: { ref } = {},
    } = props;

    return (
        <Chip
            ref={ref}
            sx={{
                paddingLeft: 0.5, // 1 unit equals 8px by default in Material UI theme
                paddingRight: 0.5, // 1 unit equals 8px by default in Material UI theme
                '.MuiChip-label': {
                    lineHeight: '2.25rem',
                },
            }}
            avatar={<CalendarTodayIcon />}
            label={chipDateLabel(value)}
            clickable
            color={chipColor} // Use the prop here
            onClick={() => setPickerOpen(true)}
            style={style}
        />
    );
}

function isToday(someDate: DateTime): boolean {
    return someDate.hasSame(DateTime.local(), 'day');
}

function chipDateLabel(date: DateTime | null): string {
    if (date === null) {
        return 'No Date';
    }
    if (isToday(date)) {
        return 'Today';
    } else {
        return date.toLocaleString();
    }
}

export default function SelectDateChip({
    onChange,
    value,
    style,
    chipColor = 'primary',
    type = 'date',
}: Props) {
    const [pickerOpen, setPickerOpen] = useState(false);

    const PickerComponent = type === 'date' ? DatePicker : DateTimePicker;

    return (
        <>
            <PickerComponent
                value={value}
                onChange={onChange}
                open={pickerOpen}
                onClose={() => setPickerOpen(false)}
                slots={{
                    field: ChipField,
                }}
                slotProps={{
                    field: {
                        chipColor,
                        chipDateLabel,
                        style,
                        setPickerOpen,
                        value,
                    } as any,
                }}
            />
        </>
    );
}
