import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const KpiProgressBar = ({ value, max }: { value: number; max: number }) => {
    const progress = Math.min(100, Math.round((value / max) * 100));

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={50}>
                <Typography variant="body2" color="textSecondary">{`${value} / ${max}`}</Typography>
            </Box>
        </Box>
    );
};

export default KpiProgressBar;
