import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';

export interface Confirmation {
    title: string;
    message: string;
    cancelButtonText?: string | undefined;
    okButtonText?: string | undefined;
    onCancelPressed?: () => void;
}

export interface ConfirmationDialogProps {
    show: boolean;
    proceed: (status: boolean) => void;
    confirmation: Confirmation;
}

const ConfirmationDialog = ({ show, proceed, confirmation }: ConfirmationDialogProps) => (
    <Dialog
        open={show}
        keepMounted
        onClose={() => proceed(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
    >
        <DialogTitle id="alert-dialog-slide-title">{confirmation.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                {confirmation.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
                onClick={() => {
                    confirmation.onCancelPressed && confirmation.onCancelPressed();
                    proceed(false);
                }}
                color="primary"
            >
                {confirmation.cancelButtonText || 'Cancel'}
            </Button>
            <Button onClick={() => proceed(true)} color="primary">
                {confirmation.okButtonText || 'Ok'}
            </Button>
        </DialogActions>
    </Dialog>
);

export const confirmViaDialog = createConfirmation(confirmable(ConfirmationDialog));
