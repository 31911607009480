import {
    Alert,
    Box,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import {
    createOrganization,
    getSignedUrl,
    uploadFile,
    userRegister,
    viewOrganization,
    viewOrganizationByPartnerId,
} from 'api';
import { AxiosError } from 'axios';
import { CustomStepper, MaskedInput, RadioGroupInput, UploadLogo } from 'components';
import { Field } from 'formik';
import { Switch as FormikMuiSwitch, TextField as FormikMuiTextField } from 'formik-mui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Organization, PhysicalProductForPurchase, yupSchemas } from 'utils';
import userUsernameCheck from '../../../api/Auth/userUsernameCheck';
import goldMedal from '../../../images/gold-medal (1).png';
import { authSelector, loginUser, registerUser } from '../../../redux/reducers/auth';
import { getUserData } from '../../../redux/reducers/user';
import { useAppDispatch } from '../../../redux/store';
import * as yup from 'yup';
import { Switch as MuiSwitch } from '@mui/material';
import { useFormikContext } from 'formik';
import Pod from '../../../images/pod.png';
import Vest from '../../../images/vestTransparent.png';
import Charger from '../../../images/charger1.png';
import Fiyrlink from '../../../images/fiyrlink.png';
import TripleCharger from '../../../images/charger3x.png';
import orgCombo from '../../../images/orgCombo.png';
import { getPhysicalProducts } from '../../../api/Auth/getPhysicalProducts';
import createCheckoutOrgIntroComboSubscription from '../../../api/Auth/createCheckoutOrgIntroComboSubscription';
import HoverImage from '../../../components/Images/HoverImage';
import getCurrentUser from '../../../api/Auth/getCurrentUser';
import QuantityControl from './QuantityControl';

interface OrganizationValues {
    name: string;
    zip: string;
    organizationLogo: string;
    organizationType: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    user_name: string;
    password: string;
    password_confirmation: string;
    selected_price: string;
    owner: boolean;
}

interface UserRegistrationValues {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    password_confirmation: string;
    phone: string;
    user_name: string;
    name: string;
    zip: string;
    organizationType: string;
    organizationLogo?: string; // optional
}

interface UserLoginValues {
    userName: string;
    password: string;
}

interface UrlCallbackResponse {
    data: {
        url: string;
    };
}

const CreateOrganization = () => {
    const { push } = useHistory();
    const { id } = useParams<{ id: string }>();
    const { parent } = useParams<{ parent: string }>();

    const options = [
        { label: 'Professional', value: 'professional' },
        { label: 'College', value: 'college' },
        { label: 'High School', value: 'high-school' },
        { label: 'Travel/Club', value: 'travel' },
        { label: 'Athletic Training/Therapy', value: 'athletic-training' },
        { label: 'Private Instruction', value: 'private' },
        { label: 'Club', value: 'club' },
        { label: 'League', value: 'league' },
    ];

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState<Set<number>>(new Set());
    const [uploadLogo, setUploadLogo] = useState<File>();
    const [selectedOrg, setSelectedOrg] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [invitingOrganization, setInvitingOrganization] = useState<Organization>();
    const [parentOrganization, setParentOrganization] = useState<Organization>();
    const { isAuth } = useSelector(authSelector);
    const dispatch = useAppDispatch();
    const [hasAccount, setHasAccount] = useState(false);
    const [vestsForPurchase, setVestsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [selectedVestCountsForPods, setSelectedVestCountsForPods] = useState<
        Record<string, number>
    >({});
    const [podsForPurchase, setPodsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [selectedPodCounts, setSelectedPodCounts] = useState<Record<string, number>>({});
    const [loggedInUser, setLoggedInUser] = useState<UserDataInterface | null>(null);
    const [singleChargersForPurchase, setSingleChargersForPurchase] = useState<
        PhysicalProductForPurchase[]
    >([]);
    const [tripleChargersForPurchase, setTripleChargersForPurchase] = useState<
        PhysicalProductForPurchase[]
    >([]);
    const [fiyrlinkGatewaysForPurchase, setFiyrlinkGatewaysForPurchase] = useState<
        PhysicalProductForPurchase[]
    >([]);
    const [selectedSingleChargerCountsForPods, setSelectedSingleChargerCountsForPods] = useState<
        Record<string, number>
    >({});
    const [selectedTripleChargerCountsForPods, setSelectedTripleChargerCountsForPods] = useState<
        Record<string, number>
    >({});
    const [selectedFiyrlinkGatewayCountsForPods, setSelectedFiyrlinkGatewayCountsForPods] =
        useState<Record<string, number>>({});

    const SHIPPING_FEE = 15;

    const handleVestForPodCountChange = (priceId: string, count: number) => {
        setSelectedVestCountsForPods((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const handlePodCountChange = (uuid: string, count: number) => {
        setSelectedPodCounts((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[uuid];
            } else {
                newCounts[uuid] = count;
            }
            return newCounts;
        });
    };

    const handleOrgComboPurchase = useCallback(
        async (callbackUrl: string, orgUuid: string): Promise<UrlCallbackResponse | null> => {
            try {
                const response = await createCheckoutOrgIntroComboSubscription(
                    callbackUrl,
                    orgUuid,
                    id,
                    selectedVestCountsForPods,
                    selectedPodCounts,
                    selectedSingleChargerCountsForPods,
                    selectedTripleChargerCountsForPods,
                    selectedFiyrlinkGatewayCountsForPods,
                );

                if (response.status >= 400) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                return response;
            } catch (e: any) {
                setError(`Error creating checkout session. ${e.response?.data?.message}`);
                return null;
            }
        },
        [
            id,
            selectedPodCounts,
            selectedVestCountsForPods,
            selectedSingleChargerCountsForPods,
            selectedTripleChargerCountsForPods,
            selectedFiyrlinkGatewayCountsForPods,
        ],
    );

    const isFinishButtonDisabled = () => {
        return (
            Object.values(selectedPodCounts).reduce((sum, count) => sum + count, 0) >
            Object.values(selectedVestCountsForPods).reduce((sum, count) => sum + count, 0)
        );
    };

    const calculateSubtotal = () => {
        let goldSubscriptionPrice = 100;
        let totalPodQuantity = Object.values(selectedPodCounts).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );
        const totalVestQuantity = Object.values(selectedVestCountsForPods).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );
        const totalSingleChargerQuantity = Object.values(selectedSingleChargerCountsForPods).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );
        const totalTripleChargerQuantity = Object.values(selectedTripleChargerCountsForPods).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );
        const totalFiyrlinkGatewayQuantity = Object.values(
            selectedFiyrlinkGatewayCountsForPods,
        ).reduce((sum, quantity) => sum + quantity, 0);

        return (
            goldSubscriptionPrice +
            (totalVestQuantity + totalSingleChargerQuantity + totalTripleChargerQuantity) * 40 +
            (totalPodQuantity + totalFiyrlinkGatewayQuantity) * 60
        );
    };

    const calculateVestDiscount = () => {
        let totalPodQuantity = Object.values(selectedPodCounts).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );
        const totalVestQuantity = Object.values(selectedVestCountsForPods).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );

        let totalDiscountedItems =
            totalPodQuantity >= totalVestQuantity ? totalVestQuantity : totalPodQuantity;

        return totalDiscountedItems * 40;
    };

    const calculateFiyrlinkDiscount = () => {
        const totalPodQuantity = Object.values(selectedPodCounts).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );
        const totalFiyrlinkGatewayQuantity = Object.values(
            selectedFiyrlinkGatewayCountsForPods,
        ).reduce((sum, quantity) => sum + quantity, 0);

        return totalPodQuantity >= 5 && totalFiyrlinkGatewayQuantity >= 1 ? 60 : 0;
    };

    const fiyrlinkDiscount = calculateFiyrlinkDiscount();

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    const organizationResponse = await viewOrganization(id);
                    setInvitingOrganization(organizationResponse.data);
                    if (parent) {
                        const parentOrganizationResponse = await viewOrganizationByPartnerId(
                            parent,
                        );
                        setParentOrganization(parentOrganizationResponse.data);
                    }
                } catch (e) {
                    console.log(e);
                }
            })();

            /**
             * Get pods, vests, chargers, and Fiyrlinks for purchase.
             */
            getPhysicalProducts('FiyrPod Vest')
                .then((response) => {
                    setVestsForPurchase(response.data.data);
                })
                .catch((e: any) => {
                    setError(`Error retrieving FiyrPod vests. ${e.response?.data?.message}`);
                });

            getPhysicalProducts('Fiyrpod')
                .then((response) => {
                    setPodsForPurchase(response.data.data);
                    const defaultPodCounts = response.data.data.reduce(
                        (
                            acc: Record<string, number>,
                            pod: PhysicalProductForPurchase,
                            index: number,
                        ) => {
                            acc[pod.uuid] = index === 0 ? 5 : 0;
                            return acc;
                        },
                        {},
                    );
                    setSelectedPodCounts(defaultPodCounts);
                })
                .catch((e: any) => {
                    setError(`Error retrieving FiyrPods. ${e.response?.data?.message}`);
                });

            getPhysicalProducts('Fiyrpod Single Charger')
                .then((response) => {
                    setSingleChargersForPurchase(response.data.data);
                })
                .catch((e: any) => {
                    setError(
                        `Error retrieving FiyrPod single chargers. ${e.response?.data?.message}`,
                    );
                });

            getPhysicalProducts('Fiyrpod Triple Charger')
                .then((response) => {
                    setTripleChargersForPurchase(response.data.data);
                })
                .catch((e: any) => {
                    setError(
                        `Error retrieving FiyrPod triple chargers. ${e.response?.data?.message}`,
                    );
                });

            getPhysicalProducts('Fiyrlink')
                .then((response) => {
                    setFiyrlinkGatewaysForPurchase(response.data.data);
                    const defaultFiyrlinkCounts = response.data.data.reduce(
                        (
                            acc: Record<string, number>,
                            fiyrlink: PhysicalProductForPurchase,
                            index: number,
                        ) => {
                            acc[fiyrlink.stripe_price_id] = index === 0 ? 1 : 0;
                            return acc;
                        },
                        {},
                    );
                    setSelectedFiyrlinkGatewayCountsForPods(defaultFiyrlinkCounts);
                })
                .catch((e: any) => {
                    setError(
                        `Error retrieving Fiyrlink Laptop Gateways. ${e.response?.data?.message}`,
                    );
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleSingleChargerForPodCountChange = (priceId: string, count: number) => {
        setSelectedSingleChargerCountsForPods((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const handleTripleChargerForPodCountChange = (priceId: string, count: number) => {
        setSelectedTripleChargerCountsForPods((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const handleFiyrlinkGatewayForPodCountChange = (priceId: string, count: number) => {
        setSelectedFiyrlinkGatewayCountsForPods((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const uploadLogoOnS3 = async (logo: File) => {
        try {
            const response = await getSignedUrl();
            if (response.status === 201) {
                const res = await uploadFile(response.data.url, logo);
                if (res.status === 200) {
                    return response.data.key;
                }
            }
            return false;
        } catch (err: any) {
            const error: AxiosError<{ message: string }> = err;
            setError(error.message);
            return false;
        }
    };

    interface UserDataInterface {
        uuid: string;
    }

    const handleLogin = async (values: UserLoginValues) => {
        const result = await dispatch(
            loginUser({ username: values.userName, password: values.password }),
        );

        if (loginUser.fulfilled.match(result)) {
            console.log('login result', result);
            const userData = await getCurrentUser();
            console.log('user data', userData.data);
            if (userData && userData.data) {
                setLoggedInUser(userData.data);
                return true;
            }

            return false;
        } else {
            if (result.payload && 'errors' in result.payload) {
                setError(result.payload.message || '');
                return false;
            }
        }
    };

    const checkUsername = async (values: UserRegistrationValues) => {
        try {
            const result = await userUsernameCheck({
                user_name: values.user_name,
                phone: values.phone,
                email: values.email,
            });

            if (result.data.unique) {
                return true;
            } else {
                setError('Username already exists in 1Tul. Try another username.');
                return false;
            }
        } catch (error: any) {
            setError(error.response.data.message || 'An error occurred. Please try again.');
            return false;
        }
    };

    const handleUserRegistration = useCallback(
        async (isAuth: boolean, values: UserRegistrationValues) => {
            if (!isAuth) {
                const result = await dispatch(registerUser(values));

                if (!registerUser.fulfilled.match(result)) {
                    if (result.payload && 'errors' in result.payload) {
                        setError(result.payload.message || '');
                        throw new Error('Registration failed');
                    }
                } else {
                    await dispatch(getUserData());
                    return result.payload.user;
                }
            } else {
                const userResult = await userRegister(
                    values.email,
                    values.firstName,
                    values.lastName,
                    values.password,
                    values.password_confirmation,
                    values.phone,
                    values.user_name,
                    true,
                );

                return userResult.data.user;
            }
        },
        [dispatch],
    );

    const handleSubmit = useCallback(
        async (values: OrganizationValues) => {
            setLoading(true);
            try {
                let userResponse = null;

                if (!hasAccount) {
                    userResponse = await handleUserRegistration(isAuth, values);
                }

                if (uploadLogo) {
                    const key = await uploadLogoOnS3(uploadLogo);
                    if (key) {
                        values.organizationLogo = key;
                    }
                }

                const orgResponse = await createOrganization(
                    values.name,
                    values.zip,
                    values.organizationType,
                    values.organizationLogo ?? undefined,
                    invitingOrganization?.uuid,
                    userResponse ? userResponse.uuid : loggedInUser?.uuid,
                    values.owner,
                    parentOrganization?.uuid,
                );

                const checkoutSession = await handleOrgComboPurchase(
                    `${process.env.REACT_APP_URL}/networking/${orgResponse.data.uuid}`,
                    orgResponse.data.uuid,
                );

                if (checkoutSession?.data.url) {
                    window.location.href = checkoutSession.data.url;
                    return false;
                }

                return true;
            } catch (err: any) {
                const error: AxiosError<{ message: string }> = err;
                setError(error.response?.data?.message ?? 'An error occurred. Please try again.');
                return false;
            } finally {
                setLoading(false);
            }
        },
        [
            invitingOrganization?.uuid,
            isAuth,
            handleUserRegistration,
            uploadLogo,
            hasAccount,
            handleOrgComboPurchase,
            loggedInUser,
            parentOrganization?.uuid,
        ],
    );

    const handleHasAccountChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: (field: string, value: any) => void,
    ) => {
        const newValue = event.target.checked;
        setHasAccount(newValue);
        setFieldValue('hasAccount', newValue);
    };

    const FormValueSync = () => {
        const { values } = useFormikContext<{ hasAccount: boolean }>();

        useEffect(() => {
            setHasAccount(values.hasAccount);
        }, [values.hasAccount]);

        return null;
    };

    const hasPhysicalProducts = () => {
        return (
            Object.values(selectedPodCounts).some((count) => count > 0) ||
            Object.values(selectedVestCountsForPods).some((count) => count > 0) ||
            Object.values(selectedSingleChargerCountsForPods).some((count) => count > 0) ||
            Object.values(selectedTripleChargerCountsForPods).some((count) => count > 0) ||
            Object.values(selectedFiyrlinkGatewayCountsForPods).some((count) => count > 0)
        );
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        const vestDiscount = calculateVestDiscount();
        const fiyrlinkDiscount = calculateFiyrlinkDiscount();
        const shippingFee = hasPhysicalProducts() ? SHIPPING_FEE : 0;

        return subtotal - vestDiscount - fiyrlinkDiscount + shippingFee;
    };

    const theme = useTheme();

    return (
        <Box
            sx={{
                flex: 1,
                height: '100%',
                backgroundColor: 'white',
            }}
        >
            <CustomStepper
                skipped={skipped}
                setSkipped={setSkipped}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                initialValues={{
                    name: '',
                    zip: '',
                    organizationLogo: '',
                    organizationType: '',
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    user_name: '',
                    password: '',
                    password_confirmation: '',
                    owner: true,
                    hasAccount: false,
                }}
                steps={[
                    {
                        optional: false,
                        label: 'Choose Type',
                        validationSchema: yupSchemas.createOrganization.pick(['organizationType']),
                        onNext: async (values: OrganizationValues) => {
                            const type = options.find(
                                (item) => item.value === values.organizationType,
                            );
                            setSelectedOrg(type?.label || '');
                            setActiveStep(activeStep + 1);
                        },
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        Onboard New Organization
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        Your Sponsor: {invitingOrganization?.name}
                                    </Typography>
                                    {parentOrganization && (
                                        <Typography
                                            variant="h5"
                                            align="center"
                                            sx={{
                                                marginBottom: theme.spacing(16),
                                                whiteSpace: 'pre-line',
                                            }}
                                        >
                                            Your Parent: {parentOrganization?.name}
                                        </Typography>
                                    )}
                                    <Typography
                                        variant="h5"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        Get Started by CHOOSING YOUR ORGANIZATION TYPE
                                    </Typography>
                                    <Alert style={{ marginBottom: '2rem' }}>
                                        <strong>
                                            Note: if you already have an organization and want to
                                            buy products or subscriptions, please go to the{' '}
                                            <a
                                                href={`https://${window.location.host}/marketplace/${invitingOrganization?.slug}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {invitingOrganization?.name} Marketplace
                                            </a>
                                        </strong>
                                    </Alert>
                                    <Field
                                        name="organizationType"
                                        options={options}
                                        sx={{
                                            fontWeight: 600,
                                            borderRadius: 5,
                                            border: '1px solid',
                                            borderColor: '#E3E3E3',
                                            margin: theme.spacing(2, 0),
                                            paddingLeft: theme.spacing(8),
                                            paddingRight: theme.spacing(8),
                                        }}
                                        component={RadioGroupInput}
                                    />
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        optional: false,
                        label: 'Account',
                        validationSchema: yup.object({
                            hasAccount: yup.boolean().required(),
                        }),
                        onNext: (values: any) => {
                            setHasAccount(values.hasAccount);
                            if (!values.hasAccount) {
                                setActiveStep((prevStep) => prevStep + 1);
                            }
                            return true;
                        },
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    padding: theme.spacing(4),
                                    width: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <FormValueSync />
                                    <Typography variant="h5">
                                        Do you have a 1Tul user account?
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Field name="hasAccount">
                                                {({ field, form }: { field: any; form: any }) => (
                                                    <MuiSwitch
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={(e) =>
                                                            handleHasAccountChange(
                                                                e,
                                                                form.setFieldValue,
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        }
                                        label={
                                            hasAccount
                                                ? 'Yes, I have an account'
                                                : 'No, I need to register'
                                        }
                                    />
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        optional: false,
                        label: 'Login',
                        validationSchema: yupSchemas.usernameAndPasswordSimple,
                        onNext: async (values: any) => {
                            return await handleLogin(values);
                        },
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    padding: theme.spacing(4),
                                    width: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="h5">
                                        Log in to your 1Tul user account
                                    </Typography>
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="userName"
                                        label="User Name"
                                        variant="filled"
                                        component={FormikMuiTextField}
                                    />
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="password"
                                        label="Password"
                                        type="password"
                                        variant="filled"
                                        component={FormikMuiTextField}
                                    />
                                </Box>
                            </Box>
                        ),
                        disabled: !hasAccount,
                    },
                    {
                        optional: false,
                        label: 'Personal',
                        validationSchema: yupSchemas.registration,
                        onNext: async (values: any) => {
                            return await checkUsername(values);
                        },
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    padding: theme.spacing(4),
                                    width: '100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(4),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        Personal Information & Login
                                    </Typography>
                                    <Box>
                                        <Typography variant={'body1'}>
                                            The personal information will be used to create a 1Tul
                                            account.
                                        </Typography>
                                        <Field
                                            sx={{
                                                width: '100%',
                                                marginTop: theme.spacing(10),
                                            }}
                                            name="firstName"
                                            id="firstName"
                                            label="First Name"
                                            variant="filled"
                                            disabled={loading}
                                            component={FormikMuiTextField}
                                        />
                                        <Field
                                            sx={{
                                                width: '100%',
                                                marginTop: theme.spacing(10),
                                            }}
                                            name="lastName"
                                            id="lastName"
                                            label="Last Name"
                                            variant="filled"
                                            disabled={loading}
                                            component={FormikMuiTextField}
                                        />
                                        <Field
                                            sx={{
                                                width: '100%',
                                                marginTop: theme.spacing(10),
                                            }}
                                            name="phone"
                                            id="phone"
                                            label="Phone Number"
                                            mask="(999)999-9999"
                                            variant="filled"
                                            disabled={loading}
                                            component={MaskedInput}
                                        />
                                        <Field
                                            sx={{
                                                width: '100%',
                                                marginTop: theme.spacing(10),
                                            }}
                                            name="email"
                                            id="email"
                                            label="Email"
                                            variant="filled"
                                            disabled={loading}
                                            component={FormikMuiTextField}
                                        />
                                        <Field
                                            sx={{
                                                width: '100%',
                                                marginTop: theme.spacing(10),
                                            }}
                                            name="user_name"
                                            id="user_name"
                                            label="User Name"
                                            variant="filled"
                                            disabled={loading}
                                            component={FormikMuiTextField}
                                        />
                                        <Field
                                            sx={{
                                                width: '100%',
                                                marginTop: theme.spacing(10),
                                            }}
                                            name="password"
                                            id="password"
                                            label="Password"
                                            type="password"
                                            variant="filled"
                                            disabled={loading}
                                            component={FormikMuiTextField}
                                        />
                                        <Field
                                            sx={{
                                                width: '100%',
                                                marginTop: theme.spacing(10),
                                            }}
                                            name="password_confirmation"
                                            id="password_confirmation"
                                            label="Confirm Password"
                                            variant="filled"
                                            type="password"
                                            disabled={loading}
                                            component={FormikMuiTextField}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        ),
                        disabled: hasAccount,
                    },
                    {
                        optional: false,
                        validationSchema: yupSchemas.createOrganization.pick([
                            'name',
                            'zip',
                            'owner',
                        ]),
                        label: 'Create Organization',
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {`Enter A Name And Zip Code For Your  ${selectedOrg} Organization`}
                                    </Typography>
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="name"
                                        id="name"
                                        label="Organization Name"
                                        variant="filled"
                                        component={FormikMuiTextField}
                                        disabled={loading}
                                    />
                                    <Field
                                        sx={{
                                            width: '100%',
                                            marginTop: theme.spacing(10),
                                        }}
                                        name="zip"
                                        id="zip"
                                        label="Zip Code"
                                        variant="filled"
                                        component={FormikMuiTextField}
                                        disabled={loading}
                                    />
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    component={FormikMuiSwitch}
                                                    type="checkbox"
                                                    name="owner"
                                                    label="I am the owner of this organization"
                                                />
                                            }
                                            label="I am the owner of this organization"
                                        />
                                    </FormGroup>
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        optional: true,
                        disabled: false,
                        loading: loading,
                        label: 'Upload Logo',
                        validationSchema: yupSchemas.createOrganization.pick(['organizationLogo']),
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        sx={{
                                            marginBottom: theme.spacing(16),
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {`Upload A Logo (optional)`}
                                    </Typography>
                                    <UploadLogo
                                        loading={loading}
                                        uploadImage={uploadLogo}
                                        setUploadImage={setUploadLogo}
                                    />
                                </Box>
                            </Box>
                        ),
                    },
                    {
                        optional: false,
                        loading: loading,
                        onNext: async (values: OrganizationValues) => {
                            const response = await handleSubmit(values);
                            if (response) {
                                push('/home');
                            }
                            return response;
                        },
                        label: 'Choose Plan',
                        validationSchema: yupSchemas.createOrganization.pick(['organizationLogo']),
                        content: (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    marginTop: '24px',
                                }}
                            >
                                <Box
                                    sx={{
                                        maxWidth: 512,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        border: '1px solid lightgray',
                                        borderRadius: '8px',
                                        padding: '12px',
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        gutterBottom
                                        style={{ marginTop: '24px' }}
                                    >
                                        Organization 🏅 Starter Combo Package
                                    </Typography>
                                    <img src={orgCombo} alt="orgCombo" style={{ width: '240px' }} />
                                    <FormControl
                                        fullWidth
                                        margin="normal"
                                        style={{ marginTop: 0, marginBottom: '8px' }}
                                    >
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 24,
                                                    width: 24,
                                                    marginRight: 2,
                                                }}
                                                alt="goldMedal"
                                                src={goldMedal}
                                            />
                                            <Typography
                                                sx={{
                                                    width: 'calc(80% - 24px)',
                                                    fontSize: '0.8rem',
                                                }}
                                            >
                                                Organization Gold Package
                                                <span style={{ fontWeight: '500' }}>
                                                    {' '}
                                                    $100/month
                                                </span>
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}
                                                sx={{
                                                    width: '120px',
                                                    minWidth: '120px',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {}}
                                                    disabled={true}
                                                    style={{ opacity: 0 }}
                                                    sx={{
                                                        width: '32px',
                                                        height: '32px',
                                                        minWidth: '32px',
                                                        padding: 0,
                                                    }}
                                                >
                                                    −
                                                </IconButton>
                                                <TextField
                                                    value={1}
                                                    inputProps={{
                                                        readOnly: true,
                                                        style: {
                                                            textAlign: 'center',
                                                            padding: '4px',
                                                            width: '40px',
                                                            minWidth: '40px',
                                                            fontSize: '0.8rem',
                                                        },
                                                    }}
                                                    sx={{
                                                        width: '40px',
                                                        minWidth: '40px',
                                                        '& .MuiOutlinedInput-root': {
                                                            padding: 0,
                                                        },
                                                    }}
                                                    size="small"
                                                    disabled
                                                />
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {}}
                                                    disabled={true}
                                                    style={{ opacity: 0 }}
                                                    sx={{
                                                        width: '32px',
                                                        height: '32px',
                                                        minWidth: '32px',
                                                        padding: 0,
                                                    }}
                                                >
                                                    +
                                                </IconButton>
                                            </Stack>
                                        </Stack>
                                    </FormControl>
                                    <hr
                                        style={{
                                            width: '100%',
                                            margin: '16px 0',
                                            border: 'none',
                                            borderTop: '1px solid #e0e0e0',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.8rem',
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            marginTop: 2,
                                        }}
                                    >
                                        Fiyrpods
                                    </Typography>
                                    {podsForPurchase.map((pod) => (
                                        <FormControl
                                            key={pod.uuid}
                                            fullWidth
                                            margin="normal"
                                            style={{ marginTop: 0, marginBottom: '8px' }}
                                        >
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <HoverImage src={Pod} alt="Pod" />
                                                <Typography
                                                    sx={{
                                                        width: 'calc(80% - 24px)',
                                                        fontSize: '0.8rem',
                                                    }}
                                                >
                                                    {pod.name}
                                                    <span style={{ fontWeight: '500' }}>
                                                        {' $'}
                                                        {(pod.price / 100).toFixed(2)}
                                                        /month
                                                    </span>
                                                    <span
                                                        style={{
                                                            color:
                                                                pod.inventory_count > 0
                                                                    ? 'green'
                                                                    : 'red',
                                                            fontStyle: 'italic',
                                                            fontSize: '0.65rem',
                                                        }}
                                                    >
                                                        {pod.inventory_count > 0
                                                            ? ` ${pod.inventory_count} available`
                                                            : ' available soon'}
                                                    </span>
                                                </Typography>
                                                {pod.inventory_count > 0 && (
                                                    <QuantityControl
                                                        value={selectedPodCounts[pod.uuid] || 0}
                                                        onDecrement={() =>
                                                            handlePodCountChange(
                                                                pod.uuid,
                                                                Math.max(
                                                                    (selectedPodCounts[pod.uuid] ||
                                                                        0) - 1,
                                                                    0,
                                                                ),
                                                            )
                                                        }
                                                        onIncrement={() =>
                                                            handlePodCountChange(
                                                                pod.uuid,
                                                                Math.min(
                                                                    (selectedPodCounts[pod.uuid] ||
                                                                        0) + 1,
                                                                    pod.inventory_count,
                                                                ),
                                                            )
                                                        }
                                                        decrementDisabled={
                                                            (selectedPodCounts[pod.uuid] || 0) === 0
                                                        }
                                                        incrementDisabled={
                                                            (selectedPodCounts[
                                                                pod.stripe_price_id
                                                            ] || 0) >= pod.inventory_count
                                                        }
                                                    />
                                                )}
                                            </Stack>
                                        </FormControl>
                                    ))}
                                    <hr
                                        style={{
                                            width: '100%',
                                            margin: '16px 0',
                                            border: 'none',
                                            borderTop: '1px solid #e0e0e0',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.8rem',
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            marginTop: 2,
                                        }}
                                    >
                                        Vests
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '0.65rem',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Get a free vest with every pod subscription
                                    </Typography>
                                    {vestsForPurchase.map((vest) => (
                                        <FormControl
                                            key={vest.uuid}
                                            fullWidth
                                            margin="normal"
                                            style={{ marginTop: 0, marginBottom: '8px' }}
                                        >
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <HoverImage src={Vest} alt="Vest" />
                                                <Typography
                                                    sx={{
                                                        width: 'calc(80% - 24px)',
                                                        fontSize: '0.8rem',
                                                    }}
                                                >
                                                    {vest.name}
                                                    <span style={{ fontWeight: '500' }}>
                                                        {' $'}
                                                        {(vest.price / 100).toFixed(2)}{' '}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color:
                                                                vest.inventory_count > 0
                                                                    ? 'green'
                                                                    : 'red',
                                                            fontStyle: 'italic',
                                                            fontSize: '0.65rem',
                                                        }}
                                                    >
                                                        {vest.inventory_count > 0
                                                            ? ` ${vest.inventory_count} available`
                                                            : ' available soon'}
                                                    </span>
                                                </Typography>
                                                {vest.inventory_count > 0 && (
                                                    <QuantityControl
                                                        value={
                                                            selectedVestCountsForPods[
                                                                vest.stripe_price_id
                                                            ] || 0
                                                        }
                                                        onDecrement={() =>
                                                            handleVestForPodCountChange(
                                                                vest.stripe_price_id,
                                                                Math.max(
                                                                    (selectedVestCountsForPods[
                                                                        vest.stripe_price_id
                                                                    ] || 0) - 1,
                                                                    0,
                                                                ),
                                                            )
                                                        }
                                                        onIncrement={() =>
                                                            handleVestForPodCountChange(
                                                                vest.stripe_price_id,
                                                                Math.min(
                                                                    (selectedVestCountsForPods[
                                                                        vest.stripe_price_id
                                                                    ] || 0) + 1,
                                                                    vest.inventory_count,
                                                                ),
                                                            )
                                                        }
                                                        decrementDisabled={
                                                            (selectedVestCountsForPods[
                                                                vest.stripe_price_id
                                                            ] || 0) === 0
                                                        }
                                                        incrementDisabled={
                                                            (selectedVestCountsForPods[
                                                                vest.stripe_price_id
                                                            ] || 0) >= vest.inventory_count
                                                        }
                                                    />
                                                )}
                                            </Stack>
                                        </FormControl>
                                    ))}
                                    <hr
                                        style={{
                                            width: '100%',
                                            margin: '16px 0',
                                            border: 'none',
                                            borderTop: '1px solid #e0e0e0',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.8rem',
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            marginTop: 2,
                                        }}
                                    >
                                        Single Chargers
                                    </Typography>
                                    {singleChargersForPurchase.length === 0 && (
                                        <Typography
                                            sx={{
                                                fontSize: '0.65rem',
                                                textAlign: 'center',
                                                marginTop: 2,
                                                color: 'red',
                                            }}
                                        >
                                            No single chargers available
                                        </Typography>
                                    )}
                                    {singleChargersForPurchase.map((charger) => (
                                        <FormControl
                                            key={charger.uuid}
                                            fullWidth
                                            margin="normal"
                                            style={{ marginTop: 0, marginBottom: '8px' }}
                                        >
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <HoverImage src={Charger} alt="Charger" />
                                                <Typography
                                                    sx={{
                                                        width: 'calc(80% - 24px)',
                                                        fontSize: '0.8rem',
                                                    }}
                                                >
                                                    {charger.name}
                                                    <span style={{ fontWeight: '500' }}>
                                                        {' $'}
                                                        {(charger.price / 100).toFixed(2)}{' '}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color:
                                                                charger.inventory_count > 0
                                                                    ? 'green'
                                                                    : 'red',
                                                            fontStyle: 'italic',
                                                            fontSize: '0.65rem',
                                                        }}
                                                    >
                                                        {charger.inventory_count > 0
                                                            ? ` ${charger.inventory_count} available`
                                                            : ' available soon'}
                                                    </span>
                                                </Typography>
                                                {charger.inventory_count > 0 && (
                                                    <QuantityControl
                                                        value={
                                                            selectedSingleChargerCountsForPods[
                                                                charger.stripe_price_id
                                                            ] || 0
                                                        }
                                                        onDecrement={() =>
                                                            handleSingleChargerForPodCountChange(
                                                                charger.stripe_price_id,
                                                                Math.max(
                                                                    (selectedSingleChargerCountsForPods[
                                                                        charger.stripe_price_id
                                                                    ] || 0) - 1,
                                                                    0,
                                                                ),
                                                            )
                                                        }
                                                        onIncrement={() =>
                                                            handleSingleChargerForPodCountChange(
                                                                charger.stripe_price_id,
                                                                Math.min(
                                                                    (selectedSingleChargerCountsForPods[
                                                                        charger.stripe_price_id
                                                                    ] || 0) + 1,
                                                                    charger.inventory_count,
                                                                ),
                                                            )
                                                        }
                                                        decrementDisabled={
                                                            (selectedSingleChargerCountsForPods[
                                                                charger.stripe_price_id
                                                            ] || 0) === 0
                                                        }
                                                        incrementDisabled={
                                                            (selectedSingleChargerCountsForPods[
                                                                charger.stripe_price_id
                                                            ] || 0) >= charger.inventory_count
                                                        }
                                                    />
                                                )}
                                            </Stack>
                                        </FormControl>
                                    ))}
                                    <hr
                                        style={{
                                            width: '100%',
                                            margin: '16px 0',
                                            border: 'none',
                                            borderTop: '1px solid #e0e0e0',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.8rem',
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            marginTop: 2,
                                        }}
                                    >
                                        Triple Chargers
                                    </Typography>
                                    {tripleChargersForPurchase.length === 0 && (
                                        <Typography
                                            sx={{
                                                fontSize: '0.65rem',
                                                textAlign: 'center',
                                                marginTop: 2,
                                                color: 'red',
                                            }}
                                        >
                                            No triple chargers available
                                        </Typography>
                                    )}
                                    {tripleChargersForPurchase.map((charger) => (
                                        <FormControl
                                            key={charger.uuid}
                                            fullWidth
                                            margin="normal"
                                            style={{ marginTop: 0, marginBottom: '8px' }}
                                        >
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <HoverImage
                                                    src={TripleCharger}
                                                    alt="TripleCharger"
                                                />
                                                <Typography
                                                    sx={{
                                                        width: 'calc(80% - 24px)',
                                                        fontSize: '0.8rem',
                                                    }}
                                                >
                                                    {charger.name}
                                                    <span style={{ fontWeight: '500' }}>
                                                        {' $'}
                                                        {(charger.price / 100).toFixed(2)}{' '}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color:
                                                                charger.inventory_count > 0
                                                                    ? 'green'
                                                                    : 'red',
                                                            fontStyle: 'italic',
                                                            fontSize: '0.65rem',
                                                        }}
                                                    >
                                                        {charger.inventory_count > 0
                                                            ? ` ${charger.inventory_count} available`
                                                            : ' available soon'}
                                                    </span>
                                                </Typography>
                                                {charger.inventory_count > 0 && (
                                                    <QuantityControl
                                                        value={
                                                            selectedTripleChargerCountsForPods[
                                                                charger.stripe_price_id
                                                            ] || 0
                                                        }
                                                        onDecrement={() =>
                                                            handleTripleChargerForPodCountChange(
                                                                charger.stripe_price_id,
                                                                Math.max(
                                                                    (selectedTripleChargerCountsForPods[
                                                                        charger.stripe_price_id
                                                                    ] || 0) - 1,
                                                                    0,
                                                                ),
                                                            )
                                                        }
                                                        onIncrement={() =>
                                                            handleTripleChargerForPodCountChange(
                                                                charger.stripe_price_id,
                                                                Math.min(
                                                                    (selectedTripleChargerCountsForPods[
                                                                        charger.stripe_price_id
                                                                    ] || 0) + 1,
                                                                    charger.inventory_count,
                                                                ),
                                                            )
                                                        }
                                                        decrementDisabled={
                                                            (selectedTripleChargerCountsForPods[
                                                                charger.stripe_price_id
                                                            ] || 0) === 0
                                                        }
                                                        incrementDisabled={
                                                            (selectedTripleChargerCountsForPods[
                                                                charger.stripe_price_id
                                                            ] || 0) >= charger.inventory_count
                                                        }
                                                    />
                                                )}
                                            </Stack>
                                        </FormControl>
                                    ))}
                                    <hr
                                        style={{
                                            width: '100%',
                                            margin: '16px 0',
                                            border: 'none',
                                            borderTop: '1px solid #e0e0e0',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.8rem',
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            marginTop: 2,
                                        }}
                                    >
                                        Fiyrlink Gateways
                                    </Typography>
                                    {fiyrlinkGatewaysForPurchase.length === 0 && (
                                        <Typography
                                            sx={{
                                                fontSize: '0.65rem',
                                                textAlign: 'center',
                                                marginTop: 2,
                                                color: 'red',
                                            }}
                                        >
                                            No Fiyrlink gateways available
                                        </Typography>
                                    )}
                                    {fiyrlinkGatewaysForPurchase.map((fiyrlink) => (
                                        <FormControl
                                            key={fiyrlink.uuid}
                                            fullWidth
                                            margin="normal"
                                            style={{ marginTop: 0, marginBottom: '8px' }}
                                        >
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <HoverImage src={Fiyrlink} alt="Fiyrlink" />
                                                <Typography
                                                    sx={{
                                                        width: 'calc(80% - 24px)',
                                                        fontSize: '0.8rem',
                                                    }}
                                                >
                                                    {fiyrlink.name}
                                                    <span style={{ fontWeight: '500' }}>
                                                        {' $'}
                                                        {(fiyrlink.price / 100).toFixed(
                                                            2,
                                                        )}/month{' '}
                                                    </span>
                                                    <span
                                                        style={{
                                                            color:
                                                                fiyrlink.inventory_count > 0
                                                                    ? 'green'
                                                                    : 'red',
                                                            fontStyle: 'italic',
                                                            fontSize: '0.65rem',
                                                        }}
                                                    >
                                                        {fiyrlink.inventory_count > 0
                                                            ? ` ${fiyrlink.inventory_count} available`
                                                            : ' available soon'}
                                                    </span>
                                                </Typography>
                                                {fiyrlink.inventory_count > 0 && (
                                                    <QuantityControl
                                                        value={
                                                            selectedFiyrlinkGatewayCountsForPods[
                                                                fiyrlink.stripe_price_id
                                                            ] || 0
                                                        }
                                                        onDecrement={() =>
                                                            handleFiyrlinkGatewayForPodCountChange(
                                                                fiyrlink.stripe_price_id,
                                                                Math.max(
                                                                    (selectedFiyrlinkGatewayCountsForPods[
                                                                        fiyrlink.stripe_price_id
                                                                    ] || 0) - 1,
                                                                    0,
                                                                ),
                                                            )
                                                        }
                                                        onIncrement={() =>
                                                            handleFiyrlinkGatewayForPodCountChange(
                                                                fiyrlink.stripe_price_id,
                                                                Math.min(
                                                                    (selectedFiyrlinkGatewayCountsForPods[
                                                                        fiyrlink.stripe_price_id
                                                                    ] || 0) + 1,
                                                                    fiyrlink.inventory_count,
                                                                ),
                                                            )
                                                        }
                                                        decrementDisabled={
                                                            (selectedFiyrlinkGatewayCountsForPods[
                                                                fiyrlink.stripe_price_id
                                                            ] || 0) === 0
                                                        }
                                                        incrementDisabled={
                                                            (selectedFiyrlinkGatewayCountsForPods[
                                                                fiyrlink.stripe_price_id
                                                            ] || 0) >= fiyrlink.inventory_count
                                                        }
                                                    />
                                                )}
                                            </Stack>
                                        </FormControl>
                                    ))}

                                    {Object.values(selectedPodCounts).reduce(
                                        (sum, count) => sum + count,
                                        0,
                                    ) <=
                                    Object.values(selectedVestCountsForPods).reduce(
                                        (sum, count) => sum + count,
                                        0,
                                    ) ? (
                                        <>
                                            <hr
                                                style={{
                                                    width: '100%',
                                                    margin: '16px 0',
                                                    border: 'none',
                                                    borderTop: '1px solid #e0e0e0',
                                                }}
                                            />
                                            {isFinishButtonDisabled() && (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        padding: '10px 16px 4px 0',
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            color: 'red',
                                                            fontSize: '0.65rem',
                                                        }}
                                                    >
                                                        You have selected more pods than vests. You
                                                        can select&nbsp;
                                                        {Object.values(selectedPodCounts).reduce(
                                                            (sum, count) => sum + count,
                                                            0,
                                                        ) -
                                                            Object.values(
                                                                selectedVestCountsForPods,
                                                            ).reduce(
                                                                (sum, count) => sum + count,
                                                                0,
                                                            )}
                                                        &nbsp;more vest(s) free of charge.
                                                    </Typography>
                                                </Box>
                                            )}

                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    textAlign: 'right',
                                                    padding: '10px 16px 4px 0',
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        fontSize: '0.65rem',
                                                        color: '#424242',
                                                        fontWeight: '450',
                                                        display: 'block',
                                                    }}
                                                >
                                                    Subtotal: &nbsp;$
                                                    {calculateSubtotal().toFixed(2)}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        fontSize: '0.65rem',
                                                        color: '#424242',
                                                        fontWeight: '450',
                                                        display: 'block',
                                                    }}
                                                >
                                                    Vest discount: -$
                                                    {calculateVestDiscount().toFixed(2)}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        fontSize: '0.65rem',
                                                        color: '#424242',
                                                        fontWeight: '450',
                                                        display: fiyrlinkDiscount
                                                            ? 'block'
                                                            : 'none',
                                                    }}
                                                >
                                                    Fiyrlink discount: -$
                                                    {fiyrlinkDiscount.toFixed(2)}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        fontSize: '0.6rem',
                                                        color: '#424242',
                                                        display: 'block',
                                                    }}
                                                >
                                                    Tax: (will be calculated)
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        fontSize: '0.6rem',
                                                        color: '#424242',
                                                        display: 'block',
                                                    }}
                                                >
                                                    Shipping: $
                                                    {hasPhysicalProducts()
                                                        ? SHIPPING_FEE.toFixed(2)
                                                        : '0.00'}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    sx={{
                                                        fontSize: '0.65rem',
                                                        color: '#424242',
                                                        fontWeight: '600',
                                                        display: 'block',
                                                    }}
                                                >
                                                    Total: ${calculateTotal().toFixed(2)}
                                                </Typography>
                                            </Box>
                                        </>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: '4px',
                                                marginBottom: '48px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontSize: '0.65rem',
                                                    color: 'red',
                                                    margin: '8px',
                                                }}
                                            >
                                                Please select an equal or greater number of vests to
                                                match the pods you have selected. You get a free
                                                vest with every pod subscription. You get a free
                                                Fiyrlink subscription if you order 5 or more pods.
                                            </span>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        ),
                    },
                ]}
                isFinishButtonDisabled={isFinishButtonDisabled}
            />
            <Snackbar
                open={!!error}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                message={error}
                onClose={() => {
                    setError('');
                }}
                autoHideDuration={6000}
            />
        </Box>
    );
};

export default CreateOrganization;
