import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { PublicUser } from '../../utils';

type ReturnType = Promise<
    AxiosResponse<{
        token: { access_token: string; expires_at: string };
        user: PublicUser;
    }>
>;

const userRegister = (
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    password_confirmation: string,
    phone: string,
    username: string,
    skip_login: boolean = false,
): ReturnType =>
    Request.post(`/users`, {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        password_confirmation,
        user_name: username,
        phone,
        skip_login,
    });

export default userRegister;
