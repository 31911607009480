import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close';
import LinearProgress from '@mui/material/LinearProgress';
import { ButtonWithLoader, CustomButton } from 'components/Buttons';
import TypographyTitle from 'components/Typography/TypographyTitle';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export interface FormModalProps {
    children: React.ReactNode;
    /** handleClose A callback when the close button is clicked */
    handleClose: () => void;
    /** handleButtonClicked A callback when the close button is clicked */
    handleButtonClicked?: () => void;
    /** title The text to display in the title of the dialog */
    title?: string;
    /** open Whether or not the form modal is open */
    open: boolean;
    /** loading Whether or not the modal is in a loading state */
    loading?: boolean;
    /** buttonText The text on the action button */
    buttonText?: string;
    /** cancelText the text on the cancel button */
    cancelText?: string;
    /** fullWidth if the modal is full width or not */
    fullWidth?: boolean;
    /** maxWidth the maximum width of the modal */
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    /** disabled if the button is disabled */
    disabled?: boolean;
    /** className for the DialogContent */
    contentClassnameSx?: SxProps<Theme>;
    /** remove top padding from the DialogContent */
    noPadding?: boolean;
    /** hide the Header */
    noHeader?: boolean;
    /** deleteText The text on the delete button */
    deleteText?: string;
    /** handleDelete A callback when the delete button is clicked */
    handleDelete?: () => void;
}

function FormModal({
    children,
    handleClose,
    title,
    open,
    handleButtonClicked,
    contentClassnameSx,
    loading = false,
    buttonText = 'Save',
    cancelText = 'Cancel',
    fullWidth = true,
    maxWidth = 'sm',
    disabled = false,
    noPadding = false,
    noHeader = false,
    deleteText = '',
    handleDelete = () => {},
}: FormModalProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Dialog fullWidth={fullWidth} maxWidth={maxWidth} fullScreen={fullScreen} open={open}>
            {loading && <LinearProgress />}

            {fullScreen
                ? !noHeader && (
                      <AppBar sx={{ position: 'relative' }}>
                          <Toolbar>
                              <IconButton
                                  edge="start"
                                  color="inherit"
                                  onClick={handleClose}
                                  aria-label="close"
                                  size="large"
                              >
                                  <CloseIcon />
                              </IconButton>
                              {!!title?.length && (
                                  <TypographyTitle sx={{ marginLeft: theme.spacing(2), flex: 1 }}>
                                      {title}
                                  </TypographyTitle>
                              )}
                              <CustomButton
                                  disabled={disabled || loading}
                                  color="inherit"
                                  onClick={handleButtonClicked}
                              >
                                  {buttonText}
                              </CustomButton>
                          </Toolbar>
                      </AppBar>
                  )
                : !!title?.length && <DialogTitle>{title}</DialogTitle>}
            {noPadding && <div style={{ display: 'none' }} />}
            <DialogContent dividers sx={contentClassnameSx}>
                {children}
            </DialogContent>
            {!fullScreen && (
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {deleteText && (
                        <CustomButton onClick={handleDelete} color="error">
                            {deleteText}
                        </CustomButton>
                    )}
                    {!deleteText && <div />}
                    <Box>
                        <CustomButton color="primary" onClick={handleClose}>
                            {cancelText}
                        </CustomButton>
                        {handleButtonClicked && (
                            <ButtonWithLoader
                                variant="contained"
                                color="primary"
                                onClick={handleButtonClicked}
                                disabled={disabled || loading}
                                loading={loading}
                            >
                                {buttonText}
                            </ButtonWithLoader>
                        )}
                    </Box>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default FormModal;
