import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { StaticTimePicker } from '@mui/x-date-pickers-pro/';
import { TimezoneSelect } from 'components/Select';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { FC, useCallback, useState } from 'react';
import Calendar from 'react-calendar';
import { TimezoneKey } from 'utils/timezones';
import { DateValue } from '../../utils';

const CARD_TIME_FORMAT = 'hh:mm a';

type DateInputView = 'date' | 'time' | 'timezone';

export interface DateTimePickerDialogProps {
    /* browser timezone */
    currentTimezone: string;
    /** A callback fired when the component requests to be closed. */
    onClose: () => void;
    /** A callback fired when the date & timepicker input values change */
    onChange: (value: DateValue) => void;
    /** If true, the Dialog is open. */
    open: boolean;
    /** input date value */
    sessionDate: DateValue;
}

const DateTimePickerDialog: FC<React.PropsWithChildren<DateTimePickerDialogProps>> = ({
    currentTimezone,
    onClose,
    onChange,
    open,
    sessionDate,
}) => {
    const [dateInputView, setDateInputView] = useState<DateInputView>('date');
    const theme = useTheme();
    const handleChangeDate = useCallback(
        (value: DateTime): void => {
            onChange({
                ...sessionDate,
                date: value,
            });
        },
        [sessionDate, onChange],
    );

    const handleSwitchToTimeView = useCallback(() => {
        if (!sessionDate.date) {
            onChange({
                ...sessionDate,
                date: DateTime.now(),
            });
        }
        setDateInputView('time');
    }, [sessionDate, onChange]);

    const handleClickAllDay = useCallback(() => {
        onChange({
            ...sessionDate,
            time: null,
            isWholeDay: true,
        });
        onClose();
    }, [sessionDate, onChange, onClose]);

    const handleClickChooseTime = useCallback(() => {
        let coeff = 1000 * 60 * 5;
        let date = DateTime.now(); //or use any other date
        let rounded = new Date(Math.round(date.toMillis() / coeff) * coeff);

        onChange({
            ...sessionDate,
            time: sessionDate.time ?? DateTime.fromJSDate(rounded),
            isWholeDay: false,
        });
        onClose();
    }, [sessionDate, onChange, onClose]);

    const formattedTimeText = moment(sessionDate.time).format(CARD_TIME_FORMAT);
    const formattedTimezoneText =
        sessionDate.timezone !== currentTimezone && sessionDate.timezone
            ? ` ${moment().tz(sessionDate.timezone).format('z')}`
            : '';

    const addTimeButtonText = sessionDate.time
        ? `${formattedTimeText}${formattedTimezoneText}`
        : 'Add Time';

    return (
        <Dialog onClose={onClose} aria-labelledby="dateTimePicker" open={open}>
            {dateInputView === 'date' && (
                <>
                    <DialogTitle
                        sx={{ textAlign: 'center', margin: 0, padding: theme.spacing(5) }}
                        id="dateTimePicker"
                    >
                        <Typography variant="h6">Choose Date</Typography>
                        <IconButton
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: theme.spacing(1),
                                top: theme.spacing(1),
                                color: theme.palette.grey[500],
                            }}
                            onClick={onClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers sx={{ padding: theme.spacing(0) }}>
                        <Calendar
                            onChange={(date: any) => handleChangeDate(DateTime.fromJSDate(date))}
                            value={(sessionDate.date || DateTime.now()).toJSDate()}
                        />
                    </DialogContent>
                    <DialogActions
                        sx={{
                            margin: 0,
                            padding: theme.spacing(5, 10),
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button onClick={handleSwitchToTimeView} color="primary">
                            {addTimeButtonText}
                        </Button>
                        <Button onClick={handleClickAllDay} color="primary">
                            All Day
                        </Button>
                    </DialogActions>
                </>
            )}

            {dateInputView === 'time' && (
                <>
                    <StaticTimePicker
                        value={sessionDate.time}
                        openTo="hours"
                        minutesStep={5}
                        onChange={(dateTime) => {
                            onChange({
                                ...sessionDate,
                                isWholeDay: false,
                                time: dateTime,
                            });
                        }}
                        slots={{}}
                        slotProps={{
                            actionBar: {
                                actions: [],
                            },
                        }}
                        localeText={{
                            toolbarTitle: '',
                        }}
                    />
                    <DialogActions
                        sx={{
                            margin: 0,
                            padding: theme.spacing(5, 10),
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button onClick={() => setDateInputView('date')} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleClickChooseTime} color="primary">
                            Ok
                        </Button>
                        {sessionDate.time && (
                            <Button onClick={() => setDateInputView('timezone')} color="primary">
                                Choose Timezone
                            </Button>
                        )}
                    </DialogActions>
                </>
            )}

            {dateInputView === 'timezone' && (
                <Box sx={{ padding: theme.spacing(5, 10) }}>
                    <TimezoneSelect
                        onChange={(timezoneInputValue) => {
                            onChange({
                                ...sessionDate,
                                timezone: timezoneInputValue as TimezoneKey,
                            });
                        }}
                        id="timezoneSelect"
                        label="Event time zone"
                        value={sessionDate.timezone ?? 'America/Los_Angeles'}
                    />
                    <DialogActions sx={{ padding: theme.spacing(8, 0, 0) }}>
                        <Button onClick={() => setDateInputView('time')} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={onClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Box>
            )}
        </Dialog>
    );
};

export default DateTimePickerDialog;
