import { Box } from '@mui/material';

const getColor = (value: number) => {
    if (value <= 0.5) return 'red';
    if (value <= 1) return 'orange';
    if (value <= 1.5) return 'yellow';
    return 'green';
};

// Usage: <ColorCircle value={yourValue} />
const ColorCircle = ({ value }: { value: number }) => (
    <Box sx={{ bgcolor: getColor(value), width: 24, height: 24, borderRadius: '50%' }} />
);

export default ColorCircle;
