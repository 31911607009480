import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Activity } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Activity>>>;

interface GetActivityParams {
    'filter[in_progress]'?: 1;
    'filter[complete]'?: 1;
    'filter[unattested]'?: 1;
    'filter[archived]'?: 1;
    'filter[unarchived]'?: 1;
}

const getMyActivities = (params: GetActivityParams): ReturnType => {
    return Request.get(`/workouts/activities`, { params });
};

export default getMyActivities;
