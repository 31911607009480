import {
    Box,
    Button,
    ButtonBase,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Actions, convertDateToHours, TimelineEntries } from 'utils';
import { timelineTypeMapper } from 'utils/timelineUtils';
import { Can } from '../../../components';
import { GenericSubject } from '../../../utils/ability';

type TimelineCardProps = {
    data: TimelineEntries[];
};

const TimelineCard = ({ data }: TimelineCardProps) => {
    const [opened, setOpened] = useState<string | number | null>(null);
    const { push } = useHistory();
    const toggleOpened = (id: number | string): void => {
        if (opened === id) {
            setOpened(null);
        } else {
            setOpened(id);
        }
    };
    const renderListItem = (
        startTime: string,
        endTime: string,
        desc: string,
        isButton: boolean,
        isOpen: boolean,
        item: TimelineEntries,
        url: string | null,
        buttonPermission: Actions,
        buttonText: string,
        genericPermissionObject: GenericSubject,
    ) => (
        <ListItem
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
            }}
        >
            <ButtonBase
                onClick={() => toggleOpened(item.id)}
                sx={{ width: '100%', margin: 2, display: 'flex', justifyContent: 'flex-start' }}
            >
                <ListItemText
                    sx={(theme) => ({
                        flex: '0 0 15%',
                        color: theme.palette.text.secondary,
                        '& > *': {
                            fontSize: 13,
                            fontWeight: 500,
                        },
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                    })}
                >
                    {startTime}
                </ListItemText>
                <ListItemText
                    sx={(theme) => ({
                        flex: '0 0 85%',
                        color: theme.palette.text.secondary,
                        '& > *': {
                            fontSize: 12,
                        },
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                    })}
                >
                    {desc}
                </ListItemText>
            </ButtonBase>

            <Collapse sx={{ width: '100%' }} in={isOpen}>
                {isOpen && (
                    <>
                        {url && (
                            <Can I={buttonPermission} this={genericPermissionObject}>
                                <Box
                                    sx={{
                                        width: '100%',
                                        margin: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        onClick={() => push(url)}
                                        sx={{ backgroundColor: 'white' }}
                                        size="small"
                                        variant="outlined"
                                    >
                                        {buttonText}
                                    </Button>
                                </Box>
                            </Can>
                        )}
                        {item.type === 'form.submitted' && (
                            <TableContainer>
                                <Table size="small" aria-label="Answers">
                                    <TableBody key={item.id}>
                                        {item.form_submitted_values.map((v, index) => (
                                            <React.Fragment key={`${item.id}-${index}-question`}>
                                                <TableRow style={{ background: '#003E9D14' }}>
                                                    <TableCell>{v.question.question}</TableCell>
                                                </TableRow>
                                                <TableRow style={{ background: 'white' }}>
                                                    <TableCell>{v.value}</TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </>
                )}
            </Collapse>
        </ListItem>
    );
    return (
        <Paper elevation={3} sx={{ padding: '0 0px', borderRadius: 4 }}>
            <List dense>
                {data.map((item, index) => {
                    const startTime = item.reference_date
                        ? convertDateToHours(item.reference_date)
                        : '';
                    const endTime = item.end_time ? convertDateToHours(item.end_time) : '';
                    const { desc, url, buttonPermission, buttonText, permissionObject } =
                        timelineTypeMapper(item);
                    return (
                        <React.Fragment key={item.id}>
                            {renderListItem(
                                startTime,
                                endTime,
                                desc,
                                false,
                                opened === item.id,
                                item,
                                url,
                                buttonPermission,
                                buttonText,
                                permissionObject,
                            )}
                            {index !== data.length - 1 && <Divider />}
                        </React.Fragment>
                    );
                })}
            </List>
        </Paper>
    );
};

export { TimelineCard };
