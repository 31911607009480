import { RouteConfigProps } from '../../Layouts/routeconfig';
import Explore from './scenes/Explore';
import NavigationLayout from '../../Layouts/NavigationLayout';
import ExplorationDetails from './scenes/ExplorationDetails';
import { Navigation } from '../../utils';

const exploreRoutes: RouteConfigProps[] = [
    {
        exact: true,
        layout: NavigationLayout,
        path: '/explore',
        component: Explore,
        primaryNavigation: Navigation.EXPLORE,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/explore/:explorationId',
        component: ExplorationDetails,
    },
];

export default exploreRoutes;
