import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Individual } from 'utils';

type ReturnType = Promise<AxiosResponse<Individual>>;

const createIndividual = (
    name: string,
    organization?: string | null,
    logo?: string,
): ReturnType => {
    return Request.post(`/individuals`, {
        name: name,
        profile_photo: logo,
        organization,
    });
};

export default createIndividual;
