import { Request } from '../../api';
import { AxiosResponse } from 'axios';
import { Exploration, ExplorationFilters } from './explore.types';
import { SimplePaginateResponse } from '../../app.types';
import { CropLabel } from '../community/community.types';

export const getExplorations = (
    params: Record<string, string | number | boolean | string[]>,
): Promise<AxiosResponse<SimplePaginateResponse<Exploration>>> => {
    return Request.get('/explorations', { params });
};

export const getExploration = (id: string): Promise<AxiosResponse<Exploration>> => {
    return Request.get(`/explorations/${id}`);
};

export const deleteExploration = (id: string): Promise<AxiosResponse<void>> => {
    return Request.delete(`/explorations/${id}`);
};

export const createExploration = (
    params: Record<string, string | number | boolean | ExplorationFilters>,
): Promise<AxiosResponse<Exploration>> => {
    return Request.post('/explorations', params);
};

export const updateExploration = (
    id: string,
    params: Record<string, string | number | boolean | ExplorationFilters>,
): Promise<AxiosResponse<Exploration>> => {
    return Request.put(`/explorations/${id}`, params);
};

export const getAllCropLabels = (organizationId: string): Promise<AxiosResponse<CropLabel[]>> => {
    return Request.get(`/workouts/crop-labels`, {
        params: {
            organization_uuid: organizationId,
        },
    });
};

export const batchGroupsForExploration = (
    id: string,
    params: Record<'groups', Array<string>>,
): Promise<AxiosResponse<void>> => {
    return Request.post(`/explorations/${id}/groups`, params);
};

export const batchIndividualsForExploration = (
    id: string,
    params: Record<'individuals', Array<string>>,
): Promise<AxiosResponse<void>> => {
    return Request.post(`/explorations/${id}/individuals`, params);
};
