import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangeValue } from '../../app.types';
import SelectDateChip from '../Chips/SelectDateChip';

export type RangeChoice =
    | 'last 7 days'
    | 'last 14 days'
    | 'last 30 days'
    | 'last 60 days'
    | 'last 90 days'
    | 'last 120 days'
    | 'this week'
    | 'this month'
    | 'last week'
    | 'last month'
    | 'last 6 months'
    | 'this year'
    | 'all'
    | 'custom'
    | 'from 1 year ago to now'
    | 'from 6 months ago to now'
    | 'from 1 months ago to now'
    | 'from 7 days ago to now';

const RANGE_CHOICES: { [key in RangeChoice]: string } = {
    all: 'All Time',
    'last 7 days': 'Last 7 Days',
    'last 14 days': 'Last 14 Days',
    'last 30 days': 'Last 30 Days',
    'last 60 days': 'Last 60 Days',
    'last 90 days': 'Last 90 Days',
    'last 120 days': 'Last 120 Days',
    'this week': 'This Week',
    'this month': 'This Month',
    'last week': 'Last Week',
    'last month': 'Last Month',
    'last 6 months': 'Last 6 Months',
    'this year': 'This Year',
    'from 1 year ago to now': 'In The Last Year',
    'from 6 months ago to now': 'In The Last 6 Months',
    'from 1 months ago to now': 'In The Last Month',
    'from 7 days ago to now': 'In The Last 7 Days',
    custom: 'Custom',
};

interface Props {
    value: DateRangeValue;
    onChange: (value: DateRangeValue) => void;
    size?: 'small' | 'medium';
    exclude?: RangeChoice[];
    customStartRecent?: boolean;
}

function getDateRangeValueFromRangeChoice(
    choice: RangeChoice,
    customStartRecent: boolean = false,
): DateRangeValue {
    switch (choice) {
        case 'custom':
            return [
                moment()
                    .subtract(1, customStartRecent ? 'months' : 'years')
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                moment().endOf('day').format('YYYY-MM-DD'),
            ];
    }
    return choice;
}

export default function CubeRelativeDateRangeSelector({
    value,
    onChange,
    size = 'medium',
    exclude = [],
    customStartRecent = false,
}: Props): React.ReactElement {
    useEffect(() => {
        if (Array.isArray(value)) {
            setRange('custom');
        } else {
            setRange(value as RangeChoice);
        }
    }, [value]);

    const [range, setRange] = useState<RangeChoice>('all');

    function handleRangeChange(range: RangeChoice) {
        setRange(range);
        onChange(getDateRangeValueFromRangeChoice(range, customStartRecent));
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl>
                <Select
                    variant={'outlined'}
                    labelId="date-range-label"
                    id="date-range"
                    value={range}
                    size={size}
                    onChange={(e) => handleRangeChange((e.target.value as RangeChoice) ?? 'all')}
                >
                    {Object.keys(RANGE_CHOICES).map((key: any) => (
                        <MenuItem
                            key={key}
                            value={key}
                            disabled={exclude?.indexOf(key) !== -1}
                            sx={{ color: exclude?.indexOf(key) !== -1 ? 'grey.500' : undefined }}
                        >
                            {RANGE_CHOICES[key as RangeChoice] as string}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {range === 'custom' && (
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginLeft={5}
                    marginRight={5}
                >
                    <SelectDateChip
                        onChange={(v) =>
                            onChange([v?.toISODate() ?? DateTime.now().toISODate(), value[1]])
                        }
                        value={DateTime.fromISO(value[0])}
                    />
                    <Typography style={{ marginLeft: 6, marginRight: 6 }}> To </Typography>
                    <SelectDateChip
                        onChange={(v) =>
                            onChange([value[0], (v ?? DateTime.now()).toISODate() ?? ''])
                        }
                        value={DateTime.fromISO(value[1])}
                    />
                </Box>
            )}
        </Box>
    );
}
