import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AdminAssignCoachesToGroup() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL allows you to add organizational users and give them permissions
                        specific to their roles in the organization. There are three organizational
                        roles: <u>Administrators</u>, <u>Organizational Coaches</u>, and{' '}
                        <u>Content Managers</u>.
                        <br />
                        <br />
                        <strong>Administrators </strong> have full access to all organizational data
                        and can delete data and update the organization. This is the highest role in
                        the organization and should be used with discretion.
                        <br />
                        <strong>Organizational Coaches </strong> can only access to all group data
                        and can perform all group management functions. In addition the
                        Organizational Coach can create and edit all content. However, this role
                        cannot delete data or update the organization itself.
                        <br />
                        <strong>Content Managers </strong> have access to all organizational data
                        except for the ability to delete data and update the organization and manage
                        groups. This role is reserved for those who need to manage the content of
                        the organization such as create movements, sessions, and learning content.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/884220347?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Inviting Organizational Users'}
                            description={'.'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminAssignCoachesToGroup;
