import React from 'react';
import SearchIndividual from './SearchIndividual';
import { Individual, Organization } from '../../utils';
import { Avatar, Box, Chip } from '@mui/material';

interface Props {
    organization: Organization;
    value: Individual[];
    onChange: (individuals: Individual[]) => void;
}

function FilterByIndividual({ organization, value, onChange }: Props): React.ReactElement {
    return (
        <Box display="flex">
            <Box display="flex" alignItems="center">
                {value.map((i) => (
                    <Chip
                        key={i.uuid}
                        avatar={<Avatar alt={i.name} src={i.image_urls.avatar ?? ''} />}
                        onDelete={(e) => {
                            e.preventDefault();
                            onChange(value.filter((ind) => ind.uuid !== i.uuid));
                        }}
                        label={i.name}
                    />
                ))}
            </Box>
            <SearchIndividual
                createButtonText=""
                buttonText="Add Athlete(s)"
                onIndividualSelected={(individuals) => {
                    const individual = individuals[0];
                    if (value.findIndex((i) => i.uuid === individual.uuid) === -1) {
                        onChange([...value, individual]);
                    }
                }}
                organization={organization}
            />
        </Box>
    );
}

export default FilterByIndividual;
