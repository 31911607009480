import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AdminAssignCoachesToGroup() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        <strong>Group Coaches </strong>can only access group data and perform group
                        management functions after they have been granted permission through the
                        permissions link. The coach must click on the permission link and enter the
                        password you created to gain access.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/876111616?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Assigning Coaches to a Group'}
                            description={
                                'Assigning Coaches to a Group grants the coach access to only data belonging to the group the coach is assigned to.'
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminAssignCoachesToGroup;
