import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import ToeOffKinogram from '../media/VideoAnalysisInteractiveKinogram.png';
import FullScreenDialogCard from '../components/HowToDialogCard';
import VideoAnalysisDatabase from '../media/VideoAnalysisDatabase.png';
import VideoAnalysisReport from '../media/VideoAnalysisDetailedReport.png';
import AsymmetyAnalysis from '../media/AssymetryAnalysis.png';
import ReportTable from '../media/VideoAnalysisReportTable.png';
import ReportTimeSpeed from '../media/VideoAnalysisTimeSpeed.png';

function AIVideoOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL AI Video Analysis is an advanced markerless biomechanical joint
                        tracking tool that allows you to analyse sprint performance with just an
                        iPhone and 60 frames per second (fps) video.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Features include interactive kinogram, video analysis global database
                            with drill down capabilities, fly 10 yard split time, fly 10 avg. speed,
                            peak flexion and extension angles, and stride asymmetries.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={VideoAnalysisDatabase}
                    title="Video Analysis Database"
                    headline="Video Analysis Database"
                    description="Video analysis database allows you to see all your video analysis metrics in one spot while providing sorting and filtering features as well as advanced drill down capabilities."
                />

                <FullScreenDialogCard
                    image={VideoAnalysisReport}
                    title="Video Analysis Report"
                    headline="Video Analysis Report"
                    description="Video analysis report provides key metrics to evaluate asymetries and help prevent injuries while evaluating return to play."
                />
                <FullScreenDialogCard
                    image={ToeOffKinogram}
                    title="Toe Off Kinogram"
                    headline="Toe Off Kinogram"
                    description="Interactive kinogram selections include: toe off, foot down, peak flexion and peak extension."
                />

                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Analyse asymmetries to monitor return to play and prevent injuries.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={AsymmetyAnalysis}
                    title="Video Analysis Report Chart"
                    headline="Video Analysis Report Chart"
                    description="Easily identify differences between left and right flexion, extension, ground time and air time."
                />
                <FullScreenDialogCard
                    image={ReportTable}
                    title="Video Analysis Report Table"
                    headline="Video Analysis Report Table"
                    description="View all the metrics in a data table and click through frames to see interactive kinogram."
                />
                <FullScreenDialogCard
                    image={ReportTimeSpeed}
                    title="Report - Split Time and Speed"
                    headline="Report - Split Time and Speed"
                    description="View split time and average speed with extreme accuracy."
                />
            </Grid>
        </>
    );
}

export default AIVideoOverview;
