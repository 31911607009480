import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ url: string }>>;

const createCheckoutPodOrgSubscriptionWithVests = (
    return_url: string,
    org_id_for_purchase: string,
    seller_org_id: string,
    vest_price_id_quantity_map: Record<string, number>,
    pod_quantity_map: Record<string, number>,
): ReturnType => {
    return Request.post(
        `/organizations/${org_id_for_purchase}/checkout-pod-subscription-with-vests-session`,
        {
            return_url,
            seller_org_id,
            vest_price_id_quantity_map,
            pod_quantity_map,
        },
    );
};
export default createCheckoutPodOrgSubscriptionWithVests;
