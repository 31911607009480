import ChooseItemModalLayout from './ChooseItemModalLayout';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Form } from 'utils';
import { RowDivider } from 'components/Separators';
import { debounce } from 'lodash';
import { getAllForms } from '../../api';

interface ChooseFormProps {
    activeOrganizationId: string | undefined;
    open: boolean;
    onClose: () => void;
    onClickForm: (form: Form) => void;
    allowCreate?: boolean;
}

const ChooseFormModal = ({ activeOrganizationId, open, onClose, onClickForm }: ChooseFormProps) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [filter, setFilter] = useState('');
    const [data, setData] = useState<Array<Form>>([]);

    const debounceLoadData = debounce(setFilter, 400);

    const onClick = (form: Form) => {
        if (form.organization.uuid !== activeOrganizationId) {
            return;
        }
        onClickForm(form);
        onClose();
    };

    const handleChange = (value: string): void => {
        setSearchValue(value);
        debounceLoadData(value);
    };

    const loadForms = (query: {
        'filter[title]'?: string;
        'filter[organization]'?: string;
    }): void => {
        getAllForms({
            ...query,
            limit: 50,
        }).then((response) => setData(response.data.data));
    };

    useEffect(() => {
        if (filter.length > 0) {
            loadForms({
                'filter[organization]': activeOrganizationId || '',
                'filter[title]': filter,
            });
        }
    }, [dispatch, filter, activeOrganizationId]);

    useEffect(() => {
        loadForms({
            'filter[title]': '',
            'filter[organization]': activeOrganizationId || '',
        });
    }, [activeOrganizationId]);

    return (
        <>
            <ChooseItemModalLayout
                open={open}
                onClose={onClose}
                buttonText="Create New Form"
                searchHeading="Form List"
                searchValue={searchValue}
                setSearchValue={handleChange}
                noResultText="No results"
                searchResults={[]}
                searchBarPlaceHolder="Add a Form"
            >
                {data.map((form) => {
                    const disabled = form.organization.uuid !== activeOrganizationId;
                    return (
                        <Fragment key={form.uuid}>
                            <Box
                                aria-disabled={disabled}
                                bgcolor={disabled ? 'grey.100' : 'white'}
                                color={disabled ? 'text.disabled' : 'text.primary'}
                                sx={{
                                    cursor: 'pointer',
                                    '&[aria-disabled="true"]': {
                                        cursor: 'not-allowed',
                                    },
                                }}
                                onClick={() => {
                                    onClick(form);
                                }}
                                onKeyDown={() => {
                                    onClick(form);
                                }}
                                p={8}
                                pl={16}
                                role="button"
                                tabIndex={disabled ? -1 : 0}
                            >
                                <Typography>{`${form.title}`}</Typography>
                                <Typography variant="caption">{form.organization.name}</Typography>
                            </Box>
                            <RowDivider />
                        </Fragment>
                    );
                })}
            </ChooseItemModalLayout>
        </>
    );
};

export default ChooseFormModal;
