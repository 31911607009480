import { ExplorationApiFilters, ExplorationFilters, MeasureKey } from './explore.types';
import { getGroups } from '../community/api/groups.api';
import { Group, Individual } from '../../utils';
import { getIndividuals } from '../../api';

export function convertApiFiltersToExplorationFilters(
    filters: ExplorationApiFilters,
    defaultMeasures: MeasureKey[] = ['GpsSummary.maxSpeed'],
): ExplorationFilters {
    return {
        range: filters.range ? filters.range : 'from 1 months ago to now',
        showOnSameGraph: filters.showOnSameGraph ?? true,
        individuals: filters.individuals ?? [],
        groups: filters.groups ?? [],
        cropLabels: filters.cropLabels ?? [],
        tags: filters.tags ?? [],
        yAxisKey: filters.yAxisKey ?? 'Velocity (mph)',
        measures: filters.measures ?? defaultMeasures,
        dimensions: filters.dimensions ?? [
            'GpsSummary.athleteName',
            'GpsSummary.athleteProfilePhotoUrl',
        ],
        timeGrouping: filters.timeGrouping ?? 'week',
        excludedCrops: filters.excludedCrops ?? [],
    };
}

export const groupDataGetter = (page: number, limit: number, payload: Record<string, any>) =>
    getGroups({
        page,
        limit,
        ...payload,
    });

export const groupDataMapper = (response: any) => ({
    dimensions: response.data.map((i: Group) => ({
        title: i.name,
        image_url: i.image_urls.avatar ?? '',
        uuid: i.uuid,
    })),
    page: response.meta.current_page,
    hasMore: Boolean(response.links.next),
});

export const individualDataGetter = (page: number, limit: number, payload: Record<string, any>) =>
    getIndividuals({
        page,
        limit,
        ...payload,
    });

export const individualDataMapper = (response: any) => ({
    dimensions: response.data.map((i: Individual) => ({
        title: `${i.name}(${i.managing_user?.user_name ?? ' No Username Yet'})`,
        image_url: i.image_urls.avatar ?? '',
        uuid: i.uuid,
    })),
    page: response.meta.current_page,
    hasMore: Boolean(response.links.next),
});

export const formatGpsSummaryData = (number: number, measure: string) => {
    if (measure === 'GpsSummary.maxSpeed') {
        return `${number.toFixed(1)} mph`;
    }
    if (measure === 'GpsSummary.avgSpeed') {
        return `${number.toFixed(1)} mph`;
    }
    if (measure === 'GpsSummary.maxDistance') {
        // use intl number here
        return `${Intl.NumberFormat('en-US').format(Math.floor(number))} yd`;
    }
    return number;
};
