import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { TypographyTitle } from '../../../components';
import LandingAuthScreenLayout from '../../../components/Layouts/LandingAuthScreenLayout';
import DbLogo from '../../../images/db_icon.png';
import Lottie from 'lottie-react';
import RegisterAnimation from '../../../lottie-animations/sign-up.json';
import LoginAnimation from '../../../lottie-animations/log-in.json';

const LandingPage = () => {
    const history = useHistory();
    const handleLoginClick = () => {
        history.push('/login');
    };
    const handleRegisterClick = () => {
        history.push('/register');
    };

    return (
        <LandingAuthScreenLayout>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="50%" // This ensures the content takes the full height of the parent
            >
                <Typography
                    sx={{ mt: 4, mb: 2, display: 'flex', alignItems: 'center' }}
                    variant="subtitle1"
                    component="div"
                >
                    <img src={DbLogo} alt="1TUL Logo" width={90} />
                </Typography>
                <TypographyTitle align="center">
                    1TUL to Prove Performance Improvement.
                </TypographyTitle>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Lottie
                            animationData={LoginAnimation}
                            loop={true}
                            autoplay={true}
                            style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                            onClick={handleLoginClick}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Lottie
                            animationData={RegisterAnimation}
                            loop={true}
                            autoplay={true}
                            style={{ width: '120px', height: '120px', cursor: 'pointer' }}
                            onClick={handleRegisterClick}
                        />
                    </Grid>
                </Grid>
                <Typography
                    sx={{ mt: 16, mb: 2, display: 'flex', alignItems: 'center' }}
                    variant="subtitle1"
                    component="div"
                >
                    Additional Technology Required: GPS, 1080 Motion, or smart phone with 60 fps
                    camera is required for most 1TUL features.
                </Typography>
            </Box>
        </LandingAuthScreenLayout>
    );
};

export default LandingPage;
