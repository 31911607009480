import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    Hidden,
    Paper,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { addMovementsToSessionInBulk, getSession } from 'api';
import updateSession, { SessionUpdatePayload } from 'api/Sessions/updateSession';
import { AxiosResponse } from 'axios';
import { ChooseMediaDialog, confirmViaDialog } from 'components/Dialogs';
import { AttributeInput } from 'components/FormControl';
import { TextEditorInput } from 'components/Inputs';
import { SessionSharingDrawer } from 'components/Navigation';
import convert from 'convert-units';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import Lottie from 'lottie-react';
import { archiveSession } from 'modules/train/api/sessions';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getSessionAttributes } from 'redux/reducers/attributes';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import {
    AccelerationUnit,
    AttributeCategory,
    AttributeValue,
    DistanceUnit,
    ForceUnit,
    LoadUnit,
    Movement,
    NewSessionMovement,
    PowerUnit,
    RangeOfMotionUnit,
    Session,
    SessionMovement,
    SetAttributeType,
    SharedUser,
    somethingWentWrong,
    SpeedUnit,
    TimeDisplayFormat,
    TimeUnit,
} from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { Media } from '../../app.types';
import Archive from '../../lottie-animations/Parcel.json';
import { unarchiveSession } from '../../modules/train/api/sessions';
import { CustomButton } from '../Buttons';
import { Can } from '../Functional';
import SessionMovementsWithGoals from './SessionMovementsWithGoals';
import SessionMovementWithGoalsCondensed from './SessionMovementWithGoalsCondensed';

const timeDisplayHierarchy: string[] = [
    'ss',
    'ss.SSS',
    'mm:ss',
    'mm:ss.SSS',
    'hh:mm:ss',
    'hh:mm:ss.SSS',
];

export interface EditSessionFormProps {
    onFormReady: () => void;
    onSaveError: () => void;
    onUsersLoaded: (users: Array<SharedUser>) => void;
}

export interface EditSessionRefProps {
    onSessionSaved: () => Promise<AxiosResponse<Session>> | null;
    onSessionShareDrawerOpened: () => void;
    getSession: () => Session | null;
}

const EditSessionForm = React.forwardRef(
    ({ onFormReady, onUsersLoaded, onSaveError }: EditSessionFormProps, refs) => {
        let { id: sessionUuid } = useParams<{ id: string }>();
        const dispatch = useAppDispatch();
        const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('sm'));
        const attributes = useSelector(getSessionAttributes);
        const [attributeValues, setAttributeValues] = useState<AttributeValue[]>([]);
        const [session, setSession] = useState<Session | null>(null);
        const [saving, setSaving] = useState<boolean>(false);
        const [sessionShareDrawer, setSessionShareDrawer] = useState<boolean>(false);
        const [openImageSelectionDialog, setOpenImageSelectionDialog] = useState<boolean>(false);

        // Load the form from the database
        useEffect(() => {
            if (sessionUuid) {
                getSession(sessionUuid).then((response) => {
                    let session = response.data as Session;
                    // Making sure the session movements are shown as per their order in the backend
                    session?.movements?.sort(
                        (m1, m2) => (m1?.pivot?.order || 0) - (m2?.pivot?.order || 0),
                    );
                    setSession(response.data as Session);
                    setAttributeValues(response.data.attributes);
                });
            }
        }, [sessionUuid]);

        useEffect(() => {
            if (session) onFormReady();
        }, [onFormReady, session]);

        const updateSessionData = (syncedSession: Session, payload: SessionUpdatePayload) => {
            if (!syncedSession) {
                return;
            }
            updateSession(syncedSession.uuid, payload);
        };

        const onSessionTitleUpdated = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            let updatedSesssion = cloneDeep(session) as Session;
            updatedSesssion.title = event.target.value || '';
            setSession(updatedSesssion);
            updateSessionData(updatedSesssion, {
                title: updatedSesssion.title,
            });
        }, 2000);

        const onSessionDurationUpdated = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
            let updatedSesssion = cloneDeep(session) as Session;
            updatedSesssion.duration = event.target.value || '';
            setSession(updatedSesssion);
            updateSessionData(updatedSesssion, {
                duration: parseInt(updatedSesssion.duration, 10) || 0,
            });
        }, 2000);

        const onSessionCoverPhotoUpdated = (media: Media | undefined) => {
            let updatedSession = cloneDeep(session) as Session;
            updatedSession.cover_photo = media?.url || null;
            setSession(updatedSession);
            updateSessionData(updatedSession, {
                cover_photo_media: media?.uuid,
            });
        };

        const onSessionDescriptionUpdated = debounce((newDescription: string) => {
            let updatedSesssion = cloneDeep(session) as Session;
            updatedSesssion.description = newDescription;
            setSession(updatedSesssion);
            updateSessionData(updatedSesssion, {
                description: updatedSesssion.description,
            });
        }, 2000);

        const onSessionAttributesUpdated = debounce((attributeValues: AttributeValue[]) => {
            let updatedSesssion = cloneDeep(session) as Session;
            updatedSesssion.attributes = attributeValues;
            setSession(updatedSesssion);
            setAttributeValues(attributeValues);
            updateSessionData(updatedSesssion, {
                attributes: attributeValues.filter((value) => value.value != ''),
            });
        }, 2000);

        const onMovementAddedToSession = (addedMovement: Movement) => {
            let updatedSesssion = cloneDeep(session) as Session;
            (updatedSesssion.movements = updatedSesssion.movements || []).push({
                ...addedMovement,
                pivot: {
                    uuid: uuidv4(),
                    reps: 1,
                    load_unit: LoadUnit.Pound,
                    distance_unit: DistanceUnit.Yard,
                },
            } as SessionMovement);
            setSession(updatedSesssion);
        };

        // When a movement is added to a session, we can select different chips for goals
        // like reps, distance, load, time etc
        // These can be now added as part of movement presets so we dont need to select
        // same chips again
        // This function checks if the movement already has a preset, then choose those chips here
        const getAddedMovementPresets = (
            addedMovement: Movement,
            groupTitle: string | null = null,
        ) => {
            const presets = addedMovement?.movement_presets || {};
            return {
                uuid: uuidv4(),
                group_title: groupTitle,
                reps: 'Reps' in presets ? 0 : null,
                load_unit: presets?.['Load'] || LoadUnit.Pound,
                load_value: 'Load' in presets ? 0 : null,
                distance_unit: presets?.['Distance'] || DistanceUnit.Yard,
                distance_value: 'Distance' in presets ? 0 : null,
                time_unit: presets?.['Time'] || TimeUnit.Millisecond,
                time_display_format: presets?.['Time'] || TimeDisplayFormat.Run,
                time_value: 'Time' in presets ? 0 : null,
                range_of_motion_unit: presets?.['RangeOfMotion'] || RangeOfMotionUnit.Degree,
                range_of_motion_value: 'RangeOfMotion' in presets ? 0 : null,
                speed_unit: presets?.['Speed'] || SpeedUnit.MPH,
                speed_value: 'Speed' in presets ? 0 : null,
                acceleration_unit: presets?.['Acceleration'] || AccelerationUnit.FPS,
                acceleration_value: 'Acceleration' in presets ? 0 : null,
                power_unit: presets?.['Power'] || PowerUnit.Watt,
                power_value: 'Power' in presets ? 0 : null,
                force_unit: presets?.['Force'] || ForceUnit.Newton,
                force_value: 'Force' in presets ? 0 : null,
                rsi_value: 'RSI' in presets ? 0 : null,
                body_side: 'BodySide' in presets ? 'both' : null,
                rpe_value: 'RPE' in presets ? 3 : null, //moderate,
                band_value: 'Band' in presets ? 3 : null, //medium
                rpm_value: 'RPM' in presets ? 0 : null,
                gps_player_load_value: 'GPSPlayerLoad' in presets ? 0 : null,
                gps_accel_count_value: 'GPSAccelCount' in presets ? 0 : null,
                gps_decel_count_value: 'GPSDecelCount' in presets ? 0 : null,
            };
        };

        const onMultipleMovementsAddedToSession = (addedMovements: Array<Movement>) => {
            let updatedSesssion = cloneDeep(session) as Session;
            let sessionMovements = updatedSesssion.movements || [];

            addedMovements.forEach((addedMovement) => {
                sessionMovements.push({
                    ...addedMovement,
                    pivot: getAddedMovementPresets(addedMovement),
                } as SessionMovement);
            });

            updatedSesssion.movements = sessionMovements;

            setSession(updatedSesssion);
        };

        const onMultipleSessionMovementsAddedToSession = (
            addedMovements: Array<SessionMovement>,
            groupTitle: string | null = null,
        ) => {
            let updatedSesssion = cloneDeep(session) as Session;
            let sessionMovements = updatedSesssion.movements || [];

            sessionMovements.push(
                ...addedMovements.map((m) => {
                    if (m.pivot) {
                        m.pivot.group_title = groupTitle;
                        return {
                            ...m,
                            pivot: m.pivot,
                        };
                    }
                    return m;
                }),
            );
            updatedSesssion.movements = sessionMovements;

            setSession(updatedSesssion);
        };

        const addSessionMovement = (previousSessionMovement: SessionMovement) => {
            let updatedSesssion = cloneDeep(session) as Session;

            let newSessionMovement = cloneDeep(previousSessionMovement);
            if (newSessionMovement.pivot) {
                newSessionMovement.pivot.uuid = uuidv4();
            }

            if (!updatedSesssion.movements) {
                // No session movements are present, so push the new session movement in an empty array
                updatedSesssion.movements = [newSessionMovement];
            } else {
                let previousSessionMovementIndex: number = updatedSesssion.movements?.findIndex(
                    (sessionMovement) =>
                        sessionMovement.pivot?.uuid == previousSessionMovement.pivot?.uuid,
                );
                if (previousSessionMovementIndex < 0) {
                    return;
                }

                updatedSesssion.movements?.splice(
                    previousSessionMovementIndex,
                    0,
                    newSessionMovement,
                );
            }

            setSession(updatedSesssion);
            saveSessionUpdates(updatedSesssion)
                ?.then(() => setSaving(false))

                .catch(() => {
                    setSaving(false);
                    somethingWentWrong();
                });
        };

        const removeSessionMovement = (removedSessionMovement: SessionMovement) => {
            let updatedSesssion = cloneDeep(session) as Session;

            if (!updatedSesssion.movements) {
                return;
            }

            updatedSesssion.movements = updatedSesssion.movements.filter(
                (sessionMovement) =>
                    sessionMovement.pivot?.uuid != removedSessionMovement.pivot?.uuid,
            );

            saveSessionUpdates(updatedSesssion)
                ?.then(() => {
                    setSession(updatedSesssion);
                    setSaving(false);
                })
                .catch(() => {
                    setSaving(false);
                    somethingWentWrong();
                });
        };

        const removeMultipleSessionMovements = (
            removedSessionMovements: Array<SessionMovement>,
        ) => {
            let updatedSesssion = cloneDeep(session) as Session;

            if (!updatedSesssion.movements) {
                return;
            }

            updatedSesssion.movements = updatedSesssion.movements.filter(
                (sessionMovement) =>
                    removedSessionMovements.findIndex(
                        (removedSessionMovement) =>
                            removedSessionMovement.pivot?.uuid == sessionMovement.pivot?.uuid,
                    ) < 0,
            );

            saveSessionUpdates(updatedSesssion)
                ?.then(() => {
                    setSession(updatedSesssion);
                    setSaving(false);
                })
                .catch(() => {
                    setSaving(false);
                    somethingWentWrong();
                });
        };

        const addCriteriaToSessionMovements = (
            updatedSessionMovements: Array<SessionMovement>,
            criteria: SetAttributeType,
        ) => {
            let updatedSesssion = cloneDeep(session) as Session;
            updatedSesssion.movements =
                updatedSesssion.movements?.map((movement) => {
                    if (
                        updatedSessionMovements.findIndex(
                            (updatedSessionMovement) =>
                                !isEmpty(updatedSessionMovement.pivot) &&
                                updatedSessionMovement.pivot?.uuid == movement.pivot?.uuid,
                        ) >= 0 &&
                        movement.pivot
                    ) {
                        switch (criteria) {
                            case SetAttributeType.Reps:
                                movement.pivot.reps = 0;
                                break;

                            case SetAttributeType.Load:
                                movement.pivot.load_value = 0;
                                break;

                            case SetAttributeType.Time:
                                movement.pivot.time_value = 0;
                                break;

                            case SetAttributeType.Distance:
                                movement.pivot.distance_value = 0;
                                break;

                            case SetAttributeType.RangeOfMotion:
                                movement.pivot.range_of_motion_value = 0;
                                break;

                            case SetAttributeType.Speed:
                                movement.pivot.speed_value = 0;
                                break;
                            case SetAttributeType.Acceleration:
                                movement.pivot.acceleration_value = 0;
                                break;
                            case SetAttributeType.Power:
                                movement.pivot.power_value = 0;
                                break;
                            case SetAttributeType.Force:
                                movement.pivot.force_value = 0;
                                break;

                            case SetAttributeType.Rest:
                                movement.pivot.rest_value = 0;
                                break;

                            case SetAttributeType.BodySide:
                                movement.pivot.body_side = 'both';
                                break;

                            case SetAttributeType.RPE:
                                movement.pivot.rpe_value = 3; //moderate
                                break;

                            case SetAttributeType.Band:
                                movement.pivot.band_value = 3; //medium
                                break;

                            case SetAttributeType.RSI:
                                movement.pivot.rsi_value = 0;
                                break;

                            case SetAttributeType.RPM:
                                movement.pivot.rpm_value = 0;
                                break;
                            case SetAttributeType.GPSPlayerLoad:
                                movement.pivot.gps_player_load_value = 0;
                                break;
                            case SetAttributeType.GPSAccelCount:
                                movement.pivot.gps_accel_count_value = 0;
                                break;
                            case SetAttributeType.GPSDecelCount:
                                movement.pivot.gps_decel_count_value = 0;
                                break;

                            default:
                                break;
                        }
                    }
                    return movement;
                }) || [];

            setSession(updatedSesssion);
        };

        const changeSessionMovementUnit = async (
            updatedSessionMovements: Array<SessionMovement>,
            criteria: SetAttributeType,
            oldUnit: string,
            newUnit: string,
        ) => {
            let updatedSesssion = cloneDeep(session) as Session;

            // If user changes time from a higher to lower unit, we can not convert duration into required mask
            // So we ask for user's confirmation before erasing the values
            if (
                criteria == SetAttributeType.Time &&
                timeDisplayHierarchy.indexOf(oldUnit as TimeDisplayFormat) >
                    timeDisplayHierarchy.indexOf(newUnit as TimeDisplayFormat)
            ) {
                if (
                    !(await confirmViaDialog({
                        confirmation: {
                            title: 'Are you sure?',
                            message: 'This will delete the existing time values for this set.',
                        },
                    }))
                ) {
                    return;
                }
            }

            updatedSesssion.movements = updatedSesssion.movements?.map((sessionMovement) => {
                let sessionMovementIndex = updatedSessionMovements.findIndex(
                    (updatedSessionMovement) =>
                        updatedSessionMovement.pivot?.uuid == sessionMovement.pivot?.uuid,
                );
                if (sessionMovementIndex < 0 || !sessionMovement.pivot) {
                    return sessionMovement;
                }

                switch (criteria) {
                    case SetAttributeType.Load:
                        sessionMovement.pivot.load_value = parseFloat(
                            convert(sessionMovement.pivot.load_value)
                                .from(oldUnit as LoadUnit)
                                .to(newUnit as LoadUnit)
                                .toFixed(4),
                        );
                        sessionMovement.pivot.load_unit = newUnit;
                        break;

                    case SetAttributeType.Distance:
                        sessionMovement.pivot.distance_value = parseFloat(
                            convert(sessionMovement.pivot.distance_value)
                                .from(oldUnit as DistanceUnit)
                                .to(newUnit as DistanceUnit)
                                .toFixed(4),
                        );
                        sessionMovement.pivot.distance_unit = newUnit;
                        break;
                    case SetAttributeType.Time:
                        if (
                            timeDisplayHierarchy.indexOf(oldUnit as TimeDisplayFormat) >
                            timeDisplayHierarchy.indexOf(newUnit as TimeDisplayFormat)
                        ) {
                            sessionMovement.pivot.time_value = 1000;
                            sessionMovement.pivot.time_display_format = newUnit;
                        } else {
                            sessionMovement.pivot.time_display_format = newUnit;
                        }
                        break;
                    case SetAttributeType.Speed:
                        sessionMovement.pivot.speed_value = parseFloat(
                            convert(sessionMovement.pivot.speed_value)
                                .from(oldUnit as SpeedUnit)
                                .to(newUnit as SpeedUnit)
                                .toFixed(4),
                        );
                        sessionMovement.pivot.speed_unit = newUnit;
                        break;

                    default:
                        break;
                }

                return sessionMovement;
            });

            setSession(updatedSesssion);
        };

        const removeCriteriaFromSessionMovements = (
            updatedSessionMovements: Array<SessionMovement>,
            criteria: SetAttributeType,
        ) => {
            let updatedSesssion = cloneDeep(session) as Session;
            updatedSesssion.movements =
                updatedSesssion.movements?.map((movement) => {
                    if (
                        updatedSessionMovements.findIndex(
                            (updatedSessionMovement) =>
                                !isEmpty(updatedSessionMovement.pivot) &&
                                updatedSessionMovement.pivot?.uuid == movement.pivot?.uuid,
                        ) >= 0 &&
                        movement.pivot
                    ) {
                        switch (criteria) {
                            case SetAttributeType.Reps:
                                movement.pivot.reps = null;
                                break;

                            case SetAttributeType.Load:
                                movement.pivot.load_value = null;
                                break;

                            case SetAttributeType.Time:
                                movement.pivot.time_value = null;
                                break;

                            case SetAttributeType.Distance:
                                movement.pivot.distance_value = null;
                                break;

                            case SetAttributeType.RangeOfMotion:
                                movement.pivot.range_of_motion_value = null;
                                break;

                            case SetAttributeType.Speed:
                                movement.pivot.speed_value = null;
                                break;

                            case SetAttributeType.Acceleration:
                                movement.pivot.acceleration_value = null;
                                break;

                            case SetAttributeType.Power:
                                movement.pivot.power_value = null;
                                break;

                            case SetAttributeType.Force:
                                movement.pivot.force_value = null;
                                break;

                            case SetAttributeType.RSI:
                                movement.pivot.rsi_value = null;
                                break;

                            case SetAttributeType.BodySide:
                                movement.pivot.body_side = null;
                                break;

                            case SetAttributeType.RPE:
                                movement.pivot.rpe_value = null;
                                break;

                            case SetAttributeType.Band:
                                movement.pivot.band_value = null;
                                break;

                            case SetAttributeType.Rest:
                                movement.pivot.rest_value = null;
                                break;
                            case SetAttributeType.RPM:
                                movement.pivot.rpm_value = null;
                                break;
                            case SetAttributeType.GPSPlayerLoad:
                                movement.pivot.gps_player_load_value = null;
                                break;
                            case SetAttributeType.GPSAccelCount:
                                movement.pivot.gps_accel_count_value = null;
                                break;
                            case SetAttributeType.GPSDecelCount:
                                movement.pivot.gps_decel_count_value = null;
                                break;

                            default:
                                break;
                        }
                    }
                    return movement;
                }) || [];

            setSession(updatedSesssion);
        };

        const saveSessionUpdates = (
            updatedSession: Session,
        ): Promise<AxiosResponse<Session>> | null => {
            if (!updatedSession || saving) {
                return null;
            }

            setSaving(true);

            let isError = false;

            let payload: Array<NewSessionMovement> =
                updatedSession.movements?.map((sessionMovement) => {
                    if (
                        sessionMovement.pivot?.load_value &&
                        sessionMovement.pivot?.load_value > Math.pow(10, 5) &&
                        !isError
                    ) {
                        dispatch(
                            pushMessage({
                                status: 'error',
                                message: `Load value ${sessionMovement.pivot?.load_value} of ${sessionMovement.name} is too large. Please use bigger load scale if available.`,
                            }),
                        );
                        isError = true;
                    }

                    if (
                        sessionMovement.pivot?.distance_value &&
                        sessionMovement.pivot?.distance_value > Math.pow(10, 5) &&
                        !isError
                    ) {
                        dispatch(
                            pushMessage({
                                status: 'error',
                                message: `Distance value ${sessionMovement.pivot?.distance_value} of ${sessionMovement.name} is too large. Please use bigger distance scale if available.`,
                            }),
                        );
                        isError = true;
                    }

                    if (
                        sessionMovement.pivot?.speed_value &&
                        sessionMovement.pivot?.speed_value > Math.pow(10, 5) &&
                        !isError
                    ) {
                        dispatch(
                            pushMessage({
                                status: 'error',
                                message: `Speed value ${sessionMovement.pivot?.speed_value} of ${sessionMovement.name} is too large. Please use bigger speed scale if available.`,
                            }),
                        );
                        isError = true;
                    }

                    if (
                        sessionMovement.pivot?.acceleration_value &&
                        sessionMovement.pivot?.acceleration_value > Math.pow(10, 3) &&
                        !isError
                    ) {
                        dispatch(
                            pushMessage({
                                status: 'error',
                                message: `Acceleration value ${sessionMovement.pivot?.acceleration_value} of ${sessionMovement.name} is too large.`,
                            }),
                        );
                        isError = true;
                    }

                    if (
                        sessionMovement.pivot?.range_of_motion_value &&
                        sessionMovement.pivot?.range_of_motion_value > 360 &&
                        !isError
                    ) {
                        dispatch(
                            pushMessage({
                                status: 'error',
                                message: `Range Of Motion value ${sessionMovement.pivot?.range_of_motion_value} of ${sessionMovement.name} is too large. Please use a value between 0 and 360 degrees.`,
                            }),
                        );
                        isError = true;
                    }

                    return {
                        movement: sessionMovement.uuid,
                        reps: sessionMovement.pivot?.reps ?? null,
                        load_unit: sessionMovement.pivot?.load_unit || LoadUnit.Pound,
                        load_value: sessionMovement.pivot?.load_value ?? null,
                        distance_unit: sessionMovement.pivot?.distance_unit || DistanceUnit.Yard,
                        distance_value: sessionMovement.pivot?.distance_value ?? null,
                        time_unit: TimeUnit.Millisecond,
                        time_display_format:
                            sessionMovement.pivot?.time_display_format || TimeDisplayFormat.Common,
                        time_value: sessionMovement.pivot?.time_value ?? null,
                        rest_unit: sessionMovement.pivot?.rest_unit || TimeUnit.Second,
                        rest_value: sessionMovement.pivot?.rest_value ?? null,
                        weight_unit: sessionMovement.pivot?.weight_unit || LoadUnit.Pound,
                        weight_value: sessionMovement.pivot?.weight_value ?? null,
                        range_of_motion_value: sessionMovement.pivot?.range_of_motion_value ?? null,
                        range_of_motion_unit:
                            sessionMovement.pivot?.range_of_motion_unit || RangeOfMotionUnit.Degree,
                        speed_value: sessionMovement.pivot?.speed_value ?? null,
                        speed_unit: sessionMovement.pivot?.speed_unit || SpeedUnit.MPH,
                        acceleration_value: sessionMovement.pivot?.acceleration_value ?? null,
                        acceleration_unit:
                            sessionMovement.pivot?.acceleration_unit || AccelerationUnit.FPS,
                        power_value: sessionMovement.pivot?.power_value ?? null,
                        power_unit: sessionMovement.pivot?.power_unit || PowerUnit.Watt,
                        rsi_value: sessionMovement.pivot?.rsi_value ?? null,
                        force_value: sessionMovement.pivot?.force_value ?? null,
                        force_unit: ForceUnit.Newton,
                        body_side: sessionMovement.pivot?.body_side ?? null,
                        rpe_value: sessionMovement.pivot?.rpe_value ?? null,
                        band_value: sessionMovement.pivot?.band_value ?? null,
                        group_title: sessionMovement.pivot?.group_title ?? null,
                        rpm_value: sessionMovement.pivot?.rpm_value ?? null,
                        gps_player_load_value: sessionMovement.pivot?.gps_player_load_value ?? null,
                        gps_accel_count_value: sessionMovement.pivot?.gps_accel_count_value ?? null,
                        gps_decel_count_value: sessionMovement.pivot?.gps_decel_count_value ?? null,
                    } as NewSessionMovement;
                }) || [];

            if (isError) {
                setSaving(false);
                onSaveError();
                return null;
            }

            return addMovementsToSessionInBulk(payload, updatedSession.uuid);
        };

        const updateMovementSessionGoalValue = debounce(
            (
                updatedSessionMovement: SessionMovement,
                updatedGoalCriteria: SetAttributeType,
                newValue: any,
            ) => {
                setSession((oldSession) => {
                    let sessionMovementToUpdate = oldSession?.movements?.find(
                        (sessionMovement) =>
                            sessionMovement.pivot?.uuid == updatedSessionMovement.pivot?.uuid,
                    );

                    if (!sessionMovementToUpdate || !sessionMovementToUpdate.pivot) {
                        return oldSession;
                    }
                    switch (updatedGoalCriteria) {
                        case SetAttributeType.Reps:
                            sessionMovementToUpdate.pivot.reps = newValue;
                            break;

                        case SetAttributeType.Rest:
                            sessionMovementToUpdate.pivot.rest_value = newValue;
                            break;

                        case SetAttributeType.Distance:
                            sessionMovementToUpdate.pivot.distance_value = newValue;
                            break;

                        case SetAttributeType.Time:
                            sessionMovementToUpdate.pivot.time_value = newValue;
                            break;

                        case SetAttributeType.Load:
                            sessionMovementToUpdate.pivot.load_value = newValue;
                            break;

                        case SetAttributeType.RangeOfMotion:
                            sessionMovementToUpdate.pivot.range_of_motion_value = newValue;
                            break;

                        case SetAttributeType.Speed:
                            sessionMovementToUpdate.pivot.speed_value = newValue;
                            break;

                        case SetAttributeType.Acceleration:
                            sessionMovementToUpdate.pivot.acceleration_value = newValue;
                            break;

                        case SetAttributeType.Power:
                            sessionMovementToUpdate.pivot.power_value = newValue;
                            break;

                        case SetAttributeType.RSI:
                            sessionMovementToUpdate.pivot.rsi_value = newValue;
                            break;

                        case SetAttributeType.Force:
                            sessionMovementToUpdate.pivot.force_value = newValue;
                            break;

                        case SetAttributeType.BodySide:
                            sessionMovementToUpdate.pivot.body_side = newValue;
                            break;

                        case SetAttributeType.RPE:
                            sessionMovementToUpdate.pivot.rpe_value = newValue;
                            break;

                        case SetAttributeType.Band:
                            sessionMovementToUpdate.pivot.band_value = newValue;
                            break;
                        case SetAttributeType.RPM:
                            sessionMovementToUpdate.pivot.rpm_value = newValue;
                            break;
                        case SetAttributeType.GPSPlayerLoad:
                            sessionMovementToUpdate.pivot.gps_player_load_value = newValue;
                            break;
                        case SetAttributeType.GPSAccelCount:
                            sessionMovementToUpdate.pivot.gps_accel_count_value = newValue;
                            break;
                        case SetAttributeType.GPSDecelCount:
                            sessionMovementToUpdate.pivot.gps_decel_count_value = newValue;
                            break;

                        default:
                            break;
                    }

                    return oldSession;
                });
            },
            100,
        );

        const reOrderSessionMovements = (updatedMovements: Array<SessionMovement>) => {
            let updatedSession = cloneDeep(session) as Session;

            updatedSession.movements = updatedMovements;

            saveSessionUpdates(updatedSession)
                ?.then(() => {
                    setSession(updatedSession);
                    setSaving(false);
                })
                .catch(() => {
                    somethingWentWrong();
                    setSaving(false);
                });
        };

        // We are going to use useImperativeHandle because the save button is placed on the parent layout component
        // But all the actions happen on the content component (which is the form here)
        // So we are using react's forwardref <> useImperativeHandle pattern
        useImperativeHandle(refs, () => {
            return {
                onSessionSaved: () => {
                    if (!session) {
                        return null;
                    }
                    return saveSessionUpdates(session);
                },
                onSessionShareDrawerOpened: () => {
                    setSessionShareDrawer(true);
                },
                getSession: () => session,
            } as EditSessionRefProps;
        });

        const { push } = useHistory();
        const theme = useTheme();
        return (
            <>
                {session ? (
                    <Container sx={{ marginBottom: theme.spacing(10) }} maxWidth="xl">
                        <SessionSharingDrawer
                            open={sessionShareDrawer}
                            onClose={() => setSessionShareDrawer(false)}
                            sessionUuid={sessionUuid}
                            onUsersLoaded={onUsersLoaded}
                        />

                        {session.organization && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item sx={{ marginTop: 3, marginBottom: 9 }}>
                                    <Avatar
                                        src={session.organization.image_urls['avatar'] ?? ''}
                                        alt={session.organization.name}
                                        variant="rounded"
                                        sx={{
                                            boxShadow:
                                                '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {/* Title */}
                        <Paper key={session?.uuid} elevation={4}>
                            <Grid
                                container
                                spacing={6}
                                justifyContent="space-between"
                                marginBottom={4}
                            >
                                <Grid item xs={12} md={9} sx={{ padding: 4, marginLeft: 4 }}>
                                    <TextField
                                        name="title"
                                        variant="outlined"
                                        label="Title"
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                        fullWidth
                                        defaultValue={session?.title || ''}
                                        onChange={onSessionTitleUpdated}
                                    />
                                </Grid>
                                <Grid item xs={6} md={2} padding={4}>
                                    <TextField
                                        name="duration"
                                        variant="outlined"
                                        label="Time (m)"
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                        fullWidth
                                        type="number"
                                        defaultValue={session?.duration || ''}
                                        onChange={onSessionDurationUpdated}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        {/* Description */}
                        <Paper>
                            <Grid container marginBottom={4}>
                                <Grid item xs={12} md={12} sx={{ padding: 4, marginLeft: 4 }}>
                                    <TextEditorInput
                                        label="Description"
                                        placeholder="Enter description here"
                                        defaultValue={session?.description || ''}
                                        onChange={onSessionDescriptionUpdated}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>

                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item xs={4} sx={{ textAlign: 'center' }}>
                                <Paper sx={{ paddingLeft: 4, marginBottom: 3 }}>
                                    <AttributeInput
                                        addText={
                                            attributeValues.length > 0
                                                ? 'Add Another Tag'
                                                : 'Add Tag'
                                        }
                                        attributes={attributes.filter(
                                            (a) =>
                                                a.organization_uuid === session.organization_uuid,
                                        )}
                                        value={attributeValues}
                                        attributeCategory={AttributeCategory.Session}
                                        onChange={(values) => {
                                            onSessionAttributesUpdated(values);
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={2}>
                                <Can I={'session:update'} this={session}>
                                    <Box
                                        sx={{
                                            paddingTop: 12,
                                            display: 'flex',
                                            justifyContent: 'end',
                                        }}
                                    >
                                        {!session.archived_at && (
                                            <CustomButton
                                                variant={'outlined'}
                                                color="primary"
                                                onClick={async () => {
                                                    try {
                                                        await archiveSession(session.uuid);
                                                        push('/train/sessions');
                                                    } catch (e) {
                                                        console.log(e);
                                                    }
                                                }}
                                                disabled={false}
                                            >
                                                <Lottie
                                                    animationData={Archive}
                                                    loop={false}
                                                    autoplay={true}
                                                    style={{
                                                        width: '30px', // Adjust the width and height as needed
                                                        height: '30px',
                                                    }}
                                                />
                                                Archive
                                            </CustomButton>
                                        )}
                                        {session.archived_at && (
                                            <CustomButton
                                                color="primary"
                                                onClick={async () => {
                                                    try {
                                                        await unarchiveSession(session.uuid);
                                                        push('/train/sessions');
                                                    } catch (e) {
                                                        console.log(e);
                                                    }
                                                }}
                                                disabled={false}
                                            >
                                                Unarchive
                                            </CustomButton>
                                        )}
                                    </Box>
                                </Can>
                            </Grid>
                            <Grid item xs={6}>
                                {session.cover_photo ? (
                                    <Paper sx={{ marginBottom: 3 }}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Avatar
                                                variant="rounded"
                                                src={session.cover_photo}
                                                sx={{
                                                    height: 'auto',
                                                    minWidth: 90,
                                                    padding: theme.spacing(0, 0),
                                                    display: 'inline-flex',
                                                    margin: 3,
                                                }}
                                            ></Avatar>
                                            <Button
                                                sx={{
                                                    padding: 3,
                                                    marginTop: 3,
                                                    marginBottom: 3,
                                                }}
                                                onClick={() =>
                                                    onSessionCoverPhotoUpdated(undefined)
                                                }
                                            >
                                                Remove Cover Photo
                                            </Button>
                                        </Box>
                                    </Paper>
                                ) : (
                                    <>
                                        <Button
                                            variant="outlined"
                                            onClick={() => setOpenImageSelectionDialog(true)}
                                            sx={{ marginLeft: 20, marginTop: 12 }}
                                        >
                                            <Typography variant="caption">
                                                Upload Cover Photo
                                            </Typography>
                                        </Button>
                                        <ChooseMediaDialog
                                            open={openImageSelectionDialog}
                                            fullScreen={isMobileDevice}
                                            onClose={() => setOpenImageSelectionDialog(false)}
                                            onSelected={(media) => {
                                                setOpenImageSelectionDialog(false);
                                                onSessionCoverPhotoUpdated(media);
                                            }}
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Hidden smDown>
                                <Grid item xs={2}>
                                    <Paper
                                        style={{
                                            position: 'sticky',
                                            top: 78,
                                            maxHeight: 'calc(100vh - 90px)',
                                            overflowY: 'auto',
                                            marginRight: 8,
                                        }}
                                    >
                                        <Typography
                                            variant={'subtitle2'}
                                            component="h4"
                                            align={'center'}
                                            style={{ borderBottom: '1px solid #000' }}
                                        >
                                            Movement Summary List
                                        </Typography>
                                        <SessionMovementWithGoalsCondensed
                                            sessionMovements={session.movements || []}
                                        />
                                    </Paper>
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} md={10}>
                                {/* Movements */}
                                <SessionMovementsWithGoals
                                    sessionMovements={session.movements || []}
                                    organizationUuid={session.organization_uuid}
                                    onMovementAdded={onMovementAddedToSession}
                                    onMultipleMovementsAdded={onMultipleMovementsAddedToSession}
                                    onSessionMovementRemoved={removeSessionMovement}
                                    onSessionMovementsRemoved={removeMultipleSessionMovements}
                                    onSessionMovementAdded={addSessionMovement}
                                    onMultipleSessionMovementsAdded={
                                        onMultipleSessionMovementsAddedToSession
                                    }
                                    onCriteriaAddedToSessionMovements={
                                        addCriteriaToSessionMovements
                                    }
                                    onSessionMovementGoalValueChanged={
                                        updateMovementSessionGoalValue
                                    }
                                    onCriteriaRemovedFromSessionMovements={
                                        removeCriteriaFromSessionMovements
                                    }
                                    onSessionMovementUnitChanged={changeSessionMovementUnit}
                                    onSessionMovementsReOrdered={reOrderSessionMovements}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                ) : (
                    <Box pt={20} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
            </>
        );
    },
);

EditSessionForm.displayName = 'EditSessionForm';

export default EditSessionForm;
