import { Avatar, Box, Card, CardActionArea, CardActions, Grow, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import moment from 'moment';
import { useToggle } from '../../utils';
import VideocamIcon from '@mui/icons-material/Videocam';
import useTheme from '@mui/material/styles/useTheme';

interface Props {
    title: string;
    image: string;
    due?: number;
    showOrganizationImage?: boolean;
    organizationImage: string | null;
    organizationName?: string;
    color?: string;
    actions: React.ReactNode;
    showLoggingButtons?: boolean;
}

export default function ListItemCard({
    showLoggingButtons = false,
    title,
    image,
    due = moment().unix(),
    showOrganizationImage = true,
    organizationImage = null,
    organizationName = '',
    color = '#003E9D',
    actions = <></>,
}: Props): ReactElement {
    const theme = useTheme();

    const [isOpen, toggle] = useToggle(false);

    return (
        <Card
            sx={{
                marginTop: 12,
                backgroundColor: theme.palette.backgroundColor.grey,
                borderRadius: 8,
                boxShadow:
                    '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)',
            }}
        >
            <CardActionArea onClick={() => toggle()}>
                <Box display="flex" justifyContent="space-between" style={{ gap: 10 }}>
                    <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
                        <Box
                            px={4}
                            py={6}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            flexGrow={1}
                        >
                            <Box display="flex" alignItems="center" style={{ gap: 10 }}>
                                <Avatar
                                    alt={title}
                                    style={{
                                        height: 50,
                                        width: 'auto',
                                        maxWidth: 100,
                                        minWidth: 50,
                                        objectFit: 'cover',
                                    }}
                                    src={image}
                                    variant={'rounded'}
                                >
                                    <VideocamIcon />
                                </Avatar>
                                <Typography variant="body2" noWrap={false}>
                                    {title}
                                </Typography>
                            </Box>
                            {showOrganizationImage && (
                                <Avatar
                                    alt={organizationName}
                                    style={{ width: 40, height: 40, objectFit: 'cover' }}
                                    src={organizationImage ?? ''}
                                    variant="rounded"
                                />
                            )}
                        </Box>
                        {isOpen && (
                            <Grow in={true}>
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    {showLoggingButtons && actions}
                                </CardActions>
                            </Grow>
                        )}
                    </Box>

                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        bgcolor={color}
                        flexBasis="75px"
                        minWidth="75px"
                    >
                        <span style={{ fontWeight: 600, fontSize: 14 }}>Due</span>
                        <span style={{ fontSize: 16 }}>{moment(due * 1000).format('M/D')}</span>
                    </Box>
                </Box>
            </CardActionArea>
        </Card>
    );
}
