import {
    Block,
    CheckBox,
    CheckBoxOutlineBlank,
    CheckBoxOutlined,
    Close as CloseIcon,
} from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
} from '@mui/material';
import { viewSubmission } from 'api';
import AssetPreviewCard from 'components/Cards/AssetPreviewCard';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Asset, Choice, getHtml, Question, somethingWentWrong, Submission } from 'utils';

interface SubmissionResultModalProps {
    open: boolean;
    onClose: () => void;
    submissionId: string;
}

const SubmissionResultModal = ({ open, onClose, submissionId }: SubmissionResultModalProps) => {
    const [submission, setSubmission] = useState<Submission>();
    const [totalQuestions, setTotalQuestions] = useState<number>(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;
        if (submissionId && open) {
            setLoading(true);
            viewSubmission(submissionId)
                .then((response) => {
                    if (isMounted) {
                        setSubmission(response.data);
                        setTotalQuestions(
                            response.data.form.sections
                                .map((section) => section.questions.length)
                                .reduce((sum, noOfQuestions) => sum + noOfQuestions, 0),
                        );
                    }
                })
                .catch(() => isMounted && somethingWentWrong('Failed to Load Assessment'))
                .finally(() => isMounted && setLoading(false));
        }

        return () => {
            isMounted = false;
        };
    }, [submissionId, open]);

    const findAnswer = (questionUuid: string): string | null => {
        return submission?.values?.find((v) => v.question.uuid == questionUuid)?.text_value || null;
    };

    const questionHasCorrectAnswers = (question: Question): boolean =>
        question?.choices?.some((choice) => choice.is_answer) || false;

    const getChoiceIcon = (hasCorrectAnswers: boolean, choice: Choice, answer: string | null) => {
        if (choice.text === answer && !hasCorrectAnswers) {
            return <CheckBox />;
        }
        if (choice.text === answer && choice.is_answer) {
            return (
                <CheckBox
                    sx={{
                        color: '#4CAF50',
                        '&.MuiCheckbox-checked': {
                            color: '#4CAF50',
                        },
                    }}
                />
            );
        }
        if (choice.text === answer && !choice.is_answer) {
            return (
                <Block
                    sx={{
                        color: 'red',
                        '&.Mui-checked': {
                            color: 'green',
                        },
                    }}
                />
            );
        }
        if (choice.text !== answer && choice.is_answer) {
            return (
                <CheckBoxOutlined
                    sx={{
                        color: '#4CAF50',
                        '&.MuiCheckbox-checked': {
                            color: '#4CAF50',
                        },
                    }}
                />
            );
        }
        return <CheckBoxOutlineBlank />;
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth scroll="paper">
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Assessment Submission Results</Typography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Container maxWidth="md">
                    {loading ? (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 50,
                                minHeight: '200px',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box
                            textAlign="center"
                            display="flex"
                            flexDirection="column"
                            style={{ gap: 6 }}
                            sx={{
                                gap: 6,
                                mt: 3,
                                mb: 3,
                                px: 3,
                            }}
                        >
                            <Box
                                width={'100%'}
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                borderBottom={'1px solid gray'}
                                mb={8}
                            >
                                <Typography variant="h6">
                                    {submission?.form.title || 'Untitled Form'}
                                </Typography>
                                <Typography variant="body2">
                                    {submission?.individual?.name || 'Unknown'}
                                </Typography>
                            </Box>
                            {submission?.score && (
                                <Box
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    {submission.score}% ({submission.score_numerator} /
                                    {submission.score_denominator})
                                </Box>
                            )}
                            {submission?.form?.sections?.length ? (
                                submission?.form?.sections?.map((section, sectionIndex) => {
                                    const descriptionHTML: string = getHtml(
                                        section.description || '',
                                    );

                                    return (
                                        <React.Fragment key={section.uuid}>
                                            <Typography variant="h6">
                                                Section {sectionIndex + 1}
                                            </Typography>
                                            <Box mb={10}>
                                                <Typography
                                                    variant="body2"
                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                >
                                                    {section.name}
                                                </Typography>
                                            </Box>
                                            <Box pt={5} pb={5} textAlign="left">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: descriptionHTML,
                                                    }}
                                                />
                                            </Box>
                                            {!isEmpty(section?.asset) && (
                                                <AssetPreviewCard
                                                    fullwidth={true}
                                                    asset={section.asset as Asset}
                                                    altTitle={section.name}
                                                    onAssetRemoved={() => {}}
                                                    disableRemove={true}
                                                />
                                            )}
                                            {totalQuestions > 0 ? (
                                                section.questions.map((question, questionIndex) => {
                                                    return (
                                                        <Box
                                                            key={question.uuid}
                                                            display="flex"
                                                            flexDirection="column"
                                                            style={{ gap: 20 }}
                                                            textAlign="left"
                                                        >
                                                            <Typography variant="h6">
                                                                Question {questionIndex + 1}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                style={{ whiteSpace: 'pre-wrap' }}
                                                            >
                                                                {question.question}{' '}
                                                            </Typography>
                                                            {question?.choices && (
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                >
                                                                    {question.choices.map(
                                                                        (choice) => {
                                                                            return (
                                                                                <Box
                                                                                    sx={{
                                                                                        display:
                                                                                            'flex',
                                                                                        alignItems:
                                                                                            'center',
                                                                                        '& > :first-of-type':
                                                                                            {
                                                                                                marginRight:
                                                                                                    '10px',
                                                                                            },
                                                                                    }}
                                                                                    key={
                                                                                        choice.uuid
                                                                                    }
                                                                                >
                                                                                    {getChoiceIcon(
                                                                                        questionHasCorrectAnswers(
                                                                                            question,
                                                                                        ),
                                                                                        choice,
                                                                                        findAnswer(
                                                                                            question?.uuid ||
                                                                                                '',
                                                                                        ),
                                                                                    )}
                                                                                    {choice.text}
                                                                                </Box>
                                                                            );
                                                                        },
                                                                    )}
                                                                </Typography>
                                                            )}
                                                            {!isEmpty(question?.asset) && (
                                                                <AssetPreviewCard
                                                                    fullwidth={true}
                                                                    asset={question.asset as Asset}
                                                                    altTitle={question.question}
                                                                    onAssetRemoved={() => {}}
                                                                    disableRemove={true}
                                                                />
                                                            )}
                                                            {question.type === 'text' && (
                                                                <Typography variant="body2">
                                                                    Submitted answer:{' '}
                                                                    {findAnswer(
                                                                        question?.uuid || '',
                                                                    ) || 'No Answer'}
                                                                </Typography>
                                                            )}
                                                            <Divider />
                                                        </Box>
                                                    );
                                                })
                                            ) : (
                                                <Typography variant="body2">
                                                    This assessment does not contain any questions.
                                                </Typography>
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <Typography variant="body2">
                                    This assessment does not contain any sections.
                                </Typography>
                            )}

                            <Box display="flex" justifyContent="center" mt={4}>
                                <Button variant="contained" color="primary" onClick={onClose}>
                                    Close
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Container>
            </DialogContent>
        </Dialog>
    );
};

export default SubmissionResultModal;
