import {
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import InviteStatusChip, { InviteStatusChipStatus } from 'components/Chips/InviteStatusChip';
import React, { ReactElement } from 'react';
import { Invite } from 'utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface Props {
    invite: Invite;
    onResend: (invite: Invite) => void;
    onRevoke: (invite: Invite) => void;
}

export default function InviteRow({ invite, onResend, onRevoke }: Props): ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ListItem key={invite.uuid}>
            <ListItemText
                primary={invite.email}
                secondary={
                    <React.Fragment>
                        <InviteStatusChip
                            status={invite.status as InviteStatusChipStatus}
                            label={invite.status}
                        />
                    </React.Fragment>
                }
            />
            <ListItemSecondaryAction>
                <IconButton onClick={handleClick} edge="end" aria-label="more" size="large">
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            onResend(invite);
                            handleClose();
                        }}
                    >
                        Resend
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            onRevoke(invite);
                            handleClose();
                        }}
                    >
                        Revoke
                    </MenuItem>
                </Menu>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
