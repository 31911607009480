import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
    getInvitesForForm,
    getLinksForForm,
    getRoles,
    getSharedUsersForForm,
    removeSharedUserForForm,
    revokeInviteForForm,
    addUsersToForm,
} from 'api';
import type { Contact, Invite, Link, Role, SharedUser } from 'utils';
import SharingDrawer from './SharingDrawer';

interface Props {
    formId: string;
    open: boolean;
    onClose: () => void;
    onUsersLoaded: (users: Array<SharedUser>) => void;
}

export default function FormSharingDrawer({
    formId,
    open,
    onClose = () => {},
    onUsersLoaded = () => {},
}: Props): ReactElement {
    const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const [sharingInProgress, setSharingInProgress] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [links, setLinks] = useState<Array<Link>>([]);
    const [invites, setInvites] = useState<Array<Invite>>([]);
    const previousFormId = useRef<string>('');

    useEffect(() => {
        if (previousFormId.current !== formId) {
            loadSharedUsers(formId);
            loadLinks(formId);
            loadInvites(formId);
            getRoles('form').then((response) => {
                setRoles(response.data);
            });
        }
        previousFormId.current = formId;
    }, [formId, open]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadSharedUsers = (id: string) => {
        setSharingInProgress(true);
        getSharedUsersForForm(id).then((response) => {
            setSharedUsers(response.data);
            setSharingInProgress(false);
            onUsersLoaded(response.data);
        });
    };

    const loadLinks = (id: string) => {
        getLinksForForm(id).then((response) => {
            setLinks(response.data);
        });
    };

    const loadInvites = (id: string) => {
        getInvitesForForm(id).then((response) => {
            setInvites(response.data);
        });
    };

    const onSharedUserRemoved = (user: SharedUser) => {
        setSharingInProgress(true);
        setSharedUsers((users) => users.filter((u) => u.uuid !== user.uuid));
        onUsersLoaded(sharedUsers.filter((u) => u.uuid !== user.uuid));
        removeSharedUserForForm(formId, user.uuid)
            .then(() => {
                setSharingInProgress(false);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const onShared = (message: string, users: Array<Contact>, role = 'form-manager') => {
        setSharingInProgress(true);
        const usersToAdd: Array<string> = users.map((u) => u.uuid);
        addUsersToForm(formId, usersToAdd, role, message)
            .then((response) => {
                setSharedUsers(response.data);
                setSharingInProgress(false);
                onUsersLoaded(response.data);
                loadInvites(formId);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const handleInviteRevoked = (invite: Invite) => {
        setInvites((invites) => invites.filter((i) => i.uuid !== invite.uuid));
        revokeInviteForForm(formId, invite.uuid)
            .then(() => {})
            .catch(() => {});
    };

    const handleInviteResent = (invite: Invite) => {
        onShared(
            '',
            [{ uuid: invite.email, name: invite.email, relationship: 'invitee', type: 'email' }],
            invite.role.name,
        );
    };

    const handleLinkDeletedClicked = (link: Link) => {
        setLinks((links) => links.filter((l) => l.uuid !== link.uuid));
    };
    const handleLinkCreated = (link: Link) => {
        setLinks((links) => [link, ...links]);
    };

    return (
        <SharingDrawer
            open={open}
            onClose={onClose}
            userAccessList={sharedUsers}
            onRemove={onSharedUserRemoved}
            onShared={onShared}
            processing={sharingInProgress}
            onLinkCreated={handleLinkCreated}
            onLinkDeleteClicked={handleLinkDeletedClicked}
            onLinkDeleteFailed={() => {}}
            onLinkDeleteSucceeded={() => {}}
            onInviteRevoked={handleInviteRevoked}
            onInviteResent={handleInviteResent}
            entityId={formId}
            entityType="form"
            links={links}
            roles={roles}
            invites={invites}
        />
    );
}
