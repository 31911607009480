import AssignmentIcon from '@mui/icons-material/Assignment';
import { Box, Button, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { startNewFormSubmissionForSelf } from '../../../api';
import CountCard from '../../../components/Cards/CountCard';
import { OrganizationFilterList } from '../../../components/Filters';
import { userSelector } from '../../../redux/reducers/user';
import { CountSearchDto, DashboardCounts } from '../../../utils';
import { getAttendance, getCalendarDashboard, skipSession } from '../../train/api/calendar';
import { CardWithItems, ListItemCard } from '../../train/components';
import { Attendance, AttendanceSearchFilters } from '../../train/train.types';

const AssessmentDashboard: React.FC<React.PropsWithChildren<unknown>> = () => {
    const theme = useTheme();
    const screenSizeSmall = useMediaQuery(theme.breakpoints.down('md'));
    const [selectedOrganization, setSelectedOrganization] = useState<string | false>(false);
    const [selectedCategory, setSelectedCategory] = useState<
        'upcoming' | 'overdue' | 'complete' | false
    >(false);
    const [loadKey, setLoadKey] = useState(0);
    const { organizations } = useContext(OrganizationsContext);
    const [attendance, setAttendance] = useState<Attendance[]>([]);
    const [completedAttendance, setCompletedAttendance] = useState<Attendance[]>([]);
    const [counts, setCounts] = useState<DashboardCounts>({ upcoming: 0, complete: 0, overdue: 0 });
    const { userData } = useSelector(userSelector);
    const { push } = useHistory();
    const now = Math.round(+DateTime.now() / 1000);

    const getColor = (a: Attendance): string => {
        if (!a.ended_at && a.event.starts_at_timestamp < now && !a.event.started_at) {
            return '#F44336';
        }

        if (!a.started_at && a.event.starts_at_timestamp > now) {
            return '#4CAF50';
        }

        if (a.started_at) {
            return '#2196F3';
        }

        return '#003E9D';
    };

    const handleSkipped = (attendance: Attendance) => {
        skipSession({ event: attendance.event.uuid, individual: attendance.individual.uuid })
            .then(() => {
                setLoadKey((key) => key + 1);
            })
            .catch(() => {});
    };

    useEffect(() => {
        if (!userData) {
            return;
        }
        let countSearchPayload = {
            'filter[is_assessment]': '1',
            'filter[individual.secondary_owner_uuid]': userData.uuid,
            'filter[not_skipped]': '1',
        } as CountSearchDto;
        if (selectedOrganization) {
            countSearchPayload['filter[event.organization.source_id]'] = selectedOrganization;
        }

        getCalendarDashboard(countSearchPayload)
            .then((results) => setCounts(results.data))
            .catch(() => console.log('error'));
    }, [selectedOrganization, userData, loadKey]);

    useEffect(() => {
        if (!userData) {
            return;
        }
        let searchPayload = {
            'filter[is_assessment]': '1',
            'filter[not_skipped]': '1',
            'filter[individual.secondary_owner_uuid]': userData.uuid,
        } as AttendanceSearchFilters;

        if (!selectedCategory) {
            searchPayload['filter[incomplete]'] = '1';
        }
        if (selectedCategory === 'upcoming') {
            searchPayload['filter[upcoming]'] = '1';
        }
        if (selectedCategory === 'overdue') {
            searchPayload['filter[overdue]'] = '1';
        }
        if (selectedCategory === 'complete') {
            searchPayload['filter[complete]'] = '1';
        }
        if (selectedOrganization) {
            searchPayload['filter[event.organization.source_id]'] = [selectedOrganization];
        }
        getAttendance(searchPayload)
            .then((response) => {
                setAttendance(response.data);
            })
            .catch(() => {});
    }, [selectedCategory, selectedOrganization, userData, loadKey]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!userData) {
            return;
        }
        getAttendance({
            'filter[is_assessment]': '1',
            'filter[individual.secondary_owner_uuid]': userData.uuid,
            'filter[complete]': '1',
        })
            .then((response) => {
                setCompletedAttendance(response.data);
            })
            .catch(() => {});
    }, [userData, loadKey]);

    return (
        <Container style={{ paddingTop: 32, paddingBottom: 64 }}>
            <Box marginBottom={16} display="flex" justifyContent="space-around">
                <CountCard
                    title="UPCOMING"
                    count={counts.upcoming}
                    icon={screenSizeSmall ? undefined : <AssignmentIcon />}
                    color={
                        selectedCategory && selectedCategory !== 'upcoming'
                            ? theme.palette.backgroundColor.quickSilver
                            : '#4CAF50'
                    }
                    onClick={() =>
                        selectedCategory !== 'upcoming'
                            ? setSelectedCategory('upcoming')
                            : setSelectedCategory(false)
                    }
                    cardProps={{
                        style: {
                            flexBasis: '30%',
                        },
                    }}
                />
                <CountCard
                    title="OVERDUE"
                    count={counts.overdue}
                    icon={screenSizeSmall ? undefined : <AssignmentIcon />}
                    color={
                        selectedCategory && selectedCategory !== 'overdue'
                            ? theme.palette.backgroundColor.quickSilver
                            : '#F44336'
                    }
                    onClick={() =>
                        selectedCategory !== 'overdue'
                            ? setSelectedCategory('overdue')
                            : setSelectedCategory(false)
                    }
                    cardProps={{
                        style: {
                            flexBasis: '30%',
                        },
                    }}
                />
                <CountCard
                    title="COMPLETED"
                    count={counts.complete}
                    icon={screenSizeSmall ? undefined : <AssignmentIcon />}
                    color={
                        selectedCategory && selectedCategory !== 'complete'
                            ? theme.palette.backgroundColor.quickSilver
                            : '#2196F3'
                    }
                    onClick={() =>
                        selectedCategory !== 'complete'
                            ? setSelectedCategory('complete')
                            : setSelectedCategory(false)
                    }
                    cardProps={{
                        style: {
                            flexBasis: '30%',
                        },
                    }}
                />
            </Box>
            <Box marginBottom={16} display="flex" alignItems="center" flexWrap="wrap">
                <OrganizationFilterList
                    organizations={organizations}
                    onClicked={(organization) => setSelectedOrganization(organization.uuid)}
                    selectedOrganization={selectedOrganization}
                />
            </Box>
            <Grid container>
                <Grid sx={{ padding: 8 }} item xs={12} md={!selectedCategory ? 8 : 12}>
                    <CardWithItems title={'Assigned Assessments'}>
                        {attendance.length === 0 && (
                            <Typography variant={'body1'} style={{ marginTop: 8 }}>
                                No assigned assessments
                            </Typography>
                        )}
                        {attendance.map((attendance) => (
                            <ListItemCard
                                showLoggingButtons={true}
                                image={attendance.event.form_photo ?? ''}
                                due={attendance.event.starts_at_timestamp}
                                title={attendance.event.form_name}
                                key={attendance.event.uuid}
                                showOrganizationImage={true}
                                organizationImage={attendance.event.organization_photo ?? ''}
                                organizationName={attendance.event.organization_name}
                                color={getColor(attendance)}
                                actions={
                                    <>
                                        {attendance.event.form_id && (
                                            <>
                                                <Button
                                                    onMouseDown={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                    onClick={(e) => {
                                                        handleSkipped(attendance);
                                                        e.stopPropagation();
                                                    }}
                                                    size="small"
                                                >
                                                    Skip
                                                </Button>
                                                <Button
                                                    onMouseDown={(mouseEvent) => {
                                                        mouseEvent.stopPropagation();
                                                    }}
                                                    onClick={(mouseEvent) => {
                                                        push(
                                                            `/class/assessments/preview/${attendance.event.form_id}`,
                                                        );
                                                        mouseEvent.stopPropagation();
                                                    }}
                                                    size="small"
                                                    color="primary"
                                                >
                                                    Preview
                                                </Button>
                                                <Button
                                                    onMouseDown={(mouseEvent) => {
                                                        mouseEvent.stopPropagation();
                                                    }}
                                                    onClick={(mouseEvent) => {
                                                        if (!attendance.event.form_id) {
                                                            return;
                                                        }
                                                        startNewFormSubmissionForSelf(
                                                            attendance.event.form_id,
                                                            {
                                                                date: DateTime.now(),
                                                                event: attendance.event.uuid,
                                                            },
                                                        )
                                                            .then((response) => {
                                                                push(
                                                                    `/class/submissions/${response.data.uuid}`,
                                                                );
                                                            })
                                                            .catch(() => {});

                                                        mouseEvent.stopPropagation();
                                                    }}
                                                    size="small"
                                                    color="primary"
                                                >
                                                    Begin
                                                </Button>
                                            </>
                                        )}
                                    </>
                                }
                            />
                        ))}
                    </CardWithItems>
                </Grid>
                {!selectedCategory && (
                    <Grid sx={{ padding: 8 }} item xs={12} md={4}>
                        <CardWithItems title={'Completed Assessments'}>
                            {completedAttendance.length === 0 && (
                                <Typography variant={'body1'} style={{ marginTop: 8 }}>
                                    No completed assessments
                                </Typography>
                            )}
                            {completedAttendance.map((e) => (
                                <ListItemCard
                                    showLoggingButtons={false}
                                    image={e.event.form_photo ?? ''}
                                    due={e.event.starts_at_timestamp}
                                    title={e.event.form_name}
                                    key={e.event.uuid}
                                    showOrganizationImage={false}
                                    organizationImage={e.event.organization_photo ?? ''}
                                    organizationName={e.event.organization_name}
                                    color={getColor(e)}
                                    actions={<></>}
                                />
                            ))}
                        </CardWithItems>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default AssessmentDashboard;
