import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Organization } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Organization>>>;

const getAllOrganizations = (): ReturnType => {
    return Request.get('/organizations');
};

export default getAllOrganizations;
