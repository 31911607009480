import { Box, LinearProgress } from '@mui/material';
import { checkPasswordStrength } from 'utils';
import React from 'react';
import useTheme from '@mui/material/styles/useTheme';

interface PasswordStrenghIndicatorProps {
    password: string;
}

const PasswordStrenghIndicator = ({ password }: PasswordStrenghIndicatorProps) => {
    const { color, strength, text } = checkPasswordStrength(password);
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%', marginBottom: theme.spacing(8) }}>
            <LinearProgress
                sx={{
                    height: 10,
                    width: '100%',
                    borderRadius: 4,
                    marginBottom: 4,
                    bgcolor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
                    '& .MuiLinearProgress-bar': {
                        borderRadius: 5,
                        backgroundColor: color,
                    },
                }}
                value={strength}
                variant="determinate"
            />
            <span style={{ fontSize: 15, fontFamily: 'Roboto' }}>
                {'Password Strength: '}
                <span style={{ fontWeight: 'bold' }}>{text}</span>
            </span>
        </Box>
    );
};

export default PasswordStrenghIndicator;
