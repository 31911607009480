import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import {
    Avatar,
    Box,
    Button,
    Container,
    Drawer,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    LinearProgress,
    ListItemIcon,
    MenuItem,
    Paper,
    Select,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import { orderBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { getAllMedia, uploadMedia } from '../../../api/media.api';
import { Media } from '../../../app.types';
import { TypographyTitleh6 } from '../../../components';
import ImageUploadDialog from '../../../components/Dialogs/ImageUploadDialog';
import { OrganizationFilterList } from '../../../components/Filters';
import { getAssociatedOrganizations } from '../../../redux/reducers/user';
import { MediaType, Meta, PaginationLink, UploadedMedia } from '../../../utils';
import ViewEditMedia from '../components/ViewEditMedia';
import ViewMediaImageOrVideo from '../components/ViewMediaImageOrVideo';
import { deleteMedia, updateMedia } from '../media.api';

function mapMediaCategoryToHTTPFilter(category: string) {
    if (category === 'form') {
        return 'form,section,choice,question';
    }
    return category;
}

function MediaLibraryDashboard(): React.ReactElement {
    const [media, setMedia] = useState<Media[]>([]);
    const [meta, setMeta] = useState<Meta | undefined>();
    const [links, setLinks] = useState<PaginationLink | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);
    const [creating, setCreating] = useState<boolean>(false);
    const [showUpload, setShowUpload] = useState<boolean>(false);
    const [mediaToUpload, setMediaToUpload] = useState<UploadedMedia | null>(null);
    const [uploadingPromptOpen, setUploadingPromptOpen] = useState<boolean>(false);
    const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();
    const [category, setCategory] = useState<string>('all');
    const [searchValue, setSearchValue] = useState<string>('');
    const [debouncedSearchValue] = useDebounce(searchValue, 300);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { organizations } = useContext(OrganizationsContext);
    const [selectedOrganization, setSelectedOrganization] = useState<string | false>(false);
    const teams = useSelector(getAssociatedOrganizations);
    const [chosenOrganization, setChosenOrganization] = React.useState('');

    useEffect(() => {
        let payload = { page: 1 } as any;
        if (category !== 'all')
            payload['filter[category]'] = mapMediaCategoryToHTTPFilter(category);
        if (debouncedSearchValue) payload['filter[search]'] = debouncedSearchValue;
        if (selectedOrganization) payload['filter[organization_uuid]'] = selectedOrganization;
        loadMedia(payload);
    }, [debouncedSearchValue, category, selectedOrganization]);

    function loadMedia(query: any) {
        setLoading(true);
        getAllMedia(query)
            .then((response) => {
                setMedia(response.data.data);
                setMeta(response.data.meta);
                setLinks(response.data.links);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    return (
        <>
            {loading && <LinearProgress variant={'indeterminate'} />}
            <Container maxWidth={'xl'}>
                <Box display="flex" flexDirection="column">
                    <Box display="flex">
                        <Select
                            value={category}
                            onChange={(e) => setCategory(e.target.value as string)}
                        >
                            <MenuItem value={'all'}>All Categories</MenuItem>
                            <MenuItem value={'group'}>Groups</MenuItem>
                            <MenuItem value={'movement'}>Movements</MenuItem>
                            <MenuItem value={'session'}>Sessions</MenuItem>
                            <MenuItem value={'individual'}>Athletes</MenuItem>
                            <MenuItem value={'form'}>Assessments</MenuItem>
                            <MenuItem value={'user'}>Profile Photos</MenuItem>
                            <MenuItem value={'other'}>Uploaded By Me</MenuItem>
                        </Select>
                        <Input
                            onChange={(e) => setSearchValue(e.target.value as string)}
                            value={searchValue}
                            startAdornment={<SearchIcon />}
                            placeholder={'Search For Images...'}
                            sx={{
                                flexGrow: 1,
                                borderRadius: 14,
                                padding: 2,
                                margin: '4px 0 4px 2px',
                                backgroundColor: 'white',
                                color: 'text.secondary',
                                '&&&:before': {
                                    borderBottom: 'none',
                                },
                                '&&:after': {
                                    borderBottom: 'none',
                                },
                                '&::placeholder': {
                                    color: 'primary.main',
                                    marginLeft: 6,
                                },
                                '@media (max-width:900px)': {
                                    minWidth: 250,
                                },
                                '@media (max-width:600px)': {
                                    borderRadius: '0px',
                                    margin: 0,
                                },
                            }}
                        />
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <OrganizationFilterList
                            organizations={organizations}
                            onClicked={(organization) => setSelectedOrganization(organization.uuid)}
                            selectedOrganization={selectedOrganization}
                        />
                        <Button
                            onClick={() => setCreating(true)}
                            variant={'contained'}
                            color={'primary'}
                        >
                            Upload New
                            <AddIcon />
                        </Button>
                    </Box>
                    {media.map((m) => (
                        <Paper
                            key={m.uuid}
                            component={'a'}
                            style={{ cursor: 'pointer', margin: 8 }}
                            elevation={3}
                            onClick={() => setSelectedMedia(m)}
                        >
                            <Box
                                display="flex"
                                justifyContent={'space-between'}
                                alignItems={'center'}
                            >
                                <Box display={'flex'}>
                                    <ViewMediaImageOrVideo
                                        media={m}
                                        style={{ width: 100, objectFit: 'contain' }}
                                    />
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent={'center'}
                                        padding={8}
                                    >
                                        <TypographyTitleh6>{m.name}</TypographyTitleh6>
                                    </Box>
                                </Box>
                                <Avatar
                                    style={{ margin: 4 }}
                                    src={m.organization.image_urls['avatar'] ?? ''}
                                    alt={m.organization.name}
                                />
                            </Box>
                        </Paper>
                    ))}
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Button
                        onClick={() => loadMedia({ page: (meta?.current_page ?? 1) - 1 })}
                        disabled={!links?.prev || loading}
                    >
                        Previous
                    </Button>
                    <Button
                        onClick={() => loadMedia({ page: (meta?.current_page ?? 1) + 1 })}
                        disabled={!links?.next || loading}
                    >
                        Next
                    </Button>
                </Box>
                <Drawer
                    PaperProps={{ style: { width: isMobile ? '100%' : '60%' } }}
                    anchor={'right'}
                    open={creating}
                    onBackdropClick={() => setCreating(false)}
                >
                    <Box display="flex" flexDirection="column">
                        <Box
                            display="flex"
                            alignItems={'center'}
                            justifyContent="space-between"
                            style={{ borderBottom: '1px solid grey', padding: 20 }}
                        >
                            <Box>
                                <TypographyTitleh6
                                    style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Upload New Media
                                </TypographyTitleh6>
                            </Box>
                            <IconButton
                                onClick={() => setCreating(false)}
                                aria-label="close"
                                size="medium"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'flex-start'}
                            p={8}
                            borderBottom={'1px solid grey'}
                        >
                            <FormControl
                                variant="filled"
                                style={{ marginBottom: 8, minWidth: 240 }}
                            >
                                <InputLabel>Choose An Affiliation</InputLabel>
                                <Select
                                    value={chosenOrganization}
                                    onChange={(event) =>
                                        setChosenOrganization(event.target.value as string)
                                    }
                                    id="demo-simple-select"
                                    name="organization"
                                >
                                    {orderBy(teams, ['name'], ['asc'])?.map((organization) => {
                                        return (
                                            <MenuItem
                                                key={organization.name}
                                                value={organization.name}
                                            >
                                                <ListItemIcon>
                                                    <Avatar
                                                        src={
                                                            organizations.find(
                                                                (o) => o.uuid === organization.name,
                                                            )?.image_urls.avatar ?? ''
                                                        }
                                                    />
                                                </ListItemIcon>
                                                {organization.display_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <Button
                                color={'primary'}
                                variant={'outlined'}
                                onClick={() => setShowUpload(true)}
                            >
                                <CloudUploadIcon />
                                {!mediaToUpload && <>Choose File</>}
                                {mediaToUpload && <>{mediaToUpload.originalName}</>}
                            </Button>
                        </Box>
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            width={'100%'}
                            p={8}
                        >
                            <Button variant={'outlined'} onClick={() => setCreating(false)}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    if (!mediaToUpload) {
                                        return;
                                    }
                                    setCreating(false);
                                    setUploadingPromptOpen(true);
                                    uploadMedia({
                                        path: mediaToUpload.tmpFileUrl,
                                        name: mediaToUpload.originalName,
                                        mime_type: mediaToUpload.type,
                                        size: mediaToUpload.uploadedFile.size,
                                        organization: chosenOrganization,
                                    })
                                        .then((response) => {
                                            setMediaToUpload(null);
                                            setMedia([response.data, ...media]);
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                        });
                                }}
                                variant={'contained'}
                                color={'primary'}
                                type={'submit'}
                            >
                                Upload
                            </Button>
                        </Box>
                    </Box>
                    <ImageUploadDialog
                        buttonText={'Choose Media'}
                        open={showUpload}
                        onClose={() => setShowUpload(false)}
                        onMediaSelection={(files) => {
                            setMediaToUpload(files[0]);
                        }}
                        allow={MediaType.imageOrVideo}
                    />
                </Drawer>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={uploadingPromptOpen}
                    onClose={() => setUploadingPromptOpen(false)}
                    autoHideDuration={2000}
                >
                    <SnackbarContent
                        message={'Uploading in background...'}
                        action={
                            <Button
                                color={'secondary'}
                                onClick={() => setUploadingPromptOpen(false)}
                            >
                                Close
                            </Button>
                        }
                    />
                </Snackbar>
                <Drawer
                    PaperProps={{ style: { width: isMobile ? '100%' : '60%' } }}
                    anchor={'right'}
                    open={!!selectedMedia}
                    onBackdropClick={() => setSelectedMedia(undefined)}
                >
                    {selectedMedia && (
                        <Box display="flex" flexDirection="column">
                            <ViewEditMedia
                                media={selectedMedia}
                                onDelete={(deletedMedia) => {
                                    setUpdating(true);
                                    deleteMedia(deletedMedia.uuid)
                                        .then(() => {
                                            setUpdating(false);
                                            setSelectedMedia(undefined);
                                            setMedia((media) => {
                                                const ix = media.findIndex(
                                                    (m) => m.uuid === deletedMedia.uuid,
                                                );

                                                if (ix > -1) {
                                                    media.splice(ix, 1);
                                                }

                                                return [...media];
                                            });
                                        })
                                        .catch(() => setUpdating(false));
                                }}
                                onUpdated={(m, payload) => {
                                    setUpdating(true);
                                    updateMedia(m.uuid, payload)
                                        .then((response) => {
                                            setUpdating(false);
                                            setSelectedMedia(undefined);
                                            setMedia((media) => {
                                                const ix = media.findIndex(
                                                    (m) => m.uuid === response.data.uuid,
                                                );

                                                if (ix > -1) {
                                                    media.splice(ix, 1, response.data);
                                                }

                                                return [...media];
                                            });
                                        })
                                        .catch(() => setUpdating(false));
                                }}
                                updating={updating}
                                onClose={() => setSelectedMedia(undefined)}
                            />
                        </Box>
                    )}
                </Drawer>
            </Container>
        </>
    );
}

export default MediaLibraryDashboard;
