import { ArrowBack, GetApp, Refresh } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Avatar,
    Box,
    Checkbox,
    Chip,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    Link,
    Menu,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip as TooltipMui,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { keyframes, styled } from '@mui/system';
import { drop, sum } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link as ReactRouterDomLink, useHistory, useParams } from 'react-router-dom';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { getTags } from '../../../api/tags.api';
import { Tag } from '../../../app.types';
import { Can, confirmViaDialog, SelectDateChip } from '../../../components';
import { AbilityContext } from '../../../components/Functional/Can';
import SlimSideDrawerWithTitleAndActions from '../../../components/Layouts/SlimSideDrawerWithTitleAndActions';
import { pushMessage } from '../../../redux/reducers/messages';
import { useAppDispatch } from '../../../redux/store';
import ROUTES from '../../../Routes/routes';
import {
    deleteSprint,
    deleteUpload,
    getUpload,
    saveSprint,
    saveUpload,
} from '../api/performance.api';
import { mapTagIntoFreeSoloOption } from '../community.func';
import { Motion, PerformanceDataUpload, Sprint } from '../community.types';
import FreeSoloCreateOptionDialog, {
    FreeSoloOption,
} from '../components/FreeSoloCreateOptionDialog';
import RawDataViewer from '../components/RawDataViewer';
import ForceVelocityProfileSprint from './ForceVelocityProfileSprint';
import ViewTemMotion from './ViewTemMotion';

function ForceVelocityProfile() {
    const isMobileDevice = useMediaQuery(useTheme().breakpoints.down('md'));

    const [snackMessage, setSnackMessage] = useState<string>('');
    const [selectedSprint, setSelectedSprint] = useState<Sprint>();
    const [selectedMotion, setSelectedMotion] = useState<Motion>();
    const dispatch = useAppDispatch();
    const [selectedSprintTag, setSelectedSprintTag] = useState<FreeSoloOption | null>(null);
    const [upload, setUpload] = useState<PerformanceDataUpload>();
    const { goBack, push } = useHistory();
    const { individualId, uploadId, sprintId, motionId } = useParams<{
        individualId: string;
        uploadId: string;
        sprintId: string;
        motionId: string;
    }>();
    const [deleting, setDeleting] = useState<boolean>(false);
    const [hideFVP, setHideFVP] = useState<boolean>(true);
    const [sideBySide, setSideBySide] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [editInProcess, setEditInProcess] = useState<boolean>(false);
    const [tags, setTags] = useState<Tag[]>([]);
    const [editForm, setEditForm] = useState<{
        tag: FreeSoloOption | null;
        date: string | null;
        file_name: string | null;
    }>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [distanceUnit, setDistanceUnit] = React.useState<'yd' | 'ft' | 'm'>('yd');
    const [distanceValue, setDistanceValue] = useState<number>(() => {
        if (upload && upload.summary_distance) {
            return upload.summary_distance * 0.00062137;
        }
        return 0;
    });
    const convertedDistanceRef = useRef<number>(
        upload?.summary_distance ? upload.summary_distance * 0.00062137 : 0,
    );
    const [maxAccelerationValue, setMaxAccelerationValue] = useState<number | null>(null);
    const [selectedMaxAccelerationUnit, setSelectedMaxAccelerationUnit] = useState<
        'ft/s²' | 'm/s²' | 'g'
    >('ft/s²');

    const [maxDecelerationValue, setMaxDecelerationValue] = useState<number | null>(null);
    const [selectedMaxDecelerationUnit, setSelectedMaxDecelerationUnit] = useState<
        'ft/s²' | 'm/s²' | 'g'
    >('ft/s²');

    const ability = useContext(AbilityContext);

    const fadeInOut = keyframes`
        0% {
            opacity: 0.5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.5;
        }
    `;

    const AnimatedTypography = styled(Typography)(() => ({
        animation: `${fadeInOut} 2s infinite`,
    }));

    useEffect(() => {
        if (upload && upload.summary_max_acceleration != null) {
            let convertedMaxAcceleration: number;

            switch (selectedMaxAccelerationUnit) {
                case 'm/s²':
                    convertedMaxAcceleration = upload.summary_max_acceleration * 1;
                    break;
                case 'ft/s²':
                    convertedMaxAcceleration = upload.summary_max_acceleration * 3.28084;
                    break;
                case 'g':
                    convertedMaxAcceleration = upload.summary_max_acceleration / 9.80665;
                    break;
                default:
                    convertedMaxAcceleration = upload.summary_max_acceleration * 3.28084;
            }

            setMaxAccelerationValue(convertedMaxAcceleration);
        } else {
            setMaxAccelerationValue(null);
        }
    }, [upload, selectedMaxAccelerationUnit]);

    useEffect(() => {
        if (upload && upload.summary_max_deceleration != null) {
            let convertedMaxDeceleration: number;

            switch (selectedMaxDecelerationUnit) {
                case 'm/s²':
                    convertedMaxDeceleration = upload.summary_max_deceleration * 1;
                    break;
                case 'ft/s²':
                    convertedMaxDeceleration = upload.summary_max_deceleration * 3.28084;
                    break;
                case 'g':
                    convertedMaxDeceleration = upload.summary_max_deceleration / 9.80665;
                    break;
                default:
                    convertedMaxDeceleration = upload.summary_max_deceleration * 3.28084;
            }

            setMaxDecelerationValue(convertedMaxDeceleration);
        } else {
            setMaxDecelerationValue(null);
        }
    }, [upload, selectedMaxDecelerationUnit]);

    const handleMaxAccelerationUnitChange = (event: SelectChangeEvent<'ft/s²' | 'm/s²' | 'g'>) => {
        setSelectedMaxAccelerationUnit(event.target.value as 'ft/s²' | 'm/s²' | 'g');
    };

    const handleMaxDecelerationUnitChange = (event: SelectChangeEvent<'ft/s²' | 'm/s²' | 'g'>) => {
        setSelectedMaxDecelerationUnit(event.target.value as 'ft/s²' | 'm/s²' | 'g');
    };

    useEffect(() => {
        setDistanceValue(convertedDistanceRef.current);
    }, []);
    const [selectedUnit, setSelectedUnit] = useState<'mi' | 'km' | 'm' | 'yd'>('mi');

    useEffect(() => {
        if (upload && upload.summary_distance) {
            let convertedDistance = 0;

            if (selectedUnit === 'km') {
                convertedDistance = upload.summary_distance * 0.001;
            } else if (selectedUnit === 'm') {
                convertedDistance = upload.summary_distance;
            } else if (selectedUnit === 'yd') {
                convertedDistance = upload.summary_distance * 1.09361;
            } else {
                convertedDistance = upload.summary_distance * 0.00062137;
            }

            setDistanceValue(convertedDistance);
        }
    }, [upload, selectedUnit]);

    const handleDistanceUnitChange = useCallback(
        (event: SelectChangeEvent<{ value: 'mi' | 'km' | 'm' | 'yd' }>) => {
            const newDistanceUnit: 'mi' | 'km' | 'm' | 'yd' = event.target.value as
                | 'mi'
                | 'km'
                | 'm'
                | 'yd';

            if (upload && upload.summary_distance) {
                let convertedDistance = 0;

                if (newDistanceUnit === 'km') {
                    convertedDistance = upload.summary_distance * 0.001;
                } else if (newDistanceUnit === 'm') {
                    convertedDistance = upload.summary_distance;
                } else if (newDistanceUnit === 'yd') {
                    convertedDistance = upload.summary_distance * 1.09361;
                } else {
                    convertedDistance = upload.summary_distance * 0.00062137;
                }

                convertedDistanceRef.current = convertedDistance;

                setDistanceValue(convertedDistance);
                setSelectedUnit(newDistanceUnit);
            }
        },
        [upload],
    );
    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newDistanceUnit: 'yd' | 'ft' | 'm',
    ) => {
        setDistanceUnit(newDistanceUnit);
    };
    /*Max Speed Unit of Measure*/

    const [maxSpeedValue, setMaxSpeedValue] = useState<number>(() => {
        if (upload && upload.summary_max_velocity) {
            const velocity = Number(upload.summary_max_velocity);
            return isNaN(velocity) ? 0 : Number((velocity * 2.23694).toFixed(3));
        }
        return 0;
    });

    const convertedMaxSpeedRef = useRef<number>(
        upload?.summary_max_velocity ? Number(upload.summary_max_velocity) * 2.23694 : 0,
    );

    useEffect(() => {
        setMaxSpeedValue(convertedMaxSpeedRef.current);
    }, []);

    const [selectedMaxSpeedUnit, setSelectedMaxSpeedUnit] = useState<
        'mph' | 'm/s' | 'km/h' | 'yd/s'
    >('mph');

    useEffect(() => {
        if (upload && upload.summary_max_velocity) {
            let convertedMaxSpeedRef = 0;

            if (selectedMaxSpeedUnit === 'm/s') {
                convertedMaxSpeedRef = Number(upload.summary_max_velocity);
            } else if (selectedMaxSpeedUnit === 'km/h') {
                convertedMaxSpeedRef = Number(upload.summary_max_velocity) * 3.6;
            } else if (selectedMaxSpeedUnit === 'yd/s') {
                convertedMaxSpeedRef = Number(upload.summary_max_velocity) * 1.09361;
            } else {
                convertedMaxSpeedRef = Number(upload.summary_max_velocity) * 2.23694;
            }

            setMaxSpeedValue(convertedMaxSpeedRef);
        }
    }, [upload, selectedMaxSpeedUnit]);

    const handleMaxSpeedUnitChange = useCallback(
        (event: SelectChangeEvent<{ value: 'mph' | 'm/s' | 'km/h' | 'yd/s' }>) => {
            const newMaxSpeedUnit: 'mph' | 'm/s' | 'km/h' | 'yd/s' = event.target.value as
                | 'mph'
                | 'm/s'
                | 'km/h'
                | 'yd/s';

            if (upload && upload.summary_max_velocity) {
                let convertedMaxSpeed = 0;

                if (newMaxSpeedUnit === 'm/s') {
                    convertedMaxSpeed = Number(upload.summary_max_velocity);
                } else if (newMaxSpeedUnit === 'km/h') {
                    convertedMaxSpeed = Number(upload.summary_max_velocity * 3.6);
                } else if (newMaxSpeedUnit === 'yd/s') {
                    convertedMaxSpeed = Number(upload.summary_max_velocity * 1.09361);
                } else {
                    convertedMaxSpeed = Number(upload.summary_max_velocity * 2.23694);
                }

                convertedMaxSpeedRef.current = convertedMaxSpeed;

                setMaxSpeedValue(convertedMaxSpeed);
                setSelectedMaxSpeedUnit(newMaxSpeedUnit);
            }
        },
        [upload],
    );

    function handleDelete() {
        confirmViaDialog({
            confirmation: {
                title: 'Are you sure?',
                message:
                    'This will permanently delete this entire session and all associated data.',
            },
        })
            .then((confirmed) => {
                if (confirmed) {
                    // User confirmed the action
                    setDeleting(true);
                    deleteUpload(individualId, upload?.uuid ?? '')
                        .then(() => {
                            setDeleting(false);
                            history.back();
                        })
                        .catch(() => {
                            setDeleting(false);
                        });
                }
                // If not confirmed, do nothing
            })
            .catch((error) => {
                // Handle any errors from the confirmation dialog here
                console.error(error);
            });
    }

    const handleSprintDeleted = useCallback(() => {
        confirmViaDialog({
            confirmation: {
                title: 'Are you sure?',
                message: 'This will permanently delete only this sprint and all associated data.',
            },
        })
            .then((confirmed) => {
                if (confirmed) {
                    deleteSprint(individualId, upload?.uuid ?? '', selectedSprint?.uuid ?? '')
                        .then(() => {
                            push('../');
                        })
                        .catch(() => {
                            console.log('sprint not deleted');
                        })
                        .finally(() => {
                            console.log('sprint delete finally');
                        });
                }
            })
            .catch((error) => {
                // Handle any errors from the confirmation dialog here
                console.error(error);
            });
    }, [selectedSprint, individualId, upload, push]);

    useEffect(() => {
        if (upload) {
            setEditForm(() => ({
                file_name: upload.file_name,
                tag: upload.tag ? mapTagIntoFreeSoloOption(upload.tag) : null,
                date: upload?.activity_date ?? null,
            }));
        }
    }, [upload]);

    useEffect(() => {
        if (!upload || (upload && upload.uuid !== uploadId)) {
            getUpload(individualId, uploadId)
                .then((response) => {
                    setUpload(response.data);
                })
                .catch(() => {});
        }
    }, [individualId, uploadId, sprintId, upload, push]);

    useEffect(() => {
        getTags({ 'filter[type]': 'uploads,sprints' })
            .then((response) => {
                setTags(response.data);
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        if (!upload) {
            return;
        }

        const s = upload.sprints.find((sprint) => sprint.uuid === sprintId);

        setSelectedSprint(s);
    }, [sprintId, upload]);

    useEffect(() => {
        if (selectedSprint) {
            setSelectedSprintTag(
                selectedSprint.tag ? mapTagIntoFreeSoloOption(selectedSprint.tag) : null,
            );
        }
    }, [selectedSprint]);

    useEffect(() => {
        if (!upload) {
            return;
        }
        setSelectedMotion(upload.motions.find((motion) => motion.tem_motion_guid === motionId));
    }, [motionId, upload]);

    const refreshBrowser = () => {
        window.location.reload();
    };

    return (
        <Container maxWidth={'xl'}>
            <Snackbar
                open={snackMessage.length > 0}
                autoHideDuration={3000}
                onClose={() => setSnackMessage('')}
            >
                <SnackbarContent message={snackMessage} />
            </Snackbar>
            {upload && (
                <Paper elevation={0}>
                    <Box mt={4} sx={{ backgroundColor: '#F5F5F5' }}>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems={'center'}
                            p={8}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 4 }}>
                                    <IconButton
                                        onClick={() => goBack()}
                                        color="primary"
                                        aria-label="back"
                                        component="span"
                                        size="large"
                                    >
                                        <ArrowBack />
                                    </IconButton>
                                    <Avatar
                                        src={
                                            upload?.individual.organization.image_urls.avatar ?? ''
                                        }
                                        variant="rounded"
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant={'h6'}>
                                        {upload?.individual.organization.name}
                                    </Typography>
                                    <Typography variant={'body1'}>{upload?.file_name}</Typography>
                                </Box>
                            </Box>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Typography variant={'h5'}>
                                        {moment(upload.activity_date).format('MMMM DD, YYYY')}
                                    </Typography>
                                    {upload.tag && <Chip label={upload.tag.name} />}
                                </Box>

                                <>
                                    <IconButton
                                        id="demo-positioned-button"
                                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                                        aria-haspopup="true"
                                        size={'large'}
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                    >
                                        <SettingsIcon fontSize={'large'} />
                                    </IconButton>
                                    <Menu
                                        id="demo-positioned-menu"
                                        aria-labelledby="demo-positioned-button"
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Can I={'individual:manage-logs'} this={upload.individual}>
                                            <MenuItem onClick={() => setEditing(true)}>
                                                <EditIcon /> Edit
                                            </MenuItem>

                                            <MenuItem
                                                onClick={() => {
                                                    handleDelete();
                                                }}
                                                disabled={deleting}
                                            >
                                                <DeleteIcon /> Delete Session
                                            </MenuItem>
                                            {selectedSprint && (
                                                <MenuItem
                                                    onClick={() => {
                                                        handleSprintDeleted();
                                                    }}
                                                >
                                                    <DeleteIcon /> Delete Sprint
                                                </MenuItem>
                                            )}
                                        </Can>

                                        <MenuItem>
                                            {/* eslint-disable-next-line react/jsx-no-undef */}
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={distanceUnit}
                                                exclusive
                                                onChange={handleChange}
                                                aria-label="Platform"
                                            >
                                                <ToggleButton value="yd">Yd</ToggleButton>
                                                <ToggleButton value="ft">Ft</ToggleButton>
                                                <ToggleButton value="m">M</ToggleButton>
                                            </ToggleButtonGroup>
                                        </MenuItem>
                                        <MenuItem>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={hideFVP}
                                                        onChange={(e) =>
                                                            setHideFVP(e.target.checked as boolean)
                                                        }
                                                        name="hideFVP"
                                                        color="primary"
                                                    />
                                                }
                                                label="Hide FVP Curve"
                                            />
                                        </MenuItem>
                                        <MenuItem>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={sideBySide}
                                                        onChange={(e) =>
                                                            setSideBySide(
                                                                e.target.checked as boolean,
                                                            )
                                                        }
                                                        name="sideBySide"
                                                        color="primary"
                                                    />
                                                }
                                                label="Accel-Decel Side by Side"
                                            />
                                        </MenuItem>
                                        {!upload.error && (
                                            <MenuItem>
                                                {sprintId && (
                                                    <TooltipMui title="Download Raw Sprint Data">
                                                        <IconButton
                                                            color="primary"
                                                            aria-label="download raw sprint data"
                                                            component="a"
                                                            target="_blank"
                                                            href={selectedSprint?.csv_url}
                                                            download
                                                            size="large"
                                                        >
                                                            <GetApp />
                                                        </IconButton>
                                                    </TooltipMui>
                                                )}
                                                Download Sprint Data
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </>
                            </Box>
                        </Box>
                        <SlimSideDrawerWithTitleAndActions
                            loading={editInProcess}
                            disabled={editInProcess}
                            onClose={() => setEditing(false)}
                            onSubmit={() => {
                                setEditInProcess(true);

                                saveUpload(individualId, uploadId, {
                                    date: editForm?.date ?? null,
                                    tag: editForm?.tag?.id ?? null,
                                    file_name: editForm?.file_name ?? null,
                                })
                                    .then((response) => {
                                        setUpload(response.data);
                                        setEditing(false);
                                    })
                                    .catch(() => {
                                        dispatch(
                                            pushMessage({
                                                status: 'error',
                                                message:
                                                    'Error while saving. Try again or contact customer support.',
                                            }),
                                        );
                                    })
                                    .finally(() => {
                                        setEditInProcess(false);
                                    });
                            }}
                            open={editing}
                            body={
                                <>
                                    {editForm && (
                                        <>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginBottom: 4,
                                                }}
                                            >
                                                <Typography>File Name / Title</Typography>
                                                <TextField
                                                    onChange={(e: any) =>
                                                        setEditForm((form) => ({
                                                            file_name: e.target.value ?? '',
                                                            date: form?.date ?? null,
                                                            tag: form?.tag ?? null,
                                                        }))
                                                    }
                                                    type={'text'}
                                                    value={editForm.file_name}
                                                    variant="outlined"
                                                    sx={{ maxWidth: 700, minWidth: 325 }}
                                                />
                                            </Box>

                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginBottom: 4,
                                                }}
                                            >
                                                <Typography>Date</Typography>
                                                <SelectDateChip
                                                    onChange={(value) =>
                                                        setEditForm((form) => ({
                                                            file_name: form?.file_name ?? null,
                                                            date: value ? value.toISODate() : null,
                                                            tag: form?.tag ?? null,
                                                        }))
                                                    }
                                                    value={
                                                        editForm.date
                                                            ? DateTime.fromFormat(
                                                                  editForm.date,
                                                                  'yyyy-MM-dd',
                                                              )
                                                            : null
                                                    }
                                                />
                                            </Box>

                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography>Choose A Category</Typography>
                                                <FreeSoloCreateOptionDialog
                                                    createProps={{ type: 'uploads' }}
                                                    value={editForm.tag}
                                                    onChange={(v) =>
                                                        setEditForm((form) => ({
                                                            file_name: form?.file_name ?? null,
                                                            date: form?.date ?? null,
                                                            tag: v,
                                                        }))
                                                    }
                                                    organization={
                                                        upload.individual.organization.uuid
                                                    }
                                                    choices={tags
                                                        .filter((t) => t.type === 'uploads')
                                                        .map((t) => mapTagIntoFreeSoloOption(t))
                                                        .sort((a, b) =>
                                                            a.label.localeCompare(b.label),
                                                        )} // Sort alphabetically
                                                    onTagCreated={() => {}}
                                                />
                                            </Box>
                                        </>
                                    )}
                                </>
                            }
                            title={
                                <Typography variant={'h6'}>Edit Sprint Profile Details</Typography>
                            }
                        />
                        {/*name and sprint line*/}
                        <Grid
                            container
                            spacing={2}
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            padding={8}
                            borderTop={'1px solid lightgrey'}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 4 }}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        height: 65,
                                        width: 65,
                                        marginBottom: 3,
                                        boxShadow:
                                            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                                    }}
                                    src={upload?.individual.image_urls.avatar ?? ''}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        padding: 4,
                                    }}
                                >
                                    {ability.can('individual:manage-logs', upload.individual) ? (
                                        <Link
                                            to={ROUTES.IndividualDetails.path.replace(
                                                ':individualId',
                                                upload.individual.uuid,
                                            )}
                                            component={ReactRouterDomLink}
                                            variant="h4"
                                            underline="hover"
                                            color="inherit"
                                        >
                                            <Typography variant={'h4'}>
                                                {upload.individual.name}
                                            </Typography>
                                        </Link>
                                    ) : (
                                        <Typography variant={'h4'}>
                                            {upload.individual.name}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            {upload.error && (
                                <Typography variant={'h4'}>
                                    Error During Processing - Try Again
                                </Typography>
                            )}
                            {!upload.error && (
                                <Box display={'flex'} alignItems={'center'}>
                                    {upload.upload_type === 'tem' && (
                                        <FormControl variant="outlined" style={{ minWidth: 220 }}>
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Select Motion
                                            </InputLabel>
                                            <Select
                                                value={motionId}
                                                onChange={(event) =>
                                                    push(
                                                        `/community/individuals/${individualId}/performance/${uploadId}/motions/${
                                                            event.target.value as string
                                                        }`,
                                                    )
                                                }
                                                id="select-motion"
                                                name="motion"
                                                label="Select A Motion"
                                            >
                                                {upload.motions.map((motion) => (
                                                    <MenuItem
                                                        key={motion.tem_motion_guid}
                                                        value={motion.tem_motion_guid}
                                                    >
                                                        {moment
                                                            .unix(motion.timestamp)
                                                            .format('h:mm:ss A')}{' '}
                                                        {(motion.peak_speed * 2.23694).toFixed(3)}{' '}
                                                        mph{' '}
                                                        {motion.con_mass != 0 && (
                                                            <> ({Math.round(motion.con_mass)}kg)</>
                                                        )}
                                                        {motion.duration != 0 && (
                                                            <> {motion.duration.toFixed(3)}s</>
                                                        )}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}

                                    {upload.upload_type === 'sprint' && (
                                        <FormControl variant="outlined" style={{ minWidth: 220 }}>
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Select Sprint
                                            </InputLabel>
                                            <Select
                                                value={sprintId}
                                                onChange={(event) => {
                                                    if (event.target.value === 'sessionSummary') {
                                                        push(
                                                            `/community/individuals/${individualId}/performance/${uploadId}`,
                                                        );
                                                    } else {
                                                        push(
                                                            `/community/individuals/${individualId}/performance/${uploadId}/sprints/${
                                                                event.target.value as string
                                                            }`,
                                                        );
                                                    }
                                                }}
                                                id="demo-simple-select"
                                                name="organization"
                                                label="Select A Sprint"
                                            >
                                                <MenuItem value="sessionSummary">
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            textAlign: 'center',
                                                            textDecoration: 'underline',
                                                        }}
                                                    >
                                                        Session Summary
                                                    </div>
                                                </MenuItem>
                                                {upload.sprints.map((sprint, ix) => (
                                                    <MenuItem key={sprint.uuid} value={sprint.uuid}>
                                                        {ix + 1} -
                                                        {moment(sprint.timestamp).format(
                                                            'h:mm:ss A',
                                                        )}
                                                        - {sprint.duration}s -
                                                        {(sprint.v_max * 2.23694).toFixed(3)}mph
                                                        {sprint.load != 0 && (
                                                            <> ({Math.round(sprint.load)}kg)</>
                                                        )}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>
                            )}
                        </Grid>
                        {selectedSprint && (
                            <Can I={'individual:manage-logs'} this={upload.individual}>
                                <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <Box
                                        sx={{
                                            width: 400,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography>Choose A Sprint Category</Typography>
                                        <FreeSoloCreateOptionDialog
                                            createProps={{ type: 'sprints' }}
                                            value={selectedSprintTag}
                                            onChange={(v) => {
                                                setSelectedSprintTag(v);

                                                // save the sprint tag
                                                saveSprint(
                                                    individualId,
                                                    upload.uuid,
                                                    selectedSprint.uuid,
                                                    {
                                                        tag_id: v?.id ?? null,
                                                    },
                                                )
                                                    .then(() => {
                                                        setSnackMessage('Sprint Tag Saved');
                                                    })
                                                    .catch(() => {
                                                        setSnackMessage('Error Saving Sprint Tag');
                                                    });
                                            }}
                                            organization={upload.individual.organization.uuid}
                                            choices={tags
                                                .filter((t) => t.type === 'sprints')
                                                .map((t) => mapTagIntoFreeSoloOption(t))}
                                            onTagCreated={() => {}}
                                        />
                                    </Box>
                                </Box>
                            </Can>
                        )}
                        {selectedSprint && (
                            <ForceVelocityProfileSprint
                                upload={upload}
                                individual={upload.individual}
                                sprint={selectedSprint}
                                distanceUnit={distanceUnit}
                                isMobileDevice={isMobileDevice}
                                hideFVP={hideFVP}
                                sideBySide={sideBySide}
                                onDeleted={() => {
                                    push('../');
                                }}
                            />
                        )}
                        {selectedMotion && <ViewTemMotion motion={selectedMotion} />}

                        {!selectedSprint && !selectedMotion && upload && (
                            <>
                                <Box>
                                    <Typography
                                        variant={'h4'}
                                        sx={{ color: grey[700], my: 4 }}
                                        textAlign={'center'}
                                    >
                                        Session Summary Data
                                    </Typography>
                                    {upload.sprints.length < 1 && (
                                        <>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    color: '#00196D',
                                                    my: 4,
                                                }}
                                            >
                                                <AnimatedTypography
                                                    variant={'h6'}
                                                    textAlign={'center'}
                                                >
                                                    Data is still processing . . . click to{' '}
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            display: 'inline-flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        refresh
                                                        <IconButton
                                                            color="primary"
                                                            aria-label="upload picture"
                                                            component="span"
                                                            onClick={() => refreshBrowser()}
                                                            size="large"
                                                        >
                                                            <Refresh />
                                                        </IconButton>
                                                    </Box>
                                                    in a few seconds
                                                </AnimatedTypography>
                                            </Box>
                                        </>
                                    )}

                                    <Grid container spacing={6}>
                                        {upload.summary_duration && (
                                            <Grid item xs={12} sm={4} lg={3}>
                                                <Paper
                                                    sx={{
                                                        px: 12,
                                                        py: 8,
                                                    }}
                                                >
                                                    <Typography
                                                        variant={'subtitle1'}
                                                        sx={{ color: grey[500] }}
                                                    >
                                                        Session Duration
                                                    </Typography>

                                                    <Typography variant={'h4'}>
                                                        {(upload.summary_duration / 60).toFixed(1)}{' '}
                                                        min
                                                    </Typography>
                                                </Paper>
                                            </Grid>
                                        )}
                                        {upload.summary_distance && (
                                            <Grid item xs={12} sm={4} lg={3}>
                                                <Paper
                                                    sx={{
                                                        px: 12,
                                                        py: 8,
                                                    }}
                                                >
                                                    <Typography
                                                        variant={'subtitle1'}
                                                        sx={{ color: grey[500] }}
                                                    >
                                                        Session Distance
                                                    </Typography>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h4">
                                                            {distanceValue.toFixed(3)}
                                                        </Typography>
                                                        <Select
                                                            onChange={handleDistanceUnitChange}
                                                            value={
                                                                selectedUnit as
                                                                    | ''
                                                                    | {
                                                                          value:
                                                                              | 'yd'
                                                                              | 'm'
                                                                              | 'mi'
                                                                              | 'km';
                                                                      }
                                                            }
                                                            style={{
                                                                marginLeft: '10px',
                                                                maxHeight: 40,
                                                            }}
                                                            inputProps={{
                                                                style: { outline: 'none' },
                                                            }}
                                                        >
                                                            <MenuItem value="mi">
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    mi
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem value="km">
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    km
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem value="m">
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    m
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem value="yd">
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    yd
                                                                </Typography>
                                                            </MenuItem>
                                                        </Select>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        )}
                                        {upload.summary_max_velocity && (
                                            <Grid item xs={12} sm={4} lg={3}>
                                                <Paper
                                                    sx={{
                                                        px: 12,
                                                        py: 8,
                                                    }}
                                                >
                                                    <Typography
                                                        variant={'subtitle1'}
                                                        sx={{
                                                            color: grey[500],
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        Max Velocity
                                                        <TooltipMui title="This may be different than the SMax in sprints because we exclude values where: (1) gps unit had an error within 3 seconds of the Max Speed, (2) number of connected satelites was less than 4, and (3) where the geometric quality of the satalite configuration is less than 99%">
                                                            <IconButton
                                                                size="small"
                                                                sx={{ marginLeft: 1 }}
                                                            >
                                                                <InfoIcon fontSize="small" />
                                                            </IconButton>
                                                        </TooltipMui>
                                                    </Typography>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h4">
                                                            {maxSpeedValue.toFixed(3)}
                                                        </Typography>
                                                        <Select
                                                            onChange={handleMaxSpeedUnitChange}
                                                            value={
                                                                selectedMaxSpeedUnit as
                                                                    | ''
                                                                    | {
                                                                          value:
                                                                              | 'mph'
                                                                              | 'm/s'
                                                                              | 'km/h'
                                                                              | 'yd/s';
                                                                      }
                                                            }
                                                            style={{
                                                                marginLeft: '10px',
                                                                maxHeight: 40,
                                                            }}
                                                            inputProps={{
                                                                style: { outline: 'none' },
                                                            }}
                                                        >
                                                            <MenuItem value="mph">
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    mph
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem value="m/s">
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    m/s
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem value="km/h">
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    km/h
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem value="yd/s">
                                                                <Typography
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: 24,
                                                                    }}
                                                                >
                                                                    yd/s
                                                                </Typography>
                                                            </MenuItem>
                                                        </Select>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        )}
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Paper
                                                sx={{
                                                    px: 12,
                                                    py: 8,
                                                }}
                                            >
                                                <Typography
                                                    variant={'subtitle1'}
                                                    sx={{ color: grey[500] }}
                                                >
                                                    Number Of Sprints
                                                </Typography>

                                                <Typography variant={'h4'}>
                                                    {upload.sprints.length}
                                                </Typography>
                                            </Paper>
                                        </Grid>
                                        {upload.summary_player_load && (
                                            <Grid item xs={12} sm={4} lg={3}>
                                                <Paper
                                                    sx={{
                                                        px: 12,
                                                        py: 8,
                                                    }}
                                                >
                                                    <Typography
                                                        variant={'subtitle1'}
                                                        sx={{ color: grey[500] }}
                                                    >
                                                        Player Load
                                                    </Typography>

                                                    <Typography variant={'h4'}>
                                                        {upload.summary_player_load ?? '-'}
                                                    </Typography>
                                                </Paper>
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Paper
                                                sx={{
                                                    px: 12,
                                                    py: 8,
                                                }}
                                            >
                                                <Typography
                                                    variant={'subtitle1'}
                                                    sx={{ color: grey[500] }}
                                                >
                                                    # Accels (&gt; 2m/s²)
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 2,
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography variant={'h4'}>
                                                        {upload.summary_number_of_accelerations ??
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Paper
                                                sx={{
                                                    px: 12,
                                                    py: 8,
                                                }}
                                            >
                                                <Typography
                                                    variant={'subtitle1'}
                                                    sx={{ color: grey[500] }}
                                                >
                                                    # Accels (&gt; 3m/s²)
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 2,
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography variant={'h4'}>
                                                        {upload.summary_number_of_accelerations_gt_3 ??
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Paper
                                                sx={{
                                                    px: 12,
                                                    py: 8,
                                                }}
                                            >
                                                <Typography
                                                    variant={'subtitle1'}
                                                    sx={{ color: grey[500] }}
                                                >
                                                    # Accels (&gt; 4m/s²)
                                                </Typography>

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 2,
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography variant={'h4'}>
                                                        {upload.summary_number_of_accelerations_gt_4 ??
                                                            '-'}
                                                    </Typography>
                                                </Box>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Paper
                                                sx={{
                                                    px: 12,
                                                    py: 8,
                                                }}
                                            >
                                                <Typography
                                                    variant={'subtitle1'}
                                                    sx={{ color: grey[500] }}
                                                >
                                                    Max Accel
                                                    <TooltipMui title="Acceleration event must have been maintained for at least 1s to count here.">
                                                        <IconButton
                                                            size="small"
                                                            sx={{ marginLeft: 1 }}
                                                        >
                                                            <InfoIcon fontSize="small" />
                                                        </IconButton>
                                                    </TooltipMui>
                                                </Typography>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h4">
                                                        {maxAccelerationValue !== null
                                                            ? maxAccelerationValue.toFixed(2)
                                                            : '-'}
                                                    </Typography>
                                                    <Select
                                                        value={selectedMaxAccelerationUnit}
                                                        onChange={handleMaxAccelerationUnitChange}
                                                        style={{
                                                            marginLeft: '10px',
                                                            maxHeight: 40,
                                                        }}
                                                        inputProps={{ style: { outline: 'none' } }}
                                                    >
                                                        <MenuItem value="ft/s²">
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    fontSize: 24,
                                                                }}
                                                            >
                                                                ft/s²
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem value="m/s²">
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    fontSize: 24,
                                                                }}
                                                            >
                                                                m/s²
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem value="g">
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    fontSize: 24,
                                                                }}
                                                            >
                                                                g
                                                            </Typography>
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Paper
                                                sx={{
                                                    px: 12,
                                                    py: 8,
                                                }}
                                            >
                                                <Typography
                                                    variant={'subtitle1'}
                                                    sx={{ color: grey[500] }}
                                                >
                                                    # Decels
                                                </Typography>

                                                <Typography variant={'h4'}>
                                                    {upload.summary_number_of_decelerations ?? '-'}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12} sm={4} lg={3}>
                                            <Paper sx={{ px: 12, py: 8 }}>
                                                <Typography
                                                    variant={'subtitle1'}
                                                    sx={{ color: grey[500] }}
                                                >
                                                    Max Decel
                                                    <TooltipMui title="Deceleration event must have been maintained for at least 1s to count here.">
                                                        <IconButton
                                                            size="small"
                                                            sx={{ marginLeft: 1 }}
                                                        >
                                                            <InfoIcon fontSize="small" />
                                                        </IconButton>
                                                    </TooltipMui>
                                                </Typography>

                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h4">
                                                        {maxDecelerationValue !== null
                                                            ? maxDecelerationValue.toFixed(2)
                                                            : '-'}
                                                    </Typography>
                                                    <Select
                                                        value={selectedMaxDecelerationUnit}
                                                        onChange={handleMaxDecelerationUnitChange}
                                                        style={{
                                                            marginLeft: '10px',
                                                            maxHeight: 40,
                                                        }}
                                                        inputProps={{ style: { outline: 'none' } }}
                                                    >
                                                        <MenuItem value="ft/s²">
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    fontSize: 24,
                                                                }}
                                                            >
                                                                ft/s²
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem value="m/s²">
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    fontSize: 24,
                                                                }}
                                                            >
                                                                m/s²
                                                            </Typography>
                                                        </MenuItem>
                                                        <MenuItem value="g">
                                                            <Typography
                                                                style={{
                                                                    fontWeight: 'bold',
                                                                    fontSize: 24,
                                                                }}
                                                            >
                                                                g
                                                            </Typography>
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        {upload.summary_distance_per_speed && (
                                            <Grid item xs={12} sm={6}>
                                                <Box sx={{ p: 4 }}>
                                                    <Typography
                                                        sx={{ color: grey[700], my: 2 }}
                                                        variant={'h4'}
                                                        textAlign={'center'}
                                                    >
                                                        Distance In Speed Zones
                                                    </Typography>
                                                    <Paper
                                                        sx={{ height: 320, width: '100%', p: 12 }}
                                                    >
                                                        <ResponsiveContainer
                                                            width="100%"
                                                            height="100%"
                                                        >
                                                            <BarChart
                                                                width={500}
                                                                height={300}
                                                                data={drop(
                                                                    upload.summary_distance_per_speed
                                                                        .map((d, i) => ({
                                                                            name: i,
                                                                            distance: d,
                                                                        }))
                                                                        .filter(
                                                                            (d) => d.name <= 28,
                                                                        ),
                                                                    5,
                                                                )}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 5,
                                                                    left: 5,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis
                                                                    type="number"
                                                                    dataKey="name"
                                                                    label={{
                                                                        value: 'Speed (mph)',
                                                                        position:
                                                                            'insideBottomLeft',
                                                                        offset: -20,
                                                                    }}
                                                                />
                                                                <YAxis
                                                                    type="number"
                                                                    label={{
                                                                        value: 'Distance (yd)',
                                                                        angle: -90,
                                                                        position: 'insideLeft',
                                                                        offset: 2,
                                                                    }}
                                                                />
                                                                <Tooltip
                                                                    formatter={(
                                                                        value: number,
                                                                        name: string,
                                                                    ) => [name, value.toFixed(3)]}
                                                                    labelFormatter={(
                                                                        label: number,
                                                                    ) => {
                                                                        return `${label - 1}-${
                                                                            label - 0.01
                                                                        }`;
                                                                    }}
                                                                />
                                                                <Legend />
                                                                <Bar
                                                                    dataKey="distance"
                                                                    fill="#8884d8"
                                                                />
                                                            </BarChart>
                                                        </ResponsiveContainer>
                                                    </Paper>
                                                </Box>
                                            </Grid>
                                        )}
                                        {upload.summary_duration_per_acceleration && (
                                            <Grid item xs={12} sm={6}>
                                                <Box sx={{ p: 4 }}>
                                                    <Typography
                                                        sx={{ color: grey[700], my: 2 }}
                                                        variant={'h4'}
                                                        textAlign={'center'}
                                                    >
                                                        Time In Acceleration Zones
                                                    </Typography>
                                                    <TableContainer component={Paper}>
                                                        <Table sx={{ width: '100%' }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Acceleration (ft/s^2)
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        Time (s)
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th':
                                                                            {
                                                                                border: 0,
                                                                            },
                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        12-13.99
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {sum(
                                                                            upload.summary_duration_per_acceleration.slice(
                                                                                12,
                                                                                14,
                                                                            ),
                                                                        ).toFixed(3)}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th':
                                                                            {
                                                                                border: 0,
                                                                            },
                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        14-15.99
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {sum(
                                                                            upload.summary_duration_per_acceleration.slice(
                                                                                14,
                                                                                16,
                                                                            ),
                                                                        ).toFixed(3)}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th':
                                                                            {
                                                                                border: 0,
                                                                            },
                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        16-17.99
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {sum(
                                                                            upload.summary_duration_per_acceleration.slice(
                                                                                15,
                                                                                17,
                                                                            ),
                                                                        ).toFixed(3)}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th':
                                                                            {
                                                                                border: 0,
                                                                            },
                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        18-19.99
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {sum(
                                                                            upload.summary_duration_per_acceleration.slice(
                                                                                18,
                                                                                20,
                                                                            ),
                                                                        ).toFixed(3)}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th':
                                                                            {
                                                                                border: 0,
                                                                            },
                                                                    }}
                                                                >
                                                                    <TableCell
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        20+
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {sum(
                                                                            upload.summary_duration_per_acceleration.slice(
                                                                                20,
                                                                                40,
                                                                            ),
                                                                        ).toFixed(3)}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Grid>
                                        )}
                                        {upload && (
                                            <Grid item xs={12}>
                                                <RawDataViewer upload={upload} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            </>
                        )}

                        {upload.sprints.map.length < 1 && !upload.error && (
                            <Box>
                                <Grid
                                    container
                                    spacing={2}
                                    padding={4}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={9}>
                                        <Typography variant={'h6'}>
                                            Refresh to check if sprint calculations are complete
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="span"
                                            onClick={() =>
                                                getUpload(individualId, uploadId)
                                                    .then((response) => {
                                                        setUpload(response.data);
                                                    })
                                                    .catch(() => {})
                                            }
                                            size="large"
                                        >
                                            <Refresh />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Box>
                </Paper>
            )}
            {!upload && <>Loading</>}
        </Container>
    );
}
export default ForceVelocityProfile;
