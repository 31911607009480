import React from 'react';
import { useFormik } from 'formik';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextareaAutosize,
    TextField,
    Typography,
} from '@mui/material';
import LandingPageNavBar from './LandingPageNavBar';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    cell: Yup.string().required('Cell phone number is required'),
    role: Yup.string().required('Role is required'),
    organization: Yup.string(), // Optional field
    numberOfAthletes: Yup.number(), // Optional field
    sports: Yup.string(), // Optional field
    painPoints: Yup.string().required('This field is required'), // Required field
});

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    background: 'linear-gradient(to bottom, #0066ff, #070A19)', // Set the background gradient
    border: '2px solid #A7ABB7',
    borderRadius: '10px', // Add border radius
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};

interface ContactFormValues {
    name: string;
    role: 'coach' | 'athlete';
    organization?: string;
    numberOfAthletes: number | ''; // Changed to number or empty string
    sports?: string;
    painPoints?: string;
    cell: string;
    email: string;
}

const ContactForm: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const history = useHistory();
    const formik = useFormik<ContactFormValues>({
        initialValues: {
            name: '',
            role: 'coach',
            organization: '',
            numberOfAthletes: '', // Keep as a string for form handling
            sports: '',
            painPoints: '',
            cell: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const url = `${process.env.REACT_APP_API_HOST}/submit-contact-form`;
            console.log('Submitting to:', url);

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            })
                .then((response) => response.json())
                .then((data) => console.log(data))
                .catch((error) => console.error('Error:', error));
            setIsModalOpen(true);
            setTimeout(() => {
                history.push('/');
            }, 4000);
        },
    });
    const closeModal = () => setIsModalOpen(false);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const gradientTextStyles = {
        mt: 4,
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 8,
        maxWidth: 900,
        background: 'linear-gradient(to bottom, #fffffF, #A7ABB7)', // Example gradient
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent', // This makes the text transparent, showing only the background
        backgroundClip: 'text',
        color: 'transparent', // Fallback for browsers that do not support background-clip
        marginTop: 15,
    };

    return (
        <>
            <LandingPageNavBar />
            <Box
                sx={{ flex: 1, backgroundImage: 'linear-gradient(to bottom, #0066ff, #070A19)' }}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start" // This will distribute space evenly
                alignItems="center"
                height="100vh"
            >
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    Tell us a little about yourself and how we can help.
                </Typography>
                <Paper
                    elevation={matches ? 3 : 0}
                    sx={{
                        width: '100%',
                        maxWidth: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        padding: theme.spacing(24, 16),
                        [theme.breakpoints.down('md')]: {
                            minHeight: '50%',
                            width: 'calc(100% - 44px)',
                            flexDirection: 'column',
                        },
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            id="name"
                            name="name"
                            label="Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            id="cell"
                            name="cell"
                            label="Cell Phone Number"
                            type="tel"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formik.values.cell}
                            onChange={formik.handleChange}
                            error={formik.touched.cell && Boolean(formik.errors.cell)}
                            helperText={formik.touched.cell && formik.errors.cell}
                        />

                        <TextField
                            id="email"
                            name="email"
                            label="Email Address"
                            type="email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="role-label">Role</InputLabel>
                            <Select
                                labelId="role-label"
                                id="role"
                                name="role"
                                value={formik.values.role}
                                onChange={formik.handleChange}
                                label="Role"
                            >
                                <MenuItem value="athlete">Athlete</MenuItem>
                                <MenuItem value="coach">Coach</MenuItem>
                            </Select>
                        </FormControl>

                        {formik.values.role === 'coach' && (
                            <>
                                <TextField
                                    id="organization"
                                    name="organization"
                                    label="Organization"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={formik.values.organization}
                                    onChange={formik.handleChange}
                                />

                                <TextField
                                    id="numberOfAthletes"
                                    name="numberOfAthletes"
                                    label="Number of Athletes"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={formik.values.numberOfAthletes}
                                    onChange={formik.handleChange}
                                />

                                <TextField
                                    id="sports"
                                    name="sports"
                                    label="Sports Coached"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={formik.values.sports}
                                    onChange={formik.handleChange}
                                />
                            </>
                        )}

                        <TextareaAutosize
                            aria-label="What do you need help with?"
                            minRows={3}
                            placeholder="What do you need help with?"
                            style={{
                                width: '100%',
                                marginTop: '20px',
                                border:
                                    formik.touched.painPoints && formik.errors.painPoints
                                        ? '2px solid red'
                                        : '1px solid #ced4da',
                            }}
                            id="painPoints"
                            name="painPoints"
                            value={formik.values.painPoints}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.painPoints && formik.errors.painPoints ? (
                            <div style={{ color: 'red', marginTop: '5px' }}>
                                {formik.errors.painPoints}
                            </div>
                        ) : null}

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ marginTop: '20px' }}
                        >
                            Submit
                        </Button>
                    </form>
                </Paper>
            </Box>
            <Modal open={isModalOpen} onClose={closeModal} closeAfterTransition>
                <Fade in={isModalOpen}>
                    <Box sx={style}>
                        <Typography
                            sx={gradientTextStyles}
                            id="transition-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Thank you for your submission!
                        </Typography>
                        <Typography sx={gradientTextStyles} id="transition-modal-description">
                            We will get back to you soon.
                        </Typography>
                        <Typography sx={gradientTextStyles} id="transition-modal-description">
                            Redirecting to home page...
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default ContactForm;
