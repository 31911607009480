import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    Input,
    Typography,
} from '@mui/material';
import React, { ChangeEvent, FC, Fragment, memo, SyntheticEvent, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { Dimensions } from 'utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FilterLabel } from './FilterLabel';
import { FiltersBySourceType } from 'redux/reducers/timeline';
import SearchIcon from '@mui/icons-material/Search';
import { listOfFilters } from 'utils/timelineUtils';
import { TypographyTitleh4 } from '../Typography';

type FiltersProps = {
    isLoaded: boolean;
    isLoading: boolean;
    filters: FiltersBySourceType;
    selectedFilters: Dimensions[];
    handleChangeFilter: (selectedFilters: Dimensions[]) => void;
};

const Filters: FC<React.PropsWithChildren<FiltersProps>> = ({
    isLoaded,
    isLoading,
    filters,
    handleChangeFilter,
    selectedFilters,
}) => {
    const [searchValue, setSearchValue] = useState<Record<string, string>>({});

    const handleFilterClick = (
        event: SyntheticEvent<HTMLLabelElement | HTMLInputElement>,
        filter: Dimensions,
    ) => {
        const { type } = event.target as HTMLInputElement;
        if (type === 'checkbox') {
            let filterList = listOfFilters(selectedFilters, filter);
            handleChangeFilter(filterList);
        }
    };

    const getSelectedFiltersCount = (value: Dimensions[]): string | number => {
        let result = 0;

        selectedFilters.map((selectedFilter) => {
            let found = value.filter((x) => x.id === selectedFilter.id);
            if (found.length) result++;
        });

        return result > 0 ? result : '';
    };

    const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.currentTarget;
        const searchValueObj = { ...searchValue, [name]: value };
        setSearchValue(searchValueObj);
    };

    const filterEntries = Object.entries(filters);
    const theme = useTheme();

    return !filterEntries.length && isLoaded && !isLoading ? (
        <Box
            sx={{
                marginTop: theme.spacing(10),
                flexBasis: '100%',
                [theme.breakpoints.up('md')]: {
                    flexBasis: '25%',
                },
            }}
        >
            <Typography variant="subtitle1" align="center" gutterBottom>
                No timeline filters to show
            </Typography>
        </Box>
    ) : (
        <Box
            sx={{
                flexBasis: '25%',
                overflowX: 'hidden',
                [theme.breakpoints.down('lg')]: {
                    flexBasis: '35%',
                },
            }}
        >
            {filterEntries.map(([key, value]) => (
                <Fragment key={key}>
                    <Divider />
                    <Accordion
                        key={key}
                        sx={{
                            boxShadow: 'none',
                            background: 'transparent',
                            '&:before': {
                                backgroundColor: 'transparent',
                            },
                            '&.Mui-expanded': {
                                margin: 0,
                            },
                        }}
                    >
                        <AccordionSummary
                            sx={{
                                '&.MuiAccordionSummary-root': {
                                    minHeight: 15,
                                    marginBottom: 8,
                                    marginTop: 8,
                                    padding: 0,
                                },
                                '&.Mui-expanded': {
                                    minHeight: 15,
                                    padding: 0,
                                    marginRight: 0,
                                },
                                '& .MuiAccordionSummary-expandIcon': {
                                    padding: 0,
                                    marginRight: 0,
                                },
                                '& .MuiIconButton-edgeEnd': {
                                    padding: 0,
                                    marginRight: 0,
                                },
                                '& > .Mui-expanded': {
                                    padding: 0,
                                    minHeight: 15,
                                    margin: 0,
                                },
                                '& > .MuiAccordionSummary-content': {
                                    margin: 0,
                                    alignItems: 'center',
                                },
                            }}
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <TypographyTitleh4 sx={{ margin: 0, paddingLeft: 6 }}>
                                {value[0]?.type_display || 'Nothing to filter'}
                            </TypographyTitleh4>
                            {getSelectedFiltersCount(value) && (
                                <Chip
                                    sx={{
                                        ml: 8,
                                        height: 'auto',
                                        '& .MuiChip-label': {
                                            padding: (theme) => theme.spacing(1, 4),
                                        },
                                    }}
                                    color="primary"
                                    label={getSelectedFiltersCount(value)}
                                />
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Input
                                    onChange={handleChangeSearch}
                                    value={searchValue[key] ?? ''}
                                    sx={{
                                        backgroundColor: '#fff',
                                        width: '100%',
                                        borderRadius: 4,
                                        '&&&:before': {
                                            display: 'none',
                                        },
                                        '&&:after': {
                                            display: 'none',
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            backgroundColor: '#003f9d3b',
                                        },
                                    }}
                                    name={key}
                                    startAdornment={
                                        <SearchIcon
                                            sx={{
                                                fontSize: 16,
                                                margin: '0 8px',
                                                color: theme.palette.text.secondary,
                                            }}
                                        />
                                    }
                                    placeholder={`Find ${
                                        value[0]?.type_display || 'Nothing to filter'
                                    } `}
                                />
                                {value
                                    .filter(({ name }) => {
                                        if (!searchValue[key]?.length) return true;
                                        return name
                                            .toLowerCase()
                                            .includes(searchValue[key].toLowerCase());
                                    })
                                    .map((filter) => (
                                        <FormControlLabel
                                            key={filter.id}
                                            sx={{
                                                fontSize: 16,
                                                flexBasis: '100%',
                                                '& > span': {
                                                    fontSize: 'inherit',
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 15,
                                                    },
                                                },
                                            }}
                                            aria-label="Acknowledge"
                                            onClick={(event) => handleFilterClick(event, filter)}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <Checkbox
                                                    checked={
                                                        selectedFilters.findIndex(
                                                            (f) =>
                                                                f.type === filter.type &&
                                                                f.id === filter.id,
                                                        ) > -1
                                                    }
                                                />
                                            }
                                            label={
                                                <FilterLabel
                                                    name={filter.name}
                                                    src={filter.image_url || undefined}
                                                />
                                            }
                                        />
                                    ))}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Fragment>
            ))}
        </Box>
    );
};

export default memo(Filters);
