import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Invite } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Invite>>>;

const getInvites = (id: string): ReturnType => {
    return Request.get(`/workouts/sessions/${id}/invites`);
};

export default getInvites;
