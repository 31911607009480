import React, { useEffect, useState, useCallback } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataGridPremium, GridPaginationModel } from '@mui/x-data-grid-premium';
import { GridColDef } from '@mui/x-data-grid-premium';
import {
    getNetworkingPayoutJobList,
    getNetworkingPayoutLogsForAdmin,
} from '../networkingPayoutLogs.api';
import { CommandLog, NetworkingPayoutLog } from '../networkingPayoutLogs.types';
import { DatePicker } from '@mui/x-date-pickers-pro/';
import { DateTime } from 'luxon';

const ResponsiveFormItem = styled(Box)(({ theme }) => ({
    width: 'calc(15% - 16px)',
    maxWidth: '300px',
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        width: 'calc(100% - 16px)',
        maxWidth: 'none',
    },
}));

const ResponsiveForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    margin: theme.spacing(-1),
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));

const NetworkingPayoutList = () => {
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
    const [logs, setLogs] = useState<NetworkingPayoutLog[]>([]);
    const [searchParams, setSearchParams] = useState({
        log_level: '',
        log: '',
        created_at: '',
        command_id: '',
        page: 1,
        per_page: 10,
        sort: 'id',
    });
    const [totalLogs, setTotalLogs] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const [logLevel, setLogLevel] = useState('');
    const [log, setLog] = useState('');
    const [commandId, setCommandId] = useState('');
    const [payoutJobList, setPayoutJobList] = useState<CommandLog[]>([]);
    const [selectedPayoutJob, setSelectedPayoutJob] = useState<CommandLog | null>(null);
    const [selectedDate, setSelectedDate] = useState<DateTime | null>(null);

    const fetchLogs = useCallback(async () => {
        try {
            const response = await getNetworkingPayoutLogsForAdmin({
                ...searchParams,
                page: paginationModel.page + 1,
                per_page: paginationModel.pageSize,
            });
            setLogs(response.data.data);
            setTotalLogs(response.data.meta.total);
        } catch (error) {
            console.error('Failed to fetch logs', error);
        }
    }, [searchParams, paginationModel]);

    const fetchPayoutJobs = useCallback(async (created_at: string) => {
        try {
            const response = await getNetworkingPayoutJobList({ created_at });
            setPayoutJobList(response.data);
        } catch (error) {
            console.error('Failed to fetch payout jobs', error);
        }
    }, []);

    useEffect(() => {
        fetchLogs();
        fetchPayoutJobs('');
    }, [fetchLogs, fetchPayoutJobs]);

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
    };

    const handleLogSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        setSearchParams({
            ...searchParams,
            log_level: logLevel,
            log: log,
            command_id: commandId,
        });
    };

    const handleDateChange = (date: DateTime | null) => {
        setSelectedDate(date);
        const formattedDate = date ? date.toFormat('yyyy-MM-dd') : '';
        fetchPayoutJobs(formattedDate);
    };

    const columns: GridColDef[] = [
        { field: 'log_level', headerName: 'Log Level', width: 100, sortable: false },
        { field: 'log', headerName: 'Log', width: 1100, sortable: false },
        { field: 'command_id', headerName: 'Command ID', width: 300, sortable: false },
        {
            field: 'created_at',
            headerName: 'Payout Date',
            width: 150,
            sortable: false,
            valueFormatter: (params) => {
                const date = new Date(params);
                return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
            },
        },
    ];

    return (
        <Box style={{ marginLeft: '2rem' }}>
            <h2>Networking Payout Logs</h2>
            <section style={{ marginBottom: '2rem' }}>
                <h4>Search Logs</h4>
                <ResponsiveForm onSubmit={handleLogSearch} noValidate>
                    <ResponsiveFormItem>
                        <FormControl fullWidth size="small">
                            <InputLabel id="filter-by-log-level">Log Level</InputLabel>
                            <Select
                                labelId="filter-by-log-level"
                                label="Log Level"
                                value={logLevel}
                                onChange={(event: SelectChangeEvent<string>) => {
                                    setLogLevel(event.target.value);
                                }}
                            >
                                <MenuItem value="">All</MenuItem>
                                <MenuItem value="INFO">INFO</MenuItem>
                                <MenuItem value="ERROR">ERROR</MenuItem>
                            </Select>
                        </FormControl>
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <TextField
                            label="Log"
                            value={log}
                            onChange={(event) => {
                                setLog(event.target.value);
                            }}
                            size="small"
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <DatePicker
                            label="Filter by Date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            slotProps={{
                                textField: {
                                    variant: 'outlined',
                                    size: 'small',
                                    fullWidth: true,
                                },
                            }}
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Autocomplete
                            options={payoutJobList}
                            getOptionLabel={(option) =>
                                `${option.created_at} - ${option.command_id}`
                            }
                            renderInput={(params) => <TextField {...params} label="Payout Job" />}
                            onChange={(_, value) => {
                                setSelectedPayoutJob(value);
                                setCommandId(value?.command_id ?? '');
                            }}
                            value={selectedPayoutJob}
                            onInputChange={(event, newInputValue, reason) => {
                                if (reason !== 'reset') {
                                    fetchPayoutJobs(newInputValue);
                                }
                            }}
                            getOptionKey={(option) => option.command_id}
                            filterOptions={(options) => options}
                            size={'small'}
                            fullWidth
                        />
                    </ResponsiveFormItem>
                    <ResponsiveFormItem>
                        <Button
                            type="submit"
                            size={'medium'}
                            variant="contained"
                            fullWidth={isNarrow}
                        >
                            Search
                        </Button>
                    </ResponsiveFormItem>
                </ResponsiveForm>
            </section>
            <Box width="98%" maxHeight="600px" overflow="auto" className="dataGridContainer">
                <DataGridPremium
                    rows={logs}
                    columns={columns}
                    checkboxSelection={false}
                    sortingMode="server"
                    getRowId={(row) => row.id}
                    style={{ height: '624px', marginBottom: '50px' }}
                    pagination
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    paginationModel={paginationModel}
                    disableColumnMenu={true}
                    onPaginationModelChange={handlePaginationModelChange}
                    rowCount={totalLogs}
                    paginationMode="server"
                    sx={{
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default NetworkingPayoutList;
