import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { SessionStackCreateRequest } from 'utils';

type ReturnType = Promise<AxiosResponse<void>>;

interface AddSessionStackArgs {
    individualId: string;
    calendarId: string;
    data: SessionStackCreateRequest;
}

const addSessionStack = ({ individualId, calendarId, data }: AddSessionStackArgs): ReturnType => {
    return Request.post(
        `/individuals/${individualId}/calendars/${calendarId}/add-session-stack`,
        data,
    );
};

export default addSessionStack;
