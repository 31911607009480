import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Activity } from 'utils';

type ReturnType = Promise<AxiosResponse<Activity>>;

const startActivityForSelf = (
    local_timezone: string,
    session?: string,
    event?: string,
): ReturnType => {
    return Request.post(`/workouts/activities/start-self`, { local_timezone, session, event });
};
export default startActivityForSelf;
