import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

interface Props {
    word: string;
    description: string | number;
}

function DescriptionListItem({ word, description }: Props): React.ReactElement {
    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            style={{ paddingTop: 12, paddingBottom: 12 }}
        >
            <dt style={{ color: grey[700], fontWeight: 'bold' }}>{word}</dt>
            <dd style={{ color: grey[900] }}>{description}</dd>
        </Box>
    );
}

export default DescriptionListItem;
