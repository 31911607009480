import { Box, Container } from '@mui/material';
import React from 'react';
import LayoutBox from './LayoutBox';

const AuthLayout: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    return (
        <LayoutBox sx={{ backgroundImage: 'linear-gradient(to bottom, #0066ff, #070A19)' }}>
            <Container maxWidth="lg">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    height="100vh"
                >
                    {children}
                </Box>
            </Container>
        </LayoutBox>
    );
};

export default AuthLayout;
