import React from 'react';
import CustomButton from './CustomButton';
import FacebookIcon from 'components/Icons/FacebookIcon';

interface FacebookButtonProps {
    children?: string;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function FacebookButton({
    onClick,
    className = '',
    children = 'Sign in with Facebook',
}: FacebookButtonProps) {
    return (
        <CustomButton
            className={className}
            size="large"
            variant="outlined"
            onClick={onClick}
            startIcon={<FacebookIcon style={{ fill: '#4267B2' }} />}
        >
            {children}
        </CustomButton>
    );
}

export default FacebookButton;
