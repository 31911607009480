import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Form } from 'utils';

type ReturnType = Promise<AxiosResponse<Form>>;

const getFormDetails = (id: string): ReturnType => {
    return Request.get(`/data/forms/${id}`);
};
export default getFormDetails;
