import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FullScreenDialogCard from '../components/HowToDialogCard';
import MakeReportStep1 from '../media/MakeReportStep1.png';
import MakeReportStep2 from '../media/MakeReportStep2.png';
import MakeReportStep3 from '../media/MakeReportStep3.png';
import MakeDashboardStep3 from '../media/MakeDashboardStep3.png';
import MakeDashboardStep2 from '../media/MakeDashboardStep2.png';
import MakeDashboardStep1 from '../media/MakeDashboardStep1.png';

function AdminGPSOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Explore provides a more focussed version of reporting and data exploration
                        with a more limited set of filters and options than Reports and Dashboards.
                        <strong> A Dashboard is simply a collection of Reports.</strong> Reports and
                        Dashboards are more powerful but Explore is easier to use. Reports gives you
                        direct access to your entire database set but requires an understanding of
                        OLAP cubes, Star Schemas, SQL queries, and a more technical understanding to
                        get the most out of the data.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            To create a Dashboard, you must first create a Report. Then you can
                            create a Dashboard and add multiple reports from as many data sources as
                            you like.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    lgSize={6}
                    image={MakeReportStep1}
                    title="Create Report: Select Organization and Data Source"
                    headline="Create Report: Select Organization and Data Source"
                    description="You must select an organization
                            then its data source (Training Logs, Sprint Profiling - Sprints, Sprint
                            Profiling - Time Segments, 1080 Motion, or GPS Group Summary)."
                />
                <FullScreenDialogCard
                    lgSize={6}
                    image={MakeReportStep2}
                    title="Create Report: Select Filters, Report Type, and Chart Type"
                    headline="Create Report: Select Filters, Report Type, and Chart Type"
                    description="Next select the appropriate filters and chart type. These options vary depending upon the data source."
                />
                <FullScreenDialogCard
                    lgSize={6}
                    image={MakeReportStep3}
                    title="Create Report: Name and Save Report"
                    headline="Create Report: Name and Save Report"
                    description="Finally, you must name and save your report."
                />
                <FullScreenDialogCard
                    lgSize={6}
                    image={MakeDashboardStep1}
                    title="Create Dashboard: Click New Dashboard"
                    headline="Create Dashboard: Click New Dashboard"
                    description="Go to Community -> Dashboards and Click New Dashboard."
                />
                <FullScreenDialogCard
                    lgSize={6}
                    image={MakeDashboardStep2}
                    title="Create Dashboard: Select Title, Description, and Organization."
                    headline="Create Dashboard: Select Title, Description, and Organization."
                    description="Next select the appropriate Title, Description, and Organization."
                />
                <FullScreenDialogCard
                    lgSize={6}
                    image={MakeDashboardStep3}
                    title="Create Dashboard: Add Reports"
                    headline="Create Dashboard: Add Reports"
                    description="Finally, add as many reports to the dashboard as you like."
                />
            </Grid>
        </>
    );
}

export default AdminGPSOverview;
