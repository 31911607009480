import { CheckBoxRounded, RadioButtonChecked } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import AssetPreviewCard from 'components/Cards/AssetPreviewCard';
import { isEmpty } from 'lodash';
import React from 'react';
import { Asset, Form, getHtml, SubmissionQuestion } from 'utils';

export interface AssessmentSubmissionPreviewProps {
    form: Form;
    submittedAnswers?: Array<SubmissionQuestion>;
    onQuestionAnswerEdited?: (questionUuid: string) => void;
    view?: boolean | null;
}

const AssessmentSubmissionPreview = ({
    form,
    submittedAnswers,
    onQuestionAnswerEdited,
    view,
}: AssessmentSubmissionPreviewProps) => {
    const totalQuestions = form.sections
        .map((section) => section.questions.length)
        .reduce((sum, noOfQuestions) => sum + noOfQuestions, 0);

    return (
        <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            style={{ gap: 6 }}
            mt={10}
            mb={30}
        >
            {!view && (
                <Typography variant="body2">
                    Please review your answers below before submitting the assessment.
                </Typography>
            )}
            {form?.sections?.length ? (
                form?.sections?.map((section, sectionIndex) => {
                    // Convert markdown to HTML
                    const descriptionHTML: string = getHtml(section.description || '');

                    return (
                        <React.Fragment key={section.uuid}>
                            <Typography variant="h6">Section {sectionIndex + 1}</Typography>
                            <Box mb={10}>
                                <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                                    {section.name}
                                </Typography>
                            </Box>
                            <Box pt={5} pb={5} textAlign="left">
                                <div dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
                            </Box>
                            {!isEmpty(section?.asset) && (
                                <AssetPreviewCard
                                    asset={section.asset as Asset}
                                    altTitle={section.name}
                                    onAssetRemoved={() => {}}
                                    disableRemove={true}
                                    fullwidth={true}
                                />
                            )}
                            {totalQuestions > 0 ? (
                                section.questions.map((question, questionIndex) => {
                                    return (
                                        <Box
                                            key={question.uuid}
                                            display="flex"
                                            flexDirection="column"
                                            style={{ gap: 20 }}
                                            textAlign="left"
                                        >
                                            <Typography variant="h6">
                                                Question {questionIndex + 1}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                style={{ whiteSpace: 'pre-wrap' }}
                                            >
                                                {question.question}{' '}
                                                {!view && (
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        color="primary"
                                                        sx={(theme) => ({
                                                            float: 'right',
                                                            marginRight: theme.spacing(4),
                                                        })}
                                                        onClick={() =>
                                                            onQuestionAnswerEdited &&
                                                            onQuestionAnswerEdited(
                                                                question?.uuid || '',
                                                            )
                                                        }
                                                    >
                                                        Edit Answer
                                                    </Button>
                                                )}
                                            </Typography>
                                            {view && question?.choices && (
                                                <Typography component="span" variant="body2">
                                                    {question.choices.map((choice) => {
                                                        return (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    '& > :first-of-type': {
                                                                        marginRight: '10px',
                                                                    },
                                                                }}
                                                                key={choice.uuid}
                                                            >
                                                                {question.type ===
                                                                'multiple-choice-multi-select' ? (
                                                                    <CheckBoxRounded />
                                                                ) : (
                                                                    <RadioButtonChecked />
                                                                )}
                                                                {choice.text}
                                                            </Box>
                                                        );
                                                    })}
                                                </Typography>
                                            )}
                                            {!isEmpty(question?.asset) && (
                                                <AssetPreviewCard
                                                    asset={question.asset as Asset}
                                                    altTitle={question.question}
                                                    onAssetRemoved={() => {}}
                                                    disableRemove={true}
                                                />
                                            )}
                                            <Typography variant="body2">
                                                Your answer :{' '}
                                                {submittedAnswers?.find(
                                                    (sumittedAnswer) =>
                                                        sumittedAnswer.question == question.uuid,
                                                )?.answer || 'No Answer'}
                                            </Typography>
                                            <Divider />
                                        </Box>
                                    );
                                })
                            ) : (
                                <Typography variant="body2">
                                    This assessment does not contain any questions.
                                </Typography>
                            )}
                        </React.Fragment>
                    );
                })
            ) : (
                <Typography variant="body2">
                    This assessment does not contain any sections.
                </Typography>
            )}
        </Box>
    );
};

export default AssessmentSubmissionPreview;
