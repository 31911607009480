import * as React from 'react';
import { Grid, Typography } from '@mui/material';

import SQLAccess from '../media/SQLAccess.png';
import PremiumFeatures from '../media/PremiumFeatures.png';
import Connecting3rdPartyApps from '../media/Connecting3rdPartyApps.png';
import InvitingOrganizationalUsers from '../media/InvitingOrganizationalUsers.png';
import CreateOrganization from '../media/CreateOrganization.png';
import FullScreenDialogCard from '../components/HowToDialogCard';

function AIVideoOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL allows organizations to manage high level functions like adding
                        organizations, inviting organizational users, connecting 3rd party apps,
                        accessing premium features, and connecting to your SQL database.
                    </Typography>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={CreateOrganization}
                    title="Adding a New Organization"
                    headline=""
                    description="1TUL allows you to easily manage multiple organizations."
                />

                <FullScreenDialogCard
                    image={InvitingOrganizationalUsers}
                    title="Inviting Organizational Users"
                    headline=""
                    description="1TUL allows you to invite organizational users to your organization and set their permissions to best match their role."
                />
                <FullScreenDialogCard
                    image={Connecting3rdPartyApps}
                    title="Connecting 3rd Party Apps"
                    headline=""
                    description="1TUL allows you to connect to 3rd party apps like 1080 Motion, Stripe, and more comming soon."
                />

                {/* First row */}
                <FullScreenDialogCard
                    image={PremiumFeatures}
                    title="Accessing Premium Features"
                    headline=""
                    description="1TUL allows you to access premium features like AI Video Analysis and SQL Access."
                />
                <FullScreenDialogCard
                    image={SQLAccess}
                    title="SQL Access"
                    headline=""
                    description="If you have intermediate SQL skills, you can access your data directly from your SQL database."
                />
            </Grid>
        </>
    );
}

export default AIVideoOverview;
