import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ url: string }>>;

const athleteUpdatePaymentMethod = (return_url: string): ReturnType => {
    return Request.post(`/athlete-users/billing/update-payment-method`, {
        return_url,
    });
};
export default athleteUpdatePaymentMethod;
