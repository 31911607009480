import { AxiosResponse } from 'axios';
import { Request } from 'api';

type ReturnType = Promise<AxiosResponse<Array<any>>>;

const deleteFromFeed = (activity: string): ReturnType => {
    return Request.post(`/workouts/activities/${activity}/remove-from-social-feed`, {});
};

export default deleteFromFeed;
