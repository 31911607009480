import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from '@mui/lab';
import { Button, IconButton, Typography } from '@mui/material';
import React, { FC } from 'react';

type LoadMoreProps = {
    isLoading: boolean;
    handleLoadMore: () => void;
};

const LoadMore: FC<React.PropsWithChildren<LoadMoreProps>> = ({ isLoading, handleLoadMore }) => {
    return (
        <TimelineItem
            sx={{
                '&:before': {
                    flex: 0,
                },
            }}
        >
            <TimelineSeparator
                sx={{
                    '& > .MuiTimelineConnector-root': {
                        display: 'none',
                    },
                }}
            >
                <TimelineDot sx={{ border: 'none', backgroundColor: '#fff' }} color="inherit">
                    <IconButton
                        disabled={isLoading}
                        sx={{ width: 15, height: 15, padding: 10 }}
                        onClick={handleLoadMore}
                        size="large"
                    >
                        <UnfoldMoreIcon color="primary" />
                    </IconButton>
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Button disabled={isLoading} size="small" onClick={handleLoadMore}>
                    <Typography
                        sx={{ fontSize: 15, fontWeight: 600, marginTop: 4, textTransform: 'none' }}
                        color="primary"
                    >
                        {!isLoading && 'See more'}
                        {isLoading && 'Loading...'}
                    </Typography>
                </Button>
            </TimelineContent>
        </TimelineItem>
    );
};

export { LoadMore };
