import { FormDialog } from './index';
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { createItem } from '../../api/marketplace.api';
import { Organization } from '../../utils';
import { MarketplaceItem } from '../../app.types';

interface Props {
    open: boolean;
    onClose: () => void;
    onSave: (item: MarketplaceItem) => void;
    onError: (message: string) => void;
    title?: string;
    organization: Organization;
    uuid: string;
    type: 'session' | 'form';
}

const CreateMarketplaceItemDialog = ({
    open,
    onClose,
    onSave,
    onError,
    title,
    uuid,
    type,
    organization,
}: Props) => {
    const { values, errors, handleChange, setFieldValue, submitForm } = useFormik({
        initialValues: {
            price: '1.99',
            sales_type: 'business_to_business',
        },
        validationSchema: yup.object({
            price: yup.string().required('Setting a price is required'),
        }),
        onSubmit: async () => {
            try {
                const response = await createItem(
                    organization.uuid,
                    uuid,
                    type,
                    parseFloat(values.price) * 100,
                    values.sales_type,
                );
                onSave(response.data);
            } catch (e: any) {
                // if it's a 422, convert to a readable message
                console.log(e.response);
                if (e.response.status === 422) {
                    console.log('on error');
                    onError(e.response.data.message);
                }
            }
        },
    });

    const handleSalesTypeChange = (event: any) => {
        setFieldValue('sales_type', event.target.value);
    };

    return (
        <FormDialog
            handleButtonClicked={() => submitForm()}
            loading={false}
            handleClose={onClose}
            disabled={false}
            open={open}
            buttonText="Create Marketplace Item"
            cancelText="Close"
            title={title || 'Create Marketplace Item'}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Stack sx={{ my: 8 }} spacing={8}>
                    <TextField
                        autoFocus={true}
                        inputProps={{
                            pattern: '[-.0-9]*',
                            inputMode: 'numeric',
                            type: 'text',
                        }}
                        fullWidth
                        id="price"
                        name="price"
                        label="Price"
                        placeholder="0.00"
                        value={values.price}
                        onChange={handleChange}
                        error={Boolean(errors.price)}
                        helperText={errors.price as string}
                    />
                </Stack>
                <FormControl fullWidth>
                    <InputLabel>Sales type</InputLabel>
                    <Select
                        label="Sales type"
                        variant={'outlined'}
                        fullWidth
                        value={values.sales_type}
                        onChange={handleSalesTypeChange}
                    >
                        <MenuItem value={'business_to_business'}>
                            Organization to Organization
                        </MenuItem>
                        <MenuItem value={'business_to_customer'}>Organization to Athlete</MenuItem>
                    </Select>
                </FormControl>
            </form>
        </FormDialog>
    );
};

export default CreateMarketplaceItemDialog;
