import { Meta } from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SchoolIcon from '@mui/icons-material/School';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {
    Avatar,
    Box,
    Button,
    Container,
    Drawer,
    Fab,
    IconButton,
    LinearProgress,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Snackbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { cubejsApi } from 'app.functions';
import { Field, Form, Formik } from 'formik';
import { TextField as MuiTextField } from 'formik-mui';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { TypographyTitleh6 } from '../../../components';
import EmptyState from '../../../components/Feedback/EmptyStates/EmptyState';
import { AbilityContext } from '../../../components/Functional/Can';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import { createDashboard, getAllDashboards } from '../api/dashboards.api';
import { renderOrganizationMenuItem } from '../community.render';
import { Dashboard } from '../community.types';
import ViewDashboard from './ViewDashboard';

function CommunityDashboards() {
    let { path } = useRouteMatch();
    const [createLoading, setCreateLoading] = useState<boolean>(false);
    const [cubeMeta, setCubeMeta] = useState<Meta>();
    const [message, setMessage] = useState<string>('');
    const { dashboardId } = useParams<{ dashboardId: string }>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { organizations } = useContext(OrganizationsContext);
    const currentRoute = useRouteMatch();
    let { push } = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [dashboards, setDashboards] = useState<Dashboard[]>([]);
    const ability = useContext(AbilityContext);

    useEffect(() => {
        cubejsApi
            .meta()
            .then((result: Meta) => {
                setCubeMeta(result);
            })
            .catch(() => setMessage('Unable to business intelligence tools'));
    }, []);
    useEffect(() => {
        setLoading(true);
        getAllDashboards()
            .then((dashboards) => {
                setDashboards(dashboards.data);
                setLoaded(true);
                if (dashboards.data.length > 0) {
                    // push(`/community/dashboards/${dashboards.data[0].uuid}`);
                }
            })
            .catch(() => setMessage('Error: We had trouble loading your dashboards. Try again.'))
            .finally(() => setLoading(false));
    }, [push]);

    return (
        <CubeProvider cubejsApi={cubejsApi}>
            {loading && <LinearProgress />}
            <Container maxWidth="xl" style={{ paddingBottom: 64, paddingLeft: 0, paddingRight: 0 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 24,
                        borderBottom: '1px solid grey',
                        backgroundColor: 'white',
                        [theme.breakpoints.down('lg')]: {
                            flexDirection: 'column',
                            paddingTop: 6,
                            paddingBottom: 6,
                            paddingRight: 10,
                            paddingLeft: 10,
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ flex: '1 1 0%', marginTop: 10, marginBottom: 6 }}></Box>
                        {loaded && (
                            <Select
                                onChange={(e) => {
                                    push(`/community/dashboards/${e.target.value}`);
                                }}
                                value={dashboardId ?? ''}
                                variant="outlined"
                                sx={{
                                    minWidth: 300,
                                    maxWidth: 700,
                                    marginRight: 8,
                                    '& .MuiSelect-select': { display: 'flex' },
                                }}
                            >
                                {dashboards.map((d) => (
                                    <MenuItem key={d.uuid} value={d.uuid}>
                                        {d.organization && (
                                            <ListItemAvatar>
                                                <Avatar
                                                    variant={'square'}
                                                    src={d.organization.image_urls['avatar'] ?? ''}
                                                >
                                                    <SchoolIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                        )}
                                        <ListItemText primary={d.title} />
                                    </MenuItem>
                                ))}
                                <MenuItem value={'create-new'}>
                                    <ListItemText primary={'Create New Dashboard'} />
                                    <ListItemIcon>
                                        <AddIcon />
                                    </ListItemIcon>
                                </MenuItem>
                            </Select>
                        )}
                        <Box sx={{ '& > :not(style)': { m: 1 }, marginLeft: 8 }}>
                            <Fab
                                variant="extended"
                                size="medium"
                                color="primary"
                                aria-label="add"
                                onClick={() => {
                                    push(`${currentRoute.url}/create-new`);
                                }}
                                value={'create-new'}
                            >
                                <AddIcon />
                                New Dashboard
                            </Fab>
                        </Box>
                    </Box>
                </Box>
                {loaded && dashboards.length === 0 && (
                    <EmptyState
                        title={'No Dashboards Yet'}
                        phrase={'Add reports to a dashboard under reports'}
                        icon={<TrendingUpIcon />}
                    />
                )}
                {loaded && dashboards.length > 0 && !dashboardId && (
                    <EmptyState
                        title={'Choose A Dashboard'}
                        phrase={'Choose a dashboard to view'}
                        icon={<TrendingUpIcon />}
                    />
                )}

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={!!message}
                    autoHideDuration={1500}
                    onClose={() => setMessage('')}
                    message={message}
                    action={
                        <React.Fragment>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => setMessage('')}
                            >
                                <CloseIcon />
                            </IconButton>
                        </React.Fragment>
                    }
                />
                <Switch>
                    <Route path={`${path}/create-new`}>
                        <Drawer
                            PaperProps={{ style: { width: isMobile ? '100%' : '60%' } }}
                            anchor={'right'}
                            open={true}
                        >
                            <Formik
                                onSubmit={(values) => {
                                    setCreateLoading(true);
                                    createDashboard({
                                        title: values.title,
                                        description: values.description,
                                        organization: values.organization,
                                    })
                                        .then(({ data }) => {
                                            setDashboards((dashboards) => [data, ...dashboards]);
                                            push(`${currentRoute.url}/${data.uuid}`);
                                        })
                                        .catch(() => {
                                            setMessage(
                                                'Error: We had an error while creating the dashboard',
                                            );
                                        })
                                        .finally(() => setCreateLoading(false));
                                }}
                                initialValues={{
                                    title: '',
                                    description: '',
                                    organization: '',
                                }}
                            >
                                {({ values, handleChange }) => (
                                    <Form
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            overflowY: 'scroll',
                                        }}
                                    >
                                        {createLoading && <LinearProgress />}
                                        <Box
                                            display="flex"
                                            alignItems={'center'}
                                            justifyContent="space-between"
                                            style={{ borderBottom: '1px solid grey', padding: 20 }}
                                        >
                                            <Box display="flex" alignItems="center">
                                                <TrendingUpIcon />
                                                <TypographyTitleh6
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        alignItems: 'center',
                                                        marginLeft: 8,
                                                    }}
                                                >
                                                    Create New Dashboard
                                                </TypographyTitleh6>
                                            </Box>
                                            <IconButton
                                                onClick={() => {
                                                    push(`${currentRoute.url}`);
                                                }}
                                                aria-label="close"
                                                size="medium"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>

                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            alignItems={'flex-start'}
                                            p={8}
                                            borderBottom={'1px solid grey'}
                                            flex={1}
                                        >
                                            <Typography
                                                variant={'body1'}
                                                style={{ marginBottom: 8 }}
                                            >
                                                Give this dashboard a title and a description
                                            </Typography>
                                            <Field
                                                name="title"
                                                fullWidth
                                                label="Title"
                                                variant="filled"
                                                placeholder={'e.g Workout Summary'}
                                                disabled={createLoading}
                                                component={MuiTextField}
                                            />
                                            <Field
                                                style={{ marginTop: 8 }}
                                                name="description"
                                                fullWidth
                                                id="description"
                                                label="Short Description (optional)"
                                                variant="filled"
                                                disabled={createLoading}
                                                placeholder={
                                                    'e.g Shows reports for workout activity'
                                                }
                                                component={MuiTextField}
                                            />
                                            <Select
                                                fullWidth
                                                name="organization"
                                                id="organizations"
                                                label="Choose An Organization (optional)"
                                                variant="filled"
                                                disabled={createLoading}
                                                value={values.organization}
                                                onChange={handleChange}
                                                displayEmpty
                                                sx={{
                                                    paddingTop: 16,
                                                    display: 'flex',
                                                    marginTop: 4,
                                                }}
                                            >
                                                <MenuItem key="none" value={''}>
                                                    No Organization Selected
                                                </MenuItem>
                                                {organizations
                                                    .filter((o) =>
                                                        ability.can(
                                                            'organization:create-dashboards',
                                                            o,
                                                        ),
                                                    )
                                                    .map((o) => renderOrganizationMenuItem(o))}
                                            </Select>
                                        </Box>
                                        <Box
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            width={'100%'}
                                            p={8}
                                            style={{ flexShrink: 1 }}
                                        >
                                            <Button
                                                variant={'outlined'}
                                                onClick={() => {
                                                    push(`/community/dashboards`);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                disabled={createLoading}
                                                variant={'contained'}
                                                color={'primary'}
                                                type={'submit'}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Drawer>
                    </Route>
                    <Route exact path={`${path}/:dashboardId`}>
                        {cubeMeta && (
                            <ViewDashboard
                                cubeMeta={cubeMeta}
                                onDelete={(dashboardId) => {
                                    setDashboards((dashboards) =>
                                        dashboards.filter((d) => d.uuid !== dashboardId),
                                    );
                                    push(`/community/dashboards`);
                                }}
                            />
                        )}
                    </Route>
                </Switch>
            </Container>
        </CubeProvider>
    );
}

export default CommunityDashboards;
