import ChooseItemModalLayout from './ChooseItemModalLayout';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { Session, useToggle } from 'utils';
import { RowDivider } from 'components/Separators';
import { debounce } from 'lodash';
import { getAllSessions } from '../../api';
import { CreateSessionModal } from '.';

interface ChooseSessionProps {
    activeOrganizationId: string | undefined;
    open: boolean;
    onClose: () => void;
    onClickSession: (session: Session) => void;
    allowCreate?: boolean;
}

const ChooseSessionModal = ({
    activeOrganizationId,
    open,
    onClose,
    onClickSession,
    allowCreate = false,
}: ChooseSessionProps) => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [filter, setFilter] = useState('');
    const [data, setData] = useState<Array<Session>>([]);
    const [openCreateSessionDiaglogue, toggleOpen] = useToggle(false);

    const debounceLoadData = debounce(setFilter, 400);

    const onClick = (session: Session) => {
        if (session.organization.uuid !== activeOrganizationId) {
            return;
        }
        onClickSession(session);
        onClose();
    };

    const handleChange = (value: string): void => {
        setSearchValue(value);
        debounceLoadData(value);
    };

    const addNewSession = () => {
        toggleOpen();
    };

    const loadSessions = useCallback(
        (query: { 'filter[title]'?: string }): void => {
            getAllSessions({
                ...query,
                'filter[organization]': activeOrganizationId || '',
                limit: 50,
            }).then((response) => setData(response.data.data));
        },
        [activeOrganizationId],
    );

    const createNewSession = (created: boolean) => {
        toggleOpen();
        if (created) {
            loadSessions({
                'filter[title]': filter,
            });
        }
    };

    useEffect(() => {
        if (filter.length > 0) {
            loadSessions({
                'filter[title]': filter,
            });
        }
    }, [dispatch, filter, loadSessions]);

    useEffect(() => {
        loadSessions({
            'filter[title]': '',
        });
    }, [loadSessions]);

    return (
        <>
            <ChooseItemModalLayout
                open={open}
                onClose={onClose}
                buttonText={allowCreate ? 'Create Session' : ''}
                onClickButton={allowCreate ? addNewSession : undefined}
                searchHeading="Session List"
                searchValue={searchValue}
                setSearchValue={handleChange}
                noResultText="No results"
                searchResults={[]}
                searchBarPlaceHolder="Add a Session"
            >
                {data.map((session) => {
                    const disabled = session.organization.uuid !== activeOrganizationId;
                    return (
                        <Fragment key={session.uuid}>
                            <Box
                                aria-disabled={disabled}
                                bgcolor={disabled ? 'grey.100' : 'white'}
                                color={disabled ? 'text.disabled' : 'text.primary'}
                                sx={{
                                    bgcolor: disabled ? 'grey.100' : 'white',
                                    color: disabled ? 'text.disabled' : 'text.primary',
                                    cursor: disabled ? 'not-allowed' : 'pointer',
                                    padding: 8,
                                    paddingLeft: 16,
                                }}
                                onClick={() => {
                                    onClick(session);
                                }}
                                onKeyDown={() => {
                                    onClick(session);
                                }}
                                p={8}
                                pl={16}
                                role="button"
                                tabIndex={disabled ? -1 : 0}
                            >
                                <Typography>{`${session.title}`}</Typography>
                                <Typography variant="caption">
                                    {session.organization.name}
                                </Typography>
                            </Box>
                            <RowDivider />
                        </Fragment>
                    );
                })}
            </ChooseItemModalLayout>
            <CreateSessionModal
                open={openCreateSessionDiaglogue}
                defaultTitle={searchValue}
                onClose={createNewSession}
            />
        </>
    );
};

export default ChooseSessionModal;
