import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { AttributeValue, Member } from 'utils';

type ReturnType = Promise<AxiosResponse<Member>>;

const createMember = (
    group: string,
    payload: {
        first_name: string;
        last_name: string;
        attributes: Array<AttributeValue>;
        member_type: {
            name: string;
            uuid: string;
        };
        individual?: string | null;
        profile_photo?: string;
    },
): ReturnType => {
    return Request.post(`/groups/${group}/members/`, payload);
};

export default createMember;
