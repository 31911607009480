import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { ASSESSMENT_FILTER_OPTIONS, Form, Organization } from 'utils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    Box,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import RoundButton from 'components/Buttons/RoundButton';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import useTheme from '@mui/material/styles/useTheme';

interface Props {
    open: boolean;
    loading: boolean;
    updating: boolean;
    assessment: Form;
    organizations: Organization[];
    onChange: (attribute: string, value: string) => void;
    onClose: () => void;
    onSave: () => void;
}

const EditAssessmentDialog: FC<React.PropsWithChildren<Props>> = ({
    open,
    assessment,
    loading,
    updating,
    organizations,
    onChange,
    onClose,
    onSave,
}) => {
    const theme = useTheme();

    const initialTitle = assessment.title === 'Untitled Assessment' ? '' : assessment.title;
    const onChangeTitle = useCallback(
        (e: ChangeEvent<any>) => {
            onChange('title', e.currentTarget.value);
        },
        [onChange],
    );
    const initialOrganization =
        organizations.find((o) => o.name === assessment.organization.name)?.name || '';
    const [organization, setOrganization] = useState<string>(initialOrganization);
    const onChangeOrganization = useCallback(
        (e: SelectChangeEvent<string>) => {
            onChange('organization', e.target.value);
            setOrganization(e.target.value);
        },
        [onChange],
    );
    const initialType =
        ASSESSMENT_FILTER_OPTIONS.find((filterOption) => {
            return filterOption.value === assessment?.type;
        })?.value ?? 'other';
    const [type, setType] = useState<string>(initialType);
    const onChangeType = useCallback(
        (e: SelectChangeEvent<any>) => {
            setType(e.target.value);
            onChange('type', e.target.value);
        },
        [onChange],
    );

    return (
        <Dialog aria-labelledby="edit-title" open={open}>
            <DialogTitle id="edit-title">
                <Box textAlign="center" color="#003E9D" mt={16}>
                    Edit
                </Box>
            </DialogTitle>
            <DialogContent
                sx={{
                    [theme.breakpoints.up('sm')]: {
                        width: 480,
                    },
                }}
            >
                <Grid container>
                    {/* Title Text */}
                    <Grid item xs={12}>
                        <Box mb={8}>
                            <TextField
                                fullWidth
                                name="title"
                                variant="filled"
                                label="Title"
                                defaultValue={initialTitle}
                                onChange={onChangeTitle}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    },
                                    // horizontally align the label with the select options
                                    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
                                        transform: 'translate(0px, 10px) scale(0.75)',
                                    },
                                }}
                            />
                        </Box>
                    </Grid>

                    {/* Organization Select */}
                    <Grid item xs={12}>
                        <Box mb={8}>
                            <InputLabel shrink id="organization-label">
                                Organization
                            </InputLabel>
                            <Select
                                fullWidth
                                labelId="organization-label"
                                disabled={loading || !organizations.length}
                                value={organization}
                                onChange={onChangeOrganization}
                            >
                                <MenuItem value="" disabled>
                                    Organization Name
                                </MenuItem>
                                {organizations.map(({ uuid, name }) => {
                                    return (
                                        <MenuItem key={uuid} value={name}>
                                            {name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Box>
                    </Grid>

                    {/* Category Select */}
                    <Grid item xs={12}>
                        <Box mb={8}>
                            <InputLabel shrink id="type-label">
                                Category
                            </InputLabel>
                            <Select
                                fullWidth
                                labelId="type-label"
                                value={type}
                                onChange={onChangeType}
                            >
                                <MenuItem value="sport-specific">Sports Specific</MenuItem>
                                <MenuItem value="athletic-training">Athletic Training</MenuItem>
                                <MenuItem value="learning-assessments">
                                    Learning Assessments
                                </MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ paddingBottom: 50, margin: '0 auto' }}>
                <RoundButton variant="outlined" color="primary" onClick={onClose}>
                    Close
                </RoundButton>
                <RoundButton
                    disabled={updating}
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                    endIcon={updating ? <CircularProgress size={20} /> : <SaveIcon />}
                >
                    {updating ? 'Saving' : 'Save Changes'}
                </RoundButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditAssessmentDialog;
