import { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { SimplePaginateResponse } from '../../../app.types';
import { TimelineEntries } from '../../../utils';

export interface AthleteTimelineParams {
    'filter[athlete]'?: string;
    'filter[organization_source_uuid]'?: string;
}

export function getAthleteTimeline(
    params: AthleteTimelineParams,
): Promise<AxiosResponse<SimplePaginateResponse<TimelineEntries>>> {
    return Request.get(`athletes/timeline`, { params });
}
