import * as React from 'react';
import { ReactNode, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import OneTulOverview from './OneTulOverview';
import WhatIs1Tul from './WhatIs1Tul';
import Integrations from './Integrations';
import SprintAnalysisOverview from './SprintAnalysisOverview';
import AIVideoOverview from './AIVideoOverview';
import SprintCroppingOverview from './SprintCroppingOverview';
import HowWeDifferent from './HowWeDifferent';
import ForAthletes from './ForAthletes';
import AthleteRecieveAssignment from './AthleteReceiveAssignments';
import AthleteLoggingAssignment from './AthleteLoggingAssignment';
import OneTulForAdmins from './OneTulForAdmins';
import AdminGPSOverview from './AdminGPSOverview';
import ImportingRawGPSData from './ImportingRawGPSData';
import SingleRawDataImport from './SingleRawDataImport';
import MultiRawDataImportToGroup from './MultiRawDataImportToGroup';
import WorkingWithRawGPSData from './WorkingWithRawGPSData';
import CroppingSprints from './CroppingSprints';
import AISprintVideoSetup from './AISprintAnalysisVideoSetup';
import AdminTrainData from './AdminTrainData';
import AdminCreateNewMovement from './AdminCreateNewMovement';
import AdminGroupManagement from './AdminGroupManagement';
import AdminCreateNewGroup from './AdminCreateNewGroup';
import AdminAddMembersToGroup from './AdminAddMembersToGroup';
import AdminAssignCoachesToGroup from './AdminAssignCoachesToGroup';
import AdminAssigningGroupContent from './AdminAssigningGroupContent';
import AdminLearningContentOverview from './AdminLearningContentOverview';
import AdminCreateLearningContent from './AdminCreateLearningContent';
import AdminAssignLearningContent from './AdminAssignLearningContent';
import AthleteTrackProgress from './AthleteTrackProgress';
import AdminDatabaseViews from './AdminDatabaseViews';
import AdminTrainDatabase from './AdminTrainDatabase';
import AdminSprintDatabase from './AdminSprintDatabase';
import AthleteCompleteLearningAssignment from './AthleteCompleteLearningAssignment';
import AdminExploreSprintCropping from './AdminExploreSprintCropping';
import AdminAutomatedSprintExtraction from './AdminAutomatedSprintExtraction';
import AdminTimeSegmentAnalysis from './AdminTimeSegmentAnalysis';
import AdminGPSSessionSummaryImport from './AdminGPSSessionSummaryImport';
import AdminGPSSessionSummaryMcLloydExport from './AdminGPSSessionSummaryMcLloydExport';
import AdminGPSRawDataMcLloydExport from './AdminGPSRawDataMcLloydExport';
import AdminRawGPSSessionSummary from './AdminRawGPSSessionSummary';
import AdminCreatingSession from './AdminCreatingSession';
import AdminAssignSessionToAthlete from './AdminAssignSessionToAthlete';
import AdminAssignSessionToGroup from './AdminAssignSessionToGroup';
import AdminAssignLearnContentToAthletes from './AdminAssignLearnContentToAthletes';
import AdminTimeSegmentsDB from './AdminTimeSegmentsDB';
import AdminVideoAnalysisDB from './AdminVideoAnalysisDB';
import Admin1080DBView from './Admin1080DBView';
import AdminGPSSummaryDBView from './AdminGPSSummaryDBView';
import AdminExploreOverview from './AdminExploreOverview';
import AdminExploreSprintCrop from './AdminExploreSprintCrop';
import AdminExploreLeaderboard from './AdminExploreLeaderboard';
import AdminExploreAthleteProfiles from './AdminExploreAthleteProfiles';
import AdminExploreAthleteProgress from './AdminExploreAthleteProgress';
import AdminReportsAndDashboardsOverview from './AdminReportsAndDashboardsOverview';
import AdminCreateReport from './AdminCreateReport';
import AdminCreateDashboardAndShare from './AdminCreateDashboardAndShare';
import AdminShareDashboardWithGroup from './AdminShareDashboardWithGroup';
import AdminRemoveUserFromIndividual from './AdminRemoveUserFromIndividual';
import DifferentExtremeScalability from './DifferentExtremeScalability';
import DifferentShareContentWithGroups from './DifferentShareContentWithGroups';
import CoachesGroupLog from './CoachesGroupLog';
import CoachesOverview from './CoachesOverview';
import AdminAssignCoachesToOrganization from './AdminAssigningCoachesToOrganization';
import AdminInvitingOrganizationalUsers from './AdminInvitingOrganizationalUsers';
import AdminConnect3rdPartyApps from './AdminConnect3rdPartyApps';
import AdminOrganizationManagementOverview from './AdminOrganizationalManagementOverview';
import AdminUpdateOrgLogo from './AdminUpdateOrgLogo';
import RemoteCoachingAtScale from './DifferentRemoteCoachingAtScale';
import MclloydLogo from '../../../images/McLloydFlame.png';
import PlayertekLogo from '../media/PlayertekLogo.png';
import temLogo from '../media/1080Sprint2.png';
import McLloydUnit from '../media/McLloydUnit.png';
import Money from '../media/money.png';
import AdminMcLloydHelpCenterOverview from './AdminMcLloydHelpCenterOverview';
import AdminGPSMcLloydDownloadVideo from './AdminGPSMcLloydDownloadVideo';
import AdminGPSMcLloydAdminSettings from './AdminGPSMcLloydAdminSettings';
import AdminGPSPlayertek from './AdminGPSPlayertek';
import AdminTEMApi from './temAPI';
import AdminMarketPlaceOverview from './AdminMarketPlaceOverview';
import AdminArchivingGroups from './AdminArchivingGroups';
import AdminArchivingSessions from './AdminArchivingSessions';
import BillingReturnsCancellations from './BillingReturnsCancellations';
import AthleteAppDownload from './AthleteAppDownload';

const StyledTreeItemHeader = styled(TreeItem)(({ theme }) => ({
    '&& .MuiTreeItem-label': {
        fontSize: '24px',
        fontWeight: 'bold',
        color: theme.palette.text.primary,
    },
}));
const StyledTreeItemSubHeader = styled(TreeItem)(({ theme }) => ({
    '&& .MuiTreeItem-label': {
        fontSize: '22px',
        fontWeight: 525,
        color: theme.palette.text.primary,
        position: 'relative',
        paddingLeft: '1em', // Adjust the spacing as needed
        '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            width: '4px', // Adjust the width of the bullet point
            height: '4px', // Adjust the height of the bullet point
            background: 'currentColor', // Use the text color as the bullet color
            borderRadius: '50%',
        },
    },
}));

const StyledTreeItem2ndSubHeader = styled(TreeItem)(({ theme }) => ({
    '&& .MuiTreeItem-label': {
        fontSize: '18px',
        fontWeight: 475,
        color: theme.palette.text.primary,
    },
}));
const StyledTreeItem3rdSubHeader = styled(TreeItem)(({ theme }) => ({
    '&& .MuiTreeItem-label': {
        fontSize: '18px',
        fontWeight: 400,
        color: theme.palette.text.primary,
    },
}));

interface PaperGradientProps {
    children: ReactNode;
}

type NodeMappingType = {
    [key: string]: string;
};

function PaperGradient({ children }: PaperGradientProps) {
    return (
        <Paper
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: '#D9DADF',
                borderRadius: 6,
                border: '1px solid #A7ABB7',
                background: 'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                padding: 10,
            }}
        >
            {children}
        </Paper>
    );
}

export default function InteractiveList() {
    const [selectedSideBarName, setSelectedSideBarName] = useState('');

    // This function will handle the onNodeSelect event

    const nodeIdToLabelMapping: NodeMappingType = {
        '1': '1TUL Overview',
        '2': 'What is 1TUL?',
        '3': 'Integrations',
        '4': 'Sprint Analysis',
        '4.1': 'Sprint Cropping',
        '5': 'AI Video Analysis',
        '6': 'The 1TUL Edge',
        '7': 'Extreme Scaleability',
        '7.1': 'Share Content with Groups',
        '7.2': 'Remote Coaching At-Scale',
        '8': 'Multi-Organization Management',
        '9': 'Sprint Drill Down',
        '10': 'Built Around Speed Analytics',
        '11': '1TUL for Athletes',
        '12': 'Receiving Assignments',
        '12.1': 'FiyrPod for Athletes',
        '12.2': 'Athlete Billing, Returns, Cancellations',
        '12.3': 'Athlete App Download',
        '13': 'Logging a Workout',
        '14': 'Completing a Learning Assignment',
        '15': 'Tracking Your Progress',
        '16': '1TUL for Admins',
        '16.1': 'Marketplace',
        '17': 'GPS',
        '17.1': '1080 Motion',
        '18': 'Importing Raw Data',
        '19': 'Single Raw Data Import',
        '20': 'Multi-Raw Data Import',
        '21': 'Importing Session Summary Data',
        '22': 'Exporting Session Summary - FiyrPod',
        '23': 'Importing Session Summary - FiyrPod',
        '23.1': 'FiyrPod Help Center',
        '23.2': 'Installing the FiyrPod Desktop App',
        '23.3': 'FiyrPod App Settings',
        '23.4': 'Playertek Help Center',
        '24': 'Working with Raw GPS Data',
        '25': 'Cropping Sprints',
        '26': 'Exploring Sprint Crops',
        '27': 'Raw GPS Session Summary',
        '28': 'Automated Sprint Extraction',
        '29': 'Time Segment Analysis',
        '30': 'AI Sprint Video Analysis',
        '31': 'AI Sprint Video Analysis Setup',
        '32': 'FiyrPod Raw Data Export',
        '33': 'Training Data',
        '34': 'Creating Movements',
        '35': 'Creating Sessions',
        '35.1': 'Archiving Sessions',
        '36': 'Assigning Sessions to Athletes',
        '36.5': 'Assigning Sessions to Groups',
        '37': 'Learning Content',
        '38': 'Creating Learning Content',
        '38.5': 'Assigning Learning Content to Athletes',
        '39': 'Assigning Learning Content to Groups',
        '40': 'Group Management',
        '40.0.1': 'Archiving Groups',
        '40.1': 'Organization Management',
        '40.2': 'Creating Organizations',
        '40.3': 'Adding Organizational Users',
        '40.4': 'Connecting 3rd Party Applications',
        '40.5': 'Premium Features',
        '40.6': 'Video AI',
        '40.7': 'Connecting Stripe',
        '40.8': 'SQL Access',
        '40.9': 'Update Organization Logo',
        '41': 'Creating Groups',
        '42': 'Adding Athletes',
        '42.1': 'Removing User from Individual',
        '43': 'Assigning Coaches to Group',
        '43.1': 'Assigning Coaches to Organization',
        '44': 'Assigning Group Content',
        '45': 'Database Views',
        '46': 'Training Database',
        '47': 'Sprint Database',
        '48': 'Time Segments Database',
        '49': 'Video Analysis Database',
        '50': '1080 Database',
        '51': 'GPS Summaries Database',
        '52': 'Explore',
        '53': 'GPS Sprint Cropping',
        '54': 'GPS Summary - Leaderboard',
        '55': 'GPS Summary - Athlete Profiles',
        '56': 'GPS Summary - Acute to Chronic Workload Ratio',
        '57': 'GPS Summary - Athlete Progress',
        '58': 'Reports and Dashboards',
        '59': 'Creating a Report',
        '60': 'Creating a Dashboard',
        '61': 'Share Dashboard with a Group',
        '62': '1TUL for Coaches',
        '63': 'Group Log',
        // ... add all other mappings
    };

    const handleNodeSelect = (event: React.SyntheticEvent, nodes: string[]) => {
        const selectedNodeId = nodes[0];
        const selectedLabel = nodeIdToLabelMapping[selectedNodeId];

        if (selectedLabel) {
            setSelectedSideBarName(selectedLabel);
        }
    };
    return (
        <Grid
            container
            spacing={1}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            padding={2}
        >
            <Grid item md={6} lg={3} xl={3} sx={{ margin: 1, height: 'auto' }}>
                <SimpleTreeView
                    sx={{ width: '100%', maxWidth: 460 }}
                    aria-label="multi-select"
                    slots={{ collapseIcon: ExpandMoreIcon, expandIcon: ChevronRightIcon }}
                    slotProps={{ collapseIcon: { fontSize: '45px' } }}
                    multiSelect
                    onSelectedItemsChange={handleNodeSelect}
                >
                    <StyledTreeItemHeader itemId="1" label="1TUL Overview">
                        <StyledTreeItemSubHeader itemId="2" label="What is 1TUL?" />
                        <StyledTreeItemSubHeader itemId="3" label="Integrations" />
                        <StyledTreeItemSubHeader itemId="4" label="Sprint Analysis">
                            <StyledTreeItem2ndSubHeader itemId="4.1" label="Sprint Cropping" />
                        </StyledTreeItemSubHeader>
                        <StyledTreeItemSubHeader itemId="5" label="AI Video Analysis" />
                    </StyledTreeItemHeader>
                    <StyledTreeItemHeader itemId="6" label="The 1TUL Edge">
                        <StyledTreeItemSubHeader itemId="7" label="Extreme Scaleability" />
                        <StyledTreeItemSubHeader itemId="7.1" label="Share Content with Groups" />
                        <StyledTreeItemSubHeader itemId="7.2" label="Remote Coaching At-Scale" />
                        {/*    <TreeItem nodeId="8" label="Multi-Organization Management" />*/}
                        {/*</TreeItem>*/}
                        {/*<TreeItem nodeId="9" label="Sprint Drill Down" />*/}
                        {/*<TreeItem nodeId="10" label="Built Around Speed Analytics" />*/}
                    </StyledTreeItemHeader>
                    <StyledTreeItemHeader itemId="11" label="1TUL for Athletes">
                        <StyledTreeItemSubHeader itemId="12" label="Receiving Assignments">
                            <StyledTreeItem2ndSubHeader itemId="13" label="Logging a Workout" />
                            <StyledTreeItem2ndSubHeader
                                itemId="14"
                                label="Completing a Learning Assignment"
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId="15"
                                label="Tracking Your Progress"
                            />
                        </StyledTreeItemSubHeader>
                        <StyledTreeItemSubHeader
                            itemId={'12.1'}
                            label={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={McLloydUnit}
                                        alt="McLloyd Logo"
                                        style={{
                                            width: '26px',
                                            height: '26px',
                                            marginRight: '5px',
                                        }}
                                    />
                                    FiyrPod for Athletes
                                </span>
                            }
                        >
                            <StyledTreeItem2ndSubHeader
                                itemId={'12.2'}
                                label={'Athlete Billing, Returns, Cancellations'}
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId={'12.3'}
                                label={'Athlete App Download'}
                            />
                        </StyledTreeItemSubHeader>
                    </StyledTreeItemHeader>
                    <StyledTreeItemHeader itemId="16" label="1TUL for Admins">
                        <StyledTreeItemSubHeader
                            itemId="16.1"
                            label={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={Money}
                                        alt="Money"
                                        style={{
                                            width: '26px',
                                            height: '26px',
                                            marginRight: '5px',
                                        }}
                                    />
                                    Marketplace
                                </span>
                            }
                        />
                        <StyledTreeItemSubHeader itemId="40.1" label="Organization Management">
                            {/*<TreeItem nodeId={'40.2'} label="Creating Organizations" />*/}
                            <StyledTreeItem2ndSubHeader
                                itemId={'40.3'}
                                label="Adding Organizational Users"
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId={'40.4'}
                                label="Connecting 3rd Party Applications"
                            />
                            {/* <TreeItem nodeId={'40.5'} label="Premium Features">
                                <TreeItem nodeId={'40.6'} label="Video AI" />
                                <TreeItem nodeId={'40.7'} label="Connecting Stripe" />
                                <TreeItem nodeId={'40.8'} label="SQL Access" />*/}
                            <StyledTreeItem2ndSubHeader
                                itemId={'40.9'}
                                label="Update Organization Logo"
                            />
                        </StyledTreeItemSubHeader>
                        <StyledTreeItemSubHeader
                            itemId="17"
                            label={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={McLloydUnit}
                                        alt="McLloyd Logo"
                                        style={{
                                            width: '26px',
                                            height: '26px',
                                            marginRight: '5px',
                                        }}
                                    />
                                    GPS
                                </span>
                            }
                        >
                            <StyledTreeItem2ndSubHeader itemId="18" label="Importing Raw Data">
                                <StyledTreeItem3rdSubHeader
                                    itemId="32"
                                    label="FiyrPod Raw Data Export"
                                />
                                <StyledTreeItem3rdSubHeader
                                    itemId="19"
                                    label="Single Raw Data Import"
                                />
                                <StyledTreeItem3rdSubHeader
                                    itemId="20"
                                    label="Multi-Raw Data Import"
                                />
                            </StyledTreeItem2ndSubHeader>
                            <StyledTreeItem2ndSubHeader
                                itemId="24"
                                label="Working with Raw GPS Data"
                            >
                                <StyledTreeItem3rdSubHeader
                                    itemId={'25'}
                                    label={'Cropping Sprints'}
                                />
                                <StyledTreeItem3rdSubHeader
                                    itemId={'26'}
                                    label={'Exploring Sprint Crops'}
                                />
                                <StyledTreeItem3rdSubHeader
                                    itemId={'27'}
                                    label={'Session Summary'}
                                />
                                <StyledTreeItem3rdSubHeader
                                    itemId={'28'}
                                    label={'Automated Sprint Extraction'}
                                />
                                <StyledTreeItem3rdSubHeader
                                    itemId={'29'}
                                    label={'Time Segment Analysis'}
                                />
                            </StyledTreeItem2ndSubHeader>
                            <StyledTreeItem2ndSubHeader
                                itemId="21"
                                label="Importing Session Summary Data"
                            >
                                <StyledTreeItem3rdSubHeader
                                    itemId="22"
                                    label="Exporting Session Summary - FiyrPod"
                                />
                                <StyledTreeItem3rdSubHeader
                                    itemId="23"
                                    label="Importing Session Summary - FiyrPod"
                                />
                            </StyledTreeItem2ndSubHeader>
                            <StyledTreeItem2ndSubHeader
                                itemId="23.1"
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={MclloydLogo}
                                            alt="McLloyd Logo"
                                            style={{
                                                width: '26px',
                                                height: '26px',
                                                marginRight: '5px',
                                            }}
                                        />
                                        FiyrPod Help Center
                                    </span>
                                }
                            >
                                <StyledTreeItem3rdSubHeader
                                    itemId="23.2"
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={MclloydLogo}
                                                alt="McLloyd Logo"
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    marginRight: '5px',
                                                }}
                                            />
                                            Installing the FiyrPod App
                                        </span>
                                    }
                                />{' '}
                                <StyledTreeItem3rdSubHeader
                                    itemId="23.3"
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            <img
                                                src={MclloydLogo}
                                                alt="McLloyd Logo"
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    marginRight: '5px',
                                                }}
                                            />
                                            FiyrPod Desktop App Settings
                                        </span>
                                    }
                                />
                            </StyledTreeItem2ndSubHeader>
                            <StyledTreeItem2ndSubHeader
                                itemId="23.4"
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={PlayertekLogo}
                                            alt="Playertek Logo"
                                            style={{
                                                width: '25px',
                                                height: '25px',
                                                marginRight: '5px',
                                            }}
                                        />
                                        Playertek Help Center
                                    </span>
                                }
                            />
                        </StyledTreeItemSubHeader>
                        <StyledTreeItemSubHeader
                            itemId="17.1"
                            label={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={temLogo}
                                        alt="McLloyd Logo"
                                        style={{
                                            width: '26px',
                                            height: '26px',
                                            marginRight: '5px',
                                        }}
                                    />
                                    1080 Motion
                                </span>
                            }
                        />
                        <StyledTreeItemSubHeader itemId="30" label="AI Sprint Video Analysis">
                            <StyledTreeItem2ndSubHeader
                                itemId="31"
                                label="AI Sprint Video Analysis Setup"
                            />
                        </StyledTreeItemSubHeader>
                        <StyledTreeItemSubHeader itemId="33" label="Training Data">
                            <StyledTreeItem2ndSubHeader itemId="34" label="Creating Movements" />
                            <StyledTreeItem2ndSubHeader itemId="35" label="Creating Sessions" />
                            <StyledTreeItem2ndSubHeader itemId="35.1" label="Archiving Sessions" />
                            <StyledTreeItem2ndSubHeader
                                itemId="36"
                                label="Assigning Sessions to Athletes"
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId="36.5"
                                label="Assigning Sessions to Groups"
                            />
                        </StyledTreeItemSubHeader>
                        <StyledTreeItemSubHeader itemId="37" label="Learning Content">
                            <StyledTreeItem2ndSubHeader
                                itemId="38"
                                label="Creating Learning Content"
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId="38.5"
                                label="Assigning Learning Content to Athletes"
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId="39"
                                label="Assigning Learning Content to Groups"
                            />
                        </StyledTreeItemSubHeader>
                        <StyledTreeItemSubHeader itemId="40" label="Group Management">
                            <StyledTreeItem2ndSubHeader itemId="41" label="Creating Groups" />
                            <StyledTreeItem2ndSubHeader itemId="40.0.1" label="Archiving Groups" />
                            <StyledTreeItem2ndSubHeader itemId="42" label="Adding Athletes" />
                            <StyledTreeItem2ndSubHeader
                                itemId="42.1"
                                label="Removing User from Individual"
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId="43"
                                label="Assigning Coaches to Group"
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId="43.1"
                                label="Assigning Coaches to Organization"
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId={'44'}
                                label={'Assigning Group Content'}
                            />
                        </StyledTreeItemSubHeader>
                        <StyledTreeItemSubHeader itemId={'45'} label={'Database Views'}>
                            <StyledTreeItem2ndSubHeader itemId={'46'} label={'Training Database'} />
                            <StyledTreeItem2ndSubHeader itemId={'47'} label={'Sprint Database'} />
                            <StyledTreeItem2ndSubHeader
                                itemId={'48'}
                                label={'Time Segments Database'}
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId={'49'}
                                label={'Video Analysis Database'}
                            />
                            <StyledTreeItem2ndSubHeader itemId={'50'} label={'1080 Database'} />
                            <StyledTreeItem2ndSubHeader
                                itemId={'51'}
                                label={'GPS Summaries Database'}
                            />
                        </StyledTreeItemSubHeader>
                        <StyledTreeItemSubHeader itemId={'52'} label={'Explore'}>
                            <StyledTreeItem2ndSubHeader
                                itemId={'53'}
                                label={'GPS Sprint Cropping'}
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId={'54'}
                                label={'GPS Summary - Leaderboard'}
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId={'55'}
                                label={'GPS Summary - Athlete Profiles'}
                            />
                            {/*<TreeItem
                                nodeId={'56'}
                                label={'GPS Summary - Accute to Chronic Workload Ratio'}
                            />*/}
                            <StyledTreeItem2ndSubHeader
                                itemId={'57'}
                                label={'GPS Summary - Athlete Progress'}
                            />
                        </StyledTreeItemSubHeader>

                        <StyledTreeItemSubHeader itemId={'58'} label={'Reports and Dashboards'}>
                            <StyledTreeItem2ndSubHeader itemId={'59'} label={'Creating a Report'} />
                            <StyledTreeItem2ndSubHeader
                                itemId={'60'}
                                label={'Creating a Dashboard'}
                            />
                            <StyledTreeItem2ndSubHeader
                                itemId={'61'}
                                label={'Share Dashboard with a Group'}
                            />
                        </StyledTreeItemSubHeader>
                    </StyledTreeItemHeader>
                    <StyledTreeItemHeader itemId="62" label="1TUL for Coaches">
                        <StyledTreeItemSubHeader itemId={'63'} label={'Group Log'} />
                    </StyledTreeItemHeader>
                </SimpleTreeView>

                {/*Quick Start For Athletes*/}

                {/*System Outline*/}

                {/*Integrations*/}
            </Grid>

            {/*Center View with Buttons*/}

            <Grid item sm={12} md={8} sx={{ margin: 1 }}>
                <PaperGradient>
                    <Typography variant={'h6'}>
                        {selectedSideBarName ? selectedSideBarName : '1TUL Overview'}
                    </Typography>
                    {!selectedSideBarName && <OneTulOverview />}
                    {selectedSideBarName === '1TUL Overview' && <OneTulOverview />}
                    {selectedSideBarName === 'What is 1TUL?' && <WhatIs1Tul />}
                    {selectedSideBarName === 'Integrations' && <Integrations />}
                    {selectedSideBarName === 'Sprint Analysis' && <SprintAnalysisOverview />}
                    {selectedSideBarName === 'Sprint Cropping' && <SprintCroppingOverview />}
                    {selectedSideBarName === 'The 1TUL Edge' && <HowWeDifferent />}
                    {selectedSideBarName === 'AI Video Analysis' && <AIVideoOverview />}
                    {selectedSideBarName === '1TUL for Athletes' && <ForAthletes />}
                    {selectedSideBarName === 'Built Around Speed Analytics' && (
                        <SprintAnalysisOverview />
                    )}
                    {selectedSideBarName === 'Receiving Assignments' && (
                        <AthleteRecieveAssignment />
                    )}
                    {selectedSideBarName === 'Logging a Workout' && <AthleteLoggingAssignment />}
                    {selectedSideBarName === 'Athlete Billing, Returns, Cancellations' && (
                        <BillingReturnsCancellations />
                    )}
                    {selectedSideBarName === 'Athlete App Download' && <AthleteAppDownload />}
                    {selectedSideBarName === '1TUL for Admins' && <OneTulForAdmins />}
                    {selectedSideBarName === 'GPS' && <AdminGPSOverview />}
                    {selectedSideBarName === 'Importing Raw Data' && <ImportingRawGPSData />}
                    {selectedSideBarName === 'Single Raw Data Import' && <SingleRawDataImport />}
                    {selectedSideBarName === 'Multi-Raw Data Import' && (
                        <MultiRawDataImportToGroup />
                    )}
                    {selectedSideBarName === 'Working with Raw GPS Data' && (
                        <WorkingWithRawGPSData />
                    )}
                    {selectedSideBarName === 'Cropping Sprints' && <CroppingSprints />}
                    {selectedSideBarName === 'AI Sprint Video Analysis' && <AIVideoOverview />}
                    {selectedSideBarName === 'AI Sprint Video Analysis Setup' && (
                        <AISprintVideoSetup />
                    )}
                    {selectedSideBarName === 'Training Data' && <AdminTrainData />}
                    {selectedSideBarName === 'Creating Movements' && <AdminCreateNewMovement />}
                    {selectedSideBarName === 'Group Management' && <AdminGroupManagement />}
                    {selectedSideBarName === 'Creating Groups' && <AdminCreateNewGroup />}
                    {selectedSideBarName === 'Adding Athletes' && <AdminAddMembersToGroup />}
                    {selectedSideBarName === 'Assigning Coaches to Group' && (
                        <AdminAssignCoachesToGroup />
                    )}
                    {selectedSideBarName === 'Assigning Coaches to Organization' && (
                        <AdminAssignCoachesToOrganization />
                    )}
                    {selectedSideBarName === 'Assigning Group Content' && (
                        <AdminAssigningGroupContent />
                    )}
                    {selectedSideBarName === 'Learning Content' && <AdminLearningContentOverview />}
                    {selectedSideBarName === 'Creating Learning Content' && (
                        <AdminCreateLearningContent />
                    )}
                    {selectedSideBarName === 'Assigning Learning Content to Groups' && (
                        <AdminAssignLearningContent />
                    )}
                    {selectedSideBarName === 'Assigning Learning Content to Athletes' && (
                        <AdminAssignLearnContentToAthletes />
                    )}
                    {selectedSideBarName === 'Tracking Your Progress' && <AthleteTrackProgress />}
                    {selectedSideBarName === 'Database Views' && <AdminDatabaseViews />}
                    {selectedSideBarName === 'Training Database' && <AdminTrainDatabase />}
                    {selectedSideBarName === 'Sprint Database' && <AdminSprintDatabase />}
                    {selectedSideBarName === 'Completing a Learning Assignment' && (
                        <AthleteCompleteLearningAssignment />
                    )}
                    {selectedSideBarName === 'Exploring Sprint Crops' && (
                        <AdminExploreSprintCropping />
                    )}
                    {selectedSideBarName === 'Automated Sprint Extraction' && (
                        <AdminAutomatedSprintExtraction />
                    )}
                    {selectedSideBarName === 'Time Segment Analysis' && (
                        <AdminTimeSegmentAnalysis />
                    )}
                    {selectedSideBarName === 'Importing Session Summary Data' && (
                        <AdminGPSSessionSummaryImport />
                    )}
                    {selectedSideBarName === 'Exporting Session Summary - FiyrPod' && (
                        <AdminGPSSessionSummaryMcLloydExport />
                    )}
                    {selectedSideBarName === 'FiyrPod Raw Data Export' && (
                        <AdminGPSRawDataMcLloydExport />
                    )}
                    {selectedSideBarName === 'Importing Session Summary - FiyrPod' && (
                        <AdminGPSSessionSummaryImport />
                    )}
                    {selectedSideBarName === 'Raw GPS Session Summary' && (
                        <AdminRawGPSSessionSummary />
                    )}
                    {selectedSideBarName === 'Creating Sessions' && <AdminCreatingSession />}
                    {selectedSideBarName === 'Archiving Sessions' && <AdminArchivingSessions />}
                    {selectedSideBarName === 'Assigning Sessions to Athletes' && (
                        <AdminAssignSessionToAthlete />
                    )}
                    {selectedSideBarName === 'Assigning Sessions to Groups' && (
                        <AdminAssignSessionToGroup />
                    )}
                    {selectedSideBarName === 'Time Segments Database' && <AdminTimeSegmentsDB />}
                    {selectedSideBarName === 'Video Analysis Database' && <AdminVideoAnalysisDB />}
                    {selectedSideBarName === '1080 Database' && <Admin1080DBView />}
                    {selectedSideBarName === 'GPS Summaries Database' && <AdminGPSSummaryDBView />}
                    {selectedSideBarName === 'Explore' && <AdminExploreOverview />}
                    {selectedSideBarName === 'GPS Sprint Cropping' && <AdminExploreSprintCrop />}
                    {selectedSideBarName === 'GPS Summary - Leaderboard' && (
                        <AdminExploreLeaderboard />
                    )}
                    {selectedSideBarName === 'GPS Summary - Athlete Profiles' && (
                        <AdminExploreAthleteProfiles />
                    )}
                    {selectedSideBarName === 'GPS Summary - Athlete Progress' && (
                        <AdminExploreAthleteProgress />
                    )}
                    {selectedSideBarName === 'Reports and Dashboards' && (
                        <AdminReportsAndDashboardsOverview />
                    )}
                    {selectedSideBarName === 'Creating a Report' && <AdminCreateReport />}
                    {selectedSideBarName === 'Creating a Dashboard' && (
                        <AdminCreateDashboardAndShare />
                    )}
                    {selectedSideBarName === 'Share Dashboard with a Group' && (
                        <AdminShareDashboardWithGroup />
                    )}
                    {selectedSideBarName === 'Removing User from Individual' && (
                        <AdminRemoveUserFromIndividual />
                    )}
                    {selectedSideBarName === 'Extreme Scaleability' && (
                        <DifferentExtremeScalability />
                    )}
                    {selectedSideBarName === 'Share Content with Groups' && (
                        <DifferentShareContentWithGroups />
                    )}
                    {selectedSideBarName === 'Group Log' && <CoachesGroupLog />}
                    {selectedSideBarName === '1TUL for Coaches' && <CoachesOverview />}
                    {selectedSideBarName === 'Adding Organizational Users' && (
                        <AdminInvitingOrganizationalUsers />
                    )}
                    {selectedSideBarName === 'Connecting 3rd Party Applications' && (
                        <AdminConnect3rdPartyApps />
                    )}
                    {selectedSideBarName === 'Organization Management' && (
                        <AdminOrganizationManagementOverview />
                    )}
                    {selectedSideBarName === 'Update Organization Logo' && <AdminUpdateOrgLogo />}
                    {selectedSideBarName === 'Remote Coaching At-Scale' && (
                        <RemoteCoachingAtScale />
                    )}
                    {selectedSideBarName === 'FiyrPod Help Center' && (
                        <AdminMcLloydHelpCenterOverview />
                    )}
                    {selectedSideBarName === 'Installing the FiyrPod Desktop App' && (
                        <AdminGPSMcLloydDownloadVideo />
                    )}
                    {selectedSideBarName === 'FiyrPod App Settings' && (
                        <AdminGPSMcLloydAdminSettings />
                    )}
                    {selectedSideBarName === 'Playertek Help Center' && <AdminGPSPlayertek />}
                    {selectedSideBarName === '1080 Motion' && <AdminTEMApi />}
                    {selectedSideBarName === 'Marketplace' && <AdminMarketPlaceOverview />}
                    {selectedSideBarName === 'Archiving Groups' && <AdminArchivingGroups />}
                </PaperGradient>
            </Grid>
        </Grid>
    );
}
