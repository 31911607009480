import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Dimensions } from 'utils';

type ReturnType = Promise<AxiosResponse<Dimensions[]>>;

const getDimensions = (query = {}): ReturnType => {
    return Request.get(`/calendar/dimensions`, {
        params: query,
    });
};

export default getDimensions;
