import { useParams } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { getExploration, updateExploration } from '../explore.api';
import { Exploration } from '../explore.types';
import { Card, CardContent, CardHeader, Modal, Snackbar, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ExplorationCrops from '../components/ExplorationCrops';
import ExplorationDetailsPanel from '../components/ExplorationDetailsPanel';
import { debounce } from 'lodash';
import ExplorationSharingPanel from '../components/ExplorationSharingPanel';
import { AbilityContext, Can } from '../../../components/Functional/Can';
import ExplorationGpsSummaryLeaderboard from '../components/ExplorationGpsSummaryLeaderboard';
import ExplorationAthleteProfiles from '../components/ExplorationAthleteProfiles';
import ExplorationGpsSummaryProgress from '../components/ExplorationGpsSummaryProgress';
import ExplorationGpsSummaryAcwr from '../components/ExplorationGpsSummaryAcwr';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { keyframes, styled } from '@mui/system';
import { convertApiFiltersToExplorationFilters } from '../explore.func';

const ExplorationDetails = () => {
    const { explorationId } = useParams<{ explorationId: string }>();
    const [exploration, setExploration] = useState<Exploration>();
    const [message, setMessage] = useState('');
    const ability = useContext(AbilityContext);
    const [loading, setLoading] = useState(false); // New state for loading

    useEffect(() => {
        async function fetchExploration() {
            try {
                setLoading(true); // Set loading to true when API call starts
                const response = await getExploration(explorationId);
                if (!ignore) {
                    setExploration(response.data);
                }
            } catch (e: any) {
                setMessage('Failed to load exploration');
            } finally {
                setLoading(false); // Set loading to false when API call ends
            }
        }

        let ignore = false;
        fetchExploration();
        return () => {
            ignore = true;
        };
    }, [explorationId]);

    const fadeInOut = keyframes`
        0% {
            opacity: 0.5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.5;
        }
    `;

    const AnimatedTypography = styled(Typography)(() => ({
        animation: `${fadeInOut} 2s infinite`,
    }));

    const debouncedUpdateExploration = useRef(
        debounce((explorationId, setExploration, setMessage, filters, title) => {
            updateExploration(explorationId, {
                filters,
                title,
            })
                .then((response) => {
                    setExploration(response.data);
                })
                .catch(() => {
                    setMessage('Failed to update exploration details');
                });
        }, 1000),
    );

    const onChangeMemoized = useCallback(
        (filters: any, title: any) => {
            debouncedUpdateExploration.current(
                explorationId,
                setExploration,
                setMessage,
                filters,
                title,
            );
        },
        [explorationId, setExploration, setMessage],
    );

    return (
        <>
            <Grid container spacing={2}>
                {exploration && (
                    <>
                        <Grid
                            xs={12}
                            md={
                                ability.can(
                                    'organization:update-explorations',
                                    exploration.organization,
                                )
                                    ? 3
                                    : 0
                            }
                        >
                            <Can
                                I={'organization:update-explorations'}
                                this={exploration.organization}
                            >
                                <ExplorationDetailsPanel
                                    onChange={onChangeMemoized}
                                    exploration={exploration}
                                />

                                <ExplorationSharingPanel exploration={exploration} />
                            </Can>
                        </Grid>
                        <Grid
                            xs={12}
                            md={
                                ability.can(
                                    'organization:update-explorations',
                                    exploration.organization,
                                )
                                    ? 9
                                    : 12
                            }
                        >
                            <Card
                                sx={{
                                    width: '100%',
                                    overflowY: 'scroll',
                                }}
                            >
                                <CardHeader title={'Exploration'}></CardHeader>
                                <CardContent>
                                    <Modal
                                        open={loading}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: 'rgba(0, 0, 0, 0.1)', // semi-transparent background
                                            maxHeight: 400,
                                            maxWidth: 400,
                                        }}
                                    >
                                        <>
                                            <AnimatedTypography variant={'h6'} textAlign={'center'}>
                                                Connecting to Database . . .
                                            </AnimatedTypography>
                                            <CustomLoadingOverlay />
                                        </>
                                    </Modal>
                                    {exploration.type === 'crops' && (
                                        <ExplorationCrops
                                            exploration={exploration}
                                            onExcludedCropsChanged={(crops) => {
                                                updateExploration(explorationId, {
                                                    filters: {
                                                        ...convertApiFiltersToExplorationFilters(
                                                            exploration.filters,
                                                        ),
                                                        excludedCrops: crops,
                                                    },
                                                })
                                                    .then((response) => {
                                                        setExploration(response.data);
                                                    })
                                                    .catch(() => {
                                                        setMessage(
                                                            'Failed to update exploration details',
                                                        );
                                                    });
                                            }}
                                        />
                                    )}
                                    {exploration.type === 'gps_summary_leaderboard' && (
                                        <ExplorationGpsSummaryLeaderboard
                                            exploration={exploration}
                                        />
                                    )}
                                    {exploration.type === 'gps_summary_athlete_profile' && (
                                        <ExplorationAthleteProfiles exploration={exploration} />
                                    )}
                                    {exploration.type === 'gps_summary_athlete_progress' && (
                                        <ExplorationGpsSummaryProgress exploration={exploration} />
                                    )}
                                    {exploration.type === 'gps_summary_acwr' && (
                                        <ExplorationGpsSummaryAcwr exploration={exploration} />
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                )}
            </Grid>
            <Snackbar
                onClose={() => setMessage('')}
                open={message !== ''}
                message={message}
                autoHideDuration={6000}
            />
        </>
    );
};

export default ExplorationDetails;
