import { ChatBubbleOutline } from '@mui/icons-material';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import {
    AppBar,
    Box,
    Drawer,
    Fade,
    IconButton,
    Tab,
    Tabs,
    Toolbar,
    Typography,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import { SpotlightSearch } from 'components';
import ProfileOptions from 'components/Navigation/ProfileOptions';
import Logo from 'images/db_icon.png';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useNotificationData } from '../../contexts/NotificationDataProviderContext';
import StreamChatPage from '../../pages/StreamChat';
import AlertIconWithBadge from './AlertIconWithBadge';
import useNavigationTabConfig, { NavigationTabConfigProps } from './navigationconfig';
import { useNavigationContext } from './NavigationContext';
import SecondaryNavigation from './SecondaryNavigation';

const DesktopTopNavigation = () => {
    const { push } = useHistory();
    const { setPrimaryNavigation, primaryNavigation } = useNavigationContext();
    const { counts } = useNotificationData();
    const [chatOpen, setChatOpen] = useState(false);
    const [showBadge, setShowBadge] = useState(true); // Initially show the badge
    const navigationTabConfig = useNavigationTabConfig();

    useEffect(() => {
        // Hide the badge after a delay (e.g., 7000 milliseconds)
        const hideBadgeTimeout = setTimeout(() => {
            setShowBadge(false);
        }, 12000); // Hide badge after 7 seconds

        // Clear the timeout when the component unmounts
        return () => {
            clearTimeout(hideBadgeTimeout);
        };
    }, []);

    const keyframes = `
        @keyframes constantScale {
            0% {
                transform: scale(1.1);
            }
            50% {
                transform: scale(1.05);
            }
            100% {
                transform: scale(1.1);
            }
        }
    `;

    // Define the inline CSS for animation
    const castIconStyle: React.CSSProperties = {
        animation: 'constantScale 3s infinite', // Apply the animation
        cursor: 'pointer',
    };

    return (
        <>
            <style>{keyframes}</style>
            <AppBar position={'relative'} component="header">
                <Toolbar style={{ justifyContent: 'space-between' }} variant="dense">
                    <Box display="flex" alignItems="center" style={{ gap: 20 }} flexBasis="33%">
                        <Link to="/home/sprint-dashboard">
                            <img src={Logo} alt="1TUL Logo" width={70} />
                        </Link>
                        <SpotlightSearch />
                    </Box>
                    <Box flexBasis="33%" alignItems="center" justifyContent="center" display="flex">
                        <Tabs
                            indicatorColor="primary"
                            value={primaryNavigation ? primaryNavigation : false}
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: 'common.white',
                                },
                            }}
                        >
                            {navigationTabConfig.map(
                                (
                                    secondaryNavigationTab: NavigationTabConfigProps,
                                    index: number,
                                ) => (
                                    <Tab
                                        key={`secondary-navigation-tab-${index}`}
                                        value={secondaryNavigationTab.value}
                                        sx={{
                                            width: 80,
                                            minWidth: 'inherit',
                                            minHeight: 'inherit',
                                            color: '#4083ff',
                                            paddingTop: 8,
                                            '&.Mui-selected': {
                                                color: 'common.white',
                                            },
                                            '& .MuiTypography-root': {
                                                fontWeight: 600,
                                            },
                                        }}
                                        label={
                                            <Typography variant="caption">
                                                {secondaryNavigationTab.label}
                                            </Typography>
                                        }
                                        icon={
                                            secondaryNavigationTab.badgeContent ? (
                                                <Badge
                                                    badgeContent={
                                                        secondaryNavigationTab.badgeContent
                                                    }
                                                    color="error"
                                                >
                                                    <secondaryNavigationTab.icon />
                                                </Badge>
                                            ) : (
                                                <secondaryNavigationTab.icon />
                                            )
                                        }
                                        onClick={() => {
                                            push(secondaryNavigationTab.path);
                                            setPrimaryNavigation(secondaryNavigationTab.value);
                                        }}
                                        title={secondaryNavigationTab.title}
                                    />
                                ),
                            )}
                        </Tabs>
                    </Box>
                    <Box
                        flexBasis="33%"
                        alignItems="center"
                        justifyContent="flex-end"
                        display="flex"
                    >
                        <IconButton color={'secondary'} onClick={() => setChatOpen(true)}>
                            <ChatBubbleOutline />
                        </IconButton>
                        <AlertIconWithBadge count={counts?.unattested_count ?? 0} />
                        {showBadge ? (
                            <Fade in={showBadge} timeout={{ enter: 7000 }}>
                                <Badge
                                    sx={{
                                        transition: 'opacity 2s ease-in-out', // Adjust the duration as needed
                                        opacity: 0, // Initially hide the badge
                                        marginRight: 8,
                                    }}
                                    badgeContent={'New'}
                                    color="info"
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <CastForEducationIcon
                                        onClick={() => push('/Learn1TUL')}
                                        sx={{ marginRight: 8 }}
                                        style={castIconStyle} // Apply the inline style
                                    />
                                </Badge>
                            </Fade>
                        ) : (
                            <CastForEducationIcon
                                onClick={() => push('/Learn1TUL')}
                                sx={{ marginRight: 8 }}
                                style={castIconStyle} // Apply the inline style
                            />
                        )}

                        <ProfileOptions />
                    </Box>
                </Toolbar>
                <SecondaryNavigation />
                <Drawer
                    PaperProps={{ style: { width: '65%' } }}
                    anchor={'right'}
                    open={chatOpen}
                    onClose={() => setChatOpen(false)}
                >
                    <StreamChatPage />
                </Drawer>
            </AppBar>
        </>
    );
};

export default DesktopTopNavigation;
