import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<void>>;

interface RequestTransferParams {
    individual_uuid: string;
}

const requestPodTransferToAthlete = (
    organizationUuid: string,
    selectedPodUuid: string,
    params: RequestTransferParams,
): ReturnType => {
    return Request.post(
        `organizations/${organizationUuid}/pods/${selectedPodUuid}/request-transfer`,
        params,
    );
};

export { requestPodTransferToAthlete };
