import React, { ReactElement, useEffect, useState } from 'react';
import { Attribute, AttributeCategory, AttributeValue, AttributeValueType } from 'utils';
import { AttributeListViewer } from 'components/FormControl/AttributeList';
import { AttributeList } from 'components/FormControl/AttributeList';
import { FormikErrors, FormikValues } from 'formik';
import { isEmpty } from 'lodash';

interface Props {
    attributes: Array<Attribute>;
    onChange: (attributeValues: AttributeValue[]) => void;
    value: Array<AttributeValue>;
    addText?: string;
    errors?: FormikErrors<FormikValues>;
    setErrors?: (errors: FormikErrors<FormikValues>) => void;
    attributeCategory?: AttributeCategory;
}

export default function AttributeInput({
    attributes,
    onChange,
    value,
    addText = 'Add Another Tag',
    errors,
    setErrors,
    attributeCategory,
}: Props): ReactElement {
    // We will be setting a local state here instead of directly manipulating the prop
    // to avoid state corruptions
    const [mutableAttributeValues, setMutableAttributeValues] =
        useState<Array<AttributeValue>>(value);
    useEffect(() => {
        if (errors && setErrors) {
            setErrors({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        value && setMutableAttributeValues(value);
    }, [value]);

    // returns the selected attributes by appending their choices with them
    const getValue = (): AttributeValue[] => {
        if (mutableAttributeValues.length) {
            let result: AttributeValue[] = [];
            mutableAttributeValues.map((selected) => {
                let found = attributes.filter((x) => x.name === selected.name);

                result.push({ ...selected, choices: found.length ? found[0].choices : [] });
            });

            return result;
        }
        return [];
    };

    const attributeDeleted = () => {};
    const attributesUpdated = (attributeValues: AttributeValue[]) => {
        // Set the local state with the updated attributes
        setMutableAttributeValues(attributeValues);
        // Send only valid attributes to the parent which have value
        onChange(attributeValues.filter((v) => !isEmpty(v.value)));
    };

    const attributeAdded = (attribute: Attribute) => {
        const existingAttributeValues = [...value];
        if (existingAttributeValues.findIndex((v) => v.name === attribute.name) > -1) {
            return;
        }
        existingAttributeValues.push({
            name: attribute.name,
            category: attribute.category,
            sub_category: attribute.sub_category,
            value: attribute.type === AttributeValueType.LIST ? [] : '',
            type: attribute.type,
            choices: attribute.choices,
        });
        setMutableAttributeValues(existingAttributeValues);
    };
    return (
        <div>
            <AttributeListViewer
                errors={errors}
                attributeValues={getValue()}
                deleted={attributeDeleted}
                updated={attributesUpdated}
            />
            <AttributeList
                addText={addText}
                attributes={attributes}
                disabled={value.map((v) => v.name)}
                onAttributeClicked={attributeAdded}
                onAttributeAdded={attributeAdded}
                attributeCategory={attributeCategory}
            />
        </div>
    );
}
