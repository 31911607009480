import { CombinedState, combineReducers } from 'redux';
import { resetPassword, resetSelector, resetSlice, ResetState } from './reset';

export type AuthReducerState = CombinedState<{
    reset: ResetState;
}>;

export const authenticationReducer = combineReducers<AuthReducerState>({
    reset: resetSlice.reducer,
});

export const authActions = {
    resetPassword,
};

export const authSelectors = {
    resetSelector,
};
