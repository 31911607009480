import AuthLayout from '../../../Layouts/AuthLayout';
import { RouteConfigProps } from '../../../Layouts/routeconfig';
import ResetPassword from './ResetPassword';
import VerifyEmailConfirmation from './VerifyEmailConfirmation';

export const authRoutes: Array<RouteConfigProps> = [
    {
        exact: true,
        layout: AuthLayout,
        path: '/auth/reset-password',
        component: ResetPassword,
        private: false,
        pageTitle: 'Choose A New Password',
    },
    {
        exact: true,
        layout: AuthLayout,
        path: '/auth/email-verified',
        component: VerifyEmailConfirmation,
        private: false,
        pageTitle: 'Email Was Verified',
    },
];
