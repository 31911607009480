import React from 'react';
import { Movement, Organization } from '../../utils';
import { Box, Chip } from '@mui/material';
import SearchMovement from './SearchMovement';

interface Props {
    organization: Organization;
    value: Movement[];
    onChange: (movements: Movement[]) => void;
}

function FilterByMovement({ organization, value, onChange }: Props): React.ReactElement {
    return (
        <Box display="flex">
            <Box display="flex" alignItems="center">
                {value.map((m) => (
                    <Chip
                        key={m.uuid}
                        onDelete={(e) => {
                            e.preventDefault();
                            onChange(value.filter((ind) => ind.uuid !== m.uuid));
                        }}
                        label={m.name}
                    />
                ))}
            </Box>
            <SearchMovement
                onMovementSelected={(movement) => {
                    if (value.findIndex((i) => i.uuid === movement.uuid) === -1) {
                        onChange([...value, movement]);
                    }
                }}
                organization={organization}
            />
        </Box>
    );
}

export default FilterByMovement;
