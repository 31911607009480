import React, { useRef, useEffect, useState } from 'react';
import { Card, CardContent, CardActions, Typography, Box, Button, useTheme } from '@mui/material';
import { OrganizationPublic } from 'utils';

interface OrganizationTileProps {
    organization: OrganizationPublic;
    onClick: () => void;
}

const OrganizationTile: React.FC<OrganizationTileProps> = ({ organization, onClick }) => {
    const theme = useTheme();
    const nameRef = useRef<HTMLSpanElement>(null);
    const [fontSize, setFontSize] = useState(2);

    useEffect(() => {
        const adjustFontSize = () => {
            const element = nameRef.current;
            if (!element) return;

            const container = element.parentElement;
            if (!container) return;

            let size = 2;
            element.style.fontSize = `${size}rem`;

            while (element.scrollWidth > container.clientWidth - 70 && size > 1) {
                size -= 0.1;
                element.style.fontSize = `${size}rem`;
            }

            setFontSize(size);
        };

        adjustFontSize();
        window.addEventListener('resize', adjustFontSize);
        return () => window.removeEventListener('resize', adjustFontSize);
    }, [organization.name]);

    return (
        <Card
            sx={{
                width: 280,
                height: 320,
                borderRadius: 3,
                background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                color: theme.palette.common.white,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                },
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
                margin: '8px',
            }}
        >
            <Box
                sx={{
                    height: '62px',
                    backgroundColor: organization.banner_url ? 'black' : 'darkgray',
                    position: 'relative',
                }}
            >
                {organization.banner_url && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage: `url(${organization.banner_url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            opacity: 0.5,
                        }}
                    />
                )}
                {organization && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 8,
                            left: 8,
                            right: 8,
                            zIndex: 1,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {organization.logo_url ? (
                            <img
                                src={organization.logo_url}
                                alt={organization.name}
                                style={{
                                    height: 46,
                                    width: 46,
                                    borderRadius: '25%',
                                    border: '1px solid white',
                                    flexShrink: 0,
                                }}
                            />
                        ) : (
                            <Box
                                sx={{
                                    height: 46,
                                    width: 46,
                                    borderRadius: '25%',
                                    backgroundColor: 'gray',
                                    flexShrink: 0,
                                }}
                            />
                        )}
                        <Typography
                            component="span"
                            ref={nameRef}
                            sx={{
                                marginLeft: '12px',
                                fontWeight: 500,
                                fontSize: `${fontSize}rem`,
                                textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                                zIndex: 1,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                flexGrow: 1,
                            }}
                        >
                            {organization.name}
                        </Typography>
                    </Box>
                )}
            </Box>
            <CardContent sx={{ flexGrow: 1, position: 'relative', zIndex: 1 }}>
                <Typography
                    sx={{
                        color: 'FFF',
                        fontSize: '0.8rem',
                        fontWeight: 500,
                        textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                    }}
                    variant="body1"
                >
                    {organization.about_us}
                </Typography>
            </CardContent>
            <CardActions sx={{ position: 'relative', zIndex: 1 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                    sx={{
                        margin: 'auto',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                        '&:hover': {
                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
                        },
                    }}
                >
                    Marketplace
                </Button>
            </CardActions>
        </Card>
    );
};

export default OrganizationTile;
