import { CircularProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authSelector } from '../../../redux/reducers/auth';
import { getUserData } from '../../../redux/reducers/user';
import { useAppDispatch } from '../../../redux/store';
import ROUTES from '../../../Routes/routes';

/**
 * This component is meant to refresh the user data so it's not stale any more.  If the user is not logged
 * in it will redirect to the login screen
 *
 * @constructor
 */
const VerifyEmailConfirmation = () => {
    const dispatch = useAppDispatch();
    const { isAuth } = useSelector(authSelector);
    const { push } = useHistory();

    // if a user is authenticated, refresh the user data and redirect
    // to the home dashboard
    useEffect(() => {
        if (isAuth) {
            dispatch(getUserData());
            push('/home');
        } else {
            push(ROUTES.Login.path);
        }
    }, [dispatch, push, isAuth]);
    return (
        <>
            <CircularProgress size={80} />
            <Typography variant={'h4'}>Verifying Email</Typography>
        </>
    );
};

export default VerifyEmailConfirmation;
