import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<
    AxiosResponse<{
        expires_at: string;
        access_token: string;
    }>
>;

const userLogin = (username: string, password: string): ReturnType => {
    return Request.post(`/authentication/login`, { user_name: username, password });
};
export default userLogin;
