import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SchoolIcon from '@mui/icons-material/School';
import { Avatar, Box, CircularProgress, Fab, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getForm, viewIndividual } from '../../../api';
import { TwoColumnLayout } from '../../../components';
import EventListCard from '../../../components/Cards/EventListCard';
import { pushMessage } from '../../../redux/reducers/messages';
import { useAppDispatch } from '../../../redux/store';
import { CalendarEvent, DateValue, Form, TimezoneKey } from '../../../utils';
import {
    formatEndDateTimeRequest,
    formatStartDateTimeRequest,
} from '../../../utils/mapSessionStackUpdate';
import { viewGroup } from '../../community/api/groups.api';
import { getEvents } from '../../plan/api/timeline';
import { ChooseDateCard } from '../components';
import { AssignFormRequestParams, assignFormToCalendar } from '../learn.api';

interface Props {}

const AssignAssessment: React.FC<React.PropsWithChildren<Props>> = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>();
    const [form, setForm] = useState<Form>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const theme = useTheme();
    const [assigneePhotoUrl, setAssigneePhotoUrl] = useState<string>('');
    const [selectedDate, setSelectedDate] = useState<DateValue>({
        date: null,
        time: null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as TimezoneKey,
        isWholeDay: false,
    });
    const handleDateChange = (value: DateValue): void => {
        setSelectedDate(value);
    };
    const { push } = useHistory();
    const dispatch = useAppDispatch();
    const { individualId, groupId, assessmentId } = useParams<{
        individualId?: string;
        groupId?: string;
        assessmentId: string;
    }>();
    const handleClickSubmit = () => {
        setSubmitting(true);
        let params = {
            start_date_time: formatStartDateTimeRequest({
                isWholeDay: selectedDate.isWholeDay,
                date: selectedDate.date ?? DateTime.now(),
                time: selectedDate.time,
            }),
            end_date_time: formatEndDateTimeRequest({
                date: selectedDate.date ?? DateTime.now(),
                time: selectedDate.time,
                isWholeDay: selectedDate.isWholeDay,
                duration: 60,
            }),
            timezone: selectedDate.timezone,
        } as AssignFormRequestParams;
        if (individualId) {
            params['individual'] = individualId;
        }
        if (groupId) {
            params['group'] = groupId;
        }
        assignFormToCalendar(assessmentId, params)
            .then(() => {
                dispatch(
                    pushMessage({
                        status: 'success',
                        message: `Assessment Assigned To ${name}'s Calendar`,
                    }),
                );
                push('/plan/calendar');
            })
            .catch(() => {
                dispatch(
                    pushMessage({
                        status: 'error',
                        message: `We couldn't assign the assessment to ${name}'s Calendar`,
                    }),
                );
            })
            .finally(() => {
                setSubmitting(false);
            });
    };
    useEffect(() => {
        let requests = [getForm(assessmentId)] as Promise<any>[];

        if (individualId) {
            requests.push(viewIndividual(individualId));
            requests.push(getEvents({ 'filter[source_individual_uuid]': individualId }));
        }
        if (groupId) {
            requests.push(viewGroup(groupId));
            requests.push(getEvents({ 'filter[group_source_uuid][]': groupId }));
        }
        Promise.all(requests)
            .then((responses) => {
                const [formResponse, entityResponse, eventsResponse] = responses;
                setName(entityResponse.data.name);
                setForm(formResponse.data);
                setEvents(eventsResponse.data);
                setAssigneePhotoUrl(entityResponse.data.image_urls.avatar);
            })
            .catch(() => {
                dispatch(
                    pushMessage({ status: 'error', message: 'Failed to load session details' }),
                );
            })
            .finally(() => setLoading(false));
    }, [individualId, groupId, assessmentId, dispatch]);

    const getTitleOfEvent = (
        form_id: string | null,
        form_name: string,
        session_id: string | null,
        session_name: string,
    ): string => {
        //if form_id is !null -> return form_name
        //if session id !null -> return session_name
        if (form_id !== null) {
            return form_name;
        }
        if (session_id !== null) {
            return session_name;
        }
        return '';
    };
    return (
        <>
            <TwoColumnLayout
                side={
                    <EventListCard
                        events={events.map(
                            ({
                                uuid,
                                form_name,
                                session_name,
                                starts_at_timestamp,
                                ends_at_timestamp,
                                form_id,
                                session_id,
                            }) => ({
                                id: uuid,
                                title: getTitleOfEvent(
                                    form_id,
                                    form_name,
                                    session_id,
                                    session_name,
                                ),
                                starts_at: starts_at_timestamp,
                                ends_at: ends_at_timestamp,
                            }),
                        )}
                        title={`${name ?? ''}'s Calendar`}
                        assigneePhotoUrl={assigneePhotoUrl}
                    />
                }
                body={
                    <Box width={'100%'} paddingTop={4}>
                        <Typography variant={'body1'}>Choose dates and times below</Typography>
                        <ChooseDateCard
                            onChange={handleDateChange}
                            value={selectedDate}
                            title={form?.title ?? ''}
                            avatar={
                                <Avatar variant={'square'} src={form?.image_urls[200] ?? ''}>
                                    <SchoolIcon />
                                </Avatar>
                            }
                        />
                        {loading && <span>Loading</span>}
                    </Box>
                }
            />
            <Fab
                disabled={submitting}
                color="primary"
                sx={{
                    bottom: 80,
                    left: '50%',
                    position: 'fixed',
                    transform: 'translateX(-50%)',
                    zIndex: 1102,
                    [theme.breakpoints.up('sm')]: {
                        bottom: 39,
                    },
                }}
                variant="extended"
                onClick={() => handleClickSubmit()}
            >
                <Box mr={5}>
                    {submitting ? (
                        <CircularProgress size={26} color="inherit" />
                    ) : (
                        'Assign Assessment'
                    )}
                </Box>
                <ArrowForwardIcon />
            </Fab>
        </>
    );
};

export default AssignAssessment;
