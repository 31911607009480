import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Stack,
    Typography,
} from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSubscriptionsSelector } from '../../../redux/reducers/user';
import { getPhysicalProducts } from '../../../api/Auth/getPhysicalProducts';
import { OrganizationPublic, PhysicalProductForPurchase } from '../../../utils';
import Pod from '../../../images/pod.png';
import Vest from '../../../images/vestTransparent.png';
import Charger from '../../../images/charger1.png';
import GoldMedal from '../../../images/gold-medal.png';
import getOrganizationBySlug from '../../../api/Public/getOrganizationBySlug';
import createCheckoutComboAthleteSubscriptionWithVestsAndOptionalChargers from '../../../api/Auth/createCheckoutComboAthleteSubscriptionWithVestsAndOptionalChargers';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import QuantityControl from '../../website/scenes/QuantityControl';

const AthleteComboSubscribe = (): ReactElement => {
    const subscriptions = useSelector(userSubscriptionsSelector);
    const [organization, setOrganization] = useState<OrganizationPublic | null>(null);
    const [snackMessage, setSnackMessage] = useState<string>('');

    const [podsForPurchase, setPodsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [vestsForPurchase, setVestsForPurchase] = useState<PhysicalProductForPurchase[]>([]);
    const [chargersForPurchase, setChargersForPurchase] = useState<PhysicalProductForPurchase[]>(
        [],
    );
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    // For purchasing pods with vests and chargers
    const [selectedPodForPurchase, setSelectedPodForPurchase] = useState<string>('');
    const [selectedVestCountsForPods, setSelectedVestCountsForPods] = useState<
        Record<string, number>
    >({});
    const [selectedChargerCountsForPods, setSelectedChargerCountsForPods] = useState<
        Record<string, number>
    >({});
    const [warningForNoChargersSelected, setWarningForNoChargersSelected] =
        useState<boolean>(false);

    // Flat rate shipping fee $15
    const SHIPPING_FEE = 15;
    const DEFAULT_SUBSCRIPTION_PRICE = 9.99;

    useEffect(() => {
        const getOrganizationFromUrlSlug = async () => {
            try {
                const response = await getOrganizationBySlug(
                    window.location.pathname.split('/')[2],
                );
                console.log(response.data);
                setOrganization(response.data);

                getPhysicalProducts('Fiyrpod Single Charger')
                    .then((response) => {
                        setChargersForPurchase(response.data.data);
                        if (response.data.data.length > 0) {
                            setSelectedChargerCountsForPods({
                                [response.data.data[0].stripe_price_id]: 1,
                            });
                        }
                    })
                    .catch((e: any) => {
                        console.error(
                            `Error retrieving FiyrPod chargers. ${e.response?.data?.message}`,
                        );
                    });

                getPhysicalProducts('FiyrPod Vest')
                    .then((response) => {
                        setVestsForPurchase(response.data.data);
                    })
                    .catch((e: any) => {
                        console.error(
                            `Error retrieving FiyrPod vests. ${e.response?.data?.message}`,
                        );
                    });

                getPhysicalProducts('Fiyrpod')
                    .then((response) => {
                        setPodsForPurchase(response.data.data);
                        setIsLoaded(true);
                    })
                    .catch((e: any) => {
                        console.error(`Error retrieving FiyrPods. ${e.response?.data?.message}`);
                    });
            } catch (e) {
                console.error(e);
                setSnackMessage(
                    'Error retrieving organization information. Please check with your coach.',
                );
            }
        };

        getOrganizationFromUrlSlug();
    }, []);

    useEffect(() => {
        if (
            subscriptions.some(
                (subscription) =>
                    subscription.stripe_status === 'active' &&
                    subscription.name === 'athlete_combo',
            )
        ) {
            window.location.href = '/athletes';
        }
    }, [subscriptions]);

    const handlePurchaseClick = () => {
        if (Object.values(selectedChargerCountsForPods).every((count) => count === 0)) {
            setWarningForNoChargersSelected(true);
        } else {
            handleComboPurchase();
        }
    };

    const handleComboPurchase = async () => {
        createCheckoutComboAthleteSubscriptionWithVestsAndOptionalChargers(
            `${process.env.REACT_APP_URL}/athletes`,
            organization?.uuid as string,
            selectedPodForPurchase,
            selectedVestCountsForPods,
            selectedChargerCountsForPods,
        )
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((e: any) => {
                console.error(`Error creating checkout session. ${e.response?.data?.message}`);
            });
    };

    const handleVestForPodCountChange = (priceId: string, count: number) => {
        setSelectedVestCountsForPods((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const handleChargerForPodCountChange = (priceId: string, count: number) => {
        setSelectedChargerCountsForPods((prevCounts) => {
            const newCounts = { ...prevCounts };
            if (count === 0) {
                delete newCounts[priceId];
            } else {
                newCounts[priceId] = count;
            }
            return newCounts;
        });
    };

    const calculateTotalPodVestChargerPurchaseCost = () => {
        let initialPrice = 69.99; // Price of single pod plus default subscription.
        const totalVestQuantity = Object.values(selectedVestCountsForPods).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );
        const totalChargerQuantity = Object.values(selectedChargerCountsForPods).reduce(
            (sum, quantity) => sum + quantity,
            0,
        );
        return (totalVestQuantity + totalChargerQuantity) * 40 + initialPrice;
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 8,
                    pb: 6,
                }}
            >
                <Box
                    sx={{
                        width: 150,
                        height: 150,
                        borderRadius: '25%',
                        border: '2px solid',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        overflow: 'hidden',
                        mb: 2,
                    }}
                >
                    <img
                        src={organization?.logo_url || ''}
                        alt={`${organization?.name} logo`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </Box>
                <Typography
                    align="center"
                    color="textPrimary"
                    gutterBottom
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '1.5rem',
                    }}
                >
                    {organization?.name}
                </Typography>
            </Box>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    paddingTop: 20,
                }}
                maxWidth={'xl'}
            >
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={!!snackMessage}
                    autoHideDuration={6000}
                    message={snackMessage}
                    onClose={() => setSnackMessage('')}
                />
                {isLoaded && (
                    <Container
                        sx={{
                            width: { xs: '100%', sm: '40%' },
                            border: '1px solid lightgray',
                            borderRadius: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '24px',
                            position: 'relative',
                        }}
                    >
                        <Box
                            component="img"
                            src={GoldMedal}
                            alt="Gold Medal"
                            sx={{
                                position: 'absolute',
                                top: -25,
                                left: -25,
                                width: 65,
                                height: 65,
                                zIndex: 1,
                            }}
                        />
                        <Typography variant="h6" component="div" gutterBottom>
                            Athlete 🏅 Gold Package - 1Tul and 🔥 Fiyrpod 🔥
                        </Typography>
                        <FormControl fullWidth margin="normal" size="small">
                            <InputLabel id="pod-select-label">Select Pod</InputLabel>
                            <Select
                                variant="outlined"
                                labelId="pod-select-label"
                                value={selectedPodForPurchase}
                                onChange={(e) =>
                                    setSelectedPodForPurchase(e.target.value as string)
                                }
                                label="Select Pod"
                                size="small"
                            >
                                {podsForPurchase.map((pod) =>
                                    pod.inventory_count > 0 ? (
                                        <MenuItem key={pod.uuid} value={pod.uuid}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 24,
                                                    width: 24,
                                                    marginRight: 2,
                                                    display: 'inline-block',
                                                    verticalAlign: 'middle',
                                                }}
                                                alt="Pod"
                                                src={Pod}
                                            />
                                            <Typography
                                                sx={{
                                                    width: 'calc(80% - 24px)',
                                                    fontSize: '0.8rem',
                                                    display: 'inline-block',
                                                }}
                                            >
                                                {pod.name} + 1Tul subscription
                                                <span style={{ fontWeight: '500' }}>
                                                    {' $'}
                                                    {(
                                                        pod.price / 100 +
                                                        DEFAULT_SUBSCRIPTION_PRICE
                                                    ).toFixed(2)}
                                                    /month
                                                </span>
                                                <span
                                                    style={{
                                                        color: 'green',
                                                        fontStyle: 'italic',
                                                        fontSize: '0.65rem',
                                                    }}
                                                >
                                                    {' '}
                                                    {pod.inventory_count} available
                                                </span>
                                            </Typography>
                                        </MenuItem>
                                    ) : null,
                                )}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal" size="small">
                            <Typography
                                sx={{
                                    fontSize: '0.8rem',
                                    fontWeight: '600',
                                    textAlign: 'center',
                                }}
                            >
                                Vests (Select 2 for optimal ✨hygiene)
                            </Typography>
                        </FormControl>
                        {vestsForPurchase.map((vest) => (
                            <FormControl
                                key={vest.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 24,
                                            width: 24,
                                            marginRight: 2,
                                        }}
                                        alt="Vest"
                                        src={Vest}
                                    />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {' '}
                                        {vest.name}
                                        <span style={{ fontWeight: '500' }}>
                                            {' '}
                                            {' $'}
                                            {(vest.price / 100).toFixed(2)}{' '}
                                        </span>
                                        <span
                                            style={{
                                                color: vest.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {vest.inventory_count > 0
                                                ? ` ${vest.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {vest.inventory_count > 0 && (
                                        <QuantityControl
                                            value={
                                                selectedVestCountsForPods[vest.stripe_price_id] || 0
                                            }
                                            onDecrement={() =>
                                                handleVestForPodCountChange(
                                                    vest.stripe_price_id,
                                                    Math.max(
                                                        (selectedVestCountsForPods[
                                                            vest.stripe_price_id
                                                        ] || 0) - 1,
                                                        0,
                                                    ),
                                                )
                                            }
                                            onIncrement={() =>
                                                handleVestForPodCountChange(
                                                    vest.stripe_price_id,
                                                    Math.min(
                                                        (selectedVestCountsForPods[
                                                            vest.stripe_price_id
                                                        ] || 0) + 1,
                                                        vest.inventory_count,
                                                    ),
                                                )
                                            }
                                            incrementDisabled={
                                                (selectedVestCountsForPods[vest.stripe_price_id] ||
                                                    0) >= vest.inventory_count
                                            }
                                            decrementDisabled={
                                                (selectedVestCountsForPods[vest.stripe_price_id] ||
                                                    0) === 0
                                            }
                                        />
                                    )}
                                </Stack>
                            </FormControl>
                        ))}
                        <FormControl fullWidth margin="normal" size="small">
                            <Typography
                                sx={{
                                    fontSize: '0.8rem',
                                    fontWeight: '600',
                                    textAlign: 'center',
                                }}
                            >
                                ⚡ Charger and 📱Phone Cradle for 📶 Maximum Reception and 🛡️
                                Protection
                            </Typography>
                        </FormControl>
                        {chargersForPurchase.map((charger) => (
                            <FormControl
                                key={charger.uuid}
                                fullWidth
                                margin="normal"
                                style={{ marginTop: 0, marginBottom: '8px' }}
                            >
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 24,
                                            width: 24,
                                            marginRight: 2,
                                        }}
                                        alt="Charger"
                                        src={Charger}
                                    />
                                    <Typography
                                        sx={{
                                            width: 'calc(80% - 24px)',
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {' '}
                                        {charger.name}
                                        <span style={{ fontWeight: '500' }}>
                                            {' '}
                                            {' $'}
                                            {(charger.price / 100).toFixed(2)}{' '}
                                        </span>
                                        <span
                                            style={{
                                                color:
                                                    charger.inventory_count > 0 ? 'green' : 'red',
                                                fontStyle: 'italic',
                                                fontSize: '0.65rem',
                                            }}
                                        >
                                            {charger.inventory_count > 0
                                                ? ` ${charger.inventory_count} available`
                                                : ' available soon'}
                                        </span>
                                    </Typography>
                                    {charger.inventory_count > 0 && (
                                        <Stack sx={{ width: '64px', minWidth: '64px' }}>
                                            <Select
                                                variant="outlined"
                                                value={
                                                    selectedChargerCountsForPods[
                                                        charger.stripe_price_id
                                                    ] || 0
                                                }
                                                onChange={(e) =>
                                                    handleChargerForPodCountChange(
                                                        charger.stripe_price_id,
                                                        Number(e.target.value),
                                                    )
                                                }
                                                fullWidth
                                                size="small"
                                                sx={{ fontSize: '0.8rem' }}
                                            >
                                                {[...Array(charger.inventory_count + 1).keys()].map(
                                                    (count) => (
                                                        <MenuItem
                                                            key={count}
                                                            value={count}
                                                            sx={{ fontSize: '0.8rem' }}
                                                        >
                                                            {count}
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Select>
                                        </Stack>
                                    )}
                                </Stack>
                            </FormControl>
                        ))}
                        {selectedPodForPurchase &&
                        Object.keys(selectedVestCountsForPods).length > 0 ? (
                            <Box
                                sx={{
                                    width: '100%',
                                    textAlign: 'right',
                                    padding: '10px 16px 4px 0',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: '0.65rem',
                                        color: '#424242',
                                        fontWeight: '450',
                                        display: 'block',
                                    }}
                                >
                                    Subtotal: ${calculateTotalPodVestChargerPurchaseCost()}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: '0.6rem',
                                        color: '#424242',
                                        display: 'block',
                                    }}
                                >
                                    Tax: (will be calculated)
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: '0.6rem',
                                        color: '#424242',
                                        display: 'block',
                                    }}
                                >
                                    Shipping: ${SHIPPING_FEE.toFixed(2)}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        fontSize: '0.65rem',
                                        color: '#424242',
                                        fontWeight: '600',
                                        display: 'block',
                                    }}
                                >
                                    Total: $
                                    {(
                                        calculateTotalPodVestChargerPurchaseCost() + SHIPPING_FEE
                                    ).toFixed(2)}
                                </Typography>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '4px',
                                    marginBottom: '48px',
                                }}
                            >
                                <span style={{ fontSize: '0.65rem', color: 'red' }}>
                                    Select one pod subscription and at least one vest
                                </span>
                            </Box>
                        )}
                        <Button
                            onClick={handlePurchaseClick}
                            variant="contained"
                            disabled={
                                !selectedPodForPurchase ||
                                Object.keys(selectedVestCountsForPods).length === 0
                            }
                        >
                            Purchase
                        </Button>
                    </Container>
                )}
                {!isLoaded && <CircularProgress />}
                <Dialog
                    open={warningForNoChargersSelected}
                    onClose={() => setWarningForNoChargersSelected(false)}
                >
                    <DialogTitle>No Chargers Selected</DialogTitle>
                    <DialogContent>
                        <Typography>
                            Are you sure you do not want to purchase any chargers?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => setWarningForNoChargersSelected(false)}
                        >
                            No, I will buy one
                        </Button>
                        <Button variant="contained" onClick={handleComboPurchase}>
                            Yes, submit my order
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
};

export default AthleteComboSubscribe;
