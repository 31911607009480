import { styled } from '@mui/system';
import Typography, { TypographyProps } from '@mui/material/Typography';

const TypographyTitleh6 = styled(Typography)<TypographyProps>(({ style }) => ({
    fontWeight: 600,
    fontSize: 16,
    ...style,
}));

export default TypographyTitleh6;
