import { Box, Card, CardActionArea, CardContent, CardHeader, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { SessionMovementGoalsTableHeader } from 'components';
import MovementMedia from 'components/Media/MovementMedia';
import React, { useEffect, useState } from 'react';
import {
    AccelerationUnit,
    DistanceUnit,
    EntityType,
    LoadUnit,
    Log,
    Movement,
    MovementType,
    PowerUnit,
    SetAttributeType,
    SpeedUnit,
    TimeDisplayFormat,
} from 'utils';
import LogRow from './LogRow';

export interface LogSetSummaryProps {
    logs: Array<Log>;
}

export interface SessionMovementGoalSet {
    movement: Movement;
    goalSets: Array<Log>;
}

const LogSetSummary = ({ logs }: LogSetSummaryProps) => {
    const theme = useTheme();
    const [sessionMovementGoalSets, setSessionMovementGoalSets] = useState<
        Array<SessionMovementGoalSet>
    >([]);
    const [openMovementAssetSliderIndex, setOpenMovementAssetSliderIndex] = useState<number | null>(
        null,
    );

    useEffect(() => {
        let goalSets: Array<SessionMovementGoalSet> = [];

        for (let i = 0; i < logs.length; i++) {
            if (i == 0 || logs[i].movement.uuid != logs[i - 1].movement.uuid) {
                goalSets.push({
                    movement: {
                        uuid: logs[i].movement?.uuid,
                        name: logs[i].movement.name,
                        object: EntityType.MOVEMENT,
                        description: logs[i].movement.description,
                        movement_type: logs[i].movement.movement_type as MovementType,
                        published_at: logs[i].movement.published_at,
                        assets: logs[i].movement.assets,
                    },
                    goalSets: [logs[i]],
                });
            } else {
                goalSets[goalSets.length - 1].goalSets.push(logs[i]);
            }
        }

        setSessionMovementGoalSets(goalSets);
    }, [logs]);

    //A goal set is a set of movement attached to the session with reps, load, distance, time etc
    // A movement can just have reps and load while other movement from same session might have
    // distance and time
    // This function will get the goal criteria which are applicable for a movement goal set
    // This is used to show header columns as if there is no distance goal for any set of a session movement
    // We don't need to show it in the header
    const getGoalCriteriasFromGoalSet = (
        sessionMovementGoalSet: SessionMovementGoalSet,
    ): Array<SetAttributeType> => {
        let goalCriterias: Set<SetAttributeType> = new Set();

        sessionMovementGoalSet.goalSets.forEach((goalSet: Log) => {
            if (goalSet?.reps_goal != null || goalSet?.reps != null) {
                goalCriterias.add(SetAttributeType.Reps);
            }
            if (goalSet?.load_goal_value != null || goalSet?.load_value != null) {
                goalCriterias.add(SetAttributeType.Load);
            }
            if (goalSet?.time_goal_value != null || goalSet?.time_value != null) {
                goalCriterias.add(SetAttributeType.Time);
            }
            if (goalSet?.distance_goal_value != null || goalSet?.distance_value != null) {
                goalCriterias.add(SetAttributeType.Distance);
            }
            if (
                goalSet?.range_of_motion_goal_value != null ||
                goalSet?.range_of_motion_value != null
            ) {
                goalCriterias.add(SetAttributeType.RangeOfMotion);
            }
            if (goalSet?.speed_goal_value != null || goalSet?.speed_value != null) {
                goalCriterias.add(SetAttributeType.Speed);
            }
            if (goalSet?.acceleration_goal_value != null || goalSet?.acceleration_value != null) {
                goalCriterias.add(SetAttributeType.Acceleration);
            }
            if (goalSet?.power_goal_value != null || goalSet?.power_value != null) {
                goalCriterias.add(SetAttributeType.Power);
            }
            if (goalSet?.rsi_goal_value != null || goalSet?.rsi_value != null) {
                goalCriterias.add(SetAttributeType.RSI);
            }
            if (goalSet?.rpm_goal_value != null || goalSet?.rpm_value != null) {
                goalCriterias.add(SetAttributeType.RPM);
            }
            if (goalSet?.force_goal_value != null || goalSet?.force_value != null) {
                goalCriterias.add(SetAttributeType.Force);
            }
            if (goalSet?.body_side_goal != null || goalSet?.body_side != null) {
                goalCriterias.add(SetAttributeType.BodySide);
            }
            if (goalSet?.rpe_goal_value != null || goalSet?.rpe_value != null) {
                goalCriterias.add(SetAttributeType.RPE);
            }
            if (goalSet?.band_goal_value != null || goalSet?.band_value != null) {
                goalCriterias.add(SetAttributeType.Band);
            }
            if (goalSet?.rest_goal_value != null || goalSet?.rest_value != null) {
                goalCriterias.add(SetAttributeType.Rest);
            }
            if (
                goalSet?.gps_player_load_goal_value != null ||
                goalSet?.gps_player_load_value != null
            ) {
                goalCriterias.add(SetAttributeType.GPSPlayerLoad);
            }
            if (
                goalSet?.gps_accel_count_goal_value != null ||
                goalSet?.gps_accel_count_value != null
            ) {
                goalCriterias.add(SetAttributeType.GPSAccelCount);
            }
            if (
                goalSet?.gps_decel_count_goal_value != null ||
                goalSet?.gps_decel_count_value != null
            ) {
                goalCriterias.add(SetAttributeType.GPSDecelCount);
            }
        });
        if (goalCriterias.size == 0) {
            // Add at least one movement preset if none have been selected
            goalCriterias.add(SetAttributeType.Reps);
        }

        return Array.from(goalCriterias);
    };

    return (
        <>
            {sessionMovementGoalSets.length > 0 ? (
                <div>
                    {sessionMovementGoalSets.map((sessionMovementGoalSet, goalSetIndex) => {
                        const applicableCriterias =
                            getGoalCriteriasFromGoalSet(sessionMovementGoalSet);

                        return (
                            <Card
                                sx={{
                                    '& .MuiInputBase-root': {
                                        borderBottom: `1px solid transparent`,
                                        '&:hover, &:focus': {
                                            borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                        },
                                    },
                                }}
                                key={goalSetIndex}
                            >
                                <CardActionArea
                                    disableRipple={true}
                                    onClick={() => {
                                        if (openMovementAssetSliderIndex == goalSetIndex) {
                                            setOpenMovementAssetSliderIndex(null);
                                        } else {
                                            setOpenMovementAssetSliderIndex(goalSetIndex);
                                        }
                                    }}
                                >
                                    <CardHeader
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        title={
                                            <Typography
                                                component="div"
                                                sx={{
                                                    flexGrow: 1,
                                                }}
                                            >
                                                {sessionMovementGoalSet.movement.name}
                                            </Typography>
                                        }
                                        avatar={
                                            <Box
                                                width={
                                                    openMovementAssetSliderIndex == goalSetIndex
                                                        ? '100%'
                                                        : 80
                                                }
                                                flex={
                                                    openMovementAssetSliderIndex == goalSetIndex
                                                        ? '0 0 100%'
                                                        : 'inherit'
                                                }
                                            >
                                                <MovementMedia
                                                    movement={sessionMovementGoalSet.movement}
                                                />
                                            </Box>
                                        }
                                    />
                                </CardActionArea>
                                <CardContent
                                    sx={{
                                        display: 'block',
                                        backgroundColor: theme.palette.backgroundColor.hover,
                                        borderTop: `1px solid ${theme.palette.grey[300]}`,
                                    }}
                                >
                                    {sessionMovementGoalSet.goalSets.length > 0 ? (
                                        <>
                                            {/* Headers */}
                                            <SessionMovementGoalsTableHeader
                                                applicableCriterias={applicableCriterias}
                                                loadUnit={
                                                    (sessionMovementGoalSet.goalSets[0]
                                                        .load_unit as LoadUnit) || LoadUnit.Pound
                                                }
                                                distanceUnit={
                                                    (sessionMovementGoalSet.goalSets[0]
                                                        .distance_unit as DistanceUnit) ||
                                                    DistanceUnit.Mile
                                                }
                                                speedUnit={
                                                    (sessionMovementGoalSet.goalSets[0]
                                                        .speed_unit as SpeedUnit) || SpeedUnit.MPH
                                                }
                                                accelerationUnit={
                                                    (sessionMovementGoalSet.goalSets[0]
                                                        .acceleration_unit as AccelerationUnit) ||
                                                    AccelerationUnit.FPS
                                                }
                                                powerUnit={
                                                    (sessionMovementGoalSet.goalSets[0]
                                                        .power_unit as PowerUnit) || PowerUnit.Watt
                                                }
                                                timeDisplayFormat={
                                                    (sessionMovementGoalSet.goalSets[0]
                                                        .time_display_format as TimeDisplayFormat) ||
                                                    TimeDisplayFormat.Common
                                                }
                                            />

                                            {sessionMovementGoalSet.goalSets.map(
                                                (goalSet, index) => {
                                                    return (
                                                        <LogRow
                                                            key={`goal-set-${goalSetIndex}-session-moment-row-${index}`}
                                                            index={index}
                                                            goalSet={goalSet}
                                                            applicableCriterias={
                                                                applicableCriterias
                                                            }
                                                        />
                                                    );
                                                },
                                            )}
                                        </>
                                    ) : (
                                        <Typography variant="body2">No sets added.</Typography>
                                    )}
                                </CardContent>
                            </Card>
                        );
                    })}
                </div>
            ) : (
                <Box pt={20} display="flex" justifyContent="center" pb={20}>
                    <Typography variant="body2">
                        This session does not contain any movements.
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default LogSetSummary;
