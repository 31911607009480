import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import LearningContent from 'images/LearningContent.png';
import FullScreenDialogCard from '../components/HowToDialogCard';
import AssessmentScore from '../media/AssessmentScore.png';
import LearningAssessmentsList from '../media/AdminSessionsListNew.png';

function AdminLearningContentOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL provides you a comprehensive learning management platform so you can
                        teach and track knowledge retention.{' '}
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={LearningContent}
                    title="Learning Content"
                    headline="Learning Management"
                    description="Create and share learning content with athletes and coaches. Track
                                who has completed the content and their knowledge retention."
                />
                <FullScreenDialogCard
                    image={AssessmentScore}
                    title="Scored Exams and Learning Assessments"
                    headline="Scored Exams and Learning Assessments"
                    description="Quickly view the scores of submitted exams and learning assessments."
                />
                <FullScreenDialogCard
                    image={LearningAssessmentsList}
                    title="Learning Content List"
                    headline="Learning Content List"
                    description="View all your learning content in one spot."
                />
            </Grid>
        </>
    );
}

export default AdminLearningContentOverview;
