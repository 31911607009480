import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    DataGridPremium,
    GridSortModel,
    GridColDef,
    GridValueFormatter,
    GridPaginationModel,
} from '@mui/x-data-grid-premium';
import { Organization } from '../../utils';
import { AxiosResponse } from 'axios';
import { Request } from '../../api';
import { getIndividuals } from '../../api';
import { requestPodTransferToAthlete } from '../../api/Organization/requestPodTransferToAthlete';
import { styled } from '@mui/system';
import { debounce } from 'lodash';
import { cancelPodTransferToAthlete } from '../../api/Organization/cancelPodTransferToAthlete';
import { Pod } from '../../modules/admin/modules/pods/pods.types';

const StyledDataGrid = styled(DataGridPremium)({
    '& .MuiDataGrid-cell:focus': {
        outline: 'none',
        border: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
        border: 'none',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within .MuiDataGrid-sortIcon': {
        border: 'none',
        outline: 'none',
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },
    '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
    },
    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
});

interface GetOrganizationPodsParams {
    serial_number?: string;
    simple_id?: string;
    athlete_name?: string;
    organization_name?: string;
    level?: number;
    created_at?: string;
    sort?:
        | 'serial_number'
        | 'created_at'
        | 'athlete_name'
        | '-serial_number'
        | '-created_at'
        | '-athlete_name';
    page?: number;
    per_page?: number;
}

interface OrganizationPod {
    uuid: string;
    serial_number: string;
    simple_id: string;
    athlete_name: string | null;
    transfer_request_sent_to: string | null;
    transfer_request_sent_at: Date;
    transfer_request_accepted_at: Date;
    created_at: Date;
}

interface Individual {
    name: string;
    uuid: string;
    image_urls: {
        avatar: string | null;
    };
}

const getPodsForOrganization = (
    organizationUuid: string,
    options: GetOrganizationPodsParams,
): Promise<AxiosResponse<any>> => {
    return Request.get(`/organizations/${organizationUuid}/pods`, {
        params: {
            'filter[serial_number]': options.serial_number,
            'filter[simple_id]': options.simple_id,
            'filter[athlete_name]': options.athlete_name,
            'filter[organization_name]': options.organization_name,
            level: options.level,
            sort: options.sort,
            page: options.page,
            per_page: options.per_page,
        },
    });
};

const PodsPage = ({ organization }: { organization: Organization }) => {
    const [individuals, setIndividuals] = useState<Individual[]>([]);
    const [podTransferNameFilter, setPodTransferNameFilter] = useState<string>('');
    const [searchParams, setSearchParams] = useState<GetOrganizationPodsParams>({
        serial_number: '',
        simple_id: '',
        athlete_name: '',
        organization_name: '',
        level: 0,
        sort: '-created_at',
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [canTransferPod, setCanTransferPod] = useState(true);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });
    const [totalRows, setTotalRows] = useState<number>(0);
    const [pods, setPods] = useState<OrganizationPod[]>([]);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));

    const openDialogModal = (title: string, message: string) => {
        setDialogTitle(title);
        setDialogMessage(message);
        setOpenDialog(true);
    };

    const closeDialogModal = () => {
        setOpenDialog(false);
    };

    const fetchPods = (options?: GetOrganizationPodsParams) => {
        let requestParams = {
            ...searchParams,
            ...options,
            page: paginationModel.page + 1,
            per_page: paginationModel.pageSize,
        };
        console.debug('fetchPods params', requestParams);
        getPodsForOrganization(organization.uuid, requestParams)
            .then((response) => {
                setCanTransferPod(requestParams.level === 0);
                const updatedPods = response.data.data.map((pod: OrganizationPod) => {
                    const transferRequestAcceptedAt = pod.transfer_request_accepted_at
                        ? new Date(pod.transfer_request_accepted_at).toLocaleString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                          })
                        : '';
                    return {
                        ...pod,
                        transfer_request_accepted_at: transferRequestAcceptedAt,
                    };
                });
                setPods(updatedPods);
                setTotalRows(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch pods', error);
                openDialogModal('Error', `Failed to fetch pods: ${error.response.data.message}.`);
            });
    };

    const fetchIndividuals = () => {
        getIndividuals({
            'filter[organization]': organization.uuid,
            'filter[name]': podTransferNameFilter,
        })
            .then((response) => {
                setIndividuals(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch individuals', error);
            });
    };

    const debouncedSetPodTransferNameFilter = debounce((newInputValue) => {
        setPodTransferNameFilter(newInputValue);
    }, 300);

    useEffect(() => {
        fetchPods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel]);

    useEffect(() => {
        fetchIndividuals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [podTransferNameFilter]);

    const handlePaginationModelChange = (newPaginationModel: GridPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };

    const columns: GridColDef[] = [
        { field: 'organization_name', headerName: 'Organization', width: 120, sortable: true },
        { field: 'serial_number', headerName: 'Serial Number', width: 120, sortable: true },
        { field: 'simple_id', headerName: 'Simple ID', width: 100, sortable: true },
        { field: 'athlete_name', headerName: 'Athlete', width: 150, sortable: true },
        {
            field: 'transfer_info',
            headerName: 'Transfer Request Sent To',
            width: 200,
            sortable: false,
            renderCell: (params: { row: OrganizationPod }) => {
                const transferTo = params.row.transfer_request_sent_to;
                const transferAt = params.row.transfer_request_sent_at
                    ? new Date(params.row.transfer_request_sent_at).toLocaleString('en-US', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                      })
                    : '';
                return transferTo ? (
                    <div>
                        <span style={{ fontSize: '0.7rem' }}>{transferTo}&nbsp;</span>
                        <Chip
                            label={transferAt}
                            color="primary"
                            sx={{
                                borderRadius: '2px',
                                fontSize: '0.6rem',
                                height: '16px',
                                '& span': {
                                    padding: '1px',
                                },
                            }}
                        />
                    </div>
                ) : (
                    ''
                );
            },
        },
        {
            field: 'transfer_request_accepted_at',
            headerName: 'Transfer Request Accepted At',
            width: 220,
            sortable: true,
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 150,
            sortable: true,
            valueFormatter: ((params: number) => {
                return params ? new Date(params).toLocaleDateString('en-US') : '';
            }) as GridValueFormatter<Pod>,
        },
        {
            field: 'transfer',
            headerName: 'Transfer',
            width: 150,
            sortable: false,
            renderCell: (params: { row: OrganizationPod }) =>
                !params.row.athlete_name &&
                !params.row.transfer_request_sent_at &&
                canTransferPod ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleTransferClick(params.row)}
                        sx={{ fontSize: '0.8rem', textTransform: 'none' }}
                    >
                        Transfer to Athlete
                    </Button>
                ) : params.row.transfer_request_sent_to &&
                  !params.row.transfer_request_accepted_at ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleCancelPendingTransferClick(params.row)}
                        sx={{ fontSize: '0.8rem', textTransform: 'none' }}
                    >
                        Cancel Transfer
                    </Button>
                ) : null,
        },
    ];

    const [openConfirmTransferDialog, setOpenConfirmTransferDialog] = useState(false);
    const [openConfirmCancelPendingTransferDialog, setOpenConfirmCancelPendingTransferDialog] =
        useState(false);
    const [selectedPod, setSelectedPod] = useState<OrganizationPod | null>(null);
    const [selectedIndividual, setSelectedIndividual] = useState<Individual | null>(null);

    const handleTransferClick = (pod: OrganizationPod) => {
        setSelectedPod(pod);
        setOpenConfirmTransferDialog(true);
    };

    const handleCancelPendingTransferClick = (pod: OrganizationPod) => {
        setSelectedPod(pod);
        setOpenConfirmCancelPendingTransferDialog(true);
    };

    const handleCancelTransferClick = () => {
        setOpenConfirmTransferDialog(false);
        setPodTransferNameFilter('');
        setSelectedIndividual(null);
    };

    const handleConfirmTransferClick = () => {
        if (selectedIndividual && selectedPod) {
            requestPodTransferToAthlete(organization.uuid, selectedPod.uuid, {
                individual_uuid: selectedIndividual.uuid,
            })
                .then((response: AxiosResponse) => {
                    console.debug('Transfer request successful', response);
                    setOpenConfirmTransferDialog(false);
                    setPodTransferNameFilter('');
                    setSelectedIndividual(null);
                    openDialogModal('Success', response.data.message);
                    fetchPods();
                })
                .catch((error: any) => {
                    setOpenConfirmTransferDialog(false);
                    console.error('Failed to request transfer', error);
                    let errorMsg = error.response?.data?.message || 'an unknown error occurred.';
                    openDialogModal('Error', `Failed to request transfer: ${errorMsg}`);
                });
        }
    };

    const handleConfirmCancelPendingTransferClick = () => {
        if (selectedPod) {
            cancelPodTransferToAthlete(organization.uuid, selectedPod.uuid)
                .then((response: AxiosResponse) => {
                    console.debug('Cancel pod transfer request successful', response);
                    setOpenConfirmCancelPendingTransferDialog(false);
                    openDialogModal('Success', response.data.message);
                    fetchPods();
                })
                .catch((error: any) => {
                    setOpenConfirmCancelPendingTransferDialog(false);
                    console.error('Failed to cancel pod transfer', error);
                    let errorMsg = error.response?.data?.message || 'an unknown error occurred.';
                    openDialogModal('Error', `Failed to cancel pod transfer: ${errorMsg}`);
                });
        }
    };

    const handleSearchFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setSearchParams({
            ...searchParams,
            [event.target.name as string]: event.target.value,
        });
    };

    const handleSort = (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        /**
         * Sort will be undefined, or it will be ascending, ie "createdAt", or in this case
         * it will be descending, ie "-createdAt". If descending, we prepend a hyphen.
         */
        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }
        let updatedSearchParams = {
            ...searchParams,
            sort: sortField as GetOrganizationPodsParams['sort'],
        };
        setSearchParams(updatedSearchParams);
        fetchPods(updatedSearchParams);
    };

    return (
        <>
            <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        marginY: 4,
                        marginX: 10,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: isSmallScreen ? 'column' : 'row',
                        flexWrap: 'wrap',
                    }}
                >
                    <Box
                        sx={{
                            marginRight: 1,
                            spacing: 1,
                            flexDirection: isSmallScreen ? 'column' : 'row',
                            flexWrap: 'wrap',
                            display: 'flex',
                        }}
                    >
                        <FormControl
                            size="small"
                            sx={{ width: '240px', display: 'inline-flex', marginRight: '2px' }}
                        >
                            <InputLabel id="order-status-label">Downline Level</InputLabel>
                            <Select
                                label="Downline Level"
                                value={searchParams.level}
                                onChange={(e) => {
                                    const newLevel = e.target.value as number;
                                    setSearchParams((prev) => ({
                                        ...prev,
                                        level: newLevel,
                                    }));
                                }}
                                size="small"
                                sx={{ width: '240px', display: 'inline-flex', marginRight: '2px' }}
                            >
                                <MenuItem value={0}>My Pods</MenuItem>
                                <MenuItem value={1}>Level 1 Pods</MenuItem>
                                <MenuItem value={2}>Level 2 Pods</MenuItem>
                                <MenuItem value={3}>Level 3 Pods</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            name="serial_number"
                            value={searchParams.serial_number}
                            onChange={handleSearchFieldChange}
                            placeholder="Enter Serial Number"
                            label="Serial Number"
                            size={'small'}
                            style={{ margin: '2px' }}
                        />
                        <TextField
                            name="simple_id"
                            value={searchParams.simple_id}
                            onChange={handleSearchFieldChange}
                            placeholder="Enter Simple ID"
                            label="Simple ID"
                            size={'small'}
                            style={{ margin: '2px' }}
                        />
                        <TextField
                            name="athlete_name"
                            value={searchParams.athlete_name}
                            onChange={handleSearchFieldChange}
                            placeholder="Enter Athlete Name"
                            label="Athlete Name"
                            size={'small'}
                            style={{ margin: '2px' }}
                        />
                        <TextField
                            name="organization_name"
                            value={searchParams.organization_name}
                            onChange={handleSearchFieldChange}
                            placeholder="Enter Organization Name"
                            label="Organization Name"
                            size={'small'}
                            style={{ margin: '2px' }}
                        />
                    </Box>
                    <Box>
                        <Button
                            type="submit"
                            size={'medium'}
                            onClick={() => fetchPods()}
                            variant="contained"
                            sx={{ marginBottom: '8px' }}
                        >
                            Search Pods
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        marginX: 'auto',
                        width: '98%',
                        '& .super-app.normal': {
                            backgroundColor: '#FFFFFF',
                            color: '#252525',
                            fontWeight: '400',
                        },
                        '& .super-app.negative': {
                            backgroundColor: '#FFFFFF',
                            color: '#252525',
                            fontWeight: '400',
                        },
                        '& .super-app.positive': {
                            backgroundColor: '#FFFFFF',
                            color: '#252525',
                            fontWeight: '400',
                        },
                        maxHeight: '600px',
                        overflow: 'auto',
                    }}
                >
                    <StyledDataGrid
                        rows={pods}
                        columns={columns}
                        checkboxSelection={false}
                        sortingMode="server"
                        onSortModelChange={(model) => handleSort(model)}
                        getRowId={(row) => row.serial_number}
                        style={{ height: '624px', marginBottom: '50px' }}
                        pagination
                        paginationMode="server"
                        rowCount={totalRows}
                        paginationModel={paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        pageSizeOptions={[10, 25, 50, 100]}
                    />
                </Box>
            </Paper>
            <Dialog
                open={openConfirmTransferDialog}
                onClose={() => setOpenConfirmTransferDialog(false)}
            >
                <DialogTitle>Transfer Pod to Athlete</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        id="individuals-select"
                        options={individuals}
                        getOptionLabel={(option) => option.name}
                        value={selectedIndividual}
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason === 'input') {
                                debouncedSetPodTransferNameFilter(newInputValue);
                            }
                        }}
                        onChange={(event, newValue) => {
                            setSelectedIndividual(newValue);
                        }}
                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                        renderOption={(props, option) => (
                            <li
                                {...props}
                                key={option.uuid}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                {option.image_urls.avatar ? (
                                    <img
                                        src={option.image_urls.avatar}
                                        alt={option.name}
                                        style={{
                                            marginRight: 8,
                                            width: 24,
                                            height: 24,
                                        }}
                                    />
                                ) : (
                                    // Optional: Display a default image or leave as is for no image
                                    <div
                                        style={{
                                            marginRight: 8,
                                            width: 24,
                                            height: 24,
                                        }}
                                    ></div>
                                )}
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} variant="standard" label="Select Individual" />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelTransferClick}
                        sx={{
                            color: 'primary.main',
                            backgroundColor: '#fff',
                            borderColor: 'primary.main',
                            textTransform: 'none',
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmTransferClick}
                        color="primary"
                        sx={{
                            backgroundColor: 'primary.main',
                            color: '#fff',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: 'primary.main',
                                color: '#fff',
                            },
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirmCancelPendingTransferDialog}
                onClose={() => setOpenConfirmCancelPendingTransferDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Paper style={{ minWidth: '300px' }}>
                    <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This will cancel this pod transfer. You can then transfer this pod to
                            another athlete.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setOpenConfirmCancelPendingTransferDialog(false)}
                            variant="outlined"
                            autoFocus
                        >
                            Close
                        </Button>
                        <Button
                            onClick={handleConfirmCancelPendingTransferClick}
                            variant="contained"
                            autoFocus
                        >
                            Cancel Transfer
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
            <Dialog
                open={openDialog}
                onClose={closeDialogModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Paper style={{ minWidth: '300px' }}>
                    <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialogMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialogModal} variant="contained" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
        </>
    );
};
export default PodsPage;
