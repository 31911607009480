import { CombinedState, combineReducers } from 'redux';
import { CalendarEventState, eventsSelector, eventsSlice, loadEvents } from './events';
import { CalendarFilterState, filtersSelector, filtersSlice, loadFilters } from './filters';

export type CalendaReducerState = CombinedState<{
    filters: CalendarFilterState;
    events: CalendarEventState;
}>;

export const calendarReducer = combineReducers<CalendaReducerState>({
    filters: filtersSlice.reducer,
    events: eventsSlice.reducer,
});

export const calendarActions = {
    loadEvents,
    loadFilters,
};

export const calendarSelectors = {
    filtersSelector,
    eventsSelector,
};
