import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import { orderBy } from 'lodash';
import { useContext } from 'react';
import { getInitials } from 'utils';
import { AbilityContext } from 'components/Functional/Can';

const AssociatedOrgList = () => {
    const orgProvider = useContext(OrganizationsContext);
    const ability = useContext(AbilityContext);

    return (
        <>
            <List>
                {orderBy(
                    orgProvider.organizations.filter((o) => ability.can('organization:update', o)),
                    ['name'],
                    ['asc'],
                )?.map((organization) => (
                    <ListItem button key={organization.name}>
                        <ListItemAvatar>
                            <Avatar
                                variant="rounded"
                                src={organization.logo_url || ''}
                                alt={organization.name}
                                style={{
                                    width: 40,
                                    height: 40,
                                }}
                            >
                                {getInitials(organization.name)}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>{organization.name}</ListItemText>
                    </ListItem>
                ))}
            </List>
        </>
    );
};

export default AssociatedOrgList;
