import { ErrorResponse, FormikError } from './auth.types';

export function transformErrorIntoFormikError(errors: ErrorResponse) {
    let errorObject = {} as FormikError;

    Object.keys(errors).map(function (key) {
        errorObject[key] = errors[key][0];
    });

    return errorObject;
}
