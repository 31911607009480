import { CircularProgress } from '@mui/material';
import { Videocam } from '@mui/icons-material';
import React, { ReactElement } from 'react';
import { Asset, getImageFromVersions, Movement } from 'utils';
import { MovementVideoClip } from 'components/Functional/MovementVideoClip';

interface MovementMediaProps {
    movement: Movement;
    processingIcon?: ReactElement;
    noMediaIcon?: ReactElement;
}

interface MovementImageProps {
    src: string;
    alt: string;
}

// Whenever we are rendering an image of a movement, this will be used
// This uses basic img component with objectFit
// The image will never exceed 600 X 600 px dimensions and uses `fit contain` to avoid stretching
const MovementImage: React.FC<React.PropsWithChildren<MovementImageProps>> = ({ src, alt }) => (
    <img
        alt={alt}
        src={src}
        style={{
            maxWidth: '100%',
            objectFit: 'contain',
            height: 'auto',
        }}
    />
);

interface MovementVideoProps {
    assets: Asset[];
}

// Whenever we are rendering a vide of a movement, this will be used
const MovementVideo: React.FC<React.PropsWithChildren<MovementVideoProps>> = ({ assets }) => (
    <MovementVideoClip
        assets={assets}
        clickable={false}
        style={{ width: '100%', maxWidth: '100%' }}
        videoProps={{
            style: {
                width: '100%',
                height: 'auto',
            },
        }}
    />
);

const MovementMedia: React.FC<React.PropsWithChildren<MovementMediaProps>> = ({
    movement,
    noMediaIcon = <Videocam />,
    processingIcon = <CircularProgress />,
}) => {
    const isVideo = (asset: Asset): boolean => {
        return (
            asset.versions.findIndex(
                (v) =>
                    v.file_mime_type.startsWith('video') ||
                    ['application/vnd.apple.mpegurl'].indexOf(v.file_mime_type) > -1,
            ) > -1
        );
    };

    const isImage = (asset: Asset): boolean => {
        return asset.versions.findIndex((v) => v.file_mime_type.startsWith('image')) > -1;
    };

    const isReady = movement.assets.findIndex((a) => a.status === 'ready') > -1;
    const isProcessing = movement.assets.findIndex((a) => a.status === 'processing') > -1;
    const isUploaded = movement.assets.findIndex((a) => a.status === 'uploaded') > -1;
    const firstVideo = movement.assets.find((asset) => isVideo(asset));
    const firstImage = movement.assets.find((asset) => isImage(asset));

    if (movement.is_uploading && !isReady) {
        return processingIcon;
    }

    // Movement asset is in processing status(Encoding or uploading etc)
    if (isProcessing || isUploaded) {
        return processingIcon;
    }

    // There is no cover asset and no other assets to choose any media from
    if (movement.assets.length == 0 && !movement.cover_asset) {
        return noMediaIcon;
    }

    // We have a cover asset which is a video
    if (movement.cover_asset && isVideo(movement.cover_asset)) {
        return <MovementVideo assets={[movement.cover_asset]} />;
    }

    // We have a cover asset which is an image
    if (movement.cover_asset && isImage(movement.cover_asset)) {
        return (
            <MovementImage
                alt={movement.name}
                src={getImageFromVersions(movement.cover_asset.versions)}
            />
        );
    }

    // We don't have a cover asset so check if there is any video present in the assets
    if (firstVideo) {
        return <MovementVideo assets={[firstVideo]} />;
    }

    // We don't have cover asset and there is no video in the assets
    // So now check if there is any image present
    if (firstImage) {
        return (
            <MovementImage alt={movement.name} src={getImageFromVersions(firstImage.versions)} />
        );
    }

    // At this point we dont have any valid media asset to show
    return null;
};

export default MovementMedia;
