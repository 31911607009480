import axios, { AxiosResponse } from 'axios';

type ReturnType = Promise<
    AxiosResponse<{
        status: number;
    }>
>;

const uploadFile = (url: string, file?: File): ReturnType => {
    return axios.put(url, file, {
        headers: {
            Accept: '*/*',
            'Content-Type': file?.type || 'image/png',
        },
    });
};

export default uploadFile;
