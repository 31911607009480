import { Box, CircularProgress, Container } from '@mui/material';
import { getForm } from 'api';
import Placeholder from 'images/placeholder.jpeg';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'utils';
import { AssessmentSubmissionPreview } from '../components';

const ViewAssessment = () => {
    const { location } = useHistory();
    const path = location.pathname.split('/');
    const assessmentUuid = path[path.length - 2];

    const [assessment, setAssessment] = useState<Form | null>(null);
    const isLoading = !assessment;

    useEffect(() => {
        if (!assessment) {
            getForm(assessmentUuid).then((response) => {
                setAssessment(response.data as Form);
            });
        }
    }, [assessmentUuid, assessment]);
    return (
        <FixedLayout
            title={assessment?.title || 'View Assessment'}
            content={
                <Container maxWidth="md">
                    {isLoading ? (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 50,
                                minHeight: '200px',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {assessment?.asset && (
                                <Box
                                    sx={{
                                        maxWidth: 500,
                                        margin: 'auto',
                                    }}
                                >
                                    <figure>
                                        <Box
                                            component={'img'}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                objectPosition: 'center center',
                                                cursor: 'pointer',
                                            }}
                                            src={assessment.image_urls['1000'] ?? Placeholder}
                                            alt={assessment?.title}
                                        />
                                    </figure>
                                </Box>
                            )}
                            {assessment && <AssessmentSubmissionPreview form={assessment} view />}
                        </>
                    )}
                </Container>
            }
        />
    );
};

export default ViewAssessment;
