import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { AbilityContext } from 'components/Functional/Can';

import PlaceholderImage from 'images/placeholder.jpeg';
import React, { FC, ReactElement, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from 'Routes/routes';
import { Form } from 'utils';
import { ButtonProps } from '@mui/material/Button';
import { unarchiveForm } from '../learn.api';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import { loadAssessments } from 'redux/reducers/assessments';

type ButtonGroupProps = {
    children: React.ReactNode;
};

type AssessmentListCardProps = {
    hasActiveMenuItem: boolean;
    assessment: Form;
    beginButton?: ReactElement<ButtonProps>;
    buttonGroup?: ReactElement<ButtonGroupProps>;
    currentQuery?: Record<string, any>;
};

const AssessmentListCard: FC<React.PropsWithChildren<AssessmentListCardProps>> = ({
    hasActiveMenuItem,
    assessment,
    beginButton,
    buttonGroup,
    currentQuery,
}) => {
    const { push } = useHistory();
    const { title, uuid, organization } = assessment;
    const ability = useContext(AbilityContext);
    const dispatch = useAppDispatch();

    const handleUnarchive = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            await unarchiveForm(assessment.uuid);

            dispatch(
                pushMessage({
                    status: 'success',
                    message: 'Form unarchived successfully',
                }),
            );

            if (currentQuery) {
                dispatch(loadAssessments({ query: currentQuery }));
            }
        } catch (error) {
            dispatch(
                pushMessage({
                    status: 'error',
                    message: 'Failed to unarchive form',
                }),
            );
            console.error(error);
        }
    };

    return (
        <Card
            sx={{
                flex: '1 0 220px',
                maxWidth: 220,
                '&:hover': {
                    // update elevation for added visibility
                    boxShadow: [
                        '0px 3px 3px -2px rgb(0 0 0 / 20%)',
                        '0px 3px 4px 0px rgb(0 0 0 / 14%)',
                        '0px 1px 8px 0px rgb(0 0 0 / 12%)',
                    ].join(','),
                },
            }}
            elevation={1}
        >
            <Box
                display="flex"
                flexDirection="column"
                flexWrap="wrap"
                height="100%"
                sx={{ padding: '8px 10px', alignItems: 'center' }}
                role="button"
                onClick={() => {
                    if (hasActiveMenuItem) return;

                    if (ability.can('form:update', assessment)) {
                        push(ROUTES.CreateAssessment.path.replace(':id', uuid));
                    }
                }}
            >
                <CardMedia
                    component="img"
                    sx={{ height: 100, width: 'auto', objectFit: 'contain' }}
                    image={
                        assessment.image_urls['200']
                            ? assessment.image_urls['200']
                            : PlaceholderImage
                    }
                    alt="Assessment Photo"
                />
                <Box display="flex" flexDirection="column" width="100%" alignItems="flex-start">
                    <CardContent sx={{ flex: '1 0', padding: '3px 10px 0' }}>
                        <Box>
                            <Typography sx={{ padding: 0 }} variant="body2">
                                {title} {assessment.deleted_at ? '(Archived)' : ''}
                            </Typography>
                        </Box>
                        <Typography sx={{ fontSize: 10 }} color="textSecondary">
                            {organization.name}
                        </Typography>
                    </CardContent>
                    {assessment.deleted_at ? (
                        <CardActions
                            sx={{
                                padding: '8px 10px',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <Button variant="contained" size="small" onClick={handleUnarchive}>
                                unarchive
                            </Button>
                        </CardActions>
                    ) : (
                        <CardActions
                            sx={{
                                padding: '8px 10px',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box style={{ width: '100%' }}>
                                <Box
                                    style={{
                                        width: '33%',
                                        display: 'inline-block',
                                    }}
                                ></Box>
                                <Box
                                    style={{
                                        width: '33%',
                                        display: 'inline-block',
                                    }}
                                >
                                    {beginButton}
                                </Box>
                                <Box
                                    style={{
                                        width: '33%',
                                        display: 'inline-block',
                                        textAlign: 'right',
                                    }}
                                >
                                    {buttonGroup}
                                </Box>
                            </Box>
                        </CardActions>
                    )}
                </Box>
            </Box>
        </Card>
    );
};

export default AssessmentListCard;
