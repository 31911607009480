import Grid from '@mui/material/Grid';
import { Skeleton } from '@mui/material';
import React, { ReactElement } from 'react';
import useTheme from '@mui/material/styles/useTheme';

export default function CardsListSkeleton(): ReactElement {
    const theme = useTheme();
    return (
        <Grid
            container
            sx={{
                margin: theme.spacing(8, 'auto'),
                width: 300,
                [theme.breakpoints.up('md')]: {
                    width: 720,
                },
            }}
            spacing={10}
        >
            <Grid item xs={12}>
                <Skeleton sx={{ borderRadius: 4 }} animation="wave" variant="text" height={50} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton sx={{ borderRadius: 4 }} animation="wave" variant="text" height={50} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton sx={{ borderRadius: 4 }} animation="wave" variant="text" height={50} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton sx={{ borderRadius: 4 }} animation="wave" variant="text" height={50} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton sx={{ borderRadius: 4 }} animation="wave" variant="text" height={50} />
            </Grid>
        </Grid>
    );
}
