import { GridColDef } from '@mui/x-data-grid';
import ScrollableCardList, { Item } from '../components/ScrollableCardList';
import {
    Box,
    Container,
    Grid,
    LinearProgress,
    Snackbar,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    DataGridPremium,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridActionsCellItem,
    GridActionsCellItemProps,
    GridRenderCellParams,
    GridRowModel,
    GridRowParams,
    GridRowSelectionModel,
    GridSortItem,
    GridSortModel,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import Leaderboard from 'images/GPSSummaryLeaderboard.png';
import Crop from 'images/CropComparison.png';
import ExploreGPSSummaryProfiles from 'images/ExploreGPSSummaryProfiles.png';
import AthleteProgress from 'images/ExploreAthleteProgress.png';
import Acwr from 'images/ACWLRCover.png';
import {
    createExploration,
    deleteExploration,
    getExplorations,
    updateExploration,
} from '../explore.api';
import {
    Exploration,
    ExplorationFilters,
    ExplorationType,
    ExplorationTypeMap,
} from '../explore.types';
import { useNonInitialEffect } from '../../../hooks';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import { useDebounce } from 'use-debounce';
import { Organization, PaginationLink } from '../../../utils';
import { DateTime } from 'luxon';
import CustomToolBar from '../components/CustomToolBar';
import CustomPagination from '../../../components/Functional/CustomPagination';
import DeleteIcon from '@mui/icons-material/Delete';
import { OpenInNew } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { blue } from '@mui/material/colors';
import EditExplorationModal from '../components/EditExplorationModal';
import EditIcon from '@mui/icons-material/Edit';
import { OrganizationFilterList } from '../../../components/Filters';
import { AbilityContext, Can } from '../../../components/Functional/Can';
import { convertApiFiltersToExplorationFilters } from '../explore.func';

const Explore = () => {
    const apiRef = useGridApiRef();
    const theme = useTheme();
    const [query, setQuery] = useState<
        Record<'limit' | 'page' | 'sort' | 'filter[organization_uuid]', string | number | string[]>
    >({
        limit: 10,
        page: 1,
        sort: ['-updated_at'],
        'filter[organization_uuid]': '',
    });
    const [debouncedQueryOptions] = useDebounce(query, 500);
    const ability = useContext(AbilityContext);
    const [message, setMessage] = useState<string>('');
    const [links, setLinks] = useState<PaginationLink>();
    const [loading, setLoading] = useState<boolean>(false);
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const [numbCards, setNumbCards] = useState(3);
    const [rows, setRows] = useState<Exploration[]>([]);
    const [selectedExploration, setSelectedExploration] = useState<Exploration | null>(null);
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const { organizations } = useContext(OrganizationsContext);
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | false>(false);

    const getRowActions = useCallback(
        (params: GridRowParams) => {
            let actions: React.ReactElement<GridActionsCellItemProps>[] = [];
            if (ability.can('organization:delete-explorations', params.row.organization)) {
                actions.push(
                    <GridActionsCellItem
                        key={'delete'}
                        icon={<DeleteIcon />}
                        onClick={() => {
                            setLoading(true);
                            deleteExploration(params.id as string)
                                .then(() => {
                                    setMessage('Deleted exploration');
                                    setRows((o) => o.filter((row) => row.uuid !== params.id));
                                })
                                .catch(() => {
                                    setMessage('Error deleting exploration');
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }}
                        label="Delete"
                        showInMenu
                    />,
                );
            }
            if (ability.can('organization:update-explorations', params.row.organization)) {
                actions.push(
                    <GridActionsCellItem
                        key={'rename'}
                        icon={<EditIcon />}
                        onClick={() => {
                            setSelectedExploration(params.row as Exploration);
                        }}
                        label="Rename"
                        showInMenu
                    />,
                );
            }

            if (ability.can('organization:view-explorations', params.row.organization)) {
                actions.push(
                    <GridActionsCellItem
                        key={'open-in-new'}
                        icon={<OpenInNew />}
                        onClick={() => {
                            window.open(`/explore/${params.id}`, '_blank');
                        }}
                        label="Open In New Window"
                        showInMenu
                    />,
                );
            }
            return actions;
        },
        [ability],
    );

    useEffect(() => {
        if (query['filter[organization_uuid]'] && organizations.length > 0) {
            const o = organizations.find((o) => o.uuid === query['filter[organization_uuid]']);

            if (o) {
                setSelectedOrganization(o);
            } else {
                setSelectedOrganization(false);
            }
        } else {
            setSelectedOrganization(false);
        }
    }, [organizations, query]);
    const { push } = useHistory();

    const columns: GridColDef[] = useMemo(() => {
        // @ts-ignore
        const cols: GridColDef[] = [
            {
                field: 'organization',
                headerName: 'Organization',
                renderCell: (params: GridRenderCellParams<Organization>) =>
                    params.value?.name ?? '',
                width: 200,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'title',
                headerName: 'Title',
                width: 280,
                editable: true,
                disableColumnMenu: true,
            },
            {
                field: 'type',
                headerName: 'Type',
                disableColumnMenu: true,
                width: 200,
                renderCell: function (params: GridRenderCellParams<any, ExplorationType>) {
                    if (!params.value) {
                        return '-';
                    }
                    if (ExplorationTypeMap.has(params.value)) {
                        return ExplorationTypeMap.get(params.value);
                    }

                    return params.value;
                },
            },
            {
                field: 'owner',
                headerName: 'Owner',
                renderCell: (params: GridRenderCellParams<any>) => params.value?.name ?? '',
                width: 170,
                disableColumnMenu: true,
                sortable: false,
            },
            {
                field: 'updated_at',
                headerName: 'Last Modified',
                width: 200,
                renderCell: (params: GridRenderCellParams<any, number>) =>
                    params.value
                        ? DateTime.fromSeconds(params.value).toLocaleString(DateTime.DATETIME_MED)
                        : '-',
                disableColumnMenu: true,
            },
            {
                field: 'actions',
                type: 'actions',
                width: 50,
                getActions: (params: GridRowParams) => getRowActions(params),
            },
        ];
        if (
            selectedOrganization &&
            ability.can('organization:delete-explorations', selectedOrganization)
        ) {
            cols.push({
                ...GRID_CHECKBOX_SELECTION_COL_DEF,
                width: 50,
                renderHeader: () => <></>,
                disableColumnMenu: true,
            });
        }

        return cols;
    }, [ability, selectedOrganization, getRowActions]);

    const processRowUpdate = (row: GridRowModel) => {
        updateExploration(row.uuid, {
            title: row.title,
        })
            .then(() => {
                setMessage('Renamed exploration');
            })
            .catch(() => {
                setMessage('Error updating exploration. Make sure the field is fiiled out');
            });
        return row;
    };

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setQuery((o) => ({
            ...o,
            sort: sortModel.map((item: GridSortItem) => {
                if (item.sort === 'asc') {
                    return `${item.field}`;
                }
                return `-${item.field}`;
            }),
        }));
    }, []);

    const createNewExploration = async (
        payload: Record<string, string | number | boolean | ExplorationFilters>,
    ) => {
        try {
            const response = await createExploration(payload);

            push(`/explore/${response.data.uuid}`);
        } catch (err: any) {
            if (err.response.data?.errors) {
                setMessage(err.response.data.errors[Object.keys(err.response.data.errors)[0]][0]);
            } else {
                setMessage('Error creating exploration');
            }
        }
    };

    const items: Item[] = [
        {
            image: Crop,
            title: 'Crop Comparison',
            category: 'GPS Raw Data',
            description: `Compare crops to see an athlete's progress over time or to compare one athlete with another on the same graph or on multiple graphs.`,

            onClick: () =>
                createNewExploration({
                    organization_uuid: query['filter[organization_uuid]'] as string,
                    title: 'Untitled Crop Exploration',
                    type: 'crops',
                    filters: convertApiFiltersToExplorationFilters({}),
                }),
        },
        {
            image: Leaderboard,
            title: 'GPS Summary Leaderboard',
            category: 'GPS Summary Data',
            description:
                'Build a leaderboard of the top performing athletes in GPS session. Answer questions like "Who is the fastest athlete on the team in the last month?"',
            onClick: () =>
                createNewExploration({
                    organization_uuid: query['filter[organization_uuid]'] as string,
                    title: 'Untitled Leaderboard Exploration',
                    type: 'gps_summary_leaderboard',
                    filters: convertApiFiltersToExplorationFilters({}),
                }),
        },
        {
            image: ExploreGPSSummaryProfiles,
            title: 'GPS Summary Athlete Profiles',
            category: 'GPS Summary Data',
            imageAlignment: 'top',
            description:
                'Display athlete profiles in a time period for GPS summary data and view them side by side.  Answer questions like "How has an athlete performed in key metrics in the last month?"',
            onClick: () =>
                createNewExploration({
                    organization_uuid: query['filter[organization_uuid]'] as string,
                    title: 'Untitled Athlete Profile',
                    type: 'gps_summary_athlete_profile',
                    filters: convertApiFiltersToExplorationFilters({}),
                }),
        },
        {
            image: Acwr,
            title: 'Acute Chronic Workload Ratio (ACWR)',
            category: 'GPS Summary Data',
            description:
                'Compare athlete Acute Chronic Workload Ratio (ACWR) by day. Answer questions like "Which athletes are at risk for injury?" and "Which athletes are prepared?"',
            onClick: () =>
                createNewExploration({
                    organization_uuid: query['filter[organization_uuid]'] as string,
                    title: 'Untitled ACWR',
                    type: 'gps_summary_acwr',
                    filters: convertApiFiltersToExplorationFilters(
                        {
                            timeGrouping: 'day',
                        },
                        [
                            'GpsSummary.acuteLoad',
                            'GpsSummary.chronicLoad',
                            'GpsSummary.acuteToChronicRatio',
                            'GpsSummary.totalPlayerLoad',
                            'GpsSummary.acuteLoadDistance',
                            'GpsSummary.chronicLoadDistance',
                            'GpsSummary.acuteToChronicRatioDistance',
                            'GpsSummary.totalDistance',
                        ],
                    ),
                }),
        },
        {
            image: AthleteProgress,
            title: 'Athlete Progress Or Exertion Comparison',
            category: 'GPS Summary Data',
            description:
                'Compare athlete progress or exertion by day, week or month for GPS summary data. Answer questions like "How has this athlete improved week by week in the last 3 weeks compared to the rest of the team?"',
            onClick: () =>
                createNewExploration({
                    organization_uuid: query['filter[organization_uuid]'] as string,
                    title: 'Untitled Athlete Progress',
                    type: 'gps_summary_athlete_progress',
                    filters: convertApiFiltersToExplorationFilters({}),
                }),
        },
    ];

    useNonInitialEffect(() => {
        async function startFetching() {
            try {
                setLoading(true);
                const response = await getExplorations(query);
                if (!ignore) {
                    setRows(response.data.data);
                    setLinks(response.data.links);
                    setLoading(false);
                }
            } catch (e: any) {
                setMessage('Error fetching data');
                setLoading(false);
            }
        }

        let ignore = false;
        startFetching();
        return () => {
            ignore = true;
        };
    }, [debouncedQueryOptions]);

    useEffect(() => {
        setNumbCards(sm ? 1 : md ? 2 : lg ? 3 : 4);
    }, [sm, md, lg]);

    return (
        <Container maxWidth={'xl'}>
            <Box sx={{ py: 8, textAlign: 'center' }}>
                <Grid container justifyContent="center">
                    <Grid item>
                        <OrganizationFilterList
                            avatarSize={selectedOrganization ? 'medium' : 175}
                            bottomMargin={10}
                            titleSize={selectedOrganization ? 'h6' : 'h3'}
                            title={
                                selectedOrganization
                                    ? 'Switch Organization to Explore'
                                    : 'Select Organization to Explore'
                            }
                            organizations={organizations}
                            onClicked={(organization) => {
                                setQuery((o) => ({
                                    ...o,
                                    page: 1,
                                    'filter[organization_uuid]': organization.uuid,
                                }));
                            }}
                            selectedOrganization={
                                (query['filter[organization_uuid]'] as string) ?? ''
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
            {query['filter[organization_uuid]'] && (
                <>
                    {selectedOrganization && (
                        <Can I={'organization:create-explorations'} this={selectedOrganization}>
                            <h1>Create A New Exploration By Clicking Below</h1>
                            <ScrollableCardList
                                imageAlignment="top"
                                items={items}
                                cardsToShow={numbCards}
                            />
                        </Can>
                    )}
                    {selectedExploration && (
                        <EditExplorationModal
                            open={true}
                            handleClose={() => {
                                setSelectedExploration(null);
                            }}
                            onSaveError={() => {
                                setMessage('Error while renaming exploration');
                            }}
                            exploration={selectedExploration}
                            onSaved={(exploration) => {
                                setMessage('Successfully renamed exploration');
                                setRows((o) =>
                                    o.map((row) =>
                                        row.uuid === exploration.uuid ? exploration : row,
                                    ),
                                );
                            }}
                        />
                    )}
                    {loading && <LinearProgress />}
                    {selectedOrganization && (
                        <>
                            <h1>View/Edit Existing Explorations by Clicking Below</h1>
                            <DataGridPremium
                                sx={{
                                    '& .MuiDataGrid-row:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: blue[100],
                                    },
                                }}
                                apiRef={apiRef}
                                processRowUpdate={processRowUpdate}
                                initialState={{
                                    sorting: { sortModel: [{ field: 'updated_at', sort: 'desc' }] },
                                }}
                                disableRowSelectionOnClick
                                getRowId={(row) => row['uuid']}
                                sortingMode={'server'}
                                onSortModelChange={handleSortModelChange}
                                autoHeight={true}
                                rows={rows}
                                columns={columns}
                                checkboxSelection={ability.can(
                                    'organization:delete-explorations',
                                    selectedOrganization,
                                )}
                                rowSelectionModel={rowSelectionModel}
                                onRowSelectionModelChange={(newSelection) => {
                                    setRowSelectionModel(newSelection);
                                }}
                                onRowClick={(row) => {
                                    push(`/explore/${row.id}`);
                                }}
                                slots={{
                                    toolbar: CustomToolBar,
                                    footer: CustomPagination,
                                }}
                                slotProps={{
                                    footer: {
                                        hasNext: (links && links.next !== null) ?? false,
                                        hasPrevious: query.page > 1,
                                        onNext: () =>
                                            setQuery((o) => ({ ...o, page: +o.page + 1 })),
                                        onPrevious: () =>
                                            setQuery((o) => ({ ...o, page: +o.page - 1 })),
                                        page: query.page as number,
                                    },
                                    toolbar: {
                                        setRowSelectionModel,
                                        onSearchChange: (v: string) => {
                                            setQuery((o) => ({
                                                ...o,
                                                page: 1,
                                                'filter[title]': v,
                                            }));
                                        },
                                        onError: () => {
                                            setLoading(false);
                                            setMessage('Error deleting exploration');
                                        },
                                        onDeleteFinished: (amount: number) => {
                                            setLoading(false);
                                            setMessage(`${amount} explorations deleted`);
                                            setQuery((o) => ({ ...o, page: 1 }));
                                        },
                                        onDeleteStart: () => {
                                            setLoading(true);
                                        },
                                    },
                                }}
                            />
                        </>
                    )}
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={Boolean(message)}
                        message={message}
                        onClose={() => setMessage('')}
                        autoHideDuration={3000}
                    />
                </>
            )}
        </Container>
    );
};

export default Explore;
