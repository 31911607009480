import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Checkbox, Container, Paper } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AxiosError } from 'axios';
import { defaults } from 'lodash';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CreateSessionModal, CustomButton, ListWithSearchContainer } from '../../../components';
import TableWithCardsSkeleton from '../../../components/Skeletons/TableWithCardsSkeleton';
import { pushMessage } from '../../../redux/reducers/messages';
import { start as startActivity, startForSelf } from '../../../redux/reducers/movementLogs';
import { loadSessions, sessionsSelector } from '../../../redux/reducers/sessions';
import { useAppDispatch } from '../../../redux/store';
import ROUTES from '../../../Routes/routes';
import {
    Group,
    Individual,
    Organization,
    Session,
    somethingWentWrong,
    useToggle,
} from '../../../utils';
import { duplicateSession } from '../api/sessions';
import { SessionCard } from '../components';

interface Props {}

const SessionsList: React.FC<React.PropsWithChildren<Props>> = () => {
    const initialUrlSearchParams = new URLSearchParams(window.location.search);
    const [creatingActivity, setCreatingActivity] = useState(false);
    const { push } = useHistory();
    const [openCreateSessionDiaglogue, toggleOpen] = useToggle(false);
    const dispatch = useAppDispatch();

    const { data, isLoading, isLoaded, error, pagination, paginationMeta } =
        useSelector(sessionsSelector);

    const [query, setQuery] = useState<{
        'filter[title]'?: string;
        page?: number;
        sort?: string;
        'filter[organization]'?: string;
        'filter[is_archived]'?: boolean;
    }>({
        'filter[title]': '',
        page: 1,
        sort: '-created_at',
        'filter[organization]': initialUrlSearchParams.get('filter[organization]') || '',
        'filter[is_archived]': false,
    });
    const [searchValue, setSearchValue] = useState<string>(query['filter[title]'] as string);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [creatingWorkout, setCreatingWorkout] = useState(false);
    const [filters, setFilters] = useState<any>({});

    const handleSearchValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        debounceLoadData({
            'filter[title]': event.target.value,
            sort: '-created_at',
            page: 1,
            'filter[is_archived]': false,
        });
    };

    const debounceLoadData = useCallback(debounce(setQuery, 400), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFilters(
            defaults(query, {
                'filter[title]': '',
                'filter[is_archived]': false,
                sort: '-created_at',
                page: 1,
            }),
        );
    }, [query]);

    useEffect(() => {
        dispatch(
            loadSessions({ ...filters } as {
                'filter[title]': string;
                'filter[is_archived]': boolean;
                sort: string;
                page: number;
            }),
        );
    }, [dispatch, filters]);

    useEffect(() => {
        if (error.message) {
            setShowErrorMessage(true);
        }
    }, [error.message]);

    const createNewSession = (
        created: boolean,
        sessionId?: string,
        sessionName?: string,
        sessionDesc?: string,
    ) => {
        toggleOpen();
        created &&
            push(`/train/sessions/${sessionId}`, {
                sessionId: sessionId,
                sessionName: sessionName,
                sessionDesc: sessionDesc,
            });
    };

    const onSessionViewClicked = (uuid: string) => {
        push(ROUTES.ViewSession.path.replace(':id', uuid));
    };

    const onSessionClicked = (uuid: string) => {
        push(ROUTES.EditSession.path.replace(':id', uuid));
    };

    const onSessionAssign = useCallback(
        ({ sessionId, individualId }: { sessionId: string; individualId: [] }): void => {
            push(
                ROUTES.AssignSession.path
                    .replace(':sessionId', sessionId)
                    .replace(':individualId', individualId.toString()),
            );
        },
        [push],
    );

    const onIndividualClicked = (individual: Individual[], session: Session) => {
        startNewActivity(individual[0].uuid, session);
    };

    const onGroupClicked = (group: Group, session: Session) => {
        setCreatingWorkout(true);
        push(`/train/logging/group-workouts/start-log/${session.uuid}/${group.uuid}`);
    };

    const onLogSelfClicked = async (session: Session, onLogSelfClickedCallBack: () => void) => {
        setCreatingActivity(true);
        const response: any = await dispatch(
            startForSelf({
                local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                session: session.uuid,
            }),
        );
        if (startForSelf.fulfilled.match(response)) {
            push(ROUTES.AddQuickSession.path.replace(':id', response.payload.uuid));
        } else {
            dispatch(
                pushMessage({
                    status: 'error',
                    message: response.payload.message,
                }),
            );
        }
        onLogSelfClickedCallBack();
    };

    const startNewActivity = async (individualId: string, session: Session) => {
        setCreatingActivity(true);
        try {
            const response: any = await dispatch(
                startActivity({
                    local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    individual: individualId,
                    session: session.uuid,
                }),
            );
            push(ROUTES.AddQuickSession.path.replace(':id', response.payload.uuid));
        } catch (err: any) {
            dispatch(
                pushMessage({
                    status: 'error',
                    message: `We had trouble starting a new log.`,
                }),
            );
        }
    };

    const onNextClicked = () => {
        const nextPage = paginationMeta ? paginationMeta.current_page + 1 : 1;
        handlePageChanged(nextPage);
    };

    const onPrevClicked = () => {
        const prevPage = paginationMeta ? paginationMeta.current_page - 1 : 1;
        handlePageChanged(prevPage);
    };

    const handlePageChanged = (newPage: number) => {
        setQuery((query) => ({ ...query, page: newPage }));
    };

    const onSessionDuplicated = (
        session: Session,
        organization: Organization,
        onDuplicationCompetedCallback: () => void,
    ) => {
        duplicateSession(session.uuid, {
            organization: organization.uuid,
        })
            .then(() => {
                dispatch(
                    pushMessage({
                        status: 'success',
                        message: `Session duplication is initiated under ${organization.name} organization. Please refresh this page after few minutes to view the duplicated session.`,
                    }),
                );
            })
            .catch((error: AxiosError<any>) => {
                somethingWentWrong(error.response?.data?.message);
            })
            .finally(() => onDuplicationCompetedCallback());
    };

    return (
        <>
            <ListWithSearchContainer
                buttonText="NEW SESSION"
                onClickButton={toggleOpen}
                searchBarPlaceHolder="Find Session"
                searchValue={searchValue}
                setSearchValue={handleSearchValueChanged}
                showChips={false}
                isError={!!(error.message && showErrorMessage)}
                errorMessage={error.message}
                setOpen={setShowErrorMessage}
                onFilter={(q) => {
                    const sort = q.isFavorite ? '-favorited,-created_at' : '-created_at';
                    setQuery((query) => ({
                        ...query,
                        ...q,
                        sort: sort,
                    }));
                }}
                enabledFilters={{
                    'filter[organization]': query['filter[organization]'] || '',
                    isFavorite: query?.sort?.includes('favorited') || false,
                }}
                hasFavorite={true}
            >
                {isLoading && !isLoaded && <TableWithCardsSkeleton />}

                {!data.length && !isLoading ? (
                    <Container sx={{ textAlign: 'center' }}>No sessions to display</Container>
                ) : (
                    isLoaded && (
                        <React.Fragment>
                            {data.map((session: Session) => (
                                <SessionCard
                                    creatingActivity={creatingActivity}
                                    creatingGroupWorkout={creatingWorkout}
                                    key={session.uuid}
                                    item={session}
                                    onLogSelfClicked={(onLogSelfClickedCallBack) =>
                                        onLogSelfClicked(session, onLogSelfClickedCallBack)
                                    }
                                    onSessionAssign={onSessionAssign}
                                    onIndividualClicked={onIndividualClicked}
                                    onGroupClicked={onGroupClicked}
                                    onSessionViewClicked={() => onSessionViewClicked(session.uuid)}
                                    onSessionClicked={() => onSessionClicked(session.uuid ?? '')}
                                    onSessionDuplicated={onSessionDuplicated}
                                />
                            ))}
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <CustomButton
                                    variant="text"
                                    color="primary"
                                    disabled={!pagination?.prev}
                                    startIcon={<NavigateBeforeIcon />}
                                    onClick={() => onPrevClicked()}
                                >
                                    Previous
                                </CustomButton>
                                <CustomButton
                                    variant="text"
                                    color="primary"
                                    disabled={!pagination?.next}
                                    endIcon={<NavigateNextIcon />}
                                    onClick={() => onNextClicked()}
                                >
                                    Next
                                </CustomButton>
                            </Box>
                        </React.Fragment>
                    )
                )}
                <CreateSessionModal open={openCreateSessionDiaglogue} onClose={createNewSession} />
                <Box display="flex" justifyContent="center" width="100%">
                    <Paper>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Boolean(query['filter[is_archived]'])}
                                    onChange={() => {
                                        setQuery({
                                            ...query,
                                            'filter[is_archived]': query['filter[is_archived]']
                                                ? undefined
                                                : true,
                                        });
                                    }}
                                    name="archived"
                                    color="primary"
                                    sx={{ marginLeft: 8 }}
                                    size={'medium'}
                                />
                            }
                            label="Show Only Archived Sessions"
                        />
                    </Paper>
                </Box>
            </ListWithSearchContainer>
        </>
    );
};
export default SessionsList;
