import { Box } from '@mui/material';
import 'reactflow/dist/style.css';
import React, { useContext } from 'react';
import { Route, useHistory, useParams } from 'react-router-dom';
import { OrganizationFilterList } from '../../../components/Filters';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import OrganizationPartnerDetailsOverviewScene from './OrganizationPartnerDetailsOverviewScene';
import { Organization } from '../../../utils';
import { NetworkingProvider } from '../state/NetworkingContext';

const NetworkingHomeScene = () => {
    const { organizations } = useContext(OrganizationsContext);
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const onOrganizationClicked = (organization: Organization) => {
        history.push(`/networking/${organization.uuid}`);
    };

    return (
        <NetworkingProvider id={id}>
            <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 8,
                        backgroundColor: 'white',
                    }}
                >
                    <OrganizationFilterList
                        title={''}
                        organizations={organizations.filter((organization) => organization)}
                        onClicked={onOrganizationClicked}
                        selectedOrganization={id}
                    />
                </Box>
                <Route path={`/networking/:id`}>
                    <OrganizationPartnerDetailsOverviewScene />
                </Route>
            </Box>
        </NetworkingProvider>
    );
};

export default NetworkingHomeScene;
