import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { millisecondsToDurationString } from 'app.functions';
import React from 'react';
import { Set } from '../plan.types';

interface Props {
    sets: Set[];
}

const ActivityTable = ({ sets }: Props) => {
    const setHasCol = (value: any): boolean => {
        return value !== null;
    };
    return (
        <>
            {sets.map((set, index) => (
                <React.Fragment key={index}>
                    <Typography variant="h6">{set.movement.name ?? 'Unknown Exercise'}</Typography>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow style={{ background: '#003E9D14' }}>
                                    <TableCell>Set</TableCell>
                                    {setHasCol(set.logs[0].reps) && (
                                        <TableCell align="right">Reps</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].load_value) && (
                                        <TableCell align="right">Load</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].time_value) && (
                                        <TableCell align="right">Time</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].distance_value) && (
                                        <TableCell align="right">Dist</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].speed_value) && (
                                        <TableCell align="right">Speed</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].acceleration_value) && (
                                        <TableCell align="right">Speed</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].power_value) && (
                                        <TableCell align="right">Power</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].range_of_motion_value) && (
                                        <TableCell align="right">ROM</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].band_value) && (
                                        <TableCell align="right">Band</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].rpe_value) && (
                                        <TableCell align="right">RPE</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].body_side) && (
                                        <TableCell align="right">Body Side</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].rpm_value) && (
                                        <TableCell align="right">RPM</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].gps_player_load_value) && (
                                        <TableCell align="right">GPSPlayerLoad</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].gps_accel_count_value) && (
                                        <TableCell align="right">GPSAccelCount</TableCell>
                                    )}
                                    {setHasCol(set.logs[0].gps_decel_count_value) && (
                                        <TableCell align="right">GPSDecelCount</TableCell>
                                    )}
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {set.logs.map((log: any, index: number) => (
                                    <TableRow key={log.uuid} style={{ background: 'white' }}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        {setHasCol(set.logs[0].reps) && (
                                            <TableCell align="right">{log.reps}</TableCell>
                                        )}
                                        {setHasCol(set.logs[0].load_value) && (
                                            <TableCell align="right">
                                                {log.load_value}
                                                {log.load_unit}
                                            </TableCell>
                                        )}
                                        {setHasCol(set.logs[0].time_value) && (
                                            <TableCell align="right">
                                                {millisecondsToDurationString(
                                                    log.time_value,
                                                    log.time_display_format,
                                                )}
                                            </TableCell>
                                        )}
                                        {setHasCol(set.logs[0].distance_value) && (
                                            <TableCell align="right">
                                                {log.distance_value}
                                                {log.distance_unit}
                                            </TableCell>
                                        )}
                                        {setHasCol(set.logs[0].speed_value) && (
                                            <TableCell align="right">
                                                {log.speed_value}
                                                {log.speed_unit}
                                            </TableCell>
                                        )}
                                        {setHasCol(set.logs[0].acceleration_value) && (
                                            <TableCell align="right">
                                                {log.acceleration_value}
                                                {log.acceleration_unit}
                                            </TableCell>
                                        )}
                                        {setHasCol(set.logs[0].power_value) && (
                                            <TableCell align="right">
                                                {log.power_value}
                                                {log.power_unit}
                                            </TableCell>
                                        )}
                                        {setHasCol(set.logs[0].range_of_motion_value) && (
                                            <TableCell align="right">
                                                {log.range_of_motion_value}
                                            </TableCell>
                                        )}
                                        {setHasCol(set.logs[0].rpe_value) && (
                                            <TableCell align="right">{log.rpe_value}</TableCell>
                                        )}
                                        {setHasCol(set.logs[0].band_value) && (
                                            <TableCell align="right">{log.band_value}</TableCell>
                                        )}
                                        {setHasCol(set.logs[0].body_side) && (
                                            <TableCell align="right">{log.body_side}</TableCell>
                                        )}
                                        <TableCell align="right">
                                            {log.markedAsComplete && (
                                                <CheckCircleIcon style={{ color: green[500] }} />
                                            )}
                                            {!log.markedAsComplete && <CheckCircleOutlineIcon />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </React.Fragment>
            ))}
        </>
    );
};
export default ActivityTable;
