import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import { Order } from '../networking.types';
import { Divider } from '@mui/material';

interface OrderDialogProps {
    open: boolean;
    order: Order | null;
    onClose: () => void;
}

const OrderDialog: React.FC<OrderDialogProps> = ({ open, order, onClose }) => {
    const formatCurrency = (amount: number): string => {
        amount = amount / 100;
        return `$${amount.toFixed(2)}`;
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    View Order
                    {order && (
                        <>
                            <Grid container direction="row" alignItems="left" spacing={1}>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Total <strong>{formatCurrency(order.total)}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Items{' '}
                                        <strong>
                                            {formatCurrency(order.commissionable_total)}
                                        </strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Tax <strong>{formatCurrency(order.tax)}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Shipping <strong>{formatCurrency(order.shipping)}</strong>
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent="flex-end">
                                    <Grid item style={{ marginRight: '10px' }}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: '0.8rem', textAlign: 'right' }}
                                        >
                                            Received{' '}
                                            <strong>{order.created_at.toLocaleString()}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ marginRight: '10px' }}>
                                        <Typography variant="body2" style={{ fontSize: '0.8rem' }}>
                                            <span>
                                                Buyer <strong>{order?.buyer_org_name}</strong>
                                            </span>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" style={{ fontSize: '0.8rem' }}>
                                            <span>
                                                Status <strong>{order?.order_status}</strong>
                                            </span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {order && (
                        <>
                            <div>
                                <h4>Ship To:</h4>
                                <div
                                    style={{
                                        fontSize: '0.8rem',
                                    }}
                                >
                                    <span>
                                        {order.buyer_org_name ??
                                            `${order.buyer_athlete?.first_name} ${order.buyer_athlete?.last_name}`}
                                    </span>
                                </div>
                                <div
                                    style={{
                                        fontSize: '0.8rem',
                                    }}
                                >
                                    <span>
                                        {order.shipping_address_1}{' '}
                                        {order.shipping_address_2
                                            ? `${order.shipping_address_2}, `
                                            : ''}
                                    </span>
                                </div>
                                <div
                                    style={{
                                        fontSize: '0.8rem',
                                    }}
                                >
                                    <span>
                                        {order.shipping_city}, {order.shipping_state}{' '}
                                        {order.shipping_zip}
                                    </span>
                                </div>
                            </div>

                            <h4>Line Items</h4>
                            <Divider style={{ marginTop: '20px', backgroundColor: 'lightgray' }} />
                            {order.line_items.map((item, index) => (
                                <React.Fragment key={item.uuid}>
                                    <Grid
                                        container
                                        spacing={2}
                                        style={{ marginTop: '10px', marginLeft: '20px' }}
                                    >
                                        <Grid item xs={12} style={{ position: 'relative' }}>
                                            <Typography
                                                variant="body2"
                                                style={{
                                                    fontSize: '0.8rem',
                                                    color: '#111111',
                                                    position: 'absolute',
                                                    left: '-20px',
                                                    top: '0',
                                                }}
                                            >
                                                {index + 1}.
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                style={{ fontSize: '0.8rem', color: '#111111' }}
                                            >
                                                <strong>Product:</strong> {item.description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                style={{ fontSize: '0.8rem', color: '#111111' }}
                                            >
                                                <strong>Unit Amount:</strong>{' '}
                                                {formatCurrency(item.unit_amount)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                style={{ fontSize: '0.8rem', color: '#111111' }}
                                            >
                                                <strong>Quantity:</strong> {item.quantity}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="body2"
                                                style={{ fontSize: '0.8rem', color: '#111111' }}
                                            >
                                                <strong>Subtotal:</strong>{' '}
                                                {formatCurrency(item.amount_subtotal)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider
                                        style={{
                                            marginTop: '20px',
                                            backgroundColor: 'lightgray',
                                            marginBottom: '24px',
                                        }}
                                    />
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="contained" onClick={onClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OrderDialog;
