import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { DataGridPremium, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { GetOrdersParams, getOrders, getOrderStatuses } from '../networking.api';
import { Order } from '../networking.types';
import { GridColDef } from '@mui/x-data-grid-premium';
import OrderDialog from '../components/OrderDialog';
import { Organization } from 'utils';
import { getOrderOrganizations } from '../networking.api';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';

const ProductOrdersListScene = () => {
    const { id } = useParams<{ id: string }>();
    const [sellerOrganizations, setSellerOrganizations] = useState<Organization[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [searchParams, setSearchParams] = useState<GetOrdersParams>({
        order_status: 'processing',
        level: 0,
        seller_org_uuid: '',
        sort: '-created_at',
        page: 1,
        per_page: 10,
    });

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [orderStatuses, setOrderStatuses] = useState<string[]>([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [selectedSellerOrg, setSelectedSellerOrg] = useState<Organization | null>(null);

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        setSearchParams({
            ...searchParams,
            page: newModel.page + 1, // API usually expects 1-indexed pages
            per_page: newModel.pageSize,
        });
        getOrders(id, false, {
            ...searchParams,
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        })
            .then((response) => {
                setOrders(response.data.data);
                setTotalOrders(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch orders', error);
            });
    };

    const handleOpenDialog = (order: Order) => {
        setSelectedOrder(order);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const fetchSellerOrganizations = debounce((id: string, name: string | null, level: number) => {
        // Replace this with your actual API call
        getOrderOrganizations(id, false, name, level)
            .then((response) => {
                setSellerOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch buyer organizations', error);
            });
    }, 300);

    useEffect(() => {
        fetchSellerOrganizations(id, null, 0);

        const fetchOrderStatuses = async () => {
            const response = await getOrderStatuses(id);
            setOrderStatuses(response.data);
        };
        fetchOrderStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getOrders(id, false, { order_status: 'processing', sort: '-created_at' })
            .then((response) => {
                setOrders(response.data.data);
                setTotalOrders(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch orders', error);
            });
    }, [id]);

    const formatCentsToDollars = (cents: number): string => {
        const dollars = cents / 100;
        return `$${dollars.toFixed(2)}`;
    };

    interface LineItem {
        description: string;
        quantity: number;
    }

    function formatLineItemsDescription(lineItems: LineItem[]): string {
        const maxDescriptionLength = 50; // Adjust as needed
        let description = lineItems
            .map((item) => `${item.description} [${item.quantity}]`)
            .join(', ');

        // Truncate and append ellipsis if overflows
        if (description.length > maxDescriptionLength) {
            description = `${description.substring(0, maxDescriptionLength - 3)}...`;
        }

        return description;
    }

    const columns: GridColDef<Order, any, any>[] = [
        {
            field: 'seller_org_name',
            headerName: 'Seller',
            width: 150,
            sortable: false,
            type: 'string',
        },
        {
            field: 'buyerName',
            headerName: 'Buyer',
            width: 150,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                return (
                    params.row.buyer_org_name ??
                    `${params.row.buyer_athlete?.first_name} ${params.row.buyer_athlete?.last_name}`
                );
            },
        },
        {
            field: 'salesDescription',
            headerName: 'Order',
            width: 300,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                // Assuming each order has a lineItems property
                const description = formatLineItemsDescription(params.row.line_items);
                return <span>{description}</span>;
            },
        },
        {
            field: 'commissionable_total',
            headerName: 'Product Total',
            width: 100,
            sortable: false,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'tax',
            headerName: 'Tax',
            width: 100,
            sortable: true,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'shipping',
            headerName: 'Shipping',
            width: 100,
            sortable: false,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 100,
            sortable: true,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'shipping_tracking_number',
            headerName: 'Tracking Number',
            width: 150,
            sortable: false,
            type: 'string',
        },
        {
            field: 'order_status',
            headerName: 'Order Status',
            width: 150,
            sortable: true,
            type: 'string',
        },
        {
            field: 'created_at',
            headerName: 'Received',
            sortable: true,
            width: 100,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            renderCell: (params) => (
                <Button onClick={() => handleOpenDialog(params.row)} variant="contained">
                    View
                </Button>
            ),
        },
    ];

    const handleOrderSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await getOrderSearchResults(searchParams);
        } catch (error) {
            console.error(error);
        }
    };

    const getOrderSearchResults = async (params: any) => {
        const response = await getOrders(id, false, params);
        if (Array.isArray(response.data.data)) {
            const updatedOrders = response.data.data.map((order: Order) => {
                return {
                    ...order,
                };
            });
            setOrders(updatedOrders);
            setTotalOrders(response.data.meta.total);
        } else {
            console.error('Unexpected data format: pods data is not an array');
        }
    };

    const handleOrderSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        /**
         * Sort will be undefined, or it will be ascending, ie "createdAt", or in this case
         * it will be descending, ie "-createdAt". If descending, we prepend a hyphen.
         */
        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        // Update your API request with the new sort model and fetch the data again
        const params = {
            ...searchParams,
            sort: sortField as GetOrdersParams['sort'],
        };

        await getOrderSearchResults(params);
    };

    // @ts-ignore
    return (
        <Box style={{ marginLeft: '2rem' }}>
            <h2>Product Orders</h2>
            <section style={{ marginBottom: '2rem' }}>
                <h4>Search Orders by Downline Level or Organization</h4>
                <h5>
                    You can view orders you made directly to any seller (which you can filter by
                    seller organization), or you can view orders made by you and your downline
                    sellers filtered by the level of the seller.
                </h5>
                <form onSubmit={handleOrderSearch} noValidate>
                    <FormControl
                        size="small"
                        sx={{ width: '240px', display: 'inline-flex', marginRight: '2px' }}
                    >
                        <InputLabel id="order-status-label">Seller Downline Level</InputLabel>
                        <Select
                            label="Seller Downline Level"
                            value={searchParams.level}
                            onChange={(e) => {
                                const newLevel = e.target.value as number;
                                setSearchParams((prev) => ({
                                    ...prev,
                                    level: newLevel,
                                    seller_org_uuid: undefined,
                                }));
                                setSelectedSellerOrg(null);
                                fetchSellerOrganizations(id, null, newLevel);
                            }}
                            size="small"
                            sx={{ width: '240px', display: 'inline-flex', marginRight: '2px' }}
                        >
                            <MenuItem value={0}>My orders from any seller</MenuItem>
                            <MenuItem value={1}>Orders to level 1 sellers</MenuItem>
                            <MenuItem value={2}>Orders to level 2 sellers</MenuItem>
                            <MenuItem value={3}>Orders to level 3 sellers</MenuItem>
                        </Select>
                    </FormControl>
                    <Autocomplete
                        value={selectedSellerOrg}
                        onChange={(event, newValue) => {
                            setSelectedSellerOrg(newValue);
                            setSearchParams((prev) => ({
                                ...prev,
                                seller_org_uuid: newValue ? newValue.uuid : '',
                            }));
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason !== 'reset') {
                                fetchSellerOrganizations(
                                    id,
                                    newInputValue,
                                    searchParams.level ?? 0,
                                );
                            }
                        }}
                        getOptionKey={(option) => option.uuid}
                        filterOptions={(options) => (Array.isArray(options) ? options : [])}
                        size={'small'}
                        sx={{
                            width: '240px',
                            display: 'inline-flex',
                            marginRight: '2px',
                            '&.Mui-disabled': {
                                cursor: 'not-allowed',
                                pointerEvents: 'all',
                            },
                            '& .MuiInputBase-root.Mui-disabled': {
                                cursor: 'not-allowed',
                            },
                        }}
                        options={Array.isArray(sellerOrganizations) ? sellerOrganizations : []}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Seller Organization" />
                        )}
                    />
                    <FormControl
                        size="small"
                        sx={{ width: '240px', display: 'inline-flex', marginRight: '2px' }}
                    >
                        <InputLabel id="order-status-label">Order Status</InputLabel>
                        <Select
                            label="Order Status"
                            value={
                                searchParams.order_status === '' ? 'all' : searchParams.order_status
                            }
                            onChange={(e) => {
                                setSearchParams({
                                    ...searchParams,
                                    order_status: e.target.value === 'all' ? '' : e.target.value,
                                });
                            }}
                            size="small"
                            sx={{ width: '240px', display: 'inline-flex', marginRight: '2px' }}
                        >
                            <MenuItem value="all">all</MenuItem>
                            {orderStatuses.map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button type="submit" size={'medium'} variant="contained">
                        Search Orders
                    </Button>
                </form>
            </section>
            <Box width="98%" maxHeight="600px" overflow="auto">
                <DataGridPremium
                    rows={orders}
                    columns={columns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model: GridSortModel) => handleOrderSort(model)}
                    getRowId={(row) => row.uuid}
                    style={{ height: '624px', marginBottom: '50px' }}
                    pagination
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    rowCount={totalOrders}
                    paginationMode="server"
                    disableColumnMenu={true}
                />
            </Box>
            <OrderDialog open={isDialogOpen} order={selectedOrder} onClose={handleCloseDialog} />
        </Box>
    );
};

export default ProductOrdersListScene;
