import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FullScreenDialogCard from '../components/HowToDialogCard';
import Admin1080MotionDatabaseView from '../media/Admin1080MotionDatabaseView.png';
import AdminGPSSummaryDatabaseView from '../media/Admin1080MotionDatabaseView.png';
import AdminSprintDatabaseView from '../media/AdminSprintDatabaseView.png';
import AdminVideoAnalysisDatabaseView from '../media/AdminVideoAnalysisDatabaseView.png';
import AdminTrainDatabaseView from '../media/AdminTrainDatabaseView.png';
import AdminTimeSegmentsDatabaseView from '../media/AdminTimeSegmentsDatabaseView.png';

function AdminDatabaseViews() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL provides 6 main database views that represent the highest level of data
                        management. From these database views you have the most options to filter
                        data per group, athlete, and other specifics. From here you can drill down
                        to the specifics under the view link.{' '}
                        <b>
                            Database views include: Train Data, Sprint Data, Video Analysis, 1080
                            Motion, Time Segments, and GPS Summary.
                        </b>{' '}
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={AdminTrainDatabaseView}
                    title="Training Database"
                    headline="Training Database"
                    description="Training database includes all logs from sessions and movements in the Train section of 1TUL."
                />
                <FullScreenDialogCard
                    image={AdminSprintDatabaseView}
                    title="Sprint Database"
                    headline="Sprint Database"
                    description="Sprint database includes all sprints from raw gps imports and 1080 motion."
                />
                <FullScreenDialogCard
                    image={AdminVideoAnalysisDatabaseView}
                    title="Video Analysis Database"
                    headline="Video Analysis Database"
                    description="Video Analsis database includes all data generated from video analysis section of 1TUL."
                />
                <FullScreenDialogCard
                    image={Admin1080MotionDatabaseView}
                    title="1080 Motin Database"
                    headline="1080 Motion Database"
                    description="1080 Motion database includes all data generated from 1080 Motin API."
                />
                <FullScreenDialogCard
                    image={AdminTimeSegmentsDatabaseView}
                    title="Time Segments Database"
                    headline="Time Segments Database"
                    description="Time Segments database is generated from Sprint Database and automatically segments each sprint into 1 second and 2 second time segments for more in-depth analysis on acceleration qualities."
                />
                <FullScreenDialogCard
                    image={AdminGPSSummaryDatabaseView}
                    title="GPS Summary Database"
                    headline="GPS Summary Database"
                    description="GPS Summary database includes data from GPS Summary imports."
                />
            </Grid>
        </>
    );
}

export default AdminDatabaseViews;
