import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Organization } from 'utils';

type ReturnType = Promise<AxiosResponse<Organization>>;

export interface UpdateOrganizationPayload {
    name?: string;
    logo_path?: string;
    zip?: string;
}

const updateOrganization = (uuid: string, payload: UpdateOrganizationPayload = {}): ReturnType => {
    return Request.put(`/organizations/${uuid}`, payload);
};

export default updateOrganization;
