import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import GroupsIcon from '@mui/icons-material/Groups';

interface Props {
    open: boolean;
    onClose: () => void;
    onPost: () => void;
    loading: boolean;
}

function AskToPostToSocialDialog({ open, onClose }: Props) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <GroupsIcon sx={{ marginRight: 8 }} />
                {'Progress Through Collaboration'}
                <InsightsIcon sx={{ marginLeft: 8 }} />
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    <p>
                        All completed logs are automatically shared with your
                        <i> private Athlete Support Team</i>.
                        <p>
                            You can view and update your Athlete Support Team and your unreviewed
                            logs in Plan/Timeline/Notifications to Review
                        </p>
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} color="primary">
                    I Understand
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AskToPostToSocialDialog;
