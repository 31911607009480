import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    ListItem,
    ListItemAvatar,
    Stack,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import DimensionFilterBuilder from '../../home/components/DimensionFilterBuilder';
import { batchGroupsForExploration, batchIndividualsForExploration } from '../explore.api';
import {
    groupDataGetter,
    groupDataMapper,
    individualDataGetter,
    individualDataMapper,
} from '../explore.func';
import List from '@mui/material/List';
import { getInitials, SharedUser } from '../../../utils';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useState } from 'react';
import { Exploration } from '../explore.types';
import { getSharedUsersOnOrganization } from '../../../api';
import { debounce } from 'lodash';

const ExplorationSharingPanel = ({ exploration }: { exploration: Exploration }) => {
    const [selectedSharingTab, setSelectedSharingTab] = useState(0);
    const [organizationUsers, setOrganizationUsers] = useState<SharedUser[]>([]);

    useEffect(() => {
        async function fetchOrganizationUsers() {
            if (!exploration?.organization.uuid) {
                return;
            }
            try {
                const response = await getSharedUsersOnOrganization(exploration?.organization.uuid);
                setOrganizationUsers(response.data);
            } catch (e: any) {
                console.log(e);
            }
        }

        fetchOrganizationUsers();
    }, [exploration?.organization.uuid]);
    return (
        <Card
            sx={{
                width: '100%',
                marginTop: 6,
            }}
        >
            <CardHeader
                title={'Sharing'}
                subheader={'Share this exploration with other people'}
            ></CardHeader>
            <CardContent>
                <Tabs
                    value={selectedSharingTab}
                    onChange={(_, v) => {
                        setSelectedSharingTab(v);
                    }}
                >
                    <Tab label={'Groups & Athletes'} />
                    <Tab label={'Collaborators'} />
                </Tabs>
                {selectedSharingTab === 0 && (
                    <Stack
                        sx={{
                            p: 8,
                        }}
                        divider={<Divider orientation={'horizontal'} />}
                        direction={'column'}
                        spacing={6}
                    >
                        <Typography>
                            Selected groups and athletes will be able to view the exploration (but
                            not edit it).
                        </Typography>
                        <DimensionFilterBuilder
                            initialState={exploration.groups.reduce((acc: any, uuid) => {
                                acc[uuid] = true;
                                return acc;
                            }, {})}
                            onChange={debounce((v) => {
                                batchGroupsForExploration(exploration.uuid, {
                                    groups: v as string[],
                                })
                                    .then(() => {})
                                    .catch(() => {});
                            }, 1000)}
                            dataGetter={(page, limit, payload) =>
                                groupDataGetter(page, limit, {
                                    ...payload,
                                    'filter[name]': payload['filter[query]'] ?? '',
                                    'filter[organization]': exploration?.organization.uuid,
                                })
                            }
                            dataMapper={groupDataMapper}
                            buttonText={'Click To Select Groups'}
                            id={'search-groups'}
                        />
                        <DimensionFilterBuilder
                            initialState={exploration.individuals.reduce((acc: any, uuid) => {
                                acc[uuid] = true;
                                return acc;
                            }, {})}
                            onChange={debounce((v) => {
                                batchIndividualsForExploration(exploration.uuid, {
                                    individuals: v as string[],
                                })
                                    .then(() => {})
                                    .catch(() => {});
                            }, 1000)}
                            dataGetter={(page, limit, payload) =>
                                individualDataGetter(page, limit, {
                                    ...payload,
                                    'filter[name]': payload['filter[query]'] ?? '',
                                    'filter[organization]': exploration?.organization.uuid,
                                })
                            }
                            dataMapper={individualDataMapper}
                            buttonText={'Click To Select Athletes'}
                            id={'search-individuals'}
                        />
                    </Stack>
                )}
                {selectedSharingTab === 1 && (
                    <Box
                        sx={{
                            p: 8,
                        }}
                    >
                        <Typography>
                            Collaborators are automatically assigned based on your
                            organization&apos;s users. To update these, go to your organization
                            details and click on the sharing icon.
                        </Typography>
                        <List>
                            {organizationUsers.map((u) => (
                                <ListItem key={u.uuid}>
                                    <ListItemAvatar>
                                        <Avatar
                                            src={u.avatar_url ?? ''}
                                            alt={getInitials(u.display_name)}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={u.name}
                                        secondary={u.role.display_name}
                                    ></ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default ExplorationSharingPanel;
