import React, { ReactElement } from 'react';
import { VideoClipPlayer } from '../../../components';
import { ButtonBase } from '@mui/material';

interface Props {
    mediaUrl: string | null | undefined;
    mediaType: 'image' | 'video' | null | undefined;
    onClick: () => void;
    alt?: string;
}

function VideoClipOrImage(props: Props): ReactElement {
    return (
        <>
            {props.mediaUrl && (
                <ButtonBase onClick={() => props.onClick()}>
                    {props.mediaType === 'video' && (
                        <VideoClipPlayer
                            mp4={props.mediaUrl}
                            poster={''}
                            webm={''}
                            classesSx={{
                                borderRadius: 4,
                                width: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    )}
                    {props.mediaType === 'image' && (
                        <img
                            style={{ borderRadius: 4, width: '100%', objectFit: 'cover' }}
                            alt={props.alt ?? ''}
                            src={props.mediaUrl}
                        />
                    )}
                </ButtonBase>
            )}
        </>
    );
}

export default VideoClipOrImage;
