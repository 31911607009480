import { Avatar, Box, Paper, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

interface Event {
    id: string;
    starts_at: number;
    ends_at: number | null;
    title: string;
}

interface Props {
    title: string;
    events: Array<Event>;
    assigneePhotoUrl: string | null;
}

const EventListCard = ({ title, events, assigneePhotoUrl }: Props) => {
    return (
        <Paper elevation={6} style={{ width: '100%', padding: 16 }}>
            {title && (
                <Box display={'flex'} alignItems={'center'}>
                    <Avatar src={assigneePhotoUrl ?? ''} />
                    <Typography variant={'body1'} style={{ marginLeft: 8 }}>
                        {title}
                    </Typography>
                </Box>
            )}
            {events.length === 0 && (
                <Box mt={16}>
                    <b>Nothing scheduled</b>
                </Box>
            )}
            {events.length > 0 && (
                <Box
                    component="ul"
                    sx={{
                        listStyle: 'none',
                        fontWeight: 'bold',
                        '& li::before': {
                            content: '"•"',
                            color: 'primary.light',
                            fontWeight: 'bold',
                            display: 'inline-block',
                            width: '1em',
                        },
                        '& li': {
                            margin: '10px 0',
                        },
                    }}
                >
                    {events.map((e) => (
                        <li key={e.id}>{`${moment(e.starts_at * 1000).format('MMM D')} - ${
                            e.title
                        } @ ${
                            e.ends_at ? moment(e.ends_at * 1000).format('H:ma') : 'Any Time'
                        }`}</li>
                    ))}
                </Box>
            )}
        </Paper>
    );
};

export default EventListCard;
