import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

interface LeavePagePromptProps {
    when: boolean;
}

// React router dom gives us a component called prompt using which
// you can give user a prompt if they are sure to navigate away
// However, it uses alert prompt. If user has pop-ups disabled on the browser,
// the confirmation won't pop up
// So we are creating a custom component which uses material UI modal
// Example reference : https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39

const LeavePagePrompt = ({ when }: LeavePagePromptProps) => {
    const { push } = useHistory();

    const [open, setOpen] = useState<boolean>(false);
    const [nextLocation, setNextLocation] = useState<Location | undefined>();
    const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);

    const handlePrompt = (location: Location): boolean => {
        if (!confirmedNavigation) {
            setOpen(true);
            setNextLocation(location);
            return false;
        }

        return true;
    };

    useEffect(() => {
        if (confirmedNavigation && nextLocation) {
            push(nextLocation);
        }
    }, [confirmedNavigation, nextLocation, push]);

    return (
        <>
            <Prompt when={when} message={handlePrompt} />

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are trying to navigate away from this page. Press Continue to navigate
                        away or Cancel to stay on the current page.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            setOpen(false);
                            setConfirmedNavigation(true);
                        }}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LeavePagePrompt;
