import { Box, Chip, Fab, Hidden, Input } from '@mui/material';
import { useToggle } from 'utils';
import React, { ChangeEventHandler, FC, ReactNode } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import SnackBar from 'components/Indicators/SnackBar';
import useTheme from '@mui/material/styles/useTheme';

interface LayoutWithSearchProps {
    children: ReactNode;
    isError: boolean;

    showChips?: boolean;
    errorMessage: string;
    searchValue: string;

    setOpen: (newVal: boolean) => void;
    onClickButton: () => void;
    setSearchValue: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    fabText?: string;
}

const LayoutWithSearch: FC<React.PropsWithChildren<LayoutWithSearchProps>> = ({
    children,
    isError,

    showChips = false,
    errorMessage,
    searchValue,

    setOpen,
    onClickButton,
    fabText,

    setSearchValue,
}) => {
    const theme = useTheme();
    const [newFilter, toggleNewFilter] = useToggle(false);
    const [defaultFilter, toggleDefaultFilter] = useToggle(false);

    return (
        <Box
            sx={{
                color: theme.palette.text.secondary,
                background: theme.palette.backgroundColor.action,
                maxWidth: '100vw',
            }}
            flex="1"
        >
            <Box
                sx={{ maxWidth: '90%', margin: '0 auto' }}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}
            >
                <Box
                    sx={{
                        background: theme.palette.backgroundColor.action,
                        padding: theme.spacing(12.5, 10, 2.5, 9.5),
                        maxWidth: 810,
                        width: '100%',
                        [theme.breakpoints.up('md')]: {
                            padding: theme.spacing(8, 0, 8, 0),
                        },
                    }}
                >
                    <Hidden mdDown>
                        {showChips && (
                            <Box sx={{ display: 'flex' }}>
                                <Chip
                                    sx={{
                                        margin: (theme) => theme.spacing(0, 1),
                                        color: newFilter
                                            ? theme.palette.background.paper
                                            : theme.palette.text.secondary,
                                    }}
                                    color={newFilter ? 'primary' : 'default'}
                                    variant={newFilter ? 'filled' : 'outlined'}
                                    label="New"
                                    clickable
                                    onClick={toggleNewFilter}
                                />
                                <Chip
                                    sx={{
                                        margin: (theme) => theme.spacing(0, 1),
                                        color: defaultFilter
                                            ? theme.palette.background.paper
                                            : theme.palette.text.secondary,
                                    }}
                                    color={defaultFilter ? 'primary' : 'default'}
                                    variant={defaultFilter ? 'filled' : 'outlined'}
                                    label="Drafts"
                                    clickable
                                    onClick={toggleDefaultFilter}
                                />
                            </Box>
                        )}
                    </Hidden>

                    <Input
                        onChange={setSearchValue}
                        value={searchValue ?? ''}
                        sx={{
                            background: theme.palette.background.paper,
                            borderRadius: 4,
                            color: theme.palette.text.secondary,
                            fontSize: 14,
                            height: 56,
                            padding: theme.spacing(9, 8),
                            width: '100%',
                            maxWidth: 800,
                            '& .MuiInput-input': {
                                padding: 0,
                            },
                            '&&&:before': {
                                borderBottom: 'none',
                            },
                            '&&:after': {
                                borderBottom: 'none',
                            },
                            '&::placeholder': {
                                color: theme.palette.primary.main,
                            },
                            [theme.breakpoints.up('md')]: {
                                width: '100%',
                            },
                        }}
                        endAdornment={<SearchIcon />}
                        placeholder="Search for an assessment"
                    />
                </Box>
                <Box
                    width="100%"
                    sx={{
                        overflowY: 'auto',
                        padding: theme.spacing(8, 8, 8),
                        [theme.breakpoints.up('md')]: {
                            paddingTop: theme.spacing(8),
                        },
                    }}
                >
                    {children}
                </Box>
                <Fab
                    variant="extended"
                    size="medium"
                    aria-label="add"
                    color="primary"
                    onClick={onClickButton}
                    onTouchStart={onClickButton}
                    sx={{ marginTop: 10, zIndex: 0 }}
                >
                    <AddIcon />
                    {fabText}
                </Fab>
                <SnackBar
                    open={isError}
                    message={errorMessage}
                    severity="error"
                    setOpen={setOpen}
                />
            </Box>
        </Box>
    );
};

export default LayoutWithSearch;
