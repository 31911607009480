import React from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Typography,
    useTheme,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import Button from '@mui/material/Button';

interface Organization {
    uuid: string;
    name: string;
}

interface GlobalMarketplaceCardProps {
    title: string;
    body: string;
    image: string;
    buttonText: string;
    organizations: Organization[];
    selectedOrganization: string;
    onOrganizationChange: (event: SelectChangeEvent) => void;
    onButtonClick: () => void;
    disabled?: boolean;
}

const GlobalMarketplaceCard: React.FC<GlobalMarketplaceCardProps> = ({
    title,
    body,
    image,
    buttonText,
    organizations,
    selectedOrganization,
    onOrganizationChange,
    onButtonClick,
    disabled = false,
}) => {
    const theme = useTheme();

    return (
        <Card
            sx={{
                width: 300,
                height: 340,
                borderRadius: 3,
                background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                color: theme.palette.common.white,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                },
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '15%',
                    left: '15%',
                    right: '15%',
                    bottom: '15%',
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.4,
                }}
            />
            <CardContent sx={{ flexGrow: 1, position: 'relative', zIndex: 1, pt: 2 }}>
                <Typography
                    textAlign="center"
                    component="div"
                    sx={{
                        fontSize: '1.2rem',
                        fontWeight: 600,
                        textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                        marginTop: '12px',
                    }}
                    gutterBottom
                >
                    {title}
                </Typography>
                <Typography
                    sx={{
                        color: 'FFF',
                        padding: 2,
                        fontSize: '0.8rem',
                        fontWeight: 500,
                        textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                    }}
                    variant="body2"
                >
                    {body}
                </Typography>
            </CardContent>

            <CardActions sx={{ flexDirection: 'column', alignItems: 'center', padding: 4 }}>
                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                    <InputLabel sx={{ color: 'white' }}>Select an Organization</InputLabel>
                    <Select
                        value={selectedOrganization}
                        onChange={onOrganizationChange}
                        label="Select an Organization"
                        sx={{
                            color: 'white',
                            '.MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                            marginBottom: '12px',
                        }}
                    >
                        {organizations.map((org) => (
                            <MenuItem key={org.uuid} value={org.uuid}>
                                {org.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onButtonClick}
                    disabled={disabled || !selectedOrganization}
                    sx={{
                        padding: '5px 10px',
                        borderRadius: 1,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                        '&:hover': {
                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
                        },
                        width: 'fit-content', // This ensures the button width fits its content
                    }}
                >
                    {buttonText}
                </Button>
            </CardActions>
        </Card>
    );
};

export default GlobalMarketplaceCard;
