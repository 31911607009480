import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import { SvgIconProps, useMediaQuery, useTheme } from '@mui/material';
import { Individual, Organization } from 'utils';
import Search from './Search';
import debounce from 'lodash/debounce';
import { getIndividuals } from 'api';
import CreateIndividualModal from 'modules/community/components/CreateIndividualModal';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface Props {
    onIndividualSelected: (individual: Individual[]) => void;
    showLoadingOverlay?: boolean;
    buttonText?: string | React.ReactElement<SvgIconProps>;
    buttonVariant?: 'text' | 'contained' | 'outlined';
    buttonClass?: string | React.CSSProperties;
    buttonComponent?: React.ElementType;
    disabled?: boolean;
    organization: Organization;
    buttonColor?: 'primary' | 'inherit' | 'secondary';
    createButtonText?: string;
    multi?: boolean;
    sx?: SxProps<Theme>;
}

const SearchIndividual = ({
    onIndividualSelected,
    showLoadingOverlay = false,
    buttonText = 'Log Session',
    buttonVariant = 'text',
    buttonClass = '',
    buttonComponent = 'button',
    buttonColor = 'primary',
    disabled = false,
    organization,
    createButtonText = 'Create New Athlete',
    multi = false,
    sx,
}: Props) => {
    const theme = useTheme();
    const breakPoint = useMediaQuery(theme.breakpoints.up('sm'));

    const [inputValue, setInputValue] = React.useState('');

    const [isOpen, setIsOpen] = useState(false);
    const [createIndividualOpen, setCreateIndividualOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const debouncedSearchIndividual = useMemo(
        () =>
            debounce(
                (value) =>
                    searchIndividuals({
                        limit: 50,
                        sort: 'name',
                        'filter[name]': value,
                    }),
                400,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const searchIndividuals = (params = {}) => {
        setLoading(true);
        getIndividuals(
            organization ? { ...params, 'filter[organization]': organization.uuid } : params,
        ).then(function (result) {
            setFilteredIndividualsList(result.data.data);
            setLoading(false);
        });
    };

    const handleCreateIndividualClicked = () => {
        setCreateIndividualOpen(true);
    };

    const handleInputChanged = (value: string) => {
        setInputValue(value);
        debouncedSearchIndividual(value);
    };
    const handleClose = () => {
        setIsOpen(false);
        setSelectedIndividualsList([]);
        setInputValue('');
    };

    const [filteredIndividualsList, setFilteredIndividualsList] = useState<Array<Individual>>([]);
    const [selectedIndividualsList, setSelectedIndividualsList] = useState<Array<Individual>>([]);
    const [selectedResults, setSelectedResults] = useState<Array<string>>([]);

    useEffect(() => {
        setSelectedResults(selectedIndividualsList.map((i) => i.uuid));
    }, [selectedIndividualsList]);

    return (
        <>
            <>
                <CreateIndividualModal
                    onIndividualCreated={(individual) => {
                        setCreateIndividualOpen(false);
                        onIndividualSelected([individual]);
                    }}
                    onIndividualCreatedFailed={() => {}}
                    open={createIndividualOpen}
                    onClose={() => setCreateIndividualOpen(false)}
                />
                <Button
                    variant={buttonVariant}
                    className={typeof buttonClass === 'string' ? buttonClass : undefined}
                    sx={typeof buttonClass === 'object' ? buttonClass : sx}
                    disabled={disabled}
                    color={buttonColor}
                    component={buttonComponent}
                    aria-describedby={isOpen ? 'search-member-popover' : undefined}
                    onClick={() => {
                        searchIndividuals({ limit: 50, sort: 'name' });
                        setIsOpen(true);
                    }}
                >
                    {buttonText}
                </Button>
                <Search
                    isOpen={isOpen}
                    breakPoint={breakPoint}
                    inputValue={inputValue}
                    handleClose={handleClose}
                    setInputValue={handleInputChanged}
                    isFiltered={!!inputValue.length}
                    searchResults={filteredIndividualsList.map((i) => ({
                        id: i.uuid,
                        title: i.name,
                        subtitle: i.managing_user?.user_name ?? '',
                        avatarUrl: i.image_urls.avatar ?? '',
                    }))}
                    onCreateClicked={handleCreateIndividualClicked}
                    loading={loading}
                    showOverlay={showLoadingOverlay}
                    onResultSelected={(result) => {
                        if (selectedIndividualsList.findIndex((i) => i.uuid === result.id) === -1) {
                            setSelectedIndividualsList([
                                ...selectedIndividualsList,
                                filteredIndividualsList.find((i) => i.uuid === result.id)!,
                            ]);
                        } else {
                            setSelectedIndividualsList(
                                selectedIndividualsList.filter((i) => i.uuid !== result.id),
                            );
                        }

                        if (!multi) {
                            setIsOpen(false);
                            setInputValue('');
                            onIndividualSelected(
                                filteredIndividualsList.filter((i) => i.uuid === result.id)!,
                            );
                        }
                    }}
                    organization={organization}
                    createButtonText={createButtonText}
                    placeholderText={'Search for an athlete'}
                    multi={multi}
                    selectedResults={selectedResults}
                    onMultiButtonClicked={() => onIndividualSelected(selectedIndividualsList)}
                />
            </>
        </>
    );
};

export default SearchIndividual;
