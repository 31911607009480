import { AxiosResponse } from 'axios';
import { Request } from 'api';

type ReturnType = Promise<AxiosResponse<any>>;

const loadMyProfile = (): ReturnType => {
    return Request.get(`/social/profile`);
};

export default loadMyProfile;
