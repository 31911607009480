import { v4 as uuidv4 } from 'uuid';
import {
    Asset,
    AssetVersionType,
    Attribute,
    AttributeCategory,
    AttributeValueType,
    EntityType,
    Question,
    QuestionType,
    Role,
    Section,
    Session,
    SpotlightResult,
    Submission,
} from './types';

const GROUP_MEMBERS = [
    {
        uuid: '1',
        firstName: 'Andrew',
        middleName: '',
        lastName: 'Bain',
        role: 'Coach',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        uuid: '2',
        firstName: 'Kevin',
        middleName: 'J.',
        lastName: 'Morgan',
        role: 'Player',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        uuid: '3',
        firstName: 'Floyed',
        middleName: '',
        lastName: 'Miles',
        role: 'Athelete',
        thumbnail: null,
    },
    {
        uuid: '4',
        firstName: 'Robert',
        middleName: '',
        lastName: 'Park',
        role: 'Other',
        thumbnail: null,
    },
];

const ATTRIBUTES: Array<Attribute> = [
    {
        name: 'Team Color',
        category: AttributeCategory.Group,
        sub_category: 'Body Measurements',
        type: AttributeValueType.TEXT,
        organization_uuid: '',
    },
    {
        name: 'Current Height',
        category: AttributeCategory.Member,
        sub_category: 'Body Measurements',
        type: AttributeValueType.TEXT,
        organization_uuid: '',
    },
    {
        name: 'Current Weight',
        category: AttributeCategory.Member,
        sub_category: 'Body Measurements',
        type: AttributeValueType.TEXT,
        organization_uuid: '',
    },
    {
        name: 'Pant Size',
        category: AttributeCategory.Member,
        sub_category: 'Body Measurements',
        type: AttributeValueType.TEXT,
        organization_uuid: '',
    },
    {
        name: 'Other',
        category: AttributeCategory.Member,
        sub_category: 'Body Measurements',
        type: AttributeValueType.TEXT,
        organization_uuid: '',
    },
    {
        name: 'Something Else',
        category: AttributeCategory.Member,
        sub_category: 'Body Measurements',
        type: AttributeValueType.TEXT,
        organization_uuid: '',
    },
    {
        name: 'Difficulty Level',
        category: AttributeCategory.Movement,
        sub_category: '',
        type: AttributeValueType.TEXT,
        organization_uuid: '',
    },
    {
        name: 'Joint',
        category: AttributeCategory.Movement,
        sub_category: '',
        type: AttributeValueType.TEXT,
        organization_uuid: '',
    },
    {
        name: 'Body Quadrant',
        category: AttributeCategory.Movement,
        sub_category: '',
        type: AttributeValueType.TEXT,
        organization_uuid: '',
    },
];

const SESSIONS: Array<Session> = [
    {
        uuid: '123',
        title: 'Sprint Warmup',
        attributes: [],
        views: 25,
        is_favorite: false,
        image_urls: {
            '200': null,
            '350': null,
            '500': null,
            '1000': null,
            avatar: null,
            original: null,
        },
        archived_at: null,
        object: EntityType.SESSION,
        organization: {
            name: 'Test Org',
            slug: 'test-org',
            about_us: 'Test Org Description',
            uuid: 'test-uuid',
            object: EntityType.ORGANIZATION,
            logo_url: '',
            banner_url: '',
            video_url: '',
            mux_playback_id: '',
            image_urls: {
                '200': null,
                '350': null,
                '500': null,
                '1000': null,
                avatar: null,
                original: null,
            },
        },
    },
    {
        uuid: '23221234',
        title: 'Sprint Warmup',
        attributes: [],
        views: 25,
        image_urls: {
            '200': null,
            '350': null,
            '500': null,
            '1000': null,
            avatar: null,
            original: null,
        },
        is_favorite: false,
        object: EntityType.SESSION,
        archived_at: null,
        organization: {
            name: 'Test Org',
            slug: 'test-org',
            about_us: 'Test Org Description',
            uuid: 'test-uuid',
            object: EntityType.ORGANIZATION,
            logo_url: '',
            banner_url: '',
            video_url: '',
            mux_playback_id: '',
            image_urls: {
                '200': null,
                '350': null,
                '500': null,
                '1000': null,
                avatar: null,
                original: null,
            },
        },
    },
];

const MOVEMENTS = [
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
    {
        name: 'Side Hurdles',
        type: '#Endurance',
        description: 'NY Yankees',
        thumbnail: 'https://picsum.photos/200/300',
    },
];

const ASSET: Asset = {
    uuid: '1',
    purpose: 'Test Purpose Asset',
    status: 'ready',
    versions: [
        {
            uuid: '1',
            type: AssetVersionType.Thumbnail,
            file_mime_type: 'img',
            file_signed_url: 'https://picsum.photos/200/300',
            file_size: 200,
            height: 40,
            width: 60,
            encoding_status: 'encoded',
            duration: null,
            framerate: null,
            cookie: {
                cookie: 1,
            },
            image_urls: {
                '200': null,
                '350': null,
                '500': null,
                '1000': null,
                avatar: null,
                original: null,
            },
        },
    ],
};

const QUESTION: Question = {
    question: 'Test Question',
    type: 'Question Type',
    asset: ASSET,
    version_number: 1,
    order: 1,
    choices: [
        {
            uuid: '1',
            text: 'Test Choice 1',
            media: null,
            media_url: null,
        },
        {
            uuid: '2',
            text: 'Test Choice 2',
            media: null,
            media_url: null,
        },
        {
            uuid: '3',
            text: 'Test Choice 3',
            media: null,
            media_url: null,
        },
    ],
};

const QUESTION_WITHOUT_CHOICE: Question = {
    question: 'Test Question',
    type: 'Question Type',
    asset: ASSET,
    version_number: 1,
    choices: [],
    order: 1,
};

const SECTION: Section = {
    uuid: uuidv4(),
    name: 'Test Section',
    questions: [QUESTION_WITHOUT_CHOICE, QUESTION, QUESTION, QUESTION_WITHOUT_CHOICE],
};

const ROLE_SESSION_ADMIN: Role = {
    name: 'session-admin',
    display_name: 'Session Admin',
    classification: 'session',
    description: 'Administer a session',
};

const ROLE_SESSION_MANAGER: Role = {
    name: 'session-manager',
    display_name: 'Session Manager',
    classification: 'session',
    description: 'Administer a session',
};

const ROLES: { [key: string]: Role } = {
    [ROLE_SESSION_ADMIN.name]: ROLE_SESSION_ADMIN,
    [ROLE_SESSION_MANAGER.name]: ROLE_SESSION_MANAGER,
};

const SUBMISSIONS: Submission[] = [
    {
        uuid: '1',
        submitted_at: 1623142726,
        thumbnail: 'https://picsum.photos/200/300',
        score: 50.0,
        score_numerator: 1,
        score_denominator: 2,
        form: {
            uuid: '123',
            title: 'Test Form',
            media_url: 'https://picsum.photos/200/300',
            type: 'Test form type',
            description: 'Test form description',
            asset: ASSET,
            completed_submissions_count: 2,
            object: EntityType.FORM,
            organization: {
                name: 'Test Org',
                slug: 'test-org',
                about_us: 'Test Org Description',
                uuid: 'test-uuid',
                object: EntityType.ORGANIZATION,
                logo_url: '',
                banner_url: '',
                video_url: '',
                mux_playback_id: '',
                image_urls: {
                    '200': null,
                    '350': null,
                    '500': null,
                    '1000': null,
                    avatar: null,
                    original: null,
                },
            },
            image_urls: {
                '200': null,
                '350': null,
                '500': null,
                '1000': null,
                avatar: null,
                original: null,
            },
            sections: [
                {
                    uuid: uuidv4(),
                    name: 'Test Section',
                    questions: [
                        {
                            uuid: uuidv4(),
                            question: 'Test Question',
                            type: QuestionType.Text,
                            order: 1,
                        },
                    ],
                },
            ],
            attributes: [
                {
                    name: 'Test Attribute',
                    category: AttributeCategory.Form,
                    sub_category: 'Test Form Sub Category',
                    value: '1',
                    type: AttributeValueType.TEXT,
                },
            ],
        },
        name: 'Test Submission',
        values: [
            {
                section: {
                    uuid: uuidv4(),
                    name: 'Test Section',
                    questions: [QUESTION_WITHOUT_CHOICE, QUESTION, QUESTION, QUESTION],
                },
                text_value: '2',
                question_version: 1,
                question: QUESTION,
            },
            {
                section: {
                    uuid: uuidv4(),
                    name: 'Test Section 2',
                    questions: [QUESTION, QUESTION, QUESTION, QUESTION],
                },
                text_value: '2',
                question_version: 1,
                question: QUESTION,
            },
            {
                section: {
                    uuid: uuidv4(),
                    name: 'Test Section 3',
                    questions: [QUESTION, QUESTION, QUESTION, QUESTION],
                },
                text_value: '2',
                question_version: 1,
                question: QUESTION,
            },
        ],
    },
    {
        uuid: '1',
        submitted_at: 1623142726,
        thumbnail: 'https://picsum.photos/200/300',
        score: 50.0,
        score_numerator: 1,
        score_denominator: 2,
        form: {
            organization: {
                name: 'Test Org',
                slug: 'test-org',
                about_us: 'Test Org Description',
                uuid: 'test-uuid',
                object: EntityType.ORGANIZATION,
                logo_url: '',
                banner_url: '',
                video_url: '',
                mux_playback_id: '',
                image_urls: {
                    '200': null,
                    '350': null,
                    '500': null,
                    '1000': null,
                    avatar: null,
                    original: null,
                },
            },
            image_urls: {
                '200': null,
                '350': null,
                '500': null,
                '1000': null,
                avatar: null,
                original: null,
            },
            uuid: '123',
            title: 'Test Form',
            media_url: 'https://picsum.photos/200/300',
            type: 'Test form type',
            description: 'Test form description',
            asset: ASSET,
            completed_submissions_count: 2,
            object: EntityType.FORM,
            sections: [
                {
                    uuid: uuidv4(),
                    name: 'Test Section',
                    questions: [
                        {
                            uuid: uuidv4(),
                            question: 'Test Question',
                            type: QuestionType.Text,
                            order: 1,
                        },
                    ],
                },
            ],
            attributes: [
                {
                    name: 'Test Attribute',
                    category: AttributeCategory.Form,
                    sub_category: 'Test Form Sub Category',
                    value: '1',
                    type: AttributeValueType.TEXT,
                },
            ],
        },
        name: 'Test Submission',
        values: [
            {
                section: {
                    uuid: uuidv4(),
                    name: 'Test Section',
                    questions: [QUESTION, QUESTION, QUESTION, QUESTION],
                },
                text_value: '2',
                question_version: 1,
                question: QUESTION,
            },
            {
                section: {
                    uuid: uuidv4(),
                    name: 'Test Section 2',
                    questions: [QUESTION, QUESTION, QUESTION, QUESTION],
                },
                text_value: '2',
                question_version: 1,
                question: QUESTION,
            },
            {
                section: {
                    uuid: uuidv4(),
                    name: 'Test Section 3',
                    questions: [QUESTION, QUESTION, QUESTION, QUESTION],
                },
                text_value: '2',
                question_version: 1,
                question: QUESTION,
            },
        ],
    },
    {
        uuid: '1',
        submitted_at: 1623142726,
        thumbnail: 'https://picsum.photos/200/300',
        score: 50.0,
        score_numerator: 1,
        score_denominator: 2,
        form: {
            uuid: '123',
            title: 'Test Form',
            media_url: 'https://picsum.photos/200/300',
            type: 'Test form type',
            description: 'Test form description',
            completed_submissions_count: 2,
            asset: ASSET,
            object: EntityType.FORM,
            image_urls: {
                '200': null,
                '350': null,
                '500': null,
                '1000': null,
                avatar: null,
                original: null,
            },
            organization: {
                name: 'Test Org',
                slug: 'test-org',
                about_us: 'Test Org Description',
                uuid: 'test-uuid',
                object: EntityType.ORGANIZATION,
                logo_url: '',
                banner_url: '',
                video_url: '',
                mux_playback_id: '',
                image_urls: {
                    '200': null,
                    '350': null,
                    '500': null,
                    '1000': null,
                    avatar: null,
                    original: null,
                },
            },
            sections: [
                {
                    uuid: uuidv4(),
                    name: 'Test Section',
                    questions: [
                        {
                            uuid: uuidv4(),
                            question: 'Test Question',
                            type: QuestionType.Text,
                            order: 1,
                        },
                    ],
                },
            ],
            attributes: [
                {
                    name: 'Test Attribute',
                    category: AttributeCategory.Form,
                    sub_category: 'Test Form Sub Category',
                    value: '1',
                    type: AttributeValueType.TEXT,
                },
            ],
        },
        name: 'Test Submission',
        values: [
            {
                section: {
                    uuid: uuidv4(),
                    name: 'Test Section',
                    questions: [QUESTION, QUESTION, QUESTION, QUESTION],
                },
                text_value: '2',
                question_version: 1,
                question: QUESTION,
            },
            {
                section: {
                    uuid: uuidv4(),
                    name: 'Test Section 2',
                    questions: [QUESTION, QUESTION, QUESTION, QUESTION],
                },
                text_value: '2',
                question_version: 1,
                question: QUESTION,
            },
            {
                section: {
                    uuid: uuidv4(),
                    name: 'Test Section 3',
                    questions: [QUESTION, QUESTION, QUESTION, QUESTION],
                },
                text_value: '2',
                question_version: 1,
                question: QUESTION,
            },
        ],
    },
];

const SEARCH_RESULTS: SpotlightResult[] = [
    {
        resultType: 'session',
        resultId: '733ffaf1-ed3f-4064-b7ea-026048b72088',
        resultText: 'Baseball Push Ups',
        resultSubtext: 'Equipment: BW Body Part: Chest',
        avatarUrl: '',
        resultCaption: '',
    },
    {
        resultType: 'program',
        resultId: '9ac1d308-c05b-4ed2-88a5-fd8c97bb044a',
        resultText: 'U18 Summer Baseball 2021',
        resultSubtext: '',
        avatarUrl: '',
        resultCaption: '',
    },
];

const SCRUB_DATA = {
    GROUP_MEMBERS,
    ATTRIBUTES,
    SESSIONS,
    MOVEMENTS,
    SUBMISSIONS,
    ASSET,
    QUESTION,
    SECTION,
    ROLES,
    QUESTION_WITHOUT_CHOICE,
    SEARCH_RESULTS,
};

export default SCRUB_DATA;
