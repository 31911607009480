import React, { useState } from 'react';
import { Avatar, Badge, Box, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import {
    updateUserData,
    userOriginalProfilePhotoSelector,
    userSelector,
} from 'redux/reducers/user';
import { CloudUpload, DeleteForever } from '@mui/icons-material';
import { getSignedUrl, uploadFile } from 'api';
import { useAppDispatch } from 'redux/store';
import { pushMessage } from 'redux/reducers/messages';
import { ButtonWithLoader } from 'components/Buttons';
import useTheme from '@mui/material/styles/useTheme';

const ProfileDetailsForm = () => {
    const theme = useTheme();
    const { userData } = useSelector(userSelector);
    const currentProfilePhoto = useSelector(userOriginalProfilePhotoSelector);
    const [profilePhoto, setProfilePhoto] = useState<File | null>();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const changeAvatarPhoto = (files: FileList | null) => {
        if (!files) {
            return;
        }

        Array.from(files).forEach((file) => {
            setProfilePhoto(file);
        });
    };
    const undoProfileUpload = () => {
        setProfilePhoto(null);
    };

    const uploadProfilePhotoToS3 = async () => {
        if (profilePhoto) {
            setIsUploading(true);
            try {
                const response = await getSignedUrl();
                if (response.status === 201) {
                    const res = await uploadFile(response.data.url, profilePhoto);
                    if (res.status === 200) {
                        const newProfilePhoto = response.data.key;
                        const result = await dispatch(
                            updateUserData({
                                userUuid: userData?.uuid || '',
                                profile_photo: newProfilePhoto,
                                first_name: null,
                                last_name: null,
                                email: null,
                                phone: null,
                            }),
                        );

                        if (updateUserData.fulfilled.match(result)) {
                            setProfilePhoto(null);
                            dispatch(
                                pushMessage({
                                    status: 'success',
                                    message: 'Profile updated successfully.',
                                }),
                            );
                        } else {
                            if (result.payload) {
                                // Server sent validation errors
                                if ('errors' in result.payload) {
                                    dispatch(
                                        pushMessage({
                                            status: 'error',
                                            message: `${result.payload?.errors}`,
                                        }),
                                    );
                                } else {
                                    // No validation errors
                                    // Something might be wrong on server side
                                    dispatch(
                                        pushMessage({
                                            status: 'error',
                                            message: 'Something went wrong, please try again',
                                        }),
                                    );
                                }
                            }
                        }
                    }
                }
                return false;
            } catch (err: any) {
                dispatch(
                    pushMessage({
                        status: 'error',
                        message: 'Something went wrong, please try again',
                    }),
                );
                return false;
            } finally {
                setIsUploading(false);
            }
        }
        return false;
    };

    return (
        <Box>
            <Box sx={{ marginTop: theme.spacing(6) }}>
                <Box my={2} textAlign="center">
                    <Badge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent={
                            isUploading ? (
                                ''
                            ) : (
                                <Box
                                    sx={{
                                        background: theme.palette.primary.light,
                                        borderRadius: '50%',
                                    }}
                                >
                                    {!profilePhoto && (
                                        <label htmlFor="icon-button-file">
                                            <input
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="icon-button-file"
                                                type="file"
                                                onChange={(event) => {
                                                    changeAvatarPhoto(event.target.files);
                                                }}
                                                name="profile_photo"
                                            />
                                            <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                component="span"
                                                sx={{ color: 'white' }}
                                                size="large"
                                            >
                                                <CloudUpload />
                                            </IconButton>
                                        </label>
                                    )}
                                    {profilePhoto && (
                                        <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="span"
                                            sx={{ color: 'white' }}
                                            onClick={undoProfileUpload}
                                            size="large"
                                        >
                                            <DeleteForever />
                                        </IconButton>
                                    )}
                                </Box>
                            )
                        }
                    >
                        <Avatar
                            alt="Travis Howard"
                            variant="rounded"
                            src={
                                profilePhoto
                                    ? URL.createObjectURL(profilePhoto)
                                    : currentProfilePhoto || ''
                            }
                            sx={{
                                width: theme.spacing(116),
                                height: theme.spacing(116),
                                variant: 'rounded',
                            }}
                        >
                            {userData?.first_name} {userData?.last_name}
                        </Avatar>
                    </Badge>
                </Box>
                <Box my={2} textAlign="center">
                    <ButtonWithLoader
                        size="large"
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={uploadProfilePhotoToS3}
                        sx={{ marginTop: theme.spacing(6) }}
                        disabled={isUploading ? true : profilePhoto == null}
                        loading={isUploading}
                    >
                        {isUploading ? 'Updating' : 'Update'}
                    </ButtonWithLoader>
                </Box>
            </Box>
        </Box>
    );
};

export default ProfileDetailsForm;
