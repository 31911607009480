import NavigationLayout from '../../../Layouts/NavigationLayout';
import { Navigation } from '../../../utils';
import EditMovementForm from '../components/EditMovementForm';
import GroupLogging from './GroupLogging';
import ListOfMovements from './ListOfMovements';
import StartGroupLog from './StartGroupLog';
import TrainDashboard from './TrainDashboard';

export const trainRoutes = [
    {
        exact: true,
        layout: NavigationLayout,
        path: '/train',
        component: TrainDashboard,
        private: true,
        primaryNavigation: Navigation.TRAIN,
        secondaryNavigation: Navigation.TRAIN_DASHBOARD,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/train/movements',
        component: ListOfMovements,
        private: true,
        primaryNavigation: Navigation.TRAIN,
        secondaryNavigation: Navigation.TRAIN_MOVEMENTS,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/train/movements/:id',
        component: EditMovementForm,
        private: true,
        primaryNavigation: Navigation.TRAIN,
        secondaryNavigation: Navigation.TRAIN_MOVEMENTS,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/train/logging/group-workouts/:groupWorkoutId',
        component: GroupLogging,
        private: true,
        primaryNavigation: Navigation.TRAIN,
        secondaryNavigation: Navigation.TRAIN_MOVEMENTS,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/train/logging/group-workouts/start-log/:sessionId/:groupId',
        component: StartGroupLog,
        private: true,
        primaryNavigation: Navigation.TRAIN,
        secondaryNavigation: Navigation.TRAIN_MOVEMENTS,
    },
];
