import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import ScribeCard from '../components/ScribeCard';

function ImportingRawGPSData() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        GPS data can provide the most accurate and most granular method to monitor
                        an athlete&apos;s speed. There are two ways to import Raw GPS data: API and
                        Manual CSV Import. Although we are currently working on APIs for McLloyd and
                        Catapult, only manual csv import is availible.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Manual CSV import to individual and multi-file import thru groups.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}
                <ScribeCard
                    title={'McLloyd Manual CSV import to Individual'}
                    headline={'McLloyd Manual CSV import to Individual'}
                    iframeSrc={
                        'https://scribehow.com/embed/Uploading_McLloyd_Raw_Data__Sprint_Profiling__H2Fc66T6TSmSPuPC_1SSpQ?as=scrollable&skipIntro=true&removeLogo=true'
                    }
                />
                <ScribeCard
                    title={'Multiple CSV imports to Group'}
                    headline={'Multiple CSV imports to Group'}
                    iframeSrc={
                        'https://scribehow.com/embed/Upload_Multiple_Raw_GPS_Files_to_Group__oL6Dn9GRSIaIG-46Pg6Hrw?as=scrollable&skipIntro=true&removeLogo=true'
                    }
                />
            </Grid>
        </>
    );
}

export default ImportingRawGPSData;
