import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import CompareSprintCrops from '../../../images/CompareSprints.png';
import GPSSummaryLeaderboard from '../../../images/GPSSummaryLeaderboard.png';
import AthleteProgress from '../../../images/ExploreAthleteProgress.png';
import SummaryProfiles from '../../../images/ExploreGPSSummaryProfiles.png';
import ACWLRatio from '../../../images/ACWLRCover.png';
import FullScreenDialogCard from '../components/HowToDialogCard';

function AIVideoOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Explore provides a more focussed version of reporting and data exploration.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Currently, Explore is limited to GPS Raw and GPS Summary Data. Explore
                            has one feature for Raw GPS Data: GPS Sprint Cropping. Explore has 4
                            features for GPS Summary Data: Leaderboard, Athlete Profiles, Acute to
                            Chronic Workload Ratio, and Athlete Progress.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={CompareSprintCrops}
                    title="GPS Sprint Cropping"
                    headline=""
                    description="Compare crops to see an athlete's progress over time or to compare one athlete with another on the same graph or on multiple graphs."
                />

                <FullScreenDialogCard
                    image={GPSSummaryLeaderboard}
                    title="GPS Summary Leaderboard"
                    headline=""
                    description="Build a leaderboard of the top performing athletes in GPS session. Answer questions like 'Who is the fastest athlete on the team in the last month?'"
                />
                <FullScreenDialogCard
                    image={SummaryProfiles}
                    title="GPS Summary Athlete Profiles"
                    headline=""
                    description="Display athlete profiles in a time period for GPS summary data and view them side by side. Answer questions like 'How has an athlete performed in key metrics in the last month?'"
                />

                {/* First row */}
                <FullScreenDialogCard
                    image={ACWLRatio}
                    title="GPS Summary Acute Chronic Workload Ratio"
                    headline=""
                    description="Compare athlete Acute Chronic Workload Ratio (ACWR) by day. Answer questions like 'Which athletes are at risk for injury?' and 'Which athletes are prepared?'"
                />
                <FullScreenDialogCard
                    image={AthleteProgress}
                    title="GPS Summary Athlete Progress"
                    headline=""
                    description="Compare athlete progress or exertion by day, week or month for GPS summary data. Answer questions like 'How has this athlete improved week by week in the last 3 weeks compared to the rest of the team?'"
                />
            </Grid>
        </>
    );
}

export default AIVideoOverview;
