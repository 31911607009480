import { styled } from '@mui/system';
import Button from '@mui/material/Button';

const MediumButton = styled(Button)({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 15,
    color: '#003E9D',
    textTransform: 'none',
});

export default MediumButton;
