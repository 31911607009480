import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { EntityType, Movement, MovementType, SessionMovement } from 'utils';

export interface SessionMovementsWithGoalsProps {
    sessionMovements: Array<SessionMovement>;
}

export interface SessionMovementGoalSet {
    groupTitle: string | null;
    movement: Movement;
    goalSets: Array<SessionMovement>;
}

const SessionMovementsWithGoalsCondensed = ({
    sessionMovements,
}: SessionMovementsWithGoalsProps) => {
    const [sessionMovementGoalSets, setSessionMovementGoalSets] = useState<
        Array<SessionMovementGoalSet>
    >([]);

    useEffect(() => {
        let goalSets: Array<SessionMovementGoalSet> = [];
        for (let i = 0; i < sessionMovements.length; i++) {
            if (i == 0 || sessionMovements[i].uuid != sessionMovements[i - 1].uuid) {
                goalSets.push({
                    groupTitle: sessionMovements[i].pivot?.group_title ?? '',
                    movement: {
                        uuid: sessionMovements[i].uuid,
                        name: sessionMovements[i].name,
                        object: EntityType.MOVEMENT,
                        description: sessionMovements[i].description,
                        movement_type: sessionMovements[i].movement_type as MovementType,
                        published_at: sessionMovements[i].published_at,
                        assets: sessionMovements[i].assets,
                    },
                    goalSets: [sessionMovements[i]],
                });
            } else {
                goalSets[goalSets.length - 1].goalSets.push(sessionMovements[i]);
            }
        }

        setSessionMovementGoalSets(goalSets);
    }, [sessionMovements]);

    return (
        <>
            {sessionMovementGoalSets.length > 0 ? (
                <div>
                    {sessionMovementGoalSets.map((sessionMovementGoalSet, goalSetIndex) => {
                        return (
                            <div
                                key={`${goalSetIndex}-${sessionMovementGoalSet.movement.uuid}`}
                                data-index={goalSetIndex}
                                style={{
                                    borderTop: '.05px solid #A7ABB7',
                                    borderBottom: '.05px solid #A7ABB7',
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexWrap="wrap"
                                >
                                    <Typography align={'center'} variant={'body2'}>
                                        {sessionMovementGoalSet.movement.name}
                                    </Typography>
                                    <Typography
                                        align={'center'}
                                        variant={'caption'}
                                        style={{ color: '#A7ABB7' }}
                                    >
                                        {' '}
                                        {sessionMovementGoalSet.groupTitle && (
                                            <>[{sessionMovementGoalSet.groupTitle}]</>
                                        )}
                                    </Typography>
                                </Box>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <Box pt={20} display="flex" justifyContent="center" pb={20}>
                    <Typography variant="body2">No movements added.</Typography>
                </Box>
            )}
        </>
    );
};

export default SessionMovementsWithGoalsCondensed;
