import { Box, Typography } from '@mui/material';
import React from 'react';

const NotFound = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                sx={{
                    fontSize: 60,
                    textAlign: 'center',
                    fontWeight: 'bolder',
                }}
                color="primary"
            >
                404 - Page Not Found
            </Typography>
        </Box>
    );
};

export default NotFound;
