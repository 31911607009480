import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { MovementFilter } from '../../../../app.types';

export const movementsAdapter = createEntityAdapter<MovementFilter>({
    selectId: (movement) => movement.movement_key,
});

export interface MovementFilterState extends EntityState<MovementFilter> {
    loading: boolean;
    loaded: boolean;
    lastLoaded: null;
}
