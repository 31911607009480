import { Box, Button, CircularProgress, Hidden } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { getFormDetails, submitFormSubmission, viewSubmission } from 'api';
import { useNavigationContext } from 'Layouts/Navigation/NavigationContext';
import { orderBy } from 'lodash';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from 'Routes/routes';
import { Form, getIdFromEndOfRoute, somethingWentWrong, Submission } from 'utils';
import { AssessmentSubmission } from '../components';

const CompleteAssessment = () => {
    const theme = useTheme();
    const { push, location } = useHistory();
    const { blockRedirection } = useNavigationContext();

    const [form, setForm] = useState<Form | null>(null);
    const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
    const [submission, setSubmission] = useState<Submission | null>(null);

    // Get submission UUID from the url params
    let submissionUuid = getIdFromEndOfRoute(location.pathname);

    // Load the form from the database
    useEffect(() => {
        if (!submission) {
            viewSubmission(submissionUuid)
                .then((result) => {
                    setSubmission(result.data);
                    getFormDetails(result.data.form.uuid)
                        .then((response) => {
                            const form = response.data as Form;
                            form.sections = form.sections.map((section) => {
                                return {
                                    ...section,
                                    questions: orderBy(
                                        section.questions.map((question) => {
                                            return {
                                                ...question,
                                                choices: orderBy(question.choices || [], 'order'),
                                            };
                                        }),
                                        'order',
                                    ),
                                };
                            });
                            setForm(response.data);
                        })
                        .catch(() => somethingWentWrong());
                })
                .catch(() => somethingWentWrong());
        }
    }, [submission, submissionUuid]);

    useEffect(() => {
        window.onbeforeunload = function () {
            return 'Are you sure you want to leave?';
        };
    }, []);

    const submitAssessment = () => {
        blockRedirection(false);
        if (form?.uuid && submission?.uuid) {
            submitFormSubmission(form?.uuid, submission.uuid)
                .then(() => {
                    push(`/assessments/${submission?.uuid}`);
                })
                .catch(() => somethingWentWrong());
        }
    };

    return (
        <FixedLayout
            disableBackButton={true}
            title={form?.title || ''}
            titleActions={
                <>
                    {readyToSubmit && (
                        <Hidden mdUp>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => {
                                    submitAssessment();
                                }}
                            >
                                Submit
                            </Button>
                        </Hidden>
                    )}
                </>
            }
            content={
                form && submission ? (
                    <AssessmentSubmission
                        form={form}
                        submission={submission}
                        onReadyToSubmit={() => setReadyToSubmit(true)}
                        onSubmitCancelled={() => setReadyToSubmit(false)}
                        onFormExited={() => push(ROUTES.ClassAssessments.path)}
                    />
                ) : (
                    <Box pt={20} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )
            }
            hideFooterOnMobileDevices={true}
            footerActions={
                <>
                    {readyToSubmit && (
                        <Box display="flex" justifyContent="center">
                            <Button
                                sx={{ marginTop: theme.spacing(6) }}
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => {
                                    submitAssessment();
                                }}
                            >
                                Submit Assessment
                            </Button>
                        </Box>
                    )}
                </>
            }
        />
    );
};

export default CompleteAssessment;
