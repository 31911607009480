import { Box, Card, CardActions, CardContent, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { OrganizationPublic } from '../../../utils';
import LockIcon from '@mui/icons-material/Lock';

interface SignUpCardProps {
    organization: OrganizationPublic;
    isUnlocked: boolean;
    isSubscribed: boolean;
    body: string;
    secondaryBody: string;
    subscribedText?: string;
    notSubscribedText?: string;
    subscribedButtonText?: string;
    notSubscribedButtonText?: string;
    onButtonClick?: () => void;
    disabled?: boolean;
    image?: string;
}

const AthleteSignUpCard: React.FC<SignUpCardProps> = ({
    organization,
    isUnlocked,
    isSubscribed,
    body,
    secondaryBody,
    subscribedText,
    notSubscribedText,
    subscribedButtonText,
    notSubscribedButtonText,
    onButtonClick,
    disabled,
    image,
}) => {
    const theme = useTheme();

    const handleButtonClick = () => {
        if (isUnlocked && onButtonClick) {
            onButtonClick();
        }
    };

    return (
        <Card
            sx={{
                maxWidth: 300,
                height: 340,
                marginTop: 16,
                borderRadius: 3,
                background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                color: theme.palette.common.white,
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                },
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                overflow: 'hidden',
                opacity: isUnlocked ? 1 : 0.8,
            }}
        >
            {image && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '15%',
                        left: '15%',
                        right: '15%',
                        bottom: '15%',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            opacity: 0.4,
                        },
                    }}
                >
                    {!isUnlocked && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 1,
                            }}
                        >
                            <LockIcon
                                sx={{
                                    fontSize: 100,
                                    color: 'rgba(255, 255, 255, 0.8)',
                                }}
                            />
                        </Box>
                    )}
                </Box>
            )}
            <CardContent sx={{ flexGrow: 1, position: 'relative', zIndex: 1 }}>
                {organization.logo_url && !isSubscribed && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: '50%',
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'left',
                        }}
                    >
                        <img
                            src={organization.logo_url}
                            alt={organization.name}
                            style={{
                                maxHeight: 46,
                                maxWidth: 46,
                                borderRadius: '25%',
                                margin: '6px',
                            }}
                        />
                    </Box>
                )}
                <Typography
                    textAlign="center"
                    component="div"
                    sx={{
                        fontSize: '1.2rem',
                        fontWeight: 600,
                        textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                    }}
                    gutterBottom
                >
                    {isSubscribed ? subscribedText : notSubscribedText}
                </Typography>
                <Typography
                    sx={{
                        color: 'FFF',
                        padding: 6,
                        fontSize: '0.8rem',
                        fontWeight: 500,
                        textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                    }}
                    variant="body1"
                >
                    {body}
                </Typography>
                <Typography
                    sx={{
                        color: 'FFF',
                        padding: 6,
                        fontSize: '0.8rem',
                        fontWeight: 500,
                        textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                    }}
                    variant="body1"
                >
                    {secondaryBody}
                </Typography>
            </CardContent>

            <CardActions sx={{ position: 'relative', zIndex: 1 }}>
                {isUnlocked ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleButtonClick}
                        disabled={disabled}
                        sx={{
                            margin: 'auto',
                            padding: '5px 10',
                            borderRadius: '10',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
                            },
                        }}
                    >
                        {isSubscribed ? subscribedButtonText : notSubscribedButtonText}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={true}
                        sx={{
                            margin: 'auto',
                            padding: '5px 10',
                            borderRadius: '10',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
                            },
                        }}
                    >
                        Subscribe to Unlock!
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};

export default AthleteSignUpCard;
