import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    deleteFromFeed as apiDeleteFromFeed,
    follow as apiFollow,
    getMyFeed,
    postToFeed as apiPostToFeed,
    unfollow as apiUnfollow,
} from '../../../modules/social/api';
import { RootState } from '../index';

export interface MyFeedState {
    items: Array<any>;
    loading: boolean;
    loaded: boolean;
    query: { page: number };
    hasAnotherPage: boolean;
    error: string | null;
}

// reducers
export const initialState: MyFeedState = {
    items: [],
    loading: true,
    loaded: false,
    error: null,
    hasAnotherPage: false,
    query: { page: 1 },
};

// actions
export const loadMyFeed = createAsyncThunk<
    { data: any },
    { query: { status: string; page: number } },
    { rejectValue: { message: string } }
>('social/fetchMyFeed', async ({ query }, { rejectWithValue }) => {
    try {
        const { data } = await getMyFeed(query);
        return { data };
    } catch {
        return rejectWithValue({ message: 'Failed to get feed data' });
    }
});

export const postToFeed = createAsyncThunk<
    undefined,
    { activity: string; index: number },
    { rejectValue: { message: string } }
>('social/post', async ({ activity }, { rejectWithValue }) => {
    try {
        await apiPostToFeed(activity);
    } catch {
        return rejectWithValue({ message: 'Failed to get feed data' });
    }
});

export const deleteFromFeed = createAsyncThunk<
    undefined,
    { activity: string; index: number },
    { rejectValue: { message: string } }
>('social/delete', async ({ activity }, { rejectWithValue }) => {
    try {
        await apiDeleteFromFeed(activity);
    } catch {
        return rejectWithValue({ message: 'Failed to delete feed activity' });
    }
});

export const follow = createAsyncThunk<
    undefined,
    { user: string },
    { rejectValue: { message: string } }
>('social/follow', async ({ user }, { rejectWithValue }) => {
    try {
        await apiFollow(user);
    } catch {
        return rejectWithValue({ message: 'Failed to get feed data' });
    }
});

export const unfollow = createAsyncThunk<
    undefined,
    { user: string },
    { rejectValue: { message: string } }
>('social/unfollow', async ({ user }, { rejectWithValue }) => {
    try {
        await apiUnfollow(user);
    } catch {
        return rejectWithValue({ message: 'Failed to get feed data' });
    }
});

// base slice
export const myFeedSlice = createSlice({
    name: 'myFeed',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadMyFeed.pending, (state, action) => {
            state.loading = true;
            state.error = null;
            state.query = action.meta.arg.query;
        });
        addCase(loadMyFeed.fulfilled, (state, action) => {
            state.loading = false;
            state.loaded = true;
            if (!action.payload.data.prev_page_url) {
                state.items = action.payload.data.data;
            } else {
                state.items = [...state.items, ...action.payload.data.data];
            }
            state.error = null;
            state.hasAnotherPage = !!action.payload.data.next_page_url;
        });
        addCase(loadMyFeed.rejected, (state) => {
            state.loading = false;
            state.error = null;
        });
        addCase(postToFeed.pending, (state, action) => {
            const item = state.items[action.meta.arg.index];

            item.public = true;
        });
        addCase(deleteFromFeed.pending, (state, action) => {
            const item = state.items[action.meta.arg.index];

            item.public = false;
        });
    },
});

// selectors
export const myFeedSelector = (state: RootState) => state.social.myFeed;
