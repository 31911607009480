import {
    AddCircleOutline,
    Check,
    CheckBox,
    Delete,
    DragIndicator,
    FileCopyOutlined,
    ImageOutlined,
    RadioButtonChecked,
    Sort,
} from '@mui/icons-material';
import {
    Box,
    debounce,
    Divider,
    Grid,
    Hidden,
    IconButton,
    InputBase,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Tooltip,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import AssetPreviewCard from 'components/Cards/AssetPreviewCard';
import React, { FC, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { updateQuestionDetails, updateQuestionType } from 'redux/reducers/form';
import { useAppDispatch } from 'redux/store';
import { Asset, AssetVersion, AssetVersionType, Choice, Question, QuestionType } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { Media } from '../../../app.types';
import { ChooseMediaDialog } from '../../../components';
import QuestionOptionsList from './QuestionOptionsList';

export interface TextQuestionCardProps {
    sectionUuid: string;
    formUuid: string;
    organization: string;
    question: Question;
    provided: DraggableProvided;
    disableDelete: boolean;
    onQuestionCopied: (copiedQuestion: Question) => void;
    onQuestionAdd: () => void;
    onQuestionDelete: (questionUuid: string) => void;
}

const TextQuestionCard: FC<React.PropsWithChildren<TextQuestionCardProps>> = ({
    disableDelete,
    sectionUuid,
    formUuid,
    question,
    provided,
    onQuestionCopied,
    onQuestionAdd,
    onQuestionDelete,
    organization,
}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [showQuestionMediaUpload, setShowQuestionMediaUpload] = useState<boolean>(false);

    const onQuestionAssetUploaded = (media: Media) => {
        const newAsset = {
            uuid: media.uuid,
            purpose: 'uploaded',
            versions: [
                {
                    uuid: uuidv4(),
                    type: AssetVersionType.Uploaded,
                    file_mime_type: media.mime_type,
                    file_signed_url: media.url,
                    file_size: null,
                    height: null,
                    width: null,
                    duration: null,
                    framerate: null,
                    encoding_status: '',
                    tmpFileUrl: media.path,
                } as AssetVersion,
            ],
        } as Asset;

        dispatch(
            updateQuestionDetails({
                formUuid: formUuid,
                sectionUuid: sectionUuid,
                questionUuid: question.uuid as string,
                updatePayload: {
                    asset: newAsset,
                    media_uuid: media.uuid,
                },
            }),
        );
    };

    return (
        <>
            <Box mb={8} key={question.uuid} id={question.uuid}>
                <Paper elevation={2}>
                    <Box p={8}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12}>
                                {/* Drag indicator */}
                                <Box textAlign="center">
                                    <IconButton
                                        {...provided.dragHandleProps}
                                        sx={{
                                            height: 48,
                                            transform: 'rotate(90deg)',
                                        }}
                                        size="large"
                                    >
                                        <DragIndicator />
                                    </IconButton>
                                </Box>
                                {/* Question-type selector */}
                                <Box>
                                    <Select
                                        value={question.type}
                                        onChange={(event: SelectChangeEvent<any>) => {
                                            dispatch(
                                                updateQuestionType({
                                                    formUuid: formUuid,
                                                    sectionUuid: sectionUuid,
                                                    questionUuid: question.uuid as string,
                                                    newType:
                                                        event.target.value ||
                                                        QuestionType.MultipleChoiceSingleSelect,
                                                }),
                                            );
                                        }}
                                        sx={{
                                            maxHeight: (theme) => theme.spacing(30),
                                            background: (theme) =>
                                                theme.palette.backgroundColor.main,
                                            marginBottom: (theme) => theme.spacing(10),
                                            maxWidth: '100%',
                                            '& .Mui-Focused': {
                                                background: (theme) =>
                                                    theme.palette.backgroundColor.main,
                                            },
                                            '@media screen and (max-width: 600px)': {
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        <MenuItem value="multiple-choice-single-select">
                                            <Box display="flex" justifyContent="space-between">
                                                <RadioButtonChecked
                                                    sx={{
                                                        margin: theme.spacing(3, 4),
                                                        color: theme.palette.text.secondary,
                                                    }}
                                                />
                                                <ListItemText primary="Multiple Choice" />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="multiple-choice-multi-select">
                                            <Box display="flex" justifyContent="space-between">
                                                <CheckBox
                                                    sx={{
                                                        margin: theme.spacing(3, 4),
                                                        color: theme.palette.text.secondary,
                                                    }}
                                                />
                                                <ListItemText primary="Multiple Selection" />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="text">
                                            <Box display="flex" justifyContent="space-between">
                                                <Sort
                                                    sx={{
                                                        margin: theme.spacing(3, 4),
                                                        color: theme.palette.text.secondary,
                                                    }}
                                                />
                                                <ListItemText primary="Short Response" />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value="confirmation">
                                            <Box display="flex" justifyContent="space-between">
                                                <Check
                                                    sx={{
                                                        margin: theme.spacing(3, 4),
                                                        color: theme.palette.text.secondary,
                                                    }}
                                                />
                                                <ListItemText primary="Confirmation (no question)" />
                                            </Box>
                                        </MenuItem>
                                    </Select>
                                </Box>
                                {/* Question input */}
                                <InputBase
                                    fullWidth
                                    sx={{
                                        '&.Mui-focused': {
                                            backgroundColor: (theme) =>
                                                theme.palette.backgroundColor.hover,
                                        },
                                    }}
                                    defaultValue={
                                        question?.question == 'Untitled Question'
                                            ? ''
                                            : question?.question
                                    }
                                    inputProps={{
                                        style: { fontSize: 17 },
                                        'aria-label': 'naked',
                                        maxLength: 2000,
                                    }}
                                    multiline
                                    maxRows={5}
                                    placeholder="Question"
                                    onChange={debounce(
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            dispatch(
                                                updateQuestionDetails({
                                                    formUuid: formUuid,
                                                    sectionUuid: sectionUuid,
                                                    questionUuid: question.uuid as string,
                                                    updatePayload: {
                                                        question: event.target.value || '',
                                                    },
                                                }),
                                            );
                                        },
                                        2000,
                                    )}
                                />
                                <Divider />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: (theme) => theme.spacing(5),
                                    gap: (theme) => theme.spacing(5),
                                }}
                            >
                                {question.asset && (
                                    <Hidden smUp>
                                        <AssetPreviewCard
                                            fullwidth={true}
                                            key={question.asset.uuid}
                                            asset={question.asset}
                                            altTitle={question.question}
                                            onAssetRemoved={() => {
                                                dispatch(
                                                    updateQuestionDetails({
                                                        formUuid: formUuid,
                                                        sectionUuid: sectionUuid,
                                                        questionUuid: question.uuid as string,
                                                        updatePayload: {
                                                            asset: null,
                                                        },
                                                    }),
                                                );
                                            }}
                                        />
                                    </Hidden>
                                )}
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="space-between">
                            {question.asset && (
                                <Hidden smDown>
                                    <AssetPreviewCard
                                        fullwidth={true}
                                        key={question.asset.uuid}
                                        asset={question.asset}
                                        altTitle={question.question}
                                        onAssetRemoved={() => {
                                            dispatch(
                                                updateQuestionDetails({
                                                    formUuid: formUuid,
                                                    sectionUuid: sectionUuid,
                                                    questionUuid: question.uuid as string,
                                                    updatePayload: {
                                                        asset: null,
                                                    },
                                                }),
                                            );
                                        }}
                                    />
                                </Hidden>
                            )}
                        </Box>

                        {(question.type == 'multiple-choice-multi-select' ||
                            question.type == 'multiple-choice-single-select' ||
                            question.type == 'confirmation') && (
                            <QuestionOptionsList
                                organization={organization}
                                question={question}
                                questionOptions={question.choices || []}
                                onQuestionOptionsUpdated={(updatedChoices: Array<Choice>) => {
                                    dispatch(
                                        updateQuestionDetails({
                                            formUuid: formUuid,
                                            sectionUuid: sectionUuid,
                                            questionUuid: question.uuid as string,
                                            updatePayload: {
                                                choices: updatedChoices,
                                            },
                                        }),
                                    );
                                }}
                                sectionUuid={sectionUuid}
                                formUuid={formUuid}
                            />
                        )}

                        <Box py={8}>{question.type !== 'text' && <Divider />}</Box>

                        <Box>
                            <Grid container>
                                <Grid item xs={4} />
                                <Grid item xs={4}>
                                    <Box textAlign="center">
                                        <Tooltip title="Add question">
                                            <IconButton
                                                color="primary"
                                                aria-label="copy question"
                                                onClick={onQuestionAdd}
                                                size="small"
                                            >
                                                <AddCircleOutline />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box textAlign="right">
                                        <Tooltip title="Upload question image">
                                            <IconButton
                                                aria-label="upload question image"
                                                onClick={() => setShowQuestionMediaUpload(true)}
                                                size="small"
                                            >
                                                <ImageOutlined />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Copy question">
                                            <IconButton
                                                aria-label="copy question"
                                                onClick={() => onQuestionCopied(question)}
                                                size="small"
                                            >
                                                <FileCopyOutlined />
                                            </IconButton>
                                        </Tooltip>
                                        {!disableDelete && (
                                            <Tooltip title="Delete question">
                                                <IconButton
                                                    aria-label="delete question"
                                                    size="small"
                                                    onClick={() => onQuestionDelete(question.uuid!)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Paper>
            </Box>
            <ChooseMediaDialog
                showVideo={true}
                open={showQuestionMediaUpload}
                fullScreen={false}
                onClose={() => setShowQuestionMediaUpload(false)}
                onSelected={(media) => {
                    setShowQuestionMediaUpload(false);
                    onQuestionAssetUploaded(media);
                }}
                autoSelectMediaAfterUpload={true}
                organization={organization}
            />
        </>
    );
};

export default TextQuestionCard;
