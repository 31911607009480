import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import McLloydLogo from 'images/McLLOYDLogo.png';
import FullScreenDialogCard from '../components/HowToDialogCard';
import SessionsList from 'images/SessionsList.png';
import Playertek from 'images/Playertek.png';
import TemMotion from 'images/1080Motion.png';
import AIVideoAnalysie from 'images/AIVideoAnalysis.png';
import LearningContent from 'images/LearningContent.png';
import CatapultVector from 'images/CatapultVector.png';
import Whoop from 'images/Whoop.png';

function Integrations() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        We don{"'"}t just import data from other sources, we process it and add
                        value during the processing so the data is easier to understand and use.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Processing data at scale is our specialty.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}

                <FullScreenDialogCard
                    image={McLloydLogo}
                    imgPosition={'center'}
                    title="McLloyd GPS"
                    headline="McLloyd GPS"
                    description="Import raw GPS and session summary data.
                    We process the data and allow you to sprint crop any segment and also have scripts to automatically break out each sprint and analysie time segments."
                />
                <FullScreenDialogCard
                    image={Playertek}
                    imgPosition={'center'}
                    title="Catapult Playertek GPS"
                    headline="Catapult Playertek GPS"
                    description="Import raw GPS and session summary data.
                    We process the data and allow you to sprint crop any segment and also have scripts to automatically break out each sprint and analysie time segments."
                />
                <FullScreenDialogCard
                    image={TemMotion}
                    imgPosition={'center'}
                    title="1080 Motion"
                    headline="1080 Motion"
                    description="We have a direct API with 1080 motion that allows you all the capabilities of our GPS data processing."
                />
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        In-house integrations. These are integrations for data collection services
                        we provide directly in our application{' '}
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={AIVideoAnalysie}
                    title="AI Video Analysis"
                    headline="AI Video Analysis"
                    description="All you need is an iPhone. Upload a 60 fps video and we will process it and provide you with a report on the athlete's biometric sprint performance."
                />
                <FullScreenDialogCard
                    image={LearningContent}
                    title="Learning Content"
                    headline="Learning Management System"
                    description="Educate your coaches and athletes with our in-house learning managment system. Document their knowledge retention with scored exams"
                />
                <FullScreenDialogCard
                    image={SessionsList}
                    title="Fitness and Drills"
                    headline="Fitness and Drills"
                    description="Share Sessions inside and outside your organization via a link and
                                password or QR. Assign permissions through the link to limit access
                                and editing rights."
                />

                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Upcoming Integrations....{' '}
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={CatapultVector}
                    imgPosition={'center'}
                    title="Catapult Vector"
                    headline="Catapult Vector"
                    description="Catapult's premium product for teams."
                />
                <FullScreenDialogCard
                    image={Whoop}
                    imgPosition={'center'}
                    title="Whoop"
                    headline="Whoop"
                    description="Explore corelations between recovery and performance data."
                />
            </Grid>
        </>
    );
}

export default Integrations;
