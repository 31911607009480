import { Box, IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import React, { ReactElement } from 'react';

interface Props {
    src: string;
    alt: string;
    onDelete: () => void;
}

function ImageWithDelete({ src, alt, onDelete }: Props): ReactElement {
    return (
        <Box sx={{ position: 'relative', maxWidth: 300, maxHeight: 300 }}>
            <IconButton
                onClick={() => onDelete()}
                sx={{ position: 'absolute', top: 0, right: 0, zIndex: 5, padding: 0 }}
                size="large"
            >
                <Cancel />
            </IconButton>
            <img src={src} alt={alt} style={{ width: '100%', height: '100%' }} />
        </Box>
    );
}

export { ImageWithDelete };
