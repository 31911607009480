import React, { useContext, useEffect, useState } from 'react';
import { createGroup, getSignedUrl, uploadFile } from 'api';
import {
    EXIT_TEXT,
    Group,
    GroupType,
    SAVE_INFORMATION_TEXT,
    SAVE_TEXT,
    UNSAVED_CHANGES_TEXT,
    yupSchemas,
} from 'utils';
import { useFormik } from 'formik';
import {
    Avatar,
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { UploadLogo } from 'components/Functional';
import { AxiosError } from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { OrganizationsContext } from '../../contexts/OrganizationsContext';
import { ButtonWithLoader } from 'components/Buttons';
import { confirmViaDialog } from 'components/Dialogs';
import { useTheme } from '@mui/system';

export interface CreateGroupFormProps {
    /** onGroupCreated Group created callback */
    onGroupCreated: (group: Group) => void;
    /** onGroupCreationFailed Group creation failed */
    onGroupCreationFailed: (errorMessage: string) => void;
    /** groupType The group type that you're creating */
    groupType: GroupType;
    onClose?: Function;
}

const CreateGroupForm = ({
    onGroupCreated,
    onGroupCreationFailed,
    groupType = GroupType.INDIVIDUAL,
    onClose,
}: CreateGroupFormProps) => {
    const [loading, setLoading] = useState(false);
    const [uploadLogo, setUploadLogo] = useState<File>();
    const { organizations } = useContext(OrganizationsContext);
    const theme = useTheme();
    const initialOrganization =
        organizations && organizations.length > 0 ? organizations[0]?.name : '';

    const getGroupNameLabel = (type: GroupType): string => {
        if (type === GroupType.GROUP) return 'Enter Group Name...';
        if (type === GroupType.INDIVIDUAL) return "Athlete's Name";
        if (type === GroupType.TEAM) return 'Team Name';
        return '';
    };

    const getButtonText = (type: GroupType): string => {
        if (type === GroupType.GROUP) return 'Create Group';
        if (type === GroupType.INDIVIDUAL) return 'Add Athlete';
        if (type === GroupType.TEAM) return 'Create Team';
        return '';
    };

    const getImageName = (type: GroupType): 'logo' | 'profile' | 'cover photo' => {
        if (type === GroupType.GROUP) return 'cover photo';
        if (type === GroupType.INDIVIDUAL) return 'profile';
        if (type === GroupType.TEAM) return 'logo';
        return 'logo';
    };

    useEffect(() => {
        setFieldValue(
            'organization',
            organizations && organizations.length > 0 ? organizations[0].name : '',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizations]);

    const { handleSubmit, handleChange, values, errors, touched, isValid, dirty, setFieldValue } =
        useFormik({
            initialValues: yupSchemas.createGroup.getDefault(),
            validationSchema: yupSchemas.createGroup,
            onSubmit: async ({ groupName, organization }) => {
                setLoading(true);
                try {
                    if (uploadLogo) {
                        const response = await getSignedUrl();
                        if (response.status === 201) {
                            const res = await uploadFile(response.data.url, uploadLogo);
                            if (res.status === 200) {
                                const organizationLogo = response.data.key;
                                const formSubmitted = await createGroup(
                                    groupName,
                                    groupType,
                                    organization,
                                    organizationLogo,
                                );
                                if (formSubmitted.status === 200) {
                                    onGroupCreated(formSubmitted.data);
                                }
                            }
                        }
                    } else {
                        const response = await createGroup(groupName, groupType, organization);
                        if (response.status === 200) {
                            onGroupCreated(response.data);
                        }
                    }
                } catch (err: any) {
                    const error: AxiosError<{ message: string }> = err;
                    onGroupCreationFailed(error.message);
                } finally {
                    setLoading(false);
                }
            },
        });

    const onCancel = async () => {
        if (uploadLogo || values.groupName || initialOrganization != values.organization) {
            const res = await confirmViaDialog({
                confirmation: {
                    title: UNSAVED_CHANGES_TEXT,
                    message: SAVE_INFORMATION_TEXT,
                    cancelButtonText: EXIT_TEXT,
                    okButtonText: SAVE_TEXT,
                    onCancelPressed: () => onClose && onClose(),
                },
            });
            res && handleSubmit();
        } else onClose && onClose();
    };

    return (
        <>
            <div>
                <IconButton onClick={() => onCancel()} size="large">
                    <CloseIcon />
                </IconButton>
            </div>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    minWidth: 400,
                    backgroundColor: 'white',
                }}
            >
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'white',
                        flex: 1,
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <UploadLogo
                            uploadImage={uploadLogo}
                            setUploadImage={setUploadLogo}
                            loading={loading}
                            imageName={getImageName(groupType)}
                        />
                        <Box
                            sx={{
                                width: '85%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',

                                [theme.breakpoints.down(850)]: {
                                    width: '60%',
                                },
                                [theme.breakpoints.down('sm')]: {
                                    width: '90%',
                                },
                            }}
                        >
                            <TextField
                                sx={{ minWidth: '400', marginTop: theme.spacing(16) }}
                                name="groupName"
                                fullWidth
                                id="groupName"
                                label={getGroupNameLabel(groupType)}
                                variant="outlined"
                                onChange={handleChange}
                                value={values.groupName}
                                error={touched.groupName && Boolean(errors.groupName)}
                            />
                            {organizations && organizations.length > 0 && (
                                <FormControl
                                    fullWidth
                                    sx={{ minWidth: '400', marginTop: theme.spacing(16) }}
                                >
                                    <InputLabel id="demo-simple-select-label">
                                        Organization
                                    </InputLabel>
                                    <Select
                                        name="organization"
                                        id="organization"
                                        value={values.organization}
                                        onChange={handleChange}
                                        label="Organization"
                                    >
                                        {organizations.map((o) => {
                                            return (
                                                <MenuItem key={o.uuid} value={o.uuid}>
                                                    <Box
                                                        sx={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                        }}
                                                    >
                                                        <Avatar
                                                            src={o.image_urls['avatar'] ?? ''}
                                                            alt={o.name}
                                                            variant="rounded"
                                                            sx={{
                                                                marginRight: 6,
                                                                display: 'inline-flex',
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                display: 'inline-flex',
                                                            }}
                                                        >
                                                            {o.name}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            marginTop: theme.spacing(10),
                            padding: theme.spacing(12, 24),
                            justifyContent: 'flex-end',
                            borderTop: '1px solid #EBEBEB',

                            [theme.breakpoints.down('sm')]: {
                                padding: theme.spacing(8),
                            },
                        }}
                    >
                        <ButtonWithLoader
                            loading={loading}
                            color="primary"
                            variant="contained"
                            type="submit"
                            size="large"
                            disabled={!(isValid && dirty) || loading}
                        >
                            {getButtonText(groupType)}
                        </ButtonWithLoader>
                    </Box>
                </form>
            </Box>
        </>
    );
};

export default CreateGroupForm;
