import { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { GroupWorkout } from '../train.types';

export const createWorkout = (
    group: string,
    session: string,
    members: string[],
    params = {},
): Promise<AxiosResponse<GroupWorkout>> => {
    return Request.post(`/workouts/group-workouts`, {
        group,
        session,
        members,
        ...params,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
};

export const viewGroupWorkout = (id: string): Promise<AxiosResponse<GroupWorkout>> => {
    return Request.get(`/workouts/group-workouts/${id}`);
};

export const updateGroupWorkout = (
    id: string,
    payload: { date: string },
): Promise<AxiosResponse<GroupWorkout>> => {
    return Request.patch(`/workouts/group-workouts/${id}`, {
        date: payload.date,
        date_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
};

export const saveGroupWorkoutRow = (id: string, row = {}): Promise<AxiosResponse<GroupWorkout>> => {
    return Request.post(`/workouts/group-workouts/${id}/save-row`, {
        row,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
};

export const submitGroupWorkout = (id: string): Promise<AxiosResponse<GroupWorkout>> => {
    return Request.post(`/workouts/group-workouts/${id}/submit`, {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
};
