export function displayTimeInMmSS(seconds: number): string {
    const isoDate = new Date(seconds * 1000).toISOString();
    if (seconds < 60) {
        return `${isoDate.slice(17, 23)}s`;
    }
    if (seconds < 60 * 60) {
        return isoDate.slice(14, 19);
    }
    return `${isoDate.slice(11, 19)}`;
}

export function getArrayOfStringsFromCubeFilter(filter?: any): string[] {
    if (!filter) {
        return [];
    }

    if (filter.values) {
        return filter.values;
    }

    return [];
}
