import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    AvatarGroup,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { Can, CustomButton } from 'components';
import { AvatarWithInitials } from 'components/Icons';
import { useHistory } from 'react-router-dom';
import ROUTES from 'Routes/routes';

import { Group, useToggle } from 'utils';
import { GroupMemberCountChip } from './';

interface GroupCardProps {
    group: Group;
    isGroupsPath: boolean;
}

const MuiAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    '&.MuiAccordionSummary-root': {
        '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: theme.spacing(0),
            padding: theme.spacing(6, 0, 6, 8),
            color: theme.palette.text.secondary,
        },
        '& .MuiAccordionSummary-expandIcon': {
            marginRight: theme.spacing(1),
        },
    },
}));
const GroupCard = ({ group, isGroupsPath }: GroupCardProps) => {
    const { push } = useHistory();
    const [expanded, toggleExpanded] = useToggle(false);
    const theme = useTheme();
    return (
        <Accordion
            sx={{ width: '100%' }}
            expanded={expanded && isGroupsPath}
            onChange={() => isGroupsPath && toggleExpanded()}
        >
            <MuiAccordionSummary expandIcon={isGroupsPath && <ExpandMoreIcon />}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        src={group.image_urls.avatar || ''}
                        sx={{ width: '80px', height: '80px' }}
                        variant="rounded"
                    />
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            flexDirection: 'column',
                            marginLeft: theme.spacing(8),
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 800,
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: theme.palette.text.primary,
                                marginLeft: 5,
                            }}
                        >
                            {group.name}
                        </Typography>
                        {isGroupsPath && <GroupMemberCountChip group={group} />}
                    </div>
                </div>
                {group.organization && (
                    <Avatar
                        variant="rounded"
                        sx={{ height: '35px', width: '35px', marginLeft: theme.spacing(8) }}
                        src={group.organization.image_urls.avatar ?? ''}
                    />
                )}
            </MuiAccordionSummary>
            <AccordionDetails
                sx={{ display: 'flex', flexDirection: 'column', paddingLeft: theme.spacing(8) }}
            >
                <AvatarGroup variant="rounded" max={5} total={group.members_count}>
                    {group.members_sample &&
                        group.members_sample?.map((member, index) => (
                            <AvatarWithInitials
                                key={index}
                                src={member.image_urls?.avatar ?? ''}
                                firstname={member.first_name}
                                lastname={member.last_name}
                                sx={{ width: '80px', height: '80px' }}
                            />
                        ))}
                </AvatarGroup>
            </AccordionDetails>
            <AccordionActions
                sx={{ justifyContent: 'flex-start', paddingLeft: theme.spacing(6), width: '100%' }}
            >
                {isGroupsPath && (
                    <>
                        <Can I={'group:view-members'} this={group}>
                            <CustomButton
                                onClick={() =>
                                    push(ROUTES.GroupDetails.path.replace(':id', group.uuid))
                                }
                                variant="text"
                                color="primary"
                            >
                                Details
                            </CustomButton>
                        </Can>
                        <Can I={'group:manage-logs'} this={group}>
                            <CustomButton
                                onClick={() => push(`/community/groups/${group.uuid}/logs`)}
                                variant="text"
                                color="primary"
                            >
                                Logs
                            </CustomButton>
                        </Can>
                        <Can I={'group:manage-logs'} this={group}>
                            <CustomButton
                                onClick={() => push(`/community/groups/${group.uuid}/gps-summary`)}
                                variant="text"
                                color="primary"
                            >
                                GPS Sessions
                            </CustomButton>
                        </Can>
                        <Can I={'group:manage-logs'} this={group}>
                            <CustomButton
                                onClick={() => push(`/community/groups/${group.uuid}/gps-upload`)}
                                variant="text"
                                color="primary"
                            >
                                Upload GPS Raw Data
                            </CustomButton>
                        </Can>
                    </>
                )}
            </AccordionActions>
        </Accordion>
    );
};

export default GroupCard;
