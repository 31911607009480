import { Chip, ChipProps } from '@mui/material';
import { useTheme } from '@mui/system';

const AttributeChip = (props: ChipProps) => {
    const theme = useTheme();
    return (
        <Chip
            sx={{
                fontSize: 12,
                lineHeight: '166%',
                letterSpacing: '0.4px',
                marginRight: theme.spacing(3),
                background:
                    'linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF',
                height: '28px',
                borderRadius: '8px',
            }}
            {...props}
        />
    );
};

export default AttributeChip;
