import {
    Chip,
    Container,
    Divider,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { TypographyTitle } from '../../../components';
import EmptyStateWithDashedBorder from '../../../components/Feedback/EmptyStates/EmptyStateWithDashedBorder';
import { Group } from '../../../utils';
import { GroupWorkout } from '../../train/train.types';
import { getGroupWorkouts, viewGroup } from '../api/groups.api';

function GroupWorkouts() {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [workouts, setWorkouts] = useState<Array<GroupWorkout>>([]);
    const [group, setGroup] = useState<Group>();
    const { id } = useParams<{ id: string }>();
    const { push } = useHistory();

    useEffect(() => {
        setLoading(true);
        Promise.all([viewGroup(id), getGroupWorkouts(id)])
            .then(([groupResponse, response]) => {
                setLoaded(true);
                setWorkouts(response.data);
                setGroup(groupResponse.data);
            })
            .catch(() => {})
            .finally(() => {});
        getGroupWorkouts(id)
            .then((response) => {
                setLoaded(true);
                setWorkouts(response.data);
            })
            .finally(() => setLoading(false));
    }, [id]);

    return (
        <>
            {loading && <LinearProgress />}
            <Container maxWidth={'xl'} style={{ paddingTop: 16 }}>
                {loaded && group && (
                    <>
                        <TypographyTitle>Group Logs For {group.name}</TypographyTitle>{' '}
                        {workouts.length === 0 && (
                            <EmptyStateWithDashedBorder
                                message={'No logs for this group. Click here to go to sessions'}
                                onClick={() => push('/train/sessions')}
                            />
                        )}
                        {workouts.length > 0 && (
                            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                {workouts.map((workout) => (
                                    <>
                                        <ListItem disablePadding key={workout.uuid}>
                                            <ListItemButton
                                                dense
                                                onClick={() => {
                                                    push(
                                                        '/train/logging/group-workouts/:groupWorkoutId'.replace(
                                                            ':groupWorkoutId',
                                                            workout.uuid,
                                                        ),
                                                    );
                                                }}
                                            >
                                                <ListItemText
                                                    primary={workout.session.title}
                                                    secondary={
                                                        workout.date
                                                            ? moment(workout.date * 1000).format(
                                                                  'lll',
                                                              )
                                                            : 'No Date'
                                                    }
                                                />
                                                {!workout.submitted_at && (
                                                    <Chip
                                                        label={'In Progress'}
                                                        color={'secondary'}
                                                    />
                                                )}
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider component="li" />
                                    </>
                                ))}
                            </List>
                        )}
                    </>
                )}
            </Container>
        </>
    );
}

export default GroupWorkouts;
