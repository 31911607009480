import * as yup from 'yup';

export const resetPassword = yup.object({
    user_name: yup.string().default('').required('Username is required'),
    password: yup
        .string()
        .default('')
        .required('Password is required')
        .min(8, 'Must be at least 8 characters')
        .matches(/[A-Z]/, 'Must contain at least 1 uppercase')
        .matches(/[a-z]/, 'Must contain at least 1 lowercase')
        .matches(/[\d@#$%^&*(),.?":{}|<>]/, 'Must contain a special character or number'),
    password_confirmation: yup
        .string()
        .required('Password confirmation is required')
        .oneOf([yup.ref('password')], "Doesn't match password"),
});

export type ResetPasswordPayload = yup.InferType<typeof resetPassword>;
