import { Dialog, Fade, IconButton } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { Asset, getThumbnailFromVersions, getVideoSourcesFromVersions } from 'utils';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Player } from 'components/Functional/Player';

interface Props {
    open: boolean;
    asset: Asset;
    onClose: () => void;
}

export default function ViewAssetModal({ open, asset, onClose }: Props): ReactElement {
    const [mouseMoveTimeout, setMouseMoveTimeout] = useState<number>(-1);
    const [showClose, setShowClose] = useState<boolean>(false);

    const onMouseMove = () => {
        if (!showClose) {
            setShowClose(true);
        }

        if (mouseMoveTimeout) {
            window.clearTimeout(mouseMoveTimeout);
        }

        setMouseMoveTimeout(
            window.setTimeout(() => {
                setShowClose(false);
            }, 2500),
        );
    };

    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('touchmove', onMouseMove);
        window.addEventListener('touchend', onMouseMove);

        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('touchmove', onMouseMove);
            window.removeEventListener('touchend', onMouseMove);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Dialog open={open} fullScreen>
            <Fade in={showClose}>
                <IconButton
                    onClick={onClose}
                    aria-label="delete"
                    sx={{
                        top: 8,
                        left: 8,
                        width: 64,
                        height: 64,
                        position: 'absolute',
                        color: 'white',
                        zIndex: 10,
                    }}
                    size="large"
                >
                    <HighlightOffIcon />
                </IconButton>
            </Fade>
            {getVideoSourcesFromVersions(asset.versions).length > 0 && (
                <Player
                    size={{ fill: true }}
                    classesSx={{ height: '100vh' }}
                    poster={getThumbnailFromVersions(asset.versions)}
                    sources={getVideoSourcesFromVersions(asset.versions)}
                    cookiesToSet={asset.versions.filter((v) => !!v.cookie)[0]?.cookie || {}}
                />
            )}
        </Dialog>
    );
}
