import { Exploration, ExplorationFilters } from '../explore.types';
import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Divider,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { convertApiFiltersToExplorationFilters } from '../explore.func';
import cubejs, { CubejsApi } from '@cubejs-client/core';
import { getCubeToken } from '../../../api/charts.api';
import LoadingWithText from './LoadingWithText';
import { grey } from '@mui/material/colors';

interface Props {
    exploration: Exploration;
}

type LeaderboardEntry = { [key: string]: string | number | boolean };

const ExplorationGpsSummaryLeaderboard = ({ exploration }: Props) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [leaderboardData, setLeaderboardData] = useState<LeaderboardEntry[]>();
    const [filters, setFilters] = useState<ExplorationFilters>(
        convertApiFiltersToExplorationFilters(exploration.filters),
    );
    const [api, setApi] = useState<CubejsApi>();

    useEffect(() => {
        setApi(
            cubejs(
                () =>
                    getCubeToken({
                        exploration: exploration.uuid,
                    }).then((r) => r.data.token),
                {
                    apiUrl: `${process.env.REACT_APP_CUBE_URL}/cubejs-api/v1`,
                },
            ),
        );
        setFilters(convertApiFiltersToExplorationFilters(exploration.filters));
    }, [exploration]);
    useEffect(() => {
        if (!api) {
            return;
        }
        setLoading(true);
        api.load({
            dimensions: filters.dimensions,
            measures: filters.measures,
        })
            .then((leaderboardResponse) => {
                setLeaderboardData(leaderboardResponse.tablePivot());
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, [filters, api]);

    return (
        <>
            <Avatar
                variant={'rounded'}
                sx={{ margin: 'auto', width: 100, height: 100 }}
                src={exploration.organization.logo_url ?? ''}
            />
            <Typography variant={'h4'} align={'center'}>
                {exploration.title}
            </Typography>
            <Typography variant={'h5'} align={'center'}>
                {filters.measures &&
                    filters.measures.indexOf('GpsSummary.maxDistance') > -1 &&
                    'Total Distance'}
                {filters.measures &&
                    filters.measures.indexOf('GpsSummary.maxSpeed') > -1 &&
                    'Top Speed'}
                {filters.measures &&
                    filters.measures.indexOf('GpsSummary.maxNumberOfSprints') > -1 &&
                    'Max Number Of Sprints'}
                {filters.measures &&
                    filters.measures.indexOf('GpsSummary.numberOfSprints') > -1 &&
                    'Total Number Of Sprints'}
            </Typography>
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LoadingWithText text={'Loading Leaderboard'} />
                </Box>
            )}
            {leaderboardData?.length === 0 && !loading && (
                <Stack sx={{ justifyContent: 'center', alignItems: 'center' }} spacing={8}>
                    <LocationOffIcon sx={{ fontSize: 100, color: grey[500] }} />
                    <Typography>No GPS summary data for the specified date range</Typography>
                </Stack>
            )}
            <TableContainer sx={{ background: theme.palette.primary.main }} component={Paper}>
                <Table>
                    <TableBody>
                        {leaderboardData?.map((d, i) => (
                            <>
                                <TableRow
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)', // Lighter color on hover
                                            boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)', // Increased drop shadow
                                        },
                                    }}
                                    key={d['GpsSummary.athleteName'] as string}
                                >
                                    <TableCell sx={{ color: '#fff' }}>{i + 1}</TableCell>
                                    <TableCell sx={{ color: '#fff', fontSize: 20 }}>
                                        {`${d['GpsSummary.athleteName']}`}
                                    </TableCell>
                                    <TableCell sx={{ fontSize: 24, color: '#fff' }}>
                                        {d['GpsSummary.maxDistance'] && d['GpsSummary.maxDistance']}
                                        {d['GpsSummary.maxSpeed'] && d['GpsSummary.maxSpeed']}
                                        {d['GpsSummary.maxNumberOfSprints'] &&
                                            d['GpsSummary.maxNumberOfSprints']}
                                        {d['GpsSummary.numberOfSprints'] &&
                                            d['GpsSummary.numberOfSprints']}
                                        {d['GpsSummary.maxSpeed'] && <> MPH</>}
                                        {d['GpsSummary.maxDistance'] && <> FT</>}
                                    </TableCell>
                                </TableRow>
                                <Divider
                                    sx={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Lighter divider
                                    }}
                                />
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ExplorationGpsSummaryLeaderboard;
