import { Link } from '@mui/material';
import { styled } from '@mui/system';

const TextButton = styled(Link)({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    color: '#003E9D',
    textTransform: 'none',
    letterSpacing: 0.15,
    cursor: 'pointer',
});

export default TextButton;
