import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import { Group, Individual, SearchedGroup, SearchToken, somethingWentWrong } from 'utils';
import { Close } from '@mui/icons-material';
import { getSearchToken, search } from 'api/Search';
import { AlgoliaIndex } from 'api/Search/algoliaSearch';
import { viewIndividual } from 'api';

export interface MemberSelectionDialogProps {
    open: boolean;
    onCancel: () => void;
    onIndividualSelected: (individual: Individual) => void;
    onNewButtonpressed: () => void;
    organizationUuid?: string | undefined;
    group: Group;
}

export default function MemberSelectionDialog({
    open,
    onCancel,
    organizationUuid,
    onIndividualSelected,
    onNewButtonpressed,
    group,
}: MemberSelectionDialogProps): ReactElement {
    const theme = useTheme();
    const [loading, setLoading] = useState<boolean>(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [individuals, setIndividuals] = useState<SearchedGroup[]>([]);
    const [searchToken, setSearchToken] = useState<SearchToken | null>(null);

    const debouncedSearchQuery = React.useRef(
        debounce((value: string) => {
            setSearchQuery(value);
        }, 100),
    ).current;

    const searchAlgolia = useCallback(
        (query: any) => {
            if (searchToken) {
                search(AlgoliaIndex.Individual, {
                    searchQuery: query,
                    searchToken,
                    filters: organizationUuid ? [`organizationId:${organizationUuid}`] : [],
                })
                    .then((response) => {
                        if (response.data) {
                            setIndividuals(
                                response.data.hits.map((hit) => ({
                                    ...hit,
                                    groupIds: hit.groupIds ?? [],
                                })),
                            );
                        }
                    })
                    .catch((error) => {
                        somethingWentWrong(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [searchToken, organizationUuid],
    );

    useEffect(() => {
        searchAlgolia(searchQuery);
    }, [searchQuery, searchAlgolia]);

    // When an individual group is selected, we have `SearchedGroup` instance
    // which has uuid and name
    const onExistingIndividualSelected = (selectedGroup: SearchedGroup) => {
        // Get the selected group details
        viewIndividual(selectedGroup.id)
            .then((response) => {
                onIndividualSelected(response.data);
                clearResults();
            })
            .catch(() => somethingWentWrong());
    };

    const clearResults = () => {
        setSearchQuery('');
        setIndividuals([]);
        setLoading(false);
    };

    // The algolia search has permissions (visible by filters) which handle what data a user can see.
    // So to always have fresh permissions, we will request a new token when the component is initialized on the page.
    useEffect(() => {
        if (!searchToken) {
            getSearchToken()
                .then((response) => {
                    setSearchToken(response.data as SearchToken);
                })
                .catch(() => somethingWentWrong());
        }
    }, [searchToken]);

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={() => {}}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Typography variant="h6">Choose member</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            clearResults();
                            onCancel();
                        }}
                        sx={{
                            position: 'absolute',
                            right: theme.spacing(4),
                            top: theme.spacing(4),
                            color: theme.palette.grey[500],
                        }}
                        size="large"
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        minHeight: theme.spacing(300),
                        [theme.breakpoints.up('md')]: {
                            minWidth: 600,
                        },
                    }}
                >
                    <>
                        <TextField
                            fullWidth
                            placeholder="Start typing here..."
                            onChange={(e) => debouncedSearchQuery(e.target.value as string)}
                        />
                    </>
                    {searchToken ? (
                        <Box
                            textAlign="center"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            style={{ gap: 10 }}
                        >
                            {loading && (
                                <Box
                                    mt={20}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    style={{ gap: 10 }}
                                >
                                    <CircularProgress />
                                    <Typography variant="caption">
                                        Finding matching members...
                                    </Typography>
                                </Box>
                            )}

                            {individuals.length > 0 && !loading && (
                                <div style={{ marginTop: theme.spacing(5), width: '100%' }}>
                                    <Typography
                                        variant="caption"
                                        align="center"
                                        color="textSecondary"
                                    >
                                        Showing {individuals.length} matching{' '}
                                        {individuals.length > 1 ? 'members' : 'member'}
                                    </Typography>
                                    <List
                                        dense
                                        sx={{
                                            width: '100%',
                                            height: theme.spacing(200),
                                            maxHeight: theme.spacing(200),
                                            overflowX: 'hidden',
                                            overflowY: 'scroll',
                                        }}
                                    >
                                        {individuals.map((individual: SearchedGroup) => {
                                            return (
                                                <ListItem key={individual.id} disableGutters>
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            alt={individual.name}
                                                            src={individual.objectAvatarPhoto || ''}
                                                            variant="rounded"
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText>
                                                        <Typography variant="body2">
                                                            {individual.name}
                                                        </Typography>
                                                        {!isEmpty(individual.organizationName) && (
                                                            <Typography
                                                                variant="caption"
                                                                color="textSecondary"
                                                            >
                                                                {individual.organizationName}
                                                            </Typography>
                                                        )}
                                                    </ListItemText>
                                                    <ListItemSecondaryAction sx={{ right: 0 }}>
                                                        {individual.groupIds.indexOf(group.uuid) ===
                                                            -1 && (
                                                            <Button
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    onExistingIndividualSelected(
                                                                        individual,
                                                                    );
                                                                }}
                                                                color="primary"
                                                                size="small"
                                                            >
                                                                Select
                                                            </Button>
                                                        )}
                                                        {individual.groupIds?.indexOf(group.uuid) >
                                                            -1 && <span>Already a member</span>}
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </div>
                            )}
                            {!isEmpty(searchQuery) && individuals.length == 0 && !loading && (
                                <Box fontStyle="italic" pt={20} pb={20}>
                                    <Typography align="center" variant="body2">
                                        Sorry, No members matched your search.
                                    </Typography>
                                </Box>
                            )}
                            {!isEmpty(searchQuery) && !loading && (
                                <>
                                    <Divider
                                        variant="fullWidth"
                                        component="div"
                                        style={{ width: '100%' }}
                                    />
                                    <Box fontStyle="italic">
                                        <Typography align="center" variant="body2">
                                            Can&apos;t find the member you are looking for?
                                        </Typography>
                                    </Box>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            onNewButtonpressed();
                                            clearResults();
                                        }}
                                    >
                                        Create a member
                                    </Button>
                                </>
                            )}
                        </Box>
                    ) : (
                        <Box pt={20} display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
        </>
    );
}
