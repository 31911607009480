import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import KpiProgressBar from './KpiProgressBar';
import React from 'react';
import { IndividualKpis } from '../community.types';
import { Link } from 'react-router-dom';
import { DateRangeValue } from '../../../app.types';
import { DateTime } from 'luxon';
import InfoIcon from '@mui/icons-material/Info';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import key from '../../../images/key.png';
import { Individual } from '../../../utils';

interface Props {
    kpis: IndividualKpis;
    onDrilldownClick: (range: DateRangeValue) => void;
    onMaxVelocityClick: (range: DateRangeValue) => void;
    isLoadingKpis: boolean;
    fullname: string;
    individual: Individual;
}

const IndividualKpisCard = (props: Props) => {
    const { kpis, isLoadingKpis } = props;
    const { fullname } = props;
    const theme = useTheme();
    return (
        <Card>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: theme.spacing(6, 8, 2),
                }}
            >
                <img
                    src={key}
                    alt="Icon"
                    style={{ marginRight: theme.spacing(2), width: 75, height: 75 }}
                />
                <Typography
                    sx={{
                        fontSize: 20,
                        fontWeight: 500,
                        padding: theme.spacing(6, 8, 2),
                        textAlign: 'center',
                    }}
                >
                    <strong> {fullname}&apos;s </strong>Key Performance Indicators
                </Typography>
            </Box>
            <Divider
                sx={{ width: '100%', color: 'black', marginTop: 4, marginBottom: 4 }}
            ></Divider>
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 500,
                            }}
                            align={'center'}
                        >
                            Max Speed
                        </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Stack direction={'column'} alignItems={'center'}>
                                <Typography>Last Year</Typography>
                                {isLoadingKpis ? ( // isLoadingLastYearKpi is a boolean indicating loading state
                                    <CircularProgress size={24} /> // Display CircularProgress when data is loading
                                ) : (
                                    <Link
                                        to="#"
                                        onClick={() => {
                                            props.onMaxVelocityClick('from one year ago to now');
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 300,
                                            }}
                                        >
                                            {kpis.maxSpeeds?.last52Weeks?.toFixed(1) ?? '-'}
                                            mph
                                        </Typography>
                                    </Link>
                                )}
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack direction={'column'} alignItems={'center'}>
                                <Typography>Last 12 Weeks</Typography>
                                {isLoadingKpis ? ( // isLoadingLastYearKpi is a boolean indicating loading state
                                    <CircularProgress size={24} /> // Display CircularProgress when data is loading
                                ) : (
                                    <Link
                                        to="#"
                                        onClick={() => {
                                            props.onMaxVelocityClick([
                                                DateTime.now()
                                                    .minus({ weeks: 12 })
                                                    .startOf('day')
                                                    .toISODate(),
                                                DateTime.now().endOf('day').toISODate(),
                                            ]);
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 300,
                                            }}
                                        >
                                            {kpis.maxSpeeds?.last12Weeks?.toFixed(1) ?? '-'}
                                            mph
                                        </Typography>
                                    </Link>
                                )}
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack direction={'column'} alignItems={'center'}>
                                <Typography>Last 6 Weeks</Typography>
                                {isLoadingKpis ? ( // isLoadingLastYearKpi is a boolean indicating loading state
                                    <CircularProgress size={24} /> // Display CircularProgress when data is loading
                                ) : (
                                    <Link
                                        to="#"
                                        onClick={() => {
                                            props.onMaxVelocityClick([
                                                DateTime.now()
                                                    .minus({ weeks: 6 })
                                                    .startOf('day')
                                                    .toISODate(),
                                                DateTime.now().endOf('day').toISODate(),
                                            ]);
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 300,
                                            }}
                                        >
                                            {kpis.maxSpeeds?.last6Weeks?.toFixed(1) ?? '-'}
                                            mph
                                        </Typography>
                                    </Link>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack direction={'column'} alignItems={'center'}>
                                <Typography>Last Week</Typography>
                                {isLoadingKpis ? ( // isLoadingLastYearKpi is a boolean indicating loading state
                                    <CircularProgress size={24} /> // Display CircularProgress when data is loading
                                ) : (
                                    <Link
                                        to="#"
                                        onClick={() => {
                                            props.onMaxVelocityClick([
                                                DateTime.now()
                                                    .minus({ weeks: 1 })
                                                    .startOf('day')
                                                    .toISODate(),
                                                DateTime.now().endOf('day').toISODate(),
                                            ]);
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 12,
                                                fontWeight: 300,
                                            }}
                                        >
                                            {kpis.maxSpeeds?.thisWeek?.toFixed(1) ?? '-'}
                                            mph
                                        </Typography>
                                    </Link>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{ width: '100%', color: 'black', marginTop: 4, marginBottom: 4 }}
                    ></Divider>
                    <Grid item xs={12} alignItems={'center'}>
                        <Typography
                            align={'center'}
                            sx={{
                                fontSize: 18,
                                fontWeight: 500,
                                marginBottom: 3,
                            }}
                        >
                            High Speed{' '}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        spacing={4}
                        justifyContent={'space-around'}
                        alignItems={'center'}
                    >
                        <Grid item xs={6} alignItems={'center'}>
                            <Grid
                                container
                                spacing={4}
                                justifyContent={'space-around'}
                                alignItems={'center'}
                            >
                                <Grid item xs={12}>
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                fontWeight: 500,
                                            }}
                                            align={'center'}
                                        >
                                            Days
                                        </Typography>
                                        <Tooltip
                                            title={
                                                'The number of days in which the highest speed recorded was 90% or more of the highest speed recorded in the past 3 months.'
                                            }
                                        >
                                            <InfoIcon
                                                fontSize="small"
                                                sx={{ marginLeft: 3, color: 'gray' }}
                                            />
                                        </Tooltip>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} alignItems={'center'}>
                                    <Stack
                                        alignItems={'center'}
                                        direction={'row'}
                                        justifyContent={'center'}
                                    >
                                        <Typography>Last Week</Typography>
                                        <IconButton
                                            onClick={() =>
                                                props.onDrilldownClick([
                                                    DateTime.now()
                                                        .minus({ weeks: 1 })
                                                        .startOf('day')
                                                        .toISODate(),
                                                    DateTime.now().endOf('day').toISODate(),
                                                ])
                                            }
                                            size={'small'}
                                        >
                                            <RemoveRedEyeTwoToneIcon fontSize={'small'} />
                                        </IconButton>
                                    </Stack>
                                    <Box
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {kpis.highSpeedDays && (
                                            <KpiProgressBar
                                                value={kpis.highSpeedDays?.thisWeek ?? 0}
                                                max={kpis.totalActiveDays?.thisWeek ?? 0}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack
                                        alignItems={'center'}
                                        direction={'row'}
                                        justifyContent={'center'}
                                    >
                                        <Typography>Last 6 Weeks </Typography>
                                        <IconButton
                                            onClick={() =>
                                                props.onDrilldownClick([
                                                    DateTime.now()
                                                        .minus({ weeks: 6 })
                                                        .startOf('day')
                                                        .toISODate(),
                                                    DateTime.now().endOf('day').toISODate(),
                                                ])
                                            }
                                            size={'small'}
                                        >
                                            <RemoveRedEyeTwoToneIcon fontSize={'small'} />
                                        </IconButton>
                                    </Stack>
                                    <Box
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {kpis.highSpeedDays && (
                                            <KpiProgressBar
                                                value={kpis.highSpeedDays?.last6Weeks ?? 0}
                                                max={kpis.totalActiveDays?.last6Weeks ?? 0}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack
                                        alignItems={'center'}
                                        direction={'row'}
                                        justifyContent={'center'}
                                    >
                                        <Typography>Last 12 Weeks </Typography>
                                        <IconButton
                                            onClick={() =>
                                                props.onDrilldownClick([
                                                    DateTime.now()
                                                        .minus({ weeks: 12 })
                                                        .startOf('day')
                                                        .toISODate(),
                                                    DateTime.now().endOf('day').toISODate(),
                                                ])
                                            }
                                            size={'small'}
                                        >
                                            <RemoveRedEyeTwoToneIcon fontSize={'small'} />
                                        </IconButton>
                                    </Stack>
                                    <Box
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {kpis.highSpeedDays && (
                                            <KpiProgressBar
                                                value={kpis.highSpeedDays?.last12Weeks ?? 0}
                                                max={kpis.totalActiveDays?.last12Weeks ?? 0}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack
                                        alignItems={'center'}
                                        direction={'row'}
                                        justifyContent={'center'}
                                    >
                                        <Typography>Last Year </Typography>
                                        <IconButton
                                            onClick={() =>
                                                props.onDrilldownClick([
                                                    DateTime.now()
                                                        .minus({ years: 1 })
                                                        .startOf('day')
                                                        .toISODate(),
                                                    DateTime.now().endOf('day').toISODate(),
                                                ])
                                            }
                                            size={'small'}
                                        >
                                            <RemoveRedEyeTwoToneIcon fontSize={'small'} />
                                        </IconButton>
                                    </Stack>
                                    <Box
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {kpis.highSpeedDays && (
                                            <KpiProgressBar
                                                value={kpis.highSpeedDays?.last52Weeks ?? 0}
                                                max={kpis.totalActiveDays?.last52Weeks ?? 0}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            spacing={4}
                            alignItems={'center'}
                            justifyContent={'space-around'}
                        >
                            {' '}
                            {/* This grid takes up half the width */}
                            <Grid
                                container
                                spacing={4}
                                alignItems={'center'}
                                justifyContent={'space-around'}
                            >
                                <Grid item xs={12}>
                                    <Stack
                                        direction={'row'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 18,
                                                fontWeight: 500,
                                            }}
                                        >
                                            Runs
                                        </Typography>
                                        <Tooltip
                                            title={
                                                'The number of runs that reached 90% of the highest speed recorded in the past 3 months.'
                                            }
                                        >
                                            <InfoIcon
                                                fontSize="small"
                                                sx={{ marginLeft: 3, color: 'gray' }}
                                            />
                                        </Tooltip>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack
                                        alignItems={'center'}
                                        direction={'row'}
                                        justifyContent={'center'}
                                    >
                                        <Typography>Last Week</Typography>
                                        <IconButton
                                            onClick={() =>
                                                props.onDrilldownClick([
                                                    DateTime.now()
                                                        .minus({ weeks: 1 })
                                                        .startOf('day')
                                                        .toISODate(),
                                                    DateTime.now().endOf('day').toISODate(),
                                                ])
                                            }
                                            size={'small'}
                                        >
                                            <RemoveRedEyeTwoToneIcon fontSize={'small'} />
                                        </IconButton>
                                    </Stack>
                                    <Box
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {kpis.totalSprints && kpis.highSpeedSprints && (
                                            <KpiProgressBar
                                                value={kpis.highSpeedSprints?.thisWeek ?? 0}
                                                max={kpis.totalSprints?.thisWeek ?? 0}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack
                                        alignItems={'center'}
                                        direction={'row'}
                                        justifyContent={'center'}
                                    >
                                        <Typography>Last 6 Weeks</Typography>
                                        <IconButton
                                            onClick={() =>
                                                props.onDrilldownClick([
                                                    DateTime.now()
                                                        .minus({ weeks: 6 })
                                                        .startOf('day')
                                                        .toISODate(),
                                                    DateTime.now().endOf('day').toISODate(),
                                                ])
                                            }
                                            size={'small'}
                                        >
                                            <RemoveRedEyeTwoToneIcon fontSize={'small'} />
                                        </IconButton>
                                    </Stack>
                                    <Box
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {kpis.totalSprints && kpis.highSpeedSprints && (
                                            <KpiProgressBar
                                                value={kpis.highSpeedSprints?.last6Weeks ?? 0}
                                                max={kpis.totalSprints?.last6Weeks ?? 0}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack
                                        alignItems={'center'}
                                        direction={'row'}
                                        justifyContent={'center'}
                                    >
                                        <Typography>Last 12 Weeks</Typography>
                                        <IconButton
                                            onClick={() =>
                                                props.onDrilldownClick([
                                                    DateTime.now()
                                                        .minus({ weeks: 12 })
                                                        .startOf('day')
                                                        .toISODate(),
                                                    DateTime.now().endOf('day').toISODate(),
                                                ])
                                            }
                                            size={'small'}
                                        >
                                            <RemoveRedEyeTwoToneIcon fontSize={'small'} />
                                        </IconButton>
                                    </Stack>
                                    <Box
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {kpis.totalSprints && kpis.highSpeedSprints && (
                                            <KpiProgressBar
                                                value={kpis.highSpeedSprints?.last12Weeks ?? 0}
                                                max={kpis.totalSprints?.last12Weeks ?? 0}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack
                                        alignItems={'center'}
                                        direction={'row'}
                                        justifyContent={'center'}
                                    >
                                        <Typography>Last Year</Typography>
                                        <IconButton
                                            onClick={() =>
                                                props.onDrilldownClick([
                                                    DateTime.now()
                                                        .minus({ years: 1 })
                                                        .startOf('day')
                                                        .toISODate(),
                                                    DateTime.now().endOf('day').toISODate(),
                                                ])
                                            }
                                            size={'small'}
                                        >
                                            <RemoveRedEyeTwoToneIcon fontSize={'small'} />
                                        </IconButton>
                                    </Stack>
                                    <Box
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: 300,
                                        }}
                                    >
                                        {kpis.totalSprints && kpis.highSpeedSprints && (
                                            <KpiProgressBar
                                                value={kpis.highSpeedSprints?.last52Weeks ?? 0}
                                                max={kpis.totalSprints?.last52Weeks ?? 0}
                                            />
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider
                        sx={{ width: '100%', color: 'black', marginTop: 4, marginBottom: 4 }}
                    ></Divider>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: 18,
                                fontWeight: 500,
                            }}
                            align={'center'}
                        >
                            Best Split Times (0-1 kg Resistance)
                        </Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'center'}>Yds</TableCell>
                                    <TableCell align="center">Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {kpis.splitTimes?.map((split, index) => (
                                    <TableRow key={index}>
                                        <TableCell align={'center'} component="th" scope="row">
                                            {split['DimSplit.splitDistanceName']}
                                        </TableCell>
                                        <TableCell align="center">
                                            {(+split['FactSprintSplits.minTime']).toFixed(2) ?? '-'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            spacing={2}
                        >
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 500,
                                }}
                                align={'center'}
                            >
                                Training Sessions Logged
                            </Typography>
                            <Tooltip
                                title={
                                    'Training Sessions Logged only includes the number of train sessions logged in the app, not the number of times an athlete recorded GPS or 1080 sprints.'
                                }
                            >
                                <InfoIcon fontSize="small" sx={{ marginLeft: 3, color: 'gray' }} />
                            </Tooltip>
                        </Stack>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction={'column'} alignItems={'center'}>
                                    <Typography>Past 7 Days</Typography>
                                    {isLoadingKpis ? ( // isLoadingLastYearKpi is a boolean indicating loading state
                                        <CircularProgress size={24} /> // Display CircularProgress when data is loading
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {kpis.logsSubmitted?.thisWeek ?? '-'}
                                        </Typography>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction={'column'} alignItems={'center'}>
                                    <Typography>8-14 Days Ago</Typography>
                                    {isLoadingKpis ? ( // isLoadingLastYearKpi is a boolean indicating loading state
                                        <CircularProgress size={24} /> // Display CircularProgress when data is loading
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {kpis.logsSubmitted?.previousWeek ?? '-'}
                                        </Typography>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction={'column'} alignItems={'center'}>
                                    <Typography>Past 4 Weeks</Typography>
                                    {isLoadingKpis ? ( // isLoadingLastYearKpi is a boolean indicating loading state
                                        <CircularProgress size={24} /> // Display CircularProgress when data is loading
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {kpis.logsSubmitted?.last4Weeks ?? '-'}
                                        </Typography>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction={'column'} alignItems={'center'}>
                                    <Typography>5-8 Weeks Ago</Typography>
                                    {isLoadingKpis ? ( // isLoadingLastYearKpi is a boolean indicating loading state
                                        <CircularProgress size={24} /> // Display CircularProgress when data is loading
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {kpis.logsSubmitted?.previous4Weeks ?? '-'}
                                        </Typography>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            spacing={2}
                        >
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 500,
                                }}
                                align={'center'}
                            >
                                Fastest Time Segment
                            </Typography>
                            <Tooltip
                                title={
                                    'Best time segment for sprints. Only sprints with 0 or 1kg load are included.'
                                }
                            >
                                <InfoIcon fontSize="small" sx={{ marginLeft: 3, color: 'gray' }} />
                            </Tooltip>
                        </Stack>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction={'column'} alignItems={'center'}>
                                    <Typography>Distance Covered</Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                        }}
                                    >
                                        {kpis.maxTimeSegment?.totalDistance?.toFixed(1) ?? '-'}ft
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction={'column'} alignItems={'center'}>
                                    <Typography>Avg Speed</Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 400,
                                        }}
                                    >
                                        {kpis.maxTimeSegment?.averageVelocity?.toFixed(1) ?? '-'}mph
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default IndividualKpisCard;
