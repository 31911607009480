import { SeriesNamesColumn, TableColumn } from '@cubejs-client/core';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Paper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getTags } from '../../../api/tags.api';
import { cubejsApi } from '../../../app.functions';
import { Tag } from '../../../app.types';
import { Can, confirmViaDialog, SelectDateChip } from '../../../components';
import MemberSpreadsheet from '../../../pages/MemberSpreadsheet';
import { Group } from '../../../utils';
import { CardTitle } from '../../train/components';
import { deleteGpsSession, updateGpsSession, viewGpsSessionForGroup } from '../api/gpsAnalysis.api';
import { viewGroup } from '../api/groups.api';
import { formatNumber, mapTagIntoFreeSoloOption } from '../community.func';
import { GpsSummary } from '../community.types';
import FreeSoloCreateOptionDialog, {
    FreeSoloOption,
} from '../components/FreeSoloCreateOptionDialog';
import GpsSummaryBarChart from '../components/GpsSummaryBarChart';
import ListOfGpsSummaryDates from '../components/ListOfGpsSummaryDates';

const columnsWithValues = (data: { [p: string]: string | number | boolean | null }[]) => {
    const result: { [key: string]: boolean } = {};

    // Initialize the result object with 'allNull' for each key
    if (data.length > 0) {
        for (let key in data[0]) {
            result[key] = false;
        }
    }

    // Check each object in the array
    for (let obj of data) {
        for (let key in obj) {
            if (obj[key] !== null) {
                result[key] = true;
            }
        }
    }

    return result;
};

const GroupGpsSummaryView = () => {
    const [loading, setLoading] = React.useState(false);
    const { id, summaryId } = useParams<{ id: string; summaryId: string }>();
    const [group, setGroup] = useState<Group>();
    const [summary, setSummary] = React.useState<GpsSummary>();
    const [aggregateColumns, setAggregateColumns] = React.useState<TableColumn[]>([]);
    const [table, setTable] = React.useState<{ [p: string]: string | number | boolean }[]>([]);
    const [tableColumns, setTableColumns] = React.useState<TableColumn[]>([]);
    const [chart, setChart] = React.useState<{ [p: string]: string | number | boolean }[]>([]);
    const [chartSeries, setChartSeries] = React.useState<SeriesNamesColumn[]>([]);
    const [chartByGroup, setChartByGroup] = React.useState<
        { [p: string]: string | number | boolean }[]
    >([]);
    const [chartByGroupSeries, setChartByGroupSeries] = React.useState<SeriesNamesColumn[]>([]);
    const [deleting, setDeleting] = React.useState(false);
    const [memberSpreadsheetOpen, setMemberSpreadsheetOpen] = React.useState(false);
    const [aggregateValues, setAggregateValues] = React.useState<
        { [p: string]: string | number | boolean }[]
    >([]);
    const [tags, setTags] = React.useState<Tag[]>([]);
    const { push } = useHistory();
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        setLoading(true);
        getTags({ 'filter[type]': 'reports' })
            .then((response) => {
                setTags(response.data);
            })
            .catch(() => {});
        viewGpsSessionForGroup(id, summaryId)
            .then((res) => {
                setSummary(res.data);
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
            });

        viewGroup(id)
            .then((result) => {
                setGroup(result.data);
            })
            .catch(() => {});

        cubejsApi
            .load({
                dimensions: [],
                measures: [
                    'FactGpsSummaries.avgDistance',
                    'FactGpsSummaries.avgPlayerLoad',
                    'FactGpsSummaries.avgNumberOfSprints',
                    'FactGpsSummaries.avgHeartRate',
                ],
                filters: [
                    {
                        dimension: 'FactGpsSummaries.uuid',
                        operator: 'equals',
                        values: [summaryId],
                    },
                ],
            })
            .then((r) => {
                setAggregateColumns(r.tableColumns());
                setAggregateValues(r.tablePivot());
            })
            .catch(() => {});

        cubejsApi
            .load({
                dimensions: [
                    'DimIndividual.name',
                    'FactGpsSummaries.dimPlayerLoad',
                    'FactGpsSummaries.dimNumberOfSprints',
                    'FactGpsSummaries.dimMaxAcceleration',
                    'FactGpsSummaries.dimMaxSpeed',
                    'FactGpsSummaries.dimDistance',
                    'FactGpsSummaries.dimNumberOfAccelerations',
                    'FactGpsSummaries.dimNumberOfDecelerations',
                    'FactGpsSummaries.dimStepImbalance',
                    'FactGpsSummaries.dimMetersPerMinute',
                    'FactGpsSummaries.dimNumberOfAccelerationsOver2',
                    'FactGpsSummaries.dimNumberOfAccelerationsOver3',
                    'FactGpsSummaries.dimNumberOfAccelerationsOver4',
                    'FactGpsSummaries.dimNumberOfAccelerationsOver5',
                    'FactGpsSummaries.dimNumberOfCollisions',
                    'FactGpsSummaries.dimNumberOfChangesOfDirection',
                    'FactGpsSummaries.dimDistanceBetween435And932',
                    'FactGpsSummaries.dimDistanceBetween932And1367',
                    'FactGpsSummaries.dimDistanceBetween1367And174',
                    'FactGpsSummaries.dimDistanceBetween174And2485',
                    'FactGpsSummaries.dimMinHeartRate',
                    'FactGpsSummaries.dimMaxHeartRate',
                    'FactGpsSummaries.dimAverageHeartRate',
                    'FactGpsSummaries.dimHeartRateInZone0to70',
                    'FactGpsSummaries.dimHeartRateInZone70to110',
                    'FactGpsSummaries.dimHeartRateInZone110to150',
                    'FactGpsSummaries.dimHeartRateInZone150to180',
                    'FactGpsSummaries.dimHeartRateInZone180to250',
                ],
                measures: [],
                filters: [
                    {
                        dimension: 'FactGpsSummaries.uuid',
                        operator: 'equals',
                        values: [summaryId],
                    },
                ],
                ungrouped: true,
            })
            .then((r) => {
                const columns = r.tableColumns();
                const table = r.tablePivot();
                const colsToDisplay = columnsWithValues(table);
                setTableColumns(columns.filter((c) => colsToDisplay[c.key as string]));
                setTable(table);
            })
            .catch(() => {});

        cubejsApi
            .load({
                dimensions: ['DimIndividual.name'],
                measures: ['FactGpsSummaries.avgPlayerLoad'],
                filters: [
                    {
                        dimension: 'FactGpsSummaries.uuid',
                        operator: 'equals',
                        values: [summaryId],
                    },
                ],
            })
            .then((r) => {
                setChart(r.chartPivot());
                setChartSeries(r.seriesNames());
            })
            .catch(() => {});
        cubejsApi
            .load({
                dimensions: ['DimPosition.name'],
                measures: ['FactGpsSummaries.avgPlayerLoad'],
                filters: [
                    {
                        dimension: 'FactGpsSummaries.uuid',
                        operator: 'equals',
                        values: [summaryId],
                    },
                ],
            })
            .then((r) => {
                setChartByGroup(r.chartPivot());
                setChartByGroupSeries(r.seriesNames());
            })
            .catch(() => {});
    }, [id, summaryId]);

    const handleSortColumn = (columnKey: string) => {
        if (sortColumn === columnKey) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(columnKey);
            setSortDirection('desc'); // Set to 'desc' on first click
        }
    };

    const sortedTableData = [...table].sort((a, b) => {
        if (sortColumn) {
            const aValue = a[sortColumn];
            const bValue = b[sortColumn];

            if (aValue === bValue) {
                return 0;
            }

            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue > bValue ? -1 : 1;
        }

        return 0;
    });

    return (
        <Container maxWidth={'xl'}>
            {group && (
                <MemberSpreadsheet
                    onClose={() => setMemberSpreadsheetOpen(false)}
                    group={group}
                    open={memberSpreadsheetOpen}
                />
            )}
            <Grid container spacing={4}>
                <Grid sx={{ pt: 6 }} xs={12} sm={4} md={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', // Added to center content horizontally
                            padding: 4,
                            textDecoration: 'none', // Remove underline from link
                            '&:hover': {
                                textDecoration: 'none', // Remove underline when hovered
                            },
                        }}
                    >
                        <Link
                            to={`/community/groups/${group?.uuid}/gps-summary`}
                            style={{
                                textDecoration: 'none',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar
                                sx={{ width: 60, height: 60, marginRight: 2 }}
                                alt={group?.name}
                                src={group?.image_urls.avatar || ''}
                                variant={'rounded'}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        fontSize: 20,
                                        color: 'black',
                                    }}
                                >
                                    {group?.name}
                                </Typography>
                            </Box>
                        </Link>
                    </Box>

                    <ListOfGpsSummaryDates groupId={id} summary={summary} />
                </Grid>
                <Grid xs={12} sm={8} md={10}>
                    <Paper
                        sx={{
                            mb: 4,
                            p: 8,
                            fontWeight: 'bold',
                            fontSize: 24,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            GPS Session Report For{' '}
                            <b>
                                {summary &&
                                    DateTime.fromFormat(
                                        summary.local_session_date_time,
                                        'yyyy-MM-dd HH:mm:ss',
                                    ).toLocaleString(DateTime.DATETIME_SHORT)}
                            </b>
                        </Box>
                        <Button
                            disabled={deleting}
                            onClick={() => {
                                setDeleting(true);
                                confirmViaDialog({
                                    confirmation: {
                                        title: 'Are you sure?',
                                        message: 'Are you sure you want to delete this session?',
                                    },
                                })
                                    .then((confirmed) => {
                                        if (confirmed) {
                                            deleteGpsSession(id, summaryId)
                                                .then(() => {
                                                    push(`/community/groups/${id}/gps-summary`);
                                                })
                                                .catch(() => {})
                                                .finally(() => setDeleting(false));
                                        } else {
                                            setDeleting(false);
                                        }
                                    })
                                    .catch(() => {})
                                    .finally(() => setDeleting(true));
                            }}
                            color={'warning'}
                        >
                            Delete
                        </Button>
                    </Paper>
                    <Paper sx={{ p: 8, mb: 4, textAlign: 'center' }}>
                        {summary && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <SelectDateChip
                                    type={'datetime'}
                                    onChange={async (v) => {
                                        const confirmed = await confirmViaDialog({
                                            confirmation: {
                                                title: 'Are you sure?',
                                                message:
                                                    'Are you sure you want to change the date for this session?',
                                            },
                                        }); // send post request to update the date
                                        if (confirmed && v) {
                                            updateGpsSession(id, summaryId, {
                                                local_session_date_time:
                                                    v.toFormat('yyyy-MM-dd HH:mm:ss'),
                                            })
                                                .then((r) => {
                                                    setSummary(r.data);
                                                })
                                                .catch(() => {});
                                        }
                                    }}
                                    value={DateTime.fromFormat(
                                        summary.local_session_date_time,
                                        'yyyy-MM-dd HH:mm:ss',
                                    )}
                                />
                                <Box sx={{ minWidth: 260 }}>
                                    <FreeSoloCreateOptionDialog
                                        placeholder={
                                            'Choose category (optional, for finding later)'
                                        }
                                        value={
                                            summary.tag
                                                ? ({
                                                      id: summary.tag.id,
                                                      inputValue: summary.tag.name,
                                                      title: summary.tag.name,
                                                  } as FreeSoloOption)
                                                : null
                                        }
                                        onChange={(v) => {
                                            if (!v?.id) {
                                                return;
                                            }
                                            updateGpsSession(id, summaryId, {
                                                tag: v?.id,
                                            })
                                                .then((r) => {
                                                    setSummary(r.data);
                                                })
                                                .catch(() => {});
                                        }}
                                        organization={summary.organization_uuid}
                                        choices={tags.map((t) => mapTagIntoFreeSoloOption(t))}
                                        onTagCreated={() => {}}
                                        createProps={{ type: 'reports' }}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Paper>

                    {loading && <Skeleton variant={'rectangular'} width={300} height={400} />}
                    {!loading && (
                        <Grid container spacing={4}>
                            {aggregateColumns.map((column) => (
                                <Grid key={column.key} xs={12} sm={6} md={3}>
                                    <Card>
                                        <CardTitle>
                                            <Box sx={{ p: 2, textAlign: 'center' }}>
                                                {column.shortTitle}
                                            </Box>
                                        </CardTitle>
                                        <CardContent
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                height: '100%',
                                                fontWeight: 'bold',
                                                fontSize: 48,
                                            }}
                                        >
                                            {aggregateValues.length > 0
                                                ? formatNumber(
                                                      Math.round(+aggregateValues[0][column.key]),
                                                  )
                                                : '-'}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {group && (
                        <Can I={'group:update-members'} this={group}>
                            <Box sx={{ my: 4 }}>
                                <Button
                                    onClick={() => {
                                        setMemberSpreadsheetOpen(true);
                                    }}
                                    variant={'contained'}
                                    color={'primary'}
                                >
                                    Edit Athletes Positions
                                </Button>
                            </Box>
                        </Can>
                    )}
                    {table.length > 0 && !loading && (
                        <TableContainer component={Paper}>
                            <Table size={'small'} sx={{ my: 4 }}>
                                <TableHead>
                                    <TableRow>
                                        {tableColumns.map((c) => (
                                            <TableCell
                                                key={c.key as string}
                                                onClick={() => handleSortColumn(c.key as string)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {c.shortTitle}
                                                {sortColumn === c.key && (
                                                    <span>
                                                        {sortDirection === 'asc' ? ' ↓' : ' ↑'}
                                                    </span>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {sortedTableData.map((row, i) => (
                                        <TableRow key={i}>
                                            {tableColumns.map((c) => (
                                                <TableCell key={c.key as string}>
                                                    {row[c.key] ?? '-'}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    <Stack>
                        <Typography variant={'h5'}>Average Load By Player</Typography>
                        <Paper sx={{ my: 4, height: '450px', width: '100%' }}>
                            {chart.length > 0 && !loading && (
                                <GpsSummaryBarChart chart={chart} series={chartSeries} />
                            )}
                        </Paper>
                        <Typography variant={'h5'}>Average Load By Position</Typography>
                        <Paper sx={{ my: 4, height: '450px', width: '100%' }}>
                            {chart.length > 0 && !loading && (
                                <GpsSummaryBarChart
                                    chart={chartByGroup}
                                    series={chartByGroupSeries}
                                />
                            )}
                        </Paper>
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    );
};

export default GroupGpsSummaryView;
