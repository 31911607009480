import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Checkbox, CircularProgress, Container } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { duplicateMovement } from 'api';
import {
    CreateMovementModal,
    CustomButton,
    ListWithSearchContainer,
    MovementCard,
    MovementSharingDrawer,
} from 'components';
import { defaults } from 'lodash';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getMovementAttributes, loadAttributes } from 'redux/reducers/attributes';
import { pushMessage } from 'redux/reducers/messages';
import {
    assetProcessingComplete,
    getAllMovements,
    loadMovements,
    movementsSelector,
} from 'redux/reducers/movements';
import { useAppDispatch } from 'redux/store';
import ROUTES from 'Routes/routes';
import { AssetProcessingCompleted, Movement, Organization, somethingWentWrong } from 'utils';
import { usePusher } from '../../../contexts/PusherContext';
import { userSelector } from '../../../redux/reducers/user';
import { EditMovementModal } from '../components';

const ListOfMovements = () => {
    const pusher = usePusher();
    const { userData } = useSelector(userSelector);

    const [open, setOpen] = useState(false);
    const [expandedCardIndex, setExpandedCardIndex] = useState(-1);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const { push } = useHistory();

    const [editingMovement, setEditingMovement] = useState<string | null>(null);

    // const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const [sharingMovement, setSharingMovement] = useState<Movement | null>(null);
    const [queryFilters, setQueryFilters] = useState<any>({});

    const [query, setQuery] = useState<{
        'filter[name]'?: string;
        'filter[organization]'?: string;
        'filter[is_archived]'?: boolean | undefined;
        page?: number;
    }>({
        'filter[is_archived]': false,
        page: 1,
    });
    const attributes = useSelector(getMovementAttributes);

    useEffect(() => {
        setQueryFilters(
            defaults(query, {
                'filter[is_archived]': false,
            }),
        );
    }, [query]);

    const handleSearchValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        debounceLoadData({ 'filter[name]': event.target.value, page: 1 });
    };

    const debounceLoadData = useCallback(debounce(setQuery, 400), []); // eslint-disable-line react-hooks/exhaustive-deps

    const dispatch = useAppDispatch();

    const movements = useSelector(getAllMovements);

    const { isLoading, isLoaded, error, pagination, paginationMeta } =
        useSelector(movementsSelector);

    useEffect(() => {
        dispatch(loadMovements(queryFilters as { 'filter[name]': string; page: number }));
        dispatch(loadAttributes());
    }, [dispatch, queryFilters]);

    useEffect(() => {
        if (error.message) {
            setShowErrorMessage(true);
        }
    }, [error.message]);

    useEffect(() => {
        function childEventCallback(data: AssetProcessingCompleted) {
            dispatch(assetProcessingComplete({ asset: data.asset }));
        }

        if (pusher && userData) {
            const channel = pusher.subscribe(`private-users.${userData.uuid}`);
            channel.bind('asset.processing.complete', childEventCallback);
            return () => {
                channel.unbind('asset.processing.complete', childEventCallback);
                channel.unsubscribe();
            };
        }
    }, [pusher, userData, dispatch]);

    const handlePageChanged = (newPage: number) => {
        setExpandedCardIndex(-1);
        setQuery((query) => ({ ...query, page: newPage }));
    };

    const handleDetailsClicked = (movement: Movement) => {
        push(ROUTES.ViewMovement.path.replace(':id', movement.uuid));
    };

    useHotkeys('cmd+m', () => setOpen(true));
    useHotkeys('ctrl+m', () => setOpen(true));

    const onMovementDuplicated = (movement: Movement, organization: Organization) => {
        duplicateMovement(movement.uuid, {
            organization: organization.uuid,
        })
            .then(() => {
                dispatch(loadMovements(queryFilters as { 'filter[name]': string; page: number }));
                setExpandedCardIndex(-1);

                dispatch(
                    pushMessage({
                        status: 'success',
                        message: `Successfully duplicated movement under ${organization.name} organization.`,
                    }),
                );
            })
            .catch(() => {
                somethingWentWrong();
            });
    };

    const onNextClicked = () => {
        const nextPage = paginationMeta ? paginationMeta.current_page + 1 : 1;
        handlePageChanged(nextPage);
    };

    const onPrevClicked = () => {
        const prevPage = paginationMeta ? paginationMeta.current_page - 1 : 1;
        handlePageChanged(prevPage);
    };

    return (
        <ListWithSearchContainer
            buttonText="NEW MOVEMENT"
            onClickButton={() => setOpen(true)}
            searchBarPlaceHolder="Find Movement"
            searchValue={query['filter[name]'] || ''}
            setSearchValue={handleSearchValueChanged}
            isError={!!(error.message && showErrorMessage)}
            errorMessage={error.message}
            setOpen={setShowErrorMessage}
            onFilter={(filter) => setQuery({ ...query, ...filter })}
            enabledFilters={{
                'filter[organization]': query['filter[organization]'],
            }}
        >
            <CreateMovementModal
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            />

            {isLoaded && (
                <Box display="flex" justifyContent="space-between" width="100%">
                    {isLoading ? <CircularProgress /> : <Box />}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={Boolean(query['filter[is_archived]'])}
                                onChange={() => {
                                    setQuery({
                                        ...query,
                                        'filter[is_archived]': query['filter[is_archived]']
                                            ? undefined
                                            : true,
                                    });
                                }}
                                name="archived"
                                color="primary"
                                sx={{ marginLeft: 8 }}
                                size={'medium'}
                            />
                        }
                        label="Show Only Archived Movements"
                    />
                </Box>
            )}

            {!movements.length && isLoaded ? (
                <>
                    {!query['filter[name]'] ? (
                        <Container sx={{ textAlign: 'center' }}>
                            Create your first movement by clicking New Movement
                        </Container>
                    ) : (
                        <Container sx={{ textAlign: 'center' }}>
                            You search did not match any movements
                        </Container>
                    )}
                </>
            ) : (
                <React.Fragment>
                    {movements.map((item: Movement, index) => {
                        return (
                            <MovementCard
                                onClickDetails={handleDetailsClicked}
                                onShare={(movement: Movement) => {
                                    setSharingMovement(movement);
                                }}
                                isExpanded={Boolean(index === expandedCardIndex)}
                                onClick={() =>
                                    setExpandedCardIndex(index === expandedCardIndex ? -1 : index)
                                }
                                key={item.uuid}
                                item={item}
                                onMovementDuplicated={(movement, organization) =>
                                    onMovementDuplicated(movement, organization)
                                }
                                onEdit={(movement: Movement) => {
                                    setEditingMovement(movement.uuid);
                                }}
                            />
                        );
                    })}
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <CustomButton
                            variant="text"
                            color="primary"
                            disabled={!pagination?.prev}
                            startIcon={<NavigateBeforeIcon />}
                            onClick={() => onPrevClicked()}
                        >
                            Previous
                        </CustomButton>
                        <CustomButton
                            variant="text"
                            color="primary"
                            disabled={!pagination?.next}
                            endIcon={<NavigateNextIcon />}
                            onClick={() => onNextClicked()}
                        >
                            Next
                        </CustomButton>
                    </Box>
                </React.Fragment>
            )}
            {sharingMovement && (
                <MovementSharingDrawer
                    movement={sharingMovement as Movement}
                    open={Boolean(sharingMovement)}
                    onClose={() => setSharingMovement(null)}
                    // onUsersChanged={setSharedUsers}
                />
            )}
            {editingMovement && (
                <EditMovementModal
                    open={Boolean(editingMovement)}
                    onClose={() => setEditingMovement(null)}
                    movement={movements.find((m) => m.uuid === editingMovement) as Movement}
                    attributes={attributes.filter(
                        (a) =>
                            a.organization_uuid ===
                            (movements.find((m) => m.uuid === editingMovement) as Movement)
                                .organization_uuid,
                    )}
                />
            )}
        </ListWithSearchContainer>
    );
};

export default ListOfMovements;
