import { Box, Button, Typography } from '@mui/material';
import AssetPreviewCard from 'components/Cards/AssetPreviewCard';
import React from 'react';
import { Form, getHtml, Submission } from 'utils';

export interface AssessmentFormPreviewProps {
    form: Form;
    submission?: Submission | null;
    onFormExited: () => void;
    onFormSubmissionStarted: () => void;
}

const AssessmentFormPreview = ({
    form,
    submission = null,
    onFormExited,
    onFormSubmissionStarted,
}: AssessmentFormPreviewProps) => {
    const totalQuestions = form.sections
        .map((section) => section.questions.length)
        .reduce((sum, noOfQuestions) => sum + noOfQuestions, 0);

    return (
        <>
            <Box
                textAlign="center"
                mt={10}
                pb={40}
                display="flex"
                flexDirection="column"
                style={{ gap: 30 }}
                alignItems="center"
            >
                <Box mb={10} textAlign="left">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getHtml(form.description || ''),
                        }}
                    />
                </Box>
                {form.asset && (
                    <>
                        <AssetPreviewCard
                            asset={form.asset}
                            altTitle={form.title}
                            onAssetRemoved={() => {}}
                            disableRemove={true}
                            fullwidth={true}
                        />
                    </>
                )}
                {totalQuestions > 0 ? (
                    <React.Fragment>
                        <Typography variant="body2">
                            This assessment contains {totalQuestions} questions.
                        </Typography>
                        {submission?.submitted_at ? (
                            <Typography variant="body2">
                                You won&apos;t be able to answer this assessment as you have already
                                submitted this assessment before.
                            </Typography>
                        ) : (
                            <Box
                                textAlign="center"
                                display="flex"
                                style={{ gap: 10 }}
                                alignItems="center"
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => onFormExited()}
                                    color="primary"
                                    size="small"
                                >
                                    Go Back
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => onFormSubmissionStarted()}
                                    color="primary"
                                >
                                    Start Now
                                </Button>
                            </Box>
                        )}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography variant="body2">
                            This assessment does not contain any questions.
                        </Typography>
                        <Box
                            textAlign="center"
                            display="flex"
                            style={{ gap: 10 }}
                            alignItems="center"
                        >
                            <Button
                                variant="outlined"
                                onClick={() => onFormExited()}
                                color="secondary"
                                size="small"
                            >
                                Go Back
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </>
    );
};

export default AssessmentFormPreview;
