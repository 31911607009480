import TextField from '@mui/material/TextField';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useState } from 'react';
import {
    Attribute,
    AttributeCategory,
    AttributeValue,
    Individual,
    Member,
    MemberType,
    yupSchemas,
} from 'utils';
import AttributeInput from 'components/FormControl/AttributeInput';
import MenuItem from '@mui/material/MenuItem';
import { isEmpty } from 'lodash';
import FormikOnChange from './FormikOnChange';
import { UploadLogo } from 'components/Functional/UploadLogo';
import { useTheme } from '@mui/system';

export interface CreateMemberFormProps {
    /** memberTypes The available member types to display in the dropdown */
    memberTypes: Array<MemberType>;
    /** assets An array of attributes to display in the attribute selection */
    attributes: Array<Attribute>;
    /** onSubmit Submit the form */
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void;
    /** onChange Submit the form */
    onChange: (isValid: boolean, dirty: boolean, values: FormikValues) => void;
    /** onAttributeValuesChanged */
    onAttributeValuesChanged: (values: AttributeValue[]) => void;
    /** innerRef The reference to the form */
    innerRef: React.Ref<FormikProps<FormikValues>>;
    selectedIndividual?: Individual | null;
    editMember?: Member | null;
    profilePhoto: File | undefined;
    setProfilePhoto: React.Dispatch<React.SetStateAction<File | undefined>>;
}

export default function CreateMemberForm({
    attributes,
    onSubmit,
    onChange,
    onAttributeValuesChanged,
    innerRef,
    memberTypes,
    selectedIndividual,
    editMember,
    profilePhoto,
    setProfilePhoto,
}: CreateMemberFormProps): ReactElement {
    const theme = useTheme();
    let names = selectedIndividual?.name.split(' ');
    let firstName = names?.shift();
    let lastName = names?.join(' ');

    const [attributeValues, setAttributeValues] = useState<AttributeValue[]>(
        editMember?.attributes ?? [],
    );

    const handleAttributeValuesChanged = (attributeValues: AttributeValue[]) => {
        setAttributeValues(attributeValues);
        onAttributeValuesChanged(attributeValues);
    };

    return (
        <Formik
            innerRef={innerRef}
            initialValues={{
                first_name: editMember?.first_name ?? firstName,
                last_name: editMember?.last_name ?? lastName,
                member_type:
                    editMember?.memberType.uuid ??
                    memberTypes.find((t) => t.name === 'Athlete')?.uuid ??
                    '',
                photo_path: selectedIndividual?.profile_photo_path,
            }}
            validationSchema={yupSchemas.createMember}
            onSubmit={onSubmit}
        >
            {({ values, handleChange, errors }) => {
                return (
                    <Form style={{ width: '100%' }}>
                        <FormikOnChange onChange={onChange} />
                        <UploadLogo
                            button={false}
                            defaultImgSrc={
                                editMember?.image_urls?.avatar ??
                                selectedIndividual?.profile_photo ??
                                ''
                            }
                            onClose={() => {}}
                            setUploadImage={setProfilePhoto}
                            uploadContainerSx={{
                                borderRadius: '25%',
                                height: 95,
                                overflow: 'hidden',
                                width: 95,
                                marginBottom: theme.spacing(8),
                            }}
                            uploadedImageSx={{ width: '100%', height: 'auto' }}
                            uploadImage={profilePhoto}
                        />
                        <TextField
                            sx={{ width: '100%', marginBottom: theme.spacing(4) }}
                            label="First Name"
                            id="first_name"
                            name="first_name"
                            variant="filled"
                            value={values.first_name}
                            onChange={handleChange}
                            error={Boolean(errors.first_name)}
                            helperText={errors.first_name as string}
                            InputProps={{
                                readOnly: !isEmpty(firstName),
                            }}
                        />
                        <TextField
                            multiline
                            sx={{ width: '100%', marginBottom: theme.spacing(4) }}
                            label="Last Name"
                            id="last_name"
                            name="last_name"
                            variant="filled"
                            value={values.last_name}
                            onChange={handleChange}
                            error={Boolean(errors.last_name)}
                            helperText={errors.last_name as string}
                            InputProps={{
                                readOnly: !isEmpty(lastName),
                            }}
                        />
                        <TextField
                            id="member_type"
                            name="member_type"
                            label="Member Type"
                            sx={{ width: '100%', marginBottom: theme.spacing(4) }}
                            variant="filled"
                            onChange={handleChange}
                            value={values.member_type}
                            select
                        >
                            {memberTypes.map((type) => (
                                <MenuItem key={type.uuid} value={type.uuid}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <AttributeInput
                            attributes={attributes}
                            attributeCategory={AttributeCategory.Member}
                            value={attributeValues}
                            onChange={(values) => handleAttributeValuesChanged(values)}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}
