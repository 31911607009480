import { Box, SxProps } from '@mui/material';
import { styled } from '@mui/system';
import React, { ReactElement, useRef } from 'react';
import VideocamIcon from '@mui/icons-material/Videocam';
import isEmpty from 'lodash/isEmpty';

interface Props {
    poster: string;
    mp4: string;
    webm: string;
    classesSx?: SxProps;
    videoProps?: React.HTMLAttributes<HTMLVideoElement>;
}

function ClipPlayer({ poster, mp4, webm, classesSx, videoProps }: Props): ReactElement {
    const vidRef = useRef<HTMLVideoElement | null>(null);
    if (mp4 || webm || poster)
        return (
            <>
                {isEmpty(mp4) && isEmpty(webm) ? (
                    <Box component={'img'} src={poster} alt="Movement" sx={classesSx} />
                ) : (
                    <Box
                        component={'video'}
                        ref={vidRef}
                        sx={classesSx}
                        poster={poster}
                        preload="none"
                        loop
                        muted
                        playsInline
                        autoPlay
                        {...videoProps}
                    >
                        {mp4 && <source src={mp4} type="video/mp4" />}
                        {webm && <source src={webm} type="video/webm" />}
                    </Box>
                )}
            </>
        );
    return <VideocamIcon sx={classesSx} color="action" fontSize="small" />;
}

const VideoClipPlayer = React.memo(
    styled(ClipPlayer)({
        maxWidth: '100%',
    }),
);
export { VideoClipPlayer };
