import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Dimensions } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Dimensions>>>;

const getDimensions = (): ReturnType => {
    return Request.get(`/timeline/dimensions`);
};

export default getDimensions;
