import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ url: string }>>;

/**
 * Handle intro combo subscription for new organizations.
 */
const createCheckoutOrgIntroComboSubscription = (
    return_url: string,
    org_id_for_purchase: string,
    seller_org_id: string,
    vest_price_id_quantity_map: Record<string, number>,
    pod_quantity_map: Record<string, number>,
    single_charger_quantity_map: Record<string, number>,
    triple_charger_quantity_map: Record<string, number>,
    fiyrlink_quantity_map: Record<string, number>,
): ReturnType => {
    return Request.post(
        `/organizations/${org_id_for_purchase}/checkout-combo-subscription-session`,
        {
            return_url,
            seller_org_id,
            vest_price_id_quantity_map,
            pod_quantity_map,
            single_charger_quantity_map,
            triple_charger_quantity_map,
            fiyrlink_quantity_map,
        },
    );
};
export default createCheckoutOrgIntroComboSubscription;
