import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import DateCell from './DateCell';
import { Box } from '@mui/material';

interface DateCarouselProps {
    startDate: DateTime;
    endDate: DateTime;
    renderCell?: (date: DateTime) => React.ReactNode;
    onClick: (date: DateTime) => void;
    selectedDate?: DateTime;
}

export const DateCarousel: React.FC<React.PropsWithChildren<DateCarouselProps>> = ({
    startDate,
    endDate,
    renderCell,
    onClick,
    selectedDate,
}) => {
    const [dates, setDates] = useState<DateTime[]>([]);

    const carouselRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const daysBetween = Math.abs(endDate.diff(startDate, 'days').days) + 1;
        const newDates = Array.from({ length: daysBetween }, (_, i) => startDate.plus({ days: i }));
        setDates(newDates);
        if (carouselRef.current) {
            carouselRef.current.scrollLeft = carouselRef.current.scrollWidth;
        }
    }, [startDate, endDate]);

    return (
        <Box ref={carouselRef} sx={{ display: 'flex', overflowX: 'scroll', width: '100%' }}>
            {dates.map((date, index) => (
                <React.Fragment key={index}>
                    {renderCell ? (
                        renderCell(date)
                    ) : (
                        <DateCell
                            isSelected={selectedDate?.toISODate() === date.toISODate()}
                            date={date}
                            onClick={() => onClick(date)}
                        />
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default DateCarousel;
