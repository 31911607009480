import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    Typography,
} from '@mui/material';
import { LibraryAdd, OpenInNew } from '@mui/icons-material';
import DragIndicator from '@mui/icons-material/DragIndicator';
import MovementSelectionDialog from 'components/Dialogs/MovementSelectionDialog';
import MovementMedia from 'components/Media/MovementMedia';
import { cloneDeep, last } from 'lodash';
import { GoalChipSelect } from 'modules/train/components';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import ROUTES from 'Routes/routes';
import {
    AccelerationUnit,
    DistanceUnit,
    EntityType,
    LoadUnit,
    Movement,
    MovementType,
    openInNewTab,
    PowerUnit,
    Session,
    SessionMovement,
    SetAttributeType,
    somethingWentWrong,
    SpeedUnit,
    TimeDisplayFormat,
} from 'utils';
import SessionMovementGoalSettings from './SessionMovementGoalSettings';
import SessionMovementGoalsTableHeader from './SessionMovementGoalsTableHeader';
import SessionMovementRow from './SessionMovementRow';
import SelectContentModal from '../../modules/plan/components/SelectContentModal';
import { getSession } from '../../api';
import { Can } from 'components/Functional/Can';
import useTheme from '@mui/material/styles/useTheme';

export interface SessionMovementsWithGoalsProps {
    sessionMovements: Array<SessionMovement>;
    onMovementAdded?: (movement: Movement) => void | undefined;
    onMultipleMovementsAdded: (movements: Array<Movement>) => void;
    onMultipleSessionMovementsAdded: (
        movements: Array<SessionMovement>,
        groupTitle: string | null,
    ) => void;
    onCriteriaAddedToSessionMovements: (
        sessionMovements: Array<SessionMovement>,
        criteria: SetAttributeType,
    ) => void;
    onCriteriaRemovedFromSessionMovements: (
        sessionMovements: Array<SessionMovement>,
        criteria: SetAttributeType,
    ) => void;
    onSessionMovementAdded: (previousSessionMovement: SessionMovement) => void;
    onSessionMovementRemoved: (removedSessionMovement: SessionMovement) => void;
    onSessionMovementGoalValueChanged: (
        updatedSessionMovement: SessionMovement,
        updatedGoalCriteria: SetAttributeType,
        newValue: any,
    ) => void;
    onSessionMovementsRemoved: (removedSessionMovements: Array<SessionMovement>) => void;
    onSessionMovementUnitChanged: (
        sessionMovements: Array<SessionMovement>,
        criteria: SetAttributeType,
        oldUnit: string,
        newUnit: string,
    ) => void;
    onSessionMovementsReOrdered: (updatedMovements: Array<SessionMovement>) => void;
    organizationUuid?: string | undefined;
}

export interface SessionMovementGoalSet {
    groupTitle: string | null;
    movement: Movement;
    goalSets: Array<SessionMovement>;
}

const SessionMovementsWithGoals = ({
    organizationUuid,
    sessionMovements,
    onMultipleMovementsAdded,
    onSessionMovementAdded,
    onSessionMovementRemoved,
    onSessionMovementsRemoved,
    onSessionMovementUnitChanged,
    onMultipleSessionMovementsAdded,
    onSessionMovementGoalValueChanged,
    onCriteriaAddedToSessionMovements,
    onSessionMovementsReOrdered,
    onCriteriaRemovedFromSessionMovements,
}: SessionMovementsWithGoalsProps) => {
    const [showMovementSelectionDialog, setShowMovementSelectionDialog] = useState<boolean>(false);
    const [sessionMovementGoalSets, setSessionMovementGoalSets] = useState<
        Array<SessionMovementGoalSet>
    >([]);
    const [showSessionSelectionDialog, setShowSessionSelectionDialog] = useState(false);

    function onMovementsAddedFromSession(sessions: Array<Session>): void {
        getSession(sessions[0].uuid).then((response) => {
            if (!response.data.movements || response.data.movements?.length == 0) {
                somethingWentWrong(`That session doesnt have any movements`);
            } else {
                onMultipleSessionMovementsAdded(response.data.movements, response.data.title);
            }
        });
    }

    const [openMovementAssetSliderIndex, setOpenMovementAssetSliderIndex] = useState<number | null>(
        null,
    );

    useEffect(() => {
        let goalSets: Array<SessionMovementGoalSet> = [];
        for (let i = 0; i < sessionMovements.length; i++) {
            if (i == 0 || sessionMovements[i].uuid != sessionMovements[i - 1].uuid) {
                goalSets.push({
                    groupTitle: sessionMovements[i].pivot?.group_title ?? '',
                    movement: {
                        uuid: sessionMovements[i].uuid,
                        name: sessionMovements[i].name,
                        object: EntityType.MOVEMENT,
                        description: sessionMovements[i].description,
                        movement_type: sessionMovements[i].movement_type as MovementType,
                        published_at: sessionMovements[i].published_at,
                        assets: sessionMovements[i].assets,
                    },
                    goalSets: [sessionMovements[i]],
                });
            } else {
                goalSets[goalSets.length - 1].goalSets.push(sessionMovements[i]);
            }
        }

        setSessionMovementGoalSets(goalSets);
    }, [sessionMovements]);

    //A goal set is a set of movement attached to the session with reps, load, distance, time etc
    // A movement can just have reps and load while other movement from same session might have
    // distance and time
    // This function will get the goal criterias which are applicable for a movement goal set
    // This is used to show header columns as if there is no distance goal for any set of a session movement
    // We don't need to show it in the header
    const getGoalCriteriasFromGoalSet = (
        sessionMovementGoalSet: SessionMovementGoalSet,
    ): Array<SetAttributeType> => {
        let goalCriterias: Set<SetAttributeType> = new Set();

        sessionMovementGoalSet.goalSets.forEach((goalSet) => {
            if (goalSet.pivot?.reps != null) {
                goalCriterias.add(SetAttributeType.Reps);
            }
            if (goalSet.pivot?.load_value != null) {
                goalCriterias.add(SetAttributeType.Load);
            }
            if (goalSet.pivot?.time_value != null) {
                goalCriterias.add(SetAttributeType.Time);
            }
            if (goalSet.pivot?.distance_value != null) {
                goalCriterias.add(SetAttributeType.Distance);
            }
            if (goalSet.pivot?.range_of_motion_value != null) {
                goalCriterias.add(SetAttributeType.RangeOfMotion);
            }
            if (goalSet.pivot?.speed_value != null) {
                goalCriterias.add(SetAttributeType.Speed);
            }
            if (goalSet.pivot?.acceleration_value != null) {
                goalCriterias.add(SetAttributeType.Acceleration);
            }
            if (goalSet.pivot?.power_value != null) {
                goalCriterias.add(SetAttributeType.Power);
            }
            if (goalSet.pivot?.rsi_value != null) {
                goalCriterias.add(SetAttributeType.RSI);
            }
            if (goalSet.pivot?.rpm_value != null) {
                goalCriterias.add(SetAttributeType.RPM);
            }
            if (goalSet.pivot?.force_value != null) {
                goalCriterias.add(SetAttributeType.Force);
            }
            if (goalSet.pivot?.body_side != null) {
                goalCriterias.add(SetAttributeType.BodySide);
            }
            if (goalSet.pivot?.rpe_value != null) {
                goalCriterias.add(SetAttributeType.RPE);
            }
            if (goalSet.pivot?.band_value != null) {
                goalCriterias.add(SetAttributeType.Band);
            }
            if (goalSet.pivot?.rest_value != null) {
                goalCriterias.add(SetAttributeType.Rest);
            }
            if (goalSet.pivot?.gps_player_load_value != null) {
                goalCriterias.add(SetAttributeType.GPSPlayerLoad);
            }
            if (goalSet.pivot?.gps_accel_count_value != null) {
                goalCriterias.add(SetAttributeType.GPSAccelCount);
            }
            if (goalSet.pivot?.gps_decel_count_value != null) {
                goalCriterias.add(SetAttributeType.GPSDecelCount);
            }
        });
        if (goalCriterias.size == 0) {
            // Add reps if no other movement preset has been added
            goalCriterias.add(SetAttributeType.Reps);
        }

        return Array.from(goalCriterias);
    };

    const onDragEnd = (result: DropResult) => {
        // Dropped outside the expected container
        if (!result.destination || !result.source || !result.type) {
            return;
        }

        let updatedSessionMovementGoalSets = cloneDeep(sessionMovementGoalSets);

        // Re-order the goal sets
        const [removed] = updatedSessionMovementGoalSets.splice(result.source.index, 1);
        updatedSessionMovementGoalSets.splice(result.destination.index, 0, removed);
        setSessionMovementGoalSets(updatedSessionMovementGoalSets);

        // We have re-ordered the goal sets on DOM level, however we need to make sure session's session-movement
        // has correct order and it is saved in the backend
        // We will update the session movement order below and send it to the parent component
        // so it can update the new order in the backend
        let updatedMovements: Array<SessionMovement> = [];

        let counter = 1;

        // Loop through each session movement goal set
        updatedSessionMovementGoalSets.forEach((updatedGoalSet) => {
            // Loop through each goal set(which essentially is a session movement in backend context)
            updatedGoalSet.goalSets.forEach((sessionMovement) => {
                if (sessionMovement.pivot) {
                    sessionMovement.pivot.order = counter;
                }
                counter++;
            });

            // Push the new orders to the updatedMovement stack
            updatedMovements = updatedMovements.concat(updatedGoalSet.goalSets);
        });

        onSessionMovementsReOrdered(updatedMovements);
    };
    const theme = useTheme();

    return (
        <>
            {sessionMovementGoalSets.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable type="movements" droppableId="movements">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {sessionMovementGoalSets.map(
                                    (sessionMovementGoalSet, goalSetIndex) => {
                                        const applicableCriterias =
                                            getGoalCriteriasFromGoalSet(sessionMovementGoalSet);

                                        return (
                                            <Draggable
                                                key={`${goalSetIndex}-${sessionMovementGoalSet.movement.uuid}`}
                                                draggableId={`session-movement-${goalSetIndex}`}
                                                index={goalSetIndex}
                                            >
                                                {(provided) => (
                                                    <Accordion
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                borderBottom: `1px solid transparent`,
                                                                '&:hover, &:focus': {
                                                                    borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                                                },
                                                            },
                                                        }}
                                                        expanded={true}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <AccordionSummary
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Box
                                                                display="flex"
                                                                justifyContent="space-between"
                                                                alignItems="center"
                                                                sx={{
                                                                    width: '100%',
                                                                    gap: theme.spacing(5),
                                                                }}
                                                                flexWrap="wrap"
                                                            >
                                                                <Box
                                                                    textAlign="center"
                                                                    onClick={() => {
                                                                        if (
                                                                            openMovementAssetSliderIndex ==
                                                                            goalSetIndex
                                                                        ) {
                                                                            setOpenMovementAssetSliderIndex(
                                                                                null,
                                                                            );
                                                                        } else {
                                                                            setOpenMovementAssetSliderIndex(
                                                                                goalSetIndex,
                                                                            );
                                                                        }
                                                                    }}
                                                                    width={
                                                                        openMovementAssetSliderIndex ==
                                                                        goalSetIndex
                                                                            ? '100%'
                                                                            : 80
                                                                    }
                                                                    flex={
                                                                        openMovementAssetSliderIndex ==
                                                                        goalSetIndex
                                                                            ? '0 0 100%'
                                                                            : 'inherit'
                                                                    }
                                                                >
                                                                    <MovementMedia
                                                                        movement={
                                                                            sessionMovementGoalSet.movement
                                                                        }
                                                                    />
                                                                </Box>
                                                                <IconButton
                                                                    sx={{
                                                                        padddingTop: 0,
                                                                        padddingBottom: 0,
                                                                        [theme.breakpoints.down(
                                                                            'md',
                                                                        )]: {
                                                                            padding: 0,
                                                                        },
                                                                    }}
                                                                    {...provided.dragHandleProps}
                                                                    size="large"
                                                                >
                                                                    <DragIndicator />
                                                                </IconButton>
                                                                <Typography
                                                                    component="div"
                                                                    sx={{ flexGrow: 1 }}
                                                                >
                                                                    {
                                                                        sessionMovementGoalSet
                                                                            .movement.name
                                                                    }
                                                                    <Can
                                                                        I="movement:view"
                                                                        this={
                                                                            sessionMovementGoalSet.movement
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                openInNewTab(
                                                                                    ROUTES.ViewMovement.path.replace(
                                                                                        ':id',
                                                                                        sessionMovementGoalSet
                                                                                            .movement
                                                                                            .uuid,
                                                                                    ),
                                                                                )
                                                                            }
                                                                            size="large"
                                                                        >
                                                                            <OpenInNew fontSize="small" />
                                                                        </IconButton>
                                                                    </Can>
                                                                    {sessionMovementGoalSet.groupTitle && (
                                                                        <>
                                                                            [
                                                                            {
                                                                                sessionMovementGoalSet.groupTitle
                                                                            }
                                                                            ]
                                                                        </>
                                                                    )}
                                                                </Typography>
                                                                <Button
                                                                    variant="text"
                                                                    size="small"
                                                                    sx={{
                                                                        color: theme.palette.text
                                                                            .secondary,
                                                                    }}
                                                                    onClick={() =>
                                                                        onSessionMovementAdded(
                                                                            last(
                                                                                sessionMovementGoalSet.goalSets,
                                                                            ) as SessionMovement,
                                                                        )
                                                                    }
                                                                >
                                                                    <LibraryAdd />
                                                                    &nbsp;Add Set
                                                                </Button>

                                                                {/* Settings */}
                                                                <SessionMovementGoalSettings
                                                                    removeConfirmationMessage={`This will remove ${sessionMovementGoalSet.goalSets.length} sets of ${sessionMovementGoalSet.movement.name}.`}
                                                                    onRemoveConfirmed={() => {
                                                                        onSessionMovementsRemoved(
                                                                            sessionMovementGoalSet.goalSets,
                                                                        );
                                                                    }}
                                                                    onUnitChanged={(
                                                                        criteria: SetAttributeType,
                                                                        oldUnit: string,
                                                                        newUnit: string,
                                                                    ) => {
                                                                        onSessionMovementUnitChanged(
                                                                            sessionMovementGoalSet.goalSets,
                                                                            criteria,
                                                                            oldUnit,
                                                                            newUnit,
                                                                        );
                                                                    }}
                                                                    distanceUnit={
                                                                        (sessionMovementGoalSet
                                                                            .goalSets[0].pivot
                                                                            ?.distance_unit ||
                                                                            DistanceUnit.Mile) as DistanceUnit
                                                                    }
                                                                    timeDisplayFormat={
                                                                        (sessionMovementGoalSet
                                                                            .goalSets[0].pivot
                                                                            ?.time_display_format ||
                                                                            TimeDisplayFormat.Common) as TimeDisplayFormat
                                                                    }
                                                                    speedUnit={
                                                                        (sessionMovementGoalSet
                                                                            .goalSets[0].pivot
                                                                            ?.speed_unit ||
                                                                            SpeedUnit.MPH) as SpeedUnit
                                                                    }
                                                                    loadUnit={
                                                                        (sessionMovementGoalSet
                                                                            .goalSets[0].pivot
                                                                            ?.load_unit ||
                                                                            LoadUnit.Pound) as LoadUnit
                                                                    }
                                                                />
                                                            </Box>
                                                        </AccordionSummary>
                                                        <AccordionDetails
                                                            sx={{
                                                                display: 'block',
                                                                backgroundColor:
                                                                    theme.palette.backgroundColor
                                                                        .hover,
                                                                borderTop: `1px solid ${theme.palette.grey[300]}`,
                                                            }}
                                                        >
                                                            {sessionMovementGoalSet.goalSets
                                                                .length > 0 ? (
                                                                <>
                                                                    {/* Headers */}
                                                                    <SessionMovementGoalsTableHeader
                                                                        applicableCriterias={
                                                                            applicableCriterias
                                                                        }
                                                                        loadUnit={
                                                                            (sessionMovementGoalSet
                                                                                .goalSets[0]?.pivot
                                                                                ?.load_unit as LoadUnit) ||
                                                                            LoadUnit.Pound
                                                                        }
                                                                        distanceUnit={
                                                                            (sessionMovementGoalSet
                                                                                .goalSets[0]?.pivot
                                                                                ?.distance_unit as DistanceUnit) ||
                                                                            DistanceUnit.Mile
                                                                        }
                                                                        speedUnit={
                                                                            (sessionMovementGoalSet
                                                                                .goalSets[0]?.pivot
                                                                                ?.speed_unit as SpeedUnit) ||
                                                                            SpeedUnit.MPH
                                                                        }
                                                                        accelerationUnit={
                                                                            (sessionMovementGoalSet
                                                                                .goalSets[0]?.pivot
                                                                                ?.acceleration_unit as AccelerationUnit) ||
                                                                            AccelerationUnit.FPS
                                                                        }
                                                                        powerUnit={
                                                                            (sessionMovementGoalSet
                                                                                .goalSets[0]?.pivot
                                                                                ?.power_unit as PowerUnit) ||
                                                                            PowerUnit.Watt
                                                                        }
                                                                        timeDisplayFormat={
                                                                            (sessionMovementGoalSet
                                                                                .goalSets[0]?.pivot
                                                                                ?.time_display_format as TimeDisplayFormat) ||
                                                                            TimeDisplayFormat.Common
                                                                        }
                                                                    />

                                                                    {sessionMovementGoalSet.goalSets.map(
                                                                        (goalSet, index) => {
                                                                            return (
                                                                                <SessionMovementRow
                                                                                    key={`goal-set-${goalSetIndex}-session-moment-row-${index}`}
                                                                                    index={index}
                                                                                    goalSet={
                                                                                        goalSet
                                                                                    }
                                                                                    applicableCriterias={
                                                                                        applicableCriterias
                                                                                    }
                                                                                    onSessionMovementRemoved={
                                                                                        onSessionMovementRemoved
                                                                                    }
                                                                                    onSessionMovementGoalValueChanged={
                                                                                        onSessionMovementGoalValueChanged
                                                                                    }
                                                                                />
                                                                            );
                                                                        },
                                                                    )}
                                                                    <Box
                                                                        textAlign="center"
                                                                        my={5}
                                                                        display="flex"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                        style={{ gap: 20 }}
                                                                        flexWrap="wrap"
                                                                    >
                                                                        <Button
                                                                            variant="text"
                                                                            size="small"
                                                                            sx={{
                                                                                color: theme.palette
                                                                                    .text.secondary,
                                                                            }}
                                                                            onClick={() =>
                                                                                onSessionMovementAdded(
                                                                                    last(
                                                                                        sessionMovementGoalSet.goalSets,
                                                                                    ) as SessionMovement,
                                                                                )
                                                                            }
                                                                        >
                                                                            <LibraryAdd />
                                                                            &nbsp;Add Set
                                                                        </Button>
                                                                        {/* Chip selection */}
                                                                        <GoalChipSelect
                                                                            selectedGoals={
                                                                                applicableCriterias
                                                                            }
                                                                            onChipDeleted={(chip) =>
                                                                                onCriteriaRemovedFromSessionMovements(
                                                                                    sessionMovementGoalSet.goalSets,
                                                                                    chip,
                                                                                )
                                                                            }
                                                                            onChipSelected={(
                                                                                chip,
                                                                            ) =>
                                                                                onCriteriaAddedToSessionMovements(
                                                                                    sessionMovementGoalSet.goalSets,
                                                                                    chip,
                                                                                )
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                <Typography variant="body2">
                                                                    No sets added.
                                                                </Typography>
                                                            )}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )}
                                            </Draggable>
                                        );
                                    },
                                )}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <Box pt={20} display="flex" justifyContent="center" pb={20}>
                    <Typography variant="body2">No movements added.</Typography>
                </Box>
            )}
            <Box textAlign="center">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowSessionSelectionDialog(true)}
                    disabled={false}
                >
                    Add Session
                </Button>
                <Button
                    variant="contained"
                    style={{ gap: 10, padding: 10, margin: 20 }}
                    color="primary"
                    size="medium"
                    onClick={() => setShowMovementSelectionDialog(true)}
                >
                    Add Movement
                </Button>
                {showSessionSelectionDialog && (
                    <SelectContentModal
                        filters={{ 'filter[organization]': organizationUuid }}
                        open={showSessionSelectionDialog}
                        onClose={() => setShowSessionSelectionDialog(false)}
                        onSelect={(sessions) =>
                            onMovementsAddedFromSession(Object.values(sessions) || [])
                        }
                        fullScreen={false}
                        allowMultiple={false}
                    />
                )}
                <MovementSelectionDialog
                    open={showMovementSelectionDialog}
                    organizationUuid={organizationUuid}
                    multiple
                    onCancel={() => setShowMovementSelectionDialog(false)}
                    onMultipleMovementsSelected={(movements: Array<Movement>) => {
                        onMultipleMovementsAdded(movements);
                    }}
                />
            </Box>
        </>
    );
};

export default SessionMovementsWithGoals;
