import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ url: string }>>;

const createCheckoutAthletePhysicalItem = (
    return_url: string,
    seller_org_id: string,
    price_id_quantity_map: Record<string, number>,
): ReturnType => {
    return Request.post(`/athlete-users/billing/checkout-marketplace-physical-item-session`, {
        return_url,
        seller_org_id,
        price_id_quantity_map,
    });
};
export default createCheckoutAthletePhysicalItem;
