import { Unarchive } from '@mui/icons-material';
import { Card, CardHeader, IconButton } from '@mui/material';
import React from 'react';

interface Props {
    title: string;
    avatar: React.ReactNode;
    subtitle: string;
    onUnarchiveClicked: () => void;
}

const ArchivedCard: React.FC<React.PropsWithChildren<Props>> = ({
    title,
    avatar,
    subtitle,
    onUnarchiveClicked,
}: Props) => {
    const handleUnarchiveClicked = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        onUnarchiveClicked();
    };
    return (
        <Card style={{ width: '100%', marginBottom: 16 }}>
            <CardHeader
                avatar={avatar}
                title={title}
                subheader={subtitle}
                action={
                    <IconButton
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => handleUnarchiveClicked(e)}
                        aria-label="archive"
                        size="large"
                    >
                        <Unarchive />
                    </IconButton>
                }
            ></CardHeader>
        </Card>
    );
};

export default ArchivedCard;
