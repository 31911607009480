import { Box, CardContent, Chip, Collapse, IconButton, Typography } from '@mui/material';
import { Check, Add, ExpandMore, ExpandLess } from '@mui/icons-material';
import React, { useState } from 'react';
import { getDisplayNameFromGoalSetAttributeType, SetAttributeType } from 'utils';
import { v4 as uuidv4 } from 'uuid';

interface GoalChipSelectProps {
    selectedGoals: SetAttributeType[];
    onChipSelected: (criteria: SetAttributeType) => void;
    onChipDeleted: (criteria: SetAttributeType) => void;
}

//This component is used to show chip selection on session log and session edit
// A chip may represent reps, load, distance etc
// Goal is the value we enter for this chip when creating a session
const GoalChipSelect: React.FC<React.PropsWithChildren<GoalChipSelectProps>> = ({
    selectedGoals,
    onChipDeleted,
    onChipSelected,
}) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <>
            <IconButton onClick={() => setExpanded((e) => !e)} size="small">
                {expanded ? <ExpandMore /> : <ExpandLess />}
            </IconButton>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent style={{ padding: 0 }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{ gap: 10 }}
                        flexWrap="wrap"
                    >
                        {Object.values(SetAttributeType).map((goal: SetAttributeType) => {
                            const isSelected: boolean = selectedGoals.includes(goal);

                            return (
                                <Chip
                                    key={uuidv4()}
                                    label={
                                        <Typography variant="caption">
                                            {getDisplayNameFromGoalSetAttributeType(goal)}
                                        </Typography>
                                    }
                                    onClick={() => onChipSelected(goal)}
                                    icon={isSelected ? <Check /> : <Add />}
                                    onDelete={() => onChipDeleted(goal)}
                                    color={isSelected ? `primary` : `default`}
                                    size="small"
                                />
                            );
                        })}
                    </Box>
                </CardContent>
            </Collapse>
        </>
    );
};

export default GoalChipSelect;
