import { Avatar, Typography, Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { Variant } from '@mui/material/styles/createTypography';
import useTheme from '@mui/material/styles/useTheme';

export interface ThumbnailTitleChipProps {
    title: string;
    src?: string;
    thumbnailClassname?: string;
    avatarVariant?: 'circular' | 'rounded' | 'square';
    rootClass?: string;
    titleClassName?: string;
    titleTypographyVariant?: Variant | 'inherit';
    sx?: SxProps<Theme>;
}

const ThumbnailTitleChip = (props: ThumbnailTitleChipProps) => {
    const theme = useTheme();
    return (
        <Box
            className={props.rootClass}
            sx={{
                borderRadius: 1,
                padding: 2,
                margin: 1,
                boxShadow: theme.shadows[1],
                backgroundColor: theme.palette.background.paper,
                minWidth: '243px',
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                '&:hover': {
                    cursor: 'pointer',
                },
                [theme.breakpoints.down('sm')]: {
                    minWidth: '100%',
                    marginBottom: theme.spacing(3),
                },
                ...props.sx,
            }}
        >
            <Avatar
                variant={props.avatarVariant ?? 'square'}
                src={props.src ?? ''}
                className={props.thumbnailClassname}
                sx={{
                    width: '66px',
                    height: '48px',
                }}
            />
            <Typography
                variant={props.titleTypographyVariant ?? 'body1'}
                className={props.titleClassName}
                sx={{
                    color: theme.palette.text.primary,
                    width: '100%',
                    margin: theme.spacing(1.5, 2),
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            >
                {props.title ?? ''}
            </Typography>
        </Box>
    );
};

export default ThumbnailTitleChip;
