import { Box, SxProps, Theme } from '@mui/material';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useNavigationContext } from './Navigation/NavigationContext';
import FlashNotifications from './Partials/FlashNotifications';
import LayoutMetaHead from './Partials/LayoutMetaHead';
import LoadUserPermissions from './Partials/LoadUserPermissions';
import UploadProgress from './Partials/UploadProgress';

interface LayoutBoxProps {
    children: React.ReactNode;
    sx?: SxProps<Theme>; // This defines the type for the sx prop
}

const LayoutBox: React.FC<React.PropsWithChildren<LayoutBoxProps>> = ({ children, sx }) => {
    const { pageTitle } = useNavigationContext();

    const title = !isEmpty(pageTitle)
        ? `1TUL | ${capitalize(pageTitle)}`
        : '1TUL | Athlete Management Technologies';

    const defaultSx: SxProps<Theme> = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...sx, // This will override the default styles if sx is provided
    };

    return (
        <Box sx={defaultSx}>
            <LoadUserPermissions>
                <LayoutMetaHead title={title} />
                <FlashNotifications />
                <UploadProgress />
                {children}
            </LoadUserPermissions>
        </Box>
    );
};

export default LayoutBox;
