import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FullScreenDialogCard from '../components/HowToDialogCard';
import SprintDrilldown from '../media/SprintDrilldown.png';
import AthleteTrainDashboard from '../media/AthleteTrainDashboard.png';
import AthleteLearnDashboard from '../media/AthleteLearnDashboard.png';
import AthleteLogWorkout from '../media/AthleteLogWorkout.png';
import AthleteSharedDashboard from '../media/AthleteSharedDashboard.png';
import AthleteSessionList from '../media/AthleteSessionList.png';
import AthleteAssessmentList from '../media/AthleteAssessmentList.png';
import BeginAssessment from '../media/BeginAssessment.png';
import AthleteSprintDashboard from '../media/AthleteSprintDash.png';

function ForAthletes() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: 10, marginTop: 15 }}
                    justifyContent={'center'}
                >
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Athlete interaction is paramount to any successful athlete management
                        system.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL allows athletes to view their training data and content from any
                        device. Athletes can recieve assignments from their coaches and can also log
                        workouts, gps sessions, and learning content from their mobile device.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Receive and log assignments from coaches.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}

                <FullScreenDialogCard
                    image={AthleteTrainDashboard}
                    title="Athlete's Train Dashboard"
                    headline="Athlete's Train Dashboard"
                    description="Athlete's training assignments appear on dashboard and they can choose to preview, skip, or log the assignment."
                />
                <FullScreenDialogCard
                    image={AthleteLearnDashboard}
                    title="Athlete's Learn Dashboard"
                    headline="Athlete's Learn Dashboard"
                    description="Athlete's learning assignments appear on dashboard and they can choose to preview, skip, or log the assignment.."
                />
                <FullScreenDialogCard
                    image={AthleteLogWorkout}
                    title="Athlete's Workout Log"
                    headline="Athlete's Workout Log"
                    description="Athletes can quickly begin a workout log from the assignments dashboard."
                />
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Viewing and logging shared content from coaches.
                    </Typography>
                </Grid>

                <FullScreenDialogCard
                    image={AthleteSessionList}
                    title="Sessions List"
                    headline="Sessions List"
                    description="View any sessions coaches have shared with you.
                    Shared sessions can be logged just like assigned sessions."
                />
                <FullScreenDialogCard
                    imgPosition={'top'}
                    image={AthleteAssessmentList}
                    title="Assessments List"
                    headline="Assessments List"
                    description="View learning content that coaches have shared with you.
                    Shared assessments can be performed just like assigned assessments."
                />
                <FullScreenDialogCard
                    height={1120}
                    imgPosition={'top'}
                    image={BeginAssessment}
                    title="Learning Content"
                    headline="Learning Content"
                    description="Educate yourself to be the best athlete you can be."
                />
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Viewing reports from coaches and Sprint Dashboard with drill-down feature.
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={AthleteSharedDashboard}
                    title="Athlete Shared Dashboard"
                    headline="Athlete Shared Dashboard"
                    description="Coaches can create a dashboard containing several reports and share it with athletes."
                />

                <FullScreenDialogCard
                    image={AthleteSprintDashboard}
                    title="Sprint Database Dashboard"
                    headline="Sprint Database Dashboard"
                    description="View all your sprints in one place. Quickly drilldown to view the sprint in greater detail"
                />
                <FullScreenDialogCard
                    image={SprintDrilldown}
                    title="Individual Sprint View"
                    headline="Individual Sprint View"
                    description="Individual sprint view allows drill down into each instance speed in .1s increments."
                />
            </Grid>
        </>
    );
}

export default ForAthletes;
