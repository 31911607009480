import React from 'react';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import LandingPageNavBar from './LandingPageNavBar';

function LandingPageAbout() {
    const gradientTextStyles = {
        mt: 4,
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 8,
        maxWidth: 900,
        background: 'linear-gradient(to bottom, #fffffF, #A7ABB7)', // Example gradient
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent', // This makes the text transparent, showing only the background
        backgroundClip: 'text',
        color: 'transparent', // Fallback for browsers that do not support background-clip
        marginTop: 15,
    };

    return (
        <>
            <LandingPageNavBar />
            <Box
                sx={{ flex: 1, backgroundImage: 'linear-gradient(to bottom, #0066ff, #070A19)' }}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start" // This will distribute space evenly
                alignItems="center"
                height="100%"
            >
                <Typography sx={gradientTextStyles} variant="h3" component="div">
                    App End User Agreement
                </Typography>
                <Typography sx={gradientTextStyles} variant="h5" component="div">
                    This Mobile Application End User License Agreement (&quot;Agreement&quot;) is a
                    binding agreement between you (&quot;End User&quot; or &quot;you&quot;) and
                    Athlete Management Technologies, LLC. (&quot;Company&quot;). This Agreement
                    governs your use of the Company’s character development and holistic wellness
                    log and analytics service and mobile application on the Android or Apple Store
                    platforms, (including all related documentation, the &quot;Application&quot;).
                    The Application is licensed, not sold, to you.
                </Typography>
                <Typography sx={gradientTextStyles} variant="h6" component="div">
                    BY CLICKING THE &apos;AGREE&apos; BUTTON OR OTHERWISE DOWNLOADING, INSTALLING,
                    OR USING THE APPLICATION, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND
                    THIS AGREEMENT; (B) REPRESENT THAT YOU ARE OF LEGAL AGE TO ENTER INTO A BINDING
                    AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY
                    ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT DOWNLOAD, INSTALL, OR USE
                    THE APPLICATION AND DELETE IT FROM YOUR MOBILE DEVICE.
                </Typography>
                <Typography
                    sx={gradientTextStyles}
                    style={{ textAlign: 'left' }}
                    variant="body2"
                    component="div"
                >
                    License Grant. Subject to the terms of this Agreement, Company grants you a
                    limited, non-exclusive, and nontransferable license to: download, install, and
                    use the Application for your personal, non-commercial use on a single mobile
                    device owned or otherwise controlled by you (&apos;Mobile Device&apos;) strictly
                    in accordance with the Application&apos;s documentation. <br /> License
                    Restrictions. You shall not: copy the Application, except as expressly permitted
                    by this license; modify, translate, adapt, or otherwise create derivative works
                    or improvements, whether or not patentable, of the Application; reverse
                    engineer, disassemble, decompile, decode, or otherwise attempt to derive or gain
                    access to the source code of the Application or any part thereof; remove,
                    delete, alter, or obscure any trademarks or any copyright, trademark, patent, or
                    other intellectual property or proprietary rights notices from the Application,
                    including any copy thereof; rent, lease, lend, sell, sublicense, assign,
                    distribute, publish, transfer, or otherwise make available the Application, or
                    any features or functionality of the Application, to any third party for any
                    reason, including by making the Application available on a network where it is
                    capable of being accessed by more than one device at any time; or remove,
                    disable, circumvent, or otherwise create or implement any workaround to any copy
                    protection, rights management, or security features in or protecting the
                    Application. <br /> Reservation of Rights. You acknowledge and agree that the
                    Application is provided under license, and not sold, to you. You do not acquire
                    any ownership interest in the Application under this Agreement, or any other
                    rights thereto other than to use the Application in accordance with the license
                    granted, and subject to all terms, conditions, and restrictions, under this
                    Agreement. Company and its licensors and service providers reserve and shall
                    retain their entire right, title, and interest in and to the Application,
                    including all copyrights, trademarks, and other intellectual property rights
                    therein or relating thereto, except as expressly granted to you in this
                    Agreement. <br />
                    Collection and Use of Your Information. You acknowledge that when you download,
                    install, or use the Application, Company may use automatic means (including, for
                    example, cookies and web beacons) to collect information about your Mobile
                    Device and about your use of the Application. To use the Application or some of
                    the resources it offers, you may be asked to provide certain registration
                    details or other information. It is a condition of your use of the Application
                    that all the information you provide on the Application is correct, current, and
                    complete.
                    <br /> We may from time to time provide you with the option to register with the
                    Application through your account with certain third-party services such as
                    Google or Facebook (collectively, &apos;TPS&apos;). When you register through
                    your TPS account, you will be asked to login to the Application using your TPS
                    account credentials. By creating an Account via your account with a TPS, you are
                    allowing the Company to access your TPS account information and you are agreeing
                    to abide by the applicable terms and conditions of your TPS in your use of the
                    Application via such TPS. You have the option to disable the connection between
                    your Company account and TPS account at any time by accessing the TPS account
                    and disconnecting access to the Service. <br />
                    You agree that all information you provide to register with the Application or
                    otherwise, including, but not limited to, through the use of any interactive
                    features on the Application, is governed by our Privacy Policy
                    https://www.1tul.com/privacypolicy, and you consent to all actions we take with
                    respect to your information consistent with our Privacy Policy.
                    <br /> If you choose, or are provided with, a user name, password, or any other
                    piece of information as part of our security procedures, you must treat such
                    information as confidential, and you must not disclose it to any other person or
                    entity. You also acknowledge that your account is personal to you and agree not
                    to provide any other person with access to your account on the Application or
                    portions of it using your user name, password, or other security information.
                    You agree to notify us immediately of any unauthorized access to or use of your
                    user name or password or any other breach of security. You also agree to ensure
                    that you exit from your account at the end of each session. You should use
                    particular caution when accessing your account from a public or shared computer
                    so that others are not able to view or record your password or other personal
                    information. <br />
                    Updates. Company may from time to time in its sole discretion develop and
                    provide Application updates, which may include upgrades, bug fixes, patches,
                    other error corrections, and/or new features (collectively, including related
                    documentation, &apos;Updates&apos;). You agree that Company has no obligation to
                    provide any Updates or to continue to provide or enable any particular features
                    or functionality. Based on your Mobile Device settings, when your Mobile Device
                    is connected to the internet either the Application will automatically download
                    and install all available Updates; or you may receive notice of or be prompted
                    to download and install available Updates. <br />
                    You shall promptly download and install all Updates and acknowledge and agree
                    that the Application or portions thereof may not properly operate should you
                    fail to do so. You further agree that all Updates will be deemed part of the
                    Application and be subject to all terms and conditions of this Agreement.
                    <br />
                    Third-Party Materials. The Application may display, include, or make available
                    third-party content (including data, information, applications, and other
                    products, services, and/or materials) or provide links to third-party websites
                    or services, including through third-party advertising (&apos;Third-Party
                    Materials&apos;). You acknowledge and agree that Company is not responsible for
                    Third-Party Materials, including their accuracy, completeness, timeliness,
                    validity, copyright compliance, legality, decency, quality, or any other aspect
                    thereof. Company does not assume and will not have any liability or
                    responsibility to you or any other person or entity for any Third-Party
                    Materials. Third-Party Materials and links thereto are provided solely as a
                    convenience to you, and you access and use them entirely at your own risk and
                    subject to such third parties&apos; terms and conditions. Sharing Features. This
                    Application may provide certain sharing or other social media features that
                    enable you to: Link from the Application or third-party services to certain
                    content generated by the Application, including your Character Card, and
                    information contained in your Character Card. Send emails or other
                    communications with certain content, or links to certain content, on the
                    Application. Cause limited portions of content on the Application to be
                    displayed or appear to be displayed on the Application or certain third-party
                    services. <br />
                    You may use these features solely as they are provided by us and solely with
                    respect to the content they are displayed with. Subject to the foregoing, you
                    must not cause the Application or portions of it to be displayed on, or appear
                    to be displayed by, any other site, for example, framing, deep linking, or
                    in-line linking, or otherwise take any action with respect to the content or
                    materials on the Application that is inconsistent with any other provision of
                    this EULA. We may disable all or any sharing features and any links at any time
                    without notice at our discretion. As with any profile information, you should
                    exercise caution when sharing content with third parties.
                    <br /> Term and Termination. The term of Agreement commences when you download
                    the Application and acknowledge your acceptance and will continue in effect
                    until terminated by you or Company. You may terminate this Agreement by deleting
                    the Application and all copies thereof from your Mobile Device. Company may
                    terminate this Agreement at any time without notice if it ceases to support the
                    Application, which Company may do in its sole discretion. Company may, in its
                    sole discretion, suspend, disable, or delete your account (or any part thereof)
                    or block or remove any content that you submitted, for any lawful reason,
                    including if Company determines that you have violated these Terms or that your
                    conduct or content would tend to damage Company’s reputation or goodwill. Upon
                    termination all rights granted to you under this Agreement will also terminate;
                    and you must cease all use of the Application and delete all copies of the
                    Application from your Mobile Device and account. Termination will not limit
                    either party’s rights or remedies at law or in equity. <br />
                    Disclaimer of Warranties. THE APPLICATION IS PROVIDED TO END USER &apos;AS
                    IS&apos; AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE
                    MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON
                    BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE
                    PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED,
                    STATUTORY, OR OTHERWISE, WITH RESPECT TO THE APPLICATION, INCLUDING ALL IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                    NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE
                    OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING,
                    COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY
                    KIND THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED
                    RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS,
                    OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY
                    STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE
                    CORRECTED. <br />
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR LIMITATIONS ON IMPLIED
                    WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER,
                    SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
                    <br />
                    Limitation of Liability. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN
                    NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE
                    LICENSORS OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO
                    YOUR USE OF OR INABILITY TO USE THE APPLICATION OR THE CONTENT AND SERVICES.
                    SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL
                    OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. <br />
                    Indemnification. You agree to indemnify, defend, and hold harmless Company and
                    its officers, directors, employees, agents, affiliates, successors, and assigns
                    from and against any and all losses, damages, liabilities, deficiencies, claims,
                    actions, judgments, settlements, interest, awards, penalties, fines, costs, or
                    expenses of whatever kind, including reasonable attorneys&apos; fees, arising
                    from or relating to your use or misuse of the Application or your breach of this
                    Agreement, including but not limited to the content you submit or make available
                    through this Application. <br />
                    Geographic Restrictions. The Application is based in and operates from servers
                    located in the United States. We make no claims that the Application or any of
                    its content is accessible or appropriate outside of the United States. Access to
                    the Application may not be legal by certain persons or in certain countries. If
                    you access the Service from outside the United States, you do so on your own
                    initiative and are responsible for compliance with local laws. <br />
                    Export Regulation. The Application may be subject to US export control laws,
                    including the Export Control Reform Act and its associated regulations. You
                    shall not, directly or indirectly, export, re-export, or release the Application
                    to, or make the Application accessible from, any jurisdiction or country to
                    which export, re-export, or release is prohibited by law, rule, or regulation.
                    You shall comply with all applicable federal laws, regulations, and rules, and
                    complete all required undertakings (including obtaining any necessary export
                    license or other governmental approval), prior to exporting, re-exporting,
                    releasing, or otherwise making the Application available outside the US.
                    <br />
                    Severability. If any provision of this Agreement is illegal or unenforceable
                    under applicable law, the remainder of the provision will be amended to achieve
                    as closely as possible the effect of the original term and all other provisions
                    of this Agreement will continue in full force and effect. <br />
                    Governing Law. This Agreement is governed by and construed in accordance with
                    the internal laws of the State of California without giving effect to any choice
                    or conflict of law provision or rule. Any legal suit, action, or proceeding
                    arising out of or related to this Agreement or the Application shall be
                    instituted exclusively in the federal courts of the United States or the courts
                    of the State of California in each case located in San Diego County. You waive
                    any and all objections to the exercise of jurisdiction over you by such courts
                    and to venue in such courts. Entire Agreement. This Agreement and our Privacy
                    Policy constitute the entire agreement between you and Company with respect to
                    the Application and supersede all prior or contemporaneous understandings and
                    agreements, whether written or oral, with respect to the Application. <br />
                    Waiver. No failure to exercise, and no delay in exercising, on the part of
                    either party, any right or any power hereunder shall operate as a waiver
                    thereof, nor shall any single or partial exercise of any right or power
                    hereunder preclude further exercise of that or any other right hereunder. In the
                    event of a conflict between this Agreement and any applicable purchase or other
                    terms, the terms of this Agreement shall govern. <br />
                    Your Comments and Concerns. This Service is operated by Athlete Management
                    Technologies, LLC. All other feedback, comments, requests for technical support,
                    and other communications relating to the Service should be directed to:
                    support@1tul.com.
                </Typography>

                <Button
                    sx={gradientTextStyles}
                    variant="contained"
                    size={'large'}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                    Back to the top
                </Button>
            </Box>
        </>
    );
}

export default LandingPageAbout;
