import { Box, Grid, Skeleton } from '@mui/material';
import React, { FC } from 'react';

const AssessmentListSkeleton: FC<React.PropsWithChildren<unknown>> = () => (
    <>
        <Box my={16}>
            <Grid container spacing={4}>
                <Grid container spacing={4}>
                    {[...Array(4)].map((_, idx) => (
                        <Grid key={`skeletona-${idx}`} item xs={6} sm={3}>
                            <Skeleton animation="wave" variant="rectangular" height={125} />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box>
        <Grid container spacing={4}>
            <Grid container spacing={4}>
                {[...Array(4)].map((_, idx) => (
                    <Grid key={`skeletonb-${idx}`} item xs={6} sm={3}>
                        <Skeleton animation="wave" variant="rectangular" height={125} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    </>
);

export default AssessmentListSkeleton;
