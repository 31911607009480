import { Paper } from '@mui/material';
import React from 'react';

interface GradientPaperProps {
    children: React.ReactNode;
    height?: number | string;
    borderRadius?: number | string;
}

const ChartGradientPaper = ({ children, height = 400, borderRadius = 20 }: GradientPaperProps) => {
    return (
        <Paper
            style={{
                width: '100%',
                height: height,
                backgroundColor: '#D9DADF',
                borderRadius: borderRadius,
                border: '1px solid #A7ABB7',
                background: 'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                padding: 20,
            }}
        >
            {children}
        </Paper>
    );
};

export default ChartGradientPaper;
