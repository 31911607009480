import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<
    AxiosResponse<{
        unattested_count: number;
    }>
>;

const getUserNotificationCounts = (): ReturnType => Request.get(`/users/notification-counts`);

export default getUserNotificationCounts;
