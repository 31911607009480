import { Avatar, AvatarProps } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React from 'react';

interface AvatarWithInitialsProps extends AvatarProps {
    firstname?: string;
    lastname?: string;
    showBorder?: boolean;
    src?: string;
}

const AvatarWithInitials = ({
    src,
    firstname = '',
    lastname = '',
    showBorder = false,
    ...props
}: AvatarWithInitialsProps) => {
    function getNameInitials(firstName: string = '', lastName: string = '') {
        return (firstName[0] ?? '').toUpperCase() + (lastName[0] ?? '').toUpperCase();
    }

    const theme = useTheme();
    return src ? (
        <Avatar
            src={src}
            variant={'rounded'}
            sx={{
                background: '#BDBDBD',
                width: 30,
                height: 30,
                border: showBorder ? `2px solid ${theme.palette.primary.light}` : 'none',
            }}
            {...props}
        />
    ) : (
        <Avatar
            variant={'rounded'}
            sx={{
                background: '#BDBDBD',
                width: 30,
                height: 30,
                border: showBorder ? `2px solid ${theme.palette.primary.light}` : 'none',
            }}
            {...props}
        >
            {getNameInitials(firstname, lastname)}
        </Avatar>
    );
};

export default AvatarWithInitials;
