import React from 'react';
import { Container, Grid } from '@mui/material';

interface Props {
    side: React.ReactNode;
    body: React.ReactNode;
}

const TwoColumnLayout: React.FC<React.PropsWithChildren<Props>> = ({ side, body }: Props) => {
    return (
        <Container maxWidth="xl">
            <Grid container>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{
                        padding: 16,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {side}
                </Grid>
                <Grid item xs={12} sm={8} style={{ padding: 16 }}>
                    {body}
                </Grid>
            </Grid>
        </Container>
    );
};

export default TwoColumnLayout;
