import UploadsSnackBar from 'components/Dialogs/UploadsSnackBar';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPartialUploadList, getUploadsClosed } from 'redux/reducers/media';

const UploadProgress = () => {
    const uploads = useSelector(getPartialUploadList);
    const uploadsClosed = useSelector(getUploadsClosed);

    if (Object.keys(uploads).length > 0 && !uploadsClosed) {
        return <UploadsSnackBar uploads={uploads}></UploadsSnackBar>;
    }

    return null;
};

export default UploadProgress;
