import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { OrgSubscription, UserSubscription } from './subscriptions.types';
import { SubscriptionItemChangeRequest } from '../../../../app.types';

interface OrgSubscriptionPaginatedApiResponse {
    data: Array<OrgSubscription>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

interface UserSubscriptionPaginatedApiResponse {
    data: Array<UserSubscription>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

interface SubscriptionItemChangeRequestPaginatedApiResponse {
    data: Array<SubscriptionItemChangeRequest>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type OrgReturnTypeCollection = Promise<AxiosResponse<OrgSubscriptionPaginatedApiResponse>>;
type UserReturnTypeCollection = Promise<AxiosResponse<UserSubscriptionPaginatedApiResponse>>;
type SubscriptionChangeRequestReturnTypeCollection = Promise<
    AxiosResponse<SubscriptionItemChangeRequestPaginatedApiResponse>
>;

interface GetOrgSubscriptionParams {
    name?: string;
    organization_uuid?: string;
    stripe_status?: 'active' | 'canceled' | 'past_due';
    sort?: 'name' | 'organization_name' | '-created_at' | '-updated_at';
    between_dates?: Array<Date | undefined>;
    page?: number;
    per_page?: number;
}

interface GetUserSubscriptionParams {
    name?: string;
    user_name?: string;
    stripe_status?: 'active' | 'canceled' | 'past_due';
    sort?: 'name' | 'user_name' | '-created_at' | '-updated_at';
    between_dates?: Array<Date | undefined>;
    page?: number;
    per_page?: number;
}

interface GetSubscriptionItemChangeRequestParams {
    organization_uuid?: string;
    status?: 'requested' | 'completed' | 'rejected';
    page?: number;
    per_page?: number;
    sort?: string;
}

interface UpdateSubscriptionItemChangeRequestParams {
    status: string;
    notes: string;
}

const getOrgSubscriptions = (params?: GetOrgSubscriptionParams): OrgReturnTypeCollection => {
    const filterParams = {
        'filter[organization_uuid]': params?.organization_uuid,
        'filter[name]': params?.name,
        'filter[stripe_status]': params?.stripe_status,
        sort: params?.sort,
        //between_dates: params?.between_dates,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/subscriptions/organization`, { params: filterParams });
};

const getUserSubscriptions = (params?: GetUserSubscriptionParams): UserReturnTypeCollection => {
    const filterParams = {
        'filter[user_name]': params?.user_name,
        'filter[name]': params?.name,
        'filter[stripe_status]': params?.stripe_status,
        sort: params?.sort,
        //between_dates: params?.between_dates,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/subscriptions/user`, { params: filterParams });
};

const getSubscriptionItemChangeRequests = (
    params?: GetSubscriptionItemChangeRequestParams,
): SubscriptionChangeRequestReturnTypeCollection => {
    console.log(params);
    const filterParams = {
        'filter[organization_uuid]': params?.organization_uuid,
        'filter[status]': params?.status,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/subscription-items/change-requests`, { params: filterParams });
};

const updateSubscriptionItemChangeRequest = (
    uuid: string,
    params: UpdateSubscriptionItemChangeRequestParams,
): SubscriptionChangeRequestReturnTypeCollection => {
    return Request.put(`admin/subscription-items/change-requests/${uuid}`, {
        status: params.status,
        notes: params.notes,
    });
};

export {
    getOrgSubscriptions,
    getUserSubscriptions,
    getSubscriptionItemChangeRequests,
    updateSubscriptionItemChangeRequest,
};
export type {
    GetOrgSubscriptionParams,
    GetUserSubscriptionParams,
    GetSubscriptionItemChangeRequestParams,
};
