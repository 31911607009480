import * as React from 'react';
import { Grid, Typography } from '@mui/material';

import CompareSprint from 'images/CompareSprints.png';
import TimeSegmentAnalysis from 'images/TimeSegmentAnalysis.png';
import AIVideoAnalysis from 'images/AIVideoAnalysis.png';
import LearningContent from 'images/LearningContent.png';
import ShareReports from 'images/ShareReports.png';
import ShareSessions from 'images/ShareSessions.png';
import TrackingPlayerLoad from 'images/TrackingPlayerLoad.png';
import GPSSessionSummary from 'images/GPSSessionSummary.png';
import DataTableView from 'images/DataTableView.png';
import FullScreenDialogCard from '../components/HowToDialogCard';
import MovementLibrary from 'images/MovementLibrary.png';
import SessionsList from 'images/SessionsList.png';
import MediaLibrary from 'images/MediaLibrary.png';

function WhatIs1Tul() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL to process, organise, analyse, and share training data to help
                        athletes, training professionals, and organizations prove performance
                        improvement.{' '}
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Speed is our specialty.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}

                <FullScreenDialogCard
                    image={CompareSprint}
                    title="Compare Sprint"
                    headline="Sprint Comparison"
                    description="Compare sprint performance between athletes, sessions, and types of
                                training."
                />
                <FullScreenDialogCard
                    image={TimeSegmentAnalysis}
                    title="Time Segment Analysis"
                    headline="Time Segment Analysis"
                    description="GPS derived one second segments provide the best evidence to analyse
                                all phases of a sprint."
                />
                <FullScreenDialogCard
                    image={AIVideoAnalysis}
                    title="AI Video Analysis"
                    headline="AI Video Analysis"
                    description="Capture stride, flexion, and extension asymmetries. Also includes 10
                                yard split times with average speed."
                />
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        We support learning and collaboration.{' '}
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={LearningContent}
                    title="Learning Content"
                    headline="Learning Management"
                    description="Create and share learning content with athletes and coaches. Track
                                who has completed the content and their knowledge retention."
                />
                <FullScreenDialogCard
                    image={ShareReports}
                    title="Share Reports"
                    headline="Sharing Reports"
                    description="Share reports via a link and password or QR. Assign permissions
                                through the link to limit access and editing rights."
                />
                <FullScreenDialogCard
                    image={ShareSessions}
                    title="Share Sessions"
                    headline="Sharing Sessions"
                    description="Share Sessions inside and outside your organization via a link and
                                password or QR. Assign permissions through the link to limit access
                                and editing rights."
                />

                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        We help you monitor workload and track progress.{' '}
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={TrackingPlayerLoad}
                    title="Tracking Player Load"
                    headline="Tracking Team Player Load"
                    description="Track team player load and compare it to individual player load.
                                Compare practice and game loads to better monitor and plan
                                practices."
                />
                <FullScreenDialogCard
                    image={GPSSessionSummary}
                    title="GPS Session Summary"
                    headline="GPS Session Summaries"
                    description="Track GPS session summaries and compare them to other sessions. Sort
                                metric data and compare athletes against each other and past
                                performances."
                />
                <FullScreenDialogCard
                    image={DataTableView}
                    title="Data Table View"
                    headline="Data Table View"
                    description="Quickly nagigate all your data in data table view. Sort and filter
                                to find the data you need. Quickly drill down to the sprint or
                                training session."
                />
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        We help you keep all your training material in one spot.{' '}
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={MovementLibrary}
                    title="Movement Library"
                    headline="Movement Library"
                    description="Develop your own movement library and share it with your athletes.
                                Select which metrics you want to track and how you want to track
                                them."
                />
                <FullScreenDialogCard
                    image={SessionsList}
                    title="Training Sessions and Drills"
                    headline="Training Sessions and Drills"
                    description="Combine your movement library into training sessions and drills.
                                Assign sessions and drills to your athletes or share them with
                                other coaches."
                />
                <FullScreenDialogCard
                    image={MediaLibrary}
                    title="Media Library"
                    headline="Media Library"
                    description="Build your media library and quickly reuse media accross the entire
                                platform. Reuse in learning content, sessions, and drills."
                />
            </Grid>
        </>
    );
}

export default WhatIs1Tul;
