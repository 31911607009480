import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Snackbar,
    Stack,
    Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { GridColDef } from '@mui/x-data-grid';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridActionsCellItemProps,
    GridRenderCellParams,
    GridSortItem,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import Lottie from 'lottie-react';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllIntegrations } from '../../api/integrations.api';
import {
    addMarketplaceItemForResale,
    deleteItem,
    getMyMarketplaceItems,
    getResellableMarketplaceItems,
    removeMarketplaceItemForResale,
} from '../../api/marketplace.api';
import {
    createStripeAccountLink,
    createStripeExpressAccount,
    createStripeLoginLink,
    getStripeExpressAccount,
} from '../../api/organizations.api';
import { formatCurrency } from '../../app.functions';
import { Integration, MarketplaceItem, StripeAccount } from '../../app.types';
import { TypographyTitle } from '../../components';
import ChooseFormModal from '../../components/Dialogs/ChooseFormModal';
import ChooseSessionModal from '../../components/Dialogs/ChooseSessionModal';
import CreateMarketplaceItemDialog from '../../components/Dialogs/CreateMarketplaceItemDialog';
import EditMarketplaceItem from '../../components/Forms/EditMarketplaceItem';
import CustomPagination from '../../components/Functional/CustomPagination';
import GetStarted from '../../lottie-animations/GetStarted.json';
import IAgree from '../../lottie-animations/IAgree.json';
import Terms from '../../lottie-animations/TermsAndConditions.svg';
import { Form, Organization, PaginationLink, Session } from '../../utils';
import { Block, OpenInNew, PointOfSale } from '@mui/icons-material';

type MarketplaceItemRow = {
    id: string;
    organization_name: string;
    organization_slug: string;
    title: string;
    description: string;
    type: string;
    image_url: string;
    sales_type: string;
    price: number;
    resell_status: string;
};

const MarketplaceSettingsPage = ({ organization }: { organization: Organization }) => {
    const [items, setMarketplaceItems] = React.useState<MarketplaceItem[]>([]);
    const [resellableItems, setResellableMarketplaceItems] = React.useState<MarketplaceItem[]>([]);
    const [myMarketplaceItemRows, setMyMarketplaceItemRows] = React.useState<MarketplaceItemRow[]>(
        [],
    );
    const [resellableMarketplaceItemRows, setResellableMarketplaceItemRows] = React.useState<
        MarketplaceItemRow[]
    >([]);
    const [chooseSessionModalOpen, setChooseSessionModalOpen] = React.useState<
        'session' | 'form'
    >();
    const [createItemFromEntityOpen, setCreateItemFromEntityOpen] = React.useState<{
        type: 'session' | 'form';
        uuid: string;
    }>();
    const [snackMessage, setSnackMessage] = React.useState<string | null>(null);
    const [loadingMyItems, setLoadingMyItems] = React.useState<boolean>(false);
    const [loadingResellableItems, setLoadingResellableItems] = React.useState<boolean>(false);
    const [integrations, setIntegrations] = React.useState<Integration[]>([]);
    const [stripeAccount, setStripeAccount] = React.useState<StripeAccount>();
    const [hasIntegratedStripe, setHasIntegratedStripe] = React.useState<boolean>(false);
    const [integrationsLoaded, setIntegrationsLoaded] = React.useState(false);
    const [settingUpMarketplace, setSettingUpMarketplace] = React.useState<boolean>(false);
    const [selectedItem, setSelectedItem] = React.useState<MarketplaceItem | null>(null);
    const [selectedItemForDelete, setSelectedItemForDelete] =
        React.useState<MarketplaceItem | null>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
    const [myItemLinks, setMyItemLinks] = useState<PaginationLink>();
    const [resellableItemLinks, setResellableItemLinks] = useState<PaginationLink>();
    const [resellItemModalOpen, setResellItemModalOpen] = useState<boolean>(false);
    const [selectedResellItemUuid, setSelectedResellItemUuid] = useState<string>('');
    const [stopResellItemModalOpen, setStopResellItemModalOpen] = useState<boolean>(false);
    const [selectedStopResellItemUuid, setSelectedStopResellItemUuid] = useState<string>('');

    const [myItemsQuery, setMyItemsQuery] = useState<
        Record<'page' | 'sort', string | number | string[]>
    >({
        page: 1,
        sort: ['-updated_at'],
    });
    const [resellableItemsQuery, setResellableItemsQuery] = useState<
        Record<'page' | 'sort', string | number | string[]>
    >({
        page: 1,
        sort: ['-updated_at'],
    });

    const mapMarketplaceItemsToMarketplaceItemRows = (
        item: MarketplaceItem[],
    ): MarketplaceItemRow[] => {
        return item.map((item) => {
            return {
                id: item.uuid,
                organization_name: item.organization?.name || '',
                organization_slug: item.organization?.slug || '',
                title: item.marketplaceable?.title || '',
                description: item.marketplaceable?.description || '',
                image_url: item.marketplaceable?.image_urls.avatar || '',
                sales_type:
                    item.sales_type === 'business_to_business'
                        ? 'Organization to Organization'
                        : 'Organization to Athlete',
                price: item.price,
                resell_status: item.resell_status,
                is_reselling: item.organization_uuid !== organization.uuid,
                type: item.marketplaceable_type,
                created_at: item.created_at,
                updated_at: item.updated_at,
            };
        });
    };

    const myMarketplaceItemsColumns: GridColDef[] = [
        {
            field: 'organization_name',
            headerName: 'Creator',
            sortable: false,
            filterable: false,
            width: 300,
            groupable: false,
        },
        {
            field: 'image_url',
            headerName: 'Image',
            width: 75,
            renderCell: (params: GridRenderCellParams<any, string>) => (
                <Avatar variant={'square'} src={params.value} sx={{ width: 50, height: 50 }} />
            ),
            sortable: false,
            filterable: false,
            groupable: false,
        },
        {
            field: 'title',
            headerName: 'Title',
            sortable: false,
            filterable: false,
            width: 240,
            groupable: false,
        },
        {
            field: 'sales_type',
            headerName: 'Sales Type',
            width: 250,
            groupable: false,
        },
        {
            field: 'price',
            headerName: 'Price',
            width: 100,
            valueFormatter: (value?: number) => {
                return formatCurrency({ amount: (value ?? 0) / 100, currency: 'USD' });
            },
            groupable: false,
        },
        {
            field: 'resell_status',
            headerName: 'Reselling Enabled',
            width: 150,
            groupable: false,
        },
        {
            field: 'updated_at',
            headerName: 'Last Modified',
            width: 200,
            renderCell: (params: GridRenderCellParams<any, number>) =>
                params.value
                    ? DateTime.fromSeconds(params.value).toLocaleString(DateTime.DATETIME_MED)
                    : '-',
            disableColumnMenu: true,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 150,
            getActions: (params) => {
                let actions: React.ReactElement<GridActionsCellItemProps>[] = [];

                if (params.row.is_reselling) {
                    actions.push(
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<Block />}
                            onClick={() => openStopResellItemModal(params.row.id)}
                        >
                            STOP RESELLING
                        </Button>,
                    );
                } else {
                    actions.push(
                        <GridActionsCellItem
                            key={'delete'}
                            icon={<DeleteIcon />}
                            onClick={async () => {
                                const marketplaceItem = convertRowToMarketplaceItem(params.row);
                                setSelectedItemForDelete(marketplaceItem ?? null);
                                console.log('delete', params.row.id);
                                setDeleteDialogOpen(true);
                            }}
                            label="Delete"
                            showInMenu
                        />,
                    );
                    actions.push(
                        <GridActionsCellItem
                            key={'rename'}
                            icon={<EditIcon />}
                            onClick={() => {
                                const marketplaceItem = convertRowToMarketplaceItem(params.row);

                                if (marketplaceItem) {
                                    setSelectedItem(marketplaceItem);
                                } else {
                                    setSnackMessage('Error updating item');
                                    setSelectedItem(null);
                                }
                            }}
                            label="Edit"
                            showInMenu
                        />,
                    );
                }

                return actions;
            },
        },
    ];

    const resellableMarketplaceItemsColumns: GridColDef[] = [
        {
            field: 'organization_name',
            headerName: 'Creator',
            sortable: false,
            filterable: false,
            width: 300,
            groupable: false,
        },
        {
            field: 'image_url',
            headerName: 'Image',
            width: 75,
            renderCell: (params: GridRenderCellParams<any, string>) => (
                <Avatar variant={'square'} src={params.value} sx={{ width: 50, height: 50 }} />
            ),
            sortable: false,
            filterable: false,
            groupable: false,
        },
        {
            field: 'title',
            headerName: 'Title',
            sortable: false,
            filterable: false,
            width: 350,
            groupable: false,
        },
        {
            field: 'sales_type',
            headerName: 'Sales Type',
            width: 250,
            groupable: false,
        },
        {
            field: 'price',
            headerName: 'Price',
            width: 100,
            valueFormatter: (value?: number) => {
                return formatCurrency({ amount: (value ?? 0) / 100, currency: 'USD' });
            },
            groupable: false,
        },
        {
            field: 'updated_at',
            headerName: 'Last Modified',
            width: 250,
            renderCell: (params: GridRenderCellParams<any, number>) =>
                params.value
                    ? DateTime.fromSeconds(params.value).toLocaleString(DateTime.DATETIME_MED)
                    : '-',
            disableColumnMenu: true,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 250,
            sortable: false,
            renderCell: (params: GridRenderCellParams<MarketplaceItemRow>) => (
                <>
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<OpenInNew />}
                        style={{ marginRight: 8 }}
                        onClick={() => {
                            const url = `${process.env.REACT_APP_URL}/marketplace/${params.row.organization_slug}/${params.row.id}`;
                            window.open(url, '_blank');
                        }}
                    >
                        VIEW
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<PointOfSale />}
                        onClick={() => openResellItemModal(params.row.id)}
                    >
                        RESELL
                    </Button>
                </>
            ),
        },
    ];

    const openResellItemModal = (itemUuid: string) => {
        setResellItemModalOpen(true);
        setSelectedResellItemUuid(itemUuid);
    };

    const openStopResellItemModal = (itemUuid: string) => {
        setStopResellItemModalOpen(true);
        setSelectedStopResellItemUuid(itemUuid);
    };

    const handleSortMyMarketplaceItemsModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            setMyItemsQuery((o) => ({
                ...o,
                sort: sortModel.map((item: GridSortItem) => {
                    if (item.sort === 'asc') {
                        return `${item.field}`;
                    }
                    return `-${item.field}`;
                }),
            }));
        },
        [],
    );

    const handleSortResellableMarketplaceItemsModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            setResellableItemsQuery((o) => ({
                ...o,
                sort: sortModel.map((item: GridSortItem) => {
                    if (item.sort === 'asc') {
                        return `${item.field}`;
                    }
                    return `-${item.field}`;
                }),
            }));
        },
        [],
    );

    useEffect(() => {
        const fetchItems = async () => {
            setLoadingMyItems(true);
            try {
                const response = await getMyMarketplaceItems(organization.uuid, myItemsQuery);
                setMarketplaceItems(response.data.data);
                setMyItemLinks(response.data.links);
            } catch (e) {
                setSnackMessage('Error fetching marketplace items');
            } finally {
                setLoadingMyItems(false);
            }
        };
        const fetchResellableItems = async () => {
            setLoadingResellableItems(true);
            try {
                const response = await getResellableMarketplaceItems(
                    organization.uuid,
                    resellableItemsQuery,
                );
                console.log('resellable items', response.data.data);
                setResellableMarketplaceItems(response.data.data);
                setResellableItemLinks(response.data.links);
            } catch (e) {
                setSnackMessage('Error fetching resellable marketplace items');
            } finally {
                setLoadingResellableItems(false);
            }
        };
        const fetchIntegrations = async () => {
            try {
                const response = await getAllIntegrations(organization.uuid);
                setIntegrations(response.data);
                setIntegrationsLoaded(true);
            } catch (e) {
                setSnackMessage('Error while loading required information to display this page');
            }
        };
        fetchItems();
        fetchResellableItems();
        fetchIntegrations();
    }, [organization, myItemsQuery, resellableItemsQuery]);

    useEffect(() => {
        setMyMarketplaceItemRows(mapMarketplaceItemsToMarketplaceItemRows(items));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        setResellableMarketplaceItemRows(mapMarketplaceItemsToMarketplaceItemRows(resellableItems));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resellableItems]);

    useEffect(() => {
        setHasIntegratedStripe(
            integrations.filter((i) => i.api_type === 'stripe_express').length > 0 ?? false,
        );
    }, [integrations]);

    useEffect(() => {
        const fetchStripeAccountStatus = async () => {
            try {
                const response = await getStripeExpressAccount(organization.uuid);
                setStripeAccount(response.data);
            } catch (e) {
                setSnackMessage('Error while loading required information to display this page');
            }
        };

        // if we have a stripe account, we need to fetch the status
        if (hasIntegratedStripe) {
            fetchStripeAccountStatus();
        }
    }, [hasIntegratedStripe, organization.uuid]);

    const convertRowToMarketplaceItem = useCallback(
        (row: MarketplaceItemRow): MarketplaceItem | undefined => {
            if (items.find((i) => i.uuid === row.id)) {
                return items.find((i) => i.uuid === row.id) as MarketplaceItem;
            }
        },
        [items],
    );

    const [termsModalOpen, setTermsModalOpen] = React.useState<boolean>(false);
    const handleTermsModalToggle = () => {
        setTermsModalOpen(!termsModalOpen);
    };

    const addMarketplaceItemForResellerToSell = async () => {
        try {
            await addMarketplaceItemForResale(selectedResellItemUuid, organization.uuid);
            setResellItemModalOpen(false);
            setSnackMessage('Item added for resale');
            const res = await getResellableMarketplaceItems(
                organization.uuid,
                resellableItemsQuery,
            );
            setResellableMarketplaceItems(res.data.data);
            const res2 = await getMyMarketplaceItems(organization.uuid, myItemsQuery);
            setMarketplaceItems(res2.data.data);
        } catch (e) {
            setSnackMessage('Error adding item for resale');
        }
    };

    const removeMarketplaceItemForResellerToSell = async () => {
        try {
            await removeMarketplaceItemForResale(selectedStopResellItemUuid, organization.uuid);
            setStopResellItemModalOpen(false);
            setSnackMessage('Item removed for resale');
            const res = await getResellableMarketplaceItems(
                organization.uuid,
                resellableItemsQuery,
            );
            setResellableMarketplaceItems(res.data.data);
            const res2 = await getMyMarketplaceItems(organization.uuid, myItemsQuery);
            setMarketplaceItems(res2.data.data);
        } catch (e) {
            setSnackMessage('Error removing item for resale');
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                    margin: 6,
                }}
            >
                <img src={Terms} alt={'Terms and Conditions'} style={{ width: 24, height: 24 }} />
                <Typography>
                    By using the marketplace you agree to these
                    <Button color="primary" onClick={handleTermsModalToggle}>
                        terms and conditions.
                    </Button>
                </Typography>
            </Box>
            <Dialog open={termsModalOpen} onClose={handleTermsModalToggle}>
                <DialogTitle>Terms and Conditions</DialogTitle>
                <DialogContent>
                    <Typography paragraph>
                        <strong>Welcome to our Marketplace</strong>, an integral part of our
                        expansive multi-level marketing platform. By participating in our
                        Marketplace, you have the unique opportunity to both sell your own content
                        and also the content of others, earning a substantial portion of the revenue
                        generated from these sales.
                    </Typography>

                    <Typography paragraph>
                        <strong>Revenue Sharing for Selling Your Own Content:</strong> When you sell
                        content that you have created, you are entitled to receive 75% of the total
                        revenue generated from these sales. This incentivizes the creation and
                        promotion of your own high-quality content on our platform.
                    </Typography>

                    <Typography paragraph>
                        <strong>Earnings from Selling Others&apos; Content:</strong> If you choose
                        to sell content created by other members of our platform, you will earn 25%
                        of the revenue from such sales. This aspect of our program encourages the
                        promotion and distribution of a diverse range of content available on our
                        Marketplace.
                    </Typography>

                    <Typography paragraph>
                        <strong>Compensation When Others Sell Your Content:</strong> In the event
                        that another user sells your content, you will receive 50% of the revenue
                        from these sales. This arrangement ensures that you benefit from the broader
                        distribution of your content by other participants in the Marketplace.
                    </Typography>

                    <Typography paragraph>
                        <strong>Payment Processing and Distribution:</strong> All payments on our
                        platform are processed securely through Stripe. Please be aware that there
                        is a standard 2-week delay in the distribution of payments. This delay is
                        necessary to ensure accurate and secure processing of all transactions
                        within the Marketplace.
                    </Typography>

                    <Typography paragraph>
                        By engaging with our Marketplace, you agree to these terms and conditions.
                        We encourage you to actively participate, both in creating your own content
                        and in promoting the content of others, to maximize the benefits of our
                        multi-level marketing system. We are committed to fair and transparent
                        operations and are excited to see you grow with our platform.
                    </Typography>
                </DialogContent>

                <Lottie
                    animationData={IAgree}
                    loop={true}
                    autoplay={true}
                    style={{
                        width: '300px', // Adjust the width and height as needed
                        height: '300px',
                        cursor: 'pointer',
                    }}
                    onClick={handleTermsModalToggle}
                />
            </Dialog>

            {createItemFromEntityOpen && (
                <CreateMarketplaceItemDialog
                    onClose={() => {
                        setCreateItemFromEntityOpen(undefined);
                    }}
                    open={true}
                    onSave={(item) => {
                        setMarketplaceItems((oldItems) => [...oldItems, item]);
                        setCreateItemFromEntityOpen(undefined);
                    }}
                    onError={(message) => {
                        setCreateItemFromEntityOpen(undefined);
                        setSnackMessage(message);
                    }}
                    organization={organization}
                    uuid={createItemFromEntityOpen.uuid}
                    type={createItemFromEntityOpen.type}
                />
            )}
            {chooseSessionModalOpen === 'session' && (
                <ChooseSessionModal
                    activeOrganizationId={organization.uuid}
                    open={true}
                    onClose={() => {
                        setChooseSessionModalOpen(undefined);
                    }}
                    onClickSession={(session: Session) => {
                        setCreateItemFromEntityOpen({ type: 'session', uuid: session.uuid });
                    }}
                />
            )}
            {chooseSessionModalOpen === 'form' && (
                <ChooseFormModal
                    activeOrganizationId={organization.uuid}
                    open={true}
                    onClose={() => {
                        setChooseSessionModalOpen(undefined);
                    }}
                    onClickForm={(form: Form) => {
                        setCreateItemFromEntityOpen({ type: 'form', uuid: form.uuid });
                    }}
                />
            )}
            {selectedItem && (
                <EditMarketplaceItem
                    open={true}
                    handleClose={function (): void {
                        setSelectedItem(null);
                    }}
                    item={selectedItem}
                    onSaved={function (item: MarketplaceItem): void {
                        setSnackMessage('Item updated successfully');
                        setMarketplaceItems((oldItems) =>
                            oldItems.map((i) => {
                                if (i.uuid === item.uuid) {
                                    return item;
                                }
                                return i;
                            }),
                        );
                    }}
                    onSaveError={function (): void {
                        setSnackMessage('Error updating item');
                    }}
                    isGoldSeller={organization.subscription_tier?.name === 'Gold'}
                />
            )}
            <Dialog open={deleteDialogOpen}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <Typography>Do you want to delete this item?</Typography>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" marginTop={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={function (): void {
                                setSelectedItemForDelete(null);
                                setDeleteDialogOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async function (): Promise<void> {
                                try {
                                    console.log('delete item method', selectedItemForDelete);
                                    await deleteItem(
                                        organization.uuid,
                                        selectedItemForDelete?.uuid || '',
                                    );
                                    setMarketplaceItems((oldItems) =>
                                        oldItems.filter(
                                            (i) => i.uuid !== selectedItemForDelete?.uuid,
                                        ),
                                    );
                                    setSnackMessage('Item deleted successfully');
                                    setDeleteDialogOpen(false);
                                } catch (e) {
                                    setSnackMessage('Error deleting item');
                                }
                                setSelectedItemForDelete(null);
                            }}
                        >
                            Delete
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>

            {!hasIntegratedStripe && integrationsLoaded && (
                <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography variant={'h6'} marginTop={6}>
                        You must configure your organization settings to enable the marketplace
                        feature. Get started below.
                    </Typography>
                    <Box>
                        <Lottie
                            animationData={GetStarted}
                            disabled={settingUpMarketplace}
                            loop={true}
                            autoplay={true}
                            style={{
                                width: '300px', // Adjust the width and height as needed
                                height: '200px',
                                cursor: 'pointer',
                            }}
                            onClick={async () => {
                                setSettingUpMarketplace(true);
                                try {
                                    await createStripeExpressAccount(organization.uuid);
                                    const response = await createStripeAccountLink(
                                        organization.uuid,
                                    );
                                    window.location.href = response.data.url;
                                } catch (e) {
                                    setSnackMessage('Error setting up marketplace account');
                                } finally {
                                    setSettingUpMarketplace(false);
                                }
                            }}
                        />
                    </Box>
                </Stack>
            )}
            {(!integrationsLoaded || (hasIntegratedStripe && !stripeAccount)) && (
                <Stack direction={'column'} alignContent={'center'}>
                    <TypographyTitle>Loading...</TypographyTitle>
                </Stack>
            )}
            {hasIntegratedStripe &&
                integrationsLoaded &&
                stripeAccount &&
                !stripeAccount.charges_enabled && (
                    <Stack
                        direction={'column'}
                        alignContent={'center'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography variant={'h6'}>
                            Complete your onboarding to receive payments.
                        </Typography>
                        <Box>
                            <Button
                                variant="outlined"
                                color="primary"
                                endIcon={<OpenInNew />}
                                onClick={async () => {
                                    try {
                                        const response = await createStripeAccountLink(
                                            organization.uuid,
                                        );
                                        window.location.href = response.data.url;
                                    } catch (e) {
                                        setSnackMessage('Error setting up marketplace account');
                                    }
                                }}
                            >
                                Complete Onboarding
                            </Button>
                        </Box>
                    </Stack>
                )}
            {hasIntegratedStripe && stripeAccount?.charges_enabled && (
                <Stack direction="column" spacing={8}>
                    <Stack>
                        <TypographyTitle>
                            Marketplace Settings For {organization.name}
                        </TypographyTitle>
                        <p>
                            This is where you can configure the marketplace for your organization.
                            You can configure the marketplace to sell your assessments and sessions
                            to other organizations.
                        </p>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        setChooseSessionModalOpen('session');
                                    }}
                                >
                                    Add Session
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        setChooseSessionModalOpen('form');
                                    }}
                                >
                                    Add Assessment
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    endIcon={<OpenInNew />}
                                    onClick={async () => {
                                        try {
                                            const response = await createStripeLoginLink(
                                                organization.uuid,
                                            );
                                            window.location.href = response.data.url;
                                        } catch (e) {
                                            setSnackMessage('Error setting up marketplace account');
                                        }
                                    }}
                                >
                                    Payouts And Analytics
                                </Button>

                                <Link to={`/marketplace/${organization.slug}`} target="_blank">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        endIcon={<OpenInNew />}
                                    >
                                        My Marketplace
                                    </Button>
                                </Link>
                            </Stack>
                        </Box>
                    </Stack>
                    <h3>My Marketplace Items</h3>
                    {loadingMyItems && <LinearProgress />}
                    <DataGridPremium
                        slots={{
                            noRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Nothing for sale right now
                                </Stack>
                            ),
                            noResultsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Filters returned no results
                                </Stack>
                            ),
                            footer: CustomPagination,
                        }}
                        sx={{
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                                backgroundColor: blue[100],
                            },
                            minHeight: 375,
                        }}
                        autoHeight={false}
                        rows={myMarketplaceItemRows}
                        columns={myMarketplaceItemsColumns}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        sortingMode={'server'}
                        onSortModelChange={handleSortMyMarketplaceItemsModelChange}
                        initialState={{
                            sorting: { sortModel: [{ field: 'updated_at', sort: 'desc' }] },
                            pagination: {
                                paginationModel: { pageSize: 5, page: 1 },
                            },
                        }}
                        slotProps={{
                            footer: {
                                hasNext: (myItemLinks && myItemLinks.next !== null) ?? false,
                                hasPrevious: myItemsQuery.page > 1,
                                onNext: () => setMyItemsQuery((o) => ({ ...o, page: +o.page + 1 })),
                                onPrevious: () =>
                                    setMyItemsQuery((o) => ({ ...o, page: +o.page - 1 })),
                                page: myItemsQuery.page as number,
                            },
                        }}
                    />
                    <h3 style={{ marginTop: '30px' }}>Resellable Marketplace Items</h3>
                    {loadingResellableItems && <LinearProgress />}
                    <DataGridPremium
                        slots={{
                            noRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    No items available for resale now
                                </Stack>
                            ),
                            noResultsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    Filters returned no results
                                </Stack>
                            ),
                            footer: CustomPagination,
                        }}
                        sx={{
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                                backgroundColor: blue[100],
                            },
                            minHeight: 375,
                        }}
                        autoHeight={false}
                        rows={resellableMarketplaceItemRows}
                        columns={resellableMarketplaceItemsColumns}
                        pageSizeOptions={[5]}
                        disableRowSelectionOnClick
                        sortingMode={'server'}
                        onSortModelChange={handleSortResellableMarketplaceItemsModelChange}
                        initialState={{
                            sorting: { sortModel: [{ field: 'updated_at', sort: 'desc' }] },
                            pagination: {
                                paginationModel: { pageSize: 5, page: 1 },
                            },
                        }}
                        slotProps={{
                            footer: {
                                hasNext:
                                    (resellableItemLinks && resellableItemLinks.next !== null) ??
                                    false,
                                hasPrevious: resellableItemsQuery.page > 1,
                                onNext: () =>
                                    setResellableItemsQuery((o) => ({ ...o, page: +o.page + 1 })),
                                onPrevious: () =>
                                    setResellableItemsQuery((o) => ({ ...o, page: +o.page - 1 })),
                                page: resellableItemsQuery.page as number,
                            },
                        }}
                    />
                </Stack>
            )}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={!!snackMessage}
                message={snackMessage ?? ''}
                autoHideDuration={3000}
                onClose={() => setSnackMessage(null)}
            />
            <Dialog open={resellItemModalOpen} onClose={() => setResellItemModalOpen(false)}>
                <DialogTitle>Resell Item</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to resell this item?</Typography>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" marginTop={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setResellItemModalOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={addMarketplaceItemForResellerToSell}
                        >
                            Resell this Item
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
            <Dialog
                open={stopResellItemModalOpen}
                onClose={() => setStopResellItemModalOpen(false)}
            >
                <DialogTitle>Stop Reselling Item</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to stop reselling this item?</Typography>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" marginTop={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                setStopResellItemModalOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={removeMarketplaceItemForResellerToSell}
                        >
                            Remove Item
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MarketplaceSettingsPage;
