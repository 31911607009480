import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { NewSessionMovement, Session } from 'utils';

type ReturnType = Promise<AxiosResponse<Session>>;

const addMovementsToSessionInBulk = (
    movements: NewSessionMovement[],
    sessionId: string,
): ReturnType => {
    return Request.post(`/workouts/sessions/${sessionId}/movements/bulk`, { movements: movements });
};

export default addMovementsToSessionInBulk;
