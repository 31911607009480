import { useState } from 'react';
import { yupSchemas } from 'utils';
import { forgotPassword } from 'api';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useHistory } from 'react-router-dom';
import { ButtonWithLoader } from 'components/Buttons';
import { SnackBar } from 'components/Indicators';
import ROUTES from '../../Routes/routes';
import { pushMessage } from '../../redux/reducers/messages';
import { useAppDispatch } from '../../redux/store';
import { useTheme } from '@mui/system';

const ForgotPasswordForm = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [notifyUserMessage, setNotifyUserMessage] = useState('');
    const theme = useTheme();
    const { push } = useHistory();

    const handleSubmit = async (values: { username: string }) => {
        const { username } = values;
        setIsLoading(true);
        try {
            await forgotPassword(username);
            setSuccess(true);
            dispatch(
                pushMessage({
                    status: 'success',
                    message: `Link has been sent successfully to your email`,
                }),
            );
            push(ROUTES.Login.path);
        } catch (err: any) {
            setSuccess(false);
            setNotifyUserMessage('Please try again with a different email.');
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Formik
            initialValues={yupSchemas.username.getDefault()}
            validationSchema={yupSchemas.username}
            onSubmit={handleSubmit}
        >
            {() => (
                <Form
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Field
                        style={{ width: '100%', marginBottom: theme.spacing(10) }}
                        component={TextField}
                        disabled={isLoading}
                        name="username"
                        type="username"
                        label="Username"
                    />
                    <ButtonWithLoader
                        type="submit"
                        style={{ width: '100%', marginBottom: theme.spacing(10) }}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isLoading}
                        loading={isLoading}
                    >
                        Email me recovery link
                    </ButtonWithLoader>
                    <SnackBar
                        open={notifyUserMessage ? true : false}
                        message={notifyUserMessage}
                        setOpen={() => {
                            setNotifyUserMessage('');
                        }}
                        severity={success ? 'success' : 'error'}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default ForgotPasswordForm;
