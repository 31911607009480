import { Meta } from '@cubejs-client/core';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import {
    Box,
    Breadcrumbs,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputBase,
    LinearProgress,
    Link,
    Paper,
    Snackbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { viewOrganization } from '../../../api';
import { getTags } from '../../../api/tags.api';
import { cubejsApi } from '../../../app.functions';
import { Tag } from '../../../app.types';
import { Can, SideDrawerWithTitleAndActions } from '../../../components';
import { useNonInitialEffect } from '../../../hooks';
import { Organization } from '../../../utils';
import { deleteSavedFilter, updateSavedFilter, viewSavedFilter } from '../api/savedFilters.api';
import { mapTagIntoChipChoice, mapTagsIntoChipChoices } from '../community.func';
import { CommunityReportFilters, SavedFilter, SavedFilterType } from '../community.types';
import CommunityActivityFilters from '../components/CommunityActivityFilters';
import SingleChipList from '../components/SingleChipList';
import TimeseriesReport from '../components/TimeseriesReport';
import CommunityReportsCreateSavedFilterDrawer from './CommunityReportsCreateSavedFilterDrawer';

function CommunityReportsViewReport() {
    let { savedFilterId } = useParams<{ savedFilterId: string }>();
    let { savedFilterType } = useParams<{ savedFilterType: SavedFilterType }>();
    let [query] = useQueryParams({ organization: StringParam });
    const [organization, setOrganization] = useState<Organization>();
    const [savedFilter, setSavedFilter] = useState<SavedFilter>();
    const [type, setType] = useState<SavedFilterType>('community.activity');
    const [saving, setSaving] = useState<boolean>(false);
    const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);
    const [titleChanged, setTitleChanged] = useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [loading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [tags, setTags] = useState<Tag[]>([]);
    const [cubeMeta, setCubeMeta] = useState<Meta>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [creating, setCreating] = useState<boolean>(false);
    const [filters, setFilters] = useState<CommunityReportFilters>({
        organizations: [],
        movements: [],
        measurements: [],
        individuals: [],
        timeDimension: 'all',
        timeGroup: 'day',
        fillMissingDates: false,
        showSkipped: false,
        chartType: 'line',
        reportType: 'trend',
        temExercises: [],
    });
    let { push } = useHistory();
    const handleDelete = () => {
        setDeleting(true);
        deleteSavedFilter(savedFilterId)
            .then(() => push(`/community/reports`))
            .catch(() => setMessage('Error while deleting'))
            .finally(() => {
                setDeleting(false);
                setOpenConfirmDialog(false); // Close the dialog after deletion
            });
    };

    function save() {
        setSaving(true);
        updateSavedFilter(savedFilterId, {
            title: savedFilter?.title ?? '',
            short_description: savedFilter?.short_description ?? '',
            tag_id: savedFilter?.tag ? savedFilter?.tag?.id : null,
            filters,
        })
            .then(() => setMessage('Saved'))
            .catch(() => setMessage('Error while saving'))
            .finally(() => {
                setSaving(false);
                setTitleChanged(false);
            });
    }

    useEffect(() => {
        cubejsApi
            .meta()
            .then((result: Meta) => {
                setCubeMeta(result);
            })
            .catch(() => setMessage('Unable to load data'));
        getTags({ 'filter[type]': 'reports' })
            .then((response) => {
                setTags(response.data);
            })
            .catch(() => {});
    }, []);

    useEffect(() => {
        if (savedFilterType) {
            setType(savedFilterType);
        }
        if (savedFilter) {
            setType(savedFilter.type);
        }
    }, [savedFilterType, savedFilter]);

    useEffect(() => {
        if (savedFilterType) {
            setFiltersOpen(true);
        }
    }, [savedFilterType]);

    useEffect(() => {
        if (query.organization) {
            setFilters((f) => ({ ...f, organizations: [query.organization as string] }));
        }
    }, [query.organization]);

    useNonInitialEffect(() => {
        if (filters.reportType.startsWith('trend')) {
            setFilters((f) => ({ ...f, timeGroup: 'day' }));
        }
    }, [filters.reportType]);

    useEffect(() => {
        if (savedFilterId) {
            viewSavedFilter(savedFilterId)
                .then(({ data }) => {
                    setSavedFilter(data);
                    setFilters({
                        ...data.filters,
                        reportType: data.filters.reportType ?? 'trend',
                        chartType: data.filters.chartType ?? 'line',
                    });
                })
                .catch(() => setMessage('Unable to load saved chart'))
                .finally();
        }
    }, [savedFilterId]);

    useEffect(() => {
        if (query.organization) {
            viewOrganization(query.organization)
                .then((response) => setOrganization(response.data))
                .catch(() => {})
                .finally(() => {});
        }
    }, [query.organization]);

    useEffect(() => {
        if (!savedFilterId && type) {
            if (type === 'community.performance') {
                setFilters((f) => ({
                    ...f,
                    reportType: 'chart',
                    chartType: 'athletes',
                }));
            }
            if (type === 'community.upload.time.segments') {
                setFilters((f) => ({
                    ...f,
                    reportType: 'trend',
                    chartType: 'line',
                    load: ['0'],
                    timeDimension: 'last 30 days',
                    segment: ['1'],
                }));
            }
            if (type === 'community.upload') {
                setFilters((f) => ({
                    ...f,
                    timeDimension: 'last 7 days',
                }));
            }
        }
    }, [type, savedFilterId]);

    return (
        <>
            {loading && (
                <LinearProgress
                    variant={'indeterminate'}
                    style={{ width: '100%' }}
                    color="secondary"
                />
            )}
            {
                <Paper elevation={4} style={{ margin: 12, padding: 16 }}>
                    <Box
                        marginBottom={12}
                        paddingBottom={12}
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        borderBottom={'1px solid'}
                        style={{ borderBottomColor: grey[300] }}
                    >
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/community/reports">
                                Community Reports
                            </Link>
                            <Typography color="textPrimary">
                                {!savedFilter && <>Create New Report</>}
                                {savedFilter && <>{savedFilter.title}</>}
                            </Typography>
                        </Breadcrumbs>
                        <Box>
                            {savedFilterId && titleChanged && (
                                <Button
                                    onClick={() => {
                                        save();
                                    }}
                                    disabled={saving}
                                    color={'primary'}
                                    variant={'contained'}
                                    style={{ marginRight: 4 }}
                                >
                                    Save
                                </Button>
                            )}
                            {organization && !savedFilterId && (
                                <Can I={'organization:create-saved-filters'} this={organization}>
                                    <Button
                                        onClick={() => setCreating(true)}
                                        color={'primary'}
                                        variant={'contained'}
                                    >
                                        Click here to give report title and save
                                    </Button>
                                </Can>
                            )}
                            {savedFilterId && (
                                <IconButton
                                    disabled={deleting}
                                    onClick={() => setOpenConfirmDialog(true)}
                                    aria-label="delete"
                                    size="large"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                            <Dialog
                                open={openConfirmDialog}
                                onClose={() => setOpenConfirmDialog(false)}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {'Confirm Deletion'}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to delete this report?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={() => setOpenConfirmDialog(false)}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button onClick={handleDelete} color="primary" autoFocus>
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Box>
                    {savedFilter && (
                        <Box marginBottom={12}>
                            <Box>
                                <InputBase
                                    sx={{
                                        width: '100%',
                                        fontSize: 24,
                                        fontWeight: 'bold',
                                    }}
                                    value={savedFilter.title ?? ''}
                                    onChange={(e) => {
                                        setSavedFilter((f) => {
                                            if (f) {
                                                return { ...f, title: e.target.value };
                                            }
                                            return f;
                                        });
                                        setTitleChanged(true);
                                    }}
                                />
                            </Box>
                            <Box>
                                <InputBase
                                    sx={{ width: '100%' }}
                                    value={savedFilter.short_description}
                                    onChange={(e) => {
                                        setSavedFilter((f) => {
                                            if (f) {
                                                return { ...f, short_description: e.target.value };
                                            }
                                            return f;
                                        });
                                    }}
                                />
                            </Box>
                            <Box>
                                <SingleChipList
                                    choices={mapTagsIntoChipChoices(tags)}
                                    value={
                                        savedFilter.tag
                                            ? mapTagIntoChipChoice(savedFilter.tag)
                                            : null
                                    }
                                    onChange={(v) => {
                                        const tag = v
                                            ? tags.find((t) => t.id === v?.id) ?? null
                                            : null;

                                        setSavedFilter((f) => {
                                            if (f) {
                                                return { ...f, tag };
                                            }
                                            return f;
                                        });
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
                        <Button
                            startIcon={<SettingsSuggestIcon fontSize="large" />}
                            variant="outlined"
                            onClick={() => setFiltersOpen(true)}
                        >
                            Update Chart Settings{' '}
                            {filters.measurements.length > 0 && (
                                <>[{filters.measurements.map((m) => m.shortTitle).join(',')}]</>
                            )}
                        </Button>
                    </Box>
                    <SideDrawerWithTitleAndActions
                        open={filtersOpen}
                        disabled={false}
                        onClose={() => setFiltersOpen(false)}
                        onSubmit={() => {
                            setFiltersOpen(false);
                            savedFilterId && save();
                        }}
                        title={<>Report Filters</>}
                        body={
                            <Box
                                bgcolor={grey[100]}
                                border={'1px solid'}
                                borderColor={grey[200]}
                                borderRadius="3px"
                                padding={4}
                                width={'100%'}
                            >
                                {cubeMeta && organization && !savedFilter && (
                                    <CommunityActivityFilters
                                        filters={filters}
                                        onChange={(filters) => setFilters(filters)}
                                        meta={cubeMeta}
                                        organization={organization}
                                        savedFilterType={type}
                                    />
                                )}
                                {cubeMeta && savedFilter && (
                                    <CommunityActivityFilters
                                        filters={filters}
                                        onChange={(filters) => setFilters(filters)}
                                        meta={cubeMeta}
                                        organization={savedFilter.organization}
                                        savedFilterType={type}
                                    />
                                )}
                            </Box>
                        }
                        loading={false}
                    />

                    <Box width={'100%'}>
                        {cubeMeta && (
                            <TimeseriesReport cubeMeta={cubeMeta} filters={filters} type={type} />
                        )}
                    </Box>
                </Paper>
            }

            <CommunityReportsCreateSavedFilterDrawer
                fullScreen={isMobile}
                open={creating}
                onClose={() => setCreating(false)}
                onSaved={(f) => {
                    setCreating(false);
                    if (savedFilterId) {
                        push(`./${f.uuid}`);
                    } else {
                        push(`../${f.uuid}`);
                    }
                }}
                onSavedError={() => setMessage('Error: We had an error while saving')}
                organizationId={savedFilter?.organization_uuid ?? query.organization ?? ''}
                filters={filters}
                savedFilterType={type}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={!!message}
                autoHideDuration={1000}
                onClose={() => setMessage('')}
                message={message}
                action={
                    <React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setMessage('')}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </>
    );
}

export default CommunityReportsViewReport;
