import { Request } from '../../api';
import { AxiosResponse } from 'axios';

interface DownloadUrlResponse {
    url: string;
}

/**
 * Gets a presigned URL for downloading a specific file from S3.
 * @returns Promise<AxiosResponse<DownloadUrlResponse>>
 */
export const getPresignedDownloadUrl = (
    fileKey: string,
): Promise<AxiosResponse<DownloadUrlResponse>> => {
    return Request.get(`/public/get-public-file-url`, {
        params: { key: fileKey },
    });
};
