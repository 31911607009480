import {
    Accordion as MuiAccordion,
    AccordionActions,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { getHtml, getInitials, Movement, Organization } from 'utils';
import React, { useState } from 'react';
import { getOrganizationsForMovementCreation } from 'redux/reducers/user';
import { useSelector } from 'react-redux';
import MovementMedia from 'components/Media/MovementMedia';
import { AttributeChips } from 'components/Chips';
import { ChooseOrganizationDialog } from 'components/Dialogs';
import { Can } from 'components/Functional/Can';
import useTheme from '@mui/material/styles/useTheme';

export interface MovementCardProps {
    item: Movement;
    isExpanded: boolean;
    onClick: () => void;
    onClickDetails: (movement: Movement) => void;
    onMovementDuplicated: (movement: Movement, organization: Organization) => void;
    onEdit: (movement: Movement) => void;
    onShare: (movement: Movement) => void;
}

const MuiAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    padding: theme.spacing(0),
    justifyContent: 'space-between',
    '& .MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(0),
        padding: theme.spacing(1),
        justifyContent: 'space-between',
        color: theme.palette.text.secondary,
    },
}));

const MovementCard = ({
    item,
    isExpanded,
    onClick,
    onMovementDuplicated,
    onEdit,
    onShare,
}: MovementCardProps) => {
    const [showOrganizationSelectionDialog, setShowOrganizationSelectionDialog] =
        useState<boolean>(false);
    const [duplicatingMovement, setDuplicatingMovement] = useState<Movement | null>(null);
    const organizations = useSelector(getOrganizationsForMovementCreation);
    const theme = useTheme();
    return (
        <Box boxShadow={3} sx={{ padding: theme.spacing(0) }}>
            <MuiAccordion
                expanded={isExpanded}
                sx={{ borderRadius: '4px', marginBottom: theme.spacing(4) }}
                onClick={onClick}
            >
                <MuiAccordionSummary>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-around"
                        sx={
                            isExpanded
                                ? {
                                      height: 'auto',
                                      maxWidth: 400,
                                      minWidth: 150,
                                      padding: (theme) => theme.spacing(0, 4),
                                      '@media (max-width: 600px)': {
                                          maxWidth: 250,
                                      },
                                  }
                                : {
                                      maxWidth: 90,
                                      minWidth: 90,
                                      padding: (theme) => theme.spacing(0, 0),
                                      overflow: 'hidden',
                                  }
                        }
                    >
                        <MovementMedia movement={item} />
                    </Box>
                    {!isExpanded && (
                        <Typography
                            variant="subtitle2"
                            sx={{
                                marginLeft: 10,
                                color: theme.palette.text.primary,
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                        >
                            {item.name}
                        </Typography>
                    )}

                    {!isExpanded && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-around"
                            paddingLeft="16px"
                            marginLeft="auto"
                        >
                            {item.organization && item.organization?.logo_url && (
                                <Avatar
                                    src={item?.organization?.logo_url}
                                    sx={{
                                        marginRight: theme.spacing(4),
                                        marginLeft: theme.spacing(4),
                                        width: theme.spacing(12),
                                        height: theme.spacing(12),
                                        variant: 'rounded',
                                    }}
                                    alt={getInitials(item.organization.name || '')}
                                />
                            )}
                            {item.organization && !item.organization?.logo_url && (
                                <Avatar
                                    sx={{
                                        marginRight: theme.spacing(4),
                                        marginLeft: theme.spacing(4),
                                        width: theme.spacing(12),
                                        height: theme.spacing(12),
                                        variant: 'rounded',
                                    }}
                                    alt={item.organization.name}
                                >
                                    {item.organization.name[0]}
                                </Avatar>
                            )}
                        </Box>
                    )}

                    {isExpanded && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            flexWrap="wrap"
                            flexGrow={1}
                            style={{ gap: 4 }}
                        >
                            <Box display="flex" justifyContent="space-between">
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        color: theme.palette.text.primary,
                                        '@media (max-width: 600px)': {
                                            fontSize: 16,
                                        },
                                    }}
                                >
                                    {item.name}
                                </Typography>
                                {item.organization && item.organization?.logo_url && (
                                    <Avatar
                                        src={item?.organization?.logo_url}
                                        sx={{
                                            marginRight: theme.spacing(4),
                                            marginLeft: theme.spacing(4),
                                            width: theme.spacing(12),
                                            height: theme.spacing(12),
                                            variant: 'rounded',
                                        }}
                                        alt={getInitials(item.organization.name || '')}
                                    />
                                )}
                                {item.organization && !item.organization?.logo_url && (
                                    <Avatar
                                        sx={{
                                            marginRight: theme.spacing(4),
                                            marginLeft: theme.spacing(4),
                                            width: theme.spacing(12),
                                            height: theme.spacing(12),
                                            variant: 'rounded',
                                        }}
                                        alt={item.organization.name}
                                    >
                                        {item.organization.name[0]}
                                    </Avatar>
                                )}
                            </Box>
                            <Box
                                sx={{
                                    color: theme.palette.text.secondary,
                                    overflowWrap: 'break-word',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                    hyphens: 'auto',
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: getHtml(item.description ?? ''),
                                }}
                            />
                            <AttributeChips attributes={item.attributes ? item.attributes : []} />
                        </Box>
                    )}
                </MuiAccordionSummary>

                <AccordionActions>
                    <Can I={'movement:duplicate'} this={item}>
                        <Button
                            variant="text"
                            onClick={() => {
                                setDuplicatingMovement(item);
                                if (organizations?.length == 1) {
                                    onMovementDuplicated(item, {
                                        uuid: organizations?.[0]?.name || '',
                                        name: organizations?.[0]?.display_name || '',
                                    } as Organization);
                                } else {
                                    setShowOrganizationSelectionDialog(true);
                                }
                            }}
                            color={
                                duplicatingMovement?.uuid === item.uuid ? 'secondary' : 'primary'
                            }
                            disabled={duplicatingMovement?.uuid === item.uuid}
                        >
                            {duplicatingMovement && duplicatingMovement.uuid === item.uuid
                                ? 'Duplicating'
                                : 'Duplicate'}
                        </Button>
                    </Can>
                    <Can I={'movement:update'} this={item}>
                        <Button color="primary" onClick={() => onEdit(item)}>
                            Edit
                        </Button>
                    </Can>
                    <Can I={'movement:share'} this={item}>
                        <Button color="primary" onClick={() => onShare(item)}>
                            Share
                        </Button>
                    </Can>
                    <ChooseOrganizationDialog
                        open={showOrganizationSelectionDialog}
                        onOrganizationSelected={(selectedOrganization: Organization) => {
                            onMovementDuplicated(
                                duplicatingMovement as Movement,
                                selectedOrganization,
                            );
                            setShowOrganizationSelectionDialog(false);
                        }}
                        onCancel={() => {
                            setShowOrganizationSelectionDialog(false);
                            setDuplicatingMovement(null);
                        }}
                        buttonText="Duplicate"
                    />
                </AccordionActions>
            </MuiAccordion>
        </Box>
    );
};

export default MovementCard;
