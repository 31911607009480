import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Movement } from 'utils';

type ReturnType = Promise<AxiosResponse<Movement>>;

const viewMovement = (uuid: string): ReturnType => {
    return Request.get(`/workouts/movements/${uuid}`);
};

export default viewMovement;
