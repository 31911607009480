import SchoolIcon from '@mui/icons-material/School';
import { Avatar, Box } from '@mui/material';
import React, { memo } from 'react';
import { DateValue, Session } from 'utils';
import { ChooseDateCard } from '../../learn/components';

export interface PlanSessionCardProps {
    item: Session;
    onChange: (value: DateValue) => void;
    sessionDate: DateValue;
}

const PlanSessionCard = ({ item, sessionDate, onChange }: PlanSessionCardProps) => {
    return (
        <Box mb={8}>
            <ChooseDateCard
                onChange={onChange}
                value={sessionDate}
                title={item.title}
                subheader={`${item.movement_count} movements`}
                avatar={
                    <Avatar variant={'square'} src={item.image_urls['avatar'] ?? ''}>
                        <SchoolIcon />
                    </Avatar>
                }
            />
        </Box>
    );
};

export default memo(PlanSessionCard);
