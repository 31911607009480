import React from 'react';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import LandingPageNavBar from './LandingPageNavBar';

function LandingPageAbout() {
    const gradientTextStyles = {
        mt: 4,
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 8,
        maxWidth: 900,
        background: 'linear-gradient(to bottom, #fffffF, #A7ABB7)', // Example gradient
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent', // This makes the text transparent, showing only the background
        backgroundClip: 'text',
        color: 'transparent', // Fallback for browsers that do not support background-clip
        marginTop: 15,
    };

    return (
        <>
            <LandingPageNavBar />
            <Box
                sx={{ flex: 1, backgroundImage: 'linear-gradient(to bottom, #0066ff, #070A19)' }}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start" // This will distribute space evenly
                alignItems="center"
                height="100%"
            >
                <Typography sx={gradientTextStyles} variant="h3" component="div">
                    Privacy Policy
                </Typography>
                <Typography sx={gradientTextStyles} variant="h5" component="div">
                    Athlete Management Technologies, LLC. (&apos;Company&apos; or &apos;We&apos;)
                    respect your privacy and are committed to protecting it through our compliance
                    with this policy. This policy describes: The types of information we may collect
                    or that you may provide when you purchase, download, install, register with,
                    access, or use the Athlete Management Technologies website and mobile
                    application (collectively, the &apos;Application&apos;). Our practices for
                    collecting, using, maintaining, protecting, and disclosing that information.
                    This policy applies only to information we collect in this Application, in
                    email, text, and other electronic communications sent through or in connection
                    with this Application.
                </Typography>{' '}
                <Typography
                    sx={gradientTextStyles}
                    variant="body1"
                    style={{ textAlign: 'left' }}
                    component="div"
                >
                    This policy DOES NOT apply to information that: We collect offline or through
                    any third-party applications or websites, including websites you may access
                    through this Application. You provide to or is collected by any third party (see
                    Third-Party Information Collection). Third parties have their own privacy
                    policies, which we encourage you to read before providing information on or
                    through them. Please read this policy carefully to understand our policies and
                    practices regarding your information and how we will treat it. If you do not
                    agree with our policies and practices, do not download, register with, or use
                    this Application. By downloading, registering with, or using this Application,
                    you acknowledge notice of the provisions in this privacy policy. This policy may
                    change from time to time (see Changes to Our Privacy Policy). Your continued use
                    of this Application after we revise this policy means you accept those changes,
                    so please check the policy periodically for updates. <br />
                    Children Under the Age of 13. The Application is not intended for children under
                    13 years of age, and we do not knowingly collect personal information from
                    children under 13. If we learn we have collected or received personal
                    information from a child under 13 without verification of parental consent, we
                    will delete that information. If you believe we might have any information from
                    or about a child under 13, please contact us at support@1tul.com <br />
                    California residents under 16 years of age may have additional rights regarding
                    the collection and sale of their personal information. Please see Your State
                    Privacy Rights for more information. <br />
                    Information We Collect and How We Collect. It We collect information from and
                    about users of our Application: Directly from you when you provide it to us.
                    Automatically when you use the Application. Information You Provide to Us When
                    you download, register with, or use this Application, we may ask you provide
                    information: By which you may be personally identified, such as name, postal
                    address, email address, telephone number, or any other identifier by which you
                    may be contacted online or offline (&apos;Personal Information&apos;). That is
                    about you but individually does not specifically identify you, such as your
                    school name, year in school, date of birth, grade point average or other
                    academic information, team name and position, or any other similar information
                    you may have the option to provide to fully take advantage of our services. This
                    information includes: Information that you provide by filling in forms in the
                    Application. This includes information provided as soon as you begin interacting
                    with the Application, in the process of registering to use the Application,
                    subscribing to our service, posting material, and requesting further services.
                    We may also ask you for information when you report a problem with the
                    Application. Records and copies of your correspondence (including email
                    addresses and phone numbers) if you contact us. Your responses to surveys that
                    we might ask you to complete for research purposes. Details of transactions you
                    carry out through the Application. You may also have the option to provide
                    reports, summaries, and other information obtained through your use of the
                    Application for publication or display on public websites or other sharing
                    services you access (collectively, &apos;User Content&apos;). Your User Content
                    is transmitted to others and to the public, where applicable, at your own risk.
                    Although you may set certain privacy settings for such information by logging
                    into your account profile, please be aware that no security measures are perfect
                    or impenetrable. Additionally, we cannot control the actions of third parties
                    with whom you may choose to share your User Content. Therefore, we cannot and do
                    not guarantee that your User Content will not be viewed by unauthorized persons.
                    We encourage you to use caution when sharing User Content publicly. Automatic
                    Information Collection and Tracking When you download, access, and use the
                    Application, it may use technology to automatically collect: Usage Details. When
                    you access and use the Application, we may automatically collect certain details
                    of your access to and use of the Application, including traffic data, location
                    data, logs, and other communication data and the resources that you access and
                    use on or through the Application. Device Information. We may collect
                    information about your mobile device and internet connection, including the
                    device&apos;s unique device identifier, IP address, operating system, browser
                    type, mobile network information, and the device&apos;s telephone number. Stored
                    Information and Files. The Application also may access metadata and other
                    information associated with other files stored on your device. This may include,
                    for example, photographs, audio and video clips, personal contacts, and address
                    book information. Location Information. This Application may collect real-time
                    information about the location of your device. If you do not want us to collect
                    this information do not download the Application or delete it from your device.
                    You may also opt out at any time by emailing us or adjusting your account
                    settings. For more information, see Your Choices About Our Collection, Use, and
                    Disclosure of Your Information. Note, however, that opting out of the
                    Application&apos;s collection of location information will disable its
                    location-based features. We also may use these technologies to collect
                    information about your activities over time and across third-party websites,
                    apps, or other online services (behavioral tracking). Information Collection and
                    Tracking Technologies The technologies we use for automatic information
                    collection may include: Cookies (or mobile cookies). A cookie is a small file
                    placed on your smartphone. It may be possible to refuse to accept mobile cookies
                    by activating the appropriate setting on your smartphone. However, if you select
                    this setting you may be unable to access certain parts of our Application. Web
                    Beacons. Pages of the Application and our emails may contain small electronic
                    files known as web beacons (also referred to as clear gifs, pixel tags, and
                    single-pixel gifs) that permit the Company, for example, to count users who have
                    visited those pages or opened an email and for other related app statistics (for
                    example, recording the popularity of certain app content and verifying system
                    and server integrity). Third-Party Information Collection When you use the
                    Application or its content, certain third parties may use automatic information
                    collection technologies to collect information about you or your device. These
                    third parties may include: Advertisers, ad networks, and ad servers. Analytics
                    companies. Your mobile device manufacturer. Your mobile service provider. These
                    third parties may use tracking technologies to collect information about you
                    when you use this Application. The information they collect may be associated
                    with your personal information or they may collect information, including
                    personal information, about your online activities over time and across
                    different websites, apps, and other online services websites. They may use this
                    information to provide you with interest-based (behavioral) advertising or other
                    targeted content. We do not control these third parties&apos; tracking
                    technologies or how they may be used. If you have any questions about an
                    advertisement or other targeted content, you should contact the responsible
                    provider directly. For information about how you can opt out of receiving
                    targeted advertising from many providers, see Your Choices About Our Collection,
                    Use, and Disclosure of Your Information. <br />
                    How We Use Your Information. We use information that we collect about you or
                    that you provide to us, including any personal information, to: Provide you with
                    the Application and its contents, and any other information, products, or
                    services that you request from us. Give you notices about your
                    account/subscription, including expiration and renewal notices. Carry out our
                    obligations and enforce our rights arising from any contracts entered into
                    between you and us, including our EULA. Notify you when Application updates are
                    available, and of changes to any products or services we offer or provide though
                    it. The usage information we collect helps us to improve our Application and to
                    deliver a better and more personalized experience by enabling us to: Estimate
                    our audience size and usage patterns. Store information about your preferences,
                    allowing us to customize our Application according to your individual interests.
                    Speed up your searches. Recognize you when you use the Application. Depending on
                    how you use the Application and your device settings, type, and connectivity, we
                    may collect location-based information including your IP address, GPS location,
                    city, county, zip code and region, and your smart device’s proximity to
                    “beacons”, Bluetooth networks, and/or other proximity systems. You will have the
                    ability to opt-in or out of our collection of location information by selecting
                    the location and Bluetooth settings in your device, but this may affect your
                    ability to use certain location dependent Services. We may also use your
                    information to contact you about our own and third parties&apos; goods and
                    services that may be of interest to you. If you do not want us to use your
                    information in this way, please check the relevant box located on the form on
                    which we collect your data (the registration form) or adjust your user
                    preferences in your account profile settings. For more information, see Your
                    Choices About Our Collection, Use, and Disclosure of Your Information. We may
                    use the information we collect to display advertisements to our
                    advertisers&apos; target audiences. Even though we do not disclose your personal
                    information for these purposes without your consent, if you click on or
                    otherwise interact with an advertisement, the advertiser may assume that you
                    meet its target criteria. <br />
                    Disclosure of Your Information. We may disclose aggregated information about our
                    users, and information that does not identify any individual or device, without
                    restriction. In addition, we may disclose personal information that we collect
                    or you provide: To our subsidiaries and affiliates. To contractors, service
                    providers, and other third parties we use to support our business and who are
                    bound by contractual obligations to keep personal information confidential and
                    use it only for the purposes for which we disclose it to them. To a buyer or
                    other successor in the event of a merger, divestiture, restructuring,
                    reorganization, dissolution, or other sale or transfer of some or all of Athlete
                    Management Technologies’s assets, whether as a going concern or as part of
                    bankruptcy, liquidation, or similar proceeding, in which personal information
                    held by Athlete Management Technologies about our Application users is among the
                    assets transferred. To third parties to market their products or services to you
                    if you have not opted out of these disclosures. We contractually require these
                    third parties to keep personal information confidential and use it only for the
                    purposes for which we disclose it to them. For more information, see Your
                    Choices About Our Collection, Use, and Disclosure of Your Information. To
                    fulfill the purpose for which you provide it. For example, when you use the
                    &apos;Send Card&apos; feature of our Application, we will transmit the contents
                    of the Character Card and your email address to the recipients. When you use the
                    “or “Send a PRIZM invite” feature of our Application, recipients will receive
                    the invite with your email address shown. For any other purpose disclosed by us
                    when you provide the information. With your consent. To comply with any court
                    order, law, or legal process, including to respond to any government or
                    regulatory request. To enforce our rights arising from any contracts entered
                    into between you and us, including the Application EULA. If we believe
                    disclosure is necessary or appropriate to protect the rights, property, or
                    safety of Athlete Management Technologies, our customers, or others. <br />
                    Your Choices About Our Collection, Use, and Disclosure of Your Information. We
                    strive to provide you with choices regarding the personal information you
                    provide to us. This section describes mechanisms we provide for you to control
                    certain uses and disclosures of your information. Tracking Technologies. You can
                    set your browser to refuse all or some browser cookies, or to alert you when
                    cookies are being sent. You can also choose whether or not to allow the
                    Application to collect information through other tracking technologies by
                    contacting us at support@1tul.com. If you disable or refuse cookies or block the
                    use of other tracking technologies, some parts of the Application may then be
                    inaccessible or not function properly. Location Information. You can choose
                    whether or not to allow the Application to collect and use real-time information
                    about your device&apos;s location through the device&apos;s privacy settings. If
                    you block the use of location information, some parts of the Application may
                    become inaccessible or not function properly. Promotion by the Company. If you
                    do not want us to use your email address to promote our own or third
                    parties&apos; products or services, you can opt-out by logging into the
                    Application and adjusting your user preferences in your account profile by
                    checking or unchecking the relevant boxes or by sending us an email stating your
                    request to support@1tul.com. Targeted Advertising by the Company. We do not
                    currently engage in targeted advertising, but reserve the right to do so in the
                    future. If you do not want us to use information that we collect or that you
                    provide to us to deliver advertisements according to our advertisers&apos;
                    target-audience preferences, you can opt-out by logging into the Application and
                    adjusting your user preferences in your account profile by checking or
                    unchecking the relevant boxes or by sending us an email stating your request to
                    support@1tul.com. Disclosure of Your Information for Third-Party Advertising and
                    Marketing. We do not currently permit targeted third-party advertising on our
                    Application, but reserve the right to do so in the future. If you do not want us
                    to share your personal information with unaffiliated or non-agent third parties
                    for advertising and marketing purposes, you can opt-out by logging into the
                    Application and adjusting your user preferences in your account profile by
                    checking or unchecking the relevant boxes or by sending us an email stating your
                    request to support@1tul.com. We do not control third parties&apos; collection or
                    use of your information to serve interest-based advertising. However, these
                    third parties may provide you with ways to choose not to have your information
                    collected or used in this way. You can opt out of receiving targeted ads from
                    members of the Network Advertising Initiative (&apos;NAI&apos;) on the
                    NAI&apos;s website. Residents in certain states, such as California, may have
                    additional personal information rights and choices. Please see Your State
                    Privacy Rights for more information. <br />
                    Accessing and Correcting Your Personal Information. You can review and change
                    your personal information by logging into the Application and visiting your
                    account profile page. You may also send us an email at support@1tul.com to
                    request access to, correct, or delete any personal information that you have
                    provided to us. We cannot delete your personal information except by also
                    deleting your user account. We may not accommodate a request to change
                    information if we believe the change would violate any law or legal requirement
                    or cause the information to be incorrect. If you delete your User Content from
                    the Application, copies of your User Content may remain viewable in cached and
                    archived pages or might have been copied or stored by other Application users.
                    Additionally, if you have provided your information to third party websites or
                    made it available to the public, you may not be able to remove or edit your
                    information from some internet databases. Proper access and use of information
                    provided on the Application, including User Content, is governed by our terms of
                    use https://www.1tul.com/privacy/eula. Residents in certain states, such as
                    California, may have additional personal information rights and choices. Please
                    see Your State Privacy Rights for more information. <br />
                    Your State Privacy Rights. State consumer privacy laws may provide their
                    residents with additional rights regarding our use of their personal
                    information. To learn more about California residents&apos; privacy rights,
                    visit the California Consumer Privacy Act (&apos;CCPA&apos;) Privacy Notice for
                    California Residents. For more information on privacy rights for California
                    residents, please see our supplemental Privacy Policy for California Residents
                    https://www.1tul.com/caprivacy. <br />
                    Data Security. We have implemented measures designed to secure your personal
                    information from accidental loss and from unauthorized access, use, alteration,
                    and disclosure. All information you provide to us is stored on our secure
                    servers behind firewalls. Any payment transactions will be encrypted using SSL
                    technology. The safety and security of your information also depends on you.
                    Where we have given you (or where you have chosen) a password for access to
                    certain parts of our Application, you are responsible for keeping this password
                    confidential. We ask you not to share your password with anyone. We urge you to
                    be careful about giving out information in public areas of the Application like
                    message boards. The information you share in public areas of the Application may
                    be viewed by other users, and any information you share on third-party networks
                    or other social media applications may be viewed publicly. Unfortunately, the
                    transmission of information via the internet and mobile platforms is not
                    completely secure. Although we do our best to protect your personal information,
                    we cannot guarantee the security of your personal information transmitted
                    through our Application. Any transmission of personal information is at your own
                    risk. We are not responsible for circumvention of any privacy settings or
                    security measures we provide. <br />
                    Changes to Our Privacy Policy. We may update our privacy policy from time to
                    time. If we make material changes to how we treat our users&apos; personal
                    information, we will post the new privacy policy on this page with a notice that
                    the privacy policy has been updated and notify you by email to the primary email
                    address specified in your account and/or an in-Application alert the first time
                    you use the Application after we make the change. The date the privacy policy
                    was last revised is identified at the top of the page. You are responsible for
                    ensuring we have an up-to-date active and deliverable email address for you and
                    for periodically visiting this privacy policy to check for any changes. <br />
                    Contact Information To ask questions or comment about this privacy policy and
                    our privacy practices, contact us at: Postal Address: Athlete Management
                    Technologies, LLC. 3753 Arroyo Sorrento Rd. San Diego, CA 92130 support@1TUL.com
                </Typography>
                <Button
                    sx={gradientTextStyles}
                    variant="contained"
                    size={'large'}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                    Back to the top
                </Button>
            </Box>
        </>
    );
}

export default LandingPageAbout;
