import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { UserFilter } from '../../../../modules/plan/plan.types';

export const usersAdapter = createEntityAdapter<UserFilter>({
    selectId: (user) => user.user_key,
});

export interface UserFilterState extends EntityState<UserFilter> {
    loading: boolean;
    loaded: boolean;
}
