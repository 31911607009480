import React, { useState } from 'react';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { Attribute, AttributeCategory, AttributeValueType } from 'utils';
import AttributeListSelection from './AttributeListSelection';
import CreateAttributeDialog from './CreateAttributeDialog';
import { Popover } from '@mui/material';
import CustomButton from 'components/Buttons/CustomButton';

export interface AttributeListProps {
    /** addText The text to add a new attribute. */
    addText: string;
    /** attributes The attributes to display in the list */
    attributes: Array<Attribute>;
    /** onAttributeClicked */
    onAttributeClicked: (attribute: Attribute) => void;
    /** onAttributeAdded When a new attribute is added */
    onAttributeAdded: (attribute: Attribute) => void;
    /** category of new attributes */
    attributeCategory?: AttributeCategory;

    disabled?: string[];
}

export default function AttributeList({
    attributes,
    onAttributeClicked,
    onAttributeAdded,
    attributeCategory,
    addText = 'Add Attribute',
    disabled = [],
}: AttributeListProps) {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);

    const [dialogOpen, toggleDialogOpen] = useState<boolean>(false);

    const [attributeToCreate, setAttributeToCreate] = useState<Attribute>({
        name: 'New Attribute',
        type: AttributeValueType.TEXT,
        category: attributeCategory ?? AttributeCategory.Other,
        sub_category: '',
        organization_uuid: '',
    });

    const handleAttributeClicked = (attribute: Attribute) => {
        onAttributeClicked(attribute);
        setAnchorEl(null);
    };

    const handleAttributeAdded = (attribute: Attribute) => {
        setAttributeToCreate(attribute);
        toggleDialogOpen(true);
    };

    const handleAttributeDialogSubmit = () => {
        onAttributeAdded(attributeToCreate);
        toggleDialogOpen(false);
        setAnchorEl(null);
    };

    return (
        <div>
            <CustomButton
                onClick={handleClick}
                color="primary"
                startIcon={<AddCircleOutline />}
                size="small"
            >
                {addText}
            </CustomButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ minWidth: 375 }}
            >
                <AttributeListSelection
                    attributes={attributes}
                    attributeCategory={attributeCategory}
                    onAttributeAdded={handleAttributeAdded}
                    onAttributeClicked={handleAttributeClicked}
                    disabled={disabled}
                />
            </Popover>
            <CreateAttributeDialog
                open={dialogOpen}
                handleSubmit={handleAttributeDialogSubmit}
                handleClose={() => toggleDialogOpen(false)}
                value={attributeToCreate}
                onChange={(attribute) => setAttributeToCreate(attribute)}
            />
        </div>
    );
}
