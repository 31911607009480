import { Box, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { GridSlotsComponentsProps } from '@mui/x-data-grid-premium';

declare module '@mui/x-data-grid-premium' {
    interface FooterPropsOverrides {
        hasNext: boolean;
        hasPrevious: boolean;
        onNext: () => void;
        onPrevious: () => void;
        page: number;
        total?: number;
    }
}
const CustomPagination = (props: NonNullable<GridSlotsComponentsProps['footer']>) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <IconButton
                disabled={!props.hasPrevious}
                onClick={() => props.onPrevious && props.onPrevious()}
            >
                <ArrowBackIosIcon />
            </IconButton>
            Page {props.page}
            {props.total ? ` (${props.total} results)` : ''}
            <IconButton disabled={!props.hasNext} onClick={() => props.onNext && props.onNext()}>
                <ArrowForwardIosIcon />
            </IconButton>
        </Box>
    );
};

export default CustomPagination;
