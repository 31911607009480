import AddIcon from '@mui/icons-material/Add';
import { Alert, Avatar, Container, Grid, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';
import { getIndividualLink } from '../../api';
import { CustomButton, TypographyBodyOne } from '../../components';
import PublicIndividualLinkSkeleton from '../../components/Skeletons/PublicIndividualLinkSkeleton';
import { attachManagingUserToIndividual } from '../../modules/community/api/groups.api';
import { authSelector } from '../../redux/reducers/auth';
import { getUserData } from '../../redux/reducers/user';
import { useAppDispatch } from '../../redux/store';
import ROUTES from '../../Routes/routes';
import { PublicIndividual } from '../../utils';

interface OwnProps {}

type Props = OwnProps;

const IndividualLink: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
    let { id } = useParams<{ id: string }>();
    const [autoAccept] = useQueryParam('auto_accept', NumberParam);
    let { push } = useHistory();

    const { isAuth } = useSelector(authSelector);

    const [loading, setLoading] = useState<boolean>(false);
    const [accepting, setAccepting] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [individual, setIndividual] = useState<PublicIndividual | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const dispatch = useAppDispatch();
    const history = useHistory();

    useEffect(() => {
        if (autoAccept === 1) {
            handleAcceptInviteClicked();
        }
    }, [autoAccept]);

    const handleAcceptInviteClicked = () => {
        if (!isAuth) {
            push(ROUTES.Login.path, {
                referrer: ROUTES.IndividualLink.path.replace(`:id`, id) + '?auto_accept=1',
                referrer_message: 'Log in now to manage this account.',
            });
        } else {
            setAccepting(true);
            attachManagingUserToIndividual(id)
                .then(({ data }) => {
                    dispatch(getUserData());
                    push(ROUTES.IndividualDetails.path.replace(':individualId', data.uuid));
                })
                .catch((e) => {
                    if (e.response.status >= 400 && e.response.status < 500) {
                        setErrorMessage(e.response.data.message);
                    } else {
                        setErrorMessage(
                            'We had an unknown technical error and have been informed. Please try again later.',
                        );
                    }
                })
                .finally(() => {
                    setAccepting(false);
                });
        }
    };

    useEffect(() => {
        setLoading(true);
        getIndividualLink(id)
            .then((response) => {
                setIndividual(response.data);
                setLoaded(true);
            })
            .catch(() => {
                push(ROUTES.NotFound.path);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        if (individual && !individual?.can_accept) {
            history.push('/login');
        }
    }, [individual, history]);

    return (
        <Container maxWidth="lg">
            <Grid
                sx={{
                    marginTop: '40px',
                }}
                container
                direction="column"
                spacing={10}
            >
                {!loading && loaded && individual && (
                    <React.Fragment>
                        <Grid
                            sx={{
                                margin: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            item
                        >
                            <Avatar
                                sx={{
                                    width: 140,
                                    height: 140,
                                    variant: 'rounded',
                                }}
                                alt={individual.name}
                                src={individual.image_urls.avatar || ''}
                                variant={'rounded'}
                            />
                            <AddIcon />
                            <Avatar
                                sx={{
                                    width: 140,
                                    height: 140,
                                    variant: 'rounded',
                                }}
                                alt={individual.organization.name}
                                src={individual.organization.image_urls.avatar || ''}
                                variant={'rounded'}
                            />
                        </Grid>
                        <Grid item>
                            {individual.can_accept && (
                                <Typography variant={'h4'} align="center">
                                    Click below to access {individual.name}&apos;s profile through
                                    the 1TUL athletic management system
                                </Typography>
                            )}
                        </Grid>
                        <Grid item>
                            {individual.can_accept && (
                                <TypographyBodyOne align={'center'}>
                                    Once you accept this invite you'll be able to start interacting
                                    with {individual.organization.name} through 1Tul.
                                </TypographyBodyOne>
                            )}
                        </Grid>
                        {individual.can_accept && (
                            <Grid
                                item
                                sx={{
                                    margin: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <CustomButton
                                    disabled={accepting}
                                    onClick={handleAcceptInviteClicked}
                                    variant="contained"
                                    color="primary"
                                >
                                    Access {individual.organization.name}
                                </CustomButton>
                            </Grid>
                        )}
                        {errorMessage && (
                            <Grid
                                item
                                sx={{
                                    margin: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Alert severity="error">{errorMessage}</Alert>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {loading && <PublicIndividualLinkSkeleton />}
            </Grid>
        </Container>
    );
};

export default IndividualLink;
