import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { getRoles } from 'api';
import type { Contact, Invite, Link, Role, SharedUser } from 'utils';
import SharingDrawer from '../../../components/Navigation/SharingDrawer';
import {
    addUsersToDashboard,
    getInvitesForDashboard,
    getLinksForDashboard,
    getSharedUsersForDashboard,
    removeSharedUserFromDashboard,
    revokeInviteForDashboard,
} from '../api/dashboards.api';

interface Props {
    dashboardId: string;
    open: boolean;
    onClose: () => void;
    onUsersLoaded: (users: Array<SharedUser>) => void;
}

export default function DashboardSharingDrawer({
    dashboardId,
    open,
    onClose = () => {},
    onUsersLoaded = () => {},
}: Props): ReactElement {
    const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const [sharingInProgress, setSharingInProgress] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [links, setLinks] = useState<Array<Link>>([]);
    const [invites, setInvites] = useState<Array<Invite>>([]);
    const previousDashboardId = useRef<string>('');

    useEffect(() => {
        if (previousDashboardId.current !== dashboardId) {
            loadSharedUsers(dashboardId);
            loadLinks(dashboardId);
            loadInvites(dashboardId);
            getRoles('dashboard')
                .then((response) => {
                    setRoles(response.data);
                })
                .catch(() => {});
        }
        previousDashboardId.current = dashboardId;
    }, [dashboardId, open]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadSharedUsers = (id: string) => {
        setSharingInProgress(true);
        getSharedUsersForDashboard(id)
            .then((response) => {
                setSharedUsers(response.data);
                setSharingInProgress(false);
                onUsersLoaded(response.data);
            })
            .catch(() => {});
    };

    const loadLinks = (id: string) => {
        getLinksForDashboard(id)
            .then((response) => {
                setLinks(response.data);
            })
            .catch(() => {});
    };

    const loadInvites = (id: string) => {
        getInvitesForDashboard(id)
            .then((response) => {
                setInvites(response.data);
            })
            .catch(() => {});
    };

    const onSharedUserRemoved = (user: SharedUser) => {
        setSharingInProgress(true);
        setSharedUsers((users) => users.filter((u) => u.uuid !== user.uuid));
        onUsersLoaded(sharedUsers.filter((u) => u.uuid !== user.uuid));
        removeSharedUserFromDashboard(dashboardId, user.uuid)
            .then(() => {
                setSharingInProgress(false);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const onShared = (message: string, users: Array<Contact>, role = 'dashboard-manager') => {
        setSharingInProgress(true);
        const usersToAdd: Array<string> = users.map((u) => u.uuid);
        addUsersToDashboard(dashboardId, usersToAdd, role, message)
            .then((response) => {
                setSharedUsers(response.data);
                setSharingInProgress(false);
                onUsersLoaded(response.data);
                loadInvites(dashboardId);
            })
            .catch(() => {
                setSharingInProgress(false);
            });
    };

    const handleInviteRevoked = (invite: Invite) => {
        setInvites((invites) => invites.filter((i) => i.uuid !== invite.uuid));
        revokeInviteForDashboard(dashboardId, invite.uuid)
            .then(() => {})
            .catch(() => {});
    };

    const handleInviteResent = (invite: Invite) => {
        onShared(
            '',
            [{ uuid: invite.email, name: invite.email, relationship: 'invitee', type: 'email' }],
            invite.role.name,
        );
    };

    const handleLinkDeletedClicked = (link: Link) => {
        setLinks((links) => links.filter((l) => l.uuid !== link.uuid));
    };
    const handleLinkCreated = (link: Link) => {
        setLinks((links) => [link, ...links]);
    };

    return (
        <SharingDrawer
            open={open}
            onClose={onClose}
            userAccessList={sharedUsers}
            onRemove={onSharedUserRemoved}
            onShared={onShared}
            processing={sharingInProgress}
            onLinkCreated={handleLinkCreated}
            onLinkDeleteClicked={handleLinkDeletedClicked}
            onLinkDeleteFailed={() => {}}
            onLinkDeleteSucceeded={() => {}}
            onInviteRevoked={handleInviteRevoked}
            onInviteResent={handleInviteResent}
            entityId={dashboardId}
            entityType="dashboard"
            links={links}
            roles={roles}
            invites={invites}
        />
    );
}
