import { AnyAction, CombinedState, combineReducers } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { authenticationReducer, AuthReducerState } from '../../modules/auth/store';
import { assessmentsSlice, AssessmentsState } from './assessments';
import { attributeSlice, AttributeState } from './attributes';
import { authSlice, AuthState } from './auth';
import { billingSlice, BillingState } from './billing';
import { CalendaReducerState, calendarReducer } from './calendar';
import { formSlice, FormState } from './form';
import { mediaSlice, MediaState } from './media';
import { messageSlice, MessageState } from './messages';
import { initialLogActivityState, logsSlice } from './movementLogs';
import { movementSlice, MovementsState } from './movements';
import { planReducer, PlanReducerState } from './plan';
import { programSlice, ProgramState } from './programs';
import { searchTokenSlice, SearchTokenState } from './searchToken';
import { sessionSlice, SessionsState } from './sessions';
import { socialReducer, SocialReducerState } from './social';
import { timelineSlice, TimelineState } from './timeline';
import { userSlice, UserState } from './user';

const appReducer = combineReducers({
    auth: authSlice.reducer,
    user: userSlice.reducer,
    billing: billingSlice.reducer,
    attributes: attributeSlice.reducer,
    movements: movementSlice.reducer,
    sessions: sessionSlice.reducer,
    programs: programSlice.reducer,
    logs: logsSlice.reducer,
    media: mediaSlice.reducer,
    messages: messageSlice.reducer,
    assessments: assessmentsSlice.reducer,
    searchToken: searchTokenSlice.reducer,
    timeline: timelineSlice.reducer,
    form: formSlice.reducer,
    calendarEvents: calendarReducer,
    social: socialReducer,
    authentication: authenticationReducer,
    plan: planReducer,
});

const rootReducer = (
    state:
        | CombinedState<{
              auth: AuthState;
              user: UserState;
              billing: BillingState;
              attributes: AttributeState;
              movements: MovementsState;
              sessions: SessionsState;
              programs: ProgramState;
              logs: initialLogActivityState;
              media: MediaState;
              messages: MessageState;
              assessments: AssessmentsState;
              searchToken: SearchTokenState;
              timeline: TimelineState;
              form: FormState;
              calendarEvents: CalendaReducerState;
              social: SocialReducerState;
              plan: PlanReducerState;
              authentication: AuthReducerState;
          }>
        | undefined,
    action: AnyAction,
) => {
    if (action.type === 'auth/logOut' || action.type === 'auth/logOutFromAllDevices') {
        storage.removeItem('root');
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
