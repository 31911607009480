import { Box, LinearProgress } from '@mui/material';
import React, { Fragment } from 'react';
import useTheme from '@mui/material/styles/useTheme';

export interface LinearProgressWithOverlayProps {
    loading: boolean;
}

const LinearProgressWithOverlay = ({ loading }: LinearProgressWithOverlayProps) => {
    const theme = useTheme();

    if (!loading) return <Fragment />;
    else
        return (
            <Fragment>
                <LinearProgress />
                <Box
                    sx={{
                        zIndex: theme.zIndex.modal + 1,
                        position: 'absolute',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }}
                />
            </Fragment>
        );
};

export { LinearProgressWithOverlay };
