import { Typography } from '@mui/material';
import {
    AuthScreenLayout,
    LoginForm,
    TextButton,
    TypographySubtitle,
    TypographyTitle,
} from 'components';
import { isEmpty } from 'lodash';
import Lottie from 'lottie-react';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ROUTES from 'Routes/routes';
import { LocationState } from 'utils';
import DbLogo from '../../../src/images/db_icon.png';
import LoginAnimation from '../../lottie-animations/sign-up.json';

const Login = () => {
    const { push } = useHistory();
    const location = useLocation<LocationState>();

    return (
        <AuthScreenLayout>
            <Typography
                sx={{ mt: 4, mb: 2, display: 'flex', alignItems: 'center' }}
                variant="subtitle1"
                component="div"
            >
                <img src={DbLogo} alt="1TUL Logo" width={60} />
            </Typography>

            <TypographyTitle
                align="center"
                sx={{
                    marginY: 5,
                    marginX: 0,
                }}
            >
                Welcome back
            </TypographyTitle>
            <TypographySubtitle align="center" sx={{ marginBottom: 8 }}>
                Login to manage your account
            </TypographySubtitle>

            {!isEmpty(location.state?.referrer_message) && (
                <TypographySubtitle
                    align="center"
                    sx={{
                        margin: 0,
                    }}
                >
                    {location.state?.referrer_message || ''}
                </TypographySubtitle>
            )}
            <LoginForm />
            <TypographyTitle>{`Don't have an account? `}</TypographyTitle>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Lottie
                    animationData={LoginAnimation} // Correct property name
                    loop={true}
                    autoplay={true}
                    style={{
                        width: '100px', // Adjust the width and height as needed
                        height: '100px',
                        cursor: 'pointer',
                    }}
                    onClick={() => push(ROUTES.Register.path, location.state)}
                />
            </div>

            <TypographyTitle
                align="center"
                sx={{
                    marginTop: 5,
                    marginX: 0,
                }}
            >
                Forget something?
            </TypographyTitle>
            <TextButton align="center" onClick={() => push(ROUTES.ForgotPassword.path)}>
                <Typography align="center">Forgot your email or password?</Typography>
            </TextButton>
            <TextButton align="center" onClick={() => push(ROUTES.ForgotUsername.path)}>
                <Typography align="center">Forgot your username?</Typography>
            </TextButton>
            <TypographyTitle sx={{ mt: 4 }} align="center">
                Want more information?
            </TypographyTitle>
            <TextButton
                align="center"
                onClick={() => {
                    push('/');
                }}
            >
                <Typography align="center">Visit our information page</Typography>
            </TextButton>
        </AuthScreenLayout>
    );
};

export default Login;
