import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from 'Routes/routes';

const Redirecting = () => {
    const { push } = useHistory();

    useEffect(() => {
        setInterval(() => push(ROUTES.Login.path), 2000);
    }, [push]);

    return (
        <Box
            sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 60,
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginRight: 10,
                    }}
                >
                    Redirecting
                </Typography>
                <CircularProgress
                    sx={{
                        color: 'white',
                    }}
                />
            </Box>
            <Typography
                sx={{
                    fontSize: 32,
                    color: 'white',
                    fontWeight: 'normal',
                    textAlign: 'center',
                }}
            >
                You need to login first to access this page.
            </Typography>
        </Box>
    );
};

export default Redirecting;
