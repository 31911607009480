import { FormikValues, useFormikContext } from 'formik';
import { useEffect } from 'react';

export interface FormikOnChangeProps {
    onChange: (isValid: boolean, dirty: boolean, values: FormikValues) => void;
}

const FormikOnChange = ({ onChange }: FormikOnChangeProps) => {
    const { values, isValid, dirty } = useFormikContext<FormikValues>();
    useEffect(() => {
        onChange(isValid, dirty, values);
    }, [dirty, isValid, values, onChange]);

    return null;
};

export default FormikOnChange;
