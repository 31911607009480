import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Meta, PaginationLink, Session } from 'utils';

type GetAllSessionsReturnType = {
    data: Session[];
    links: PaginationLink;
    meta: Meta;
};

type ReturnType = Promise<AxiosResponse<GetAllSessionsReturnType>>;

const getAllSessions = (query = {}): ReturnType => {
    return Request.get(`/workouts/sessions`, { params: query });
};

export default getAllSessions;
