import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { UserData } from 'utils';

type ReturnType = Promise<AxiosResponse<UserData>>;

const getCurrentUser = (): ReturnType => {
    return Request.get(`/authentication/current-user`);
};

export default getCurrentUser;
