import {
    Asset,
    getAssetPreviewUrl,
    getThumbnailFromVersions,
    getVideoSourcesFromVersions,
    isImageAsset,
    isLocalVideoAsset,
    isS3VideoAsset,
} from 'utils';
import { IconButton } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Cancel } from '@mui/icons-material';
import React from 'react';

export interface AssetPreviewCardProps {
    className?: string;
    asset: Asset;
    altTitle: string;
    onAssetRemoved?: (asset: Asset) => void | undefined;
    disableRemove?: boolean | null;
    fullwidth?: boolean;
}

export interface AssetPreviewCardStyleProps {
    disableRemove: boolean;
    fullwidth: boolean;
}

const AssetPreviewCard = ({
    className = '',
    asset,
    altTitle,
    onAssetRemoved,
    disableRemove = false,
    fullwidth = false,
}: AssetPreviewCardProps) => {
    const theme = useTheme();
    return (
        <div className={className} style={{ width: '100%' }}>
            {asset && isImageAsset(asset) && (
                <div
                    style={{
                        position: 'relative',
                        textAlign: disableRemove ? 'center' : 'right',
                    }}
                >
                    <img
                        src={getAssetPreviewUrl(asset)}
                        alt={altTitle}
                        style={{
                            maxWidth: fullwidth ? '100%' : 500,
                            maxHeight: 500,
                            objectFit: 'cover',
                            backgroundColor: '#000',
                        }}
                    />
                    {!disableRemove && (
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                padding: 0,
                                backgroundColor: '#fff',
                                '&:hover': {
                                    backgroundColor: '#fff',
                                    borderRadius: 100,
                                },
                            }}
                            size="large"
                            onClick={() => {
                                if (onAssetRemoved) {
                                    onAssetRemoved(asset);
                                }
                            }}
                        >
                            <Cancel />
                        </IconButton>
                    )}
                </div>
            )}
            {asset && isLocalVideoAsset(asset) && !isS3VideoAsset(asset) && (
                <div
                    style={{
                        position: 'relative',
                        textAlign: disableRemove ? 'center' : 'right',
                    }}
                >
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                        controls={true}
                        autoPlay={false}
                        style={{
                            maxWidth: fullwidth ? '100%' : 500,
                            maxHeight: 500,
                            objectFit: 'cover',
                            margin: 'auto',
                        }}
                        playsInline
                        controlsList={'nodownload'}
                    >
                        {getVideoSourcesFromVersions(asset.versions, false).map((s) => (
                            <source key={s.src} src={s.src} type={s.type}></source>
                        ))}
                        Your browser does not support the video tag.
                    </video>
                    {!disableRemove && (
                        <IconButton
                            onClick={() => {
                                if (onAssetRemoved) {
                                    onAssetRemoved(asset);
                                }
                            }}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                padding: 0,
                                backgroundColor: theme.palette.common.white,
                                '& :hover': {
                                    backgroundColor: theme.palette.common.white,
                                    borderRadius: 100,
                                },
                            }}
                            size="large"
                        >
                            <Cancel />
                        </IconButton>
                    )}
                </div>
            )}
            {asset && isS3VideoAsset(asset) && (
                <div>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                        controls={true}
                        autoPlay={false}
                        style={{
                            maxWidth: fullwidth ? '100%' : 500,
                            maxHeight: 500,
                            objectFit: 'cover',
                        }}
                        poster={getThumbnailFromVersions(asset.versions)}
                        playsInline
                        controlsList={'nodownload'}
                    >
                        {getVideoSourcesFromVersions(asset.versions, false).map((s) => (
                            <source key={s.src} src={s.src} type={s.type}></source>
                        ))}
                    </video>
                    {!disableRemove && (
                        <IconButton
                            onClick={() => {
                                if (onAssetRemoved) {
                                    onAssetRemoved(asset);
                                }
                            }}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                padding: 0,
                                backgroundColor: theme.palette.common.white,
                                '& :hover': {
                                    backgroundColor: theme.palette.common.white,
                                    borderRadius: 100,
                                },
                            }}
                            size="large"
                        >
                            <Cancel />
                        </IconButton>
                    )}
                </div>
            )}
        </div>
    );
};

export default AssetPreviewCard;
