import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Group, GroupType } from 'utils';

type ReturnType = Promise<AxiosResponse<Group>>;

const createGroup = (
    name: string,
    type: GroupType,
    organization?: string | null,
    logo?: string,
): ReturnType => {
    return Request.post(`/groups`, {
        type,
        name: name,
        logo,
        organization,
    });
};

export default createGroup;
