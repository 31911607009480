import * as yup from 'yup';

const email = yup.object({
    email: yup.string().default('').email('Enter a valid email').required('Email is required'),
});

const username = yup.object({
    username: yup
        .string()
        .default('')
        .matches(
            /^[a-z0-9_-]{5,20}$/gi,
            'Username may only contain at least 5 letters, numbers, dashes and underscores.',
        )
        .required('Username is required'),
});

const passwordAndConfirmPassword = yup.object({
    password: yup
        .string()
        .default('')
        .required('Password is required')
        .min(8, ' ')
        .matches(/[A-Z]/, ' ')
        .matches(/[a-z]/, ' ')
        .matches(/[\d@#$%^&*(),.?":{}|<>]/, ' '),
    confirmPassword: yup
        .string()
        .default('')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const usernameAndPassword = yup.object({
    username: yup
        .string()
        .default('')
        .matches(
            /^[a-z0-9_-]{5,20}$/gi,
            'Username may only contain atleast 5 letters, numbers, dashes and underscores.',
        )
        .required('Username is required'),
    password: yup
        .string()
        .default('')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
});

const usernameAndPasswordSimple = yup.object({
    userName: yup.string().default('').required('Username is required'),
    password: yup.string().default('').required('Password is required'),
});

const profile = yup.object({
    firstName: yup
        .string()
        .default('')
        .required('Firstname is required')
        .min(2, 'First name is too short')
        .max(50, 'First name is too long'),
    lastName: yup
        .string()
        .default('')
        .required('Lastname is required')
        .min(2, 'Last name is too short')
        .max(50, 'Last name is too long'),
    phone: yup
        .string()
        .default('')
        .required('Phone Number is required')
        .matches(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/, 'Phone Number is invalid'),
    email: yup.string().default('').email('Enter a valid email').required('Email is required'),
});

const registration = yup.object({
    email: yup.string().default('').email('Enter a valid email').required('Email is required'),
    password: yup
        .string()
        .default('')
        .required('Password is required')
        .min(8, 'Password must contain min 8 characters'),
    firstName: yup
        .string()
        .default('')
        .required('First name is required')
        .min(2, 'First name is too short')
        .max(50, 'First name is too long'),
    lastName: yup
        .string()
        .default('')
        .required('Last name is required')
        .min(2, 'Last name is too short')
        .max(50, 'Last name is too long'),
    phone: yup
        .string()
        .default('')
        .required('Phone Number is required')
        .matches(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/, 'Phone Number is invalid'),
    user_name: yup
        .string()
        .matches(
            /^[a-z0-9_-]{5,20}$/gi,
            'Must be 5 characters only numbers, letters, dashes and underscores',
        )
        .default('')
        .required('Username is required'),
    password_confirmation: yup
        .string()
        .default('')
        .required('Password confirmation is required')
        .oneOf([yup.ref('password')], "Passwords don't match"),
});

const createOrganization = yup.object({
    name: yup.string().default('').max(200).required('Organization name is required'),
    zip: yup
        .string()
        .default('')
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Must be 5 or 9 digits')
        .required('Zip is required'),
    organizationType: yup.string().default('').required('Organization type is required'),
    organizationLogo: yup.string().default('').notRequired(),
    owner: yup.boolean(),
});

const createMovement = yup.object({
    name: yup
        .string()
        .default('')
        .max(200, 'Maximum length of the title is 200 characters')
        .required('Title is required'),
    description: yup
        .string()
        .default('')
        .max(1000, 'Maximum length of the description is 1000 characters')
        .nullable(),
    organization: yup
        .string()
        .default('')
        .max(500, 'Maximum length of the organization is 500 characters')
        .nullable(),
});

const createSession = yup.object({
    title: yup
        .string()
        .default('')
        .max(200, 'Maximum length of the session name is 200 characters')
        .required(),
    organization: yup
        .string()
        .default('')
        .max(500, 'Maximum length of the organization is 500 characters')
        .nullable(),
    duration: yup.number().default(null).nullable(),
});

const chooseOrganization = yup.object({
    organization: yup
        .string()
        .required('Please choose organization')
        .default('')
        .max(500, 'Maximum length of the organization is 500 characters')
        .nullable(),
});

const updateMember = yup.object({
    first_name: yup
        .string()
        .max(200, 'Max length is 200 characters')
        .required('First name is required'),
    last_name: yup
        .string()
        .max(200, 'Maximum length is 200 characters')
        .required('Last name is required'),
});

const createMember = yup.object({
    first_name: yup
        .string()
        .max(200, 'Max length is 200 characters')
        .required('First name is required'),
    last_name: yup
        .string()
        .max(200, 'Maximum length is 200 characters')
        .required('Last name is required'),
    member_type: yup.string().required('Type is required'),
});

const createAssessmentDialog = yup.object({
    title: yup.string().default('').required('Title is required'),
    type: yup.string().default('').required('Type is required'),
    organization: yup.string().default('').required('Organization is required'),
});

const createLink = yup.object({
    role: yup.string().required('Role is required'),
    code: yup.string().max(50, 'Maximum length is 50 characters'),
});

const createGroup = yup.object({
    groupName: yup.string().default('').max(200).required('Name is required'),
    organization: yup
        .string()
        .default('')
        .max(500, 'Maximum length of the organization is 500 characters')
        .required('Choose an affiliation'),
});

const createIndividual = yup.object({
    individualName: yup.string().default('').max(200).required('Name is required'),
    organization: yup
        .string()
        .default('')
        .max(500, 'Maximum length of the organization is 500 characters')
        .nullable(),
});

const editEvent = yup.object({
    title: yup.string().required('Event title is required').max(200),
    starts_at: yup
        .date()
        .nullable()
        .transform((v) => {
            return !isNaN(v) ? v : null;
        })
        .required('Starting date and time is required')
        .typeError('Invalid Date'),
    all_day: yup.boolean(),
    ends_at: yup
        .date()
        .nullable()
        .when(['starts_at', 'all_day'], (starts_at: Date | null, all_day: any) => {
            const dateSchema = yup
                .date()
                .nullable()
                .transform((v) => (!isNaN(v) ? v : null));
            if (all_day) {
                return dateSchema;
            }
            if (!starts_at) {
                return dateSchema;
            }
            starts_at.setSeconds(starts_at.getSeconds() + 1);
            return dateSchema
                .required('Enter a valid value for ends at')
                .min(starts_at, 'Ends at must be after starts at');
        }),
});

const yupSchemas = {
    email,
    profile,
    usernameAndPassword,
    usernameAndPasswordSimple,
    registration,
    createOrganization,
    username,
    passwordAndConfirmPassword,
    createMovement,
    createGroup,
    updateMember,
    createMember,
    createLink,
    createSession,
    createAssessmentDialog,
    createIndividual,
    chooseOrganization,
    editEvent,
};

export default yupSchemas;
