import { CheckCircle } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { millisecondsToDurationString } from 'app.functions';
import React from 'react';
import { BandOptions, Log, RPEOptions, SetAttributeType, TimeDisplayFormat } from 'utils';

export interface LogRowProps {
    index: number;
    goalSet: Log;
    applicableCriterias: Array<SetAttributeType>;
}

const LogRow = ({ index, goalSet, applicableCriterias }: LogRowProps) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                '& .MuiInputBase-root': {
                    borderBottom: `1px solid transparent`,
                    '&:hover, &:focus': {
                        borderBottom: `1px solid ${theme.palette.text.secondary}`,
                    },
                },
            }}
            key={goalSet.uuid}
        >
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p={10}
                    sx={{
                        padding: theme.spacing(2, 8),
                        [theme.breakpoints.down('md')]: {
                            padding: theme.spacing(2, 4),
                        },
                    }}
                >
                    <Box
                        sx={{
                            width: theme.spacing(25),
                        }}
                    >
                        <Typography variant="body2">{index + 1}</Typography>
                    </Box>

                    {/* Reps column */}
                    {applicableCriterias.indexOf(SetAttributeType.Reps) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.reps ?? 'NA'}&nbsp;
                            {goalSet.reps_goal != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.reps_goal})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Load column */}
                    {applicableCriterias.indexOf(SetAttributeType.Load) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.load_value ?? 'NA'}&nbsp;
                            {goalSet.load_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.load_goal_value} {goalSet.load_goal_unit})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Time column */}
                    {applicableCriterias.indexOf(SetAttributeType.Time) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {millisecondsToDurationString(
                                goalSet.time_value || 0,
                                (goalSet.time_display_format as TimeDisplayFormat) ||
                                    TimeDisplayFormat.Common,
                            )}
                            &nbsp;
                            <Typography variant="caption" color="textSecondary">
                                (Goal:{' '}
                                {millisecondsToDurationString(
                                    goalSet.time_goal_value || 0,
                                    (goalSet.time_goal_display_format as TimeDisplayFormat) ||
                                        TimeDisplayFormat.Common,
                                )}
                                )
                            </Typography>
                        </Box>
                    )}

                    {/* Distance column */}
                    {applicableCriterias.indexOf(SetAttributeType.Distance) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.distance_value ?? 'NA'}&nbsp;
                            {goalSet.distance_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.distance_goal_value}
                                    {goalSet.distance_goal_unit})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Range Of Motion column */}
                    {applicableCriterias.indexOf(SetAttributeType.RangeOfMotion) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.range_of_motion_value ?? 'NA'}&nbsp;
                            {goalSet.range_of_motion_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.range_of_motion_goal_value}
                                    {goalSet.range_of_motion_goal_unit})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Speed column */}
                    {applicableCriterias.indexOf(SetAttributeType.Speed) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.speed_value ?? 'NA'}&nbsp;
                            {goalSet.speed_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.speed_goal_value}
                                    {goalSet.speed_goal_unit})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Acceleration column */}
                    {applicableCriterias.indexOf(SetAttributeType.Acceleration) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.acceleration_value ?? 'NA'}&nbsp;
                            {goalSet.acceleration_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.acceleration_goal_value}
                                    {goalSet.acceleration_goal_unit})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Power column */}
                    {applicableCriterias.indexOf(SetAttributeType.Power) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.power_value ?? 'NA'}&nbsp;
                            {goalSet.power_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.power_goal_value}
                                    {goalSet.power_goal_unit})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* RSI column */}
                    {applicableCriterias.indexOf(SetAttributeType.RSI) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.rsi_value ?? 'NA'}&nbsp;
                            {goalSet.rsi_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.rsi_goal_value})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* RPM column */}
                    {applicableCriterias.indexOf(SetAttributeType.RPM) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.rpm_value ?? 'NA'}&nbsp;
                            {goalSet.rpm_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.rpm_goal_value})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Force column */}
                    {applicableCriterias.indexOf(SetAttributeType.Force) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.force_value ?? 'NA'}&nbsp;
                            {goalSet.force_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.force_goal_value}
                                    {goalSet.force_goal_unit})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Body Side column */}
                    {applicableCriterias.indexOf(SetAttributeType.BodySide) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.body_side ?? 'NA'}&nbsp;
                            {goalSet.body_side_goal != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.body_side_goal})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* RPE column */}
                    {/* RPE is stored as a number so we can run analytics on it later, but for the frontend user it is a string */}
                    {/* So we are using the value to string mapping from types */}
                    {applicableCriterias.indexOf(SetAttributeType.RPE) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.rpe_value ? RPEOptions[goalSet.rpe_value as number] : 'NA'}
                            &nbsp;
                            {goalSet.rpe_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {RPEOptions[goalSet.rpe_goal_value as number]})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Band column */}
                    {/* Band is stored as a number so we can run analytics on it later, but for the frontend user it is a string */}
                    {/* So we are using the value to string mapping from types */}
                    {applicableCriterias.indexOf(SetAttributeType.Band) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.band_value ? BandOptions[goalSet.band_value as number] : 'NA'}
                            &nbsp;
                            {goalSet.band_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {BandOptions[goalSet.band_goal_value as number]})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Rest column */}
                    {applicableCriterias.indexOf(SetAttributeType.Rest) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.rest_value ?? 'NA'}&nbsp;
                            {goalSet.rest_goal_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.rest_goal_value}
                                    {goalSet.rest_goal_unit})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* GPS Player Load column */}
                    {applicableCriterias.indexOf(SetAttributeType.GPSPlayerLoad) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.gps_player_load_value ?? 'NA'}&nbsp;
                            {goalSet.gps_player_load_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.gps_player_load_value})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* GPS Acceleration Count column */}
                    {applicableCriterias.indexOf(SetAttributeType.GPSAccelCount) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.gps_accel_count_value ?? 'NA'}&nbsp;
                            {goalSet.gps_accel_count_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.gps_accel_count_value})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* GPS Deceleration Count column */}
                    {applicableCriterias.indexOf(SetAttributeType.GPSDecelCount) >= 0 && (
                        <Box
                            sx={{
                                width: theme.spacing(50),
                                '& .MuiInputBase-root': {
                                    fontSize: 14,
                                    width: '100%',
                                },
                            }}
                        >
                            {goalSet.gps_decel_count_value ?? 'NA'}&nbsp;
                            {goalSet.gps_decel_count_value != null && (
                                <Typography variant="caption" color="textSecondary">
                                    (Goal: {goalSet.gps_decel_count_value})
                                </Typography>
                            )}
                        </Box>
                    )}

                    {/* Actions column */}
                    <Box
                        sx={{
                            width: theme.spacing(30),
                            marginRight: theme.spacing(4),
                            [theme.breakpoints.down('md')]: {
                                marginRight: 0,
                            },
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                                fontWeight: 700,
                            }}
                        >
                            {/* Hiding the rest for V1 */}
                            {goalSet.marked_as_complete ? (
                                <CheckCircle color="secondary" />
                            ) : (
                                <Typography variant="caption" color="textSecondary">
                                    Skipped
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>

                <Divider
                    sx={{
                        height: theme.spacing(5),
                        background: theme.palette.common.white,
                    }}
                />
            </>
        </Box>
    );
};

export default LogRow;
