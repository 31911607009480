import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Submission, SubmissionQuestion } from 'utils';

type ReturnType = Promise<AxiosResponse<Submission>>;

const saveFormSubmission = (
    formId: string,
    submissionId: string,
    submission: SubmissionQuestion[],
): ReturnType => {
    return Request.put(`/data/forms/${formId}/submissions/${submissionId}`, { submission });
};
export default saveFormSubmission;
