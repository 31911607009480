import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Organization } from 'utils';

type ReturnType = Promise<AxiosResponse<Organization>>;

const viewOrganizationBySlug = (slug: string): ReturnType => {
    return Request.get(`/organizations/slug/${slug}`);
};

export default viewOrganizationBySlug;
