import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Movement, SearchedMovement, SearchToken, somethingWentWrong } from 'utils';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import { getSearchToken, search } from 'api/Search';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { AlgoliaIndex } from 'api/Search/algoliaSearch';
import { Close } from '@mui/icons-material';
import { viewMovement } from 'api';
import Hidden from '@mui/material/Hidden';
import { CreateMovementModal } from '.';

export interface MovementSelectionDialogProps {
    open: boolean;
    onCancel: () => void;
    multiple?: boolean | null;
    onMovementSelected?: (movement: Movement) => void | undefined;
    onMultipleMovementsSelected?: (movements: Array<Movement>) => void | undefined;
    organizationUuid?: string | undefined;
}

export default function MovementSelectionDialog({
    open,
    onCancel,
    organizationUuid,
    multiple = false,
    onMovementSelected,
    onMultipleMovementsSelected,
}: MovementSelectionDialogProps): ReactElement {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    let allMovements = useRef<Array<Array<SearchedMovement>>>([]);

    const searchInputRef = React.createRef<HTMLInputElement>();
    const [openCreateMovementDialog, setOpenCreateMovementDialog] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string | null>(null);
    const [movements, setMovements] = useState<Array<Array<SearchedMovement>>>([]);
    const [newAddedMovement, setNewAddedMovement] = useState<Movement | null>(null);
    const [searchToken, setSearchToken] = useState<SearchToken | null>(null);
    const [selectedMovements, setSelectedMovements] = useState<Array<Movement>>([]);
    const [loadingMovement, setLoadingMovement] = useState<SearchedMovement | null>(null);
    const otherIndex = 50;

    const groupAlphabetically = (
        movements: Array<SearchedMovement>,
    ): Array<Array<SearchedMovement>> => {
        let sortedMovements: Array<Array<SearchedMovement>> = [];

        movements.sort((m1, m2) => m1.name.trim().localeCompare(m2.name.trim()));

        movements.forEach((movement) => {
            let movementName = movement.name.toLowerCase().trim();
            if (!movementName || movementName.length == 0) {
                return;
            }
            let alphaIndex: number;
            let firstCharCode = movementName.charCodeAt(0);

            // We are going to group like this :
            // [a-z] : Movements starting with alphabets in a group depending of their starting alphabet
            // All others movements in a single group called 'Others'
            if (firstCharCode > 96 && firstCharCode < 123) {
                // Movements starting with alphabets
                // These will have index in the grouping from 0 - 25
                alphaIndex = movementName.charCodeAt(0) - 97; // CharCode of 'a' is 97
            } else {
                // Movements starting with numbers other characters
                alphaIndex = otherIndex; // We basically need any index greater than 25 here so the `others` group is after the alphabets
            }

            if (!sortedMovements[alphaIndex]) {
                sortedMovements[alphaIndex] = [];
            }

            sortedMovements[alphaIndex].push(movement);
        });

        return sortedMovements;
    };

    const getAllMovements = useCallback(
        (searchToken: SearchToken) => {
            // Avoid getting all movements again
            if (allMovements.current.length > 0) {
                setMovements(allMovements.current);
                return;
            }

            search(AlgoliaIndex.Movement, {
                searchQuery: '*',
                hitsPerPage: 1000,
                searchToken: searchToken,
                // Filter results by organization if specified
                filters: organizationUuid ? [`organizationId:${organizationUuid}`] : [],
            })
                .then((response) => {
                    let results = groupAlphabetically(response.data.hits);
                    setMovements(results);
                    allMovements.current = results;
                })
                .catch(() => somethingWentWrong());
        },
        [organizationUuid],
    );

    const onSearch = debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        let newSearchQuery = (event.target.value || '').trim();
        if (newSearchQuery.length == 0 && isEmpty(searchQuery)) {
            // Repeatedly backspace pressed so don't send search requests
            return;
        }
        if (newSearchQuery.length == 0 && !isEmpty(searchQuery) && searchToken) {
            // The new search query is blank so get all movements
            setSearchQuery(newSearchQuery);
            getAllMovements(searchToken);
            return;
        }

        setSearchQuery(newSearchQuery);

        // Only send request when token is available and we a query something to search
        if (!isEmpty(newSearchQuery) && searchToken) {
            search(AlgoliaIndex.Movement, {
                searchQuery: newSearchQuery,
                searchToken: searchToken,
                // Filter results by organization if specified
                filters: organizationUuid ? [`organizationId:${organizationUuid}`] : [],
            })
                .then((response) => {
                    let results = groupAlphabetically(response.data.hits);
                    setMovements(results);
                })
                .catch(() => somethingWentWrong());
        }
    }, 100);

    const addMovementToSelection = (movement: Movement) => {
        if (!multiple && onMovementSelected) {
            // Only one movement selection is allowed
            onMovementSelected(movement);
            setOpenCreateMovementDialog(false);
            return;
        }

        let existingSelectedMovements = cloneDeep(selectedMovements);
        existingSelectedMovements.push(movement);
        setSelectedMovements(existingSelectedMovements);
        setNewAddedMovement(null);
    };

    // When a movement is selected, we have `SearchedMovement` instance
    // which has uuid and name
    // We will get the other movement details from the API so we can send it
    // to the parent component
    const onExistingMovementSelected = (selectedMovement: SearchedMovement) => {
        // Get the selected movement details
        setLoadingMovement(selectedMovement);
        viewMovement(selectedMovement.id)
            .then((response) => {
                addMovementToSelection(response.data);
                setLoadingMovement(null);
            })
            .catch(() => somethingWentWrong());
    };

    const clearResults = () => {
        setSearchQuery(null);
        setMovements([]);
        setSelectedMovements([]);
        setNewAddedMovement(null);
    };

    const removeSelectedMovementAtIndex = (index: number) => {
        let existingSelectedMovements = cloneDeep(selectedMovements);
        existingSelectedMovements.splice(index, 1);
        setSelectedMovements(existingSelectedMovements);
    };

    const handleCloseDialog = (): void => {
        onMultipleMovementsSelected?.(selectedMovements);
        clearResults();
        onCancel();
    };
    // The algolia search has permissions (visible by filters) which handle what data a user can see.
    // So to always have fresh permissions, we will request a new token when the component is initialized on the page.
    useEffect(() => {
        if (!searchToken) {
            getSearchToken()
                .then((response) => {
                    setSearchToken(response.data);
                })
                .catch(() => somethingWentWrong());
        }
    }, [searchToken]);

    useEffect(() => {
        if (searchToken) {
            getAllMovements(searchToken);
        }
    }, [open, searchToken, getAllMovements]);

    return (
        <>
            <Dialog
                fullScreen={fullScreen}
                open={open && !openCreateMovementDialog}
                onClose={handleCloseDialog}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Box display="flex" alignItems="center" justifyContent="center">
                        {selectedMovements.length > 0 ? (
                            ''
                        ) : (
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseDialog}
                                sx={{
                                    position: 'absolute',
                                    right: theme.spacing(4),
                                    top: theme.spacing(4),
                                    color: theme.palette.grey[500],
                                }}
                                size="large"
                            >
                                <Close />
                            </IconButton>
                        )}
                    </Box>
                </DialogTitle>
                <DialogContent
                    sx={{
                        minHeight: theme.spacing(300),
                        [theme.breakpoints.up('md')]: {
                            minWidth: 600,
                        },
                    }}
                >
                    <>
                        <TextField
                            fullWidth
                            placeholder="Search..."
                            onChange={onSearch}
                            inputRef={searchInputRef}
                        />
                    </>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        {selectedMovements.length > 0 ? (
                            <Button
                                size="small"
                                variant="contained"
                                onClick={handleCloseDialog}
                                color="primary"
                                sx={{
                                    backgroundImage:
                                        'linear-gradient(180deg, #64B6F7 0%, #0B79D0 64%)',
                                    marginTop: theme.spacing(4),
                                }}
                            >
                                Save Movements to Log{' '}
                                <Hidden smUp>
                                    {' '}
                                    <TouchAppIcon />{' '}
                                </Hidden>
                            </Button>
                        ) : (
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseDialog}
                                sx={{
                                    position: 'absolute',
                                    right: theme.spacing(4),
                                    top: theme.spacing(4),
                                    color: theme.palette.grey[500],
                                }}
                                size="large"
                            >
                                <Close />
                            </IconButton>
                        )}
                    </Box>
                    {searchToken ? (
                        <Box
                            textAlign="center"
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            style={{ gap: 10 }}
                        >
                            {newAddedMovement && (
                                <List
                                    dense
                                    sx={{ width: '100%' }}
                                    subheader={<ListSubheader>New movement</ListSubheader>}
                                >
                                    <ListItem disableGutters>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={newAddedMovement.name}
                                                src=""
                                                variant="rounded"
                                                sx={{
                                                    width: theme.spacing(17),
                                                    height: theme.spacing(17),
                                                }}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={newAddedMovement.name}
                                            sx={{
                                                textOverflow: 'ellipsis',
                                                maxWidth: '80%',
                                                [theme.breakpoints.down('md')]: {
                                                    maxWidth: '75%',
                                                },
                                            }}
                                        />
                                        <ListItemSecondaryAction sx={{ right: 0 }}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    addMovementToSelection(newAddedMovement);
                                                }}
                                                color="primary"
                                                size="small"
                                            >
                                                Add
                                            </Button>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            )}

                            <Box
                                mt={10}
                                display="flex"
                                alignItems="center"
                                style={{ gap: 10, minHeight: 70 }}
                                flexDirection="column"
                            >
                                {selectedMovements.length > 0 && (
                                    <Typography variant="body2">
                                        <Typography variant="caption">
                                            Added: {selectedMovements.map((m) => m.name).join(', ')}
                                        </Typography>
                                    </Typography>
                                )}
                            </Box>

                            {movements.length > 0 && (
                                <React.Fragment>
                                    <Box mt={4}>
                                        <Typography
                                            variant="caption"
                                            align="center"
                                            color="textSecondary"
                                        >
                                            {!searchQuery
                                                ? `Showing All Movements`
                                                : `Showing ${movements.reduce(
                                                      (count, m) => count + m.length,
                                                      0,
                                                  )} matching 
                                        ${movements.length > 1 ? 'movements' : 'movement'}`}
                                        </Typography>
                                    </Box>
                                    <List
                                        dense
                                        sx={{
                                            width: '100%',
                                            maxHeight: 520,
                                            overflowX: 'hidden',
                                            overflowY: 'scroll',
                                        }}
                                    >
                                        {movements.map(
                                            (
                                                movementGroupedByAlpha: Array<SearchedMovement>,
                                                alphaIndex,
                                            ) => {
                                                return (
                                                    <React.Fragment
                                                        key={`grouped-by-alpha-${alphaIndex}`}
                                                    >
                                                        <ListItem disableGutters>
                                                            <Typography
                                                                variant="caption"
                                                                sx={{
                                                                    color: theme.palette.grey[500],
                                                                }}
                                                            >
                                                                {alphaIndex < otherIndex
                                                                    ? String.fromCharCode(
                                                                          alphaIndex + 97,
                                                                      ).toUpperCase()
                                                                    : 'Others'}
                                                            </Typography>
                                                        </ListItem>
                                                        {movementGroupedByAlpha.map(
                                                            (movement: SearchedMovement) => {
                                                                let selectedMovementIndex =
                                                                    selectedMovements.findIndex(
                                                                        (selectedMovement) =>
                                                                            selectedMovement.uuid ==
                                                                            movement.id,
                                                                    );
                                                                return (
                                                                    <ListItem
                                                                        key={`alpha-${alphaIndex}-movement-${movement.id}`}
                                                                        disableGutters
                                                                    >
                                                                        <ListItemAvatar>
                                                                            <Avatar
                                                                                alt={movement.name}
                                                                                src={
                                                                                    movement.objectAvatarPhoto ||
                                                                                    ''
                                                                                }
                                                                                variant="rounded"
                                                                                sx={{
                                                                                    width: theme.spacing(
                                                                                        17,
                                                                                    ),
                                                                                    height: theme.spacing(
                                                                                        17,
                                                                                    ),
                                                                                }}
                                                                            >
                                                                                {movement.name
                                                                                    .trim()
                                                                                    .substring(0, 1)
                                                                                    .toUpperCase()}
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            sx={{
                                                                                textOverflow:
                                                                                    'ellipsis',
                                                                                maxWidth: '80%',
                                                                                [theme.breakpoints.down(
                                                                                    'md',
                                                                                )]: {
                                                                                    maxWidth: '75%',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Typography variant="body2">
                                                                                {movement.name}
                                                                            </Typography>
                                                                            {!isEmpty(
                                                                                movement.organizationName,
                                                                            ) && (
                                                                                <Typography
                                                                                    variant="caption"
                                                                                    color="textSecondary"
                                                                                >
                                                                                    {
                                                                                        movement.organizationName
                                                                                    }
                                                                                </Typography>
                                                                            )}
                                                                        </ListItemText>
                                                                        <ListItemSecondaryAction
                                                                            sx={{ right: 0 }}
                                                                        >
                                                                            {movement.id ==
                                                                                loadingMovement?.id && (
                                                                                <span>Adding</span>
                                                                            )}
                                                                            {movement.id !=
                                                                                loadingMovement?.id &&
                                                                                selectedMovementIndex <
                                                                                    0 && (
                                                                                    <Button
                                                                                        variant="outlined"
                                                                                        onClick={() => {
                                                                                            onExistingMovementSelected(
                                                                                                movement,
                                                                                            );
                                                                                        }}
                                                                                        size="small"
                                                                                    >
                                                                                        Add
                                                                                    </Button>
                                                                                )}
                                                                            {movement.id !=
                                                                                loadingMovement?.id &&
                                                                                selectedMovementIndex >=
                                                                                    0 && (
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        onClick={() => {
                                                                                            removeSelectedMovementAtIndex(
                                                                                                selectedMovementIndex,
                                                                                            );
                                                                                        }}
                                                                                        color="primary"
                                                                                        size="small"
                                                                                    >
                                                                                        Added
                                                                                    </Button>
                                                                                )}
                                                                        </ListItemSecondaryAction>
                                                                    </ListItem>
                                                                );
                                                            },
                                                        )}
                                                    </React.Fragment>
                                                );
                                            },
                                        )}
                                    </List>
                                </React.Fragment>
                            )}
                            {movements.length == 0 && (
                                <Box fontStyle="italic" pt={20} pb={20} height={200}>
                                    <Typography align="center" variant="body2">
                                        Sorry, No movements matched your search.
                                    </Typography>
                                </Box>
                            )}
                            <Divider
                                variant="fullWidth"
                                component="div"
                                style={{ width: '100%' }}
                            />
                            <Box fontStyle="italic">
                                <Typography align="center" variant="subtitle2" color="inherit">
                                    Can&apos;t find it?
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={() => {
                                    setOpenCreateMovementDialog(true);
                                }}
                            >
                                Create a New Movement
                            </Button>
                        </Box>
                    ) : (
                        <Box pt={20} display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions />
            </Dialog>
            <CreateMovementModal
                open={openCreateMovementDialog}
                onClose={(movement: Movement | null) => {
                    if (movement) {
                        setNewAddedMovement(movement);
                        setSearchQuery(null);
                        if (searchToken) {
                            getAllMovements(searchToken);
                        }
                    }
                    setOpenCreateMovementDialog(false);
                }}
                defaultName={searchQuery}
                defaultOrganization={organizationUuid}
            />
        </>
    );
}
