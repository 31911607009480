import { Box, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

interface Props {
    children: React.ReactNode;
}

export default function CardTitle({ children }: Props): ReactElement {
    return (
        <Box py={8} borderBottom="1px solid #E0E0E0">
            <Typography variant="h4" sx={{ fontSize: 20 }}>
                {children}
            </Typography>
        </Box>
    );
}
