import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { createTag } from '../api/tags.api';
import { LinearProgress } from '@mui/material';
import { Tag, TagType } from '../../../app.types';

const filter = createFilterOptions<FreeSoloOption>();

interface Props {
    organization: string;
    choices: Array<FreeSoloOption>;
    onTagCreated: (tag: Tag) => void;
    value: FreeSoloOption | null;
    onChange: (tag: FreeSoloOption | null) => void;
    placeholder?: string;
    createProps?: { type?: TagType };
}

export default function FreeSoloCreateOptionDialog(props: Props) {
    const [open, toggleOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [dialogValue, setDialogValue] = React.useState({
        title: '',
    });

    const handleClose = () => {
        setDialogValue({
            title: '',
        });
        toggleOpen(false);
    };

    const handleSubmit = () => {
        setLoading(true);
        createTag({
            name: dialogValue.title,
            organization: props.organization,
            ...props.createProps,
        })
            .then((response) => {
                props.onChange({
                    id: response.data.id,
                    title: response.data.name,
                });
                props.onTagCreated(response.data);
                handleClose();
            })
            .catch(() => {})
            .finally(() => setLoading(false));
    };

    return (
        <React.Fragment>
            <Autocomplete
                value={props.value}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            toggleOpen(true);
                            setDialogValue({
                                title: newValue,
                            });
                        });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setDialogValue({
                            title: newValue.inputValue,
                        });
                    } else {
                        props.onChange(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            title: `Add "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                id="free-solo-dialog"
                options={props.choices}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option;
                    }
                    return option.title;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id ?? option.inputValue ?? option.title}>
                            {option.title}
                        </li>
                    );
                }}
                sx={{ minWidth: '68%' }}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        placeholder={props.placeholder}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                        {...params}
                    />
                )}
            />
            <Dialog open={open} onClose={handleClose}>
                {loading && <LinearProgress />}
                <DialogTitle>Add a new category</DialogTitle>
                <DialogContent>
                    <DialogContentText>Create a new category</DialogContentText>
                    <TextField
                        margin="dense"
                        id="name"
                        value={dialogValue.title}
                        onChange={(event) =>
                            setDialogValue({
                                ...dialogValue,
                                title: event.target.value,
                            })
                        }
                        label="title"
                        type="text"
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => handleSubmit()} type="submit">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export interface FreeSoloOption {
    id?: string;
    inputValue?: string;
    title: string;
}
