import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logOut } from '../../redux/reducers/auth';

const NoOrganizationsWarning: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { push } = useHistory();
    const dispatch = useDispatch();

    return (
        <Box
            sx={{
                flexGrow: 1,
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                You&#39;re not associated with any organizations.
            </Typography>
            <Grid container maxWidth={'lg'} spacing={2} justifyContent="center">
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent sx={{ minHeight: 225 }}>
                            <Typography gutterBottom variant="h5" component="div">
                                For Athletes
                            </Typography>
                            <Typography variant="body2">
                                If you&#39;re an athlete looking to join an organization, ask for an
                                invite to get started.
                            </Typography>
                        </CardContent>
                        <CardActions
                            sx={{
                                justifyContent: 'center',
                            }}
                        >
                            <Typography sx={{ my: 2 }}>
                                Ask Your Coach Or Office Staff For An Invite
                            </Typography>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent sx={{ minHeight: 225 }}>
                            <Typography gutterBottom variant="h5" component="div">
                                For Coaches and Admins
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                If you&#39;re a coach or an admin of an organization, you can create
                                a new organization.
                            </Typography>
                        </CardContent>
                        <CardActions
                            sx={{
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    push('/create-org-007');
                                }}
                                size="small"
                                variant={'outlined'}
                            >
                                Create New Organization
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>

            <Box sx={{ mt: 8 }}>
                <Button
                    onClick={() => {
                        dispatch(logOut());
                        push('/');
                    }}
                    variant={'outlined'}
                >
                    Or Log Out
                </Button>
            </Box>
        </Box>
    );
};

export default NoOrganizationsWarning;
