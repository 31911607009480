import { Request } from 'api';
import { AxiosResponse } from 'axios';

function authorizePusher(payload: {
    socket_id: string;
    channel_name: string;
}): Promise<AxiosResponse<{ auth: string }>> {
    return Request.post(`${process.env.REACT_APP_API_HOST}/broadcasting/auth`, payload);
}

export default authorizePusher;
