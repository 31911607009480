import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { PublicIndividual } from 'utils';

type ReturnType = Promise<AxiosResponse<PublicIndividual>>;

const getIndividualLink = (individualId: string): ReturnType => {
    return Request.post(`/public/links/individuals/${individualId}`);
};

export default getIndividualLink;
