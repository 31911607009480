import React, { useCallback, useRef, useState } from 'react';
import EmptyStateWithDashedBorder from '../../../components/Feedback/EmptyStates/EmptyStateWithDashedBorder';
import VideocamIcon from '@mui/icons-material/Videocam';
import { uploadToS3 } from '../community.func';
import { CircularProgress } from '@mui/material';
import SimpleDateTimePickerDialog from './SimpleDateTimePickerDialog';
import { DateTime } from 'luxon';

function VideoUploadButton({
    accessToken,
    onUploaded,
    defaultText = 'Standard Video Upload To Feed',
    chooseDateFirst = false,
}: {
    accessToken: string;
    onUploaded: (path: string, date?: DateTime | null) => void;
    defaultText?: string;
    chooseDateFirst?: boolean;
}) {
    const videoInputRef = useRef<HTMLInputElement>(null);
    const [progress, setProgress] = useState<number>(0);
    const [error, setError] = useState<string>('');
    const [uploading, setUploading] = useState<boolean>(false);
    const [showDateTimePicker, setShowDateTimePicker] = useState<boolean>(false);
    const [date, setDate] = useState<DateTime | null>(null);

    const getMessage = useCallback(() => {
        if (error) {
            return error;
        }
        if (uploading) {
            return `${Math.round(progress * 100)}%`;
        }

        return defaultText;
    }, [uploading, progress, error, defaultText]);

    const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files && e.currentTarget.files.length > 0) {
            uploadToS3(
                e.currentTarget.files[0],
                accessToken ?? '',
                function onComplete(path) {
                    onUploaded(path, date);
                    setUploading(false);
                },
                function onError() {
                    setError('Error while uploading. Try again.');
                    setUploading(false);
                },
                function onProgress(fileName, originalFileName, p) {
                    setProgress(p);
                },
                function onStarted() {
                    setUploading(true);
                },
            );
        }
    };

    return (
        <>
            <EmptyStateWithDashedBorder
                disabled={uploading}
                message={getMessage()}
                icon={
                    uploading ? (
                        <CircularProgress variant="determinate" value={progress * 100} size={60} />
                    ) : (
                        <VideocamIcon style={{ width: 80, height: 80 }} />
                    )
                }
                onClick={() => {
                    if (chooseDateFirst) {
                        setShowDateTimePicker(true);
                    } else {
                        videoInputRef?.current?.click();
                    }
                }}
            />
            <input
                type="file"
                accept="video/mp4,video/quicktime,video/x-m4v,video/*"
                ref={videoInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <SimpleDateTimePickerDialog
                onClose={() => setShowDateTimePicker(false)}
                open={showDateTimePicker}
                onChosen={(v) => {
                    setDate(v);
                    setShowDateTimePicker(false);
                    videoInputRef?.current?.click();
                }}
            />
        </>
    );
}

export default VideoUploadButton;
