import { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { Tag } from '../../../app.types';

export const createTag = (data: {
    name: string;
    organization: string;
}): Promise<AxiosResponse<Tag>> => {
    return Request.post(`/workouts/tags`, data);
};
