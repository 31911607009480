import {
    Button,
    Dialog,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { Link } from 'utils';
import DeleteIcon from '@mui/icons-material/Delete';
import QRCode from 'react-qr-code';
import DialogContent from '@mui/material/DialogContent';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { CopyLink } from 'components/Functional/CopyLink';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface LinkListProps {
    links: Array<Link>;
    onDelete: (link: Link) => void;
}

export default function LinkList({ links, onDelete }: LinkListProps): ReactElement {
    const onCopy = (link: string) => {
        console.log(link);
    };
    const [openQR, setOpenQR] = React.useState(false);

    const handleClickOpenQR = () => {
        setOpenQR(true);
    };

    const handleCloseQR = () => {
        setOpenQR(false);
    };
    return (
        <List sx={{ maxHeight: 550, overflowY: 'scroll', width: '100%' }} dense>
            {links.map((link) => (
                <ListItem key={link.uuid}>
                    <ListItemText
                        secondary={
                            <span
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>
                                    <strong>Role: </strong> {link.role.display_name}
                                </span>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Button onClick={handleClickOpenQR}>QR Code</Button>
                                        <Dialog
                                            open={openQR}
                                            TransitionComponent={Transition}
                                            keepMounted
                                            onClose={handleCloseQR}
                                            aria-describedby="alert-dialog-slide-description"
                                        >
                                            <DialogContent>
                                                <QRCode
                                                    size={356}
                                                    style={{
                                                        height: 'auto',
                                                        maxWidth: '100%',
                                                        width: '100%',
                                                        marginTop: 10,
                                                    }}
                                                    value={`${window.location.protocol}//${window.location.host}/links/${link.uuid}`}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                                <span>
                                    <strong>Password: </strong>
                                    {link.code || 'No Password Required'}
                                </span>
                            </span>
                        }
                    >
                        <CopyLink
                            link={`${window.location.protocol}//${window.location.host}/links/${link.uuid}`}
                            onCopyToClipboard={onCopy}
                        />
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <IconButton
                            onClick={() => onDelete(link)}
                            edge="end"
                            aria-label="delete"
                            size="large"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
}
