import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AdminAssignCoachesToGroup() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        <strong>Organization Coaches </strong> can access all group data and perform
                        group management functions after they have been granted permission through
                        the permissions link under the organization share drawer. The coach must
                        click on the permission link and enter the password you created to gain
                        access.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/884184190?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Assigning Coaches to the Organization'}
                            description={
                                'Assigning Coaches to the Organization grants them access to all the organizations data and groups.'
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminAssignCoachesToGroup;
