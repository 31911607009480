import React, { useEffect } from 'react';
import { getTags } from '../../../../api/tags.api';
import { Tag } from '../../../../app.types';
import MultiChipList from '../MultiChipList';

const TagMultiSelect = ({
    selected,
    setSelected,
    tagType = 'uploads',
}: {
    selected: string[];
    setSelected: (id: string[]) => void;
    tagType: 'uploads' | 'reports';
}) => {
    const [tags, setTags] = React.useState<Tag[]>([]);

    useEffect(() => {
        getTags({ 'filter[type]': tagType })
            .then((response) => {
                setTags(response.data);
            })
            .catch(() => {});
    }, [tagType]);

    return (
        <MultiChipList
            value={tags
                .filter((t) => selected.findIndex((tagInFilter) => tagInFilter === t.id) > -1)
                .map((v) => ({ label: v.name, id: v.id, isDeleteable: true }))}
            choices={tags.map((t) => ({
                label: t.name,
                id: t.id,
                isDeleteable: true,
            }))}
            onChange={(v) => setSelected(v.map((c) => c.id))}
        />
    );
};

export default TagMultiSelect;
