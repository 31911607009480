import React from 'react';
import LandingPageNavBar from './LandingPageNavBar';
import Learn1TULLanding from '../../modules/Learn1TUL/scenes/Learn1TULLanding';

interface LandingAuthScreenLayoutProps {
    children: React.ReactNode;
    containerClassName?: string;
}

function LandingPageFeatures({ children, containerClassName }: LandingAuthScreenLayoutProps) {
    return (
        <>
            <LandingPageNavBar />
            <Learn1TULLanding />
            {/* If you want to render children components, include them here */}
            <div className={containerClassName}>{children}</div>
        </>
    );
}

export default LandingPageFeatures;
