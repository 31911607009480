import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
    Box,
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Typography,
} from '@mui/material';
import { getIndividuals } from 'api';
import { CustomButton, LayoutWithTopBar, ListWithSearchContainer } from 'components';
import debounce from 'lodash/debounce';
import IndividualCard from 'modules/community/components/IndividualCard';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from 'Routes/routes';
import { Individual, Meta, PaginationLink, useToggle } from 'utils';
import { CreateIndividualModal, IndividualsListSkeleton } from '../components';
import IndividualInviteDialog from '../components/IndividualInviteDialog';

export default function ListOfIndividuals(): ReactElement {
    const { goBack, push } = useHistory();
    const initialUrlSearchParams = new URLSearchParams(window.location.search);
    const [query, setQuery] = useState<{
        'filter[name]'?: string;
        'filter[organization]'?: string;
        'filter[has_managing_user]'?: boolean;
        sort?: string;
        page?: number;
        limit?: number;
    }>({
        'filter[organization]': initialUrlSearchParams.get('filter[organization]') ?? undefined,
        page: 1,
        limit: 100,
        sort: 'name',
    });

    const [searchValue, setSearchValue] = useState<string>(query['filter[name]'] as string);
    const [individuals, setIndividuals] = useState<Individual[]>([]);
    const [selectedIndividual, setSelectedIndividual] = useState<Individual | null>(null);
    const [meta, setMeta] = useState<Meta>();
    const [links, setLinks] = useState<PaginationLink>();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [createIndividualModal, toggleCreateIndividualModal] = useToggle(false);
    const [snackMessage, setSnackMessage] = useState<string>('');

    const handleDialogClose = () => {
        setSelectedIndividual(null);
    };

    const handleSearchValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        debounceLoadData({ 'filter[name]': event.target.value, page: 1 });
    };

    const onNextClicked = () => {
        const nextPage = meta ? meta.current_page + 1 : 1;
        setQuery((query) => ({ ...query, page: nextPage }));
    };

    const onPrevClicked = () => {
        const prevPage = meta ? meta.current_page - 1 : 1;
        setQuery((query) => ({ ...query, page: prevPage }));
    };

    const handleIndividualClicked = (individual: Individual) => {
        push(ROUTES.IndividualDetails.path.replace(':individualId', individual.uuid));
    };

    const handleInviteClicked = (individual: Individual) => {
        setSelectedIndividual(individual);
    };

    const debounceLoadData = useCallback(debounce(setQuery, 400), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setLoading(true);
        if (!query) {
            return;
        }
        getIndividuals(query)
            .then((response) => {
                setLoaded(true);
                setIndividuals(response.data.data);
                setMeta(response.data.meta);
                setLinks(response.data.links);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {};
    }, [query]);

    return (
        <React.Fragment>
            <Snackbar
                open={snackMessage !== ''}
                message={snackMessage}
                autoHideDuration={6000}
                onClose={() => {
                    setSnackMessage('');
                }}
            />
            <LayoutWithTopBar title="Individuals" onBack={goBack}>
                <Container maxWidth="lg">
                    <ListWithSearchContainer
                        searchValue={searchValue}
                        setSearchValue={handleSearchValueChanged}
                        setOpen={() => {}}
                        onClickButton={toggleCreateIndividualModal}
                        isError={false}
                        errorMessage=""
                        searchBarPlaceHolder="Search"
                        buttonText="Add Individual"
                        searchBarLoader={false}
                        onFilter={(q) => {
                            setQuery((query) => ({
                                ...query,
                                ...q,
                            }));
                        }}
                        enabledFilters={{
                            'filter[organization]': query['filter[organization]'] || '',
                        }}
                    >
                        <Box
                            sx={{
                                marginBottom: 6,
                                marginTop: 5,
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Button
                                variant={
                                    !query['filter[has_managing_user]'] ? 'outlined' : 'contained'
                                }
                                onClick={() => {
                                    let newQuery = { ...query };
                                    if (query['filter[has_managing_user]']) {
                                        newQuery['filter[has_managing_user]'] = undefined;
                                    } else {
                                        newQuery['filter[has_managing_user]'] = true;
                                    }
                                    setQuery(newQuery);
                                }}
                                sx={{ marginRight: 8 }}
                            >
                                {!query['filter[has_managing_user]'] ? (
                                    <VerifiedUserIcon sx={{ color: '#1B9CEA' }} />
                                ) : (
                                    ''
                                )}

                                {query['filter[has_managing_user]']
                                    ? 'See All Athletes'
                                    : 'See Only Users'}
                            </Button>
                            <FormControl>
                                <InputLabel id="sort">Sort By</InputLabel>
                                <Select
                                    sx={{ minWidth: 200 }}
                                    labelId="sort"
                                    id="sort"
                                    value={query.sort}
                                    label="Sort By"
                                    onChange={(event) => {
                                        setQuery({
                                            ...query,
                                            sort: event.target.value,
                                            page: 1,
                                        });
                                    }}
                                    defaultValue={'name'}
                                >
                                    <MenuItem value={'name'}>A to Z</MenuItem>
                                    <MenuItem value={'-name'}>Z to A</MenuItem>
                                    <MenuItem value={'-created_at'}>Most Recently Added</MenuItem>
                                    <MenuItem value={'created_at'}>First Created</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        {!loaded && loading && <IndividualsListSkeleton />}
                        {loaded && individuals.length === 0 && (
                            <Box display="flex" justifyContent="center">
                                <Typography>No Individuals Found</Typography>
                            </Box>
                        )}
                        {individuals.map((individual) => (
                            <IndividualCard
                                key={individual.uuid}
                                onInviteClicked={() => handleInviteClicked(individual)}
                                onClick={() => handleIndividualClicked(individual)}
                                individual={individual}
                            />
                        ))}
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <CustomButton
                                variant="text"
                                color="primary"
                                disabled={!links?.prev}
                                startIcon={<NavigateBeforeIcon />}
                                onClick={() => onPrevClicked()}
                            >
                                Previous
                            </CustomButton>
                            <CustomButton
                                variant="text"
                                color="primary"
                                disabled={!links?.next}
                                endIcon={<NavigateNextIcon />}
                                onClick={() => onNextClicked()}
                            >
                                Next
                            </CustomButton>
                        </Box>
                    </ListWithSearchContainer>
                </Container>
            </LayoutWithTopBar>
            {selectedIndividual && (
                <IndividualInviteDialog
                    open={true}
                    onClose={handleDialogClose}
                    individual={selectedIndividual}
                />
            )}
            <CreateIndividualModal
                onIndividualCreatedFailed={(message: string) => {
                    setSnackMessage(message);
                }}
                onIndividualCreated={(individual) => {
                    toggleCreateIndividualModal();
                    setIndividuals([...(individuals ?? []), individual]);
                }}
                onClose={toggleCreateIndividualModal}
                open={createIndividualModal}
            />
        </React.Fragment>
    );
}
