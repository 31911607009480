import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { SharedUser } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<SharedUser>>>;

const getSharedUsersForForm = (id: string): ReturnType => {
    return Request.get(`/data/forms/${id}/users`);
};

export default getSharedUsersForForm;
