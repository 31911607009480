import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { styled, useTheme } from '@mui/system';
import { SxProps } from '@mui/system';
import moment from 'moment';
import { Fragment } from 'react';
import { NewMovementSet, SetAttribute, SetAttributeType } from 'utils';
import CustomCellTypography from './CustomCellTypography';

const MuiTableCell = styled(TableCell)({
    root: {
        borderBottom: 'none',
        padding: '0px',
    },
});

interface NewMovementSetsSummaryTableProps {
    sets: Array<NewMovementSet>;
    tablePadding?: 'checkbox' | 'normal' | 'none';
    tableBodyRowRootClassSx?: SxProps<Theme>;
    leftCellClassSx?: SxProps<Theme>;
    tableContainerClassSx?: SxProps<Theme>;
}

const NewMovementSetsSummaryTable = ({
    sets,
    tableContainerClassSx,
    tablePadding = 'normal',
    tableBodyRowRootClassSx,
    leftCellClassSx,
}: NewMovementSetsSummaryTableProps) => {
    function getAttributeValue(attributes: SetAttribute[], type: string) {
        let value: string | number = 0;
        attributes.map((attribute) => {
            if (attribute.type === type) {
                if (attribute.type === SetAttributeType.Time) {
                    value = formatDefaultValue(parseInt(attribute.value as string, 10) || 0);
                } else value = attribute.value;
            }
        });

        return value;
    }

    const formatDefaultValue = (seconds: number): string => {
        if (seconds < 59) {
            return `00:${seconds.toString().padStart(2, '0')}`;
        } else if (seconds < 3600) {
            return moment('2015-01-01').startOf('day').seconds(seconds).format('mm:ss');
        } else {
            return moment('2015-01-01').startOf('day').seconds(seconds).format('HH:mm:ss');
        }
    };

    const theme = useTheme();

    return (
        <TableContainer
            sx={{
                maxWidth: '90%',
                padding: theme.spacing(4, 4),

                [theme.breakpoints.down(801)]: {
                    maxWidth: '100%',
                },
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    padding: theme.spacing(0),
                    overflowX: 'scroll',
                },
                ...tableContainerClassSx,
            }}
        >
            <Table
                padding={tablePadding}
                size="small"
                sx={{
                    flex: 1,
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                padding: theme.spacing(3, 0),
                                ...leftCellClassSx,
                            }}
                        >
                            <CustomCellTypography
                                sx={{
                                    margin: theme.spacing(0, 2, 4, 0),
                                }}
                            >
                                Set
                            </CustomCellTypography>
                        </TableCell>
                        {Object.keys(SetAttributeType).map((type, key) => (
                            <MuiTableCell key={key} align="left">
                                <CustomCellTypography
                                    sx={{
                                        margin: theme.spacing(0, 2, 4, 0),
                                    }}
                                >
                                    {type}
                                </CustomCellTypography>
                            </MuiTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sets.map((set, key) => (
                        <TableRow key={key} sx={tableBodyRowRootClassSx}>
                            <Fragment>
                                <TableCell
                                    align="left"
                                    sx={{
                                        borderBottom: 'none',
                                        padding: theme.spacing(3, 0),
                                    }}
                                >
                                    <CustomCellTypography>{key + 1}</CustomCellTypography>
                                </TableCell>
                                {Object.keys(SetAttributeType).map((attribute, key2) => {
                                    return (
                                        <MuiTableCell key={key2} align="left">
                                            <CustomCellTypography>
                                                {getAttributeValue(sets[key].attributes, attribute)}
                                            </CustomCellTypography>
                                        </MuiTableCell>
                                    );
                                })}
                            </Fragment>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default NewMovementSetsSummaryTable;
