import { getSignedUrl, uploadFile } from 'api';
import { FormikErrors, FormikValues } from 'formik';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import React from 'react';
import { Remarkable } from 'remarkable';
import ROUTES from 'Routes/routes';
import { Time } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { MediaRendition } from '../app.types';
import { DATE_FORMAT } from './constants';
import {
    Asset,
    AssetVersion,
    AssetVersionType,
    Attribute,
    AttributeValue,
    CalendarEvent,
    CompletedAssessmentSections,
    DistanceUnit,
    EntityType,
    FullCalendarEvent,
    groupedByDateSubmissions,
    Log,
    Movement,
    MovementDetail,
    MovementLogReq,
    NewMovement,
    NewMovementSet,
    NewSessionMovement,
    Program,
    Session,
    SessionMovement,
    Set,
    SetAttribute,
    SetAttributeType,
    Submission,
    SubmissionValue,
} from './types';

export const deleteItemFromIndexOfArray = (arr: Array<any>, itemIndex: number): Array<any> => {
    if (arr.length > 0) return arr.filter((_x: any, index: any) => index !== itemIndex);
    return [];
};

export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;

export const preventDefault = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
    event.preventDefault();

export const checkPasswordStrength = (password: string) => {
    let strength = 0;

    if (password.length > 7) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[a-z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) strength += 1;

    switch (strength) {
        case 1:
            return {
                strength: (strength / 5) * 100,
                color: '#a80404',
                text: 'Weak',
            };
        case 2:
            return {
                strength: (strength / 5) * 100,
                color: '#d64343',
                text: 'Fair',
            };
        case 3:
            return {
                strength: (strength / 5) * 100,
                color: '#e6881c',
                text: 'Reasonable',
            };
        case 4:
            return {
                strength: (strength / 5) * 100,
                color: '#f5df1d',
                text: 'Good',
            };
        case 5:
            return {
                strength: (strength / 5) * 100,
                color: '#b2df49',
                text: 'Strong',
            };
        default:
            return {
                strength: (strength / 5) * 100,
                color: '#000',
                text: 'Too Short!',
            };
    }
};

export const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const generateUuid = (): string => {
    function S4(): string {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    return S4() + S4();
};

export const transformApiErrorsToFormikErrors = (errors: {
    [key: string]: Array<string>;
}): FormikErrors<FormikValues> => {
    const ret: { [key: string]: string } = {};
    for (const key in errors) {
        ret[key] = errors[key][0];
    }
    return ret;
};

export const hasLowerCase = (str: string) => {
    return /[a-z]/.test(str);
};

export const hasUpperCase = (str: string) => {
    return /[A-Z]/.test(str);
};

export const hasDigit = (str: string) => {
    return /[0-9]/.test(str);
};

export const hasSpecialCharacter = (str: string) => {
    // eslint-disable-next-line
    return /[@#$%^&*(),.?":{}|<>]/g.test(str);
};

export const isEmail = (str: string) => {
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        str,
    );
};

export const isImageAsset = (asset: Asset): boolean => {
    return asset.versions[0].file_mime_type.startsWith('image') || false;
};

export const isLocalVideoAsset = (asset: Asset): boolean => {
    return (
        asset.versions[0].file_mime_type.startsWith('video') &&
        asset.versions.some(({ type }) => type == 'uploaded')
    );
};

export const isS3VideoAsset = (asset: Asset): boolean => {
    return (
        asset.versions[0].file_mime_type.startsWith('video') &&
        getAssetPreviewUrl(asset).startsWith('https://')
    );
};

export const getAssetPreviewUrl = (asset: Asset): string => {
    if (asset.purpose == 'uploaded') {
        return (
            asset.versions.find(({ type }) => type === AssetVersionType.Uploaded)
                ?.file_signed_url || ''
        );
    }

    let originalAsset = asset.versions.find(({ type }) => type === AssetVersionType.Original);

    if (originalAsset?.file_mime_type.startsWith('video')) {
        return (
            asset.versions.find(({ type }) => type === AssetVersionType.Mp4)?.file_signed_url ||
            asset.versions.find(({ type }) => type === AssetVersionType.Original)
                ?.file_signed_url ||
            ''
        );
    }

    return originalAsset?.file_signed_url || '';
};

export const getThumbnailFromRenditions = (renditions: Array<MediaRendition>): string => {
    const thumbnail = renditions.find((v) => v.type === 'thumbnail')?.url;

    if (thumbnail) {
        return thumbnail;
    }

    // if the original is a gif just return it (we don't support resized gifs)
    const originalGif = renditions.find(
        (v) => v.type === 'original' && ['image/gif'].indexOf(v.mime_type) > -1,
    )?.url;

    if (originalGif) {
        return originalGif;
    }

    // otherwise get the original image
    const original = renditions.find(
        (v) => v.type === 'original' && ['image/jpeg', 'image/png'].indexOf(v.mime_type) > -1,
    )?.url;
    // or get the original image
    if (original) {
        return original;
    }

    return '';
};

export const getThumbnailFromVersions = (versions: Array<AssetVersion>): string => {
    const thumbnail = versions.find((v) => v.type === AssetVersionType.Thumbnail)
        ?.image_urls?.[200];

    if (thumbnail) {
        return thumbnail;
    }

    // if the original is a gif just return it (we don't support resized gifs)
    const originalGif = versions.find(
        (v) => v.type === AssetVersionType.Original && ['image/gif'].indexOf(v.file_mime_type) > -1,
    )?.file_signed_url;

    if (originalGif) {
        return originalGif;
    }

    // otherwise get the original image
    const original = versions.find(
        (v) =>
            v.type === AssetVersionType.Original &&
            ['image/jpeg', 'image/png'].indexOf(v.file_mime_type) > -1,
    )?.file_signed_url;
    // or get the original image
    if (original) {
        return original;
    }
    // if none of the above, get avatar
    const avatar = versions.find((v) => v.image_urls?.avatar !== undefined)?.image_urls?.avatar;
    return avatar || '';
};
export const hasVideoClipAsset = (assets: Asset[]): boolean => {
    return Boolean(
        getMp4ClipFromAssets(assets) ||
            getWebmClipFromAssets(assets) ||
            getThumbnailFromAssets(assets),
    );
};

export const getImageFromAssets = (assets: Asset[]) => {
    const versions = (assets && assets.flatMap((a) => a.versions)) ?? [];

    return getImageFromVersions(versions);
};

export const getIsProcessingFromAssets = (assets: Asset[]) => {
    return assets.findIndex((a) => a.status === 'processing') > -1;
};

export const getImageFromVersions = (versions: AssetVersion[]): string => {
    const mp4 = versions.find(
        (v) =>
            (v.type === AssetVersionType.Original || v.type === AssetVersionType.Gif) &&
            v.file_mime_type.startsWith('image'),
    )?.file_signed_url;

    if (mp4) {
        return mp4;
    }

    return '';
};

export const getThumbnailFromAssets = (assets: Asset[]) => {
    const versions = (assets && assets.flatMap((a) => a.versions)) ?? [];

    return getThumbnailFromVersions(versions);
};

export const getMp4ClipFromAssets = (assets: Asset[]): string => {
    const versions = (assets && assets.flatMap((a) => a.versions)) ?? [];
    return getMp4ClipFromVersions(versions);
};

export const getMp4ClipFromVersions = (versions: AssetVersion[]): string => {
    const mp4 = versions.find((v) => v.type === AssetVersionType.Mp4Clip)?.file_signed_url;

    if (mp4) {
        return mp4;
    }

    return '';
};
export const getMp4ClipFromRenditions = (renditions: MediaRendition[]): string => {
    const mp4 = renditions.find((r) => r.type === 'mp4_clip' && r.mime_type === 'video/mp4')?.url;

    if (mp4) {
        return mp4;
    }

    return '';
};

export const getWebmClipFromAssets = (assets: Asset[]): string => {
    const versions = (assets && assets.flatMap((a) => a.versions)) ?? [];

    return getWebmClipFromVersions(versions);
};

export const getWebmClipFromVersions = (versions: AssetVersion[]): string => {
    const webm = versions.find((v) => v.type === AssetVersionType.WebmClip)?.file_signed_url;

    if (webm) {
        return webm;
    }

    return '';
};

export const getTimePlaceholder = (key: Time, index: number): string => {
    return key == Time.TimeInHours
        ? index == 0
            ? 'hh'
            : 'mm'
        : key == Time.TimeInMinutes
        ? index == 0
            ? 'mm'
            : 'ss'
        : 'ss';
};

export const getTwoDigitString = (val: number): string => {
    return val.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });
};

export const makeEmptySet = (uuid: string): Set => {
    return {
        attributes: [
            {
                type: SetAttributeType.Reps,
                value: 1,
            },
            {
                type: SetAttributeType.Time,
                value: 0,
            },

            {
                type: SetAttributeType.Load,
                value: 0,
            },
        ],
        uuid: uuid,
        logged: false,
    };
};

export const transformLogToMovementDetail = (logs: Log[]): MovementDetail[] => {
    let movements = new Map();

    logs.forEach((log: Log) => {
        if (isEmpty(log.movement)) {
            return;
        }
        let movement: MovementDetail =
            movements.get(log.movement?.uuid) ||
            ({
                title: log.movement?.name,
                attributesList: [
                    SetAttributeType.Reps,
                    SetAttributeType.Load,
                    SetAttributeType.Distance,
                    SetAttributeType.Time,
                ],
                sets: [],
                logged: true,
                uuid: log.movement?.uuid,
            } as MovementDetail);

        let attributes: SetAttribute[] = [];

        attributes.push({ type: SetAttributeType.Reps, value: log.reps ?? 0 });
        attributes.push({ type: SetAttributeType.Load, value: log.load_value ?? 0 });
        attributes.push({ type: SetAttributeType.Distance, value: log.distance_value ?? 0 });
        attributes.push({
            type: SetAttributeType.Time,
            value: log?.time_value || 0,
        });

        movement.sets.push({
            attributes: attributes,
            logged: log?.marked_as_complete ?? false,
            uuid: log.uuid,
        });

        movements.set(log.movement?.uuid, movement);
    });

    return Array.from(movements.values());
};

export const transformToMovementLogReq = (movements: MovementDetail[]): MovementLogReq[] => {
    let bulkMovementLogRequest: MovementLogReq[] = [];

    movements.forEach((movement) => {
        movement.sets.forEach((set: Set) => {
            bulkMovementLogRequest.push({
                uuid: set.uuid,
                movement: movement.uuid,
                load_value: getLogValFromAttribute(set, SetAttributeType.Load),
                load_unit: 'lb',
                distance_value: getLogValFromAttribute(set, SetAttributeType.Distance),
                distance_unit: DistanceUnit.Yard,
                time_value: getLogValFromAttribute(set, SetAttributeType.Time),
                time_display_format: 's',
                reps: getLogValFromAttribute(set, SetAttributeType.Reps),
                marked_as_complete: set.logged,
            } as MovementLogReq);
        });
    });

    return bulkMovementLogRequest;
};

const getLogValFromAttribute = (set: Set, type: SetAttributeType): number | string => {
    for (let attribute of set.attributes) {
        if (attribute.type == type) {
            return attribute.value;
        }
    }
    return 0;
};

export const getMovement = (movement: Movement): MovementDetail => {
    return {
        title: movement.name,
        attributesList: [SetAttributeType.Reps, SetAttributeType.Time, SetAttributeType.Load],
        sets: [makeEmptySet(uuidv4())],
        logged: true,
        uuid: movement.uuid,
    };
};

const resrtructureBackendAttributes = (movement: SessionMovement) => {
    let attributes: SetAttribute[] = [];
    attributes.push({ type: SetAttributeType.Reps, value: movement?.pivot?.reps || 0 });
    attributes.push({ type: SetAttributeType.Load, value: movement?.pivot?.load_value || 0 });
    attributes.push({
        type: SetAttributeType.Distance,
        value: movement?.pivot?.distance_value || 0,
    });
    attributes.push({
        type: SetAttributeType.Time,
        value: movement?.pivot?.time_value || 0,
        subType: Time.TimeInSeconds,
    });
    return attributes;
};

export const restructureBackendMovementsToFrontEnd = (movements: SessionMovement[]) => {
    let updatedMovements: NewMovement[] = [];

    movements.map((movement, index) => {
        if (index > 0 && movement.uuid === movements[index - 1].uuid) {
            let newSet: NewMovementSet = {
                uuid: movement.uuid,
                attributes: resrtructureBackendAttributes(movement),
            };
            updatedMovements[updatedMovements.length - 1].sets.push(newSet);
        } else {
            let newMovement: NewMovement;
            newMovement = {
                uuid: movement.uuid,
                name: movement.name,
                thumbnail: getThumbnailFromAssets(movement.assets),
                sets: [
                    {
                        uuid: movement?.pivot?.uuid || '',
                        attributes: resrtructureBackendAttributes(movement),
                    },
                ],
                attributesList: [
                    SetAttributeType.Reps,
                    SetAttributeType.Load,
                    SetAttributeType.Distance,
                    SetAttributeType.Time,
                ],
            };
            updatedMovements.push(newMovement);
        }
    });

    return updatedMovements;
};

export const restructureNewMovementSet = (uuid: string, set: NewMovementSet) => {
    let convertedMovement: NewSessionMovement = {
        movement: uuid,
    };
    set.attributes.forEach(({ type, value, subType, secondaryValue }) => {
        switch (type) {
            case SetAttributeType.Distance:
                convertedMovement.distance_unit = 'm';
                convertedMovement.distance_value = +value;
                break;
            case SetAttributeType.Load:
                convertedMovement.load_unit = 'lb';
                convertedMovement.load_value = +value;
                break;
            case SetAttributeType.Reps:
                convertedMovement.reps = +value;
                break;
            case SetAttributeType.Time:
                if (!secondaryValue) {
                    convertedMovement.time_display_format = 's';
                    convertedMovement.time_value = +value;
                } else {
                    convertedMovement.time_display_format =
                        subType === Time.TimeInMinutes ? 'm' : 'h';
                    convertedMovement.time_value =
                        subType === Time.TimeInMinutes
                            ? +value + secondaryValue * 60
                            : +value * 60 + secondaryValue * 60 * 60;
                }
                break;
        }
    });
    return convertedMovement;
};

export const restructureNewMovements = (newMovements: NewMovement[]): NewSessionMovement[] => {
    let movements: NewSessionMovement[] = [];
    newMovements.forEach((newMovement) => {
        let convertedMovement: NewSessionMovement = {
            movement: newMovement.uuid,
        };
        newMovement.sets.forEach((set) => {
            convertedMovement = restructureNewMovementSet(newMovement.uuid, set);
            movements.push({ ...convertedMovement });
        });
    });
    return movements;
};

export const reoderNewMovements = (newMovements: NewMovement[]): string[] => {
    let reorderedMovements: string[] = [];

    newMovements.map((newMovement) =>
        newMovement.sets.map((set) => reorderedMovements.push(set.uuid)),
    );

    return reorderedMovements;
};

export const addSetInMovementOfSession = (
    movements: NewMovement[],
    response: SessionMovement[],
): string => {
    let updatedMovements: string[] = [];
    response.map((movement) => updatedMovements.push(movement?.pivot?.uuid || ''));

    let existingMovements: string[] = [];
    movements.map((movement) => movement.sets.map((set) => existingMovements.push(set.uuid)));

    if (updatedMovements.length - existingMovements.length === 1) {
        const addedMovement: string[] = updatedMovements.filter(
            (x) => !existingMovements.includes(x),
        );
        return addedMovement[0];
    }
    return '';
};

export const getListingUrlFromEntity = (entity: EntityType) => {
    switch (entity) {
        case 'session':
            return ROUTES.ListOfSessions.path;
        case 'movement':
            return ROUTES.ListOfMovements.path;
        default:
            break;
    }
};

export function getEmptyAttributesForNewSet() {
    return [
        {
            type: SetAttributeType.Reps,
            value: 1,
        },
        {
            type: SetAttributeType.Time,
            value: 0,
        },

        {
            type: SetAttributeType.Load,
            value: 0,
        },
        {
            type: SetAttributeType.Distance,
            value: 0,
        },
    ];
}

export function getSessionAttributes(session: Session) {
    let attributes: AttributeValue[] = [];
    session?.movements?.forEach((movement) => {
        attributes = [...attributes, ...(movement.attributes ?? [])];
    });
    return attributes;
}

export function getProgramAttributes(program: Program) {
    let attributes: AttributeValue[] = [];
    program.sessions.forEach((session) => {
        attributes = [...attributes, ...getSessionAttributes(session)];
    });
    return attributes;
}

export function convertDate(date: string) {
    return moment(date, DATE_FORMAT).format('LL');
}

export function convertDateToHours(date: number) {
    return moment.unix(date).format('LT').toLocaleLowerCase();
}

export function getDay(date: string) {
    let dt = moment(date, DATE_FORMAT).format('LL');
    let currentDate = moment();
    if (dt === currentDate.format('LL')) {
        return 'Today';
    } else if (dt === currentDate.subtract(1, 'day').format('LL')) {
        return 'Yesterday';
    } else if (dt === currentDate.add(1, 'day').format('LL')) {
        return 'Tomorrow';
    } else {
        return moment(date, DATE_FORMAT).format('dddd');
    }
}

export function transformSetsToNewMovementSets(sets: Set[]): NewMovementSet[] {
    let newMovementSets: NewMovementSet[] = [];
    sets.map((set) => newMovementSets.push({ uuid: set.uuid ?? '', attributes: set.attributes }));
    return newMovementSets;
}

export function convertTimestampToDate(dt: number | null): string {
    if (!dt) {
        return '';
    }
    let date = new Date(dt * 1000);
    return date.toISOString().split('T')[0];
}

export function getIdFromEndOfRoute(pathname: string) {
    const path = pathname.split('/');
    return path[path.length - 1];
}

type SearchParamsProps = 'page' | 'limit' | 'filter[title]' | 'filter[type]';

export function getPropertyFromSearchParams(url: string | null, accessor: SearchParamsProps) {
    if (url === null) return null;
    const searchParams = url?.split('?')[1];
    const getParams = new URLSearchParams(searchParams);

    return getParams.get(accessor);
}

export function groupAssessmentsByDate(submissions: Submission[]): groupedByDateSubmissions {
    let groupedSubmissions: groupedByDateSubmissions = {};
    const sortedSubmissionsByDate = submissions.sort((a, b) => b.submitted_at - a.submitted_at);

    sortedSubmissionsByDate.map((submission) => {
        const date = convertTimestampToDate(submission.submitted_at);
        if (groupedSubmissions[date]) {
            groupedSubmissions[date].push(submission);
        } else {
            groupedSubmissions[date] = [submission];
        }
    });
    return groupedSubmissions;
}

export function groupSectionsOfSubmissionValues(
    submissionValues: SubmissionValue[],
): CompletedAssessmentSections {
    let sections: CompletedAssessmentSections = {};

    submissionValues.map((submissionValue) => {
        if (submissionValue.section) {
            const id = submissionValue.section.uuid;
            if (!sections[id]) {
                sections[id] = { name: submissionValue.section.name, questions: [] };
            }
            if (submissionValue.question) {
                sections[id].questions.push({
                    question: submissionValue.question.question,
                    answer: submissionValue.text_value,
                });
            }
        }
    });
    return sections;
}

export function isExpired(expire: number) {
    let seconds = DateTime.now().toMillis();
    return Boolean(seconds > expire * 1000);
}

export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

export const getVideoFramerateFromVersions = (versions: AssetVersion[]): number => {
    const hls = versions.find((v) => v.type === AssetVersionType.Hls);
    const mp4 = versions.find((v) => v.type === AssetVersionType.Mp4);
    if (mp4 && mp4.framerate) {
        return mp4.framerate;
    }
    if (hls && hls.framerate) {
        return hls.framerate;
    }
    return 30;
};

export const getVideoSourcesFromVersions = (versions: AssetVersion[], showHls = true) => {
    let sources = [];
    const hls = versions.find((v) => v.type === AssetVersionType.Hls);
    const mp4 = versions.find((v) => v.type === AssetVersionType.Mp4);
    const original = versions.find(
        (v) => v.type === AssetVersionType.Original && v.file_mime_type.startsWith('video'),
    );
    const uploaded = versions.find(
        (v) => v.type === AssetVersionType.Uploaded && v.file_mime_type.startsWith('video'),
    );

    if (mp4 && mp4.file_signed_url) {
        sources.push({
            src: mp4.file_signed_url,
            type: mp4.file_mime_type,
        });
    } else if (hls && hls.file_signed_url && showHls) {
        sources.push({
            src: hls.file_signed_url,
            withCredentials: true,
        });
    } else if (original && original.file_signed_url) {
        sources.push({
            src: original.file_signed_url,
            type: original.file_mime_type,
        });
    } else if (uploaded && uploaded.file_signed_url) {
        sources.push({
            src: uploaded.file_signed_url,
            type: uploaded.file_mime_type,
        });
    }
    return sources;
};

export const getSubNavBarTabValue = (pathname: string): number | boolean => {
    if (pathname === ROUTES.TrainDashboard.path) {
        //train
        return 0;
    } else if (
        pathname === ROUTES.ListOfMovements.path ||
        pathname.includes(ROUTES.ViewMovement.path.replace(':id', ''))
    ) {
        return 1;
    } else if (pathname === ROUTES.ListOfSessions.path) {
        return 2;
    } else if (
        // class
        pathname === ROUTES.ClassAssessments.path ||
        pathname === ROUTES.Assessments.path ||
        pathname.includes(ROUTES.SubmitForm.path.replace(':id', '')) ||
        pathname.includes(ROUTES.CompleteAssessment.path.replace(':id', '')) ||
        pathname.includes(ROUTES.AssessmentSubmissionPreview.path.replace(':id', '')) ||
        pathname.includes(ROUTES.CreateAssessment.path.replace(':id', ''))
    ) {
        return 0;
    } else if (
        pathname === ROUTES.ListOfGroups.path ||
        pathname.includes(ROUTES.GroupDetails.path.replace(':id', ''))
    ) {
        // social
        return 0;
    } else if (pathname === ROUTES.ListOfIndividualGroups.path) {
        return 1;
    } else if (pathname === ROUTES.ViewTimeline.path) {
        //plan
        return 0;
    } else if (pathname === ROUTES.CalendarPage.path) {
        return 1;
    } else if (pathname === ROUTES.Posts.path) {
        return 0;
    } else if (pathname === ROUTES.MyFeed.path) {
        return 1;
    }

    return false;
};

export const getInitials = (text: string): string => {
    return text
        .split(' ')
        .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
        .join('');
};

export const refactorBackendEventsToFullCalendarEvents = (
    events: Array<CalendarEvent>,
): Array<FullCalendarEvent> => {
    return events.map(({ uuid, title, starts_at_timestamp, ends_at_timestamp, ...others }) => ({
        id: uuid,
        title,
        start: new Date(starts_at_timestamp * 1000).toISOString(),
        end: (ends_at_timestamp && new Date(ends_at_timestamp * 1000).toISOString()) || undefined,
        allDay: ends_at_timestamp === null,
        ...others,
    }));
};

export const isSubset = (superSet: AttributeValue[], subSet: Attribute[]): boolean => {
    let names = subSet.map((attribute) => attribute.name);

    let isSupserSet = superSet.every((attribute) => names.indexOf(attribute.name) >= 0);
    return isSupserSet;
};

export const handleFileUpload = async (file: File | undefined): Promise<string | undefined> => {
    if (!file) {
        return;
    }
    try {
        const getSignedUrlResponse = await getSignedUrl();
        await uploadFile(getSignedUrlResponse.data.url, file);
        return getSignedUrlResponse.data.key;
    } catch {
        throw new Error('Failed to upload file');
    }
};

export const getDisplayNameFromGoalSetAttributeType = (type: SetAttributeType): string => {
    if (type == SetAttributeType.RangeOfMotion) {
        return 'ROM';
    }
    if (type == SetAttributeType.BodySide) {
        return 'Body Side';
    }

    return type;
};

// returns the markup text converted to html
// target is set to blank for links in the converted html
export const getHtml = (value?: string): string => {
    const md = new Remarkable('commonmark', {
        linkTarget: '_blank',
    });
    return md.render(value ?? '');
};

export { default as yupSchemas } from './yupSchemas';
export { default as SCRUB_DATA } from './scrubData';
export { default as useToggle } from './useToggle';
export * from './types';
export * from './constants';
export * from './utilActions';
export { mapSessionStackUpdate } from './mapSessionStackUpdate';
export { timezones } from './timezones';
export type { TimezoneKey } from './timezones';
export { dayOfYear } from './dayOfYear';

export const sports = [
    'archery',
    'badminton',
    'baseball',
    'basketball',
    'bowling',
    'boxing',
    'bungeejumping',
    'cricket',
    'cycling',
    'darts',
    'fencing',
    'figureskating',
    'fishing',
    'football',
    'golf',
    'gymnastics',
    'hanggliding',
    'horseracing',
    'icehockey',
    'iceskating',
    'jetskiing',
    'judo',
    'karate',
    'kickboxing',
    'pool',
    'rockclimbing',
    'rollerskating',
    'rugby',
    'running',
    'scubadiving',
    'skateboarding',
    'skiing',
    'skydiving',
    'snowboarding',
    'soccer',
    'sumowrestling',
    'surfing',
    'swimming',
    'tabletennis',
    'taekwondo',
    'tennis',
    'volleyball',
    'waterskiing',
    'weightlifting',
    'windsurfing',
];

export const playerLevels = ['college', 'highschool', 'professional', 'youth'];
