import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Link } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Link>>>;

const getLinks = (individualId: string): ReturnType => {
    return Request.get(`/links/individuals/${individualId}`);
};

export default getLinks;
