import { Avatar, Box } from '@mui/material';

import React, { FC } from 'react';

type FilterLabelProps = {
    name: string;
    src?: string | undefined;
};

const FilterLabel: FC<React.PropsWithChildren<FilterLabelProps>> = ({ name, src }) => (
    <Box display="flex" alignItems="center">
        <Box marginRight={8}>
            <Avatar style={{ width: 24, height: 24 }} src={src} alt={name} />
        </Box>
        <Box>{name}</Box>
    </Box>
);

export { FilterLabel };
