import { Container } from '@mui/material';
import React from 'react';
import LayoutBox from './LayoutBox';

const BlankLayout: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    return (
        <LayoutBox>
            <Container sx={{ height: '100%' }} maxWidth={false}>
                {children}
            </Container>
        </LayoutBox>
    );
};

export default BlankLayout;
