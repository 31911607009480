import { Log } from '../../utils';
import { Set } from './plan.types';

export const convertToSets = (logs: Array<Log>): Array<Set> => {
    let goalSets = [];

    for (let i = 0; i < logs.length; i++) {
        if (!logs[i].movement) {
            continue;
        }
        if (i == 0 || logs[i].movement.uuid != logs[i - 1].movement.uuid) {
            goalSets.push({
                movement: logs[i].movement,
                logs: [logs[i]],
            });
        } else {
            goalSets[goalSets.length - 1].logs.push(logs[i]);
        }
    }

    return goalSets;
};

export const maybePluralize = (count: number, noun: string, suffix = 's') =>
    `${noun}${count !== 1 ? suffix : ''}`;
