import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
    Box,
    Checkbox,
    Container,
    Drawer,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridColDef } from '@mui/x-data-grid';
import {
    DataGridPremium,
    GridRenderCellParams,
    GridSortItem,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { Request } from 'api';
import React, { useEffect, useState } from 'react';
import { formatCurrency, formatDateTimeStringToLocale } from '../../../app.functions';
import CustomPagination from '../../../components/Functional/CustomPagination';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import PartnershipCommissionsAnalytics from '../components/PartnershipCommissionsAnalytics';
import { getCommissionTypes } from '../networking.api';
import { Commission } from '../networking.types';
import { NetworkingActionTypes } from '../state/networking.state';
import { useDispatchContext, useNetworkingCommissions } from '../state/NetworkingContext';

interface PartnershipEarningsSceneProps {
    organizationId: string;
}

const PartnershipEarningsScene: React.FC<PartnershipEarningsSceneProps> = ({ organizationId }) => {
    const commissions = useNetworkingCommissions();
    const dispatch = useDispatchContext();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedCheckMatchingPayoutUuid, setSelectedCheckMatchingPayoutUuid] = useState('');
    const [drawerCommissions, setDrawerCommissions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [commissionTypes, setCommissionTypes] = useState<string[]>([]);
    const [selectedCommissionType, setSelectedCommissionType] = useState<string[]>([]);
    const debouncedSearchTerm = useDebounce(searchTerm, 600);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    function useDebounce(value: string, delay: number) {
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    }

    useEffect(() => {
        if (drawerOpen && selectedCheckMatchingPayoutUuid) {
            Request.get(
                `/organizations/${organizationId}/networking/commissions?filter[check_matching_payout_uuid]=${selectedCheckMatchingPayoutUuid}`,
            )
                .then((response) => {
                    setDrawerCommissions(response.data.data);
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        }
    }, [organizationId, drawerOpen, selectedCheckMatchingPayoutUuid, dispatch]);

    useEffect(() => {
        dispatch({
            type: NetworkingActionTypes.COMMISSIONS_FILTERS_CHANGED,
            payload: {
                page: 1,
                search: debouncedSearchTerm,
            },
        });
    }, [debouncedSearchTerm, dispatch]);

    useEffect(() => {
        const fetchCommissionTypes = async () => {
            const response = await getCommissionTypes(organizationId);
            setCommissionTypes(response.data);
        };
        fetchCommissionTypes();
    }, [organizationId]);

    const handleTypeFilterChange = (event: SelectChangeEvent<string[]>) => {
        setSelectedCommissionType(event.target.value as string[]);
        dispatch({
            type: NetworkingActionTypes.COMMISSIONS_FILTERS_CHANGED,
            payload: {
                page: 1,
                'filter[type]': event.target.value,
            },
        });
    };

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Earnings Type',
            minWidth: 250,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                const containsCheckMatching = params.value.includes('Check Matching');
                const commission: Commission = params.row as Commission; // assuming params.row is of type Commission
                return (
                    <Box display="flex" alignItems="center">
                        <span>{params.value}</span>
                        {containsCheckMatching && commission.check_matching_payout_uuid && (
                            <IconButton
                                onClick={() => {
                                    setDrawerOpen(true);
                                    setSelectedCheckMatchingPayoutUuid(
                                        commission.check_matching_payout_uuid,
                                    );
                                }}
                            >
                                <MonetizationOnIcon />
                            </IconButton>
                        )}
                    </Box>
                );
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 100,
            disableColumnMenu: true,
            sortable: true,
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return formatCurrency({
                    amount: ((params.value ?? 0) / 100) as number,
                    currency: 'USD',
                });
            },
        },
        {
            field: 'earned_date',
            headerName: 'Earned On',
            minWidth: 180,
            disableColumnMenu: true,
            sortable: true,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                return formatDateTimeStringToLocale(params.value, 'America/Los_Angeles');
            },
        },
        {
            field: 'is_paid',
            headerName: 'Paid Out',
            minWidth: 50,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                return params.value ? 'Yes' : 'No';
            },
        },
        {
            field: 'partner',
            headerName: 'Partner',
            minWidth: 250,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (_: any, row: any) => {
                return row.other_partner?.organization?.name ?? '-';
            },
        },
        {
            field: 'athlete',
            headerName: 'Athlete',
            minWidth: 250,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (_: any, row: any) => {
                return row.athlete_user?.name ?? '-';
            },
        },
        {
            field: 'sellerPartner',
            headerName: 'Reseller',
            minWidth: 250,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (_: any, row: any) => {
                return row.seller_partner?.organization?.name ?? '-';
            },
        },
        {
            field: 'reason',
            headerName: 'Reason',
            minWidth: 250,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (_: any, row: any) => {
                return row.bonus?.description;
            },
        },
    ];

    const drawerColumns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Earnings Type',
            minWidth: 250,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Box display="flex" alignItems="center">
                        <span>{params.value}</span>
                    </Box>
                );
            },
        },
        {
            field: 'amount',
            headerName: 'Amount',
            minWidth: 100,
            disableColumnMenu: true,
            sortable: true,
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return formatCurrency({
                    amount: ((params.value ?? 0) / 100) as number,
                    currency: 'USD',
                });
            },
        },
        {
            field: 'earned_date',
            headerName: 'Earned On',
            minWidth: 180,
            disableColumnMenu: true,
            sortable: true,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                return formatDateTimeStringToLocale(params.value, 'America/Los_Angeles');
            },
        },
        {
            field: 'is_paid',
            headerName: 'Paid Out',
            minWidth: 50,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                return params.value ? 'Yes' : 'No';
            },
        },
        {
            field: 'partner',
            headerName: 'Partner',
            minWidth: 250,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (_: any, row: any) => {
                return row.other_partner?.organization?.name ?? '-';
            },
        },
        {
            field: 'athlete',
            headerName: 'Athlete',
            minWidth: 250,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (_: any, row: any) => {
                return row.athlete_user?.name ?? '-';
            },
        },
        {
            field: 'reason',
            headerName: 'Reason',
            minWidth: 250,
            disableColumnMenu: true,
            sortable: false,
            valueGetter: (_: any, row: any) => {
                return row.bonus?.description;
            },
        },
    ];

    const handleSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            dispatch({
                type: NetworkingActionTypes.COMMISSIONS_FILTERS_CHANGED,
                payload: {
                    page: 1,
                    sort: sortModel.map((item: GridSortItem) => {
                        if (item.sort === 'asc') {
                            return `${item.field}`;
                        }
                        return `-${item.field}`;
                    }),
                },
            });
        },
        [dispatch],
    );

    return (
        <Container
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
            }}
            maxWidth={'xl'}
        >
            <Stack spacing={2}>
                <PartnershipCommissionsAnalytics />
                <h2>Partnership Earnings List</h2>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isSmallScreen ? 'column' : 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={commissions.filters['filter[is_paid]'] === 0}
                                onChange={() => {
                                    dispatch({
                                        type: NetworkingActionTypes.COMMISSIONS_FILTERS_CHANGED,
                                        payload: {
                                            page: 1,
                                            'filter[is_paid]':
                                                commissions.filters['filter[is_paid]'] === 0
                                                    ? undefined
                                                    : 0,
                                        },
                                    });
                                }}
                                name="is_paid"
                                color="primary"
                                size="medium"
                            />
                        }
                        label="Show Only Unpaid Earnings"
                    />
                    <FormControl sx={{ m: 1, width: isSmallScreen ? '100%' : 300 }} size="small">
                        <InputLabel id="filter-by-type">Filter by Type</InputLabel>
                        <Select
                            label="Filter by Type"
                            variant={'outlined'}
                            value={selectedCommissionType}
                            onChange={handleTypeFilterChange}
                            multiple
                            placeholder={'Filter by Type'}
                            size="small"
                        >
                            {commissionTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search earnings type, partner or reason"
                        sx={{ width: isSmallScreen ? '100%' : 350 }}
                        size="small"
                    />
                </Box>
            </Stack>
            <Box
                sx={{
                    flex: 1,
                    width: '100%',
                    overflow: 'auto',
                }}
            >
                <DataGridPremium
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer',
                            backgroundColor: blue[100],
                        },
                        minHeight: '500px',
                    }}
                    loading={commissions.loading}
                    rows={commissions.data}
                    columns={columns}
                    sortingMode={'server'}
                    onSortModelChange={handleSortModelChange}
                    slots={{
                        loadingOverlay: CustomLoadingOverlay,
                        footer: CustomPagination,
                        noRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                <Typography variant="h6">No Results</Typography>
                            </Stack>
                        ),
                        noResultsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No Results When Filter Applied
                            </Stack>
                        ),
                    }}
                    getRowId={(row) => row.uuid}
                    slotProps={{
                        footer: {
                            hasNext: commissions.pagination.has_next,
                            hasPrevious: commissions.pagination.has_prev,
                            onNext: () => {
                                dispatch({
                                    type: NetworkingActionTypes.COMMISSIONS_FILTERS_CHANGED,
                                    payload: {
                                        page: commissions.pagination.current_page + 1,
                                    },
                                });
                            },
                            onPrevious: () => {
                                dispatch({
                                    type: NetworkingActionTypes.COMMISSIONS_FILTERS_CHANGED,
                                    payload: {
                                        page: commissions.pagination.current_page - 1,
                                    },
                                });
                            },
                            page: commissions.pagination.current_page,
                        },
                    }}
                />
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={Boolean(commissions.errorMessage)}
                message={commissions.errorMessage}
                onClose={() => {
                    dispatch({
                        type: NetworkingActionTypes.COMMISSIONS_ERROR_MESSAGE_CLEARED,
                    });
                }}
                autoHideDuration={3000}
            />
            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => {
                    setDrawerOpen(false);
                    setDrawerCommissions([]);
                }}
                sx={{ width: '75%' }} // Set this to your preferred width
            >
                <Box sx={{ width: '100%', height: 400 }}>
                    <DataGridPremium
                        rows={drawerCommissions}
                        columns={drawerColumns}
                        sx={{ width: '100%', height: '100%' }}
                        getRowId={(row) => row.uuid}
                    />
                </Box>
            </Drawer>
        </Container>
    );
};
export default PartnershipEarningsScene;
