import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { DataGridPremium, GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { GetOrdersParams, getOrders, getOrderStatuses, getOrderAthletes } from '../networking.api';
import { Order } from '../networking.types';
import { GridColDef } from '@mui/x-data-grid-premium';
import OrderDialog from '../components/OrderDialog';
import { Organization, UserData } from 'utils';
import { getOrderOrganizations } from '../networking.api';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';

const ProductSalesListScene = () => {
    const { id } = useParams<{ id: string }>();
    const [buyerOrganizations, setBuyerOrganizations] = useState<Organization[]>([]);
    const [buyerAthletes, setBuyerAthletes] = useState<UserData[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [searchParams, setSearchParams] = useState<GetOrdersParams>({
        order_status: 'processing',
        buyer_org_uuid: '',
        athlete_uuid: '',
        level: 0,
        sort: '-created_at',
        page: 1,
        per_page: 10,
    });

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [orderStatuses, setOrderStatuses] = useState<string[]>([]);
    const [totalOrders, setTotalOrders] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [selectedBuyerOrg, setSelectedBuyerOrg] = useState<Organization | null>(null);
    const [selectedBuyerAthlete, setSelectedBuyerAthlete] = useState<UserData | null>(null);

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        setSearchParams({
            ...searchParams,
            page: newModel.page + 1, // API usually expects 1-indexed pages
            per_page: newModel.pageSize,
        });
        getOrders(id, true, {
            ...searchParams,
            page: newModel.page + 1,
            per_page: newModel.pageSize,
        })
            .then((response) => {
                setOrders(response.data.data);
                setTotalOrders(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch orders', error);
            });
    };

    const handleOpenDialog = (order: Order) => {
        setSelectedOrder(order);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const fetchBuyerOrganizations = debounce((id: string, name: string | null, level: number) => {
        // Replace this with your actual API call
        getOrderOrganizations(id, true, name, level)
            .then((response) => {
                setBuyerOrganizations(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch buyer organizations', error);
            });
    }, 300);

    const fetchBuyerAthletes = debounce((id: string, name: string | null) => {
        // Replace this with your actual API call
        getOrderAthletes(id, true, name)
            .then((response) => {
                setBuyerAthletes(response.data.data);
            })
            .catch((error) => {
                console.error('Failed to fetch buyer organizations', error);
            });
    }, 300);

    useEffect(() => {
        fetchBuyerOrganizations(id, null, 0);
        fetchBuyerAthletes(id, null);

        const fetchOrderStatuses = async () => {
            const response = await getOrderStatuses(id);
            setOrderStatuses(response.data);
        };
        fetchOrderStatuses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getOrders(id, true, {
            order_status: 'processing',
            sort: '-created_at',
        })
            .then((response) => {
                setOrders(response.data.data);
                setTotalOrders(response.data.meta.total);
            })
            .catch((error) => {
                console.error('Failed to fetch orders', error);
            });
    }, [id]);

    const formatCentsToDollars = (cents: number): string => {
        const dollars = cents / 100;
        return `$${dollars.toFixed(2)}`;
    };

    interface LineItem {
        description: string;
        quantity: number;
    }

    function formatLineItemsDescription(lineItems: LineItem[]): string {
        const maxDescriptionLength = 50; // Adjust as needed
        let description = lineItems
            .map((item) => `${item.description} [${item.quantity}]`)
            .join(', ');

        // Truncate and append ellipsis if overflows
        if (description.length > maxDescriptionLength) {
            description = `${description.substring(0, maxDescriptionLength - 3)}...`;
        }

        return description;
    }

    const columns: GridColDef<Order, any, any>[] = [
        {
            field: 'sellerName',
            headerName: 'Seller',
            width: 200,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                return params.row.seller_org_name;
            },
        },
        {
            field: 'buyerName',
            headerName: 'Buyer',
            width: 200,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                return (
                    params.row.buyer_org_name ??
                    `${params.row.buyer_athlete?.first_name} ${params.row.buyer_athlete?.last_name}`
                );
            },
        },
        {
            field: 'salesDescription',
            headerName: 'Order',
            width: 300,
            sortable: false,
            type: 'string',
            renderCell: (params) => {
                // Assuming each order has a lineItems property
                const description = formatLineItemsDescription(params.row.line_items);
                return <span>{description}</span>;
            },
        },
        {
            field: 'commissionable_total',
            headerName: 'Product Total',
            width: 100,
            sortable: false,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'tax',
            headerName: 'Tax',
            width: 100,
            sortable: true,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'shipping',
            headerName: 'Shipping',
            width: 100,
            sortable: false,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 100,
            sortable: true,
            type: 'number',
            valueFormatter: (value) => formatCentsToDollars(value),
        },
        {
            field: 'shipping_tracking_number',
            headerName: 'Tracking Number',
            width: 150,
            sortable: false,
            type: 'string',
        },
        {
            field: 'order_status',
            headerName: 'Order Status',
            width: 150,
            sortable: true,
            type: 'string',
        },
        {
            field: 'created_at',
            headerName: 'Received',
            sortable: true,
            width: 100,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            renderCell: (params) => (
                <Button onClick={() => handleOpenDialog(params.row)} variant="contained">
                    View
                </Button>
            ),
        },
    ];

    const handleOrderSearch = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await getOrderSearchResults(searchParams);
        } catch (error) {
            console.error(error);
        }
    };

    const getOrderSearchResults = async (params: any) => {
        const response = await getOrders(id, true, params);
        console.debug('Orders response', response.data.data);
        if (Array.isArray(response.data.data)) {
            const updatedOrders = response.data.data.map((order: Order) => {
                return {
                    ...order,
                };
            });
            setOrders(updatedOrders);
            setTotalOrders(response.data.meta.total);
        } else {
            console.error('Unexpected data format: pods data is not an array');
        }
    };

    const handleOrderSort = async (model: GridSortModel) => {
        let sortField = model[0]?.field;
        const sortOrder = model[0]?.sort;

        /**
         * Sort will be undefined, or it will be ascending, ie "createdAt", or in this case
         * it will be descending, ie "-createdAt". If descending, we prepend a hyphen.
         */
        if (sortField && sortOrder === 'desc') {
            sortField = `-${sortField}`;
        }

        // Update your API request with the new sort model and fetch the data again
        const params = {
            ...searchParams,
            sort: sortField as GetOrdersParams['sort'],
        };

        await getOrderSearchResults(params);
    };

    // @ts-ignore
    return (
        <Box style={{ marginLeft: '2rem' }}>
            <h2>Product Sales</h2>
            <section style={{ marginBottom: '2rem' }}>
                <h4>Search Sales by Downline Level or Organization.</h4>
                <h5>
                    You can view sales you made directly to any buyer (which you can filter by buyer
                    organization or athlete), or you can view sales made by you and your downline
                    sellers filtered by the level of the buyer.
                </h5>
                <form onSubmit={handleOrderSearch} noValidate>
                    <FormControl
                        size="small"
                        sx={{ width: '240px', display: 'inline-flex', marginRight: '2px' }}
                    >
                        <InputLabel id="order-status-label">Buyer Downline Level</InputLabel>
                        <Select
                            label="Buyer Downline Level"
                            value={searchParams.level}
                            onChange={(e) => {
                                const newLevel = e.target.value as number;
                                setSearchParams((prev) => ({
                                    ...prev,
                                    level: newLevel,
                                    buyer_org_uuid: newLevel !== 0 ? '' : prev.buyer_org_uuid,
                                }));
                                if (newLevel !== 0) {
                                    setSelectedBuyerOrg(null);
                                    fetchBuyerOrganizations(id, null, newLevel);
                                }
                            }}
                            size="small"
                            sx={{ width: '240px', display: 'inline-flex', marginRight: '2px' }}
                        >
                            <MenuItem value={0}>My sales to any buyer</MenuItem>
                            <MenuItem value={1}>Sales to level 1 buyers</MenuItem>
                            <MenuItem value={2}>Sales to level 2 buyers</MenuItem>
                            <MenuItem value={3}>Sales to level 3 buyers</MenuItem>
                        </Select>
                    </FormControl>
                    <Autocomplete
                        value={selectedBuyerOrg}
                        onChange={(event, newValue) => {
                            setSelectedBuyerOrg(newValue);
                            setSearchParams((prev) => ({
                                ...prev,
                                buyer_org_uuid: newValue ? newValue.uuid : '',
                            }));
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason !== 'reset') {
                                fetchBuyerOrganizations(id, newInputValue, searchParams.level ?? 0);
                            }
                        }}
                        getOptionKey={(option) => option.uuid}
                        filterOptions={(options) => (Array.isArray(options) ? options : [])}
                        size={'small'}
                        sx={{
                            width: '240px',
                            display: 'inline-flex',
                            marginRight: '2px',
                            '&.Mui-disabled': {
                                cursor: 'not-allowed',
                                pointerEvents: 'all',
                            },
                            '& .MuiInputBase-root.Mui-disabled': {
                                cursor: 'not-allowed',
                            },
                        }}
                        options={Array.isArray(buyerOrganizations) ? buyerOrganizations : []}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} label="Buyer Organization" />
                        )}
                        disabled={!!selectedBuyerAthlete}
                    />
                    <Autocomplete
                        value={selectedBuyerAthlete}
                        onChange={(event, newValue) => {
                            setSelectedBuyerAthlete(newValue);
                            setSearchParams((prev) => ({
                                ...prev,
                                athlete_uuid: newValue ? newValue.uuid : '',
                            }));
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            if (reason !== 'reset') {
                                fetchBuyerAthletes(id, newInputValue);
                            }
                        }}
                        getOptionKey={(option) => option.uuid}
                        filterOptions={(options) => (Array.isArray(options) ? options : [])}
                        size={'small'}
                        sx={{
                            width: '240px',
                            display: 'inline-flex',
                            marginRight: '2px',
                            '&.Mui-disabled': {
                                cursor: 'not-allowed',
                                pointerEvents: 'all',
                            },
                            '& .MuiInputBase-root.Mui-disabled': {
                                cursor: 'not-allowed',
                            },
                        }}
                        options={Array.isArray(buyerAthletes) ? buyerAthletes : []}
                        getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                        renderInput={(params) => <TextField {...params} label="Buyer Athlete" />}
                        disabled={!!selectedBuyerOrg}
                    />
                    <FormControl
                        size="small"
                        sx={{ width: '240px', display: 'inline-flex', marginRight: '2px' }}
                    >
                        <InputLabel id="order-status-label">Order Status</InputLabel>
                        <Select
                            label="Order Status"
                            value={
                                searchParams.order_status === '' ? 'all' : searchParams.order_status
                            }
                            onChange={(e) => {
                                setSearchParams({
                                    ...searchParams,
                                    order_status: e.target.value === 'all' ? '' : e.target.value,
                                });
                            }}
                            size="small"
                            sx={{ width: '240px', display: 'inline-flex', marginRight: '2px' }}
                        >
                            <MenuItem value="all">all</MenuItem>
                            {orderStatuses.map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button type="submit" size={'medium'} variant="contained">
                        Search Sales
                    </Button>
                </form>
            </section>
            <Box width="98%" maxHeight="600px" overflow="auto">
                <DataGridPremium
                    rows={orders}
                    columns={columns}
                    checkboxSelection={false}
                    sortingMode="server"
                    onSortModelChange={(model: GridSortModel) => handleOrderSort(model)}
                    getRowId={(row) => row.uuid}
                    style={{ height: '624px', marginBottom: '50px' }}
                    pagination
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    rowCount={totalOrders}
                    paginationMode="server"
                    disableColumnMenu={true}
                />
            </Box>
            <OrderDialog open={isDialogOpen} order={selectedOrder} onClose={handleCloseDialog} />
        </Box>
    );
};

export default ProductSalesListScene;
