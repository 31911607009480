import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { AbilityContext } from 'components/Functional/Can';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import { orderBy } from 'lodash';
import { useContext } from 'react';
import { getInitials } from 'utils';

const AssociatedOrgList = () => {
    const orgProvider = useContext(OrganizationsContext);
    const ability = useContext(AbilityContext);

    return (
        <>
            {orgProvider.organizations.filter(
                (o) => !ability.can('organization:update', o) && !ability.can('movement:update', o),
            ).length === 0 ? (
                <Typography textAlign={'center'}>
                    You have no associated organizations that you train, learn, or share content
                    with.{' '}
                </Typography>
            ) : (
                <List>
                    {orderBy(
                        orgProvider.organizations.filter(
                            (o) =>
                                !ability.can('organization:update', o) &&
                                !ability.can('movement:update', o),
                        ),
                        ['name'],
                        ['asc'],
                    )?.map((organization) => (
                        <ListItem button key={organization.name}>
                            <ListItemAvatar>
                                <Avatar
                                    variant="rounded"
                                    src={organization.logo_url || ''}
                                    alt={organization.name}
                                    style={{
                                        width: 40,
                                        height: 40,
                                    }}
                                >
                                    {getInitials(organization.name)}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>{organization.name}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            )}
        </>
    );
};

export default AssociatedOrgList;
