import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createProgram, getAllPrograms, updateProgramDetails, updateSessionsForProgram } from 'api';
import { AxiosError } from 'axios';
import { Program } from 'utils';
import { RootState } from '.';

export interface ProgramError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export interface ProgramState {
    isLoading: boolean;
    isLoaded: boolean;
    data: Array<Program>;
    error: ProgramError;
}

export const initialState: ProgramState = {
    isLoading: false,
    isLoaded: false,
    data: [],
    error: { message: '' },
};

export const loadPrograms = createAsyncThunk<
    { data: Program[] },
    { 'filter[name]': string },
    { rejectValue: ProgramError }
>('programs/fetchAll', async (query, { rejectWithValue }) => {
    try {
        const { data } = await getAllPrograms(query);
        return { data };
    } catch (err: any) {
        const error: AxiosError<ProgramError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Loading Programs' });
    }
});

export const createNewProgram = createAsyncThunk<
    Program,
    {
        name: string;
        organization: string;
    },
    {
        rejectValue: ProgramError;
    }
>('programs/create', async ({ name, organization }, { rejectWithValue }) => {
    try {
        const { data } = await createProgram(name, organization);
        return data;
    } catch (err: any) {
        const error: AxiosError<ProgramError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Creating Program' });
    }
});

export const updateProgramSessions = createAsyncThunk<
    Program,
    {
        uuid: string;
        sessions: string[];
    },
    {
        rejectValue: ProgramError;
    }
>('programs/updateSessions', async ({ uuid, sessions }, { rejectWithValue }) => {
    try {
        const { data } = await updateSessionsForProgram(uuid, sessions);
        return data;
    } catch (err: any) {
        const error: AxiosError<ProgramError> = err;
        return rejectWithValue(
            error.response?.data ?? { message: 'Error Updating Program Sessions' },
        );
    }
});

export const updateProgram = createAsyncThunk<
    Program,
    {
        uuid: string;
        name: string;
    },
    {
        rejectValue: ProgramError;
    }
>('programs/updateProgram', async ({ uuid, name }, { rejectWithValue }) => {
    try {
        const { data } = await updateProgramDetails(uuid, name);
        return data;
    } catch (err: any) {
        const error: AxiosError<ProgramError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Updating Program' });
    }
});

export const programSlice = createSlice({
    name: 'programs',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadPrograms.fulfilled, (state, { payload }) => {
            state.data = payload.data;
            state.error = { message: '' };
            state.isLoading = false;
            state.isLoaded = true;
        });
        addCase(loadPrograms.pending, (state) => {
            state.error = { message: '' };
            state.isLoading = true;
            state.isLoaded = false;
        });
        addCase(loadPrograms.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Loading Programs';
            state.isLoading = false;
        });
        addCase(createNewProgram.fulfilled, (state) => {
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(createNewProgram.pending, (state) => {
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(createNewProgram.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Creating Program';
            state.isLoading = false;
        });
        addCase(updateProgramSessions.fulfilled, (state) => {
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(updateProgramSessions.pending, (state) => {
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(updateProgramSessions.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Updating Program Sessions';
            state.isLoading = false;
        });
        addCase(updateProgram.fulfilled, (state) => {
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(updateProgram.pending, (state) => {
            state.error = { message: '' };
            state.isLoading = true;
        });
        addCase(updateProgram.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Updating Program';
            state.isLoading = false;
        });
    },
});

export const programsSelector = (state: RootState) => state.programs;
