import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    useTheme,
    IconButton,
    Tooltip,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormControl,
    InputLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import {
    adminGetAllOrganizationsAdvanced,
    GetOrganizationsAndSubscriptionDataParams,
} from '../organizations.api';

const MapContainer = styled(Box)(({ theme }) => ({
    height: '500px',
    width: '100%',
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
}));

const SearchForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));

const SearchField = styled(Box)(({ theme }) => ({
    flex: 1,
    minWidth: '200px',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5),
    },
}));

interface MapLocation {
    id: string;
    lat: number;
    lng: number;
    name: string;
    address: string;
    city: string;
    state: string;
    postal_code: string;
}

const CopyButton = ({ text }: { text: string }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = async (e: React.MouseEvent) => {
        // Prevent the DataGrid row click event from firing
        e.stopPropagation();

        try {
            // Try modern Clipboard API first
            if (navigator.clipboard && window.isSecureContext) {
                await navigator.clipboard.writeText(text);
            } else {
                // Fallback for older browsers or non-HTTPS
                const textarea = document.createElement('textarea');
                textarea.value = text;
                textarea.style.position = 'fixed';
                textarea.style.opacity = '0';
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
            }

            // Visual feedback
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    return (
        <Tooltip title={copied ? 'Copied!' : 'Copy address'}>
            <IconButton
                onClick={handleCopy}
                size="small"
                sx={{
                    color: copied ? 'success.main' : 'action.active',
                }}
            >
                {copied ? <CheckIcon fontSize="small" /> : <ContentCopyIcon fontSize="small" />}
            </IconButton>
        </Tooltip>
    );
};

const getFullAddress = (location: MapLocation) => {
    return `${location.address}, ${location.city}, ${location.state} ${location.postal_code}`;
};

const columns: GridColDef[] = [
    { field: 'name', headerName: 'Organization Name', flex: 1 },
    {
        field: 'subscription_tier_string',
        headerName: 'Tier',
        width: 120,
        renderCell: (params: GridRenderCellParams) => {
            return params.row.subscription_tier_string !== 'no_tier'
                ? params.row.subscription_tier_string
                : 'No Tier';
        },
    },
    { field: 'address', headerName: 'Address', flex: 1 },
    { field: 'city', headerName: 'City', flex: 1 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'postal_code', headerName: 'ZIP Code', width: 120 },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        renderCell: (params: GridRenderCellParams<MapLocation>) => (
            <CopyButton text={getFullAddress(params.row)} />
        ),
    },
];

const OrganizationMap = () => {
    const theme = useTheme();
    const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
    const [locations, setLocations] = useState<MapLocation[]>([]);
    const [searchParams, setSearchParams] = useState({
        name: '',
        tier: 'all',
        state: '',
        city: '',
        postal_code: '',
        distance: '50',
    });
    const [center, setCenter] = useState({ lat: 39.8283, lng: -98.5795 }); // Center of USA
    const [zoom, setZoom] = useState<number>(4);
    const [selectedLocation, setSelectedLocation] = useState<MapLocation | null>(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await adminGetAllOrganizationsAdvanced({
                name: searchParams.name,
                tier: searchParams.tier,
                state: searchParams.state,
                city: searchParams.city,
                postalCode: searchParams.postal_code,
                distance: searchParams.distance,
            });

            const newLocations = response.data.data
                .filter((org) => org.address?.latitude && org.address?.longitude)
                .map((org) => ({
                    id: org.uuid,
                    lat: Number(org.address!.latitude),
                    lng: Number(org.address!.longitude),
                    name: org.name,
                    address: `${org.address!.address_line1} ${org.address!.address_line2 ?? ''}`,
                    city: org.address!.city,
                    state: org.address!.state,
                    postal_code: org.address!.postal_code,
                    subscription_tier_string: org.subscription_tier_string || 'no_tier',
                }));

            setLocations(newLocations);

            if (newLocations.length > 0) {
                setCenter({ lat: newLocations[0].lat, lng: newLocations[0].lng });
                setZoom(10);
            }
        } catch (error) {
            console.error('Failed to fetch organizations:', error);
        }
    };

    const handleOrgMapTierChange = (event: SelectChangeEvent<unknown>) => {
        setSearchParams({
            ...searchParams,
            tier: (event.target.value as GetOrganizationsAndSubscriptionDataParams['tier']) || '',
        });
    };

    return (
        <Box sx={{ margin: theme.spacing(4, 16, 16, 4) }}>
            <h2>Organization Map</h2>

            <SearchForm onSubmit={handleSearch}>
                <SearchField>
                    <TextField
                        fullWidth
                        label="Organization Name"
                        size="small"
                        value={searchParams.name}
                        onChange={(e) => setSearchParams({ ...searchParams, name: e.target.value })}
                    />
                </SearchField>
                <FormControl margin="normal" variant="outlined" size="small" sx={{ marginTop: 0 }}>
                    <InputLabel id="tier-label">Tier</InputLabel>
                    <Select
                        label="Tier"
                        id="tier-select"
                        variant="outlined"
                        size={'small'}
                        value={searchParams.tier}
                        onChange={handleOrgMapTierChange}
                        sx={{ width: '150px' }}
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="gold">Gold</MenuItem>
                        <MenuItem value="silver">Silver</MenuItem>
                        <MenuItem value="bronze">Bronze</MenuItem>
                        <MenuItem value="no_tier">No Tier</MenuItem>
                    </Select>
                </FormControl>
                <SearchField>
                    <TextField
                        fullWidth
                        label="State"
                        size="small"
                        value={searchParams.state}
                        onChange={(e) =>
                            setSearchParams({ ...searchParams, state: e.target.value })
                        }
                    />
                </SearchField>

                <SearchField>
                    <TextField
                        fullWidth
                        label="City"
                        size="small"
                        value={searchParams.city}
                        onChange={(e) => setSearchParams({ ...searchParams, city: e.target.value })}
                    />
                </SearchField>

                <SearchField>
                    <TextField
                        fullWidth
                        label="ZIP Code"
                        size="small"
                        value={searchParams.postal_code}
                        onChange={(e) =>
                            setSearchParams({ ...searchParams, postal_code: e.target.value })
                        }
                    />
                </SearchField>

                <SearchField>
                    <TextField
                        fullWidth
                        label="Distance (miles)"
                        type="number"
                        size="small"
                        value={searchParams.distance}
                        onChange={(e) =>
                            setSearchParams({ ...searchParams, distance: e.target.value })
                        }
                    />
                </SearchField>

                <Button
                    variant="contained"
                    type="submit"
                    fullWidth={isNarrow}
                    sx={{ minWidth: '200px', height: '40px', marginTop: theme.spacing(1) }}
                >
                    Search
                </Button>
            </SearchForm>

            {locations.length > 0 && (
                <Box sx={{ height: 400, width: '100%', mb: 3 }}>
                    <DataGrid
                        rows={locations}
                        columns={columns}
                        pageSizeOptions={[5, 10, 25, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        disableRowSelectionOnClick
                        onRowClick={(params) => {
                            const location = locations.find((loc) => loc.id === params.row.id);
                            if (location) {
                                setSelectedLocation(location);
                                setCenter({ lat: location.lat, lng: location.lng });
                                setZoom(15);
                            }
                        }}
                    />
                </Box>
            )}

            <MapContainer>
                <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
                    onError={(error) => {
                        console.error('LoadScript Error:', error);
                    }}
                    onLoad={() => {
                        console.log('Maps API loaded successfully');
                    }}
                >
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={center}
                        zoom={zoom}
                        onLoad={() => {
                            console.log('Map component loaded successfully');
                        }}
                    >
                        {locations.map((location) => (
                            <Marker
                                key={location.id}
                                position={{ lat: location.lat, lng: location.lng }}
                                onClick={() => setSelectedLocation(location)}
                            />
                        ))}

                        {selectedLocation && (
                            <InfoWindow
                                position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
                                onCloseClick={() => setSelectedLocation(null)}
                            >
                                <div className="p-2">
                                    <h3 className="text-lg font-semibold mb-2">
                                        {selectedLocation.name}
                                    </h3>
                                    <div className="flex items-center gap-2">
                                        <p className="m-0">{getFullAddress(selectedLocation)}</p>
                                        <CopyButton text={getFullAddress(selectedLocation)} />
                                    </div>
                                </div>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                </LoadScript>
            </MapContainer>
        </Box>
    );
};

export default OrganizationMap;
