// FilterPanel.tsx
import React from 'react';
import {
    Box,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    InputAdornment,
    Typography,
} from '@mui/material';
import { ranks, subscriptionLevels, athleteRanges } from '../networking.constants';

interface FilterPanelProps {
    nameFilter: string;
    setNameFilter: (value: string) => void;
    rankFilter: string[];
    setRankFilter: (value: string[]) => void;
    salesFilter: number | '';
    setSalesFilter: (value: number | '') => void;
    subscriptionFilter: string[];
    setSubscriptionFilter: (value: string[]) => void;
    athleteFilter: string[];
    setAthleteFilter: (value: string[]) => void;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
    nameFilter,
    setNameFilter,
    rankFilter,
    setRankFilter,
    salesFilter,
    setSalesFilter,
    subscriptionFilter,
    setSubscriptionFilter,
    athleteFilter,
    setAthleteFilter,
}) => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box>
                    <Typography variant="caption">Partner Name</Typography>
                    <TextField
                        size="small"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        fullWidth
                        placeholder="Enter partner name"
                    />
                </Box>
                <Box>
                    <Typography variant="caption">Rank</Typography>
                    <Select
                        size="small"
                        multiple
                        value={rankFilter}
                        onChange={(e) =>
                            setRankFilter(
                                typeof e.target.value === 'string'
                                    ? e.target.value.split(',')
                                    : e.target.value,
                            )
                        }
                        renderValue={(selected) => selected.join(', ')}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem disabled value="">
                            <em>Select Ranks</em>
                        </MenuItem>
                        {ranks.map((rank) => (
                            <MenuItem key={rank} value={rank}>
                                <Checkbox checked={rankFilter.indexOf(rank) > -1} />
                                <ListItemText primary={rank} />
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box>
                    <Typography variant="caption">Minimum Marketplace Sales</Typography>
                    <TextField
                        size="small"
                        type="number"
                        value={salesFilter}
                        onChange={(e) =>
                            setSalesFilter(e.target.value === '' ? '' : Number(e.target.value))
                        }
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        fullWidth
                        placeholder="Enter minimum sales"
                    />
                </Box>
                <Box>
                    <Typography variant="caption">Org Subscription</Typography>
                    <Select
                        size="small"
                        multiple
                        value={subscriptionFilter}
                        onChange={(e) =>
                            setSubscriptionFilter(
                                typeof e.target.value === 'string'
                                    ? e.target.value.split(',')
                                    : e.target.value,
                            )
                        }
                        renderValue={(selected) => selected.join(', ')}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem disabled value="">
                            <em>Select Subscription Levels</em>
                        </MenuItem>
                        {subscriptionLevels.map((level) => (
                            <MenuItem key={level} value={level}>
                                <Checkbox checked={subscriptionFilter.indexOf(level) > -1} />
                                <ListItemText primary={level} />
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box>
                    <Typography variant="caption">Number of Athletes</Typography>
                    <Select
                        size="small"
                        multiple
                        value={athleteFilter}
                        onChange={(e) =>
                            setAthleteFilter(
                                typeof e.target.value === 'string'
                                    ? e.target.value.split(',')
                                    : e.target.value,
                            )
                        }
                        renderValue={(selected) => selected.join(', ')}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem disabled value="">
                            <em>Select Athlete Ranges</em>
                        </MenuItem>
                        {athleteRanges.map((range) => (
                            <MenuItem key={range.label} value={range.label}>
                                <Checkbox checked={athleteFilter.indexOf(range.label) > -1} />
                                <ListItemText primary={range.label} />
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Box>
        </>
    );
};

export default FilterPanel;
