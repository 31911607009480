// sharedConstants.ts
export const ranks = ['Not Ranked', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond'];
export type RankNumber = 0 | 1 | 2 | 3 | 4 | 5;
export const rankMapping: { [key in RankNumber]: string } = {
    0: 'Not Ranked',
    1: 'Bronze',
    2: 'Silver',
    3: 'Gold',
    4: 'Platinum',
    5: 'Diamond',
};
export const subscriptionLevels = ['-', 'Bronze', 'Silver', 'Gold'];
export const athleteRanges = [
    { label: '0', min: 0, max: 0 },
    { label: '1-10', min: 1, max: 10 },
    { label: '11-50', min: 11, max: 50 },
    { label: '51-100', min: 51, max: 100 },
    { label: '101-250', min: 101, max: 250 },
    { label: '251-500', min: 251, max: 500 },
    { label: '501-1000', min: 501, max: 1000 },
    { label: '1000+', min: 1001, max: Infinity },
];
