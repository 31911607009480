import React from 'react';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import LandingPageNavBar from './LandingPageNavBar';
import Lottie from 'lottie-react';
import GetStarted from '../../lottie-animations/GetStarted.json';
import FitnessTeacher from '../../lottie-animations/fitness-teacher.svg';
import EmpoweringAnalytics from '../../lottie-animations/EmpoweringAnalytics.svg';
import USBased from '../../lottie-animations/USBased2.svg';
import ROUTES from '../../Routes/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { LocationState } from '../../utils';

function LandingPageAbout() {
    const gradientTextStyles = {
        mt: 4,
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: 8,
        maxWidth: 900,
        background: 'linear-gradient(to bottom, #fffffF, #A7ABB7)', // Example gradient
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent', // This makes the text transparent, showing only the background
        backgroundClip: 'text',
        color: 'transparent', // Fallback for browsers that do not support background-clip
        marginTop: 15,
    };
    const { push } = useHistory();
    const location = useLocation<LocationState>();

    return (
        <>
            <LandingPageNavBar />
            <Box
                sx={{
                    flex: 1,
                    backgroundImage: 'linear-gradient(to bottom, #0066ff, #070A19)',
                }}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start" // This will distribute space evenly
                alignItems="center"
                height="100%"
            >
                <Typography sx={gradientTextStyles} variant="h3" component="div">
                    About Athlete Management Technologies and 1TUL
                </Typography>
                <Typography sx={gradientTextStyles} variant="h5" component="div">
                    Athlete Management Technologies crafts innovative solutions for the modern
                    athletic landscape. At the heart of our mission lies 1TUL, a pioneering software
                    developed to revolutionize how speed trainers, colleges, and professional teams
                    manage and analyze athletic performance data to prove speed improvement.
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                        src={USBased}
                        alt="Your SVG Description"
                        style={{ width: 200, height: 200 }}
                    />
                </div>
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    Based in San Diego, California
                </Typography>
                <Typography sx={gradientTextStyles} variant="h5" component="div">
                    Our unique edge lies in our team: every software developer at Athlete Management
                    Technologies proudly lives in America and boasts a high-level sports background,
                    merging technical prowess with an insider&apos;s understanding of athletic
                    needs. In addition to being athletes and senior software developers our
                    educational and professional backgrounds include Chemical Engineering,
                    Mathmatics, Law, Healthcare Management, Performance Training, and Business.
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                        src={EmpoweringAnalytics}
                        alt="Your SVG Description"
                        style={{ width: 200, height: 200 }}
                    />
                </div>
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    Empowering Performance with Data-Driven Insights
                </Typography>
                <Typography sx={gradientTextStyles} variant="h5" component="div">
                    1TUL isn&apos;t just software; it’s a collaboration platform where speed,
                    training, and learning data management converge for organizations that aim to
                    scale. By integrating with cutting-edge GPS technology, 1080 Motion, and smart
                    devices, we ensure precise data collection.
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img
                        src={FitnessTeacher}
                        alt="Your SVG Description"
                        style={{ width: 200, height: 200 }}
                    />
                </div>
                <Typography sx={gradientTextStyles} variant="h4" component="div">
                    Fostering a Community of Learning and Growth
                </Typography>

                <Typography sx={gradientTextStyles} variant="h5" component="div">
                    1TUL transcends beyond data management. It&apos;s about building a community
                    where knowledge is shared and learning is continual. Our Learning Management
                    System allows you to create, distribute, and track educational content, ensuring
                    that athletes and coaches stay at the forefront of training methodologies.
                </Typography>
                <Typography sx={gradientTextStyles} variant="h3" component="div">
                    Get Started
                </Typography>
                <Typography sx={gradientTextStyles} variant="h5" component="div">
                    Learn the platform in less than an hour, become a power user in less than a day.
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie
                        animationData={GetStarted} // Correct property name
                        loop={true}
                        autoplay={true}
                        style={{
                            width: '200px', // Adjust the width and height as needed
                            height: '100px',
                            cursor: 'pointer',
                        }}
                        onClick={() => push(ROUTES.Register.path, location.state)}
                    />
                </div>
                <Button
                    sx={gradientTextStyles}
                    variant="contained"
                    size={'large'}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                    Back to the top
                </Button>
            </Box>
        </>
    );
}

export default LandingPageAbout;
