// admin.routes.ts

import NavigationLayout from '../../Layouts/NavigationLayout';
import { Navigation } from '../../utils';
import AdminBasePage from './AdminBasePage';
import OrdersList from './modules/orders/scenes/OrdersList';
import PodList from './modules/pods/scenes/PodList';
import PhysicalProductList from './modules/physicalProducts/scenes/PhysicalProductList';
import SubscriptionList from './modules/subscriptions/scenes/SubscriptionList';
import CommissionList from './modules/commissions/scenes/CommissionList';

export const adminRoutes = [
    {
        exact: false,
        layout: NavigationLayout,
        path: '/admin',
        component: AdminBasePage,
        private: true,
        primaryNavigation: Navigation.ADMIN,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/admin/orders',
        component: OrdersList,
        private: true,
        primaryNavigation: Navigation.ADMIN,
        secondaryNavigation: Navigation.ADMIN_ORDERS,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/admin/pods',
        component: PodList,
        private: true,
        primaryNavigation: Navigation.ADMIN,
        secondaryNavigation: Navigation.ADMIN_PODS,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/admin/products',
        component: PhysicalProductList,
        private: true,
        primaryNavigation: Navigation.ADMIN,
        secondaryNavigation: Navigation.ADMIN_PRODUCTS,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/admin/subscriptions',
        component: SubscriptionList,
        private: true,
        primaryNavigation: Navigation.ADMIN,
        secondaryNavigation: Navigation.ADMIN_SUBSCRIPTIONS,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/admin/commissions',
        component: CommissionList,
        private: true,
        primaryNavigation: Navigation.ADMIN,
        secondaryNavigation: Navigation.ADMIN_COMMISSIONS,
    },
];
