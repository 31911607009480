import { Avatar, Box, Button, Snackbar, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getSession } from '../../../api';
import { Group, Session } from '../../../utils';
import { getMembers, viewGroup } from '../../community/api/groups.api';
import { createWorkout } from '../api/groups';

function StartGroupLog(): React.ReactElement {
    const { groupId } = useParams<{ groupId: string }>();
    const { sessionId } = useParams<{ sessionId: string }>();
    const [group, setGroup] = useState<Group>();
    const [session, setSession] = useState<Session>();
    const [submitting, setSubmitting] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const { push } = useHistory();
    const [rows, setRows] = useState<any[]>([]);
    const columns: GridColDef[] = [{ field: 'name', headerName: 'Name', width: 200 }];
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const handleSubmit = useCallback(() => {
        setSubmitting(true);
        createWorkout(groupId, sessionId, selectedRows)
            .then((response) => {
                push(
                    '/train/logging/group-workouts/:groupWorkoutId'.replace(
                        ':groupWorkoutId',
                        response.data.uuid,
                    ),
                );
            })
            .catch(() => setSnackMessage('Error while creating workout'))
            .finally(() => {
                setSubmitting(false);
            });
    }, [groupId, sessionId, selectedRows, push]);

    useEffect(() => {
        const loadGroupAndSession = async () => {
            try {
                const responseGroup = await viewGroup(groupId);
                setGroup(responseGroup.data);

                const responseMembers = await getMembers(groupId, { limit: 1000 });
                setRows(
                    responseMembers.data.data.map((member) => ({
                        id: member.uuid,
                        name: `${member.first_name} ${member.last_name}`,
                    })),
                );

                const responseSession = await getSession(sessionId);
                setSession(responseSession.data);
            } catch (e) {
                setSnackMessage('Error while loading group or session');
            }
        };

        loadGroupAndSession();
    }, [groupId, sessionId]);

    return (
        <>
            <Snackbar
                open={Boolean(snackMessage)}
                autoHideDuration={3000}
                onClose={() => setSnackMessage('')}
                message={snackMessage}
                action={
                    <Button color="secondary" size="small" onClick={() => setSnackMessage('')}>
                        Ok
                    </Button>
                }
            />
            <Box
                sx={{
                    paddingX: { xs: 2, lg: 12 },
                    paddingY: 2,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Avatar
                    src={group?.organization?.image_urls.avatar ?? ''}
                    variant={'square'}
                    sx={{ width: { xs: 40, md: 80 }, height: { xs: 40, md: 80 } }}
                />
                <Typography
                    sx={{ marginLeft: 8, fontSize: { xs: '1.5em', md: '2em' }, fontWeight: 'bold' }}
                >
                    Choose Athlete(s) To Group Log
                </Typography>
            </Box>
            <Box sx={{ paddingX: { lg: 12 }, paddingY: { lg: 3 }, width: '100%' }}>
                <Box
                    sx={{
                        display: { md: 'flex' },
                        alignItems: { md: 'center' },
                        justifyContent: { md: 'space-between' },
                        width: '100%',
                        boxShadow: { lg: 3 },
                        backgroundColor: 'white',
                        borderRadius: { lg: 3 },
                        paddingY: 12,
                        paddingX: 8,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            sx={{
                                width: { xs: 40, md: 80 },
                                height: { xs: 40, md: 80 },
                                marginRight: 6,
                            }}
                            src={group?.image_urls['avatar'] ?? ''}
                            variant={'rounded'}
                            alt={group?.name ?? ''}
                        />
                        <Box sx={{ flex: '1 1 0%' }}>
                            <Typography variant="h5">{group?.name}</Typography>
                            <Typography variant="body1">
                                Choose Athletes for {session?.title}
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        disabled={Boolean(submitting) || Boolean(selectedRows.length === 0)}
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                    >
                        Start Session
                        {selectedRows.length === 0
                            ? ' (Select Some Athletes)'
                            : ` (${selectedRows.length} Selected)`}
                    </Button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', paddingX: { lg: 12 } }}>
                <div style={{ flexGrow: 1, height: 600 }}>
                    <DataGridPremium
                        sx={{
                            backgroundColor: '#ffffff',
                            boxShadow: { lg: 3 },
                            borderRadius: { lg: 3 },
                            padding: { lg: 2 },
                            height: 600,
                        }}
                        autoHeight={false}
                        rows={rows}
                        columns={columns}
                        checkboxSelection
                        onRowSelectionModelChange={(newSelection) => {
                            setSelectedRows(newSelection);
                        }}
                        rowSelectionModel={selectedRows}
                    />
                </div>
            </Box>
        </>
    );
}

export default StartGroupLog;
