import NotificationsIcon from '@mui/icons-material/Notifications';
import SystemUpdateRoundedIcon from '@mui/icons-material/SystemUpdateRounded';
import { Box, Button, ListItemIcon, ListItemText } from '@mui/material';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { useHistory } from 'react-router';

import { BrowserRouter as Router } from 'react-router-dom';

interface Props {
    count: number;
}

export default function AlertIconWithBadge({ count }: Props) {
    const { push } = useHistory();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Router>
            <Box
                sx={{
                    '& > *': {
                        margin: 1,
                    },
                }}
            >
                <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    disableElevation={true}
                >
                    <Badge badgeContent={count} color="error">
                        <NotificationsIcon />
                    </Badge>
                </Button>
                <Menu
                    sx={{
                        '& .MuiMenu-paper': {
                            backgroundColor: 'background.paper',
                            border: '1px solid #d3d4d5',
                        },
                    }}
                    elevation={0}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {count > 0 && (
                        <MenuItem
                            sx={{
                                '&:focus': {
                                    backgroundColor: 'primary.main',
                                    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                                        color: 'common.white',
                                    },
                                },
                            }}
                        >
                            <Button onClick={() => push('/plan/timeline?modalOpen=1')}>
                                <ListItemIcon>
                                    <SystemUpdateRoundedIcon color="inherit" />
                                </ListItemIcon>
                                <ListItemText primary="Logs Ready to Download" />
                            </Button>
                        </MenuItem>
                    )}
                    {count === 0 && (
                        <ListItemText style={{ padding: 8 }}>No Unreviewed Logs</ListItemText>
                    )}
                </Menu>
            </Box>
        </Router>
    );
}
