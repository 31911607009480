import { Tab as MuiTab } from '@mui/material';
import { styled } from '@mui/system';

const Tab = styled(MuiTab)(({ theme }) => ({
    minWidth: '25%',
    color: '#4083ff',
    textTransform: 'none',
    '&:hover': {
        opacity: 1,
        color: '#40a9ff',
    },
    '&.Mui-selected': {
        color: theme.palette.background.paper,
        fontWeight: 'medium',
    },
    '&:focus': {
        color: theme.palette.background.paper,
    },
    [theme.breakpoints.down('sm')]: {
        color: 'rgba(0, 0, 0, 0.38)',
        '&.Mui-selected': {
            color: theme.palette.primary.main,
            fontWeight: 'medium',
        },
    },
}));

export default Tab;
