import {
    AuthScreenLayout,
    RestorePasswordForm,
    TypographySubtitle,
    TypographyTitle,
} from 'components';
import React from 'react';

const RestorePassword = () => {
    return (
        <AuthScreenLayout>
            <TypographyTitle
                sx={{
                    marginY: 5,
                }}
            >
                Forgot your password?
            </TypographyTitle>
            <TypographySubtitle
                sx={{
                    textAlign: 'center',
                    marginTop: 5,
                    marginBottom: 16,
                }}
            >
                You will receive a link to create a new password via email.
            </TypographySubtitle>
            <RestorePasswordForm />
        </AuthScreenLayout>
    );
};

export default RestorePassword;
