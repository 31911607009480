import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FullScreenDialogCard from '../components/HowToDialogCard';
import AthleteTrainAssignmentOptions from '../media/AthleteTrainAssignmentOptions.png';
import AthleteReceiveAssignment from '../media/AthleteTrainDashboard.png';
import AthleteLearnDashboardPhoto from '../media/AthleteLearnDashboardPhoto.png';
import AthleteLearnAssignmentOptions from '../media/AthleteLearnAssignmentOptions.png';

function AthleteReceiveAssignments() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: 10, marginTop: 15 }}
                    justifyContent={'center'}
                >
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Athletes receive assignments from their Train and Learn Dashboard.{' '}
                    </Typography>
                </Grid>

                {/* First row */}

                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Viewing Train Dashboard.
                    </Typography>
                </Grid>

                <FullScreenDialogCard
                    imgPosition={'top'}
                    lgSize={6}
                    cardHeight={450}
                    image={AthleteReceiveAssignment}
                    title="Train Assignment List"
                    headline="Train Assignment List"
                    description="View all your training assignments in one spot."
                />
                <FullScreenDialogCard
                    lgSize={6}
                    cardHeight={450}
                    image={AthleteTrainAssignmentOptions}
                    title="Train Assignment Options"
                    headline="Train Assignment Options"
                    description="Athlete's options for training assignments include: Skip, Preview, and Begin."
                />
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Viewing Learn Dashboard.
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    lgSize={6}
                    cardHeight={450}
                    image={AthleteLearnDashboardPhoto}
                    title="Athlete Learn Dashboard"
                    headline="Athlete Learn Dashboard"
                    description="View all your learning assignments in one spot."
                />

                <FullScreenDialogCard
                    lgSize={6}
                    cardHeight={450}
                    image={AthleteLearnAssignmentOptions}
                    title="Learn Assignment Options"
                    headline="Learn Assignment Options"
                    description="Athlete's options for learning assignments include: Preview and Begin.."
                />
            </Grid>
        </>
    );
}

export default AthleteReceiveAssignments;
