import { Exploration, ExplorationFilters } from '../explore.types';
import React, { useCallback, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { convertApiFiltersToExplorationFilters } from '../explore.func';
import cubejs, { CubejsApi, Series } from '@cubejs-client/core';
import { getCubeToken } from '../../../api/charts.api';
import { DateTime } from 'luxon';
import DateCarousel from './DateCarousel';
import DateCell from './DateCell';
import LoadingWithText from './LoadingWithText';

interface Props {
    exploration: Exploration;
}

const getBackgroundColor = (value: number) => {
    if (value < 0.8) {
        return '#FF9800';
    }
    if (value < 1.3) {
        return '#4CAF50';
    }

    return '#F44336';
};

const ExplorationGpsSummaryAcwr = ({ exploration }: Props) => {
    const [data, setData] = useState<Series<any>[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [startDate, setStartDate] = useState<DateTime>(DateTime.now());
    const [endDate, setEndDate] = useState<DateTime>(DateTime.now());
    const [filters, setFilters] = useState<ExplorationFilters>(
        convertApiFiltersToExplorationFilters(exploration.filters),
    );
    const [api, setApi] = useState<CubejsApi>();

    const getTableData = useCallback(() => {
        if (!selectedDate || !tableData || tableData?.length === 0) {
            return [];
        }
        return tableData.filter(
            (row) => row['GpsSummary.sessionDateTime.day'].slice(0, 10) === selectedDate,
        );
    }, [tableData, selectedDate]);

    useEffect(() => {
        setApi(
            cubejs(
                () =>
                    getCubeToken({
                        exploration: exploration.uuid,
                    }).then((r) => r.data.token),
                {
                    apiUrl: `${process.env.REACT_APP_CUBE_URL}/cubejs-api/v1`,
                },
            ),
        );
        setFilters(convertApiFiltersToExplorationFilters(exploration.filters));
    }, [exploration]);
    useEffect(() => {
        if (!api) {
            return;
        }
        setLoading(true);
        api.load({
            dimensions: filters.dimensions,
            measures: filters.measures,
        })
            .then((progressResponse) => {
                setTableData(progressResponse.tablePivot());
                setData(progressResponse.series());
                setLoaded(true);
                const categories = progressResponse.categories();
                // set start date and end date from categories[0] and categories[categories.length - 1]
                setStartDate(DateTime.fromISO(categories[0].x.slice(0, 10)));
                setEndDate(DateTime.fromISO(categories[categories.length - 1].x.slice(0, 10)));
                // if there is data returned, set the selected date to the last date
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, [filters, api]);

    return (
        <>
            <Avatar
                variant={'rounded'}
                sx={{ margin: 'auto', width: 100, height: 100 }}
                src={exploration.organization.logo_url ?? ''}
            />
            <Typography variant={'h4'} align={'center'}>
                {exploration.title}
            </Typography>
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LoadingWithText text={'Loading ACWR Calendar'} />
                </Box>
            )}
            <>
                {data && startDate && endDate && tableData.length > 0 && (
                    <DateCarousel
                        startDate={startDate}
                        endDate={endDate}
                        renderCell={(date) => {
                            let indicator;

                            // get the indicator color for the date by first getting the data for the date
                            const dataForDate = tableData.filter(
                                (d) =>
                                    d['GpsSummary.sessionDateTime.day'].slice(0, 10) ===
                                    date.toFormat('yyyy-MM-dd'),
                            );

                            // get the average GpsSummary.acuteToChronicRatio and average GpsSummary.acuteToChronicRatioDistance
                            // for the date
                            if (dataForDate.length > 0) {
                                const averageAcuteToChronicRatio =
                                    dataForDate.reduce(
                                        (acc, curr) =>
                                            acc + (curr['GpsSummary.acuteToChronicRatio'] ?? 0),
                                        0,
                                    ) / dataForDate.length;

                                indicator = getBackgroundColor(averageAcuteToChronicRatio);
                            }
                            return (
                                <DateCell
                                    isSelected={selectedDate === date.toFormat('yyyy-MM-dd')}
                                    date={date}
                                    indicator={indicator}
                                    onClick={() => setSelectedDate(date.toFormat('yyyy-MM-dd'))}
                                />
                            );
                        }}
                        onClick={(date) => {
                            setSelectedDate(date.toFormat('yyyy-MM-dd'));
                        }}
                    />
                )}
                {loaded && tableData && tableData.length === 0 && (
                    <Typography variant={'h6'} align={'center'}>
                        No data to display for the date range
                    </Typography>
                )}
                {tableData && tableData.length > 0 && (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Athlete Name</TableCell>
                                <TableCell>Load</TableCell>
                                <TableCell>Distance</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getTableData().length === 0 && selectedDate && (
                                <TableRow>
                                    <TableCell colSpan={3} align={'center'}>
                                        No data for the selected date
                                    </TableCell>
                                </TableRow>
                            )}
                            {!selectedDate && (
                                <TableRow>
                                    <TableCell colSpan={3} align={'center'}>
                                        Choose a date to view ACWR
                                    </TableCell>
                                </TableRow>
                            )}
                            {getTableData().map((r) => (
                                <TableRow key={r['GpsSummary.athleteName']}>
                                    <TableCell>{r['GpsSummary.athleteName'] ?? '-'}</TableCell>

                                    <TableCell
                                        sx={{
                                            backgroundColor: getBackgroundColor(
                                                r['GpsSummary.acuteToChronicRatio'],
                                            ),
                                        }}
                                    >
                                        <Tooltip
                                            title={
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <span>
                                                        Acute Load (Last 7 Days):{' '}
                                                        {Math.round(r['GpsSummary.acuteLoad'])}
                                                    </span>
                                                    <span>
                                                        Chronic Load (Previous 21 Days):{' '}
                                                        {Math.round(r['GpsSummary.chronicLoad'])}
                                                    </span>
                                                    <span>
                                                        ACWR (acute / chronic):{' '}
                                                        {(
                                                            r['GpsSummary.acuteLoad'] /
                                                            r['GpsSummary.chronicLoad']
                                                        ).toFixed(2)}
                                                    </span>
                                                </Box>
                                            }
                                        >
                                            <Button>
                                                {r['GpsSummary.acuteToChronicRatio']?.toFixed(2) ??
                                                    '-'}
                                            </Button>
                                        </Tooltip>
                                    </TableCell>

                                    <TableCell
                                        sx={{
                                            backgroundColor: getBackgroundColor(
                                                r['GpsSummary.acuteToChronicRatioDistance'],
                                            ),
                                        }}
                                    >
                                        <Tooltip
                                            title={
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <span>
                                                        Acute Load (Last 7 Days):{' '}
                                                        {Math.round(
                                                            r['GpsSummary.acuteLoadDistance'],
                                                        )}
                                                    </span>
                                                    <span>
                                                        Chronic Load (Previous 21 Days):{' '}
                                                        {Math.round(
                                                            r['GpsSummary.chronicLoadDistance'],
                                                        )}
                                                    </span>
                                                    <span>
                                                        ACWR (acute / chronic):{' '}
                                                        {(
                                                            r['GpsSummary.acuteLoadDistance'] /
                                                            r['GpsSummary.chronicLoadDistance']
                                                        ).toFixed(2)}
                                                    </span>
                                                </Box>
                                            }
                                        >
                                            <Button>
                                                {r[
                                                    'GpsSummary.acuteToChronicRatioDistance'
                                                ]?.toFixed(2) ?? '-'}
                                            </Button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </>
        </>
    );
};

export default ExplorationGpsSummaryAcwr;
