import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';

import { SessionMovement } from 'utils';
import React from 'react';
import SessionMovementMedia from '../Media/SessionMovementMedia';

export interface MovementSummaryCardProps {
    item: SessionMovement;
    index: number;
    movementNumber: number;
}

const MovementSummaryCard = ({ item, index }: MovementSummaryCardProps) => {
    const theme = useTheme();
    return (
        <Box
            boxShadow={3}
            sx={{
                borderRadius: '4px',
                marginBottom: theme.spacing(4),
                margin: '4px',
                width: '154px',
                padding: 4,
            }}
        >
            <Box display={'flex'} alignItems={'center'}>
                <Typography variant="caption" align="left">
                    [{index + 1}]
                </Typography>
                <Typography variant="subtitle2" align="center" sx={{ marginLeft: 4 }}>
                    {item.name}
                </Typography>
            </Box>
            <Box
                sx={{ minWidth: 90, maxWidth: 150, padding: 'theme.spacing(0, 0)' }}
                alignItems={'center'}
            >
                <SessionMovementMedia movement={item} />
            </Box>
        </Box>
    );
};

export default MovementSummaryCard;
