import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Athlete } from './athletes.types';

interface PaginatedApiResponse {
    data: Array<Athlete>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;
type ReturnType = Promise<AxiosResponse<void>>;

const adminGetAthletes = (params = {}): ReturnTypeCollection => {
    return Request.get<PaginatedApiResponse>('/admin/athletes', {
        params,
    });
};

const assignAthleteToPartner = (userUuid: string, partnerUuid: string): ReturnType => {
    return Request.post(`/admin/assign-athlete`, {
        user_uuid: userUuid,
        partner_uuid: partnerUuid,
    });
};

export { adminGetAthletes, assignAthleteToPartner };
