import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
    title: string;
    phrase: string;
    icon: React.ReactNode;
}

function EmptyState({ title, phrase, icon = <PermIdentityIcon /> }: Props): React.ReactElement {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingY={20}
        >
            {icon}
            <Typography variant="h6" style={{ marginTop: 6, fontWeight: 'bold' }}>
                {title}
            </Typography>
            <Typography variant="body1" style={{ marginTop: 3 }}>
                {phrase}
            </Typography>
        </Box>
    );
}

export default EmptyState;
