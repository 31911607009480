import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Grid,
    Typography,
    InputAdornment,
} from '@mui/material';
import { Commission } from '../../../../networking/networking.types';
import { updateCommission, UpdateCommissionParams } from '../commissions.api';
import { Divider } from '@mui/material';

interface CommissionDialogProps {
    open: boolean;
    commission: Commission | null;
    onClose: () => void;
    onSave: () => void;
}

const CommissionDialog: React.FC<CommissionDialogProps> = ({
    open,
    commission,
    onClose,
    onSave,
}) => {
    const [commissionNotes, setCommissionNotes] = useState<string>('');
    const [commissionAmount, setCommissionAmount] = useState<string>('');
    const [updateCommissionModalOpen, setUpdateCommissionModalOpen] = useState<boolean>(false);
    const [updateCommissionResult, setUpdateCommissionResult] = useState<string>('');

    useEffect(() => {
        if (commission) {
            setCommissionNotes(commission.notes || '');
            setCommissionAmount(formatCurrency(commission.amount, false));
        }
    }, [commission]);

    const handleSave = async () => {
        if (commission) {
            const amountInCents = Math.round(parseFloat(commissionAmount) * 100);
            const updateParams: UpdateCommissionParams = {
                notes: commissionNotes,
                amount: amountInCents,
            };

            try {
                await updateCommission(commission.uuid, updateParams);
                setUpdateCommissionResult('Update successful.');
            } catch (error) {
                setUpdateCommissionResult('Update failed.');
            } finally {
                onSave();
                setUpdateCommissionModalOpen(true);
            }
        }
    };

    const handleCommissionAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Allow only numbers and a single decimal point
        if (/^\d*\.?\d*$/.test(value)) {
            setCommissionAmount(value);
        }
    };

    const formatCurrency = (amount: number, dollarSign: boolean = true): string => {
        const formattedAmount = (amount / 100).toFixed(2);
        return dollarSign ? `$${formattedAmount}` : formattedAmount;
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>
                    {commission && (
                        <>
                            {commission.is_paid ? (
                                <span>View Commission</span>
                            ) : (
                                <span>Edit Commission</span>
                            )}
                            <Grid container direction="row" alignItems="left" spacing={1}>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Organization{' '}
                                        <strong>{commission.partner?.organization?.name}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Amount <strong>{formatCurrency(commission.amount)}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{ fontSize: '0.8rem', marginRight: '10px' }}
                                    >
                                        Type <strong>{commission.type}</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {commission && (
                        <>
                            <TextField
                                label="Commission Amount"
                                value={commissionAmount}
                                margin="dense"
                                onChange={handleCommissionAmountChange}
                                size="small"
                                style={{ width: '35%' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                }}
                                type="text"
                                inputProps={{
                                    inputMode: 'decimal',
                                    pattern: '[0-9]*\\.?[0-9]*',
                                }}
                                disabled={commission.is_paid}
                            />
                            <TextField
                                label="Commission Notes"
                                value={commissionNotes}
                                fullWidth
                                margin="dense"
                                onChange={(e) => setCommissionNotes(e.target.value)}
                                multiline
                                rows={2}
                                size="small"
                                disabled={commission.is_paid}
                            />
                        </>
                    )}
                </DialogContent>
                <Divider />
                {commission && commission.is_paid ? (
                    <DialogActions>
                        <Button onClick={onClose}>Close</Button>
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={handleSave} variant="contained">
                            Save
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
            <Dialog
                open={updateCommissionModalOpen}
                onClose={() => setUpdateCommissionModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>Update Commission</DialogTitle>
                <Divider />
                <DialogContent style={{ minWidth: '320px' }}>
                    <p id="simple-modal-description" style={{ fontSize: '0.9rem' }}>
                        {updateCommissionResult}
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => setUpdateCommissionModalOpen(false)}
                        style={{ float: 'right' }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CommissionDialog;
