import { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { SimplePaginateResponse } from '../../../app.types';
import { Comment } from '../../../utils';

export function getComments(
    individualId: string,
    params: { 'filter[asset_uuid]'?: string; page?: number } = {},
): Promise<AxiosResponse<SimplePaginateResponse<Comment>>> {
    return Request.get(`/individuals/${individualId}/videos/comments`, {
        params,
    });
}

export function createComment(
    individualId: string,
    data: { asset: string; comment: string },
): Promise<AxiosResponse<Comment>> {
    return Request.post(`/individuals/${individualId}/videos/comments`, data);
}

export function deleteComment(
    individualId: string,
    commentId: string,
): Promise<AxiosResponse<Comment>> {
    return Request.delete(`/individuals/${individualId}/videos/comments/${commentId}`);
}
