import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ButtonWithLoader, CustomButton } from 'components/Buttons';
import useTheme from '@mui/material/styles/useTheme';
import { SxProps, Theme } from '@mui/system';

//
export interface FormModalProps {
    children: React.ReactNode;
    /** handleClose A callback when the close button is clicked */
    handleClose: () => void;
    /** handleButtonClicked A callback when the close button is clicked */
    handleButtonClicked?: () => void;
    /** title The text to display in the title ofs the dialog */
    title?: string;
    /** open Whether or not the form modal is open */
    open: boolean;
    /** loading Whether or not the modal is in a loading state */
    loading?: boolean;
    /** buttonText The text on the action button */
    buttonText?: string;
    /** cancelText the text on the cancel button */
    cancelText?: string;
    /** fullWidth if the modal is full width or not */
    fullWidth?: boolean;
    /** maxWidth the maximum width of the modal */
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    /** disabled if the button is disabled */
    disabled?: boolean;
    /** className for the DialogContent */
    contentClassnameSx?: SxProps<Theme>;
    /** remove top padding from the DialogContent */
    noPadding?: boolean;
    /** hide the Header */
    noHeader?: boolean;
    /** Rounded button variant */
    roundedButton?: boolean;
    /** fullscreen if mobile */
    mobileFullScreen?: boolean;
}

function FormModal({
    children,
    handleClose,
    title,
    open,
    handleButtonClicked,
    contentClassnameSx,
    loading = false,
    buttonText = 'Save',
    cancelText = 'Cancel',
    maxWidth = 'sm',
    disabled = false,
    noPadding = false,
}: FormModalProps) {
    const theme = useTheme();
    return (
        <Dialog
            sx={{
                width: '100%',
                '& .MuiDialog-paper': {
                    margin: '32px 20px',
                },
                '& .MuiDialog-paperScrollPaper': {
                    width: '100%',
                },
            }}
            maxWidth={maxWidth}
            open={open}
        >
            <div
                style={{
                    padding: '50px 70px',
                    [theme.breakpoints.down('md')]: {
                        padding: '50px 38px',
                    },
                }}
            >
                {loading && <LinearProgress />}
                {!!title?.length && (
                    <DialogTitle
                        sx={{
                            color: theme.palette.primary.main,
                            textAlign: 'center',
                            [theme.breakpoints.down('md')]: {
                                fontSize: '12px',
                                lineHeight: '22px',
                                textAlign: 'left',
                                padding: '0 0 16px 0',
                            },
                        }}
                    >
                        {title}
                    </DialogTitle>
                )}
                {noPadding && <div style={{ display: 'none' }} />}
                <DialogContent sx={contentClassnameSx}>{children}</DialogContent>

                <DialogActions
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        [theme.breakpoints.down('md')]: {
                            padding: 0,
                        },
                    }}
                >
                    <CustomButton
                        color="primary"
                        onClick={handleClose}
                        variant="outlined"
                        sx={{
                            borderRadius: '50px',
                            textTransform: 'uppercase',
                            fontSize: '0.875rem',
                            [theme.breakpoints.down('md')]: {
                                fontSize: '12px',
                                lineHeight: '22px',
                            },
                            '&:disabled': {
                                backgroundColor: '#00000061',
                                color: '#fff',
                            },
                        }}
                    >
                        {cancelText}
                    </CustomButton>
                    {handleButtonClicked && (
                        <ButtonWithLoader
                            variant="contained"
                            color="primary"
                            onClick={handleButtonClicked}
                            disabled={disabled || loading}
                            loading={loading}
                            sx={{
                                borderRadius: '50px',
                                textTransform: 'uppercase',
                                fontSize: '0.875rem',
                                '@media (max-width:900px)': {
                                    // updated breakpoint. Please replace '900px' with actual break value.
                                    fontSize: '12px',
                                    lineHeight: '22px',
                                },
                                '&.Mui-disabled': {
                                    // updated pseudo-classes selector for MUI
                                    backgroundColor: '#00000061',
                                    color: '#fff',
                                },
                            }}
                        >
                            {buttonText}
                            <ArrowForwardIcon sx={{ marginLeft: 8, width: 12, height: 12 }} />
                        </ButtonWithLoader>
                    )}
                </DialogActions>
            </div>
        </Dialog>
    );
}

export default FormModal;
