import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Form, PaginationLink } from 'utils';

type GetAllFormsProps = {
    data: Form[];
    links: PaginationLink;
    cursor?: string;
};

type ReturnType = Promise<AxiosResponse<GetAllFormsProps>>;

const getAllForms = (params = {}): ReturnType => {
    return Request.get(`/data/forms`, {
        params,
    });
};

export default getAllForms;
