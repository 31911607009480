import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { SessionStackCreateRequest } from 'utils';

type ReturnType = Promise<AxiosResponse<void>>;

interface AddSessionStackArgs {
    groupId: string;
    calendarId: string;
    data: SessionStackCreateRequest;
}

const addSessionStackToGroup = ({ groupId, calendarId, data }: AddSessionStackArgs): ReturnType => {
    return Request.post(`/groups/${groupId}/calendars/${calendarId}/add-session-stack`, data);
};

export default addSessionStackToGroup;
