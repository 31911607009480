import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<
    AxiosResponse<{
        unique: boolean;
    }>
>;

const userUsernameCheck = (data: {
    user_name: string;
    phone?: string;
    email?: string;
}): ReturnType => {
    return Request.post(`/unique-username`, {
        user_name: data.user_name,
        phone: data.phone,
        email: data.email,
    });
};

export default userUsernameCheck;
