import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { bulkUpdateForm } from 'api';
import { cloneDeep, isUndefined, last, now, omitBy } from 'lodash';
import { Choice, Form, Question, QuestionType, Section } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '.';

export type FormState = {
    form: Form | null;
    lastUpdatedAt: number;
    lastSaveAt: number;
    uploadingAssetTmpUrls: Array<string>;
    uploadedAssetUrls: Array<string>;
    updating: boolean;
};

const initialState: FormState = {
    form: null,
    lastUpdatedAt: Infinity,
    lastSaveAt: 0,
    uploadingAssetTmpUrls: [],
    uploadedAssetUrls: [],
    updating: false,
};

const getEmptyChoice = () =>
    ({
        uuid: uuidv4(),
        text: '',
        is_answer: false,
    } as Choice);

const getEmptySection = () =>
    ({
        uuid: uuidv4(),
        name: 'Untitled Section',
        questions: [
            {
                uuid: uuidv4(),
                question: 'Untitled Question',
                type: QuestionType.MultipleChoiceSingleSelect,
                order: 1,
            } as Question,
        ],
    } as Section);

const getEmptyQuestion = () =>
    ({
        uuid: uuidv4(),
        question: 'Untitled Question',
        type: QuestionType.MultipleChoiceSingleSelect,
        order: 0,
    } as Question);

// issue mint new UUIDs for assessment sections and questions
const cloneSection = (section: Section): Section => {
    return {
        ...section,
        uuid: uuidv4(),
        name: `${section.name} (Copy)`,
        questions: section.questions.map((q) => ({
            ...q,
            uuid: uuidv4(),
        })),
    };
};

const formReducers = {
    setForm: (state: FormState, action: PayloadAction<Form>) => {
        state.form = action.payload as Form;
        state.lastUpdatedAt = 0;
        state.lastSaveAt = now();
    },
    updateFormDetails: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            updatePayload:
                | Pick<
                      Partial<Form>,
                      'title' | 'type' | 'description' | 'organization' | 'media' | 'image_urls'
                  >
                | { [k: string]: any; media_uuid?: string };
            imageUrl?: string | null;
        }>,
    ) => {
        let { updatePayload, imageUrl } = action.payload;
        // Remove the payload keys which are undefined before spreading into the state
        updatePayload = omitBy(updatePayload, isUndefined);

        state.form = {
            ...(state.form as Form),
            ...updatePayload,
            image_urls: {
                '200': null,
                '350': imageUrl ?? null,
                '500': imageUrl ?? null,
                '1000': imageUrl ?? null,
                avatar: null,
                original: null,
            },
        };
        state.lastUpdatedAt = now();
    },
    updateFormLastSavedAt: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            lastSavedAt: number;
        }>,
    ) => {
        state.lastSaveAt = action.payload.lastSavedAt;
    },
};

const sectionReducers = {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    addSectionToForm: (state: FormState, action: PayloadAction<{ formUuid: string }>) => {
        state.form = {
            ...(state.form as Form),
            sections: [...(state.form?.sections ?? []), getEmptySection()],
        };
        state.lastUpdatedAt = now();
    },
    copySectionToForm: (state: FormState, action: PayloadAction<{ section: Section }>) => {
        state.form = {
            ...(state.form as Form),
            sections: [...(state.form?.sections || []), cloneSection(action.payload.section)],
        };
        state.lastUpdatedAt = now();
    },
    removeSectionFromForm: (
        state: FormState,
        action: PayloadAction<{ formUuid: string; removedSectionUuid: string }>,
    ) => {
        const { removedSectionUuid } = action.payload;
        state.form = {
            ...(state.form as Form),
            sections: (state.form as Form).sections.filter(
                ({ uuid }) => uuid != removedSectionUuid,
            ),
        };
        state.lastUpdatedAt = now();
    },
    sectionsReoredered: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuidsInOrder: Array<string>;
        }>,
    ) => {
        const { sectionUuidsInOrder } = action.payload;
        state.form = {
            ...(state.form as Form),
            sections: (state.form as Form).sections.map((section) => ({
                ...section,
                order: Math.max(sectionUuidsInOrder.indexOf(section.uuid as string), 0),
            })),
        };

        state.lastUpdatedAt = now();
    },
    updateSectionDetails: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuid: string;
            updatePayload:
                | Pick<Partial<Section>, 'name' | 'description' | 'asset' | 'media'>
                | { [key: string]: any };
        }>,
    ) => {
        let { sectionUuid, updatePayload } = action.payload;
        // Remove the payload keys which are undefined before spreading into the state
        updatePayload = omitBy(updatePayload, isUndefined);

        state.form = {
            ...(state.form as Form),
            sections: (state.form?.sections ?? []).map((section) => {
                if (section.uuid != sectionUuid) {
                    return section;
                }
                return {
                    ...section,
                    ...updatePayload,
                };
            }),
        };
        state.lastUpdatedAt = now();
    },
};

const questionReducers = {
    addQuestionToFormSection: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuid: string;
            newQuestion?: Question | undefined;
        }>,
    ) => {
        const { sectionUuid, newQuestion } = action.payload;

        state.form = {
            ...(state.form as Form),
            sections: (state.form?.sections ?? []).map((section) => {
                if (section.uuid != sectionUuid) {
                    return section;
                }
                return {
                    ...section,
                    questions: [
                        ...section.questions,
                        newQuestion || {
                            ...getEmptyQuestion(),
                            order: (last(section.questions)?.order || 0) + 1,
                        },
                    ],
                };
            }),
        };
        state.lastUpdatedAt = now();
    },
    removeQuestionFromFormSection: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuid: string;
            removedQuestionUuid: string;
        }>,
    ) => {
        const { sectionUuid, removedQuestionUuid } = action.payload;
        state.form = {
            ...(state.form as Form),
            sections: (state.form?.sections ?? []).map((section) => {
                if (section.uuid != sectionUuid) {
                    return section;
                }
                return {
                    ...section,
                    questions: section.questions.filter(({ uuid }) => uuid != removedQuestionUuid),
                };
            }),
        };
        state.lastUpdatedAt = now();
    },
    questionsReoredered: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuid: string;
            questionUuidsInOrder: Array<string>;
        }>,
    ) => {
        const { sectionUuid, questionUuidsInOrder } = action.payload;
        state.form = {
            ...(state.form as Form),
            sections: (state.form?.sections ?? []).map((section) => {
                if (section.uuid != sectionUuid) {
                    return section;
                }
                return {
                    ...section,
                    questions: section.questions.map((question) => ({
                        ...question,
                        order: Math.max(questionUuidsInOrder.indexOf(question.uuid as string), 0),
                    })),
                };
            }),
        };
        state.lastUpdatedAt = now();
    },
    updateQuestionDetails: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuid: string;
            questionUuid: string;
            updatePayload:
                | Pick<Partial<Question>, 'question' | 'choices' | 'asset' | 'media'>
                | { [key: string]: any };
        }>,
    ) => {
        let { sectionUuid, questionUuid, updatePayload } = action.payload;
        // Remove the payload keys which are undefined before spreading into the state
        updatePayload = omitBy(updatePayload, isUndefined);

        state.form = {
            ...(state.form as Form),
            sections: (state.form?.sections ?? []).map((section) => {
                if (section.uuid != sectionUuid) {
                    return section;
                }
                return {
                    ...section,
                    questions: section.questions.map((question) => {
                        if (question.uuid != questionUuid) {
                            return question;
                        }
                        return {
                            ...question,
                            ...updatePayload,
                        };
                    }),
                };
            }),
        };
        state.lastUpdatedAt = now();
    },
    updateQuestionType: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuid: string;
            questionUuid: string;
            newType: QuestionType;
        }>,
    ) => {
        let { sectionUuid, questionUuid, newType } = action.payload;

        state.form = {
            ...(state.form as Form),
            sections: (state.form?.sections ?? []).map((section) => {
                if (section.uuid != sectionUuid) {
                    return section;
                }
                return {
                    ...section,
                    questions: section.questions.map((question) => {
                        if (question.uuid != questionUuid) {
                            return question;
                        }

                        let choices: Array<Choice> = cloneDeep(question.choices || []);
                        let updatedQuestion = question.question;

                        switch (newType) {
                            case QuestionType.Text:
                                choices = [];
                                break;

                            case QuestionType.Confirmation:
                                // Set default question text and single choice for confirmation
                                updatedQuestion =
                                    'Click to confirm you have completed this section.';
                                choices = [
                                    {
                                        uuid: uuidv4(),
                                        text: 'Confirm',
                                        is_answer: false,
                                        order: 0,
                                    },
                                ];
                                break;

                            // If there are existing choices, we will keep it
                            // but just unmark all the choices marked as answer
                            case QuestionType.MultipleChoiceMultiSelect:
                            case QuestionType.MultipleChoiceSingleSelect:
                                choices = choices.map((choice) => ({
                                    ...choice,
                                    is_answer: false,
                                }));
                                // If there are no choices, add one
                                if (choices.length == 0) {
                                    choices.push(getEmptyChoice());
                                }
                                break;

                            default:
                                break;
                        }

                        return {
                            ...question,
                            type: newType,
                            question: updatedQuestion,
                            choices: choices,
                        };
                    }),
                };
            }),
        };

        state.lastUpdatedAt = now();
    },
};

const questionChoiceReducers = {
    updateQuestionChoiceDetails: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuid: string;
            questionUuid: string;
            choiceUuid: string;
            updatePayload:
                | Pick<Partial<Choice>, 'text' | 'media' | 'media_url' | 'is_answer'>
                | { [key: string]: any };
        }>,
    ) => {
        let { sectionUuid, questionUuid, choiceUuid, updatePayload } = action.payload;
        // Remove the payload keys which are undefined before spreading into the state
        updatePayload = omitBy(updatePayload, isUndefined);

        state.form = {
            ...(state.form as Form),
            sections: (state.form?.sections ?? []).map((section) => {
                if (section.uuid != sectionUuid) {
                    return section;
                }
                return {
                    ...section,
                    questions: section.questions.map((question) => {
                        if (question.uuid != questionUuid) {
                            return question;
                        }
                        return {
                            ...question,
                            choices: question.choices?.map((choice) => {
                                if (choice.uuid != choiceUuid) {
                                    return choice;
                                }

                                return {
                                    ...choice,
                                    ...updatePayload,
                                };
                            }),
                        };
                    }),
                };
            }),
        };
        state.lastUpdatedAt = now();
    },
    addQuestionChoice: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuid: string;
            questionUuid: string;
        }>,
    ) => {
        let { sectionUuid, questionUuid } = action.payload;

        state.form = {
            ...(state.form as Form),
            sections: (state.form?.sections ?? []).map((section) => {
                if (section.uuid != sectionUuid) {
                    return section;
                }
                return {
                    ...section,
                    questions: section.questions.map((question) => {
                        if (question.uuid != questionUuid) {
                            return question;
                        }
                        return {
                            ...question,
                            choices: [...(question.choices || []), getEmptyChoice()],
                        };
                    }),
                };
            }),
        };
        state.lastUpdatedAt = now();
    },
    removeQuestionChoice: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuid: string;
            questionUuid: string;
            removedChoiceUuid: string;
        }>,
    ) => {
        let { sectionUuid, questionUuid, removedChoiceUuid } = action.payload;

        state.form = {
            ...(state.form as Form),
            sections: (state.form?.sections ?? []).map((section) => {
                if (section.uuid != sectionUuid) {
                    return section;
                }
                return {
                    ...section,
                    questions: section.questions.map((question) => {
                        if (question.uuid != questionUuid) {
                            return question;
                        }
                        return {
                            ...question,
                            choices: (question.choices || []).filter(
                                ({ uuid }) => uuid != removedChoiceUuid,
                            ),
                        };
                    }),
                };
            }),
        };
        state.lastUpdatedAt = now();
    },
    choicesReoredered: (
        state: FormState,
        action: PayloadAction<{
            formUuid: string;
            sectionUuid: string;
            questionUuid: string;
            choiceUuidsInOrder: Array<string>;
        }>,
    ) => {
        const { sectionUuid, questionUuid, choiceUuidsInOrder } = action.payload;
        state.form = {
            ...(state.form as Form),
            sections: (state.form?.sections ?? []).map((section) => {
                if (section.uuid != sectionUuid) {
                    return section;
                }
                return {
                    ...section,
                    questions: section.questions.map((question) => {
                        if (question.uuid != questionUuid) {
                            return question;
                        }

                        return {
                            ...question,
                            choices: (question.choices || []).map((choice) => ({
                                ...choice,
                                order: Math.max(
                                    choiceUuidsInOrder.indexOf(choice.uuid as string),
                                    0,
                                ),
                            })),
                        };
                    }),
                };
            }),
        };

        state.lastUpdatedAt = now();
    },
};

// The main purpose of this is to remove *.asset as
// thats something useful for the frontend to show image, backend does not need that
// Also we remove the assets which are already uploaded from the payload
// otherwise backend will throw an error that file does not exist in tmp storage
const prepareFormPayload = (uploadedAssets: Array<string>, form: Form): Form => {
    if (uploadedAssets.indexOf(form.media || '') >= 0) {
        delete form.media;
    }
    delete form.asset;

    return {
        ...form,
        sections: form.sections.map((section) => {
            delete section.asset;
            if (uploadedAssets.indexOf(section.media || '') >= 0) {
                delete section.media;
            }
            return {
                ...section,
                questions: section.questions.map((question) => {
                    delete question.asset;
                    if (uploadedAssets.indexOf(question.media || '') >= 0) {
                        delete question.media;
                    }
                    return {
                        ...question,
                        choices: (question.choices || []).map((choice) => {
                            if (uploadedAssets.indexOf(choice.media || '') >= 0) {
                                delete choice.media;
                            }
                            // This is a frontend data, backend does not need this
                            delete choice.media_url;
                            return choice;
                        }),
                    };
                }),
            };
        }),
    };
};

export const saveForm = createAsyncThunk('forms/saveForm', async (_, { getState }) => {
    let { form }: RootState = getState() as RootState;
    const formToUpdate = form.form;

    if (!formToUpdate) {
        return;
    }

    const formUpdatePayload = prepareFormPayload(form.uploadedAssetUrls, cloneDeep(formToUpdate));

    const { status } = await bulkUpdateForm(formToUpdate.uuid, formUpdatePayload);
    return status;
});

export const formSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        ...formReducers,
        ...sectionReducers,
        ...questionReducers,
        ...questionChoiceReducers,
    },
    extraReducers: ({ addCase }) => {
        addCase(saveForm.pending, (state) => {
            // Push all assets which are newly uploaded assets(start with tmp/)
            // to the state
            let uploadingAssetTmpUrls: Array<string> = [];

            if (
                state.form?.media?.startsWith('tmp') &&
                state.uploadedAssetUrls.indexOf(state.form.media) < 0
            ) {
                uploadingAssetTmpUrls.push(state.form.media);
            }

            state.form?.sections.forEach((section) => {
                if (
                    section.media?.startsWith('tmp') &&
                    state.uploadedAssetUrls.indexOf(section.media) < 0
                ) {
                    uploadingAssetTmpUrls.push(section.media);
                }

                section.questions.forEach((question) => {
                    if (
                        question.media?.startsWith('tmp') &&
                        state.uploadedAssetUrls.indexOf(question.media) < 0
                    ) {
                        uploadingAssetTmpUrls.push(question.media);
                    }

                    question.choices?.forEach((choice) => {
                        if (
                            choice.media?.startsWith('tmp') &&
                            state.uploadedAssetUrls.indexOf(choice.media) < 0
                        ) {
                            uploadingAssetTmpUrls.push(choice.media);
                        }
                    });
                });
            });

            state.uploadingAssetTmpUrls = uploadingAssetTmpUrls;
            state.updating = true;
        });
        addCase(saveForm.fulfilled, (state) => {
            state.lastSaveAt = now();
            state.uploadedAssetUrls = [...state.uploadedAssetUrls, ...state.uploadingAssetTmpUrls];
            state.uploadingAssetTmpUrls = [];
            state.updating = false;
        });
        addCase(saveForm.rejected, (state) => {
            state.lastSaveAt = now();
            state.updating = false;
        });
    },
});

export const {
    setForm,
    updateFormDetails,
    updateFormLastSavedAt,
    addSectionToForm,
    removeSectionFromForm,
    sectionsReoredered,
    copySectionToForm,
    updateSectionDetails,
    addQuestionToFormSection,
    removeQuestionFromFormSection,
    questionsReoredered,
    updateQuestionDetails,
    updateQuestionType,
    updateQuestionChoiceDetails,
    addQuestionChoice,
    removeQuestionChoice,
    choicesReoredered,
} = formSlice.actions;

export const formSelector = (state: RootState) => state.form;
