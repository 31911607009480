import { updateMember, createMember } from 'api';
import { CreateMemberForm, FormModal, confirmViaDialog } from 'components';
import { FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMemberAttributes, loadAttributes } from 'redux/reducers/attributes';
import { useAppDispatch } from 'redux/store';
import {
    AttributeValue,
    Group,
    Individual,
    Member,
    MemberType,
    SAVE_INFORMATION_TEXT,
    SAVE_TEXT,
    UNSAVED_CHANGES_TEXT,
    EXIT_TEXT,
    isSubset,
    handleFileUpload,
} from 'utils';

interface CreateMemberModalProps {
    /** open Whether or not the movement modal is open */
    open: boolean;
    /** Callback when the movement modal is closed */
    onClose: (member: Member | false) => void;
    /** group The group you are creating a member for. */
    group: Group;
    /** memberTypes The available member types */
    memberTypes: Array<MemberType>;
    selectedIndividual?: Individual | null;
    editMember?: Member | null;
}

export default function CreateMemberModal({
    open,
    onClose,
    group,
    memberTypes,
    selectedIndividual,
    editMember,
}: CreateMemberModalProps): ReactElement {
    const attributes = useSelector(getMemberAttributes);

    const [disabled, setDisabled] = useState(true);

    const [loading, setLoading] = useState<boolean>(false);

    const [attributeValues, setAttributeValues] = useState<AttributeValue[]>(
        editMember ? editMember.attributes : [],
    );
    const [profilePhoto, setProfilePhoto] = useState<File>();

    const formRef = useRef<FormikProps<FormikValues>>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (editMember) {
            setAttributeValues(editMember.attributes);
        }
    }, [editMember]);

    useEffect(() => {
        dispatch(loadAttributes());
    }, [dispatch]);

    const onButtonClicked = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const reloadAttributes = () => {
        if (!isSubset(attributeValues, attributes)) {
            dispatch(loadAttributes());
        }
    };

    const onChange = (isValid: boolean) => {
        setDisabled(!isValid);
    };

    const onCancel = async () => {
        const values = formRef.current?.values;
        if (
            formRef.current?.dirty &&
            (profilePhoto ||
                values?.first_name ||
                values?.last_name ||
                values?.member_type ||
                attributeValues.length > 0)
        ) {
            const res = await confirmViaDialog({
                confirmation: {
                    title: UNSAVED_CHANGES_TEXT,
                    message: SAVE_INFORMATION_TEXT,
                    cancelButtonText: EXIT_TEXT,
                    okButtonText: SAVE_TEXT,
                    onCancelPressed: () => onClose(false),
                },
            });
            res && onButtonClicked();
        } else {
            onClose(false);
        }
    };

    const onSubmit = async (values: FormikValues) => {
        try {
            setLoading(true);
            let photoKey: string | undefined = undefined;
            if (profilePhoto) {
                photoKey = await handleFileUpload(profilePhoto);
            }

            if (editMember) {
                await updateMember(group.uuid, editMember.uuid, {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    member_type: values.member_type,
                    attributes: attributeValues,
                    profile_photo_path: values.photo_path ?? photoKey,
                }).then((res) => {
                    reloadAttributes();
                    onClose(res.data);
                });
            } else {
                const response = await createMember(group.uuid, {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    member_type: values.member_type,
                    attributes: attributeValues,
                    individual: selectedIndividual?.uuid || null,
                    profile_photo: photoKey,
                });
                setLoading(false);
                if (response?.request?.status === 200) {
                    reloadAttributes();
                    onClose(response.data);
                }
            }
        } catch (err: any) {
            setLoading(false);
        } finally {
            setProfilePhoto(undefined);
            setLoading(false);
        }
    };

    return (
        <FormModal
            handleButtonClicked={onButtonClicked}
            disabled={disabled || loading}
            loading={loading}
            handleClose={onCancel}
            open={open}
            title={editMember ? 'Edit Member' : 'Add New Member'}
        >
            <CreateMemberForm
                memberTypes={memberTypes}
                innerRef={formRef}
                onChange={onChange}
                onSubmit={onSubmit}
                onAttributeValuesChanged={setAttributeValues}
                attributes={attributes}
                selectedIndividual={selectedIndividual}
                editMember={editMember}
                profilePhoto={profilePhoto}
                setProfilePhoto={setProfilePhoto}
            />
        </FormModal>
    );
}
