import React, { ReactElement, useEffect } from 'react';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CustomButton, TypographyTitleh6 } from '../../../components';
import { useSelector } from 'react-redux';
import { feedActions, feedSelectors, profileActions } from '../../../redux/reducers/social';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../Routes/routes';
import { useAppDispatch } from '../../../redux/store';
import { dayOfYear } from '../../../utils';
import moment from 'moment';
import { FeedActivityCard, SocialProfileCard, SocialProfileCardMobile } from '../components';

export default function Posts(): ReactElement {
    const { push } = useHistory();
    const theme = useTheme();
    const { profile, loaded: profileLoaded } = useSelector(feedSelectors.myProfileSelector);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        items,
        loading: feedLoading,
        loaded: feedLoaded,
        query,
        hasAnotherPage,
    } = useSelector(feedSelectors.feedSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!profileLoaded) {
            dispatch(profileActions.loadMyProfile());
        }
        dispatch(
            feedActions.loadFeed({
                query: {
                    page: 1,
                },
            }),
        );
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onNextPage = () => {
        dispatch(
            feedActions.loadFeed({
                query: {
                    page: query.page + 1,
                },
            }),
        );
    };

    return (
        <Container>
            <Grid
                sx={{ marginTop: 36, padding: '0 16px', overflow: 'hidden' }}
                container
                direction={isMobile ? 'column-reverse' : 'row'}
            >
                <Grid item xs={12} sm={6} md={7} lg={7}>
                    {items.length === 0 && feedLoaded && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{ height: '100%' }}
                        >
                            <Typography variant="h6" color="primary">
                                Inspire others by posting your workouts!
                            </Typography>
                            <></>
                            <Typography variant="h6" color="primary">
                                Click post from MyFeed and share workouts with followers!
                            </Typography>
                        </Box>
                    )}
                    {items.length > 0 && feedLoaded && (
                        <Grid container>
                            {items.map((item: any, index: any) => (
                                <Grid key={index} item sx={{ width: '100%' }}>
                                    {index === 0 ||
                                    (index !== 0 &&
                                        items[index - 1] &&
                                        dayOfYear(items[index - 1].created) !==
                                            dayOfYear(item.created)) ? (
                                        <TypographyTitleh6
                                            color="primary"
                                            style={{ paddingBottom: '16px' }}
                                        >
                                            {moment(item.created * 1000)
                                                .local()
                                                .format('MMMM DD, YYYY')}
                                        </TypographyTitleh6>
                                    ) : (
                                        ''
                                    )}
                                    <FeedActivityCard
                                        initialExpandedState={false}
                                        sessionTitle={item.session_name ?? ''}
                                        sessionCoverPhoto={item.session_cover_photo ?? ''}
                                        logs={item.logs}
                                        title={`${item.individual_name} completed ${item.session_name}`}
                                        subheader={moment(item.date * 1000).calendar()}
                                        description={item.session_description ?? ''}
                                        isPublic={item.public}
                                        avatar={item.profile_photo['avatar']}
                                        avatarName={item.user_full_name}
                                        showActions={false}
                                        onAvatarClicked={() => push(`/@${item.user_slug}`)}
                                    />
                                </Grid>
                            ))}
                            <Grid item sx={{ width: '100%' }}>
                                <CustomButton
                                    style={{ marginBottom: '80px' }}
                                    disabled={!hasAnotherPage || feedLoading}
                                    onClick={onNextPage}
                                >
                                    view more
                                </CustomButton>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    sx={
                        isMobile
                            ? {
                                  right: 0,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                              }
                            : {}
                    }
                    item
                    xs={12}
                    sm={6}
                    md={5}
                    lg={5}
                >
                    {profile && !isMobile && (
                        <React.Fragment>
                            <SocialProfileCard
                                bio={profile.bio ?? ''}
                                slug={profile.slug}
                                user={profile}
                                onMyProfileClicked={() => push(ROUTES.MyFeed.path)}
                                isMyProfile
                            />
                        </React.Fragment>
                    )}
                    {profile && isMobile && (
                        <SocialProfileCardMobile
                            bio={profile.bio ?? ''}
                            user={profile}
                            onMyProfileClicked={() => push(ROUTES.MyFeed.path)}
                            isMyProfile
                        />
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}
