import { Box, Button, CircularProgress, Hidden } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { getFormDetails } from 'api';
import { orderBy } from 'lodash';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import ROUTES from 'Routes/routes';
import { Form, getIdFromEndOfRoute, somethingWentWrong } from 'utils';
import { AssessmentSubmissionOwnerPreview } from '../components';

const CompleteAssessmentPreview = () => {
    const { push, location } = useHistory();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const [form, setForm] = useState<Form | null>(null);
    const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);

    // Get form UUID from the url params
    // As this is a preview, there is no submisision
    let formUuid = getIdFromEndOfRoute(location.pathname);

    // Load the form from the database
    useEffect(() => {
        if (!form) {
            getFormDetails(formUuid)
                .then((response) => {
                    const form = response.data as Form;
                    form.sections = form.sections.map((section) => {
                        return {
                            ...section,
                            questions: orderBy(
                                section.questions.map((question) => {
                                    return {
                                        ...question,
                                        choices: orderBy(question.choices || [], 'order'),
                                    };
                                }),
                                'order',
                            ),
                        };
                    });
                    setForm(response.data);
                })
                .catch(() => somethingWentWrong());
        }
    }, [form, formUuid]);

    useEffect(() => {
        window.onbeforeunload = function () {
            return 'Are you sure you want to leave?';
        };
    }, []);

    const submitAssessment = async () => {
        if (form?.uuid) {
            push(ROUTES.ClassAssessments.path);
            dispatch(
                pushMessage({
                    status: 'success',
                    message: 'Assessment submitted successfully.',
                }),
            );
        }
    };

    return (
        <FixedLayout
            disableBackButton={true}
            title={form?.title || ''}
            titleActions={
                <>
                    {readyToSubmit && (
                        <Hidden mdUp>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => {
                                    submitAssessment();
                                }}
                            >
                                Submit
                            </Button>
                        </Hidden>
                    )}
                </>
            }
            content={
                form ? (
                    <AssessmentSubmissionOwnerPreview
                        form={form}
                        onReadyToSubmit={() => setReadyToSubmit(true)}
                        onSubmitCancelled={() => setReadyToSubmit(false)}
                        // This is a preview page and not taking the actual assessment.
                        // So back route should take to the assessment edit page from where they started the preview
                        onFormExited={() =>
                            push(ROUTES.CreateAssessment.path.replace(':id', formUuid))
                        }
                    />
                ) : (
                    <Box pt={20} display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )
            }
            hideFooterOnMobileDevices={true}
            footerActions={
                <>
                    {readyToSubmit && (
                        <Box display="flex" justifyContent="center">
                            <Button
                                sx={{ marginTop: theme.spacing(6) }}
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => {
                                    submitAssessment();
                                }}
                            >
                                Submit Assessment
                            </Button>
                        </Box>
                    )}
                </>
            }
        ></FixedLayout>
    );
};

export default CompleteAssessmentPreview;
