import NavigationLayout from '../../Layouts/NavigationLayout';
import AthleteMarketplace from './scenes/AthleteMarketplace';
import AthleteHome from './scenes/AthleteHome';
import AthleteStart from './scenes/AthleteStart';
import AthleteComboSubscribe from './scenes/AthleteComboSubscribe';
import BlankLayout from '../../Layouts/BlankLayout';

export const athleteRoutes = [
    {
        exact: true,
        layout: NavigationLayout,
        path: '/athletes',
        private: true,
        component: AthleteHome,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/athletes/:organizationSlug',
        private: true,
        component: AthleteMarketplace,
    },
    {
        exact: true,
        layout: BlankLayout,
        path: '/athletes-subscribe/:organizationSlug',
        private: false,
        component: AthleteComboSubscribe,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/athletes-start/:organizationSlug',
        private: false,
        component: AthleteStart,
        is_auth_route: true,
    },
];
