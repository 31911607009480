import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    Avatar,
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import createForm from 'api/Form/createForm';
import { confirmViaDialog, FormDialog } from 'components';
import { useFormik } from 'formik';

import { isEmpty } from 'lodash';
import React, { FC, useContext } from 'react';
import {
    EXIT_TEXT,
    SAVE_INFORMATION_TEXT,
    SAVE_TEXT,
    somethingWentWrong,
    UNSAVED_CHANGES_TEXT,
    yupSchemas,
} from 'utils';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';

type CreateAssessmentModalProps = {
    open: boolean;
    onClose: (newVal: boolean, title?: string, type?: string) => void;
};

const CreateAssessmentModal: FC<React.PropsWithChildren<CreateAssessmentModalProps>> = ({
    open,
    onClose,
}) => {
    const { organizations } = useContext(OrganizationsContext);

    const { handleSubmit, setErrors, handleChange, values, errors, touched, isValid } = useFormik({
        initialValues: yupSchemas.createAssessmentDialog.getDefault(),
        validationSchema: yupSchemas.createAssessmentDialog,
        validateOnBlur: true,
        validateOnMount: true,
        onSubmit: async ({ title, type, organization }, { setErrors }) => {
            try {
                const response = await createForm({
                    title,
                    type,
                    organization,
                });

                if (response.status !== 200) {
                    alert('Failed to create form. Please reload the page and try again.');
                }

                onClose(true, response.data.uuid);
            } catch (error: any) {
                setErrors(error || {});
                somethingWentWrong();
            }
        },
    });

    const onCancel = async () => {
        if (values.title || values.organization !== '' || values.type !== '') {
            const res = await confirmViaDialog({
                confirmation: {
                    title: UNSAVED_CHANGES_TEXT,
                    message: SAVE_INFORMATION_TEXT,
                    cancelButtonText: EXIT_TEXT,
                    okButtonText: SAVE_TEXT,
                    onCancelPressed: () => close(),
                },
            });
            res && handleSubmit();
        } else {
            close();
        }
    };

    const close = () => {
        setErrors({});
        onClose(false);
    };
    const theme = useTheme();

    return (
        <FormDialog
            handleButtonClicked={handleSubmit}
            loading={false}
            handleClose={onCancel}
            disabled={!isValid}
            open={open}
            buttonText="Create Assessment"
            cancelText="Close"
            title="New Assessment"
        >
            <form onSubmit={handleSubmit}>
                <TextField
                    sx={{ width: '100%', marginBottom: theme.spacing(4), marginTop: '4px' }}
                    onChange={handleChange}
                    value={values.title}
                    error={touched.title && Boolean(errors.title)}
                    label="Title"
                    id="title"
                    name="title"
                    variant="outlined"
                />
                {!isEmpty(organizations) && (
                    <FormControl
                        fullWidth
                        sx={{ width: '100%', marginBottom: '8px', marginTop: '8px' }}
                    >
                        <InputLabel id="demo-simple-select-label">Organization</InputLabel>
                        <Select
                            name="organization"
                            id="organization"
                            value={values.organization}
                            onChange={handleChange}
                            label="Organization"
                        >
                            {organizations.map((o) => {
                                return (
                                    <MenuItem key={o.uuid} value={o.uuid}>
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                            }}
                                        >
                                            <Avatar
                                                src={o.image_urls['avatar'] ?? ''}
                                                alt={o.name}
                                                variant="rounded"
                                                sx={{
                                                    marginRight: 6,
                                                    display: 'inline-block',
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    display: 'inline-block',
                                                }}
                                            >
                                                {o.name}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
                <FormControl
                    sx={{ width: '100%', marginBottom: '8px', marginTop: '8px' }}
                    fullWidth
                    variant="outlined"
                >
                    <InputLabel>Category</InputLabel>
                    <Select
                        id="type"
                        name="type"
                        label="Category"
                        value={values.type}
                        onChange={handleChange}
                        IconComponent={KeyboardArrowDownIcon}
                        error={touched.type && Boolean(errors.type)}
                    >
                        <MenuItem disabled value="">
                            Select a category
                        </MenuItem>
                        <MenuItem value="sport-specific">Sports Specific</MenuItem>
                        <MenuItem value="athletic-training">Athletic Training</MenuItem>
                        <MenuItem value="learning-assessments">Learning Assessments</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                    </Select>
                    {touched.type && Boolean(errors.type) && (
                        <FormHelperText>{errors.type}</FormHelperText>
                    )}
                </FormControl>
            </form>
        </FormDialog>
    );
};

export default CreateAssessmentModal;
