import { pushMessage } from 'redux/reducers/messages';
import { store } from 'redux/store';

export const somethingWentWrong = (message?: string) => {
    store.dispatch(
        pushMessage({
            status: 'error',
            message: message || 'Something went wrong, please try again',
        }),
    );
};
