import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FullScreenDialogCard from '../components/HowToDialogCard';
import SprintCropInitial from '../media/SprintCropInitial.png';
import SprintCrop2nd from '../media/SprintCrop2nd.png';
import SprintCropFineTune from '../media/SprintCropFineTune.png';

function SprintCroppingOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'h4'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Take sprint analysis to a whole new level with fully customizable sprint
                        crops. Manually select the exact start and stop of each sprint. Label the
                        sprint crops with the type of sport specific movement like stealing a base,
                        specific football route, or any applicable name.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Sprint cropping tool has 3 levels of granularity for optimized
                            performance.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}

                <FullScreenDialogCard
                    image={SprintCropInitial}
                    title="Cropping in one second detail from session summary"
                    headline="Cropping in one second detail from session summary"
                    description="Crop an rough estimate of the sprint so you can fine tune the start and stop time with precision in the next step. Click, hold, drag, and release to make a crop."
                />
                <FullScreenDialogCard
                    image={SprintCrop2nd}
                    title="Fine tuning the crop in 10hz detail"
                    headline="Fine tuning the crop in 10hz detail"
                    description="Get the exact start and end to provide the most accurate analysis for sprints, change of direction, and sport specific tasks."
                />
                <FullScreenDialogCard
                    image={SprintCropFineTune}
                    title="Analysing the crop and save for future use"
                    headline="Analysing the crop and save for future use"
                    description="Imediately get automated analysis for time segments and save for future analysis in exploration tab."
                />
            </Grid>
        </>
    );
}

export default SprintCroppingOverview;
