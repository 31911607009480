import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<
    AxiosResponse<{
        url: string;
        key: string;
    }>
>;

const getSignedUrl = (): ReturnType => Request.post(`/files/signed-url`);

export default getSignedUrl;
