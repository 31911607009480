import Evaporate, { TransferStats } from 'evaporate';
import sha256 from 'js-sha256';
import sparkMD5 from 'spark-md5';
import { v4 as uuidv4 } from 'uuid';

export const evaporateConfig = {
    logging: false,
    signerUrl: `${process.env.REACT_APP_API_HOST}/files/evaporate-signed-url`,
    aws_key: process.env.REACT_APP_AWS_KEY ?? '',
    bucket: process.env.REACT_APP_AWS_BUCKET ?? '',
    cloudfront: false,
    computeContentMd5: true,
    cryptoMd5Method: (d: ArrayBuffer) => btoa(sparkMD5.ArrayBuffer.hash(d, true)),
    cryptoHexEncodedHash256: (data: string | ArrayBuffer | null) =>
        sha256.sha256.hex(data as sha256.Message),
    s3Acceleration: process.env.REACT_APP_AWS_ACCELERATE === 'true',
    awsRegion: process.env.REACT_APP_AWS_BUCKET_REGION ?? 'us-east-1',
};
export default class S3Upload {
    private evaporate: Evaporate | undefined;

    constructor(
        private evaporateSettings: Evaporate.CreateConfig = evaporateConfig,
        private s3Path = 'tmp/',
    ) {}

    onStarted?: (fileName: string, file_key: string) => void;
    onUploadInitiated?: (fileName: string, s3UploadId?: string) => void;
    onCancelled?: (fileName: string) => void;
    onComplete?: (fileName: string, awsObjectKey: string) => void;
    onError?: (fileName: string, msg: string) => void;
    onProgress?: (
        fileName: string,
        originalFileName: string,
        p: number,
        stats: TransferStats,
    ) => void;
    onBeforeSigner?: (fileName: string, xhr: XMLHttpRequest, url: string) => void;

    cancelAll(): Promise<any> {
        return Evaporate.create(this.evaporateSettings).then((evaporate: Evaporate) =>
            evaporate.cancel(),
        );
    }

    uploadToS3(file: File): void {
        const evaporateOptions = this.evaporateSettings;

        Evaporate.create(evaporateOptions).then((evaporate: Evaporate) => {
            const name = this.s3Path + uuidv4();

            const addConfig = {
                name,
                file,
                contentType: file.type,
                beforeSigner: (xhr: XMLHttpRequest, url: string) => {
                    if (this.onBeforeSigner) this.onBeforeSigner(name, xhr, url);
                },
                started: (file_key: string) => {
                    if (this.onStarted) this.onStarted(name, file_key);
                },
                uploadInitiated: (s3UploadId?: string) => {
                    if (this.onUploadInitiated) this.onUploadInitiated(name, s3UploadId);
                },
                cancelled: () => {
                    if (this.onCancelled) this.onCancelled(name);
                },
                progress: (progressValue: number, stats: TransferStats) => {
                    if (this.onProgress) this.onProgress(name, file.name, progressValue, stats);
                },
                complete: (xhr: XMLHttpRequest, awsObjectKey: string, stats: TransferStats) => {
                    if (xhr.status === 200 && this.onProgress) {
                        this.onProgress(name, file.name, 100, stats);
                    } else {
                        if (this.onError) this.onError(name, 'Upload failed');
                    }
                },
                error: (message: string) => {
                    if (this.onError) this.onError(name, message);
                },
            } as Evaporate.AddConfig;
            this.evaporate = evaporate;
            evaporate.add(addConfig).then((awsKey: string) => {
                if (this.onComplete) this.onComplete(name, awsKey);
            });
        });
    }
}
