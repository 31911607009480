import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Organization } from './organizations.types';
import { SimplePaginateResponse } from '../../../../app.types';

interface PaginatedApiResponse {
    data: Array<Organization>;
    links: any; // Replace 'any' with the actual type of 'links'
    meta: any; // Replace 'any' with the actual type of 'meta'
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;

interface GetOrganizationsAndSubscriptionDataParams {
    organization_uuid?: string;
    tier?: 'all' | 'no_tier' | 'gold' | 'silver' | 'bronze' | '';
    sort?:
        | 'created_at'
        | '-created_at'
        | 'name'
        | '-name'
        | 'athlete_count'
        | '-athlete_count'
        | 'athlete_pod_subscriptions'
        | '-athlete_pod_subscriptions'
        | 'uploads_this_month'
        | '-uploads_this_month'
        | 'subscription_level'
        | '-subscription_level'
        | 'total_commissions'
        | '-total_commissions'
        | 'marketplace_commissions'
        | '-marketplace_commissions'
        | 'pib_bonus_commissions'
        | '-pib_bonus_commissions'
        | 'residual_commissions'
        | '-residual_commissions'
        | 'check_match_commissions'
        | '-check_match_commissions'
        | 'org_pod_subscriptions'
        | '-org_pod_subscriptions';
    page?: number;
    per_page?: number;
}

const getOrganizationsAndSubscriptionData = (
    params?: GetOrganizationsAndSubscriptionDataParams,
): ReturnTypeCollection => {
    if (params?.tier === 'all') {
        params.tier = '';
    }
    const filterParams = {
        'filter[organization_uuid]': params?.organization_uuid,
        'filter[tier]': params?.tier,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/organizations/dashboard`, { params: filterParams });
};

// New interface and function for advanced filtering
interface AdvancedSearchParams {
    'filter[name]'?: string;
    'filter[tier]'?: string;
    'filter[address.state]'?: string;
    'filter[address.city]'?: string;
    'filter[address.postal_code]'?: string;
    'filter[distance]'?: string;
    perPage?: number;
}

interface SearchOptions {
    name?: string;
    tier?: string;
    state?: string;
    city?: string;
    postalCode?: string;
    distance?: string;
    perPage?: number;
}

const adminGetAllOrganizationsAdvanced = (
    searchOptions: SearchOptions,
): Promise<AxiosResponse<SimplePaginateResponse<Organization>>> => {
    const params: AdvancedSearchParams = {};

    if (searchOptions.name) {
        params['filter[name]'] = searchOptions.name;
    }

    if (searchOptions.tier) {
        if (searchOptions.tier === 'all') {
            searchOptions.tier = '';
        }
        params['filter[tier]'] = searchOptions.tier;
    }

    if (searchOptions.state) {
        params['filter[address.state]'] = searchOptions.state;
    }

    if (searchOptions.city) {
        params['filter[address.city]'] = searchOptions.city;
    }

    if (searchOptions.postalCode) {
        if (searchOptions.distance) {
            params['filter[distance]'] = `${searchOptions.postalCode},${searchOptions.distance}`;
        } else {
            params['filter[address.postal_code]'] = searchOptions.postalCode;
        }
    }

    if (searchOptions.perPage) {
        params.perPage = searchOptions.perPage;
    }

    return Request.get('/admin/organizations', {
        params,
    });
};

export { getOrganizationsAndSubscriptionData, adminGetAllOrganizationsAdvanced };
export type { GetOrganizationsAndSubscriptionDataParams };
