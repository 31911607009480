import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../redux/reducers';
import { resetPassword as apiResetPassword } from '../api/reset';
import { ErrorResponse, ResetPasswordDto } from '../auth.types';

export interface ResetState {
    loading: boolean;
    errorMessage: string;
    errors: ErrorResponse;
}

// actions
export const resetPassword = createAsyncThunk<
    undefined,
    ResetPasswordDto,
    { rejectValue: { message: string; errors: ErrorResponse } }
>('resetPassword/fetch', async (payload, { rejectWithValue }) => {
    try {
        await apiResetPassword(payload);
    } catch (e: any) {
        return rejectWithValue({
            errors: e.response?.data?.errors ?? {},
            message: 'Reset password failed. Link may have expired. Please try again.',
        });
    }
});

// reducers
export const initialState: ResetState = {
    loading: false,
    errorMessage: '',
    errors: {},
};

export const resetSlice = createSlice({
    name: 'reset',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(resetPassword.pending, (state) => {
            state.loading = true;
            state.errorMessage = '';
        });
        addCase(resetPassword.fulfilled, (state) => {
            state.loading = false;
            state.errorMessage = '';
        });
        addCase(resetPassword.rejected, (state, { payload }) => {
            state.loading = false;
            state.errorMessage = payload?.message ?? '';
            state.errors = payload?.errors ?? {};
        });
    },
});

// selectors
export const resetSelector = (state: RootState) => state.authentication.reset;
