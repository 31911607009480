import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generateUuid, GlobalMessage } from 'utils';
import { RootState } from '.';

export interface MessagesError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export interface MessageState {
    isLoading: boolean;
    data: Array<GlobalMessage>;
    error: MessagesError;
}

export const initialState: MessageState = {
    isLoading: false,
    data: [],
    error: { message: '' },
};

export const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        pushMessage: {
            reducer: (state, action: PayloadAction<GlobalMessage>) => {
                state.data.push(action.payload);
            },
            prepare: (message: GlobalMessage) => {
                // Assigning a unique UUID to each global message
                // So we can use that remove message from message state slice
                // When user sees the message on frontend
                message.uuid = generateUuid();
                return { payload: message };
            },
        },
        markMessageAsSeen: (state, action: PayloadAction<GlobalMessage>) => {
            state.data = state.data.filter((message) => action.payload.uuid != message.uuid);
        },
        markAllMessagesAsSeen: (state) => {
            state.data = [];
        },
    },
});

export const { pushMessage, markMessageAsSeen, markAllMessagesAsSeen } = messageSlice.actions;

export const messageSelector = (state: RootState) => state.messages;
