import React, { ReactChildren, ReactNode } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
    waitText?: null | string;
    open: boolean;
    fullScreen?: boolean;
    children?: ReactNode | ReactChildren | null;
}

const WaitingLoaderOverlay = ({ waitText, open, children, fullScreen }: Props) => {
    return (
        <Box
            sx={{
                position: open && !fullScreen ? 'relative' : 'initial',
            }}
        >
            <>
                <Backdrop
                    open={open}
                    sx={{
                        position: !fullScreen ? 'absolute' : 'fixed',
                        zIndex: !fullScreen ? 9999 : 'auto',
                    }}
                >
                    {waitText && (
                        <Box
                            sx={{
                                position: 'absolute',
                                marginBottom: (theme) => theme.spacing(50),
                            }}
                        >
                            {waitText}
                        </Box>
                    )}
                    <CircularProgress color="inherit" />
                </Backdrop>
                {children}
            </>
        </Box>
    );
};

export default WaitingLoaderOverlay;
