import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import { Box, Button, debounce } from '@mui/material';
import { getActivity } from 'api';
import { AskToPostToSocialDialog, EditLogForm } from 'components';
import { confirmViaDialog } from 'components/Dialogs/ConfirmationDialog';
import { EditLogRefProps } from 'components/Forms/EditLogForm';
import LogDomain from 'domain/LogDomain';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { Activity, Log, somethingWentWrong } from 'utils';
import markedSetIcon from '../../../src/images/MarkedSet1.png';
import skippedSetIcon from '../../../src/images/SkippedSet.png';
import ButtonSaveLoader from '../../components/Buttons/ButtonSaveLoader';
import { postToFeed } from '../../redux/reducers/social/my-feed';
import { userSelector } from '../../redux/reducers/user';

const LogSession = () => {
    let { id: activityUuid } = useParams<{ id: string }>();
    const [activity, setActivity] = useState<Activity | null>(null);
    const [logs, setLogs] = useState<Log[]>([]);
    const editLogFormRef = useRef<EditLogRefProps>();
    const { userData } = useSelector(userSelector);
    const [saving, setSaving] = useState<boolean>(false);
    const [postingToSocial, setPostingToSocial] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { push } = useHistory();

    const updateActivity = debounce((payload: { notes?: string; date?: string | null }) => {
        setActivity((activity) => {
            if (activity) {
                return {
                    ...activity,
                    ...payload,
                    logs: activity.logs,
                };
            }
            return activity;
        });
        LogDomain.updateActivity(activity as Activity, payload);
    }, 500);

    // Lo the activity session from the database and pass to the form component
    useEffect(() => {
        if (!activity) {
            getActivity(activityUuid).then((response) => {
                setActivity(response.data as Activity);
                setLoaded(true);
            });
        }
    }, [activityUuid, activity]);

    const [socialDialogOpen, setSocialDialogOpen] = useState(false);

    const onPostedToSocial = async () => {
        setPostingToSocial(true);
        await dispatch(postToFeed({ activity: activity?.uuid ?? '', index: 0 }));
        setSocialDialogOpen(false);
        setPostingToSocial(false);
        push('/social/my-posts');
    };

    // While ending the log session we need to first check if
    // there are any sets which are incomplete (Not marked as complete by the user)
    // If yes, then we need to show a warning to confirm
    // If there are no incomplete sets, then there is no warning
    const endSession = async () => {
        // Session does not have any incomplete sets
        if (!editLogFormRef.current?.hasIncompleteSets()) {
            endSessionConfirmed();
            return;
        }
        // Confirm and if user cancels the confirmation, save it instead of ending the log
        if (
            await confirmViaDialog({
                confirmation: {
                    title: 'Skip Unmarked Sets?',
                    message: (
                        <>
                            <p>Gold check indicates a marked set, outline represents skipped</p>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img
                                    src={markedSetIcon}
                                    alt="markedSetIcon"
                                    style={{ marginRight: '10px' }}
                                />
                                <img src={skippedSetIcon} alt="skippedSetIcon" />
                            </div>
                        </>
                    ),
                    okButtonText: 'Yes',
                    cancelButtonText: 'No',
                },
            })
        ) {
            endSessionConfirmed();
        }
    };

    const endSessionConfirmed = () => {
        setSaving(true);
        editLogFormRef.current
            ?.onLogEnded()
            ?.then(() => {
                setSaving(false);
                if (activity?.individual?.managing_user_uuid === userData?.uuid) {
                    setSocialDialogOpen(true);
                } else {
                    push('/train/sessions');
                }
            })
            .catch((error) => {
                setSaving(false);
                somethingWentWrong(error.response?.data?.message);
            }) || setSaving(false);
    };

    return (
        <>
            <AskToPostToSocialDialog
                open={socialDialogOpen}
                onClose={() => {
                    setSocialDialogOpen(false);
                    push('/train/sessions');
                }}
                loading={postingToSocial}
                onPost={() => onPostedToSocial()}
            />
            <FixedLayout
                disableBackButton={true}
                displayLeftActions={
                    <Button
                        variant={
                            logs.filter((l) => !l.marked_as_complete).length > 0
                                ? 'contained'
                                : 'contained'
                        }
                        color="primary"
                        disabled={saving}
                        sx={{ minHeight: 55, topMargin: 10 }}
                        onClick={() => editLogFormRef.current?.onAllActivityLogsMarkedAsComplete()}
                    >
                        {logs.filter((l) => l.marked_as_complete).length === logs.length &&
                        loaded ? (
                            <>
                                All <CheckCircle color="secondary" style={{ fontSize: 15 }} />{' '}
                                &apos; d
                            </>
                        ) : (
                            <>
                                <CheckCircleOutline /> All
                            </>
                        )}
                    </Button>
                }
                title={activity?.session?.title ?? 'Loading'}
                titleActions={
                    <>
                        {activity && (
                            <Box display="flex">
                                <ButtonSaveLoader
                                    variant="contained"
                                    color="primary"
                                    sx={{ minHeight: 55, topMargin: 10 }}
                                    disabled={saving}
                                    onClick={() => endSession()}
                                >
                                    {saving ? 'saving' : 'Finish'}
                                </ButtonSaveLoader>
                            </Box>
                        )}
                    </>
                }
                content={
                    activity ? (
                        <EditLogForm
                            onLoading={(loading) => setSaving(loading)}
                            onFinishClick={() => endSession()}
                            finishing={saving}
                            activity={activity}
                            activityChanged={(payload) => updateActivity(payload)}
                            ref={editLogFormRef}
                            onLogsChanged={(logs) => setLogs(logs)}
                        />
                    ) : (
                        <></>
                    )
                }
            />
        </>
    );
};

export default LogSession;
