import { OpenInNew } from '@mui/icons-material';
import { Avatar, Box, Button, Paper } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridRenderCellParams,
    GridRowParams,
    GridSortItem,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { PaginationLink } from 'utils';
import CustomDateRangePicker from '../../../components/FormControl/CustomDateRangePicker';
import CustomPagination from '../../../components/Functional/CustomPagination';
import CheckboxFilterList from '../../../components/Inputs/CheckboxFilterList';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import { useNonInitialEffect } from '../../../hooks';
import DimensionFilterBuilder from '../components/DimensionFilterBuilder';
import { getTimeSegments } from '../home.api';

function TimeSegmentDashboard() {
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any>([]);
    const [links, setLinks] = useState<PaginationLink>();
    const [dateRangeValue, setDateRangeValue] = useState<DateRange<DateTime>>([null, null]);
    const [individualFilters, setIndividualFilters] = useState<{ [key: string]: any }>({});
    const [groupFilters] = useState<{ [key: string]: any }>({});
    let { push } = useHistory();

    const savedQueryOptions = localStorage.getItem('segmentsQueryOptions');

    const initialQueryOptions = savedQueryOptions
        ? JSON.parse(savedQueryOptions)
        : {
              limit: 100,
              page: 1,
              'filter[duration]': '1',
              'filter[time_segment_name]': ['0-1'],
          };

    const [queryOptions, setQueryOptions] = useState<{
        sort?: string[];
        'filter[individual_uuid]'?: string;
        'filter[group_uuid]'?: string;
        'filter[after]'?: string;
        'filter[before]'?: string;
        'filter[duration]'?: string;
        'filter[load]'?: string[];
        'filter[time_segment_name]'?: string[];
        'filter[organizations]'?: string[];
        limit: number;
        page: number;
    }>(initialQueryOptions);

    useEffect(() => {
        localStorage.setItem('queryOptions', JSON.stringify(queryOptions));
    }, [queryOptions]);

    const [debouncedQueryOptions] = useDebounce(queryOptions, 700);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setQueryOptions((o) => ({
            ...o,
            sort: sortModel.map((item: GridSortItem) => `${item.field},${item.sort}`),
        }));
    }, []);

    const handleIndividualsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                'filter[individual_uuid]': value.join(','),
            });
        },
        [queryOptions],
    );

    const handleGroupsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                'filter[group_uuid]': value.length > 0 ? value.join(',') : undefined,
            });
            setIndividualFilters((filters) => ({
                ...filters,
                'filter[group_uuid]': value.length > 0 ? value.join(',') : undefined,
            }));
        },
        [queryOptions],
    );

    useNonInitialEffect(() => {
        let rangeQuery = {} as any;
        if (dateRangeValue[0]) {
            rangeQuery['filter[after]'] = dateRangeValue[0]?.toISODate();
        }
        if (dateRangeValue[1]) {
            rangeQuery['filter[before]'] = dateRangeValue[1]?.toISODate();
        }
        setQueryOptions((options) => {
            delete options['filter[after]'];
            delete options['filter[before]'];
            return {
                ...options,
                ...rangeQuery,
            };
        });
    }, [dateRangeValue]);

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'individual_name',
                headerName: 'Athlete',
                minWidth: 180,
                filterable: false,
                sortable: true,
            },
            {
                field: 'organization_logo',
                headerName: 'Organization',
                width: 100,
                filterable: false,
                sortable: false,
                renderCell: (params: GridRenderCellParams<any, string>) => (
                    <Avatar src={params.value} sx={{ width: 40, height: 40 }} />
                ),
            },
            {
                field: 'load',
                headerName: 'Load',
                minWidth: 120,
                filterable: false,
                sortable: true,
            },
            {
                field: 'full_date',
                headerName: 'Date',
                minWidth: 120,
                filterable: false,
                sortable: true,
            },
            {
                field: 'time_segment_name',
                headerName: 'Time Segment',
                minWidth: 120,
                filterable: true,
                sortable: true,
            },
            {
                field: 'distance_ft',
                headerName: 'Distance (ft)',
                minWidth: 140,
                filterable: false,
                sortable: true,
            },
            {
                field: 'average_velocity_mph',
                headerName: 'Average Velocity (mph)',
                minWidth: 170,
                filterable: false,
                sortable: true,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'View',
                getActions: (params: GridRowParams) => [
                    <GridActionsCellItem
                        key={'view-button'}
                        icon={<OpenInNew />}
                        onClick={() => {
                            push(
                                `/community/individuals/${params.row.individual_uuid}/performance/${params.row.upload_uuid}/sprints/${params.row.sprint_uuid}`,
                            );
                        }}
                        label={'View'}
                    />,
                ],
            },
        ],
        [push],
    );

    const getSegmentOptions = (duration: string | undefined) => {
        if (duration === '2')
            return [
                { label: '0-2s', value: '0-2' },
                { label: '2-4s', value: '2-4' },
                { label: '4-6s', value: '4-6' },
                { label: '6-8s', value: '6-8' },
                { label: '8-10s', value: '8-10' },
            ];
        return [
            { label: '0-1s', value: '0-1' },
            { label: '1-2s', value: '1-2' },
            { label: '2-3s', value: '2-3' },
            { label: '3-4s', value: '3-4' },
            { label: '4-5s', value: '4-5' },
            { label: '5-6s', value: '5-6' },
            { label: '6-7s', value: '6-7' },
            { label: '7-8s', value: '7-8' },
            { label: '8-9s', value: '8-9' },
            { label: '9-10s', value: '9-10' },
        ];
    };

    useEffect(() => {
        setLoading(true);
        getTimeSegments(debouncedQueryOptions)
            .then((response) => {
                setRows(
                    response.data.data.map((r: any) => ({
                        ...r,
                        distance_yds: (r.distance_meters * 1.09361).toFixed(2),
                        cumulative_distance_yds: (r.cumulative_distance_meters * 1.09361).toFixed(
                            2,
                        ),
                        average_velocity_yds_per_sec: (r.average_velocity * 1.09361).toFixed(2),
                        distance_ft: (r.distance_meters * 3.28084).toFixed(2),
                        cumulative_distance_ft: (r.cumulative_distance_meters * 3.28084).toFixed(2),
                        average_velocity_ft_per_sec: (r.average_velocity * 3.28084).toFixed(2),
                        average_velocity_mph: (r.average_velocity * 2.23694).toFixed(2),
                    })),
                );
                setLinks(response.data.links);
            })
            .catch(() => {})
            .finally(() => setLoading(false));
    }, [debouncedQueryOptions]);

    const { organizations } = useContext(OrganizationsContext);

    const [resetKey, setResetKey] = useState<number>(0);

    const resetFilters = () => {
        const defaultOptions = {
            limit: 100,
            page: 1,
            'filter[duration]': '1',
            'filter[time_segment_name]': ['0-1'],
        };

        localStorage.setItem('queryOptions', JSON.stringify(defaultOptions));
        setQueryOptions(defaultOptions);
        setDateRangeValue([null, null]);
        setIndividualFilters({});
        setResetKey((prevKey) => prevKey + 1); // Increment the resetKey
    };

    return (
        <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ marginY: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Box sx={{ marginRight: 1, spacing: 1 }}>
                    <DimensionFilterBuilder
                        id={'filter-list-groups'}
                        resourcePath="groups"
                        buttonText="Group(s)"
                        onChange={handleGroupsFilterChanged}
                        otherFilters={groupFilters}
                        key={`group-filter-${resetKey}`}
                    />
                </Box>
                <Box sx={{ marginRight: 1, spacing: 1 }}>
                    <DimensionFilterBuilder
                        id={'filter-list-individuals'}
                        resourcePath="individuals"
                        buttonText="Athletes"
                        onChange={handleIndividualsFilterChanged}
                        otherFilters={individualFilters}
                        key={`individual-filter-${resetKey}`}
                    />
                </Box>
                <Box sx={{ marginRight: 1, spacing: 1 }}>
                    <CheckboxFilterList
                        buttonText={'Organizations'}
                        onChange={(v) =>
                            setQueryOptions((f) => ({
                                ...f,
                                'filter[organizations]': v as string[],
                            }))
                        }
                        options={organizations.map((o) => ({
                            label: o.name,
                            value: o.uuid,
                            avatar_url: o.logo_url,
                        }))}
                        value={queryOptions['filter[organizations]'] ?? []}
                        key={`organization-filter-${resetKey}`}
                    />
                </Box>
                <Box sx={{ marginRight: 1, spacing: 1 }}>
                    <CheckboxFilterList
                        buttonText={'Load'}
                        onChange={(v) =>
                            setQueryOptions((f) => ({
                                ...f,
                                'filter[load]': v as string[],
                            }))
                        }
                        options={[
                            { label: '-7kg', value: '-7' },
                            { label: '-5kg', value: '-5' },
                            { label: '-3kg', value: '-3' },
                            { label: '0kg', value: '0' },
                            { label: '1kg', value: '1' },
                            { label: '3kg', value: '3' },
                            { label: '5kg', value: '5' },
                            { label: '7kg', value: '7' },
                            { label: '15kg', value: '15' },
                            { label: '30kg', value: '30' },
                        ]}
                        value={queryOptions['filter[load]'] ?? []}
                        key={`load-filter-${resetKey}`}
                    />
                </Box>
                <Box sx={{ marginRight: 1, spacing: 1 }}>
                    <CheckboxFilterList
                        buttonText={'Segment'}
                        onChange={(v) =>
                            setQueryOptions((f) => ({
                                ...f,
                                'filter[time_segment_name]': v as string[],
                            }))
                        }
                        options={getSegmentOptions(queryOptions['filter[duration]'])}
                        value={queryOptions['filter[time_segment_name]'] ?? []}
                        key={`time_segment_name-filter-${resetKey}`}
                    />
                </Box>
                <Button
                    sx={{ marginX: 1, spacing: 1 }}
                    onClick={() =>
                        setQueryOptions((f) => ({
                            ...f,
                            'filter[time_segment_name]': undefined,
                            'filter[duration]': '1',
                        }))
                    }
                    variant={queryOptions['filter[duration]'] === '1' ? 'contained' : 'outlined'}
                >
                    1s
                </Button>
                <Button
                    onClick={() =>
                        setQueryOptions((f) => ({
                            ...f,
                            'filter[time_segment_name]': undefined,
                            'filter[duration]': '2',
                        }))
                    }
                    variant={queryOptions['filter[duration]'] === '2' ? 'contained' : 'outlined'}
                >
                    2s
                </Button>
                <Box sx={{ marginRight: 1, spacing: 1 }}>
                    <CustomDateRangePicker
                        value={dateRangeValue}
                        onChange={(newValue) => {
                            setDateRangeValue(newValue);
                        }}
                    />
                </Box>
                <Box sx={{ marginRight: 1, spacing: 1 }}>
                    <Button
                        onClick={resetFilters}
                        variant={
                            Object.values(queryOptions).some(
                                (f) => f !== null && f !== '' && f !== undefined,
                            )
                                ? 'contained'
                                : 'outlined'
                        }
                        color="primary"
                    >
                        Reset Filters
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    width: '100%',
                }}
            >
                <DataGridPremium
                    sx={{
                        backgroundColor: '#ffffff',
                        boxShadow: { lg: 3 },
                        borderRadius: { lg: 3 },
                        padding: { lg: 2 },
                        '.MuiDataGrid-virtualScrollerContent': {
                            height: '100%!important',
                        },
                    }}
                    autoHeight={false}
                    density="compact"
                    rows={rows}
                    columns={columns}
                    initialState={{ pinnedColumns: { left: ['individual_name'] } }}
                    sortingMode="server"
                    loading={loading}
                    disableRowSelectionOnClick
                    pagination={false}
                    slots={{
                        footer: CustomPagination,
                        loadingOverlay: CustomLoadingOverlay,
                    }}
                    slotProps={{
                        footer: {
                            hasNext: (links && links.next !== null) ?? false,
                            hasPrevious: queryOptions.page > 1,
                            onNext: () => setQueryOptions((o) => ({ ...o, page: o.page + 1 })),
                            onPrevious: () => setQueryOptions((o) => ({ ...o, page: o.page - 1 })),
                            page: queryOptions.page,
                        },
                    }}
                    onSortModelChange={handleSortModelChange}
                />
            </Box>
        </Paper>
    );
}

export default React.memo(TimeSegmentDashboard);
