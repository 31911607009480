import { AxiosResponse } from 'axios';
import { Integration, SqlUser, StripeAccount } from '../app.types';
import { Request } from './index';
/*Get all integrations for 1080*/
export const getAllSqlUsers = (organizationUuid: string): Promise<AxiosResponse<SqlUser[]>> => {
    return Request.get(`/organizations/${organizationUuid}/sql-users`);
};

export const deleteSqlUser = (
    organizationUuid: string,
    sqlUserUuid: string,
): Promise<AxiosResponse<void>> => {
    return Request.delete(`/organizations/${organizationUuid}/sql-users/${sqlUserUuid}`);
};

export const createSqlUser = (
    organizationUuid: string,
    payload: {
        user_name: string;
    },
): Promise<AxiosResponse<SqlUser>> => {
    return Request.post(`/organizations/${organizationUuid}/sql-users`, payload);
};

export const createStripeExpressAccount = (
    organizationUuid: string,
): Promise<AxiosResponse<Integration>> => {
    return Request.post(`/organizations/${organizationUuid}/integrations/stripe-express`);
};

export const createStripeAccountLink = (
    organizationUuid: string,
): Promise<AxiosResponse<{ url: string }>> => {
    return Request.post(`/organizations/${organizationUuid}/integrations/stripe-account-link`);
};

export const createStripeLoginLink = (
    organizationUuid: string,
): Promise<AxiosResponse<{ url: string }>> => {
    return Request.post(`/organizations/${organizationUuid}/integrations/stripe-login-link`);
};

export const getStripeExpressAccount = (
    organizationUuid: string,
): Promise<AxiosResponse<StripeAccount>> => {
    return Request.get(`/organizations/${organizationUuid}/integrations/stripe-express-account`);
};
