import { Meta } from '@cubejs-client/core';
import { Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    LinearProgress,
    Link,
    Paper,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { debounce } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getTags } from '../../../api/tags.api';
import { cubejsApi } from '../../../app.functions';
import { Tag } from '../../../app.types';
import { NextPrevPagination, SideDrawerWithTitleAndActions } from '../../../components';
import { OrganizationFilterList } from '../../../components/Filters';
import { AbilityContext } from '../../../components/Functional/Can';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import { Meta as PageMeta, Organization, PaginationLink } from '../../../utils';
import { getAllSavedFilters } from '../api/savedFilters.api';
import { mapTagIntoChipChoice, mapTagsIntoChipChoices } from '../community.func';
import { renderOrganizationMenuItem } from '../community.render';
import { SavedFilter, SavedFilterType } from '../community.types';
import ChartGradientPaper from '../components/Charting/ChartGradientPaperContainer';
import MultiChipList from '../components/MultiChipList';
import TimeseriesReport from '../components/TimeseriesReport';

function CommunityReportsViewAllCharts() {
    const [filters, setFilters] = useState<{
        'filter[title]'?: string;
        'filter[tag_id]': string[];
        'filter[organization_uuid]'?: string | null;
        limit?: number;
        page?: number;
    }>({
        'filter[organization_uuid]': null,
        'filter[tag_id]': [],
    });

    const { organizations } = useContext(OrganizationsContext);
    const [charts, setCharts] = useState<SavedFilter[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [pageMeta, setPageMeta] = useState<PageMeta>();
    const [pageLinks, setPageLinks] = useState<PaginationLink>();
    const [chartsLoading, setChartsLoading] = useState(false);
    const [chartCreateOpen, setChartCreateOpen] = useState(false);
    const [chartCreateValue, setChartCreateValue] = useState<{
        type: SavedFilterType;
        organization: string;
    }>({ type: 'community.activity', organization: '' });
    const [cubeMeta, setCubeMeta] = useState<Meta>();
    const theme = useTheme();
    const [cookie] = useCookies(['favOrg']);
    const ability = useContext(AbilityContext);

    useEffect(() => {
        cubejsApi
            .meta()
            .then((result: Meta) => {
                setCubeMeta(result);
            })
            .catch(() => {});

        getTags({ 'filter[type]': 'reports' })
            .then((response) => {
                setTags(response.data);
            })
            .catch(() => {});
    }, []);
    let { push } = useHistory();
    const match = useRouteMatch();

    async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        debouncedSearch(e.target.value as string);
    }

    const debouncedSearch = React.useRef(
        debounce((value: string) => {
            setFilters((f) => ({ ...f, page: 1, 'filter[title]': value as string }));
        }, 500),
    ).current;
    const loadCharts = useCallback((params = {}) => {
        setChartsLoading(true);
        getAllSavedFilters(params)
            .then((response) => {
                setCharts(response.data.data);
                setPageMeta(response.data.meta);
                setPageLinks(response.data.links);
            })
            .catch(() => {})
            .finally(() => setChartsLoading(false));
    }, []);

    useEffect(() => {
        loadCharts(filters);
    }, [filters, loadCharts]);

    useEffect(() => {
        if (organizations.length > 0) {
            const ix = organizations.findIndex((o) => `${o.name}${o.uuid}` === cookie.favOrg);
            if (cookie.favOrg && ix > -1) {
                setChartCreateValue((v) => ({ ...v, organization: organizations[ix].uuid }));
            } else {
                setChartCreateValue((v) => ({ ...v, organization: organizations[0].uuid }));
            }
        }
    }, [organizations, cookie.favOrg]);

    function handleOrganizationChanged(organization: Organization) {
        if (organization.uuid === filters['filter[organization_uuid]']) {
            setFilters({ ...filters, page: 1, 'filter[organization_uuid]': null });

            return;
        }

        if (organization) {
            setFilters(() => ({
                ...filters,
                'filter[organization_uuid]': organization.uuid,
                page: 1,
            }));
            setChartCreateValue((v) => ({ ...v, organization: organization.uuid }));
        }
    }

    return (
        <>
            {chartsLoading && <LinearProgress />}
            <Container maxWidth={'xl'}>
                <Paper elevation={4} style={{ margin: 12, padding: 16 }}>
                    <Breadcrumbs
                        style={{
                            paddingBottom: 12,
                            borderBottom: '1px solid',
                            borderBottomColor: grey[300],
                            marginBottom: 12,
                        }}
                        aria-label="breadcrumb"
                    >
                        <Link color="inherit" href="/community/reports">
                            Community Reports
                        </Link>
                        <Typography color="textPrimary">View All Reports</Typography>
                    </Breadcrumbs>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={5}>
                            <OrganizationFilterList
                                organizations={organizations}
                                onClicked={handleOrganizationChanged}
                                selectedOrganization={filters['filter[organization_uuid]'] ?? false}
                            />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <MultiChipList
                                value={tags
                                    .filter(
                                        (t) =>
                                            filters['filter[tag_id]'].findIndex(
                                                (tagInFilter) => tagInFilter === t.id,
                                            ) > -1,
                                    )
                                    .map(mapTagIntoChipChoice)}
                                choices={mapTagsIntoChipChoices(tags)}
                                onChange={(v) =>
                                    setFilters({
                                        ...filters,
                                        page: 1,
                                        'filter[tag_id]': v.map((c) => c.id),
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={3} md={4}>
                            <TextField
                                id="search-text"
                                label="Search"
                                type={'text'}
                                onChange={handleChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box
                        padding={16}
                        minHeight={275}
                        display="flex"
                        justifyContent="center"
                        flexWrap="wrap"
                    >
                        <Card
                            elevation={4}
                            style={{
                                width: 325,
                                margin: 12,
                                borderColor: theme.palette.primary.main,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                borderRadius: 20,
                            }}
                        >
                            <CardActionArea onClick={() => setChartCreateOpen(true)}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    height={168}
                                    padding={20}
                                    style={{
                                        backgroundColor: '#D9DADF',
                                        background:
                                            'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                    }}
                                >
                                    <AddIcon style={{ fontSize: 80 }} />
                                </Box>
                                <CardContent style={{ height: 168 }}>
                                    <Typography gutterBottom variant="h6" component="div">
                                        Create New Report
                                    </Typography>
                                    <Typography variant="body2">
                                        Many reports can be added to a single dashboard
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        {charts.map((chart) => (
                            <Card
                                key={chart.uuid}
                                elevation={4}
                                style={{ width: 325, margin: 12, borderRadius: 20 }}
                            >
                                <CardActionArea
                                    onClick={() => push(`${match.url}/charts/${chart.uuid}`)}
                                >
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        height={168}
                                        width={'100%'}
                                    >
                                        {cubeMeta && (
                                            <ChartGradientPaper
                                                height={'auto'}
                                                borderRadius={'20px 20px 0px 0px'}
                                            >
                                                <TimeseriesReport
                                                    cubeMeta={cubeMeta}
                                                    filters={chart.filters}
                                                    limit={100}
                                                    width={'100%'}
                                                    height={125}
                                                    hideLegend={true}
                                                    hideTooltip={true}
                                                    hideYAxis={true}
                                                    hideXAxis={true}
                                                    plotMissingOnChart={false}
                                                    pieOptions={{ legendType: 'none' }}
                                                    type={chart.type}
                                                    simplifiedView={true}
                                                />
                                            </ChartGradientPaper>
                                        )}
                                    </Box>
                                    <CardContent
                                        style={{
                                            height: 200,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Box sx={{ flexGrow: 1 }}>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {chart.title}
                                            </Typography>
                                            <Typography variant="body2" noWrap={true}>
                                                {chart.short_description}
                                            </Typography>
                                            <Box>
                                                {chart.tag && <Chip label={chart.tag.name} />}
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'flex-end',
                                                margin: 5,
                                            }}
                                        >
                                            <Avatar
                                                src={chart.organization.image_urls['avatar'] ?? ''}
                                                alt={chart.organization.name}
                                                variant="rounded"
                                                sx={{ marginRight: 3 }}
                                            />
                                        </Box>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        ))}
                        <NextPrevPagination
                            onNext={() =>
                                loadCharts({ ...filters, page: (pageMeta?.current_page ?? 1) + 1 })
                            }
                            onPrev={() =>
                                loadCharts({ ...filters, page: (pageMeta?.current_page ?? 1) - 1 })
                            }
                            disablePrev={!pageLinks?.prev || chartsLoading}
                            disableNext={!pageLinks?.next || chartsLoading}
                        />
                    </Box>
                </Paper>
                <SideDrawerWithTitleAndActions
                    open={chartCreateOpen}
                    onClose={() => setChartCreateOpen(false)}
                    body={
                        <Stack spacing={4}>
                            <FormControl sx={{ width: '100%' }}>
                                <RadioGroup
                                    aria-labelledby="report-type-label"
                                    defaultValue="community.activity"
                                    name="report-type"
                                    value={chartCreateValue['type']}
                                    onChange={(e) =>
                                        setChartCreateValue({
                                            ...chartCreateValue,
                                            type: e.target.value as SavedFilterType,
                                        })
                                    }
                                >
                                    <FormControlLabel
                                        value="community.activity"
                                        control={<Radio />}
                                        label={
                                            <>
                                                <Box>Training Logs</Box>
                                                <Typography sx={{ color: grey[700], fontSize: 14 }}>
                                                    Analyze logs recorded through the 1tul.com
                                                    system
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <FormControlLabel
                                        value="community.upload"
                                        control={<Radio />}
                                        label={
                                            <>
                                                <Box>Sprint Profiling Database (Entire Sprint)</Box>
                                                <Typography sx={{ color: grey[700], fontSize: 14 }}>
                                                    Visualize Your Sprint Database
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <FormControlLabel
                                        value="community.upload.time.segments"
                                        control={<Radio />}
                                        label={
                                            <>
                                                <Box>Sprint Profiling Database (Time Segments)</Box>
                                                <Typography sx={{ color: grey[700], fontSize: 14 }}>
                                                    Visualize your sprint database for time
                                                    segments. e.g 0-1s,1-2s,2-3s
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <FormControlLabel
                                        value="community.tem"
                                        control={<Radio />}
                                        label={
                                            <>
                                                <Box>1080 Motion Capture</Box>
                                                <Typography sx={{ color: grey[700], fontSize: 14 }}>
                                                    Analyze 1080 specific data
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <FormControlLabel
                                        value="community.group.gps"
                                        control={<Radio />}
                                        label={
                                            <>
                                                <Box>GPS Group Summary</Box>
                                                <Typography sx={{ color: grey[700], fontSize: 14 }}>
                                                    Analyze GPS summary data imported into 1Tul
                                                </Typography>
                                            </>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel>Organization</InputLabel>
                                <Select
                                    id="organization"
                                    label="Organization"
                                    value={chartCreateValue['organization']}
                                    onChange={(e: SelectChangeEvent) =>
                                        setChartCreateValue({
                                            ...chartCreateValue,
                                            organization: e.target.value as string,
                                        })
                                    }
                                >
                                    {organizations
                                        .filter((o) =>
                                            ability.can('organization:create-saved-filters', o),
                                        )
                                        .map((o) => renderOrganizationMenuItem(o))}
                                </Select>
                            </FormControl>
                            <Typography variant={'body1'} padding={10} marginTop={10}>
                                Reports from multiple databases can be combined into a single
                                dashboard view
                            </Typography>
                        </Stack>
                    }
                    title={
                        <>
                            <Typography variant={'h6'}>
                                Which Database would you like to pull the report from?
                            </Typography>
                        </>
                    }
                    onSubmit={() =>
                        push(
                            `${match.url}/charts/create/${chartCreateValue['type']}?organization=${chartCreateValue['organization']}`,
                        )
                    }
                    disabled={false}
                    loading={false}
                    submitButtonText={'Next'}
                />
            </Container>
        </>
    );
}

export default CommunityReportsViewAllCharts;
