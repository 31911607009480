import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { importMembers } from 'api';
import React, { ReactElement, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from 'redux/reducers/auth';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import { Group } from 'utils';
import S3Upload from 'utils/S3Upload';
import useTheme from '@mui/system/useTheme';

interface MemberImportDialogProps {
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
    group: Group;
}

export type ColumnDetail = {
    columnName: String;
    description: String;
    isRequired: String;
    availableValues?: String[] | undefined;
};

export default function MemberImportDialog({
    open,
    onClose,
    onSuccess,
    group,
}: MemberImportDialogProps): ReactElement {
    const dispatch = useAppDispatch();
    const { currentUser } = useSelector(authSelector);
    const inputElement = useRef<HTMLInputElement | null>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [failedOnce, setFailedOnce] = useState<boolean>(false);
    const [importErrors, setImportErrors] = useState<String[]>([]);
    const theme = useTheme();
    const importMembersViaCSV = (csvPath: string) => {
        setProcessing(true);
        importMembers(group, csvPath)
            .then(() => {
                dispatch(
                    pushMessage({
                        status: 'success',
                        message: `Successfully initiated the user import to ${group.name}. You can revisit group after some time to see the new users.`,
                    }),
                );
                setUploading(false);
                setProcessing(false);
                setFailedOnce(false);
                onSuccess();
            })
            .catch((error) => {
                setFailedOnce(true);

                if (!error.response.data.import_errors) {
                    setImportErrors(['Something went wrong, please try again']);
                    return;
                }

                if (error.response.data.import_errors.length > 0) {
                    setImportErrors(error.response.data.import_errors);
                }
            })
            .finally(() => {
                setUploading(false);
                setProcessing(false);
                if (inputElement.current) {
                    inputElement.current.value = '';
                }
            });
    };

    // Uploading file to s3 using multi-part uploads
    const uploadToS3 = (csvFile: File) => {
        const uploader = new S3Upload();

        uploader.onStarted = () => {
            setUploading(true);
        };

        uploader.onBeforeSigner = (fileName, xhr) => {
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', `Bearer ${currentUser?.accessToken}`);
        };

        uploader.onComplete = (_1, awsObjectKey) => {
            setUploading(false);
            importMembersViaCSV(awsObjectKey);
        };

        uploader.uploadToS3(csvFile as File);
    };

    return (
        <Dialog
            onClose={() => {
                if (!uploading && !processing) {
                    onClose();
                }
            }}
            open={open}
            aria-labelledby="invite-individual"
        >
            <DialogTitle id="member-import-dialog-title" sx={{ padding: theme.spacing(6, 12, 0) }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Import from file</Typography>
                    {!uploading && !processing && (
                        <IconButton
                            aria-label="close"
                            onClick={() => {
                                onClose();
                            }}
                            size="large"
                        >
                            <Close />
                        </IconButton>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent sx={{ padding: theme.spacing(6, 12, 0) }}>
                <Box mb={5} textAlign="center">
                    <Typography variant="caption" color="textSecondary">
                        Import multiple players from CSV template to {group.name}
                    </Typography>
                </Box>
                {!uploading && !processing && (
                    <Box mb={5} display="flex" alignItems="center" justifyContent="space-around">
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (inputElement.current) {
                                    inputElement.current.click();
                                }
                            }}
                        >
                            {failedOnce ? `Retry Upload` : `Upload Template`}
                        </Button>
                        {!failedOnce && (
                            <Button
                                size="small"
                                variant="outlined"
                                href={`${process.env.PUBLIC_URL}/import_templates/User Upload Template.csv`}
                                target="_blank"
                                download
                            >
                                Download Template
                            </Button>
                        )}
                    </Box>
                )}

                {uploading && (
                    <Box textAlign="center" mb={5}>
                        <Typography variant="caption" color="textSecondary">
                            Uploading...
                        </Typography>
                        <LinearProgress />
                    </Box>
                )}
                {processing && (
                    <Box textAlign="center" mb={5}>
                        <Typography variant="caption" color="textSecondary">
                            Initiating the import in background...
                        </Typography>
                        <LinearProgress />
                    </Box>
                )}
                {importErrors.length > 0 && (
                    <Box textAlign="center" mb={5}>
                        <Typography variant="body2" color="textSecondary">
                            <b>Upload Failed</b>
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            Please update CSV with following corrections and re-upload the CSV
                            template.
                        </Typography>
                        <Box mt={5} textAlign="left">
                            {importErrors.map((importError, index) => (
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    key={`import-error-${index}`}
                                    component="div"
                                >
                                    {index + 1}. {importError}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                )}
                <input
                    type="file"
                    accept="text/csv"
                    hidden
                    ref={inputElement}
                    onChange={(event) => {
                        if (event.target.files && event.target.files.length > 0) {
                            uploadToS3(event.target.files[0]);
                        }
                        setImportErrors([]);
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}
