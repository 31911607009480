import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const HashTagIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
            <path
                d="M16 6V4H12V0H10V4H6V0H4V4H0V6H4V10H0V12H4V16H6V12H10V16H12V12H16V10H12V6H16ZM10 10H6V6H10V10Z"
                fill="black"
                fillOpacity="0.54"
            />
        </SvgIcon>
    );
};

export default HashTagIcon;
