import { Box } from '@mui/material';
import { RouteProps } from 'react-router-dom';

type RouteNames =
    | 'AcceptInvite'
    | 'Login'
    | 'Profile'
    | 'Register'
    | 'ForgotPassword'
    | 'RestorePassword'
    | 'LandingPage'
    | 'NotFound'
    | 'Unauthorized'
    | 'Redirecting'
    | 'CreateOrganization'
    | 'EditOrganization'
    | 'TrainDashboard'
    | 'ClassAssessments'
    | 'StartAssessmentForSelf'
    | 'ListOfSessions'
    | 'ListOfMovements'
    | 'AddQuickSession'
    | 'ForgotUsername'
    | 'Assessments'
    | 'AssessmentResult'
    | 'AssessmentSubmissionPreview'
    | 'ViewLog'
    | 'SubmitForm'
    | 'EditSession'
    | 'CreateAssessment'
    | 'ViewAssessment'
    | 'CompleteAssessment'
    | 'ViewMovement'
    | 'ListOfGroups'
    | 'GroupDetails'
    | 'AssignSession'
    | 'ListOfIndividualGroups'
    | 'IndividualDetails'
    | 'CalendarPage'
    | 'Train'
    | 'Community'
    | 'Social'
    | 'Plan'
    | 'Class'
    | 'ViewTimeline'
    | 'IndividualLink'
    | 'ViewSession'
    | 'MyFeed'
    | 'Posts'
    | 'Learn1TUL'
    | 'Features'
    | 'About'
    | 'ContactUs'
    | 'AppUserAgreement'
    | 'CaPrivacyPolicy'
    | 'PrivacyPolicy';

type CustomRouteProp = RouteProps & {
    restricted: boolean;
    path: string;
};

const ROUTES: Record<RouteNames, CustomRouteProp> = {
    IndividualLink: {
        component: Box,
        exact: true,
        path: '/i/:id',
        restricted: false,
    },
    AcceptInvite: {
        path: '/accept-invite/:token',
        exact: true,
        component: Box,
        restricted: false,
    },
    Login: {
        exact: true,
        path: '/login',
        component: Box,
        restricted: false,
    },
    ForgotPassword: {
        exact: true,
        path: '/forgot-password',
        component: Box,
        restricted: false,
    },
    ForgotUsername: {
        exact: true,
        path: '/forgot-username',
        component: Box,
        restricted: false,
    },
    RestorePassword: {
        exact: true,
        path: '/api/authentication/password/redirect-to-reset',
        component: Box,
        restricted: false,
    },
    Register: {
        exact: true,
        path: '/register',
        component: Box,
        restricted: false,
    },
    LandingPage: {
        exact: true,
        path: '/links/:linkId',
        component: Box,
        restricted: false,
    },

    //Redirecting Page if user is not logged in
    Redirecting: {
        path: '/redirecting',
        exact: true,
        component: Box,
        restricted: false,
    },
    //404 Page Not Found
    NotFound: {
        path: '/not-found',
        exact: true,
        restricted: false,
        component: Box,
    },
    Unauthorized: {
        path: '/unauthorized',
        exact: true,
        restricted: true,
        component: Box,
    },
    Profile: {
        exact: true,
        path: '/profile',
        component: Box,
        restricted: true,
    },
    CreateOrganization: {
        exact: true,
        path: '/create-organization',
        component: Box,
        restricted: true,
    },
    EditSession: {
        exact: true,
        path: '/train/sessions/:id',
        component: Box,
        restricted: true,
    },
    ViewSession: {
        exact: true,
        path: '/train/sessions/:id/view',
        component: Box,
        restricted: true,
    },
    AddQuickSession: {
        exact: true,
        path: '/train/activities/:id',
        component: Box,
        restricted: true,
    },
    Train: {
        exact: false,
        path: '/train',
        component: Box,
        restricted: true,
    },
    EditOrganization: {
        exact: true,
        path: '/organizations/:id',
        component: Box,
        restricted: true,
    },
    TrainDashboard: {
        path: '/train',
        restricted: true,
    },
    Class: {
        exact: false,
        path: '/class',
        component: Box,
        restricted: true,
    },
    ClassAssessments: {
        exact: true,
        path: '/class/assessments',
        component: Box,
        restricted: true,
    },
    ListOfSessions: {
        exact: true,
        path: '/train/sessions',
        restricted: true,
    },
    ListOfMovements: {
        exact: true,
        path: '/train/movements',
        restricted: true,
    },
    ViewMovement: {
        exact: true,
        path: '/train/movements/:id',
        restricted: true,
    },
    Assessments: {
        exact: true,
        path: '/class/submissions',
        restricted: true,
    },
    AssessmentResult: {
        exact: true,
        path: '/assessments/:id',
        component: Box,
        restricted: true,
    },
    ViewLog: {
        exact: true,
        path: '/activities/:id',
        component: Box,
        restricted: true,
    },
    SubmitForm: {
        exact: true,
        path: '/class/forms/:id',
        restricted: true,
    },
    CompleteAssessment: {
        exact: true,
        path: '/class/submissions/:id',
        restricted: true,
    },
    StartAssessmentForSelf: {
        exact: true,
        path: '/assessments/:id/start-self',
        restricted: true,
        component: Box,
    },
    AssessmentSubmissionPreview: {
        exact: true,
        path: '/class/assessments/preview/:id',
        restricted: true,
    },
    IndividualDetails: {
        exact: true,
        path: '/community/individuals/:individualId',
        component: Box,
        restricted: true,
    },
    Community: {
        exact: false,
        path: '/community',
        component: Box,
        restricted: true,
    },
    Social: {
        exact: false,
        path: '/social',
        component: Box,
        restricted: true,
    },
    ListOfGroups: {
        exact: true,
        path: '/community/groups',
        restricted: true,
    },
    GroupDetails: {
        exact: true,
        path: '/community/groups/:id',
        restricted: true,
    },
    CreateAssessment: {
        exact: true,
        path: '/class/assessments/:id',
        restricted: true,
    },
    ViewAssessment: {
        exact: true,
        path: '/class/assessments/:id/view',
        restricted: true,
    },
    AssignSession: {
        component: Box,
        exact: true,
        path: '/plan/sessions/:sessionId/assign/:individualId',
        restricted: true,
    },
    Plan: {
        exact: false,
        path: '/plan',
        component: Box,
        restricted: true,
    },
    ViewTimeline: {
        exact: true,
        path: '/plan/timeline',
        restricted: true,
    },
    CalendarPage: {
        exact: true,
        path: '/plan/calendar',
        restricted: true,
    },
    ListOfIndividualGroups: {
        exact: true,
        path: '/community/individuals',
        restricted: true,
    },
    MyFeed: {
        exact: true,
        path: '/social/my-posts',
        restricted: true,
    },
    Posts: {
        exact: true,
        path: '/social/feed',
        restricted: true,
    },
    Learn1TUL: {
        exact: true,
        path: '/Learn1TUL',
        component: Box, // This will be changed in the next step.
        restricted: true, // Adjust based on your needs.
    },
    Features: {
        exact: true,
        path: '/Features',
        component: Box,
        restricted: false,
    },
    About: {
        exact: true,
        path: '/About',
        component: Box,
        restricted: false,
    },
    ContactUs: {
        exact: true,
        path: '/ContactUs',
        component: Box,
        restricted: false,
    },
    AppUserAgreement: {
        exact: true,
        path: '/AppUserAgreement',
        component: Box,
        restricted: false,
    },
    PrivacyPolicy: {
        exact: true,
        path: '/PrivacyPolicy',
        component: Box,
        restricted: false,
    },
    CaPrivacyPolicy: {
        exact: true,
        path: '/CaPrivacyPolicy',
        component: Box,
        restricted: false,
    },
};

export default ROUTES;
