import { ReactElement, useContext } from 'react';
import { Avatar, Box, Button, Card, Typography } from '@mui/material';
import { confirmViaDialog } from '../../../components';
import { AbilityContext } from '../../../components/Functional/Can';
import { Individual } from '../../../utils';
import useTheme from '@mui/material/styles/useTheme';

interface IndividualAttribute {
    header: string;
    logo_url?: string | null;
    subheader: string | number;
}

export interface IndividualDetailsCardProps {
    attributes: IndividualAttribute[];
    emptyResultsText?: string;
    title: string;
    individual: Individual;
    onDeleteClick?: () => void;
    deleting?: boolean;
    onEditProfileClick?: () => void;
}

const IndividualDetailsCard = ({
    attributes,
    emptyResultsText,
    title,
    deleting = false,
    onDeleteClick,
    onEditProfileClick,
    individual,
}: IndividualDetailsCardProps): ReactElement => {
    const theme = useTheme();
    const ability = useContext(AbilityContext);

    return (
        <Card
            sx={{
                width: '100%',
                display: 'flex', // Add this if you want vertical centering
                flexDirection: 'column', // Ensures flex children (Typography) are stacked vertically
                justifyContent: 'center', // Centers flex children vertically
            }}
        >
            <Typography
                sx={{
                    textAlign: 'center', // Correct property for horizontal centering
                    fontSize: 20,
                    fontWeight: 500,
                    padding: theme.spacing(6, 8, 2),
                }}
            >
                {title}
            </Typography>
            {!attributes.length ? (
                <Typography sx={{ padding: theme.spacing(6, 10) }}>
                    {emptyResultsText || 'No groups'}
                </Typography>
            ) : (
                attributes.map(({ logo_url, header, subheader }) => (
                    <Box
                        key={header}
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                            '&:not(:last-of-type) > *': {
                                borderBottom: `1px solid #e0e0e0`,
                            },
                        }}
                    >
                        {logo_url !== undefined ? (
                            <Avatar
                                variant="rounded"
                                sx={{ margin: theme.spacing(0, 8) }}
                                alt={header}
                                src={logo_url ?? ''}
                            />
                        ) : (
                            <Box sx={{ marginLeft: 16 }} />
                        )}
                        <Box
                            sx={{
                                display: 'flex', // Set display to flex to enable flexbox properties
                                flexDirection: 'row', // Align children in a row
                                padding: theme.spacing(8, 0),
                                alignItems: 'center', // Align items vertically in the center
                                width: '100%', // Ensures the container takes full width
                            }}
                        >
                            <Box
                                sx={{
                                    fontSize: 16,
                                    lineHeight: '20px',
                                    marginRight: theme.spacing(7),
                                }}
                            >
                                {header}&nbsp;
                            </Box>
                            <Box
                                sx={{
                                    color: theme.palette.text.secondary,
                                    fontSize: 14,
                                    lineHeight: '20px',
                                }}
                            >
                                {subheader}
                            </Box>
                        </Box>
                    </Box>
                ))
            )}
            {onDeleteClick && ability.can('individual:delete', individual) && (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        {onEditProfileClick && (
                            <Button onClick={() => onEditProfileClick()}> Edit Profile </Button>
                        )}
                        <Button
                            disabled={deleting}
                            onClick={() => {
                                confirmViaDialog({
                                    confirmation: {
                                        title: 'Are You Sure?',
                                        message: 'This will archive this individual',
                                        cancelButtonText: 'Cancel',
                                        okButtonText: 'Archive',
                                        onCancelPressed: () => {},
                                    },
                                }).then((result) => {
                                    result && onDeleteClick();
                                });
                            }}
                        >
                            Archive
                        </Button>
                    </Box>
                </>
            )}
        </Card>
    );
};

export default IndividualDetailsCard;
