import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Program } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Program>>>;

const getAllPrograms = (query = {}): ReturnType => {
    return Request.get(`/workouts/programs`, { params: query });
};

export default getAllPrograms;
