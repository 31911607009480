import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { Subscription } from 'app.types';
import { AxiosError } from 'axios';
import { getCurrentUserSubscriptions } from '../../api';
import { RootState } from './index';
import { UserError } from './user';

export interface BillingState {
    loaded: boolean;
    subscriptions: Subscription[];
}

export const initialState: BillingState = {
    loaded: false,
    subscriptions: [],
};

export const getUserBilling = createAsyncThunk<
    Subscription[],
    undefined,
    {
        rejectValue: UserError;
    }
>('user/getUserBilling', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getCurrentUserSubscriptions();
        return data;
    } catch (err: any) {
        const error: AxiosError<UserError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(getUserBilling.fulfilled, (state, { payload }) => {
            state.subscriptions = payload;
            state.loaded = true;
        });
    },
});

export const selectBilling = (state: RootState) => state.billing;
export const selectBillingLoaded = createSelector(selectBilling, (billing) => billing.loaded);
export const selectBillingSubscriptions = createSelector(
    selectBilling,
    (billing) => billing.subscriptions,
);
