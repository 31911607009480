import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Movement } from 'utils';

type ReturnType = Promise<AxiosResponse<Movement>>;

export interface MovementDuplicationPayload {
    organization?: string | null;
}

const duplicateMovement = (id: string, payload: MovementDuplicationPayload): ReturnType => {
    return Request.post(`/workouts/movements/${id}/duplicate`, payload);
};

export default duplicateMovement;
