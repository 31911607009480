import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import CoachGroupLog from '../media/CoachGroupLog.png';
import FullScreenDialogCard from '../components/HowToDialogCard';
import CoachCreateMovement from '../media/CoachCreateMovement.png';
import CoachCreateSession from '../media/CoachCreateSession.png';
import CoachVideoFeedback from '../media/CoachVideoFeedback.png';
import CoachCreateLearningContent from '../media/CoachCreateLearningContent.png';
import CoachInviteAthletesToGroup from '../media/CoachInviteAthletesToGroup.png';

function AIVideoOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL allows Organizational Coaches many permissions in the system including:
                        creating movements, sessions, learning content, and groups, logging for an
                        entire group or individual athlete, invite members to a group, and provide
                        video feedback to athletes.
                    </Typography>
                </Grid>

                {/* First row */}
                <FullScreenDialogCard
                    image={CoachCreateMovement}
                    title="Create Movements"
                    headline="Create Movements"
                    description="Create movements and select what data values you want to collect."
                />

                <FullScreenDialogCard
                    image={CoachCreateSession}
                    title="Create Sessions"
                    headline="Create Sessions"
                    description="Create sessions and assign or share with individuals or groups."
                />
                <FullScreenDialogCard
                    image={CoachCreateLearningContent}
                    title="Create Learning Content"
                    headline="Create Learning Content"
                    description="Create Learning Content and assign or share with individuals or groups."
                />
                <FullScreenDialogCard
                    image={CoachGroupLog}
                    title="Group Log"
                    headline="Group Log"
                    description="Quickly log a session for an entire group."
                />
                <FullScreenDialogCard
                    image={CoachInviteAthletesToGroup}
                    title="Group Invite"
                    headline="Group Invite"
                    description="Coaches can invite athletes to a group thru invite links."
                />
                <FullScreenDialogCard
                    image={CoachVideoFeedback}
                    title="Video Feedback"
                    headline="Video Feedback"
                    description="Provide feedback to athletes through video analysis comments."
                />
            </Grid>
        </>
    );
}

export default AIVideoOverview;
