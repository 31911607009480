import React, { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Skeleton } from '@mui/material';

interface OwnProps {}

type Props = OwnProps;

const PublicIndividualLinkSkeleton: FunctionComponent<React.PropsWithChildren<Props>> = () => {
    return (
        <Grid sx={{ marginTop: '40px' }} container direction="column" spacing={10}>
            <Grid sx={{ margin: 'auto', display: 'flex', alignItems: 'center' }} item>
                <Skeleton sx={{ width: 140, height: 140 }} variant="circular" />
                <AddIcon />
                <Skeleton sx={{ width: 140, height: 140 }} variant="circular" />
            </Grid>
            <Grid sx={{ margin: 'auto', display: 'flex', alignItems: 'center' }} item>
                <Skeleton width={360} height={32} />
            </Grid>
            <Grid sx={{ margin: 'auto', display: 'flex', alignItems: 'center' }} item>
                <Skeleton width={360} height={32} />
            </Grid>
            <Grid item sx={{ margin: 'auto', display: 'flex', alignItems: 'center' }}>
                <Skeleton width={240} height={100} />
            </Grid>
        </Grid>
    );
};

export default PublicIndividualLinkSkeleton;
