import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Organization } from 'utils';

type ReturnType = Promise<AxiosResponse<Organization>>;

const viewOrganizationByPartnerId = (partnerId: string): ReturnType => {
    return Request.get(`/organizations/partner/${partnerId}`);
};

export default viewOrganizationByPartnerId;
