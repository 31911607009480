import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Form } from 'utils';

type ReturnType = Promise<AxiosResponse<Form>>;

interface FormCreatePayload {
    title: string;
    type: string;
    organization: string;
}

const createForm = (payload: FormCreatePayload): ReturnType => {
    return Request.post(`/data/forms/`, payload);
};

export default createForm;
