import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { Submission } from 'utils';

type ReturnType = Promise<AxiosResponse<Submission>>;

const startNewFormSubmissionForSelf = (
    id: string,
    params: { date: DateTime; event?: string },
): ReturnType => {
    return Request.post(`/data/forms/${id}/submissions/start-self`, params);
};
export default startNewFormSubmissionForSelf;
