import { Box, IconButton, Typography } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { Asset, PartialUploadList } from 'utils/types';
import SuccessCard from './MediaSelectionGroupComponents/SuccessCard';
import UploadingCard from './MediaSelectionGroupComponents/UploadingCard';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import AssetCard from './MediaSelectionGroupComponents/AssetCard';
import {
    getMp4ClipFromRenditions,
    getMp4ClipFromVersions,
    getThumbnailFromVersions,
    getWebmClipFromVersions,
} from 'utils';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { ChooseMediaDialog } from '../Dialogs';
import { EncodingOptionsPayload, Media } from '../../app.types';
import useTheme from '@mui/system/useTheme';

export interface MediaSelectionGroupProps {
    /** assets An array of Assets to display in the media selection group */
    assets: Array<Asset>;
    /** uploads */
    uploads: PartialUploadList;
    /** onFileSelected When a file is selected */
    onFileSelected: (file: File) => void;
    /** onMediaSelected When the user chooses media from the dialog **/
    onMediaSelected: (media: Media) => void;
    onMediaDeleteClicked?: (media: Media) => void;
    /** onDeleteClicked Callback for when the delete button is clicked on a media element */
    onDeleteClicked?: (asset: Asset, index: number) => void;
    /** onAssetClicked callback when the asset is clicked from inside the media seleciton group */
    onAssetClicked?: (asset: Asset) => void;
    /** canAddMedia If media is allowed to be added to the list. */
    canAddMedia?: boolean;
    /** If this component allows you to remove media. */
    canRemoveMedia?: boolean;
    /** If this component allows you to set cover photo. */
    showCoverOptions?: boolean;
    /* Uuid of the default asset */
    defaultCoverAssetUuid?: string;
    /* Event when cover asset is updated */
    onCoverAssetUpdated?: (coverAsset: Asset) => void;
    /** If media is added to the list **/
    media?: Media[];
    /** Organization that the list belongs to */
    organization: string;
    onEncodingOptionsSelected?: (fileName: string, payload: EncodingOptionsPayload) => void;
}

export default function MediaSelectionGroup({
    assets,
    uploads,
    onFileSelected,
    onAssetClicked = () => {},
    onDeleteClicked = () => {},
    onMediaSelected = () => {},
    onMediaDeleteClicked = () => {},
    canAddMedia = true,
    canRemoveMedia = true,
    showCoverOptions = false,
    media = [],
    defaultCoverAssetUuid,
    onCoverAssetUpdated,
    organization,
    onEncodingOptionsSelected = () => {},
}: MediaSelectionGroupProps): ReactElement {
    const [showMediaDialog, setShowMediaDialog] = useState(false);
    const theme = useTheme();
    const inputFileRef = React.useRef<HTMLInputElement | null>(null);

    const handleInputChange = (files: FileList | null) => {
        if (!files) {
            return;
        }

        Array.from(files).forEach((file) => {
            onFileSelected(file);
        });
    };

    const hasVideo = (asset: Asset): boolean => {
        return (
            asset.versions.findIndex(
                (v) =>
                    v.file_mime_type.startsWith('video') ||
                    ['application/vnd.apple.mpegurl'].indexOf(v.file_mime_type) > -1,
            ) > -1
        );
    };

    const [coverAssetUuid, setCoverAssetUuid] = useState<string | null>(
        defaultCoverAssetUuid || assets[0]?.uuid || null,
    );

    useEffect(() => {
        if (!coverAssetUuid) {
            setCoverAssetUuid(defaultCoverAssetUuid || assets[0]?.uuid || null);
        }
    }, [assets, defaultCoverAssetUuid, coverAssetUuid]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                overflowX: 'auto',
                marginBottom: 20,
                width: '100%',
                '-webkit-overflow-scrolling': 'touch',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}
        >
            {!canAddMedia && assets.length === 0 && (
                <Box
                    sx={{
                        flex: '0 0 auto',
                        marginRight: 12,
                        border: 1,
                        borderStyle: 'dashed',
                        borderRadius: 4,
                        color: theme.palette.text.secondary,
                        width: 128,
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    None
                </Box>
            )}
            {canAddMedia && (
                <>
                    <Box
                        onClick={() => setShowMediaDialog(true)}
                        onKeyDown={() => setShowMediaDialog(true)}
                        role="button"
                        tabIndex={0}
                        sx={{
                            flex: '0 0 auto',
                            marginRight: 12,
                            border: 1,
                            borderStyle: 'dashed',
                            borderRadius: 4,
                            color: theme.palette.text.secondary,
                            width: 128,
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 128,
                                height: 'auto',
                                cursor: 'pointer',
                            }}
                        >
                            <AddAPhotoOutlinedIcon />
                            <span>Add Media</span>
                        </Box>
                    </Box>
                    <input
                        ref={inputFileRef}
                        multiple
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                            handleInputChange(e.target.files);
                            setShowMediaDialog(false);
                        }}
                        type="file"
                        accept="video/*,image/*"
                    />
                    <ChooseMediaDialog
                        open={showMediaDialog}
                        fullScreen={false}
                        onClose={() => setShowMediaDialog(false)}
                        onInputClick={() => inputFileRef?.current?.click()}
                        showVideo={canAddMedia}
                        onSelected={(media) => {
                            setShowMediaDialog(false);
                            onMediaSelected(media);
                        }}
                        organization={organization}
                    />
                </>
            )}
            {Object.keys(uploads)
                .reverse()
                .map((fileName, index) => {
                    if (uploads[fileName].progress === 1) {
                        return (
                            <SuccessCard
                                clipStartTime={
                                    uploads[fileName].encodingOptions?.clip_start_time ?? 0
                                }
                                onClipStartTimeSelected={(clipStartTime) =>
                                    onEncodingOptionsSelected(fileName, {
                                        clip_start_time: clipStartTime,
                                    })
                                }
                                text={uploads[fileName].originalFileName}
                                key={index}
                            />
                        );
                    }
                    if (uploads[fileName].progress >= 0 && uploads[fileName].progress < 1) {
                        return (
                            <UploadingCard
                                key={index}
                                progress={uploads[fileName].progress * 100}
                                text={uploads[fileName].originalFileName}
                                clipStartTime={
                                    uploads[fileName].encodingOptions?.clip_start_time ?? 0
                                }
                                onClipStartTimeSelected={(clipStartTime) =>
                                    onEncodingOptionsSelected(fileName, {
                                        clip_start_time: clipStartTime,
                                    })
                                }
                            />
                        );
                    }
                })}
            {media.map((media: Media) => (
                <AssetCard
                    key={media.uuid}
                    thumbSrc={media.url}
                    mp4ClipSrc={getMp4ClipFromRenditions(media.renditions) || ''}
                    onDeleteClicked={() => onMediaDeleteClicked(media)}
                    canRemoveMedia={true}
                />
            ))}
            {assets.map((asset, index) => (
                <Box key={asset.uuid}>
                    <AssetCard
                        clickable={hasVideo(asset)}
                        isError={asset.status === 'error'}
                        thumbSrc={getThumbnailFromVersions(asset.versions) || ''}
                        mp4ClipSrc={getMp4ClipFromVersions(asset.versions) || ''}
                        webmClipSrc={getWebmClipFromVersions(asset.versions) || ''}
                        alt={asset.purpose}
                        onDeleteClicked={() => onDeleteClicked(asset, index)}
                        onClick={() => onAssetClicked(asset)}
                        canRemoveMedia={canRemoveMedia}
                    />
                    {showCoverOptions && (
                        <Box display="flex" justifyContent="start" alignItems="center">
                            <IconButton
                                size="small"
                                onClick={() => {
                                    setCoverAssetUuid(asset.uuid);
                                    if (onCoverAssetUpdated) {
                                        onCoverAssetUpdated(asset);
                                    }
                                }}
                            >
                                {coverAssetUuid == asset.uuid ? (
                                    <RadioButtonChecked />
                                ) : (
                                    <RadioButtonUnchecked />
                                )}{' '}
                            </IconButton>
                            {coverAssetUuid == asset.uuid && (
                                <Typography variant="caption">Selected as cover</Typography>
                            )}
                        </Box>
                    )}
                </Box>
            ))}
        </Box>
    );
}
