import { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { MovementChartData, MovementFilter, MovementMetricFilter } from '../../../app.types';
import { TimezoneKey } from '../../../utils';
import { UserFilter } from '../plan.types';

export function getReportMovements(): Promise<AxiosResponse<Array<MovementFilter>>> {
    return Request.get(`analytics/logs/dimensions/movements`);
}

export function getReportUsers(): Promise<AxiosResponse<Array<UserFilter>>> {
    return Request.get(`analytics/logs/dimensions/users`);
}

export function getUserReportForMovement(
    filters: MovementMetricFilter,
): Promise<AxiosResponse<MovementChartData>> {
    return Request.get(`/analytics/logs/metrics/user-movement-timeseries`, {
        params: {
            ...filters,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as TimezoneKey,
        },
    });
}
