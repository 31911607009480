import React from 'react';
import { Group, Organization } from '../../utils';
import { Box, Chip } from '@mui/material';
import SearchGroup from './SearchGroup';

interface Props {
    organization: Organization;
    value: Group[];
    onChange: (movements: Group[]) => void;
}

function FilterByGroup({ organization, value, onChange }: Props): React.ReactElement {
    return (
        <Box display="flex">
            <Box display="flex" alignItems="center">
                {value.map((g) => (
                    <Chip
                        key={g.uuid}
                        onDelete={(e) => {
                            e.preventDefault();
                            onChange(value.filter((group) => group.uuid !== g.uuid));
                        }}
                        label={g.name}
                    />
                ))}
            </Box>
            <SearchGroup
                buttonText="Choose Group/Team(s)"
                onGroupSelected={(group) => {
                    if (value.findIndex((i) => i.uuid === group.uuid) === -1) {
                        onChange([...value, group]);
                    }
                }}
                organization={organization}
            />
        </Box>
    );
}

export default FilterByGroup;
