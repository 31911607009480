import {
    CheckBox,
    CheckBoxOutlineBlank,
    Close,
    DragIndicator,
    Image,
    RadioButtonChecked,
    RadioButtonUnchecked,
} from '@mui/icons-material';
import {
    Box,
    ButtonBase,
    Grid,
    InputAdornment,
    InputBase,
    List,
    ListItem,
    ListItemIcon,
    Tooltip,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { ChooseMediaDialog, ImageWithDelete } from 'components';
import { debounce, orderBy } from 'lodash';
import React, { ReactElement, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
    addQuestionChoice,
    removeQuestionChoice,
    updateQuestionChoiceDetails,
} from 'redux/reducers/form';
import { useAppDispatch } from 'redux/store';
import { Choice, Question } from 'utils';
import { Media } from '../../../app.types';

export interface QuestionOptionsListProps {
    question: Question;
    questionOptions: Array<Choice>;
    onQuestionOptionsUpdated: (updatedOptions: Array<Choice>) => void;
    sectionUuid: string;
    formUuid: string;
    organization: string;
}

const QuestionOptionsList = ({
    question,
    formUuid,
    sectionUuid,
    organization,
}: QuestionOptionsListProps): ReactElement => {
    const dispatch = useAppDispatch();
    const [activeOption, setActiveOption] = useState<string | null>(null);
    const [showOptionMediaUpload, setShowOptionMediaUpload] = useState<boolean>(false);
    const theme = useTheme();
    const onChoiceMarkedAsAnswer = (updatedChoice: Choice) => {
        dispatch(
            updateQuestionChoiceDetails({
                formUuid: formUuid,
                sectionUuid: sectionUuid,
                questionUuid: question.uuid as string,
                choiceUuid: updatedChoice.uuid,
                updatePayload: {
                    is_answer: !updatedChoice.is_answer,
                },
            }),
        );
    };

    const onChoiceAssetUploaded = (media: Media) => {
        if (!activeOption) {
            return;
        }

        dispatch(
            updateQuestionChoiceDetails({
                formUuid: formUuid,
                sectionUuid: sectionUuid,
                questionUuid: question.uuid as string,
                choiceUuid: activeOption as string,
                updatePayload: {
                    media_uuid: media.uuid,
                    media_url: media.url,
                },
            }),
        );
    };

    if (question.type === 'confirmation') {
        const confirmChoice = question.choices?.[0];

        if (!confirmChoice) {
            return <div />;
        }

        return (
            <List component="nav">
                <ListItem
                    alignItems="flex-start"
                    disableGutters
                    sx={{
                        '@media screen and (min-width: 960px)': {
                            '& .question-actions': {
                                visibility: 'hidden',
                            },
                            '&:hover .question-actions': {
                                visibility: 'visible',
                            },
                        },
                    }}
                >
                    <Grid container alignItems="flex-start">
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <InputBase
                                    fullWidth
                                    defaultValue={confirmChoice.text}
                                    placeholder="Confirmation text"
                                    inputProps={{
                                        'aria-label': 'confirmation text',
                                    }}
                                    onChange={debounce(
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            dispatch(
                                                updateQuestionChoiceDetails({
                                                    formUuid: formUuid,
                                                    sectionUuid: sectionUuid,
                                                    questionUuid: question.uuid as string,
                                                    choiceUuid: confirmChoice.uuid,
                                                    updatePayload: {
                                                        text: event.target.value || '',
                                                    },
                                                }),
                                            );
                                        },
                                        2000,
                                    )}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            {confirmChoice.is_answer ? (
                                                <CheckBox
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        onChoiceMarkedAsAnswer(confirmChoice);
                                                    }}
                                                />
                                            ) : (
                                                <CheckBoxOutlineBlank
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        onChoiceMarkedAsAnswer(confirmChoice);
                                                    }}
                                                />
                                            )}
                                        </InputAdornment>
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </ListItem>
            </List>
        );
    }

    return (
        <>
            <ChooseMediaDialog
                open={showOptionMediaUpload}
                fullScreen={false}
                onClose={() => setShowOptionMediaUpload(false)}
                onSelected={(media) => {
                    setShowOptionMediaUpload(false);
                    onChoiceAssetUploaded(media);
                }}
                organization={organization}
            />
            <Droppable type="choices" droppableId={question.uuid || ''}>
                {(provided) => (
                    <List component="nav" {...provided.droppableProps} ref={provided.innerRef}>
                        {orderBy(question.choices, 'order').map((choice, index) => (
                            <Draggable key={choice.uuid} draggableId={choice.uuid} index={index}>
                                {(provided) => (
                                    <ListItem
                                        alignItems="flex-start"
                                        sx={{
                                            '@media screen and (min-width: 960px)': {
                                                '& .question-actions': {
                                                    visibility: 'hidden',
                                                },
                                                '&:hover .question-actions': {
                                                    visibility: 'visible',
                                                },
                                            },
                                        }}
                                        disableGutters
                                        button
                                        key={choice.uuid}
                                        onMouseEnter={() => setActiveOption(choice.uuid)}
                                        onMouseLeave={() => {
                                            if (!showOptionMediaUpload) {
                                                // The media selection dialog it not open
                                                // So we can use mouseleave event
                                                // If its open, we would like the current option to stay active until
                                                // The media selection dialog is closed
                                                setActiveOption(null);
                                            }
                                        }}
                                        disableRipple
                                        disableTouchRipple
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                    >
                                        {/* Drag handle to reorder choices */}
                                        <ListItemIcon
                                            {...provided.dragHandleProps}
                                            sx={{
                                                marginTop: 0,
                                                padding: '4px 0 7px', // match alignment of text-input padding
                                                '@media screen and (max-width: 600px)': {
                                                    minWidth: 0,
                                                },
                                            }}
                                        >
                                            <DragIndicator />
                                        </ListItemIcon>

                                        <Grid container alignItems="flex-start">
                                            <Grid item xs={10} md={11}>
                                                <Box display="flex" flexDirection="column">
                                                    <InputBase
                                                        fullWidth
                                                        defaultValue={choice.text ?? null}
                                                        placeholder="Add option"
                                                        inputProps={{
                                                            'aria-label': 'naked',
                                                        }}
                                                        onChange={debounce(
                                                            (
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                            ) => {
                                                                dispatch(
                                                                    updateQuestionChoiceDetails({
                                                                        formUuid: formUuid,
                                                                        sectionUuid: sectionUuid,
                                                                        questionUuid:
                                                                            question.uuid as string,
                                                                        choiceUuid: choice.uuid,
                                                                        updatePayload: {
                                                                            text:
                                                                                event.target
                                                                                    .value || '',
                                                                        },
                                                                    }),
                                                                );
                                                            },
                                                            2000,
                                                        )}
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                {question.type ==
                                                                    'multiple-choice-single-select' &&
                                                                    Boolean(choice?.is_answer) && (
                                                                        <RadioButtonChecked
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                                onChoiceMarkedAsAnswer(
                                                                                    choice,
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                {question.type ==
                                                                    'multiple-choice-single-select' &&
                                                                    !choice?.is_answer && (
                                                                        <RadioButtonUnchecked
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                                onChoiceMarkedAsAnswer(
                                                                                    choice,
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                {question.type ==
                                                                    'multiple-choice-multi-select' &&
                                                                    !!choice?.is_answer && (
                                                                        <CheckBox
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                                onChoiceMarkedAsAnswer(
                                                                                    choice,
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                {question.type ==
                                                                    'multiple-choice-multi-select' &&
                                                                    !choice?.is_answer && (
                                                                        <CheckBoxOutlineBlank
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                                onChoiceMarkedAsAnswer(
                                                                                    choice,
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    {choice.media_url && (
                                                        <ImageWithDelete
                                                            src={choice.media_url}
                                                            alt={choice.text}
                                                            onDelete={() =>
                                                                dispatch(
                                                                    updateQuestionChoiceDetails({
                                                                        formUuid: formUuid,
                                                                        sectionUuid: sectionUuid,
                                                                        questionUuid:
                                                                            question.uuid as string,
                                                                        choiceUuid: choice.uuid,
                                                                        updatePayload: {
                                                                            media_url: null,
                                                                            media: null,
                                                                        },
                                                                    }),
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2} md={1} className="question-actions">
                                                <Box
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                    style={{ gap: 7 }}
                                                    pt={3}
                                                >
                                                    <Tooltip title="Upload option image">
                                                        <Image
                                                            sx={{
                                                                cursor: 'pointer',
                                                                color: (theme) =>
                                                                    theme.palette.text.secondary,
                                                            }}
                                                            onClick={() =>
                                                                setShowOptionMediaUpload(true)
                                                            }
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Remove option">
                                                        <Close
                                                            sx={{
                                                                cursor: 'pointer',
                                                                color: theme.palette.text.secondary,
                                                            }}
                                                            onClick={() => {
                                                                dispatch(
                                                                    removeQuestionChoice({
                                                                        formUuid: formUuid,
                                                                        sectionUuid: sectionUuid,
                                                                        questionUuid:
                                                                            question.uuid as string,
                                                                        removedChoiceUuid:
                                                                            choice.uuid,
                                                                    }),
                                                                );
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
            <Box textAlign="left">
                <Tooltip
                    title={
                        question.type === 'multiple-choice-single-select'
                            ? 'Add a multiple choice option'
                            : question.type === 'multiple-choice-multiple-select'
                            ? 'Add a multiselect option'
                            : 'Confirmation'
                    }
                >
                    <ButtonBase
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            color: 'rgba(0,0,0,0.38)',
                            fontSize: 16,
                            justifyContent: 'flex-start',
                            lineHeight: '32px',
                            paddingLeft: 24,
                            width: '100%',
                            // icon wrapper
                            '& .MuiBox-root': {
                                display: 'flex',
                            },
                            '@media screen and (min-width: 600px)': {
                                paddingLeft: 56,
                            },
                        }}
                        onClick={() => {
                            dispatch(
                                addQuestionChoice({
                                    formUuid: formUuid,
                                    sectionUuid: sectionUuid,
                                    questionUuid: question.uuid as string,
                                }),
                            );
                        }}
                    >
                        <Box mr={4}>
                            {question.type === 'multiple-choice-single-select' ? (
                                <RadioButtonUnchecked />
                            ) : (
                                <CheckBoxOutlineBlank />
                            )}
                        </Box>
                        {question.type === 'multiple-choice-single-select'
                            ? 'Add a multiple choice option'
                            : 'Add a multiselect option'}
                    </ButtonBase>
                </Tooltip>
            </Box>
        </>
    );
};

export default QuestionOptionsList;
