import { Card, CardContent, Grid, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const IndividualDetailsSkeleton = () => {
    const theme = useTheme();
    return (
        <>
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    fontWeight: 600,
                    gap: theme.spacing(8),
                    padding: theme.spacing(17, 0),
                }}
            >
                <Skeleton
                    animation="wave"
                    variant="circular"
                    sx={{
                        height: 44,
                        width: 44,
                        [theme.breakpoints.up('md')]: {
                            height: 88,
                            width: 88,
                        },
                    }}
                />
                <Skeleton animation="wave" height={30} width={150} />
                <div
                    style={{
                        display: 'flex',
                        flex: '1 0 100%',
                        gap: theme.spacing(8),
                        [theme.breakpoints.up('md')]: {
                            flex: '0 1',
                        },
                    }}
                >
                    <Skeleton animation="wave" height={30} width={100} />
                    <Skeleton animation="wave" height={30} width={100} />
                </div>
            </div>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Skeleton
                                animation="wave"
                                height={40}
                                width={150}
                                sx={{ arginBottom: theme.spacing(10) }}
                            />
                            <Grid container direction="column" spacing={10}>
                                <Grid item>
                                    <Skeleton animation="wave" height={24} width={150} />
                                    <Skeleton animation="wave" height={20} width={100} />
                                </Grid>
                                <Grid item>
                                    <Skeleton animation="wave" height={24} width={150} />
                                    <Skeleton animation="wave" height={20} width={100} />
                                </Grid>
                                <Grid item>
                                    <Skeleton animation="wave" height={24} width={150} />
                                    <Skeleton animation="wave" height={20} width={100} />
                                </Grid>
                                <Grid item>
                                    <Skeleton animation="wave" height={24} width={150} />
                                    <Skeleton animation="wave" height={20} width={100} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card>
                        <CardContent>
                            <Skeleton
                                animation="wave"
                                height={40}
                                width={150}
                                sx={{ marginBottom: theme.spacing(10) }}
                            />
                            <Grid container direction="column" spacing={10}>
                                <Grid item>
                                    <Skeleton animation="wave" height={24} width={150} />
                                    <Skeleton animation="wave" height={20} width={100} />
                                </Grid>
                                <Grid item>
                                    <Skeleton animation="wave" height={24} width={150} />
                                    <Skeleton animation="wave" height={20} width={100} />
                                </Grid>
                                <Grid item>
                                    <Skeleton animation="wave" height={24} width={150} />
                                    <Skeleton animation="wave" height={20} width={100} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default IndividualDetailsSkeleton;
