import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { RawDataPointEnhanced } from '../community.types';

type DataTableProps = {
    data: RawDataPointEnhanced[];
};

const CropDataTable: React.FC<React.PropsWithChildren<DataTableProps>> = ({ data }) => {
    // Create an array to hold the sum of distances for each second
    const distances = new Array(
        Math.ceil(Math.max(...data.map((item) => item['Duration (s)']))),
    ).fill(0);

    // Iterate through the data and sum the distances for each second
    data.forEach((item) => {
        const index = Math.floor(item['Duration (s)']);
        distances[index] += item['Distance (m)'];
    });

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Duration (s)</TableCell>
                    {distances.map((_, index) => (
                        <TableCell key={index}>
                            {index}-{index + 0.99}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Distance (ft)</TableCell>
                    {distances.map((distance, index) => (
                        <TableCell key={index}>
                            {distance ? (distance * 1.09361 * 3).toFixed(2) : '-'}
                        </TableCell>
                    ))}
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default CropDataTable;
