import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { SharedUser } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<SharedUser>>>;

const addUserToOrganization = (
    id: string,
    users: Array<string>,
    role: string,
    message?: string,
): ReturnType => {
    return Request.post(`/organizations/${id}/users`, {
        users,
        role,
        message,
    });
};

export default addUserToOrganization;
