import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Activity } from 'utils';

type ReturnType = Promise<AxiosResponse<Activity>>;

interface ActivityUpdatePayload {
    notes?: string;
    date?: string | null;
}

const updateActivity = (uuid: string, payload: Partial<ActivityUpdatePayload>): ReturnType => {
    return Request.put(`/workouts/activities/${uuid}`, payload);
};
export default updateActivity;
