import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ListItemButton, ListItemText, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SearchInput from 'components/Inputs/SearchInput';
import React, { useState } from 'react';
import { Attribute, AttributeCategory, AttributeValueType } from 'utils';

export interface AttributeListProps {
    onAttributeClicked: (attribute: Attribute) => void;
    onAttributeAdded: (attribute: Attribute) => void;
    /** attributes The attributes to display in the list */
    attributes: Array<Attribute>;
    attributeCategory?: AttributeCategory;
    disabled: string[];
}

export default function AttributeListSelection({
    attributes,
    onAttributeClicked,
    onAttributeAdded,
    attributeCategory,
    disabled = [],
}: AttributeListProps) {
    const [searchValue, setSearchValue] = useState('');
    const [attributeList, setAttributeList] = useState<Attribute[]>(attributes);

    const handleKeyPressed = (e: React.KeyboardEvent) => {
        if (e.type === 'keyup' && e.key === 'Enter') {
            handleSearchValueClicked();
        }
    };

    const handleSearchValueClicked = () => {
        const existingAttributeIndex = attributes.findIndex((a) => a.name === searchValue);

        if (existingAttributeIndex > -1) {
            onAttributeClicked(attributes[existingAttributeIndex]);
        } else {
            onAttributeAdded({
                name: searchValue,
                category: attributeCategory ?? AttributeCategory.Other,
                type: AttributeValueType.TEXT,
                sub_category: '',
                organization_uuid: '',
            });
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchValue(value);
        let filteredList = attributes;
        // Converting the attributes to lowercase before matching so the search is case insensitive
        if (value) {
            filteredList = attributes.filter((attribute: Attribute) =>
                attribute.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
            );
        }
        setAttributeList(filteredList);
    };

    return (
        <List>
            <ListItem>
                <SearchInput
                    onKeyUp={handleKeyPressed}
                    value={searchValue}
                    onChange={onChange}
                    placeholderText="Type to filter tags or create new..."
                />
            </ListItem>
            {searchValue && (
                <ListItem button onClick={handleSearchValueClicked}>
                    Create New Tag:
                    <Chip label={searchValue} clickable onClick={handleSearchValueClicked} />
                    <ListItemIcon>
                        <KeyboardArrowRightIcon />
                    </ListItemIcon>
                </ListItem>
            )}
            <Divider />
            {attributeList.map((attribute, index) => (
                <ListItemButton
                    key={`attribute.name--${index}`}
                    onClick={() => onAttributeClicked(attribute)}
                    disabled={disabled.indexOf(attribute.name) > -1}
                >
                    <ListItemText>
                        <Typography
                            style={{ wordBreak: 'break-word' }}
                            variant="body1"
                            component="span"
                        >
                            {attribute.name}{' '}
                        </Typography>
                        <Typography variant="caption" component="span" color="textSecondary">
                            ({attribute.type}){' '}
                        </Typography>
                    </ListItemText>
                    <ListItemIcon>
                        <KeyboardArrowRightIcon />
                    </ListItemIcon>
                </ListItemButton>
            ))}
        </List>
    );
}
