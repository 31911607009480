import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AdminCreateNewGrup() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL allows you to easily import your GPS Session summary data and analyse
                        Player Load, Total Distance, Max Speed, Number of Accelerations, Number of
                        Decelerations, Number of Sprints, and other summary data.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/881020442?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'GPS Session Summary Import and Analysis'}
                            description={'GPS Session Summary Import and Analysis'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminCreateNewGrup;
