import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Movement } from '../../utils';

type ReturnType = Promise<AxiosResponse<Movement>>;

const unarchiveMovement = (uuid: string): ReturnType => {
    return Request.post(`/workouts/movements/${uuid}/unarchive`);
};

export default unarchiveMovement;
