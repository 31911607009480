import { Box, Button, TextField } from '@mui/material';
import { Send } from '@mui/icons-material';
import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

const form = yup.object({
    comment: yup.string().default('').max(200).required('Comment is required'),
});

function SubmitCommentForm({
    onSubmit,
    name,
}: {
    onSubmit: (comment: string) => void;
    name: string;
}) {
    return (
        <Formik
            initialValues={{
                comment: '',
            }}
            validationSchema={form}
            onSubmit={(values, { resetForm }) => {
                onSubmit(values.comment);
                resetForm();
            }}
        >
            {({ dirty, isValid, values, handleChange }) => (
                <Form style={{ width: '100%' }}>
                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            fullWidth
                            id="comment"
                            name="comment"
                            label={`Commenting as ${name}`}
                            value={values.comment}
                            onChange={handleChange}
                        />
                        <Button disabled={!isValid || !dirty} type="submit" sx={{ margin: 2 }}>
                            Submit
                            <Send />
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
}

export default SubmitCommentForm;
