import {
    IconButton,
    Popover,
    Input,
    Dialog,
    Hidden,
    CircularProgress,
    Box,
    Divider,
    Typography,
    debounce,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import React, { useCallback, useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { HashTagIcon } from 'icons';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
    deleteItemFromIndexOfArray,
    HITS_PER_PAGE,
    isExpired,
    RECENET_SEARCHES_LENGTH,
    RECENT_SEARCHES_LOCAL_STORAGE_TAG,
    SearchToken,
    somethingWentWrong,
} from 'utils';
import { useSelector } from 'react-redux';
import { loadToken, searchTokenSelector } from 'redux/reducers/searchToken';
import { useAppDispatch } from 'redux/store';
import { search } from 'api';
import { AlgoliaIndex } from 'api/Search/algoliaSearch';
import { isEmpty } from 'lodash';
import { useDebounce } from 'use-debounce';
import RowDivider from 'components/Separators/RowDivider';
import SpotlightSearchResult from 'components/ListElements/SpotlightSearchResult';
import useTheme from '@mui/material/styles/useTheme';

const SpotlightSearch = () => {
    const [value, setValue] = useState('');
    const [debouncedSearchValue] = useDebounce(value, 300);
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [searchResults, setSearchResults] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(false);
    const [recentSearches, setRecentSearches] = useState<Array<string>>(
        localStorage.getItem(RECENT_SEARCHES_LOCAL_STORAGE_TAG)
            ? JSON.parse(localStorage.getItem(RECENT_SEARCHES_LOCAL_STORAGE_TAG) ?? '')
            : [],
    );
    const { data } = useSelector(searchTokenSelector);
    const dispatch = useAppDispatch();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const insertRecentSearch = () => {
        const ind = recentSearches.findIndex((x) => x === value);
        if (ind >= 0) {
            recentSearches.splice(ind, 1);
        }
        if (recentSearches.length >= RECENET_SEARCHES_LENGTH)
            recentSearches.length = RECENET_SEARCHES_LENGTH - 1;
        recentSearches.splice(0, 0, value);
        setLocalStorageSearches(recentSearches);
    };

    const setLocalStorageSearches = (searches: Array<string>) =>
        localStorage.setItem(RECENT_SEARCHES_LOCAL_STORAGE_TAG, JSON.stringify(searches));

    const handleClose = () => {
        setAnchorEl(null);
        if (value.length > 0) {
            insertRecentSearch();
        }
        setValue('');
        setSearchResults([]);
    };

    const loadResults = (token: SearchToken, query: string) => {
        search(AlgoliaIndex.Site, {
            searchToken: token,
            searchQuery: query,
            hitsPerPage: HITS_PER_PAGE,
        })
            .then((response) => setSearchResults(response.data.hits))
            .catch(() => somethingWentWrong('Failed to load results'))
            .finally(() => setLoading(false));
    };

    const clearRecentSearches = () => {
        setRecentSearches([]);
        setLocalStorageSearches([]);
    };

    const deleteRecentSearchFromIndex = (searchIndex: number) => {
        let updatedArr = deleteItemFromIndexOfArray(recentSearches, searchIndex);
        setRecentSearches(updatedArr);
        setLocalStorageSearches(updatedArr);
    };

    const handleInputValueChanged = useCallback(
        async (val: string) => {
            if (val.length > 0) {
                setLoading(true);
                if (!data || isExpired(data?.expires ?? 0)) {
                    const res = await dispatch(loadToken());

                    if (loadToken.fulfilled.match(res)) {
                        loadResults(res.payload, val);
                    } else if (loadToken.rejected.match(res)) {
                        setLoading(false);
                    }
                } else {
                    loadResults(data, val);
                }
            } else {
                setSearchResults([]);
            }
        },
        [data, dispatch],
    );

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        if (val.length === 0 && value.length > 0) {
            insertRecentSearch();
        }

        setValue(val);
    };
    useEffect(() => {
        handleInputValueChanged(debouncedSearchValue);
    }, [debouncedSearchValue, handleInputValueChanged]);

    const onClickRecentSearch = (val: string) => {
        setValue(val);
    };

    const Children = () => (
        <React.Fragment>
            <RowDivider />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    maxHeight: '50vh',

                    scrollbarWidth: 'none',

                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.backgroundColor.scrollBar,
                        borderRadius: '32px',
                    },

                    [theme.breakpoints.down('sm')]: {
                        maxHeight: '100%',
                    },
                }}
            >
                {searchResults.length > 0 ? (
                    searchResults.map((result, index) => (
                        <SpotlightSearchResult
                            key={index}
                            result={{
                                resultId: result.id,
                                resultType: result.objectType,
                                resultText: result.title,
                                resultSubtext: result.content,
                                resultCaption: !isEmpty(result.organizationName)
                                    ? `Organization : ${result.organizationName}`
                                    : '',
                                avatarUrl: result.objectAvatarPhoto,
                            }}
                            onClick={insertRecentSearch}
                        />
                    ))
                ) : value.length === 0 ? (
                    <React.Fragment>
                        {recentSearches.length > 0 && (
                            <>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    margin={5}
                                >
                                    <Typography variant="caption" color="textSecondary">
                                        Your Recent Searches
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        onClick={clearRecentSearches}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                color: theme.palette.primary.main,
                                            },
                                        }}
                                    >
                                        {' '}
                                        Clear All
                                    </Typography>
                                </Box>
                                <Divider />
                            </>
                        )}
                        {recentSearches.map((search, index) => (
                            <>
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: theme.palette.text.secondary,
                                        justifyContent: 'space-between',
                                        paddingLeft: theme.spacing(8),

                                        '&:hover': {
                                            background: theme.palette.backgroundColor.grey,
                                        },
                                    }}
                                    key={index}
                                >
                                    <Box
                                        sx={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: 'calc(100% - 48px)',
                                        }}
                                        tabIndex={0}
                                        role="button"
                                        onClick={() => onClickRecentSearch(search)}
                                        onKeyDown={() => onClickRecentSearch(search)}
                                    >
                                        {search.toLowerCase().includes('tag') ? (
                                            <HashTagIcon
                                                sx={{
                                                    height: '16px',
                                                    width: '16px',
                                                    marginRight: theme.spacing(8),
                                                }}
                                            />
                                        ) : (
                                            <AccessTimeIcon
                                                sx={{
                                                    height: '20px',
                                                    width: '20px',
                                                    marginRight: theme.spacing(8),
                                                }}
                                            />
                                        )}
                                        <Typography variant="body2" color="textPrimary">
                                            {search}
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        onClick={() => deleteRecentSearchFromIndex(index)}
                                        sx={{ color: theme.palette.text.secondary }}
                                        size="large"
                                    >
                                        <CloseIcon fontSize="small" color="inherit" />
                                    </IconButton>
                                </Box>
                                <Divider />
                            </>
                        ))}
                    </React.Fragment>
                ) : (
                    <Box
                        sx={{
                            width: '100%',
                            padding: theme.spacing(5),
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Typography variant="caption">
                                Your search returned zero results
                            </Typography>
                        )}
                    </Box>
                )}
            </Box>
        </React.Fragment>
    );

    const open = Boolean(anchorEl);

    return (
        <React.Fragment>
            <Input
                readOnly
                fullWidth
                sx={{
                    background: theme.palette.primary.light,
                    padding: theme.spacing(1, 4),
                    borderRadius: '4px',
                    color: theme.palette.backgroundColor.paper,
                    width: 200,
                }}
                startAdornment={<SearchIcon />}
                onClick={handleClick}
                placeholder="Start typing here..."
            />
            <Hidden smDown>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    sx={{
                        width: '50vw',
                        '.MuiPopover-paper': {},
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            justifyContent: 'space-between',
                            paddingLeft: theme.spacing(8),
                        }}
                    >
                        <Input
                            inputProps={{ autoFocus: true }}
                            sx={{
                                width: '80%',

                                '&&&:before': {
                                    borderBottom: 'none',
                                },
                                '&&:after': {
                                    borderBottom: 'none',
                                },
                            }}
                            startAdornment={<SearchIcon />}
                            onChange={debounce((event: React.ChangeEvent<HTMLInputElement>) => {
                                handleInput(event);
                            }, 300)}
                        />
                        <IconButton onClick={handleClose} size="large">
                            <CancelIcon />
                        </IconButton>
                    </Box>
                    <Children />
                </Popover>
            </Hidden>
            <Hidden smUp>
                <Dialog open={open} onClose={handleClose} fullScreen>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            justifyContent: 'space-between',
                            paddingLeft: theme.spacing(8),
                        }}
                    >
                        <Input
                            sx={{
                                width: '80%',

                                '&&&:before': {
                                    borderBottom: 'none',
                                },
                                '&&:after': {
                                    borderBottom: 'none',
                                },
                            }}
                            startAdornment={<SearchIcon />}
                            onChange={handleInput}
                            value={value}
                            placeholder="Search"
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                        />
                        <IconButton onClick={handleClose} size="large">
                            <CancelIcon />
                        </IconButton>
                    </Box>
                    <Children />
                </Dialog>
            </Hidden>
        </React.Fragment>
    );
};

export default SpotlightSearch;
