import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const TypographyBodyOne = styled(Typography)(({ theme, style }) => ({
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
    fontSize: 16,
    textTransform: 'none',
    ...style,
}));

export default TypographyBodyOne;
