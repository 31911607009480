import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import CommentList from './CommentList';
import { createComment, deleteComment, getComments } from '../api/comments.api';
import EmptyStateWithDashedBorder from '../../../components/Feedback/EmptyStates/EmptyStateWithDashedBorder';
import { CommentsDisabled } from '@mui/icons-material';
import { Comment, Individual, Meta, PaginationLink, UserData } from '../../../utils';
import SubmitCommentForm from './SubmitCommentForm';
import { AbilityContext } from '../../../components/Functional/Can';

interface Props extends DialogProps {
    individual: Individual;
    commentableUuid: string;
    user: UserData;
    commentDeleted: (commentId: string) => void;
    commentAdded: (comment: Comment) => void;
}

function CommentViewingModal({
    individual,
    commentableUuid,
    user,
    commentAdded,
    commentDeleted,
    ...props
}: Props) {
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [comments, setComments] = useState<Comment[]>([]);
    const [commentsMeta, setCommentsMeta] = useState<Meta>();
    const [commentsLinks, setCommentsLinks] = useState<PaginationLink>();
    const ability = useContext(AbilityContext);

    const handleDeleteComment = useCallback(
        (commentId: string) => {
            commentDeleted(commentId);
            setComments((c) => c.filter((comm) => comm.uuid !== commentId));
            deleteComment(individual.uuid, commentId)
                .then(() => {})
                .catch(() => {})
                .finally(() => {});
        },
        [individual, commentDeleted],
    );

    const loadComments = useCallback(
        (page: number) => {
            getComments(individual.uuid, {
                page,
                'filter[asset_uuid]': commentableUuid,
            })
                .then((result) => {
                    setLoaded(true);
                    if (page === 1) {
                        setComments(result.data.data);
                    } else {
                        setComments((comments) => [...comments, ...result.data.data]);
                    }

                    setCommentsMeta(result.data.meta);
                    setCommentsLinks(result.data.links);
                })
                .catch(() => {})
                .finally(() => setLoading(false));
        },
        [individual, commentableUuid],
    );

    useEffect(() => {
        setLoading(true);
        loadComments(1);
    }, [loadComments, props.open]);

    const loadNextPage = useCallback(() => {
        loadComments((commentsMeta?.current_page ?? 1) + 1);
    }, [loadComments, commentsMeta]);

    return (
        <Dialog {...props}>
            <DialogTitle>View Comments</DialogTitle>
            <DialogContent>
                <CommentList
                    deleted={(comment) => handleDeleteComment(comment.uuid)}
                    comments={comments}
                    canDelete={ability.can('individual:manage-videos', individual)}
                />
                {loading && <CircularProgress />}
                {comments.length === 0 && loaded && (
                    <EmptyStateWithDashedBorder
                        icon={<CommentsDisabled />}
                        message="No comments yet"
                        onClick={() => {}}
                        disabled={true}
                    />
                )}
                <Button onClick={() => loadNextPage()} disabled={loading || !commentsLinks?.next}>
                    Show More
                </Button>
            </DialogContent>
            <DialogActions>
                <SubmitCommentForm
                    name={`${user.first_name} ${user.last_name}`}
                    onSubmit={(comment) => {
                        createComment(individual.uuid, { asset: commentableUuid, comment })
                            .then((comment) => {
                                commentAdded(comment.data);
                                setComments([comment.data, ...comments]);
                            })
                            .catch(() => {})
                            .finally(() => {});
                    }}
                />
            </DialogActions>
        </Dialog>
    );
}

export default CommentViewingModal;
