import { Close, Search } from '@mui/icons-material';
import {
    Box,
    FormControl,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    InputAdornment,
} from '@mui/material';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import {
    formatCurrency,
    formatDateStringToLocale,
    formatDateTimeStringToLocale,
} from '../../../app.functions';
import { Commission } from '../networking.types';
import { NetworkingActionTypes } from '../state/networking.state';
import {
    useDispatchContext,
    useNetworkingCommissionsPerPayout,
    useNetworkingPayout,
    useNetworkingSelectedPayoutId,
    useNetworkingCommissions,
} from '../state/NetworkingContext';

const associationString = (commission: Commission) => {
    if (commission.other_partner) {
        return commission.other_partner.organization?.name;
    }

    if (commission.athlete_user?.name) {
        return commission.athlete_user.name;
    }

    return '-';
};

interface Props {
    onClose: () => void;
}

const PartnershipPayoutCommissionsListScene: React.FC<Props> = ({ onClose }) => {
    const commissionsData = useNetworkingCommissionsPerPayout();
    const { data: commissions, loading, filters } = useNetworkingCommissions();
    const selectedPayoutId = useNetworkingSelectedPayoutId();
    const { payout } = useNetworkingPayout();
    const { id, payoutId } = useParams<{ id: string; payoutId: string }>();
    const dispatch = useDispatchContext();
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (payoutId) {
            dispatch({
                type: NetworkingActionTypes.PAYOUT_SELECTED,
                payoutId: payoutId,
            });
            // Trigger the initial fetch of commissions for this payout
            dispatch({
                type: NetworkingActionTypes.LOAD_COMMISSIONS_FOR_PAYOUT_REQUEST,
                payoutId: payoutId,
            });
        }
    }, [payoutId, dispatch, id]);

    const debouncedSearch = useMemo(
        () =>
            debounce((value: string) => {
                dispatch({
                    type: NetworkingActionTypes.UPDATE_COMMISSIONS_FILTERS,
                    payload: {
                        search: value,
                        'filter[payout_uuid]': payoutId,
                    },
                });
            }, 600),
        [dispatch, payoutId],
    );

    const handleSearchChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setSearchTerm(value);
            debouncedSearch(value);
        },
        [debouncedSearch],
    );

    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    const displayedCommissions = useMemo(() => {
        if (loading) return [];

        // If there's a search term, use the general commissions data
        if (filters.search) {
            return commissions || [];
        }

        // Otherwise, use the payout-specific commissions data
        return commissionsData.data[selectedPayoutId]?.commissions || [];
    }, [loading, commissions, commissionsData.data, selectedPayoutId, filters.search]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    margin: '10px',
                    flex: 1,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                }}
            >
                <Box
                    sx={{
                        py: 8,
                        px: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flex: 0,
                    }}
                >
                    <Box>
                        <Typography variant={'h6'}>
                            Commissions For {formatDateStringToLocale(payout?.payout_date)}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={onClose}>
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        flex: 0,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            flex: 0,
                            padding: 2,
                        }}
                    >
                        <FormControl sx={{ width: '300px' }}>
                            <TextField
                                label="Search commissions"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{ flex: 1, overflowY: 'scroll' }}>
                    <TableContainer sx={{ width: '100%' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Earned</TableCell>
                                    <TableCell>Reason</TableCell>
                                    <TableCell>Association</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {displayedCommissions.map((c) => (
                                    <TableRow key={c.uuid}>
                                        <TableCell>
                                            {formatDateTimeStringToLocale(
                                                c.earned_date,
                                                Intl.DateTimeFormat().resolvedOptions().timeZone ||
                                                    'America/Los_Angeles',
                                            )}
                                        </TableCell>
                                        <TableCell>{c.type}</TableCell>
                                        <TableCell>{associationString(c)}</TableCell>
                                        <TableCell>
                                            {formatCurrency({
                                                amount: c.amount / 100,
                                                currency: 'USD',
                                            })}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 8,
                    borderTop: '1px solid #e0e0e0',
                    flex: 0,
                    fontSize: '1.5rem',
                    fontWeight: 600,
                }}
            >
                {payout && (
                    <>
                        <Box>{formatDateStringToLocale(payout?.payout_date)}</Box>
                        <Box>
                            {formatCurrency({
                                amount: payout?.amount / 100,
                                currency: 'USD',
                            })}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default PartnershipPayoutCommissionsListScene;
