import { RouteConfigProps } from '../../Layouts/routeconfig';
import NetworkingHomeScene from './scenes/NetworkingHomeScene';
import NavigationLayout from '../../Layouts/NavigationLayout';

const networkingRoutes: RouteConfigProps[] = [
    {
        exact: false,
        path: '/networking/:id?',
        layout: NavigationLayout,
        component: NetworkingHomeScene,
    },
];

export default networkingRoutes;
