import { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { Activity, CalendarEvent, SupportTeam, TimezoneKey } from '../../../utils';

export function attestActivityApi(activity: Activity): Promise<void> {
    return Request.post(`workouts/activities/${activity.uuid}/attest`, {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as TimezoneKey,
    });
}

export function archiveActivityApi(activity: Activity): Promise<void> {
    return Request.post(`workouts/activities/${activity.uuid}/archive`);
}

export function unarchiveActivityApi(activity: Activity): Promise<void> {
    return Request.post(`workouts/activities/${activity.uuid}/unarchive`);
}

export function supportTeam(): Promise<AxiosResponse<Array<SupportTeam>>> {
    return Request.get(`care-team`);
}

export function toggleSupportTeamApi(id: string): Promise<AxiosResponse<void>> {
    return Request.post(`care-team/${id}/toggle`);
}

export function getEvents(params?: {
    [key: string]: string;
}): Promise<AxiosResponse<Array<CalendarEvent>>> {
    return Request.get(`/calendar/events`, { params });
}
