import { Delete, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import FastForwardIcon from '@mui/icons-material/FastForward';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import SixtyFpsSelectIcon from '@mui/icons-material/SixtyFpsSelect';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React, { createRef, useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Comment } from 'utils/types';
import { Can, confirmViaDialog } from '../../../components';
import { AbilityContext } from '../../../components/Functional/Can';
import VideoAnalysisHelper from '../../../images/VideoAnalysisHelper.png';
import {
    Analysis,
    Asset,
    getThumbnailFromVersions,
    getVideoFramerateFromVersions,
    getVideoSourcesFromVersions,
    Individual,
    UserData,
} from '../../../utils';
import { createComment, deleteComment } from '../api/comments.api';
import { deleteVideo } from '../api/individuals.api';
import CommentList from './CommentList';
import SubmitCommentForm from './SubmitCommentForm';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function VideoCard({
    asset,
    individual,
    onVideoDelete,
    isDeleting,
    viewAllCommentsClicked,
    commentAdded,
    user,
    commentDeleted,
    showSeek = true,
    analysis = null,
    seekToTime = 0,
}: {
    asset: Asset;
    individual: Individual;
    isDeleting: boolean;
    onVideoDelete?: (uuid: string) => void;
    commentAdded: (comment: Comment) => void;
    viewAllCommentsClicked: (event: any, asset: Asset) => void;
    user: UserData;
    commentDeleted: (commentId: string) => void;
    showSeek?: boolean;
    analysis?: Analysis | null;
    seekToTime?: number;
}) {
    const vidRef = createRef<HTMLVideoElement>();
    const ability = useContext(AbilityContext);
    const [time, setTime] = React.useState<number>(0);
    const { push } = useHistory();
    const [enteredTime, setEnteredTime] = React.useState<number>(0);
    const [open, setOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [fpsOpen, setFPSOpen] = React.useState(false);

    const getFramerate = useCallback(() => {
        return getVideoFramerateFromVersions(asset.versions);
    }, [asset]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleFPSClickOpen = () => {
        setFPSOpen(true);
    };
    const handleFPSClose = () => {
        setFPSOpen(false);
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleVideoDelete = useCallback(() => {
        confirmViaDialog({
            confirmation: {
                title: 'Are you sure?',
                message: 'This will permanently delete this video.',
            },
        });
        deleteVideo(asset.uuid, individual.uuid)
            .then(() => {
                onVideoDelete && onVideoDelete(asset.uuid);
            })
            .catch(() => {})
            .finally(() => {});
    }, [individual, asset, onVideoDelete]);

    const handleDeleteComment = useCallback(
        (commentId: string) => {
            commentDeleted(commentId);
            deleteComment(individual.uuid, commentId)
                .then(() => {})
                .catch(() => {})
                .finally(() => {});
        },
        [individual, commentDeleted],
    );

    const onTimeUpdate = useCallback((event: any) => {
        setTime(event.target.currentTime);
    }, []);

    const onSeek = useCallback(
        (time: any) => {
            if (vidRef.current) {
                vidRef.current.currentTime = time;
            }
        },
        [vidRef],
    );

    useEffect(() => {
        onSeek(seekToTime ?? 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seekToTime]);

    return (
        <Card key={asset.uuid} sx={{ minHeight: 200 }}>
            <CardContent>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                    ref={vidRef}
                    style={{ width: '100%' }}
                    controls
                    preload="auto"
                    poster={getThumbnailFromVersions(asset.versions)}
                    autoPlay={false}
                    controlsList="nodownload"
                    onTimeUpdate={onTimeUpdate}
                >
                    {getVideoSourcesFromVersions(asset.versions, false).map((s) => (
                        <source key={s.src} src={s.src} type={s.type}></source>
                    ))}
                </video>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 4,
                    }}
                >
                    {showSeek && (
                        <>
                            <form
                                onSubmit={(e) => {
                                    onSeek(enteredTime ?? 0);
                                    e.preventDefault();
                                }}
                                style={{ display: 'flex' }}
                            >
                                <TextField
                                    sx={{ width: 285 }}
                                    type={'number'}
                                    placeholder={'Click a Frame in data table below'}
                                    size={'small'}
                                    inputProps={{
                                        step: 0.001,
                                    }}
                                    onChange={(e) => {
                                        setEnteredTime(+e.target.value);
                                    }}
                                />
                                <Button type="submit">
                                    Go <FastForwardIcon />
                                </Button>
                            </form>
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        if (vidRef.current) {
                                            const seekToTime =
                                                vidRef.current.currentTime - 1 / getFramerate();

                                            if (
                                                seekToTime < 0 ||
                                                seekToTime > vidRef.current.duration
                                            ) {
                                                return;
                                            }

                                            onSeek(seekToTime);
                                        }
                                    }}
                                >
                                    <KeyboardDoubleArrowLeft />
                                </IconButton>
                                {time.toFixed(3)}s
                                <IconButton
                                    onClick={() => {
                                        if (vidRef.current) {
                                            const seekToTime =
                                                vidRef.current.currentTime + 1 / getFramerate();

                                            if (
                                                seekToTime < 0 ||
                                                seekToTime > vidRef.current.duration
                                            ) {
                                                return;
                                            }

                                            onSeek(seekToTime);
                                        }
                                    }}
                                >
                                    <KeyboardDoubleArrowRight />
                                </IconButton>
                            </Box>
                        </>
                    )}
                    <Box display="flex" flexWrap="wrap">
                        {analysis && (
                            <Chip
                                onClick={() => {
                                    push(`/community/video-analysis/${analysis.uuid}`);
                                }}
                                icon={<ElectricBoltIcon />}
                                clickable={analysis.status === 'Complete' ? true : false}
                                label={`Video Analysis - ${analysis.status}`}
                                color={analysis.status === 'Complete' ? 'success' : 'warning'}
                            />
                        )}
                        {analysis?.status === 'Failed' && (
                            <>
                                <Chip
                                    icon={<SixtyFpsSelectIcon />}
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {analysis.analysis.reason === 'FPS_TOO_LOW' ? (
                                                <>
                                                    Video is {analysis.fps}
                                                    {'fps'}
                                                    <HelpOutlineIcon sx={{ marginLeft: 3 }} />
                                                </>
                                            ) : (
                                                <>
                                                    Error - {analysis.analysis.reason}
                                                    <HelpOutlineIcon />
                                                </>
                                            )}
                                        </div>
                                    }
                                    color="error"
                                    onClick={handleFPSClickOpen}
                                />

                                <Dialog
                                    open={fpsOpen}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle>
                                        {'Sprint Analysis Processing Video Requirements'}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            When you upload through the &quot;Sprint Analysis
                                            Processing&quot; path your video will be sent to our
                                            servers for processing. <br />
                                            <br />
                                            This processing will take 1-2 minutes and will be done
                                            in the background. <br />
                                            <br />
                                            You will be able to see the results in the &quot;Video
                                            Analysis Database&quot; under your top left cloud
                                            database icon.
                                        </DialogContentText>
                                        <DialogContentText>
                                            <Typography variant="body1" fontWeight="bold">
                                                <br />
                                                Requirements:
                                                <br /> (1) Videos must be taken from the side
                                                <br /> (2) One athlete in the frame
                                                <br /> (3) 60fps or higher
                                            </Typography>
                                        </DialogContentText>
                                        <Button onClick={handleModalOpen} sx={{ marginTop: 6 }}>
                                            <ImageSearchIcon sx={{ marginRight: 4 }} /> View Example
                                            Video Angle
                                        </Button>
                                        <Dialog open={modalOpen} onClose={handleModalClose}>
                                            <DialogTitle>{'Example Video'}</DialogTitle>
                                            <DialogContent>
                                                <img
                                                    src={VideoAnalysisHelper}
                                                    alt="example video"
                                                />
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleModalClose}>Close</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleFPSClose}>I understand</Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                        )}
                    </Box>
                    {onVideoDelete && (
                        <Can I={'individual:manage-videos'} this={individual}>
                            <>
                                <IconButton disabled={!!isDeleting} onClick={handleClickOpen}>
                                    <Delete />
                                </IconButton>
                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    aria-describedby="alert-dialog-slide-description"
                                >
                                    <DialogTitle>
                                        <Alert severity="warning">
                                            Warning: This action cannot be undone.
                                        </Alert>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-slide-description">
                                            Are you sure you want to permanently delete this video?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Cancel</Button>
                                        <Button onClick={() => handleVideoDelete()}>
                                            Delete Video
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                        </Can>
                    )}
                </Box>
                {asset.comments && (
                    <Box>
                        <CommentList
                            deleted={(comment) => handleDeleteComment(comment.uuid)}
                            comments={asset.comments}
                            canDelete={ability.can('individual:manage-videos', individual)}
                        />
                        {asset.comments.length > 0 && (
                            <Button
                                sx={{ ml: 36, my: 4 }}
                                onClick={(e) => viewAllCommentsClicked(e, asset)}
                            >
                                View All Comments
                            </Button>
                        )}
                    </Box>
                )}
                <Box sx={{ display: 'flex' }}>
                    <Avatar
                        variant="rounded"
                        src={individual?.organization.logo_url || ''}
                        sx={{ margin: 8 }}
                    />
                    <SubmitCommentForm
                        name={`${user.first_name} ${user.last_name}`}
                        onSubmit={(comment) =>
                            createComment(individual.uuid, { asset: asset.uuid, comment })
                                .then((response) => commentAdded(response.data))
                                .catch(() => {})
                                .finally(() => {})
                        }
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

export default VideoCard;
