import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { AttributeValue, Session } from 'utils';

type ReturnType = Promise<AxiosResponse<Session>>;

export interface SessionUpdatePayload {
    title?: string | null;
    description?: string | null;
    duration?: number | null;
    attributes?: Array<AttributeValue> | null;
    cover_photo?: string | null;
    cover_photo_media?: string;
}

const updateSession = (id: string, payload: SessionUpdatePayload): ReturnType => {
    return Request.put(`/workouts/sessions/${id}`, payload);
};

export default updateSession;
