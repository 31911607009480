import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Movement } from 'utils';

type ReturnType = Promise<AxiosResponse<Movement>>;

const attachMediaAsAsset = (mediaUuid: string, movementUuid: string): ReturnType => {
    return Request.post(`/workouts/movements/${movementUuid}/assets-from-media`, {
        media: mediaUuid,
    });
};

export default attachMediaAsAsset;
