import {
    AuthScreenLayout,
    RegisterForm,
    TextButton,
    TypographySubtitle,
    TypographyTitle,
} from 'components';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { authSelector } from 'redux/reducers/auth';
import ROUTES from 'Routes/routes';
import { LocationState } from 'utils';

function Register() {
    const { push } = useHistory();
    const location = useLocation<LocationState>();

    const { isAuth } = useSelector(authSelector);
    if (isAuth) {
        push('/home');
    }

    return (
        <AuthScreenLayout
            sx={{
                position: 'relative',
                width: '100%',
            }}
        >
            <TypographyTitle
                sx={{
                    margin: 5,
                }}
                align="center"
            >
                Register your account
            </TypographyTitle>
            <TypographySubtitle
                sx={{
                    marginTop: 5,
                    marginBottom: 16,
                }}
                align="center"
            >
                Already have an account?{' '}
                <TextButton onClick={() => push(ROUTES.Login.path, location.state)} color="primary">
                    Sign In
                </TextButton>
            </TypographySubtitle>
            {isEmpty(location.state?.referrer_message) && (
                <TypographySubtitle
                    align="center"
                    sx={{
                        marginY: 8,
                    }}
                >
                    {location.state?.referrer_message || ''}
                </TypographySubtitle>
            )}
            <RegisterForm />
        </AuthScreenLayout>
    );
}

export default Register;
