import { Request } from 'api/index';
import { AxiosResponse } from 'axios';
import { Subscription } from '../../app.types';

type ReturnType = Promise<AxiosResponse<Array<Subscription>>>;

const getAllOrganizationSubscriptions = (): ReturnType =>
    Request.get(`/organizations/subscriptions`);

export default getAllOrganizationSubscriptions;
