import { useNavigationContext } from 'Layouts/Navigation/NavigationContext';
import { RouteConfigProps } from 'Layouts/routeconfig';
import React, { useEffect } from 'react';
import { Navigation } from 'utils';

interface SetNavigationProps {
    config: RouteConfigProps;
}

// This compnent is used to just set the primary, secondary navigation and the page title
// It is memoized to make sure it does not re-render every time the route is changed.
// Because the route configurations are same.
// e.g. When we are on `Train > Session`, the primary navigation will always be `Train` and secondary be `Sessions`
const SetNavigation = ({ config }: SetNavigationProps) => {
    const { setPrimaryNavigation, setSecondaryNavigation, setPageTitle } = useNavigationContext();

    useEffect(() => {
        // Instead of manually checking the url and setting the
        // navigation to active, we get that information from
        // the route config
        // So when this route is active, we set the primary and secondary navigation
        // using navigation context
        setPrimaryNavigation(config.primaryNavigation || Navigation.EMPTY);
        setSecondaryNavigation(config.secondaryNavigation || Navigation.EMPTY);
        setPageTitle(config.pageTitle || (config.primaryNavigation as string));
    }, [config, setPrimaryNavigation, setSecondaryNavigation, setPageTitle]);

    return null;
};

export default React.memo(SetNavigation);
