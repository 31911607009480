import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    DialogContentText,
    FormControl,
    FormControlLabel,
    Radio,
    DialogActions,
    Button,
    TextField,
    RadioGroup,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { Attribute, AttributeValueType } from 'utils';

interface Props {
    open: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
    onChange: (value: Attribute) => void;
    value: Attribute;
}

export default function CreateAttributeDialog({
    open,
    handleClose,
    handleSubmit,
    onChange,
    value,
}: Props): ReactElement {
    const handleAttributeTypeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...value,
            type: event.target.value as AttributeValueType,
        });
    };

    const handleAttributeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...value,
            name: event.target.value,
        });
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create New Tag?</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <DialogContentText>
                        Tags help you organize your content, teams and athletes on 1TUL.
                    </DialogContentText>
                    <TextField
                        variant="filled"
                        id="name"
                        value={value.name}
                        onChange={handleAttributeNameChange}
                        label="Tag Name"
                        type="text"
                    />
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="tag type"
                            name="type"
                            value={value.type}
                            onChange={handleAttributeTypeChanged}
                        >
                            <FormControlLabel
                                value={AttributeValueType.TEXT}
                                control={<Radio />}
                                label="Text, Character, or Number "
                            />
                            <FormControlLabel
                                value={AttributeValueType.DATE}
                                control={<Radio />}
                                label="Date"
                            />
                            <FormControlLabel
                                value={AttributeValueType.TIME}
                                control={<Radio />}
                                label="Time of Day"
                            />
                            <FormControlLabel
                                value={AttributeValueType.DATETIME}
                                control={<Radio />}
                                label="Date and Time Together"
                            />
                            <FormControlLabel
                                value={AttributeValueType.LIST}
                                control={<Radio />}
                                label="List of Values"
                            />
                            <FormControlLabel
                                value={AttributeValueType.NUMERIC}
                                control={<Radio />}
                                label="Number"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}
