import React, { useCallback } from 'react';
import { Avatar, Box, Button, Checkbox, FormGroup, Popover } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { xor } from 'lodash';

interface Props {
    value: string[];
    onChange: (v: string[]) => void;
    options: { label: string; value: string; avatar_url?: string | null }[];
    buttonText: string;
    maxHeight?: string;
}

function CheckboxFilterList({
    value,
    onChange,
    options,
    buttonText,
    maxHeight,
}: Props): React.ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const CheckboxLabel = useCallback(
        ({
            label,
            checkboxValue,
            checked,
            avatarUrl,
        }: {
            label: string;
            checkboxValue: string;
            checked: boolean;
            avatarUrl?: string | null;
        }) => {
            return (
                <FormControlLabel
                    label={
                        avatarUrl ? (
                            <Box sx={{ display: 'flex' }}>
                                <Avatar
                                    sx={{ width: 32, height: 32, mr: 4 }}
                                    src={avatarUrl ?? ''}
                                    variant={'rounded'}
                                />
                                {label}
                            </Box>
                        ) : (
                            label
                        )
                    }
                    control={
                        <Checkbox
                            onClick={() => onChange(xor(value, [checkboxValue]))}
                            checked={checked}
                            value={checkboxValue}
                            size="small"
                        />
                    }
                />
            );
        },
        [value, onChange],
    );

    return (
        <>
            <Popover
                open={!!anchorEl}
                id={'personal-record-filter'}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted={true}
            >
                <Box sx={{ maxHeight: maxHeight || '600px', overflowY: 'auto' }}>
                    <FormGroup sx={{ px: 4 }}>
                        {options.map((pr) => (
                            <CheckboxLabel
                                key={pr.value}
                                label={pr.label}
                                avatarUrl={pr.avatar_url}
                                checkboxValue={pr.value}
                                checked={value.indexOf(pr.value) > -1}
                            />
                        ))}
                    </FormGroup>
                </Box>
            </Popover>
            <Button onClick={handleClick} variant={value.length > 0 ? 'contained' : 'outlined'}>
                {buttonText}
                {value.length > 0 ? ` (${value.length})` : ''}
            </Button>
        </>
    );
}

export default CheckboxFilterList;
