import {
    Box,
    Container,
    FormControl,
    MenuItem,
    Select,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Evaporate, { TransferStats } from 'evaporate';
import { mean } from 'lodash';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getTags } from '../../../api/tags.api';
import { secondsToDurationString } from '../../../app.functions';
import { Tag } from '../../../app.types';
import {
    LinearProgressWithLabel,
    SelectDateChip,
    TypographySubtitle,
    TypographyTitle,
    TypographyTitleh6,
} from '../../../components';
import ButtonWithLoader from '../../../components/Buttons/ButtonWithLoader';
import ColorCircle from '../../../components/Indicators/ColorCircle';
import { authSelector } from '../../../redux/reducers/auth';
import { Group, Individual, TimeDisplayFormat } from '../../../utils';
import { evaporateConfig } from '../../../utils/S3Upload';
import { getMembers, viewGroup } from '../api/groups.api';
import { uploadPerformanceData } from '../api/performance.api';
import { SprintDataType } from '../community.types';
import DirectoryInput from '../components/DirectoryInput';
import FreeSoloCreateOptionDialog, {
    FreeSoloOption,
} from '../components/FreeSoloCreateOptionDialog';

interface FileObject {
    file: File;
    individual: Individual | null;
}

const evaporate = new Evaporate(evaporateConfig);
const GroupGpsUpload = () => {
    const { push } = useHistory();
    const [timer, setTimer] = useState<number | null>(null);
    const { groupId } = useParams<{ groupId: string }>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>('');
    const [date, setDate] = useState<DateTime | null>(DateTime.now());
    const [minVelocity, setMinVelocity] = useState<number>(3.57);
    const [dataType, setDataType] = useState<SprintDataType>('mclloyd');
    const [finished, setFinished] = useState<boolean>(false);
    const [files, setFiles] = useState<{
        [key: number]: FileObject;
    }>({});
    const [group, setGroup] = useState<Group>();
    const [individuals, setIndividuals] = useState<Individual[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [tag, setTag] = React.useState<FreeSoloOption | null>(null);
    const [tags, setTags] = React.useState<Tag[]>([]);
    const [progress, setProgress] = React.useState<Record<string, number>>({});
    const [stats, setStats] = React.useState<Record<string, TransferStats>>({});
    const { currentUser } = useSelector(authSelector);
    const uploadFilesInParallel = async (files: FileObject[]): Promise<void> => {
        const results = await Promise.all(
            files.map(async (file) => {
                const progressKey = `tmp/${uuidv4()}-${file.file.name}`;
                try {
                    const response = await evaporate.add({
                        name: progressKey,
                        file: file.file,
                        contentType: file.file.type,
                        beforeSigner: (xhr) => {
                            xhr.setRequestHeader('Accept', 'application/json');
                            xhr.setRequestHeader(
                                'Authorization',
                                `Bearer ${currentUser?.accessToken}`,
                            );
                        },
                        progress: (value, stats) => {
                            setProgress((p) => ({ ...p, [file.file.name]: value }));
                            setStats((p) => ({ ...p, [file.file.name]: stats }));
                        },
                        complete: () => {
                            uploadPerformanceData(
                                file.individual?.uuid ?? '',
                                file.file.name,
                                progressKey,
                                dataType,
                                date!.toFormat('yyyy-MM-dd') ?? '',
                                180,
                                72,
                                minVelocity,
                                tag ? tag.id : '',
                            );
                        },
                    });
                    console.log(response);
                    return 1;
                } catch (e) {
                    return file.individual?.name;
                }
            }),
        );

        // show snack bar with the total number of successes and the names of the files that failed
        const successes = results.filter((r) => r === 1);
        const failures = results.filter((r) => r !== 1);
        setSnackBarMessage(
            `${successes.length} files uploaded successfully. ${
                failures.length > 0 ? `The following files failed: ${failures.join(', ')}` : ''
            }
                `,
        );
        setFinished(true);
        setTimer(() => {
            const timeoutValue = setTimeout(() => push(`/community/groups/${groupId}`), 3000);

            return +timeoutValue;
        });
    };

    const handleSubmit = async () => {
        // loop through the files and upload them in parallel. First, get the signed
        // url using getSignedUrl() and then upload the file using uploadFile() to the signed url
        setSubmitting(true);

        await uploadFilesInParallel(Object.values(files));

        setSubmitting(false);
    };

    useEffect(() => {
        // Clearing the timer when the component is unmounted
        return () => {
            if (timer !== null) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    useEffect(() => {
        const allFiles = Object.values(files);
        // files without an individual
        const unassignedFiles = allFiles.filter((f) => !f.individual);
        if (unassignedFiles.length > 0) {
            setErrorMessage('Please assign all files to an athlete');
        } else if (!date) {
            setErrorMessage('Please select a date');
        } else if (allFiles.length === 0) {
            setErrorMessage('Please select at least one file');
        } else {
            setErrorMessage('');
        }
    }, [files, date]);

    useEffect(() => {
        viewGroup(groupId)
            .then((response) => setGroup(response.data))
            .catch(() => {})
            .finally();
        getMembers(groupId, { limit: 1000 })
            .then((response) => {
                const sortedIndividuals = sortBy(
                    response.data.data.map((m) => m.individual),
                    'name',
                );
                setIndividuals(sortedIndividuals);
            })
            .catch(() => {});
        getTags({ 'filter[type]': 'uploads' })
            .then((response) => {
                setTags(response.data);
            })
            .catch(() => {});
    }, [groupId]);

    return (
        <Container sx={{ mt: 8 }} maxWidth={'xl'}>
            <Stack spacing={4}>
                <TypographyTitle>
                    Upload Raw GPS Data Files For A Group Session {group?.name}
                </TypographyTitle>
                <TypographySubtitle>
                    You can upload multiple raw GPS data files for each athlete in a group session
                    here. To upload <b>summary</b> data, please upload to the{' '}
                    <Link to={'./gps-summary'}>group session summary page</Link>.
                </TypographySubtitle>
                {finished && (
                    <TypographyTitleh6
                        sx={{
                            textAlign: 'center',
                            color: 'green',
                        }}
                    >
                        Upload complete. Redirecting you to the group page...
                    </TypographyTitleh6>
                )}
                {!finished && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TableContainer sx={{ maxWidth: 775 }}>
                            <Table size={'small'}>
                                <TableHead></TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell align={'right'}>
                                            <SelectDateChip
                                                onChange={(value) => setDate(value ?? null)}
                                                value={date ? date : null}
                                                chipColor={date ? 'default' : 'primary'} // Color changes based on whether a date is selected
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Directory</TableCell>
                                        <TableCell align={'right'}>
                                            <DirectoryInput
                                                onChange={(e) => {
                                                    setFiles((files) => {
                                                        if (!e.currentTarget?.files) {
                                                            return files;
                                                        }

                                                        const newFiles = { ...files };

                                                        for (
                                                            let i = 0;
                                                            i < e.currentTarget.files.length;
                                                            i++
                                                        ) {
                                                            const file = e.currentTarget.files[i];
                                                            if (file.type === 'text/csv') {
                                                                newFiles[i] = {
                                                                    file,
                                                                    individual: null,
                                                                };
                                                            } else {
                                                                console.log(
                                                                    'File is not a CSV file',
                                                                );
                                                            }
                                                        }

                                                        return newFiles;
                                                    });
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Tag / Category</TableCell>
                                        <TableCell align={'right'}>
                                            <FreeSoloCreateOptionDialog
                                                createProps={{ type: 'uploads' }}
                                                value={tag}
                                                onChange={(v) => setTag(v)}
                                                organization={group?.organization?.uuid ?? ''}
                                                choices={tags.map((t) => ({
                                                    id: t.id,
                                                    title: t.name,
                                                }))}
                                                onTagCreated={() => {}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>GPS Device</TableCell>
                                        <TableCell align={'right'}>
                                            <FormControl>
                                                <Select
                                                    size={'small'}
                                                    value={dataType}
                                                    onChange={(event) =>
                                                        setDataType(
                                                            event.target.value as SprintDataType,
                                                        )
                                                    }
                                                    name="organization"
                                                >
                                                    <MenuItem value={'mclloyd'}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="text.primary"
                                                            sx={{ marginRight: 16 }}
                                                        >
                                                            Mclloyd STV4
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem value={'mclloyd-ATV1'}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="text.primary"
                                                            sx={{ marginRight: 16 }}
                                                        >
                                                            Mclloyd ATV1
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem value={'1080'}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="text.primary"
                                                            sx={{ marginRight: 10 }}
                                                        >
                                                            1080 Sprint
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem value={'catapultPlayerTek'}>
                                                        <Typography
                                                            variant="subtitle1"
                                                            color="text.primary"
                                                            sx={{ marginRight: 10 }}
                                                        >
                                                            Catapult PlayerTek
                                                        </Typography>
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Sprint Detection Velocity</TableCell>
                                        <TableCell align={'right'}>
                                            <FormControl variant="outlined">
                                                <Select
                                                    size={'small'}
                                                    value={minVelocity}
                                                    onChange={(event) =>
                                                        setMinVelocity(+event.target.value)
                                                    }
                                                    name="min_velocity"
                                                    type="number"
                                                >
                                                    <MenuItem value={2.68}>6mph</MenuItem>
                                                    <MenuItem value={3.57}>8mph</MenuItem>
                                                    <MenuItem value={4.47}>10mph</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                    {Object.keys(files).map((ix) => (
                                        <TableRow key={ix}>
                                            <TableCell>
                                                <Stack>
                                                    <p>{files[+ix].file.name}</p>
                                                    {progress[files[+ix].file.name] && (
                                                        <LinearProgressWithLabel
                                                            value={Math.round(
                                                                progress[files[+ix].file.name] *
                                                                    100,
                                                            )}
                                                        />
                                                    )}
                                                    {stats[files[+ix].file.name] && (
                                                        <Stack direction="row">
                                                            {stats[files[+ix].file.name]
                                                                .secondsLeft && (
                                                                <>
                                                                    {' '}
                                                                    <span>
                                                                        {secondsToDurationString(
                                                                            stats[
                                                                                files[+ix].file.name
                                                                            ].secondsLeft,
                                                                            TimeDisplayFormat.Duration,
                                                                        )}{' '}
                                                                        Remaining
                                                                    </span>
                                                                    <span>&nbsp;•&nbsp;</span>
                                                                </>
                                                            )}

                                                            <span>
                                                                {
                                                                    stats[files[+ix].file.name]
                                                                        .readableSpeed
                                                                }
                                                                /s
                                                            </span>
                                                            <span>&nbsp;•&nbsp;</span>
                                                            <ColorCircle
                                                                value={
                                                                    stats[files[+ix].file.name]
                                                                        .speed /
                                                                    1024 /
                                                                    1024
                                                                }
                                                            ></ColorCircle>
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            </TableCell>
                                            <TableCell align={'right'}>
                                                <Select
                                                    value={files[+ix].individual?.uuid ?? ''}
                                                    onChange={(e) => {
                                                        setFiles({
                                                            ...files,
                                                            [ix]: {
                                                                ...files[+ix],
                                                                individual: individuals.find(
                                                                    (i) =>
                                                                        i.uuid === e.target.value,
                                                                ),
                                                            },
                                                        });
                                                    }}
                                                    size={'small'}
                                                >
                                                    <MenuItem value={''}>Select Athlete</MenuItem>
                                                    {individuals.map((individual) => (
                                                        <MenuItem
                                                            key={individual.uuid}
                                                            value={individual.uuid}
                                                        >
                                                            {individual.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            {errorMessage && (
                                                <TypographyTitleh6
                                                    sx={{
                                                        textAlign: 'center',
                                                        color: 'red',
                                                    }}
                                                >
                                                    {errorMessage}
                                                </TypographyTitleh6>
                                            )}
                                            {!errorMessage && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <ButtonWithLoader
                                                        onClick={() => handleSubmit()}
                                                        disabled={submitting}
                                                        variant={'contained'}
                                                        loading={submitting}
                                                        value={
                                                            Object.keys(progress).length > 0
                                                                ? mean(Object.values(progress)) *
                                                                  100
                                                                : 0
                                                        }
                                                    >
                                                        Submit
                                                    </ButtonWithLoader>
                                                </Box>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </Stack>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackBarMessage !== ''}
                autoHideDuration={6000}
                onClose={() => setSnackBarMessage('')}
                message={snackBarMessage}
            ></Snackbar>
        </Container>
    );
};

export default GroupGpsUpload;
