import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, ButtonBase, Modal, Theme, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ButtonWithLoader } from 'components/Buttons';
import { SxProps } from '@mui/system';
import MuxPlayer from '@mux/mux-player-react';

export interface UploadVideoProps {
    loading?: boolean;
    uploadVideo: File | undefined;
    setUploadVideo: React.Dispatch<React.SetStateAction<File | undefined>>;
    defaultVideoSrc?: string | null;
    playbackId?: string | undefined;
    button?: boolean;
    uploadContainerClassname?: string;
    uploadedVideoClassname?: string;
    videoName?: 'video';
    onClose?: (file?: File) => void;
    uploadContainerSx?: SxProps<Theme>;
    uploadedVideoSx?: SxProps<Theme>;
    onMessage?: (message: string) => void;
    deleteVideo?: () => void;
}

const UploadVideo = ({
    loading,
    setUploadVideo,
    playbackId,
    button = true,
    videoName = 'video',
    onMessage = () => {},
    deleteVideo = () => {},
}: UploadVideoProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [videoFileName, setVideoFileName] = useState<string | null>(null);
    const [isVideoDeleted, setIsVideoDeleted] = useState(false);
    const [videoThumbnail, setVideoThumbnail] = useState<string | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    useEffect(() => {
        if (playbackId) {
            setVideoFileName(null);
            setUploadVideo(undefined);
            setVideoThumbnail(null);
        }
    }, [playbackId, setUploadVideo]);

    const setOriginalVideo = useCallback(
        (file: File) => {
            setUploadVideo(file);
            setVideoFileName(file.name);
            setIsVideoDeleted(false);

            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);

            /**
             * We can implement this check if we want to restrict the video format.
             */
            // videoElement.onloadedmetadata = () => {
            //     if (videoElement.videoWidth / videoElement.videoHeight != 16 / 9) {
            //         console.log(videoElement.videoWidth / videoElement.videoHeight, 16 / 9);
            //         onError && onError('The video must be in 16x9 format such as 1080p or 720p');
            //     }
            // };

            videoElement.currentTime = 1; // Seek to 1 second to get a frame in the middle of the video
            videoElement.onseeked = () => {
                const canvas = document.createElement('canvas');
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                console.log(canvas);
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                setVideoThumbnail(canvas.toDataURL());
            };
        },
        [onMessage, setUploadVideo], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                }}
            >
                {(playbackId || videoFileName) && !isVideoDeleted && (
                    <Button
                        sx={{
                            position: 'absolute',
                            bottom: '0',
                            right: '-16px',
                            cursor: 'pointer',
                            padding: '1px',
                            color: 'white',
                            height: '32px',
                            width: '32px',
                            minWidth: '32px',
                            background: 'rgba(0,0,0,0)',
                            borderRadius: '2px',
                            zIndex: 9999,
                        }}
                        onClick={() => {
                            setIsDeleteModalOpen(true);
                        }}
                    >
                        🗑️
                    </Button>
                )}
                <ButtonBase
                    onClick={
                        !playbackId || isVideoDeleted
                            ? () => fileInputRef.current?.click()
                            : undefined
                    }
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 320,
                        height: 180,
                        cursor: 'pointer',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        m: 6,
                        border:
                            (!playbackId && !videoThumbnail) || isVideoDeleted
                                ? '2px dashed rgba(87, 95, 107, 0.5)'
                                : 'none',
                        background:
                            'linear-gradient(0deg, rgba(0, 62, 157, 0.08), rgba(0, 62, 157, 0.08)), #FFFFFF',
                    }}
                >
                    {playbackId && !isVideoDeleted && !videoFileName && (
                        <MuxPlayer playbackId={playbackId} />
                    )}
                    {videoThumbnail && <img src={videoThumbnail} alt={videoFileName ?? ''} />}
                    {((!playbackId && !videoThumbnail) || isVideoDeleted) && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <AddOutlinedIcon sx={{ fontSize: 24 }} />
                            <Box>Add Video</Box>
                        </Box>
                    )}
                </ButtonBase>
                <div>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="video/*"
                        onChange={(e) => {
                            const files = e.target.files;
                            if (files && files.length > 0) setOriginalVideo(files[0]);
                        }}
                        onClick={(event) => {
                            if (event.currentTarget.value) event.currentTarget.value = '';
                        }}
                        hidden={true}
                    />
                </div>

                {button && (playbackId || videoThumbnail) && (
                    <ButtonWithLoader
                        loading={loading}
                        disabled={loading}
                        color="primary"
                        sx={{ minWidth: 192, minHeight: 56 }}
                        onClick={() => fileInputRef.current?.click()}
                        variant={'outlined'}
                    >
                        {`Replace ${videoName}`}
                    </ButtonWithLoader>
                )}
            </Box>
            <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                <Box
                    sx={{
                        padding: 8,
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        margin: 'auto',
                        background: 'white',
                        color: 'black',
                        height: '200px',
                        width: '300px',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '4px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <Typography>Are you sure you want to delete this video?</Typography>
                    <Box
                        sx={{
                            marginTop: 'auto',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            sx={{ marginRight: 2 }}
                            onClick={async () => {
                                deleteVideo();
                                setIsVideoDeleted(true);
                                setUploadVideo(undefined);
                                setIsDeleteModalOpen(false);
                                setVideoThumbnail(null);
                                onMessage('Video deleted successfully!');
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setIsDeleteModalOpen(false)}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export { UploadVideo };
