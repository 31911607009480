import { OpenInNew } from '@mui/icons-material';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { Box, Paper } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
    DataGridPremium,
    GridActionsCellItem,
    GridOverlay,
    GridRenderCellParams,
    GridSortItem,
    GridSortModel,
} from '@mui/x-data-grid-premium';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { PaginationLink } from 'utils';
import CustomDateRangePicker from '../../../components/FormControl/CustomDateRangePicker';
import CustomPagination from '../../../components/Functional/CustomPagination';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { useNonInitialEffect } from '../../../hooks';
import DimensionFilterBuilder from '../components/DimensionFilterBuilder';
import { getTemMotions } from '../home.api';
import { FactMotion } from '../home.types';

function TemDashboard() {
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any>([]);
    const [links, setLinks] = useState<PaginationLink>();
    const [dateRangeValue, setDateRangeValue] = useState<DateRange<DateTime>>([null, null]);
    const [individualFilters, setIndividualFilters] = useState<{ [key: string]: any }>({});
    const [temExerciseGuids] = useState<{ [key: string]: any }>({});
    const [groupFilters] = useState<{ [key: string]: any }>({});
    const { push } = useHistory();
    const [queryOptions, setQueryOptions] = useState<{
        sort?: string[];
        'filter[individual_uuid]'?: string;
        'filter[group_uuid]'?: string;
        'filter[tem_exercise_type_guid]'?: string;
        'filter[after]'?: string;
        'filter[before]'?: string;
        limit: number;
        page: number;
    }>({
        sort: ['date,desc'],
        limit: 100,
        page: 1,
    });
    const [debouncedQueryOptions] = useDebounce(queryOptions, 700);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        setQueryOptions((o) => ({
            ...o,
            sort: sortModel.map((item: GridSortItem) => `${item.field},${item.sort}`),
        }));
    }, []);

    const handleIndividualsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                'filter[individual_uuid]': value.join(','),
            });
        },
        [queryOptions],
    );

    const handleTemExercisesChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                'filter[tem_exercise_type_guid]': value.length > 0 ? value.join(',') : undefined,
            });
        },
        [queryOptions],
    );

    const handleGroupsFilterChanged = React.useCallback(
        (value: any) => {
            setQueryOptions({
                ...queryOptions,
                'filter[group_uuid]': value.length > 0 ? value.join(',') : undefined,
            });
            setIndividualFilters((filters) => ({
                ...filters,
                'filter[group_uuid]': value.length > 0 ? value.join(',') : undefined,
            }));
        },
        [queryOptions],
    );

    useNonInitialEffect(() => {
        let rangeQuery = {} as any;
        if (dateRangeValue[0]) {
            rangeQuery['filter[after]'] = dateRangeValue[0]?.toISODate();
        }
        if (dateRangeValue[1]) {
            rangeQuery['filter[before]'] = dateRangeValue[1]?.toISODate();
        }
        setQueryOptions((options) => {
            delete options['filter[after]'];
            delete options['filter[before]'];
            return {
                ...options,
                ...rangeQuery,
            };
        });
    }, [dateRangeValue]);

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'individual_name',
                headerName: 'Athlete',
                minWidth: 180,
                filterable: false,
                sortable: true,
            },
            {
                field: 'date',
                headerName: 'Date',
                minWidth: 120,
                filterable: false,
                sortable: true,
            },
            {
                field: 'side',
                headerName: 'Side',
                minWidth: 80,
                filterable: false,
                sortable: true,
            },
            {
                field: 'exercise_name',
                headerName: 'Exercise',
                minWidth: 60,
                filterable: false,
                sortable: false,
            },
            {
                field: 'con_mass',
                headerName: 'Resist',
                minWidth: 80,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{`${+(params?.value ?? 0)} kg`}</>
                ),
            },
            {
                field: 'ecc_mass',
                headerName: 'Assist',
                minWidth: 80,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{`${+(params?.value ?? 0)} kg`}</>
                ),
            },
            {
                field: 'peak_speed',
                headerName: 'Peak Speed',
                minWidth: 80,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{`${(+(params?.value ?? 0)).toFixed(2)} mph`}</>
                ),
            },
            {
                field: 'avg_speed',
                headerName: 'Avg Speed',
                minWidth: 80,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{`${(+(params?.value ?? 0)).toFixed(2)} mph`}</>
                ),
            },
            {
                field: 'peak_force',
                headerName: 'Peak Force',
                minWidth: 80,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{`${(+(params?.value ?? 0)).toFixed(2)} N`}</>
                ),
            },
            {
                field: 'avg_force',
                headerName: 'Avg Force',
                minWidth: 80,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{`${(+(params?.value ?? 0)).toFixed(2)} N`}</>
                ),
            },
            {
                field: 'peak_power',
                headerName: 'Peak Power',
                minWidth: 80,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{`${(+(params?.value ?? 0)).toFixed(2)} W`}</>
                ),
            },
            {
                field: 'avg_power',
                headerName: 'Avg Power',
                minWidth: 80,
                filterable: false,
                sortable: true,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <>{`${(+(params?.value ?? 0)).toFixed(2)} W`}</>
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: 'View',
                getActions: (params) => [
                    <GridActionsCellItem
                        key={'view-button'}
                        icon={<OpenInNew />}
                        onClick={() => {
                            push(
                                `/community/individuals/${params.row.individual_uuid}/performance/${params.row.upload_uuid}/motions/${params.row.tem_motion_guid}`,
                            );
                        }}
                        label={'View'}
                    />,
                ],
            },
        ],
        [push],
    );

    useEffect(() => {
        setLoading(true);
        getTemMotions(debouncedQueryOptions)
            .then((response) => {
                setRows(
                    response.data.data.map((motion: FactMotion) => ({
                        ...motion,
                        peak_speed: +(motion.peak_speed * 2.23694),
                        avg_speed: +(motion.avg_speed * 2.23694),
                    })),
                );
                setLinks(response.data.links);
            })
            .catch(() => {})
            .finally(() => setLoading(false));
    }, [debouncedQueryOptions]);
    const castIconStyle: React.CSSProperties = {
        animation: 'constantScale 3s infinite', // Apply the animation
        cursor: 'pointer',
    };
    const CustomNoRowsOverlay = () => {
        return (
            <GridOverlay>
                <>
                    <div>
                        You likely haven&apos;t connected your 1080 Motion API yet. Learn how by
                        clicking on the Learn Center at the top right of your screen:{' '}
                    </div>
                    <div
                        style={castIconStyle} // If castIconStyle includes cursor:pointer and other styles
                    >
                        <CastForEducationIcon
                            sx={{ marginRight: 8, marginLeft: 9 }}
                            // remove style from here
                        />
                    </div>
                </>
            </GridOverlay>
        );
    };
    return (
        <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ marginY: 4, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Box sx={{ padding: 2 }}>
                    <DimensionFilterBuilder
                        id={'filter-list-groups'}
                        resourcePath="groups"
                        buttonText="Group(s)"
                        onChange={handleGroupsFilterChanged}
                        otherFilters={groupFilters}
                    />
                </Box>
                <Box sx={{ padding: 2 }}>
                    <DimensionFilterBuilder
                        id={'filter-list-individuals'}
                        resourcePath="individuals"
                        buttonText="Athletes"
                        onChange={handleIndividualsFilterChanged}
                        otherFilters={individualFilters}
                    />
                </Box>
                <Box sx={{ padding: 2 }}>
                    <DimensionFilterBuilder
                        id={'filter-list-tem-exercises'}
                        resourcePath="tem-exercises"
                        buttonText="Exercise(s)"
                        onChange={handleTemExercisesChanged}
                        otherFilters={temExerciseGuids}
                    />
                </Box>
                <Box sx={{ padding: 2 }}>
                    <CustomDateRangePicker
                        value={dateRangeValue}
                        onChange={(newValue) => {
                            setDateRangeValue(newValue);
                        }}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    width: '100%',
                }}
            >
                <DataGridPremium
                    sx={{
                        backgroundColor: '#ffffff',
                        boxShadow: { lg: 3 },
                        borderRadius: { lg: 3 },
                        padding: { lg: 2 },
                        '.MuiDataGrid-virtualScrollerContent': {
                            height: '100%!important',
                        },
                    }}
                    autoHeight={false}
                    density="compact"
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pinnedColumns: { left: ['individual_name'] },
                        sorting: { sortModel: [{ field: 'date', sort: 'desc' }] },
                    }}
                    sortingMode="server"
                    loading={loading}
                    disableRowSelectionOnClick
                    slots={{
                        footer: CustomPagination,
                        loadingOverlay: CustomLoadingOverlay,
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    slotProps={{
                        footer: {
                            hasNext: (links && links.next !== null) ?? false,
                            hasPrevious: queryOptions.page > 1,
                            onNext: () => setQueryOptions((o) => ({ ...o, page: o.page + 1 })),
                            onPrevious: () => setQueryOptions((o) => ({ ...o, page: o.page - 1 })),
                            page: queryOptions.page,
                        },
                    }}
                    onSortModelChange={handleSortModelChange}
                />
            </Box>
        </Paper>
    );
}

export default React.memo(TemDashboard);
