import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logMovement as logActivity, startActivity } from 'api';
import { AxiosError } from 'axios';
import { Activity, MovementLogReq, MovementLogRes } from 'utils';
import { RootState } from '.';
import startActivityForSelf from '../../api/Logs/startActivityForSelf';

export interface ActivityError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export interface initialLogActivityState {
    data: Activity | MovementLogRes | null;
    error: ActivityError;
    isLoading: boolean;
}

export const initialState: initialLogActivityState = {
    data: null,
    error: { message: '' },
    isLoading: false,
};

export const start = createAsyncThunk<
    Activity,
    { local_timezone: string; individual: string; session?: string },
    { rejectValue: ActivityError }
>('logs/startActivity', async ({ local_timezone, individual, session }, { rejectWithValue }) => {
    try {
        const { data } = await startActivity(local_timezone, individual, session);
        return data;
    } catch (err: any) {
        const error: AxiosError<ActivityError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Loading Movements' });
    }
});

export const startForSelf = createAsyncThunk<
    Activity,
    { local_timezone: string; session?: string; event?: string },
    { rejectValue: ActivityError }
>('logs/startActivityForSelf', async ({ local_timezone, session, event }, { rejectWithValue }) => {
    try {
        const { data } = await startActivityForSelf(local_timezone, session, event);
        return data;
    } catch (err: any) {
        const error: AxiosError<ActivityError> = err;
        throw rejectWithValue(
            error.response?.data ?? { message: 'Error Starting Activity For Self' },
        );
    }
});

export const log = createAsyncThunk<
    MovementLogRes,
    { logedMovement: MovementLogReq; activityId: string },
    { rejectValue: ActivityError }
>('logs/logActivity', async ({ logedMovement, activityId }, { rejectWithValue }) => {
    try {
        const { data } = await logActivity({ ...logedMovement }, activityId);
        return data;
    } catch (err: any) {
        const error: AxiosError<ActivityError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error Logging Movements' });
    }
});

export const logsSlice = createSlice({
    name: 'log',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(start.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.error = { message: '' };
        });
        addCase(start.pending, (state) => {
            state.error = { message: '' };
        });
        addCase(start.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Loading Movements';
        });
        addCase(startForSelf.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.error = { message: '' };
        });
        addCase(startForSelf.pending, (state) => {
            state.error = { message: '' };
        });
        addCase(startForSelf.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Starting Session For Self';
        });
        addCase(log.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.error = { message: '' };
        });
        addCase(log.pending, (state) => {
            state.error = { message: '' };
        });
        addCase(log.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Loading Movements';
        });
    },
});

export const logsSelector = (state: RootState) => state.logs;
