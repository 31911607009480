import DoneIcon from '@mui/icons-material/Done';
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator,
} from '@mui/lab';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { convertDate, TimelineEntries } from 'utils';
import { TimelineCard } from './';

type DynamicTimelineItemProps = {
    data: TimelineEntries[];
    date: string;
    hideSegment: boolean;
};

const DynamicTimelineItem = ({ data, date, hideSegment }: DynamicTimelineItemProps) => {
    const theme = useTheme(); // For accessing the theme

    return (
        <TimelineItem
            sx={{
                '&:before': {
                    flex: 0,
                    [theme.breakpoints.down('sm')]: {
                        padding: '6px 0',
                    },
                },
            }}
        >
            <TimelineSeparator
                sx={{
                    '& .MuiTimelineConnector-root': {
                        display: hideSegment ? 'none' : 'block',
                    },
                }}
            >
                <TimelineDot
                    color="inherit"
                    sx={{
                        color: theme.palette.secondary.main,
                    }}
                    variant="outlined"
                >
                    <DoneIcon
                        sx={{
                            color: theme.palette.secondary.main,
                            fontSize: '14px',
                        }}
                        color="primary"
                    />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    textAlign: 'left',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '13px',
                        color: theme.palette.text.secondary,
                        marginBottom: '8px',
                        marginTop: '8px',
                        textAlign: 'left',
                    }}
                >
                    {convertDate(date)}
                </Typography>
                <TimelineCard data={data} />
            </TimelineContent>
        </TimelineItem>
    );
};

export { DynamicTimelineItem };
