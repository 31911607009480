import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { Request } from '../../../api';
import { Asset, AttributeValue, Individual, ResponseWithPagination } from '../../../utils';
import { ExternalId } from '../community.types';

export function updateIndividual(
    individualId: string,
    payload: {
        name: string;
        attributes: Array<Pick<AttributeValue, 'name' | 'value' | 'type'>>;
        profile_photo?: string;
    },
): Promise<AxiosResponse<Individual>> {
    return Request.put(`/individuals/${individualId}`, payload);
}

export function deleteIndividual(individualId: string): Promise<AxiosResponse<void>> {
    return Request.delete(`/individuals/${individualId}`);
}

export function getVideos(
    individualId: string,
    page: number,
): Promise<AxiosResponse<ResponseWithPagination<Asset>>> {
    return Request.get(`/individuals/${individualId}/videos`, { params: { page } });
}

export function createVideo(
    individualId: string,
    path: string,
    date?: DateTime | undefined,
): Promise<AxiosResponse<Asset>> {
    return Request.post(`/individuals/${individualId}/videos`, {
        path,
        datetime: date?.toFormat('yyyy-MM-dd HH:mm:ss'),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
}

export function deleteVideo(id: string, individualId: string): Promise<AxiosResponse<Asset>> {
    return Request.delete(`/individuals/${individualId}/videos/${id}`);
}

export function getExternalIds(individualId: string): Promise<AxiosResponse<Array<ExternalId>>> {
    return Request.get(`/individuals/${individualId}/external-ids`);
}

export function importTemData(individualId: string, params = {}): Promise<AxiosResponse<void>> {
    return Request.post(`/individuals/${individualId}/import-tem-data`, params);
}
