import ROUTES from '../Routes/routes';
import { Role, ShareableClassification } from './types';

export const getRedirectPath = (
    shareableType: ShareableClassification,
    shareableUuid: string,
    role: Role,
): string => {
    if (shareableType === 'session') {
        return ROUTES.ViewSession.path.replace(':id', shareableUuid);
    }

    if (shareableType === 'organization') {
        return '/organizations/:id/invitation-accepted'.replace(':id', shareableUuid);
    }

    if (shareableType === 'form') {
        if (role.name === 'form-admin') {
            return ROUTES.CreateAssessment.path.replace(':id', shareableUuid);
        } else if (role.name === 'form-manager') {
            return ROUTES.CreateAssessment.path.replace(':id', shareableUuid);
        } else if (role.name === 'form-viewer') {
            return ROUTES.ViewAssessment.path.replace(':id', shareableUuid);
        } else {
            return ROUTES.StartAssessmentForSelf.path.replace(':id', shareableUuid);
        }
    }

    if (shareableType === 'movement') {
        if (
            role.name === 'movement-admin' ||
            role.name === 'movement-manager' ||
            role.name === 'movement-viewer'
        ) {
            return ROUTES.ViewMovement.path.replace(':id', shareableUuid);
        } else {
            return ROUTES.ListOfMovements.path;
        }
    }
    if (shareableType === 'group') {
        return ROUTES.GroupDetails.path.replace(':id', shareableUuid);
    }

    if (shareableType === 'dashboard') {
        return `/community/dashboards/${shareableUuid}`;
    }

    return '';
};
