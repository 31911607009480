import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import DomainIcon from '@mui/icons-material/Domain';
import {
    Avatar,
    Box,
    Checkbox,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import moment from 'moment';
import React, { useState } from 'react';
import {
    archiveActivity,
    attestActivity,
    toggleSupportTeam,
    unarchiveActivity,
} from '../../../redux/reducers/timeline';
import { useAppDispatch } from '../../../redux/store';
import { Activity, getInitials, SupportTeam } from '../../../utils';
import { convertToSets } from '../plan.functions';
import { ActivityTable, ArchivedCard, UnreviewedCard } from './';

interface Props {
    onCloseClicked: () => void;
    isVisible: boolean;
    archivedActivities: Activity[];
    unattestedActivities: Activity[];
    supportTeam: { [key: string]: Array<SupportTeam> };
}

const FilterDialogContents: React.FC<React.PropsWithChildren<Props>> = ({
    onCloseClicked,
    archivedActivities,
    unattestedActivities,
    supportTeam,
}: Props) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const dispatch = useAppDispatch();
    const handleArchiveClicked = (activity: Activity): void => {
        dispatch(archiveActivity({ activity }));
    };
    const handleAttestClicked = (activity: Activity): void => {
        dispatch(attestActivity({ activity }));
    };
    const handleUnarchiveClicked = (activity: Activity): void => {
        dispatch(unarchiveActivity({ activity }));
    };

    const handleSupportTeamClicked = (id: string): void => {
        dispatch(toggleSupportTeam({ id }));
    };
    const theme = useTheme();

    return (
        <Box>
            <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={() => onCloseClicked()} aria-label="close" size="small">
                    <CloseIcon />
                </IconButton>
            </Box>
            <Tabs
                value={selectedTabIndex}
                onChange={(e, value) => setSelectedTabIndex(value)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example"
            >
                <Tab value={0} label="Unreviewed Logs" aria-label="unreviewed logs" />
                <Tab value={1} label="My Athlete Support Team" aria-label="care team" />
            </Tabs>
            <Box style={{ paddingTop: 24 }}>
                {selectedTabIndex === 0 && (
                    <>
                        <Typography sx={{ color: theme.palette.primary.light }}>
                            UNREVIEWED LOGS
                        </Typography>
                        {unattestedActivities.length === 0 && (
                            <Typography>All logs have been reviewed</Typography>
                        )}
                        {unattestedActivities.map((activity) => (
                            <UnreviewedCard
                                key={activity.uuid}
                                onArchiveClicked={() => handleArchiveClicked(activity)}
                                onAttestClicked={() => handleAttestClicked(activity)}
                                avatar={
                                    <Avatar
                                        variant="square"
                                        aria-label={`${activity.session?.title} photo`}
                                        src={activity.session?.cover_photo ?? ''}
                                    >
                                        {getInitials(activity.session?.title ?? '')}
                                    </Avatar>
                                }
                                title={`${activity.name} logged ${activity.session?.title} for you`}
                                subtitle={moment(
                                    activity.date ?? activity.started_at * 1000,
                                ).format('MMMM DD, YYYY')}
                                collapsableContent={
                                    <ActivityTable sets={convertToSets(activity.logs)} />
                                }
                            />
                        ))}

                        <Typography sx={{ color: theme.palette.primary.light }}>
                            ARCHIVED
                        </Typography>
                        {archivedActivities.length === 0 && (
                            <Typography>No archived logs</Typography>
                        )}
                        {archivedActivities.map((activity) => (
                            <ArchivedCard
                                key={activity.uuid}
                                onUnarchiveClicked={() => handleUnarchiveClicked(activity)}
                                avatar={
                                    <Avatar
                                        variant="square"
                                        aria-label={`${activity.session?.title} photo`}
                                        src={activity.session?.cover_photo ?? ''}
                                    >
                                        {getInitials(activity.session?.title ?? '')}
                                    </Avatar>
                                }
                                title={`${activity.name} logged ${activity.session?.title} for you`}
                                subtitle={moment(
                                    (activity.ended_at ?? activity.started_at) * 1000,
                                ).format('MMMM DD, YYYY @ hh:mma')}
                            />
                        ))}
                    </>
                )}
                {selectedTabIndex === 1 && (
                    <List aria-labelledby="careteam">
                        {Object.keys(supportTeam).map((organizationUuid) => (
                            <>
                                <ListItem key={organizationUuid}>
                                    <ListItemAvatar>
                                        <Avatar
                                            src={
                                                supportTeam[organizationUuid][0].organization
                                                    .image_urls[200] ?? ''
                                            }
                                            variant="square"
                                        >
                                            <DomainIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={supportTeam[organizationUuid][0].organization.name}
                                    />
                                </ListItem>
                                <List component="div" disablePadding>
                                    {supportTeam[organizationUuid].map((team) => (
                                        <ListItem
                                            key={team.uuid}
                                            button
                                            sx={{ paddingLeft: theme.spacing(24) }}
                                            dense
                                            onClick={() => handleSupportTeamClicked(team.uuid)}
                                        >
                                            <ListItemAvatar>
                                                <Avatar src={team.user.profile_photo ?? ''}>
                                                    <AccountCircleIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText id={team.uuid} primary={team.user.name} />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    checked={!team.revoked}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': team.uuid }}
                                                    edge="end"
                                                    onClick={(e) => {
                                                        handleSupportTeamClicked(team.uuid);
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        ))}
                    </List>
                )}
            </Box>
        </Box>
    );
};

export default FilterDialogContents;
