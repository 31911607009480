import React, { useState } from 'react';
import { Box, Tooltip } from '@mui/material';

interface HoverImageProps {
    src: string;
    alt: string;
    size?: number;
}

const HoverImage: React.FC<HoverImageProps> = ({ src, alt, size = 24 }) => {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <Tooltip
            title={
                <Box
                    component="img"
                    src={src}
                    alt={alt}
                    sx={{
                        width: 200,
                        height: 200,
                        objectFit: 'contain',
                    }}
                />
            }
            open={isHovering}
            disableFocusListener
            disableTouchListener
        >
            <Box
                component="img"
                src={src}
                alt={alt}
                sx={{
                    height: size,
                    width: size,
                    cursor: 'pointer',
                }}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            />
        </Tooltip>
    );
};

export default HoverImage;
