import { Request } from 'api';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<void>>;

const removeSharedUserForGroup = (id: string, userId: string): ReturnType => {
    return Request.delete(`/groups/${id}/users/${userId}`);
};

export default removeSharedUserForGroup;
