import { Request } from 'api';
import { AxiosResponse } from 'axios';

interface checkAdminResponse {
    is_global_admin: boolean;
    notifications: {
        pending_orders: number;
        pending_marketplace_resell_requests: number;
        pending_subscription_change_requests: number;
    };
}

type ReturnType = Promise<AxiosResponse<checkAdminResponse>>;

const checkAdminStatus = (): ReturnType => {
    return Request.get(`/admin/check`);
};
export default checkAdminStatus;
