import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Grid,
    Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { Individual } from 'utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomButton } from 'components/Buttons';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import useTheme from '@mui/material/styles/useTheme';

interface Props {
    individual: Individual;
    onClick: () => void;
    onInviteClicked: () => void;
}

function IndividualCard({ individual, onClick, onInviteClicked }: Props): ReactElement {
    const theme = useTheme();
    return (
        <Accordion>
            <AccordionSummary
                sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}
                expandIcon={<ExpandMoreIcon />}
            >
                <div style={{ alignItems: 'center', display: 'flex' }}>
                    <Avatar
                        sx={{ width: 25, height: 25 }}
                        src={individual.profile_photo}
                        variant="rounded"
                    />
                    <Grid container>
                        <Grid item>
                            <Typography variant="body1" sx={{ fontWeight: 450, marginLeft: 4 }}>
                                {individual.name}
                            </Typography>
                        </Grid>
                        {individual?.managing_user && (
                            <Grid item>
                                <Box display="flex" alignItems="center">
                                    <VerifiedUserIcon sx={{ color: '#1B9CEA' }} />
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: 400, color: '#A7ABB7', marginLeft: 1 }}
                                    >
                                        {individual?.managing_user?.user_name}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </div>
                <Grid container direction={'row'} alignItems={'center'}>
                    {(individual?.groups ?? []).length > 0 && (
                        <Typography
                            variant={'body2'}
                            sx={{ marginLeft: 20, marginRight: 10, fontWeight: 100 }}
                        >
                            Groups/Teams:{' '}
                        </Typography>
                    )}

                    {individual.groups?.map((g) => (
                        <Grid item key={g.uuid}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                    variant="rounded"
                                    src={g.logo_url}
                                    sx={{ marginLeft: 10, height: 20, width: 20 }}
                                />
                                <Typography
                                    style={{ marginLeft: 8, marginRight: 8, color: '#A7ABB7' }}
                                >
                                    {g.name}
                                </Typography>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Avatar
                    variant="rounded"
                    sx={{ height: '25px', width: '25px', marginLeft: theme.spacing(8) }}
                    src={individual.organization.image_urls.avatar ?? ''}
                />
            </AccordionSummary>
            <AccordionDetails>
                <Box display={'flex'}>
                    <CustomButton onClick={onClick}>Details</CustomButton>
                    {!individual?.managing_user && (
                        <CustomButton onClick={onInviteClicked}>Invite</CustomButton>
                    )}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default React.memo(IndividualCard);
