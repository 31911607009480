import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { EmptyCard } from '.';
import ClipStartTimeButtonGroupSelect from './ClipStartTimeButtonGroupSelect';
import { CircularProgressWithLabel } from 'components/Functional/CircularProgressWithLabel';
import useTheme from '@mui/material/styles/useTheme';

export interface UploadingCardProps {
    /** progress The progress the card displays for uploading */
    progress: number;
    /** text The display text */
    text: string;
    /** clipStartTime The start time of the clip */
    clipStartTime?: number;
    /** onClipStartTimeSelected Callback for when the clip start time is selected */
    onClipStartTimeSelected?: (clipStartTime: number) => void;
}

export default function UploadingCard({
    progress,
    text,
    clipStartTime,
    onClipStartTimeSelected,
}: UploadingCardProps): ReactElement {
    const theme = useTheme();

    return (
        <EmptyCard>
            <CircularProgressWithLabel value={progress} variant="determinate" />
            <Typography
                sx={{ margin: theme.spacing(2, 0), maxWidth: 124 }}
                noWrap={true}
                variant="body2"
            >
                {text}
            </Typography>

            {onClipStartTimeSelected && clipStartTime !== undefined && (
                <ClipStartTimeButtonGroupSelect
                    clipStartTime={clipStartTime}
                    onClipStartTimeSelected={onClipStartTimeSelected}
                />
            )}
        </EmptyCard>
    );
}
