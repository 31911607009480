import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Program } from 'utils';

type ReturnType = Promise<AxiosResponse<Program>>;

const createProgram = (name: string, organization: string): ReturnType => {
    return Request.post(`/workouts/programs`, {
        name,
        organization,
    });
};

export default createProgram;
