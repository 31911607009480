import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import React, { FC } from 'react';

type MobileFilterLayoutProps = {
    isMobile: boolean;
    showFiltersMobile: boolean;
    submitFilters: () => void;
    toggleMobileFilters: () => void;
    handleClearFilters: () => void;
};

const MobileFilterLayout: FC<React.PropsWithChildren<MobileFilterLayoutProps>> = ({
    children,
    isMobile,
    showFiltersMobile,
    submitFilters,
    toggleMobileFilters,
    handleClearFilters,
}) => {
    const handleShowResults = () => {
        toggleMobileFilters();
        submitFilters();
    };

    return (
        <>
            {isMobile && showFiltersMobile && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 16,
                        marginLeft: -16,
                        padding: 16,
                        boxShadow: '0px 4px 5px -3px rgb(0 0 0 / 20%)',
                        width: 'calc(100% + 32px)',
                    }}
                >
                    <CloseIcon onClick={toggleMobileFilters} />
                    <span style={{ fontSize: 20 }}>Filters</span>
                </div>
            )}
            {children}
            {isMobile && showFiltersMobile && (
                <div
                    style={{
                        height: 72,
                        display: showFiltersMobile ? 'flex' : 'none',
                        background: 'white',
                        boxShadow: '0px 1px 6px 0px rgb(0 0 0 / 20%)',
                        position: 'fixed',
                        bottom: 72,
                        width: '100vw',
                        marginLeft: -16,
                        padding: 16,
                        justifyContent: 'space-between',
                    }}
                >
                    <Button color="primary" onClick={handleClearFilters}>
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        onClick={handleShowResults}
                    >
                        Show Results
                    </Button>
                </div>
            )}
        </>
    );
};

export { MobileFilterLayout };
