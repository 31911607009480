import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import CustomButton from 'components/Buttons/CustomButton';

export interface CropImageModalProps {
    isOpen: boolean;
    uploadImage: string;
    buttonText?: string;
    close: Function;
    onComplete: (file: File) => void;
    initialCrop?: ReactCrop.Crop;
}

const CropImageModal = ({
    uploadImage,
    isOpen,
    close,
    buttonText,
    onComplete,
    initialCrop = {
        aspect: 1,
        width: 200,
        height: 200,
        x: 0,
        y: 0,
    },
}: CropImageModalProps) => {
    const imgRef = useRef<HTMLImageElement | null>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const [crop, setCrop] = useState<ReactCrop.Crop>(initialCrop);
    const [completedCrop, setCompletedCrop] = useState<ReactCrop.Crop>({});

    const onLoad = useCallback((img: any) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (completedCrop && previewCanvasRef.current && imgRef.current) {
            const image = imgRef.current;
            const canvas = previewCanvasRef.current;
            const crop = completedCrop;

            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;

            const ctx = canvas.getContext('2d');
            const pixelRatio = window.devicePixelRatio;

            const cropWidth = crop.height ?? 0;
            const cropHeight = crop.height ?? 0;
            const cropX = crop.x ?? 0;
            const cropY = crop.y ?? 0;

            canvas.width = cropWidth * pixelRatio;
            canvas.height = cropHeight * pixelRatio;

            if (ctx) {
                ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                ctx.imageSmoothingQuality = 'high';

                ctx.drawImage(
                    image,
                    cropX * scaleX,
                    cropY * scaleY,
                    cropWidth * scaleX,
                    cropHeight * scaleY,
                    0,
                    0,
                    cropWidth,
                    cropHeight,
                );
            }
        } else return;
    }, [completedCrop]);

    function onSave(canvas: HTMLCanvasElement | null, crop: ReactCrop.Crop) {
        if (!crop || !canvas) return;

        canvas.toBlob(
            (blob) => {
                onComplete(blob as File);
            },
            'image/png',
            1,
        );
    }

    return (
        <Dialog open={isOpen} onClose={() => close()} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                <Typography>
                    <b>Crop Your Image</b>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box>
                    {!!uploadImage && (
                        <ReactCrop
                            crop={crop}
                            circularCrop
                            src={uploadImage}
                            onImageLoaded={onLoad}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => setCompletedCrop(c)}
                            imageStyle={{ objectFit: 'contain', width: '100%' }}
                            minWidth={100}
                            minHeight={100}
                        />
                    )}
                    <canvas
                        ref={previewCanvasRef}
                        style={{
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0),
                            display: 'none',
                        }}
                    />
                </Box>
                <Box textAlign="center">
                    <CustomButton
                        variant="contained"
                        color="primary"
                        onClick={() => onSave(previewCanvasRef.current, completedCrop)}
                    >
                        {buttonText ?? 'Save'}
                    </CustomButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default CropImageModal;
