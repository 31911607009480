import CheckboxFilterList from '../../../components/Inputs/CheckboxFilterList';

interface Props {
    value: string[];
    onChange: (v: string[]) => void;
}

export function PersonalRecordFilter(props: Props) {
    return (
        <CheckboxFilterList
            options={[
                {
                    label: 'Personal Record (PR)',
                    value: 'FactLogsWithPersonalRecords.isPersonalRecord',
                },
            ]}
            buttonText={'PR'}
            {...props}
        />
    );
}
