import { TextField } from '@mui/material';
import CustomButton from 'components/Buttons/CustomButton';
import React, { useState } from 'react';
import { Box } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';

interface QuestionInputFormProps {
    onClick: (answer: string) => void;
}

const QuestionInputForm = ({ onClick }: QuestionInputFormProps) => {
    const [value, setValue] = useState('');
    const theme = useTheme();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    return (
        <Box
            sx={{
                padding: theme.spacing(2, 0),
                display: 'flex',
                flexDirection: 'column',
                [theme.breakpoints.down('sm')]: {
                    height: '100%',
                    justifyContent: 'space-between',
                },
            }}
        >
            <TextField
                fullWidth
                sx={{
                    background: theme.palette.backgroundColor.paper,
                    borderRadius: '4px 4px 0px 0px',
                    padding: theme.spacing(0, 6, 0, 4),
                    marginBottom: theme.spacing(16),
                }}
                placeholder="Type Answer"
                multiline
                rows={6}
                maxRows={6}
                value={value}
                onChange={handleChange}
                InputProps={{ disableUnderline: true }}
            />
            <CustomButton
                sx={{
                    width: '180px',
                    padding: theme.spacing(6, 16),
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                    },
                }}
                color="primary"
                variant="contained"
                disabled={!value}
                onClick={() => onClick(value)}
            >
                Submit Answer
            </CustomButton>
        </Box>
    );
};

export default QuestionInputForm;
