import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Role } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Role>>>;

const getRoles = (classification: string): ReturnType =>
    Request.get(`/roles`, {
        params: {
            'filter[classification]': classification,
        },
    });

export default getRoles;
