import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export type Color = 'success' | 'info' | 'warning' | 'error';

interface Props {
    open: boolean;
    message: string;
    severity?: Color;
    autoHide?: boolean;
    setOpen: (newVal: boolean) => void;
}

export default function CustomizedSnackbars({
    open,
    message,
    setOpen,
    autoHide = true,
    severity = 'success',
}: Props) {
    const theme = useTheme();
    const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Box
            sx={{
                width: '100%',
                '& > * + *': {
                    marginTop: theme.spacing(2),
                },
            }}
        >
            <Snackbar open={open} autoHideDuration={autoHide ? 6000 : null} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </Box>
    );
}
