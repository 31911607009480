import { AxiosResponse } from 'axios';
import { MarketplaceItem, SimplePaginateResponse } from '../app.types';
import { Request } from './index';

export const getMyMarketplaceItems = (
    organizationUuid: string,
    query: Record<string, unknown> = {},
): Promise<AxiosResponse<SimplePaginateResponse<MarketplaceItem>>> => {
    return Request.get(`/organizations/${organizationUuid}/marketplace/items`, {
        params: query,
    });
};

export const getResellableMarketplaceItems = (
    organizationUuid: string,
    query: Record<string, unknown> = {},
): Promise<AxiosResponse<SimplePaginateResponse<MarketplaceItem>>> => {
    return Request.get(`/organizations/${organizationUuid}/marketplace/resellable-items`, {
        params: query,
    });
};

export const updateItem = (
    organizationUuid: string,
    itemUuid: string,
    values: Record<'price', string>,
): Promise<AxiosResponse<MarketplaceItem>> => {
    return Request.put(`/organizations/${organizationUuid}/marketplace/items/${itemUuid}`, values);
};

export const createItem = (
    organizationUuid: string,
    uuid: string,
    type: 'session' | 'form',
    price: number,
    sales_type: string,
): Promise<AxiosResponse<MarketplaceItem>> => {
    return Request.post(`/organizations/${organizationUuid}/marketplace/items`, {
        uuid,
        type,
        price,
        sales_type,
    });
};

export const deleteItem = (
    organizationUuid: string,
    itemUuid: string,
): Promise<AxiosResponse<void>> => {
    console.log('deleteItem', itemUuid);
    return Request.delete(`/organizations/${organizationUuid}/marketplace/items/${itemUuid}`);
};

export const addMarketplaceItemForResale = (
    selectedResellItemUuid: string,
    organizationUuid: string,
): Promise<AxiosResponse<void>> => {
    return Request.post(
        `/organizations/${organizationUuid}/marketplace/items/${selectedResellItemUuid}/add-for-resale`,
    );
};

export const removeMarketplaceItemForResale = (
    selectedResellItemUuid: string,
    organizationUuid: string,
): Promise<AxiosResponse<void>> => {
    return Request.post(
        `/organizations/${organizationUuid}/marketplace/items/${selectedResellItemUuid}/remove-from-resale`,
    );
};
