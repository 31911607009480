import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';
import * as yup from 'yup';
import { Media } from '../../../app.types';
import { TypographyTitleh6 } from '../../../components';
import { UpdateMediaPayload } from '../media.api';
import DescriptionList from './DescriptionList';
import DescriptionListItem from './DescriptionListItem';
import ViewMediaImageOrVideo from './ViewMediaImageOrVideo';

interface Props {
    media: Media;
    onClose: () => void;
    updating: boolean;
    onUpdated: (media: Media, payload: UpdateMediaPayload) => void;
    onDelete: (media: Media) => void;
}

const form = yup.object({
    name: yup.string().default('').max(200).required('Title is required'),
    description: yup
        .string()
        .default('')
        .max(2000, 'Maximum length of the description is 500 characters')
        .nullable(),
});

function ViewEditMedia({
    media,
    onClose,
    onUpdated,
    onDelete,
    updating,
}: Props): React.ReactElement {
    const [mode, setMode] = useState<'edit' | 'view'>('view');
    return (
        <>
            <Box
                display="flex"
                alignItems={'center'}
                justifyContent="space-between"
                sx={{ borderBottom: '1px solid grey', padding: 20 }}
            >
                <Box>
                    <TypographyTitleh6
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {media.name}
                    </TypographyTitleh6>
                    {mode === 'view' && (
                        <Button onClick={() => setMode('edit')} color="primary">
                            Edit
                            <EditIcon />
                        </Button>
                    )}
                </Box>
                <IconButton onClick={() => onClose()} aria-label="close" size="medium">
                    <CloseIcon />
                </IconButton>
            </Box>
            {mode === 'edit' && (
                <Formik
                    initialValues={{
                        name: media.name ?? '',
                        description: media.description ?? '',
                    }}
                    validationSchema={form}
                    onSubmit={(values) => {
                        onUpdated(media, values);
                    }}
                >
                    <Form>
                        <Box display={'flex'} flexDirection={'column'} style={{ padding: 20 }}>
                            <Field
                                style={{ marginBottom: 16 }}
                                component={TextField}
                                variant="filled"
                                name="name"
                                type="text"
                                label="Title"
                            />
                            <Field
                                style={{ marginBottom: 16 }}
                                component={TextField}
                                variant="filled"
                                name="description"
                                type="text"
                                label="Description"
                                multiline
                                minRows={4}
                            />
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                            >
                                <Button
                                    disabled={updating}
                                    onClick={() => {
                                        onDelete(media);
                                    }}
                                    style={{ color: red[700] }}
                                >
                                    Delete
                                </Button>
                                <div>
                                    <Button onClick={() => setMode('view')}>Cancel</Button>
                                    <Button disabled={updating} color={'primary'} type={'submit'}>
                                        Save
                                    </Button>
                                </div>
                            </Box>
                        </Box>
                    </Form>
                </Formik>
            )}
            {mode === 'view' && (
                <Box style={{ padding: 20 }}>
                    <ViewMediaImageOrVideo
                        media={media}
                        style={{ width: '100%', objectFit: 'contain', height: 300 }}
                    />
                    <div>
                        <TypographyTitleh6>Information</TypographyTitleh6>
                        <DescriptionList>
                            <DescriptionListItem
                                word="Organization"
                                description={media.organization.name}
                            />
                            <DescriptionListItem
                                word="File Size"
                                description={`${(media.size / (1024 * 1024)).toFixed(2)}MB`}
                            />
                            <DescriptionListItem word="Category" description={media.category} />
                            <DescriptionListItem word="Status" description={media.status} />
                            <DescriptionListItem
                                word="Uploaded File Type"
                                description={media.mime_type}
                            />
                        </DescriptionList>
                    </div>
                    <div>
                        <TypographyTitleh6>Description</TypographyTitleh6>
                        <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            style={{ marginTop: 4 }}
                        >
                            {media.description && <p>{media.description}</p>}
                            {!media.description && (
                                <p
                                    style={{
                                        fontSize: '1rem',
                                        fontStyle: 'italic',
                                        color: grey[500],
                                    }}
                                >
                                    Add a description to this media.
                                </p>
                            )}
                        </Box>
                    </div>
                </Box>
            )}
        </>
    );
}

export default ViewEditMedia;
