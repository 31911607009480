import React, { useCallback } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Stack, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { MarketplaceItem } from '../../app.types';
import { updateItem } from '../../api/marketplace.api';
import { FormModal } from '../Dialogs';

const EditMarketplaceItem = ({
    open,
    handleClose,
    item,
    onSaved,
    onSaveError,
    isGoldSeller,
}: {
    open: boolean;
    handleClose: () => void;
    item: MarketplaceItem;
    onSaved: (item: MarketplaceItem) => void;
    onSaveError: () => void;
    isGoldSeller: boolean;
}) => {
    const saveItem = useCallback(
        (values: { price: string; resell_status: string }) => {
            // convert price into a number of cents
            const payload = {
                price: Math.round(parseFloat(values.price) * 100),
                resell_status: values.resell_status as 'enabled' | 'disabled' | 'pending',
            } as any;

            updateItem(item.organization_uuid, item.uuid, payload)
                .then((result) => {
                    onSaved(result.data);
                    handleClose();
                })
                .catch(() => {
                    onSaveError();
                });
        },
        [item, onSaved, handleClose, onSaveError],
    );

    const { values, errors, handleChange } = useFormik({
        initialValues: {
            price: (item.price / 100).toFixed(2),
            resell_status: item.resell_status,
        },
        validationSchema: yup.object({
            price: yup.string().required('Price is required'),
            resell_status: yup.string().required('Resell status is required'),
        }),
        onSubmit: () => {},
    });
    return (
        <FormModal
            handleClose={handleClose}
            open={open}
            handleButtonClicked={() => saveItem(values)}
            disabled={Boolean(errors.price)}
        >
            <form
                onSubmit={(e) => {
                    saveItem(values);
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <Stack spacing={8}>
                    <Typography>Enter a price</Typography>
                    <TextField
                        autoFocus={true}
                        id="price"
                        name="price"
                        inputProps={{
                            pattern: '[-.0-9]*',
                            inputMode: 'numeric',
                            type: 'text',
                        }}
                        placeholder="0.00"
                        label="Price"
                        value={values.price}
                        onChange={handleChange}
                        error={!!errors.price}
                        helperText={errors.price as string}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={
                                    values.resell_status === 'enabled' ||
                                    values.resell_status === 'pending'
                                }
                                onChange={(event) => {
                                    const newStatus = event.target.checked ? 'pending' : 'disabled';
                                    handleChange({
                                        target: {
                                            name: 'resell_status',
                                            value: newStatus,
                                        },
                                    });
                                }}
                                name="resell_status"
                                color="primary"
                                size="large"
                                disabled={!isGoldSeller}
                            />
                        }
                        label={
                            isGoldSeller
                                ? 'Allow other organizations to resell this item (check the box to have this item reviewed by an admin)'
                                : 'Allow other organizations to resell this item. Note: you must be a Gold subscriber to allow other organizations to resell your items'
                        }
                    />
                </Stack>
            </form>
        </FormModal>
    );
};

export default EditMarketplaceItem;
