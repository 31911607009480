import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import VimeoCard from '../components/VimeoCard';

function AdminAutomatedSprintDetection() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL performs all the heavy calculations for you. 1TUL automatically detects
                        and extracts the sprints then spearates, labels, and shows time and maxV.
                        Click on any sprint in the dropdown to see the details.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <VimeoCard
                            width={650}
                            videoUrl={
                                'https://player.vimeo.com/video/880307335?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479'
                            }
                            title={'Automated Sprint Extraction'}
                            description={'Automated Sprint Extraction'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default AdminAutomatedSprintDetection;
