import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Session } from 'utils';

type ReturnType = Promise<AxiosResponse<Session>>;

const createSession = (
    title: string,
    description: string,
    duration: number,
    organization: string | null = null,
): ReturnType => {
    return Request.post(`/workouts/sessions`, {
        title: title,
        description: description,
        duration: duration,
        organization,
    });
};

export default createSession;
