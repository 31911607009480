import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { EntityPermission } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<EntityPermission>>>;

const getCurrentUserPermissions = (id: string): ReturnType =>
    Request.get(`/users/${id}/authorization`);

export default getCurrentUserPermissions;
