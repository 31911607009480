import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { MovementLogReq, MovementLogRes } from 'utils';

type ReturnType = Promise<AxiosResponse<MovementLogRes>>;

const logMovement = (logedMovement: MovementLogReq, activityId: string): ReturnType => {
    return Request.post(`/workouts/activities/${activityId}/logs`, logedMovement);
};

export default logMovement;
