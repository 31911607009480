import { SearchClearableCloseableInput, SearchClearableInput } from 'components/Inputs';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { TypographySubtitle } from 'components/Typography';
import { CustomButton } from 'components/Buttons';
import FormModal from './FormModal';

interface ChoseItemModalProps {
    open: boolean;
    children: React.ReactNode;
    searchValue: string;
    buttonText: string;
    searchHeading: string;
    noResultText: string;
    searchResults: Array<any>;
    searchBarPlaceHolder: string;
    onClose: () => void;
    setSearchValue: (searchValue: string) => void;
    onClickButton?: React.MouseEventHandler;
}

const ChoseItemModalLayout = ({
    open,
    children,
    searchValue,
    buttonText,
    searchHeading,
    noResultText,
    searchResults,
    searchBarPlaceHolder,
    onClose,
    onClickButton,
    setSearchValue,
}: ChoseItemModalProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <FormModal
            noHeader
            noPadding
            open={open}
            loading={false}
            disabled={false}
            handleClose={onClose}
            contentClassnameSx={{
                padding: theme.spacing(0),
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                alignItems: 'flex-start',
            }}
        >
            {fullScreen ? (
                <SearchClearableCloseableInput
                    onClose={onClose}
                    value={searchValue}
                    placeholderText={searchBarPlaceHolder}
                    onChange={(newValue) => setSearchValue(newValue)}
                />
            ) : (
                <SearchClearableInput
                    value={searchValue}
                    placeholderText={searchBarPlaceHolder}
                    onChange={(newValue) => setSearchValue(newValue)}
                />
            )}
            {!!searchValue.length && !searchResults.length && onClickButton && (
                <CustomButton
                    fullWidth
                    variant="text"
                    color="primary"
                    startIcon={<AddIcon />}
                    sx={{ padding: theme.spacing(8), justifyContent: 'flex-start' }}
                    onClick={onClickButton}
                >
                    {buttonText}
                </CustomButton>
            )}
            <Box
                sx={{
                    width: '100%',
                    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 13,
                        fontWeight: 600,
                        margin: theme.spacing(2, 8),
                        color: theme.palette.text.secondary,
                    }}
                >
                    {searchValue.length ? 'SEARCH RESULT' : searchHeading}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                {children}
                {!!searchValue.length && !searchResults.length && (
                    <TypographySubtitle sx={{ margin: 'auto', padding: theme.spacing(8) }}>
                        {noResultText}
                    </TypographySubtitle>
                )}
            </Box>
        </FormModal>
    );
};

export default ChoseItemModalLayout;
