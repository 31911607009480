import { CardTitle } from './index';
import React from 'react';
import { Card } from '@mui/material';

interface Props {
    title: string;
    children: React.ReactNode;
}

export default function CardWithItems({ title, children }: Props) {
    return (
        <Card
            sx={{
                padding: 24,
                borderRadius: 16,
                boxShadow:
                    '0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)',
            }}
        >
            <CardTitle>{title}</CardTitle>
            {children}
        </Card>
    );
}
