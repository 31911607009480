import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userLogin, userRegister } from 'api';
import { AxiosError } from 'axios';
import { RootState } from '.';
import { PublicUser } from '../../utils';

export interface AuthError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export interface AuthState {
    isAuth: boolean;
    error: AuthError;
    isLoading: boolean;
    currentUser?: CurrentUser;
    organizationsLoadKey: number;
}

export interface CurrentUser {
    expiresAt: string;
    accessToken: string;
}

export interface RegisteredUser {
    token: CurrentUser;
    user: PublicUser;
}

export const initialState: AuthState = {
    isAuth: false,
    isLoading: false,
    error: { message: 'An Error occurred' },
    organizationsLoadKey: 0,
};

export const loginUser = createAsyncThunk<
    CurrentUser,
    { username: string; password: string },
    {
        rejectValue: AuthError;
    }
>('user/login', async ({ username, password }, { rejectWithValue }) => {
    try {
        const { data } = await userLogin(username, password);
        return {
            accessToken: data.access_token,
            expiresAt: data.expires_at,
        };
    } catch (err: any) {
        const error: AxiosError<AuthError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const registerUser = createAsyncThunk<
    RegisteredUser,
    {
        email: string;
        firstName: string;
        lastName: string;
        password: string;
        password_confirmation: string;
        user_name: string;
        phone: string;
    },
    {
        rejectValue: AuthError;
    }
>(
    'user/register',
    async (
        { email, firstName, lastName, password, password_confirmation, phone, user_name },
        { rejectWithValue },
    ) => {
        try {
            const { data } = await userRegister(
                email,
                firstName,
                lastName,
                password,
                password_confirmation,
                phone,
                user_name,
            );
            return {
                token: {
                    accessToken: data.token.access_token,
                    expiresAt: data.token.expires_at,
                },
                user: data.user,
            } as RegisteredUser;
        } catch (err: any) {
            const error: AxiosError<AuthError> = err;
            return rejectWithValue(error.response?.data ?? { message: 'Error' });
        }
    },
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
        setCurrentUser: (state, { payload }: PayloadAction<CurrentUser>) => {
            state.currentUser = payload;
            state.isAuth = true;
        },
        logOut: (state) => {
            state.isAuth = false;
            state.currentUser = undefined;
        },
        logOutFromAllDevices: (state) => {
            state.isAuth = false;
            state.currentUser = undefined;
        },
        setAuthFailed: (state, { payload }: PayloadAction<AuthError>) => {
            state.error = payload;
            state.isLoading = false;
            state.isAuth = false;
        },
        clearAuthFailed: (state) => {
            state.error = { message: '' };
            state.isLoading = false;
        },
        shuffleOrganizationsLoadKey: (state) => {
            state.organizationsLoadKey += 1;
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(loginUser.fulfilled, (state, { payload }) => {
            state.currentUser = payload;
            state.error = { message: '' };
            state.isAuth = true;
            state.isLoading = false;
        });
        addCase(loginUser.pending, (state) => {
            state.currentUser = undefined;
            state.error = { message: '' };
            state.isAuth = false;
            state.isLoading = true;
        });
        addCase(loginUser.rejected, (state, { payload }) => {
            state.currentUser = undefined;
            state.error.message = payload?.message ?? 'Error';
            state.isAuth = false;
            state.isLoading = false;
        });
        addCase(registerUser.fulfilled, (state, { payload }) => {
            state.currentUser = payload.token;
            state.isAuth = true;
            state.error = { message: '' };
            state.isLoading = false;
        });
        addCase(registerUser.pending, (state) => {
            state.currentUser = undefined;
            state.error = { message: '' };
            state.isAuth = false;
            state.isLoading = true;
        });
        addCase(registerUser.rejected, (state, { payload }) => {
            state.currentUser = undefined;
            state.error = payload ?? { message: 'Error', errors: {} };
            state.isAuth = false;
            state.isLoading = false;
        });
    },
});

export const {
    logOut,
    logOutFromAllDevices,
    setLoading,
    setAuthFailed,
    setCurrentUser,
    clearAuthFailed,
    shuffleOrganizationsLoadKey,
} = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;
