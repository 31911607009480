import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StreamChat } from 'stream-chat';
import { userChatToken, userSelector } from '../redux/reducers/user';
import { UserData } from '../utils';

interface Props {
    client?: StreamChat;
    userConnected: boolean;
    userData?: UserData;
}

export const ChatContext = React.createContext<Props>({
    client: undefined,
    userConnected: false,
    userData: undefined,
});

export function ChatProvider({ children }: { children: React.ReactNode }) {
    const chatToken = useSelector(userChatToken);
    const { userData } = useSelector(userSelector);
    const [client, setClient] = React.useState<StreamChat>();
    const [userConnected, setUserConnected] = React.useState(false);

    useEffect(() => {
        // Initialize the client only once
        if (!client) {
            const newClient = StreamChat.getInstance(process.env.REACT_APP_GET_STREAM_API_KEY!);
            setClient(newClient);
        }

        // Cleanup function to disconnect when component unmounts
        return () => {
            if (client) {
                client.disconnectUser();
            }
        };
    }, [client]); // Empty array ensures this effect runs only once

    const connectUser = useCallback(async () => {
        if (chatToken && client && userData) {
            try {
                await client.connectUser({ id: userData.uuid }, chatToken);
                console.log('User connected');
                setUserConnected(true);
            } catch (e) {
                console.error('Failed to connect user:', e);
                setUserConnected(false);
            }
        }
    }, [chatToken, client, userData]);

    // Attempt to connect the user whenever chatToken changes and client is initialized
    useEffect(() => {
        connectUser();
    }, [chatToken, connectUser]);

    return (
        <ChatContext.Provider value={{ client, userConnected, userData }}>
            {children}
        </ChatContext.Provider>
    );
}
