import { RouteConfigProps } from '../../Layouts/routeconfig';
import NavigationLayout from '../../Layouts/NavigationLayout';
import { Navigation } from '../../utils';
import { MyFeed, Posts, SocialProfile } from './scenes';

export const socialRoutes: Array<RouteConfigProps> = [
    {
        exact: true,
        layout: NavigationLayout,
        path: '/social/my-posts',
        private: true,
        primaryNavigation: Navigation.SOCIAL,
        secondaryNavigation: Navigation.SOCIAL_FEEDS,
        component: MyFeed,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/social/feed',
        private: true,
        component: Posts,
        primaryNavigation: Navigation.SOCIAL,
        secondaryNavigation: Navigation.SOCIAL_POSTS,
    },
    {
        component: SocialProfile,
        exact: true,
        layout: NavigationLayout,
        path: '/@:id',
        private: false,
    },
];
