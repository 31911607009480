import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Activity } from 'utils';

type ReturnType = Promise<AxiosResponse<Activity>>;

const endActivity = (local_timezone: string, activity: string): ReturnType => {
    return Request.post(`/workouts/activities/${activity}/end`, { local_timezone });
};
export default endActivity;
