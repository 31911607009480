import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

import SearchIcon from '@mui/icons-material/Search';
import StorageIcon from '@mui/icons-material/Storage';
import { Avatar, Box, Button, Card, CardHeader, Input, Typography } from '@mui/material';
import useTheme from '@mui/system/useTheme';
import { get } from 'lodash';
import React from 'react';
import { Session } from '../../../utils';

interface Props {
    objects: { [key: string]: Session };
    selectedObjects: { [key: string]: any };
    onChange: (objects: { [key: string]: any }) => void;
    searchValue: string;
    searchValueChanged: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;

    searchPlaceholder?: string;
    noneFoundText?: string;
    notSelectedText?: string;
    coverPhotoObjectPath?: string;
}

const SelectObjectModalContents: React.FC<React.PropsWithChildren<Props>> = ({
    objects,
    selectedObjects,
    onChange,
    searchValue,
    searchValueChanged,
    searchPlaceholder = 'Search Sessions...',
    noneFoundText = 'No Sessions Found',
    notSelectedText = 'Add Session',
    coverPhotoObjectPath = 'cover_photo',
}: Props) => {
    const theme = useTheme();

    return (
        <Box display="relative" overflow="auto" marginBottom={20}>
            <Input
                onChange={searchValueChanged}
                value={searchValue}
                sx={{
                    width: '100%',
                    borderRadius: '32px',
                    padding: theme.spacing(5),
                    margin: theme.spacing(4, 0, 4),
                    background: theme.palette.background.paper,
                    color: theme.palette.text.secondary,

                    '&&&:before': {
                        borderBottom: 'none',
                    },
                    '&&:after': {
                        borderBottom: 'none',
                    },
                    '&::placeholder': {
                        color: theme.palette.primary.main,
                        marginLeft: theme.spacing(6),
                    },

                    [theme.breakpoints.down('sm')]: {
                        borderRadius: '0px',
                        margin: theme.spacing(0),
                    },
                    marginLeft: theme.spacing(6),
                }}
                startAdornment={<SearchIcon />}
                placeholder={searchPlaceholder}
            />
            <div style={{ overflowY: 'auto' }}>
                {Object.keys(objects).length === 0 && (
                    <Typography variant={'body1'}>{noneFoundText}</Typography>
                )}
                {Object.keys(objects).map((key) => (
                    <Card style={{ width: '100%', marginTop: 16 }} key={objects[key].uuid}>
                        <CardHeader
                            avatar={
                                <Avatar
                                    src={get(objects[key], coverPhotoObjectPath) ?? ''}
                                    variant="square"
                                >
                                    <DirectionsRunIcon />
                                </Avatar>
                            }
                            title={
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {objects[key].title}
                                </Typography>
                            }
                            action={
                                <Box display={'flex'} alignItems={'center'}>
                                    <Avatar
                                        style={{ marginRight: 4 }}
                                        src={objects[key].organization.image_urls[200] ?? ''}
                                    >
                                        <StorageIcon />
                                    </Avatar>
                                    <Button
                                        color="primary"
                                        variant={selectedObjects[key] ? 'outlined' : 'contained'}
                                        onClick={() => {
                                            if (selectedObjects[key]) {
                                                delete selectedObjects[key];
                                                onChange(selectedObjects);
                                            } else {
                                                onChange({
                                                    ...selectedObjects,
                                                    [key]: objects[key],
                                                });
                                            }
                                        }}
                                    >
                                        {selectedObjects[key] ? 'Selected' : notSelectedText}
                                    </Button>
                                </Box>
                            }
                            sx={{ margin: 0 }}
                        />
                    </Card>
                ))}
            </div>
        </Box>
    );
};

export default SelectObjectModalContents;
