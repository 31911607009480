import { Box } from '@mui/material';
import React from 'react';
import { formatCurrency } from '../../../app.functions';

export const AnalyticsTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        return (
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    p: 2,
                    borderRadius: 1,
                    boxShadow: 1,
                }}
            >
                <Box sx={{ mb: 1, fontWeight: 'bold' }}>{payload[0].payload.date}</Box>
                <Box>
                    {formatCurrency({
                        amount: payload[0].value,
                        currency: 'USD',
                    })}
                </Box>
            </Box>
        );
    }
    return null;
};
