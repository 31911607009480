import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { TypographyTitle } from '../../../components';
import { useAppDispatch } from '../../../redux/store';
import { ResetPasswordPayload } from '../auth.schemas';
import { ResetPasswordDto } from '../auth.types';
import ResetPasswordForm from '../components/ResetPasswordForm';
import { authActions, authSelectors } from '../store';

const ResetPassword: React.FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useAppDispatch();
    const { push } = useHistory();
    const fromResetStore = useSelector(authSelectors.resetSelector);
    const [token] = useQueryParam('token', StringParam);
    const theme = useTheme();

    const onFormSubmitted = async (payload: ResetPasswordPayload) => {
        const result = await dispatch(
            authActions.resetPassword({
                ...payload,
                token,
            } as ResetPasswordDto),
        );

        if (authActions.resetPassword.fulfilled.match(result)) {
            push('/login', {
                referrer_message: `Password reset. Enter your new password to log in`,
            });
        }
    };
    return (
        <Paper
            elevation={3}
            sx={{
                padding: theme.spacing(24, 16),
            }}
        >
            <TypographyTitle align="center">
                Enter Username And Choose A New Password
            </TypographyTitle>
            <ResetPasswordForm
                onSubmit={onFormSubmitted}
                loading={fromResetStore.loading}
                errorMessage={fromResetStore.errorMessage}
                errors={fromResetStore.errors}
            />
        </Paper>
    );
};

export default ResetPassword;
