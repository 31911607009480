import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Container, Fab, Skeleton, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getFormDetails, startNewFormSubmissionForSelf } from '../../../api';
import { ResponsiveImage } from '../../../components';
import ROUTES from '../../../Routes/routes';
import { Form, getHtml } from '../../../utils';

const StartForSelf: FC<React.PropsWithChildren<unknown>> = () => {
    const [error, setError] = useState<string>('');
    const [form, setForm] = useState<Form>();
    const [loading, setLoading] = useState(true);
    const { push } = useHistory();
    const { id } = useParams<{ id: string }>();
    const [creatingSubmission, setCreatingSubmission] = useState(false);
    const theme = useTheme();
    const startNewSubmissionForSelf = async (): Promise<void> => {
        if (!form) {
            return;
        }
        setCreatingSubmission(true);
        try {
            const response: any = await startNewFormSubmissionForSelf(form.uuid, {
                date: DateTime.now(),
            });
            push(`${ROUTES.CompleteAssessment.path.replace(':id', response.data.uuid)}?start=1`);
        } catch (err: any) {
            setCreatingSubmission(false);
            console.log('error', err);
        }
    };

    useEffect(() => {
        let isMounted = true;

        getFormDetails(id)
            .then((response) => isMounted && setForm(response.data))
            .catch(() => {
                setError("Couldn't Load Assessment");
            })
            .finally(() => isMounted && setLoading(false));

        return () => {
            isMounted = false;
        };
    }, [id]);
    return (
        <Container sx={{ position: 'relative' }} maxWidth={'xl'}>
            {loading && <Skeleton width={375} height={500} />}
            {!loading && error && <Typography variant={'h2'}>{error}</Typography>}
            {!loading && form && (
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Typography variant={'h2'}>{form.title}</Typography>
                    <ResponsiveImage style={{ maxWidth: '100%' }} src={form.image_urls} />
                    <Box
                        dangerouslySetInnerHTML={{
                            __html: getHtml(form.description),
                        }}
                    />
                </Box>
            )}
            <Fab
                disabled={creatingSubmission}
                color="primary"
                sx={{
                    bottom: 80,
                    left: '50%',
                    position: 'fixed',
                    transform: 'translateX(-50%)',
                    zIndex: 1102,
                    [theme.breakpoints.up('md')]: {
                        bottom: 39,
                    },
                }}
                variant="extended"
                onClick={() => startNewSubmissionForSelf()}
            >
                <Box mr={5}>Begin Assessment</Box>
                <ArrowForwardIcon />
            </Fab>
        </Container>
    );
};

export default StartForSelf;
