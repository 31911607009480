import {
    Avatar,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import React from 'react';
import { grey } from '@mui/material/colors';
import { DateTime } from 'luxon';
import { Comment } from '../../../utils';
import { Delete } from '@mui/icons-material';

interface Props {
    comments: Comment[];
    canDelete: boolean;
    deleted: (comment: Comment) => void;
}

function CommentList({ comments, deleted, canDelete = false }: Props) {
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {comments.map((comment) => (
                <React.Fragment key={comment.uuid}>
                    <ListItem
                        secondaryAction={
                            canDelete ? (
                                <IconButton
                                    onClick={() => deleted(comment)}
                                    edge="end"
                                    aria-label="delete"
                                >
                                    <Delete />
                                </IconButton>
                            ) : (
                                <></>
                            )
                        }
                        alignItems="flex-start"
                    >
                        <ListItemAvatar>
                            <Avatar
                                alt={comment.user.name}
                                src={comment.user.profile_photo ?? ''}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline', fontWeight: 'bold' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {comment.user.name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            display: 'inline',
                                            fontWeight: 'normal',
                                            color: grey[600],
                                            ml: 4,
                                        }}
                                        component="span"
                                        variant="body2"
                                    >
                                        {comment.comment}
                                    </Typography>
                                </React.Fragment>
                            }
                            secondary={DateTime.fromMillis(comment.created_at * 1000).toRelative()}
                        />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                </React.Fragment>
            ))}
        </List>
    );
}

export default CommentList;
