import React, { useState } from 'react';
import { Card, CardContent, Dialog, DialogContent, Grid, Typography } from '@mui/material';

interface FullScreenDialogCardProps {
    title?: string;
    headline?: string;
    description?: string;
    height?: number;
    iframeSrc: string;
}

const ScribeCard: React.FC<React.PropsWithChildren<FullScreenDialogCardProps>> = ({
    title,
    headline,
    description,
    height = 720,

    iframeSrc,
}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card
                onClick={handleOpen}
                sx={{
                    cursor: 'pointer',
                    transition: 'box-shadow 0.3s',
                    '&:hover': {
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
                    },
                }}
            >
                <div
                    style={{
                        height: '660px',
                        width: '100%',
                        overflow: 'hidden',
                        position: 'relative',
                    }}
                >
                    <iframe
                        title={title}
                        src={iframeSrc}
                        style={{
                            height: '100%',
                            width: '100%',
                            border: 'none',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                        }}
                        allow={'autoplay'}
                    ></iframe>
                </div>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {headline}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </Card>

            <Dialog open={open} onClick={handleClose} maxWidth="md" fullWidth>
                <DialogContent sx={{ width: '2x', height: '2x' }}>
                    <Card sx={{ cursor: 'zoom-out' }}>
                        <div
                            style={{
                                height: `${height}px`,
                                width: '100%',
                                overflow: 'hidden',
                                position: 'relative',
                            }}
                        >
                            <iframe
                                title={title}
                                src={iframeSrc}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    border: 'none',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                }}
                            ></iframe>
                        </div>
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                {headline}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" fontSize={35}>
                                {description}
                            </Typography>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export default ScribeCard;
