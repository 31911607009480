import { styled } from '@mui/system';
import MuiTabs from '@mui/material/Tabs';

const Tabs = styled(MuiTabs)(({ theme }) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& .MuiTab-wrapper': {
            [theme.breakpoints.down('md')]: {
                fontSize: '0.77rem',
            },
        },
    },
    indicator: {
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('sm')]: {
            background: theme.palette.primary.main,
        },
    },
}));

export default Tabs;
