import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { Invite } from 'utils';

type ReturnType = Promise<AxiosResponse<Array<Invite>>>;

const getInvitesForForm = (id: string): ReturnType => {
    return Request.get(`/data/forms/${id}/invites`);
};

export default getInvitesForForm;
