import { CheckCircleOutline, NoteAdd, StarHalf } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { updateActivity } from 'api';
import { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Activity, getHtml, Session } from 'utils';
import { Can } from '../../components';
import LogSetSummary from './LogSetSummary';

export interface LogSummaryProps {
    activity: Activity;
}

export interface EditSessionRefProps {
    onSessionSaved: () => Promise<AxiosResponse<Session>> | null;
    onSessionShareDrawerOpened: () => void;
    getSession: () => Session | null;
}

const LogSummary = ({ activity }: LogSummaryProps) => {
    const theme = useTheme();
    const [showNotes, setShowNotes] = useState<boolean>(false);
    const { push } = useHistory();
    // Convert markdown to HTML
    const descriptionHTML: string = getHtml(activity?.session?.description || '');

    activity.logs.sort((a1, a2) => (a1?.order || 0) - (a2?.order || 0));

    // Calculate counts for summary
    const completedLogCount = activity.logs.filter((log) => log.marked_as_complete).length;
    const skippedLogCount = activity.logs.length - completedLogCount;

    const startedAt = moment.utc(activity.date).format('MM/DD/YYYY');

    const updateNote = debounce((newNote: string) => {
        updateActivity(activity.uuid, {
            notes: newNote,
        });
    }, 2000);

    return (
        <>
            {activity.session ? (
                <Container
                    sx={{
                        marginBottom: theme.spacing(10),
                    }}
                    maxWidth="md"
                >
                    <Box
                        pt={8}
                        textAlign="center"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Box>
                            {activity.individual && (
                                <Can I={'individual:manage-logs'} this={activity.individual}>
                                    <Button
                                        onClick={() => push(`/train/activities/${activity.uuid}`)}
                                        variant={'outlined'}
                                    >
                                        Edit Log
                                    </Button>
                                </Can>
                            )}
                        </Box>
                        <Box>
                            <IconButton onClick={() => setShowNotes((show) => !show)} size="large">
                                <NoteAdd />
                            </IconButton>
                        </Box>

                        {showNotes && (
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        name="duration"
                                        variant="filled"
                                        label="Add your notes here"
                                        inputProps={{
                                            maxLength: 10000,
                                        }}
                                        fullWidth
                                        type="text"
                                        multiline
                                        rows={3}
                                        defaultValue={activity?.notes || ''}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            updateNote(event.target.value);
                                            activity.notes = event.target.value;
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {/* Duration */}
                    </Box>
                    {/* Athlete details */}
                    <Box pb={1} textAlign="center">
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Grid item xs={1} alignItems="center" justifyContent="center">
                                <Avatar
                                    variant="rounded"
                                    src={activity.individual?.profile_photo}
                                    sx={{
                                        boxShadow:
                                            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                                        marginBottom: 6,
                                        width: 75,
                                        height: 75,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" color="textPrimary">
                                    {activity.individual?.name || ''} performed{' '}
                                    {activity.session.title} on {startedAt}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    {activity.session.cover_photo && (
                        <Box textAlign="center" sx={{ paddingTop: 4, paddingBottom: 4 }}>
                            <Box
                                component="img"
                                src={activity.session.cover_photo}
                                alt={activity.session.title || ''}
                                sx={{
                                    whiteSpace: 'pre-line',
                                }}
                            />
                        </Box>
                    )}
                    {activity.session.organization && (
                        <Box pt={5} pb={5}>
                            <Typography variant="body2" align="center">
                                This session belongs to {activity.session.organization?.name}.
                            </Typography>
                        </Box>
                    )}
                    {/* Description */}
                    <Box pt={8} pb={8} textAlign="center">
                        <div
                            style={{ textAlign: 'left' }}
                            dangerouslySetInnerHTML={{ __html: descriptionHTML }}
                        />
                    </Box>
                    {/* Summary counts */}
                    <Box pb={8} textAlign="center">
                        {skippedLogCount == 0 && (
                            <>
                                <CheckCircleOutline
                                    color="secondary"
                                    sx={{
                                        verticalAlign: 'bottom',
                                    }}
                                />{' '}
                                <Typography variant="caption" color="textSecondary">
                                    All session sets were completed
                                </Typography>
                            </>
                        )}
                        {completedLogCount == 0 && (
                            <>
                                <CheckCircleOutline
                                    sx={{
                                        verticalAlign: 'bottom',
                                    }}
                                />{' '}
                                <Typography variant="caption" color="textSecondary">
                                    All session sets were skipped
                                </Typography>
                            </>
                        )}
                        {skippedLogCount > 0 && completedLogCount > 0 && (
                            <>
                                <StarHalf
                                    sx={{
                                        verticalAlign: 'bottom',
                                    }}
                                />{' '}
                                <Typography variant="caption" color="textSecondary">
                                    You completed {completedLogCount} set(s) from this session and
                                    skipped {skippedLogCount} set(s)
                                </Typography>
                            </>
                        )}
                    </Box>
                    <LogSetSummary logs={activity.logs} />
                </Container>
            ) : (
                <Box pt={20} display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

LogSummary.displayName = 'LogSummary';

export default LogSummary;
