import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Grid,
    Paper,
    Slide,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip as TooltipMui,
    Typography,
    Zoom,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect, useState } from 'react';
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Scatter,
    ScatterChart,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Individual } from '../../../utils';
import { displayDistanceByUnit } from '../community.func';
import { ChartData, PerformanceDataUpload, Sprint } from '../community.types';
import ChartGradientPaper from '../components/Charting/ChartGradientPaperContainer';

interface Props {
    individual: Individual;
    upload: PerformanceDataUpload;
    sprint: Sprint;
    isMobileDevice: boolean;
    distanceUnit: 'm' | 'yd' | 'ft';
    hideFVP?: boolean;
    sideBySide?: boolean;
    onDeleted: () => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function displayMpsToMph(mps: number | null) {
    return mps ? (mps * 2.23694).toFixed(2) : '-';
}

function getSplitTimeFromSprint(sprint: Sprint, start: number, end: number) {
    const splitTimes = sprint.all_splits;
    if (!splitTimes) return null;

    const splitTime = splitTimes.find(
        (split) => split.start_distance === start && split.end_distance === end,
    );

    return splitTime?.split_time ? splitTime.split_time.toFixed(2) : null;
}

function ForceVelocityProfileSprint({
    sprint,
    isMobileDevice,
    distanceUnit,
    hideFVP,
    sideBySide,
}: Props) {
    const [openModal, setOpenModal] = React.useState(false);
    const [data, setData] = useState<ChartData[]>([]);
    const [decelData, setDecelData] = useState<any[]>([]);
    const [YaxisMax, setYaxisMax] = useState<number>(0);

    useEffect(() => {
        setYaxisMax(Math.max(...data.map((d) => d['Velocity (m/s)'])) + 3);
    }, [data]);

    useEffect(() => {
        if (!sprint) return;

        // generate fvp - time data
        setData(
            sprint.raw_data && sprint.raw_data.length > 0
                ? sprint.raw_data.map((dataElement) => ({
                      'Adjusted Time (s)': parseFloat(dataElement['Adjusted Time (s)'].toFixed(2)),
                      'Velocity (m/s)': +(
                          Math.abs(dataElement['Velocity (m/s)']) * 2.23694
                      ).toFixed(2),
                      'Model Velocity (m/s)': +(
                          dataElement['Model Velocity (m/s)'] * 2.23694
                      ).toFixed(3),
                      'Model Acceleration (m/s2)':
                          +dataElement['Model Acceleration (m/s2)'].toFixed(2),
                      'Model Force (N)': +dataElement['Model Force (N)'].toFixed(3),
                      'Model Force (N/kg)': +dataElement['Model Force (N/kg)'].toFixed(3),
                      'Model Power (W/kg)': +dataElement['Model Power (W/kg)'].toFixed(2),
                      'Cumulative Distance (yd)': dataElement['Cumulative Distance (m)']
                          ? +(+dataElement['Cumulative Distance (m)'] * 1.09361).toFixed(2)
                          : 0,
                      'Model Ratio Force (N)': +(
                          dataElement['Model Ratio Force (N)'] * 100
                      ).toFixed(1),
                  }))
                : [],
        );

        // generate decel data
        setDecelData(
            sprint.decel_raw_data && sprint.decel_raw_data.length > 0
                ? sprint.decel_raw_data.map((dataElement) => ({
                      'Adjusted Time (s)': +dataElement['Adjusted Time (s)'].toFixed(2),
                      'Velocity Filtered (m/s)': +(
                          dataElement['Velocity Filtered (m/s)'] * 2.23694
                      ).toFixed(2),
                      'Cumulative Distance (yd)': dataElement['Cumulative Distance (m)']
                          ? +(+dataElement['Cumulative Distance (m)'] * 1.09361).toFixed(2)
                          : 0,
                  }))
                : [],
        );
    }, [sprint]);

    const handleClickOpen = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            {hideFVP && (
                <Stack direction={'column'} sx={{ padding: 4 }} spacing={4}>
                    <TableContainer
                        sx={{
                            backgroundColor: '#D9DADF',
                            borderRadius: 4,
                            border: '1px solid #A7ABB7',
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        borderBottom: '1.5px solid #A7ABB7',
                                    }}
                                >
                                    {/** if there isn't FVP data then
                                     sprint.f_0 will be false for all fvp
                                     related metrics**/}
                                    <TooltipMui
                                        disableFocusListener
                                        title="Maximum speed reached during this sprint measured directly from GPS unit."
                                        placement="top-start"
                                        TransitionComponent={Zoom}
                                    >
                                        <TableCell sx={{ fontWeight: 'bold' }}>Vmax</TableCell>
                                    </TooltipMui>

                                    <TooltipMui
                                        disableFocusListener
                                        title="Total distance traveled during the sprint"
                                        placement="top-start"
                                        TransitionComponent={Zoom}
                                    >
                                        <TableCell>Total Distance</TableCell>
                                    </TooltipMui>
                                    <TooltipMui
                                        disableFocusListener
                                        title="Max speed reached during the first 40 yards"
                                        placement="top-start"
                                        TransitionComponent={Zoom}
                                    >
                                        <TableCell>Vmax (40 yd)</TableCell>
                                    </TooltipMui>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {(sprint.v_max * 2.23694).toFixed(2)}
                                        mph
                                    </TableCell>
                                    <TableCell>
                                        {!sprint.total_distance_traveled && '-'}
                                        {sprint.total_distance_traveled &&
                                            displayDistanceByUnit(
                                                sprint.total_distance_traveled,
                                                distanceUnit,
                                            )}
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_v_0_to_40)}mph
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {sprint.avg_velocity_grouped_by_second_data.length > 0 && (
                        <TableContainer
                            sx={{
                                backgroundColor: '#D9DADF',
                                borderRadius: 4,
                                border: '1px solid #A7ABB7',
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            Time Segment:
                                        </TableCell>
                                        {sprint.avg_velocity_grouped_by_second_data.map(
                                            (r: any, index: number) => (
                                                <TableCell sx={{ fontWeight: 'bold' }} key={index}>
                                                    {r['Adjusted Time Rounded (s)']}-
                                                    {r['Adjusted Time Rounded (s)'] + 0.99}s
                                                </TableCell>
                                            ),
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ borderBottom: '1.5px solid #A7ABB7' }}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            Avg Speed:
                                        </TableCell>
                                        {sprint.avg_velocity_grouped_by_second_data.map(
                                            (r: any, index: number) => (
                                                <TableCell key={index}>
                                                    {(
                                                        r['Velocity Filtered (m/s)'] * 2.23694
                                                    ).toFixed(2)}
                                                    mph
                                                </TableCell>
                                            ),
                                        )}
                                    </TableRow>
                                    <TableRow sx={{ borderBottom: '1.5px solid #A7ABB7' }}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Distance:</TableCell>
                                        {sprint.distance_grouped_by_second.map(
                                            (r: any, index: number) => (
                                                <TableCell key={index}>
                                                    {displayDistanceByUnit(
                                                        r['Distance (m)'],
                                                        distanceUnit,
                                                    )}
                                                </TableCell>
                                            ),
                                        )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    <TableContainer
                        sx={{
                            backgroundColor: '#D9DADF',
                            borderRadius: 4,
                            border: '1px solid #A7ABB7',
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Distance</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>0-10yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>0-15yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>0-20yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>0-25yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>0-30yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>0-35yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>0-40yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>10-20yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>20-30yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>20-40yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>30-40yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>40-50yd</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>40+yd</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ borderBottom: '1.5px solid #A7ABB7' }}>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Best 5m Avg Speed (mph)
                                    </TableCell>
                                    <TableCell>{displayMpsToMph(sprint.max_5m_0_to_10)}</TableCell>
                                    <TableCell>{displayMpsToMph(sprint.max_5m_0_to_20)}</TableCell>
                                    <TableCell>{displayMpsToMph(sprint.max_5m_0_to_25)}</TableCell>
                                    <TableCell>{displayMpsToMph(sprint.max_5m_0_to_30)}</TableCell>
                                    <TableCell>{displayMpsToMph(sprint.max_5m_0_to_35)}</TableCell>
                                    <TableCell>{displayMpsToMph(sprint.max_5m_0_to_40)}</TableCell>
                                    <TableCell>{displayMpsToMph(sprint.max_5m_10_to_20)}</TableCell>
                                    <TableCell>{displayMpsToMph(sprint.max_5m_20_to_30)}</TableCell>
                                    <TableCell>{displayMpsToMph(sprint.max_5m_30_to_40)}</TableCell>
                                    <TableCell>{displayMpsToMph(sprint.max_5m_40_to_50)}</TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_5m_40_to_inf)}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ borderBottom: '1.5px solid #A7ABB7' }}>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Avg Speed (mph)
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_avg_v_0_to_10)}
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_avg_v_0_to_20)}
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_avg_v_0_to_25)}
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_avg_v_0_to_30)}
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_avg_v_0_to_35)}
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_avg_v_0_to_40)}
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_avg_v_10_to_20)}
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_avg_v_20_to_30)}
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_avg_v_30_to_40)}
                                    </TableCell>
                                    <TableCell>
                                        {displayMpsToMph(sprint.max_avg_v_40_to_50)}
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow sx={{ borderBottom: '1.5px solid #A7ABB7' }}>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Split Times (s)
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 0, 10) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 0, 15) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 0, 20) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 0, 25) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 0, 30) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 0, 35) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 0, 40) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 10, 20) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 20, 30) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 20, 40) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {getSplitTimeFromSprint(sprint, 30, 40) || '-'}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Stack>
            )}
            {!hideFVP && (
                <Box sx={{ padding: 4 }}>
                    <TableContainer
                        sx={{
                            backgroundColor: '#D9DADF',
                            borderRadius: 4,
                            border: '1px solid #A7ABB7',
                            marginBottom: 5,
                            background:
                                'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow sx={{ borderBottom: '1.5px solid #A7ABB7' }}>
                                    {/** if there isn't FVP data then
                                     sprint.f_0 will be false for all fvp
                                     related metrics**/}
                                    {sprint.f_0 && (
                                        <>
                                            <TooltipMui
                                                disableFocusListener
                                                title="Maximal force output (per unit body mass) in the
                                            horizontal direction. Corresponds to the initial push of
                                            the athlete onto the ground during sprint acceleration.
                                            The higher the value, the higher the sprint-specific
                                            horizontal force production."
                                                placement="top-start"
                                                TransitionComponent={Zoom}
                                            >
                                                <TableCell>F0/kg</TableCell>
                                            </TooltipMui>

                                            <TooltipMui
                                                disableFocusListener
                                                title=" Maximal power-output capability of the athlete in the
                                            horizontal direction (per unit body mass) during sprint
                                            acceleration."
                                                placement="top-start"
                                                TransitionComponent={Zoom}
                                            >
                                                <TableCell>PMax</TableCell>
                                            </TooltipMui>
                                            <TooltipMui
                                                disableFocusListener
                                                title="Sprint-running maximal velocity capability of the
                                            athlete. Slightly higher than the actual maximal
                                            velocity. The theoretical maximal running velocity the
                                            athlete would be able to reach should mechanical
                                            resistances (ie, internal and external) against movement
                                            be null. It also represents the capability to produce
                                            horizontal force at very high running velocities."
                                                placement="top-start"
                                                TransitionComponent={Zoom}
                                            >
                                                <TableCell>V0</TableCell>
                                            </TooltipMui>
                                        </>
                                    )}
                                    <TooltipMui
                                        disableFocusListener
                                        title="Maximum speed reached during this sprint measured directly from GPS unit."
                                        placement="top-start"
                                        TransitionComponent={Zoom}
                                    >
                                        <TableCell sx={{ fontWeight: 'bold' }}>Vmax</TableCell>
                                    </TooltipMui>
                                    {sprint.f_0 && (
                                        <>
                                            <TooltipMui
                                                disableFocusListener
                                                title="Direct measurement of the proportion of the total force
                                            production that is directed in the forward direction of
                                            motion, ie, the mechanical effectiveness of force
                                            application of the athlete. The higher the value, the
                                            more important the part of the total force output
                                            directed forward."
                                                placement="top-start"
                                                TransitionComponent={Zoom}
                                            >
                                                <TableCell>RF</TableCell>
                                            </TooltipMui>
                                            <TooltipMui
                                                disableFocusListener
                                                title="Describes the athlete’s capability to limit the
                                            inevitable decrease in mechanical effectiveness with
                                            increasing speed, ie, an index of the ability to
                                            maintain a net horizontal force production despite
                                            increasing running velocity. The more negative the
                                            slope, the faster the loss of effectiveness of force
                                            application during acceleration, and vice versa."
                                                placement="top-start"
                                                TransitionComponent={Zoom}
                                            >
                                                <TableCell>DRF</TableCell>
                                            </TooltipMui>
                                        </>
                                    )}
                                    <TooltipMui
                                        disableFocusListener
                                        title="Total distance traveled during the sprint"
                                        placement="top-start"
                                        TransitionComponent={Zoom}
                                    >
                                        <TableCell>Total Distance</TableCell>
                                    </TooltipMui>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    {sprint.f_0 && (
                                        <>
                                            <TableCell>
                                                {sprint.f_0 && <>{sprint.f_0}N/kg</>}
                                                {!sprint.f_0 && <>N/A</>}
                                            </TableCell>
                                            <TableCell>
                                                {sprint.p_max && <>{sprint.p_max}W/kg</>}
                                                {!sprint.p_max && <>N/A</>}
                                            </TableCell>
                                            <TableCell>
                                                {sprint.v_0 && (
                                                    <>
                                                        {(sprint.v_0 * 2.23694).toFixed(2)}
                                                        mph
                                                    </>
                                                )}
                                                {!sprint.v_0 && <>N/A</>}
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell>
                                        {(sprint.v_max * 2.23694).toFixed(2)}
                                        mph
                                    </TableCell>
                                    {sprint.f_0 && (
                                        <>
                                            <TableCell>
                                                {sprint.rf_max && (
                                                    <>{Math.round(sprint.rf_max * 100)}%</>
                                                )}
                                                {!sprint.rf_max && <>N/A</>}
                                            </TableCell>
                                            <TableCell>
                                                {sprint.drf && <>{Math.round(sprint.drf * 100)}%</>}
                                                {!sprint.drf && <>N/A</>}
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell>
                                        {!sprint.total_distance_traveled && '-'}
                                        {sprint.total_distance_traveled &&
                                            displayDistanceByUnit(
                                                sprint.total_distance_traveled,
                                                distanceUnit,
                                            )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {sprint.avg_velocity_grouped_by_second_data.length > 0 && (
                        <TableContainer
                            sx={{
                                backgroundColor: '#D9DADF',
                                borderRadius: 4,
                                border: '1px solid #A7ABB7',
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                            }}
                        >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            Time Segment:
                                        </TableCell>
                                        {sprint.avg_velocity_grouped_by_second_data.map(
                                            (r: any, index: number) => (
                                                <TableCell sx={{ fontWeight: 'bold' }} key={index}>
                                                    {r['Adjusted Time Rounded (s)']}-
                                                    {r['Adjusted Time Rounded (s)'] + 0.99}s
                                                </TableCell>
                                            ),
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ borderBottom: '1.5px solid #A7ABB7' }}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            Avg Speed:
                                        </TableCell>
                                        {sprint.avg_velocity_grouped_by_second_data.map(
                                            (r: any, index: number) => (
                                                <TableCell key={index}>
                                                    {(
                                                        r['Velocity Filtered (m/s)'] * 2.23694
                                                    ).toFixed(2)}
                                                    mph
                                                </TableCell>
                                            ),
                                        )}
                                    </TableRow>
                                    <TableRow sx={{ borderBottom: '1.5px solid #A7ABB7' }}>
                                        <TableCell sx={{ fontWeight: 'bold' }}>Distance:</TableCell>
                                        {sprint.distance_grouped_by_second.map(
                                            (r: any, index: number) => (
                                                <TableCell key={index}>
                                                    {displayDistanceByUnit(
                                                        r['Distance (m)'],
                                                        distanceUnit,
                                                    )}
                                                </TableCell>
                                            ),
                                        )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            )}
            {/*Full Screen Modal*/}
            {isMobileDevice && (
                <>
                    <Button variant="outlined" onClick={handleClickOpen}>
                        Full Screen Mobile Graph - Turn Phone Sideways
                    </Button>
                    <Dialog
                        fullScreen
                        open={openModal}
                        TransitionComponent={Transition}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>
                            <ResponsiveContainer>
                                <ComposedChart
                                    data={data}
                                    margin={{
                                        top: 4,
                                        right: 10,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        type={'number'}
                                        scale={'linear'}
                                        allowDataOverflow={true}
                                        name="Time"
                                        dataKey="Adjusted Time (s)"
                                    />
                                    <YAxis
                                        type={'number'}
                                        domain={[0, YaxisMax]}
                                        allowDataOverflow={true}
                                    />
                                    <Tooltip
                                        payload={[]}
                                        contentStyle={{
                                            background:
                                                'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                            border: '1px solid #a8bfd0',
                                            borderRadius: 15,
                                        }}
                                    />
                                    <Legend verticalAlign="top" height={20} />
                                    <Bar
                                        name="Cumulative Distance (yd)"
                                        type="monotone"
                                        dataKey="Cumulative Distance (yd)"
                                        fill="#7794C5"
                                        barSize={0.2}
                                    />
                                    <Scatter
                                        name="Speed (mph)"
                                        dataKey="Velocity (m/s)"
                                        fill="#25C3F1"
                                    />
                                    {sprint.drf && !hideFVP && (
                                        <>
                                            <Line
                                                name="Speed (mph)"
                                                type="monotone"
                                                dataKey="Model Velocity (m/s)"
                                                stroke="#8884d8"
                                            />
                                            <Line
                                                name="Force (N/kg)"
                                                type="monotone"
                                                dataKey="Model Force (N/kg)"
                                                stroke="#0335fc"
                                            />
                                            <Line
                                                name="Power (W/kg)"
                                                type="monotone"
                                                dataKey="Model Power (W/kg)"
                                                stroke="#341f36"
                                            />
                                        </>
                                    )}
                                </ComposedChart>
                            </ResponsiveContainer>
                            <Grid container direction="row" alignItems="flex-start">
                                <Grid item>
                                    <Button onClick={handleCloseModal}>Exit</Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </>
            )}
            <Grid container>
                <Grid
                    xs={12}
                    sm={decelData.length > 0 && sideBySide ? 6 : 12}
                    item
                    style={{
                        padding: 8,
                        paddingRight: 8,
                        paddingLeft: 8,
                        paddingBottom: 8,
                    }}
                >
                    <ChartGradientPaper>
                        <ResponsiveContainer>
                            <ComposedChart
                                data={data}
                                margin={{
                                    top: 10,
                                    right: 10,
                                    left: 10,
                                    bottom: 20,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    type={'number'}
                                    name="Time"
                                    scale={'linear'}
                                    dataKey="Adjusted Time (s)"
                                    padding={{
                                        left: 20,
                                        right: 20,
                                    }}
                                    label={{
                                        value: 'Time (s)',
                                        angle: 0,
                                        position: 'bottom',
                                        textColor: '#A7ABB7',
                                        fontSize: 14,
                                    }}
                                />
                                <YAxis
                                    type={'number'}
                                    domain={[0, YaxisMax]}
                                    allowDataOverflow={true}
                                    label={{
                                        value: 'Speed (MPH)',
                                        angle: -90,
                                        position: 'insideLeft',
                                        textColor: '#A7ABB7',
                                        fontSize: 12,
                                    }}
                                />
                                <Tooltip
                                    payload={[]}
                                    contentStyle={{
                                        background:
                                            'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                        border: '1px solid #a8bfd0',
                                        borderRadius: 15,
                                    }}
                                />
                                <Legend verticalAlign="top" height={20} />
                                <Line
                                    name="Cumulative Distance (yd)"
                                    type="monotone"
                                    dataKey="Cumulative Distance (yd)"
                                    fill="#7794C5"
                                />
                                <Scatter
                                    name="Speed (mph)"
                                    dataKey="Velocity (m/s)"
                                    fill="#25C3F1"
                                />
                                {sprint.drf && !hideFVP && (
                                    <>
                                        <Line
                                            name="Model Speed (mph)"
                                            type="monotone"
                                            dataKey="Model Velocity (m/s)"
                                            stroke="#8884d8"
                                        />
                                        <Line
                                            name="Force (N/kg)"
                                            type="monotone"
                                            dataKey="Model Force (N/kg)"
                                            stroke="#0335fc"
                                        />
                                        <Line
                                            name="Power (W/kg)"
                                            type="monotone"
                                            dataKey="Model Power (W/kg)"
                                            stroke="#341f36"
                                        />
                                    </>
                                )}
                            </ComposedChart>
                        </ResponsiveContainer>
                    </ChartGradientPaper>
                </Grid>
                {decelData.length > 0 && (
                    <Grid
                        xs={12}
                        sm={sideBySide ? 6 : 12}
                        item
                        style={{
                            padding: 8,
                            paddingRight: 8,
                            paddingLeft: 8,
                            paddingBottom: 8,
                        }}
                    >
                        <Paper
                            style={{
                                width: '100%',
                                height: 400,
                                backgroundColor: '#D9DADF',
                                borderRadius: 20,
                                border: '1px solid #A7ABB7',
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                padding: 20,
                            }}
                        >
                            <ResponsiveContainer>
                                <ComposedChart
                                    data={decelData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 20,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        type={'number'}
                                        scale={'linear'}
                                        dataKey="Adjusted Time (s)"
                                        label={{
                                            value: 'Time (s)',
                                            angle: 0,
                                            position: 'bottom',
                                            color: '#A7ABB7',
                                            fontSize: 14,
                                        }}
                                    />
                                    <YAxis
                                        type={'number'}
                                        domain={[0, YaxisMax]}
                                        allowDataOverflow={true}
                                        label={{
                                            value: 'Speed (MPH)',
                                            angle: -90,
                                            position: 'insideLeft',
                                            color: '#A7ABB7',
                                            fontSize: 12,
                                        }}
                                    />
                                    <Tooltip
                                        payload={[]}
                                        contentStyle={{
                                            background:
                                                'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                            border: '1px solid #a8bfd0',
                                            borderRadius: 15,
                                        }}
                                    />
                                    <Legend verticalAlign="top" height={20} />
                                    <Line
                                        name="Cumulative Distance (yd)"
                                        type="monotone"
                                        dataKey="Cumulative Distance (yd)"
                                        fill="#A2C9FF"
                                    />
                                    <Scatter
                                        name="Deceleration Speed (mph)"
                                        dataKey="Velocity Filtered (m/s)"
                                        fill="#25C3F1"
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                )}
                {sprint.drf && !hideFVP && (
                    <Grid xs={12} item style={{ padding: 8 }}>
                        <Paper
                            style={{
                                width: '100%',
                                height: 400,
                                backgroundColor: '#D9DADF',
                                borderRadius: 20,
                                border: '1px solid #A7ABB7',
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                padding: 20,
                            }}
                        >
                            <ResponsiveContainer>
                                <ScatterChart
                                    width={500}
                                    height={300}
                                    data={data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 20,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="Model Velocity (m/s)"
                                        label={{
                                            value: 'Model Velocity (m/s)',
                                            angle: 0,
                                            position: 'bottom',
                                            color: '#A7ABB7',
                                            fontSize: 14,
                                        }}
                                    />
                                    <YAxis
                                        yAxisId="left"
                                        label={{
                                            value: 'Force (N)',
                                            angle: -90,
                                            position: 'insideLeft',
                                            color: '#A7ABB7',
                                            fontSize: 12,
                                        }}
                                    />
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        label={{
                                            value: 'Power (W/kg)',
                                            angle: -90,
                                            position: 'insideTopRight',
                                            color: '#A7ABB7',
                                            fontSize: 12,
                                        }}
                                    />
                                    <Tooltip
                                        payload={[]}
                                        contentStyle={{
                                            background:
                                                'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                            border: '1px solid #a8bfd0',
                                            borderRadius: 15,
                                        }}
                                    />
                                    <Legend verticalAlign="top" height={20} />
                                    <Scatter
                                        name="Force (N)"
                                        yAxisId="left"
                                        type="monotone"
                                        dataKey="Model Force (N)"
                                    />
                                    <Scatter
                                        name="Power (W/kg)"
                                        yAxisId="right"
                                        type="monotone"
                                        dataKey="Model Power (W/kg)"
                                        fill="#25C3F1"
                                    />
                                </ScatterChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                )}
                {sprint.drf && !hideFVP && (
                    <Grid xs={12} item style={{ padding: 8 }}>
                        <Paper
                            style={{
                                width: '100%',
                                height: 400,
                                backgroundColor: '#D9DADF',
                                borderRadius: 20,
                                border: '1px solid #A7ABB7',
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                padding: 20,
                            }}
                        >
                            <ResponsiveContainer>
                                <ScatterChart
                                    width={500}
                                    height={300}
                                    data={data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 20,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="Model Velocity (m/s)"
                                        label={{
                                            value: 'Model Velocity (m/s)',
                                            angle: 0,
                                            position: 'bottom',
                                            color: '#A7ABB7',
                                            fontSize: 14,
                                        }}
                                    />
                                    <YAxis />
                                    <Tooltip
                                        payload={[]}
                                        contentStyle={{
                                            background:
                                                'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                            border: '1px solid #a8bfd0',
                                            borderRadius: 15,
                                        }}
                                    />
                                    <Legend verticalAlign="top" height={20} />
                                    <Scatter
                                        name="Ratio Of Force (%)"
                                        type="monotone"
                                        dataKey="Model Ratio Force (N)"
                                    />
                                </ScatterChart>
                            </ResponsiveContainer>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            {sprint.drf && !hideFVP && (
                <Box p={8}>
                    <dl>
                        <div>
                            <dt>
                                <Typography>F0/kg</Typography>
                            </dt>
                            <dd>
                                Maximal force output (per unit body mass) in the horizontal
                                direction. Corresponds to the initial push of the athlete onto the
                                ground during sprint acceleration. The higher the value, the higher
                                the sprint-specific horizontal force production.
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <Typography>Pmax</Typography>
                            </dt>
                            <dd>
                                Maximal power-output capability of the athlete in the horizontal
                                direction (per unit body mass) during sprint acceleration.
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <Typography>V0</Typography>
                            </dt>
                            <dd>
                                Sprint-running maximal velocity capability of the athlete. Slightly
                                higher than the actual maximal velocity. The theoretical maximal
                                running velocity the athlete would be able to reach should
                                mechanical resistances (ie, internal and external) against movement
                                be null. It also represents the capability to produce horizontal
                                force at very high running velocities.
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <Typography>Vmax</Typography>
                            </dt>
                            <dd>Maximum speed reached during this sprint.</dd>
                        </div>
                        <div>
                            <dt>
                                <Typography>RF</Typography>
                            </dt>
                            <dd>
                                Direct measurement of the proportion of the total force production
                                that is directed in the forward direction of motion, ie, the
                                mechanical effectiveness of force application of the athlete. The
                                higher the value, the more important the part of the total force
                                output directed forward.
                            </dd>
                        </div>
                        <div>
                            <dt>
                                <Typography>DRF</Typography>
                            </dt>
                            <dd>
                                Describes the athlete’s capability to limit the inevitable decrease
                                in mechanical effectiveness with increasing speed, ie, an index of
                                the ability to maintain a net horizontal force production despite
                                increasing running velocity. The more negative the slope, the faster
                                the loss of effectiveness of force application during acceleration,
                                and vice versa.
                            </dd>
                        </div>
                    </dl>
                </Box>
            )}
        </>
    );
}

export default React.memo(ForceVelocityProfileSprint);
