import React, { FunctionComponent, useState } from 'react';
import { Dialog, DialogTitle, Button } from '@mui/material';
import { Individual } from '../../../utils';
import { CopyLink } from 'components';
import QRCode from 'react-qr-code';

interface OwnProps {
    open: boolean;
    onClose: () => void;
    individual: Individual;
}

type Props = OwnProps;

const IndividualInviteDialog: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
    const [showQRCode, setShowQRCode] = useState(false);

    const link = props.individual ? `${process.env.REACT_APP_URL}/i/${props.individual.slug}` : '';

    return (
        <Dialog onClose={props.onClose} open={!!open} aria-labelledby="invite-individual">
            <DialogTitle id="invite-individual">
                Share Link With {props.individual.name}
            </DialogTitle>
            <CopyLink link={link} onCopyToClipboard={() => {}} />
            <Button onClick={() => setShowQRCode(!showQRCode)}>
                {showQRCode ? 'Hide' : 'Show'} QR Code
            </Button>
            {showQRCode && (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 60 }}>
                    <QRCode value={link} />
                </div>
            )}
        </Dialog>
    );
};

export default IndividualInviteDialog;
