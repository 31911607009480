import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ url: string }>>;

const organizationUpdatePaymentMethod = (org_id: string, return_url: string): ReturnType => {
    return Request.post(`/organizations/${org_id}/update-payment-method`, {
        return_url,
    });
};
export default organizationUpdatePaymentMethod;
