import NavigationLayout from '../../../Layouts/NavigationLayout';
import { RouteConfigProps } from '../../../Layouts/routeconfig';
import { AssignSession, CalendarPage } from '../../../pages';
import { Navigation } from '../../../utils';
import CareTeamDashboard from './CareTeamDashboard';
import PlanReportsScene from './PlanReportsScene';
import TimelineScene from './TimelineScene';

export const planRoutes: Array<RouteConfigProps> = [
    {
        exact: true,
        layout: NavigationLayout,
        path: '/plan/dashboard',
        component: CareTeamDashboard,
        private: false,
        pageTitle: 'Timeline',
        primaryNavigation: Navigation.PLAN,
        secondaryNavigation: Navigation.PLAN_DASHBOARD,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/plan/timeline',
        component: TimelineScene,
        private: false,
        pageTitle: 'Timeline',
        primaryNavigation: Navigation.PLAN,
        secondaryNavigation: Navigation.PLAN_TIMELINE,
    },
    {
        layout: NavigationLayout,
        component: AssignSession,
        exact: true,
        path: '/plan/sessions/:sessionId/assign',
        private: true,
        primaryNavigation: Navigation.PLAN,
        secondaryNavigation: Navigation.PLAN_CALENDAR,
    },
    {
        layout: NavigationLayout,
        component: AssignSession,
        exact: true,
        path: '/plan/sessions/:sessionId/assign-group/:groupId',
        private: true,
        primaryNavigation: Navigation.PLAN,
        secondaryNavigation: Navigation.PLAN_CALENDAR,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/plan/calendar',
        private: true,
        component: CalendarPage,
        primaryNavigation: Navigation.PLAN,
        secondaryNavigation: Navigation.PLAN_CALENDAR,
    },
    {
        exact: true,
        layout: NavigationLayout,
        path: '/plan/reports',
        private: true,
        component: PlanReportsScene,
        primaryNavigation: Navigation.PLAN,
        secondaryNavigation: Navigation.PLAN_REPORTS,
    },
];
