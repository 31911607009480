import Grid from '@mui/material/Grid';
import { Skeleton } from '@mui/material';
import React, { ReactElement } from 'react';

export default function FilterSkeleton(): ReactElement {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Skeleton sx={{ height: 25 }} animation="wave" variant="rectangular" />
            </Grid>
            <Grid item xs={12}>
                <Skeleton sx={{ height: 205 }} animation="wave" variant="rectangular" />
            </Grid>
        </Grid>
    );
}
