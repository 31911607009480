import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ url: string }>>;

export const createCheckoutMarketplaceItem = (
    return_url: string,
    org_id_for_purchase: string,
    seller_org_id: string,
    price_id_quantity_map: Record<string, number>,
): ReturnType => {
    return Request.post(
        `/organizations/${org_id_for_purchase}/checkout-marketplace-physical-item-session`,
        {
            return_url,
            seller_org_id,
            price_id_quantity_map,
        },
    );
};

export const createCheckoutMarketplaceSubscriptionItem = (
    return_url: string,
    org_id_for_purchase: string,
    seller_org_id: string,
    price_id_quantity_map: Record<string, number>,
): ReturnType => {
    return Request.post(
        `/organizations/${org_id_for_purchase}/checkout-marketplace-subscription-item-session`,
        {
            return_url,
            seller_org_id,
            price_id_quantity_map,
        },
    );
};
