import { Button } from '@mui/material';
import React from 'react';

interface Props {
    variant: 'contained' | 'outlined';
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
}

export default function DimensionFilterChip({ variant, onClick, children }: Props) {
    return (
        <Button onClick={onClick} variant={variant}>
            {children}
        </Button>
    );
}
