import React from 'react';
import { Box, Card, CardActionArea, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';

const RankHeaderCard = ({
    color,
    title,
    onClick,
    selected,
    icon,
    isChecked,
}: {
    color: string;
    title: string;
    onClick: () => void;
    selected: boolean;
    icon: string;
    isChecked: boolean;
}) => {
    const theme = useTheme();
    return (
        <Card
            sx={{
                backgroundColor: selected ? color : 'white',
                boxShadow: selected ? '0px 0px 10px 0px rgba(0,0,0,0.2)' : 'none',
                borderColor: selected ? theme.palette.primary.light : 'white',
                borderStyle: selected ? 'solid' : undefined,
                borderWidth: selected ? 2 : undefined,
            }}
        >
            <CardActionArea onClick={onClick}>
                <Box sx={{ display: 'flex' }}>
                    <Box
                        sx={{
                            backgroundColor: color,
                            width: 70,
                            height: 70,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography sx={{ fontSize: 40, fontWeight: 300 }}>{icon}</Typography>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            padding: 4,
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                        }}
                    >
                        <Typography sx={{ fontWeight: 300, fontSize: 20 }}>{title}</Typography>
                        {isChecked && (
                            <CheckIcon
                                sx={{ position: 'absolute', right: 0, top: 0, color: 'green' }}
                            />
                        )}
                    </Box>
                </Box>
            </CardActionArea>
        </Card>
    );
};

export default RankHeaderCard;
