import React, { ReactElement } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { EmptyCard } from '.';
import Typography from '@mui/material/Typography';
import ClipStartTimeButtonGroupSelect from './ClipStartTimeButtonGroupSelect';

export interface SuccessCardProps {
    /** text the text to display in the card */
    text: string;
    /** clipStartTime The start time of the clip */
    clipStartTime?: number;
    /** onClipStartTimeSelected Callback for when the clip start time is selected */
    onClipStartTimeSelected?: (clipStartTime: number) => void;
}

export default function SuccessCard({
    text = '',
    onClipStartTimeSelected,
    clipStartTime,
}: SuccessCardProps): ReactElement {
    return (
        <EmptyCard>
            <DoneIcon color="primary" />
            <Typography style={{ maxWidth: 124 }} noWrap={true}>
                {text}
            </Typography>
            {onClipStartTimeSelected && clipStartTime !== undefined && (
                <ClipStartTimeButtonGroupSelect
                    clipStartTime={clipStartTime}
                    onClipStartTimeSelected={onClipStartTimeSelected}
                />
            )}
        </EmptyCard>
    );
}
