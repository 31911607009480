import React, { useRef, useState } from 'react';
import { Box, Card, CardMedia, Fab, Typography } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

export interface Item {
    image: string;
    title: string;
    category: string;
    description: string;
    onClick: () => void;
    imageAlignment?: 'center' | 'top';
}

interface ScrollableCardListProps {
    items: Item[];
    cardsToShow?: number;
    imageAlignment?: 'center' | 'top';
}

const ScrollableCardList: React.FC<React.PropsWithChildren<ScrollableCardListProps>> = ({
    items,
    cardsToShow = 3,
}) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const [lastVisibleIndex, setLastVisibleIndex] = useState(cardsToShow - 1);

    const handleArrowClick = (direction: 'left' | 'right') => {
        if (direction === 'right') {
            setLastVisibleIndex((prev) => Math.min(prev + 1, items.length - 1));
        } else {
            setLastVisibleIndex((prev) => prev - 1);
        }
    };

    return (
        <Box
            sx={{
                position: 'relative',
                padding: '0 24px',
            }}
        >
            {items.length >= cardsToShow && (
                <Fab
                    size="small"
                    onClick={() => handleArrowClick('left')}
                    disabled={lastVisibleIndex <= cardsToShow - 1}
                    color={'primary'}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                        left: '16px',
                    }}
                >
                    <ArrowBackIos />
                </Fab>
            )}
            <Box
                sx={{
                    display: 'flex',
                    overflowX: 'hidden',
                    position: 'relative',
                }}
            >
                <Box
                    ref={scrollRef}
                    sx={{
                        display: 'flex',
                        overflowX: 'hidden',
                        width: '100%',
                        scrollBehavior: 'smooth',
                    }}
                >
                    {items
                        .slice(lastVisibleIndex - cardsToShow + 1, lastVisibleIndex + 1)
                        .map((item, index) => (
                            <Box
                                key={index}
                                sx={{ flexShrink: 0, width: `calc(100% / ${cardsToShow})` }}
                            >
                                <Card
                                    onClick={item.onClick}
                                    sx={{
                                        mx: 4,
                                        cursor: 'pointer',
                                        transition: 'box-shadow .3s',
                                        '&:hover': {
                                            boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
                                        },
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        alt={item.title}
                                        height="190"
                                        image={item.image}
                                        sx={{
                                            backgroundPosition:
                                                item.imageAlignment === 'top' ? 'top' : 'center',
                                        }}
                                    />
                                </Card>
                                <Box sx={{ m: 4 }}>
                                    <Typography variant="h6" mt={1}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {item.description}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                </Box>
            </Box>
            {items.length >= cardsToShow && (
                <Fab
                    color={'primary'}
                    size="small"
                    onClick={() => handleArrowClick('right')}
                    disabled={lastVisibleIndex >= items.length - 1}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                        right: '16px',
                    }}
                >
                    <ArrowForwardIos />
                </Fab>
            )}
        </Box>
    );
};

export default ScrollableCardList;
