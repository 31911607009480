import { Description } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { SessionMovementGoalsTableHeader, SessionMovementRow } from 'components';
import MovementMedia from 'components/Media/MovementMedia';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    AccelerationUnit,
    DistanceUnit,
    EntityType,
    getHtml,
    LoadUnit,
    Movement,
    MovementType,
    PowerUnit,
    SessionMovement,
    SetAttributeType,
    SpeedUnit,
    TimeDisplayFormat,
} from 'utils';

export interface ViewSessionMovementProps {
    sessionMovements: Array<SessionMovement>;
}

export interface SessionMovementGoalSet {
    movement: Movement;
    goalSets: Array<SessionMovement>;
}

const ViewSessionMovement = ({ sessionMovements }: ViewSessionMovementProps) => {
    const theme = useTheme();
    const [sessionMovementGoalSets, setSessionMovementGoalSets] = useState<
        Array<SessionMovementGoalSet>
    >([]);
    const [openMovementAssetSliderIndex, setOpenMovementAssetSliderIndex] = useState<number | null>(
        null,
    );
    const [openMovementDescriptionSliderIndex, setOpenMovementDescriptionSliderIndex] = useState<
        number | null
    >(null);

    useEffect(() => {
        let goalSets: Array<SessionMovementGoalSet> = [];

        for (let i = 0; i < sessionMovements.length; i++) {
            if (i == 0 || sessionMovements[i].uuid != sessionMovements[i - 1].uuid) {
                goalSets.push({
                    movement: {
                        uuid: sessionMovements[i].uuid,
                        name: sessionMovements[i].name,
                        object: EntityType.MOVEMENT,
                        description: sessionMovements[i].description,
                        movement_type: sessionMovements[i].movement_type as MovementType,
                        published_at: sessionMovements[i].published_at,
                        assets: sessionMovements[i].assets,
                    },
                    goalSets: [sessionMovements[i]],
                });
            } else {
                goalSets[goalSets.length - 1].goalSets.push(sessionMovements[i]);
            }
        }

        setSessionMovementGoalSets(goalSets);
    }, [sessionMovements]);

    //A goal set is a set of movement attached to the session with reps, load, distance, time etc
    // A movement can just have reps and load while other movement from same session might have
    // distance and time
    // This function will get the goal criterias which are applicable for a movement goal set
    // This is used to show header columns as if there is no distance goal for any set of a session movement
    // We don't need to show it in the header
    const getGoalCriteriasFromGoalSet = (
        sessionMovementGoalSet: SessionMovementGoalSet,
    ): Array<SetAttributeType> => {
        let goalCriterias: Set<SetAttributeType> = new Set();

        sessionMovementGoalSet.goalSets.forEach((goalSet) => {
            if (goalSet.pivot?.reps != null) {
                goalCriterias.add(SetAttributeType.Reps);
            }
            if (goalSet.pivot?.load_value != null) {
                goalCriterias.add(SetAttributeType.Load);
            }
            if (goalSet.pivot?.time_value != null) {
                goalCriterias.add(SetAttributeType.Time);
            }
            if (goalSet.pivot?.distance_value != null) {
                goalCriterias.add(SetAttributeType.Distance);
            }
            if (goalSet.pivot?.range_of_motion_value != null) {
                goalCriterias.add(SetAttributeType.RangeOfMotion);
            }
            if (goalSet.pivot?.speed_value != null) {
                goalCriterias.add(SetAttributeType.Speed);
            }
            if (goalSet.pivot?.rsi_value != null) {
                goalCriterias.add(SetAttributeType.RSI);
            }
            if (goalSet.pivot?.rpm_value != null) {
                goalCriterias.add(SetAttributeType.RPM);
            }
            if (goalSet.pivot?.force_value != null) {
                goalCriterias.add(SetAttributeType.Force);
            }
            if (goalSet.pivot?.rpe_value != null) {
                goalCriterias.add(SetAttributeType.RPE);
            }
            if (goalSet.pivot?.band_value != null) {
                goalCriterias.add(SetAttributeType.Band);
            }
            if (goalSet.pivot?.rest_value != null) {
                goalCriterias.add(SetAttributeType.Rest);
            }
            if (goalSet.pivot?.acceleration_value != null) {
                goalCriterias.add(SetAttributeType.Acceleration);
            }
            if (goalSet.pivot?.power_value != null) {
                goalCriterias.add(SetAttributeType.Power);
            }
            if (goalSet.pivot?.gps_player_load_value != null) {
                goalCriterias.add(SetAttributeType.GPSPlayerLoad);
            }
            if (goalSet.pivot?.gps_accel_count_value != null) {
                goalCriterias.add(SetAttributeType.GPSAccelCount);
            }
            if (goalSet.pivot?.gps_decel_count_value != null) {
                goalCriterias.add(SetAttributeType.GPSDecelCount);
            }
        });
        if (goalCriterias.size == 0) {
            // Add at least one movement preset if none have been selected
            goalCriterias.add(SetAttributeType.Reps);
        }

        return Array.from(goalCriterias);
    };

    return (
        <>
            {sessionMovementGoalSets.length > 0 ? (
                <div>
                    <Typography
                        variant="body2"
                        align="center"
                        component="div"
                        color="textSecondary"
                    >
                        Session Movements
                    </Typography>
                    {sessionMovementGoalSets.map((sessionMovementGoalSet, goalSetIndex) => {
                        const applicableCriterias =
                            getGoalCriteriasFromGoalSet(sessionMovementGoalSet);

                        return (
                            <Accordion
                                sx={{
                                    '& .MuiInputBase-root': {
                                        borderBottom: `1px solid transparent`,
                                        '&:hover, &:focus': {
                                            borderBottom: `1px solid ${theme.palette.text.secondary}`,
                                        },
                                    },
                                    '& .MuiDivider-root:last-child': {
                                        display: 'none',
                                    },
                                }}
                                expanded={true}
                                key={goalSetIndex}
                            >
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{
                                            width: '100%',
                                            gap: theme.spacing(5),
                                            padding: theme.spacing(0, 5),
                                        }}
                                        flexWrap="wrap"
                                    >
                                        <Box
                                            textAlign="center"
                                            onClick={() => {
                                                if (openMovementAssetSliderIndex == goalSetIndex) {
                                                    setOpenMovementAssetSliderIndex(null);
                                                } else {
                                                    setOpenMovementAssetSliderIndex(goalSetIndex);
                                                    setOpenMovementDescriptionSliderIndex(null);
                                                }
                                            }}
                                            width={
                                                openMovementAssetSliderIndex == goalSetIndex
                                                    ? '100%'
                                                    : 80
                                            }
                                            flex={
                                                openMovementAssetSliderIndex == goalSetIndex
                                                    ? '0 0 100%'
                                                    : 'inherit'
                                            }
                                        >
                                            <MovementMedia
                                                movement={sessionMovementGoalSet.movement}
                                            />
                                        </Box>
                                        {!isEmpty(
                                            sessionMovementGoalSet.movement?.description?.trim(),
                                        ) && (
                                            <IconButton
                                                onClick={() => {
                                                    if (
                                                        openMovementDescriptionSliderIndex ==
                                                        goalSetIndex
                                                    ) {
                                                        setOpenMovementDescriptionSliderIndex(null);
                                                    } else {
                                                        setOpenMovementDescriptionSliderIndex(
                                                            goalSetIndex,
                                                        );
                                                        setOpenMovementAssetSliderIndex(null);
                                                    }
                                                }}
                                                size="large"
                                            >
                                                <Description />
                                            </IconButton>
                                        )}
                                        <Typography
                                            component="div"
                                            sx={{
                                                flexGrow: 1,
                                            }}
                                        >
                                            {sessionMovementGoalSet.movement.name}
                                        </Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        display: 'block',
                                        backgroundColor: theme.palette.backgroundColor.hover,
                                        borderTop: `1px solid ${theme.palette.grey[300]}`,
                                    }}
                                >
                                    {openMovementDescriptionSliderIndex == goalSetIndex && (
                                        <Box m={5}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: getHtml(
                                                        sessionMovementGoalSet.movement
                                                            .description || '',
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {sessionMovementGoalSet.goalSets.length > 0 ? (
                                        <>
                                            {/* Headers */}
                                            <SessionMovementGoalsTableHeader
                                                applicableCriterias={applicableCriterias}
                                                loadUnit={
                                                    (sessionMovementGoalSet.goalSets[0]?.pivot
                                                        ?.load_unit as LoadUnit) || LoadUnit.Pound
                                                }
                                                distanceUnit={
                                                    (sessionMovementGoalSet.goalSets[0]?.pivot
                                                        ?.distance_unit as DistanceUnit) ||
                                                    DistanceUnit.Mile
                                                }
                                                speedUnit={
                                                    (sessionMovementGoalSet.goalSets[0]?.pivot
                                                        ?.speed_unit as SpeedUnit) || SpeedUnit.MPH
                                                }
                                                accelerationUnit={
                                                    (sessionMovementGoalSet.goalSets[0]?.pivot
                                                        ?.acceleration_unit as AccelerationUnit) ||
                                                    AccelerationUnit.FPS
                                                }
                                                powerUnit={
                                                    (sessionMovementGoalSet.goalSets[0]?.pivot
                                                        ?.power_unit as PowerUnit) || PowerUnit.Watt
                                                }
                                                timeDisplayFormat={
                                                    (sessionMovementGoalSet.goalSets[0]?.pivot
                                                        ?.time_display_format as TimeDisplayFormat) ||
                                                    TimeDisplayFormat.Common
                                                }
                                            />

                                            {sessionMovementGoalSet.goalSets.map(
                                                (goalSet, index) => {
                                                    return (
                                                        <SessionMovementRow
                                                            readOnly={true}
                                                            key={`goal-set-${goalSetIndex}-session-moment-row-${index}`}
                                                            index={index}
                                                            goalSet={goalSet}
                                                            applicableCriterias={
                                                                applicableCriterias
                                                            }
                                                            onSessionMovementRemoved={() => {}}
                                                            onSessionMovementGoalValueChanged={() => {}}
                                                        />
                                                    );
                                                },
                                            )}
                                        </>
                                    ) : (
                                        <Typography variant="body2">No sets added.</Typography>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>
            ) : (
                <Box pt={20} display="flex" justifyContent="center" pb={20}>
                    <Typography variant="body2">
                        This session does not contain any movements.
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default ViewSessionMovement;
