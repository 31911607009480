import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ url: string }>>;

const manageComboAthleteSubscription = (return_url: string, flow: string): ReturnType => {
    return Request.post(`/athlete-users/billing/manage-combo-subscription`, {
        return_url,
        flow,
    });
};
export default manageComboAthleteSubscription;
