import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import FullScreenDialogCard from '../components/HowToDialogCard';
import SessionsList from 'images/SessionsList.png';
import MPHDecelComparison from '../media/MPHDecelComparison.png';
import twentyYdStop from '../media/20YdStop.png';
import FortyYardSameAthlete from '../media/40YardSameAthlete.png';
import SprintCapacity100M from '../media/SprintCapacity100M.png';
import SprintAnalysisAcceleration from '../media/SprintAnalysisAcceleration.png';
import SprintAnalysisMaxSpeed from '../media/SprintAnalysisMaxSpeed.png';
import LongTermMaxSpeedImprovement from '../media/ProveLongTermSpeedImprovement.png';
import SportSpecificSpeedComparison from '../media/SportsSpecificSpeedComparison.png';

function SprintAnalysisOverview() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'h4'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        1TUL allows you to analyse speed performance in great detail.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Sprint Analysis for acceleration, capacity, and maximum velocity
                            qualities.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}

                <FullScreenDialogCard
                    image={SprintAnalysisAcceleration}
                    title="Sprint Analysis Acceleration"
                    headline="Sprint Analysis Acceleration"
                    description="Analyze how much distance the athlete covers in the first second and the second second of the sprint. Here, the athlete covered 3.67 yards in the first second."
                />
                <FullScreenDialogCard
                    image={SprintCapacity100M}
                    title="Sprint Analysis Capacity"
                    headline="Sprint Analysis Capacity"
                    description="Sprint Analysis for capacity over 100M sprint.
                    You can analyse how well the athlete can hold his speed throughout the finish. Here, the athlete held his speed to the finish line."
                />
                <FullScreenDialogCard
                    image={SprintAnalysisMaxSpeed}
                    title="Sprint Analysis Max Speed"
                    headline="Sprint Analysis Max Speed"
                    description="Analyse max speed and at what time and distance it was reached. Here, the athlete reached 24.99 MPH in 5.2 seconds at 44.6 yards."
                />
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Sprint Analysis for Deceleration and Change of Direction.
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={MPHDecelComparison}
                    title="MPH Decel Comparison"
                    headline="MPH Decel Comparison"
                    description="See how long and far it takes an athlete to stop at various speeds and track improvement over time.
                    Explore how well the athlete controls their center of mass during deceleration to prevent injury."
                />
                <FullScreenDialogCard
                    image={twentyYdStop}
                    title="20 Yard Stop"
                    headline="20 Yard Stop"
                    description="Analyse total distance, max speed, time to max speed, max speed time to stop, distance to max speed, and distance from max speed to stop."
                />
                <FullScreenDialogCard
                    image={SessionsList}
                    title="Fitness and Drills"
                    headline="Fitness and Drills"
                    description="Share Sessions inside and outside your organization via a link and
                                password or QR. Assign permissions through the link to limit access
                                and editing rights."
                />

                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        Sprint Analysis Comparing Athletes among themselves and others.
                    </Typography>
                </Grid>
                <FullScreenDialogCard
                    image={FortyYardSameAthlete}
                    title="40 Yard Dash Comparison"
                    headline="40 Yard Dash Comparison"
                    description="40 Yard Dash Comparison - Same Athelte."
                />
                <FullScreenDialogCard
                    image={LongTermMaxSpeedImprovement}
                    title="Prove Long Term Improvement"
                    headline="Prove Long Term Improvement"
                    description="Compare improvements month over month and day over day to prove long term improvement."
                />
                <FullScreenDialogCard
                    image={SportSpecificSpeedComparison}
                    title="Sport Specific Analysis"
                    headline="Sport Specific Analysis"
                    description="Compare two football routes against eachother to determine separation qualities."
                />
            </Grid>
        </>
    );
}

export default SprintAnalysisOverview;
