import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatDateStringLuxon, formatNumber } from '../community.func';
import React from 'react';
import { SeriesNamesColumn } from '@cubejs-client/core';

const colors = ['#FF6492', '#141446', '#7A77FF'];

function GpsSummaryBarChart({
    chart,
    series,
}: {
    chart: { [p: string]: string | number | boolean }[];
    series: SeriesNamesColumn[];
}) {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                height={450}
                data={chart ?? []}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis
                    height={120}
                    dataKey="x"
                    angle={-90}
                    textAnchor="end"
                    interval={0}
                    style={{
                        fontSize: '0.75rem',
                    }}
                />
                <YAxis
                    tickFormatter={formatNumber}
                    style={{
                        fontSize: '0.75rem',
                    }}
                />
                <Tooltip labelFormatter={formatDateStringLuxon} />
                {series.map((s, i) => (
                    <Bar key={s.key} stackId="a" dataKey={s.key} name={s.title} fill={colors[i]} />
                ))}
            </BarChart>
        </ResponsiveContainer>
    );
}

export default GpsSummaryBarChart;
