import {
    Avatar,
    Box,
    Checkbox,
    Container,
    FormControl,
    Grid,
    InputLabel,
    ListItemIcon,
    MenuItem,
    Paper,
    Select,
    Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {
    AGGREGATE_CHOICES,
    AggregateMetricChoice,
    METRIC_CHOICES,
    MovementMetricChoice,
} from '../../../app.types';
import ChipSelect from '../../../components/Chips/ChipSelect';
import { reportsSelectors } from '../../../redux/reducers/plan';
import { chooseFilters } from '../../../redux/reducers/plan/reports/actions';
import { useAppDispatch } from '../../../redux/store';
import { RelativeDateRangeSelector } from '../../community/components';
import { DateRangeValue } from '../../community/components/RelativeDateRangeSelector';
import ReportDataResolver from '../components/ReportDataResolver';

export default function PlanReportsScene(): React.ReactElement {
    const movements = useSelector(reportsSelectors.selectMovements);
    const users = useSelector(reportsSelectors.selectUsers);
    const selectedMovement = useSelector(reportsSelectors.selectMovement);
    const usersDictionary = useSelector(reportsSelectors.selectUsersDictionary);
    const selectedUsers = useSelector(reportsSelectors.selectSelectedUsers);
    const selectedUsersCollection = useSelector(reportsSelectors.selectSelectedUsersCollection);
    const filters = useSelector(reportsSelectors.selectFilters);
    const chartData = useSelector(reportsSelectors.selectData);
    const dispatch = useAppDispatch();
    const movementsLoaded = useSelector(reportsSelectors.selectLoaded);

    const COLORS = [
        '#f9fafb',
        '#81baf3',
        '#00C49F',
        '#c6c8c9',
        '#11f903',
        '#7f8f9f',
        '#9dbfe1',
        '#aceff7',
        '#FFBB28',
        '#FF8042',
    ];

    return (
        <>
            <ReportDataResolver />
            <Container maxWidth={'xl'}>
                <Grid container>
                    <Grid item xs={12} sm={3} style={{ padding: 12 }}>
                        <FormControl
                            sx={{
                                width: '100%',
                            }}
                        >
                            <InputLabel id="athlete-select">
                                {selectedUsers.length === 0 ? (
                                    <Typography> Choose An Athlete</Typography>
                                ) : (
                                    ''
                                )}
                                {selectedUsersCollection.length === 1 ? (
                                    <Typography>
                                        {' '}
                                        {selectedUsersCollection[0].first_name}{' '}
                                        {selectedUsersCollection[0].last_name}
                                    </Typography>
                                ) : (
                                    ''
                                )}
                                {selectedUsers.length > 1 ? (
                                    <Typography> Multiple Selected</Typography>
                                ) : (
                                    ''
                                )}
                            </InputLabel>
                            <Select
                                multiple={true}
                                labelId={'athlete-select'}
                                id="athlete-select"
                                defaultValue={[]}
                                value={filters.user_key}
                                renderValue={(selected) =>
                                    (selected as number[])
                                        .map(
                                            (s) =>
                                                `${usersDictionary[s]?.first_name} ${usersDictionary[s]?.last_name}`,
                                        )
                                        .join(', ')
                                }
                                onChange={(e) =>
                                    dispatch(
                                        chooseFilters({
                                            ...filters,
                                            user_key: e.target.value as number[],
                                        }),
                                    )
                                }
                            >
                                {users.map(
                                    ({ user_key, first_name, last_name, profile_photo_url }) => (
                                        <MenuItem key={user_key} value={user_key}>
                                            <Box display="flex" alignItems="center">
                                                <Checkbox
                                                    checked={selectedUsers.indexOf(user_key) > -1}
                                                />
                                                <ListItemIcon>
                                                    <Avatar
                                                        style={{ marginRight: 6 }}
                                                        variant="rounded"
                                                        src={profile_photo_url}
                                                    />
                                                </ListItemIcon>
                                                <Typography variant="subtitle1">{` ${first_name} ${last_name}`}</Typography>
                                            </Box>
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                        {movements.length === 0 && movementsLoaded && (
                            <Typography>
                                No movements to choose from. Make sure your athletes have logged
                                some workouts.
                            </Typography>
                        )}
                        {movements.length > 0 && (
                            <Paper style={{ margin: 2, padding: 8, backgroundColor: '#5b6770' }}>
                                <FormControl
                                    sx={{
                                        width: '100%',
                                    }}
                                >
                                    {!movements && (
                                        <Typography>Choose A Movement To Get Started</Typography>
                                    )}
                                    <InputLabel id="movement-select">
                                        <Typography style={{ color: 'white' }}>
                                            {' '}
                                            {!selectedMovement && 'Select Movement'}
                                        </Typography>
                                    </InputLabel>
                                    <ChipSelect
                                        values={movements.map(({ movement_key, name }) => ({
                                            label: name,
                                            value: movement_key.toString(),
                                        }))}
                                        value={filters.movement_key.toString()}
                                        onChange={(e) =>
                                            dispatch(
                                                chooseFilters({
                                                    ...filters,
                                                    movement_key: +e,
                                                }),
                                            )
                                        }
                                    />
                                </FormControl>
                            </Paper>
                        )}
                        {selectedMovement && (
                            <>
                                <Paper
                                    style={{ margin: 2, padding: 8, backgroundColor: '#5b6770' }}
                                >
                                    <ChipSelect
                                        value={filters.measurement}
                                        values={AGGREGATE_CHOICES}
                                        onChange={(value) =>
                                            dispatch(
                                                chooseFilters({
                                                    ...filters,
                                                    measurement: value as AggregateMetricChoice,
                                                }),
                                            )
                                        }
                                    />
                                    <ChipSelect
                                        value={filters.metric}
                                        values={METRIC_CHOICES}
                                        onChange={(value) =>
                                            dispatch(
                                                chooseFilters({
                                                    ...filters,
                                                    metric: value as MovementMetricChoice,
                                                }),
                                            )
                                        }
                                    />
                                    <RelativeDateRangeSelector
                                        value={{
                                            start: DateTime.fromSeconds(filters.starts_after),
                                            end: DateTime.fromSeconds(filters.ends_before),
                                        }}
                                        onChange={(value: DateRangeValue) =>
                                            dispatch(
                                                chooseFilters({
                                                    ...filters,
                                                    starts_after: moment(value.start).unix(),
                                                    ends_before: moment(value.end).unix(),
                                                }),
                                            )
                                        }
                                    />
                                </Paper>
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={9} style={{ padding: 12 }}>
                        {chartData.length === 0 && (
                            <Box p={12}>
                                <Typography>
                                    {selectedUsers.length > 0 &&
                                        selectedMovement &&
                                        '⚠ Choose Value Selections that have been Logged for that Movement in the Timeframe Selected'}
                                </Typography>
                            </Box>
                        )}
                        {chartData.length > 0 && (
                            <Paper
                                style={{
                                    width: '100%',
                                    height: 400,
                                    backgroundColor: '#182b3a',
                                    backgroundImage:
                                        'linear-gradient(180deg, #000000 0%, #2b4e6b 94%)',
                                }}
                            >
                                <ResponsiveContainer>
                                    <LineChart
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 0,
                                            bottom: 10,
                                        }}
                                    >
                                        <CartesianGrid opacity={0.4} stroke="#5B6770" />
                                        {chartData.map((s, index) => (
                                            <Line
                                                dataKey="value"
                                                data={s.data}
                                                name={s.name}
                                                key={s.name}
                                                strokeWidth={3}
                                                type="monotone"
                                                stroke={COLORS[index % COLORS.length]}
                                            />
                                        ))}

                                        <Tooltip
                                            contentStyle={{
                                                borderRadius: 5,
                                                backgroundColor: '#182b3a',
                                                color: '#fff',
                                            }}
                                            itemStyle={{ color: '#fff' }}
                                            cursor={false}
                                            labelFormatter={(t) =>
                                                moment(t * 1000).format('MMM Do, YYYY')
                                            }
                                        />
                                        <Legend
                                            verticalAlign="top"
                                            height={30}
                                            iconSize={0}
                                            iconType="star"
                                            wrapperStyle={{ fontSize: '12px', fontWeight: 'bold' }}
                                        />
                                        <XAxis
                                            dataKey="date_timestamp"
                                            domain={['auto', 'auto']}
                                            allowDuplicatedCategory={false}
                                            tickLine={false}
                                            tick={{ fill: '#fff' }}
                                            axisLine={false}
                                            type="number"
                                            tickFormatter={(tickItem: moment.MomentInput) => {
                                                return moment(+(tickItem ?? 0) * 1000).format(
                                                    'MMM Do',
                                                );
                                            }}
                                        />
                                        <YAxis
                                            domain={['auto', 'auto']}
                                            dataKey="value"
                                            axisLine={false}
                                            tickLine={false}
                                            tickCount={10}
                                            tick={{ fill: '#fff' }}
                                            type="number"
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
