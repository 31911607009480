import Request from 'api/Request';
import { AxiosResponse } from 'axios';

type ReturnType = Promise<AxiosResponse<{ url: string }>>;

const viewStripeHistory = (return_url: string, org_id_for_managing: string): ReturnType => {
    return Request.post(`/organizations/${org_id_for_managing}/view-stripe-history`, {
        return_url,
    });
};
export default viewStripeHistory;
