import React from 'react';
import { Box, Typography } from '@mui/material';
import {
    AccelerationUnit,
    DistanceUnit,
    ForceUnit,
    LoadUnit,
    PowerUnit,
    RangeOfMotionUnit,
    SetAttributeType,
    SpeedUnit,
    TimeDisplayFormat,
} from 'utils';
import useTheme from '@mui/material/styles/useTheme';

export interface SessionMovementGoalsTableHeaderProps {
    applicableCriterias: Array<SetAttributeType>;
    loadUnit: LoadUnit;
    distanceUnit: DistanceUnit;
    speedUnit: SpeedUnit;
    accelerationUnit: AccelerationUnit;
    powerUnit: PowerUnit;
    timeDisplayFormat: TimeDisplayFormat;
}

const SessionMovementGoalsTableHeader = ({
    applicableCriterias,
    loadUnit,
    timeDisplayFormat,
    speedUnit,
    distanceUnit,
    accelerationUnit,
    powerUnit,
}: SessionMovementGoalsTableHeaderProps) => {
    const theme = useTheme();
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                padding: theme.spacing(2, 8),
                [theme.breakpoints.down('md')]: {
                    padding: theme.spacing(2, 4),
                },
            }}
        >
            {/* Set Index Column */}
            <Box sx={{ width: theme.spacing(25) }}>
                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                    Set
                    <br />
                    <Typography variant="caption" color="textSecondary">
                        &nbsp;
                    </Typography>
                </Typography>
            </Box>
            {/* Load Column */}
            {applicableCriterias.indexOf(SetAttributeType.Reps) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.Reps}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* Load Column */}
            {applicableCriterias.indexOf(SetAttributeType.Load) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.Load}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;({loadUnit})
                        </Typography>
                    </Typography>
                </Box>
            )}
            {/* Time Column */}
            {applicableCriterias.indexOf(SetAttributeType.Time) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.Time}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;({timeDisplayFormat})
                        </Typography>
                    </Typography>
                </Box>
            )}
            {/* Distance Column */}
            {applicableCriterias.indexOf(SetAttributeType.Distance) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.Distance}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;({distanceUnit})
                        </Typography>
                    </Typography>
                </Box>
            )}
            {/* Range Of Motion Column */}
            {applicableCriterias.indexOf(SetAttributeType.RangeOfMotion) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        ROM
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp; ({RangeOfMotionUnit.Degree})
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* Speed Column */}
            {applicableCriterias.indexOf(SetAttributeType.Speed) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.Speed}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp; ({speedUnit})
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* Speed Column */}
            {applicableCriterias.indexOf(SetAttributeType.Acceleration) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.Acceleration}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp; ({accelerationUnit})
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* Speed Column */}
            {applicableCriterias.indexOf(SetAttributeType.Power) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.Power}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp; ({powerUnit})
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* RSI Column */}
            {applicableCriterias.indexOf(SetAttributeType.RSI) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.RSI}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* RPM Column */}
            {applicableCriterias.indexOf(SetAttributeType.RPM) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.RPM}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* Force Column */}
            {applicableCriterias.indexOf(SetAttributeType.Force) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.Force}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp; ({ForceUnit.Newton})
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* Body Side Column */}
            {applicableCriterias.indexOf(SetAttributeType.BodySide) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        Body Side
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* RPE Column */}
            {applicableCriterias.indexOf(SetAttributeType.RPE) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        RPE
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;
                        </Typography>
                    </Typography>
                </Box>
            )}
            {/* Band Column */}
            {applicableCriterias.indexOf(SetAttributeType.Band) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        Band
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* Rest Column */}
            {applicableCriterias.indexOf(SetAttributeType.Rest) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        Rest
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            (s)
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* GPS Player Load Column */}
            {applicableCriterias.indexOf(SetAttributeType.GPSPlayerLoad) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.GPSPlayerLoad}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* GPS Acceleration Count Column */}
            {applicableCriterias.indexOf(SetAttributeType.GPSAccelCount) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.GPSAccelCount}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* GPS Deceleration Count Column */}
            {applicableCriterias.indexOf(SetAttributeType.GPSDecelCount) >= 0 && (
                <Box
                    sx={{
                        width: theme.spacing(50),
                        '& .MuiInputBase-root': {
                            fontSize: 14,
                        },
                    }}
                >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {SetAttributeType.GPSDecelCount}
                        <br />
                        <Typography variant="caption" color="textSecondary">
                            &nbsp;
                        </Typography>
                    </Typography>
                </Box>
            )}

            {/* Actions Column */}
            <Box
                sx={{
                    width: theme.spacing(30),
                    marginRight: theme.spacing(4),
                    [theme.breakpoints.down('md')]: {
                        marginRight: 0,
                    },
                }}
            >
                <Typography variant="body2" sx={{ fontWeight: 700 }}>
                    &nbsp;
                </Typography>
            </Box>
        </Box>
    );
};

export default SessionMovementGoalsTableHeader;
