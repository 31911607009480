import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import SelectDateChip from '../../../components/Chips/SelectDateChip';

type RangeChoice = 'all' | 'seven' | 'thirty' | 'sixty' | 'ninety' | 'custom';

export interface DateRangeValue {
    start: DateTime;
    end: DateTime;
}

interface Props {
    value: DateRangeValue;
    onChange: (value: DateRangeValue) => void;
}

function getDateRangeValueFromRangeChoice(choice: RangeChoice): DateRangeValue {
    switch (choice) {
        case 'all':
            return { start: DateTime.now().minus({ year: 20 }), end: DateTime.now() };
        case 'ninety':
            return {
                start: DateTime.now().minus({ day: 90 }).startOf('day'),
                end: DateTime.now(),
            };
        case 'thirty':
            return {
                start: DateTime.now().minus({ day: 30 }).startOf('day'),
                end: DateTime.now(),
            };
        case 'sixty':
            return {
                start: DateTime.now().minus({ day: 60 }).startOf('day'),
                end: DateTime.now(),
            };
        case 'seven':
            return {
                start: DateTime.now().minus({ day: 7 }).startOf('day'),
                end: DateTime.now(),
            };
    }
    return { start: DateTime.now().minus({ month: 6 }), end: DateTime.now() };
}

export default function RelativeDateRangeSelector({ value, onChange }: Props): React.ReactElement {
    const [range, setRange] = useState<RangeChoice>('ninety');

    function handleRangeChange(range: RangeChoice) {
        setRange(range);
        onChange(getDateRangeValueFromRangeChoice(range));
    }

    return (
        <div>
            <FormControl>
                <Select
                    variant={'outlined'}
                    labelId="date-range-label"
                    id="date-range"
                    value={range}
                    onChange={(e) => handleRangeChange((e.target.value as RangeChoice) ?? 'all')}
                >
                    <MenuItem value={'all'}>All Time</MenuItem>
                    <MenuItem value={'seven'}>Last 7 Days</MenuItem>
                    <MenuItem value={'thirty'}>Last 30 Days</MenuItem>
                    <MenuItem value={'sixty'}>Last 60 Days</MenuItem>
                    <MenuItem value={'ninety'}>Last 90 Days</MenuItem>
                    <MenuItem value={'custom'}>Custom</MenuItem>
                </Select>
            </FormControl>
            {range === 'custom' && (
                <Box display={'flex'} alignItems={'center'} mt={8}>
                    <SelectDateChip
                        onChange={(v) => onChange({ end: value.end, start: v ?? DateTime.now() })}
                        value={value.start ? value.start : null}
                    />
                    <Typography style={{ marginLeft: 6, marginRight: 6 }}> To </Typography>
                    <SelectDateChip
                        onChange={(v) => onChange({ start: value.start, end: v ?? DateTime.now() })}
                        value={value.end ? value.end : null}
                    />
                </Box>
            )}
        </div>
    );
}
