import { Box, FormHelperText, Grid, Paper, TextField } from '@mui/material';
import { useTheme } from '@mui/system';
import { AttributeInput, MovementPresets } from 'components';
import ViewAssetModal from 'components/Dialogs/ViewAssetModal';
import MediaSelectionGroup from 'components/FormControl/MediaSelectionGroup';
import { TextEditorInput } from 'components/Inputs';
import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { SetAttributeType, yupSchemas } from 'utils';
import {
    Asset,
    Attribute,
    AttributeCategory,
    AttributeValue,
    Movement,
    MovementPreset,
    PartialUploadList,
} from 'utils/types';
import { Media } from '../../../app.types';

interface EditMovementFormProps {
    /** assets An array of attributes to display in the attribute selection */
    attributes: Array<Attribute>;
    /** movement The movement we are updating */
    movement: Movement;
    /** onSubmit When the form is submitted */
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void;
    /** innerRef */
    innerRef: React.Ref<FormikProps<FormikValues>>;
    /** onAttributeValuesChanged */
    onAttributeValuesChanged: (values: AttributeValue[]) => void;
    /** onAssetDeleted */
    onAssetDeleted: (asset: Asset, index: number) => void;
    /** assetsToDelete An array of assets that are set to delete when the form is submitted */
    assetsToDelete: Array<Asset>;
    /** onFileSelected Submit the form */
    onFileSelected: (file: File) => void;
    /** onMediaSelected When a user chooses media from the media selection dialog */
    onMediaSelected: (media: Media) => void;
    /** uploads A partial upload list to display in the form */
    uploads: PartialUploadList;
}

export default function EditMovementForm({
    attributes,
    movement,
    onSubmit,
    innerRef,
    onAttributeValuesChanged,
    onAssetDeleted,
    assetsToDelete,
    onFileSelected,
    onMediaSelected,
    uploads,
}: EditMovementFormProps): ReactElement {
    const [attributeValues, setAttributeValues] = useState<AttributeValue[]>(
        movement?.attributes || [],
    );
    const theme = useTheme();
    const [selectedPresets, setSelectedPresets] = useState<MovementPreset>(
        movement.movement_presets ?? { [SetAttributeType.Reps as string]: null },
    );

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>();
    const [coverAsset, setCoverAsset] = useState<Asset | undefined>(movement.cover_asset);

    const handleFileSelected = (file: File) => {
        onFileSelected(file);
    };

    const handleAttributeValuesChanged = (attributeValues: AttributeValue[]) => {
        setAttributeValues(attributeValues);
        onAttributeValuesChanged(attributeValues);
    };

    const onAssetClicked = (asset: Asset) => {
        setDrawerOpen(true);
        setSelectedAsset(asset);
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
        setSelectedAsset(undefined);
    };
    const [count, setCount] = React.useState(0);

    useEffect(() => {
        setCoverAsset(movement.cover_asset);
    }, [movement.cover_asset]);

    return (
        <Formik
            innerRef={innerRef}
            initialValues={{
                name: movement.name,
                description: movement.description,
            }}
            validationSchema={yupSchemas.createMovement}
            onSubmit={(values, formikHelpers) => {
                // Pass selected presets along with the formik values
                values.movement_presets = selectedPresets;
                if (coverAsset) {
                    values.cover_asset_uuid = coverAsset.uuid;
                }
                onSubmit(values, formikHelpers);
            }}
        >
            {({ values, handleChange, errors, setFieldValue }) => {
                return (
                    <Form style={{ width: '100%' }}>
                        {selectedAsset && (
                            <ViewAssetModal
                                open={drawerOpen}
                                onClose={() => closeDrawer()}
                                asset={selectedAsset}
                            />
                        )}
                        <Paper>
                            <Box mt={5} mb={5} padding={5}>
                                <TextField
                                    sx={{ width: '100%', marginBottom: theme.spacing(4) }}
                                    label="Movement Name"
                                    id="name"
                                    name="name"
                                    variant="outlined"
                                    value={values.name}
                                    onChange={handleChange}
                                    error={Boolean(errors.name)}
                                    helperText={<>{errors.name}</>}
                                />
                            </Box>
                        </Paper>
                        <Paper>
                            <Grid
                                container
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    marginTop: 5,
                                    marginBottom: 5,
                                    padding: 5,
                                    justifyContent: 'center',
                                }}
                            >
                                <Box>
                                    <MediaSelectionGroup
                                        onFileSelected={handleFileSelected}
                                        onMediaSelected={onMediaSelected}
                                        onDeleteClicked={onAssetDeleted}
                                        onAssetClicked={onAssetClicked}
                                        uploads={uploads}
                                        assets={movement.assets.filter(
                                            (asset) =>
                                                assetsToDelete.findIndex(
                                                    (a) => a.uuid === asset.uuid,
                                                ) === -1,
                                        )}
                                        organization={movement?.organization?.uuid ?? ''}
                                        showCoverOptions={true}
                                        onCoverAssetUpdated={(coverAsset: Asset) =>
                                            setCoverAsset(coverAsset)
                                        }
                                        defaultCoverAssetUuid={movement?.cover_asset?.uuid}
                                    />
                                </Box>
                            </Grid>
                        </Paper>
                        <Paper>
                            <Box padding={5}>
                                <TextEditorInput
                                    label={`Description ${count}/500`}
                                    placeholder="Enter description here"
                                    defaultValue={values.description || ''}
                                    onChange={(newDescription) => {
                                        setFieldValue('description', newDescription);
                                        setCount(newDescription.length);
                                    }}
                                />
                                {Boolean(errors.description) && (
                                    <FormHelperText sx={{ fontSize: 18 }} error>
                                        {errors.description as string}
                                    </FormHelperText>
                                )}
                            </Box>
                        </Paper>
                        <Paper>
                            <Box mt={5} mb={5} padding={5}>
                                <MovementPresets
                                    onChange={setSelectedPresets}
                                    value={selectedPresets}
                                />
                            </Box>
                        </Paper>
                        <Paper>
                            <Box sx={{ padding: 5 }}>
                                <AttributeInput
                                    attributes={attributes}
                                    attributeCategory={AttributeCategory.Movement}
                                    value={attributeValues}
                                    onChange={(values) => handleAttributeValuesChanged(values)}
                                />
                            </Box>
                        </Paper>
                    </Form>
                );
            }}
        </Formik>
    );
}
