import { Box, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Crop, RawDataPoint, RawDataPointEnhanced } from '../../community/community.types';
import {
    getAllCropsForExploration,
    getRawUploadDataFromUrl,
} from '../../community/api/performance.api';
import { Exploration, ExplorationFilters } from '../explore.types';
import { convertApiFiltersToExplorationFilters } from '../explore.func';
import {
    computeAdditionalCropMetrics,
    convertCubeDateRangeToApiDateRange,
    getAllRawDataForPoints,
} from '../../../app.functions';
import { RawDataPointMetrics } from '../../../app.types';
import ExplorationCropsListOfCharts from './ExplorationCropsListOfCharts';
import ExplorationCropsSingleChart from './ExplorationCropsSingleChart';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { keyframes, styled } from '@mui/system';

const ExplorationCrops = ({
    exploration,
    onExcludedCropsChanged,
}: {
    exploration: Exploration;
    onExcludedCropsChanged: (crops: string[]) => void;
}) => {
    const [crops, setCrops] = useState<Crop[]>([]);
    const [cropData, setCropData] = useState<{ [key: string]: RawDataPointEnhanced[] | false }>({});
    const [filters, setFilters] = useState<ExplorationFilters>(
        convertApiFiltersToExplorationFilters(exploration.filters),
    );
    const [cropAdditionalData, setCropAdditionalData] = useState<{
        [key: string]: RawDataPointMetrics;
    }>({});

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        const filters = convertApiFiltersToExplorationFilters(exploration.filters);
        setFilters(filters);

        const dates = convertCubeDateRangeToApiDateRange(filters.range);

        getAllCropsForExploration(exploration.uuid, {
            after: dates.start ? dates.start.toMillis() : undefined,
            before: dates.end ? dates.end.toMillis() : undefined,
        })
            .then((response) => {
                setCrops(response.data);
            })
            .catch(() => {})
            .finally(() => {
                // Delay hiding the loading by 1 second
                setTimeout(() => {
                    setLoading(false); // Set loading to false after the delay
                }, 500);
            });
    }, [exploration.uuid, exploration.filters]);

    useEffect(() => {
        if (crops.length > 0) {
            // retrieve the data from crop.raw_data_url in parallel
            // and store the data in the cropData object
            Promise.allSettled(
                crops.map((crop) => {
                    return getRawUploadDataFromUrl(crop.raw_data_url)
                        .then((response) => {
                            return Promise.resolve([crop, response.data]);
                        })
                        .catch(() => {
                            return Promise.resolve([crop, false]);
                        });
                }),
            )
                .then((responses) => {
                    const returnedCropData: { [key: string]: RawDataPointEnhanced[] | false } = {};
                    const returnedCropAdditionalData: { [key: string]: RawDataPointMetrics } = {};
                    responses.forEach((response) => {
                        if (response.status === 'fulfilled') {
                            const enhancedData = getAllRawDataForPoints(
                                response.value[1] as RawDataPoint[],
                            );
                            returnedCropData[(response.value[0] as Crop).uuid as string] =
                                enhancedData;
                            returnedCropAdditionalData[(response.value[0] as Crop).uuid as string] =
                                computeAdditionalCropMetrics(enhancedData);
                        }
                    });
                    setCropData(returnedCropData);
                    setCropAdditionalData(returnedCropAdditionalData);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [crops]);

    const fadeInOut = keyframes`
      0% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.5;
      }
    `;

    const AnimatedTypography = styled(Typography)(() => ({
        animation: `${fadeInOut} 2s infinite`,
    }));

    return (
        <>
            <Modal
                open={loading}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)', // semi-transparent background
                }}
            >
                <>
                    <AnimatedTypography variant={'h6'} textAlign={'center'}>
                        Searching for Data . . .
                    </AnimatedTypography>
                    <CustomLoadingOverlay />
                </>
            </Modal>
            {crops.length === 0 && (
                <Box
                    sx={{
                        p: 8,
                    }}
                >
                    <Typography>
                        No crops found for the selected filters. Please try again.
                    </Typography>
                </Box>
            )}
            {crops.length > 0 && !filters.showOnSameGraph && cropData && cropAdditionalData && (
                <ExplorationCropsListOfCharts
                    exploration={exploration}
                    crops={crops}
                    cropData={cropData}
                    cropAdditionalData={cropAdditionalData}
                />
            )}
            {crops.length > 0 && filters.showOnSameGraph && cropData && cropAdditionalData && (
                <ExplorationCropsSingleChart
                    exploration={exploration}
                    crops={crops}
                    cropData={cropData}
                    cropAdditionalData={cropAdditionalData}
                    onExcludedCropsChanged={onExcludedCropsChanged}
                    filters={filters}
                />
            )}
        </>
    );
};

export default ExplorationCrops;
