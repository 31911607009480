import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSearchToken } from 'api';
import { AxiosError } from 'axios';
import { SearchToken } from 'utils';
import { RootState } from '.';

export interface SearchTokenError {
    message: string;
    errors?: { [key: string]: Array<string> };
}

export interface SearchTokenState {
    data: SearchToken | undefined;
    error: SearchTokenError;
}

export const initialState: SearchTokenState = {
    data: undefined,
    error: { message: '' },
};

export const loadToken = createAsyncThunk<
    SearchToken,
    undefined,
    {
        rejectValue: SearchTokenError;
    }
>('searchToken/get', async (_, { rejectWithValue }) => {
    try {
        const { data } = await getSearchToken();
        return { key: data.key, expires: data.expires };
    } catch (err: any) {
        const error: AxiosError<SearchTokenError> = err;
        return rejectWithValue(error.response?.data ?? { message: 'Error' });
    }
});

export const searchTokenSlice = createSlice({
    name: 'searchToken',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadToken.fulfilled, (state, { payload }) => {
            state.data = payload;
            state.error = { message: '' };
        });
        addCase(loadToken.pending, (state) => {
            state.error = { message: '' };
        });
        addCase(loadToken.rejected, (state, { payload }) => {
            state.error.message = payload?.message ?? 'Error Loading Search Token';
        });
    },
});

export const searchTokenSelector = (state: RootState) => state.searchToken;
