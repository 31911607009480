import { CubeProvider, QueryRenderer } from '@cubejs-client/react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Typography } from '@mui/material';
import { cubejsApiForOrganization, formatCurrency } from 'app.functions';
import { DateTime } from 'luxon';
import React from 'react';
import {
    Bar,
    BarChart,
    Cell,
    Legend,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
} from 'recharts';
import { useNetworkingOrganization } from '../state/NetworkingContext';
import { AnalyticsTooltip } from './AnalyticsTooltip';
import DasboardCard from './DashboardCard';
import GoldMedal from '../../../images/gold-medal.png';

const PartnershipCommissionsAnalytics: React.FC = () => {
    const organizationDetails = useNetworkingOrganization();

    return organizationDetails.data ? (
        <CubeProvider cubejsApi={cubejsApiForOrganization}>
            <QueryRenderer
                query={{
                    measures: ['FactCommissions.totalAmount'],
                    timeDimensions: [
                        {
                            dimension: 'FactCommissions.earnedDate',
                            granularity: 'day',
                            dateRange: [
                                DateTime.now().minus({ year: 1 }).toISODate(),
                                DateTime.now().toISODate(),
                            ],
                        },
                    ],
                    order: {
                        'FactCommissions.earnedDate': 'desc',
                    },
                    filters: [
                        {
                            member: 'DimOrganization.uuid',
                            operator: 'equals',
                            values: [organizationDetails.data.uuid],
                        },
                    ],
                }}
                render={({ resultSet }) => {
                    if (!resultSet) return null;

                    const dailyData = resultSet.tablePivot({ fillMissingDates: true });
                    // Calculate period totals
                    const last30Days = dailyData
                        .slice(-30)
                        .reduce(
                            (sum, row) =>
                                sum + (Number(row['FactCommissions.totalAmount']) / 100 || 0),
                            0,
                        );
                    const previous30Days = dailyData
                        .slice(-60, -30)
                        .reduce(
                            (sum, row) =>
                                sum + (Number(row['FactCommissions.totalAmount']) / 100 || 0),
                            0,
                        );
                    const last60Days = dailyData
                        .slice(-60)
                        .reduce(
                            (sum, row) =>
                                sum + (Number(row['FactCommissions.totalAmount']) / 100 || 0),
                            0,
                        );
                    const previous60Days = dailyData
                        .slice(-120, -60)
                        .reduce(
                            (sum, row) =>
                                sum + (Number(row['FactCommissions.totalAmount']) / 100 || 0),
                            0,
                        );
                    const last90Days = dailyData
                        .slice(-90)
                        .reduce(
                            (sum, row) =>
                                sum + (Number(row['FactCommissions.totalAmount']) / 100 || 0),
                            0,
                        );
                    const previous90Days = dailyData
                        .slice(-180, -90)
                        .reduce(
                            (sum, row) =>
                                sum + (Number(row['FactCommissions.totalAmount']) / 100 || 0),
                            0,
                        );

                    // Calculate percent changes
                    const percent30Days = previous30Days
                        ? ((last30Days - previous30Days) / previous30Days) * 100
                        : 0;
                    const percent60Days = previous60Days
                        ? ((last60Days - previous60Days) / previous60Days) * 100
                        : 0;
                    const percent90Days = previous90Days
                        ? ((last90Days - previous90Days) / previous90Days) * 100
                        : 0;

                    // Transform data for chart
                    const chartData = dailyData.slice(-180).reduce((acc: any[], row) => {
                        const date = DateTime.fromISO(
                            row['FactCommissions.earnedDate.day'] as string,
                        );
                        const monthKey = date.toFormat('LLL yyyy');
                        const amount = Number(row['FactCommissions.totalAmount']) / 100 || 0;

                        const existingMonth = acc.find((item) => item.date === monthKey);
                        if (existingMonth) {
                            existingMonth.amount += amount;
                        } else {
                            acc.push({ date: monthKey, amount });
                        }
                        return acc;
                    }, []);

                    return (
                        <>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr 1fr' },
                                    gap: 6,
                                    my: 4,
                                }}
                            >
                                <DasboardCard
                                    title="Earned Last 30 Days"
                                    value={formatCurrency({
                                        amount: last30Days,
                                        currency: 'USD',
                                    })}
                                    subtext={`${
                                        percent30Days >= 0 ? '+' : ''
                                    }${percent30Days.toFixed(1)}% vs previous period`}
                                    icon={
                                        percent30Days === 0 ? undefined : percent30Days > 0 ? (
                                            <ArrowUpwardIcon color="success" />
                                        ) : (
                                            <ArrowDownwardIcon color="error" />
                                        )
                                    }
                                />
                                <DasboardCard
                                    title="Earned Last 60 Days"
                                    value={formatCurrency({
                                        amount: last60Days,
                                        currency: 'USD',
                                    })}
                                    subtext={`${
                                        percent60Days >= 0 ? '+' : ''
                                    }${percent60Days.toFixed(1)}% vs previous period`}
                                    icon={
                                        percent60Days === 0 ? undefined : percent60Days > 0 ? (
                                            <ArrowUpwardIcon color="success" />
                                        ) : (
                                            <ArrowDownwardIcon color="error" />
                                        )
                                    }
                                />
                                <DasboardCard
                                    title="Earned Last 90 Days"
                                    value={formatCurrency({
                                        amount: last90Days,
                                        currency: 'USD',
                                    })}
                                    subtext={`${
                                        percent90Days >= 0 ? '+' : ''
                                    }${percent90Days.toFixed(1)}% vs previous period`}
                                    icon={
                                        percent90Days === 0 ? undefined : percent90Days > 0 ? (
                                            <ArrowUpwardIcon color="success" />
                                        ) : (
                                            <ArrowDownwardIcon color="error" />
                                        )
                                    }
                                />
                                <QueryRenderer
                                    query={{
                                        measures: ['FactCommissions.totalAmount'],
                                        filters: [
                                            {
                                                member: 'DimOrganization.uuid',
                                                operator: 'equals',
                                                values: [organizationDetails.data?.uuid || ''],
                                            },
                                            {
                                                member: 'FactCommissions.isPaid',
                                                operator: 'equals',
                                                values: ['false'],
                                            },
                                        ],
                                    }}
                                    render={({ resultSet }) => {
                                        if (!resultSet) return null;
                                        const unpaidAmount = resultSet
                                            .tablePivot()
                                            .reduce(
                                                (sum, row) =>
                                                    sum +
                                                    Number(row['FactCommissions.totalAmount']) /
                                                        100,
                                                0,
                                            );

                                        return (
                                            <DasboardCard
                                                title="Total Unpaid Commissions"
                                                value={formatCurrency({
                                                    amount: unpaidAmount,
                                                    currency: 'USD',
                                                })}
                                            />
                                        );
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: {
                                        xs: '1fr',
                                        md: '1fr 1fr 2fr',
                                    },
                                    gap: 6,
                                    mb: 4,
                                }}
                            >
                                <QueryRenderer
                                    query={{
                                        measures: ['FactCommissions.totalAmount'],
                                        filters: [
                                            {
                                                member: 'DimOrganization.uuid',
                                                operator: 'equals',
                                                values: [organizationDetails.data?.uuid || ''],
                                            },
                                        ],
                                    }}
                                    render={({ resultSet }) => {
                                        if (!resultSet) return null;
                                        const totalAmount = resultSet
                                            .tablePivot()
                                            .reduce(
                                                (sum, row) =>
                                                    sum +
                                                    Number(row['FactCommissions.totalAmount']) /
                                                        100,
                                                0,
                                            );

                                        return (
                                            <DasboardCard
                                                title="Total Commissions Earned"
                                                value={formatCurrency({
                                                    amount: totalAmount,
                                                    currency: 'USD',
                                                })}
                                                icon={
                                                    totalAmount > 0 ? (
                                                        <img
                                                            src={GoldMedal}
                                                            alt="Gold Medal"
                                                            style={{ width: 24, height: 24 }}
                                                        />
                                                    ) : undefined
                                                }
                                            />
                                        );
                                    }}
                                />
                                <QueryRenderer
                                    query={{
                                        measures: ['FactCommissions.totalAmount'],
                                        dimensions: ['DimOtherOrganization.name', 'DimBonus.type'],
                                        timeDimensions: [
                                            {
                                                dimension: 'FactCommissions.earnedDate',
                                                dateRange: [
                                                    DateTime.now().minus({ months: 3 }).toISODate(),
                                                    DateTime.now().toISODate(),
                                                ],
                                            },
                                        ],
                                        filters: [
                                            {
                                                member: 'DimOrganization.uuid',
                                                operator: 'equals',
                                                values: [organizationDetails?.data?.uuid || ''],
                                            },
                                            {
                                                member: 'DimOtherOrganization.uuid',
                                                operator: 'notEquals',
                                                values: [organizationDetails?.data?.uuid || ''],
                                            },
                                        ],
                                        order: {
                                            'FactCommissions.totalAmount': 'desc',
                                        },
                                        limit: 10,
                                    }}
                                    render={({ resultSet }) => {
                                        if (!resultSet) return null;

                                        const topPartners = resultSet.tablePivot().map((row) => ({
                                            partnerName: row['DimOtherOrganization.name'],
                                            commissionType: row['DimBonus.type'] as string,
                                            totalAmount:
                                                Number(row['FactCommissions.totalAmount']) / 100,
                                        }));

                                        return (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    bgcolor: 'background.paper',
                                                    borderRadius: 1,
                                                    p: 3,
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <Typography variant="h6" gutterBottom>
                                                    Top Earnings By Partner And Type
                                                </Typography>
                                                <Box sx={{ mt: 2, height: 150, overflowY: 'auto' }}>
                                                    {topPartners.length > 0 ? (
                                                        topPartners.map((partner, index) => (
                                                            <Box
                                                                key={
                                                                    partner.partnerName +
                                                                    partner.commissionType +
                                                                    index.toString()
                                                                }
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    py: 1.5,
                                                                    borderBottom: '1px solid',
                                                                    borderColor: 'divider',
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            minWidth: 30,
                                                                            color: 'text.secondary',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                    >
                                                                        #{index + 1}
                                                                    </Typography>
                                                                    <Box sx={{ ml: 2 }}>
                                                                        <Typography variant="body1">
                                                                            {partner.partnerName}
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="text.secondary"
                                                                        >
                                                                            {partner.commissionType}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Typography
                                                                    variant="body1"
                                                                    sx={{ fontWeight: 'bold' }}
                                                                >
                                                                    {formatCurrency({
                                                                        amount: partner.totalAmount,
                                                                        currency: 'USD',
                                                                    })}
                                                                </Typography>
                                                            </Box>
                                                        ))
                                                    ) : (
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                height: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: 'text.secondary',
                                                            }}
                                                        >
                                                            No partner earnings data available yet
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                />
                                <Box
                                    sx={{
                                        height: 200,
                                        bgcolor: 'background.paper',
                                        borderRadius: 1,
                                        p: 3,
                                        boxShadow: 1,
                                    }}
                                >
                                    {chartData.some((row) => row.amount > 0) ? (
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart data={chartData}>
                                                <XAxis dataKey="date" />
                                                <Tooltip content={<AnalyticsTooltip />} />
                                                <Bar dataKey="amount" fill="#8884d8" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    ) : (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'text.secondary',
                                            }}
                                        >
                                            No earnings data available yet
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: {
                                        xs: '1fr',
                                        md: '1fr 1fr 1fr',
                                    },
                                    gap: 6,
                                    mb: 4,
                                }}
                            >
                                <QueryRenderer
                                    query={{
                                        measures: ['FactCommissions.totalAmount'],
                                        dimensions: ['DimBonus.type'],
                                        filters: [
                                            {
                                                member: 'DimOrganization.uuid',
                                                operator: 'equals',
                                                values: [organizationDetails?.data?.uuid || ''],
                                            },
                                        ],
                                    }}
                                    render={({ resultSet }) => {
                                        if (!resultSet) return null;

                                        const bonusData = resultSet.tablePivot();
                                        const total = bonusData.reduce(
                                            (sum, row) =>
                                                sum +
                                                Number(row['FactCommissions.totalAmount']) / 100,
                                            0,
                                        );

                                        const pieData = bonusData.map((row) => ({
                                            name: `${row['DimBonus.type']} `,
                                            value:
                                                (Number(row['FactCommissions.totalAmount']) /
                                                    100 /
                                                    total) *
                                                100,
                                        }));

                                        const COLORS = [
                                            '#0088FE',
                                            '#00C49F',
                                            '#FFBB28',
                                            '#FF8042',
                                            '#8884D8',
                                        ];

                                        return (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    bgcolor: 'background.paper',
                                                    borderRadius: 1,
                                                    p: 3,
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <h3>Commission Types Breakdown</h3>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        height: 300,
                                                        width: '100%',
                                                    }}
                                                >
                                                    <Box sx={{ width: '100%', height: '100%' }}>
                                                        {pieData.length > 0 ? (
                                                            <ResponsiveContainer
                                                                width="100%"
                                                                height="100%"
                                                            >
                                                                <PieChart>
                                                                    <Pie
                                                                        data={pieData}
                                                                        dataKey="value"
                                                                        nameKey="name"
                                                                        cx="50%"
                                                                        cy="50%"
                                                                        label={false}
                                                                    >
                                                                        {pieData.map(
                                                                            (entry, index) => (
                                                                                <Cell
                                                                                    key={`cell-${index}`}
                                                                                    fill={
                                                                                        COLORS[
                                                                                            index %
                                                                                                COLORS.length
                                                                                        ]
                                                                                    }
                                                                                />
                                                                            ),
                                                                        )}
                                                                    </Pie>
                                                                    <Tooltip
                                                                        formatter={(
                                                                            value,
                                                                            name,
                                                                        ) => [
                                                                            `${
                                                                                typeof value ===
                                                                                'number'
                                                                                    ? value.toFixed(
                                                                                          1,
                                                                                      )
                                                                                    : value
                                                                            }%`,
                                                                            name,
                                                                        ]}
                                                                    />
                                                                    <Legend
                                                                        layout="vertical"
                                                                        align="right"
                                                                        verticalAlign="middle"
                                                                        formatter={(
                                                                            value,
                                                                            entry,
                                                                        ) => (
                                                                            <span
                                                                                style={{
                                                                                    fontSize:
                                                                                        '0.650rem',
                                                                                }}
                                                                            >
                                                                                {value}:{' '}
                                                                                {entry.payload!.value.toFixed(
                                                                                    1,
                                                                                )}
                                                                                %
                                                                            </span>
                                                                        )}
                                                                        wrapperStyle={{
                                                                            paddingLeft: '20px',
                                                                            maxWidth: '40%',
                                                                            overflowWrap:
                                                                                'break-word',
                                                                            maxHeight: '300px',
                                                                            overflowY: 'auto',
                                                                        }}
                                                                    />
                                                                </PieChart>
                                                            </ResponsiveContainer>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    height: '100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <Typography color="text.secondary">
                                                                    No commission data available
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                />
                                <QueryRenderer
                                    query={{
                                        measures: ['FactCommissions.totalAmount'],
                                        dimensions: ['FactCommissions.athleteUserKey'],
                                        filters: [
                                            {
                                                member: 'DimOrganization.uuid',
                                                operator: 'equals',
                                                values: [organizationDetails?.data?.uuid || ''],
                                            },
                                        ],
                                    }}
                                    render={({ resultSet }) => {
                                        if (!resultSet) return null;

                                        const salesData = resultSet.tablePivot();
                                        const total = salesData.reduce(
                                            (sum, row) =>
                                                sum +
                                                Number(row['FactCommissions.totalAmount']) / 100,
                                            0,
                                        );

                                        const pieData = [
                                            {
                                                name: 'Athlete Sales',
                                                value:
                                                    (salesData
                                                        .filter(
                                                            (row) =>
                                                                row[
                                                                    'FactCommissions.athleteUserKey'
                                                                ],
                                                        )
                                                        .reduce(
                                                            (sum, row) =>
                                                                sum +
                                                                Number(
                                                                    row[
                                                                        'FactCommissions.totalAmount'
                                                                    ],
                                                                ) /
                                                                    100,
                                                            0,
                                                        ) /
                                                        total) *
                                                    100,
                                            },
                                            {
                                                name: 'Organization Sales',
                                                value:
                                                    (salesData
                                                        .filter(
                                                            (row) =>
                                                                !row[
                                                                    'FactCommissions.athleteUserKey'
                                                                ],
                                                        )
                                                        .reduce(
                                                            (sum, row) =>
                                                                sum +
                                                                Number(
                                                                    row[
                                                                        'FactCommissions.totalAmount'
                                                                    ],
                                                                ) /
                                                                    100,
                                                            0,
                                                        ) /
                                                        total) *
                                                    100,
                                            },
                                        ];

                                        const COLORS = ['#0088FE', '#00C49F'];

                                        return (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    bgcolor: 'background.paper',
                                                    borderRadius: 1,
                                                    p: 3,
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <h3>Sales Source Breakdown</h3>
                                                <Box sx={{ height: 300 }}>
                                                    {pieData.length > 0 ? (
                                                        <ResponsiveContainer>
                                                            <PieChart>
                                                                <Pie
                                                                    data={pieData}
                                                                    dataKey="value"
                                                                    nameKey="name"
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    label={false}
                                                                >
                                                                    {pieData.map((entry, index) => (
                                                                        <Cell
                                                                            key={`cell-${index}`}
                                                                            fill={
                                                                                COLORS[
                                                                                    index %
                                                                                        COLORS.length
                                                                                ]
                                                                            }
                                                                        />
                                                                    ))}
                                                                </Pie>
                                                                <Tooltip
                                                                    formatter={(value, name) => [
                                                                        `${
                                                                            typeof value ===
                                                                            'number'
                                                                                ? value.toFixed(1)
                                                                                : value
                                                                        }%`,
                                                                        name,
                                                                    ]}
                                                                />
                                                                <Legend
                                                                    layout="vertical"
                                                                    align="right"
                                                                    verticalAlign="middle"
                                                                    formatter={(value, entry) => (
                                                                        <span
                                                                            style={{
                                                                                fontSize:
                                                                                    '0.650rem',
                                                                            }}
                                                                        >
                                                                            {value}:{' '}
                                                                            {!entry.payload!.value
                                                                                ? '-'
                                                                                : entry.payload!.value.toFixed(
                                                                                      1,
                                                                                  )}
                                                                            %
                                                                        </span>
                                                                    )}
                                                                    wrapperStyle={{
                                                                        paddingLeft: '20px',
                                                                        maxWidth: '40%',
                                                                        overflowWrap: 'break-word',
                                                                        maxHeight: '300px',
                                                                        overflowY: 'auto',
                                                                    }}
                                                                />
                                                            </PieChart>
                                                        </ResponsiveContainer>
                                                    ) : (
                                                        <Box
                                                            sx={{
                                                                height: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <Typography color="text.secondary">
                                                                No sales data available
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                />
                                <QueryRenderer
                                    query={{
                                        measures: ['FactCommissions.totalAmount'],
                                        dimensions: ['DimUser.userName'],
                                        filters: [
                                            {
                                                member: 'DimOrganization.uuid',
                                                operator: 'equals',
                                                values: [organizationDetails?.data?.uuid || ''],
                                            },
                                            {
                                                member: 'FactCommissions.athleteUserKey',
                                                operator: 'set',
                                            },
                                            {
                                                member: 'DimUser.uuid',
                                                operator: 'set',
                                            },
                                        ],
                                        order: {
                                            'FactCommissions.totalAmount': 'desc',
                                        },
                                        limit: 5,
                                    }}
                                    render={({ resultSet }) => {
                                        if (!resultSet) return null;

                                        const athleteData = resultSet.tablePivot();

                                        return (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    bgcolor: 'background.paper',
                                                    borderRadius: 1,
                                                    p: 3,
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <Typography variant="h6" sx={{ mb: 2 }}>
                                                    Top 5 Athletes by Earnings
                                                </Typography>
                                                {athleteData.length > 0 ? (
                                                    <Box>
                                                        {athleteData.map((row, index) => (
                                                            <Box
                                                                key={index}
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    py: 1,
                                                                    borderBottom: '1px solid',
                                                                    borderColor: 'divider',
                                                                }}
                                                            >
                                                                <Typography>
                                                                    {row['DimUser.userName']}
                                                                </Typography>
                                                                <Typography>
                                                                    {formatCurrency({
                                                                        amount:
                                                                            Number(
                                                                                row[
                                                                                    'FactCommissions.totalAmount'
                                                                                ],
                                                                            ) / 100,
                                                                        currency: 'USD',
                                                                    })}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            py: 4,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: 'text.secondary',
                                                        }}
                                                    >
                                                        No athlete earnings data available yet
                                                    </Box>
                                                )}
                                            </Box>
                                        );
                                    }}
                                />
                            </Box>
                        </>
                    );
                }}
            />
        </CubeProvider>
    ) : (
        <Box />
    );
};

export default PartnershipCommissionsAnalytics;
