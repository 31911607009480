import React, { useEffect, useMemo, useState, useCallback } from 'react';
import ReactFlow, {
    Background,
    Controls,
    MiniMap,
    useEdgesState,
    useNodesState,
    useReactFlow,
    ReactFlowProvider,
} from 'reactflow';
import PartnerViewNode from '../../../../networking/components/PartnerViewNode';
import {
    Box,
    Typography,
    List,
    ListItem,
    Alert,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
} from '@mui/material';
import FilterPanel from '../../../../networking/components/NodeFilter';
import { filterNodes, getLayoutedElements } from '../../../../networking/networking.functions';
import { AdminTreeNodes, AdminRank } from '../binaryTree.types';
import {
    adminGetBinaryTree,
    adminGetRanks,
    adminUpdateBinaryTree,
    BinaryTreeOperation,
} from '../binaryTree.api';
import { Node } from 'reactflow';
import { AccountTree } from '@mui/icons-material';
import InviteNewPartnerNode from '../../../../networking/components/InviteNewPartnerNode';

interface NodeData {
    label: string;
    partnerId?: string;
    treeNode?: AdminTreeNodes;
}

interface AttachmentPoint {
    nodeId: string;
    name: string;
    position: 'L' | 'R';
}

interface OrphanedNode {
    id: string;
    name: string;
    partnerId: string;
    previousParentId?: string;
    previousParentName?: string;
}

interface TreeOperation {
    type: 'detach' | 'attach' | 'delete';
    nodeId: string;
    nodeName: string;
    fromParentId?: string;
    fromParentName?: string;
    toParentId?: string;
    toParentName?: string;
    position?: 'L' | 'R';
}

interface TreeOperationModalProps {
    isOpen: boolean;
    onClose: () => void;
    success: boolean;
    message?: string;
    completedOperations?: string[];
    errorMessage?: string;
    errors?: string;
}

const TreeOperationModal = ({
    isOpen,
    onClose,
    success,
    message,
    completedOperations = [],
    errorMessage,
    errors,
}: TreeOperationModalProps) => {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <AccountTree sx={{ marginRight: '8px' }} />
                {success ? 'Success!' : 'Error'}
            </DialogTitle>
            <DialogContent dividers>
                {success ? (
                    <Box>
                        <Typography variant="subtitle1" gutterBottom>
                            {message || 'The following operations were completed:'}
                        </Typography>
                        {completedOperations && completedOperations.length > 0 ? (
                            <List sx={{ mt: 2 }}>
                                {completedOperations.map((operation, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            py: 1,
                                        }}
                                    >
                                        <Typography variant="body2">
                                            {index + 1}. {operation}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                                No operations were performed.
                            </Typography>
                        )}
                    </Box>
                ) : (
                    <Box>
                        <Alert severity="error" sx={{ mt: 1 }}>
                            {errorMessage || 'An error occurred while processing your request.'}
                        </Alert>
                        {errors && (
                            <pre
                                style={{
                                    backgroundColor: '#f5f5f5',
                                    padding: '10px',
                                    borderRadius: '4px',
                                    marginTop: '10px',
                                    overflowX: 'auto',
                                    fontSize: '0.6rem',
                                }}
                            >
                                {JSON.stringify(JSON.parse(errors), null, 2)}
                            </pre>
                        )}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" variant="contained">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const OperationsPanel = ({
    operations,
    orphanedNodes,
    onApply,
    onCancel,
}: {
    operations: TreeOperation[];
    orphanedNodes: OrphanedNode[];
    onApply: () => void;
    onCancel: () => void;
}) => {
    // Find nodes marked for deletion from operations
    const nodesToDelete = operations.filter((op) => op.type === 'delete').map((op) => op.nodeId);

    // Filter out orphaned nodes that are marked for deletion
    const nonDeleteOrphans = orphanedNodes.filter(
        (orphan) => !nodesToDelete.includes(orphan.partnerId),
    );
    return (
        <Alert
            severity="warning"
            icon={false}
            sx={{
                width: '95%',
                border: '1px solid red',
                borderRadius: 1,
                margin: '4px 4px 4px 4px',
            }}
        >
            <Typography variant="h6" gutterBottom>
                Pending Tree Operations
            </Typography>
            {operations.map((op, index) => (
                <Typography key={index} sx={{ mb: 1, fontSize: '0.7rem', fontWeight: 500 }}>
                    {index + 1}.{' '}
                    {op.type === 'detach'
                        ? `Node ${op.nodeName} will be detached from parent ${op.fromParentName}`
                        : op.type === 'delete'
                        ? `Node ${op.nodeName} will be deleted`
                        : `Node ${op.nodeName} will be attached to the ${
                              op.position === 'L' ? 'left' : 'right'
                          } leg of new parent ${op.toParentName}`}
                </Typography>
            ))}

            {nonDeleteOrphans.length > 0 && (
                <Alert severity="error" sx={{ mt: 2, mb: 2, fontSize: '0.7rem' }}>
                    {nonDeleteOrphans.length} node(s) still need to be reattached:
                    {nonDeleteOrphans.map((node) => (
                        <Typography
                            key={node.id}
                            component="div"
                            variant="body2"
                            sx={{ fontSize: '0.7rem', marginLeft: '4px' }}
                        >
                            - {node.name} (previously attached to {node.previousParentName})
                        </Typography>
                    ))}
                </Alert>
            )}

            <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={nonDeleteOrphans.length > 0}
                    onClick={onApply}
                >
                    Apply Changes
                </Button>
                <Button variant="contained" color="error" size="small" onClick={onCancel}>
                    Cancel
                </Button>
            </Box>
        </Alert>
    );
};

const Flow = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    // Immediate input
    const [nameFilterInput, setNameFilterInput] = useState('');
    // Debounced filter
    const [nameFilter, setNameFilter] = useState('');
    const [rankFilter, setRankFilter] = useState<string[]>([]);
    const [salesFilter, setSalesFilter] = useState<number | ''>('');
    const [subscriptionFilter, setSubscriptionFilter] = useState<string[]>([]);
    const [athleteFilter, setAthleteFilter] = useState<string[]>([]);
    const nodeTypes = useMemo(
        () => ({
            partnerViewer: PartnerViewNode,
            inviteNewPartner: InviteNewPartnerNode,
        }),
        [],
    );
    const [binaryTree, setBinaryTree] = useState<AdminTreeNodes | null>(null);
    const [ranksData, setRanksData] = useState<AdminRank[] | null>(null);
    const { setCenter } = useReactFlow();
    const [detachedNodes, setDetachedNodes] = useState<{ [key: string]: boolean }>({});
    const [treeOperations, setTreeOperations] = useState<TreeOperation[]>([]);
    const [orphanedNodes, setOrphanedNodes] = useState<OrphanedNode[]>([]);
    const [nodesToDelete, setNodesToDelete] = useState<{ [key: string]: boolean }>({});
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [modalErrorMessage, setModalErrorMessage] = useState<string>('');
    const [modalErrors, setModalErrors] = useState<string>('');
    const [modalCompletedOperations, setModalCompletedOperations] = useState<string[]>([]); // Changed from modalOperations

    useEffect(() => {
        const timer = setTimeout(() => {
            setNameFilter(nameFilterInput);
        }, 300);

        return () => clearTimeout(timer);
    }, [nameFilterInput]);

    const findNodeInTree = useCallback(
        (tree: AdminTreeNodes, partnerId: string): AdminTreeNodes | null => {
            if (tree.partner === partnerId) return tree;
            for (const child of tree.children) {
                const found = findNodeInTree(child, partnerId);
                if (found) return found;
            }
            return null;
        },
        [],
    );

    const getAvailableAttachmentPoints = useCallback(
        (currentNodes: Node<NodeData>[]): AttachmentPoint[] => {
            const attachmentPoints: AttachmentPoint[] = [];

            // Build child count map from current edges
            const childCount = new Map<string, number>();

            // Initialize all nodes with 0 children
            currentNodes.forEach((node) => {
                if (!detachedNodes[node.data.partnerId || '']) {
                    childCount.set(node.id, 0);
                }
            });

            // Count actual children from edges, ignoring Create nodes
            edges.forEach((edge) => {
                const targetNode = currentNodes.find((n) => n.id === edge.target);
                // Only count if target exists, isn't detached, and isn't a Create node
                if (
                    !detachedNodes[edge.target] &&
                    targetNode &&
                    targetNode.data.label !== 'Create'
                ) {
                    const count = (childCount.get(edge.source) || 0) + 1;
                    childCount.set(edge.source, count);
                }
            });

            // Find available positions
            currentNodes.forEach((node) => {
                // Skip if this is a detached node or Create node
                if (detachedNodes[node.data.partnerId || ''] || node.data.label === 'Create') {
                    return;
                }

                const currentChildCount = childCount.get(node.id) || 0;

                if (currentChildCount < 2) {
                    if (currentChildCount === 0) {
                        // No real children, both positions available
                        attachmentPoints.push({
                            nodeId: node.id,
                            name: node.data.label,
                            position: 'L',
                        });
                        attachmentPoints.push({
                            nodeId: node.id,
                            name: node.data.label,
                            position: 'R',
                        });
                    } else if (currentChildCount === 1) {
                        // Find which position is taken by a real (non-Create) node
                        const takenPosition = edges
                            .find(
                                (e) =>
                                    e.source === node.id &&
                                    !detachedNodes[e.target] &&
                                    currentNodes.find(
                                        (n) => n.id === e.target && n.data.label !== 'Create',
                                    ),
                            )
                            ?.sourceHandle?.toUpperCase();

                        const availablePosition = takenPosition === 'L' ? 'R' : 'L';
                        attachmentPoints.push({
                            nodeId: node.id,
                            name: node.data.label,
                            position: availablePosition,
                        });
                    }
                }
            });

            return attachmentPoints;
        },
        [edges, detachedNodes],
    );

    // Inside the Flow component, before other hooks and handlers:
    const checkOperationsCancel = useCallback((op1: TreeOperation, op2: TreeOperation): boolean => {
        // Delete operations can't be canceled
        if (op1.type === 'delete' || op2.type === 'delete') return false;

        // Rest of the existing logic for attach/detach...
        if (op1.type !== 'detach' || op2.type !== 'attach') return false;
        if (op1.nodeId !== op2.nodeId) return false;
        if (op1.fromParentId !== op2.toParentId) return false;
        const detachPosition = op1.position;
        const attachPosition = op2.position;
        return detachPosition === attachPosition;
    }, []);

    const cleanupOperations = useCallback(
        (operations: TreeOperation[]): TreeOperation[] => {
            const result: TreeOperation[] = [];

            // Process each operation
            operations.forEach((currentOp, i) => {
                // If this operation is already marked as handled, skip it
                if (!currentOp) return;

                // Look ahead for a canceling operation
                let foundMatch = false;
                for (let j = i + 1; j < operations.length; j++) {
                    const laterOp = operations[j];
                    if (!laterOp) continue;

                    if (checkOperationsCancel(currentOp, laterOp)) {
                        // Mark both operations as handled by setting them to null
                        operations[i] = null as any;
                        operations[j] = null as any;
                        foundMatch = true;
                        break;
                    }
                }

                // If no match was found, keep this operation
                if (!foundMatch) {
                    result.push(currentOp);
                }
            });

            return result;
        },
        [checkOperationsCancel],
    );

    const handleDetachNode = useCallback(
        (partnerId: string) => {
            const nodeToDetach = nodes.find((node) => node.data.partnerId === partnerId);
            if (!nodeToDetach) return;

            const parentEdge = edges.find((edge) => edge.target === nodeToDetach.id);
            if (!parentEdge) return;

            const parentNode = nodes.find((node) => node.id === parentEdge.source);
            if (!parentNode) return;

            // Track the position (L/R) the node is being detached from
            const detachPosition = parentEdge.sourceHandle?.toUpperCase() as 'L' | 'R';

            setDetachedNodes((prev) => ({
                ...prev,
                [partnerId]: true,
            }));

            setOrphanedNodes((prev) => [
                ...prev,
                {
                    id: nodeToDetach.id,
                    name: nodeToDetach.data.label,
                    partnerId: partnerId,
                    previousParentId: parentNode.id,
                    previousParentName: parentNode.data.label,
                },
            ]);

            const detachOperation: TreeOperation = {
                type: 'detach',
                nodeId: partnerId,
                nodeName: nodeToDetach.data.label,
                fromParentId: parentNode.data.partnerId || '',
                fromParentName: parentNode.data.label,
                position: detachPosition, // Add the position to the detach operation
            };

            setTreeOperations((prev) => {
                const newOps = [...prev, detachOperation];
                // Clean up any operations that cancel each other out
                return cleanupOperations(newOps);
            });

            setEdges((edges) => edges.filter((edge) => edge.id !== parentEdge.id));
        },
        [cleanupOperations, nodes, edges, setEdges],
    );

    const handleAttachNode = useCallback(
        (nodeId: string, newParentId: string, position: 'L' | 'R') => {
            const nodeToAttach = nodes.find((node) => node.data.partnerId === nodeId);
            const newParentNode = nodes.find((node) => node.id === newParentId);

            if (!nodeToAttach || !newParentNode) {
                console.error('Could not find node to attach or parent node');
                return;
            }

            setOrphanedNodes((prev) => prev.filter((orphan) => orphan.partnerId !== nodeId));

            const attachOperation: TreeOperation = {
                type: 'attach',
                nodeId: nodeId,
                nodeName: nodeToAttach.data.label,
                toParentId: newParentNode.data.partnerId || '',
                toParentName: newParentNode.data.label,
                position,
            };

            setTreeOperations((prev) => {
                const newOps = [...prev, attachOperation];
                // Clean up any operations that cancel each other out
                return cleanupOperations(newOps);
            });

            setDetachedNodes((prev) => {
                const updated = { ...prev };
                delete updated[nodeId];
                return updated;
            });

            const newEdge = {
                id: `e${newParentId}-${nodeToAttach.id}`,
                source: newParentId,
                target: nodeToAttach.id,
                sourceHandle: position.toLowerCase(),
                targetHandle: 'target',
            };

            setEdges((prev) => [...prev, newEdge]);
        },
        [cleanupOperations, nodes, setEdges],
    );

    const handleDeleteNode = useCallback(
        (partnerId: string) => {
            const nodeToDelete = nodes.find((node) => node.data.partnerId === partnerId);
            if (!nodeToDelete) return;

            // First, find and detach any children
            const childEdges = edges.filter((edge) => edge.source === nodeToDelete.id);

            // Create detach operations for each child first
            childEdges.forEach((edge) => {
                const childNode = nodes.find((node) => node.id === edge.target);
                if (childNode && childNode.data.label !== 'Create') {
                    handleDetachNode(childNode.data.partnerId || '');
                }
            });

            // Then create the delete operation
            const parentEdge = edges.find((edge) => edge.target === nodeToDelete.id);
            const parentNode = parentEdge
                ? nodes.find((node) => node.id === parentEdge.source)
                : null;

            setNodesToDelete((prev) => ({
                ...prev,
                [partnerId]: true,
            }));

            // Add delete operation
            const deleteOperation: TreeOperation = {
                type: 'delete',
                nodeId: partnerId,
                nodeName: nodeToDelete.data.label,
                fromParentId: parentNode?.data.partnerId || '',
                fromParentName: parentNode?.data.label || '',
            };

            setTreeOperations((prev) => [...prev, deleteOperation]);

            // Remove the edge connecting this node to its parent
            if (parentEdge) {
                setEdges((edges) => edges.filter((edge) => edge.id !== parentEdge.id));
            }
        },
        [nodes, edges, setEdges, handleDetachNode],
    );

    const handleApplyChanges = async () => {
        const nonDeleteOrphans = orphanedNodes.filter((orphan) => !nodesToDelete[orphan.partnerId]);

        if (nonDeleteOrphans.length > 0) {
            alert('Please reattach all orphaned nodes before applying changes');
            return;
        }

        try {
            setIsLoading(true);
            const apiOperations: BinaryTreeOperation[] = treeOperations.map((op) => ({
                type: op.type,
                nodeId: op.nodeId,
                nodeName: op.nodeName,
                fromParentId: op.fromParentId,
                fromParentName: op.fromParentName,
                toParentId: op.toParentId,
                toParentName: op.toParentName,
                leg: op.position,
            }));

            const updateRes = await adminUpdateBinaryTree(apiOperations);

            if (updateRes.status === 200) {
                setModalSuccess(true);
                const response = await adminGetBinaryTree();
                setBinaryTree(response.data);

                // Set the modal content from the response
                setModalOpen(true);
                setModalSuccess(true);
                setModalMessage(updateRes.data.message);
                setModalCompletedOperations(updateRes.data.operations); // This will be the string array from the API

                // Clear the operations after successful update
                setTreeOperations([]);
                setOrphanedNodes([]);
                setNodesToDelete({});
            } else {
                setModalSuccess(false);
                let errorJson = updateRes.data?.errors ? JSON.stringify(updateRes.data.errors) : '';
                setModalErrors(errorJson);
                setModalErrorMessage(
                    updateRes.data?.message || 'An error occurred while updating tree',
                );
            }
        } catch (error: any) {
            setModalSuccess(false);
            let errorJson = error.response?.data?.errors
                ? JSON.stringify(error.response.data.errors)
                : '';
            setModalErrors(errorJson);
            setModalErrorMessage(
                error.response?.data?.message || 'An error occurred while updating tree',
            );
        } finally {
            setModalOpen(true);
            setIsLoading(false);
        }
    };

    const handleModalClose = useCallback(() => {
        setModalOpen(false);
        setModalErrors('');
    }, []);

    const handleCancelChanges = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await adminGetBinaryTree();
            setBinaryTree(response.data);

            // Clear all operation states
            setTreeOperations([]);
            setOrphanedNodes([]);
            setDetachedNodes({});
            setNodesToDelete({});
        } catch (error) {
            console.error('Failed to reload tree data:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                setIsLoading(true);
                const [treeResponse, ranksResponse] = await Promise.all([
                    adminGetBinaryTree(),
                    adminGetRanks({ filters: {} }),
                ]);
                setBinaryTree(treeResponse.data);
                setRanksData(ranksResponse.data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        loadInitialData().then(() => {});
    }, []);

    useEffect(() => {
        if (!binaryTree || !ranksData) {
            return;
        }
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
            binaryTree,
            '',
            false,
            ranksData || [],
        );
        setNodes(layoutedNodes);
        setEdges(layoutedEdges);
    }, [setNodes, setEdges, binaryTree, ranksData]);

    const filteredNodes = useMemo(
        () =>
            filterNodes(
                nodes,
                nameFilter,
                rankFilter,
                salesFilter,
                subscriptionFilter,
                athleteFilter,
            ),
        [nodes, nameFilter, rankFilter, salesFilter, subscriptionFilter, athleteFilter],
    );

    const nodesWithProps = useMemo(
        () =>
            nodes.map((node) => {
                if (node.type === 'partnerViewer') {
                    const parentEdge = edges.find((edge) => edge.target === node.id);
                    const isDetached = !!detachedNodes[node.data.partnerId || ''];
                    const isMarkedForDelete = !!nodesToDelete[node.data.partnerId || ''];
                    const isRoot = !parentEdge; // Node is root if it has no parent edge
                    // Only calculate attachment points for this node if it's detached
                    const availableAttachments = isDetached
                        ? getAvailableAttachmentPoints(nodes)
                        : [];

                    return {
                        ...node,
                        data: {
                            ...node.data,
                            isAdmin: true,
                            onDetach:
                                parentEdge && !isMarkedForDelete ? handleDetachNode : undefined,
                            // Only provide onDelete if node is not root and not detached
                            onDelete: !isRoot && !isDetached ? handleDeleteNode : undefined,
                            isDetached,
                            isMarkedForDelete,
                            availableAttachments,
                            onAttach: handleAttachNode,
                            matchesFilter: filteredNodes.find((fn) => fn.id === node.id)?.data
                                .matchesFilter,
                        },
                    };
                }
                return node;
            }),
        [
            nodes,
            edges,
            detachedNodes,
            nodesToDelete,
            getAvailableAttachmentPoints,
            handleDetachNode,
            handleDeleteNode,
            handleAttachNode,
            filteredNodes,
        ],
    );

    const filteredEdges = useMemo(() => {
        const nodeIds = new Set(filteredNodes.map((node) => node.id));
        return edges.filter((edge) => nodeIds.has(edge.source) && nodeIds.has(edge.target));
    }, [filteredNodes, edges]);

    const alphabetizedNodes = useMemo(() => {
        return filteredNodes
            .filter((node) => node.data.label !== 'Create' && node.data.matchesFilter)
            .sort((a, b) => a.data.label.localeCompare(b.data.label))
            .map((node) => ({ id: node.id, label: node.data.label }));
    }, [filteredNodes]);

    const handleNodeClick = useCallback(
        (nodeId: string) => {
            const node = nodes.find((n) => n.id === nodeId);
            if (node) {
                setCenter(node.position.x + 200, node.position.y + 100, {
                    zoom: 1,
                    duration: 1000,
                });
            }
        },
        [nodes, setCenter],
    );

    return (
        <Box sx={{ height: '100%', display: 'flex' }}>
            {/* Filters Panel */}
            <Box
                sx={{
                    width: '15%',
                    p: 2,
                    borderRight: 1,
                    borderColor: 'divider',
                    backgroundColor: 'white',
                    overflowY: 'auto',
                }}
            >
                {treeOperations.length > 0 && (
                    <OperationsPanel
                        operations={treeOperations}
                        orphanedNodes={orphanedNodes}
                        onApply={handleApplyChanges}
                        onCancel={handleCancelChanges}
                    />
                )}
                <Typography variant="h6" gutterBottom>
                    Filters
                </Typography>
                <FilterPanel
                    nameFilter={nameFilterInput}
                    setNameFilter={setNameFilterInput}
                    rankFilter={rankFilter}
                    setRankFilter={setRankFilter}
                    salesFilter={salesFilter}
                    setSalesFilter={setSalesFilter}
                    subscriptionFilter={subscriptionFilter}
                    setSubscriptionFilter={setSubscriptionFilter}
                    athleteFilter={athleteFilter}
                    setAthleteFilter={setAthleteFilter}
                />
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                    }}
                >
                    <Typography gutterBottom>Organizations ({alphabetizedNodes.length})</Typography>
                    {alphabetizedNodes.length ? (
                        <List
                            dense
                            disablePadding
                            sx={{
                                border: '1px solid lightgray',
                                padding: '4px',
                                borderRadius: '4px',
                                maxHeight: '180px',
                                overflowY: 'auto',
                            }}
                        >
                            {alphabetizedNodes.map((node) => (
                                <ListItem
                                    key={node.id}
                                    disableGutters
                                    onClick={() => handleNodeClick(node.id)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                        },
                                    }}
                                >
                                    <Typography variant="body2">{node.label}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        ''
                    )}
                </Box>
            </Box>

            {/* ReactFlow Canvas */}
            <Box sx={{ width: '85%', height: '100%', position: 'relative' }}>
                {isLoading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            zIndex: 1000,
                        }}
                    >
                        <CircularProgress size={60} />
                    </Box>
                )}
                <ReactFlow
                    nodes={nodesWithProps}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    edges={filteredEdges}
                    nodeTypes={nodeTypes}
                    minZoom={0.1}
                    fitView
                >
                    <Background />
                    <Controls />
                    <MiniMap />
                </ReactFlow>
                <TreeOperationModal
                    isOpen={modalOpen}
                    onClose={handleModalClose}
                    success={modalSuccess}
                    message={modalMessage}
                    completedOperations={modalCompletedOperations}
                    errorMessage={modalErrorMessage}
                    errors={modalErrors}
                />
            </Box>
        </Box>
    );
};

const BinaryTreeScene = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: 'calc(100vh - 64px)',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#f5f5f5',
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%23cfd8f5' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");`,
            }}
        >
            <ReactFlowProvider>
                <Flow />
            </ReactFlowProvider>
        </Box>
    );
};

export default BinaryTreeScene;
