import { Exploration, ExplorationFilters } from '../explore.types';
import { CubeProvider } from '@cubejs-client/react';
import React, { useEffect, useState } from 'react';
import { Avatar, Box, Card, CardContent, Grid, Paper, Stack, Typography } from '@mui/material';
import { convertApiFiltersToExplorationFilters, formatGpsSummaryData } from '../explore.func';
import cubejs, { CubejsApi, TableColumn } from '@cubejs-client/core';
import { useTheme } from '@mui/system';
import { grey } from '@mui/material/colors';
import { getCubeToken } from '../../../api/charts.api';
import LoadingWithText from './LoadingWithText';
import LocationOffIcon from '@mui/icons-material/LocationOff';

interface Props {
    exploration: Exploration;
}

const ExplorationAthleteProfiles = ({ exploration }: Props) => {
    const theme = useTheme();

    const [profileData, setProfileData] = useState<
        Array<{ [key: string]: string | number | boolean }>
    >([]);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState<TableColumn[]>([]);
    const [cubejsApi, setApi] = useState<CubejsApi | null>(null);
    const [filters, setFilters] = useState<ExplorationFilters>(
        convertApiFiltersToExplorationFilters(exploration.filters),
    );
    useEffect(() => {
        setApi(
            cubejs(
                () =>
                    getCubeToken({
                        exploration: exploration.uuid,
                    }).then((r) => r.data.token),
                {
                    apiUrl: `${process.env.REACT_APP_CUBE_URL}/cubejs-api/v1`,
                },
            ),
        );
        setFilters(convertApiFiltersToExplorationFilters(exploration.filters));
    }, [exploration]);
    useEffect(() => {
        setLoading(true);
        cubejsApi
            ?.load({
                dimensions: filters.dimensions,
                measures: filters.measures,
            })
            .then((leaderboardResponse) => {
                setColumns(leaderboardResponse.tableColumns());
                setProfileData(leaderboardResponse.tablePivot());
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, [filters, cubejsApi]);
    return (
        <CubeProvider cubejsApi={cubejsApi}>
            <>
                <Avatar
                    variant={'rounded'}
                    sx={{ margin: 'auto', width: 100, height: 100 }}
                    src={exploration.organization.logo_url ?? ''}
                />
                <Typography variant={'h4'} align={'center'}>
                    {exploration.title}
                </Typography>
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <LoadingWithText text={'Loading Profiles'} />
                    </Box>
                )}
                {profileData?.length === 0 && !loading && (
                    <Stack sx={{ justifyContent: 'center', alignItems: 'center' }} spacing={8}>
                        <LocationOffIcon sx={{ fontSize: 100, color: grey[500] }} />
                        <Typography>No GPS summary data for the specified date range</Typography>
                    </Stack>
                )}
                <Grid container spacing={4}>
                    {profileData.map((c) => (
                        <Grid
                            xs={12}
                            sm={6}
                            md={4}
                            key={c['GpsSummary.athleteName'] as string}
                            item
                        >
                            <Card
                                sx={{ backgroundColor: theme.palette.primary.main }}
                                elevation={2}
                            >
                                <CardContent>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        marginBottom={2}
                                    >
                                        <Avatar
                                            src={
                                                (c[
                                                    'GpsSummary.athleteProfilePhotoUrl'
                                                ] as string) ?? ''
                                            }
                                            alt="Athlete"
                                            style={{ width: 100, height: 100 }}
                                        />
                                        <Typography
                                            sx={{ color: 'white' }}
                                            variant="h6"
                                            align="center"
                                        >
                                            {c['GpsSummary.athleteName']}
                                        </Typography>
                                    </Box>
                                    <Grid container spacing={2}>
                                        {Object.keys(c)
                                            .filter(
                                                (k) =>
                                                    k !== 'GpsSummary.athleteProfilePhotoUrl' &&
                                                    k !== 'GpsSummary.athleteName',
                                            )
                                            .map((key, index) => (
                                                <Grid item xs={6} key={index}>
                                                    <Paper
                                                        elevation={3}
                                                        sx={{
                                                            transition: 'background-color 0.3s', // Smooth transition
                                                            '&:hover': {
                                                                backgroundColor: grey[200], // Light grey on hover
                                                            },
                                                            padding: 2,
                                                            textAlign: 'center',
                                                            height: 120,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{ height: 40 }}
                                                            variant="subtitle2"
                                                            color="textSecondary"
                                                        >
                                                            {columns.find((c) => c.key === key)
                                                                ?.shortTitle ?? ''}
                                                        </Typography>
                                                        <Typography
                                                            sx={{ fontSize: 24 }}
                                                            variant="h6"
                                                        >
                                                            {formatGpsSummaryData(
                                                                c[key] as number,
                                                                key,
                                                            )}
                                                        </Typography>
                                                    </Paper>
                                                </Grid>
                                            ))}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </>
        </CubeProvider>
    );
};

export default ExplorationAthleteProfiles;
