import { AxiosResponse } from 'axios';
import { Request } from 'api';
import { ResponseWithPagination } from '../../../utils';
import { SocialUser } from '../social.types';

type ReturnType = Promise<AxiosResponse<ResponseWithPagination<SocialUser>>>;

const getFollowers = (): ReturnType => {
    return Request.get(`/social/followers`);
};

export default getFollowers;
