import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import LoginAnimation from '../../lottie-animations/Rocket.json';
import Lottie from 'lottie-react';
import React from 'react';

interface Props {
    onCloseClicked: () => void;
    visible: boolean;
    email?: string;
    name: string;
}

const VerifyEmailBanner = ({ onCloseClicked, visible, name }: Props) => {
    const history = useHistory();

    return (
        <>
            {visible && (
                <>
                    <Paper
                        elevation={0}
                        sx={{
                            padding: `8px 8px 8px 8px`,
                            background: ` linear-gradient( to bottom, #FFFFFF 0%, #D9DADF 100% )`,
                        }}
                    >
                        <Grid container wrap="nowrap" spacing={8} alignItems="center">
                            <Grid item>
                                <Lottie
                                    animationData={LoginAnimation}
                                    loop={true}
                                    autoplay={true}
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>{name}</span>, Enjoying
                                    your trial? 🌟 It&apos;s time to subscribe! This will ensure you
                                    keep access to current and future premium features to enhance
                                    your athletic development!
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end" spacing={8}>
                            <Grid item>
                                <Button color="primary" onClick={onCloseClicked}>
                                    Close
                                </Button>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => history.push('/athletes')}
                                >
                                    Subscribe Now
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Divider />
                </>
            )}
        </>
    );
};

export default VerifyEmailBanner;
