import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    loadMyProfile as apiLoadMyProfile,
    updateProfile as apiUpdateProfile,
} from '../../../modules/social/api';
import { UserProfile } from '../../../utils';
import { RootState } from '../index';

export interface ProfileState {
    profile: any;
    loading: boolean;
    loaded: boolean;
    error: string | null;
}

// reducers
export const initialState: ProfileState = {
    profile: null,
    loading: false,
    loaded: false,
    error: null,
};

// actions
export const updateProfile = createAsyncThunk<
    undefined,
    { bio: string },
    { rejectValue: { message: string } }
>('social/updateProfile', async (payload, { rejectWithValue }) => {
    try {
        await apiUpdateProfile(payload);
    } catch {
        return rejectWithValue({ message: 'Failed to delete feed activity' });
    }
});

export const loadMyProfile = createAsyncThunk<
    UserProfile,
    undefined,
    { rejectValue: { message: string } }
>('social/loadProfile', async (_, { rejectWithValue }) => {
    try {
        const { data } = await apiLoadMyProfile();

        return data;
    } catch {
        return rejectWithValue({ message: 'Failed to load my profile' });
    }
});

// base slice
export const profileSlice = createSlice({
    name: 'myProfile',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(loadMyProfile.pending, (state) => {
            state.loading = true;
        });

        addCase(loadMyProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.profile = { ...action.payload };
            state.error = null;
        });

        addCase(loadMyProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action?.payload?.message ?? 'Error Loading Profile';
        });
    },
});

// selectors
export const myProfileSelector = (state: RootState) => state.social.myProfile;
