import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { Submission } from 'utils';

type ReturnType = Promise<AxiosResponse<Submission>>;

const startNewFormSubmission = (id: string, date: DateTime, individual: string): ReturnType => {
    return Request.post(`/data/forms/${id}/submissions`, {
        individual: individual,
        date: date,
    });
};
export default startNewFormSubmission;
