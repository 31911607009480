import ArchiveIcon from '@mui/icons-material/Archive';
import CheckIcon from '@mui/icons-material/Check';
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
} from '@mui/material';
import React from 'react';
import { useToggle } from '../../../utils';

interface Props {
    title: string;
    subtitle: string;
    avatar: React.ReactNode;
    onArchiveClicked: () => void;
    onAttestClicked: () => void;
    collapsableContent: React.ReactNode;
}

const UnreviewedCard: React.FC<React.PropsWithChildren<Props>> = ({
    title,
    subtitle,
    avatar,
    onArchiveClicked,
    onAttestClicked,
    collapsableContent,
}: Props) => {
    const [expanded, toggleExpanded] = useToggle();
    const handleArchiveClicked = (
        e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>,
    ): void => {
        onArchiveClicked();
        e.stopPropagation();
    };
    const handleAttestClicked = (
        e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>,
    ): void => {
        onAttestClicked();
        e.stopPropagation();
    };
    return (
        <Card style={{ width: '100%', marginBottom: 16 }}>
            <CardActionArea onClick={() => toggleExpanded()}>
                <CardHeader
                    avatar={avatar}
                    title={title}
                    subheader={subtitle}
                    action={
                        <Box>
                            <IconButton
                                onMouseDown={(e) => e.stopPropagation()}
                                onClick={(e) => handleArchiveClicked(e)}
                                aria-label="archive"
                                size="large"
                            >
                                <ArchiveIcon />
                            </IconButton>
                            <IconButton
                                onMouseDown={(e) => e.stopPropagation()}
                                onClick={(e) => handleAttestClicked(e)}
                                aria-label="attest"
                                size="large"
                            >
                                <CheckIcon />
                            </IconButton>
                        </Box>
                    }
                />
            </CardActionArea>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>{collapsableContent}</CardContent>
            </Collapse>
        </Card>
    );
};

export default UnreviewedCard;
