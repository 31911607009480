import { Box, Button, Card, CardHeader } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { CalendarAddDateIcon, DateTimePickerDialog } from '../../../components';
import { DateValue } from '../../../utils';

interface Props {
    value: DateValue;
    title: string;
    subheader?: string;
    avatar: React.ReactNode;
    onChange: (value: DateValue) => void;
}

const ChooseDateCard = ({ avatar, title, value, onChange, subheader = '' }: Props) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const handleChangeDate = (newDate: DateValue) => {
        onChange(newDate);
    };
    const ButtonText = () => (
        <>
            {value?.date
                ? `${value.date.toFormat('LLL d')}${
                      value.isWholeDay === false && value.time
                          ? ` @ ${value.time.toFormat('hh:mm a')}`
                          : ''
                  }`
                : 'Add'}
        </>
    );

    return (
        <Card>
            <CardHeader
                sx={{ '.MuiCardHeader-action': { mt: 0, mr: 0, alignSelf: 'auto' } }}
                avatar={avatar}
                title={title}
                subheader={subheader}
                sec
                action={
                    <Box>
                        <Button
                            startIcon={value.date ? null : <CalendarAddDateIcon />}
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => {
                                if (!value.date) {
                                    handleChangeDate({
                                        ...value,
                                        date: DateTime.now(),
                                    });
                                }
                                setModalOpen(true);
                            }}
                        >
                            {ButtonText()}
                        </Button>
                    </Box>
                }
            />
            {modalOpen && (
                <DateTimePickerDialog
                    currentTimezone={
                        value.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                    }
                    onChange={handleChangeDate}
                    onClose={() => {
                        setModalOpen(false);
                    }}
                    open={modalOpen}
                    sessionDate={value}
                />
            )}
        </Card>
    );
};

export default ChooseDateCard;
