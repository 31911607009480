import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { ChartType } from '../community.types';

interface Props {
    value: ChartType;
    onChange: (value: ChartType) => void;
}

function ChartTypeSelect({ value, onChange }: Props): React.ReactElement {
    return (
        <Select
            variant={'outlined'}
            value={value}
            onChange={(e) => onChange(e.target.value as ChartType)}
            label="Chart Type"
        >
            <MenuItem value={'line'}>Line</MenuItem>
            <MenuItem value={'area'}>Area</MenuItem>
            <MenuItem value={'bar'}>Bar</MenuItem>
            <MenuItem value={'pie'}>Pie</MenuItem>
            <MenuItem value={'table'}>Table</MenuItem>
            <MenuItem value={'number'}>Number</MenuItem>
            <MenuItem value={'radar'}>Radar</MenuItem>
            <MenuItem value={'athletes'}>Athlete Display Grid</MenuItem>
        </Select>
    );
}

export default ChartTypeSelect;
