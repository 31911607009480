import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { MarketplaceItem } from '../../../../app.types';

interface PaginatedApiResponse {
    data: Array<MarketplaceItem>;
    links: any;
    meta: any;
}

type ReturnTypeCollection = Promise<AxiosResponse<PaginatedApiResponse>>;
type ReturnTypeSingle = Promise<AxiosResponse<MarketplaceItem>>;

interface GetMarketplaceItemsParams {
    resell_status?: string;
    organization_uuid?: string;
    sort?: string;
    page?: number;
    per_page?: number;
}

const getMarketplaceItems = (params?: GetMarketplaceItemsParams): ReturnTypeCollection => {
    const filterParams = {
        'filter[resell_status]': params?.resell_status,
        'filter[organization_uuid]': params?.organization_uuid,
        sort: params?.sort,
        page: params?.page,
        per_page: params?.per_page,
    };
    return Request.get(`admin/marketplace/items`, { params: filterParams });
};

const updateMarketplaceItemResellStatus = (
    uuid: string,
    resellStatus: string,
): ReturnTypeSingle => {
    return Request.put(`admin/marketplace/items/${uuid}`, {
        resell_status: resellStatus,
    });
};

export { getMarketplaceItems, updateMarketplaceItemResellStatus };
export type { GetMarketplaceItemsParams };
