import { AxiosResponse } from 'axios';
import { Request } from '../../api';
import { SimplePaginateResponse } from '../../app.types';

export const getLogs = (options = {}): Promise<AxiosResponse<any>> => {
    return Request.get(`/warehouse`, {
        params: options,
    });
};

export const getSprints = (options = {}): Promise<AxiosResponse<any>> => {
    return Request.get(`/warehouse/sprints`, {
        params: options,
    });
};

export const getVideoStrideAnalyses = (options = {}): Promise<AxiosResponse<any>> => {
    return Request.get(`/warehouse/analyses`, {
        params: options,
    });
};

export const getVideoSpeedAnalyses = (options = {}): Promise<AxiosResponse<any>> => {
    return Request.get(`/warehouse/analyses-video`, {
        params: options,
    });
};

export const getTemMotions = (options = {}): Promise<AxiosResponse<any>> => {
    return Request.get(`/warehouse/tem-motions`, {
        params: options,
    });
};

export const getTimeSegments = (options = {}): Promise<AxiosResponse<any>> => {
    return Request.get(`/warehouse/sprint-time-segments`, {
        params: options,
    });
};

export const getDimensionChoices = (
    path: string,
    params: { page?: number; 'filter[query]'?: string; limit?: number } = {},
): Promise<AxiosResponse<SimplePaginateResponse<any>>> => {
    return Request.get(`/warehouse/dimensions/${path}`, {
        params,
    });
};
