import { Request } from 'api';
import { AxiosResponse } from 'axios';
import { AttributeValue, Movement, MovementPreset } from 'utils';

type ReturnType = Promise<AxiosResponse<Movement>>;

const updateMovement = (
    uuid: string,
    name: string,
    description: string | null,
    attributes: AttributeValue[],
    movement_presets: MovementPreset | null,
    cover_asset_uuid: string | null,
): ReturnType => {
    return Request.put(`/workouts/movements/${uuid}`, {
        name,
        description,
        attributes,
        movement_presets,
        cover_asset_uuid,
    });
};

export default updateMovement;
