import { Box, ButtonBase, CircularProgress, IconButton, Paper, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { VideoClipPlayer } from 'components/Functional/VideoClipPlayer';
import UseTheme from '@mui/material/styles/useTheme';

export interface AssetCardProps {
    thumbSrc: string;
    alt?: string;
    onDeleteClicked: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    clickable?: boolean;
    onClick?: () => void;
    canRemoveMedia?: boolean;
    mp4ClipSrc?: string;
    webmClipSrc?: string;
    isError?: boolean;
}

export default function AssetCard({
    onDeleteClicked,
    thumbSrc,
    alt = '',
    clickable = false,
    onClick = () => {},
    canRemoveMedia = true,
    mp4ClipSrc = '',
    webmClipSrc = '',
    isError = false,
}: AssetCardProps): ReactElement {
    const theme = UseTheme();
    return (
        <Box
            sx={{ flex: '0 0 auto', marginRight: 12, width: 128, height: 80, position: 'relative' }}
        >
            {thumbSrc && !mp4ClipSrc && !webmClipSrc && !clickable && !isError && (
                <img
                    src={thumbSrc}
                    alt={alt}
                    style={{ borderRadius: 4, width: 128, height: 80, objectFit: 'cover' }}
                />
            )}
            {thumbSrc && (mp4ClipSrc || webmClipSrc) && !clickable && !isError && (
                <VideoClipPlayer
                    mp4={mp4ClipSrc}
                    webm={webmClipSrc}
                    poster={thumbSrc}
                    classesSx={{
                        borderRadius: 4,
                        width: 128,
                        height: 80,
                        objectFit: 'cover',
                    }}
                />
            )}
            {thumbSrc && !mp4ClipSrc && !webmClipSrc && clickable && !isError && (
                <ButtonBase onClick={onClick}>
                    <img
                        src={thumbSrc}
                        alt={alt}
                        style={{ borderRadius: 4, width: 128, height: 80, objectFit: 'cover' }}
                    />
                </ButtonBase>
            )}
            {thumbSrc && (mp4ClipSrc || webmClipSrc) && clickable && !isError && (
                <ButtonBase onClick={onClick}>
                    <VideoClipPlayer
                        mp4={mp4ClipSrc}
                        webm={webmClipSrc}
                        poster={thumbSrc}
                        classesSx={{
                            borderRadius: 4,
                            width: 128,
                            height: 80,
                            objectFit: 'cover',
                        }}
                    />
                </ButtonBase>
            )}
            {!thumbSrc && !clickable && !isError && (
                <Paper
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    sx={{ borderRadius: 4, width: 128, height: 80, objectFit: 'cover' }}
                >
                    <CircularProgress />
                </Paper>
            )}
            {isError && (
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 4,
                        width: 128,
                        height: 80,
                        objectFit: 'cover',
                    }}
                >
                    <ErrorOutlineIcon />
                    <Typography>Error</Typography>
                </Paper>
            )}
            {!thumbSrc && clickable && !isError && (
                <ButtonBase onClick={onClick}>
                    <Paper
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 4,
                            width: 128,
                            height: 80,
                            objectFit: 'cover',
                        }}
                    >
                        <CircularProgress />
                        <Typography>Processing</Typography>
                    </Paper>
                </ButtonBase>
            )}
            {canRemoveMedia && (
                <IconButton
                    onClick={(event) => onDeleteClicked(event)}
                    sx={{
                        padding: 0,
                        position: 'absolute',
                        top: 6,
                        right: 6,
                        color: theme.palette.primary.light,
                    }}
                    disableRipple
                    disableFocusRipple
                    aria-label="Remove Media"
                    size="large"
                >
                    <CancelIcon />
                </IconButton>
            )}
        </Box>
    );
}
