import React, { useState } from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Dialog,
    DialogContent,
    Grid,
    Typography,
} from '@mui/material';

interface FullScreenDialogCardProps {
    image: string;
    title: string;
    headline: string;
    description: string;
    height?: number | 'auto';
    imgPosition?: string;
    lgSize?: number;
    cardHeight?: number | 'auto';
}

const FullScreenDialogCard: React.FC<React.PropsWithChildren<FullScreenDialogCardProps>> = ({
    image,
    title,
    headline,
    description,
    height = 520,
    imgPosition = 'top',
    lgSize = 4,
    cardHeight = 260,
}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid item xs={12} sm={12} md={12} lg={lgSize}>
            <Card
                onClick={handleOpen}
                sx={{
                    cursor: 'pointer',
                    transition: 'box-shadow 0.3s', // Smooth transition for the shadow
                    '&:hover': {
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)', // Add your desired box-shadow here
                    },
                }}
            >
                <CardMedia
                    sx={{
                        height: cardHeight,
                        backgroundSize: '97%',
                        backgroundPosition: imgPosition,
                    }}
                    image={image}
                    title={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {headline}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                </CardContent>
            </Card>

            <Dialog open={open} onClick={handleClose} maxWidth="md" fullWidth>
                <DialogContent sx={{ width: '2x', height: '2x' }}>
                    <Card sx={{ cursor: 'zoom-out' }}>
                        <CardMedia
                            sx={{
                                height: height, // 2 times the original height
                                backgroundSize: '97%',
                                backgroundPosition: 'center',
                            }}
                            image={image}
                            title={title}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h3" component="div">
                                {headline}
                            </Typography>
                            <Typography variant="body2" color="text.secondary" fontSize={35}>
                                {description}
                            </Typography>
                        </CardContent>
                    </Card>
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export default FullScreenDialogCard;
