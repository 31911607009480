import { Avatar, Box, Divider, Grid, Link, Paper, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataGridPremium, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as ReactRouterDomLink, useParams } from 'react-router-dom';
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { viewIndividual } from '../../../api';
import CustomLoadingOverlay from '../../../components/Loaders/LoadingOverlay';
import { userSelector } from '../../../redux/reducers/user';
import ROUTES from '../../../Routes/routes';
import { Analysis, Asset, Comment, Individual } from '../../../utils';
import { getAnalysis, getStrideData } from '../api/analysis.api';
import CommentViewingModal from '../components/CommentViewingModal';
import VideoCard from '../components/VideoCard';

function VideoAnalysis() {
    const [analysis, setAnalysis] = useState<Analysis | null>(null);
    const [individual, setIndividual] = useState<Individual>();
    const { analysisId } = useParams<{ analysisId: string }>();
    const { userData } = useSelector(userSelector);
    const [peakExtensionData, setPeakExtensionData] =
        useState<{ rep: number; peakExtensionLeft: number; peakExtensionRight: number }[]>();
    const [peakFlexionData, setPeakFlexionData] =
        useState<{ rep: number; peakFlexionLeft: number; peakFlexionRight: number }[]>();
    const [groundTimeData, setGroundTimeData] =
        useState<{ rep: number; groundTimeLeft: number; groundTimeRight: number }[]>();
    const [airTimeData, setAirTimeData] =
        useState<{ rep: number; airTimeLeft: number; airTimeRight: number }[]>();
    const [strideTimeData, setStrideTimeData] =
        useState<{ timestamp: number; strideTimeLeft?: number; strideTimeRight?: number }[]>();
    const [selectedAssetForCommentViewing, setSelectedAssetForCommentViewing] = useState<Asset>();

    function handleCommentAdded(comment: Comment): void {
        setAnalysis((analysis) => {
            if (!analysis) {
                return analysis;
            }
            return {
                ...analysis,
                asset: {
                    ...analysis.asset,
                    comments: [comment, ...(analysis.asset?.comments ?? [])],
                },
            };
        });
    }

    function handleCommentDeleted(commentId: string): void {
        setAnalysis((analysis) => {
            if (!analysis) {
                return analysis;
            }
            return {
                ...analysis,
                asset: {
                    ...analysis.asset,
                    comments: analysis?.asset?.comments?.filter((c) => c.uuid !== commentId) ?? [],
                },
            };
        });
    }

    useEffect(() => {
        getAnalysis(analysisId)
            .then((response) => setAnalysis(response.data))
            .catch(() => {})
            .finally(() => {});
    }, [analysisId]);

    useEffect(() => {
        if (!analysis) {
            return;
        }
        viewIndividual(analysis.individual_uuid)
            .then((i) => setIndividual(i.data))
            .catch(() => {})
            .finally(() => {});
    }, [analysis]);

    useEffect(() => {
        if (analysis) {
            getStrideData(analysis.uuid)
                .then((result) => {
                    const data = groupBy(result.data.data, 'is_left');
                    const lefts = data['true'];
                    const rights = data['false'];
                    setRows(
                        result.data.data
                            .map((item, ix) => ({
                                id: item.id,
                                step: ix + 1,
                                foot: item.is_left ? 'L' : 'R',
                                start_time: (item.start_timestamp / 1000).toFixed(3),
                                end_time: (item.end_timestamp / 1000).toFixed(3),
                                mid_time: (item.mid_timestamp / 1000).toFixed(3),
                                air_time: item.is_left ? item.air_time_left : item.air_time_right,
                                ground_time: item.is_left
                                    ? item.ground_time_left
                                    : item.ground_time_right,
                                peak_extension: item.is_left
                                    ? item.peak_extension_left
                                    : item.peak_extension_right,
                                peak_flexion: item.is_left
                                    ? item.peak_flexion_left
                                    : item.peak_flexion_right,
                                stride_time: item.is_left
                                    ? item.stride_time_left
                                    : item.stride_time_right,
                            }))
                            .map((i) => ({
                                ...i,
                                ground_time: (i.ground_time / 1000).toFixed(3),
                                air_time: (i.air_time / 1000).toFixed(3),
                                stride_time: (i.stride_time / 1000).toFixed(3),
                            })),
                    );
                    setPeakExtensionData(
                        lefts.map((item, ix) => ({
                            rep: ix + 1,
                            peakExtensionLeft: item.peak_extension_left,
                            peakExtensionRight: rights[ix]?.peak_extension_right,
                        })),
                    );
                    setPeakFlexionData(
                        lefts.map((item, ix) => ({
                            rep: ix + 1,
                            peakFlexionLeft: item.peak_flexion_left,
                            peakFlexionRight: rights[ix]?.peak_flexion_right,
                        })),
                    );
                    setGroundTimeData(
                        lefts.map((item, ix) => ({
                            rep: ix + 1,
                            groundTimeLeft: item.ground_time_left,
                            groundTimeRight: rights[ix]?.ground_time_right,
                        })),
                    );
                    setAirTimeData(
                        lefts.map((item, ix) => ({
                            rep: ix + 1,
                            airTimeLeft: item.air_time_left,
                            airTimeRight: rights[ix]?.air_time_right,
                        })),
                    );
                    setStrideTimeData(
                        result.data.data.map((item) => ({
                            timestamp: parseFloat((item.end_timestamp / 1000).toFixed(3)),
                            strideTimeLeft: item.is_left ? item.stride_time_left : undefined,
                            strideTimeRight: item.is_right ? item.stride_time_right : undefined,
                        })),
                    );
                })
                .catch(() => {})
                .finally(() => {});
        }
    }, [analysis]);

    interface CustomCellProps {
        value: any;
        onClick: (value: any) => void;
    }

    const CustomCell: React.FC<React.PropsWithChildren<CustomCellProps>> = ({ value, onClick }) => {
        return (
            <button
                type="button"
                onClick={() => onClick(value)}
                style={{ cursor: 'pointer', padding: '0 16px', background: 'none', border: 'none' }}
            >
                {value}
            </button>
        );
    };
    const [seekToTime, setSeekToTime] = useState<number>(0);
    const [rows, setRows] = useState<any>([]);
    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: 'step',
                headerName: 'Stride #',
                width: 70,
                filterable: false,
                sortable: false,
            },
            {
                field: 'foot',
                headerName: 'Foot',
                width: 45,
                filterable: false,
                sortable: false,
            },
            {
                field: 'start_time',
                headerName: 'Toe Off [Frame]',
                width: 150,
                filterable: false,
                sortable: false,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <CustomCell
                        value={params.value}
                        onClick={() => setSeekToTime(params.value ?? 0)}
                    />
                ),
            },
            {
                field: 'mid_time',
                headerName: 'Peak Flexion [Frame]',
                filterable: false,
                sortable: false,
                width: 150,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <CustomCell
                        value={params.value}
                        onClick={() => setSeekToTime(params.value ?? 0)}
                    />
                ),
            },
            {
                field: 'peak_flexion',
                headerName: 'Peak Flexion (°)',
                filterable: false,
                sortable: false,
                width: 140,
            },
            {
                field: 'peak_extension',
                headerName: 'Peak Extension (°)',
                filterable: false,
                sortable: false,
                width: 140,
            },
            {
                field: 'end_time',
                headerName: 'Foot Down [Frame]',
                filterable: false,
                sortable: false,
                width: 150,
                renderCell: (params: GridRenderCellParams<any, number>) => (
                    <CustomCell
                        value={params.value}
                        onClick={() => setSeekToTime(params.value ?? 0)}
                    />
                ),
            },
            {
                field: 'ground_time',
                headerName: 'Ground Time (s)',
                filterable: false,
                sortable: false,
                width: 140,
            },
            {
                field: 'air_time',
                headerName: 'Air Time (s)',
                filterable: false,
                sortable: false,
                width: 140,
            },

            {
                field: 'stride_time',
                headerName: 'Stride Time (s)',
                filterable: false,
                sortable: false,
                width: 140,
            },
        ],
        [],
    );
    return (
        <>
            <Grid container spacing={3} sx={{ marginTop: 3 }}>
                <Grid item xs={12} md={6} container alignItems="center" justifyContent="flex-start">
                    <Grid item>
                        <Avatar
                            variant="rounded"
                            sx={{
                                marginLeft: 6,
                                height: 65,
                                width: 65,
                                boxShadow:
                                    '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
                            }}
                            src={individual?.image_urls.avatar ?? ''}
                        />
                    </Grid>
                    <Grid item>
                        <Link
                            to={ROUTES.IndividualDetails.path.replace(
                                ':individualId',
                                individual?.uuid || '',
                            )}
                            component={ReactRouterDomLink}
                            variant="h4"
                            underline="hover"
                            color="inherit"
                        >
                            <Typography variant={'h4'} sx={{ marginLeft: 6 }}>
                                {individual?.name}
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} container justifyContent="space-evenly">
                    <Grid item xs={6} md={4}>
                        <Paper
                            sx={{
                                minWidth: '130px',
                                p: 1,
                                backgroundColor: '#D9DADF',
                                borderRadius: 5,
                                border: '1px solid #A7ABB7',
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant={'h6'}>10 Yard Split</Typography>
                            <Divider />
                            <Typography variant={'caption'} sx={{ fontSize: 20 }}>
                                {Number(analysis?.summary_ten_yard_time).toFixed(3)} s
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Paper
                            sx={{
                                minWidth: '130px',
                                p: 1,
                                backgroundColor: '#D9DADF',
                                borderRadius: 5,
                                border: '1px solid #A7ABB7',
                                background:
                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant={'h6'}>10 Yard Speed</Typography>
                            <Typography variant={'caption'} sx={{ fontSize: 20 }}>
                                {Number(analysis?.summary_ten_yard_speed).toFixed(3)} MPH
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            {selectedAssetForCommentViewing && individual && userData && (
                <CommentViewingModal
                    commentAdded={(comment) => handleCommentAdded(comment)}
                    commentDeleted={(comment) => handleCommentDeleted(comment)}
                    user={userData}
                    commentableUuid={selectedAssetForCommentViewing.uuid}
                    PaperProps={{
                        sx: {
                            width: {
                                xs: '90vw',
                                sm: '60vw',
                            },
                            height: '80vh',
                        },
                    }}
                    individual={individual}
                    open={true}
                    onClose={() => setSelectedAssetForCommentViewing(undefined)}
                ></CommentViewingModal>
            )}
            <Grid container>
                <Grid sx={{ p: 4 }} xs={12} md={12} lg={6}>
                    {analysis && userData && individual ? (
                        <VideoCard
                            asset={analysis.asset}
                            individual={individual}
                            isDeleting={false}
                            commentAdded={(c) => handleCommentAdded(c)}
                            viewAllCommentsClicked={(event, asset) =>
                                setSelectedAssetForCommentViewing(asset)
                            }
                            user={userData}
                            commentDeleted={(c) => handleCommentDeleted(c)}
                            seekToTime={seekToTime}
                        />
                    ) : null}
                </Grid>
                <Grid sx={{ p: 4 }} xs={12} md={12} lg={6}>
                    <Grid container>
                        <Grid
                            xs={12}
                            sm={12}
                            item
                            style={{
                                paddingRight: 8,
                                paddingLeft: 8,
                                paddingBottom: 8,
                            }}
                        >
                            <Paper
                                style={{
                                    width: '100%',
                                    height: 300,
                                    backgroundColor: '#D9DADF',
                                    borderRadius: 20,
                                    border: '1px solid #A7ABB7',
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                }}
                            >
                                <ResponsiveContainer>
                                    <LineChart
                                        data={strideTimeData}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            left: 20,
                                            bottom: 20,
                                        }}
                                    >
                                        <Legend
                                            verticalAlign="top"
                                            height={36}
                                            payload={[{ value: 'Stride Frequency' }]}
                                            iconType="wye"
                                            iconSize={6}
                                        />
                                        <XAxis
                                            dataKey="timestamp"
                                            label={{
                                                value: 'Video Frame',
                                                angle: 0,
                                                position: 'bottom',
                                                textColor: '#A7ABB7',
                                                fontSize: 12,
                                            }}
                                        />

                                        <YAxis
                                            label={{
                                                value: 'Stride Time (ms)',
                                                angle: -90,
                                                position: 'insideBottomLeft',
                                                textColor: '#A7ABB7',
                                                fontSize: 12,
                                            }}
                                        />

                                        <Line
                                            dataKey="strideTimeLeft"
                                            stroke="#8884d8"
                                            fill="#8884d8"
                                        />
                                        <Line
                                            dataKey="strideTimeRight"
                                            stroke="#f54242"
                                            fill="#f54242"
                                        />
                                        <Tooltip
                                            payload={[]}
                                            contentStyle={{
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',

                                                border: '1px solid #a8bfd0',
                                                borderRadius: 15,
                                            }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>

                        <Grid
                            xs={12}
                            sm={6}
                            item
                            style={{
                                paddingRight: 8,
                                paddingLeft: 8,
                                paddingBottom: 8,
                            }}
                        >
                            <Paper
                                style={{
                                    width: '100%',
                                    height: 200,
                                    backgroundColor: '#D9DADF',
                                    borderRadius: 20,
                                    border: '1px solid #A7ABB7',
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                }}
                            >
                                <ResponsiveContainer>
                                    <LineChart
                                        data={peakExtensionData}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            left: 20,
                                            bottom: 20,
                                        }}
                                    >
                                        <Legend
                                            verticalAlign="top"
                                            height={36}
                                            payload={[{ value: 'Peak Extension Asymmetry' }]}
                                            iconSize={6}
                                        />
                                        <XAxis
                                            dataKey="rep"
                                            label={{
                                                value: 'Stride Number',
                                                angle: 0,
                                                position: 'bottom',
                                                textColor: '#A7ABB7',
                                                fontSize: 12,
                                            }}
                                        />

                                        <YAxis
                                            label={{
                                                value: 'Extension Degrees (°)',
                                                angle: -90,
                                                position: 'insideBottomLeft',
                                                textColor: '#A7ABB7',
                                                fontSize: 12,
                                            }}
                                        />
                                        <Line
                                            dataKey="peakExtensionLeft"
                                            stroke="#8884d8"
                                            fill="#8884d8"
                                        />
                                        <Line
                                            dataKey="peakExtensionRight"
                                            stroke="#f54242"
                                            fill="#f54242"
                                        />
                                        <Tooltip
                                            payload={[]}
                                            contentStyle={{
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',

                                                border: '1px solid #a8bfd0',
                                                borderRadius: 15,
                                            }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            item
                            style={{
                                paddingRight: 8,
                                paddingLeft: 8,
                                paddingBottom: 8,
                            }}
                        >
                            <Paper
                                style={{
                                    width: '100%',
                                    height: 200,
                                    backgroundColor: '#D9DADF',
                                    borderRadius: 20,
                                    border: '1px solid #A7ABB7',
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                }}
                            >
                                <ResponsiveContainer>
                                    <LineChart
                                        data={peakFlexionData}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            left: 20,
                                            bottom: 20,
                                        }}
                                    >
                                        <Legend
                                            verticalAlign="top"
                                            height={36}
                                            payload={[{ value: 'Peak Flexion Asymmetry' }]}
                                            iconSize={6}
                                        />
                                        <XAxis
                                            dataKey="rep"
                                            label={{
                                                value: 'Stride Number',
                                                angle: 0,
                                                position: 'bottom',
                                                textColor: '#A7ABB7',
                                                fontSize: 12,
                                            }}
                                        />
                                        <YAxis
                                            label={{
                                                value: 'Extension Degrees (°)',
                                                angle: -90,
                                                position: 'insideBottomLeft',
                                                textColor: '#A7ABB7',
                                                fontSize: 12,
                                            }}
                                        />
                                        <Line
                                            dataKey="peakFlexionLeft"
                                            stroke="#8884d8"
                                            fill="#8884d8"
                                        />
                                        <Line
                                            dataKey="peakFlexionRight"
                                            stroke="#f54242"
                                            fill="#f54242"
                                        />
                                        <Tooltip
                                            payload={[]}
                                            contentStyle={{
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',

                                                border: '1px solid #a8bfd0',
                                                borderRadius: 15,
                                            }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            item
                            style={{
                                paddingRight: 8,
                                paddingLeft: 8,
                                paddingBottom: 8,
                            }}
                        >
                            <Paper
                                style={{
                                    width: '100%',
                                    height: 200,
                                    backgroundColor: '#D9DADF',
                                    borderRadius: 20,
                                    border: '1px solid #A7ABB7',
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                }}
                            >
                                <ResponsiveContainer>
                                    <LineChart
                                        data={groundTimeData}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            left: 20,
                                            bottom: 20,
                                        }}
                                    >
                                        <Legend
                                            verticalAlign="top"
                                            height={36}
                                            payload={[{ value: 'Ground Time Asymmetry' }]}
                                            iconSize={6}
                                        />
                                        <XAxis
                                            dataKey="rep"
                                            label={{
                                                value: 'Stride Number',
                                                angle: 0,
                                                position: 'bottom',
                                                textColor: '#A7ABB7',
                                                fontSize: 12,
                                            }}
                                        />
                                        <YAxis
                                            label={{
                                                value: 'Ground Time (ms)',
                                                angle: -90,
                                                position: 'insideBottomLeft',
                                                textColor: '#A7ABB7',
                                                fontSize: 12,
                                            }}
                                        />
                                        <Line
                                            dataKey="groundTimeLeft"
                                            stroke="#8884d8"
                                            fill="#8884d8"
                                        />
                                        <Line
                                            dataKey="groundTimeRight"
                                            stroke="#f54242"
                                            fill="#f54242"
                                        />
                                        <Tooltip
                                            payload={[]}
                                            contentStyle={{
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',

                                                border: '1px solid #a8bfd0',
                                                borderRadius: 15,
                                            }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            item
                            style={{
                                paddingRight: 8,
                                paddingLeft: 8,
                                paddingBottom: 8,
                            }}
                        >
                            <Paper
                                style={{
                                    width: '100%',
                                    height: 200,
                                    backgroundColor: '#D9DADF',
                                    borderRadius: 20,
                                    border: '1px solid #A7ABB7',
                                    background:
                                        'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',
                                }}
                            >
                                <ResponsiveContainer>
                                    <LineChart
                                        data={airTimeData}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            left: 20,
                                            bottom: 20,
                                        }}
                                    >
                                        <Legend
                                            verticalAlign="top"
                                            height={36}
                                            payload={[{ value: 'Air Time Asymmetry' }]}
                                            iconSize={6}
                                        />
                                        <XAxis
                                            dataKey="rep"
                                            label={{
                                                value: 'Stride Number',
                                                angle: 0,
                                                position: 'bottom',
                                                textColor: '#A7ABB7',
                                                fontSize: 12,
                                            }}
                                        />
                                        <YAxis
                                            label={{
                                                value: 'Air Time (ms)',
                                                angle: -90,
                                                position: 'insideBottomLeft',
                                                textColor: '#A7ABB7',
                                                fontSize: 12,
                                            }}
                                        />
                                        <Line
                                            dataKey="airTimeLeft"
                                            stroke="#8884d8"
                                            fill="#8884d8"
                                        />
                                        <Line
                                            dataKey="airTimeRight"
                                            stroke="#f54242"
                                            fill="#f54242"
                                        />
                                        <Tooltip
                                            payload={[]}
                                            contentStyle={{
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,.011), rgba(217,218,223,.59))',

                                                border: '1px solid #a8bfd0',
                                                borderRadius: 15,
                                            }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Paper>
                <Box sx={{ p: 8, width: '100%' }}>
                    <DataGridPremium
                        autoHeight={true}
                        density="compact"
                        rows={rows}
                        columns={columns}
                        disableRowSelectionOnClick
                        slots={{ loadingOverlay: CustomLoadingOverlay }}
                        getRowClassName={(params) =>
                            params.row['foot'] === 'L' ? 'Mui-even' : 'Mui-odd'
                        }
                        sx={{
                            '& .MuiDataGrid-row.Mui-even': {
                                background:
                                    'linear-gradient(to bottom, rgba(208,225,243, 0.08), rgba(208,225,243, 0.38))',
                            },
                            '& .MuiDataGrid-row.Mui-odd': {
                                background:
                                    'linear-gradient(to bottom, rgba(245,203,204, 0.08), rgba(245,203,204, 0.38))',
                            },
                        }}
                    />
                </Box>
            </Paper>
        </>
    );
}

export default VideoAnalysis;
