import { Box, Button, Skeleton, Stack } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import createCheckoutSession from '../../../api/Auth/createCheckoutSession';
import { isSubscribedToOrgAccessTier, isSubscribedToVideoAnalysis } from '../../../app.functions';
import { OrganizationsContext } from '../../../contexts/OrganizationsContext';
import VideoAILogoWide from '../../../images/VideoAILogoWide.png';
import { authSelector } from '../../../redux/reducers/auth';
import { userSelector } from '../../../redux/reducers/user';
import { Asset, Comment, Individual, Meta, PaginationLink } from '../../../utils';
import { createVideo, getVideos } from '../api/individuals.api';
import CommentViewingModal from './CommentViewingModal';
import VideoAnalysisUploadButton from './VideoAnalysisUploadButton';
import VideoCard from './VideoCard';
import VideoUploadButton from './VideoUploadButton';

interface Props {
    individual: Individual;
}

function VideoFeed({ individual }: Props) {
    const [videosLoading, setVideosLoading] = useState<boolean>(false);
    const [videos, setVideos] = useState<Asset[]>([]);
    const [videosLinks, setVideosLinks] = useState<PaginationLink>();
    const { currentUser } = useSelector(authSelector);
    const { userData } = useSelector(userSelector);
    const [videosMeta, setVideosMeta] = useState<Meta>();
    const [assetsDeleting] = useState<{ [key: string]: boolean }>({});
    const [selectedAssetForCommentViewing, setSelectedAssetForCommentViewing] = useState<Asset>();
    const { subscriptions } = useContext(OrganizationsContext);
    const [checkingOut, setCheckingOut] = useState(false);

    function handleCommentAdded(comment: Comment, asset: Asset): void {
        setVideos((videos) => {
            const ix = videos.findIndex((v) => v.uuid === asset.uuid);

            const newVideo = videos[ix];

            newVideo.comments = [comment, ...(newVideo.comments ?? [])];

            videos.splice(ix, 1, newVideo);

            return [...videos];
        });
    }

    function handleCommentDeleted(commentId: string, asset: Asset): void {
        setVideos((videos) => {
            const ix = videos.findIndex((v) => v.uuid === asset.uuid);

            const newVideo = videos[ix];

            newVideo.comments = newVideo.comments?.filter((c) => c.uuid !== commentId) ?? [];

            videos.splice(ix, 1, newVideo);

            return [...videos];
        });
    }

    const handleVideoDelete = useCallback((id: string) => {
        setVideos((v) => {
            const vids = [...v];
            const ix = vids.findIndex((a) => a.uuid === id);
            if (ix > -1) {
                vids.splice(ix, 1);
            }
            return vids;
        });
    }, []);

    const loadVideos = useCallback(
        (page: any) => {
            setVideosLoading(true);
            getVideos(individual.uuid, page)
                .then((response) => {
                    if (response.data.meta.current_page > 1) {
                        setVideos((v) => [...v, ...response.data.data]);
                    } else {
                        setVideos(response.data.data);
                    }
                    setVideosMeta(response.data.meta);
                    setVideosLinks(response.data.links);
                })
                .catch(() => {})
                .finally(() => setVideosLoading(false));
        },
        [individual],
    );

    const loadNextVideosPage = useCallback(() => {
        if (videosMeta?.current_page) {
            loadVideos(videosMeta?.current_page + 1);
        }
    }, [videosMeta, loadVideos]);

    useEffect(() => {
        loadVideos(1);
    }, [loadVideos]);

    return (
        <>
            {userData && (
                <Stack spacing={4} sx={{ width: '100%' }}>
                    <Box sx={{ display: 'flex' }}>
                        <VideoUploadButton
                            accessToken={currentUser?.accessToken ?? ''}
                            onUploaded={(path) => {
                                setVideosLoading(true);
                                createVideo(individual.uuid, path)
                                    .then(() => {
                                        setVideosLoading(false);
                                        loadVideos(1);
                                    })
                                    .catch(() => {});
                            }}
                        />

                        {isSubscribedToVideoAnalysis(individual.organization.uuid, subscriptions) ||
                        isSubscribedToOrgAccessTier(individual.organization.uuid, subscriptions, [
                            process.env.REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE as string,
                        ]) ? (
                            <VideoAnalysisUploadButton
                                accessToken={currentUser?.accessToken ?? ''}
                                defaultText={'Sprint Analysis Video Upload'}
                                chooseDateFirst={true}
                                onUploaded={(path, date) => {
                                    setVideosLoading(true);
                                    createVideo(individual.uuid, path, date ? date : undefined)
                                        .then(() => {
                                            setVideosLoading(false);
                                            loadVideos(1);
                                        })
                                        .catch(() => {});
                                }}
                            />
                        ) : (
                            <Button
                                style={{
                                    marginTop: 12,
                                    display: 'flex', // Use flexbox for layout
                                    flexDirection: 'column', // Stack the elements vertically
                                    alignItems: 'center', // Center the content horizontally
                                    justifyContent: 'center', // Center the content vertically
                                    width: '100%',
                                    border: '2px dashed gray',
                                    borderRadius: 5,
                                    textAlign: 'center',
                                    backgroundColor: 'white',
                                    padding: 12,
                                    height: '150px', // Set the height to accommodate the background image and text
                                    lineHeight: '1.5', // Add spacing between lines of text
                                }}
                                disabled={checkingOut}
                                onClick={() => {
                                    setCheckingOut(true);
                                    createCheckoutSession(
                                        window.location.href,
                                        individual.organization.uuid,
                                        process.env.REACT_APP_STRIPE_VIDEO_PRICE,
                                    )
                                        .then((response) => {
                                            window.location.href = response.data.url;
                                        })
                                        .catch(() => {
                                            setCheckingOut(false);
                                        });
                                }}
                                startIcon={
                                    <img
                                        src={VideoAILogoWide}
                                        alt="Video AI Logo"
                                        style={{ width: '100px' }}
                                    />
                                } // Adjust the width as needed
                            >
                                <div style={{ marginBottom: '8px' }}>
                                    <strong>*** Premium Feature ***</strong>
                                </div>
                                Click to enable Video AI - Sprint Analysis
                            </Button>
                        )}
                    </Box>

                    {videosLoading && <Skeleton sx={{ width: '100%', height: 200 }} />}
                    {videos.map((a) => (
                        <VideoCard
                            key={a.uuid}
                            asset={a}
                            individual={individual}
                            onVideoDelete={handleVideoDelete}
                            isDeleting={assetsDeleting[a.uuid]}
                            viewAllCommentsClicked={(event, asset) =>
                                setSelectedAssetForCommentViewing(asset)
                            }
                            user={userData}
                            commentAdded={(comment) => handleCommentAdded(comment, a)}
                            commentDeleted={(comment) => handleCommentDeleted(comment, a)}
                            showSeek={false}
                            analysis={a.video_analysis}
                        />
                    ))}
                    <Button
                        onClick={() => loadNextVideosPage()}
                        disabled={!videosLinks?.next || videosLoading}
                    >
                        Show More
                    </Button>
                    {selectedAssetForCommentViewing && (
                        <CommentViewingModal
                            commentAdded={(comment) =>
                                handleCommentAdded(comment, selectedAssetForCommentViewing)
                            }
                            commentDeleted={(comment) =>
                                handleCommentDeleted(comment, selectedAssetForCommentViewing)
                            }
                            user={userData}
                            commentableUuid={selectedAssetForCommentViewing.uuid}
                            PaperProps={{
                                sx: {
                                    width: {
                                        xs: '90vw',
                                        sm: '60vw',
                                    },
                                    height: '80vh',
                                },
                            }}
                            individual={individual}
                            open={true}
                            onClose={() => setSelectedAssetForCommentViewing(undefined)}
                        ></CommentViewingModal>
                    )}
                </Stack>
            )}
        </>
    );
}

export default React.memo(VideoFeed);
