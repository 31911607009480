import { Container } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { getIndividuals } from 'api';
import OrganizationFilterList from 'components/Filters/OrganizationFilterList';
import CustomDateRangePicker from 'components/FormControl/CustomDateRangePicker';
import { OrganizationsContext } from 'contexts/OrganizationsContext';
import { DateTime } from 'luxon';
import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { Individual, Organization } from 'utils';
import { getAllUploads } from '../api/performance.api';
import { PerformanceDataUpload } from '../community.types';

export default function IndividualsGpsSummaryReport(): ReactElement {
    const { organizations } = useContext(OrganizationsContext);
    const [individuals, setIndividuals] = useState<Individual[]>([]);
    const [uploads, setUploads] = useState<{ [key: string]: PerformanceDataUpload[] }>({});
    const [loading, setLoading] = useState(true);
    const [selectedOrganization, setSelectedOrganization] = useState<string | false>(false);
    const [filters, setFilters] = useState<{
        dateRange: {
            start: DateTime;
            end: DateTime;
        };
        limit: number;
    }>({
        dateRange: {
            start: DateTime.now().minus({ days: 7 }),
            end: DateTime.now(),
        },
        limit: 50,
    });

    const handleDateRangeChange = (newDateRange: DateRange<DateTime>) => {
        if (newDateRange[0] != null && newDateRange[1] != null) {
            setFilters((prev) => ({
                ...prev,
                dateRange: {
                    start: newDateRange?.[0] ?? DateTime.now(),
                    end: newDateRange?.[1] ?? DateTime.now(),
                },
            }));
        }
    };

    const loadUploadsForIndividuals = async (
        individualIds: string[],
        startDate: DateTime,
        endDate: DateTime,
    ) => {
        try {
            const response = await getAllUploads({
                'filter[activity_after]': startDate.toFormat('yyyy-MM-dd'),
                'filter[activity_before]': endDate.toFormat('yyyy-MM-dd'),
                'filter[individual_uuid]': individualIds.join(','),
            });

            // Group uploads by individual_uuid
            const newUploads = response.data.reduce((acc, upload) => {
                if (!acc[upload.individual.uuid]) {
                    acc[upload.individual.uuid] = [];
                }
                acc[upload.individual.uuid].push(upload);
                return acc;
            }, {} as { [key: string]: PerformanceDataUpload[] });

            setUploads(newUploads);
        } catch (error) {
            console.error('Error loading uploads for individuals:', error);
        }
    };

    const loadIndividuals = useCallback(
        async (orgId?: string) => {
            try {
                setLoading(true);
                const apiFilters: any = {
                    'filter[hasUploadsAfter]': filters.dateRange.start.toFormat('yyyy-MM-dd'),
                    'filter[hasUploadsBefore]': filters.dateRange.end.toFormat('yyyy-MM-dd'),
                    limit: filters.limit,
                };

                if (orgId) {
                    apiFilters['filter[organization]'] = orgId;
                }

                const response = await getIndividuals(apiFilters);
                setIndividuals(response.data.data);

                // Load uploads for all individuals in one batch
                const individualIds = response.data.data.map((individual) => individual.uuid);
                await loadUploadsForIndividuals(
                    individualIds,
                    filters.dateRange.start,
                    filters.dateRange.end,
                );
            } catch (error) {
                console.error('Error loading individuals:', error);
            } finally {
                setLoading(false);
            }
        },
        [filters],
    );

    useEffect(() => {
        loadIndividuals(selectedOrganization || undefined);
    }, [selectedOrganization, loadIndividuals]);

    const handleOrganizationClick = (organization: Organization) => {
        setSelectedOrganization(
            selectedOrganization === organization.uuid ? false : organization.uuid,
        );
    };

    return (
        <Container maxWidth="lg">
            <OrganizationFilterList
                organizations={organizations}
                onClicked={handleOrganizationClick}
                selectedOrganization={selectedOrganization}
                title="Filter by Organization"
                avatarSize={40}
                iconButtonSize={48}
                bottomMargin={16}
                titleSize="h6"
            />
            <CustomDateRangePicker
                value={[filters.dateRange.start, filters.dateRange.end]}
                onChange={handleDateRangeChange}
            />
            {loading
                ? 'Loading...'
                : `Loaded ${individuals.length} individuals with ${
                      Object.values(uploads).flat().length
                  } total uploads`}
        </Container>
    );
}
