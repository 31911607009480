import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import ScribeCard from '../components/ScribeCard';

function SingleRawDataImport() {
    return (
        <>
            {/* Grid container to hold the rows */}
            <Grid container spacing={3}>
                <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                    <Typography
                        variant={'caption'}
                        align={'center'}
                        fontSize={25}
                        sx={{ margin: 8, marginBottom: 10 }}
                    >
                        For 1 on 1 sessions, importing a single csv file to an individual is the way
                        to go.
                    </Typography>
                </Grid>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item xs={12} sx={{ marginBottom: 10, marginTop: 15 }}>
                        <Typography
                            variant={'caption'}
                            align={'center'}
                            fontSize={25}
                            sx={{ margin: 8, marginBottom: 10 }}
                        >
                            Manual CSV import a single file to an individual.
                        </Typography>
                    </Grid>
                </Grid>

                {/* First row */}
                <ScribeCard
                    title={'McLloyd Manual CSV import to Individual'}
                    headline={'McLloyd Manual CSV import to Individual'}
                    iframeSrc={
                        'https://scribehow.com/embed/Uploading_McLloyd_Raw_Data__Sprint_Profiling__H2Fc66T6TSmSPuPC_1SSpQ?as=scrollable&skipIntro=true&removeLogo=true'
                    }
                />
            </Grid>
        </>
    );
}

export default SingleRawDataImport;
