import { AxiosResponse } from 'axios';
import { Request } from '../../../api';
import { CalendarEvent, CountSearchDto, DashboardCounts } from '../../../utils';
import { Attendance, AttendanceSearchFilters, SessionSearchDto } from '../train.types';

export function getEventsFromCalendar(
    params: SessionSearchDto,
): Promise<AxiosResponse<Array<CalendarEvent>>> {
    return Request.get('calendar/events', {
        params,
    });
}

export function editEventOnCalendar(
    id: string,
    params: {
        title?: string;
        starts_at?: string;
        ends_at?: string | null;
    },
): Promise<AxiosResponse<CalendarEvent>> {
    return Request.put<CalendarEvent>(`calendar/events/${id}`, params);
}

export function deleteEventFromCalendar(id: string): Promise<AxiosResponse<void>> {
    return Request.delete<void>(`calendar/events/${id}`);
}

export function getAttendance(
    params: AttendanceSearchFilters,
): Promise<AxiosResponse<Array<Attendance>>> {
    return Request.get('calendar/attendance', {
        params,
    });
}

export function getCalendarDashboard(
    params: CountSearchDto,
): Promise<AxiosResponse<DashboardCounts>> {
    return Request.get('calendar/dashboard', { params });
}

export function skipSession(params: {
    event: string;
    individual: string;
}): Promise<AxiosResponse<void>> {
    return Request.post(`calendar/events/${params.event}/skip`, {
        individual: params.individual,
    });
}
