import { Exploration, ExplorationFilters } from '../explore.types';
import React, { useCallback, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import { convertApiFiltersToExplorationFilters } from '../explore.func';
import cubejs, { CubejsApi, Series, SeriesNamesColumn } from '@cubejs-client/core';
import { getCubeToken } from '../../../api/charts.api';
import { DateTime } from 'luxon';
import LoadingWithText from './LoadingWithText';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { grey } from '@mui/material/colors';

interface Props {
    exploration: Exploration;
}

const ExplorationGpsSummaryProgress = ({ exploration }: Props) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const [progressData, setProgressData] = useState<Series<any>[]>([]);
    const [seriesNames, setSeriesNames] = useState<SeriesNamesColumn[]>([]);
    const [filters, setFilters] = useState<ExplorationFilters>(
        convertApiFiltersToExplorationFilters(exploration.filters),
    );
    const isBiggestNumber = (value: number, series: any[], index: number): boolean => {
        const max = Math.max(...series.map((s) => s.value));
        const lastIndex = series.map((s) => s.value).lastIndexOf(max);
        return value === max && index === lastIndex;
    };
    const [api, setApi] = useState<CubejsApi>();

    const getSeriesName = useCallback(
        (name: string) => {
            return seriesNames.find((s) => s.key === name)?.yValues[0];
        },
        [seriesNames],
    );

    useEffect(() => {
        setApi(
            cubejs(
                () =>
                    getCubeToken({
                        exploration: exploration.uuid,
                    }).then((r) => r.data.token),
                {
                    apiUrl: `${process.env.REACT_APP_CUBE_URL}/cubejs-api/v1`,
                },
            ),
        );
        setFilters(convertApiFiltersToExplorationFilters(exploration.filters));
    }, [exploration]);
    useEffect(() => {
        if (!api) {
            return;
        }
        setLoading(true);
        api.load({
            dimensions: filters.dimensions,
            measures: filters.measures,
        })
            .then((progressResponse) => {
                setSeriesNames(progressResponse.seriesNames());
                setProgressData(progressResponse.series());
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
    }, [filters, api]);

    return (
        <>
            <Avatar
                variant={'rounded'}
                sx={{ margin: 'auto', width: 100, height: 100 }}
                src={exploration.organization.logo_url ?? ''}
            />
            <Typography variant={'h4'} align={'center'}>
                {exploration.title}
            </Typography>
            <Typography variant={'h5'} align={'center'}>
                {filters.measures &&
                    filters.measures.indexOf('GpsSummary.maxDistance') > -1 &&
                    'Total Distance'}
                {filters.measures &&
                    filters.measures.indexOf('GpsSummary.maxSpeed') > -1 &&
                    'Top Speed'}
                {filters.measures &&
                    filters.measures.indexOf('GpsSummary.maxNumberOfSprints') > -1 &&
                    'Max Number Of Sprints'}
                {filters.measures &&
                    filters.measures.indexOf('GpsSummary.numberOfSprints') > -1 &&
                    'Total Number Of Sprints'}
            </Typography>
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <LoadingWithText text={'Loading Data'} />
                </Box>
            )}
            {progressData?.length === 0 && !loading && (
                <Stack sx={{ justifyContent: 'center', alignItems: 'center' }} spacing={8}>
                    <LocationOffIcon sx={{ fontSize: 100, color: grey[500] }} />
                    <Typography>No GPS summary data for the specified date range</Typography>
                </Stack>
            )}
            {progressData?.length > 0 && (
                <TableContainer sx={{ background: theme.palette.primary.main }} component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    variant={'head'}
                                    sx={{
                                        color: '#fff',
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 1,
                                        backgroundColor: theme.palette.primary.main,
                                    }}
                                >
                                    Athlete
                                </TableCell>
                                {progressData[0]?.series.map((d: any) => (
                                    <TableCell variant={'head'} sx={{ color: '#fff' }} key={d.x}>
                                        <Box
                                            sx={{
                                                writingMode: 'vertical-lr',
                                                transform: 'rotate(180deg)',
                                                textAlign: 'left',
                                            }}
                                        >
                                            {DateTime.fromISO(d.x).toFormat('MM/dd/yyyy')}
                                        </Box>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {progressData.map((d, i) => (
                                <TableRow key={i}>
                                    <TableCell
                                        sx={{
                                            color: '#fff',
                                            minWidth: 120,
                                            maxWidth: 220,
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 1,
                                            backgroundColor: theme.palette.primary.main,
                                            fontSize: 16,
                                        }}
                                    >
                                        {getSeriesName(d.key)}
                                    </TableCell>
                                    {d.series.map((s: any, index: number) => (
                                        <TableCell
                                            sx={{
                                                color: '#fff',
                                                minWidth: 40,
                                                maxWidth: 40,
                                                fontSize: 20,
                                            }}
                                            key={s.x}
                                        >
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                {s.value === 0 ? '-' : s.value}
                                                {isBiggestNumber(s.value, d.series, index) && (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            color: theme.palette.secondary.main,
                                                        }}
                                                    >
                                                        <Typography variant={'h6'}>↑</Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default ExplorationGpsSummaryProgress;
