import React from 'react';

interface Props {
    children: React.ReactNode;
}

function DescriptionList({ children }: Props): React.ReactElement {
    return (
        <dl
            style={{
                marginTop: 4,
                borderTop: '1px solid gray',
                borderBottom: '1px solid gray',
                fontSize: '1rem',
            }}
        >
            {children}
        </dl>
    );
}

export default DescriptionList;
