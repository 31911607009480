import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Meta, TCubeMeasure } from '@cubejs-client/core';
import CubeRelativeDateRangeSelector from '../../../components/FormControl/CubeRelativeDateRangeSelector';
import { CommunityReportFilters, ReportType, SavedFilterType } from '../community.types';
import { FilterByGroup, FilterByIndividual, FilterByMovement } from '../../../components';
import { Group, Individual, Movement, Organization, TemExercise } from '../../../utils';
import React, { useEffect, useState } from 'react';
import ChartTypeSelect from './ChartTypeSelect';
import FreeSoloCreateOptionDialog from './FreeSoloCreateOptionDialog';
import { DateRangeValue, Tag } from '../../../app.types';
import { getTags } from '../../../api/tags.api';
import { mapTagIntoFreeSoloOption } from '../community.func';
import TemLogo from 'images/TemLogo.svg';
import { getDimensionChoices } from '../../home/home.api';

interface Props {
    filters: CommunityReportFilters;
    onChange: (filters: CommunityReportFilters) => void;
    meta: Meta;
    organization: Organization;
    savedFilterType: SavedFilterType;
}

const reportTypes: { [key in SavedFilterType]: { [key: string]: string } } = {
    'community.activity': {
        trend: 'Trend - Compare Athletes Improvement/Decrease Over Time',
        'trend-by-movement': 'Trend - Compare Athletes & Movements',
        'trend-combined-athletes': 'Trend - Compare Movements',
        chart: 'Chart - Compare Athletes Side By Side',
        'chart-by-movement': 'Chart - Compare Movements',
        'chart-athletes-movements': 'Chart - Compare Athletes & Movements',
    },
    'community.upload': {
        trend: 'Trend - Compare Athletes',
        chart: 'Chart - Compare Athletes',
    },
    'community.performance': {
        chart: 'Compare Athletes Latest Measurements',
    },
    'community.tem': {
        trend: 'Trend - Compare Athletes',
    },
    'community.group.gps': {
        trend: 'Athlete Trends',
        'trend-by-group': 'Group Trends',
        chart: 'Athlete Chart',
    },
    'community.upload.time.segments': {
        trend: 'Trend - Athlete Improvement/Decrease Over Time',
        chart: 'Chart - Compare Time Segments Against Each Other',
    },
};

const getFactTable = (type: SavedFilterType) => {
    switch (type) {
        case 'community.upload':
            return 'FactSprints';
        case 'community.tem':
            return 'FactTemMotions';
        case 'community.activity':
            return 'FactLogs';
        case 'community.performance':
            return 'LatestLogs';
        case 'community.upload.time.segments':
            return 'FactSprintTimeSegments';
        case 'community.group.gps':
            return 'FactGpsSummaries';
        default:
            return 'FactLogs';
    }
};

function CommunityActivityFilters({
    filters,
    onChange,
    meta,
    organization,
    savedFilterType,
}: Props): React.ReactElement {
    const [measureSearchValue, setMeasureSearchValue] = useState<string>('');
    const [tags, setTags] = useState<Tag[]>([]);
    const [temExerciseNames, setTemExerciseNames] = useState<TemExercise[]>([]);

    useEffect(() => {
        if (
            savedFilterType === 'community.upload' ||
            savedFilterType === 'community.upload.time.segments'
        ) {
            getTags({ 'filter[type]': 'uploads' })
                .then((response) => {
                    setTags(response.data);
                })
                .catch(() => {});
        }
        if (savedFilterType === 'community.tem') {
            getDimensionChoices('tem-exercises', {
                page: 1,
                limit: 100,
            })
                .then((response) => {
                    setTemExerciseNames(response.data.data);
                })
                .catch(() => {});
        }
    }, [savedFilterType]);

    return (
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item sm={12} md={6}>
                <Paper sx={{ padding: 4, minHeight: 100 }}>
                    <Typography component={'div'} fontWeight={500}>
                        Choose A Report Type
                    </Typography>
                    <Select
                        variant={'outlined'}
                        value={filters.reportType}
                        onChange={(e) =>
                            onChange({
                                ...filters,
                                reportType: e.target.value as any,
                            })
                        }
                        label="Report Type By"
                        sx={{ maxWidth: '100%' }}
                    >
                        {Object.keys(reportTypes[savedFilterType]).map((key: any) => (
                            <MenuItem key={key} value={key}>
                                {reportTypes[savedFilterType][key as ReportType]}
                            </MenuItem>
                        ))}
                    </Select>
                </Paper>
            </Grid>

            <Grid item sm={12} md={6}>
                <Paper sx={{ padding: 4, minHeight: 100 }}>
                    {savedFilterType !== 'community.upload' && (
                        <Typography fontWeight={500} component={'div'}>
                            Choose Measurement(s){' '}
                        </Typography>
                    )}
                    {savedFilterType === 'community.upload' && (
                        <Typography fontWeight={500} component={'div'}>
                            Select Speed Metric{' '}
                        </Typography>
                    )}
                    <Autocomplete
                        multiple
                        id="fact-measurement-autocomplete"
                        options={Object.values(
                            meta.cubesMap[getFactTable(savedFilterType)]['measures'],
                        )}
                        selectOnFocus={false}
                        inputValue={measureSearchValue}
                        onInputChange={(_, newInputValue) => {
                            setMeasureSearchValue(newInputValue);
                        }}
                        value={filters.measurements}
                        onChange={(_, value) => {
                            onChange({
                                ...filters,
                                measurements: value,
                            });
                        }}
                        getOptionLabel={(option: TCubeMeasure) => option.shortTitle}
                        isOptionEqualToValue={(option: TCubeMeasure, value) =>
                            option?.name === value?.name
                        }
                        style={{
                            width: '100%',
                            maxWidth: 375,
                            minWidth: 250,
                            margin: 2,
                        }}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" color={'primary'} />
                        )}
                    />
                </Paper>
            </Grid>
            <Grid item sm={12} md={6}>
                <Paper sx={{ padding: 4, minHeight: 100 }}>
                    <Typography fontWeight={500} component={'div'}>
                        Date Range
                    </Typography>
                    <CubeRelativeDateRangeSelector
                        value={filters.timeDimension}
                        onChange={(value: DateRangeValue) =>
                            onChange({
                                ...filters,
                                timeDimension: value,
                            })
                        }
                    />
                </Paper>
            </Grid>

            <Grid item sm={12} md={6}>
                <Paper sx={{ padding: 4, minHeight: 100 }}>
                    <Typography fontWeight={500} component={'div'}>
                        Filter by Athlete(s)
                    </Typography>
                    <FilterByIndividual
                        organization={organization}
                        value={filters.individuals}
                        onChange={(value: Individual[]) =>
                            onChange({
                                ...filters,
                                individuals: value,
                            })
                        }
                    />
                </Paper>
            </Grid>
            {['community.activity', 'community.performance'].indexOf(savedFilterType) > -1 && (
                <Grid item sm={12} md={6}>
                    <Paper sx={{ padding: 4, minHeight: 100 }}>
                        <Typography fontWeight={500} component={'div'}>
                            Filter by Movement(s)
                        </Typography>
                        <FilterByMovement
                            organization={organization}
                            value={filters.movements}
                            onChange={(value: Movement[]) =>
                                onChange({
                                    ...filters,
                                    movements: value,
                                })
                            }
                        />
                    </Paper>
                </Grid>
            )}
            <Grid item sm={12} md={6}>
                <Paper sx={{ padding: 4, minHeight: 100 }}>
                    <Typography fontWeight={500} component={'div'}>
                        Filter by Group
                    </Typography>
                    <FilterByGroup
                        organization={organization}
                        value={filters.athleteGroups ?? []}
                        onChange={(value: Group[]) =>
                            onChange({
                                ...filters,
                                athleteGroups: value,
                            })
                        }
                    />
                </Paper>
            </Grid>
            {['community.upload', 'community.upload.time.segments'].indexOf(savedFilterType) >
                -1 && (
                <Grid item sm={12} md={6}>
                    <Paper sx={{ padding: 4, minHeight: 100 }}>
                        <Typography fontWeight={600} component={'div'}>
                            Filter by Resistance/Assistance - Load (kg){' '}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Select
                                onChange={(v) =>
                                    onChange({
                                        ...filters,
                                        load: v.target.value as any,
                                    })
                                }
                                value={filters.load ?? []}
                                multiple
                            >
                                <MenuItem value={'-7'}>-7kg</MenuItem>
                                <MenuItem value={'-5'}>-5kg</MenuItem>
                                <MenuItem value={'-3'}>-3kg</MenuItem>
                                <MenuItem value={'0'}>0kg</MenuItem>
                                <MenuItem value={'1'}>1kg</MenuItem>
                                <MenuItem value={'3'}>3kg</MenuItem>
                                <MenuItem value={'5'}>5kg</MenuItem>
                                <MenuItem value={'7'}>7kg</MenuItem>
                                <MenuItem value={'10'}>10kg</MenuItem>
                                <MenuItem value={'15'}>15kg</MenuItem>
                                <MenuItem value={'30'}>30kg</MenuItem>
                            </Select>
                            <img
                                alt={'tem-logo'}
                                src={TemLogo}
                                style={{ marginLeft: '20px', height: '10px' }}
                            />
                        </div>
                    </Paper>
                </Grid>
            )}

            {['community.upload.time.segments'].indexOf(savedFilterType) > -1 && (
                <Grid item sm={12} md={6}>
                    <Paper sx={{ padding: 4, minHeight: 100 }}>
                        <Typography fontWeight={600} component={'div'}>
                            Filter by Time Segment
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Select
                                onChange={(v) =>
                                    onChange({
                                        ...filters,
                                        segment: v.target.value as any,
                                    })
                                }
                                value={filters.segment ?? []}
                                multiple
                            >
                                <MenuItem value={'1'}>0-1s</MenuItem>
                                <MenuItem value={'2'}>1-2s</MenuItem>
                                <MenuItem value={'3'}>2-3s</MenuItem>
                                <MenuItem value={'4'}>3-4s</MenuItem>
                                <MenuItem value={'5'}>4-5s</MenuItem>
                                <MenuItem value={'6'}>5-6s</MenuItem>
                                <MenuItem value={'7'}>6-7s</MenuItem>
                                <MenuItem value={'8'}>7-8s</MenuItem>
                                <MenuItem value={'9'}>8-9s</MenuItem>
                                <MenuItem value={'10'}>9-10s</MenuItem>
                            </Select>
                        </div>
                    </Paper>
                </Grid>
            )}

            {(savedFilterType === 'community.activity' ||
                savedFilterType === 'community.performance') && (
                <Grid item sm={12} md={6}>
                    <Paper sx={{ padding: 4, minHeight: 100 }}>
                        <Typography fontWeight={500} component={'div'}>
                            Logged Through Group / Team
                        </Typography>

                        <FilterByGroup
                            organization={organization}
                            value={filters.groups ?? []}
                            onChange={(value: Group[]) =>
                                onChange({
                                    ...filters,
                                    groups: value,
                                })
                            }
                        />
                    </Paper>
                </Grid>
            )}
            {savedFilterType === 'community.tem' && (
                <Grid item sm={12} md={6}>
                    <Paper sx={{ padding: 4, minHeight: 100 }}>
                        <Typography fontWeight={500} component={'div'}>
                            Filter by Exercise Name
                        </Typography>
                        <Select
                            value={filters.temExercises ? filters.temExercises : []}
                            onChange={(v) =>
                                onChange({
                                    ...filters,
                                    temExercises: v.target.value as string[],
                                })
                            }
                            variant={'outlined'}
                            label="Exercise"
                            multiple
                        >
                            {temExerciseNames.map((e) => (
                                <MenuItem key={e.uuid} value={e.uuid}>
                                    {e.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Paper>
                </Grid>
            )}
            {['community.upload', 'community.upload.time.segments'].indexOf(savedFilterType) >
                -1 && (
                <Grid item sm={12} md={6}>
                    <Paper sx={{ padding: 4, minHeight: 100 }}>
                        <Typography fontWeight={500} component={'div'}>
                            Filter by Tag
                        </Typography>
                        <FreeSoloCreateOptionDialog
                            value={filters.tag ?? null}
                            onChange={(v) =>
                                onChange({
                                    ...filters,
                                    tag: v ?? undefined,
                                })
                            }
                            createProps={{ type: 'uploads' }}
                            organization={organization.uuid}
                            choices={tags.map((t) => mapTagIntoFreeSoloOption(t))}
                            onTagCreated={() => {}}
                        />
                    </Paper>
                </Grid>
            )}

            <Grid item sm={12} md={6}>
                <Paper sx={{ padding: 4, minHeight: 100 }}>
                    <Typography fontWeight={500} component={'div'}>
                        Time Grouping
                    </Typography>
                    <Select
                        variant={'outlined'}
                        value={filters.timeGroup}
                        onChange={(e) =>
                            onChange({
                                ...filters,
                                timeGroup: e.target.value as any,
                            })
                        }
                        label="Grouped By"
                    >
                        <MenuItem value={'day'}>Day</MenuItem>
                        <MenuItem value={'week'}>Week</MenuItem>
                        <MenuItem value={'month'}>Month</MenuItem>
                        <MenuItem value={'year'}>Year</MenuItem>
                        <MenuItem value={'none'}>None</MenuItem>
                    </Select>
                </Paper>
            </Grid>
            <Grid item sm={12} md={6}>
                <Paper sx={{ padding: 4, minHeight: 100 }}>
                    <Typography fontWeight={500} component={'div'}>
                        Chart Type
                    </Typography>
                    <ChartTypeSelect
                        value={filters.chartType}
                        onChange={(v) =>
                            onChange({
                                ...filters,
                                chartType: v,
                            })
                        }
                    />
                </Paper>
            </Grid>
            {(savedFilterType === 'community.activity' ||
                savedFilterType === 'community.performance') && (
                <Grid item sm={12} md={6}>
                    <Paper sx={{ padding: 4, minHeight: 100 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filters.showSkipped}
                                    onChange={(e) =>
                                        onChange({
                                            ...filters,
                                            showSkipped: e.target.checked as boolean,
                                        })
                                    }
                                    name="showSkipped"
                                    color="primary"
                                />
                            }
                            label="Include skipped logs"
                        />
                    </Paper>
                </Grid>
            )}

            <Grid item sm={12} md={6}>
                <Paper sx={{ padding: 4, minHeight: 100 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filters.hideLegend}
                                onChange={(e) =>
                                    onChange({
                                        ...filters,
                                        hideLegend: e.target.checked as boolean,
                                    })
                                }
                                name="showLegend"
                                color="primary"
                            />
                        }
                        label="Hide Legend"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filters.fillMissingDates}
                                onChange={(e) =>
                                    onChange({
                                        ...filters,
                                        fillMissingDates: e.target.checked as boolean,
                                    })
                                }
                                name="fillMissingDates"
                                color="primary"
                            />
                        }
                        label="Fill Missing Dates"
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default CommunityActivityFilters;
