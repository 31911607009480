import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { TypographyTitleh6 } from 'components/Typography';
import { useTheme } from '@mui/system';

interface LayoutWithTopBarProps {
    children: React.ReactNode;
    title?: string;
    onBack: () => void;
    onShare: () => void;
    right?: React.ReactNode;
    showShareButton?: boolean;
}

const TopBarWithShareIconLayout = ({
    title = '',
    children,
    onBack,
    onShare,
    showShareButton = true,
    right = <></>,
}: LayoutWithTopBarProps) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                flex: 1,
                height: '100%',
                background: theme.palette.backgroundColor.action,
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    height: '65px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: theme.spacing(8),
                    borderBottom: `1px solid ${theme.palette.primary.main}`,
                    background: theme.palette.backgroundColor.topBar,
                }}
            >
                <IconButton
                    sx={{ padding: theme.spacing(0, 4), color: theme.palette.text.secondary }}
                    onClick={onBack}
                    size="large"
                >
                    <ArrowBackIosIcon color="inherit" />
                </IconButton>
                <TypographyTitleh6
                    sx={{
                        color: theme.palette.text.primary,
                        maxWidth: '100%',
                        overflow: 'hidden',
                        display: 'block',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {title}
                </TypographyTitleh6>
                <Box sx={{ display: 'flex' }}>
                    {right}
                    {showShareButton && (
                        <IconButton
                            sx={{
                                padding: theme.spacing(0, 4),
                                color: theme.palette.text.secondary,
                            }}
                            onClick={onShare}
                            size="large"
                        >
                            <QrCode2Icon color="inherit" />
                            <ShareIcon color="inherit" />
                        </IconButton>
                    )}
                </Box>
            </Box>
            <Box
                sx={{
                    flex: 1,
                    height: 'calc(100% - 65px)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default TopBarWithShareIconLayout;
