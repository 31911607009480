import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/system';

import React, { ReactElement, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { getOrganizationsForSessionCreation } from 'redux/reducers/user';
import { Form, Formik } from 'formik';
import { Organization, yupSchemas } from 'utils';
import FormModal from './FormModal';

interface ChooseOrganizationDialogProps {
    open: boolean;
    buttonText: string | undefined;
    onCancel: () => void;
    onOrganizationSelected: (organization: Organization) => void;
}

export default function ChooseOrganizationDialog({
    open,
    onCancel,
    buttonText,
    onOrganizationSelected,
}: ChooseOrganizationDialogProps): ReactElement {
    const organizations = useSelector(getOrganizationsForSessionCreation);
    const theme = useTheme();
    const [loading, setLoading] = useState(false);

    return (
        <Formik
            initialValues={yupSchemas.chooseOrganization.getDefault()}
            enableReinitialize
            validationSchema={yupSchemas.chooseOrganization}
            onSubmit={({ organization }) => {
                setLoading(true);
                let selectedOrganization = organizations?.filter(
                    (org) => org.name == organization,
                )[0];
                onOrganizationSelected({
                    uuid: selectedOrganization?.name || '',
                    name: selectedOrganization?.display_name || '',
                } as Organization);
            }}
        >
            {({ handleSubmit, handleChange, values, errors }) => (
                <FormModal
                    handleButtonClicked={handleSubmit}
                    loading={loading}
                    handleClose={onCancel}
                    open={open}
                    title="Choose Organization"
                    buttonText={buttonText}
                >
                    <Form>
                        {!isEmpty(organizations) && (
                            <FormControl
                                sx={{ width: '100%', marginBottom: theme.spacing(4) }}
                                variant="filled"
                            >
                                <InputLabel>Choose organization</InputLabel>
                                <Select
                                    value={values.organization}
                                    onChange={handleChange}
                                    id="organization"
                                    name="organization"
                                    defaultValue=""
                                    error={Boolean(errors.organization)}
                                >
                                    {organizations?.map((organization) => {
                                        return (
                                            <MenuItem
                                                key={organization.name}
                                                value={organization.name}
                                            >
                                                {organization.display_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {errors.organization && (
                                    <FormHelperText>{errors.organization}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    </Form>
                </FormModal>
            )}
        </Formik>
    );
}
