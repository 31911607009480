import { Share } from '@mui/icons-material';
import { Box, Button, Hidden, IconButton } from '@mui/material';
import { Can, EditSessionForm, SharedUsersAvatarGroup } from 'components';
import { EditSessionRefProps } from 'components/Forms/EditSessionForm';
import FixedLayout from 'pages/Layouts/FixedLayout';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { loadAttributes } from 'redux/reducers/attributes';
import { pushMessage } from 'redux/reducers/messages';
import { useAppDispatch } from 'redux/store';
import ROUTES from 'Routes/routes';
import { Session, SharedUser, somethingWentWrong } from 'utils';

const EditSession = () => {
    const { push } = useHistory();
    const dispatch = useAppDispatch();
    const [showActions, setShowActions] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const editSessionFormRef = useRef<EditSessionRefProps>();

    useEffect(() => {
        dispatch(loadAttributes());
    }, [dispatch]);

    const saveSession = () => {
        setSaving(true);
        editSessionFormRef.current
            ?.onSessionSaved()
            ?.then(() => {
                setSaving(false);
                dispatch(
                    pushMessage({
                        status: 'success',
                        message: 'Session saved successfully.',
                    }),
                );
                push(ROUTES.ListOfSessions.path);
            })
            .catch(() => {
                setSaving(false);
                somethingWentWrong();
            });
    };

    return (
        <FixedLayout
            title="Edit Session"
            titleActions={
                <>
                    {showActions && (
                        <Can
                            I="session:share"
                            this={editSessionFormRef.current?.getSession() as Session}
                        >
                            <React.Fragment>
                                <Hidden smDown>
                                    <Box display="flex">
                                        <SharedUsersAvatarGroup sharedUsers={sharedUsers} />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                editSessionFormRef.current?.onSessionShareDrawerOpened();
                                            }}
                                        >
                                            Share
                                        </Button>
                                    </Box>
                                </Hidden>
                                <Hidden smUp>
                                    <IconButton
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            editSessionFormRef.current?.onSessionShareDrawerOpened();
                                        }}
                                    >
                                        Share
                                        <Share />
                                    </IconButton>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        disabled={saving}
                                        onClick={() => saveSession()}
                                    >
                                        {saving ? 'Saving...' : 'Save'}
                                    </Button>
                                </Hidden>
                            </React.Fragment>
                        </Can>
                    )}
                </>
            }
            content={
                <EditSessionForm
                    onFormReady={() => setShowActions(true)}
                    onUsersLoaded={(sharedusers) => setSharedUsers(sharedusers)}
                    onSaveError={() => setSaving(false)}
                    ref={editSessionFormRef}
                />
            }
            hideFooterOnMobileDevices={true}
            footerActions={
                <>
                    {showActions && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={saving}
                                onClick={() => saveSession()}
                            >
                                {saving ? 'Saving...' : 'Save Session'}
                            </Button>
                        </Box>
                    )}
                </>
            }
        ></FixedLayout>
    );
};

export default EditSession;
