import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the context's type
interface MessageContextType {
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context with a default value
const MessageContext = createContext<MessageContextType | undefined>(undefined);

// Define the provider component
export const MessageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [message, setMessage] = useState<string>('');
    return (
        <MessageContext.Provider value={{ message, setMessage }}>
            {children}
        </MessageContext.Provider>
    );
};

// Custom hook to use the context
export const useMessage = () => {
    const context = useContext(MessageContext);
    if (context === undefined) {
        throw new Error('useMessage must be used within a MessageProvider');
    }
    return context;
};
